import React from 'react'
import { Modal, ModalContent, DetailsText } from '@condofy/next/components'
import { useUserContext } from '@condofy/next/store/user'
import { phoneMask, cpfMask } from '@condofy/next/containers'

export const UserDetailsModal = () => {
  const {
    state: {
      user: { detailsModalOpen, selected }
    },
    actions: { closeDetailsUserModal: close }
  } = useUserContext()

  return (
    <Modal
      title={`Dados ${selected.name}`}
      open={detailsModalOpen}
      onClose={close}
    >
      <ModalContent>
        <DetailsText title='Nome' text={selected.name} />
        <DetailsText
          title='CPF'
          text={selected.socialSecurityNumber ? cpfMask(selected.socialSecurityNumber) : ''}
        />
        <DetailsText title='E-mail' text={selected.email} />
        <DetailsText
          title='Telefone'
          text={selected.phoneNumber ? phoneMask(selected.phoneNumber) : ''}
        />
      </ModalContent>
    </Modal>
  )
}
