import { createTheme } from '@mui/material/styles';
import { colors } from './colors'
import { typography } from './typography'
import { ProximaNova } from './fonts/'
import reset from 'styled-reset'
// import { ptBR } from '@mui/x-date-pickers/locales';
// import ptBR from 'date-fns/locale/pt-BR';

export const theme = createTheme({
  palette: {
    primary: {
      light: colors.primaryLighter,
      main: colors.primary,
      dark: colors.primaryDarker
    },
    secondary: {
      light: colors.secondaryLighter,
      main: colors.secondary,
      dark: colors.secondaryDarker
    },
    danger: {
      main: colors.danger500
    },
    warning: {
      main: colors.warning500
    },
    success: {
      main: colors.success500
    },
    text: {
      primary: colors.text,
      secondary: colors.textLight
    }
  },
  typography: {
    fontFamily: typography.type.primary,
    color: colors.text,
    button: {
      fontSize: typography.size.button
    }
  },
  components: {
    MuiDialog: {
      defaultProps: {
        onClick: (e) => e.stopPropagation(),
        BackdropProps: {
          sx: {
            pointerEvents: 'none',
          },
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        ...ProximaNova,
        ...reset,
        body: {
          height: '100%',
          // color: colors.text,
          backgroundColor: colors.light400,
          fontSize: '0.875rem',
          lineHeight: 1.43
        },
        html: {
          height: '100%'
        },
        a: {
          textDecoration: 'none',
          color: colors.primary
        }
      }
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          h1: 'h1',
          h2: 'h2',
          h3: 'h3',
          h4: 'h4',
          h5: 'h5',
          h6: 'h6',
          subtitle1: 'h6',
          subtitle2: 'h6',
          body1: 'span',
          body2: 'span'
        }
      }
    },
    MuiRadio: {
      defaultProps: {
        disableTouchRipple: true
      },
    },
    MuiSwitch: {
      defaultProps: {
        disableTouchRipple: true
      },
    },
    MuiCheckbox: {
      defaultProps: {
        disableTouchRipple: true
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableTouchRipple: true
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true
      },
      styleOverrides: {
        root: {
          borderRadius: 4,
          fontFamily: typography.type.tertiary,
          textTransform: 'none',
          fontSize: '14px',
          fontWeight: '400'
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '11px',
          color: colors.light100,
          backgroundColor: colors.dark800,
          fontFamily: typography.type.tertiary
        },
        arrow: {
          color: colors.dark800
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: colors.brandBlue500
        },
        colorSecondary: {
          backgroundColor: colors.secondary,
          color: '#fff'
        }
      }
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          borderLeft: 'solid 2px transparent',
          '&.Mui-selected': {
            'border-color': colors.brandBlue300,
            'background-color': colors.brandBlue50,
            '&:hover': {
              'background-color': colors.brandBlue50
            }
          }
        },
        gutters: {
          paddingLeft: '18px',
          paddingRight: '20px'
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            borderLeft: `solid 2px ${colors.brandBlue300}`,
            backgroundColor: colors.brandBlue50,
          }
        }
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        option: {
          '&[data-focus="true"]': {
            backgroundColor: colors.light400
          },
          '&[aria-selected="true"]': {
            backgroundColor: colors.brandBlue50,
            color: colors.primary
          }
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          height: '1.1876em'
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          lineHeight: 1.75
        },
      },
    },
  }/* ,
  ptBR */
})
