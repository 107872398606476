import React from 'react'
import {
  CommunityOnboardStep,
  CommunityOnboardStepTitle,
  CommunityOnboardStepBody
} from './CommunityOnboardStep'
import Graphic3 from 'Assets/community/work-laptop'

import { ListingForm } from '../listing/ListingForm'
import { FormProvider } from 'react-hook-form'

export const CommunityOnboardFirstOfferStep = ({ form, ...args }) => {
  return (
    <CommunityOnboardStep Image={<Graphic3 />} {...args}>
      <CommunityOnboardStepTitle>
        Vamos criar sua primeira oferta!
      </CommunityOnboardStepTitle>
      <CommunityOnboardStepBody>
        Para poder começar a aproveitar os beneficios de fazer parte da
        comunidade você precisa contribuir para a festa. Conta pra gente o que
        você tem para oferecer:
      </CommunityOnboardStepBody>
      <FormProvider {...form}>
        <ListingForm form={form} />
      </FormProvider>
    </CommunityOnboardStep>
  )
}
