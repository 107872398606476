import React from 'react'
import { Modal, ActionRow } from '@condofy/next/components'
import { ModalContent } from '../../next/components/Modal'

const PromptModal = ({
  isOpen,
  onClose,
  onConfirm,
  onCancel,
  heading,
  children,
  cancelText,
  confirmText,
  isLoading
}) => {
  const cancelHandler = onCancel || onClose

  return (
    <Modal
      title={heading}
      open={isOpen}
      onClose={onClose}
      footer={
        <ActionRow
          loading={isLoading}
          cancelText={cancelText}
          confirmText={confirmText}
          onSubmit={onConfirm}
          onCancel={cancelHandler}
        />
      }
    >
      <ModalContent>{children}</ModalContent>
    </Modal>
  )
}

export default PromptModal
