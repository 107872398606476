import React, { useCallback, useEffect } from 'react'
import { AreaCard } from '@condofy/next/containers/'
import { useUserContext } from '@condofy/next/store/user'
import { NewButton } from '@condofy/next/components'
import { TabToolbar, CardGrid } from '@condofy/components'
import {
  RestrictionAreaModal,
  RestrictionDatesModal
} from '@condofy/next/containers/area/modals'

export const AreasTab = () => {
  const {
    state: {
      isSuperAdmin,
      isAdmin,
      currentCondominium,
      area: {
        items: areaList,
        metadata: { loading }
      }
    },
    actions: { listAreas, openCreateAreaModal }
  } = useUserContext()
  const administratorUser = isSuperAdmin || isAdmin
  const stableListAreas = useCallback(listAreas, [])
  const [open, setOpen] = React.useState(false)
  const [openDates, setOpenDates] = React.useState(false)

  useEffect(() => {
    if (currentCondominium.id) {
      stableListAreas({ currentCondominium })
    }
  }, [currentCondominium, stableListAreas])

  const openRestrictionAreaModal = () => {
    setOpen(true)
  }

  const openRestrictionDatesAreaModal = () => {
    setOpenDates(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleCloseDates = () => {
    setOpenDates(false)
  }

  return (
    <>
      <TabToolbar>
        <span />
        <div>
          <NewButton
            style={{ marginRight: '15px' }}
            onClick={() => openRestrictionDatesAreaModal()}
          >
            Restrição de Datas nas Reservas
          </NewButton>
          <NewButton
            style={{ marginRight: '15px' }}
            onClick={() => openRestrictionAreaModal()}
          >
            Restrição de Reserva
          </NewButton>
          <NewButton onClick={openCreateAreaModal}>Novo</NewButton>
        </div>
      </TabToolbar>
      {administratorUser && (
        <CardGrid loading={loading}>
          {areaList.map((area) => (
            <AreaCard key={area.id} area={area} />
          ))}
        </CardGrid>
      )}

      {open && <RestrictionAreaModal isOpen={open} onClose={handleClose} />}
      {openDates && (
        <RestrictionDatesModal isOpen={openDates} onClose={handleCloseDates} />
      )}
    </>
  )
}
