import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Flex } from '../Flex'
import { Avatar } from '../Avatar'
import { DateText } from '../Text'
import styled from 'styled-components'
import { colors, typography, pxToRem, theme } from '../../styles'
import { Box, Modal } from '@mui/material'
import { OptionsButton } from '../OptionsButton'
import { deleteComment } from '../../../next/service/community'
import { DialogCloseButton } from '../Dialog/Dialog'
import { Button } from '@condofy/components'
import { Heading4, Typography } from '../Typography'
import { useCommunityContext } from '@condofy/next/store/community'
import { useSnackbar } from '@condofy/next/hooks/useSnackbar'

const Name = styled(Heading4)`
  font-family: ${typography.type.primary};
  font-size: ${pxToRem(14)}rem !important;
  font-weight: 600;
`
const Date = styled(DateText)`
  font-family: ${typography.type.primary};
  color: ${colors.dark400};
  font-size: ${pxToRem(12)}rem !important;
  font-weight: 600;
`

const Message = styled.div`
  font-family: ${typography.type.tertiary};
  font-size: ${pxToRem(14)}rem;
  color: ${colors.dark700};
  margin-top: ${theme.spacing(3)} !important;
`

const StyledAvatar = styled(Avatar)`
  margin-right: ${theme.spacing(3)} !important;
`

const DialogOverlay = styled(Modal)`
  background: rgba(0, 25, 71, 0.65);
  display: flex;
  justify-content: center;
  align-items: center;

  & .MuiPaper-root {
    background-color: ${colors.light100};
    border: solid 1px ${colors.light400};
    border-radius: 3px;
    box-shadow:
      0px 24px 18px rgba(23, 43, 77, 0.04),
      0px 35px 24px rgba(23, 43, 77, 0.08);
    padding: ${theme.spacing(3)};
    outline: none;

    ${({ maxWidth }) =>
      !maxWidth &&
      `
    max-width: 90vw;
      max-height: 90vh;

      ${theme.breakpoints.up('md')} {
        min-width: 35vw;
        max-width: 60vw;
      }
    `}
  }
`

export const Comment = ({
  avatar,
  name,
  date,
  dateFormat = 'ago',
  children,
  hasTime,
  ...props
}) => {
  return (
    <Flex direction='column' width='100%'>
      <Flex {...props}>
        <StyledAvatar src={avatar} label={name} />
        <div>
          <Name>{name}</Name>
          <Date hasTime={hasTime} date={date} format={dateFormat} />
        </div>
      </Flex>
      <Message>{children}</Message>
    </Flex>
  )
}

export const CommentWithActions = ({
  avatar,
  name,
  date,
  dateFormat = 'ago',
  children,
  isAuthor,
  hasTime,
  condominiumId,
  itemId,
  isSuperAdmin,
  isAdmin,
  ...props
}) => {
  const {
    actions: { loadFeedList }
  } = useCommunityContext()
  const { enqueueSnackbar } = useSnackbar()
  const [messageModal, setMessageModal] = useState(false)
  const [messageModalText, setMessageModalText] = useState('')

  const closeModal = () => {
    setMessageModal(false)
  }

  const deleteOpen = () => {
    setMessageModalText(
      'Tem certeza que quer excluir o comentário?\nEssa ação não poderá ser desfeita'
    )
    setMessageModal(true)
  }

  const handleAddComment = () => {
    deleteComment({ currentCondominium: condominiumId, id: itemId })
      .then(() => {
        closeModal()
        setMessageModalText('')
        loadFeedList({ currentCondominium: condominiumId })
        enqueueSnackbar('Comentário excluído com sucesso', {
          variant: 'success'
        })
      })
      .catch(() =>
        enqueueSnackbar('Erro ao excluir comentário', { variant: 'error' })
      )
  }

  return (
    <Flex direction='column' width='100%'>
      <Flex {...props}>
        <StyledAvatar src={avatar} label={name} />
        <div>
          <Name>{name}</Name>
          <Date hasTime={hasTime} date={date} format={dateFormat} />
        </div>
        {(isAuthor || isSuperAdmin || isAdmin) && (
          <Box sx={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
            <OptionsButton
              options={[
                {
                  label: 'Excluir comentário',
                  id: 0,
                  onClick: () => deleteOpen()
                }
              ]}
            />
          </Box>
        )}
      </Flex>
      <Message>{children}</Message>
      <DialogOverlay open={messageModal} onClose={closeModal}>
        <Box
          sx={{
            backgroundColor: 'white',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-between',
            minHeight: '25%',
            maxHeight: '70%',
            width: '22%',
            borderRadius: 1,
            paddingX: '2%',
            paddingBottom: '2%',
            paddingTop: '1.5%'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              width: '100%',
              height: '100%'
            }}
          >
            <DialogCloseButton onClose={closeModal} />
          </Box>
          <Box sx={{ height: '100%', marginBottom: 2, textAlign: 'center' }}>
            <Typography
              variant='h5'
              textAlign={'center'}
              whiteSpace={'pre-line'}
            >
              {messageModalText}
            </Typography>
          </Box>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <Button color='secondary' onClick={() => closeModal()}>
              Cancelar
            </Button>
            <Button color='danger' onClick={() => handleAddComment()}>
              Excluir
            </Button>
          </Box>
        </Box>
      </DialogOverlay>
    </Flex>
  )
}

Comment.propTypes = {
  avatar: PropTypes.string,
  name: PropTypes.string,
  date: PropTypes.any,
  dateFormat: PropTypes.string
}

CommentWithActions.propTypes = {
  avatar: PropTypes.string,
  name: PropTypes.string,
  date: PropTypes.any,
  dateFormat: PropTypes.string,
  isAuthor: PropTypes.boolean,
  condominiumId: PropTypes.object,
  itemId: PropTypes.string,
  isSuperAdmin: PropTypes.boolean,
  isAdmin: PropTypes.boolean
}
