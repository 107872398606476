import React, { useState } from 'react'
import {
  LoadingButton,
  Dialog,
  DialogActions,
  DialogContent,
  Flex,
  Typography,
  Skeleton
} from '@condofy/components'
import { Body } from '@condofy/components'
import { AccountBalanceOutlined } from '@mui/icons-material'
import { Grid } from '@mui/material'
import { useSnackbar, handleError } from '@condofy/next/hooks/useSnackbar'
import { cancelBelvoConsent } from '@condofy/next/service/belvo'
import { useUserContext } from '@condofy/next/store/user'
import { BankConnectionPopup } from './bankConnectionPopup'
import { BankDisconnectionPopup } from './bankDisconnectionPopup'

export const BanksIntegrationDialog = ({
  setIsOpen,
  callbackFn,
  loading: propLoading,
  banksList = []
}) => {
  const [loading, setLoading] = useState(false)
  const [deleteDialogState, setDeleteDialogState] = useState({ isOpen: false })

  const { enqueueSnackbar } = useSnackbar()
  const {
    state: { currentCondominium }
  } = useUserContext()

  const cancelConsent = (id) => {
    setLoading(true)
    cancelBelvoConsent({ condominium: currentCondominium, id })
      .then(() => {
        setDeleteDialogState({ isOpen: false })
      })
      .catch(handleError(enqueueSnackbar))
      .finally(() => {
        setLoading(false)
        callbackFn()
      })
  }

  const isLoading = loading || propLoading

  return (
    <>
      <Dialog
        open
        onClose={() => setIsOpen(false)}
        maxWidth='md'
        width='100%'
        title={
          <Body sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <AccountBalanceOutlined /> Contas integradas
          </Body>
        }
      >
        <DialogContent>
          <Grid container spacing={2} minWidth='500px' alignItems='center'>
            {!isLoading &&
              banksList.map((bank) => (
                <React.Fragment key={bank.id}>
                  <Grid item xs={8}>
                    {bank?.description?.substring(0, 20) || '-'}
                  </Grid>
                  <Grid item xs={4} sx={{display: 'flex', justifyContent: 'end'}} >
                    <LoadingButton
                      color='danger'
                      onClick={() =>
                        setDeleteDialogState({ isOpen: true, id: bank.id })
                      }
                      loading={isLoading}
                    >
                      Desconectar
                    </LoadingButton>
                  </Grid>
                </React.Fragment>
              ))}
            {isLoading &&
              Array(3)
                .fill(0)
                .map((_, index) => (
                  <Skeleton height={60} width='100%' key={index + 'skeleton'} />
                ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Flex alignItems='center' justify='space-between' width='100%'>
            <Typography>Incluir nova integração</Typography>
            <BankConnectionPopup callbackFn={callbackFn} loading={isLoading} />
          </Flex>
        </DialogActions>
      </Dialog>

      {deleteDialogState && (
        <BankDisconnectionPopup
          loading={loading}
          acceptAction={cancelConsent}
          cancelAction={() => setDeleteDialogState({ isOpen: false })}
          deleteDialogState={deleteDialogState}
        />
      )}
    </>
  )
}
