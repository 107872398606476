import { combineReducers } from '@condofy/next/store/user/combineReducers'
import { listActions, listReducer, list, listActionTypes } from './list'
import { editActions, editReducer, edit, editActionTypes } from './edit'
import {
  createActions,
  createReducer,
  createActionTypes,
  create
} from './create'

// ACTION TYPES
const ANNOUNCEMENT_SET_LOADING_INDICATOR = 'ANNOUNCEMENT_SET_LOADING_INDICATOR'
const ANNOUNCEMENT_SELECT = 'ANNOUNCEMENT_SELECT'

// ACTIONS
export const announcementActions = (state, dispatch) => ({
  ...createActions(state, dispatch),
  ...listActions(state, dispatch),
  ...editActions(state, dispatch),

  selectAnnouncement: (payload) =>
    dispatch({ type: ANNOUNCEMENT_SELECT, payload })
})

export const pending = (dispatch, payload) =>
  dispatch({ type: ANNOUNCEMENT_SET_LOADING_INDICATOR, payload })

// MIDDLEWARE
export const announcementMiddlewares = (dispatch, action) => {
  switch (action.type) {
    case createActionTypes.ANNOUNCEMENT_CREATE:
      return create(dispatch, action.payload)

    case listActionTypes.ANNOUNCEMENT_LIST:
      return list(dispatch, action.payload)

    case editActionTypes.ANNOUNCEMENT_EDIT:
      return edit(dispatch, action.payload)

    default:
      return false
  }
}

// REDUCER
const announcementMainReducer = (state, action) => {
  switch (action.type) {
    case ANNOUNCEMENT_SELECT:
      return store(state, { selected: action.payload })

    case ANNOUNCEMENT_SET_LOADING_INDICATOR:
      return store(state, {
        metadata: {
          ...state.announcement.metadata,
          loading: action.payload
        }
      })

    default:
      return state
  }
}

export const announcementReducer = combineReducers({
  announcementMainReducer,
  createReducer,
  editReducer,
  listReducer
})

export const store = (state, value) => ({
  ...state,
  announcement: { ...state.announcement, ...value }
})

export const announcement = {
  createModalOpen: false,
  deleteModalOpen: false,
  editModalOpen: false,
  selected: {
    id: 'empty',
    title: '',
    subject: '',
    content: '',
    files: []
  },
  editStep: 1,
  createStep: 1,
  items: [],
  metadata: {
    pageNumber: 1,
    totalRecords: 0,
    recordsPerPage: 10,
    loading: false
  }
}

export const announcementActionTypes = {
  ANNOUNCEMENT_SET_LOADING_INDICATOR,
  ANNOUNCEMENT_SELECT,

  ...createActionTypes,
  ...listActionTypes,
  ...editActionTypes
}
