import React from 'react'
import _ from 'lodash'
import { Attachment } from '@condofy/components'

export const SurveyAttachments = ({ attachments }) => {
  return !_.isEmpty(attachments) ? (
    <Attachment name='Baixar Anexo' url={attachments[0].url} />
  ) : (
    <></>
  )
}
