import moment from 'moment'
import React from 'react'

export const EventWithCondoAndTime = ({ title, event }) => (
  <div>
    <div className='rbc-event-labels-wrapper'>
      <div className='rbc-event-label'>
        {moment(event.start).format('HH:mm')}{' '}
        {event.end && ` - ${moment(event.end).format('HH:mm')}`}
      </div>
      <div className='rbc-event-condo-label'>
        {event.condominiumCode}
      </div>
    </div>
    <div className='rbc-event-content'>{title}</div>
  </div>
)
