import { registerFull } from '@condofy/next/service/user'
import { pending, store } from './index'

// ACTION TYPES
const USER_CONNECTION_CREATE = 'USER_CONNECTION_CREATE'
const USER_CONNECTION_CREATE_MODAL_VISIBLE =
  'USER_CONNECTION_CREATE_MODAL_VISIBLE'

export const createActionTypes = {
  USER_CONNECTION_CREATE,
  USER_CONNECTION_CREATE_MODAL_VISIBLE
}

// ACTIONS
export const createActions = (state, dispatch) => ({
  createUserConnection: (payload) =>
    dispatch({ type: USER_CONNECTION_CREATE, payload })
})

// MIDDLEWARE
export const create = async (dispatch, payload) => {
  try {
    pending(dispatch, true)
    return registerFull({ user: payload })
  } finally {
    pending(dispatch, false)
  }
}

// REDUCER
export const createReducer = (state, action) => {
  switch (action.type) {
    case USER_CONNECTION_CREATE_MODAL_VISIBLE:
      return store(state, {
        connection: {
          createModalOpen: action.payload
        }
      })

    default:
      return state
  }
}
