import React from 'react'
import { Page, PageTitle } from '@condofy/components'
import { ScheduleList } from '@condofy/next/containers/schedule/ScheduleList'
import { useUserContext } from '@condofy/next/store/user'

export const SchedulePage = () => {
  const {
    state: { isSuperAdmin, isAdmin }
  } = useUserContext()
  const administratorUser = isSuperAdmin || isAdmin

  return (
    <Page>
      <PageTitle title='Reservas' icon='schedule' />
      <ScheduleList isAdmin={administratorUser} />
    </Page>
  )
}
