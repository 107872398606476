import { editCondominium } from '@condofy/next/service/condo'
import { store, condominiumActionTypes } from './index'

// ACTION TYPES
const CONDOMINIUM_EDIT = 'CONDOMINIUM_EDIT'
const CONDOMINIUM_UPDATE_SELECTED = 'CONDOMINIUM_UPDATE_SELECTED'
const CONDOMINIUM_EDIT_MODAL_VISIBLE = 'CONDOMINIUM_EDIT_MODAL_VISIBLE'

// ACTIONS
export const condominiumEditActions = (state, dispatch) => ({
  updateSelectedCondominium: (data) =>
    dispatch({
      type: CONDOMINIUM_UPDATE_SELECTED,
      payload: data
    }),

  editCondominium: (payload) => dispatch({ type: CONDOMINIUM_EDIT, payload }),

  closeEditCondominiumModal: () => {
    dispatch({
      type: condominiumActionTypes.CONDOMINIUM_SELECT,
      payload: false
    })
    dispatch({ type: CONDOMINIUM_EDIT_MODAL_VISIBLE, payload: false })
  },

  openEditCondominiumModal: (condominium) => {
    dispatch({
      type: condominiumActionTypes.CONDOMINIUM_SELECT,
      payload: condominium
    })
    dispatch({ type: CONDOMINIUM_EDIT_MODAL_VISIBLE, payload: true })
  }
})

// MIDDLEWARE
export const updateCondominium = (dispatch, payload) =>
  editCondominium({
    condominium: {
      ...payload,
      ...payload.address,
      typeId: payload.type.id,
      planId: payload.plan.id,
      cnpj: payload.cnpj.replace(/[//.-]/gi, ''),
      zipCode: payload.address.zipCode.replace('-', '').replace('.', '')
    }
  })

// REDUCER
export const condominiumEditReducer = (state, action) => {
  switch (action.type) {
    case CONDOMINIUM_UPDATE_SELECTED:
      return store(state, {
        selectedCondominium: action.payload
      })

    case CONDOMINIUM_EDIT_MODAL_VISIBLE:
      return store(state, {
        editModalOpen: action.payload
      })

    default:
      return state
  }
}

export const editActionTypes = {
  CONDOMINIUM_EDIT,
  CONDOMINIUM_UPDATE_SELECTED,
  CONDOMINIUM_EDIT_MODAL_VISIBLE
}
