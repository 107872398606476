import React from 'react'
import styled from 'styled-components'
import {
  FlexRowCentered,
  SmallBody,
  colors,
  Icon,
  Button
} from '@condofy/components'

const UserName = styled(SmallBody)`
  margin-right: 7px;
  max-width: 96px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const Avatar = styled.img`
  margin: 0 8px;
  border-radius: 100%;

  ${({ size }) => `
    width: ${size || '32'}px;
    height: ${size || '32'}px;
  `}
`

const StyledIcon = styled(Icon)`
  color: ${colors.primary};
`

export const UserMenuButton = ({ open, name, image, ...other }) => (
  <Button color='clear' {...other}>
    <Avatar src={image} alt='User Image' />
    <UserName>{name}</UserName>
    <FlexRowCentered>
      {open ? (
        <StyledIcon icon='chevron_up' />
      ) : (
        <StyledIcon icon='chevron_down' />
      )}
    </FlexRowCentered>
  </Button>
)
