import React from 'react'
import { Flex } from './Flex'

export const FlexRow = (args) => <Flex direction='row' {...args} />
export const FlexRowWrap = (args) => <FlexRow wrap='wrap' {...args} />
export const FlexRowCentered = (args) => (
  <Flex direction='row' alignItems='center' {...args} />
)
export const FlexRowFullCentered = (args) => (
  <FlexRowCentered justify='center' {...args} />
)

export const FlexRowCenteredSpaceBetween = (args) => (
  <FlexRowCentered justify='space-between' {...args} />
)

export const FlexColumn = (args) => <Flex direction='column' {...args} />
export const FlexColumnCentered = (args) => (
  <FlexColumn alignItems='center' {...args} />
)
