import { condofyProtected } from './instance'

export const getBelvoConsentUrl = ({ condominium, params }) => {
  return condofyProtected.get(
    `v1/condominiums/${condominium.id}/belvo/consent-url`,
    {
      params
    }
  )
}

export const getBelvoConsentStatus = ({ condominium }) => {
  return condofyProtected.get(`v1/condominiums/${condominium.id}/belvo/consent`)
}

export const cancelBelvoConsent = ({ condominium, id }) => {
  return condofyProtected.delete(
    `v1/condominiums/${condominium.id}/belvo/consent/${id}`
  )
}

export const createBelvoConsent = ({ condominium, body }) => {
  return condofyProtected.post(
    `v1/condominiums/${condominium.id}/belvo/consent`,
    body
  )
}
