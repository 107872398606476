import React from 'react'
import { Checkbox as MuiCheckbox, FormControlLabel } from '@mui/material/'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { colors, typography } from '../../styles'

const StyledFormControlLabel = styled(FormControlLabel)`
  &.MuiFormControlLabel-root {
    align-items: flex-start;
  }
  .MuiFormControlLabel-label {
    font-family: ${typography.type.tertiary};
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    padding: 9px 9px 9px 0;
  }
  &.outlined {
    background: ${colors.light300};
    border-radius: 4px;
  }
  .MuiButtonBase-root {
    padding: 9px;
  }
`
export const Checkbox = ({
  color = 'primary',
  variant = 'standard',
  label,
  labelPlacement = 'end',
  value,
  // eslint-disable-next-line no-unused-vars
  error, // ignore incoming errors
  ...props
}) => {
  return (
    <StyledFormControlLabel
      value={value}
      className={variant}
      control={<MuiCheckbox color={color} {...props} />}
      label={label}
      labelPlacement={labelPlacement}
    />
  )
}
Checkbox.propTypes = {
  color: PropTypes.oneOf(['primary', 'secondary']),
  variant: PropTypes.oneOf(['standard', 'outlined']),
  label: PropTypes.node,
  labelPlacement: PropTypes.oneOf(['bottom', 'end', 'start', 'top'])
}
