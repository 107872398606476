import { useContext } from 'react'
import { createStore } from '../core/'
import * as survey from './survey'

const subStores = [survey]

const [SurveyContext, SurveyProvider] = createStore(subStores)

export const useSurveyContext = () => useContext(SurveyContext)

export { SurveyContext, SurveyProvider }
