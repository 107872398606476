import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { colors, Code } from '@condofy/components'
import { Modal, ActionRow, ModalContent } from '@condofy/next/components'
import { useSnackbar } from '@condofy/next/hooks/useSnackbar'
import { useUserContext } from '@condofy/next/store/user'

const Message = styled.span`
  font-weight: normal;
  font-size: 11px;
  line-height: 13px;
  margin-bottom: 10px;
  text-align: center;

  color: #9e9e9e;
`

const PrimaryMessage = styled(Message)`
  font-size: 13px;
  line-height: 19px;

  color: ${colors.primary1};
`

const Link = styled.a`
  cursor: pointer;
  margin: 10px 5px;
`

export const SmsCodeModal = () => {
  const { enqueueSnackbar } = useSnackbar()
  const [code, setCode] = useState('')
  const [complete, setComplete] = useState(false)

  const {
    state: { temporaryProfileData },
    actions
  } = useUserContext()

  useEffect(() => {
    if (code.length < 6) setComplete(false)
  }, [code])

  const handleSuccess = () => {
    enqueueSnackbar('Telefone alterado com sucesso', {
      variant: 'success'
    })
    actions.closeSMSModal()
  }

  const sendConfirmationCode = () => {
    actions
      .sendConfirmationCode({
        phoneNumber: temporaryProfileData.phoneNumber
          .replace(/\D/g, '')
          .substring(0, 11),
        token: code
      })
      .then(handleSuccess)
  }

  const resendSms = () => {
    //   resendSMSCode({ email })
  }

  return (
    <Modal
      title={`Confirme seu celular`}
      open={temporaryProfileData.smsModalOpen}
      onClose={actions.closeSMSModal}
      footer={
        <ActionRow
          confirmText='Enviar'
          disabled={!(code && complete)}
          onCancel={actions.closeSMSModal}
          onSubmit={sendConfirmationCode}
        />
      }
    >
      <ModalContent>
        <PrimaryMessage>
          <span>
            Por favor, verifique seu celular e digite o código de 6 digitos que
            enviamos para confirmar sua conta. Não recebeu o código?
          </span>
          <Link onClick={resendSms}>Re-enviar Código</Link>
        </PrimaryMessage>
        <Code
          value={code}
          onChange={setCode}
          onComplete={() => setComplete(true)}
        />
      </ModalContent>
    </Modal>
  )
}
