import React, { useCallback, useEffect, useState } from 'react'
import { track } from '@condofy/next/service/segment'
import { useUserContext } from '@condofy/next/store/user'
import {
  CardContent,
  CardHeader,
  Card,
  Button,
  Calendar,
  EventWithTime,
  EventWithCondoAndTimeFlat,
  EventWithCondoAndTime
} from '@condofy/components'
import { GlobalEventsListFilters } from './GlobalEventsListFilters'
import { useCalendarContext } from '@condofy/next/store/calendar'
import moment from 'moment'
import { CalendarEventList } from './CalendarEventList'
import _ from 'lodash'


export const CalendarCard = ({isFull = false}) => {


  const [view, setView] = useState('month')
  const [search, setSearch] = useState('')
  const [responsible, setResponsible] = useState('pb')
  const [selectedDate] = useState(new Date())
  const [period, setPeriod] = useState({
    start: moment(),
    end: moment()
  })
  const [calendarEvents, setCalendarEvents] = useState([])

  // const { state } = useUserContext()
  const {
    state: {
      isSuperAdmin,
      isAdmin,
      currentCondominium: { id: defaultCondominiumId },
      condominium: { allItems: condominiumList }
    },
    actions: { listAllCondominiums }
  } = useUserContext()
  const stableListAllCondominiums = useCallback(listAllCondominiums, [])

  const [condominiumFilterValue, setCondominiumFilterValue] = useState(defaultCondominiumId || 'all')

  const condominiumId = isFull ? condominiumFilterValue : defaultCondominiumId;

  const administratorUser = isSuperAdmin || isAdmin


  useEffect(() => {
    administratorUser && isFull && stableListAllCondominiums()
  }, [stableListAllCondominiums, administratorUser, isFull])

  const {
    state: {
      events: {
        items = [],
        metadata: { loading }
      }
    },
    actions: { listEvents, openCreateEventModal, openEditEventModal }
  } = useCalendarContext()

  const stableListEvents = useCallback(listEvents, [])

  useEffect(() => {
    // Month calendar shows some days ahead and before selected month
    // so let's account for that
    const start = moment(selectedDate).startOf('month').subtract(7, 'days')
    const end = moment(selectedDate).endOf('month').add(7, 'days')
    setPeriod({ start, end })
  }, [selectedDate])

  useEffect(() => {
    if (!condominiumId) {
      return
    }
    const searchData = {
      condominiumId,
      start: period.start.toDate(),
      end: period.end.toDate()
    };
    if(isFull) {
      searchData.Summary = search;
      searchData.Visibility = responsible;
    }
    console.log({searchData});
    stableListEvents(searchData)
  }, [condominiumFilterValue, stableListEvents, period, search, responsible])

  useEffect(() => {
    const list = new CalendarEventList({
      events: items,
      start: period.start,
      end: period.end
    })
    const events = list.convertToCalendarEvents()
    setCalendarEvents(events)
  }, [items, period])

  const handleEventSelection = (event) => {
    track(window.analytics, 'Calendar Event Detail Opened', event)
    openEditEventModal({
      eventId: event.id,
      condominiumId,
      item: event
    })
  }

  const handleSlotSelection = ({ start, end }) => {
    const isDay = moment(end).diff(start, 'days') >= 1
    openCreateEventModal({ selectedStart: start, selectedEnd: !isDay && end })
  }

  const handleCreateEvent = () => {
    openCreateEventModal()
  }

  const handleRangeChange = (range) => {
    const rangeStart = _.isArray(range)
      ? moment(range[0])
      : moment(range?.start)
    const rangeEnd = _.isArray(range)
      ? moment(_.last(range))
      : moment(range?.end)

    const isBefore = rangeStart.isBefore(period.start, 'day')
    const isAfter = rangeEnd.isAfter(period.end, 'day')

    if (isBefore || isAfter)
      setPeriod({
        start: isBefore ? rangeStart.subtract(7, 'days') : period.start,
        end: isAfter ? rangeEnd.add(7, 'days') : period.end
      })
  }

  let components = { month: { event: EventWithTime } };
  if(isFull) {
    components = {
      event: EventWithCondoAndTimeFlat,
      month: { event: EventWithCondoAndTime }
    };
  }

  const tooltipAccessor = isFull
    ? (event) => `(${event.condominiumCode}) ${event.title}`
    : (event) => event.title


  const filtersValue = {
    search,
    setSearch,
    responsible,
    setResponsible,
    condominiumFilterValue,
    setCondominiumFilterValue,
    condominiumList
  }

  return (
    <Card>
      <CardHeader
        title='Calendário'
        action={
          administratorUser && (isFull ? (
            <GlobalEventsListFilters
                {...filtersValue}
              />
          ) : (
            <Button onClick={handleCreateEvent}>Novo Evento</Button>
          ))
        }
      />
      <CardContent>
        <div translate='no'>
          <Calendar
            style={{ minHeight: '65vh' }}
            onView={setView}
            view={view}
            events={calendarEvents}
            selectable={true}
            multiDaySelection={false}
            onSelectSlot={handleSlotSelection}
            onSelectEvent={handleEventSelection}
            onRangeChange={handleRangeChange}
            components={components}
            tooltipAccessor={tooltipAccessor}
            loading={loading}
          />
        </div>
      </CardContent>
    </Card>
  )
}
