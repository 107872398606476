import { withProvider } from './withProvider'
import { createContext } from 'react'

export const createStore = (subStores) => {
  const reducer = (state = initialState, action) => {
    const reducers = subStores.reduce(
      (r, c) => Object.assign(r, c.createReducers()),
      {}
    )
    return reducers[action.type] ? reducers[action.type](state, action) : state
  }

  const initialState = {
    ...subStores.reduce((r, c) => Object.assign(r, c.initialState), {})
  }

  const useActions = (state, dispatch) => ({
    ...subStores.reduce(
      (r, c) => Object.assign(r, c.useActions(state, dispatch)),
      {}
    )
  })

  const applyMiddleware = (dispatch) => (action) => {
    const middleware = subStores.reduce(
      (r, c) => Object.assign(r, c.createMiddleware()),
      {}
    )

    return middleware[action.type]
      ? middleware[action.type](dispatch, action.payload)
      : dispatch(action)
  }

  const context = createContext()

  const provider = withProvider({
    reducer,
    initialState,
    useActions,
    applyMiddleware
  })(context)

  return [context, provider]
}
