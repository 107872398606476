import React, { useState } from 'react'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import { RecordVoiceOver } from '@mui/icons-material'
import { Actions } from '@Redux/occurrence'
import {
  Form,
  FormRow,
  TextFieldCounter,
  colors,
  Body,
  QuillCounter,
  FlexRowCentered as Flex
} from '@condofy/components'
import { Modal, ActionRow, FilePond } from '@condofy/next/components'
import CloudDownloadRoundedIcon from '@mui/icons-material/CloudDownloadRounded'
import { useUserContext } from '@condofy/next/store/user'
import { ModalContent } from '../../next/components/Modal'

function removeTags(str) {
  if ((str === null) || (str === ''))
    return false;
  else
    str = str.toString();
  // Regular expression to identify HTML tags in the input string.
  // Replacing the identified HTML tag with a null string.
  return str.replace(/(<([^>]+)>)/ig, '');
}

const FlexRowCentered = styled(Flex)`
  margin: 20px 8px 5px;
`

const TitleFileCall = styled(Body)`
  margin-left: 5px;
  line-height: 24px;
  color: ${colors.dark300};
`

const Spacer = styled.span`
  width: 5px;
`

export const OccurrenceAddModal = ({ options, close }) => {
  const {
    state: { currentCondominium },
    actions: { listOccurence }
  } = useUserContext()
  const [occurrence, setOccurrence] = useState({
    title: options.title || '',
    occurrence: options.occurrence || ''
  })

  const dispatch = useDispatch()
  const [files, setFiles] = useState([])

  const { isOpen, pending } = useSelector(({ occurrence, modal }) => ({
    pending: occurrence.pending.create,
    isOpen: modal.isModalOpen
  }))

  const updateOccurenceList = () => {
    setTimeout(() => {
      if (currentCondominium.id) listOccurence({ currentCondominium })
    }, 1000)
  }

  const onSubmit = (status) => {
    if (options.id) {
      dispatch(
        Actions.occurrenceUpdate({
          currentCondominium,
          payload: { ...occurrence, files, status, id: options.id }
        })
      )
    } else {
      dispatch(
        Actions.occurrenceCreate({
          currentCondominium,
          payload: { ...occurrence, files, status }
        })
      )
    }
    updateOccurenceList()
  }

  const handleChange = (value, name, limit) => {
    if (name === 'occurrence' && limit) {
      console.log({limit, value: value.length})
      if (removeTags(value).length <= limit) {
        setOccurrence({ ...occurrence, [name]: value })
      }
    } else {
      setOccurrence({ ...occurrence, [name]: value })
    }
  }

  return (
    <Modal
      icon={<RecordVoiceOver />}
      title={options.id ? 'Editar ocorrência' : 'Nova ocorrência'}
      open={isOpen}
      onClose={close}
      footer={
        <ActionRow
          loading={pending}
          cancelText='Publicar mais tarde'
          confirmText='Publicar'
          onSubmit={() => onSubmit('P')}
          onCancel={() => onSubmit('A')}
        />
      }
    >
      <ModalContent>
        <Form>
          <FormRow>
            <TextFieldCounter
              name='title'
              label='Titulo'
              onChange={(e) => handleChange(e.target.value, 'title')}
              value={occurrence.title}
              default={options}
              maxLength={50}
            />
          </FormRow>
          <FormRow>
            <QuillCounter
              required
              name='content'
              onChange={(e) => handleChange(e, 'occurrence', 2500)}
              maxLength={2500}
              defaultValue={occurrence.occurrence}
            />
          </FormRow>
          <FormRow>
            {options.attachmentUrl && options.attachmentUrl.length ? (
              <a
                href={options.attachmentUrl[0].url}
                target='_blank'
                rel='noopener noreferrer'
              >
                <FlexRowCentered>
                  <CloudDownloadRoundedIcon />
                  <Spacer />
                  <TitleFileCall>Baixar Anexo</TitleFileCall>
                </FlexRowCentered>
              </a>
            ) : (
              <span />
            )}
          </FormRow>
          <FormRow>
            <FilePond maxTotalFileSize='20MB' setFile={setFiles} />
          </FormRow>
        </Form>
      </ModalContent>
    </Modal>
  )
}
