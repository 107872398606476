/* eslint-disable no-unused-vars */
import React from 'react'
import { useSnackbar, handleError } from '@condofy/next/hooks/useSnackbar'
import { Modal, ActionRow, ModalContent } from '@condofy/next/components'
import { useUserContext } from '@condofy/next/store/user'
import { UserForm } from './UserForm'
import { track } from '@condofy/next/service/segment'

export const UserEditModal = () => {
  const { enqueueSnackbar } = useSnackbar()

  const {
    state: {
      currentCondominium,
      user: { editModalOpen, selected, loading }
    },
    actions: {
      closeEditUserModal: close,
      updateSelectedUser,
      editUser,
      listUser
    }
  } = useUserContext()

  const handleSubmit = () => {
    var phoneNumber = selected.phoneNumber.replace(/\D/g, '')
    if (phoneNumber.length > 11) {
      phoneNumber = phoneNumber.substring(0, 11)
    }
    console.log({
      first: selected.phoneNumber,
      second: phoneNumber
    })
    editUser({
      ...selected,
      phoneNumber
    })
      .then(() => {
        track(window.analytics, 'User Edited', {
          ...selected,
          condominium_id: currentCondominium.id
        })
        enqueueSnackbar(`Usuário alterado com sucesso.`, { variant: 'success' })
        close()
        listUser({ currentCondominium })
      })
      .catch(handleError(enqueueSnackbar))
  }

  return (
    <Modal
      title={`Editar ${selected.name}`}
      open={editModalOpen}
      onClose={close}
      footer={
        <ActionRow loading={loading} onSubmit={handleSubmit} onCancel={close} />
      }
    >
      <ModalContent>
        <UserForm value={selected} onChange={updateSelectedUser} />
      </ModalContent>
    </Modal>
  )
}
