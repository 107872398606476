import React from 'react'
import { Button, Card, CardContent, colors, Icon } from '@condofy/components'
import styled from 'styled-components'
import { useCommunityContext } from '../../../store/community'

const StyledCard = styled(Card)`
  &.MuiCard-root {
    background: transparent;
    border: 0;
    height: 100%;
  }
  .MuiCardContent-root {
    height: 100%;
    min-height: 200px;
    padding: 0 !important;
    position: relative;
  }
  .MuiButtonBase-root {
    background: ${colors.light500};
    border: solid 1px #dfe1e6;
    position: absolute;
    bottom: 0;
    top: 0;
    width: 100%;

    &:hover {
      background: ${colors.light500} !important;
    }
    .MuiSvgIcon-root {
      height: 18px;
    }
  }
  .MuiButton-text {
    color: ${colors.primary};
  }
`

export const CreateListingCard = ({ text = 'Nova oferta' }) => {
  const {
    actions: { openCreateListingModal }
  } = useCommunityContext()

  const handleCreate = () => {
    openCreateListingModal()
  }

  return (
    <StyledCard>
      <CardContent>
        <Button variant='text' onClick={handleCreate}>
          <Icon icon='add_circle_outlined' />
          {text}
        </Button>
      </CardContent>
    </StyledCard>
  )
}
