import React from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { toggleModal } from 'Actions/modals'
import { ASSIGN_MANAGER_MODAL, PROCESS_UNIT_MODAL } from 'Const'
import {
  ButtonMenu as Menu,
  MenuItem,
  colors,
  DataTableRow,
  Chip,
  DataTableCell,
  TextTruncate
} from '@condofy/components'
import { useUserContext } from '@condofy/next/store/user'
import {
  useSnackbar,
  handleError,
  useDownloadDefaultingReport
} from '@condofy/next/hooks/'
import Premium from 'Assets/icons/premium-tag.svg'
import { cnpjMask } from '@condofy/next/containers'

const IconPremium = styled(Premium)`
  margin-left: 5px;
  z-index: 2;
`

const ColoredTableCell = styled(DataTableCell)`
  color: ${({ $income }) =>
    $income
      ? `${colors.secondary} !important`
      : `${colors.dark200} !important`};
`

export const CondominiumListItem = ({ condominium, ...other }) => {
  const {
    state: { isSuperAdmin },
    actions: {
      openEditCondominiumModal,
      openDeleteCondominiumModal,
      openInactiveCondominiumModal,
      openActiveCondominiumModal
    }
  } = useUserContext()
  const { enqueueSnackbar } = useSnackbar()

  const dispatch = useDispatch()

  const DownloadReport = () =>
    useDownloadDefaultingReport(condominium).catch(handleError(enqueueSnackbar))

  const onEdit = (condominium) => () => {
    openEditCondominiumModal(condominium)
  }

  const showManagerModal = (condominium) => () =>
    dispatch(
      toggleModal(ASSIGN_MANAGER_MODAL, true, { condoId: condominium.id })
    )

  const showCreateUnitModal = () => () =>
    dispatch(toggleModal(PROCESS_UNIT_MODAL, true, { condominium }))

  const onDelete = (condominium) => () => {
    openDeleteCondominiumModal(condominium)
  }

  const onInactive = (condominium) => () => {
    openInactiveCondominiumModal(condominium)
  }

  const onActive = (condominium) => () => {
    openActiveCondominiumModal(condominium)
  }
  const formatAddress = (condominium) =>
    `${condominium.address.state}, ${condominium.address.city}, ${condominium.address.district}, ${condominium.address.street}`

  return (
    <DataTableRow {...other}>
      <DataTableCell>{condominium.name}</DataTableCell>
      <DataTableCell>
        <TextTruncate width='300px'>{formatAddress(condominium)}</TextTruncate>
      </DataTableCell>
      <DataTableCell>
        {condominium.cnpj ? cnpjMask(condominium.cnpj) : ''}
      </DataTableCell>
      <DataTableCell>{condominium.type.name}</DataTableCell>
      <DataTableCell>{condominium.code}</DataTableCell>
      {condominium.isInactive === false ? (
        <ColoredTableCell $income={Boolean(condominium.plan.id === '2')}>
          {condominium.plan.name}
        </ColoredTableCell>
      ) : (
        <DataTableCell>
          <Chip color='danger' label='Inativo' />
        </DataTableCell>
      )}
      <DataTableCell align='right'>
        {isSuperAdmin && (
          <Menu>
            {condominium.plan.id === '3' ? (
              <MenuItem onClick={() => DownloadReport()}>
                Inadimplentes
              </MenuItem>
            ) : (
              <MenuItem disabled>
                Inadimplentes <IconPremium />
              </MenuItem>
            )}
            <MenuItem onClick={onEdit(condominium)}>Editar</MenuItem>
            <MenuItem onClick={onDelete(condominium)}>Deletar</MenuItem>
            {!condominium.isInactive ? (
              <MenuItem onClick={onInactive(condominium)}>Inativar</MenuItem>
            ) : (
              <MenuItem onClick={onActive(condominium)}>Ativar</MenuItem>
            )}
            <MenuItem onClick={showCreateUnitModal()}>Nova unidade</MenuItem>
            <MenuItem onClick={showManagerModal(condominium)}>
              Gerenciar Síndicos
            </MenuItem>
          </Menu>
        )}
      </DataTableCell>
    </DataTableRow>
  )
}
