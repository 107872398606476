import { Box } from '@mui/material'
import React from 'react'
import { Flex } from '../Flex'

export const Page = ({ children, ...args }) => {
  return (
    <Box display='flex' width='100%' padding={3}>
      <Flex
        direction='column'
        spacingY={3}
        width='100%'
        overflow='false'
        {...args}
      >
        {children}
      </Flex>
    </Box>
  )
}
