import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useSnackbar, handleError } from '@condofy/next/hooks/useSnackbar'
import {
  fetchRestrictionList,
  removeRestriction,
  createRestrictionPromise
} from '@condofy/next/service/restriction'
import { DeleteOutline } from '@mui/icons-material'
import {
  Dialog,
  DialogContent,
  DialogSection as Section,
  FlexRowCentered,
  IconButton,
  Chip,
  colors,
  Button,
  EmptyListMessage
} from '@condofy/components'
import { fetchRestrictedUnitList } from '@condofy/next/service/unit'
import { track } from '@condofy/next/service/segment'
import { useUserContext } from '@condofy/next/store/user'
import { RestrictionCreateAreaModal } from './'

const DialogSection = styled(Section)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  background: ${colors.light300};
  padding: 2px 0px 0 7px;
  margin-bottom: 6px;
`

const Text = styled.span`
  color: ${colors.dark400};
`

const AlignEndRow = styled(FlexRowCentered)`
  place-content: unset !important;
  justify-content: flex-end !important;
`

const Restriction = ({ label, onDelete: emitDeleteEvent = () => {} }) => (
  <DialogSection>
    <Text>{label}</Text>
    <AlignEndRow>
      <Chip label='Restrito' color='danger' />
      <IconButton onClick={emitDeleteEvent}>
        <DeleteOutline />
      </IconButton>
    </AlignEndRow>
  </DialogSection>
)

export const RestrictionAreaModal = ({ isOpen, onClose }) => {
  const {
    state: { currentCondominium }
  } = useUserContext()

  const [unitList, setUnitList] = useState([])
  const [open, setOpen] = useState(false)
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    updateUnitList()
  }, [isOpen])

  const handleUnitSelect = (unit, index) => {
    const newUnitList = [...unitList]

    newUnitList.splice(index, 1, {
      ...unit,
      toBeRestricted: !unitList[index].toBeRestricted
    })

    setUnitList(newUnitList)
  }

  const updateUnitList = () => {
    fetchRestrictedUnitList({
      currentCondominium,
      onSuccess: (response) => {
        const unitList = response.data

        fetchRestrictionList({
          currentCondominium,
          onSuccess: (response) => {
            response.data.forEach((restriction) => {
              const index = unitList.findIndex(
                (unit) => unit.id === restriction.unit.id
              )
              if (index > -1) {
                unitList[index].restriction = restriction
              }
            })
            setUnitList([...unitList])
          },
          onError: (error) => {
            const errorMessage =
              error.response.data.reports[0].message || error.message
            enqueueSnackbar(errorMessage, { variant: 'error' })
          }
        })
      }
    })
  }

  const handleRemoveRestriction = (restriction) => () => {
    removeRestriction({
      currentCondominium,
      restrictionId: restriction.id,
      onSuccess: () => {
        track(window.analytics, 'Booking Restriction Deleted', restriction)
        enqueueSnackbar('Restrição removida com sucesso.', {
          variant: 'success'
        })
        updateUnitList()
      },
      onError: (error) => {
        const errorMessage =
          error.response.data.reports[0].message || error.message
        enqueueSnackbar(errorMessage, { variant: 'error' })
      }
    })
  }

  const handleSubmit = () => {
    const promiseList = []

    unitList.forEach((unit) => {
      if (unit.toBeRestricted)
        promiseList.push(
          createRestrictionPromise({ currentCondominium, unitId: unit.id })
        )
    })

    Promise.all(promiseList)
      .then(() => {
        if (promiseList.length > 0) {
          track(window.analytics, 'Booking Restriction Created', unitList)
          enqueueSnackbar('Restrições criadas com sucesso.', {
            variant: 'success'
          })
          updateUnitList()
        }
      })
      .catch(handleError(enqueueSnackbar))
      .finally(() => setOpen(false))
  }

  const createNewRestriction = () => {
    setOpen(true)
  }

  return (
    <>
      <Dialog title='Restrição de Reserva' open={isOpen} onClose={onClose}>
        <DialogContent>
          {unitList
            .filter((unit) => Boolean(unit.restriction))
            .map((restrictedUnit) => (
              <Restriction
                key={restrictedUnit.id}
                label={restrictedUnit.name}
                onDelete={handleRemoveRestriction(restrictedUnit.restriction)}
              />
            ))}
          {!unitList.filter((unit) => Boolean(unit.restriction)).length && (
            <EmptyListMessage />
          )}
        </DialogContent>
        <Section border={1}>
          <Button onClick={() => createNewRestriction()}>Adicionar</Button>
        </Section>
      </Dialog>
      {open && (
        <RestrictionCreateAreaModal
          isOpen={open}
          unitList={unitList}
          onSelect={handleUnitSelect}
          onSubmit={handleSubmit}
          onClose={() => setOpen(false)}
        />
      )}
    </>
  )
}
