import React from 'react'
import { TimePicker as MuiTimePicker } from '@mui/x-date-pickers'
import { options, dateTimeStyles as styles } from './config'
import styled from 'styled-components'

const StyledTimePicker = styled(MuiTimePicker)`
  ${styles}
`

export const TimePicker = ({
  ampm = false,
  inputVariant = 'outlined',
  format = 'HH:mm',
  cancelLabel = 'Cancelar',
  ...args
}) => (
  <StyledTimePicker
    ampm={ampm}
    format={format}
    inputVariant={inputVariant}
    cancelLabel={cancelLabel}
    {...args}
  />
)
TimePicker.propTypes = { ...options.propTypes }
