import React from 'react'
import { SvgIcon } from '@mui/material'
import { icons } from '../../assets/icons'
import PropTypes from 'prop-types'

export const Icon = ({ icon, color, styles = {}, ...props }) => (
  <SvgIcon
    sx = {{...styles}}
    style={{
      color: color || 'inherit',
      verticalAlign: 'middle'
    }}
    {...props}
  >
    <path d={icons[icon]} />
  </SvgIcon>
)

Icon.propTypes = {
  icon: PropTypes.string,
  color: PropTypes.string,
  fontSize: PropTypes.oneOf(['default', 'inherit', 'large', 'small'])
}
