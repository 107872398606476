import React, { useCallback, useEffect, useState } from 'react'
import {
  SearchTextField,
  TabToolbar,
  Flex,
  Typography,
  colors,
  typography,
  theme,
  PageSubTitle
} from '@condofy/components'
import { Box } from '@mui/material'
import styled from 'styled-components'
import { useUserContext } from '@condofy/next/store/user'
import { MarketList } from '@condofy/next/containers/community'
import { useCommunityContext } from '@condofy/next/store/community'
import { CommunityFeed } from '../../next/containers/community'

import { Autocomplete } from '@mui/material';
import TextField from '@mui/material/TextField'
import { track } from '@condofy/next/service/segment'

const StyledSearchTextField = styled(SearchTextField)`
  width: 100%;
  & .MuiOutlinedInput-root {
    width: 100%;
  }
`

const PromptText = styled(Typography)`
  color: ${colors.dark400};
  display: block;
  font-family: ${typography.type.tertiary};
  font-weight: 600;
  font-size: 20px;
  line-height: 1.2;
  margin-bottom: ${theme.spacing(2)};
`

const SearchResultsTitle = styled(PageSubTitle)`
  font-weight: normal;
  em {
    font-weight: bold;
    font-style: normal;
  }
`

export const Types = [
  { id: 1, name: 'Grátis' },
  { id: 2, name: 'Por Mês' },
  { id: 3, name: 'Por Unidade' },
  { id: 4, name: 'Por Uso/Dia' }
]

export const MarketTab = () => {
  const {
    state: { name, currentCondominium, id }
  } = useUserContext()

  const [generalSearch, setGeneralSearch] = useState('')
  const [chargerType, setChargerType] = useState('')
  const {
    state: {
      listListings: {
        metadata: { loading },
        items
      }
    },
    actions: { loadListingList }
  } = useCommunityContext()

  const stableLoadListingList = useCallback(loadListingList, [])
  const [page, setPage] = useState(1)

  useEffect(() => {
    stableLoadListingList({
      chargerType: chargerType?.id,
      generalSearch,
      currentCondominium,
      membershipId: '',
      status: 1,
      PageNumber: page
    })

    track(window.analytics, 'Community Search', {
      condominiumId: currentCondominium.id,
      searchTerm: generalSearch,
      userId: id
    })
  }, [
    stableLoadListingList,
    chargerType,
    page,
    generalSearch,
    currentCondominium
  ])

  useEffect(() => {
    setPage(1)
  }, [chargerType, generalSearch, currentCondominium])

  return (
    <>
      <TabToolbar>
        <Box display='block' width='100%'>
          <PromptText>Olá {name}, o que você está precisando hoje?</PromptText>
          <Flex spacingX={2} justify='space-between'>
            <StyledSearchTextField
              flex='1'
              size='small'
              onChange={(event) => setGeneralSearch(event.target.value)}
              value={generalSearch}
            />
            <Autocomplete
              options={Types}
              value={chargerType}
              getOptionSelected={(option) => option || null}
              getOptionLabel={(option) => option.name || ''}
              onChange={(event, newValue) => {
                setChargerType(newValue)
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label='Categoria'
                  variant='outlined'
                  size='small'
                  style={{ minWidth: '150px' }}
                />
              )}
            />
          </Flex>
        </Box>
      </TabToolbar>
      <Flex direction='column' spacingY={3}>
        <div>
          {generalSearch ? (
            <SearchResultsTitle>
              Resultados para <em>{generalSearch}</em>
            </SearchResultsTitle>
          ) : (
            <SearchResultsTitle>Novidades Disponíveis</SearchResultsTitle>
          )}
          <MarketList
            loading={loading}
            listings={items}
            page={page}
            onPageChange={setPage}
          />
        </div>
        <div>
          <PageSubTitle>Comunidade</PageSubTitle>
          <CommunityFeed />
        </div>
      </Flex>
    </>
  )
}
