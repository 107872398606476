const billingList = {
  LOAD_BILLING_LIST: 'LOAD_BILLING_LIST',
  LOAD_BILLING_LIST_PENDING: 'LOAD_BILLING_LIST_PENDING',
  LOAD_BILLING_LIST_SUCCESS: 'LOAD_BILLING_LIST_SUCCESS'
}

const billingDetail = {
  LOAD_BILLING_DETAIL: 'LOAD_BILLING_DETAIL',
  LOAD_BILLING_DETAIL_PENDING: 'LOAD_BILLING_DETAIL_PENDING',
  LOAD_BILLING_DETAIL_SUCCESS: 'LOAD_BILLING_DETAIL_SUCCESS',
  LOAD_BILLING_DETAIL_ERROR: 'LOAD_BILLING_DETAIL_ERROR',
  LOAD_BILLING_DETAIL_MODAL_VISIBLE: 'LOAD_BILLING_DETAIL_MODAL_VISIBLE'
}

const billingDeclaration = {
  LOAD_BILLING_DECLARATION: 'LOAD_BILLING_DECLARATION',
  LOAD_BILLING_DECLARATION_PENDING: 'LOAD_BILLING_DECLARATION_PENDING',
  LOAD_BILLING_DECLARATION_SUCCESS: 'LOAD_BILLING_DECLARATION_SUCCESS',
  LOAD_BILLING_DECLARATION_ERROR: 'LOAD_BILLING_DECLARATION_ERROR',
  LOAD_BILLING_DECLARATION_MODAL_VISIBLE:
    'LOAD_BILLING_DECLARATION_MODAL_VISIBLE'
}

export default {
  ...billingList,
  ...billingDetail,
  ...billingDeclaration
}
