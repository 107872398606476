import React from 'react'
import styled from 'styled-components'
import {
  SearchTextField,
  MenuItem,
  Select,
  CardActions,
  Button
} from '@condofy/components'

const StyledSelect = styled(Select)`
  min-width: 120px !important;
`

export const AnnouncementsListFilters = ({
  search,
  onSearch,
  status,
  onStatus
}) => {
  const handleStatusChange = (event) => onStatus(event.target.value)
  const handleSearch = (event) => onSearch(event.target.value)

  const cleanFilters = () => {
    onStatus('')
    onSearch('')
  }

  return (
    <CardActions style={{ justifyContent: 'flex-end' }}>
      <Button
        disabled={!search && !status}
        sx={{ marginRight: 1 }}
        onClick={cleanFilters}
      >
        Limpar Filtros
      </Button>
      <StyledSelect
        select
        sx={{
          '.MuiSelect-select': {
            height: '1.1876em',
            lineHeight: '1.1876em',
            minHeight: '1.1876em'
          }
        }}
        label='Status'
        value={status}
        onChange={handleStatusChange}
      >
        <MenuItem value=''>Todos</MenuItem>
        <MenuItem value='A'>Rascunho</MenuItem>
        <MenuItem value='P'>Enviado</MenuItem>
      </StyledSelect>
      <SearchTextField size='small' onChange={handleSearch} value={search} />
    </CardActions>
  )
}
