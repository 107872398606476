import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Typography } from '../Typography'
import { Icon } from '../Icon'
import { Flex } from '../Flex'
import { colors } from '../../styles'

const StyledTitle = styled(Flex)`
  color: ${colors.primary};
`

export const PageTitle = ({ title, icon, ...args }) => {
  return (
    <div>
      <StyledTitle spacingX={1} alignItems='center'>
        {icon && <Icon icon={icon} />}
        <Typography variant='h5' component='h2' {...args}>
          {title}
        </Typography>
      </StyledTitle>
    </div>
  )
}
PageTitle.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.string
}
