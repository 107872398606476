import React from 'react'
import styled from 'styled-components'
import {
  SearchTextField,
  MenuItem,
  Select,
  CardActions,
  Button
} from '@condofy/components'

const StyledSelect = styled(Select)`
  min-width: 120px !important;
`

export const GlobalEventsListFilters = ({
  search,
  setSearch,
  responsible,
  setResponsible,
  condominiumFilterValue,
  setCondominiumFilterValue,
  condominiumList,
}) => {
  const handleResponsibleChange = (event) => {console.log('handleResponsibleChange', event.target.value); setResponsible(event.target.value)}
  const handleCondominiumChange = (event) => {console.log('handleCondominiumChange', event.target.value); setCondominiumFilterValue(event.target.value)}
  const handleSearch = (event) => {
    console.log('handleSearch', event.target.value);
    setSearch(event.target.value)
  }

  const cleanFilters = () => {
    setResponsible('pb')
    setCondominiumFilterValue('all')
    setSearch('')
  }

  // console.log({condominiumList})

  return (
    <CardActions style={{ justifyContent: 'flex-end' }}>
      <Button
        disabled={!search && !responsible && !condominiumFilterValue}
        sx={{ marginRight: 1 }}
        onClick={cleanFilters}
      >
        Limpar Filtros
      </Button>
      <StyledSelect
        select
        sx={{
          '.MuiSelect-select': {
            height: '1.1876em',
            lineHeight: '1.1876em',
            minHeight: '1.1876em'
          }
        }}
        label='Condominio'
        value={condominiumFilterValue}
        onChange={handleCondominiumChange}
      >
        <MenuItem value={'all'}>Todos</MenuItem>
        {condominiumList.map((c) => (
          <MenuItem key={c.id} value={c.id}>{c.name}</MenuItem>
        ))}
      </StyledSelect>
      <StyledSelect
        select
        sx={{
          '.MuiSelect-select': {
            height: '1.1876em',
            lineHeight: '1.1876em',
            minHeight: '1.1876em'
          }
        }}
        label='Responsável'
        value={responsible}
        onChange={handleResponsibleChange}
      >
        <MenuItem value='pb'>Todos</MenuItem>
        <MenuItem value='pv'>Sindico</MenuItem>
      </StyledSelect>
      <SearchTextField size='small' onChange={handleSearch} value={search} />
    </CardActions>
  )
}
