import { condofyProtected } from './instance'

export const search = ({
  PageNumber = 0,
  RecordsPerPage = 10,
  condominiumId,
  generalSearch,
  status,
  initialDate,
  finalDate,
  unitIds
}) => {
  return condofyProtected.post(
    `/v1/condominiums/${condominiumId}/billings/search`,
    { generalSearch, status, initialDate, finalDate, unitIds },
    {
      params: {
        PageNumber,
        RecordsPerPage
      }
    }
  )
}

export const detail = (payload) => {
  return condofyProtected.post(
    `/v1/condominiums/${payload.condominiumId}/billings/detail`,
    {},
    {
      params: {
        entryId: payload.billing.entryId
      }
    }
  )
}

export const downloadFile = ({
  currentCondominium,
  entryId,
  type = 'text/plain;charset=utf-8',
  name = 'download'
}) =>
  condofyProtected
    .get(
      `/v1/condominiums/${currentCondominium.id}/entries/download-concatenate?entryId=${entryId}`,
      {
        responseType: 'arraybuffer'
      }
    )
    .then((response) => {
      var file = new Blob([response.data], { type })
      var fileURL = URL.createObjectURL(file)
      const link = document.createElement('a')
      link.href = fileURL
      link.download = `${name}`
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    })

export const downloadExcelFile = ({ currentCondominium, entryId, name }) =>
  downloadFile({
    currentCondominium,
    entryId,
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    name
  })

export const downloadDefautingReport = ({ currentCondominium, selected }) => {
  function formatArray(arr) {
    return arr
      .map((item, index) => (index === 0 ? '?' : '&') + `UnitIds=${item}`)
      .join('')
  }
  try {
    return condofyProtected.post(
      `v1/condominiums/${currentCondominium.id}/defaulting/report${formatArray(
        selected
      )}`,
      {},
      {
        responseType: 'arraybuffer'
      }
    )
  } catch (error) {
    console.log(error)
  }
}
