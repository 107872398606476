import { Chip } from '@condofy/components'
import React from 'react'

export const TicketStatusChip = ({ status }) => {
  const statuses = {
    new: {
      label: 'Novo',
      color: 'secondary'
    },
    open: {
      label: 'Aberto',
      color: 'success'
    },
    pending: {
      label: 'Pendente',
      color: 'primary'
    },
    solved: {
      label: 'Resolvido',
      color: 'light'
    },
    closed: {
      label: 'Fechado',
      color: 'light'
    }
  }
  const values = statuses[status]

  return values ? <Chip color={values.color} label={values.label} /> : <></>
}
