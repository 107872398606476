import { createAnnouncementModel } from '@condofy/next/service/announcementModel'
import { store, pending } from './index'

// ACTION TYPES
const ANNOUNCEMENT_MODEL_CREATE = 'ANNOUNCEMENT_MODEL_CREATE'
const ANNOUNCEMENT_MODEL_CREATE_MODAL_VISIBLE =
  'ANNOUNCEMENT_MODEL_CREATE_MODAL_VISIBLE'

export const createActionTypes = {
  ANNOUNCEMENT_MODEL_CREATE,
  ANNOUNCEMENT_MODEL_CREATE_MODAL_VISIBLE
}

// ACTIONS
export const createActions = (state, dispatch) => ({
  createAnnouncementModel: (payload) =>
    dispatch({ type: ANNOUNCEMENT_MODEL_CREATE, payload }),

  closeCreateAnnouncementModelModal: () =>
    dispatch({ type: ANNOUNCEMENT_MODEL_CREATE_MODAL_VISIBLE, payload: false }),

  openCreateAnnouncementModelModal: () =>
    dispatch({ type: ANNOUNCEMENT_MODEL_CREATE_MODAL_VISIBLE, payload: true })
})

// MIDDLEWARE
export const create = async (dispatch, payload) => {
  try {
    pending(dispatch, true)
    const response = await createAnnouncementModel(payload)
    return response
  } finally {
    pending(dispatch, false)
  }
}

// REDUCER
export const createReducer = (state, action) => {
  switch (action.type) {
    case ANNOUNCEMENT_MODEL_CREATE_MODAL_VISIBLE:
      return store(state, { createModalOpen: action.payload })

    default:
      return state
  }
}
