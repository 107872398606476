import React, { useEffect, useState } from 'react'
import { TimeSelector } from '@condofy/next/components'

export const HourAvailabilitySelector = ({
  value: [timeObj] = [],
  name: fieldName,
  required = true,
  onChange = () => {}
}) => {
  const [time, setTime] = useState({
    initialTime: timeObj?.initialTime,
    finalTime: timeObj?.finalTime
  })

  useEffect(() => {
    setTime({
      initialTime: timeObj?.initialTime,
      finalTime: timeObj?.finalTime
    })
  }, [timeObj])

  const valueChanged = ({ target }) => {
    const { name, value } = target
    const newTime = { ...time, [name]: value }
    setTime(newTime)
    onChange({
      target: {
        name: fieldName,
        value: [newTime]
      }
    })
  }

  return (
    <>
      <TimeSelector
        required={required}
        value={time.initialTime}
        name='initialTime'
        label='Início'
        fullWidth
        onChange={valueChanged}
      />
      <TimeSelector
        required={required}
        value={time.finalTime}
        name='finalTime'
        label='Fim'
        fullWidth
        onChange={valueChanged}
      />
    </>
  )
}
