import React from 'react'
import styled from 'styled-components'
import { Tab as MuiTab } from '@mui/material'
import { colors, theme } from '../../styles'

const StyledTab = styled(MuiTab)`
  &.MuiTab-root {
    border-bottom: solid 1px ${colors.light500};
    color: ${colors.dark500};
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.75px;
    padding: 0 ${theme.spacing(1)};
    min-height: 30px;
    min-width: 100px;
    width: auto;
  }
  &.Mui-selected {
    color: ${colors.dark800} !important;
  }
`

export const Tab = (args) => <StyledTab {...args} />
