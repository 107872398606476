import React, { useState, useEffect } from 'react'
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers'
import { options, dateTimeStyles as styles } from './config'
import styled from 'styled-components'
import { parseISO, isValid } from 'date-fns'

// eslint-disable-next-line no-unused-vars
const StyledDatePicker = styled(MuiDatePicker)`
  ${styles}
`
export const DatePicker = ({
  inputVariant = 'outlined',
  format = 'dd/MM/yyyy',
  cancelLabel = 'Cancelar',
  style = {},
  value: initialValue,
  ...args
}) => {
  const [dateValue, setDateValue] = useState(null)

  useEffect(() => {
    if (typeof initialValue === 'string') {
      const parsedDate = parseISO(initialValue)
      if (isValid(parsedDate)) {
        setDateValue(parsedDate)
      } else {
        setDateValue(null)
      }
    } else if (initialValue instanceof Date && isValid(initialValue)) {
      setDateValue(initialValue)
    } else {
      setDateValue(null)
    }
  }, [initialValue])

  return (
    <StyledDatePicker
      inputVariant={inputVariant}
      cancelLabel={cancelLabel}
      format={format}
      sx={{ ...style }}
      value={dateValue}
      {...args}
    />
  )
}
DatePicker.propTypes = { ...options.propTypes }
