import React from 'react'
import styled from 'styled-components'
import {
  Flex,
  Tooltip,
  Chip,
  colors
  // Icon,
  // IconButton
} from '@condofy/components'
import PropTypes from 'prop-types'
// import { useFinancialContext } from '@condofy/next/store/financial'

const StyledChip = styled(Chip)`
  background-color: ${colors.brandBlue50};
  border-radius: 4px;
  height: 16px !important;
  letter-spacing: 0 !important;
  overflow: hidden;
  padding: 0;
  text-overflow: ellipsis;

  & .MuiChip-label {
    padding: 0 3px;
    color: ${colors.darkBlue500};
  }
`

// const EditButton = styled(IconButton)`
//   &.MuiIconButton-root {
//     padding: 0;
//   }
//   .MuiSvgIcon-root {
//     height: 18px;
//     width: 18px;
//   }
// `

export const FinancialStatementItemCategories = ({
  categories,
  variant = 'normal'
  // canEdit = false,
  // item
}) => {
  // const {
  //   actions: { openStatementItemCategoryModal }
  // } = useFinancialContext()

  const categorySet = [...new Set(categories)].slice(0, 3)
  const getFirstLetter = (word) => String(word).substring(0, 1)
  const getLabel = (word) =>
    variant === 'normal' ? word : getFirstLetter(word)

  // const handleEdit = () => {
  //   openStatementItemCategoryModal(item)
  // }

  return (
    <Flex spacingX={1}>
      {categorySet.map((i) => (
        <Tooltip key={i} title={i}>
          <StyledChip maxWidth='85px' label={getLabel(i)}></StyledChip>
        </Tooltip>
      ))}
      {/* {canEdit && (
        <EditButton color='primary' onClick={handleEdit}>
          <Icon icon='edit' />
        </EditButton>
      )} */}
    </Flex>
  )
}
FinancialStatementItemCategories.propTypes = {
  variant: PropTypes.oneOf(['normal', 'compact']),
  canEdit: PropTypes.bool
}
