import { errorParser } from 'Middleware/parsers'
import {
  CONDONIUM_FILED_CHANGE,
  PROCESS_CONDOMINIUM,
  PROCESS_CONDOMINIUM_SUCCESS,
  PROCESS_CONDOMINIUM_FAIL,
  INITIAL_CONDOMINIUM_STATE,
  SET_BANK_LIST,
  SET_CONDOMINIUMS_LIST,
  SET_CONDOMINIUMS_META,
  SET_CONDOMINIUM,
  SET_CONDOMINIUM_SEARCH,
  SET_UNITS_LIST,
  CLEAR_UNITS_LIST,
  SET_CONDOMINIUM_ERROR
} from 'Actions/condominium'
import { nanoid } from 'nanoid'

const initialState = {
  condominium: {
    id: '',
    cnpj: '',
    name: '',
    typeId: '1',
    street: '',
    district: '',
    city: '',
    state: '',
    zipCode: '',
    code: nanoid(5),
    planId: '1',
    bankId: '',
    costCenter1: 'Conta Movimento',
    costCenter2: 'Fundo Reserva'
  },
  meta: { limit: 10, count: 0 },
  condominiumsList: [],
  bankList: [],
  errors: {
    id: '',
    cnpj: '',
    name: '',
    type: '',
    address: '',
    district: '',
    city: '',
    state: '',
    zipCode: '',
    code: '',
    bankId: '',
    costCenter1: '',
    costCenter2: '',
    global: []
  },
  isLoading: false,
  search: ''
}

const condominiumSection = (state = initialState, action) => {
  switch (action.type) {
    case PROCESS_CONDOMINIUM:
      return {
        ...state,
        isLoading: true
      }
    case PROCESS_CONDOMINIUM_FAIL:
      return {
        ...state,
        errors: errorParser(action.errors),
        isLoading: false
      }
    case PROCESS_CONDOMINIUM_SUCCESS:
      return {
        ...state,
        condominium: initialState.condominium,
        errors: [],
        isLoading: false
      }
    case CONDONIUM_FILED_CHANGE:
      return {
        ...state,
        condominium: {
          ...state.condominium,
          [action.name]: action.value
        }
      }
    case INITIAL_CONDOMINIUM_STATE:
      return {
        ...state,
        condominium: initialState.condominium,
        errors: initialState.errors,
        isLoading: initialState.isLoading
      }
    case SET_BANK_LIST:
      return {
        ...state,
        bankList: action.bankList
      }
    case SET_CONDOMINIUMS_LIST:
      return {
        ...state,
        // don't overwrite units if they're already loaded
        condominiumsList: action.condominiumsList.map((condo) => {
          const existingCondo = state.condominiumsList.find(
            (existingCondoItem) => condo.id === existingCondoItem.id
          )
          if (existingCondo && existingCondo.units) {
            condo.units = existingCondo.units
          }
          return condo
        })
      }
    case SET_CONDOMINIUMS_META:
      return {
        ...state,
        meta: action.payload
      }
    case SET_CONDOMINIUM:
      return {
        ...state,
        condominium: action.condominium
      }
    case SET_CONDOMINIUM_SEARCH:
      return {
        ...state,
        search: action.search
      }
    case SET_UNITS_LIST:
      return {
        ...state,
        condominiumsList: state.condominiumsList.map((condo) => {
          if (action.condoId === condo.id) {
            return {
              ...condo,
              units: action.units
            }
          }
          return condo
        })
      }
    case CLEAR_UNITS_LIST:
      return {
        ...state,
        condominiumsList: state.condominiumsList.map((condo) => {
          if (action.condoId === condo.id) {
            condo.units = null
            return condo
          }
          return condo
        })
      }
    case SET_CONDOMINIUM_ERROR:
      return {
        ...state,
        errors: errorParser(action.errors)
      }
    default:
      return {
        ...state
      }
  }
}

export default condominiumSection
