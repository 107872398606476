import React from 'react'
import { CardGrid } from '@condofy/components'
import { UnitCard } from './UnitCard'

export const UnitsGrid = ({
  items = [],
  metadata,
  currentCondominium,
  search,
  page
}) => {
  return (
    <CardGrid loading={metadata.loading}>
      {items.map((unit) => (
        <UnitCard
          key={unit.id}
          unit={unit}
          condominium={currentCondominium}
          search={search}
          page={page}
        />
      ))}
    </CardGrid>
  )
}
