import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import {
  Flex,
  DataTable,
  theme,
  Pagination,
  Typography,
  MenuItem,
  Select,
  pxToRem
} from '@condofy/components'
import { RowsPerPage } from '../../components'
import { FinancialStatementDownload } from './FinancialStatementDownload'
import { FinancialStatementDateNavigator } from './FinancialStatementDateNavigator'
import { FinancialStatementItem } from './FinancialStatementItem'
import { financialStatementColumns } from './financialStatementColumns'
import { useUserContext } from '@condofy/next/store/user'
import { useBankAccountContext } from '@condofy/next/store/bank-account'
import { Actions } from '@Redux/finance'
import { Box } from '@mui/material'
import { FinancialStatementNewEntry } from './FinancialStatementNewEntry'
import { FinancialStatementEditEntry } from './FinancialStatementEditEntry'

const StyledDataTable = styled(DataTable)`
  .MuiTableBody-root > .MuiTableRow-root > .MuiTableCell-root {
    padding-top: ${theme.spacing(1)};
    padding-bottom: ${theme.spacing(1)};
  }
`

const ResponsiveTableContainer = styled.div`
  width: 100%;
  overflow-x: auto;
`
const StatementHeader = styled(Flex)`
  padding: ${theme.spacing(3)} 0;
`
const StyledPagination = styled(Pagination)`
  .MuiPagination-ul {
    margin-top: ${theme.spacing(3)};
  }
`
const FlexGap = styled.div`
  display: flex;
  gap: 10px;
`
const Label = styled(Typography)`
  font-weight: 700;
  font-size: ${pxToRem(14)}rem;
  margin-bottom: ${theme.spacing(1)} !important;
`
const StyledSelect = styled(Select)`
  min-width: 120px;
`

const capitalize = (s) =>
  typeof s !== 'string' ? '' : s.charAt(0).toUpperCase() + s.slice(1)

export const FinancialStatement = ({ type }) => {
  const dispatch = useDispatch()
  const [openEditModal, setOpenEditModal] = useState(false)
  const [entryToEdit, setEntryToEdit] = useState({})
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [pageCount, setPageCount] = useState(1)
  const [fundingType, setFundingType] = useState(null)
  const {
    state: { currentCondominium, isAdmin, isSuperAdmin }
  } = useUserContext()
  const {
    state: { currentAccount }
  } = useBankAccountContext()

  const { finances, loading, metadata, currentMonth } = useSelector(
    ({ finance }) => ({
      finances: finance[type].list
        .filter((f) => f?.status?.id !== '1')
        .map(({ items, ...other }) => {
          return {
            ...other,
            items: items.sort((a, b) =>
              a.description.localeCompare(b.description)
            ),
            balance: fundingType !== null ? null : other.balance
          }
        }),
      loading: Boolean(finance.pending[`search${capitalize(type)}`]),
      currentMonth: finance[`${type}Month`],
      metadata: finance[type].metadata
    })
  )
  useEffect(() => {
    setPageCount(Math.ceil(metadata.count / pageSize) || 1)
  }, [metadata, pageSize])

  useEffect(() => {
    setPage(1)
  }, [currentMonth, pageSize])

  useEffect(() => {
    if (currentCondominium.id && currentAccount.bankAccountId)
      dispatch(
        Actions[`financeSearch${capitalize(type)}`]({
          currentCondominium,
          page,
          rowsPerPage: pageSize,
          bankAccountId: currentAccount.bankAccountId,
          fundingType: fundingType
        })
      )
  }, [
    currentMonth,
    dispatch,
    type,
    currentAccount,
    currentCondominium,
    page,
    pageSize,
    fundingType
  ])

  const isStarter = currentCondominium?.plan?.id === '2'
  const showRowOptions = isStarter && (isAdmin || isSuperAdmin)

  const columns = financialStatementColumns({ type, hide: !showRowOptions })

  const handleFundingTypeChange = (event) => setFundingType(event.target.value)

  const loadFinances = () => {
    if (currentCondominium.id && currentAccount.bankAccountId) {
      dispatch(
        Actions[`financeSearch${capitalize(type)}`]({
          currentCondominium,
          page,
          rowsPerPage: pageSize,
          bankAccountId: currentAccount.bankAccountId,
          fundingType: fundingType
        })
      )
    }
  }

  useEffect(() => {
    loadFinances()
  }, [])

  const handleOpenEditModal = (modalStatus) => {
    setOpenEditModal(modalStatus)
  }

  const handleEditModal = (item) => {
    setEntryToEdit(item)
    handleOpenEditModal(true)
  }

  return (
    <Flex direction='column'>
      <StatementHeader
        justify='space-between'
        alignItems='center'
        style={{ justifyContent: 'space-between' }}
      >
        <FlexGap>
          <RowsPerPage onChange={setPageSize} />
          {type === 'toDetailed' && (
            <Flex direction='column'>
              <Label component='p'>Tipo de Lançamento</Label>
              <StyledSelect
                select
                label='Tipo'
                value={fundingType}
                onChange={handleFundingTypeChange}
              >
                <MenuItem value={null}>Todos</MenuItem>
                <MenuItem value={2}>Pagamentos</MenuItem>
                <MenuItem value={1}>Recebimentos</MenuItem>
              </StyledSelect>
            </Flex>
          )}
        </FlexGap>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <FinancialStatementDateNavigator type={type} />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          {isStarter && (
            <>
              <FinancialStatementNewEntry reloadEntryList={loadFinances} />
              <FinancialStatementEditEntry
                reloadEntryList={loadFinances}
                onOpenEdit={handleOpenEditModal}
                openModal={openEditModal}
                itemToEdit={entryToEdit}
              />
            </>
          )}
          <FinancialStatementDownload type={type} />
        </Box>
      </StatementHeader>
      <ResponsiveTableContainer>
        <StyledDataTable
          columns={columns}
          loading={loading}
          noRowsFeedback='Não existem lançamentos para o período'
        >
          {finances.map((row) => (
            <FinancialStatementItem
              key={row.id}
              item={row}
              columns={columns}
              currentCondominium={currentCondominium}
              reloadEntryList={loadFinances}
              onEdit={handleEditModal}
            />
          ))}
        </StyledDataTable>
      </ResponsiveTableContainer>
      <Flex width='100%' justify='flex-end'>
        <StyledPagination
          count={pageCount || 1}
          page={page || 1}
          disabled={!pageCount || pageCount <= 1}
          onChange={(event, page) => setPage(page)}
        />
      </Flex>
    </Flex>
  )
}
FinancialStatement.propTypes = {
  type: PropTypes.oneOf(['toDetailed', 'toPay', 'toReceive'])
}
