import { List, Create, Update, Detail, Modal } from '../core/'
import {
  search,
  createSurvey,
  updateSurvey,
  createVote,
  getById
} from '@condofy/next/service/survey'

const key = 'SURVEY'

const list = new List({
  key: `${key}_LIST`,
  statePropName: 'list'
})
const detail = new Detail({ key: `${key}_LIST`, statePropName: 'detail' })

const create = new Create({ key: `${key}_CREATE`, statePropName: 'create' })
const createModal = new Modal({
  key: `${key}_CREATE_MODAL`,
  statePropName: 'create'
})

const update = new Update({ key: `${key}_UPDATE`, statePropName: 'update' })
const updateModal = new Modal({
  key: `${key}_UPDATE_MODAL`,
  statePropName: 'update'
})

const vote = new Create({ key: `${key}_CREATE_VOTE`, statePropName: 'vote' })
const voteModal = new Modal({
  key: `${key}_CREATE_VOTE_MODAL`,
  statePropName: 'vote'
})

export const initialState = {
  detail: { ...detail.initialState },
  list: { ...list.initialState },
  create: { ...create.initialState, ...createModal.initialState },
  update: { ...update.initialState, ...updateModal.initialState },
  vote: { ...vote.initialState, ...voteModal.initialState }
}

export const createMiddleware = () => {
  return {
    ...detail.middleware(getById),
    ...list.middleware(search),
    ...create.middleware(createSurvey),
    ...createModal.middleware(),
    ...update.middleware(updateSurvey),
    ...updateModal.middleware(),
    ...vote.middleware(createVote),
    ...voteModal.middleware()
  }
}

export const useActions = (state, dispatch) => ({
  listSurveys: list.actions(state, dispatch),
  createSurvey: create.actions(state, dispatch),
  openCreateSurveyModal: createModal.actionOpen(state, dispatch),
  closeCreateSurveyModal: createModal.actionClose(state, dispatch),
  updateSurvey: update.actions(state, dispatch),
  openUpdateSurveyModal: (data) => {
    detail.actions(state, dispatch)(data)
    updateModal.actionOpen(state, dispatch)(data)
  },
  closeUpdateSurveyModal: updateModal.actionClose(state, dispatch),
  createVote: vote.actions(state, dispatch),
  openVoteModal: (data) => {
    detail.actions(state, dispatch)(data)
    voteModal.actionOpen(state, dispatch)(data)
  },
  closeVoteModal: voteModal.actionClose(state, dispatch)
})

export const createReducers = () => {
  return {
    ...detail.reducers(),
    ...list.reducers(),
    ...create.reducers(),
    ...createModal.reducers(),
    ...update.reducers(),
    ...updateModal.reducers(),
    ...vote.reducers(),
    ...voteModal.reducers()
  }
}
