import React, { useEffect } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormProvider, useForm } from 'react-hook-form'
import moment from 'moment'
import { useSnackbar, handleError } from '@condofy/next/hooks/useSnackbar'
import { useUserContext } from '@condofy/next/store/user'
import { useSurveyContext } from '@condofy/next/store/survey'
import { Dialog, DialogContent } from '@condofy/components'
import { ActionRow } from '@condofy/next/components'
import { SurveyStatus } from './SurveyStatus'
import {
  surveyEventSchema,
  surveyDefaultValues as defaultValues
} from './surveyEventSchema'
import { SurveyForm } from './SurveyForm'
import { track } from '@condofy/next/service/segment'

export const CreateSurveyModal = () => {
  const { enqueueSnackbar } = useSnackbar()
  const {
    state: {
      create: {
        modal: { open },
        metadata: { loading },
        error
      }
    },
    actions: { closeCreateSurveyModal, createSurvey, listSurveys }
  } = useSurveyContext()

  const {
    state: {
      currentCondominium: { id: condominiumId }
    }
  } = useUserContext()

  const form = useForm({
    mode: 'onChange',
    resolver: yupResolver(surveyEventSchema),
    defaultValues
  })

  useEffect(() => {
    if (error) {
      handleError(enqueueSnackbar)('Ocorreu um problema ao criar a enquete')
    }
  }, [error, enqueueSnackbar])

  const handleClose = () => {
    form.reset(defaultValues)
    closeCreateSurveyModal()
  }

  const handleSubmit = (values) => {
    createSurvey({
      condominiumId,
      body: {
        ...values,
        startDate: moment.isMoment(values.startDate) ? values.startDate.toDate() : moment(values.startDate).format(),
        endDate: moment.isMoment(values.endDate) ? values.endDate.toDate() : moment(values.endDate).format()
      }
    }).then(() => {
      track(window.analytics, 'Survey Created')
      handleClose()
      listSurveys({ condominiumId })
    })
  }

  const createDraft = (values) =>
    handleSubmit({ ...values, status: SurveyStatus.DRAFT })
  const createPublished = (values) =>
    handleSubmit({ ...values, status: SurveyStatus.OPEN })

  const descriptionLimit = form.watch('description')
  const subjectLimit = form.watch('subject')

  return (
    <Dialog
      title='Nova Enquete'
      open={open}
      onClose={handleClose}
      actions={
        <ActionRow
          loading={loading}
          cancelText='Publicar mais tarde'
          confirmText='Publicar'
          onCancel={form.handleSubmit(createDraft)}
          onSubmit={form.handleSubmit(createPublished)}
        />
      }
    >
      <DialogContent>
        <FormProvider {...form}>
          <SurveyForm
            descriptionLimit={descriptionLimit.length}
            subjectLimit={subjectLimit.length}
          />
        </FormProvider>
      </DialogContent>
    </Dialog>
  )
}
