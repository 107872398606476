import { search, fullList } from '@condofy/next/service/unit'
import { pending, store } from './index'

// ACTION TYPES
const UNIT_LIST = 'UNIT_LIST'
const UNIT_LIST_FULL = 'UNIT_LIST_FULL'
const UNIT_LIST_SUCCESS = 'UNIT_LIST_SUCCESS'
const UNIT_LIST_FULL_SUCCESS = 'UNIT_LIST_FULL_SUCCESS'

export const listActionTypes = {
  UNIT_LIST,
  UNIT_LIST_FULL,
  UNIT_LIST_FULL_SUCCESS
}

// ACTIONS
export const listActions = (state, dispatch) => ({
  listUnit: (payload) => dispatch({ type: UNIT_LIST, payload }),
  listAllUnits: (payload) => dispatch({ type: UNIT_LIST_FULL, payload })
})

// MIDDLEWARE
export const list = async (dispatch, payload) => {
  pending(dispatch, true)
  try {
    const response = await search(payload)

    dispatch({ type: UNIT_LIST_SUCCESS, payload: response.data })

    return response
  } finally {
    pending(dispatch, false)
  }
}

export const listAll = async (dispatch, payload) => {
  pending(dispatch, true)
  try {
    const response = await fullList(payload)

    dispatch({ type: UNIT_LIST_FULL_SUCCESS, payload: response.data })

    return response
  } finally {
    pending(dispatch, false)
  }
}

// REDUCER
export const listReducer = (state, action) => {
  switch (action.type) {
    case UNIT_LIST_SUCCESS:
      return store(state, {
        items: action.payload.data,
        metadata: {
          ...state.unit.metadata,
          ...action.payload.paging
        }
      })

    case UNIT_LIST_FULL_SUCCESS:
      return store(state, {
        allItems: action.payload.data
      })

    default:
      return state
  }
}
