import React from 'react'
import { InfoTooltip, Flex, Switch, colors, Icon } from '@condofy/components'
import styled from 'styled-components'

const StyledTooltip = styled(InfoTooltip)`
  .MuiButtonBase-root {
    padding: 0 !important;
  }
  .MuiSvgIcon-root {
    fill: ${colors.light600};
    width: 16px;
    height: 16px;
  }
`

const Description = styled(Flex)`
  font-size: 12px;

  .MuiSvgIcon-root {
    fill: ${colors.warning400};
    height: 18px;
    width: 16px;
  }
`

const SwitchLabel = ({ heading, description, tooltip }) => (
  <>
    <Flex spacingX={1}>
      <span>{heading}</span>
      {tooltip && <StyledTooltip width='14px' icon='info' title={tooltip} />}
    </Flex>
    {description && (
      <Description spacingX={1}>
        <Icon
          icon='warning_outlined'
          style={{ width: '16px' }}
          color={colors.warning400}
        />
        <div>{description}</div>
      </Description>
    )}
  </>
)

export const InvoiceSwitch = ({ heading, description, tooltip, ...props }) => (
  <Switch
    {...props}
    label={
      <SwitchLabel
        tooltip={tooltip}
        description={description}
        heading={heading}
      />
    }
  />
)
