import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'

import PromptModal from 'Common/PromptModal'
import { getUnitsByCondo } from 'Actions/condominium'
import { callApi } from 'Middleware/api'
import { track } from '@condofy/next/service/segment'
import { useSnackbar, handleError } from '@condofy/next/hooks/useSnackbar'

const DeleteUserConnection = ({
  options: { userId, condoId },
  close,
  closeButton
}) => {
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()

  const onConfirm = () => {
    callApi(`/v1/connections/${userId}`, {
      method: 'DELETE'
    })
      .then(() => {
        track(window.analytics, 'Connection Removed', {
          user_id: userId
        })
        dispatch(getUnitsByCondo(condoId))
      })
      .catch(handleError(enqueueSnackbar))
      .finally(close())
  }

  return (
    <PromptModal
      isOpen
      heading='Excluir conexão do usuário'
      cancelText='Cancelar'
      confirmText='Deletar'
      onClose={close}
      onConfirm={onConfirm}
      closeButton={closeButton}
    >
      Você tem certeza que quer excluir está Conexão do usuário
    </PromptModal>
  )
}

DeleteUserConnection.propTypes = {
  options: PropTypes.shape({
    userId: PropTypes.string.isRequired,
    unitId: PropTypes.string.isRequired,
    condoId: PropTypes.string.isRequired
  }).isRequired,
  close: PropTypes.func.isRequired,
  closeButton: PropTypes.node.isRequired
}

export default DeleteUserConnection
