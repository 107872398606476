import React, { useState } from 'react'
import { useSnackbar, handleError } from '@condofy/next/hooks/useSnackbar'
import { useUserContext } from '@condofy/next/store/user'
import { Modal, ActionRow, ModalContent } from '@condofy/next/components'
import { track } from '@condofy/next/service/segment'

export const CondominiumDeleteModal = () => {
  const { enqueueSnackbar } = useSnackbar()
  const [loading, setLoading] = useState(false)

  const {
    state: {
      condominium: { selectedCondominium, deleteModalOpen }
    },
    actions
  } = useUserContext()

  const handleSubmit = () => {
    setLoading(true)
    actions
      .deleteCondominium(selectedCondominium)
      .then(() => {
        track(window.analytics, 'Condominium Removed', selectedCondominium)
        enqueueSnackbar('Condomínio removido com sucesso', {
          variant: 'success'
        })
        actions.listCondominium()
        actions.closeDeleteCondominiumModal()
      })
      .catch(handleError(enqueueSnackbar))
      .finally(() => setLoading(false))
  }

  return (
    <Modal
      title='Excluir condomínio'
      open={deleteModalOpen}
      onClose={actions.closeDeleteCondominiumModal}
      footer={
        <ActionRow
          loading={loading}
          confirmText='Deletar'
          onSubmit={handleSubmit}
          onCancel={actions.closeDeleteCondominiumModal}
        />
      }
    >
      <ModalContent>
        Você tem certeza que quer deletar {selectedCondominium.name}?
      </ModalContent>
    </Modal>
  )
}
