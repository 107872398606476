import React from 'react'
import { Icon } from '../Icon'
import { Typography } from '../Typography'
import styled from 'styled-components'
import { theme, colors, typography, pxToRem } from '../../styles'

const StyledAttachment = styled.a`
  color: ${colors.dark300} !important;
  display: block;
  font-family: ${typography.type.tertiary};
  margin-bottom: ${theme.spacing(1)};
`

const Filename = styled(Typography)`
  margin-left: ${theme.spacing(1)} !important;
  font-size: ${pxToRem(14)}rem !important;
`

export const Attachment = ({ name, url }) => {
  return (
    <StyledAttachment href={url} target='_blank' rel='noopener noreferrer'>
      <Icon icon='download_to' />
      <Filename>{name}</Filename>
    </StyledAttachment>
  )
}
