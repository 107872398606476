import React from 'react'
import { CardGrid } from '@condofy/components'
import { ListingCard } from './ListingCard'
import { CreateListingCard } from './CreateListingCard'
import { ListingStatusActionMenu } from './ListingStatusActionMenu'

export const MyListingsList = ({ listings = [], loading, args }) => {
  return (
    <CardGrid loading={loading} {...args}>
      {listings.map((item, index) => (
        <ListingCard
          key={index}
          listing={item}
          Actions={<ListingStatusActionMenu listing={item} />}
        />
      ))}
      <CreateListingCard />
    </CardGrid>
  )
}
