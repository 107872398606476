import React, { useState } from 'react'
import PropTypes from 'prop-types'

let MainContext
const { Provider, Consumer } = (MainContext = React.createContext())

const MainProvider = ({ children }) => {
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false)
  const toggleSidebar = () => {
    setSidebarCollapsed(!sidebarCollapsed)
  }

  return (
    <Provider value={{ sidebarCollapsed, toggleSidebar }}>{children}</Provider>
  )
}

MainProvider.propTypes = {
  children: PropTypes.node.isRequired
}

export { MainProvider, Consumer as MainConsumer, MainContext }
