import { combineReducers } from '@condofy/next/store/user/combineReducers'
import { listActions, listReducer, list, listActionTypes } from './list'
import { editActions, editReducer, edit, editActionTypes } from './edit'
import {
  createActions,
  createReducer,
  createActionTypes,
  create
} from './create'

// ACTION TYPES
const ANNOUNCEMENT_MODEL_SET_LOADING_INDICATOR =
  'ANNOUNCEMENT_MODEL_SET_LOADING_INDICATOR'
const ANNOUNCEMENT_MODEL_SELECT = 'ANNOUNCEMENT_MODEL_SELECT'

// ACTIONS
export const announcementModelActions = (state, dispatch) => ({
  ...createActions(state, dispatch),
  ...listActions(state, dispatch),
  ...editActions(state, dispatch),

  selectAnnouncementModel: (payload) =>
    dispatch({ type: ANNOUNCEMENT_MODEL_SELECT, payload })
})

export const pending = (dispatch, payload) =>
  dispatch({ type: ANNOUNCEMENT_MODEL_SET_LOADING_INDICATOR, payload })

// MIDDLEWARE
export const announcementModelMiddlewares = (dispatch, action) => {
  switch (action.type) {
    case createActionTypes.ANNOUNCEMENT_MODEL_CREATE:
      return create(dispatch, action.payload)

    case editActionTypes.ANNOUNCEMENT_MODEL_EDIT:
      return edit(dispatch, action.payload)

    case listActionTypes.ANNOUNCEMENT_MODEL_LIST:
      return list(dispatch, action.payload)

    default:
      return false
  }
}

// REDUCER
const announcementModelMainReducer = (state, action) => {
  switch (action.type) {
    case ANNOUNCEMENT_MODEL_SELECT:
      return store(state, { selected: action.payload })

    case ANNOUNCEMENT_MODEL_SET_LOADING_INDICATOR:
      return store(state, {
        metadata: {
          ...state.announcementModel.metadata,
          loading: action.payload
        }
      })

    default:
      return state
  }
}

export const announcementModelReducer = combineReducers({
  announcementModelMainReducer,
  createReducer,
  editReducer,
  listReducer
})

export const store = (state, value) => ({
  ...state,
  announcementModel: { ...state.announcementModel, ...value }
})

export const announcementModel = {
  createModalOpen: false,
  deleteModalOpen: false,
  editModalOpen: false,
  selected: {},
  items: [],
  metadata: {
    pageNumber: 1,
    totalRecords: 0,
    recordsPerPage: 10,
    loading: false
  }
}

export const announcementModelActionTypes = {
  ANNOUNCEMENT_MODEL_SET_LOADING_INDICATOR,
  ANNOUNCEMENT_MODEL_SELECT,

  ...createActionTypes,
  ...listActionTypes,
  ...editActionTypes
}
