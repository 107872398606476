import { condofyProtected } from './instance'

export const receiveProposalPremium = ({
  id,
  onError: emitErrorEvent = (e) => e,
  onSuccess: emitSuccessEvent = (e) => e
}) =>
  condofyProtected
    .post(`/v1/plans/condominium/${id}/upgrade`)
    .then(({ data }) => emitSuccessEvent(data))
    .catch((error) => emitErrorEvent(error))
