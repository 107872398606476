import React from 'react'
import { MobileDatePicker as MuiDatePicker } from '@mui/x-date-pickers'
import { options, dateTimeStyles as styles } from './config'
import styled from 'styled-components'

const StyledDatePicker = styled(MuiDatePicker)`
  ${styles}
`
export const MobileDatePicker = ({
  inputVariant = 'outlined',
  format = 'dd/MM/yyyy',
  cancelLabel = 'Cancelar',
  ...args
}) => {
  return (
    <StyledDatePicker
      inputVariant={inputVariant}
      cancelLabel={cancelLabel}
      format={format}
      {...args}
    />
  )
}
MobileDatePicker.propTypes = { ...options.propTypes }
