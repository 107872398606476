import React, { useEffect, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Actions } from '@Redux/storage'
import { Alert, Icon, LoadingButton } from '@condofy/components'
import { useUserContext } from '@condofy/next/store/user'
import { CreateFileModal } from './CreateFileModal'
import { CreateFolderModal } from './CreateFolderModal'
import { EditFolderModal } from './EditFolderModal'
import { StorageCard } from './Card'

import styled from 'styled-components'
import Button from '@mui/material/Button'
import { useSnackbar } from '@condofy/next/hooks/useSnackbar'
import { receiveProposalPremium } from '@condofy/next/service/plan'
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded'
import Cookies from 'js-cookie'

const ReceiveButton = styled(Button)`
  color: #0154ec !important;
`
const SuccessButton = styled(ReceiveButton)`
  color: #1aaa52 !important;
`

export const StorageSection = () => {
  const {
    state: { currentCondominium, isSuperAdmin, isAdmin }
  } = useUserContext()
  const { enqueueSnackbar } = useSnackbar()
  const administratorUser = isSuperAdmin || isAdmin
  const [loading, setLoading] = useState(false)
  const files = useSelector(({ storage }) => storage.list)
  const dispatch = useDispatch()
  const [fileModal, setFileModal] = useState(false)
  const [folderModal, setFolderModal] = useState(false)
  const [editfolderModal, setEditFolderModal] = useState(false)
  const [currentFolder, setCurrentFolder] = useState(files)
  const [currentPath, setCurrentPath] = useState(['Meus Arquivos'])

  const navigate = useCallback(
    (newPath) => {
      let currentPointer = files

      newPath.forEach((directory) => {
        if (directory !== 'Meus Arquivos') {
          currentPointer.some((pointerDirectory) => {
            currentPointer = pointerDirectory.files
            return directory === pointerDirectory.title
          })
        }
      })
      setCurrentPath(newPath)
      setCurrentFolder(currentPointer)
    },
    [files]
  )

  useEffect(() => {
    if (currentCondominium.id) {
      dispatch(Actions.storageList({ currentCondominium }))
      navigate(['Meus Arquivos'])
    }
  }, [currentCondominium])

  useEffect(() => {
    navigate(currentPath)
  }, [files, currentPath, navigate])

  const handleFileModalOpen = () => {
    setFileModal(true)
  }
  const handleFolderModalOpen = () => {
    setFolderModal(true)
  }
  const handleEditFolderModalOpen = (folder) => {
    setCurrentFolder(folder)
    setEditFolderModal(true)
  }

  const onChangeReceiveProposal = () => {
    setLoading(true)
    receiveProposalPremium({
      id: currentCondominium.id,
      onSuccess: () => {
        Cookies.set('receiveProposal', 'true', { expires: 5 })
        enqueueSnackbar(`Solicitação da proposta enviada com sucesso!`, {
          variant: 'success'
        })
        setLoading(false)
      },
      onError: () => {
        setLoading(false)
      }
    })
  }
  const buttomReceive = () => {
    if (Cookies.get('receiveProposal') === undefined) {
      return (
        <LoadingButton
          loading={loading}
          onClick={onChangeReceiveProposal}
          size='small'
        >
          Solicitar Upgrade
        </LoadingButton>
      )
    } else {
      return (
        <SuccessButton
          endIcon={<CheckCircleOutlineRoundedIcon />}
          disabled
          size='small'
        >
          Solicitação enviada
        </SuccessButton>
      )
    }
  }

  return (
    <>
      <CreateFileModal
        open={fileModal}
        currentFolder={currentFolder}
        onClose={() => setFileModal(false)}
      />
      <CreateFolderModal
        open={folderModal}
        currentFolder={currentFolder}
        onClose={() => setFolderModal(false)}
      />
      <EditFolderModal
        open={editfolderModal}
        currentFolder={currentFolder}
        onClose={() => setEditFolderModal(false)}
      />
      {currentCondominium.plan.id === '2' && (
        <Alert
          severity='info'
          icon={<Icon icon='error_outlined' />}
          action={administratorUser && buttomReceive()}
        >
          Armazenamento máximo de 100MB para o plano Básico.
        </Alert>
      )}
      <StorageCard
        bashPath={files}
        navigate={navigate}
        currentFolder={currentFolder}
        currentPath={currentPath}
        onChange={setCurrentFolder}
        onPathChange={setCurrentPath}
        onFileModalOpen={handleFileModalOpen}
        onFolderModalOpen={handleFolderModalOpen}
        onEditFolderModalOpen={handleEditFolderModalOpen}
      />
    </>
  )
}
