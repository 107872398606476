import { Create, Delete, Detail, List, Modal, Update } from '../core/'
import {
  getBalance,
  getExpectedValues,
  listEntryCategories,
  createFinanceEntry,
  listEntries,
  deleteFinanceEntry,
  updateFinanceEntry
} from '@condofy/next/service/entry'

const key = 'FINANCIAL_ENTRY'

const balance = new List({
  key: `${key}_BALANCE_LIST`,
  statePropName: 'balance'
})

const categories = new List({
  key: `${key}_CATEGORY_LIST`,
  statePropName: 'categories'
})

const entries = new List({
  key: `${key}_ENTRY_LIST`,
  statePropName: 'entries'
})

const expected = new Detail({
  key: `${key}_EXPECTED`,
  statePropName: 'expected'
})

const detail = new Detail({ key })
const detailModal = new Modal({ key, statePropName: 'detail' })

const create = new Create({ key })
const createEventModal = new Modal({
  key: `${key}_CREATE_EVENT_MODAL`,
  statePropName: 'create'
})

const deleteEntry = new Delete({ key })

const update = new Update({ key })

export const initialState = {
  balance: { ...balance.initialState },
  categories: { ...categories.initialState },
  entries: { ...entries.initialState },
  expected: { ...expected.initialState },
  detail: { ...detailModal.initialState, ...detail.initialState },
  create: { ...create.initialState, ...createEventModal.initialState },
  delete: { ...deleteEntry.initialState },
  update: { ...update.initialState }
}

export const createMiddleware = () => {
  return {
    ...balance.middleware(getBalance),
    ...categories.middleware(listEntryCategories),
    ...entries.middleware(listEntries),
    ...expected.middleware(getExpectedValues),
    ...create.middleware(createFinanceEntry),
    ...createEventModal.middleware(),
    ...deleteEntry.middleware(deleteFinanceEntry),
    ...update.middleware(updateFinanceEntry)
  }
}

export const useActions = (state, dispatch) => ({
  listBalance: balance.actions(state, dispatch),
  listCategory: categories.actions(state, dispatch),
  listEntries: entries.actions(state, dispatch),
  getExpected: expected.actions(state, dispatch),

  openCreateEventModal: createEventModal.actionOpen(state, dispatch),
  closeCreateEventModal: createEventModal.actionClose(state, dispatch),

  createFinanceEntry: create.actions(state, dispatch),
  updateEntry: update.actions(state, dispatch),
  deleteEntry: deleteEntry.actions(state, dispatch)
})

export const createReducers = () => {
  return {
    ...createEventModal.reducers(),
    ...balance.reducers(),
    ...categories.reducers(),
    ...entries.reducers(),
    ...expected.reducers(),
    ...create.reducers(),
    ...deleteEntry.reducers(),
    ...update.reducers()
  }
}
