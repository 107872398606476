import React from 'react'
import { useDispatch } from 'react-redux'

import PromptModal from 'Common/PromptModal'
import { getUnitsByCondo } from 'Actions/condominium'
import { callApi } from 'Middleware/api'

import { useSnackbar, handleError } from '@condofy/next/hooks/useSnackbar'
import { track } from '@condofy/next/service/segment'

export default ({ options: { id: unitId, condoId }, close, closeButton }) => {
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()

  const onConfirm = () => {
    callApi(`/v1/condominiums/${condoId}/units/${unitId}`, {
      method: 'DELETE'
    })
      .then(() => {
        track(window.analytics, 'Condominium Unit Removed', {
          condominium_id: condoId
        })
        dispatch(getUnitsByCondo(condoId))
      })
      .catch(handleError(enqueueSnackbar))
      .finally(close)
  }

  return (
    <PromptModal
      isOpen
      heading='Excluir unidade'
      cancelText='Cancelar'
      confirmText='Deletar'
      onClose={close}
      onConfirm={onConfirm}
      closeButton={closeButton}
    >
      Você tem certeza que quer excluir está unidade
    </PromptModal>
  )
}
