import { Modal, Create } from '../core/'
import { interestOffer } from '@condofy/next/service/community'

const key = 'LISTING_ORDER'

const orderCreate = new Create({ key, statePropName: 'createOrder' })
const orderCreatedModal = new Modal({
  key: `${key}_CREATED`,
  statePropName: 'orderCreated'
})

export const initialState = {
  createOrder: { ...orderCreate.initialState },
  orderCreated: { ...orderCreatedModal.initialState }
}

export const createMiddleware = () => {
  return {
    ...orderCreatedModal.middleware(),
    ...orderCreate.middleware(interestOffer)
  }
}

export const useActions = (state, dispatch) => ({
  createOrder: orderCreate.actions(state, dispatch),
  openCreatedOrderModal: orderCreatedModal.actionOpen(state, dispatch),
  closeCreatedOrderModal: orderCreatedModal.actionClose(state, dispatch)
})

export const createReducers = () => {
  return {
    ...orderCreatedModal.reducers(),
    ...orderCreate.reducers()
  }
}
