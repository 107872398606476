import * as React from 'react'

function SvgComponent(props) {
  return (
    <svg
      width={130}
      height={96}
      viewBox='0 0 130 96'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M90.577 87.734l-.258 4.142H68.643l.812-4.559 21.122.418z'
        fill='#24307D'
      />
      <path
        d='M47.681 21.238a1.13 1.13 0 100-2.258 1.13 1.13 0 000 2.258z'
        fill='#fff'
      />
      <path
        d='M96.739 74.773l1.572-1.309c-.304 4.73-2.935 4.201-2.935 4.201-.967-4.848-3.947-9.546-4.86-10.984-.914-1.439-3.476-4.285-5.408-6.145-1.931-1.861-2.759-3.528-2.759-3.528 1.17 1.19 4.144 1.867 4.144 1.867 0 .534.534 2.462.534 2.462 0-.738.41-2.134.41-2.134.759.103 1.615.611 1.615.611l-.575 3.245 1.52-2.738c1.724.608 4.427 2.095 4.292 3.346-.13 1.21-2.006 3.454-2.122 3.593l3.576-2.916.811 4.629c-.405 1.115-2.738 2.178-2.738 2.178 1.183.185 3.615-1.774 3.615-1.774s.88 2.501.88 3.142c0 .624-1.48 2.158-1.572 2.254z'
        fill='#ABD2F4'
      />
      <path
        d='M96.739 74.773l1.572-1.309c-.304 4.73-2.935 4.201-2.935 4.201-.967-4.848-3.947-9.546-4.86-10.984-.914-1.439-3.476-4.285-5.408-6.145-1.931-1.861-2.759-3.528-2.759-3.528 1.17 1.19 4.144 1.867 4.144 1.867 0 .534.534 2.462.534 2.462 0-.738.41-2.134.41-2.134.759.103 1.615.611 1.615.611l-.575 3.245 1.52-2.738c1.724.608 4.427 2.095 4.292 3.346-.13 1.21-2.006 3.454-2.122 3.593l3.576-2.916.811 4.629c-.405 1.115-2.738 2.178-2.738 2.178 1.183.185 3.615-1.774 3.615-1.774s.88 2.501.88 3.142c0 .624-1.48 2.158-1.572 2.254z'
        fill='#C2DCCC'
      />
      <path
        d='M95.376 77.665c-1.573.106-2.502-2.808-2.748-3.67-.246-.862-.882-1.907-2.195-3.015-1.313-1.107-2.38-2.954-2.38-2.954 1.129.779 2.646.472 2.646.472-2.852-1.395-3.077-2.955-3.077-2.955-1.764-.964-3.733-5.396-3.733-5.396-.966-1.087-1.539-3.14-1.539-3.14s.83 1.668 2.76 3.529c1.93 1.86 4.494 4.705 5.406 6.145.912 1.44 3.894 6.136 4.86 10.984z'
        fill='#ABD2F4'
      />
      <path
        d='M95.376 77.665c-1.573.106-2.502-2.808-2.748-3.67-.246-.862-.882-1.907-2.195-3.015-1.313-1.107-2.38-2.954-2.38-2.954 1.129.779 2.646.472 2.646.472-2.852-1.395-3.077-2.955-3.077-2.955-1.764-.964-3.733-5.396-3.733-5.396-.966-1.087-1.539-3.14-1.539-3.14s.83 1.668 2.76 3.529c1.93 1.86 4.494 4.705 5.406 6.145.912 1.44 3.894 6.136 4.86 10.984z'
        fill='#8EACA8'
      />
      <path
        d='M90.516 66.68l2.53-4.78M89.855 65.772l2.356-4.449'
        stroke='#8EACA8'
        strokeWidth={0.473}
        strokeMiterlimit={10}
      />
      <path
        d='M93.909 92.013a18.953 18.953 0 011.822-2.989c.35-.468.724-.917 1.123-1.344.401-.427.837-.82 1.302-1.176.467-.357.97-.662 1.502-.91.27-.116.535-.242.814-.33.139-.047.274-.104.416-.14l.423-.118a9.734 9.734 0 013.479-.237 6.941 6.941 0 011.701.414l.204.082.196.101c.064.035.133.064.194.105l.185.124c.243.17.454.383.622.628.325.494.511 1.068.537 1.66a4.852 4.852 0 01-.225 1.729 7.033 7.033 0 01-1.858 2.911 10.434 10.434 0 01-2.874 1.93 10.434 10.434 0 002.848-1.957 6.975 6.975 0 001.811-2.906 4.75 4.75 0 00.205-1.7 3.186 3.186 0 00-.537-1.601 2.34 2.34 0 00-.599-.593l-.178-.116c-.059-.04-.126-.066-.189-.1l-.191-.095-.2-.078a6.777 6.777 0 00-1.67-.387 9.563 9.563 0 00-3.414.27l-.413.12c-.139.036-.27.094-.406.14-.272.09-.53.217-.792.332a7.917 7.917 0 00-1.454.902c-.451.353-.873.742-1.261 1.164-.388.424-.751.87-1.089 1.334a18.68 18.68 0 00-1.76 2.956l-.274-.126z'
        fill='#8EACA8'
      />
      <path
        d='M96.263 91.899c.467-1.174.916-2.356 1.35-3.546.429-1.189.846-2.384 1.19-3.599.082-.304.167-.609.227-.917.07-.306.11-.619.12-.933a3.34 3.34 0 00-.402-1.81c-.159-.27-.38-.498-.648-.663a1.973 1.973 0 00-.892-.269 4.017 4.017 0 00-1.832.401 4.03 4.03 0 011.834-.43c.32.014.633.103.912.261.276.165.508.395.674.671.317.56.469 1.198.438 1.841a4.96 4.96 0 01-.107.95c-.056.313-.137.62-.215.928-.328 1.225-.727 2.428-1.14 3.626a121.543 121.543 0 01-1.298 3.571l-.21-.082z'
        fill='#8EACA8'
      />
      <path
        d='M95.376 77.665s2.11 9.93.992 14.276M93.728 92.074v-5.336'
        stroke='#8EACA8'
        strokeWidth={0.702}
        strokeMiterlimit={10}
      />
      <path
        d='M82.35 73.035s4.107 1.144 5.995.85c1.887-.294 1.427 1.837 1.427 1.837s3.25 1.328 3.25 2.626c0 1.299-.973 2.511-.973 2.511s.768-.154 1.605-1.537c0 0 1.342 2.312 1.183 3.271a3.996 3.996 0 01-.69 1.639c.265-.092.487-.28.622-.526 0 0-.574 2.458-1.042 3.034 0 0-1.631-1.003-2.243-2.072-.611-1.07-3.241-3.573-4.33-5.569-1.088-1.996-3.742-5.473-4.805-6.064z'
        fill='#ABD2F4'
      />
      <path
        d='M82.35 73.035s4.107 1.144 5.995.85c1.887-.294 1.427 1.837 1.427 1.837s3.25 1.328 3.25 2.626c0 1.299-.973 2.511-.973 2.511s.768-.154 1.605-1.537c0 0 1.342 2.312 1.183 3.271a3.996 3.996 0 01-.69 1.639c.265-.092.487-.28.622-.526 0 0-.574 2.458-1.042 3.034 0 0-1.631-1.003-2.243-2.072-.611-1.07-3.241-3.573-4.33-5.569-1.088-1.996-3.742-5.473-4.805-6.064z'
        fill='#8EACA8'
      />
      <path
        d='M104.877 76.323s.889-1.139 1.047-2.431c0 0 2.617-.19 3.437-.662 0 0-2.251 2.214-4.484 3.093z'
        fill='#ABD2F4'
      />
      <path
        d='M104.877 76.323s.889-1.139 1.047-2.431c0 0 2.617-.19 3.437-.662 0 0-2.251 2.214-4.484 3.093z'
        fill='#8EACA8'
      />
      <path
        d='M108.923 72.843c1.038-1.056 1.391-2.782 1.391-2.782.479-.5 1.254-2.128 1.254-2.128.674 2.605-2.466 4.79-2.645 4.91z'
        fill='#ABD2F4'
      />
      <path
        d='M108.924 72.844l-.008.006'
        stroke='#7F51A8'
        strokeWidth={0.189}
        strokeMiterlimit={10}
      />
      <path
        d='M99.463 84.48a28.95 28.95 0 00-1.152 7.46'
        stroke='#8EACA8'
        strokeWidth={0.473}
        strokeMiterlimit={10}
      />
      <path
        d='M110.313 70.061s-.352 1.726-1.39 2.782a2.487 2.487 0 01-1.662.797s-1.723 3.069-4.694 3.852c0 0 1.796.782 3.362.098 0 0-1.893 3.33-3.199 4.962-.552.69-.957 1.177-1.353 1.494-.542.435-1.065.553-1.913.436 0 0-4.947-3.304-1.519-9.334l2.317 3.276-1.69-4.45s2.301-4.382 6.293-6.448l1.362 1.926-.893-3.851s4.181-2.16 6.717-2.913l-2.582 3.483s-2.57 4.686-2.945 5.673c-.001-.001 2.662-.326 3.789-1.783z'
        fill='#D9E7DE'
      />
      <path
        d='M101.375 84.045c.397-.318.802-.805 1.354-1.495 1.306-1.632 3.198-4.962 3.198-4.962s1.011-1.447 1.394-1.532c.383-.085 2.212 4.931-5.946 7.989z'
        fill='#ABD2F4'
      />
      <path
        d='M99.463 84.481s2.651-8.33 7.06-12.638M103.472 75.777l-2.946-4.627M104.393 74.382l-2.72-4.375'
        stroke='#8EACA8'
        strokeWidth={0.473}
        strokeMiterlimit={10}
      />
      <path
        d='M106.523 71.842s.655-3.91.596-4.477c0 0 4.985-2.134 6.656-4.677 0 0-2.806 5.087-7.252 9.154z'
        fill='#ABD2F4'
      />
      <path
        d='M108.923 72.843c1.038-1.056 1.391-2.782 1.391-2.782.479-.5 1.254-2.128 1.254-2.128.674 2.605-2.466 4.79-2.645 4.91zM101.375 84.045c.397-.318.802-.805 1.354-1.495 1.306-1.632 3.198-4.962 3.198-4.962s1.011-1.447 1.394-1.532c.383-.085 2.212 4.931-5.946 7.989zM106.523 71.842s.655-3.91.596-4.477c0 0 4.985-2.134 6.656-4.677 0 0-2.806 5.087-7.252 9.154z'
        fill='#8EACA8'
      />
      <path
        d='M90.071 63.555a.377.377 0 100-.754.377.377 0 000 .754zM93.728 68.236a.302.302 0 100-.604.302.302 0 000 .603zM104.393 70.007a.241.241 0 10.001-.483.241.241 0 00-.001.483zM91.54 79.225a.241.241 0 100-.483.241.241 0 000 .483zM92.269 83.378a.193.193 0 100-.387.193.193 0 000 .387zM101.22 74.97a.193.193 0 100-.386.193.193 0 000 .385zM105.825 80.228a.087.087 0 100-.173.087.087 0 000 .173z'
        fill='#fff'
      />
      <path
        d='M19.352 12.169h12.283a3.775 3.775 0 010 7.549h-9.643l-2.582 2.35v-2.35h-.058a3.773 3.773 0 01-3.487-5.219 3.773 3.773 0 013.487-2.33z'
        fill='#B6E4DF'
      />
      <path
        d='M29.453 16.66a.663.663 0 100-1.326.663.663 0 000 1.325zM25.493 16.66a.663.663 0 100-1.326.663.663 0 000 1.325zM21.534 16.661a.663.663 0 100-1.325.663.663 0 000 1.325z'
        fill='#fff'
      />
      <path
        d='M37.26 19.246c1.906 0 3.809.116 5.7.35v9.583a1.973 1.973 0 01-1.97 1.972H12.164a1.625 1.625 0 01-1.623-1.624v-1.754a45.892 45.892 0 0126.72-8.527z'
        fill='#B1C0E5'
      />
      <path
        d='M11.819 10.877v18.651a.346.346 0 00.345.345H40.99a.693.693 0 00.693-.693V10.877a1.16 1.16 0 00-1.16-1.16H12.978a1.16 1.16 0 00-1.16 1.16z'
        fill='#fff'
      />
      <path
        d='M24.096 29.872H12.165a.346.346 0 01-.346-.345V15.412h12.277v14.46zM11.819 10.877v.9h29.863v-.9a1.159 1.159 0 00-1.16-1.16H12.978a1.16 1.16 0 00-1.16 1.16z'
        fill='#C9D2EC'
      />
      <path
        d='M39.84 11.315a.569.569 0 100-1.138.569.569 0 000 1.138zM37.483 10.747a.565.565 0 00.205.435.566.566 0 00.8-.071.563.563 0 00-.07-.801.566.566 0 00-.801.071.568.568 0 00-.134.366z'
        fill='#fff'
      />
      <path
        d='M37.784 10.971a.25.25 0 00.044.044l.225-.225.044-.044.225-.225a.278.278 0 00-.044-.044l-.225.225-.044.044-.225.225z'
        fill='#AFBDE0'
      />
      <path
        d='M37.784 10.52l.225.226.044.044.225.225a.286.286 0 00.044-.044l-.27-.269-.224-.225a.277.277 0 00-.044.044zM39.49 10.777H40.191a.277.277 0 000-.062h-.7a.296.296 0 00-.001.062z'
        fill='#AFBDE0'
      />
      <path
        d='M11.819 10.877v18.651a.346.346 0 00.345.345H40.99a.693.693 0 00.693-.693V10.877a1.16 1.16 0 00-1.16-1.16H12.978a1.16 1.16 0 00-1.16 1.16v0z'
        stroke='#fff'
        strokeWidth={0.596}
        strokeMiterlimit={10}
      />
      <path
        d='M22.062 29.873s3.732-6.056 3.058-8.52c-.675-2.463-.272-11.685-.403-11.592-.703.499-1.47.393-2.489.986-.857.5-1.664 1.356-2.359 2.43-.834 1.29-3.591 2.43-4.021 4.737-.427 2.289 1.99 4.599 2.986 5.844 1.119 1.4-.876 6.086-.876 6.086l4.104.029z'
        fill='#161616'
        opacity={0.3}
      />
      <path
        d='M31.332 0h5.577a1.714 1.714 0 010 3.428H32.53l-1.173 1.067V3.428h-.026a1.714 1.714 0 110-3.428z'
        fill='#F7CF6F'
      />
      <path
        d='M35.919 2.038a.3.3 0 100-.601.3.3 0 000 .601zM34.12 2.038a.3.3 0 100-.601.3.3 0 000 .601zM32.323 2.04a.3.3 0 100-.602.3.3 0 000 .601z'
        fill='#fff'
      />
      <path
        d='M59.63 60.748s1.21-3.451.197-3.6c-.92-.135-.989 2.946-1.365 1.68-.702-2.359-2.697-2.005-3.308-1.273-.614.737 1.527 4.243 1.527 4.243l2.949-1.05z'
        fill='#EDA28E'
      />
      <path
        d='M93.088 56.005c-2.183-2.253-2.798-3.692-3.18-5.158-.923-3.538-1.816-4.993-3.033-6.223-.952-.963-2.093-1.288-3.022-1.553-1.454-.413-3.725-1.337-5.941-.263-3.486 1.688-.925 9.704-.925 9.704s-.923 1.148-.411 3.451-1.763 3.074-1.763 3.074c-5.158 3.18 1.131 8.001 1.131 8.001 10.994 5.64 17.207-.82 17.207-.82 5.044-4.217 1.714-8.38-.063-10.213z'
        fill='#081D39'
      />
      <path
        d='M64.63 73.382l.82-1.92 6.406 1.286a21.67 21.67 0 01-1.103 3.26c-.662 1.532-1.627 3.199-2.908 4.016l-3.216-6.642z'
        fill='#EDA28E'
      />
      <path
        d='M75.207 57.593l5.478 7.132-8.56 9.288-1.29 2.066-5.46-4.68a.878.878 0 01-.257-.958c.726-2.053 3.441-8.828 4.55-10.102 1.08-1.24 3.25-2.038 4.397-2.38l1.142-.366z'
        fill='#B6E4DF'
      />
      <path
        d='M56.667 61.768l2.95-1.05L67.208 73.7c2.662 4.05 1.331 5.773 1.331 5.773-2.405 2.707-5.382.624-5.382.624-1.648-1.213-4.855-10.879-6.49-18.33z'
        fill='#EDA28E'
      />
      <path
        d='M90.576 87.732l-3.011.563-8.958 1.673a11.415 11.415 0 01-9.245-2.32.569.569 0 01-.124-.753c.373-.576 1.086-1.938 2.153-5.05 1.194-3.475.462-6.323-.478-8.993-.245-.694-.504-1.378-.746-2.056-.13-.366-.258-.73-.373-1.096-1.165-3.642-.291-6.385 3.125-8.381 3.414-1.997 2.288-3.724 2.288-3.724s.147-.056.425-.135c.392-.109.79-.196 1.192-.262l2.327-.32 5.262-.475c2.122.271 4.027.428 5.498.904 0 0 .812 4.34-.062 6.447a6.23 6.23 0 00-.143.376h-.001c-.082.236-.167.514-.258.828a28.596 28.596 0 00-.737 3.492c-.487 3.21-.589 7.184.776 9.69.223.412.417.84.579 1.28 1.453 3.9.51 8.312.51 8.312z'
        fill='#B6E4DF'
      />
      <path
        d='M72.919 61.32c-3.416 1.996-4.29 4.736-3.126 8.378.117.366.242.732.374 1.098.242.678.5 1.36.745 2.055l.647 2.07'
        stroke='#91E2D5'
        strokeWidth={1.116}
        strokeMiterlimit={10}
      />
      <path
        d='M89.911 57.307s2.153.728 3.28 1.762c1.182 1.085 2.554 3.377 2.903 5.578.277 1.748.188 6.425-.044 8.553a.877.877 0 01-1.087.755l-6.178-1.553-1.03-7.677 2.156-7.418z'
        fill='#B6E4DF'
      />
      <path
        d='M78.224 46.53c-1.795 4.434-1.444 6.667-.793 7.771a2.61 2.61 0 001.893 1.306l.193.016-.016.191-.286 1.64a2.257 2.257 0 002.468 2.633l.673-.073a2.426 2.426 0 002.153-2.656l-.44-4.368.107-.062c.677-.388 1.085-1.387 1.04-2.078-.014-.226-.058-.913-.44-1.103-.293-.146-.779-.004-1.405.409l-.069.044-.08-.018c-.106-.024-2.164-.502-3.346-1.96.388 1.055 1.163 1.99 2.315 2.793l1.194.83-1.37-.491c-.04-.014-3.66-1.36-3.791-4.824z'
        fill='#EDA28E'
      />
      <path
        d='M79.517 55.622s1.493-.16 2.514-1.054c0 0-.587 1.348-2.692 2.167l.178-1.113z'
        fill='#050F1B'
        opacity={0.6}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M74.445 83.744s-3.183-.518-3.343.186c-.094.41.324.459 1.186.868.481.228-2.775 1.888-2.344 2.237.416.337 1.767-.644 1.767-.644s-1.922 1.377-1.507 1.797c.316.32 2.157-1.275 2.157-1.275s-1.582 1.47-1.39 1.699c.394.47 2.231-1.058 2.231-1.058-.25.356-.52.695-.811 1.018-.72.849.462.428 1.705-.358s2.045-1.342 2.045-1.342l-1.696-3.128z'
        fill='#EDA28E'
      />
      <path
        d='M90.576 87.732l-3.011.563c.542-1.659.732-3.412.56-5.148-.534-4.942-1.45-6.945-1.434-11.33.016-4.287 2.883-7.54 3.014-7.687a18.06 18.06 0 00-.259.828 28.64 28.64 0 00-.736 3.492c-.488 3.21-.59 7.184.776 9.69.223.412.417.84.579 1.28 1.453 3.9.51 8.312.51 8.312z'
        fill='#91E2D5'
      />
      <path
        d='M91.367 82.682s3.3-1.174 3.85-3.977c.08-.27.135-.547.162-.827v-3.892l-6.264-1.502.136 4.29 2.116 5.908z'
        fill='#EDA28E'
      />
      <path
        d='M89.258 76.776l-14.812 6.969 1.694 3.13 15.227-4.194-2.109-5.905z'
        fill='#EDA28E'
      />
      <path
        d='M93.12 39.026v2.152h-2.07a2.08 2.08 0 01-2.076-2.077v-3.666h.556a3.59 3.59 0 013.59 3.591z'
        fill='#B1C0E5'
      />
      <path
        d='M93.12 41.18v-2.153a3.59 3.59 0 00-3.59-3.59h-.556v-15.31a46.3 46.3 0 0122.333 21.052H93.12z'
        fill='#B1C0E5'
      />
      <path
        d='M124.678 18.003v21.5a.396.396 0 01-.398.398H91.051a.799.799 0 01-.799-.799v-21.1a1.336 1.336 0 011.337-1.336h31.753a1.338 1.338 0 011.336 1.337z'
        fill='#fff'
      />
      <path
        d='M110.525 23.231h14.154v16.272a.397.397 0 01-.399.398h-13.755V23.23zM124.678 18.003v1.036H90.252v-1.037a1.338 1.338 0 011.337-1.336h31.753a1.34 1.34 0 011.336 1.337z'
        fill='#C9D2EC'
      />
      <path
        d='M92.376 18.51a.656.656 0 100-1.313.656.656 0 000 1.312zM95.091 17.852a.651.651 0 01-.235.502.654.654 0 01-1.077-.502.649.649 0 01.236-.503.653.653 0 01.923.082c.099.118.153.267.153.421z'
        fill='#fff'
      />
      <path
        d='M94.746 18.111a.297.297 0 01-.051.051l-.26-.26-.05-.05-.26-.26a.328.328 0 01.05-.051l.26.26.051.051.26.26z'
        fill='#AFBDE0'
      />
      <path
        d='M94.746 17.592l-.26.26-.05.05-.26.26a.331.331 0 01-.05-.05l.31-.31.259-.261a.33.33 0 01.05.05zM92.779 17.887h-.806a.313.313 0 010-.072h.806a.338.338 0 010 .072z'
        fill='#AFBDE0'
      />
      <path
        d='M124.678 18.003v21.5a.396.396 0 01-.398.398H91.051a.799.799 0 01-.799-.799v-21.1a1.336 1.336 0 011.337-1.336h31.753a1.338 1.338 0 011.336 1.337v0z'
        stroke='#fff'
        strokeWidth={0.687}
        strokeMiterlimit={10}
      />
      <path
        d='M112.87 39.901s-2.288-7.15-3.525-9.82c-1.88-4.063-.679-13.14-.679-13.14s1.859 1.974 5.119 3.186c3.062 1.138 6.378 5.072 5.227 13.886-.267 2.048-1.41 5.855-1.41 5.855l-4.732.033z'
        fill='#161616'
        opacity={0.3}
      />
      <path
        d='M101.914 5.11h-7.308a2.246 2.246 0 000 4.492h5.737L101.88 11V9.602h.034a2.245 2.245 0 100-4.492z'
        fill='#B6E4DF'
      />
      <path
        d='M95.904 7.782a.394.394 0 100-.789.394.394 0 000 .789zM98.26 7.782a.394.394 0 100-.789.394.394 0 000 .789zM100.616 7.782a.395.395 0 10-.001-.79.395.395 0 00.001.79z'
        fill='#fff'
      />
      <mask
        id='prefix__a'
        maskUnits='userSpaceOnUse'
        x={95}
        y={11}
        width={25}
        height={29}
      >
        <path fill='#C4C4C4' d='M95.999 11.475h23.17v28.443h-23.17z' />
      </mask>
      <g mask='url(#prefix__a)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M97.247 28.698c.673-1.832 2.93-7.895 5.045-8.722l2.262 2.85c-.382 2.388-3.078 11.48-6.526 10.1.331-1.133.01-2.478-.505-3.139-.204.483-.486.796-.797.09-.362-.823.088-1.095.52-1.18z'
          fill='#663A87'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M97.247 28.698c.673-1.832 2.93-7.895 5.045-8.722l2.262 2.85c-.382 2.388-3.078 11.48-6.526 10.1.331-1.133.01-2.478-.505-3.139-.204.483-.486.796-.797.09-.362-.823.088-1.095.52-1.18z'
          fill='#5E9BA1'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M97.247 28.698c.673-1.832 2.93-7.895 5.045-8.722l2.262 2.85c-.382 2.388-3.078 11.48-6.526 10.1.331-1.133.01-2.478-.505-3.139-.204.483-.486.796-.797.09-.362-.823.088-1.095.52-1.18z'
          fill='#F4B85D'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M97.247 28.698c.673-1.832 2.93-7.895 5.045-8.722l2.262 2.85c-.382 2.388-3.078 11.48-6.526 10.1.331-1.133.01-2.478-.505-3.139-.204.483-.486.796-.797.09-.362-.823.088-1.095.52-1.18z'
          fill='#111'
          opacity={0.2}
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M99.997 28.873c-.002-.003-.826-1.237-2.344-.887-.374.086-.595.4-.685.82-.065.352-.063.713.005 1.064.07.395.198.777.38 1.134.387.757 1.014 1.354 1.796 1.34v.03c-.796.014-1.431-.59-1.823-1.356a4.101 4.101 0 01-.383-1.143 2.879 2.879 0 01-.004-1.075c.092-.432.32-.754.707-.843 1.538-.355 2.373.896 2.376.9l-.025.016z'
          fill='#D8892B'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M97.247 28.698c.236.067.46.173.662.314l-.42-.969-.242.655z'
          fill='#050F1B'
          opacity={0.3}
        />
        <path
          d='M111.939 35.563c-2.216 5.024-7.171 17.274-7.804 22.788-.372 3.24-4.924 11.954-4.924 11.954-2.241.081-2.991-1.24-3.013-1.28 0 0 1.429-11.077 2.17-17.18.741-6.103 1.388-14.672 1.388-14.672l12.183-1.61z'
          fill='#4E67E7'
        />
        <path
          d='M111.939 35.563c-2.216 5.024-7.171 17.274-7.804 22.788-.372 3.24-4.924 11.954-4.924 11.954-2.241.081-2.991-1.24-3.013-1.28 0 0 1.429-11.077 2.17-17.18.741-6.103 1.388-14.672 1.388-14.672l12.183-1.61z'
          fill='#111'
          opacity={0.2}
        />
        <path
          d='M111.443 58.591l6.473 9.3a.596.596 0 00.763.192L121 66.898s-3.53-11.095-5.926-15.119c-.78-1.311-.641-2.822-.641-2.822-.178-4.018-.348-9.583-.944-11.783H101.49s6.133 14.877 7.485 17.448c0 0 .594 1.233 2.468 3.97z'
          fill='#4E67E7'
        />
        <path
          d='M113.489 37.174H101.49s10.829.59 12.407 2.063l-.408-2.063z'
          fill='#000'
          opacity={0.1}
        />
        <path
          d='M118.008 67.495s1.287-.492 1.779-.902c0 0-3.852-10.573-5.614-13.483-1.763-2.91-3.246-11.95-3.693-15.71'
          stroke='#3A4FC8'
          strokeWidth={0.451}
          strokeMiterlimit={10}
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M105.41 19.032s-1.438-.102-2.274.362c-.931.506-1.922 1.448-2.257 3.302-.903 5.002-1.083 9.182-1.124 14.478 0 0 .085.783 1.835.783.119 0 .277-.005.473-.013h.028c.223-.008.489-.02.791-.036l.27-.013a431.27 431.27 0 006.349-.425c.025-.002.047-.002.068-.005l.912-.066.109-.008 2.892-.218h.006c0-5.497 1.439-9.754 1.126-15.161-.081-1.43-1.796-2.248-3.306-2.692a14.452 14.452 0 00-1.083-.267c-.39-.084-.785-.146-1.182-.185l-3.634.163'
          fill='#F4B85D'
        />
        <path
          d='M108.665 16.942s-.047.336.041.417a.248.248 0 01-.171-.112s-.052.709.322.844a1.212 1.212 0 01-.735-.219c-.393-.258.048-1.037.048-1.037l.495.107z'
          fill='#081D39'
        />
        <path
          d='M105.356 19.332c-.051.44.376 1.236.376 1.236l2.844-1.699-.25-1.942a3.354 3.354 0 00-1.715.12c-.386.132-.508.83-.98.759l-.275 1.526z'
          fill='#F7CDBE'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M104.752 14.105c-.113.44-.19.886-.229 1.337a7.44 7.44 0 00-.02.755c.012.948.221 1.95.992 2.36 0 0 2.653.175 2.733-1.552 0 0 1.027-.086 1.134-.793.108-.707-.85-.834-1.284-.553a1.36 1.36 0 01-.045-.872s-.906-.245-1.884-.452c-.48-.101-1.064.076-1.397-.23z'
          fill='#F7CDBE'
        />
        <path
          d='M105.496 18.558s.931.358 2.121-.465c0 0-.671 1.022-2.22 1.012l.099-.547z'
          fill='#1E1E1E'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M114.671 23.648c-.044 3.31-.64 6.354-.968 9.621-.719-4.557-2.472-9.336-2.472-9.336l3.44-.285z'
          fill='#111'
          opacity={0.4}
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M117.991 33.776a2.202 2.202 0 00-.883-1.351c-.779-.574-2.7.464-2.7.464l.952 1.85 2.631-.963z'
          fill='#F7CDBE'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M113.977 34.824s-.534 1.085-.639 3.747l3.076-.802s1.91-2.805 1.576-3.992a2.363 2.363 0 00-.654-.376c-.828-.316-1.548-.554-2.37-.197-.238.104-.362-.456-.559-.313l-.43 1.933z'
          fill='#F7CDBE'
        />
        <path
          d='M113.338 38.572l-.545 3.226 2.15.47 2.654-6.613-4.259 2.917z'
          fill='#F7CDBE'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M114.407 32.89l-.119.537c1.33-.984 2.748-.879 3.798.31.076-.314-.131-.678-.2-.908-.202-.16-.201-.204-.441-.291-.843-.307-1.864-.19-2.69.185-.239.108-.151.02-.348.167.011.017-.016-.014 0 0z'
          fill='#1E1E1E'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M118.168 32.614c-.067-5.16-1.991-9.405-3.59-11.24-1.253-1.438-2.849-1.44-3.674.524-.425 1.44 1.357 3.805 3.226 8.239.245.58.491 1.197.735 1.852-.557-.003-.228.36-.228.36s-.388.309-.257.525c.242-.08.492-.135.746-.163.901-.1 1.908.107 2.614.66.223.173.414.385.562.625 0 0 .514-.482-.133-.854 0 0 .395-.274.122-.46a1.078 1.078 0 00-.122-.068h-.001zm-2.454-.447h.007l-.038.003.026-.002h.005z'
          fill='#F4B85D'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M114.706 31.567l.158.42a3.11 3.11 0 011.317.098s-.626-.674-1.475-.518z'
          fill='#050F1B'
          opacity={0.3}
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M111.681 20.789c-.004.002-1.521.955-.181 3.71l.434.891c1.211 2.483 2.405 4.932 2.874 6.38l.01.03h-.032c-.002 0-.709.011-.202.432l.025.02-.027.017a.458.458 0 00-.168.569c.276-.08 3.389-.92 3.91 1.151l-.045.012c-.533-2.117-3.862-1.115-3.871-1.113l-.02.006-.008-.019a.497.497 0 01.152-.627c-.494-.424.077-.484.221-.493-.474-1.448-1.659-3.88-2.862-6.345l-.434-.892c-1.358-2.792.194-3.766.198-3.769l.026.04z'
          fill='#D8892B'
        />
        <path
          d='M109.044 16.103s-.051-.27-.299-.182c.058.111.096.232.112.356a.331.331 0 01-.127.29.328.328 0 01-.314.048c-.234-.073-.101-.477-.039-.593.127-.24.62-.358.667.081z'
          fill='#F0C4C3'
          opacity={0.3}
        />
        <path
          d='M108.335 16.992a.544.544 0 01-.112-.009c.029.041.072.07.121.08l-.009-.071zM107.705 16.324s-.745-.385-.884-.799a.984.984 0 01-.522.148s.444-.215.435-.467a.285.285 0 00-.272-.28c-.047-.01-.786 1.235-1.956 1.125l.873-.426.518-1.077h-.164s-.371.49-.69.643c-.318.153-.508.24-.538.82l-.139-.756s.911-1.606 2.045-1.33c1.135.277 1.294 2.4 1.294 2.4z'
          fill='#F0C4C3'
        />
        <path
          d='M103.875 14.656c.15.378.466 1.064.578 1.38l-.035.018.042-.001c.017.047.032.095.044.143l.002-.146c1.216-.052 1.905-1.152 1.905-1.152l.27.212c-.01.197-.17.381-.382.562.273.013.56-.351.56-.351.103.568.847 1.003.847 1.003.578-1.254 1.452-.642 1.452-.642.352-1.237.463-1.664-.071-2.16-.535-.496-1.224-.207-1.563-.52-.339-.313.195-1.007.195-1.007-.427.107-.612.85-.612.85-.404.013-.495-.9-.495-.9-.122.273.1.866.116.91-.038-.052-.678-.907-1.575-.857-.924.052-.924 1.33-.924 1.33-.614.091-.549.833-.354 1.329zm.629 1.354a1.98 1.98 0 01.026-.486.389.389 0 01.248-.256c.441-.138.954-.72.954-.72-.049.764-.935 1.304-1.228 1.462z'
          fill='#081D39'
        />
        <path
          d='M108.148 20.567a5.405 5.405 0 00-2.416 0l1.027-.52 1.299.191.09.33z'
          fill='#050F1B'
          opacity={0.3}
        />
        <path
          d='M105.732 20.567s1.475-.97 2.416 0l.803-1.699-.329-.463a.102.102 0 00-.133-.032c-.597.334-1.605 1.176-2.757 2.194z'
          fill='#fff'
        />
        <path
          d='M105.732 20.568s-1.035-.732-1.76-.336l.334-.282.703-.066.654.477.069.207z'
          fill='#050F1B'
          opacity={0.3}
        />
        <path
          d='M105.732 20.567s-.895-1.162-1.76-.337l1.303-1.463.457 1.8z'
          fill='#fff'
        />
        <path d='M105.274 18.767l.192-.05-.083.459-.109-.409z' fill='#E59E41' />
      </g>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M98.624 29.97c-2.246-1.39-5.423-1.201-9.974-2.43l-.751 1.576s5.1 3.157 8.75 4.075c1.027.213 1.9-.337 2.37-1.582.342-.904-.083-1.407-.395-1.639zM87.898 29.117l.751-1.576s-1.252-2.203-1.908-2.145c-.656.057.306.945.452 1.293.146.347-.954-.29-1.784-.444-.83-.155-.426 2.155 2.489 2.872'
        fill='#F7CDBE'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M96.646 33.19c-2.585-.65-6.883-2.904-8.748-4.074.204.042 1.027.46 1.317.587.786.351 4.146 2.056 6.93 2.712 2.365.557 2.557-1.415 2.508-2.424.308.242.697.741.365 1.62-.47 1.246-1.344 1.795-2.372 1.58z'
        fill='#1E1E1E'
        opacity={0.6}
      />
      <path
        d='M67.384 87.064h-29.73a.566.566 0 01-.557-.467l-3.401-19.233a1.077 1.077 0 011.06-1.264h29.382a.566.566 0 01.556.457l3.747 19.224a1.075 1.075 0 01-1.057 1.283z'
        fill='#AFBDE0'
      />
      <path
        d='M66.545 87.064H38.147a.566.566 0 01-.557-.467l-3.4-19.233a1.078 1.078 0 011.06-1.264h28.362a1.805 1.805 0 011.77 1.458l3.384 17.358a1.804 1.804 0 01-1.77 2.148h-.45z'
        fill='#C9D2EC'
      />
      <path
        d='M53.784 80.474h-6.72l-1.773 8.641h6.5l1.993-8.641z'
        fill='#AFBDE0'
      />
      <path d='M42.558 91.183h15.658v-2.068H42.558v2.068z' fill='#C9D2EC' />
      <path d='M53.785 80.474l.094 6.59h-1.615l1.52-6.59z' fill='#AFBDE0' />
      <path d='M53.785 80.474l.094 6.59h-1.615l1.52-6.59z' fill='#91A9D8' />
      <path
        d='M34.172 74.8h-9.44V31.92M42.96 15.67h20.174v14.983h24.764M114.491 41.18v11.766h-18.44v6.92'
        stroke='#91A9D8'
        strokeWidth={1.675}
        strokeMiterlimit={10}
      />
      <path
        d='M128.073 90.935H1.927a.942.942 0 100 1.884h126.146a.942.942 0 000-1.884z'
        fill='#BDB0FF'
      />
      <path d='M72.266 89.407H60.154v1.528h12.111v-1.528z' fill='#AFBDE0' />
      <path
        d='M66.83 88.298h-1.24v1.11h1.24v-1.11zM68.863 88.298h-1.24v1.11h1.24v-1.11zM70.896 88.298h-1.24v1.11h1.24v-1.11zM64.963 88.298h-1.24v1.11h1.24v-1.11zM62.523 88.298h-1.24v1.11h1.24v-1.11z'
        fill='#91A9D8'
      />
      <path
        d='M63.134 25.395a4.156 4.156 0 100-8.311 4.156 4.156 0 000 8.311z'
        fill='#fff'
      />
      <path
        d='M64.859 22.856l-.452.452a.371.371 0 01-.415.1 4.826 4.826 0 01-2.945-2.941.367.367 0 01.1-.413l.013-.012.46-.464a.06.06 0 01.085 0l.723.724a.06.06 0 010 .085l-.32.32a.163.163 0 00-.026.195 3.88 3.88 0 001.473 1.473.164.164 0 00.196-.026l.3-.3a.06.06 0 01.084 0l.724.724a.06.06 0 010 .084v-.001zM64.172 21.183a.174.174 0 01-.172-.154.842.842 0 00-.737-.736.174.174 0 11.041-.346 1.187 1.187 0 011.041 1.04.174.174 0 01-.173.196z'
        fill='#71ABFF'
      />
      <path
        d='M65.074 21.198a.174.174 0 01-.174-.167 1.717 1.717 0 00-1.64-1.639.173.173 0 01-.121-.053.174.174 0 01.136-.295 2.068 2.068 0 011.973 1.973.174.174 0 01-.168.181h-.006z'
        fill='#71ABFF'
      />
      <path
        d='M24.732 56.405a4.156 4.156 0 100-8.311 4.156 4.156 0 000 8.31z'
        fill='#fff'
      />
      <path
        d='M26.456 53.867l-.452.452a.37.37 0 01-.416.1 4.827 4.827 0 01-2.943-2.941.367.367 0 01.1-.413l.012-.012.46-.463a.06.06 0 01.085 0l.724.724a.06.06 0 010 .085l-.32.32a.162.162 0 00-.026.195 3.88 3.88 0 001.473 1.473.165.165 0 00.196-.027l.3-.3a.058.058 0 01.042-.017c.008 0 .015.001.023.005a.06.06 0 01.019.012l.724.724a.06.06 0 01.013.066.06.06 0 01-.013.019v-.002zM25.77 52.193a.174.174 0 01-.172-.153.84.84 0 00-.737-.737.174.174 0 11.041-.346 1.187 1.187 0 011.041 1.041.174.174 0 01-.173.195z'
        fill='#71ABFF'
      />
      <path
        d='M26.671 52.209a.174.174 0 01-.174-.167 1.718 1.718 0 00-1.639-1.64.175.175 0 01-.166-.177.173.173 0 01.174-.17h.006a2.068 2.068 0 011.973 1.973.174.174 0 01-.167.18l-.007.001z'
        fill='#71ABFF'
      />
      <path
        d='M113.767 56.664a4.155 4.155 0 100-8.31 4.155 4.155 0 000 8.31z'
        fill='#fff'
      />
      <path
        d='M115.492 54.125l-.452.452a.372.372 0 01-.416.1 4.821 4.821 0 01-2.944-2.941.368.368 0 01.101-.413l.012-.012.46-.463a.059.059 0 01.042-.018c.016 0 .031.006.043.018l.724.724a.06.06 0 01.017.042.06.06 0 01-.017.043l-.32.32a.165.165 0 00-.027.195 3.88 3.88 0 001.474 1.473.163.163 0 00.195-.027l.3-.3a.059.059 0 01.084 0l.724.724a.057.057 0 01.018.043.057.057 0 01-.018.042v-.002zM114.806 52.45a.175.175 0 01-.173-.153.843.843 0 00-.736-.736.177.177 0 01-.117-.066.174.174 0 01.158-.28 1.188 1.188 0 011.041 1.04.172.172 0 01-.006.069.172.172 0 01-.167.127z'
        fill='#71ABFF'
      />
      <path
        d='M115.707 52.467a.176.176 0 01-.121-.049.176.176 0 01-.053-.118 1.718 1.718 0 00-1.639-1.64.174.174 0 01.007-.347h.007a2.066 2.066 0 011.973 1.973.175.175 0 01-.167.18h-.007zM66.373 37.318H54.09a3.774 3.774 0 100 7.55h9.643l2.582 2.35v-2.35h.058a3.774 3.774 0 000-7.55z'
        fill='#71ABFF'
      />
      <path
        d='M56.272 41.81a.663.663 0 100-1.325.663.663 0 000 1.326zM60.231 41.81a.663.663 0 100-1.326.663.663 0 000 1.326zM64.19 41.81a.663.663 0 100-1.326.663.663 0 000 1.326z'
        fill='#fff'
      />
      <path
        d='M11.615 84.928S6.737 77.38 9.99 76.622c3.252-.759 5.081 6.789 5.353 8.306h-3.727z'
        fill='#C2DCCC'
      />
      <path
        d='M19.068 84.928s4.878-7.548 1.626-8.306c-3.252-.759-5.081 6.789-5.353 8.306h3.727z'
        fill='#8EACA8'
      />
      <path
        d='M13.21 84.928s-1.465-10.545 1.59-10.545c3.056 0 2.674 9.723 2.674 10.545H13.21z'
        fill='#D9E7DE'
      />
      <path
        d='M19.106 90.95h-7.694l-1.215-5.96H20.32l-1.213 5.96z'
        fill='#C9D2EC'
      />
      <path
        d='M17.684 90.95h1.422l1.193-5.96h-1.402l-1.213 5.96z'
        fill='#AFBDE0'
      />
      <mask
        id='prefix__b'
        maskUnits='userSpaceOnUse'
        x={14}
        y={6}
        width={25}
        height={25}
      >
        <path d='M14.186 6.041h24.448V30.01H14.186V6.04z' fill='#C4C4C4' />
      </mask>
      <g mask='url(#prefix__b)'>
        <path
          d='M34.043 16.535s.035-1.839-.716-2.44c-.75-.601-1.426-.851-1.452-2.378-.025-1.527-1.303-1.402-1.553-2.804s-.677-1.972-1.756-1.944c0 0-1.035-.649-2.1 0-1.063.648-1.265 1.712-1.38 2.315-.115.604-.848 1.079-1.768 1.352a4.177 4.177 0 00-2.46 2.114c-.502 1.064-.934 1.797-1.94 2.3-1.007.505-3.552 2.071-2.33 4.962a3.452 3.452 0 01.72-3.466s-2.332 4.613 3.026 6.71l13.71-4.913v-1.808z'
          fill='#1F3684'
        />
        <path
          d='M32.239 14.517s-1.21-.767-1.485-1.694c-.274-.927.096-1.95-.68-2.59-.485-.285-.024-1.145-.166-1.677-.142-.532-.478-.949-1.185-.534-.707.416-1.227 3.253-2.031 2.532-.804-.721-.643-.96-.996-.735-.352.225-.329 1.09-.128 1.798.201.71-.686.982-1.714 1.207-1.029.224-2.546 1.619-2.84 4.675-.295 3.057 11.225-2.982 11.225-2.982z'
          fill='url(#prefix__paint0_linear)'
          style={{
            mixBlendMode: 'overlay'
          }}
        />
        <path
          d='M36.352 30.1c.08.626.22 1.244.417 1.845.355 1.151-.33 2.522-.68 2.751-.349.23-.107-.186-.107-.186s-.234.353-.33.263c-.096-.091.056-.31.056-.31s-.2.363-.323.302c-.122-.06.006-.294.006-.294s-.145.229-.244.081c-.1-.147-.12-.412-.048-.818.073-.407.146-.636.042-.715-.104-.08-.192.53-.237.757-.044.226-.177.373-.27.297-.093-.076-.035-.74-.022-1.226.014-.486.123-1.026.125-1.515.008-.357.039-.713.09-1.066a1.28 1.28 0 011.525-.166z'
          fill='#DFCEAE'
        />
        <path
          d='M31.872 14.238s2.98 2.825 4.275 9.684c.928 4.915.428 7.209.428 7.209s-1.594-.728-2.204.552l-3.465-14.62.966-2.825z'
          fill='#4686EF'
        />
        <path
          style={{
            mixBlendMode: 'overlay'
          }}
          opacity={0.1}
          d='M31.872 14.238s2.98 2.825 4.275 9.684c.928 4.915.428 7.209.428 7.209s-1.594-.728-2.204.552l-3.465-14.62.966-2.825z'
          fill='url(#prefix__paint1_linear)'
        />
        <path
          d='M29.77 13.4c.03.01.06.017.092.022l-.092-.023z'
          stroke='#E934F6'
          strokeWidth={0.288}
          strokeMiterlimit={10}
        />
        <path
          d='M20.025 25.71s-.81 8.606-.81 9.71c0 1.103 9.735.373 9.735.373s-1.65-9.047-3.144-15.22c-.864-4.273-.59-6.213-.59-6.213s.071-.796.44-1.164c-.322.052-2.318.106-4.01 1.343-1.206.88-2.257 2.36-2.44 4.866-.234 3.17.819 6.304.819 6.304z'
          fill='#4686EF'
        />
        <path
          d='M25.805 20.572c1.495 6.174 3.145 15.22 3.145 15.22l2.516-.478s-.333-10.692-.296-13.834c.054-4.608-.848-7.105-.848-7.105s-1.057.58-2.724.476c-1.667-.104-2.382-.492-2.382-.492s-.275 1.94.59 6.213z'
          fill='#fff'
        />
        <path
          d='M30.322 14.374s.903 2.498.849 7.106c-.038 3.142.295 13.834.295 13.834l3.124-.594s-.815-12.16-1.551-15.694c0 0-.644-3.823-1.168-4.79a13.25 13.25 0 00-2.01-.814c.177.306.33.624.461.952z'
          fill='#4686EF'
        />
        <path
          d='M25.216 14.36s.715.387 2.382.492c1.667.104 2.724-.477 2.724-.477a7.21 7.21 0 00-.462-.952.616.616 0 01-.091-.023 2.41 2.41 0 01-.757-.44.958.958 0 01-.318-.434.891.891 0 01-.016-.532l-.004.001-.11.016h-.007c-.22.03-.443.036-.665.016-.81-.081-1.105-.563-1.349-.958.3 1.806-.887 2.129-.887 2.129-.369.366-.44 1.162-.44 1.162z'
          fill='#EDA28E'
        />
        <path
          d='M26.543 11.068c.31.502.703 1.144 2.135.926 1.431-.218.946-2.232.946-3.081 0-.85-.295-.963-.295-.963-.257-.165-.583.058-.583.058-.621-.562-1.006.325-1.067.917-.06.591-.382.827-.619 1.02-.236.191-.236.103-.458-.222-.222-.326-.916-.355-.946.384-.03.74.887.96.887.96z'
          fill='#EDA28E'
        />
        <path
          d='M20.025 25.709l2.33 6.876c.058-.163 1.08-1.293 1.814-1.673.98-.507 1.275-.183 1.37-.133-3.18-4.681-2.639-7.508-2.421-9.442.357-3.181 1.004-4.276 1.004-4.276s.941-3.429-2.476-2.523c-1.205.88-2.256 2.36-2.44 4.866-.234 3.17.819 6.305.819 6.305z'
          fill='#4686EF'
        />
        <path
          d='M25.538 30.779c-3.18-4.68-2.638-7.507-2.421-9.442.357-3.181 1.004-4.276 1.004-4.276'
          stroke='#707070'
          strokeWidth={0.576}
          strokeMiterlimit={10}
        />
        <path
          opacity={0.3}
          d='M20.025 25.709l2.33 6.876c.058-.163 1.08-1.293 1.814-1.673.98-.507 1.275-.182 1.37-.133-3.18-4.68-2.639-7.508-2.421-9.442.357-3.181 1.004-4.276 1.004-4.276s.941-3.429-2.476-2.523c-1.205.88-2.256 2.36-2.44 4.866-.234 3.17.819 6.305.819 6.305z'
          fill='#3B74ED'
        />
        <path
          d='M27.68 11.734c.06.105.131.203.212.292.21.246.49.421.802.5a.893.893 0 01-.016-.531h-.004a.997.997 0 01-.11.017h-.007a1.115 1.115 0 01-.877-.278z'
          fill='#050F1B'
          opacity={0.6}
        />
      </g>
      <path
        d='M12.509 29.872l12.863.217 13.151-.217'
        stroke='#fff'
        strokeMiterlimit={10}
      />
      <defs>
        <linearGradient
          id='prefix__paint0_linear'
          x1={26.623}
          y1={7.854}
          x2={26.623}
          y2={18.363}
          gradientUnits='userSpaceOnUse'
        >
          <stop offset={0.082} stopColor='#021C76' />
          <stop offset={0.527} stopColor='#1F3684' />
        </linearGradient>
        <linearGradient
          id='prefix__paint1_linear'
          x1={-372.028}
          y1={22.263}
          x2={-368.322}
          y2={22.374}
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#fff' stopOpacity={0} />
          <stop offset={1} />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default SvgComponent
