import { all, fork, put } from 'redux-saga/effects'
import Service from '~/lib/api/services/announcementModel'
import { Actions } from '@Redux/announcementModel'
import { toggleModal } from 'Actions/modals'
import { ADD_ANNOUNCEMENT_MODAL } from 'Const'
import SagasUtil from '~/redux/sagas/util'

export const base = {
  baseType: 'announcementModel',
  actions: Actions,
  service: Service
}

export const condominium = localStorage.getItem('currentCondominium')

export const search = {
  beforeRequest: function ({ page = 1, search = '' }) {
    const condominiumId = condominium
    return [condominiumId, page, search]
  },
  request: 'search',
  afterRequest: ({
    data
    // paging: { pageNumber, recordsPerPage, totalRecords }
  }) => ({
    list: data
    // metadata: {
    //   page: pageNumber,
    //   limit: recordsPerPage,
    //   count: totalRecords
    // }
  })
}

export const create = {
  beforeRequest: function (payload) {
    return [condominium, payload]
  },
  request: 'create',
  afterRequest: function* () {
    // addToast('Modelo criado com sucesso', { appearance: 'success' })
    yield put(toggleModal(ADD_ANNOUNCEMENT_MODAL, false))
    yield put(Actions.announcementModelSearch(condominium))
  }
}

export const update = {
  beforeRequest: function ({ id, ...payload }) {
    return [condominium, id, payload]
  },
  request: 'update',
  afterRequest: function* () {
    // addToast('Modelo alterado com sucesso', { appearance: 'success' })
    yield put(toggleModal(ADD_ANNOUNCEMENT_MODAL, false))
    yield put(Actions.announcementModelSearch(condominium))
  }
}

export default function* root() {
  const sagas = SagasUtil(base, {
    create,
    update,
    search,
    searchStatus: search
  })
  yield all(sagas.map((saga) => fork(...saga)))
}
