import { createActions, createReducer } from 'reduxsauce'

import Util from '../util'

const { action, request, savePayload } = new Util('announcementModel')

const INITIAL_STATE = {
  list: [],
  search: '',
  pending: {},
  metadata: {
    limit: 10,
    count: 1,
    page: 1
  }
}

export const { Types, Creators: Actions } = createActions({
  ...action('search', ['', 'success', 'error', 'pending']),
  ...action('create', ['', 'success', 'error', 'pending']),
  ...action('update', ['', 'success', 'error', 'pending'])
})

export const HANDLERS = {
  [Types.ANNOUNCEMENT_MODEL_SEARCH]: savePayload,
  ...request('search'),
  ...request('create'),
  ...request('update')
}

export const Reducer = createReducer(INITIAL_STATE, HANDLERS)
