import React from 'react'
import { AccordionSummary as MuiAccordionSummary } from '@mui/material'
import styled from 'styled-components'

import { ExpandMore } from '@mui/icons-material'
import { theme } from '../../styles'

const StyledAccordionSummary = styled(MuiAccordionSummary)`
  .MuiAccordionSummary-content {
    margin: ${theme.spacing(3)} 0;
  }
`

export const AccordionSummary = ({
  expandIcon = <ExpandMore size='small' />,
  ...props
}) => <StyledAccordionSummary expandIcon={expandIcon} {...props} />
