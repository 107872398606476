import React, { useCallback, useEffect } from 'react'
import { FormRow, Form, TextField, MenuItem, Icon } from '@condofy/components'
import { Modal, ActionRow, ModalContent } from '@condofy/next/components'
import { useUserContext } from '@condofy/next/store/user'
import { useSnackbar, handleError } from '@condofy/next/hooks/useSnackbar'

const emptyModel = {
  id: 'empty',
  title: '',
  subject: '',
  content: ''
}

export const ChooseModelModal = ({
  open,
  onStepChange: emitStepChangeEvent = () => {}
}) => {
  const { enqueueSnackbar } = useSnackbar()

  const {
    state: {
      currentCondominium,
      isAdmin,
      isSuperAdmin,
      announcement: { selected },
      announcementModel: { items }
    },
    actions: {
      closeCreateAnnouncementModal,
      listAnnouncementModel,
      updateSelectedAnnouncement
    }
  } = useUserContext()
  const stableListAnnouncementModel = useCallback(listAnnouncementModel, [])

  const admnistratorUser = isAdmin || isSuperAdmin

  const nextStep = () => emitStepChangeEvent(2)

  useEffect(() => {
    if (currentCondominium.id && admnistratorUser)
      stableListAnnouncementModel({ currentCondominium }).catch(
        handleError(enqueueSnackbar)
      )
  }, [
    currentCondominium,
    admnistratorUser,
    stableListAnnouncementModel,
    enqueueSnackbar
  ])

  const handleModelChange = (event) => {
    const { value: modelId } = event.target
    const model = items.find((model) => modelId === model.id) || emptyModel
    updateSelectedAnnouncement(model)
  }

  return (
    <Modal
      icon={<Icon icon='announcements' />}
      title='Novo comunicado'
      open={open}
      onClose={closeCreateAnnouncementModal}
      sx = {{
        '.MuiDialogTitle-root>div' : {
          lineHeight: 1
        }
      }}
      footer={
        <ActionRow
          confirmText='Próximo'
          cancelText={false}
          onSubmit={nextStep}
        />
      }
    >
      <ModalContent>
        <Form>
          <FormRow>
            <TextField
              select
              label='Modelos'
              value={selected.id}
              onChange={handleModelChange}
            >
              <MenuItem value='empty'>Sem Modelo</MenuItem>
              {items.map((announcementModel) => (
                <MenuItem
                  key={announcementModel.id}
                  value={announcementModel.id}
                >
                  {announcementModel.title}
                </MenuItem>
              ))}
            </TextField>
          </FormRow>
        </Form>
      </ModalContent>
    </Modal>
  )
}
