import { RecordVoiceOver } from '@mui/icons-material'
import {
  TextFieldCounter,
  Button,
  QuillCounter,
  Form,
  FormRow
} from '@condofy/components'
import {
  Modal,
  ActionRow,
  ModalContent,
  FilePond
} from '@condofy/next/components'
import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { colors, Body, FlexRowCentered as Flex } from '@condofy/components'
import CloudDownloadRoundedIcon from '@mui/icons-material/CloudDownloadRounded'
import { useUserContext } from '@condofy/next/store/user'
import { useSnackbar, handleError } from '@condofy/next/hooks/useSnackbar'

const FlexRowCentered = styled(Flex)`
  margin: 20px 8px 5px;
`

const TitleFileCall = styled(Body)`
  margin-left: 5px;
  line-height: 24px;
  color: ${colors.dark300};
`

export const UpdateMessageModal = ({
  open,
  onStepChange: emitStepChangeEvent = () => {}
}) => {
  const { enqueueSnackbar } = useSnackbar()
  const {
    state: {
      currentCondominium,
      announcement: { selected }
    },
    actions: {
      listAnnouncement,
      closeEditAnnouncementModal: close,
      updateSelectedAnnouncement,
      editAnnouncement
    }
  } = useUserContext()

  const stableUpdateSelectedAnnouncement = useCallback(
    updateSelectedAnnouncement,
    []
  )

  const nextStep = () => emitStepChangeEvent(2)
  const [slipFile, setSlipFile] = useState([])

  const handleModelChange = (event) => {
    const { name, value } = event.target
    stableUpdateSelectedAnnouncement({ [name]: value })
  }
  const handleContentChange = (value) => {
    stableUpdateSelectedAnnouncement({ content: value })
  }

  useEffect(() => {
    stableUpdateSelectedAnnouncement({
      files: slipFile
    })
  }, [slipFile, stableUpdateSelectedAnnouncement])

  const handleSave = () => {
    editAnnouncement({
      currentCondominium,
      payload: {
        ...selected,
        condominiumId: currentCondominium.id,
        Status: 'A'
      }
    })
      .then(() => {
        enqueueSnackbar('Comunicado salvo como rascunho', {
          variant: 'success'
        })
        close()
        listAnnouncement({ currentCondominium })
      })
      .catch(handleError(enqueueSnackbar))
  }

  return (
    <Modal
      icon={<RecordVoiceOver />}
      title='Editar comunicado'
      open={open}
      onClose={close}
      footer={
        <ActionRow
          confirmText='Publicar'
          cancelText='Cancelar'
          onSubmit={nextStep}
          onCancel={close}
        >
          <Button onClick={handleSave}>Salvar</Button>
        </ActionRow>
      }
    >
      <ModalContent>
        <Form>
          <FormRow>
            <TextFieldCounter
              required
              label='Titulo'
              name='title'
              value={selected.title || ''}
              onChange={handleModelChange}
              maxLength={50}
            />
          </FormRow>
          <FormRow>
            <TextFieldCounter
              required
              label='Assunto'
              name='subject'
              value={selected.subject || ''}
              onChange={handleModelChange}
              maxLength={50}
            />
          </FormRow>
          <FormRow>
            <QuillCounter
              required
              name='content'
              onChange={handleContentChange}
              defaultValue={selected.content}
              maxLength={2500}
            />
          </FormRow>
          {selected.attachmentUrl && selected.attachmentUrl.length ? (
            <FormRow>
              <FlexRowCentered
                as='a'
                href={selected.attachmentUrl[0].url}
                target='_blank'
                rel='noopener noreferrer'
              >
                <CloudDownloadRoundedIcon />
                <TitleFileCall>Baixar Anexo</TitleFileCall>
              </FlexRowCentered>
            </FormRow>
          ) : (
            <span />
          )}
          <FormRow>
            <FilePond maxTotalFileSize='20MB' setFile={setSlipFile} />
          </FormRow>
        </Form>
      </ModalContent>
    </Modal>
  )
}
