import React from 'react'
import { LightTooltip } from './LightTooltip'
import PropTypes from 'prop-types'
import { ClickAwayListener } from '@mui/material'
// import { withStyles } from 'tss-react/mui';
import { IconButton } from '../Button'
import { Icon } from '../Icon'
import { colors } from '../../styles'

/* const StyledIcon = withStyles(() => ({
  root: {
    color: colors.dark400
  }
}))(Icon) */

const iconStyles = {
  '&': {
    color: colors.dark400
  }
}

export const InfoTooltip = ({
  width = 'auto',
  icon = 'info_outined',
  ...props
}) => {
  const [open, setOpen] = React.useState(false)
  const handleTooltipClose = () => setOpen(false)
  const handleTooltipOpen = () => setOpen(true)

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div>
        <LightTooltip
          arrow
          open={open}
          style={{ width }}
          PopperProps={{
            disablePortal: true
          }}
          onClose={handleTooltipClose}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          {...props}
        >
          <IconButton onClick={handleTooltipOpen}>
            <Icon icon={icon} styles = {iconStyles}/>
          </IconButton>
        </LightTooltip>
      </div>
    </ClickAwayListener>
  )
}
InfoTooltip.propTypes = {
  width: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string
}
