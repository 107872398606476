import React, { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useSnackbar, handleError } from '@condofy/next/hooks/useSnackbar'
import { yupResolver } from '@hookform/resolvers/yup'
import { useCalendarContext } from '@condofy/next/store/calendar'
import { CalendarEventForm } from './CalendarEventForm'
import { useUserContext } from '@condofy/next/store/user'
import { ActionRow, ProcessDialog } from '@condofy/next/components'
import { calendarEventSchema } from './calendarEventSchema'
import { CalendarEventList } from './CalendarEventList'
import { track } from '@condofy/next/service/segment'

export const CreateCalendarEventModal = () => {
  const { enqueueSnackbar } = useSnackbar()
  const {
    state: {
      create: {
        modal: { open, data },
        metadata: { loading },
        error
      }
    },
    actions: { closeCreateEventModal, createEvent, listEvents }
  } = useCalendarContext()

  const {
    state: {
      isSuperAdmin,
      isAdmin,
      currentCondominium: { id: condominiumId, name: condominiumName }
    }
  } = useUserContext()

  const isAdministrator = isSuperAdmin || isAdmin

  const form = useForm({
    mode: 'onChange',
    resolver: yupResolver(calendarEventSchema),
    defaultValues: {
      day: new Date(),
      start: new Date(),
      end: new Date(),
      summary: '',
      description: '',
      visibility: '',
      reminderMinutes: '',
      recurrence: ''
    }
  })
  const { setValue } = form

  useEffect(() => {
    const start = data?.selectedStart
    const end = data?.selectedEnd

    if (start) {
      setValue('day', start)
    }
    if (end) {
      setValue('start', start)
      setValue('end', end)
    }
  }, [data, setValue])

  useEffect(() => {
    if (error) {
      handleError(enqueueSnackbar)('Ocorreu um problema ao criar o evento')
    }
  }, [error])

  const handleClose = () => {
    form.reset()
    closeCreateEventModal()
  }

  const handleSubmit = () => {
    form.handleSubmit((values) => {
      const body = {
        ...values,
        start: CalendarEventList.mergeDates(values.day, values.start),
        end: CalendarEventList.mergeDates(values.day, values.end)
      }
      createEvent({
        condominiumId,
        body
      }).then(() => {
        handleClose()
        listEvents({ condominiumId })
        track(window.analytics, 'Calendar Event Created', {
          condominium_id: condominiumId,
          condominium_name: condominiumName,
          ...body
        })
      })
    })()
  }

  return (
    <ProcessDialog
      open={open}
      onClose={handleClose}
      title='Novo Evento'
      actions={
        isAdministrator && (
          <ActionRow
            loading={loading}
            confirmText='Salvar'
            cancelText='Cancelar'
            onCancel={handleClose}
            onSubmit={handleSubmit}
          />
        )
      }
    >
      <FormProvider {...form}>
        <CalendarEventForm isCreate />
      </FormProvider>
    </ProcessDialog>
  )
}
