import { toCamelLower } from '~/lib/util/transformString'
export const errorParser = (errors) =>
  errors
    ? errors.reduce(
        (acc, { text, Message, message, field, Field = 'global' }) => {
          const _field = toCamelLower(field || Field)
          if (_field === 'global') {
            acc.global.push(Message || message || text)
          } else {
            acc[_field] = Message || message || text
          }

          return acc
        },
        { global: [] }
      )
    : {}

export default {}
