import React, { useEffect, useState } from 'react'
import { Page, Body, theme, CircularProgress, Icon } from '@condofy/components'
import { CondofyLogo } from '@condofy/next/components'
import styled from 'styled-components'
import { useQuery } from '@condofy/next/hooks'
import { createConsent } from '@condofy/next/service/bs2'

const StyledPage = styled(Page)`
  align-items: center !important;
  max-width: 600px !important;
  margin: 0 auto;
  text-align: center !important;
`

const Text = styled(Body)`
  margin-top: ${theme.spacing(4)} !important;
`

export const BS2ConsentFinishedPage = () => {
  let [loading, setLoading] = useState(true)
  let [hasError, setHasError] = useState(false)
  let condominiumId = useQuery().get('state')
  let authorizationCode = useQuery().get('code')

  useEffect(() => {
    setLoading(true)
    createConsent({ condominium: { id: condominiumId }, authorizationCode })
      .then(() => setHasError(false))
      .catch(() => setHasError(true))
      .finally(() => setLoading(false))
  }, [authorizationCode, condominiumId])

  return (
    <StyledPage>
      <CondofyLogo />
      {loading && (
        <>
          <Text>
            <CircularProgress /> <br />O processo de integração está em curso.
          </Text>
        </>
      )}
      {!loading && hasError && (
        <Text color='error'>
          <Icon icon='warning_outlined' />
          &nbsp; Ocorreu um erro inesperado. Entre em contato com o suporte
          técnico.
        </Text>
      )}
      {!loading && !hasError && (
        <Text>
          O processo de integração está finalizado. Pode fechar a janela.
        </Text>
      )}
    </StyledPage>
  )
}
