import React from 'react'
import styled from 'styled-components'
import { CircularProgress, Button as MuiButton } from '@mui/material'
import PropTypes from 'prop-types'
import { colors } from '../../styles/colors'
import DownloadIcon from '@mui/icons-material/Download'

const StyledButton = styled(MuiButton)`
  &.MuiButton-containedPrimary {
    background-color: ${colors.primary};
    border: solid 1px ${colors.primary};
    color: ${colors.light100};

    &.hover,
    &:hover {
      background-color: ${colors.brandBlue800};
    }
    &.focus,
    &:focus {
      background-color: ${colors.primary};
    }
    &:disabled {
      background-color: ${colors.brandBlue100};
      border-color: ${colors.brandBlue100};
      color: ${colors.light100};
    }
  }
  &.MuiButton-containedSecondary {
    background: linear-gradient(180deg, #ffffff 0%, #f4f5f7 100%);
    border: solid 1px #dfe1e6;

    &.hover,
    &:hover {
      background-color: ${colors.light200};
      border-color: ${colors.light600};
    }
    &.focus,
    &:focus {
      background-color: ${colors.light200};
      border-color: ${colors.primary};
    }
    &:disabled {
      background-color: ${colors.light200};
      color: ${colors.light500};
    }
  }
  &.MuiButton-text {
    color: ${colors.darkBlue700};
    border: solid 2px transparent;

    &.hover,
    &:hover {
      background-color: ${colors.light200};
    }
    &.focus,
    &:focus {
      border-color: ${colors.darkBlue500};
    }
    &.active,
    &:active {
      background-color: ${colors.primary};
    }
    &:disabled {
      color: ${colors.light600};
    }
  }
`
const styles = {
  danger: {
    '&.MuiButton-contained': {
      backgroundColor: colors.danger600,
      color: colors.light100,
      border: `solid 1px ${colors.danger600}`,
      '&:hover': {
        backgroundColor: colors.danger700,
        borderColor: colors.danger700
      },
      '&:focus': {
        backgroundColor: colors.danger600,
        borderColor: colors.danger700
      },
      '&:disabled': {
        backgroundColor: colors.danger100,
        borderColor: colors.danger200,
        color: colors.light100
      }
    },
    '&.MuiButton-text': {
      color: `${colors.danger700} !important`
    }
  },
  green: {
    '&.MuiButton-contained': {
      backgroundColor: colors.green600,
      color: colors.light100,
      border: `solid 1px ${colors.green600}`,
      '&:hover': {
        backgroundColor: colors.green700,
        borderColor: colors.green700
      },
      '&:focus': {
        backgroundColor: colors.green600,
        borderColor: colors.green700
      },
      '&:disabled': {
        backgroundColor: colors.green100,
        borderColor: colors.green200,
        color: colors.light100
      }
    },
    '&.MuiButton-text': {
      color: `${colors.danger700} !important`
    }
  },
  light: {
    '&.MuiButton-contained': {
      backgroundColor: colors.light200,
      BorderRadius: '3px',
      color: colors.brandBlue600,
      fontWeight: 500,
      minWidth: 0,
      '&:hover': {
        backgroundColor: colors.light300
      },
      '&:focus': {
        backgroundColor: colors.light300
      },
      '&:disabled': {
        backgroundColor: colors.light300,
        color: colors.light500
      }
    }
  },
  clear: {
    backgroundColor: 'transparent',
    borderRadius: '0',
    color: 'inherit',
    margin: '0 !important',
    minWidth: '0',
    padding: 0,

    '&:hover': {
      backgroundColor: 'transparent'
    },
    '&:focus': {
      backgroundColor: 'transparent'
    },
    '&:disabled': {
      backgroundColor: 'transparent',
      color: colors.light500
    }
  }
}

export const Button = ({
  variant = 'contained',
  color = 'primary',
  loading,
  textColor,
  fullWidth = false,
  ...props
}) => {
  const className = styles[color] ? color : ''
  const buttonColor = styles[color] ? 'inherit' : color
  const style = {
    ...(textColor && { color: textColor }),
    ...(fullWidth && { width: '100%' })
  }

  if (loading) {
    return (
      <StyledButton
        sx={[
          {
            ...styles[color],
            ...style
          },
          { width: '25%' }
        ]}
        disabled={false}
        variant={variant}
        className={className}
        startIcon={<></>}
        color={buttonColor}
        {...props}
      >
        <CircularProgress color='inherit' size={25} />
      </StyledButton>
    )
  }

  return (
    <StyledButton
      sx={{
        ...(styles[color] || {}),
        ...style
      }}
      variant={variant}
      className={className}
      color={buttonColor}
      {...props}
    />
  )
}

export const ButtonDownload = ({
  variant = 'contained',
  color = 'primary',
  textColor,
  fullWidth = false,
  loading,
  ...props
}) => {
  const className = styles[color] ? color : ''
  const buttonColor = styles[color] ? 'inherit' : color
  const style = {
    ...(textColor && { color: textColor }),
    ...(fullWidth && { width: '100%' })
  }

  if (loading) {
    return (
      <StyledButton
        sx={[
          {
            ...(styles[color] || {}),
            ...style
          },
          {
            width: 124,
            height: 38,
            cursor: 'default',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }
        ]}
        onClick={() => {}}
        variant={variant}
        className={className}
        color={buttonColor}
        // disabled={loading}
      >
        <CircularProgress color='inherit' size={25} />
      </StyledButton>
    )
  } else {
    return (
      <StyledButton
        sx={[
          {
            ...(styles[color] || {}),
            ...style
          },
          { width: 124, height: 38 }
        ]}
        variant={variant}
        className={className}
        color={buttonColor}
        startIcon={<DownloadIcon size='small' />}
        {...props}
      >
        Download
      </StyledButton>
    )
  }
}

Button.propTypes = {
  variant: PropTypes.oneOf(['contained', 'text']),
  disabled: PropTypes.bool,
  color: PropTypes.oneOf(['primary', 'secondary', 'danger', 'light', 'clear']),
  startIcon: PropTypes.object,
  textColor: PropTypes.string,
  fullWidth: PropTypes.bool
}
