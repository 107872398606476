import { condofyProtected } from '@condofy/next/service/instance'
import { jsonToFormData } from '@condofy/next/service/formData'

class TicketService {
  getById({ currentCondominium, ticketId }) {
    return condofyProtected.get(
      `/v1/condominiums/${currentCondominium.id}/tickets/${ticketId}`
    )
  }

  search({ currentCondominium, page = 1, statuses = [], recordsPerPage = 10, generalSearch }) {
    return condofyProtected.post(
      `/v1/condominiums/${currentCondominium.id}/tickets/search`,
      {
        statuses: statuses && !Array.isArray(statuses) ? [statuses] : statuses,
        generalSearch
      },
      {
        params: {
          PageNumber: page,
          RecordsPerPage: recordsPerPage,

        }
      }
    )
  }

  createTicket({ currentCondominium, payload }) {
    return condofyProtected.post(
      `/v1/condominiums/${currentCondominium.id}/tickets`,
      jsonToFormData(payload)
    )
  }

  getComments({ currentCondominium, ticketId }) {
    return condofyProtected.get(
      `/v1/condominiums/${currentCondominium.id}/tickets/${ticketId}/comments`
    )
  }

  createComment({ currentCondominium, ticketId, body }) {
    return condofyProtected.post(
      `/v1/condominiums/${currentCondominium.id}/tickets/${ticketId}/comments`,
      jsonToFormData(body)
    )
  }

  getTicketType() {
    return condofyProtected.get(`/v1/ticket-types`)
  }
}

export default new TicketService()
