import React from 'react'
import { TableRow } from '../Table'
import { DataTableCell } from './DataTableCell'

export const DataTableRow = ({ row, columns = [], children, ...args }) => {
  function removeTags(str) {
    if (str === null || str === '' || typeof str === 'undefined') return ''
    return str.toString().replace(/(<([^>]+)>)/gi, '')
  }

  return (
    <TableRow {...args}>
      {Boolean(row) &&
        columns
          .filter((c) => !c.hide && !c.expand)
          .map((c, i) => {
            return (
              <DataTableCell padding={c.padding} key={`field-${i}-${c.field}`}>
                {(c.renderCell && c.renderCell({ row })) ||
                  (c.valueGetter && c.valueGetter({ row })) ||
                  removeTags(row[c.field])}
              </DataTableCell>
            )
          })}
      {children}
    </TableRow>
  )
}
