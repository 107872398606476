import React from 'react'
import { Page, PageTitle } from '@condofy/components'
import {
  SurveyList,
  CreateSurveyModal,
  UpdateSurveyModal,
  VoteModal
} from '@condofy/next/containers/survey'

export const SurveysPage = () => (
  <Page>
    <PageTitle title='Enquetes' icon='surveys' />
    <SurveyList />
    <CreateSurveyModal />
    <UpdateSurveyModal />
    <VoteModal />
  </Page>
)
