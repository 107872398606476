export const typography = {
  size: {
    h1: 40,
    h2: 32,
    h3: 28,
    h4: 24,
    h5: 20,
    h6: 16,
    body: 16,
    smallBody: 14,
    button: 16
  },
  type: {
    primary: `'Nunito Sans', sans-serif`,
    secondary: `'Proxima Nova', sans-serif`,
    tertiary: `'Inter', sans-serif`
  }
}
