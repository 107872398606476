import React from 'react'
import { Form, FormRow, TextField, DatePicker } from '@condofy/components'
import { FilePond, ProgressCharacter } from '@condofy/next/components'
import { FormField as Field } from '@condofy/next/containers/form/FormField'
import { useFormContext } from 'react-hook-form'
import styled from 'styled-components'
import { format, addDays, parseISO } from 'date-fns'

const StyledFormRow = styled(FormRow)`
  position: relative;
`

export const SurveyForm = ({ descriptionLimit, subjectLimit }) => {
  const { setValue } = useFormContext()

  const handleFiles = (files) => {
    setValue('files', files)
  }

  const currentDate = new Date()

  const startDate = format(currentDate, 'yyyy-MM-dd')

  const nextDate = addDays(currentDate, 4)
  const endDate = format(nextDate, 'yyyy-MM-dd')

  return (
    <Form>
      <FormRow>
        <Field name='title' Component={<TextField required label='Titulo' />} />
      </FormRow>
      <StyledFormRow>
        <Field
          name='subject'
          Component={
            <TextField
              required
              label='Assunto'
              multiline
              rows={3}
              inputProps={{ maxLength: 50 }}
            />
          }
        />
        <ProgressCharacter
          maxCharacterLimit={50}
          characterLimit={subjectLimit}
        />
      </StyledFormRow>
      <FormRow>
        <Field
          name='startDate'
          passRef={false}
          Component={
            <DatePicker
              disablePast
              required
              label='Inicio'
              placeholder='Escolha a data'
              defaultValue={startDate}
              value={parseISO(startDate)}
            />
          }
        />
        <Field
          name='endDate'
          passRef={false}
          Component={
            <DatePicker
              disablePast
              required
              label='Fim'
              placeholder='Escolha a data'
              defaultValue={endDate}
              value={parseISO(endDate)}
            />
          }
        />
      </FormRow>
      <StyledFormRow>
        <Field
          name='description'
          Component={
            <TextField
              required
              label='Descrição'
              inputProps={{ maxLength: 255 }}
              multiline
              rows={5}
            />
          }
        />
        <ProgressCharacter
          maxCharacterLimit={255}
          characterLimit={descriptionLimit}
        />
      </StyledFormRow>
      <FormRow>
        <FilePond maxTotalFileSize='20MB' setFile={handleFiles} />
      </FormRow>
    </Form>
  )
}
