import React from 'react'
import { Page, PageTitle } from '@condofy/components'
import {
  CalendarCard,
  CreateCalendarEventModal,
  EditCalendarEventModal
} from 'Modules/calendar'

export const CalendarPage = () => {
  return (
    <Page>
      <PageTitle title='Calendário' icon='calendar' />
      <CalendarCard />
      <CreateCalendarEventModal />
      <EditCalendarEventModal />
    </Page>
  )
}
