import React from 'react'
import styled from 'styled-components'

const BodyTitle = styled.div`
  display: block;
  color: #9797ad;
  word-wrap: break-word;
  font-size: 16px;
`

const BodyValue = styled.div`
  display: block;
  color: #31304c;
  word-wrap: break-word;
  font-size: 16px;
  margin: 4px 0 14px;
`

export const DetailsText = ({ title, text, props }) => {
  return (
    <div>
      <BodyTitle>{title}</BodyTitle>
      <BodyValue>{text}</BodyValue>
      {props}
    </div>
  )
}
