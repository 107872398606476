import React, { useCallback, useEffect, useState } from 'react'
import { LoadingButton } from '@condofy/components'
import { Box } from '@mui/material'
import { useFinancialContext } from '@condofy/next/store/financial'
import { CreateFinancialEntryEventModal } from 'Modules/financial'
import { useBankAccountContext } from '@condofy/next/store/bank-account'
import { useUserContext } from '@condofy/next/store/user'
import { fetchRestrictedUnitList } from '@condofy/next/service/unit'
import { fetchRestrictionList } from '@condofy/next/service/restriction'
import { useSnackbar, handleError } from '@condofy/next/hooks/useSnackbar'
import { track } from '@condofy/next/service/segment'

export const FinancialStatementNewEntry = ({ reloadEntryList }) => {
  const { enqueueSnackbar } = useSnackbar()
  const [unitList, setUnitList] = useState([])
  const [modalOpen, setModalOpen] = useState(false)

  const {
    state: {
      detail: { metadata, error },
      categories: { items }
    },
    actions: { createFinanceEntry, listCategory }
  } = useFinancialContext()

  const categoryList = useCallback(listCategory, [])

  useEffect(() => {
    categoryList()
  }, [categoryList])

  const {
    state: { currentCondominium }
  } = useUserContext()

  const updateUnitList = useCallback(() => {
    fetchRestrictedUnitList({
      currentCondominium,
      onSuccess: (response) => {
        const unitList = response.data

        fetchRestrictionList({
          currentCondominium,
          onSuccess: (response) => {
            response.data.forEach((restriction) => {
              const index = unitList.findIndex(
                (unit) => unit.id === restriction.unit.id
              )
              if (index > -1) {
                unitList[index].restriction = restriction
              }
            })
            setUnitList([
              {
                id: '0',
                name: '* Sem Unidade'
              },
              ...unitList
            ])
          },
          onError: (error) => {
            const errorMessage =
              error.response.data.reports[0].message || error.message
            enqueueSnackbar(errorMessage, { variant: 'error' })
          }
        })
      }
    })
  }, [])

  useEffect(() => {
    updateUnitList()
  }, [updateUnitList])

  const {
    state: {
      currentAccount: { bankAccountId }
    }
  } = useBankAccountContext()

  const handleSaveEntry = async (body) => {
    const unitId = body.unitId === '0' ? null : body.unitId

    const data = {
      FundingType: body.fundingType,
      BankAccountId: bankAccountId,
      PaidAt: body.paidAt,
      CategoryId: body.categoryId,
      Description: body.description,
      Amount: body.amount,
      receipt: body.receipt,
      UnitId: unitId
    }

    try {
      await createFinanceEntry({
        condominiumId: currentCondominium.id,
        body: data
      })

      handleCloseModal()
      reloadEntryList()
      track(window.analytics, 'Lançamento feito com sucesso', {
        condominium_id: currentCondominium.id,
        condominium_name: name,
        ...body
      })
    } catch (err) {
      let errorMessage = err.response.data.reports[0].message

      handleError(
        enqueueSnackbar(errorMessage, {
          variant: 'error'
        })
      )
    }
  }

  const handleOpenModal = () => {
    setModalOpen(true)
  }

  const handleCloseModal = () => {
    setModalOpen(false)
  }

  return (
    <Box
      sx={{
        marginRight: 2
      }}
    >
      <LoadingButton onClick={handleOpenModal}>Novo Lançamento</LoadingButton>

      <CreateFinancialEntryEventModal
        error={error}
        loading={metadata.loading}
        open={modalOpen}
        onClose={handleCloseModal}
        onSave={handleSaveEntry}
        categories={items}
        unities={unitList}
      />
    </Box>
  )
}
