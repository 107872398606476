import { DatePicker } from '../DateTime'
import clsx from 'clsx'
import React, { useState } from 'react'
import styled from 'styled-components'
import { IconButton } from '../Button'
import { Icon } from '../Icon'
import { Navigate } from 'react-big-calendar'
import { toolbar } from './styles'

export const StyledToolbar = styled.div`
  ${toolbar}
`

export const CalendarToolbar = ({
  localizer: { messages },
  onNavigate,
  views,
  onView,
  label,
  view,
  date,
  showDatePicker = true,
  datePickerProps = {}
}) => {
  const [open, setOpen] = useState(false)

  const viewNamesGroup = (messages) => {
    if (views.length > 1) {
      return views.map((name) => (
        <button
          type='button'
          key={name}
          className={clsx({ 'rbc-active': view === name })}
          onClick={() => onView(name)}
        >
          {messages[name]}
        </button>
      ))
    }
  }
  const closeCalendar = () => setOpen(false)
  const openCalendar = () => setOpen(true)
  const navigate = (action) => () => onNavigate(action)
  const handleDateChange = (date) => {
    onNavigate(Navigate.DATE, date)
    setOpen(false)
  }

  return (
    <StyledToolbar className={'rbc-toolbar'}>
      <span className='rbc-btn-group left'>
        <button type='button' onClick={navigate(Navigate.TODAY)}>
          {messages.today}
        </button>
        <button type='button' onClick={navigate(Navigate.PREVIOUS)}>
          {messages.previous}
        </button>
        <button type='button' onClick={navigate(Navigate.NEXT)}>
          {messages.next}
        </button>
      </span>
      <span className='rbc-toolbar-label'>
        {label}
        {showDatePicker && (
          <>
            <IconButton
              color='primary'
              onClick={openCalendar}
              className='date-picker-btn'
            >
              <Icon icon='calendar' />
            </IconButton>
            <DatePicker
              autoOk
              value={date}
              style={{ display: 'none' }}
              open={open}
              onChange={handleDateChange}
              onClose={closeCalendar}
              {...datePickerProps}
            />
            </>
        )}
      </span>
      <span className='rbc-btn-group right'>{viewNamesGroup(messages)}</span>
    </StyledToolbar>
  )
}
