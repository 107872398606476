import styled from 'styled-components'
import { colors, theme } from '../../styles'

export const TabToolbar = styled.div`
  background: ${colors.light100};
  border-bottom: solid 1px ${colors.light400};
  margin-top: -${theme.spacing(3)};
  margin-bottom: ${theme.spacing(3)};
  padding: ${theme.spacing(3)};
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;

  & .MuiFormControl-root {
    margin: 0;
  }
`
