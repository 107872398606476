import React from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import { colors, EmptyListMessage, Heading6, theme } from '@condofy/components'
import { PollOptions, PollOptionsConfig } from './PollOptions'
import { Box, LinearProgress } from '@mui/material'

const ResultTitle = styled(Heading6)`
  line-height: 56px;
  left: ${theme.spacing(2)};
  position: absolute;
  width: 50%;
  z-index: 999;
`

const Progress = styled(LinearProgress)`
  &.MuiLinearProgress-root {
    background-color: ${colors.light300};
    border-radius: 8px;
    height: 56px;
  }

  .MuiLinearProgress-bar {
    border-radius: 8px;
    ${({ $option }) =>
      $option === PollOptions.YES && `background-color: ${colors.success100};`}
    ${({ $option }) =>
      $option === PollOptions.NO && `background-color: ${colors.danger100};`}
  ${({ $option }) =>
      $option === PollOptions.ABSTAIN &&
      `background-color: ${colors.brandBlue200};`}
  }
`

const PollOptionResult = ({ option, votes }) => {
  const { resultLabel } = PollOptionsConfig[option]
  const percent = Math.floor(
    (votes.filter((a) => a.vote === option).length * 100) / votes.length
  )

  return (
    <Box position='relative' marginBottom={1}>
      <ResultTitle component='div'>{resultLabel(percent)}</ResultTitle>
      <Progress variant='determinate' value={percent} $option={option} />
    </Box>
  )
}

export const PollResults = ({ votes = [] }) => {
  return !_.isEmpty(votes) ? (
    <div>
      {Object.keys(PollOptions).map((k) => (
        <PollOptionResult key={k} option={PollOptions[k]} votes={votes} />
      ))}
    </div>
  ) : (
    <EmptyListMessage message='Não houve votos na enquete' />
  )
}
