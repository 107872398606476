import React from 'react'
import { Select, MenuItem, Typography } from '@condofy/components';

const organizeData = (items) => {
  const map = {};
  const roots = [];

  items.forEach(item => {
      map[item.id] = { ...item, children: [] };
  });

  items.forEach(item => {
      if (item.parentId === null) {
          roots.push(map[item.id]);
      } else {
          if (map[item.parentId]) {
              map[item.parentId].children.push(map[item.id]);
          }
      }
  });

  return roots;
};

const flattenData = (items, level = 0) => {
  let flattened = [];
  items.forEach(item => {
      flattened.push({ ...item, level });
      if (item.children.length > 0) {
          flattened = flattened.concat(flattenData(item.children, level + 1));
      }
  });
  return flattened;
};

export const CategorySelect = ({ label= 'Categoria', categories, ...props }) => {
  const organizedData = organizeData(categories);
  const flattenedData = flattenData(organizedData);

  return (
    <Select
    label={label}
    fullWidth
    SelectProps={{
      renderValue: (selected) => {
          const selectedItem = flattenedData.find(item => item.id === selected);
          return selectedItem ? selectedItem.name : '';
      }
    }}
    {...props}
      >
          {flattenedData.map(item => (
              <MenuItem key={item.id} value={item.id} disabled={item.children.length > 0}>
                  <Typography style={{ paddingLeft: item.level * 16 }}>
                      {item.name}
                  </Typography>
              </MenuItem>
          ))}
      </Select>
  );
}
