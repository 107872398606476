import React from 'react'
import { useDispatch } from 'react-redux'
import { useUserContext } from '@condofy/next/store/user'
import {
  Avatar,
  Flex,
  Heading6,
  MenuItem,
  SmallBody,
  Button,
  Icon,
  UserRoleText,
  colors,
  ButtonMenu as Menu
} from '@condofy/components'
import { DELETE_CONNECTION_MODAL } from 'Const'
import { toggleModal } from 'Actions/modals'
import { ListItemIcon, ListItemText } from '@mui/material'
import styled from 'styled-components'

const DangerMenuItem = styled(MenuItem)`
  * {
    color: ${colors.danger400} !important;
  }
`

export const UnitUserList = ({ users, unit, condominium }) => {
  const dispatch = useDispatch()
  const {
    state: { isSuperAdmin },
    actions: { openEditUserConnectionModal }
  } = useUserContext()

  const onEdit = (user) => {
    openEditUserConnectionModal({ user, condominium, unit })
  }
  const onDelete = (userId) => {
    const condoId = condominium.id
    dispatch(
      toggleModal(DELETE_CONNECTION_MODAL, true, { userId, condoId, unit })
    )
  }

  return (
    <Flex direction='column' spacingY={3}>
      {users.map((user) => (
        <Flex key={user.id} width='100%' justify='space-between'>
          <Flex spacingX={2}>
            <Avatar label={user.name} />
            <div>
              <Heading6 color='textPrimary'>{user?.user.name}</Heading6>
              <SmallBody color='textSecondary'>
                <UserRoleText role={user?.userType?.name?.toLowerCase()} />
              </SmallBody>
            </div>
          </Flex>
          {isSuperAdmin && (
            <Menu
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              Button={
                <Button variant='text' textColor={colors.dark100}>
                  <Icon icon='more_horizontal' />
                </Button>
              }
            >
              <MenuItem onClick={() => onEdit(user)}>
                <ListItemIcon>
                  <Icon icon='edit' />
                </ListItemIcon>
                <ListItemText primary='Editar' />
              </MenuItem>
              <DangerMenuItem onClick={() => onDelete(user.id)}>
                <ListItemIcon>
                  <Icon icon='delete' />
                </ListItemIcon>
                <ListItemText primary='Deletar' />
              </DangerMenuItem>
            </Menu>
          )}
        </Flex>
      ))}
    </Flex>
  )
}
