import React, { useState, useEffect } from 'react'
import { Modal, ActionRow, ModalContent } from '@condofy/next/components'
import { useSnackbar, handleError } from '@condofy/next/hooks/useSnackbar'
import { useUserContext } from '@condofy/next/store/user'
import { track } from '@condofy/next/service/segment'
import { AreaForm } from '../form/AreaForm'
import { Grid } from '@mui/material'

const formatTime = (time) =>
  time && `2020-07-23T${time.replace('0000', 'Z').replace('383', '492')}`

export const ProcessAreaModal = ({
  trackMsg,
  title,
  area: initialArea,
  footer,
  onOpen = () => {},
  onClose = () => {},
  onChange = () => {},
  onSave = () => {}
}) => {
  const {
    state: { currentCondominium },
    actions: { listAreas }
  } = useUserContext()
  const { enqueueSnackbar } = useSnackbar()
  const [loading, setLoading] = useState(false)
  const [area, setArea] = useState()
  const [isValid, setIsValid] = useState(false)

  useEffect(() => {
    const weekdaysAvailable =
      initialArea?.weekdaysAvailable ||
      [...Array(7).keys()].filter((day) =>
        Boolean(
          initialArea?.availables?.find(
            (a) => a.type === 'D' && a.weekDay === day
          )
        )
      )
    let hoursAvailable =
      initialArea?.hoursAvailable ||
      initialArea?.availables
        ?.slice()
        .reverse()
        .filter((a) => a.type === 'H')
        .splice(0, 1)
        .map((a) => {
          return {
            initialTime: formatTime(a.initialTime),
            finalTime: formatTime(a.finalTime)
          }
        })
    hoursAvailable =
      hoursAvailable?.length > 0
        ? hoursAvailable
        : [
            {
              initialTime: '2020-07-23T00:00:00.492Z',
              finalTime: '2020-07-23T23:00:00.492Z'
            }
          ]
    setArea({
      ...initialArea,
      ...{
        weekdaysAvailable,
        hoursAvailable
      }
    })
  }, [initialArea])

  useEffect(() => {
    setIsValid(
      Boolean(
        area &&
          area.name &&
          area.description &&
          area.hoursAvailable &&
          area.hoursAvailable[0]?.initialTime
      )
    )
  }, [area])

  const onSubmit = () => {
    setLoading(true)
    onSave({ currentCondominium, area })
      .then(() => {
        track(window.analytics, trackMsg, area)
        enqueueSnackbar('Área salva com sucesso', { variant: 'success' })
        onClose()
        listAreas({ currentCondominium })
      })
      .catch(handleError(enqueueSnackbar))
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <Modal
      title={title}
      open={onOpen}
      onClose={onClose}
      footer={
        <Grid container>
          <Grid item xs={6}>
            <ActionRow
              fullWidth
              loading={loading}
              onSubmit={onSubmit}
              onCancel={onClose}
              disabled={!isValid || loading}
            />
          </Grid>
        </Grid>
      }
    >
      <ModalContent>
        <AreaForm area={area} onChange={onChange}></AreaForm>
        {footer}
      </ModalContent>
    </Modal>
  )
}
