import PropTypes from 'prop-types'

export const PremiumToggle = ({
  userIsPremium = false,
  fallback,
  children
}) => {
  return userIsPremium ? children : fallback
}
PremiumToggle.propTypes = {
  userIsPremium: PropTypes.bool,
  fallback: PropTypes.any
}
