import React from 'react'
import PropTypes from 'prop-types'
import { Box } from '@mui/material'

export const Wizard = ({ onSubmit = () => {}, children, ...args }) => {
  const [activeStep, setActiveStep] = React.useState(0)

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const totalSteps = () => {
    return React.Children.toArray(children).filter(
      (child) => child.props['__TYPE'] === 'WizardStep'
    ).length
  }

  const handleNext = () => {
    setActiveStep(activeStep + 1)
  }

  const handleComplete = () => {
    onSubmit()
  }

  const isStepVisible = (step) => {
    return step === activeStep
  }

  const steps = React.Children.map(children, (child, index) => {
    if (React.isValidElement(child) && child.props['__TYPE'] === 'WizardStep') {
      return React.cloneElement(child, {
        onBack: (event) => {
          handleBack()
          if (child.props.onBack) {
            child.props.onBack(event)
          }
        },
        onNext: (event) => {
          if (index === totalSteps() - 1) {
            handleComplete()
          } else {
            handleNext()
          }
          if (child.props.onNext) {
            child.props.onNext(event)
          }
        },
        isVisible: isStepVisible(index),
        step: index
      })
    }
    return null
  })

  return <Box {...args}>{steps}</Box>
}
Wizard.propTypes = {
  onSubmit: PropTypes.func
}
