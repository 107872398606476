import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { colors, pxToRem, typography } from '../../styles'

const Message = styled.div`
  &,
  & * {
    color: ${colors.dark400};
    font-family: ${typography.type.tertiary};
    font-size: ${pxToRem(16)}rem;
    line-height: 1.25;
    text-align: center;
  }
`

export const EmptyListMessage = ({
  message = 'Não foram encontrados registros',
  ...props
}) => <Message {...props}>{message}</Message>

EmptyListMessage.propTypes = {
  message: PropTypes.node
}
