import { Typography } from '@mui/material'
import { typography } from '../../styles'
import styled from 'styled-components'

export const DialogTitle = styled(Typography)`
  flex: auto 1 1;
  padding: 0;
  align-self: center;

  &.MuiTypography-root {
    font-family: ${typography.type.tertiary};
    font-size: 16px;
    font-weight: 600;
  }
`
