import React, { useCallback, useEffect, useState } from 'react'
import {
  Chart,
  LineController,
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale,
  BarController,
  BarElement,
  Tooltip
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
import { useUserContext } from '@condofy/next/store/user'
import { useFinancialContext } from '@condofy/next/store/financial'
import { colors, ChartContainer } from '@condofy/components'
import moment from 'moment'

Chart.register(
  LineController,
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale,
  BarController,
  BarElement,
  [Tooltip]
)

const chartOptions = {
  legend: { display: false },
  scales: {
    x: { stacked: true },
    y: {
      stacked: true,
      scaleLabel: {
        labelString: 'R$',
        display: true
      }
    }
  },
  tooltips: {
    mode: 'index',
    callbacks: {
      title: (tooltipItems, data) => {
        // Assuming the date is stored in the first dataset
        const date = data.datasets[0].data[tooltipItems[0].index]
        return moment(date).format('MMM/YYYY')
      },
      label: (ttitems, data) => {
        const val = Math.round(ttitems.yLabel * 100) / 100
        return (
          data.datasets[ttitems.datasetIndex].label + ': R$ ' + Math.abs(val)
        )
      }
    }
  },
  hover: {
    mode: 'index',
    intersect: true
  }
}
const months = [5, 4, 3, 2, 1, 0].map((n) =>
  moment().startOf('month').subtract(n, 'month').toDate()
)

const labels = months.map((m) => moment(m).format('MMM/YYYY'))

const initialChartData = [
  {
    label: 'Saldo total',
    type: 'line',
    borderColor: colors.brandBlue500,
    borderWidth: 1,
    borderDash: [5, 5],
    fill: false,
    data: [0, 0, 0, 0, 0, 0],
    pointRadius: 2,
    pointBackgroundColor: colors.brandBlue500
  },
  {
    label: 'Recebido',
    legend: { display: true },
    type: 'bar',
    backgroundColor: colors.green500,
    data: [0, 0, 0, 0, 0, 0]
  },
  {
    label: 'Despesas',
    type: 'bar',
    backgroundColor: colors.red500,
    data: [0, 0, 0, 0, 0, 0]
  }
]

export const CashflowChart = () => {
  const [chartData, setChartData] = useState(initialChartData)

  const {
    state: { currentCondominium }
  } = useUserContext()

  const {
    state: {
      cashflow: {
        items = [],
        metadata: { loading }
      }
    },
    actions: { listCashflow }
  } = useFinancialContext()

  const stableListCashflow = useCallback(listCashflow, [])

  useEffect(() => {
    if (currentCondominium.id) {
      stableListCashflow({
        currentCondominium,
        startDate: months[0],
        endDate: months[months.length - 1]
      })
    }
  }, [currentCondominium, stableListCashflow])

  useEffect(() => {
    if (items) {
      const newData = [
        { ...initialChartData[0], data: [] },
        { ...initialChartData[1], data: [] },
        { ...initialChartData[2], data: [] }
      ]
      months.forEach((month) => {
        const item = items.find((r) =>
          moment(r.date).isSame(moment(month), 'month')
        )
        newData[0].data.push(Number(item?.balance?.amount) || 0)
        newData[1].data.push(Number(item?.income?.achieved) || 0)
        newData[2].data.push(Number(item?.expenses?.achieved) || 0)
      })
      setChartData(newData)
    }
  }, [items])

  return (
    <ChartContainer loading={loading}>
      <Bar data={{ datasets: chartData, labels }} options={chartOptions} />
    </ChartContainer>
  )
}
