import { combineReducers } from '@condofy/next/store/user/combineReducers'
import {
  listActions,
  listReducer,
  list,
  listAll,
  listActionTypes
} from './list'
import { editActions, editReducer, editActionTypes } from './edit'
import { createActions, createReducer, createActionTypes } from './create'

// ACTION TYPES
const UNIT_SET_LOADING_INDICATOR = 'UNIT_SET_LOADING_INDICATOR'
const UNIT_SELECT = 'UNIT_SELECT'

// ACTIONS
export const unitActions = (state, dispatch) => ({
  ...createActions(state, dispatch),
  ...listActions(state, dispatch),
  ...editActions(state, dispatch),

  selectUnit: (payload) => dispatch({ type: UNIT_SELECT, payload })
})

export const pending = (dispatch, payload) =>
  dispatch({ type: UNIT_SET_LOADING_INDICATOR, payload })

// MIDDLEWARE
export const unitMiddlewares = (dispatch, action) => {
  switch (action.type) {
    case listActionTypes.UNIT_LIST:
      return list(dispatch, action.payload)

    case listActionTypes.UNIT_LIST_FULL:
      return listAll(dispatch, action.payload)

    default:
      return false
  }
}

// REDUCER
const unitMainReducer = (state, action) => {
  switch (action.type) {
    case UNIT_SELECT:
      return store(state, { selected: action.payload })

    case UNIT_SET_LOADING_INDICATOR:
      return store(state, {
        metadata: {
          ...state.unit.metadata,
          loading: action.payload
        }
      })

    default:
      return state
  }
}

export const unitReducer = combineReducers({
  unitMainReducer,
  createReducer,
  editReducer,
  listReducer
})

export const store = (state, value) => ({
  ...state,
  unit: { ...state.unit, ...value }
})

export const unit = {
  createModalOpen: false,
  deleteModalOpen: false,
  editModalOpen: false,
  selected: {},
  items: [],
  allItems: [],
  metadata: {
    pageNumber: 1,
    totalRecords: 0,
    recordsPerPage: 10,
    loading: false
  }
}

export const unitActionTypes = {
  UNIT_SET_LOADING_INDICATOR,
  UNIT_SELECT,

  ...createActionTypes,
  ...listActionTypes,
  ...editActionTypes
}
