import { condofy } from './instance'
import { ENVIRONMENT, REDIRECT_TO_APP, REDIRECT_TO_AUTH } from './env'
import { setToken, getToken } from './token'

export const redirectToAuth = () =>
  window.location.assign(REDIRECT_TO_AUTH[ENVIRONMENT])

export const redirectToApp = () =>
  window.location.assign(REDIRECT_TO_APP[ENVIRONMENT])

export const authenticateWithPassword = async ({ login, password }) => {
  const response = await condofy.post('/accounts/authenticate', {
    login,
    password
  })
  setToken(response.data.data.accessToken)
  redirectToApp()

  return response
}

export const authenticateWithToken = () =>
  condofy.get('/accounts/profile', {
    headers: {
      Authorization: `Bearer ${getToken()}`
    }
  })
