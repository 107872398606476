import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { colors } from '../../styles'

const Input = styled.input`
  border: none;
  outline: none;
  appearance: none !important;

  display: inline-flex;
  flex: 1 1 auto;

  background: #ffffff;
  box-shadow: 0px 7px 64px rgba(0, 0, 0, 0.07);

  color: ${colors.primary};

  margin: 8px;
  padding: 12px 16px;

  box-sizing: border-box;

  &::placeholder {
    color: ${colors.dark100};
  }
  width: 100%;
  appearance: none;
  border-radius: 6px;
`

const FlexInline = styled.div`
  display: inline-flex;
`

const identity = (x) => x

const KEY_CODE = {
  backspace: 8,
  left: 37,
  up: 38,
  right: 39,
  down: 40
}

const SquareInput = styled(Input)`
  text-align: center;
  width: 45px;
  height: 45px;

  &,
  ::placeholder {
    font-weight: 600;
    font-size: 22px;
    line-height: 28px;
  }
`

export const Code = ({
  type = 'tel',
  fields = 6,
  autoFocus = true,
  disabled = false,
  required = false,
  onChange: emitChangeEvent = identity,
  onComplete: emitCompleteEvent = identity,
  values
}) => {
  const [innerValues, setInnerValues] = useState(
    values || Array(fields).fill('')
  )
  const [autoFocusIndex, setAutoFocusIndex] = useState(0)
  const inputRefs = useRef([])

  if (inputRefs.current.length !== innerValues.length) {
    inputRefs.current = Array(innerValues.length)
      .fill()
      .map((value, index) => inputRefs.current[index] || React.createRef())
  }

  useEffect(() => {
    const newValues = []
    const focusIndex = innerValues.length >= fields ? 0 : innerValues.length

    for (let index = 0; index < fields; index++) {
      newValues.push(innerValues[index] || '')
    }

    setAutoFocusIndex(focusIndex)
    setInnerValues(newValues)
  }, [])

  useEffect(() => {
    const newValues = innerValues.join('')
    emitChangeEvent(newValues)
    if (newValues.length >= fields) emitCompleteEvent(newValues)
  }, [innerValues])

  const handleChange = (event) => {
    const inputIndex = parseInt(event.target.dataset.id)
    const inputValue = event.target.value.replace(/[^\d]/gi, '')
    const values = Object.assign([], innerValues)
    let next

    if ((!inputValue && inputValue !== 0) || !event.target.validity.valid)
      return

    if (inputValue.length > 1) {
      let nextIndex = inputValue.length + inputIndex - 1

      if (nextIndex >= fields) {
        nextIndex = fields - 1
      }

      next = inputRefs.current[nextIndex]

      inputValue.split('').forEach((item, index) => {
        const cursor = inputIndex + index
        if (cursor < fields) values[cursor] = item
      })

      setInnerValues(values)
    } else {
      next = inputRefs.current[inputIndex + 1]
      values[inputIndex] = inputValue
      setInnerValues(values)
    }

    if (next) {
      next.current.focus()
      next.current.select()
    }
  }

  const handleKeyDown = (event) => {
    const index = parseInt(event.target.dataset.id)
    const prevIndex = index - 1
    const nextIndex = index + 1
    const prev = inputRefs.current[prevIndex]
    const next = inputRefs.current[nextIndex]
    const newValues = [...innerValues]

    switch (event.keyCode) {
      case KEY_CODE.backspace:
        event.preventDefault()

        if (innerValues[index]) {
          newValues[index] = ''
          setInnerValues(newValues)
        } else if (prev) {
          newValues[prevIndex] = ''
          prev.current.focus()
          setInnerValues(newValues)
        }

        break

      case KEY_CODE.left:
        event.preventDefault()
        if (prev) prev.current.focus()
        break

      case KEY_CODE.right:
        event.preventDefault()
        if (next) next.current.focus()
        break

      case KEY_CODE.up:
      case KEY_CODE.down:
        event.preventDefault()
        break

      default:
        break
    }
  }

  return (
    <FlexInline>
      {innerValues.map((value, index) => {
        return (
          <SquareInput
            type={type}
            placeholder='0'
            pattern='[0-9]*'
            autoFocus={autoFocus && index === autoFocusIndex}
            key={index}
            data-id={index}
            value={value}
            ref={inputRefs.current[index]}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            onFocus={(event) => event.target.select(event)}
            disabled={disabled}
            required={required}
          />
        )
      })}
    </FlexInline>
  )
}
