import { createActions, createReducer } from 'reduxsauce'

import { addMonths, subMonths } from 'date-fns'

import Util from '../util'

const { action, request } = new Util('finance')

const INITIAL_STATE = {
  toReceiveMonth: new Date(),
  toReceive: {
    list: [],
    metadata: {
      limit: 10,
      count: 1,
      page: 1
    }
  },
  toPayMonth: new Date(),
  toPay: {
    list: [],
    metadata: {
      limit: 10,
      count: 1,
      page: 1
    }
  },
  toDetailedMonth: new Date(),
  toDetailed: {
    list: [],
    metadata: {
      limit: 10,
      count: 1,
      page: 1
    }
  },
  search: '',
  pending: {}
}

export const { Types, Creators: Actions } = createActions({
  ...action('download', ['', 'success', 'error', 'pending']),
  ...action('downloadExcel', ['', 'success', 'error', 'pending']),
  ...action('searchToReceive', ['', 'success', 'error', 'pending']),
  ...action('searchToDetailed', ['', 'success', 'error', 'pending']),
  ...action('searchToPay', ['', 'success', 'error', 'pending']),
  ...action('toPay', ['nextMonth', 'previousMonth', 'selectMonth']),
  ...action('toDetailed', ['nextMonth', 'previousMonth', 'selectMonth']),
  ...action('toReceive', ['nextMonth', 'previousMonth', 'selectMonth'])
})

const nextMonth = (state, { payload }) => ({
  ...state,
  [`${payload.type}Month`]: addMonths(state[`${payload.type}Month`], 1)
})

const previousMonth = (state, { payload }) => ({
  ...state,
  [`${payload.type}Month`]: subMonths(state[`${payload.type}Month`], 1)
})

const selectMonth = (state, { payload }) => ({
  ...state,
  [`${payload.type}Month`]: payload.date
})

export const HANDLERS = {
  ...request('searchToReceive'),
  ...request('searchToDetailed'),
  ...request('searchToPay'),
  ...request('download'),
  ...request('downloadExcel'),

  [Types.FINANCE_TO_RECEIVE_NEXT_MONTH]: nextMonth,
  [Types.FINANCE_TO_RECEIVE_PREVIOUS_MONTH]: previousMonth,
  [Types.FINANCE_TO_RECEIVE_SELECT_MONTH]: selectMonth,

  [Types.FINANCE_TO_PAY_NEXT_MONTH]: nextMonth,
  [Types.FINANCE_TO_PAY_PREVIOUS_MONTH]: previousMonth,
  [Types.FINANCE_TO_PAY_SELECT_MONTH]: selectMonth,

  [Types.FINANCE_TO_DETAILED_NEXT_MONTH]: nextMonth,
  [Types.FINANCE_TO_DETAILED_PREVIOUS_MONTH]: previousMonth,
  [Types.FINANCE_TO_DETAILED_SELECT_MONTH]: selectMonth
}

export const Reducer = createReducer(INITIAL_STATE, HANDLERS)
