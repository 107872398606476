import React, { useState } from 'react'
import { FormRow, Form, TextField, MenuItem } from '@condofy/components'
import { Modal, ActionRow } from '@condofy/next/components'
import { userTypeListWithNoManager } from '@condofy/next/model/user'
import { useUserContext } from '@condofy/next/store/user'
import { useSnackbar, handleError } from '@condofy/next/hooks/useSnackbar'
import { getCondominiumByCode } from '@condofy/next/service/condo'
import { ModalContent } from '@condofy/next/components/Modal'

export const CreateConnectionModal = () => {
  const {
    state: {
      isAdmin,
      isSuperAdmin,
      connection: { createModalOpen, metadata, selected },
      unit: { allItems: unitList }
    },
    actions: {
      listAllUnits,
      listConnection,
      requestConnection,
      closeCreateConnectionModal
    }
  } = useUserContext()

  const admnistratorUser = isSuperAdmin || isAdmin
  const { enqueueSnackbar } = useSnackbar()
  const [condominiumCode, setCondominiumCode] = useState('')
  const [userTypeId, setUserTypeId] = useState('')
  const [unitId, setUnitId] = useState('')

  const fetchUnitList = () => {
    if (condominiumCode) {
      listAllUnits({ currentCondominium: { id: condominiumCode } }).catch(() =>
        enqueueSnackbar('Insira um código de condomínio válido', {
          variant: 'error'
        })
      )
    }
  }

  const sendConnection = async () => {
    const response = await getCondominiumByCode({ code: condominiumCode })
    const condominium = response.data.data

    try {
      await requestConnection({
        user: selected,
        condominium,
        userTypeId,
        unitId
      })
      closeCreateConnectionModal()
      enqueueSnackbar('Pedido de conexão enviado para o síndico.', {
        variant: 'success'
      })

      if (admnistratorUser) {
        listConnection({ currentCondominium: condominium })
      }
    } catch (error) {
      handleError(enqueueSnackbar)(error)
    }
  }

  return (
    <Modal
      title='Nova Conexão'
      open={createModalOpen}
      onClose={closeCreateConnectionModal}
      footer={
        <ActionRow
          loading={metadata.loading}
          confirmText='Adicionar'
          onSubmit={sendConnection}
          onCancel={closeCreateConnectionModal}
        />
      }
    >
      <ModalContent>
        <Form>
          <FormRow>
            <TextField
              label='Código do condomínio'
              value={condominiumCode}
              onChange={(event) => setCondominiumCode(event.target.value)}
              onBlur={fetchUnitList}
            />
          </FormRow>
          <FormRow>
            <TextField
              select
              label='Papel no condomínio'
              value={userTypeId}
              onChange={(event) => setUserTypeId(event.target.value)}
            >
              {userTypeListWithNoManager.map(({ key, value, children }) => (
                <MenuItem key={key} value={value}>
                  {children}
                </MenuItem>
              ))}
            </TextField>
          </FormRow>
          <FormRow>
            <TextField
              select
              label='Unidade'
              value={unitId}
              onChange={(event) => setUnitId(event.target.value)}
              disabled={!unitList.length}
            >
              {unitList.map(({ id, block, number }) => (
                <MenuItem key={id} value={id}>
                  {`${block} ${number}`}
                </MenuItem>
              ))}
            </TextField>
          </FormRow>
        </Form>
      </ModalContent>
    </Modal>
  )
}
