import { combineReducers } from '@condofy/next/store/user/combineReducers'
import { listActions, listReducer, list, listActionTypes } from './list'
import {
  createActions,
  createReducer,
  create,
  createActionTypes
} from './create'

import {
  deleteActions,
  deleteReducer,
  remove,
  deleteActionTypes
} from './delete'

import { editActions, editReducer, edit, editActionTypes } from './edit'

// ACTION TYPES
const AREA_SET_LOADING_INDICATOR = 'AREA_SET_LOADING_INDICATOR'
const AREA_SELECT = 'AREA_SELECT'

// ACTIONS
export const areaActions = (state, dispatch) => ({
  ...createActions(state, dispatch),
  ...listActions(state, dispatch),
  ...editActions(state, dispatch),
  ...deleteActions(state, dispatch),

  selectArea: (payload) => dispatch({ type: AREA_SELECT, payload })
})

export const pending = (dispatch, payload) =>
  dispatch({ type: AREA_SET_LOADING_INDICATOR, payload })

// MIDDLEWARE
export const areaMiddlewares = (dispatch, action) => {
  switch (action.type) {
    case listActionTypes.AREA_LIST:
      return list(dispatch, action.payload)

    case createActionTypes.AREA_CREATE:
      return create(dispatch, action.payload)

    case editActionTypes.AREA_EDIT:
      return edit(dispatch, action.payload)

    case deleteActionTypes.AREA_DELETE:
      return remove(dispatch, action.payload)
    default:
      return false
  }
}

// REDUCER
const areaMainReducer = (state, action) => {
  switch (action.type) {
    case AREA_SELECT:
      return store(state, { selectedArea: action.payload })

    case AREA_SET_LOADING_INDICATOR:
      return store(state, {
        metadata: {
          ...state.area.metadata,
          loading: action.payload
        }
      })

    default:
      return state
  }
}

export const areaReducer = combineReducers({
  areaMainReducer,
  createReducer,
  editReducer,
  deleteReducer,
  listReducer
})

export const store = (state, value) => ({
  ...state,
  area: { ...state.area, ...value }
})

export const area = {
  createModalOpen: false,
  deleteModalOpen: false,
  editModalOpen: false,
  selected: {},
  items: [],
  allItems: [],
  metadata: {
    pageNumber: 1,
    totalRecords: 0,
    recordsPerPage: 10,
    loading: false
  }
}

export const areaActionTypes = {
  AREA_SET_LOADING_INDICATOR,
  AREA_SELECT,

  ...createActionTypes,
  ...listActionTypes,
  ...editActionTypes,
  ...deleteActionTypes
}
