import React, { useCallback, useState } from 'react'
import {
  DataTable,
  Heading6,
  Caption,
  Body,
  CurrencyText,
  Button
} from '@condofy/components'
import { Modal, ModalContent } from '@condofy/next/components'
import { useBillingContext } from '@condofy/next/store/billing'
import { Box, Typography } from '@mui/material'
import { condofyProtected } from '../../service/instance'
import { useUserContext } from '@condofy/next/store/user'
import moment from 'moment'
import { Modal as ModalMui } from '@mui/material'
import styled from 'styled-components'
import { colors, theme } from '../../../@condofy/styles'
import { DialogCloseButton } from '../../../@condofy/components/Dialog/Dialog'
import SendIcon from '@mui/icons-material/Send'
import { CopyButton } from '../../../@condofy/components/CopyButton'
import { useSnackbar } from 'notistack'
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined'

const StatusEnum = {
  PENDENTE: 0,
  EXPIRADO: 1,
  PAGO: 2
}

const SendIconAnimated = styled(SendIcon)`
  color: ${colors.primary};
  margin-bottom: 1;
  @keyframes moveRightAndBack {
    0% {
      transform: translateX(0);
    }
    50% {
      transform: translateX(10px);
    }
    100% {
      transform: translateX(0);
    }
  }
  animation: moveRightAndBack 1s ease-in-out forwards;
`

const DialogOverlay = styled(ModalMui)`
  background: rgba(0, 25, 71, 0.65);
  display: flex;
  justify-content: center;
  align-items: center;

  & .MuiPaper-root {
    background-color: ${colors.light100};
    border: solid 1px ${colors.light400};
    border-radius: 3px;
    box-shadow:
      0px 24px 18px rgba(23, 43, 77, 0.04),
      0px 35px 24px rgba(23, 43, 77, 0.08);
    padding: ${theme.spacing(3)};
    outline: none;

    ${({ maxWidth }) =>
      !maxWidth &&
      `
    max-width: 90vw;
      max-height: 90vh;

      ${theme.breakpoints.up('md')} {
        min-width: 35vw;
        max-width: 60vw;
      }
    `}
  }
`

export const BillingDetailModal = () => {
  const {
    state: { detail },
    actions: { closeDetailModal, loadBillingList }
  } = useBillingContext()
  const stableLoadBillingList = useCallback(loadBillingList, [])
  const {
    state: { currentCondominium }
  } = useUserContext()

  const { enqueueSnackbar } = useSnackbar()

  const [newBillingLoading, setNewBillingLoading] = useState(false)
  const [errorMessageOpen, setErrorMessageOpen] = useState(false)
  const [successMessage, setSuccessMessage] = useState(false)
  const [newBillingLink, setNewBillingLink] = useState(null)

  const handleClose = () => {
    closeDetailModal()
  }

  const handleSubmit = () => {
    window.open(detail.url)
  }

  const columns = [
    { field: 'description', headerName: 'Descrição' },
    {
      field: 'value',
      headerName: 'Valor',
      renderCell: ({ row }) => <CurrencyText value={row.amount} />
    }
  ]

  const reloadBillings = async () => {
    await stableLoadBillingList({
      generalSearch: '',
      status: '',
      condominiumId: currentCondominium.id,
      initialDate: moment(null).format('YYYY-MM-DD 00:00'),
      finalDate: moment(null).format('YYYY-MM-DD 23:59'),
      unitIds: [],
      PageNumber: 1,
      RecordsPerPage: 10
    })
  }

  const reissue = async () => {
    try {
      setNewBillingLoading(true)
      const result = await condofyProtected.post(
        `/v1/condominiums/${currentCondominium.id}/billings/reissue/${detail.entryId}`
      )
      setNewBillingLink(result.data.data.invoiceUrl)
      enqueueSnackbar(
        'Seu novo boleto foi gerado com sucesso e já está disponível para pagamento.',
        {
          variant: 'success',
          anchorOrigin: { vertical: 'bottom', horizontal: 'center' }
        }
      )
      reloadBillings()
      setNewBillingLoading(false)
      handleClose()
    } catch (error) {
      enqueueSnackbar(
        'Identificamos pendências que impedem a reemissão automática da sua fatura. Para resolver, por favor, entre em contato conosco pelo e-mail: atendimento@condofy.com.br ',
        {
          variant: 'warning',
          anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
          title: 'Não foi possivel reemitir o boleto'
        }
      )
      setNewBillingLoading(false)
    }
  }

  const handleCpyBarCode = () => {
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(detail.billingDigitableLine)
        .then(() => {
          enqueueSnackbar('Código copiado com sucesso!', {
            variant: 'success',
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' }
          })
        })
        .catch((err) => {
          console.error('Erro ao copiar para a área de transferência:', err)
          enqueueSnackbar(
            'Falha ao copiar código. Por favor, tente novamente.',
            {
              variant: 'error',
              anchorOrigin: { vertical: 'bottom', horizontal: 'center' }
            }
          )
        })
    } else {
      console.error('Seu navegador não suporta a API do clipboard.')
      enqueueSnackbar('Não foi possível copiar o código. Tente manualmente.', {
        variant: 'error',
        anchorOrigin: { vertical: 'bottom', horizontal: 'center' }
      })
    }
  }

  return (
    <Modal
      title={`Fatura ${new Date(detail.dueDate).toLocaleDateString('pt-br')}`}
      open={detail.modal}
      onClose={handleClose}
      footer={
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%'
          }}
        >
          {detail.status === StatusEnum.EXPIRADO &&
            !detail.metadata.loading && (
              <Box
                sx={{
                  width: '100%',
                  backgroundColor: 'rgba(243, 116, 43, 0.12)',
                  marginBottom: '10px',
                  padding: '10px',

                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <WarningAmberOutlinedIcon
                  color='warning'
                  fontSize='small'
                  sx={{ marginRight: '10px' }}
                />
                <Caption>
                  Fatura vencida. Gere uma 2ª via do boleto para prosseguir com
                  o pagamento.
                </Caption>
              </Box>
            )}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%'
            }}
          >
            {(detail.status === StatusEnum.PENDENTE ||
              detail.status === StatusEnum.PAGO) && (
              <Button
                loading={detail.metadata.loading || !detail.url}
                onClick={handleSubmit}
              >
                Visualizar Boleto
              </Button>
            )}

            {detail.status === StatusEnum.EXPIRADO &&
              !detail.metadata.loading && (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    width: '80%'
                  }}
                >
                  <Button
                    sx={{
                      backgroundColor: '#f3742b',
                      color: '#fff',
                      ':hover': {
                        backgroundColor: '#dc6825'
                      },
                      marginRight: '5px'
                    }}
                    onClick={reissue}
                    loading={newBillingLoading}
                    color='danger'
                    textColor='white'
                  >
                    Gerar 2ª Via
                  </Button>
                  <Button
                    color='secondary'
                    loading={detail.metadata.loading || !detail.url}
                    onClick={handleSubmit}
                  >
                    Visualizar Boleto Vencido
                  </Button>
                </Box>
              )}

            <CopyButton
              loading={detail.metadata.loading}
              disabled={detail.status !== StatusEnum.PENDENTE}
              onClick={handleCpyBarCode}
            />
          </Box>
        </Box>
      }
    >
      <ModalContent>
        <Box
          marginBottom={2}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start'
          }}
        >
          <Caption fontWeight={700} color={colors.dark400}>
            Cliente
          </Caption>
          <Body fontWeight={400} color={colors.dark400}>
            {detail.recipientName}
          </Body>
        </Box>
        <Box>
          <Caption fontWeight={700} color={colors.dark400}>
            Demonstrativo
          </Caption>
          <DataTable
            variant='borderless'
            size='small'
            loading={detail.metadata.loading}
            columns={columns}
            rows={detail.items || []}
            hasFooter={true}
          />
        </Box>
        <DialogOverlay
          open={errorMessageOpen}
          onClose={() => setErrorMessageOpen(false)}
        >
          <Box
            sx={{
              backgroundColor: 'white',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              minHeight: 'cover',
              maxHeight: '70%',
              width: '22%',
              borderRadius: 1,
              paddingX: '2%',
              paddingBottom: '2%',
              paddingTop: '1.5%'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                width: '100%'
              }}
            >
              <DialogCloseButton onClose={() => setErrorMessageOpen(false)} />
            </Box>
            <Heading6 className='center' style={{ marginBottom: 30 }}>
              Erro ao reemitir o boleto
            </Heading6>
            <Box sx={{ overflow: 'auto', width: '100%' }}>
              <Typography>
                Foram encontradas pendências que impedem a reemissão automática
                da sua fatura. Pedimos que entre em contato com o atendimento
                através do email{' '}
                <a
                  href='mailto:atendimento@condofy.com.br'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  atendimento@condofy.com.br
                </a>
              </Typography>
            </Box>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: 5
              }}
            >
              <Button onClick={() => setErrorMessageOpen(false)}>Fechar</Button>
            </Box>
          </Box>
        </DialogOverlay>
      </ModalContent>
      <DialogOverlay
        open={successMessage}
        onClose={() => {
          handleClose()
          setSuccessMessage(false)
        }}
      >
        <Box
          sx={{
            backgroundColor: 'white',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            minHeight: 'cover',
            maxHeight: '70%',
            width: '22%',
            borderRadius: 1,
            paddingX: '2%',
            paddingBottom: '2%',
            paddingTop: '1.5%'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              width: '100%'
            }}
          >
            <DialogCloseButton
              onClose={() => {
                handleClose()
                setSuccessMessage(false)
              }}
            />
          </Box>
          <SendIconAnimated fontSize='medium' />
          <Heading6 className='center' style={{ marginBottom: 30 }}>
            Boleto reemitido com sucesso
          </Heading6>
          <Box sx={{ overflow: 'auto', width: '100%' }}>
            <Typography>
              Sua fatura foi reemitida e enviada para seu email. Clique{' '}
              <a
                href={newBillingLink}
                target='_blank'
                rel='noopener noreferrer'
              >
                aqui
              </a>{' '}
              e consulte os detalhes. URL da fatura nova
            </Typography>
          </Box>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: 5
            }}
          >
            <Button
              onClick={() => {
                handleClose()
                setSuccessMessage(false)
              }}
            >
              Fechar
            </Button>
          </Box>
        </Box>
      </DialogOverlay>
    </Modal>
  )
}
