import { condofyProtected, condofyProtectedMultiPart } from './instance'
import { jsonToFormData } from './formData'

export const getById = ({ currentCondominium, ticketId }) =>
  condofyProtected.get(
    `/v1/condominiums/${currentCondominium.id}/tickets/${ticketId}`
  )

export const search = ({
  currentCondominium,
  page = 1,
  statuses = '',
  RecordsPerPage = 10,
  generalSearch
}) =>
  condofyProtected.post(
    `/v1/condominiums/${currentCondominium.id}/tickets/search`,
    {
      statuses: statuses && !Array.isArray(statuses) ? [statuses] : statuses,
      generalSearch
    },
    {
      params: {
        PageNumber: page,
        RecordsPerPage
      }
    }
  )

export const createTicket = ({ currentCondominium, payload }) => {
  return condofyProtectedMultiPart.post(
    `/v1/condominiums/${currentCondominium.id}/tickets`,
    jsonToFormData(payload)
  )
}

export const listComment = ({ currentCondominium, ticket }) =>
  condofyProtected.get(
    `/v1/condominiums/${currentCondominium.id}/tickets/${ticket.id}/comments`
  )

export const createComment = ({ currentCondominium, ticket, payload }) =>
  condofyProtectedMultiPart.post(
    `/v1/condominiums/${currentCondominium.id}/tickets/${ticket.id}/comments`,
    jsonToFormData(payload)
  )
