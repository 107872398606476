import React from 'react'
import { ImageList, ImageListItem } from '@mui/material'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { theme } from '../../styles'
import { SkeletonCard } from '../Skeleton'
import { EmptyListMessage } from '../EmptyListMessage/'

const StyledGridList = styled(ImageList)`
  margin: -12px;
  display: flex!important;
  padding: 0!important;
  gap: 0px!important;
  flex-wrap: wrap;
  & .MuiCard-root {
    margin-bottom: 0 !important;
  }
  & .MuiImageListItem-tile {
    overflow: visible; /* show card shadows */
  }
  & .MuiImageListItem-root {
    width: 25%;
    padding: 12px;
    display: list-item!important;
  }
`
export const CardGrid = ({
  loading,
  recordCount,
  EmptyFeedback = <EmptyListMessage />,
  cols = 4,
  children = [],
  spacing = 3,
  ...props
}) => {
  recordCount = recordCount || children.length || 0
  const useSpacing = recordCount && theme.spacing(spacing)

  return (
    <React.Fragment>
      <StyledGridList
        rowHeight='auto'
        cols={cols}
        gap={useSpacing}
        {...props}
      >
        {loading && (
          <ImageListItem>
            <SkeletonCard />
          </ImageListItem>
        )}
        {!loading &&
          React.Children.map(children, (child) => (
            <ImageListItem>{React.cloneElement(child)}</ImageListItem>
          ))}
      </StyledGridList>
      {!loading && !recordCount && EmptyFeedback}
    </React.Fragment>
  )
}

CardGrid.propTypes = {
  loading: PropTypes.bool,
  recordCount: PropTypes.number,
  EmptyFeedback: PropTypes.node,
  cols: PropTypes.number,
  spacing: PropTypes.number
}
