import React from 'react'
import { useUserContext } from '@condofy/next/store/user'

import { ChooseModelModal } from './ChooseModelModal'
import { UpdateMessageModal } from './UpdateMessageModal'
import { PublishListModal } from './PublishListModal'

export const AnnouncementCreateModal = () => {
  const {
    state: {
      announcement: { createModalOpen, createStep }
    },
    actions: { announcementUpdateStep }
  } = useUserContext()
  return (
    <>
      <ChooseModelModal
        open={createModalOpen && createStep === 1}
        onStepChange={announcementUpdateStep}
      />
      <UpdateMessageModal
        open={createModalOpen && createStep === 2}
        onStepChange={announcementUpdateStep}
      />
      <PublishListModal open={createModalOpen && createStep === 3} />
    </>
  )
}
