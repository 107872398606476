import React, { useEffect, useCallback, useState } from 'react'
import { useUserContext } from '@condofy/next/store/user'
import { NewButton } from '@condofy/next/components'
import {
  Flex,
  Pagination,
  SearchTextField,
  TabToolbar,
  IconButton,
  Icon,
  Tooltip
} from '@condofy/components'
import { UnitsGrid, UnitsList } from '../../components/unit'
import { useDispatch } from 'react-redux'
import { toggleModal } from 'Actions/modals'
import { PROCESS_UNIT_MODAL } from 'Const'

export const UnitsTab = () => {
  const dispatch = useDispatch()
  const {
    state: {
      currentCondominium,
      isSuperAdmin,
      unit: { items, metadata }
    },
    actions: { listUnit }
  } = useUserContext()
  const stableListUnits = useCallback(listUnit, [])
  const [search, setSearch] = useState('')
  const [page, setPage] = useState(1)
  const [showGrid, setShowGrid] = useState(true)

  useEffect(() => {
    if (currentCondominium.id) {
      stableListUnits({
        PageNumber: page,
        generalSearch: search,
        currentCondominium,
        RecordsPerPage: 12,
        includeUsers: true
      })
    }
  }, [search, page, currentCondominium, stableListUnits])

  const showCreateUnitModal = () => {
    dispatch(
      toggleModal(PROCESS_UNIT_MODAL, true, { condominium: currentCondominium })
    )
  }

  return (
    <>
      <TabToolbar>
        <SearchTextField
          size='small'
          onChange={(event) => setSearch(event.target.value)}
          value={search}
        />

        <div>
          {showGrid ? (
            <Tooltip title='Lista'>
              <IconButton color='primary' onClick={() => setShowGrid(false)}>
                <Icon icon='table' />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title='Grade'>
              <IconButton color='primary' onClick={() => setShowGrid(true)}>
                <Icon icon='grid' />
              </IconButton>
            </Tooltip>
          )}

          {isSuperAdmin && (
            <NewButton onClick={showCreateUnitModal}>Novo</NewButton>
          )}
        </div>
      </TabToolbar>
      <Flex direction='column' spacingY={3}>
        <div>
          {showGrid ? (
            <UnitsGrid
              items={items}
              metadata={metadata}
              currentCondominium={currentCondominium}
              search={search}
              page={page}
            />
          ) : (
            <UnitsList
              items={items}
              metadata={metadata}
              condominium={currentCondominium}
              search={search}
              page={page}
            />
          )}
        </div>
        <Flex width='100%' justify='flex-end'>
          <Pagination
            totalResults={metadata.totalRecords}
            pageSize={metadata.recordsPerPage}
            page={page}
            disabled={metadata.loading}
            onChange={(event, page) => setPage(page)}
          />
        </Flex>
      </Flex>
    </>
  )
}
