import React, { useEffect } from 'react'
import _ from 'lodash'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormProvider, useForm } from 'react-hook-form'
import moment from 'moment'
import { useSnackbar, handleError } from '@condofy/next/hooks/useSnackbar'
import { useUserContext } from '@condofy/next/store/user'
import { useSurveyContext } from '@condofy/next/store/survey'
import { Dialog, DialogContent, FormRow } from '@condofy/components'
import { ActionRow } from '@condofy/next/components'
import { SurveyStatus } from './SurveyStatus'
import {
  surveyEventSchema,
  surveyDefaultValues as defaultValues
} from './surveyEventSchema'
import { SurveyForm } from './SurveyForm'
import { SurveyAttachments } from './SurveyAttachments'

export const UpdateSurveyModal = () => {
  const { enqueueSnackbar } = useSnackbar()
  const {
    state: {
      detail: {
        data,
        metadata: { loading: detailLoading }
      },
      update: {
        modal: { open },
        metadata: { loading },
        error
      }
    },
    actions: { closeUpdateSurveyModal, updateSurvey, listSurveys }
  } = useSurveyContext()

  const {
    state: {
      currentCondominium: { id: condominiumId }
    }
  } = useUserContext()

  const form = useForm({
    mode: 'onChange',
    resolver: yupResolver(surveyEventSchema),
    defaultValues
  })

  useEffect(() => {
    Object.keys(data).map((k) => form.setValue(k, data[k]))
  }, [data, form])

  useEffect(() => {
    if (error) {
      handleError(enqueueSnackbar)('Ocorreu um problema ao alterar a enquete')
    }
  }, [error, enqueueSnackbar])

  const handleClose = () => {
    form.reset(defaultValues)
    closeUpdateSurveyModal()
  }

  const handleSubmit = (values) => {
    updateSurvey({
      condominiumId,
      id: data.id,
      body: {
        ...values,
        startDate: moment.isMoment(values.startDate)
          ? values.startDate
          : moment(values.startDate).format(),
        endDate: moment.isMoment(values.endDate)
          ? values.endDate
          : moment(values.endDate).format()
      }
    }).then(() => {
      handleClose()
      listSurveys({ condominiumId })
    })
  }

  const updateDraft = (values) =>
    handleSubmit({ ...values, status: SurveyStatus.DRAFT })
  const updatePublished = (values) =>
    handleSubmit({ ...values, status: SurveyStatus.OPEN })

  return (
    <Dialog
      title='Alterar Enquete'
      open={open}
      onClose={handleClose}
      actions={
        <ActionRow
          loading={loading}
          cancelText='Publicar mais tarde'
          confirmText='Publicar'
          onCancel={form.handleSubmit(updateDraft)}
          onSubmit={form.handleSubmit(updatePublished)}
        />
      }
    >
      <DialogContent>
        {!detailLoading && (
          <FormProvider {...form}>
            <SurveyForm />
            {!_.isEmpty(data.attachmentUrl) && (
              <FormRow>
                <SurveyAttachments attachments={data.attachmentUrl} />
              </FormRow>
            )}
          </FormProvider>
        )}
      </DialogContent>
    </Dialog>
  )
}
