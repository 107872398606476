import { combineReducers } from '@condofy/next/store/user/combineReducers'
import { listActions, listReducer, list, listActionTypes } from './list'

// ACTION TYPES
const TICKET_TYPE_SET_LOADING_INDICATOR = 'TICKET_TYPE_SET_LOADING_INDICATOR'

export const ticketTypeActionTypes = {
  TICKET_TYPE_SET_LOADING_INDICATOR,

  ...listActionTypes
}

// ACTIONS
export const ticketTypeActions = (state, dispatch) => ({
  ...listActions(state, dispatch)
})

export const pending = (dispatch, payload) =>
  dispatch({ type: TICKET_TYPE_SET_LOADING_INDICATOR, payload })

// MIDDLEWARE
export const ticketTypeMiddlewares = (dispatch, action) => {
  switch (action.type) {
    case listActionTypes.TICKET_TYPE_LIST:
      return list(dispatch, action.payload)

    default:
      return false
  }
}

// REDUCER
const mainReducer = (state, action) => {
  switch (action.type) {
    case TICKET_TYPE_SET_LOADING_INDICATOR:
      return store(state, {
        metadata: {
          ...state.ticket.metadata,
          loading: action.payload
        }
      })

    default:
      return state
  }
}

export const ticketTypeReducer = combineReducers({
  mainReducer,
  listReducer
})

export const store = (state, value) => ({
  ...state,
  ticketType: { ...state.ticketType, ...value }
})

export const ticketType = {
  items: [],
  metadata: {
    pageNumber: 1,
    totalRecords: 0,
    recordsPerPage: 10,
    loading: false
  }
}
