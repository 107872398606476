import React, { useRef } from 'react'
import { NumericFormat } from 'react-number-format'

export const CurrencyFormatCustom = React.forwardRef(
  function CurrencyFormatCustom(props, ref) {
    const inputRef = useRef(null)
    const { onChange, fundingType, ...other } = props

    return (
      <NumericFormat
        {...other}
        getInputRef={(instance) => {
          inputRef.current = instance
          if (ref) {
            if (typeof ref === 'function') {
              ref(instance)
            } else {
              ref.current = instance
            }
          }
        }}
        onValueChange={(values) => {
          if (values.value === '') {
            onChange({
              target: {
                name: props.name,
                value: ''
              }
            })
          } else {
            onChange({
              target: {
                name: props.name,
                value: values.value
              }
            })
          }
        }}
        isAllowed={(values) => {
          const { floatValue, formattedValue } = values

          if (formattedValue === '') return true
          const isValidNumber = !isNaN(floatValue)
          const isValidLength =
            formattedValue.replace(/[^0-9,-]/g, '').length <= 14
          return isValidNumber && isValidLength
        }}
        thousandSeparator='.'
        decimalSeparator=','
        valueIsNumericString
        prefix={fundingType === '2' ? '-R$ ' : 'R$ '}
        allowNegative={true}
      />
    )
  }
)
