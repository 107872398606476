import moment from 'moment'
// eslint-disable-next-line no-unused-vars
import recur from 'moment-recur'

const CalendarEventRecurrence = {
  DAILY: 'D',
  WEEKLY: 'W',
  MONTHLY: 'M',
  YEARLY: 'Y'
}

const CalendarEventRecurrenceInterval = {
  D: 'days',
  W: 'weeks',
  M: 'months',
  Y: 'years'
}

export class CalendarEventList {
  constructor({ events = [], start, end }) {
    this._events = events
    this._start = moment(start) // date from which to start creating calendar events
    this._end = moment(end) // date to which to create calendar events
  }

  static mergeDates(day, time) {
    return moment({
      year: moment(day).year(),
      month: moment(day).month(),
      day: moment(day).date(),
      hour: moment(time).hour(),
      minute: moment(time).minute(),
      second: 0,
      millisecond: 0
    }).toDate()
  }

  _toCalendarEvent({ event, date }) {
    const mergeDates = CalendarEventList.mergeDates
    const start = date ? mergeDates(date, event.start) : new Date(event.start)
    const end = date ? mergeDates(date, event.end) : new Date(event.end)

    return {
      ...event,
      id: event.id,
      title: event.summary,
      start,
      end
      // allDay: true,
    }
  }

  _toCalendarEvents(event) {
    const eventIsVisibleInCalendar = moment(event.start).isBefore(this._end)
    const recurrences = Object.keys(CalendarEventRecurrence).map(
      (key) => CalendarEventRecurrence[key]
    )

    if (
      eventIsVisibleInCalendar &&
      recurrences.indexOf(event.recurrence) > -1
    ) {
      if (event.recurrence === CalendarEventRecurrence.YEARLY) {
        const nextOccurrences = this.calculateYearlyRecurrences(event)
        return nextOccurrences.map((date) =>
          this._toCalendarEvent({ event, date })
        )
      } else if (event.recurrence === CalendarEventRecurrence.MONTHLY){
        const nextOccurrences = this.calculateMonthlyRecurrences(event)
        return nextOccurrences.map((date) =>
          this._toCalendarEvent({ event, date })
        )
      } else if(event.recurrence === CalendarEventRecurrence.WEEKLY){
        const nextOccurrences = this.calculateWeeklyRecurrences(event)
        return nextOccurrences.map((date) =>
          this._toCalendarEvent({ event, date })
        )
      } else if(event.recurrence === CalendarEventRecurrence.DAILY){
        const nextOccurrences = this.calculateDailyRecurrences(event)
        return nextOccurrences.map((date) =>
          this._toCalendarEvent({ event, date })
        )
      }
        else {
        const interval = CalendarEventRecurrenceInterval[event.recurrence]

        return moment(event.start)
          .recur(this._end)
          .every(1, interval)
          .fromDate(this._start)
          .all()
          .map((d) => this._toCalendarEvent({ event, date: d }))
      }
    } else {
      return this._toCalendarEvent({ event })
    }
  }

  calculateDailyRecurrences(event) {
    const nextOccurrences = [];
    let currentDate = moment(event.start);
    const endDate = moment(this._end);

    while (currentDate.isBefore(endDate)) {
      nextOccurrences.push(currentDate.toDate());
      currentDate.add(1, 'day');
    }

    return nextOccurrences;
  }

  calculateYearlyRecurrences(event) {
    const nextOccurrences = []
    let currentDate = moment(event.start)
    const endDate = moment(this._end)

    while (currentDate.isBefore(endDate)) {
      nextOccurrences.push(currentDate.toDate())
      currentDate.add(1, 'year')
    }

    return nextOccurrences
  }

  calculateMonthlyRecurrences(event) {
    const nextOccurrences = [];
    let currentDate = moment(event.start);
    const endDate = moment(this._end);

    while (currentDate.isBefore(endDate)) {
      nextOccurrences.push(currentDate.toDate());
      currentDate.add(1, 'month'); // Add one month instead of one year
    }

    return nextOccurrences;
  }

  calculateWeeklyRecurrences(event) {
    const nextOccurrences = [];
    let currentDate = moment(event.start);
    const endDate = moment(this._end);

    while (currentDate.isBefore(endDate)) {
      nextOccurrences.push(currentDate.toDate());
      currentDate.add(1, 'week'); // Add one week instead of one year
    }

    return nextOccurrences;
  }

  convertToCalendarEvents() {
    return this._events
      .flatMap((e) => this._toCalendarEvents(e))
      .filter((e) => !!e)
  }
}
