import { store, ticketActionTypes } from './index'

// ACTION TYPES
const TICKET_EDIT = 'TICKET_EDIT'
const TICKET_UPDATE_SELECTED = 'TICKET_UPDATE_SELECTED'
const TICKET_EDIT_MODAL_VISIBLE = 'TICKET_EDIT_MODAL_VISIBLE'

// ACTIONS
export const editActions = (state, dispatch) => ({
  updateSelectedTicket: (data) =>
    dispatch({ type: TICKET_UPDATE_SELECTED, payload: data }),

  closeEditTicketModal: () => {
    dispatch({ type: ticketActionTypes.TICKET_SELECT, payload: false })
    dispatch({ type: TICKET_EDIT_MODAL_VISIBLE, payload: false })
  },

  openEditTicketModal: (ticket) => {
    dispatch({ type: ticketActionTypes.TICKET_SELECT, payload: ticket })
    dispatch({ type: TICKET_EDIT_MODAL_VISIBLE, payload: true })
  }
})

// REDUCER
export const editReducer = (state, action) => {
  switch (action.type) {
    case TICKET_UPDATE_SELECTED:
      return store(state, { selectedTicket: action.payload })

    case TICKET_EDIT_MODAL_VISIBLE:
      return store(state, { editModalOpen: action.payload })

    default:
      return state
  }
}

export const editActionTypes = {
  TICKET_EDIT,
  TICKET_UPDATE_SELECTED,
  TICKET_EDIT_MODAL_VISIBLE
}
