import types from './actionTypes'
import { search } from '@condofy/next/service/account'

const getPrefferedBankAccount = (bankAccountList = []) => {
  const prefferedResult = bankAccountList.find((item) => item.type === 1)
  return prefferedResult || bankAccountList[0]
}

export const applyMiddleware = (dispatch) => (action) => {
  switch (action.type) {
    case types.LOAD_BANK_ACCOUNT_LIST:
      return search(action.payload)
        .then((response) => {
          dispatch({
            type: types.LOAD_BANK_ACCOUNT_LIST_SUCCESS,
            payload: response.data
          })

          dispatch({
            type: types.SELECT_BANK_ACCOUNT,
            payload: getPrefferedBankAccount(response.data.data)
          })
        })
        .finally(() => {
          dispatch({
            type: types.SET_LOADING_INDICATOR,
            payload: false
          })
        })
    default:
      return dispatch(action)
  }
}
