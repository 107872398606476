import { Typography } from '@mui/material'
import React from 'react'
import styled from 'styled-components'

const Styled = styled(Typography)`
  font-weight: 700;
  visibility: visible;
  transition: opacity 0.7s ease, visibility 0.7s ease;
  opacity: 1;

  ${({ collapsed }) =>
    !!collapsed &&
    `
      visibility: hidden;
      opacity: 0;
    }
  `}
`

export const SidebarNavItemText = ({ collapsed = false, ...props }) => (
  <Styled
    variant='body1'
    component='span'
    noWrap
    collapsed={collapsed ? 1 : 0}
    {...props}
  />
)
