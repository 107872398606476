import React, { useCallback, useEffect, useState } from 'react'
import { Box } from '@mui/material'
import { useFinancialContext } from '@condofy/next/store/financial'
import { useBankAccountContext } from '@condofy/next/store/bank-account'
import { useUserContext } from '@condofy/next/store/user'
import { fetchRestrictedUnitList } from '@condofy/next/service/unit'
import { fetchRestrictionList } from '@condofy/next/service/restriction'
import { useSnackbar, handleError } from '@condofy/next/hooks/useSnackbar'
import { track } from '@condofy/next/service/segment'
import { EditFinancialEntryEventModal } from '../../../modules/financial/EditFinancialEntryEventModal'

export const FinancialStatementEditEntry = ({
  reloadEntryList,
  openModal,
  itemToEdit,
  onOpenEdit
}) => {
  const { enqueueSnackbar } = useSnackbar()
  const [unitList, setUnitList] = useState([])

  const {
    state: {
      detail: { metadata, error },
      categories: { items }
    },
    actions: { updateEntry, listCategory }
  } = useFinancialContext()

  const categoryList = useCallback(listCategory, [])

  useEffect(() => {
    categoryList()
  }, [categoryList])

  const {
    state: { currentCondominium }
  } = useUserContext()

  const updateUnitList = useCallback(() => {
    fetchRestrictedUnitList({
      currentCondominium,
      onSuccess: (response) => {
        const unitList = response.data

        fetchRestrictionList({
          currentCondominium,
          onSuccess: (response) => {
            response.data.forEach((restriction) => {
              const index = unitList.findIndex(
                (unit) => unit.id === restriction.unit.id
              )
              if (index > -1) {
                unitList[index].restriction = restriction
              }
            })
            setUnitList([
              {
                id: 0,
                name: '* Sem Unidade'
              },
              ...unitList
            ])
          },
          onError: (error) => {
            const errorMessage =
              error.response.data.reports[0].message || error.message
            enqueueSnackbar(errorMessage, { variant: 'error' })
          }
        })
      }
    })
  }, [])

  useEffect(() => {
    updateUnitList()
  }, [updateUnitList])

  const {
    state: {
      currentAccount: { bankAccountId }
    }
  } = useBankAccountContext()

  const handleEditEntry = async (body) => {
    const unitId = body.unitId === '0' ? null : body.unitId

    const data = {
      FundingType: body.fundingType,
      BankAccountId: bankAccountId,
      PaidAt: body.paidAt,
      CategoryId: body.categoryId,
      Description: body.description,
      Amount: body.amount,
      receipt: body.receipt,
      UnitId: unitId
    }

    try {
      const response = await updateEntry({
        condominiumId: currentCondominium.id,
        entryId: itemToEdit.id,
        body: data
      })

      /*
       * @Author: Marcos Corrêa
       * TODO: Essa regra só foi adicionado porque essa função updateEntry não está
       * caindo o catch quando existe erro de negócio.
       * TODO: Deve ser entendido o motivo pelo qual a aplicação não está conseguindo
       * entender o erro.
       * TODO: no arquivo instance.js tem um interceptador pro condofyProtected. Ele
       * está configurado corretamente, mas, mesmo com o new Promisse.reject o erro
       * não escala para a chamada do endpoint.
       */

      if (response === undefined) {
        throw new Error('422 Unprocessable Entity')
      }

      track(window.analytics, '422 - ', {
        condominium_id: currentCondominium.id,
        condominium_name: currentCondominium.name,
        ...data
      })
    } catch (error) {
      console.log('Erro capturado:', error)

      let errorMessage =
        'Ocorreu um erro ao tentar a operação. Verifique os dados e tente novamente.'

      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.reports &&
        error.response.data.reports.length > 0
      ) {
        errorMessage = error.response.data.reports[0].message
      }

      handleError(
        enqueueSnackbar(errorMessage, {
          variant: 'error'
        })
      )
    } finally {
      handleClose()
      reloadEntryList()
    }
  }

  const handleClose = () => {
    onOpenEdit(false)
  }

  return (
    <Box
      sx={{
        marginRight: 2
      }}
    >
      <EditFinancialEntryEventModal
        error={error}
        loading={metadata.loading}
        open={openModal}
        onClose={handleClose}
        onSave={handleEditEntry}
        categories={items}
        unities={unitList}
        itemToEdit={itemToEdit}
      />
    </Box>
  )
}
