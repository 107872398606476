import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Modal, ActionRow, FilePond } from '@condofy/next/components'
import { Actions } from '@Redux/storage'
import { NoteAdd } from '@mui/icons-material'
import { saveFile } from '@condofy/next/service/files'
import { useUserContext } from '@condofy/next/store/user'
import { useSnackbar } from '@condofy/next/hooks/useSnackbar'
import { track } from '@condofy/next/service/segment'
import { ModalContent } from '../../next/components/Modal'

export const CreateFileModal = ({
  open,
  onClose = () => {},
  currentFolder = []
}) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [file, setFile] = useState('')
  const {
    state: { currentCondominium }
  } = useUserContext()
  const { enqueueSnackbar } = useSnackbar()

  const buildPath = () => {
    const path = currentFolder[0].fullName.split('/')
    path.pop()
    return path.join('/')
  }

  const handleSubmit = () => {
    const isWorkspace =
      currentFolder[0].fullName.split('/').indexOf('condomínio') > 0

    setLoading(true)
    saveFile({
      path: buildPath(),
      file,
      isWorkspace,
      condominiumId: currentCondominium.id,
      onSuccess: () => {
        track(window.analytics, 'Files File Created', { path: buildPath() })
        setLoading(false)
        dispatch(Actions.storageList({ currentCondominium }))
        onClose()
        setFile('')
      },
      onError: (error) => {
        let errorMessage = error.message
        if (error.response.data)
          errorMessage = error.response.data.reports[0].message
        enqueueSnackbar(errorMessage, { variant: 'error' })
        setLoading(false)
      }
    })
  }

  return (
    <Modal
      icon={<NoteAdd />}
      title='Novo Arquivo'
      open={open}
      onClose={onClose}
      footer={
        <ActionRow
          loading={loading}
          confirmText='Adicionar'
          onSubmit={handleSubmit}
          onCancel={onClose}
          disabled={file.length > 0 ? false : true}
        />
      }
    >
      <ModalContent>
        <FilePond
          files={file}
          allowMultiple={false}
          name='files'
          onupdatefiles={(files) => setFile(files)}
        />
      </ModalContent>
    </Modal>
  )
}
