import React from 'react'
import {
  Card,
  CardContent,
  colors,
  typography,
  CurrencyText,
  theme,
  Typography
} from '@condofy/components'
import styled from 'styled-components'

const StyledCard = styled(Card)`
  height: 100%;
  font-family: ${typography.type.tertiary};
  margin: 0 !important;
`
const StyledCurrencyText = styled(CurrencyText)`
  border-bottom: solid 1px ${colors.light400};
  display: block;
  font-family: ${typography.type.tertiary} !important;
  font-size: 16px!important;
  font-weight: 600!important;
  padding-bottom: ${theme.spacing(3)};
  margin-bottom: ${theme.spacing(3)}!important;

  & .symbol,
  & .minus {
    ${({ value }) => {
      if (value >= 0) {
        return `color: ${colors.brandBlue700};`
      } else {
        return `color: ${colors.red700};`
      }
    }}
  }
`

const StyledLabel = styled(Typography)`
  color: ${colors.dark500};
  font-family: ${typography.type.tertiary} !important;
  font-size: 12px!important;
  line-height: 1.3!important;
`

export const AccountBalanceCard = ({ balance = 0, label = '', ...args }) => {
  return (
    <StyledCard {...args}>
      <CardContent>
        <StyledCurrencyText noWrap value={balance} variant='h5' />
        <StyledLabel>{label}</StyledLabel>
      </CardContent>
    </StyledCard>
  )
}
