import React, { useEffect, useState } from 'react'
import ImgBs2 from '~/assets/banco-bs2-logo.svg'
import {
  getBs2ConsentUrl,
  getConsentStatus,
  cancelConsent
} from '@condofy/next/service/bs2'
import { useSnackbar, handleError } from '@condofy/next/hooks/useSnackbar'
import { useUserContext } from '@condofy/next/store/user'
import { IntegrationCard } from './integrationCard'
import {
  Flex,
  Icon,
  colors,
  Typography,
  typography,
  theme,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  LoadingButton,
  TextField
} from '@condofy/components'
import styled from 'styled-components'

const InfoText = styled(Typography)`
  color: ${colors.dark500};
  font-family: ${typography.type.tertiary} !important;
  font-size: 12px;
  line-height: 1.3;
  margin-top: ${theme.spacing(1)} !important;
  margin-bottom: ${theme.spacing(2)} !important;
  text-align: center;
`

const BS2StatusInfo = ({ status, loading }) => {
  const icons = ['error', 'checkmark_circle_outlined']
  const statusColors = [colors.danger500, colors.green500]
  const message = [
    'Integração necessita ser reconectada',
    `Integrado por: ${status.granterName}`
  ]
  const index = status.hasError ? 0 : 1
  const visible = status.hasConsent && !loading

  return visible ? (
    <Flex direction='column' alignItems='center' justify='center'>
      <Icon
        style={{ color: statusColors[index] }}
        icon={icons[index]}
        fontSize='large'
      />
      <InfoText>{message[index]}</InfoText>
    </Flex>
  ) : (
    <></>
  )
}

const popupCenter = ({ url, title, w, h }) => {
  var left = window.screen.width / 2 - w / 2
  var top = window.screen.height / 2 - h / 2

  const newWindow = window.open(
    url,
    title,
    `
    toolbar=no, location=no, directories=no, status=no, menubar=no,
    scrollbars=no, resizable=no, copyhistory=no,
    width=${w},
    height=${h},
    top=${top},
    left=${left}
    `
  )

  if (window.focus) {
    newWindow.focus()
  }
}

export const Bs2Integration = () => {
  const {
    state: { currentCondominium }
  } = useUserContext()
  const { enqueueSnackbar } = useSnackbar()
  const [loading, setLoading] = useState(false)
  const [consentStatus, setConsentStatus] = useState({})
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)
  const [cancelText, setCancelText] = useState('')
  const [canCancel, setCanCancel] = useState(false)

  useEffect(() => {
    setCancelText('')
  }, [isDeleteDialogOpen])

  useEffect(() => {
    if (currentCondominium) {
      setLoading(true)
      getConsentStatus({ condominium: currentCondominium })
        .then(({ data }) => {
          setConsentStatus(data.data)
        })
        // .catch(handleError(enqueueSnackbar)) // if consent doesnt exist 404 is returned. Let's ignore it.
        .finally(() => setLoading(false))
    }
  }, [currentCondominium])

  const getConsent = () => {
    if (consentStatus.hasConsent && consentStatus.hasError) {
      setLoading(true)
      cancelConsent({ condominium: currentCondominium }).then(() =>
        openBS2Popup()
      )
    } else {
      openBS2Popup()
    }
  }
  const openBS2Popup = () => {
    setLoading(true)
    getBs2ConsentUrl({ condominium: currentCondominium })
      .then(({ data }) => {
        if (process.env.REACT_APP_ENVIRONMENT === 'staging') {
          let stagingUrl = data.data.url.replace(
            /app.condofy.com.br/,
            'staging.condofy.vercel.app'
          )
          popupCenter({ url: stagingUrl, title: '', w: 900, h: 800 })
        }
        popupCenter({ url: data.data.url, title: '', w: 900, h: 800 })
      })
      .catch(handleError(enqueueSnackbar))
      .finally(() => {
        setLoading(false)
      })
  }

  const openDeleteDialog = () => setIsDeleteDialogOpen(true)
  const closeDeleteDialog = () => setIsDeleteDialogOpen(false)

  const cancelBS2Consent = () => {
    setLoading(true)
    cancelConsent({ condominium: currentCondominium })
      .then(() => {
        setConsentStatus({})
        closeDeleteDialog()
      })
      .catch(handleError(enqueueSnackbar))
      .finally(() => {
        setLoading(false)
      })
  }

  const changeCancelText = (event) => {
    setCancelText(event.target.value)
    setCanCancel(event.target.value === 'CANCELAR')
  }

  return (
    <>
      <IntegrationCard
        loading={loading}
        ImageComponent={<ImgBs2 />}
        showDisconnectButton={
          consentStatus.hasConsent && !consentStatus.hasError
        }
        showConnectButton={!consentStatus.hasConsent || consentStatus.hasError}
        title='Banco BS2'
        description='Tenha sua conta totalmente integrada com nossa plataforma digital para TEDs/Pagamentos, emissão de boletos, pagamentos em débito automático e muito mais.'
        onConnect={getConsent}
        onDisconnect={openDeleteDialog}
        connectButtonText={consentStatus.hasError ? 'Reconectar' : 'Conectar'}
        AppendageComponent={
          <BS2StatusInfo status={consentStatus} loading={loading} />
        }
      />
      <Dialog
        open={isDeleteDialogOpen}
        title='Aviso'
        onClose={closeDeleteDialog}
      >
        <DialogContent>
          <p>
            Ao desconectar a integração BS2 a Condofy perderá toda a conexão com
            sua conta corrente e não poderá mais realizar nenhuma transação.
          </p>
          <p>
            Confirme abaixo que deseja realmente cancelar digitando{' '}
            <Typography color='error'>CANCELAR</Typography> no campo abaixo.
          </p>
          <p>
            <TextField
              label=''
              value={cancelText}
              onChange={changeCancelText}
            />
          </p>
        </DialogContent>
        <DialogActions>
          <Button color='secondary' onClick={closeDeleteDialog}>
            Cancelar
          </Button>
          <LoadingButton
            loading={loading}
            onClick={cancelBS2Consent}
            disabled={!canCancel}
          >
            Confirmar
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  )
}
