import { fork } from 'redux-saga/effects'
import ticket from './ticket'
import storage from './storage'
import occurrence from './occurrence'
import announcement from './announcement'
import announcementModel from './announcementModel'
import finance from './finance'

export default function* root() {
  try {
    yield fork(ticket)
    yield fork(storage)
    yield fork(occurrence)
    yield fork(announcement)
    yield fork(announcementModel)
    yield fork(finance)
  } catch (e) {
    console.error(e)
  }
}
