import { Chip } from '@condofy/components'
import React from 'react'
import { SurveyStatus } from './SurveyStatus'

const statuses = {
  [SurveyStatus.DRAFT]: {
    label: 'Em edição',
    color: 'light'
  },
  [SurveyStatus.OPEN]: {
    label: 'Em andamento',
    color: 'secondary'
  },
  [SurveyStatus.CLOSED]: {
    label: 'Encerrado',
    color: 'success'
  }
}

export const SurveyStatusChip = ({ status }) => {
  const values = statuses[status]

  return values ? <Chip color={values.color} label={values.label} /> : <></>
}
