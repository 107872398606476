export const PRODUCTION = 'production'
export const DEVELOPMENT = 'development'
export const STAGING = 'staging'
export const STAGING_DEV = 'develop'

export const REDIRECT_TO_APP = {
  [PRODUCTION]: 'https://app.condofy.com.br',
  [STAGING]: 'https://staging.condofy.vercel.app',
  [STAGING_DEV]: 'https://develop.condofy.vercel.app',
  [DEVELOPMENT]: 'http://localhost:3000'
}

export const REDIRECT_TO_AUTH = {
  [PRODUCTION]: 'https://auth.condofy.com.br',
  [STAGING]: 'https://auth-staging.condofy.vercel.app',
  [STAGING_DEV]: 'https://auth-develop.condofy.vercel.app',
  [DEVELOPMENT]: 'http://localhost:3001'
}

export const DOMAIN_URL = {
  [PRODUCTION]: 'condofy.com.br',
  [STAGING]: 'condofy.vercel.app',
  [STAGING_DEV]: 'condofy.vercel.app',
  [DEVELOPMENT]: 'localhost'
}

export const ENVIRONMENT =
  process.env.REACT_APP_ENVIRONMENT === 'staging'
    ? process.env.REACT_APP_VERCEL_GIT_COMMIT_REF ||
      process.env.REACT_APP_ENVIRONMENT ||
      STAGING
    : process.env.REACT_APP_ENVIRONMENT || STAGING
