import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Modal } from '@condofy/next/components'
import {
  FlexColumn,
  FlexRowCenteredSpaceBetween,
  Button,
  TextField,
  MenuItem
} from '@condofy/components'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import PromptModal from 'Common/PromptModal'
import {
  fetchManagers,
  disassignManager,
  assignNewManager,
  setManagersField,
  closeDeleteModal,
  openDeleteModal,
  setErrorMessage
} from 'Actions/managers'
import { getCondominiumsList } from 'Actions/condominium'
import { track } from '@condofy/next/service/segment'
import { ModalContent } from '@condofy/next/components/Modal'
import { getToken } from '@condofy/next/vendor/@condofy/token'

/* const UserSelector = styled(ReactSelect)`
  min-width: 232px;
` */

const FlexRowCenteredSpaceBetweenSelect = styled(FlexRowCenteredSpaceBetween)`
  min-height: 200px;
  align-items: baseline;
`

export default ({ options: { condoId }, closeButton, close }) => {
  const location = useLocation()
  const dispatch = useDispatch()

  const token = getToken()
  const [userList, setUserList] = useState([])
  const [user, setUser] = useState(null)
  // eslint-disable-next-line no-unused-vars
  const [filteredUserList, setFilteredUserList] = useState([])

  const {
    isModalOpen,
    selectedUser,
    managers,
    managerToDelete,
    isOpenDeleteModal
  } = useSelector(({ modal, managers }) => ({
    isModalOpen: modal.isModalOpen,
    errors: managers.errors,
    selectedUser: managers.selectedUser,
    managers: managers.managers,
    managerToDelete: managers.managerToDelete,
    isOpenDeleteModal: managers.isOpenDeleteModal
  }))

  const handleClose = () => {
    dispatch(setManagersField('selectedUser', null))
    close()
  }

  useEffect(() => {
    const filtered = userList.filter(
      (user) => !managers.some((manager) => manager.id === user.id)
    )
    setFilteredUserList(filtered)
  }, [userList, managers])

  useEffect(() => {
    retrieveCondominiumUsers()
  }, [])

  async function retrieveCondominiumUsers() {
    const response = await fetch(
      `${process.env.REACT_APP_API_HOST}/users?condominiumId=${condoId}&excludeManagers=true`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    )

    if (response.ok) {
      const responseData = await response.json()

      setUserList(responseData.data)
      return
    }
  }

  useEffect(() => {
    dispatch(fetchManagers(condoId))
  }, [dispatch, condoId])

  const handleDisassignManager = () => {
    dispatch(disassignManager(condoId, managerToDelete))
      .then(() => dispatch(fetchManagers(condoId)))
      .then(() => {
        track(window.analytics, 'Condominium Manager Removed', {
          condominium_id: condoId,
          user_id: managerToDelete.id
        })
        const search = new URLSearchParams(location.search)
        dispatch(getCondominiumsList(search.get('page')))
      })
  }

  const handleAssignNewManager = () => {
    if (selectedUser) {
      dispatch(assignNewManager(condoId, selectedUser)).then(() => {
        track(window.analytics, 'Condominium Manager Added', {
          condominium_id: condoId,
          user_id: selectedUser
        })
        dispatch(setManagersField('selectedUser', null))
        const search = new URLSearchParams(location.search)
        dispatch(getCondominiumsList(search.get('page')))
      })
    }
  }

  const onClose = () => {
    dispatch(closeDeleteModal())
    dispatch(setManagersField('selectedUser', null))
  }

  return (
    <>
      <Modal
        open={isModalOpen}
        onClose={handleClose}
        title='Síndicos do condomínio'
      >
        <ModalContent>
          <FlexRowCenteredSpaceBetweenSelect>
            <TextField
              select
              value={user}
              onChange={(event) => {
                dispatch(setManagersField('selectedUser', event.target.value))
                dispatch(setErrorMessage([]))
                setUser(event.target.value)
              }}
              style={{ width: '300px' }}
            >
              {filteredUserList.length > 0 &&
                filteredUserList.map((user) => (
                  <MenuItem key={user.id} value={user.id}>
                    {user.name}
                  </MenuItem>
                ))}
            </TextField>
            <Button onClick={handleAssignNewManager}>Novo sindico</Button>
          </FlexRowCenteredSpaceBetweenSelect>
          <FlexColumn>
            {managers.map((manager) => {
              return (
                <div
                  key={manager.id}
                  style={{
                    paddingBottom: '12px',
                    marginBottom: '12px',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between'
                  }}
                >
                  <div>{manager.name}</div>
                  <Button
                    color='secondary'
                    onClick={() => dispatch(openDeleteModal(manager.id))}
                  >
                    Desassociar
                  </Button>
                </div>
              )
            })}
          </FlexColumn>
        </ModalContent>
      </Modal>

      <PromptModal
        isOpen={isOpenDeleteModal}
        heading='Desassociar sindico'
        cancelText='Cancelar'
        confirmText='Desassociar'
        onClose={onClose}
        onConfirm={handleDisassignManager}
        closeButton={closeButton}
        backdrop={true}
      >
        Você tem certeza que quer desassociar este sindico
      </PromptModal>
    </>
  )
}
