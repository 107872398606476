import React from 'react'
import {
  CommentWithActions,
  Flex,
  Card,
  CardContent,
  theme,
  pxToRem,
  Button,
  Icon,
  colors,
  typography,
  SkeletonCard
} from '@condofy/components'
import styled from 'styled-components'
import moment from 'moment'
import { AddSubcommentCard } from './AddSubcommentCard'
import { ReplyList } from './ReplyList'
import { useUserContext } from '@condofy/next/store/user'
import { feedLike } from '@condofy/next/service/community'
import { MD5 } from '@condofy/next/service/md5'
import { track } from '@condofy/next/service/segment'

const StyledCommentMeta = styled(Flex)`
  border-top: solid 1px ${colors.light300};
  border-bottom: solid 1px ${colors.light300};
  margin-top: ${theme.spacing(3)};
  padding: ${theme.spacing(2)} 0;
`

const MetaButton = styled(Button)`
  &.MuiButton-contained {
    background: ${colors.light300};
    color: ${colors.primary};
  }
`

const CommentCountButton = styled(Button)`
  &,
  &:hover {
    background: transparent;
    font-family: ${typography.primary} !important;
    font-size: ${pxToRem(12)}rem;
    font-weight: 600;
    padding: 0;
  }
`

const CommentMeta = ({ item, isLike = false, condominiumId }) => {
  const [like, setLike] = React.useState(isLike)
  const [seeAddSubcomment, setSeeAddSubcomment] = React.useState(false)
  const toggleLike = () => setLike(!like)
  const toggleComment = () => setSeeAddSubcomment(!seeAddSubcomment)

  const {
    state: { id }
  } = useUserContext()

  const handleLiked = () => {
    toggleLike()
    feedLike({ condominiumId, id: item.id }).catch(toggleLike)
    track(window.analytics, 'Community Like Comment', {
      condominiumId: condominiumId,
      commentId: item.id,
      userId: id
    })
  }

  const handleComment = () => {
    toggleComment()
  }

  return (
    <>
      <StyledCommentMeta justify='space-between'>
        <div>
          <Flex spacingX={1}>
            <MetaButton
              color='light'
              onClick={handleComment}
              startIcon={<Icon icon='chat_outlined' size='small' />}
            >
              Comentar
            </MetaButton>
            <MetaButton
              disabled={like}
              onClick={handleLiked}
              color='light'
              startIcon={
                <Icon icon={like ? 'heart' : 'heart_outlined'} size='small' />
              }
            >
              Curtir
            </MetaButton>
          </Flex>
        </div>
        <div>
          {item.subComments > 0 && (
            <CommentCountButton color='clear'>
              {item.subComments} Comentários
            </CommentCountButton>
          )}
        </div>
      </StyledCommentMeta>
      <div>
        {seeAddSubcomment && (
          <AddSubcommentCard
            commentId={item.id}
            onChangeAddSubcomment={setSeeAddSubcomment}
          />
        )}
      </div>
    </>
  )
}

const Comments = ({ item, isAuthor, condominiumId, isSuperAdmin, isAdmin }) => {
  const date = moment(item?.publishDate, 'DD/MM/YYYY HH:mm:ss').local()
  const gravatarId = MD5(item?.user?.email)
  const gravatarImage = `https://www.gravatar.com/avatar/${gravatarId}?d=retro`

  return (
    <CommentWithActions
      avatar={gravatarImage}
      dateFormat='ago'
      name={item?.user?.name}
      date={date}
      isAuthor={isAuthor}
      condominiumId={condominiumId}
      itemId={item.id}
      isSuperAdmin={isSuperAdmin}
      isAdmin={isAdmin}
    >
      {item.description}
    </CommentWithActions>
  )
}

export const CommentList = ({ items, loading }) => {
  const {
    state: { currentCondominium, id, isSuperAdmin, isAdmin }
  } = useUserContext()

  return (
    <>
      {loading ? (
        <SkeletonCard />
      ) : (
        <>
          {items.map((item) => (
            <Card key={item.id}>
              <CardContent>
                <Comments
                  item={item}
                  isAuthor={id === item.user.id}
                  condominiumId={currentCondominium}
                  isSuperAdmin={isSuperAdmin}
                  isAdmin={isAdmin}
                />
                <CommentMeta
                  item={item}
                  isLike={item.alreadyLiked}
                  condominiumId={currentCondominium.id}
                />
                {item.subComments > 0 && (
                  <ReplyList
                    id={item.id}
                    currentCondominium={currentCondominium}
                  />
                )}
              </CardContent>
            </Card>
          ))}
        </>
      )}
    </>
  )
}
