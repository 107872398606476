import React, { useEffect, useState } from 'react'
import { Table, TableBody, TableHead, TableRow, TableContainer } from '../Table'
import PropTypes from 'prop-types'
import { SkeletonTableRow } from '../Skeleton'
import { DataTableRow } from './DataTableRow'
import { DataTableCell } from './DataTableCell'
import { EmptyRow } from './EmptyRow'
import { colors } from '../../styles'
import { TableFooter } from '@mui/material'

const styles = {
  borderless: {
    '& .MuiTableCell-root': {
      color: `${colors.dark200} !important`
    },
    '& .MuiTableCell-head': {
      color: `${colors.dark600} !important`,
      fontWeight: 600,
      border: '0 !important'
    },
    '& .MuiTableCell-footer': {
      background: 'transparent !important',
      border: '0 !important',
      color: `${colors.dark600} !important`,
      fontWeight: 600
    }
  },
  clickableRow: {
    cursor: 'pointer',
    '&:hover .MuiTableCell-root': {
      background: `${colors.light300} !important`
    }
  }
}

export const DataTable = ({
  columns = [],
  rows = [],
  size,
  loading,
  children,
  noRowsFeedback = 'Nenhum resultado',
  variant = 'standard',
  onRowClick,
  hasFooter = false,
  ...props
}) => {
  const [nCols, setNCols] = useState(5)
  const [showEmpty, setShowEmpty] = useState()
  const [showRows, setShowRows] = useState()
  const [visibleHeaders, setVisibleHeaders] = useState([])
  const [visibleFooter, setVisiblefooter] = useState(false)
  const [totalAmount, setTotalAmount] = useState(0)

  useEffect(() => {
    const visible = columns.filter((c) => !c.hide) || []
    const visibleSkeleton = columns.filter((c) => c.headerName) || []

    setVisibleHeaders(visible)
    setNCols(visibleSkeleton.length)

    if (hasFooter) {
      const hasAmountValue = columns.some((f) => f.field === 'value')
      setVisiblefooter(hasAmountValue)

      const total = rows.reduce((accumulator, currentValue) => {
        return accumulator + currentValue.amount
      }, 0)

      setTotalAmount(total)
    }
  }, [columns])

  const formatCurrency = (value) => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL'
    }).format(value)
  }

  useEffect(() => {
    const isEmpty =
      (!rows || !rows.length) && (!React.Children.count(children) || !children)
    setShowEmpty(!loading && isEmpty)
    setShowRows(!loading && !isEmpty)
  }, [rows, children, loading])

  return (
    <TableContainer>
      <Table
        size={size}
        sx={{ ...(variant === 'borderless' ? styles.borderless : {}) }}
        className={(variant === 'borderless' && 'borderless') || ''}
        {...props}
      >
        <TableHead>
          <TableRow>
            {visibleHeaders.map((c, i) => (
              <DataTableCell key={`head-${i}-${c.field}`}>
                {c.headerName}
              </DataTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {showRows &&
            rows.map((row, i) => (
              <DataTableRow
                key={`row-${i}`}
                row={row}
                sx={{ ...styles.clickableRow }}
                columns={visibleHeaders}
                onClick={() => onRowClick && onRowClick(row)}
                className={onRowClick && 'clickableRow'}
              />
            ))}
          {showRows && children}
          {showEmpty && <EmptyRow colSpan={nCols} value={noRowsFeedback} />}
          {loading && (
            <>
              {columns.length > 0 ? (
                <SkeletonTableRow colSpan={nCols} />
              ) : (
                columns.map((row, index) => (
                  <SkeletonTableRow colSpan={nCols} key={index} />
                ))
              )}
            </>
          )}
        </TableBody>
        {visibleFooter && showRows && (
          <TableFooter>
            <TableRow>
              <DataTableCell>Valor Total</DataTableCell>
              <DataTableCell>{formatCurrency(totalAmount)}</DataTableCell>
            </TableRow>
          </TableFooter>
        )}
      </Table>
    </TableContainer>
  )
}
DataTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string,
      headerName: PropTypes.string,
      valueGetter: PropTypes.func,
      renderCell: PropTypes.func,
      hide: PropTypes.bool,
      expand: PropTypes.bool
    })
  ).isRequired,
  rows: PropTypes.array,
  loading: PropTypes.bool,
  noRows: PropTypes.element,
  size: PropTypes.oneOf(['small', 'medium']),
  variant: PropTypes.oneOf(['borderless', 'standard']),
  onRowClick: PropTypes.func
}
