import { useContext } from 'react'
import { createStore } from '../core/'
import * as integration from './integration'

const subStores = [integration]

const [IntegrationContext, IntegrationProvider] = createStore(subStores)

export const useIntegrationContext = () => useContext(IntegrationContext)

export { IntegrationContext, IntegrationProvider }
