import React, { useState } from 'react'
import styled from 'styled-components'
import {
  TextField,
  typography,
  LoadingButton,
  Button,
  Flex
} from '@condofy/components'

export const ChangeButton = styled.button`
  font-family: ${typography.type.secondary};
  appearance: none;
  border: none;
  background: transparent;

  font-weight: 600;
  font-size: 14px;
  line-height: 18px;

  color: #003aa5;
`

export const EditableInput = ({
  label,
  value,
  loading,
  editable = true,
  onChange: emitChangeEvent = () => {},
  onSubmit: emitSubmitEvent = () => {}
}) => {
  const [enabled, setEnable] = useState(false)
  const toggle = () => setEnable(!enabled)

  const handleChange = ({ target: { value } }) => emitChangeEvent(value)
  const handleSubmit = () => {
    emitSubmitEvent(value)
    setEnable(false)
  }

  return (
    <Flex direction='column' spacingY={1}>
      <TextField
        label={label}
        disabled={!enabled}
        value={value}
        onChange={handleChange}
      />
      <div>
        <Flex spacingX={2}>
          {editable && (
            <Button variant='text' onClick={toggle}>
              {enabled ? 'Cancelar' : 'Editar'}
            </Button>
          )}
          {enabled && (
            <LoadingButton loading={loading} onClick={handleSubmit}>
              Mudar {label}
            </LoadingButton>
          )}
        </Flex>
      </div>
    </Flex>
  )
}
