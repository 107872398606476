import React from 'react'
import PropTypes from 'prop-types'
// import moment from 'moment'
import { Typography } from '../Typography'
import moment from 'moment-timezone'

const styles = {
  date: {
    fontSize: 'inherit !important',
    fontWeight: 'inherit',
    fontFamily: 'inherit !important',
    lineHeight: 'inherit'
  }
}

export const DateTextFormats = {
  short: 'DD/MM/YYYY',
  time: 'HH:mm',
  long: 'dddd, DD [de] MMMM [de] YYYY',
  dateTime: 'DD/MM/YYYY HH:mm',
  ago: ''
}

export const DateText = ({ date, format = 'short', hasTime, ...props }) => {
  const dateStr =
    format === 'ago'
      ? moment(date).fromNow()
      : moment(date).format(DateTextFormats[format])

  const dateStrHasTime =
    format === 'ago'
      ? moment(date).tz('America/Chicago').fromNow()
      : moment(date).tz('America/Chicago').format(DateTextFormats[format])

  return (
    <Typography
      component='span'
      className='date'
      {...props}
      sx={{ ...styles.date }}
    >
      {hasTime ? dateStrHasTime : dateStr}
    </Typography>
  )
}

DateText.propTypes = {
  date: PropTypes.any,
  format: PropTypes.oneOf(Object.keys(DateTextFormats))
}
