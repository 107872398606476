/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  LoadingButton,
  DatePicker,
  IconButton,
  Icon,
  Heading5,
  ButtonDownload
} from '@condofy/components'
import DownloadIcon from '@mui/icons-material/Download'
import AddIcon from '@mui/icons-material/Add'
import { track } from '@condofy/next/service/segment'
import { useUserContext } from '@condofy/next/store/user'
import { useBankAccountContext } from '@condofy/next/store/bank-account'
import {
  downloadToDetailed,
  downloadToReceive,
  downloadToPay
} from '@condofy/next/service/entry'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { Modal, Box, DialogContent } from '@mui/material'
import { colors, theme } from '../../../@condofy/styles'
import { DialogCloseButton } from '../../../@condofy/components/Dialog/Dialog'
import moment from 'moment'
import { SignalWifiStatusbarConnectedNoInternet4Sharp } from '@mui/icons-material'

const DialogOverlay = styled(Modal)`
  background: rgba(0, 25, 71, 0.65);
  display: flex;
  justify-content: center;
  align-items: center;

  & .MuiPaper-root {
    background-color: ${colors.light100};
    border-radius: 3px;
    box-shadow:
      0px 24px 18px rgba(23, 43, 77, 0.04),
      0px 35px 24px rgba(23, 43, 77, 0.08);
    padding: ${theme.spacing(3)};
    outline: none;

    ${({ maxWidth }) =>
      !maxWidth &&
      `
    max-width: 90vw;
      max-height: 90vh;

      ${theme.breakpoints.up('md')} {
        min-width: 35vw;
        max-width: 60vw;
      }
    `}
  }
`

const StyledDatePicker = styled(DatePicker)`
  &.MuiFormControl-root {
    width: 170px;
    margin-bottom: 25px;
  }
  & .MuiFormLabel-root {
    margin-top: -6px;
  }
  & .MuiIconButton-root {
    padding: 6px;
  }
`

const handleFile =
  ({
    name = 'download',
    type = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  }) =>
  (response) => {
    var file = new Blob([response.data], { type })
    var fileURL = URL.createObjectURL(file)
    const link = document.createElement('a')
    link.href = fileURL
    link.download = `${name}`
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

const trackDownload = (type) =>
  track(window.analytics, 'Balance Downloaded', { type })

export const FinancialStatementDownload = ({ type }) => {
  const [loading, setLoading] = useState(false)
  const [isSelectModalIsOpen, setIsSelectModalIsOpen] = useState(false)
  const [startDate, setStartDate] = useState(null)
  const [finalDate, setFinalDate] = useState(null)
  const {
    state: { currentCondominium }
  } = useUserContext()
  const {
    state: { currentAccount }
  } = useBankAccountContext()
  const { currentMonth, totalRecords } = useSelector(({ finance }) => ({
    currentMonth: finance[`${type}Month`],
    totalRecords: finance[type].metadata?.totalRecords
  }))

  useEffect(() => {
    setStartDate(
      new Date(
        moment(currentMonth).startOf('month').format('YYYY-MM-DD 00:00:00')
      )
    )
    setFinalDate(
      new Date(
        moment(currentMonth).endOf('month').format('YYYY-MM-DD 23:59:59')
      )
    )
  }, [isSelectModalIsOpen])

  const handleClose = () => {
    setIsSelectModalIsOpen(false)
    setStartDate(null)
    setFinalDate(null)
  }

  const handleDownloadExcel = (value) => () => {
    setLoading(true)
    const data = {
      currentMonth,
      currentCondominium,
      bankAccountId: currentAccount.bankAccountId,
      RecordsPerPage: totalRecords,
      initialDate: startDate,
      finalDate: finalDate
    }
    console.log({ ...data, today: new Date() })
    switch (value) {
      case 'toPay':
        trackDownload('to pay')
        downloadToPay(data)
          .then(handleFile({ name: 'lançamentos_a_pagar.xlsx' }))
          .finally(() => setLoading(false))
        break
      case 'toReceive':
        trackDownload('to receive')
        downloadToReceive(data)
          .then(handleFile({ name: 'lançamentos_a_receber.xlsx' }))
          .finally(() => setLoading(false))
        break
      default:
        trackDownload('detailed')
        downloadToDetailed(data)
          .then(handleFile({ name: 'lançamentos_detalhados.xlsx' }))
          .finally(() => setLoading(false))
    }
  }
  return (
    <>
      <Box
        sx={{
          backgroundColor: 'white',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-around'
        }}
      >
        <LoadingButton
          loading={loading}
          disabled={!totalRecords || totalRecords <= 0}
          startIcon={<DownloadIcon size='small' />}
          onClick={() => setIsSelectModalIsOpen(true)}
        >
          Download
        </LoadingButton>
      </Box>
      <DialogOverlay open={isSelectModalIsOpen} onClose={handleClose}>
        <Box
          sx={{
            backgroundColor: 'white',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            height: 'auto',
            width: '30%',
            borderRadius: 1,
            paddingX: '2%',
            paddingBottom: '2%',
            paddingTop: '1.5%'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              width: '100%'
            }}
          >
            <DialogCloseButton onClose={handleClose} />
          </Box>
          <Heading5 className='center' style={{ marginBottom: 15 }}>
            Selecione o período desejado
          </Heading5>
          <DialogContent
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <StyledDatePicker
              label='Data inicial'
              value={startDate ? startDate : new Date()}
              variant='inline'
              onChange={(date) => setStartDate(date)}
              /* sx = {{
              '.MuiIconButton-root': {
                display: 'none;'
              }
            }} */
              autoOk
              InputProps={{
                endAdornment: startDate && (
                  <IconButton onClick={() => setStartDate(null)}>
                    <Icon icon='clear' />
                  </IconButton>
                )
              }}
            />
            <StyledDatePicker
              label='Data final'
              value={finalDate ? finalDate : new Date()}
              variant='inline'
              onChange={(date) => setFinalDate(date)}
              /* sx = {{
              '.MuiIconButton-root': {
                display: 'none;'
              }
            }} */
              autoOk
              InputProps={{
                endAdornment: finalDate && (
                  <IconButton onClick={() => setFinalDate(null)}>
                    <Icon icon='clear' />
                  </IconButton>
                )
              }}
            />
          </DialogContent>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end'
            }}
          >
            <ButtonDownload
              onClick={handleDownloadExcel(type)}
              loading={loading}
              startIcon={<DownloadIcon size='small' />}
              disabled={!startDate || !finalDate}
            >
              Download
            </ButtonDownload>
          </Box>
        </Box>
      </DialogOverlay>
    </>
  )
}
FinancialStatementDownload.propTypes = {
  type: PropTypes.oneOf(['toDetailed', 'toPay', 'toReceive'])
}
