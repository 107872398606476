import { search } from '@condofy/next/service/connection'
import { pending, store } from './index'

// ACTION TYPES
const CONNECTION_LIST = 'CONNECTION_LIST'
const CONNECTION_LIST_SUCCESS = 'CONNECTION_LIST_SUCCESS'

export const listActionTypes = {
  CONNECTION_LIST,
  CONNECTION_LIST_SUCCESS
}

// ACTIONS
export const listActions = (state, dispatch) => ({
  listConnection: (payload) => dispatch({ type: CONNECTION_LIST, payload })
})

// MIDDLEWARE
export const list = async (dispatch, payload) => {
  pending(dispatch, true)
  try {
    const response = await search(payload)

    dispatch({ type: CONNECTION_LIST_SUCCESS, payload: response.data })

    return response
  } finally {
    pending(dispatch, false)
  }
}

// REDUCER
export const listReducer = (state, action) => {
  switch (action.type) {
    case CONNECTION_LIST_SUCCESS:
      return store(state, {
        items: action.payload.data,
        metadata: {
          ...state.connection.metadata,
          ...action.payload.paging
        }
      })

    default:
      return state
  }
}
