import { combineReducers } from '@condofy/next/store/user/combineReducers'
import { editActions, editReducer, edit, editActionTypes } from './edit'
import {
  detailsActions,
  detailsReducer,
  details,
  detailsActionTypes
} from './details'
import { listActions, listReducer, list, listActionTypes } from './list'

import {
  createActions,
  createReducer,
  createActionTypes,
  create
} from './create'

import {
  deleteActions,
  deleteReducer,
  remove,
  deleteActionTypes
} from './delete'

// ACTION TYPES
const USER_SET_LOADING_INDICATOR = 'USER_SET_LOADING_INDICATOR'
const USER_SELECT = 'USER_SELECT'

// ACTIONS
export const userActions = (state, dispatch) => ({
  ...createActions(state, dispatch),
  ...editActions(state, dispatch),
  ...detailsActions(state, dispatch),
  ...deleteActions(state, dispatch),
  ...listActions(state, dispatch),

  selectTicket: (payload) => dispatch({ type: USER_SELECT, payload })
})

export const pending = (dispatch, payload) =>
  dispatch({ type: USER_SET_LOADING_INDICATOR, payload })

// MIDDLEWARE
export const userMiddlewares = (dispatch, action) => {
  switch (action.type) {
    case createActionTypes.USER_CREATE:
      return create(dispatch, action.payload)

    case editActionTypes.USER_EDIT:
      return edit(dispatch, action.payload)

    case detailsActionTypes.USER_EDIT:
      return details(dispatch, action.payload)

    case deleteActionTypes.USER_DELETE:
      return remove(dispatch, action.payload)

    case listActionTypes.USER_LIST:
      return list(dispatch, action.payload)

    default:
      return false
  }
}

// REDUCER
const userMainReducer = (state, action) => {
  switch (action.type) {
    case USER_SELECT:
      return store(state, { selected: action.payload })

    case USER_SET_LOADING_INDICATOR:
      return store(state, { loading: action.payload })

    default:
      return state
  }
}

export const userReducer = combineReducers({
  userMainReducer,
  createReducer,
  editReducer,
  detailsReducer,
  listReducer,
  deleteReducer
})

export const store = (state, value) => ({
  ...state,
  user: { ...state.user, ...value }
})

export const user = {
  createModalOpen: false,
  deleteModalOpen: false,
  editModalOpen: false,
  detailsModalOpen: false,
  selected: {},
  items: [],
  metadata: {
    pageNumber: 1,
    totalRecords: 0,
    recordsPerPage: 10,
    loading: false
  }
}

export const userActionTypes = {
  USER_SET_LOADING_INDICATOR,
  USER_SELECT,

  ...createActionTypes,
  ...editActionTypes,
  ...detailsActionTypes,
  ...deleteActionTypes,
  ...listActionTypes
}
