import { Accordion as MuiAccordion, AccordionActions } from '@mui/material'
import styled from 'styled-components'

import { colors } from '../../styles'

export const Accordion = styled(MuiAccordion)`
  box-shadow: none!important;
  border-radius: 0 !important;
  border-top: solid 1px ${colors.light500};

  &:last-child {
    border-bottom: 0;
  }

  &:before {
    display: none;
  }
`

export { AccordionActions }
