import React from 'react'
import { Dialog, DialogContent } from '@condofy/components'

export const ProcessDialog = ({ title, open, onClose, actions, children }) => {
  return (
    <Dialog open={open} title={title} onClose={onClose} actions={actions}>
      <DialogContent>{children}</DialogContent>
    </Dialog>
  )
}
