import React from 'react'
import {
  Avatar,
  Flex,
  Card,
  CardContent,
  TextField,
  Button,
  pxToRem,
  theme
} from '@condofy/components'
import styled from 'styled-components'
import { useUserContext } from '@condofy/next/store/user'
import { useCommunityContext } from '@condofy/next/store/community'
import { createSubcomment } from '@condofy/next/service/community'
import { MD5 } from '@condofy/next/service/md5'
import { useSnackbar } from '@condofy/next/hooks/useSnackbar'
import { track } from '@condofy/next/service/segment'

const StyledAvatar = styled(Avatar)`
  &.MuiAvatar-root {
    margin-right: ${theme.spacing(3)} !important;
  }
`

const StyledTextField = styled(TextField)`
  &.MuiTextField-root {
    margin: ${-theme.spacing(2 / 2)} 0 ${theme.spacing(2)}
      ${-theme.spacing(3 / 2)} !important;
  }

  .MuiInputBase-input {
    font-size: ${pxToRem(20)}rem !important;
    padding: ${theme.spacing(2 / 2)} ${theme.spacing(3 / 2)} !important;
  }
  .MuiOutlinedInput {
    &-notchedOutline {
      border-color: transparent !important;
      background-color: transparent !important;
      z-index: 0;
    }
  }
`

export const AddSubcommentCard = ({ commentId, onChangeAddSubcomment }) => {
  const {
    state: { currentCondominium, email, id }
  } = useUserContext()

  const {
    actions: { loadFeedList }
  } = useCommunityContext()
  const { enqueueSnackbar } = useSnackbar()

  const gravatarId = MD5(email)
  const gravatarImage = `https://www.gravatar.com/avatar/${gravatarId}?d=retro`

  const [subComment, setSubComment] = React.useState({
    description: ''
  })

  const handleAddSubcomment = () => {
    createSubcomment({ currentCondominium, payload: { ...subComment, id: commentId } })
      .then(() => {
        setSubComment({
          description: ''
        })
        onChangeAddSubcomment(false)
        loadFeedList({ currentCondominium })
        track(window.analytics, 'Community Publish Sub-Comment', {
          condominiumId: currentCondominium.id,
          commentId: commentId,
          userId: id
        })
      })
      .catch(() =>
        enqueueSnackbar('Erro ao criar comentário.', { variant: 'error' })
      )
  }

  const handleChange = (event) => {
    const { name, value } = event.target
    setSubComment({ ...subComment, [name]: value })
  }

  return (
    <Card>
      <CardContent>
        <Flex direction='column' spacingY={3} width='100%'>
          <Flex>
            <StyledAvatar src={gravatarImage} />
            <StyledTextField
              name='description'
              value={subComment.description}
              onChange={handleChange}
              multiline
              rows={2}
              flex='1'
              placeholder='No que você está pensando?'
            />
          </Flex>
          <div>
            <Flex spacingX={2}>
              <Button onClick={handleAddSubcomment} color='primary'>
                Comentar
              </Button>
            </Flex>
          </div>
        </Flex>
      </CardContent>
    </Card>
  )
}
