import { Modal } from '../core/'

const key = 'ONBOARDING'

const onboardingModal = new Modal({ key, statePropName: 'onboarding' })
const onboardingPhoneModal = new Modal({
  key: `${key}_PHONE`,
  statePropName: 'onboardingPhone'
})
const onboardingOfferModal = new Modal({
  key: `${key}_OFFER`,
  statePropName: 'onboardingOffer'
})

export const initialState = {
  onboarding: { ...onboardingModal.initialState },
  onboardingPhone: { ...onboardingPhoneModal.initialState },
  onboardingOffer: { ...onboardingOfferModal.initialState }
}

export const createMiddleware = () => {
  return {
    ...onboardingModal.middleware(),
    ...onboardingPhoneModal.middleware(),
    ...onboardingOfferModal.middleware()
  }
}

export const useActions = (state, dispatch) => ({
  openOnboardingModal: onboardingModal.actionOpen(state, dispatch),
  closeOnboardingModal: onboardingModal.actionClose(state, dispatch),
  openOnboardingPhoneModal: onboardingPhoneModal.actionOpen(state, dispatch),
  closeOnboardingPhoneModal: onboardingPhoneModal.actionClose(state, dispatch),
  openOnboardingOfferModal: onboardingOfferModal.actionOpen(state, dispatch),
  closeOnboardingOfferModal: onboardingOfferModal.actionClose(state, dispatch)
})

export const createReducers = () => {
  return {
    ...onboardingModal.reducers(),
    ...onboardingPhoneModal.reducers(),
    ...onboardingOfferModal.reducers()
  }
}
