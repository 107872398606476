import { all, fork } from 'redux-saga/effects'
import Service from '~/lib/api/services/storage'
import { Actions } from '@Redux/storage'
import SagasUtil from '~/redux/sagas/util'

export const base = {
  baseType: 'storage',
  actions: Actions,
  service: Service
}

export const list = {
  request: 'search'
}

export const download = {
  request: 'download',
  afterRequest: ({ data }) => {
    var link = document.createElement('a')
    link.download = data.data.fileName
    link.target = '_blank'
    link.href = data.data.url
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }
}

export default function* root() {
  const sagas = SagasUtil(base, {
    list,
    download
  })
  yield all(sagas.map((saga) => fork(...saga)))
}
