export const modalInitialState = {
  modal: {
    open: false,
    data: {}
  }
}

export class Modal {
  constructor({ key, statePropName }) {
    if (!key) {
      throw Error('Modal constructor must be passed a key')
    }
    this._stateProp = statePropName
    this._key = key
    this._action = { modal: `${key}_MODAL_VISIBLE` }
    this._actionTypes = Object.keys(this._action).map((k) => {
      return { [k]: k }
    })
    this._initialState = modalInitialState
  }

  get initialState() {
    return this._initialState
  }

  set initialState(initialState) {
    this._initialState = initialState
  }

  get action() {
    return this._action
  }

  reducers() {
    return {
      [this._action.modal]: (state = this._initialState, action) => {
        const prop = this._stateProp ? this._stateProp : `${this._key}Modal`
        return {
          ...state,
          [prop]: { ...state[prop], modal: action.payload }
        }
      }
    }
  }

  middleware() {
    return () => {}
  }

  actionOpen(state, dispatch) {
    return (data) =>
      dispatch({ type: this.action.modal, payload: { open: true, data } })
  }

  actionClose(state, dispatch) {
    return () =>
      dispatch({ type: this.action.modal, payload: { open: false, data: {} } })
  }
}
