import yup from '@condofy/next/service/yup'
import moment from 'moment'

export const surveyEventSchema = yup.object().shape({
  title: yup.string().required(),
  subject: yup.string().required(),
  startDate: yup.date().required(),
  endDate: yup
    .date()
    .required()
    .default(null)
    .when(
      'startDate',
      (startDate, yup) =>
        startDate &&
        yup.min(startDate, 'Data final não pode ser antes da data inicial')
    ),
  description: yup.string().required(),
  files: yup.array()
})

export const surveyDefaultValues = {
  title: '',
  subject: '',
  description: '',
  startDate: moment().toDate(),
  endDate: moment().add(1, 'day').toDate(),
  files: []
}
