/* eslint-disable no-useless-escape */
import React, { useEffect, useState, useRef } from 'react'
import { format } from 'date-fns'
import { Modal, ModalContent } from '@condofy/next/components'
import {
  createReport,
  resendEmailReport,
  cancelReportSubmission,
  reportUpdated
} from '@condofy/next/service/accountabilityReport'
import {
  TextField,
  Button,
  FormRow,
  colors,
  Form,
  FormSectionTitle,
  FormSectionHeader,
  FormNote
} from '@condofy/components'
import styled from 'styled-components'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import ClearIcon from '@mui/icons-material/Clear'
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined'
import { Divider } from '@mui/material'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material'
import {
  Wrapper,
  SendButton,
  SuccessButton,
  WaitingButton,
  DisabledButton
} from './styles'

export const AccountabilityReportModal = ({
  openEdit,
  setOpenEdit,
  reportData,
  setReportData,
  setListChange
}) => {
  const [signer1, setSigner1] = useState({
    name: null,
    email: null,
    signedAt: null
  })
  const [signer2, setSigner2] = useState({
    name: null,
    email: null,
    signedAt: null
  })
  const [signer3, setSigner3] = useState({
    name: null,
    email: null,
    signedAt: null
  })
  const [signer4, setSigner4] = useState({
    name: null,
    email: null,
    signedAt: null
  })
  const [signer5, setSigner5] = useState({
    name: null,
    email: null,
    signedAt: null
  })
  const [signer6, setSigner6] = useState({
    name: null,
    email: null,
    signedAt: null
  })
  const [signInSequence, setSignInSequence] = useState(false)
  const [message, setMessage] = useState('')
  const [status, setStatus] = useState('')
  const [openCancelReport, setOpenCancelReport] = useState(false)
  const [openMessage, setOpenMessage] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const [openSendReport, setOpenSendReport] = useState(false)
  const [sendModalButtonsDisabled, setSendModalButtonsDisabled] =
    useState(false)
  const [cancelModalButtonsDisabled, setCancelModalButtonsDisabled] =
    useState(false)
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(false)
  const [updateComplete, setUpdateComplete] = useState(false)
  const [waitingToSent, setWaitingToSent] = useState(false)
  const [file, setFile] = useState(null)
  const [sequenceNumberToSign, setSequenceNumberToSign] = useState(false)
  const [reportStatusId, setReportStatusId] = useState(null)
  const fileInput = useRef(null)
  const [loading, setLoading] = useState(false)

  let enableSendEmail = null
  let bodyData

  const MetaButton = styled(Button)`
    &.MuiButton-contained {
      background: ${colors.light300};
      color: ${colors.primary};
    }
  `

  useEffect(() => {
    if (openEdit) {
      fillInputsFromDatabase()
    }
  }, [openEdit])

  useEffect(() => {
    if (updateComplete && waitingToSent) {
      setOpenSendReport(true)
    }
  }, [updateComplete])

  useEffect(() => {
    if (alertMessage.length > 0) {
      setOpenMessage(true)
    }
  }, [alertMessage])

  useEffect(() => {
    setReportStatusId(reportData?.status?.id)
    if (reportData.signers) {
      setSequenceNumberToSign(
        reportData.signers.some((obj) => obj.sequenceNumberToSign === 0)
      )
    }
  }, [reportData])

  function fillInputsFromDatabase() {
    if (!!reportData.signers && reportData.signers !== undefined) {
      if (reportData.signers[0]) {
        setSigner1(reportData.signers[0])
      }
      if (reportData.signers[1]) {
        setSigner2(reportData.signers[1])
      }
      if (reportData.signers[2]) {
        setSigner3(reportData.signers[2])
      }
      if (reportData.signers[3]) {
        setSigner4(reportData.signers[3])
      }
      if (reportData.signers[4]) {
        setSigner5(reportData.signers[4])
      }
      if (reportData.signers[5]) {
        setSigner6(reportData.signers[5])
      }
    }

    if (!!reportData.message && reportData.message !== undefined) {
      setMessage(reportData.message)
    }

    if (
      !!reportData.signInSequence &&
      reportData.signInSequence !== undefined
    ) {
      setSignInSequence(reportData.signInSequence)
    }

    if (!!reportData.status && reportData.status !== undefined) {
      setStatus(reportData.status.name)
    }
  }

  function validateEmail(email) {
    if (/^[\w%\+\-]+(\.[\w%\+\-]+)*@[\w%\+\-]+(\.[\w%\+\-]+)+$/.test(email)) {
      return true
    }
    return false
  }

  function validateData() {
    let counter = 0
    if (!!signer1.name && !!signer1.email) {
      if (signer1.name.trim().split(' ').length === 1) {
        setAlertMessage('O 1º signatário não contém um sobrenome.')
        return
      }
      if (!validateEmail(signer1.email)) {
        setAlertMessage('O e-mail do 1º signatário apresenta formato inválido.')
        return
      }
      counter = 1
    } else {
      if (
        (!!signer1.name && !signer1.email) ||
        (!signer1.name && !!signer1.email)
      ) {
        setAlertMessage(
          'O 1º signatário está incompleto (pendente nome ou e-mail).'
        )
        return
      }
    }

    if (!!signer2.name && !!signer2.email) {
      if (signer2.name.trim().split(' ').length === 1) {
        setAlertMessage('O 2º signatário não contém um sobrenome.')
        return
      }
      if (!validateEmail(signer2.email)) {
        setAlertMessage('O e-mail do 2º signatário apresenta formato inválido.')
        return
      }
      counter = 1
    } else {
      if (
        (!!signer2.name && !signer2.email) ||
        (!signer2.name && !!signer2.email)
      ) {
        setAlertMessage(
          'O 2º signatário está incompleto (pendente nome ou e-mail).'
        )
        return
      }
    }

    if (!!signer3.name && !!signer3.email) {
      if (signer3.name.trim().split(' ').length === 1) {
        setAlertMessage('O 3º signatário não contém um sobrenome.')
        return
      }
      if (!validateEmail(signer3.email)) {
        setAlertMessage('O e-mail do 3º signatário apresenta formato inválido.')
        return
      }
      counter = 1
    } else {
      if (
        (!!signer3.name && !signer3.email) ||
        (!signer3.name && !!signer3.email)
      ) {
        setAlertMessage(
          'O 3º signatário está incompleto (pendente nome ou e-mail).'
        )
        return
      }
    }

    if (!!signer4.name && !!signer4.email) {
      if (signer4.name.trim().split(' ').length === 1) {
        setAlertMessage('O 4º signatário não contém um sobrenome.')
        return
      }
      if (!validateEmail(signer4.email)) {
        setAlertMessage('O e-mail do 4º signatário apresenta formato inválido.')
        return
      }
      counter = 1
    } else {
      if (
        (!!signer4.name && !signer4.email) ||
        (!signer4.name && !!signer4.email)
      ) {
        setAlertMessage(
          'O 4º signatário está incompleto (pendente nome ou e-mail).'
        )
        return
      }
    }

    if (!!signer5.name && !!signer5.email) {
      if (signer5.name.trim().split(' ').length === 1) {
        setAlertMessage('O 5º signatário não contém um sobrenome.')
        return
      }
      if (!validateEmail(signer5.email)) {
        setAlertMessage('O e-mail do 5º signatário apresenta formato inválido.')
        return
      }
      counter = 1
    } else {
      if (
        (!!signer5.name && !signer5.email) ||
        (!signer5.name && !!signer5.email)
      ) {
        setAlertMessage(
          'O 5º signatário está incompleto (pendente nome ou e-mail).'
        )
        return
      }
    }

    if (!!signer6.name && !!signer6.email) {
      if (signer6.name.trim().split(' ').length === 1) {
        setAlertMessage('O 6º signatário não contém um sobrenome.')
        return
      }
      if (!validateEmail(signer6.email)) {
        setAlertMessage('O e-mail do 6º signatário apresenta formato inválido.')
        return
      }
      counter = 1
    } else {
      if (
        (!!signer6.name && !signer6.email) ||
        (!signer6.name && !!signer6.email)
      ) {
        setAlertMessage(
          'O 6º signatário está incompleto (pendente nome ou e-mail).'
        )
        return
      }
    }
    setAlertMessage('')
    return counter
  }

  function assembleMainBodyData() {
    bodyData = { signInSequence }

    const signers = []

    if (!!signer1.name && !!signer1.email) {
      signers.push({
        name: signer1.name,
        email: signer1.email
      })
    }

    if (!!signer2.name && !!signer2.email) {
      signers.push({
        name: signer2.name,
        email: signer2.email
      })
    }

    if (!!signer3.name && !!signer3.email) {
      signers.push({
        name: signer3.name,
        email: signer3.email
      })
    }

    if (!!signer4.name && !!signer4.email) {
      signers.push({
        name: signer4.name,
        email: signer4.email
      })
    }

    if (!!signer5.name && !!signer5.email) {
      signers.push({
        name: signer5.name,
        email: signer5.email
      })
    }

    if (!!signer6.name && !!signer6.email) {
      signers.push({
        name: signer6.name,
        email: signer6.email
      })
    }

    if (signers.length > 0) {
      bodyData.signers = signers
    }

    if (message) {
      bodyData.message = message
    }
  }

  const updateReport = () => {
    setSaveButtonDisabled(true)
    assembleMainBodyData()

    reportUpdated(reportData.condominiumId, reportData.id, bodyData)
      .then(() => {
        setListChange(true)
        if (!waitingToSent) {
          setAlertMessage('Relatório atualizado com sucesso!')
        }
        setSaveButtonDisabled(false)
        setUpdateComplete(true)
      })
      .catch(() => {
        setAlertMessage('Falha ao atualizar o relatório.')
        setSaveButtonDisabled(false)
      })
  }

  const sendReport = () => {
    setLoading(true)
    setSendModalButtonsDisabled(true)

    const file = fileInput.current.files[0] ?? ''

    createReport(reportData.condominiumId, reportData.id, file)
      .then(() => {
        setStatus('Em progresso')
        setListChange(true)
        setAlertMessage('Relatório enviado para assinatura!')
        setUpdateComplete(false)
        setWaitingToSent(false)
        setOpenSendReport(false)
        return
      })
      .catch(() => {
        setAlertMessage('Falha ao enviar o relatório para assinatura.')
      })
      .finally(() => {
        setOpenSendReport(false)
        setSendModalButtonsDisabled(false)
        setLoading(false)
      })
  }

  const resendEmail = (email) => {
    resendEmailReport(reportData.condominiumId, reportData.id, email)
      .then(() => {
        setAlertMessage('E-mail reenviado para assinatura!')
      })
      .catch(() => {
        setAlertMessage('Falha ao enviar o relatório para assinatura.')
      })
  }

  const cancelReport = () => {
    setCancelModalButtonsDisabled(true)

    assembleMainBodyData()

    cancelReportSubmission(reportData.condominiumId, reportData.id)
      .then(() => {
        const tempReportData = reportData
        tempReportData.status = {
          id: '4',
          name: 'Cancelado'
        }
        setReportData(tempReportData)
        setStatus('Cancelado')
        setListChange(true)
        setAlertMessage('Relatório cancelado com sucesso!')
        setOpenCancelReport(false)
        return
      })
      .catch(() => {
        setAlertMessage('Falha ao cancelar o relatório.')
        setCancelModalButtonsDisabled(false)
      })
  }

  function handleUpdateReport() {
    const validation = validateData()
    if (validation === 0) {
      setAlertMessage('É necessario incluir ao menos 1 signatário')
    } else if (validation === 1) {
      if (status !== 'Aberto') {
        setAlertMessage(
          'Apenas relatórios com status "Aberto" podem ser editados.'
        )
      } else {
        updateReport()
      }
    }
  }

  function handleSendReport() {
    if (status === 'Em progresso') {
      setAlertMessage('O relatório já foi enviado para assinatura.')
    } else {
      const validation = validateData()
      if (validation === 0) {
        setAlertMessage('É necessario incluir ao menos 1 signatário')
      } else if (validation === 1) {
        updateReport()
        setWaitingToSent(true)
      }
    }
  }

  function handleClose() {
    setOpenEdit(false)
    setReportData('')
  }

  const handleCloseMessage = () => {
    setOpenMessage(false)
    setTimeout(() => {
      setAlertMessage('')
    }, 500)
  }

  const handleFileChange = (event) => {
    setFile(event.target.files[0])
  }

  function handleFileDelete() {
    setFile(null)
    fileInput.current.value = null
  }

  function handleSelectFile() {
    fileInput.current.click()
  }

  for (let i = 0; i < reportData.signers.length - 1; i++) {
    if (
      reportData.signers[i].signedAt &&
      !reportData.signers[i + 1].signedAt &&
      reportData.signers[i + 1].sequenceNumberToSign ===
        reportData.signers[i].sequenceNumberToSign + 1
    ) {
      const nextSigner = reportData.signers[i + 1]
      enableSendEmail =
        nextSigner !== null ? nextSigner.sequenceNumberToSign : null
      break
    }
  }

  return (
    <>
      <div>
        <Dialog
          open={openMessage}
          onClose={handleCloseMessage}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle id='alert-dialog-title'>Atenção!</DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-description'>
              {alertMessage}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseMessage}>Ok</Button>
          </DialogActions>
        </Dialog>
      </div>
      <div>
        <Dialog
          open={openCancelReport}
          onClose={() => setOpenCancelReport(false)}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle id='alert-dialog-title'>Cancelar Relatório</DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-description'>
              Deseja cancelar a assinatura do relatório?<br></br>
              <b>Atenção! Essa ação é irreversível.</b>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <div
              style={{
                justifyContent: 'space-between',
                display: 'flex',
                width: '100%',
                padding: '12px'
              }}
            >
              <Button
                color='secondary'
                style={{ height: '100%' }}
                disabled={cancelModalButtonsDisabled}
                onClick={() => setOpenCancelReport(false)}
              >
                Fechar
              </Button>
              <Button
                color='light'
                loading={cancelModalButtonsDisabled}
                style={{ background: 'red', color: 'white', height: '100%' }}
                disabled={cancelModalButtonsDisabled}
                onClick={cancelReport}
              >
                Cancelar
              </Button>
            </div>
          </DialogActions>
        </Dialog>
      </div>
      <div>
        <Dialog
          open={openSendReport}
          onClose={() => setOpenSendReport(false)}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle id='alert-dialog-title'>Enviar Relatório</DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-description'>
              Confirma o envio do relatório para assinatura?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <div
              style={{
                justifyContent: 'space-between',
                display: 'flex',
                width: '100%',
                padding: '12px'
              }}
            >
              <Button
                color='secondary'
                style={{ height: '100%' }}
                disabled={sendModalButtonsDisabled}
                onClick={() => setOpenSendReport(false)}
              >
                Cancelar
              </Button>
              <Button
                loading={loading}
                style={{ height: '100%' }}
                disabled={sendModalButtonsDisabled}
                onClick={sendReport}
              >
                Confirmar
              </Button>
            </div>
          </DialogActions>
        </Dialog>
      </div>
      <Modal
        title={`${
          status === 'Finalizado' || status === 'Cancelado'
            ? 'Visualizar Signatários'
            : 'Selecionar Signatários'
        } - ${reportData.condominiumCode} - ${format(
          new Date(reportData.month + '/01/' + reportData.year),
          'MM/yyyy'
        )}${status.length > 0 ? ' (' + status.toUpperCase() + ')' : ''}`}
        open={openEdit}
        onClose={handleClose}
        footer={
          <>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row nowrap',
                width: '100%'
              }}
            >
              <div style={{ width: '40%' }}>
                {status.length > 0 &&
                  status !== 'Cancelado' &&
                  status !== 'Finalizado' && (
                    <Button
                      color='light'
                      style={{
                        background: 'red',
                        color: 'white',
                        height: '100%'
                      }}
                      onClick={() => setOpenCancelReport(true)}
                    >
                      Cancelar
                    </Button>
                  )}
              </div>
              <div
                style={{
                  width: '60%',
                  display: 'flex',
                  justifyContent: 'right',
                  alignContent: 'center',
                  alignItems: 'center'
                }}
              >
                {status !== 'Em progresso' &&
                status !== 'Finalizado' &&
                status !== 'Cancelado' ? (
                  status === '' ? (
                    <Button
                      color='secondary'
                      style={{ height: '100%' }}
                      disabled={saveButtonDisabled}
                      onClick={handleUpdateReport}
                    >
                      Salvar
                    </Button>
                  ) : (
                    <div
                      style={{
                        width: '245px',
                        display: 'flex',
                        justifyContent: 'space-between'
                      }}
                    >
                      <Button
                        color='secondary'
                        style={{
                          width: '48%',
                          display: 'flex',
                          height: '100%'
                        }}
                        disabled={saveButtonDisabled}
                        onClick={handleUpdateReport}
                      >
                        Salvar
                      </Button>
                      <Button
                        loading={loading}
                        style={{ width: '48%', display: 'flex' }}
                        onClick={handleSendReport}
                        disabled={status.length === 0}
                      >
                        Enviar
                      </Button>
                    </div>
                  )
                ) : (
                  <Button
                    color='secondary'
                    style={{ height: '100%' }}
                    onClick={() => setOpenEdit(false)}
                  >
                    Fechar
                  </Button>
                )}
              </div>
            </div>
          </>
        }
      >
        <ModalContent>
          <div style={{ display: 'flex', flexFlow: 'row' }}>
            <Form>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row  nowrap',
                  width: '100%',
                  padding: 10
                }}
              >
                <div
                  style={{
                    flexDirection: 'column',
                    width: '100%',
                    height: '100px'
                  }}
                >
                  <FormRow width={'100%'}>
                    <TextField
                      label='1º Signatário'
                      value={signer1.name}
                      onChange={(e) => {
                        setSigner1(() => ({ ...signer1, name: e.target.value }))
                      }}
                      disabled={
                        status === 'Em progresso' ||
                        status === 'Finalizado' ||
                        status === 'Cancelado'
                      }
                    />
                  </FormRow>
                  <FormRow width={'100%'}>
                    <TextField
                      style={{ width: '100%' }}
                      label='Email'
                      value={signer1.email}
                      onChange={(e) => {
                        setSigner1(() => ({
                          ...signer1,
                          email: e.target.value
                        }))
                      }}
                      disabled={
                        status === 'Em progresso' ||
                        status === 'Finalizado' ||
                        status === 'Cancelado'
                      }
                    />
                  </FormRow>
                </div>
                {reportData.cancelledAt === null && (
                  <div style={{ display: 'flex', gap: '3px' }}>
                    {reportStatusId == 2 &&
                      ((sequenceNumberToSign &&
                        signer1.email !== null &&
                        !signer1.signedAt) ||
                        (enableSendEmail === null &&
                          signer1.email !== null &&
                          signer1.signedAt === null)) && (
                        <Tooltip title='Reenviar email' arrow>
                          <SendButton
                            id='signed-button'
                            color='light'
                            onClick={() => resendEmail(signer1.email)}
                          >
                            <EmailOutlinedIcon />
                          </SendButton>
                        </Tooltip>
                      )}
                    {signer1.signedAt && (
                      <SuccessButton id='signed-button' color='light'>
                        <CheckCircleOutlineIcon />
                      </SuccessButton>
                    )}
                    {signer1.email !== null && !signer1.signedAt && (
                      <WaitingButton id='timer-button1' color='light'>
                        <HourglassBottomIcon />
                      </WaitingButton>
                    )}
                    {signer1.email === null && (
                      <DisabledButton id='empty-button1' color='light' />
                    )}
                  </div>
                )}
              </div>
            </Form>
            <Form>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row  nowrap',
                  width: '100%',
                  padding: 10
                }}
              >
                <div
                  style={{
                    flexDirection: 'column',
                    width: '100%',
                    height: '100px'
                  }}
                >
                  <FormRow width={'100%'}>
                    <TextField
                      label='2º Signatário'
                      value={signer2.name}
                      id={'signer2-name'}
                      onChange={(e) => {
                        setSigner2(() => ({ ...signer2, name: e.target.value }))
                      }}
                      disabled={
                        status === 'Em progresso' ||
                        status === 'Finalizado' ||
                        status === 'Cancelado'
                      }
                    />
                  </FormRow>
                  <FormRow width={'100%'}>
                    <TextField
                      style={{ width: '100%' }}
                      label='Email'
                      value={signer2.email}
                      onChange={(e) => {
                        setSigner2(() => ({
                          ...signer2,
                          email: e.target.value
                        }))
                      }}
                      disabled={
                        status === 'Em progresso' ||
                        status === 'Finalizado' ||
                        status === 'Cancelado'
                      }
                    />
                  </FormRow>
                </div>
                {reportData.cancelledAt === null && (
                  <div style={{ display: 'flex', gap: '3px' }}>
                    {reportStatusId == 2 &&
                      ((sequenceNumberToSign &&
                        signer2.email !== null &&
                        !signer2.signedAt) ||
                        (enableSendEmail === 2 &&
                          signer2.email !== null &&
                          signer2.signedAt === null)) && (
                        <Tooltip title='Reenviar email' arrow>
                          <SendButton
                            id='signed-button'
                            color='light'
                            onClick={() => resendEmail(signer2.email)}
                          >
                            <EmailOutlinedIcon />
                          </SendButton>
                        </Tooltip>
                      )}
                    {signer2.signedAt && (
                      <SuccessButton id='signed-button' color='light'>
                        <CheckCircleOutlineIcon />
                      </SuccessButton>
                    )}
                    {signer2.email !== null && !signer2.signedAt && (
                      <WaitingButton id='timer-button1' color='light'>
                        <HourglassBottomIcon />
                      </WaitingButton>
                    )}
                    {signer2.email === null && (
                      <DisabledButton id='empty-button1' color='light' />
                    )}
                  </div>
                )}
              </div>
            </Form>
            <Form>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row  nowrap',
                  width: '100%',
                  padding: 10
                }}
              >
                <div
                  style={{
                    flexDirection: 'column',
                    width: '100%',
                    height: '100px'
                  }}
                >
                  <FormRow width={'100%'}>
                    <TextField
                      label='3º Signatário'
                      value={signer3.name}
                      onChange={(e) => {
                        setSigner3(() => ({ ...signer3, name: e.target.value }))
                      }}
                      disabled={
                        status === 'Em progresso' ||
                        status === 'Finalizado' ||
                        status === 'Cancelado'
                      }
                    />
                  </FormRow>
                  <FormRow width={'100%'}>
                    <TextField
                      style={{ width: '100%' }}
                      label='Email'
                      value={signer3.email}
                      onChange={(e) => {
                        setSigner3(() => ({
                          ...signer3,
                          email: e.target.value
                        }))
                      }}
                      disabled={
                        status === 'Em progresso' ||
                        status === 'Finalizado' ||
                        status === 'Cancelado'
                      }
                    />
                  </FormRow>
                </div>
                {reportData.cancelledAt === null && (
                  <div style={{ display: 'flex', gap: '3px' }}>
                    {reportStatusId == 2 &&
                      ((sequenceNumberToSign &&
                        signer3.email !== null &&
                        !signer3.signedAt) ||
                        (enableSendEmail === 3 &&
                          signer3.email !== null &&
                          signer3.signedAt === null)) && (
                        <Tooltip title='Reenviar email' arrow>
                          <SendButton
                            id='signed-button'
                            color='light'
                            onClick={() => resendEmail(signer3.email)}
                          >
                            <EmailOutlinedIcon />
                          </SendButton>
                        </Tooltip>
                      )}
                    {signer3.signedAt && (
                      <SuccessButton id='signed-button' color='light'>
                        <CheckCircleOutlineIcon />
                      </SuccessButton>
                    )}
                    {signer3.email !== null && !signer3.signedAt && (
                      <WaitingButton id='timer-button1' color='light'>
                        <HourglassBottomIcon />
                      </WaitingButton>
                    )}
                    {signer3.email === null && (
                      <DisabledButton id='empty-button1' color='light' />
                    )}
                  </div>
                )}
              </div>
            </Form>
          </div>
          <div style={{ display: 'flex', flexFlow: 'row', paddingTop: '3%' }}>
            <Form>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row  nowrap',
                  width: '100%',
                  padding: 10
                }}
              >
                <div
                  style={{
                    flexDirection: 'column',
                    width: '100%',
                    height: '100px'
                  }}
                >
                  <FormRow width={'100%'}>
                    <TextField
                      label='4º Signatário'
                      value={signer4.name}
                      onChange={(e) => {
                        setSigner4(() => ({ ...signer4, name: e.target.value }))
                      }}
                      disabled={
                        status === 'Em progresso' ||
                        status === 'Finalizado' ||
                        status === 'Cancelado'
                      }
                    />
                  </FormRow>
                  <FormRow width={'100%'}>
                    <TextField
                      style={{ width: '100%' }}
                      label='Email'
                      value={signer4.email}
                      onChange={(e) => {
                        setSigner4(() => ({
                          ...signer4,
                          email: e.target.value
                        }))
                      }}
                      disabled={
                        status === 'Em progresso' ||
                        status === 'Finalizado' ||
                        status === 'Cancelado'
                      }
                    />
                  </FormRow>
                </div>
                {reportData.cancelledAt === null && (
                  <div style={{ display: 'flex', gap: '3px' }}>
                    {reportStatusId == 2 &&
                      ((sequenceNumberToSign &&
                        signer4.email !== null &&
                        !signer4.signedAt) ||
                        (enableSendEmail === 4 &&
                          signer4.email !== null &&
                          signer4.signedAt === null)) && (
                        <Tooltip title='Reenviar email' arrow>
                          <SendButton
                            id='signed-button'
                            color='light'
                            onClick={() => resendEmail(signer4.email)}
                          >
                            <EmailOutlinedIcon />
                          </SendButton>
                        </Tooltip>
                      )}
                    {signer4.signedAt && (
                      <SuccessButton id='signed-button' color='light'>
                        <CheckCircleOutlineIcon />
                      </SuccessButton>
                    )}
                    {signer4.email !== null && signer4.signedAt === null && (
                      <WaitingButton id='timer-button1' color='light'>
                        <HourglassBottomIcon />
                      </WaitingButton>
                    )}
                    {signer4.email === null && (
                      <DisabledButton id='empty-button1' color='light' />
                    )}
                  </div>
                )}
              </div>
            </Form>
            <Form>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row  nowrap',
                  width: '100%',
                  padding: 10
                }}
              >
                <div
                  style={{
                    flexDirection: 'column',
                    width: '100%',
                    height: '100px'
                  }}
                >
                  <FormRow width={'100%'}>
                    <TextField
                      label='5º Signatário'
                      value={signer5.name}
                      onChange={(e) => {
                        setSigner5(() => ({ ...signer5, name: e.target.value }))
                      }}
                      disabled={
                        status === 'Em progresso' ||
                        status === 'Finalizado' ||
                        status === 'Cancelado'
                      }
                    />
                  </FormRow>
                  <FormRow width={'100%'}>
                    <TextField
                      style={{ width: '100%' }}
                      label='Email'
                      value={signer5.email}
                      onChange={(e) => {
                        setSigner5(() => ({
                          ...signer5,
                          email: e.target.value
                        }))
                      }}
                      disabled={
                        status === 'Em progresso' ||
                        status === 'Finalizado' ||
                        status === 'Cancelado'
                      }
                    />
                  </FormRow>
                </div>
                {reportData.cancelledAt === null && (
                  <div style={{ display: 'flex', gap: '3px' }}>
                    {reportStatusId == 2 &&
                      ((sequenceNumberToSign &&
                        signer5.email !== null &&
                        !signer5.signedAt) ||
                        (enableSendEmail === 5 &&
                          signer5.email !== null &&
                          signer5.signedAt === null)) && (
                        <Tooltip title='Reenviar email' arrow>
                          <SendButton
                            id='signed-button'
                            color='light'
                            onClick={() => resendEmail(signer5.email)}
                          >
                            <EmailOutlinedIcon />
                          </SendButton>
                        </Tooltip>
                      )}
                    {signer5.signedAt && (
                      <SuccessButton id='signed-button' color='light'>
                        <CheckCircleOutlineIcon />
                      </SuccessButton>
                    )}
                    {signer5.email !== null && !signer5.signedAt && (
                      <WaitingButton id='timer-button1' color='light'>
                        <HourglassBottomIcon />
                      </WaitingButton>
                    )}
                    {signer5.email === null && (
                      <DisabledButton id='empty-button1' color='light' />
                    )}
                  </div>
                )}
              </div>
            </Form>
            <Form>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row  nowrap',
                  width: '100%',
                  padding: 10
                }}
              >
                <div
                  style={{
                    flexDirection: 'column',
                    width: '100%',
                    height: '100px'
                  }}
                >
                  <FormRow width={'100%'}>
                    <TextField
                      label='6º Signatário'
                      value={signer6.name}
                      onChange={(e) => {
                        setSigner6(() => ({ ...signer6, name: e.target.value }))
                      }}
                      disabled={
                        status === 'Em progresso' ||
                        status === 'Finalizado' ||
                        status === 'Cancelado'
                      }
                    />
                  </FormRow>
                  <FormRow width={'100%'}>
                    <TextField
                      style={{ width: '100%' }}
                      label='Email'
                      value={signer6.email}
                      onChange={(e) => {
                        setSigner6(() => ({
                          ...signer6,
                          email: e.target.value
                        }))
                      }}
                      disabled={
                        status === 'Em progresso' ||
                        status === 'Finalizado' ||
                        status === 'Cancelado'
                      }
                    />
                  </FormRow>
                </div>
                {reportData.cancelledAt === null && (
                  <div style={{ display: 'flex', gap: '3px' }}>
                    {reportStatusId == 2 &&
                      ((sequenceNumberToSign &&
                        signer6.email !== null &&
                        !signer6.signedAt) ||
                        (enableSendEmail === 6 &&
                          signer6.email !== null &&
                          signer6.signedAt === null)) && (
                        <Tooltip title='Reenviar email' arrow>
                          <SendButton
                            id='signed-button'
                            color='light'
                            onClick={() => resendEmail(signer6.email)}
                          >
                            <EmailOutlinedIcon />
                          </SendButton>
                        </Tooltip>
                      )}
                    {signer6.signedAt && (
                      <SuccessButton id='signed-button' color='light'>
                        <CheckCircleOutlineIcon />
                      </SuccessButton>
                    )}
                    {signer6.email !== null && !signer6.signedAt && (
                      <WaitingButton id='timer-button1' color='light'>
                        <HourglassBottomIcon />
                      </WaitingButton>
                    )}
                    {signer6.email === null && (
                      <DisabledButton id='empty-button1' color='light' />
                    )}
                  </div>
                )}
              </div>
            </Form>
          </div>
        </ModalContent>
        <Divider style={{ marginBottom: '1%' }} />
        <ModalContent>
          <div
            style={{
              display: 'flex',
              flexFlow: 'row',
              width: '100%',
              paddingTop: '1%',
              paddingLeft: 10
            }}
          >
            <div style={{ display: 'flex', flexFlow: 'column', width: '40%' }}>
              <div style={{ width: '280px' }}>
                <FormSectionHeader>
                  <FormSectionTitle>Assinar em sequencia?</FormSectionTitle>
                  <FormNote style={{ color: '#4D5E7E' }}>
                    SIM = O documento será assinado em ordem e um após o outro.
                  </FormNote>
                </FormSectionHeader>
                <FormSectionHeader>
                  <FormNote style={{ color: '#4D5E7E' }}>
                    NÃO = Todos os signatários receberão o documento ao mesmo
                    tempo.
                  </FormNote>
                </FormSectionHeader>
              </div>
              <div
                style={{
                  display: 'flex',
                  alignContent: 'left',
                  alignItems: 'left',
                  justifyContent: 'space-between',
                  width: '70%',
                  marginTop: '5px',
                  minWidth: 230
                }}
              >
                <MetaButton
                  style={{
                    width: '110px',
                    display: 'flex',
                    alignContent: 'center',
                    justifyContent: 'left',
                    alignItems: 'center'
                  }}
                  color='light'
                >
                  <input
                    type='radio'
                    style={{
                      marginRight: '8px',
                      display: 'flex',
                      alignItems: 'center',
                      alignContent: 'center',
                      paddingBottom: '4px'
                    }}
                    name='signingSequence'
                    value='sequence'
                    checked={signInSequence}
                    onChange={() => setSignInSequence(true)}
                    disabled={
                      status === 'Em progresso' ||
                      status === 'Finalizado' ||
                      status === 'Cancelado'
                    }
                  />
                  <div style={{ color: 'gray' }}>Sim</div>
                </MetaButton>
                <MetaButton
                  style={{
                    width: '110px',
                    display: 'flex',
                    alignContent: 'center',
                    justifyContent: 'left',
                    alignItems: 'center'
                  }}
                  color='light'
                >
                  <input
                    type='radio'
                    style={{
                      marginRight: '8px',
                      display: 'flex',
                      alignItems: 'center',
                      alignContent: 'center',
                      paddingBottom: '4px'
                    }}
                    name='signingSequence'
                    value='no-sequence'
                    checked={!signInSequence}
                    onChange={() => setSignInSequence(false)}
                    disabled={
                      status === 'Em progresso' ||
                      status === 'Finalizado' ||
                      status === 'Cancelado'
                    }
                  />
                  <div style={{ color: 'gray' }}>Não</div>
                </MetaButton>
              </div>
            </div>
            <div style={{ resize: 'none', width: '70%' }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%'
                }}
              >
                <FormSectionTitle>Mensagem</FormSectionTitle>
                <textarea
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  // multiline
                  label={'Mensagem'}
                  style={{
                    resize: 'none',
                    padding: '12px 20px',
                    border: '1px #DCDCDC solid',
                    borderRadius: '4px',
                    fontFamily: 'arial'
                  }}
                  rows={6}
                  id='TxtObservacoes'
                  maxLength={200}
                  // fullWidth
                  disabled={
                    status === 'Em progresso' ||
                    status === 'Finalizado' ||
                    status === 'Cancelado'
                  }
                ></textarea>
                <div
                  style={{
                    padding: '10px 0',
                    display: 'flex',
                    flexDirection: 'column'
                  }}
                >
                  <input
                    type='file'
                    id='fileInput'
                    style={{ display: 'none' }}
                    onChange={handleFileChange}
                    ref={fileInput}
                  />
                  {reportStatusId == 1 && (
                    <MetaButton
                      startIcon={<FileUploadOutlinedIcon size='small' />}
                      color='light'
                      onClick={handleSelectFile}
                    >
                      Anexar arquivo
                    </MetaButton>
                  )}
                  {file && (
                    <Wrapper>
                      <FormSectionTitle>{file.name}</FormSectionTitle>
                      <IconButton
                        aria-label='delete'
                        color='warning'
                        onClick={() => handleFileDelete()}
                      >
                        <ClearIcon />
                      </IconButton>
                    </Wrapper>
                  )}
                </div>
              </div>
            </div>
          </div>
        </ModalContent>
      </Modal>
    </>
  )
}
