const detailInitialState = {
  data: {},
  metadata: {
    loading: false
  },
  error: null
}

export class Detail {
  constructor({ key, statePropName = 'detail' }) {
    if (!key) {
      throw Error('Detail constructor must be passed a key')
    }
    this._key = key
    this._stateProp = statePropName
    this._action = {
      load: `LOAD_${key}_DETAIL`,
      pending: `LOAD_${key}_DETAIL_PENDING`,
      success: `LOAD_${key}_DETAIL_SUCCESS`,
      error: `LOAD_${key}_DETAIL_ERROR`
    }
    this._initialState = detailInitialState
  }

  get initialState() {
    return this._initialState
  }

  set initialState(initialState) {
    this._initialState = initialState
  }

  get actionTypes() {
    return this._action
  }

  reducers() {
    return {
      [this._action.success]: (state = this._initialState, action) =>
        this._successReducer(state, action),
      [this._action.pending]: (state = this._initialState, action) =>
        this._pendingReducer(state, action),
      [this._action.error]: (state = this._initialState, action) =>
        this._errorReducer(state, action)
    }
  }

  middleware(detailFunc) {
    return {
      [this._action.load]: this._loadDetail(detailFunc)
    }
  }

  actions(state, dispatch) {
    return (data) => dispatch({ type: this._action.load, payload: data })
  }

  _successReducer(state, action) {
    return {
      ...state,
      [this._stateProp]: {
        ...state[this._stateProp],
        data: {
          ...(action.payload.data.data || action.payload.data),
          ...action.payload.item
        }
      }
    }
  }

  _pendingReducer(state, action) {
    return {
      ...state,
      [this._stateProp]: {
        ...state[this._stateProp],
        metadata: {
          ...state[this._stateProp]?.metadata,
          loading: action.payload
        }
      }
    }
  }

  _errorReducer(state, action) {
    return {
      ...state,
      [this._stateProp]: {
        ...state[this._stateProp],
        ...action.payload.item,
        error: action.payload
      }
    }
  }

  _pendingDetail(dispatch, payload) {
    dispatch({ type: this._action.pending, payload })
  }

  _loadDetail(callback) {
    return async (dispatch, payload) => {
      this._pendingDetail(dispatch, true)
      try {
        const response = await callback(payload)

        dispatch({
          type: this._action.success,
          payload: { data: response.data || response, item: payload.item }
        })
      } catch (error) {
        dispatch({ type: this._action.error, payload: error })
      } finally {
        this._pendingDetail(dispatch, false)
      }
    }
  }
}
