import { downloadDefautingReport } from '@condofy/next/service/billings'

export const useDownloadDefaultingReport = ({
  currentCondominium,
  selected,
  stopLoading
}) => {
  try {
    downloadDefautingReport({ currentCondominium, selected }).then(
      (response) => {
        var file = new Blob([response.data], { type: 'application/pdf' })
        var fileURL = URL.createObjectURL(file)
        const link = document.createElement('a')
        link.href = fileURL
        link.download = 'relatorio-inadimplencia.pdf'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        stopLoading()
      }
    )
  } catch (error) {
    stopLoading()
    console.log(error)
  }
}
