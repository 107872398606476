import React from 'react'

export const EventWithCondoAndTimeFlat = ({ title, event }) => (
  <div className='rbc-event-labels-wrapper'>
    <div className='rbc-event-condo-label'>
      {event.condominiumCode}
    </div>
    <div className='rbc-event-content'>
      {title}
    </div>
  </div>
)
