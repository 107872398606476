import React, { useState } from 'react'
import { useUserContext } from '@condofy/next/store/user'
import { ProcessAreaModal } from './ProcessAreaModal'

export const CreateAreaModal = () => {
  const {
    state: {
      area: { createModalOpen }
    },
    actions: { createArea, closeCreateAreaModal }
  } = useUserContext()
  const [area, setArea] = useState({
    name: '',
    description: '',
    regulation: '',
    paymentType: 0,
    price: 0,
    maxTimesPerMonth: 0,
    weekdaysAvailable: [...Array(7).keys()]
  })

  const onClose = () => {
    setArea({})
    closeCreateAreaModal()
  }

  return (
    <ProcessAreaModal
      title='Nova Área'
      trackMsg='Booking Area Created'
      area={area}
      onOpen={createModalOpen}
      onClose={onClose}
      onSave={createArea}
      onChange={setArea}
    />
  )
}
