import { combineReducers } from '@condofy/next/store/user/combineReducers'
import { listActions, listReducer, list, listActionTypes } from './list'
import { editActions, editReducer, editActionTypes } from './edit'
import {
  createActions,
  createReducer,
  createActionTypes,
  create
} from './create'

// ACTION TYPES
const OCCURENCE_SET_LOADING_INDICATOR = 'OCCURENCE_SET_LOADING_INDICATOR'
const OCCURENCE_SELECT = 'OCCURENCE_SELECT'

// ACTIONS
export const occurenceActions = (state, dispatch) => ({
  ...createActions(state, dispatch),
  ...listActions(state, dispatch),
  ...editActions(state, dispatch),

  selectOccurence: (payload) => dispatch({ type: OCCURENCE_SELECT, payload })
})

export const pending = (dispatch, payload) =>
  dispatch({ type: OCCURENCE_SET_LOADING_INDICATOR, payload })

// MIDDLEWARE
export const occurenceMiddlewares = (dispatch, action) => {
  switch (action.type) {
    case createActionTypes.OCCURENCE_CREATE:
      return create(dispatch, action.payload)

    case listActionTypes.OCCURENCE_LIST:
      return list(dispatch, action.payload)

    default:
      return false
  }
}

// REDUCER
const occurenceMainReducer = (state, action) => {
  switch (action.type) {
    case OCCURENCE_SELECT:
      return store(state, { selected: action.payload })

    case OCCURENCE_SET_LOADING_INDICATOR:
      return store(state, {
        metadata: {
          ...state.occurence.metadata,
          loading: action.payload
        }
      })

    default:
      return state
  }
}

export const occurenceReducer = combineReducers({
  occurenceMainReducer,
  createReducer,
  editReducer,
  listReducer
})

export const store = (state, value) => ({
  ...state,
  occurence: { ...state.occurence, ...value }
})

export const occurence = {
  createModalOpen: false,
  deleteModalOpen: false,
  editModalOpen: false,
  selected: {},
  items: [],
  metadata: {
    pageNumber: 1,
    totalRecords: 0,
    recordsPerPage: 10,
    loading: false
  }
}

export const occurenceActionTypes = {
  OCCURENCE_SET_LOADING_INDICATOR,
  OCCURENCE_SELECT,

  ...createActionTypes,
  ...listActionTypes,
  ...editActionTypes
}
