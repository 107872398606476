import { createConnection } from '@condofy/next/service/connection'
import { store, pending, connectionActionTypes } from './index'

// ACTION TYPES
const CONNECTION_CREATE = 'CONNECTION_CREATE'
const CONNECTION_CREATE_MODAL_VISIBLE = 'CONNECTION_CREATE_MODAL_VISIBLE'
const USER_CONNECTION_CREATE_MODAL_VISIBLE =
  'USER_CONNECTION_CREATE_MODAL_VISIBLE'

export const createActionTypes = {
  CONNECTION_CREATE,
  CONNECTION_CREATE_MODAL_VISIBLE
}

// ACTIONS
export const createActions = (state, dispatch) => ({
  requestConnection: (payload) =>
    dispatch({ type: CONNECTION_CREATE, payload }),

  closeCreateConnectionModal: () => {
    dispatch({ type: connectionActionTypes.CONNECTION_SELECT, payload: false })
    return dispatch({ type: CONNECTION_CREATE_MODAL_VISIBLE, payload: false })
  },

  openCreateConnectionModal: (user) => {
    dispatch({ type: connectionActionTypes.CONNECTION_SELECT, payload: user })
    return dispatch({ type: CONNECTION_CREATE_MODAL_VISIBLE, payload: true })
  },

  closeCreateUserConnectionModal: () => {
    dispatch({ type: connectionActionTypes.CONNECTION_SELECT, payload: false })
    return dispatch({
      type: USER_CONNECTION_CREATE_MODAL_VISIBLE,
      payload: false
    })
  },

  openCreateUserConnectionModal: (user) => {
    dispatch({ type: connectionActionTypes.CONNECTION_SELECT, payload: user })
    return dispatch({
      type: USER_CONNECTION_CREATE_MODAL_VISIBLE,
      payload: true
    })
  }
})

// MIDDLEWARE
export const create = async (dispatch, payload) => {
  try {
    pending(dispatch, true)
    const response = await createConnection(payload)
    return response
  } finally {
    pending(dispatch, false)
  }
}

// REDUCER
export const createReducer = (state, action) => {
  switch (action.type) {
    case CONNECTION_CREATE_MODAL_VISIBLE:
      return store(state, {
        createModalOpen: action.payload
      })

    case USER_CONNECTION_CREATE_MODAL_VISIBLE:
      return store(state, {
        userModalOpen: action.payload
      })

    default:
      return state
  }
}
