import { List, Detail, Modal, Update, Create } from '../core/'
import {
  searchOffers,
  detailOffer,
  updateOffer,
  createOffer
} from '@condofy/next/service/community'

const key = 'LISTING'

const listingList = new List({ key, statePropName: 'listListings' })
const listingDetail = new Detail({ key, statePropName: 'detailListing' })
const listingDetailModal = new Modal({ key, statePropName: 'detailListing' })

const listingUpdate = new Update({ key, statePropName: 'updateListing' })
const listingUpdateModal = new Modal({
  key: `${key}_EDIT`,
  statePropName: 'updateListing'
})
const listingCreate = new Create({ key, statePropName: 'createListing' })
const listingCreateModal = new Modal({
  key: `${key}_CREATE`,
  statePropName: 'createListing'
})

export const initialState = {
  listListings: { ...listingList.initialState },
  detailListing: {
    ...listingDetailModal.initialState,
    ...listingDetail.initialState
  },
  updateListing: {
    ...listingUpdate.initialState,
    ...listingUpdateModal.initialState
  },
  createListing: {
    ...listingCreate.initialState,
    ...listingCreateModal.initialState
  }
}

export const createMiddleware = () => {
  return {
    ...listingList.middleware(searchOffers),
    ...listingDetail.middleware(detailOffer),
    ...listingDetailModal.middleware(),
    ...listingUpdate.middleware(updateOffer),
    ...listingUpdateModal.middleware(),
    ...listingCreate.middleware(createOffer),
    ...listingCreateModal.middleware()
  }
}

export const useActions = (state, dispatch) => ({
  loadListingList: listingList.actions(state, dispatch),
  openDetailModal: (data) => {
    listingDetail.actions(state, dispatch)(data)
    listingDetailModal.actionOpen(state, dispatch)()
  },
  closeDetailModal: listingDetailModal.actionClose(state, dispatch),
  updateListing: listingUpdate.actions(state, dispatch),
  openUpdateListingModal: (data) => {
    listingDetail.actions(state, dispatch)(data)
    listingUpdateModal.actionOpen(state, dispatch)(data)
  },
  closeUpdateListingModal: listingUpdateModal.actionClose(state, dispatch),
  creatListing: listingCreate.actions(state, dispatch),
  openCreateListingModal: listingCreateModal.actionOpen(state, dispatch),
  closeCreateListingModal: listingCreateModal.actionClose(state, dispatch)
})

export const createReducers = () => {
  return {
    ...listingList.reducers(),
    ...listingDetail.reducers(),
    ...listingDetailModal.reducers(),
    ...listingUpdate.reducers(),
    ...listingUpdateModal.reducers(),
    ...listingCreate.reducers(),
    ...listingCreateModal.reducers()
  }
}
