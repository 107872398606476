import { createActions, createReducer } from 'reduxsauce'

import Util from '../util'

const { action, request, savePayload } = new Util('ticket')

const INITIAL_STATE = {
  list: [],
  comments: [],
  types: [],
  search: '',
  pending: {},
  metadata: {
    limit: 10,
    count: 1,
    page: 1
  }
}

export const { Types, Creators: Actions } = createActions({
  ...action('search', ['', 'success', 'error', 'pending']),
  ...action('searchStatus', ['', 'success', 'error', 'pending']),
  ...action('comments', ['', 'success', 'error', 'pending']),
  ...action('types', ['', 'success', 'error', 'pending']),
  ...action('create', ['', 'success', 'error', 'pending']),
  ...action('createComment', ['', 'success', 'error', 'pending'])
})

export const HANDLERS = {
  ...request('search'),
  [Types.TICKET_SEARCH_STATUS]: savePayload,
  ...request('searchStatus', 'search'),
  ...request('comments'),
  ...request('types'),
  ...request('create'),
  ...request('createComment')
}

export const Reducer = createReducer(INITIAL_STATE, HANDLERS)
