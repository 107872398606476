import React from 'react'
import { Modal, ActionRow, ModalContent } from '@condofy/next/components'
import { useUserContext } from '@condofy/next/store/user'
import { useSnackbar, handleError } from '@condofy/next/hooks/useSnackbar'
import { track } from '@condofy/next/service/segment'

export const UserDeleteModal = () => {
  const { enqueueSnackbar } = useSnackbar()

  const {
    state: {
      currentCondominium,
      user: { selected, deleteModalOpen, loading }
    },
    actions: { closeDeleteUserModal: close, deleteUser, listUser }
  } = useUserContext()

  const handleSuccess = () => {
    track(window.analytics, 'User Removed', {
      ...selected,
      condominium_id: currentCondominium.id
    })
    enqueueSnackbar('Usuário removido com sucesso', {
      variant: 'success'
    })
    close()
    listUser({ currentCondominium })
  }

  const handleSubmit = () => {
    deleteUser(selected).then(handleSuccess).catch(handleError(enqueueSnackbar))
  }

  return (
    <Modal
      title='Excluir Usuário'
      open={deleteModalOpen}
      onClose={close}
      footer={
        <ActionRow
          loading={loading}
          confirmText='Deletar'
          onSubmit={handleSubmit}
          onCancel={close}
        />
      }
    >
      <ModalContent>
        Você tem certeza que quer deletar {selected.name}?<br />
        Ao deletar esse usuário ele não terá mais acesso ao sistema.
        <br />
        Deseja continuar?
      </ModalContent>
    </Modal>
  )
}
