import FormData from 'form-data'

export const jsonToFormData = (object) => {
  const formData = new FormData()

  Object.keys(object).forEach((key) =>
    Array.isArray(object[key])
      ? object[key].map((a) => formData.append(key, a))
      : formData.append(key, object[key])
  )

  return formData
}
