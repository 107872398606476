import React, { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useSnackbar, handleError } from '@condofy/next/hooks/useSnackbar'
import { useUserContext } from '@condofy/next/store/user'
import { useSurveyContext } from '@condofy/next/store/survey'
import {
  Dialog,
  DialogContent,
  Flex,
  Heading6,
  Icon,
  SmallBody,
  Form,
  FormRow
} from '@condofy/components'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormField } from '@condofy/next/containers/form/FormField'
import yup from '@condofy/next/service/yup'
import { ActionRow } from '@condofy/next/components'
import { SurveyStatus } from './SurveyStatus'
import { SurveyAttachments } from './SurveyAttachments'
import { Poll } from './Poll'
import { Box } from '@mui/material'
import { PollResults } from './PollResults'
import { PollComments } from './PollComments'
import { ConfirmVote } from './ConfirmVote'
import { PollOptions } from './PollOptions'

const voteSchema = yup.object().shape({
  vote: yup.mixed().oneOf(Object.values(PollOptions)),
  comment: yup.string()
})

export const VoteModal = () => {
  const [canVote, setCanVote] = useState(false)
  const [confirmVote, setConfirmVote] = useState(false)
  const form = useForm({
    mode: 'onChange',
    resolver: yupResolver(voteSchema),
    defaultValues: {
      vote: '',
      comment: ''
    }
  })
  const watchVote = form.watch()
  const { enqueueSnackbar } = useSnackbar()
  const {
    state: {
      detail: {
        data: survey,
        metadata: { loading: loadingData }
      },
      vote: {
        modal: { open },
        metadata: { loading: loadingVote },
        error
      }
    },
    actions: { closeVoteModal, createVote, listSurveys }
  } = useSurveyContext()

  const {
    state: {
      isSuperAdmin,
      isAdmin,
      currentCondominium: { id: condominiumId }
    }
  } = useUserContext()

  useEffect(() => {
    setConfirmVote(Boolean(watchVote.vote))
  }, [watchVote])

  useEffect(() => {
    const isAdministratorUser = isSuperAdmin || isAdmin

    setCanVote(
      !survey.hasVoted &&
        !isAdministratorUser &&
        survey.status !== SurveyStatus.CLOSED
    )
  }, [isSuperAdmin, isAdmin, survey, confirmVote])

  useEffect(() => {
    if (error) {
      // handleError(enqueueSnackbar)('Ocorreu um problema ao registar o voto')
      handleError(enqueueSnackbar)(error)
    }
  }, [error, enqueueSnackbar])

  const handleClose = () => {
    form.reset()
    closeVoteModal()
  }

  const handleSubmit = (values) => {
    createVote({ condominiumId, surveyId: survey.id, body: values }).then(
      () => {
        handleClose()
        listSurveys({ condominiumId })
      }
    )
  }

  return (
    <Dialog
      title='Enquete'
      open={open}
      onClose={handleClose}
      icon={<Icon icon='chart_bar_4' />}
      actions={
        confirmVote && (
          <ActionRow
            loading={loadingVote}
            confirmText='Confirmar'
            cancelText='Cancelar'
            onCancel={handleClose}
            onSubmit={form.handleSubmit(handleSubmit)}
          />
        )
      }
    >
      <DialogContent>
        {!loadingData && (
          <>
            <Flex spacingY={2} direction='column'>
              <Heading6>{survey.title}</Heading6>
              <SmallBody>{survey.subject}</SmallBody>
              <SmallBody>{survey.description}</SmallBody>
              <SurveyAttachments attachments={survey.attachmentUrl} />
            </Flex>
            <FormProvider {...form}>
              <Form>
                <Box marginTop={3}>
                  {!confirmVote ? (
                    canVote ? (
                      <FormField name='vote' Component={<Poll />} />
                    ) : (
                      <Flex direction='column' spacingY={3}>
                        <PollResults votes={survey.votes} />
                        <PollComments votes={survey.votes} />
                      </Flex>
                    )
                  ) : (
                    <FormRow>
                      <FormField
                        name='comment'
                        Component={
                          <ConfirmVote vote={form.getValues('vote')} />
                        }
                      />
                    </FormRow>
                  )}
                </Box>
              </Form>
            </FormProvider>
          </>
        )}
      </DialogContent>
    </Dialog>
  )
}
