import React from 'react'
import { Pagination as MuiPagination } from '@mui/material/'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { theme, typography, controls, colors } from '../../styles'

const StyledPagination = styled(MuiPagination)`
  .MuiPaginationItem-root {
    background-color: ${colors.light100};
    border-radius: 0;
    border: solid 1px ${controls.field.borderColor};
    font-family: ${typography.type.tertiary};
    height: 40px;
    line-height: 40px;
    margin: 0 -1px 0 0;
    padding: 0 ${theme.spacing(2)};

    &.Mui-disabled {
      background-color: ${colors.light200};
    }
    &.Mui-selected:not(.Mui-disabled) {
      &,
      &:hover {
        color: ${colors.light100};
        background-color: ${colors.primary};
        border-color: ${colors.primary};
      }
    }
  }
  .MuiPaginationItem-page:hover {
    background-color: ${colors.brandBlue50};
  }
  .MuiPagination-ul li:first-child .MuiPaginationItem-root {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  .MuiPagination-ul li:last-child .MuiPaginationItem-root {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
`

export const Pagination = ({
  variant = 'outlined',
  shape = 'rounded',
  color = 'primary',
  count,
  totalResults = 1,
  pageSize = 1,
  disabled,
  ...props
}) => {
  const newCount = count || Math.ceil(totalResults / pageSize) || 1
  const newDisabled = disabled || newCount <= 1

  return (
    <StyledPagination
      count={newCount}
      variant={variant}
      shape={shape}
      color={color}
      disabled={newDisabled}
      {...props}
    />
  )
}
Pagination.propTypes = {
  count: PropTypes.number,
  totalResults: PropTypes.number,
  pageSize: PropTypes.number
}
