import React from 'react'
import { CurrencyText, DateText, Typography } from '@condofy/components'
import { FinancialStatementItemCategories } from './FinancialStatementItemCategories'
import { FinancialStatementItemTooltip } from './FinancialStatementItemTooltip'
import { FinancialStatementDowloadItemButton } from './FinancialStatementDowloadItemButton'

export const financialStatementColumns = ({ type, hide }) => [
  {
    field: 'date',
    headerName: 'Data',
    renderCell: ({ row }) => {
      const item = row.level > 1 ? row.parent : row
      const date =
        type === 'toDetailed' && item.settlementDate
          ? item.settlementDate
          : item.dueDate

      return <DateText date={date} />
    },
    type: 'date'
  },
  {
    field: 'unit',
    headerName: 'Unidade',
    valueGetter: (params) =>
      params && params.row && params.row.unit && params.row.unit.name
        ? params.row.unit.name
        : ' '
  },
  {
    field: 'categories',
    headerName: 'Categoria',
    renderCell: ({ row }) => {
      const categories = row.items.map((item) => item.category?.name)
      return (
        <FinancialStatementItemCategories
          variant={row.level > 1 ? 'normal' : 'compact'}
          categories={categories}
          canEdit={!row.level || row.level < 2}
          item={row}
        />
      )
    }
  },
  {
    field: 'item',
    headerName: 'Item',
    renderCell: ({ row }) => {
      return (
        <FinancialStatementItemTooltip items={row.items}>
          <Typography component='span' noWrap style={{ fontSize: 'inherit' }}>
            {row.items.length > 0 && row.items[0].description}{' '}
            {row.items.length > 1 && `(${row.items.length})`}
          </Typography>
        </FinancialStatementItemTooltip>
      )
    }
  },
  { field: 'documentNumber', headerName: 'Documento' },
  {
    field: 'amount',
    headerName: 'Valor',
    renderCell: ({ row }) => <CurrencyText colorSign value={row.amount} />
  },
  {
    field: 'balance',
    headerName: 'Saldo',
    hide: type !== 'toDetailed',
    renderCell: ({ row }) => {
      return row.balance !== null ? (
        <CurrencyText colorSign value={Number(row.balance || 0)} />
      ) : (
        <p> - </p>
      )
    }
  },
  {
    field: 'download',
    headerName: '',
    hide: type !== 'toDetailed',
    padding: 'none',
    renderCell: ({ row }) => <FinancialStatementDowloadItemButton item={row} />
  },
  { field: 'actions', headerName: '', hide: hide },
  { expand: true, padding: 'none' }
]
