import React from 'react'
import { colors, typography } from '../../styles'
const merge = require('deepmerge')

import {Chart, ArcElement, CategoryScale, LinearScale, PointElement, LineElement, BarElement} from 'chart.js'
Chart.register(ArcElement, CategoryScale, LinearScale, PointElement, LineElement, BarElement);

export const ChartJsConfig = () => {
  const defaults = {
     bar: {
      scales: {
        x: {
          gridLines: {
            display: false,
            zeroLineColor: colors.light500
          }
        },
        y: {
          gridLines: {
            color: colors.light400,
            zeroLineWidth: 2,
            zeroLineColor: colors.light500,
            borderDash: [1, 3]
          }
        },
      }
    },
    line: { scales: { x: [], y: [] } }
  }

  const global = merge(defaults.global, {
    defaultFontFamily: typography.type.tertiary,
    defaultFontColor: colors.text,
    datasets: {
      bar: {
        categoryPercentage: 0.5
      }
    }
  })
  defaults.global = global

  return <React.Fragment></React.Fragment>
}
