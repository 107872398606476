import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Icon, Popover as MuiPopover } from '@mui/material'

export const Popover = ({
  children,
  id,
  Button,
  anchorOrigin = { vertical: 'bottom', horizontal: 'left' },
  transformOrigin = { vertical: 'top', horizontal: 'left' },
  ...props
}) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const isOpen = Boolean(anchorEl)
  const open = (element) => setAnchorEl(element)
  const close = () => setAnchorEl(null)

  const handleClick = (event) => {
    event.preventDefault()
    event.stopPropagation()
    open(event.currentTarget)
  }

  const handleClose = (event) => {
    event.preventDefault()
    event.stopPropagation()
    close()
  }

  const CustomButton =
    Button &&
    React.cloneElement(Button, {
      onClick: handleClick,
      open: isOpen
    })

  const modifiedChildren = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      const { closeOnClick, ...childProps } = child.props
      const filteredChild = { ...child, props: childProps }

      return React.cloneElement(filteredChild, {
        onClick: (event) => {
          if (closeOnClick) {
            handleClose(event)
          }
          if (child.props.onClick) {
            child.props.onClick(event)
          }
        }
      })
    }
  })

  return (
    <div>
      {CustomButton ? (
        CustomButton
      ) : (
        <Button
          aria-controls={id}
          aria-haspopup='true'
          onClick={handleClick}
          variant='text'
        >
          <Icon icon='more_vertical' />
        </Button>
      )}
      <MuiPopover
        open={isOpen || false}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        {...props}
      >
        {modifiedChildren}
      </MuiPopover>
    </div>
  )
}

Popover.propTypes = {
  Button: PropTypes.any
}
