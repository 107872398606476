import React, { useCallback, useEffect, useState } from 'react'
import { useUserContext } from '@condofy/next/store/user'
import {
  Button,
  Card,
  CardHeader,
  CardContent,
  Flex,
  Pagination,
  DataTable,
  DateText
} from '@condofy/components'
import { toggleModal } from 'Actions/modals'
import { ADD_ANNOUNCEMENT_MESSAGE_MODAL } from 'Const'
import { useDispatch } from 'react-redux'
import { AnnouncementStatusChip } from './AnnouncementStatusChip'
import { AnnouncementsListFilters } from './AnnouncementListFilters'

export const AnnouncementList = ({ className }) => {
  const dispatch = useDispatch()
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')
  const [status, setStatus] = useState('')
  const {
    state: {
      isSuperAdmin,
      isAdmin,
      currentCondominium,
      announcement: { items: announcementList, metadata, createModalOpen }
    },
    actions: {
      listAnnouncement,
      openCreateAnnouncementModal,
      openEditAnnouncementModal
    }
  } = useUserContext()
  const administratorUser = isSuperAdmin || isAdmin
  const stableListAnnouncement = useCallback(listAnnouncement, [])

  useEffect(() => {
    if (currentCondominium.id) {
      stableListAnnouncement({
        currentCondominium,
        page: page,
        recordsPerPage: 10,
        generalSearch: search,
        generalStatus: status
      })
    }
  }, [currentCondominium, page, stableListAnnouncement, search, status])

  useEffect(() => {
    if (currentCondominium.id && !createModalOpen) {
      setTimeout(
        () =>
          stableListAnnouncement({
            currentCondominium,
            page: page,
            recordsPerPage: 10,
            generalSearch: search,
            generalStatus: status
          }),
        500
      )
    }
  }, [createModalOpen])

  const handleEdit = (announcement) => {
    if (administratorUser && announcement.status === 'A') {
      openEditAnnouncementModal(announcement)
    } else {
      dispatch(toggleModal(ADD_ANNOUNCEMENT_MESSAGE_MODAL, true, announcement))
    }
  }

  const columns = [
    {
      headerName: 'Titulo',
      field: 'title'
    },
    {
      headerName: 'Autor',
      valueGetter: ({ row }) => row.createdBy?.name
    },
    {
      headerName: 'Data',
      renderCell: ({ row }) => <DateText format='long' date={row.createdAt} />
    },
    {
      headerName: 'Status',
      renderCell: ({ row }) => <AnnouncementStatusChip status={row.status} />
    }
  ]
  return (
    <>
      <Card className={className}>
        <CardHeader
          title='Comunicados'
          action={
            <>
              <AnnouncementsListFilters
                search={search}
                onSearch={setSearch}
                status={status}
                onStatus={setStatus}
              />
              {administratorUser && (
                <Button onClick={openCreateAnnouncementModal}>+ Novo</Button>
              )}
            </>
          }
        />
        <CardContent>
          <DataTable
            size='small'
            variant='borderless'
            loading={metadata.loading}
            columns={columns}
            rows={announcementList}
            onRowClick={handleEdit}
          />
        </CardContent>
      </Card>
      <Flex width='100%' justify='flex-end'>
        <Pagination
          page={page}
          totalResults={metadata.totalRecords}
          pageSize={metadata.recordsPerPage}
          onChange={(event, page) => setPage(page)}
          disabled={metadata.loading}
        />
      </Flex>
    </>
  )
}
