import { errorParser } from 'Middleware/parsers'

import {
  TOGGLE_NEW_CONNECTION_MODAL,
  CHANGE_NEW_CONNECTION_FIELD,
  CREATE_NEW_CONNECTION,
  CREATE_NEW_CONNECTION_SUCCESS,
  CREATE_NEW_CONNECTION_FAIL,
  SET_CONNECTIONS,
  SET_USER_CONNECTION,
  CHANGE_USER_CONNECTION_FIELDS,
  UPDATE_USER_CONNECTION_START,
  UPDATE_USER_CONNECTION_SUCCESS,
  UPDATE_USER_CONNECTION_FAIL,
  CLEAR_USER_CONNECTION
} from 'Actions/connection'

const SET_LOADING = 'SET_LOADING'

const initialState = {
  newConnection: {
    code: '',
    role: '',
    unit: ''
  },
  errors: {
    code: '',
    role: '',
    unit: '',
    global: []
  },
  userConnection: {
    id: '',
    condoId: '',
    role: '',
    unitId: '',
    isManager: false
  },
  userConnectionErrors: {
    role: '',
    unitId: '',
    global: []
  },
  connections: [],
  isOpenConnectionModal: false,
  isLoading: false
}

const connection = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_NEW_CONNECTION_MODAL: {
      const newConnectionWrapper = action.isOpenConnectionModal
        ? {}
        : { newConnection: initialState.newConnection }

      return {
        ...state,
        ...newConnectionWrapper,
        isOpenConnectionModal: action.isOpenConnectionModal,
        errors: initialState.errors
      }
    }
    case CHANGE_NEW_CONNECTION_FIELD:
      return {
        ...state,
        newConnection: {
          ...state.newConnection,
          [action.key]: action.value
        }
      }

    case SET_LOADING:
      return {
        ...state,
        isLoading: action.payload
      }

    case CREATE_NEW_CONNECTION:
      return {
        ...state,
        errors: initialState.errors,
        isLoading: true
      }

    case CREATE_NEW_CONNECTION_SUCCESS:
      return {
        ...state,
        newConnection: initialState.newConnection,
        errors: initialState.errors,
        isOpenConnectionModal: false,
        isLoading: false
      }

    case CREATE_NEW_CONNECTION_FAIL: {
      return {
        ...state,
        isLoading: false
      }
    }
    case SET_CONNECTIONS:
      return {
        ...state,
        connections: action.connections
      }
    case SET_USER_CONNECTION:
      return {
        ...state,
        userConnection: action.connection
      }
    case CHANGE_USER_CONNECTION_FIELDS:
      return {
        ...state,
        userConnection: {
          ...state.userConnection,
          [action.key]: action.value
        }
      }
    case UPDATE_USER_CONNECTION_START:
      return {
        ...state,
        isLoading: true
      }
    case UPDATE_USER_CONNECTION_SUCCESS:
      return {
        ...state,
        userConnectionErrors: {},
        isLoading: false
      }
    case UPDATE_USER_CONNECTION_FAIL:
      return {
        ...state,
        userConnectionErrors: errorParser(action.errors),
        isLoading: false
      }
    case CLEAR_USER_CONNECTION:
      return {
        ...state,
        isLoading: false,
        userConnectionErrors: {},
        userConnection: {}
      }
    default:
      return {
        ...state
      }
  }
}

export default connection
