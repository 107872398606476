import React from 'react'
import { TextField } from './TextField'
import { InputAdornment } from '@mui/material'
import { Icon } from '../Icon'

export const SearchTextField = (props) => (
  <TextField
    {...props}
    placeholder={props.placeholder || 'Pesquisar'}
    InputProps={{
      endAdornment: (
        <InputAdornment position='end'>
          <Icon icon='search' />
        </InputAdornment>
      )
    }}
  />
)
