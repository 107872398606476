/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { NumericFormat } from 'react-number-format'
import { TextField } from './TextField'
import PropTypes from 'prop-types'

const NumberCustom = React.forwardRef(({inputRef, decimalScale = 0, onChange, ...props}, ref) => (
  <NumericFormat
    getInputRef={ref}
    onValueChange={onChange}
    decimalScale={decimalScale}
    isNumericString
    decimalSeparator=','
    {...props}
  />
))

export const NumberTextField = ({
  value: incomingValue,
  onChange = () => {},
  inputRef,
  thousandSeparator,
  decimalSeparator,
  decimalScale,
  fixedDecimalScale,
  isNumericString,
  ...props
}) => {
  const [value, setValue] = useState()
  const [shrink, setShrink] = useState(false)

  const onValueChange = (values) => {
    setValue(Number(values.floatValue))
    onChange({
      target: {
        name: props.name || '',
        value: Number(values.floatValue)
      }
    })
  }

  useEffect(() => {
    if (!value) {
      setValue(incomingValue)
      setShrink(true)
    }
  }, [incomingValue])

  return (
    <TextField
      ref={inputRef}
      value={value}
      onChange={onValueChange}
      InputLabelProps={{ shrink }}
      {...props}
      InputProps={{
        inputComponent: NumberCustom,
        inputProps: {
          thousandSeparator,
          decimalSeparator,
          decimalScale,
          fixedDecimalScale,
          isNumericString
        }
      }}
    />
  )
}
NumberTextField.propTypes = {
  decimalScale: PropTypes.number
}
