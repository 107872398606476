import types from './actionTypes'
import { search, detail } from '@condofy/next/service/billings'

const pendingList = (dispatch, payload) => {
  dispatch({ type: types.LOAD_BILLING_LIST_PENDING, payload })
}

const pendingDetail = (dispatch, payload) => {
  dispatch({ type: types.LOAD_BILLING_DETAIL_PENDING, payload })
}

const pendingDeclaration = (dispatch, payload) => {
  dispatch({ type: types.LOAD_BILLING_DECLARATION_PENDING, payload })
}

const loadBillingList = async (dispatch, payload) => {
  pendingList(dispatch, true)
  try {
    const response = await search(payload)
    dispatch({ type: types.LOAD_BILLING_LIST_SUCCESS, payload: response.data })
  } finally {
    pendingList(dispatch, false)
  }
}

const loadBillingDetail = async (dispatch, payload) => {
  pendingDetail(dispatch, true)
  try {
    const response = await detail(payload)
    dispatch({
      type: types.LOAD_BILLING_DETAIL_SUCCESS,
      payload: { data: response.data, billing: payload.billing }
    })
  } finally {
    pendingDetail(dispatch, false)
  }
}

const loadBillingDeclaration = async (dispatch, payload) => {
  pendingDeclaration(dispatch, true)
  try {
    dispatch({
      type: types.LOAD_BILLING_DECLARATION_SUCCESS,
      payload: { units: payload.unitList }
    })
  } finally {
    pendingDeclaration(dispatch, false)
  }
}

export const applyMiddleware = (dispatch) => (action) => {
  switch (action.type) {
    case types.LOAD_BILLING_LIST:
      return loadBillingList(dispatch, action.payload)
    case types.LOAD_BILLING_DETAIL:
      return loadBillingDetail(dispatch, action.payload)
    case types.LOAD_BILLING_DECLARATION:
      return loadBillingDeclaration(dispatch, action.payload)
    default:
      return dispatch(action)
  }
}
