export const userTypeListWithNoManager = [
  { key: 2, children: 'Proprietário', label: 'Proprietário', value: 3 },
  { key: 3, children: 'Inquilino', label: 'Inquilino', value: 4 },
  { key: 4, children: 'Imobiliária', label: 'Imobiliária', value: 5 }
  // { label: 'Supplier', value: 'supplier' },
  // { label: 'Employee', value: 'employee' },
]

export const userTypeList = [
  { key: 2, children: 'Síndicos', label: 'Síndico', value: 2 },
  ...userTypeListWithNoManager
]
