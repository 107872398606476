import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import {
  Form,
  FormRow,
  Skeletonize,
  Comment,
  theme,
  Attachment,
  Icon,
  Flex,
  Textarea
} from '@condofy/components'
import {
  Modal,
  ActionRow,
  FilePond,
  ModalContent
} from '@condofy/next/components'
import { useUserContext } from '@condofy/next/store/user'
import { useSnackbar, handleError } from '@condofy/next/hooks/useSnackbar'

const CommentWrapper = styled.div`
  margin-bottom: ${theme.spacing(4)};
`

const AttachmentList = styled.div`
  margin-top: ${theme.spacing(2)};
`

const Footer = ({ loading }) => {
  const { enqueueSnackbar } = useSnackbar()
  const [comment, setComment] = useState('')
  const [files, setFiles] = useState([])
  const {
    state: {
      currentCondominium,
      ticket: { selected }
    },
    actions: { listTicketComment, createTicketComment }
  } = useUserContext()
  const handleSuccess = () => {
    setComment('')
    setFiles([])
    setTimeout(() => {
      listTicketComment({ currentCondominium, ticket: selected })
    }, 2000)
    enqueueSnackbar('Comentário adicionado com sucesso', { variant: 'success' })
  }

  const onSubmit = () => {
    createTicketComment({
      currentCondominium,
      ticket: selected,
      payload: {
        comment,
        files
      }
    })
      .then(handleSuccess)
      .catch(handleError(enqueueSnackbar))
  }

  return (
    <Flex direction='column' width='100%'>
      <Form>
        <FormRow>
          <Textarea
            label='Mensagem'
            rows={1}
            rowsMax={4}
            value={comment}
            onChange={(event) => setComment(event.target.value)}
          />
        </FormRow>
        <FormRow>
          {/* Zendesk API file limit is 20MB */}
          <FilePond
            files={files}
            allowMultiple={true}
            maxTotalFileSize='19MB'
            setFile={setFiles}
          />
        </FormRow>
      </Form>
      <ActionRow
        confirmText='Enviar Mensagem'
        loading={loading}
        onSubmit={onSubmit}
      />
    </Flex>
  )
}

export const TicketEditModal = () => {
  const {
    state: {
      currentCondominium,
      ticket: { editModalOpen, selected },
      ticketComment: { items: commentList, metadata }
    },
    actions: { listTicketComment, closeEditTicketModal: close }
  } = useUserContext()
  const stableListTicketComment = useCallback(listTicketComment, [])

  useEffect(() => {
    if (currentCondominium && selected.id)
      stableListTicketComment({ currentCondominium, ticket: selected })
  }, [currentCondominium, selected, stableListTicketComment])

  const canIncludeComments = () =>
    selected.status !== 'solved' && selected.status !== 'closed'

  return (
    <Modal
      open={editModalOpen}
      onClose={close}
      icon={<Icon icon='inbox' />}
      title={`${selected.subject} - Chamado: ${selected.id}`}
      sx={{
        '.MuiDialogTitle-root>div': {
          lineHeight: 1
        }
      }}
      footer={canIncludeComments() && <Footer loading={metadata.loading} />}
    >
      <ModalContent>
        <Skeletonize condition={metadata.loading}>
          {commentList.map((comment) => (
            <CommentWrapper key={comment.id}>
              <Comment
                name={comment.author}
                date={comment.createdAt}
                dateFormat='dateTime'
              >
                {comment.body}
              </Comment>
              <AttachmentList>
                {comment.attachments.map(({ contentUrl, fileName }) => (
                  <Attachment
                    key={contentUrl}
                    name={fileName}
                    url={contentUrl}
                  />
                ))}
              </AttachmentList>
            </CommentWrapper>
          ))}
        </Skeletonize>
      </ModalContent>
    </Modal>
  )
}
