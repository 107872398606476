import React, { useCallback, forwardRef, useEffect, useState } from 'react'
import { useSurveyContext } from '@condofy/next/store/survey'
import {
  Button,
  Card,
  CardHeader,
  Pagination,
  CardContent,
  Flex,
  DataTable,
  Icon,
  ListItemIcon,
  ListItemText,
  MenuItem,
  colors
} from '@condofy/components'
import styled from 'styled-components'
import { useUserContext } from '@condofy/next/store/user'
import { track } from '@condofy/next/service/segment'
import { SurveyStatusChip } from './SurveyStatusChip'
import { SurveyDateFromNow } from './SurveyDateFromNow'
import { SurveyStatus } from './SurveyStatus'
// import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material'
import { getToken } from '@condofy/next/vendor/@condofy/token'
// import { SURVEY_VOTE_MODAL } from 'Const'
// import { toggleModal } from 'Actions/modals'
// import { useDispatch } from 'react-redux'

export const SurveyList = (props) => {
  const token = getToken()

  const [page, setPage] = useState(1)

  const [openDeleteSurvey, setOpenDeleteSurvey] = useState(false)
  const [surveyData, setSurveyData] = useState()
  const [removeModalButtonsDisabled, setRemoveModalButtonsDisabled] =
    useState(false)
  const [openMessage, setOpenMessage] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const [listChange, setListChange] = useState(false)

  const ActionMenuItem = forwardRef(({ label, icon, color, ...props }, ref) => (
    <StyledMenuItem ref={ref} color={color} {...props}>
      <ListItemIcon
        style={{
          width: '100%',
          justifyContent: 'center',
          alignContent: 'right',
          alignItems: 'right'
        }}
      >
        <Icon fontSize='small' icon={icon} />
      </ListItemIcon>
      <ListItemText primary={label} />
    </StyledMenuItem>
  ))

  const StyledMenuItem = styled(MenuItem)`
    .MuiListItemIcon-root,
    .MuiListItemText-primary {
      ${({ color }) => color && ` color: ${color}`}
    }
  `

  useEffect(() => {
    if (alertMessage.length > 0) {
      setOpenMessage(true)
    }
  }, [alertMessage])

  const columns = [
    { headerName: 'Pergunta', field: 'title' },
    { headerName: 'Respostas', valueGetter: ({ row }) => row.votes.length },
    {
      headerName: 'Data',
      renderCell: ({ row }) => <SurveyDateFromNow date={row.endDate} />
    },
    {
      headerName: 'Status',
      renderCell: ({ row }) => <SurveyStatusChip status={row.status} />
    },
    {
      headerName: '',
      renderCell: ({ row }) => (
        <ActionMenuItem
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '25%',
            marginLeft: '75%',
            borderRadius: 5
          }}
          ref={ref}
          icon='delete_outlined'
          color={colors.danger400}
          onClick={(e) => {
            e.stopPropagation()
            setSurveyData(row)
            setOpenDeleteSurvey(true)
          }}
        />
      )

      // <Button
      //   color='light'
      //   style={{ background: 'red', color: 'white', width: 'auto' }}
      //   // startIcon={<DeleteForeverIcon size='small' />}
      //   onClick={(e) => {
      //     e.stopPropagation();
      //     setSurveyData(row);
      //     setOpenDeleteSurvey(true);
      //   }
      //   }
      // >
      //   Remover</Button>
    }
  ]
  const ref = React.createRef()
  const {
    state: {
      isSuperAdmin,
      isAdmin,
      currentCondominium: { id: condominiumId }
    }
  } = useUserContext()

  const {
    state: {
      list: {
        items: surveys = [],
        metadata: { loading, totalRecords, recordsPerPage }
      }
    },
    actions: {
      listSurveys,
      openCreateSurveyModal,
      openUpdateSurveyModal,
      openVoteModal
    }
  } = useSurveyContext()

  const administratorUser = isSuperAdmin || isAdmin
  const stableListSurveys = useCallback(listSurveys, [])

  useEffect(() => {
    stableListSurveys({
      condominiumId,
      page
    })
    setListChange(false)
  }, [condominiumId, page, stableListSurveys, listChange])

  const openCreateModal = () => {
    openCreateSurveyModal()
  }

  // const dispatch = useDispatch()
  const showVoteModal = (survey) => {
    openVoteModal({ condominiumId, id: survey.id, item: survey })
    // dispatch(
    //   toggleModal(SURVEY_VOTE_MODAL, true, {
    //     ...survey,
    //     hasVoted: administratorUser || survey.hasVoted
    //   })
    // )
  }

  const openUpdateModal = (survey) => {
    openUpdateSurveyModal({ condominiumId, id: survey.id, item: survey })
  }

  const handleRowClick = (survey) => {
    if (survey.status === SurveyStatus.OPEN) {
      track(window.analytics, 'Survey Vote Opened', survey)
      showVoteModal(survey)
    } else {
      if (administratorUser && survey.status === SurveyStatus.DRAFT) {
        openUpdateModal(survey)
      } else {
        track(window.analytics, 'Survey Details Opened', survey)
        showVoteModal(survey)
      }
    }
  }

  async function deleteSurvey() {
    setRemoveModalButtonsDisabled(true)

    const response = await fetch(
      `${process.env.REACT_APP_API_HOST}/v1/condominiums/${condominiumId}/surveys/${surveyData.id}`,
      {
        method: 'DELETE',
        mode: 'cors',
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    )

    if (response.ok) {
      setListChange(true)
      setAlertMessage('Enquete removida com sucesso!')
      setOpenDeleteSurvey(false)
      return
    }
    setAlertMessage('Falha ao remover a enquete.')
    setRemoveModalButtonsDisabled(false)
  }

  const handleCloseMessage = () => {
    setOpenMessage(false)
    setTimeout(() => {
      setAlertMessage('')
    }, 500)
  }

  return (
    <>
      <>
        <Card {...props}>
          <CardHeader
            title='Enquetes'
            action={
              administratorUser && (
                <Button onClick={openCreateModal}>+ Novo</Button>
              )
            }
          />
          <CardContent>
            <DataTable
              size='small'
              variant='borderless'
              loading={loading}
              columns={columns}
              rows={surveys}
              onRowClick={handleRowClick}
            />
          </CardContent>
        </Card>
        <Flex width='100%' justify='flex-end'>
          <Pagination
            page={page}
            totalResults={totalRecords}
            pageSize={recordsPerPage}
            onChange={(event, page) => setPage(page)}
            disabled={loading}
          />
        </Flex>
      </>
      <div>
        <Dialog
          open={openDeleteSurvey}
          onClose={() => setOpenDeleteSurvey(false)}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle id='alert-dialog-title'>Remover Enquete</DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-description'>
              Deseja remover a enquete?<br></br>
              <b>Atenção! Essa ação é irreversível.</b>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <div
              style={{
                justifyContent: 'space-between',
                display: 'flex',
                width: '100%',
                padding: '12px'
              }}
            >
              <Button
                color='secondary'
                style={{ height: '100%' }}
                disabled={removeModalButtonsDisabled}
                onClick={() => setOpenDeleteSurvey(false)}
              >
                Cancelar
              </Button>

              <Button
                color='light'
                style={{ background: 'red', color: 'white', height: '100%' }}
                disabled={removeModalButtonsDisabled}
                onClick={deleteSurvey}
              >
                Remover
              </Button>
            </div>
          </DialogActions>
        </Dialog>
      </div>
      <div>
        <Dialog
          open={openMessage}
          onClose={handleCloseMessage}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle id='alert-dialog-title'>Atenção!</DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-description'>
              {alertMessage}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseMessage}>Ok</Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  )
}
