import React, { useCallback, useEffect } from 'react'
import { useBankAccountContext } from '@condofy/next/store/bank-account'
import { useUserContext } from '@condofy/next/store/user'
import { CardGrid } from '@condofy/components'
import { AccountBalanceCard } from './AccountBalanceCard'

export const AccountBalanceList = () => {
  const {
    state: { currentCondominium }
  } = useUserContext()

  const {
    state: { items, total, metadata },
    actions: { loadBankAccountList }
  } = useBankAccountContext()
  const stableLoadBankAccountList = useCallback(loadBankAccountList, [])

  useEffect(() => {
    if (currentCondominium.id) {
      stableLoadBankAccountList({ condominiumId: currentCondominium.id })
    }
  }, [currentCondominium.id, stableLoadBankAccountList])

  return (
    <CardGrid loading={metadata.loading}>
      {items.map((item) => (
        <AccountBalanceCard
          key={item.bankAccountId}
          balance={item.balance}
          label={item.nickName}
        />
      ))}
      <AccountBalanceCard key='total' balance={total} label='Total' />
    </CardGrid>
  )
}
