import yup from '@condofy/next/service/yup'

export const financialEventSchema = yup.object().shape({
  description: yup.string().required(),
  paidAt: yup.date().required(),
  fundingType: yup.string().required(),
  categoryId: yup.string().required(),
  amount: yup.number().required(),
  unitId: yup.string()
})
