import React, { useState } from 'react'
import { UserForm } from './UserForm'
import { useSnackbar, handleError } from '@condofy/next/hooks/useSnackbar'
import { Modal, ActionRow, ModalContent } from '@condofy/next/components'
import { useUserContext } from '@condofy/next/store/user'
import { track } from '@condofy/next/service/segment'

export const UserCreateModal = () => {
  const {
    state: {
      currentCondominium,
      user: { loading, createModalOpen }
    },
    actions: { listUser, createUser, closeCreateUserModal: close }
  } = useUserContext()
  const { enqueueSnackbar } = useSnackbar()

  const [user, setUser] = useState({
    name: '',
    socialSecurityNumber: '',
    email: '',
    phoneNumber: '',
    password: '',
    confirmPassword: '',
    isManager: false
  })

  const handleClose = () => {
    setUser(undefined)
    close()
  }

  const handleSuccess = () => {
    track(window.analytics, 'User Created', {
      ...user,
      condominium_id: currentCondominium.id
    })
    enqueueSnackbar(`Usuário criado com sucesso`, { variant: 'success' })
    handleClose()
    listUser({ currentCondominium })
  }

  const handleSave = () => {
    createUser({
      ...user,
      socialSecurityNumber: user.socialSecurityNumber.replace(/\D/g, ''),
      phoneNumber: user.phoneNumber.replace(/\D/g, '')
    })
      .then(handleSuccess)
      .catch(handleError(enqueueSnackbar))
  }

  return (
    <Modal
      open={createModalOpen}
      onClose={handleClose}
      title='Novo Usuário'
      footer={
        <ActionRow
          onCancel={handleClose}
          onSubmit={handleSave}
          loading={loading}
        />
      }
    >
      <ModalContent>
        <UserForm value={user} onChange={setUser} />
      </ModalContent>
    </Modal>
  )
}
