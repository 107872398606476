import React from 'react'
import { DateText, SmallBody, CondominiumUnitText } from '@condofy/components'
import { ActionRow, DataCard } from '@condofy/next/components'

export const ConnectionRequestCard = ({
  connection,
  handleApprove,
  handleDisapprove,
  ...props
}) => {
  return (
    <DataCard
      title={connection.user.name}
      actions={
        <ActionRow
          cancelText='Rejeitar'
          confirmText='Aprovar'
          cancelColor='danger'
          fullWidth={true}
          onCancel={handleDisapprove(connection)}
          onSubmit={handleApprove(connection)}
        />
      }
      {...props}
    >
      <SmallBody color='textSecondary'>
        {connection.userType.name} -{' '}
        <CondominiumUnitText
          block={connection?.unit?.block}
          number={connection?.unit?.number}
        />
      </SmallBody>
      <SmallBody color='textSecondary'>
        <DateText date={connection.createdAt} />
      </SmallBody>
    </DataCard>
  )
}
