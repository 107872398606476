// @flow

import Service from "~/lib/api";
import { config } from "./constants";

class AnnouncementModelService extends Service {
  search = (condominiumId, page, generalSearch) =>
    this.get({ ...config.search(condominiumId, page, generalSearch) });
  create = (condominiumId, body) =>
    this.post({ ...config.create(condominiumId) }, body);
  update = (condominiumId, id, body) =>
    this.put({ ...config.update(condominiumId, id) }, body);
}
export default new AnnouncementModelService();
