import React from 'react'
import PropTypes from 'prop-types'
import { Typography } from '../Typography'

const styles = {
  unit: {
    fontSize: 'inherit !important',
    fontWeight: 'inherit',
    fontFamily: 'inherit !important',
    lineHeight: 'inherit'
  }
}

export const CondominiumUnitText = ({ block, number, ...props }) => {
  const result = number ? (block ? `${block} - ${number}` : `${number}`) : '--'

  return (
    <Typography component='span' className="unit" {...props} sx = {{...styles.unit}}>
      {result}
    </Typography>
  )
}

CondominiumUnitText.propTypes = {
  block: PropTypes.any,
  number: PropTypes.any
}
