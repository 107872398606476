import React from 'react'
import { MenuItem, TextField } from '@condofy/components'
import PropTypes from 'prop-types'

export const IntervalNumberSelector = ({
  length = 0,
  unit,
  emptySelectionText = '-',
  ...other
}) => {
  const plural = (index) => index > 1 && 's'

  const ItemArray = [
    <MenuItem key='0' value={0}>
      {emptySelectionText}
    </MenuItem>,
    ...Array(length)
      .fill(null)
      .map((item, i) => (
        <MenuItem key={i + 1} value={i + 1}>
          {i + 1} {unit}
          {unit && plural(i + 1)}
        </MenuItem>
      ))
  ]
  return (
    <TextField select {...other}>
      {ItemArray}
    </TextField>
  )
}
IntervalNumberSelector.propTypes = {
  unit: PropTypes.string,
  emptySelectionText: PropTypes.string,
  length: PropTypes.number
}
