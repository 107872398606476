import {
  capitalizeLowerKey,
  toCamelLower,
  toSnake,
  toSnakeAndCamel,
  groupCamel,
  groupSnake
} from "~/lib/util/transformString";

class Util {
  baseType = {};

  constructor(baseType) {
    const camel = toCamelLower(baseType);
    const snake = toSnake(camel);
    this.baseType = {
      camel,
      snake
    };
  }
  //action
  action = (_type, _categories) => {
    const { baseType } = this;
    let actionSauce = {};
    if (
      typeof _type === "string" &&
      Array.isArray(_categories) &&
      _categories.length
    ) {
      const type = toSnakeAndCamel(_type);
      _categories.forEach(_category => {
        const category = toSnakeAndCamel(_category);
        actionSauce[groupCamel([baseType, type, category])] = (
          payload = {}
        ) => {
          return {
            type: groupSnake([baseType, type, category]),
            payload: capitalizeLowerKey(payload || {}),
            track: groupSnake([baseType, type]),
            trackType: category.snake
          };
        };
      });
    } else if (Array.isArray(_type)) {
      _type.forEach(a => {
        const type = toSnakeAndCamel(a);
        actionSauce[baseType.camel + type.camel] = (payload = {}) => {
          return {
            type: groupSnake([baseType, type]),
            payload: capitalizeLowerKey(payload || {}),
            track: groupSnake([baseType, type])
          };
        };
      });
    } else if (typeof _type === "string") {
      const type = toSnakeAndCamel(_type);
      actionSauce[groupCamel([baseType, type])] = (payload = {}) => {
        return {
          type: groupSnake([baseType, type]),
          payload: capitalizeLowerKey(payload || {}),
          track: groupSnake([baseType, type])
        };
      };
    } else {
      console.error(
        `erro no metodo action da classe util com
         baseType: ${baseType}
         type: ${_type}
         categories:${_categories}`
      );
    }
    return actionSauce;
  };

  //reducer
  savePayload = (state, { payload = {} }) => ({
    ...state,
    ...payload
  });

  success = typePending => (state, { payload = {} }) => ({
    ...state,
    ...payload,
    pending: { ...state.pending, [typePending]: false }
  });

  pending = typePending => state => ({
    ...state,
    pending: { ...state.pending, [typePending]: true }
  });

  pendingFalse = typePending => state => ({
    ...state,
    pending: { ...state.pending, [typePending]: false }
  });

  request = (_type, pendingType) => {
    if (typeof _type !== "string" || _type === "") {
      console.error(
        `erro no metodo reducerRequest da classe util com
         baseType: ${this.baseType}
         type: ${_type}`
      );
      return null;
    }
    const { baseType, pending, pendingFalse, success } = this;
    let reducerSauce = {};

    const type = toSnakeAndCamel(_type);
    const typeRequest = [
      { snake: "PENDING", reducer: pending(pendingType || `${_type}`) },
      {
        snake: "ERROR",
        reducer: pendingFalse(pendingType || `${_type}`)
      },
      { snake: "SUCCESS", reducer: success(pendingType || `${_type}`) }
    ];

    typeRequest.map(
      a => (reducerSauce[groupSnake([baseType, type, a])] = a.reducer)
    );
    return reducerSauce;
  };
}

export default Util;
