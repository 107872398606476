import styled from 'styled-components'
import { colors, Heading6 } from '@condofy/components'

export const Label = styled(Heading6)`
  display: flex;
  align-items: center;
  color: ${colors.dark300};
  height: 40px;
`

export const Note = styled.span`
  font-size: 11px;
  line-height: 13px;
  color: #9e9e9e;
  margin-top: -8px;
  margin-bottom: 8px;
`
