import { Flex, MonthNavigator, ChartContainer } from '@condofy/components'
import React, { useCallback, useEffect, useState } from 'react'
import { useBankAccountContext } from '@condofy/next/store/bank-account'
import { useUserContext } from '@condofy/next/store/user'
import { useFinancialContext } from '@condofy/next/store/financial'
import { PredictedVsAchievedDoughnut } from './PredictedVsAchievedDoughnut'

export const IncomeVsExpencesChart = () => {
  // const [loading, setLoading] = useState(false)
  const [currentMonth, setCurrentMonth] = useState(new Date())
  const [balance, setBalance] = useState({
    expenses: { predicted: 0, achieved: 0 },
    income: { predicted: 0, achieved: 0 }
  })
  const {
    state: { currentCondominium }
  } = useUserContext()

  const {
    state: { currentAccount }
  } = useBankAccountContext()

  const {
    state: {
      expected: {
        data,
        metadata: { loading }
      }
    },
    actions: { getExpected }
  } = useFinancialContext()

  const stableGetExpected = useCallback(getExpected, [])

  useEffect(() => {
    if (currentCondominium.id && currentAccount.bankAccountId) {
      stableGetExpected({ currentCondominium, currentMonth })
    }
  }, [
    currentMonth,
    currentCondominium,
    currentAccount.bankAccountId,
    stableGetExpected
  ])

  useEffect(() => {
    setBalance(data)
  }, [data])

  return (
    <ChartContainer>
      <Flex spacingY={3} width='100%' alignItems='center' direction='column'>
        <MonthNavigator
          style={{ justifyContent: 'center', alignItems: 'center', height: 50 }}
          value={currentMonth}
          onChange={setCurrentMonth}
        />
        <Flex itemsGrid={6} justify='space-around' width='100%'>
          <PredictedVsAchievedDoughnut
            title='Recebimentos'
            color='success'
            loading={loading}
            total={balance?.income?.predicted}
            achieved={balance?.income?.achieved}
          />
          <PredictedVsAchievedDoughnut
            title='Despesas'
            color='danger'
            loading={loading}
            total={balance?.expenses?.predicted}
            achieved={balance?.expenses?.achieved}
          />
        </Flex>
      </Flex>
    </ChartContainer>
  )
}
