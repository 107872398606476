import React, { useCallback, useEffect, useState } from 'react'
import {
  TextFieldCounter,
  Button,
  Form,
  QuillCounter,
  FormRow
} from '@condofy/components'
import {
  Modal,
  ActionRow,
  ModalContent,
  FilePond
} from '@condofy/next/components'
import { useUserContext } from '@condofy/next/store/user'
import { useSnackbar, handleError } from '@condofy/next/hooks/useSnackbar'
import { track } from '@condofy/next/service/segment'
import { Icon } from '@mui/material'

export const UpdateMessageModal = ({
  open,
  onStepChange: emitStepChangeEvent = () => {}
}) => {
  const { enqueueSnackbar } = useSnackbar()
  const {
    state: {
      currentCondominium,
      announcement: { selected, createModalOpen }
    },
    actions: {
      listAnnouncement,
      closeCreateAnnouncementModal: close,
      updateSelectedAnnouncement,
      createAnnouncement
    }
  } = useUserContext()

  const stableUpdateSelectedAnnouncement = useCallback(
    updateSelectedAnnouncement,
    []
  )
  const nextStep = () => emitStepChangeEvent(3)
  const [slipFile, setSlipFile] = useState([])

  const handleModelChange = (event) => {
    const { name, value } = event.target
    stableUpdateSelectedAnnouncement({ [name]: value })
  }
  const handleContentChange = (value) => {
    stableUpdateSelectedAnnouncement({ content: value })
  }

  useEffect(() => {
    stableUpdateSelectedAnnouncement({
      files: slipFile
    })
  }, [slipFile, stableUpdateSelectedAnnouncement])

  const handleSave = () => {
    createAnnouncement({
      currentCondominium,
      payload: {
        ...selected,
        condominiumId: currentCondominium.id,
        files: slipFile,
        Status: 'A'
      }
    })
      .then(() => {
        track(window.analytics, 'Announcement Created', selected)
        enqueueSnackbar('Comunicado salvo como rascunho', {
          variant: 'success'
        })
        close()
        listAnnouncement({ currentCondominium })
      })
      .catch(handleError(enqueueSnackbar))
  }

  useEffect(() => {
    if (createModalOpen) {
      stableUpdateSelectedAnnouncement({ ['title']: '' })
      stableUpdateSelectedAnnouncement({ ['subject']: '' })
      stableUpdateSelectedAnnouncement({ ['content']: '' })
    }
  }, [createModalOpen])

  return (
    <Modal
      icon={<Icon icon='announcements' />}
      title='Novo comunicado'
      open={open}
      onClose={close}
      footer={
        <ActionRow
          confirmText='Publicar'
          cancelText='Cancelar'
          onSubmit={nextStep}
          onCancel={close}
        >
          <Button onClick={handleSave}>Salvar</Button>
        </ActionRow>
      }
    >
      <ModalContent>
        <Form>
          <FormRow>
            <TextFieldCounter
              required
              label='Titulo'
              name='title'
              value={selected.title}
              onChange={handleModelChange}
              maxLength={50}
            />
          </FormRow>
          <FormRow>
            <TextFieldCounter
              required
              label='Assunto'
              name='subject'
              value={selected.subject}
              onChange={handleModelChange}
              maxLength={50}
            />
          </FormRow>
          <FormRow>
            <QuillCounter
              required
              name='content'
              onChange={handleContentChange}
              defaultValue={selected.content}
              maxLength={2500}
            />
          </FormRow>
          <FormRow>
            <FilePond maxTotalFileSize='20MB' setFile={setSlipFile} />
          </FormRow>
        </Form>
      </ModalContent>
    </Modal>
  )
}
