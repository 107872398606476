import { activeCondominium } from '@condofy/next/service/condo'
import { store, condominiumActionTypes } from './index'

// ACTION TYPES
const CONDOMINIUM_ACTIVE = 'CONDOMINIUM_ACTIVE'
const CONDOMINIUM_ACTIVE_MODAL_VISIBLE = 'CONDOMINIUM_ACTIVE_MODAL_VISIBLE'

// ACTIONS
export const condominiumActiveActions = (state, dispatch) => ({
  activeCondominium: (payload) =>
    dispatch({ type: CONDOMINIUM_ACTIVE, payload }),

  closeActiveCondominiumModal: () => {
    dispatch({
      type: condominiumActionTypes.CONDOMINIUM_SELECT,
      payload: false
    })
    dispatch({ type: CONDOMINIUM_ACTIVE_MODAL_VISIBLE, payload: false })
  },

  openActiveCondominiumModal: (condominium) => {
    dispatch({
      type: condominiumActionTypes.CONDOMINIUM_SELECT,
      payload: condominium
    })
    dispatch({ type: CONDOMINIUM_ACTIVE_MODAL_VISIBLE, payload: true })
  }
})

// MIDDLEWARE
export const enableCondominium = (dispatch, payload) =>
  activeCondominium(payload)

// REDUCER
export const activeActionTypes = {
  CONDOMINIUM_ACTIVE,
  CONDOMINIUM_ACTIVE_MODAL_VISIBLE
}

// REDUCER
export const condominiumActiveReducer = (state, action) => {
  switch (action.type) {
    case CONDOMINIUM_ACTIVE_MODAL_VISIBLE:
      return store(state, {
        activeModalOpen: action.payload
      })

    default:
      return state
  }
}
