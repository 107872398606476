import { all, fork, select, put } from 'redux-saga/effects'
import Service from '~/lib/api/services/announcement'
import { Actions } from '@Redux/announcement'
import { toggleModal } from 'Actions/modals'
import { ADD_ANNOUNCEMENT_MODAL } from 'Const'
import SagasUtil from '~/redux/sagas/util'

export const base = {
  baseType: 'announcement',
  actions: Actions,
  service: Service
}

export const search = {
  request: 'search',
  afterRequest: (response) => ({
    list: response.data.data,
    metadata: response.data.paging
  })
}

export const create = {
  request: 'create',
  afterRequest: function* () {
    const currentCondominium = yield select(
      ({ auth }) => auth.currentCondominium.condominium
    )
    // addToast('Comunicado criado com sucesso', { appearance: 'success' })
    yield put(toggleModal(ADD_ANNOUNCEMENT_MODAL, false))
    yield put(Actions.announcementSearch({ currentCondominium }))
  }
}

export const update = {
  beforeRequest: function* ({ id, ...payload }) {
    const condominiumId = yield select(
      ({ auth }) => auth.currentCondominium.condominium.id
    )
    return [condominiumId, id, payload]
  },
  request: 'update',
  afterRequest: function* () {
    const currentCondominium = yield select(
      ({ auth }) => auth.currentCondominium.condominium
    )
    // addToast('Comunicado alterado com sucesso', { appearance: 'success' })
    yield put(toggleModal(ADD_ANNOUNCEMENT_MODAL, false))
    yield put(Actions.announcementSearch({ currentCondominium }))
  }
}

export const receivers = {
  beforeRequest: function ({ currentCondominium }) {
    const condominiumId = currentCondominium.id
    return [condominiumId]
  },
  request: 'receivers',
  afterRequest: ({ data }) => ({
    receivers: data.map((a) => ({ value: a.id, label: a.name }))
  })
}

export const modelsType = {
  beforeRequest: function ({ currentCondominium }) {
    const condominiumId = currentCondominium.id
    return [condominiumId]
  },
  request: 'modelsType',
  afterRequest: ({ data }) => ({
    modelsType: data.map((a) => ({ value: a.id, label: a.name }))
  })
}

export const modelSelected = {
  beforeRequest: function* (payload) {
    const condominiumId = yield select(
      ({ auth }) => auth.currentCondominium.condominium.id
    )
    return [condominiumId, payload]
  },
  request: 'modelSelected',
  afterRequest: ({ data }) => ({
    modelSelected: data
  })
}

export default function* root() {
  const sagas = SagasUtil(base, {
    create,
    update,
    search,
    searchStatus: search,
    receivers,
    modelsType,
    modelSelected
  })
  yield all(sagas.map((saga) => fork(...saga)))
}
