import { ENVIRONMENT, DOMAIN_URL } from './env'

export const setToken = (token) => {
  window.document.cookie = `${ENVIRONMENT}Token=${token};domain=${DOMAIN_URL[ENVIRONMENT]}`
}

export const getToken = () => {
  let cookieToken = window.document.cookie
    .split('; ')
    .find((row) => row.startsWith(`${ENVIRONMENT}Token`))

  if (cookieToken) cookieToken = cookieToken.split('=')[1]

  return cookieToken
}

export const removeToken = () => {
  window.document.cookie = `${ENVIRONMENT}Token=;domain=${DOMAIN_URL[ENVIRONMENT]};expires=Thu, 01 Jan 1970 00:00:00 UTC;`
}
