import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Checkbox, FormRow } from '@condofy/components'
import moment from 'moment'

const StyledCheckbox = styled(Checkbox)`
  & + .MuiFormControlLabel-label {
    text-transform: capitalize;
  }
`

export const WeekDaySelector = ({
  value = [...Array(7).keys()],
  name: fieldName,
  onChange = () => {}
}) => {
  const [weekDays, setWeekDays] = useState(
    [...Array(7).keys()].map((day) => {
      return {
        label: moment.weekdays()[day],
        checked: Boolean(value.filter((d) => d === day).length),
        name: moment().locale('en').weekday(day).format('dddd').toLowerCase()
      }
    })
  )

  useEffect(() => {
    setWeekDays(
      [...Array(7).keys()].map((day) => {
        return {
          label: moment.weekdays()[day],
          checked: Boolean(value.filter((d) => d === day).length),
          name: moment().locale('en').weekday(day).format('dddd').toLowerCase()
        }
      })
    )
  }, [value])

  const valueChanged = ({ target }) => {
    const { name, checked } = target
    const newValue = [...weekDays]
    const day = newValue.findIndex((d) => d.name === name)
    newValue[day].checked = checked

    setWeekDays(newValue)
    onChange({
      target: {
        name: fieldName,
        value: newValue
          .map((weekDay, index) => weekDay.checked && index)
          .filter(Number.isInteger)
      }
    })
  }

  return (
    <>
      <FormRow>
        {weekDays.slice(0, 3).map(({ label, checked, name }) => (
          <StyledCheckbox
            key={name}
            variant='outlined'
            checked={checked}
            label={label}
            name={name}
            onChange={valueChanged}
          />
        ))}
      </FormRow>
      <FormRow>
        {weekDays.slice(3, 6).map(({ label, checked, name }) => (
          <StyledCheckbox
            key={name}
            variant='outlined'
            checked={checked}
            label={label}
            name={name}
            onChange={valueChanged}
          />
        ))}
      </FormRow>
      <FormRow>
        {weekDays.slice(6).map(({ label, checked, name }) => (
          <StyledCheckbox
            key={name}
            variant='outlined'
            checked={checked}
            label={label}
            name={name}
            onChange={valueChanged}
          />
        ))}
      </FormRow>
    </>
  )
}
