import { createTicket } from '@condofy/next/service/ticket'
import { store, pending } from './index'

// ACTION TYPES
const TICKET_CREATE = 'TICKET_CREATE'
const TICKET_CREATE_MODAL_VISIBLE = 'TICKET_CREATE_MODAL_VISIBLE'

export const createActionTypes = {
  TICKET_CREATE,
  TICKET_CREATE_MODAL_VISIBLE
}

// ACTIONS
export const createActions = (state, dispatch) => ({
  createTicket: (payload) => dispatch({ type: TICKET_CREATE, payload }),

  closeCreateTicketModal: () =>
    dispatch({ type: TICKET_CREATE_MODAL_VISIBLE, payload: false }),

  openCreateTicketModal: () =>
    dispatch({ type: TICKET_CREATE_MODAL_VISIBLE, payload: true })
})

// MIDDLEWARE
export const create = async (dispatch, payload) => {
  try {
    pending(dispatch, true)
    const response = await createTicket(payload)
    return response
  } finally {
    pending(dispatch, false)
  }
}

// REDUCER
export const createReducer = (state, action) => {
  switch (action.type) {
    case TICKET_CREATE_MODAL_VISIBLE:
      return store(state, {
        createModalOpen: action.payload
      })

    default:
      return state
  }
}
