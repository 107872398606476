import React from 'react'
import { Page, PageTitle } from '@condofy/components'
import { BillingList } from '@condofy/next/containers/billing'
import BillingImage from '~/assets/fatura.svg'
import { PremiumPage, PremiumToggle } from '@condofy/next/components'

const StarterContent = () => (
  <PremiumPage
    title='Faturas'
    text='Tenha acesso a todas as suas faturas diretamente da plataforma Condofy. Precisa de uma segunda via? é fácil gerar através da nossa funcionalidade.'
    image={<BillingImage />}
  />
)

//todo

export const BillingPage = () => (
  <Page>
    <PremiumToggle fallback={<StarterContent />}>
      <PageTitle title='Faturas' icon='billing' />
      <BillingList controls />
    </PremiumToggle>
  </Page>
)
