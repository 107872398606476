import { callApi } from 'Middleware/api'
import { normalizeObject } from '~/lib/util/normalizeObject'
import { createConnection } from '@condofy/next/service/connection'
import { condofyProtected } from '@condofy/next/service/instance'

export const TOGGLE_NEW_CONNECTION_MODAL = 'TOGGLE_NEW_CONNECTION_MODAL'
export const CHANGE_NEW_CONNECTION_FIELD = 'CHANGE_NEW_CONNECTION_FIELD'
export const SEND_NEW_CONNECTION = 'SEND_NEW_CONNECTION'
export const CREATE_NEW_CONNECTION = 'CREATE_NEW_CONNECTION'
export const CREATE_NEW_CONNECTION_SUCCESS = 'CREATE_NEW_CONNECTION_SUCCESS'
export const CREATE_NEW_CONNECTION_FAIL = 'CREATE_NEW_CONNECTION_FAIL'
export const SET_CONNECTIONS = 'SET_CONNECTIONS'
export const SET_USER_CONNECTION = 'SET_USER_CONNECTION'
export const CHANGE_USER_CONNECTION_FIELDS = 'CHANGE_USER_CONNECTION_FIELDS'
export const UPDATE_USER_CONNECTION_START = 'UPDATE_USER_CONNECTION_START'
export const UPDATE_USER_CONNECTION_SUCCESS = 'UPDATE_USER_CONNECTION_SUCCESS'
export const UPDATE_USER_CONNECTION_FAIL = 'UPDATE_USER_CONNECTION_FAIL'
export const CLEAR_USER_CONNECTION = 'CLEAR_USER_CONNECTION'

// TODOR: new way for handle modal
export const toggleNewConnectionModal = (isOpenConnectionModal) => ({
  type: TOGGLE_NEW_CONNECTION_MODAL,
  isOpenConnectionModal
})

export const changeNewConnectionField = (key, value) => ({
  type: CHANGE_NEW_CONNECTION_FIELD,
  key,
  value
})

const createNewConnectionSuccess = () => ({
  type: CREATE_NEW_CONNECTION_SUCCESS
})

const setConnections = (connections) => ({
  type: SET_CONNECTIONS,
  connections
})

export const setUserConnection = (connection) => ({
  type: SET_USER_CONNECTION,
  connection
})

export const changeUserConnectionFields = (key, value) => ({
  type: CHANGE_USER_CONNECTION_FIELDS,
  key,
  value
})

export const updateUserConnectionFail = () => ({
  type: UPDATE_USER_CONNECTION_FAIL
})

export const clearUserConnection = () => ({
  type: CLEAR_USER_CONNECTION
})

export const getConnections = () => (dispatch) => {
  dispatch({ type: 'SET_LOADING', payload: true })

  return condofyProtected
    .get('/v1/connections/requests')
    .then((response) => dispatch(setConnections(response.data.data)))
    .finally(() => dispatch({ type: 'SET_LOADING', payload: false }))
}

export const sendNewConnection = ({
  userId,
  condominiumId,
  unitId,
  userTypeId,
  dispatch,
  onError: emitErrorEvent = (e) => e,
  onSuccess: emitSuccessEvent = (e) => e
}) => {
  createConnection({
    userId,
    condominiumId,
    unitId,
    userTypeId,
    onSuccess: (data) => {
      dispatch(createNewConnectionSuccess())
      dispatch(getConnections())
      emitSuccessEvent(data)
    },
    onError: (error) => {
      emitErrorEvent(error)
    }
  })
}

export const approveConnection = ({ connection }) => (dispatch) => {
  return condofyProtected
    .post(`/v1/connections/${connection.id}/approve`)
    .then(() => dispatch(getConnections()))
}

export const disapproveConnection = ({ connection }) => (dispatch) => {
  return condofyProtected
    .post(`/v1/connections/${connection.id}/disapprove`)
    .then(() => dispatch(getConnections()))
}

export const getExistConnection = (userId, condoId) => (dispatch) => {
  return callApi(`/v1/connections/${userId}`)
    .then((response) => {
      if (response.status === 422) {
        return Promise.reject(response.json())
      } else if (response.ok) {
        return response.json()
      }
    })
    .then((result) => {
      const {
        data: { id, userType, unit }
      } = normalizeObject(result)

      const connection = {
        id,
        condoId,
        role: userType.id,
        unitId: unit.id,
        isManager: userType.id === 2
      }

      return dispatch(setUserConnection(connection))
    })
}
