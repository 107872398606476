import React from 'react'
import styled from 'styled-components'
import { colors } from '@condofy/components'
import { Modal, ActionRow, ModalContent } from '@condofy/next/components'
import { useUserContext } from '@condofy/next/store/user'

const Message = styled.span`
  font-weight: normal;
  font-size: 11px;
  line-height: 13px;
  margin-bottom: 10px;
  text-align: center;

  color: #9e9e9e;
`

const PrimaryMessage = styled(Message)`
  font-size: 13px;
  line-height: 19px;

  color: ${colors.primary1};
`

export const EmailConfirmationModal = () => {
  const {
    state: { temporaryProfileData },
    actions
  } = useUserContext()

  return (
    <Modal
      title={`Confirme seu e-mail`}
      open={temporaryProfileData.emailModalOpen}
      onClose={actions.closeEmailModal}
      footer={
        <ActionRow
          confirmText='Entendi'
          cancelText={false}
          onSubmit={actions.closeEmailModal}
        />
      }
    >
      <ModalContent>
        <PrimaryMessage>
          <span>
            Para completar a sua alteração de email você precisa confirmar seu
            novo endereço clicando no link enviado.
          </span>
        </PrimaryMessage>
      </ModalContent>
    </Modal>
  )
}
