import React, { useCallback, useEffect, useState } from 'react'
import { Inbox } from '@mui/icons-material'
import { Form, FormRow, MenuItem, TextField } from '@condofy/components'
import {
  Modal,
  ActionRow,
  FilePond,
  ModalContent
} from '@condofy/next/components'
import { useSnackbar, handleError } from '@condofy/next/hooks/useSnackbar'
import { useUserContext } from '@condofy/next/store/user'
import { track } from '@condofy/next/service/segment'

export const TicketCreateModal = () => {
  const { enqueueSnackbar } = useSnackbar()

  const {
    state: {
      currentCondominium,
      ticket: { createModalOpen, metadata },
      ticketType: { items: ticketTypeList }
    },
    actions: {
      createTicket,
      listTicket,
      listTicketType,
      closeCreateTicketModal: close
    }
  } = useUserContext()
  const stableListTicketType = useCallback(listTicketType, [])

  const [files, setFiles] = useState([])
  const [ticket, setTicket] = useState({
    typeId: '',
    subject: '',
    comment: ''
  })

  useEffect(() => {
    stableListTicketType()
  }, [createModalOpen, stableListTicketType])

  const handleSuccess = () => {
    track(window.analytics, 'Ticket Opened', ticket)
    enqueueSnackbar(`Chamado criado com sucesso`, { variant: 'success' })
    setTicket({
      typeId: '',
      subject: '',
      comment: ''
    })
    setFiles([])
    setTimeout(() => {
      listTicket({ currentCondominium })
    }, 2000)
    close()
  }

  const handleSave = () => {
    createTicket({ currentCondominium, payload: { ...ticket, files } })
      .then(handleSuccess)
      .catch(handleError(enqueueSnackbar))
  }

  const handleChange = (event) => {
    const { name, value } = event.target
    setTicket({ ...ticket, [name]: value })
  }

  return (
    <Modal
      open={createModalOpen}
      onClose={close}
      icon={<Inbox />}
      title='Novo Chamado'
      footer={
        <ActionRow
          loading={metadata.loading}
          confirmText='Enviar'
          onCancel={close}
          onSubmit={handleSave}
        />
      }
    >
      <ModalContent>
        <Form>
          <FormRow>
            <TextField
              name='subject'
              label='Titulo'
              value={ticket.subject}
              onChange={handleChange}
            />
          </FormRow>
          <FormRow>
            <TextField
              select
              label='Assunto'
              name='typeId'
              value={ticket.typeId}
              onChange={handleChange}
            >
              {ticketTypeList.map((type) => (
                <MenuItem key={type.id} value={type.id}>
                  {type.name}
                </MenuItem>
              ))}
            </TextField>
          </FormRow>
          <FormRow>
            {' '}
            <TextField
              name='comment'
              label='Descrição'
              multiline
              rows={2}
              value={ticket.comment}
              onChange={handleChange}
            />
          </FormRow>
          <FormRow>
            {/* Zendesk API file limit is 20MB */}
            <FilePond
              allowMultiple={true}
              maxTotalFileSize='19MB'
              setFile={setFiles}
            />
          </FormRow>
        </Form>
      </ModalContent>
    </Modal>
  )
}
