import { createActions, createReducer } from 'reduxsauce'

import Util from '../util'

const { action, request, savePayload } = new Util('storage')

const INITIAL_STATE = {
  list: [],
  selected: {},
  pending: {}
}

export const { Types, Creators: Actions } = createActions({
  ...action('list', ['', 'success', 'error', 'pending']),
  ...action('download', ['', 'success', 'error', 'pending']),
  ...action('createPublic', ['', 'success', 'error', 'pending']),
  ...action('createPrivate', ['', 'success', 'error', 'pending']),
  ...action('selectNode')
})

const setList = (state, { payload }) => {
  let data = []
  payload.data.data.map(
    (file) => (data = recursiveGeneratorTree(data, file, 1))
  )

  return {
    ...state,
    list: data,
    folderSelected: data,
    deepFolder: [{ files: data }],
    pending: { ...state.pending, list: false }
  }
}

const recursiveGeneratorTree = (arr, file, index) => {
  const fullName = file.fullName
  const path = file.fullName.split('/')

  const currentPosition = arr.find((a) => a.title === path[index])
  arr = currentPosition ? arr.filter((a) => a.title !== path[index]) : arr

  const newPosition = currentPosition || {
    title: path[index],
    files: [],
    size: file.size,
    createdAt: file.createdAt,
    isPublic: file.isPublic
  }

  if (path.length === index + 1) {
    return [...arr, { ...newPosition, fullName }]
  } else {
    const files = recursiveGeneratorTree(newPosition.files, file, index + 1)
    return [
      ...arr,
      {
        ...newPosition,
        fullName: splitCurrentPosition(fullName, path[index]),
        files: Array.isArray(files) ? files : []
      }
    ]
  }
}

const splitCurrentPosition = (fullName, currentPath) =>
  fullName.split(currentPath)[0] + currentPath

export const HANDLERS = {
  ...request('list'),
  ...request('download'),
  ...request('createPublic'),
  ...request('createPrivate'),
  [Types.STORAGE_LIST_SUCCESS]: setList,
  [Types.STORAGE_SELECT_NODE]: savePayload
}

export const Reducer = createReducer(INITIAL_STATE, HANDLERS)
