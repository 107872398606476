import React from 'react'
import { NavLink } from 'react-router-dom'
import { Icon, Tooltip } from '@condofy/components'
import styled from 'styled-components'
import Premium from 'Assets/icons/premium-tag.svg'
import { SidebarNavItemText } from './SidebarNavItemText'

export const IconPremium = styled(Premium)`
  margin-left: 15px;
  z-index: 2;
`

const StyledIcon = styled(Icon)``

const StyledNavItem = styled(NavLink)`
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 12px 19px;
  margin: 8px;
  transition: padding 0.7s ease;

  &:hover,
  &.active {
    background: rgba(255, 255, 255, 0.15);
  }

  ${StyledIcon} {
    transition: margin 1s ease;
    margin-right: 19px;
  }

  ${({ collapsed }) =>
    collapsed &&
    `
    padding-left: 12px;

    ${StyledIcon} {
      margin-right: 0;
    }
  `}
`

export const SidebarNavItem = ({ item, collapsed, showPremiumBadge }) => {
  const showTooltip =
    item.premium && item.accessType !== 'self' && showPremiumBadge

  return (
    <StyledNavItem to={item.to} collapsed={collapsed ? 1 : 0}>
      <StyledIcon icon={item.icon} />
      <SidebarNavItemText collapsed={collapsed}>{item.text}</SidebarNavItemText>
      {showTooltip && (
        <Tooltip title='Este item está incluso no plano premium.' arrow>
          <span>
            <IconPremium />
          </span>
        </Tooltip>
      )}
    </StyledNavItem>
  )
}
