import moment from 'moment'
import { condofyProtected } from './instance'
import { startOfMonth, endOfMonth } from 'date-fns'

export const getCashflow = ({ currentCondominium, startDate, endDate }) =>
  condofyProtected.post(
    `v1/condominiums/${currentCondominium.id}/balance/cash-flow`,
    {
      startDate: moment(startOfMonth(startDate)).format('YYYY-MM-DDTHH:mm:ss'),
      endDate: moment(endOfMonth(endDate)).format('YYYY-MM-DDTHH:mm:ss')
    }
  )
