import { mapObject, pick } from 'underscore'
import { callApi } from 'Middleware/api'
import { getUnitsByCondo } from 'Actions/condominium'
import { condofyProtected } from '@condofy/next/service/instance'

export const PROCESS_UNIT_START = 'CREATE_NEW_UNIT'
export const PROCESS_UNIT_FIELD_CHANGE = 'NEW_UNIT_FIELD_CHANGE'
export const RESET_UNIT = 'RESET_UNIT'
export const SET_UNIT_SEARCH = 'SET_UNIT_SEARCH'
export const SET_UNIT = 'SET_UNIT'

export const changeUnitField = (name, value) => ({
  type: PROCESS_UNIT_FIELD_CHANGE,
  name,
  value
})

export const processUnitStart = () => ({
  type: PROCESS_UNIT_START
})

export const resetUnitFields = () => ({
  type: RESET_UNIT
})

export const setSearch = (search) => ({
  type: SET_UNIT_SEARCH,
  search
})

export const setUnit = (unit) => ({
  type: SET_UNIT,
  unit: pick(unit, ['block', 'number', 'observations'])
})

export const createUnit = (condoId) => (dispatch, getState) => {
  dispatch(processUnitStart())

  const {
    units: { unit }
  } = getState()

  const body = mapObject(unit, (val) => val.value)

  return condofyProtected
    .post(`/v1/condominiums/${condoId}/units`, body)
    .then(() => {
      dispatch(resetUnitFields())
      dispatch(getUnitsByCondo(condoId))
      return 'OK'
    })
}

export const updateUnit = (unitId, condoId) => (dispatch, getState) => {
  dispatch(processUnitStart())

  const {
    units: { unit }
  } = getState()

  const body = JSON.stringify(mapObject(unit, (val) => val.value))

  return callApi(`/v1/condominiums/${condoId}/units/${unitId}`, {
    method: 'PUT',
    body
  }).then(() => {
    dispatch(resetUnitFields())
    dispatch(getUnitsByCondo(condoId))
    return 'OK'
  })
}
