import { condofyProtected } from '@condofy/next/service/instance'

export const search = (
  payload = {
    PageNumber: 1,
    GeneralSearch: '',
    RecordsPerPage: 10,
    currentCondominium: { id: '' }
  }
) =>
  condofyProtected.get(
    `/v1/condominiums/${payload.currentCondominium.id}/announcements-model`,
    {
      params: {
        PageNumber: payload.PageNumber,
        GeneralSearch: payload.GeneralSearch,
        RecordsPerPage: payload.RecordsPerPage
      }
    }
  )

// update: (condominiumId, id) => ({
//   url: `/v1/condominiums/${condominiumId}/announcements-model/${id}`,
//   error: 'Ocorreu um problema ao alterar este comunicado'
// }),
// receivers: (condominiumId) => ({
//   url: `/v1/condominiums/${condominiumId}/announcements-model/receivers`,
//   error: 'Ocorreu um problema ao criar este comunicado'
// })

export const createAnnouncementModel = ({ currentCondominium, payload }) =>
  condofyProtected.post(
    `/v1/condominiums/${currentCondominium.id}/announcements-model`,
    payload
  )

export const editAnnouncementModel = ({ currentCondominium, payload }) =>
  condofyProtected.put(
    `/v1/condominiums/${currentCondominium.id}/announcements-model/${payload.id}`,
    payload
  )
