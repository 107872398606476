import types from './actionTypes'

import { unitActions } from './unit'
import { userActions } from './user'
import { ticketActions } from './ticket'
import { ticketTypeActions } from './ticket/type'
import { ticketCommentActions } from './ticket/comment'
import { condominiumActions } from './condominium'
import { connectionActions } from './connection'
import { announcementActions } from './announcement'
import { announcementModelActions } from './announcementModel'
import { announcementReceiverActions } from './announcementReceiver'
import { occurenceActions } from './occurence'
import { areaActions } from './area'

import { userConnectionActions } from './user/connection'

export const useActions = (state, dispatch) => ({
  ...userActions(state, dispatch),
  ...condominiumActions(state, dispatch),
  ...ticketActions(state, dispatch),
  ...ticketTypeActions(state, dispatch),
  ...ticketCommentActions(state, dispatch),
  ...unitActions(state, dispatch),
  ...connectionActions(state, dispatch),
  ...announcementActions(state, dispatch),
  ...announcementModelActions(state, dispatch),
  ...announcementReceiverActions(state, dispatch),
  ...occurenceActions(state, dispatch),
  ...userConnectionActions(state, dispatch),
  ...areaActions(state, dispatch),

  updateCurrentCondominium: (data) => {
    dispatch({ type: types.SET_ADMIN, payload: data })
    dispatch({ type: types.SELECT_CURRENT_CONDOMINIUM, payload: data })
  },
  loadNotifications: () => {
    return dispatch({ type: types.LOAD_NOTIFICATION })
  },
  saveNotificationClosed: (data) => {
    return dispatch({ type: types.CLOSE_NOTIFICATION, payload: data })
  },
  loadProfile: () => {
    return dispatch({ type: types.LOAD_PROFILE_DATA })
  },
  updateTemporaryName: (data) => {
    return dispatch({ type: types.UPDATE_TEMPORARY_NAME, payload: data })
  },
  updateTemporaryEmail: (data) => {
    return dispatch({ type: types.UPDATE_TEMPORARY_EMAIL, payload: data })
  },
  updateTemporaryPhoneNumber: (data) => {
    return dispatch({
      type: types.UPDATE_TEMPORARY_PHONE_NUMBER,
      payload: data
    })
  },
  updateTemporarySocialSecurityNumber: (data) => {
    return dispatch({
      type: types.UPDATE_TEMPORARY_SOCIAL_SECURITY_NUMBER,
      payload: data
    })
  },
  changeName: (data) => {
    return dispatch({ type: types.CHANGE_NAME, payload: data })
  },
  changeEmail: async (data) => {
    await dispatch({ type: types.CHANGE_EMAIL, payload: data })
    await dispatch({ type: types.SET_EMAIL_MODAL_VISIBLE, payload: true })
  },
  changePhoneNumber: async (data) => {
    await dispatch({ type: types.CHANGE_PHONE_NUMBER, payload: data })
    await dispatch({ type: types.SET_SMS_MODAL_VISIBLE, payload: true })
  },
  changePassword: (data) => {
    return dispatch({ type: types.CHANGE_PASSWORD, payload: data })
  },
  closeSMSModal: () => {
    dispatch({ type: types.SET_SMS_MODAL_VISIBLE, payload: false })
  },
  closeEmailModal: () => {
    return dispatch({ type: types.SET_EMAIL_MODAL_VISIBLE, payload: false })
  },
  sendConfirmationCode: (data) => {
    return dispatch({
      type: types.SEND_PHONE_NUMBER_CONFIRMATION_CODE,
      payload: data
    })
  },
  logout: () => {
    return dispatch({ type: types.LOGOUT })
  }
})
