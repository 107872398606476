import React from 'react'
import { Menu as MaterialMenu } from '@mui/material'
import styled from 'styled-components'
import { colors, shadows } from '../../styles'
import PropTypes from 'prop-types'

const StyledMenu = styled(MaterialMenu)`
  & .MuiPaper-root {
    border-radius: 3px;
    box-shadow: ${shadows[0]};
  }
  & .MuiList-root {
    color: ${colors.text};
    padding: 0;
  }
  & .MuiListItem-root,
  & .MuiListItemText-primary {
    color: ${colors.text};
    font-size: 14px;
  }
  & .MuiListItem-root {
    height: 40px;
    border-left: solid 2px transparent;

    &:hover {
      background-color: ${colors.light300};
    }
    /* &:focus {
      background-color: ${colors.brandBlue50};
      border-color: ${colors.brandBlue300};
    } */
  }
  & .MuiListItem-gutters {
    padding-left: 18px;
    padding-right: 20px;
  }
  & .MuiListItemIcon-root {
    color: ${colors.text};
    margin-right: 1rem;
    min-width: auto;
  }
`

export const Menu = ({
  anchorOrigin = { vertical: 'bottom', horizontal: 'left' },
  transformOrigin = { vertical: 'top', horizontal: 'left' },
  ...args
}) => (
  <StyledMenu
    anchorOrigin={anchorOrigin}
    transformOrigin={transformOrigin}
    {...args}
  />
)
Menu.propTypes = {
  anchorOrigin: PropTypes.any,
  transformOrigin: PropTypes.any
}
