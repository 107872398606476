import { updateUser } from '@condofy/next/service/user'
import { store, userActionTypes, pending } from './index'

// ACTION TYPES
const USER_EDIT = 'USER_EDIT'
const USER_UPDATE_SELECTED = 'USER_UPDATE_SELECTED'
const USER_EDIT_MODAL_VISIBLE = 'USER_EDIT_MODAL_VISIBLE'

// ACTIONS
export const editActions = (state, dispatch) => ({
  updateSelectedUser: (data) =>
    dispatch({ type: USER_UPDATE_SELECTED, payload: data }),

  editUser: (payload) => dispatch({ type: USER_EDIT, payload }),

  closeEditUserModal: () => {
    dispatch({ type: userActionTypes.USER_SELECT, payload: false })
    dispatch({ type: USER_EDIT_MODAL_VISIBLE, payload: false })
  },

  openEditUserModal: (user) => {
    dispatch({ type: userActionTypes.USER_SELECT, payload: user })
    dispatch({ type: USER_EDIT_MODAL_VISIBLE, payload: true })
  }
})

// MIDDLEWARE
export const edit = async (dispatch, payload) => {
  try {
    pending(dispatch, true)
    return updateUser({ payload })
  } finally {
    pending(dispatch, false)
  }
}

// REDUCER
export const editReducer = (state, action) => {
  switch (action.type) {
    case USER_UPDATE_SELECTED:
      return store(state, { selected: action.payload })

    case USER_EDIT_MODAL_VISIBLE:
      return store(state, { editModalOpen: action.payload })

    default:
      return state
  }
}

export const editActionTypes = {
  USER_EDIT,
  USER_UPDATE_SELECTED,
  USER_EDIT_MODAL_VISIBLE
}
