import {
  PROCESS_UNIT_FIELD_CHANGE,
  PROCESS_UNIT_START,
  RESET_UNIT,
  SET_UNIT_SEARCH,
  SET_UNIT
} from 'Actions/units'

const initialState = {
  unit: {
    block: {
      value: '',
      error: null
    },
    number: {
      value: '',
      error: null
    },
    observations: {
      value: '',
      error: null
    }
  },
  search: '',
  isLoading: false
}

const unitsSection = (state = initialState, action) => {
  switch (action.type) {
    case PROCESS_UNIT_FIELD_CHANGE:
      return {
        ...state,
        unit: {
          ...state.unit,
          [action.name]: {
            value: action.value,
            error: null
          }
        }
      }

    case PROCESS_UNIT_START:
      return {
        ...state,
        isLoading: true
      }

    case RESET_UNIT:
      return initialState

    case SET_UNIT_SEARCH:
      return {
        ...state,
        search: action.search
      }

    case SET_UNIT:
      return {
        ...state,
        unit: Object.keys(action.unit).reduce((acc, key) => {
          acc[key] = { value: action.unit[key], error: null }
          return acc
        }, {})
      }

    default:
      return {
        ...state
      }
  }
}

export default unitsSection
