export const TOGGLE_MODAL = 'TOGGLE_MODAL'

export const toggleModal = (modalType, isModalOpen, options = {}) => ({
  type: TOGGLE_MODAL,
  isModalOpen,
  options: {
    type: modalType,
    ...options
  }
})
