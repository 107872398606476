import React, { useEffect, useState } from 'react'
import { FormRow } from '@condofy/components'
import { IntervalNumberSelector } from './IntervalNumberSelector'

export const BookingRestrictionsSelector = ({
  value,
  name: fieldName,
  onChange: emitChange = () => {}
}) => {
  const getRestriction = (restrictionType, prop) => {
    const restriction = value?.find(
      (r) => r.restrictionType === restrictionType
    )
    return restriction ? restriction[prop] : null
  }

  const [restrictions, setRestrictions] = useState({
    advanceBooking: {
      restrictionType: '0',
      min: getRestriction('0', 'min'),
      max: getRestriction('0', 'max')
    },
    advanceWithdrawal: {
      restrictionType: '1',
      min: getRestriction('1', 'min'),
      max: null
    },
    usagePeriod: {
      restrictionType: '2',
      min: null,
      max: getRestriction('2', 'max')
    }
  })

  useEffect(() => {
    setRestrictions({
      advanceBooking: {
        restrictionType: '0',
        min: getRestriction('0', 'min'),
        max: getRestriction('0', 'max')
      },
      advanceWithdrawal: {
        restrictionType: '1',
        min: getRestriction('1', 'min'),
        max: null
      },
      usagePeriod: {
        restrictionType: '2',
        min: null,
        max: getRestriction('2', 'max')
      }
    })
  }, [value])

  const restrictionChanged = ({ target: { name, value } }) => {
    const restrictionType = name.split('.')[0]
    const limit = name.split('.')[1]
    let newRestrictions = { ...restrictions }
    newRestrictions[restrictionType][limit] = value

    setRestrictions(newRestrictions)
    emitChange({
      target: {
        name: fieldName,
        value: Object.values(restrictions)
      }
    })
  }

  const selectorProps = {
    emptySelectionText: 'Livre',
    fullWidth: true,
    onChange: (event) => restrictionChanged(event)
  }

  return (
    <>
      <FormRow>
        <IntervalNumberSelector
          length={60}
          unit='dia'
          name='advanceBooking.min'
          value={restrictions.advanceBooking.min || 0}
          label='Antecedência Min de Reserva'
          {...selectorProps}
        />
        <IntervalNumberSelector
          length={60}
          unit='dia'
          name='advanceBooking.max'
          value={restrictions.advanceBooking.max || 0}
          label='Antecedência Max de Reserva'
          {...selectorProps}
        />
      </FormRow>
      <FormRow>
        <IntervalNumberSelector
          length={60}
          unit='dia'
          name='advanceWithdrawal.min'
          value={restrictions.advanceWithdrawal.min || 0}
          label='Antecedência de Desistência'
          {...selectorProps}
        />
        <IntervalNumberSelector
          length={5}
          unit='Hora'
          name='usagePeriod.max'
          value={restrictions.usagePeriod.max || 0}
          label='Período Max de Uso'
          {...selectorProps}
        />
      </FormRow>
    </>
  )
}
