import React from 'react'
import {
  CommunityOnboardStep,
  CommunityOnboardStepTitle,
  CommunityOnboardStepBody
} from './CommunityOnboardStep'
import Graphic3 from 'Assets/community/work-laptop'
import { FormProvider } from 'react-hook-form'
import { Checkbox, Form } from '@condofy/components'
import { FormField as Field } from '@condofy/next/containers'
import { Link } from '@mui/material'

const TermsAgreementCheckbox = (args) => {
  return (
    <Checkbox checked={Boolean(args.value)} labelPlacement='end' {...args} />
  )
}

export const CommunityOnboardTermsStep = ({ form }) => {
  return (
    <CommunityOnboardStep Image={<Graphic3 />}>
      <CommunityOnboardStepTitle>Quase lá!</CommunityOnboardStepTitle>
      <CommunityOnboardStepBody>
        Explicar porque ele vai precisar adicionar uma primeira oferta antes de
        qualquer coisa.
      </CommunityOnboardStepBody>
      <FormProvider {...form}>
        <Form>
          <Field
            name='agreeUseTerms'
            Component={
              <TermsAgreementCheckbox
                label={
                  <>
                    Eu concordo com as{' '}
                    <Link href='#'>
                      politicas de uso e regras da plataforma
                    </Link>
                  </>
                }
              />
            }
          />
        </Form>
      </FormProvider>
    </CommunityOnboardStep>
  )
}
