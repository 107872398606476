import React from 'react'
import styled from 'styled-components'
import { Tabs as MuiTabs } from '@mui/material'

const StyledTabs = styled(MuiTabs)`
  &.MuiTabs-root {
    min-height: 30px;
  }
  & .MuiTabs-flexContainer {
    align-items: flex-end;
  }
  & .MuiTabs-indicator {
    height: 1px;
  }
`

export const Tabs = (args) => <StyledTabs {...args} />
