import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import {
  Button,
  Card as BaseCard,
  CardContent,
  Flex,
  Pagination,
  DataTable,
  colors,
  typography,
  theme
} from '@condofy/components'
import { fetchSchedulingList } from '@condofy/next/service/scheduling'
import { useSnackbar } from '@condofy/next/hooks/useSnackbar'
import { useUserContext } from '@condofy/next/store/user'
import { ScheduleListRow } from './ScheduleListRow'

import { MonthNavigator } from '@condofy/components'
import { Box, Typography } from '@mui/material'

const Card = styled(BaseCard)`
  flex: 1 1;
`

export const ScheduleList = ({
  title = 'Minhas Reservas',
  GeneralSearch,
  hasCreateButton = true,
  isAdmin,
  ...other
}) => {
  const { enqueueSnackbar } = useSnackbar()
  const [loading, setLoading] = useState(false)
  const [isFiltered, setIsFiltered] = useState(false)
  const [scheduleList, setScheduleList] = useState([])
  const [page, setPage] = useState(1)
  const [currentMonth, setCurrentMonth] = useState(new Date())
  const [metadata, setMetadata] = useState({
    totalRecords: 0,
    recordsPerPage: 10
  })

  const {
    state: { currentCondominium, email }
  } = useUserContext()

  const handleError = (error) => {
    setLoading(false)
    if (error) {
      const errorMessage =
        error.response.data.reports[0].message || error.message
      enqueueSnackbar(errorMessage, { variant: 'error' })
    }
  }

  const updateList = () => {
    setScheduleList([])
    setLoading(true)

    fetchSchedulingList({
      currentCondominium,
      IncludeUnit: true,
      GeneralSearch: isFiltered ? email : GeneralSearch,
      RecordsPerPage: 10,
      page,
      currentMonth,
      onSuccess: (response) => {
        setLoading(false)
        setMetadata(response.paging)
        setScheduleList(response.data)
      },
      onError: handleError
    })
  }

  useEffect(() => {
    if (currentCondominium.id) {
      updateList()
    }
  }, [isFiltered, currentMonth, currentCondominium.id, page])

  const headers = ['Reserva', 'Area', 'Unidade', 'Data', 'Hora', ''].map(
    (h) => {
      return { headerName: h }
    }
  )

  return (
    <>
      <Card {...other}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            minHeight: '56px',
            padding: theme.spacing(3)
          }}
        >
          <Typography
            sx={{
              color: colors.dark300,
              fontSize: typography.size.h5,
              fontWeight: 600,
              fontFamily: typography.type.secondary
            }}
          >
            {title}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              margin: 0
            }}
          >
            <MonthNavigator value={currentMonth} onChange={setCurrentMonth} />
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              maxWidth: 320,
              minWidth: 300
            }}
          >
            {isAdmin && (
              <Button onClick={() => setIsFiltered(!isFiltered)}>
                {isFiltered ? 'Todas as reservas' : 'Minhas Reservas'}
              </Button>
            )}
            {hasCreateButton && (
              <Link to='/schedule/reservation/new'>
                <Button>+ Nova Reserva</Button>
              </Link>
            )}
          </Box>
        </Box>
        <CardContent>
          <DataTable size='small' loading={loading} columns={headers}>
            {scheduleList.map((schedule) => (
              <ScheduleListRow
                key={schedule.id}
                schedule={schedule}
                handleError={handleError}
                onDelete={updateList}
              />
            ))}
          </DataTable>
        </CardContent>
      </Card>
      <Flex width='100%' justify='flex-end'>
        <Pagination
          page={page}
          totalResults={metadata.totalRecords}
          pageSize={metadata.recordsPerPage}
          onChange={(event, page) => setPage(page)}
          disabled={loading}
        />
      </Flex>
    </>
  )
}
