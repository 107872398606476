import { condofyProtected, condofyProtectedMultiPart } from './instance'
import FormData from 'form-data'

export const saveFile = ({
  path,
  file,
  isWorkspace,
  condominiumId,
  onError: emitErrorEvent = (e) => e,
  onSuccess: emitSuccessEvent = (e) => e
}) => {
  const workspace = isWorkspace ? 'workspace' : 'administration'
  const formData = new FormData()
  formData.append('path', path)
  formData.append('file', file[0].file)

  condofyProtectedMultiPart
    .post(
      `/v1/condominiums/${condominiumId}/attachments/path-${workspace}`,
      formData
    )
    .then(({ data }) => emitSuccessEvent(data))
    .catch((error) => emitErrorEvent(error))
}

export const createFolder = ({
  isWorkspace,
  path,
  condominiumId,
  onError: emitErrorEvent = (e) => e,
  onSuccess: emitSuccessEvent = (e) => e
}) => {
  const workspace = isWorkspace ? 'workspace' : 'administration'
  const formData = new FormData()
  formData.append('path', path)

  condofyProtectedMultiPart
    .post(
      `/v1/condominiums/${condominiumId}/attachments/path-${workspace}`,
      formData
    )
    .then(({ data }) => emitSuccessEvent(data))
    .catch((error) => emitErrorEvent(error))
}

export const deleteFolder = ({
  path,
  condominiumId,
  onError: emitErrorEvent = (e) => e,
  onSuccess: emitSuccessEvent = (e) => e
}) => {
  const formData = new FormData()
  formData.append('path', path)

  condofyProtectedMultiPart
    .post(`/v1/condominiums/${condominiumId}/attachments/delete`, formData)
    .then(({ data }) => emitSuccessEvent(data))
    .catch((error) => emitErrorEvent(error))
}

export const editFolder = ({
  old_path,
  new_path,
  condominiumId,
  onError: emitErrorEvent = (e) => e,
  onSuccess: emitSuccessEvent = (e) => e
}) => {
  const formData = new FormData()
  formData.append('old_path', old_path)
  formData.append('new_path', new_path)

  condofyProtectedMultiPart
    .post(`/v1/condominiums/${condominiumId}/attachments/rename`, formData)
    .then(({ data }) => emitSuccessEvent(data))
    .catch((error) => emitErrorEvent(error))
}

export const makePathPublic = ({
  fullPath,
  condominiumId,
  onError: emitErrorEvent = (e) => e,
  onSuccess: emitSuccessEvent = (e) => e
}) => {
  condofyProtected
    .post(`/v1/condominiums/${condominiumId}/attachments/make-path-public`, {
      fullPath
    })
    .then(({ data }) => emitSuccessEvent(data))
    .catch((error) => emitErrorEvent(error))
}

export const makePathPrivate = ({
  fullPath,
  condominiumId,
  onError: emitErrorEvent = (e) => e,
  onSuccess: emitSuccessEvent = (e) => e
}) => {
  condofyProtected
    .post(`/v1/condominiums/${condominiumId}/attachments/make-path-private`, {
      fullPath
    })
    .then(({ data }) => emitSuccessEvent(data))
    .catch((error) => emitErrorEvent(error))
}
