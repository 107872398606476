import React from 'react'
import { Chart, DoughnutController, ArcElement } from 'chart.js'
import { Doughnut } from 'react-chartjs-2'
import {
  CurrencyText,
  Flex,
  Typography,
  colors,
  typography,
  Skeletonize
} from '@condofy/components'
import styled from 'styled-components'
import { Box } from '@mui/material'

Chart.register(DoughnutController, ArcElement)

const Wrapper = styled(Flex)`
  &,
  & * {
    font-family: ${typography.type.tertiary} !important;
  }
`

const DoughnutContainer = styled.div`
  position: relative;
  margin: 0 auto;
  height: 150px;
  width: 150px;
`

const DoughnutInner = styled(Flex)`
  position: absolute;
`

const styles = {
  '&': {
    fontWeight: 600
  }
}

export const PredictedVsAchievedDoughnut = ({
  title,
  total,
  achieved,
  color,
  loading,
  ...other
}) => {
  const primaryColor = colors[`${color}500`]
  const secondaryColor = colors.light500
  const overflowColor = colors.light500
  let colorArray = [primaryColor, secondaryColor]
  let difference = total - achieved
  let values = [achieved, Math.abs(difference)]

  if (difference < 0) {
    colorArray = [overflowColor, primaryColor]
    values = [Math.abs(difference), total === 0 ? total : achieved]
  }

  if (total === 0 && difference === 0 && achieved === 0) {
    values = [0, 1]
  }

  const data = {
    labels: ['Realizado', 'Não realizado'],
    datasets: [
      {
        data: values,
        backgroundColor: colorArray,
        hoverBackgroundColor: colorArray,
        borderWidth: 0
      }
    ]
  }

  return (
    <Wrapper direction='column' alignItems='center' spacingY={2}>
      <Typography>{title}</Typography>
      <DoughnutContainer {...other}>
        <DoughnutInner
          height='100%'
          width='100%'
          direction='column'
          alignItems='center'
          justify='center'
        >
          <Skeletonize condition={loading} width={20}>
            <CurrencyText value={achieved} variant='body2' />
          </Skeletonize>
          <Typography
            sx={{ ...styles }}
            variant='body2'
            component='h6'
            className='root'
          >
            Realizado
          </Typography>
        </DoughnutInner>
        <Doughnut
          legend={{ display: false }}
          data={data}
          options={{
            maintainAspectRatio: false,
            cutout: '85%',
            tooltip: {
              enabled: false
            }
          }}
        />
      </DoughnutContainer>
      <Flex width='100%' itemsGrid={6}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Skeletonize condition={loading} width={20}>
            <CurrencyText
              styles={{
                '.symbol,.value': {
                  fontWeight: 600
                }
              }}
              variant='body2'
              value={Math.abs(difference)}
              className='root'
            />
          </Skeletonize>
          <Typography variant='body2' component='h6'>
            {difference >= 0 ? 'Faltam' : 'Excedente'}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Skeletonize condition={loading} width={20}>
            <CurrencyText
              styles={{
                '.symbol,.value': {
                  fontWeight: 600
                }
              }}
              value={total}
              className='root'
              variant='body2'
            />
          </Skeletonize>
          <Typography variant='body2' component='h6'>
            Previsto
          </Typography>
        </Box>
      </Flex>
    </Wrapper>
  )
}
