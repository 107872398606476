import ReactQuill from 'react-quill'
import styled from 'styled-components'
import React from 'react'
import './quill.snow.css'
import { typography, controls } from '../../styles'
import { Typography, Box } from '@mui/material'

export const Quill = styled(ReactQuill)`
  display: flex;
  flex-direction: column;
  max-height: 300px;
  min-height: 180px;
  margin: 0;

  .ql-toolbar {
    border-color: ${controls.field.borderColor} !important;
    border-top-right-radius: ${controls.field.borderRadius};
    border-top-left-radius: ${controls.field.borderRadius};
  }

  .ql-container {
    background: ${controls.field.backgroundColor};
    border-color: ${controls.field.borderColor} !important;
    border-bottom-right-radius: ${controls.field.borderRadius};
    border-bottom-left-radius: ${controls.field.borderRadius};
  }

  .ql-editor {
    color: ${controls.field.textColor};
    font-family: ${typography.type.tertiary} !important;
    font-size: ${controls.field.fontSize};
    padding: ${controls.field.padding};

    em,
    strong {
      all: revert;
    }
  }
`

function removeTags(str) {
  if (str === null || str === '') return false
  else str = str.toString()
  // Regular expression to identify HTML tags in the input string.
  // Replacing the identified HTML tag with a null string.
  return str.replace(/(<([^>]+)>)/gi, '')
}

export const QuillCounter = ({ maxLength, defaultValue, ...props }) => {
  return (
    <Box sx={{ height: '100%' }}>
      <Quill
        defaultValue={defaultValue}
        value={defaultValue}
        {...props}
        style={{ maxHeight: '100%', minHeight: '100%' }}
      />
      <Typography
        sx={{
          color:
            defaultValue && defaultValue.length === maxLength
              ? '#c62924'
              : '#686868',
          fontSize: 12,
          marginLeft: 1,
          height: '10%'
        }}
      >
        {defaultValue && removeTags(defaultValue)
          ? removeTags(defaultValue).length
          : 0}
        /{maxLength}
      </Typography>
    </Box>
  )
}
