import React from 'react'
import LinearProgress, {
  linearProgressClasses
} from '@mui/material/LinearProgress'
import { Heading5, Heading6 } from '@condofy/components'
import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'
import { Modal } from '@mui/material'
import { colors, theme } from '../../styles'

const DialogOverlay = styled(Modal)`
  background: rgba(31, 46, 75, 0.65);
  display: flex;
  justify-content: center;
  align-items: center;

  & .MuiPaper-root {
    background-color: ${colors.light100};
    border: solid 1px ${colors.light400};
    border-radius: 3px;
    box-shadow:
      0px 24px 18px rgba(23, 43, 77, 0.04),
      0px 35px 24px rgba(23, 43, 77, 0.08);
    padding: ${theme.spacing(3)};
    outline: none;

    ${({ maxWidth }) =>
      !maxWidth &&
      `
    max-width: 90vw;
      max-height: 90vh;

      ${theme.breakpoints.up('md')} {
        min-width: 35vw;
        max-width: 60vw;
      }
    `}
  }
`

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  width: '90%',
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800]
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8'
  }
}))

export const ProgressBar = ({ progress, active }) =>
  active && (
    <DialogOverlay open={active}>
      <Box
        sx={{
          backgroundColor: 'white',
          height: '12vh',
          width: 500,
          position: 'fixed',
          right: 15,
          bottom: 15,
          borderRadius: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-evenly',
          flexDirection: 'column',
          ':hover': {
            opacity: 0.85
          }
        }}
      >
        <Heading5>Download em Progresso</Heading5>
        <Box
          sx={{
            width: '90%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <Heading6>{progress}%</Heading6>
          <BorderLinearProgress variant='determinate' value={progress} />
        </Box>
      </Box>
    </DialogOverlay>
  )
