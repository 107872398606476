import React from 'react'
import { Box } from '@mui/material'

export const TextTruncate = ({ width, ...args }) => (
  <Box
    {...args}
    maxWidth={width}
    whiteSpace='noWrap'
    textOverflow='ellipsis'
    overflow='hidden'
  />
)
