import React, { useState } from 'react'
import { Actions } from '@Redux/storage'
import { useDispatch } from 'react-redux'
import { CreateNewFolder } from '@mui/icons-material'

import { useSnackbar } from '@condofy/next/hooks/useSnackbar'
import { Modal, ActionRow } from '@condofy/next/components'
import { createFolder } from '@condofy/next/service/files'
import { TextField } from '@condofy/components'
import { useUserContext } from '@condofy/next/store/user'
import { track } from '@condofy/next/service/segment'
import { ModalContent } from '../../next/components/Modal'
import { Form } from '@condofy/components'

export const CreateFolderModal = ({
  open,
  onClose = () => {},
  currentFolder
}) => {
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const [loading, setLoading] = useState(false)
  const [folderName, setFolderName] = useState('')
  const {
    state: { currentCondominium }
  } = useUserContext()

  const buildPath = (folderName) => {
    const path = currentFolder[0].fullName.split('/')
    path.pop()
    path.push(folderName + '/')
    return path.join('/')
  }

  const handleSubmit = () => {
    const isWorkspace =
      currentFolder[0].fullName.split('/').indexOf('condomínio') > 0

    setLoading(true)
    createFolder({
      condominiumId: currentCondominium.id,
      isWorkspace,
      path: buildPath(folderName),
      onSuccess: () => {
        track(window.analytics, 'Files Folder Created', {
          path: buildPath(folderName)
        })
        setLoading(false)
        dispatch(Actions.storageList({ currentCondominium }))
        onClose()
      },
      onError: (error) => {
        let errorMessage = error.message
        if (error.response && error.response.data)
          errorMessage = error.response.data.reports[0].message

        enqueueSnackbar(errorMessage, {
          variant: 'error'
        })
        setLoading(false)
        onClose()
      }
    })
  }

  return (
    <Modal
      icon={<CreateNewFolder />}
      title='Nova Pasta'
      open={open}
      onClose={onClose}
      footer={
        <ActionRow
          loading={loading}
          confirmText='Criar'
          onSubmit={handleSubmit}
          onCancel={onClose}
          disabled={folderName.length > 0 ? false : true}
        />
      }
    >
      <ModalContent>
        <Form>
          <TextField
            label='Nome da Pasta'
            value={folderName}
            onChange={(event) => setFolderName(event.target.value)}
          />
        </Form>
      </ModalContent>
    </Modal>
  )
}
