import React from 'react'
import { ExpandableTableRow } from '../Table'
import { DataTableCell } from './DataTableCell'
import { ButtonMenu, MenuItem } from '@condofy/components'

export const ExpandableDataTableRow = ({
  row,
  columns = [],
  children,
  onDeleteRow,
  onEditRow,
  ...args
}) => (
  <ExpandableTableRow {...args}>
    {Boolean(row) &&
      columns
        .filter((c) => !c.hide && !c.expand)
        .map((c, index) => {
          return (
            <>
              {c.field !== 'actions' && (
                <DataTableCell
                  padding={c.padding}
                  key={`row-${c.field}-${index}`}
                >
                  {(c.renderCell && c.renderCell({ row })) ||
                    (c.valueGetter && c.valueGetter({ row })) ||
                    row[c.field]}
                </DataTableCell>
              )}
              {c.field === 'actions' && (
                <DataTableCell align='right' key={`row-${c.field}-${index}`}>
                  <ButtonMenu>
                    <MenuItem onClick={onDeleteRow}>Excluir</MenuItem>
                    <MenuItem onClick={onEditRow}>Editar</MenuItem>
                  </ButtonMenu>
                </DataTableCell>
              )}
            </>
          )
        })}
    {children}
  </ExpandableTableRow>
)
