import React from 'react'
import {
  Heading6,
  SmallBody,
  Flex,
  typography,
  theme
} from '@condofy/components'
import styled from 'styled-components'

const WizardContent = styled(Flex)`
  padding: ${theme.spacing(3)};

  * {
    font-family: ${typography.type.tertiary} !important;
  }

  .center {
    text-align: center;
  }
`

export const CommunityOnboardStep = ({ Image, children, ...args }) => {
  return (
    <WizardContent
      direction='column'
      alignItems='center'
      spacingY={3}
      {...args}
    >
      {Image}
      {children}
    </WizardContent>
  )
}

export const CommunityOnboardStepTitle = (args) => (
  <Heading6 className='center' {...args} />
)

export const CommunityOnboardStepBody = (args) => (
  <SmallBody className='center' {...args} />
)
