import types from './actionTypes'

export const useActions = (state, dispatch) => ({
  loadBankAccountList: (data) => {
    dispatch({ type: types.SET_LOADING_INDICATOR, payload: true })
    dispatch({ type: types.LOAD_BANK_ACCOUNT_LIST, payload: data })
  },
  updateCurrentAccount: (data) => {
    dispatch({ type: types.SELECT_BANK_ACCOUNT, payload: data })
  }
})
