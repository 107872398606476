import React from 'react'
import { TextField } from '@condofy/components'
import { CurrencyFormatCustom } from './CurrencyFormatCustom'

export const CurrencyTextField = ({ fundingType = 1, ...props }) => {
  return (
    <TextField
      {...props}
      InputProps={{
        inputComponent: CurrencyFormatCustom
      }}
      inputProps={{
        fundingType
      }}
    />
  )
}
