import React, { useState } from 'react'
import { RecordVoiceOver } from '@mui/icons-material'
import { FormRow, Form, QuillCounter, TextFieldCounter } from '@condofy/components'
import { Modal, ActionRow, ModalContent } from '@condofy/next/components'
import { useUserContext } from '@condofy/next/store/user'
import { useSnackbar, handleError } from '@condofy/next/hooks/useSnackbar'
import { track } from '@condofy/next/service/segment'

export const AnnouncementeModelCreateModal = () => {
  const { enqueueSnackbar } = useSnackbar()

  const {
    state: {
      currentCondominium,
      announcementModel: { createModalOpen, metadata }
    },
    actions: {
      listAnnouncementModel,
      closeCreateAnnouncementModelModal: close,
      createAnnouncementModel
    }
  } = useUserContext()

  const [model, setModel] = useState({})

  const handleChange = (event) => {
    const { name, value } = event.target
    setModel({ ...model, [name]: value })
  }

  const contentChange = (value) => {
    setModel({ ...model, content: value })
  }

  const onSubmit = () => {
    createAnnouncementModel({
      currentCondominium,
      payload: model
    })
      .then(() => {
        track(window.analytics, 'Announcement Template Created', model)
        setModel({})
        close()
        enqueueSnackbar('Modelo criado', {
          variant: 'success'
        })
        listAnnouncementModel({ currentCondominium })
      })
      .catch(handleError(enqueueSnackbar))
  }

  return (
    <Modal
      icon={<RecordVoiceOver />}
      title='Novo modelo'
      open={createModalOpen}
      onClose={close}
      footer={
        <ActionRow
          loading={metadata.loading}
          confirmText='Salvar modelo'
          onSubmit={onSubmit}
          onCancel={close}
        />
      }
    >
      <ModalContent>
        <Form>
          <FormRow>
            <TextFieldCounter
              name='title'
              label='Titulo'
              value={model.title || ''}
              onChange={handleChange}
              maxLength={50}
            />
          </FormRow>
          <FormRow>
            <TextFieldCounter
              name='subject'
              label='Assunto'
              value={model.subject || ''}
              onChange={handleChange}
              maxLength={50}
            />
          </FormRow>
          <FormRow>
            <QuillCounter
              required
              name='content'
              onChange={contentChange}
              defaultValue={model.content || ''}
              maxLength={2500}
            />
          </FormRow>
        </Form>
      </ModalContent>
    </Modal>
  )
}
