import React from 'react'
import { Skeleton } from './Skeleton'
import styled from 'styled-components'
import { Card, CardContent } from '../Card'

const StyledCard = styled(Card)`
  height: 100%;
  min-height: 100px;
`

export const SkeletonCard = (props) => (
  <StyledCard {...props}>
    <CardContent>
      <Skeleton variant='text' />
      <Skeleton variant='text' />
    </CardContent>
  </StyledCard>
)
