export const NEW_CONDO_MODAL = 'PROCESS_CONDO_MODAL'
export const EDIT_CONDO_MODAL = 'EDIT_CONDO_MODAL'
export const DELETE_CONDO_MODAL = 'DELETE_CONDO_MODAL'
export const PROCESS_UNIT_MODAL = 'PROCESS_UNIT_MODAL'
export const DETAILS_UNIT_MODAL = 'DETAILS_UNIT_MODAL'
export const PROCESS_UNIT_USER_MODAL = 'PROCESS_UNIT_USER_MODAL'
export const DELETE_UNIT_MODAL = 'DELETE_UNIT_MODAL'
export const DELETE_CONNECTION_MODAL = 'DELETE_CONNECTION_MODAL'
export const ASSIGN_MANAGER_MODAL = 'ASSIGN_MANAGER_MODAL'
export const DELETE_USER_MODAL = 'DELETE_USER_MODAL'
export const EDIT_USER_MODAL = 'EDIT_USER_MODAL'
export const ADD_USER_CONNECTION_MODAL = 'ADD_USER_CONNECTION_MODAL'
export const CHANGE_CONDOMINIUM_MODAL = 'CHANGE_CONDOMINIUM_MODAL'
export const ADD_USER_MODAL = 'ADD_USER_MODAL'
export const EDIT_BANK_MODAL = 'EDIT_BANK_MODAL'
export const DELETE_BANK_MODAL = 'DELETE_BANK_MODAL'
export const ADD_TICKET_MODAL = 'ADD_TICKET_MODAL'
export const UPDATE_TICKET_MODAL = 'UPDATE_TICKET_MODAL'
export const ADD_OCCURRENCE_MODAL = 'ADD_OCCURRENCE_MODAL'
export const UPDATE_OCCURRENCE_MODAL = 'UPDATE_OCCURRENCE_MODAL'
export const ADD_ANNOUNCEMENT_MODAL = 'ADD_ANNOUNCEMENT_MODAL'
export const ADD_ANNOUNCEMENT_MESSAGE_MODAL = 'ADD_ANNOUNCEMENT_MESSAGE_MODAL'
export const ADD_ANNOUNCEMENT_MODEL_MODAL = 'ADD_ANNOUNCEMENT_MODEL_MODAL'
export const ADD_STORAGE_MODAL = 'ADD_STORAGE_MODAL'
export const PROCESS_AREA_MODAL = 'PROCESS_AREA_MODAL'
