import { condofyProtected } from '@condofy/next/service/instance'
import { jsonToFormData } from '@condofy/next/service/formData'

class AnnouncementService {
  search({
    page,
    generalSearch,
    recordsPerPage,
    currentCondominium,
    generalStatus
  }) {
    return condofyProtected.post(
      `/v1/condominiums/${
        currentCondominium.id
      }/announcements/search?PageNumber=${
        page === undefined ? 1 : page
      }&RecordsPerPage=${recordsPerPage === undefined ? 10 : recordsPerPage}`,
      {
        title: generalSearch === undefined ? '' : generalSearch,
        status: generalStatus === undefined ? '' : generalStatus
      }
    )
  }

  create({ currentCondominium, announcement }) {
    return condofyProtected.post(
      `/v1/condominiums/${currentCondominium.id}/announcements`,
      jsonToFormData(announcement)
    )
  }

  update(condominiumId, id, body) {
    return condofyProtected.updateFormData(
      `/v1/condominiums/${condominiumId}/announcements/${id}`,
      body
    )
  }

  receivers(condominiumId) {
    return condofyProtected.get(
      `/v1/condominiums/${condominiumId}/announcements/receivers`
    )
  }

  modelsType(condominiumId) {
    return condofyProtected.get(
      `/v1/condominiums/${condominiumId}/announcements-model/all`
    )
  }

  modelSelected(condominiumId, id) {
    return condofyProtected.get(
      `/v1/condominiums/${condominiumId}/announcements-model/${id}`
    )
  }
}

export default new AnnouncementService()
