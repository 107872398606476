import React from 'react'
import { Dialog, DialogContent, Button } from '@condofy/components'
import { CommunityOnboardAuthPhone } from './CommunityOnboardAuthPhone'
import { useCommunityContext } from '@condofy/next/store/community'
import { Link } from 'react-router-dom'

export const CommunityOnboardPhoneModal = () => {
  const {
    state: {
      onboardingPhone: {
        modal: { open }
      }
    },
    actions: { closeOnboardingPhoneModal }
  } = useCommunityContext()

  const handleClose = () => {
    closeOnboardingPhoneModal()
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      hideHeader={true}
      maxWidth='sm'
      actions={
        <Link to='/my-profile'>
          <Button>Vamos lá</Button>
        </Link>
      }
    >
      <DialogContent>
        <CommunityOnboardAuthPhone />
      </DialogContent>
    </Dialog>
  )
}
