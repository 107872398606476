import { combineReducers } from '@condofy/next/store/user/combineReducers'

import {
  condominiumCreateActions,
  condominiumCreateReducer,
  createActionTypes,
  saveCondominium
} from './create'

import {
  condominiumEditActions,
  condominiumEditReducer,
  updateCondominium,
  editActionTypes
} from './edit'

import {
  condominiumDeleteActions,
  condominiumDeleteReducer,
  removeCondominium,
  deleteActionTypes
} from './delete'

import {
  condominiumInactiveActions,
  condominiumInactiveReducer,
  disableCondominium,
  inactiveActionTypes
} from './inactive'

import {
  condominiumActiveActions,
  condominiumActiveReducer,
  enableCondominium,
  activeActionTypes
} from './active'

import {
  condominiumListActions,
  condominiumListReducer,
  loadCondominiumList,
  listActionTypes,
  listAll
} from './list'

// ACTION TYPES
const CONDOMINIUM_SET_LOADING_INDICATOR = 'CONDOMINIUM_SET_LOADING_INDICATOR'
const CONDOMINIUM_SELECT = 'CONDOMINIUM_SELECT'

// ACTIONS
export const condominiumActions = (state, dispatch) => ({
  ...condominiumCreateActions(state, dispatch),
  ...condominiumEditActions(state, dispatch),
  ...condominiumDeleteActions(state, dispatch),
  ...condominiumInactiveActions(state, dispatch),
  ...condominiumActiveActions(state, dispatch),
  ...condominiumListActions(state, dispatch),

  selectCondominium: (payload) =>
    dispatch({ type: CONDOMINIUM_SELECT, payload })
})

export const condominiumPending = (dispatch, payload) =>
  dispatch({ type: CONDOMINIUM_SET_LOADING_INDICATOR, payload })

// MIDDLEWARE
export const condominiumMiddlewares = (dispatch, action) => {
  switch (action.type) {
    case createActionTypes.CONDOMINIUM_CREATE:
      return saveCondominium(dispatch, action.payload)

    case editActionTypes.CONDOMINIUM_EDIT:
      return updateCondominium(dispatch, action.payload)

    case deleteActionTypes.CONDOMINIUM_DELETE:
      return removeCondominium(dispatch, action.payload)

    case inactiveActionTypes.CONDOMINIUM_INACTIVE:
      return disableCondominium(dispatch, action.payload)

    case activeActionTypes.CONDOMINIUM_ACTIVE:
      return enableCondominium(dispatch, action.payload)

    case listActionTypes.CONDOMINIUM_LIST:
      return loadCondominiumList(dispatch, action.payload)

    case listActionTypes.CONDOMINIUM_LIST_FULL:
      return listAll(dispatch, action.payload)

    default:
      return false
  }
}

// REDUCER
const condominiumMainReducer = (state, action) => {
  switch (action.type) {
    case CONDOMINIUM_SELECT:
      return store(state, {
        selectedCondominium: action.payload
      })

    case CONDOMINIUM_SET_LOADING_INDICATOR:
      return store(state, {
        metadata: {
          ...state.condominium.metadata,
          loading: action.payload
        }
      })

    default:
      return state
  }
}

export const condominiumReducer = combineReducers({
  condominiumMainReducer,
  condominiumCreateReducer,
  condominiumDeleteReducer,
  condominiumInactiveReducer,
  condominiumActiveReducer,
  condominiumEditReducer,
  condominiumListReducer
})

export const store = (state, value) => ({
  ...state,
  condominium: { ...state.condominium, ...value }
})

export const condominium = {
  createModalOpen: false,
  deleteModalOpen: false,
  inactiveModalOpen: false,
  editModalOpen: false,
  selectedCondominium: {
    id: false,
    cnpj: '',
    name: '',
    type: {
      id: ''
    },
    address: {
      street: '',
      number: '',
      district: '',
      city: '',
      state: '',
      zipCode: ''
    },
    code: '',
    plan: {
      id: '1'
    }
  },
  items: [],
  allItems: [],
  metadata: {
    pageNumber: 1,
    totalRecords: 0,
    recordsPerPage: 10,
    loading: false
  }
}

export const condominiumActionTypes = {
  CONDOMINIUM_SET_LOADING_INDICATOR,
  CONDOMINIUM_SELECT,

  ...createActionTypes,
  ...editActionTypes,
  ...deleteActionTypes,
  ...inactiveActionTypes,
  ...activeActionTypes,
  ...listActionTypes
}
