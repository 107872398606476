import React, { useState, useEffect, useCallback } from 'react'
import {
  Card,
  CardHeader,
  CardContent,
  Select,
  MenuItem,
  Skeletonize,
  EmptyListMessage,
} from '@condofy/components'
import { useUserContext } from '@condofy/next/store/user'
import { BalanceSheetItem } from './BalanceSheetItem'
import { useFinancialContext } from '@condofy/next/store/financial'
import { BankAccountSelectorDownload } from './BankAccountSelectorDownload'

const styles = {
    '& .MuiCardHeader-root': {
      padding: (theme) => `${theme.spacing(2)}  !important`,
      paddingBottom: `0  !important`
    },
    '& .MuiCardHeader-title': {
      fontSize: `15px !important`,
      fontWeight: 600
    },
    '& .MuiInputBase-input.MuiSelect-select': {
      fontSize: `12px !important`,
      paddingTop: (theme) => `${theme.spacing(1)} !important`,
      paddingBottom: (theme) => `${theme.spacing(1)} !important`
    }
}

const currentYear = new Date().getFullYear()
const startYear = 2020
const years = Array.from(Array(currentYear - startYear + 1), (_, i) =>
  (i + startYear).toString()
)

export const BalanceSheet = (props) => {
  const {
    state: { currentCondominium,  }
  } = useUserContext()

  const {
    state: {
      balance: {
        items = [],
        metadata: { loading }
      }
    },
    actions: { listBalance }
  } = useFinancialContext()

  const [balance, setBalance] = useState([])
  const [year, setYear] = useState(currentYear)
  const stableListBalance = useCallback(listBalance, [])

  useEffect(() => {
    if (currentCondominium.id) {
      stableListBalance({ currentCondominium, year })
    }
  }, [currentCondominium, year, stableListBalance])

  useEffect(() => {
    const sortedBalance = items.sort((a, b) =>
      a.bankAccount.name.localeCompare(b.bankAccount.name)
    )
    setBalance(sortedBalance)
  }, [items])

  return (
    <Card {...props} sx = {{...styles}}>
      <CardHeader
        title='Balancete'
        className="header"
        action={
          <>
            <Select
              label='Ano'
              value={year}
              className="select"
              onChange={(event) => setYear(event.target.value)}
            >
              {years.map((year) => (
                <MenuItem key={year} value={year}>
                  {year}
                </MenuItem>
              ))}
            </Select>
            <BankAccountSelectorDownload year={year}/>
          </>
        }
      />
      <CardContent>
        <Skeletonize condition={loading}>
          {balance.map((account) => (
            <BalanceSheetItem
              year={year}
              account={account}
              key={account.bankAccount.id}
            />
          ))}
          {!balance?.length && <EmptyListMessage />}
        </Skeletonize>
      </CardContent>
    </Card>
  )
}
