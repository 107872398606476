import React, { useEffect } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useCommunityContext } from '@condofy/next/store/community'
import { Modal, ActionRow, ModalContent } from '@condofy/next/components'
import { FormContainer } from '@condofy/components'
import { listingSchema } from './listingSchema'
import { useUserContext } from '@condofy/next/store/user'
import { ListingForm } from './ListingForm'
import { track } from '@condofy/next/service/segment'

export const CreateListingModal = () => {
  const {
    state: {
      createListing: {
        modal: { open },
        metadata: { loading }
      }
    },
    actions: { closeCreateListingModal, creatListing, loadListingList }
  } = useCommunityContext()

  const {
    state: { currentCondominium, id }
  } = useUserContext()

  const form = useForm({
    mode: 'onChange',
    resolver: yupResolver(listingSchema),
    defaultValues: {
      title: '',
      description: '',
      chargerType: '',
      price: '',
      files: []
    }
  })

  const watchChargerType = form.watch('chargerType')

  useEffect(() => {
    if (watchChargerType === 1) {
      form.setValue('price', 0)
    }
  }, [watchChargerType])

  const handleClose = () => {
    form.reset()
    closeCreateListingModal()
  }

  const handleSubmit = () => {
    form.handleSubmit((values) => {
      creatListing({
        currentCondominium,
        payload: {
          ...values,
          price:
            values.chargerType === 1
              ? 0
              : values.price.toString().replace('.', ','),
          status: 1
        }
      }).then(() => {
        track(window.analytics, 'Community Create Offer', {
          chargeType: values.chargerType,
          condominiumId: currentCondominium.id,
          hasFile: values.files.length == 0 ? false : true,
          isFirstOffer: true,
          userId: id
        })
        handleClose()
        loadListingList({ currentCondominium, membershipId: id })
      })
    })()
  }

  return (
    <Modal
      title='Nova oferta'
      open={open}
      onClose={handleClose}
      maxWidth='xs'
      footer={
        <ActionRow
          fullWidth
          loading={loading}
          confirmText='Salvar'
          onSubmit={handleSubmit}
          disabled={loading}
          onCancel={handleClose}
        />
      }
    >
      <ModalContent>
        <FormContainer>
          <FormProvider {...form}>
            <ListingForm />
          </FormProvider>
        </FormContainer>
      </ModalContent>
    </Modal>
  )
}
