import { call, put, takeLatest } from 'redux-saga/effects'
import {
  groupCamel,
  groupSnake,
  toCamelLower,
  toSnake,
  toSnakeAndCamel
} from '~/lib/util/transformString'

import { getErrorMessage } from '@condofy/next/hooks/useSnackbar'

function* sagaRequest(...data) {
  try {
    const [
      {
        type,
        request,
        afterRequest = (a) => a,
        beforeRequest = (a) => a,
        actions
      },
      { payload }
    ] = data
    yield put(actions[type + 'Pending']())
    const newPayload = yield call(beforeRequest, payload)
    let response
    if (newPayload instanceof Array)
      response = yield call(request, ...newPayload)
    else response = yield call(request, newPayload)
    const newResponse = yield call(afterRequest, response, payload)
    yield put(actions[type + 'Success'](newResponse))
  } catch (error) {
    const [{ type, actions, handleError }, { payload }] = data
    if (handleError) {
      yield call(handleError, error, payload)
    } else {
      const errorResponse = error && error.response
      if (
        !Array.isArray(error) &&
        errorResponse &&
        errorResponse.status !== 401
      ) {
        window.alert(getErrorMessage(error))
        console.log(error)
      }
    }

    yield put(actions[type + 'Error'](error.message))
  }
}
function* watchRequest(key, data) {
  yield takeLatest(key, sagaRequest, data)
}

function* watchGeneric(key, func) {
  yield takeLatest(key, sagaGeneric, func)
}

function* sagaGeneric(...data) {
  const [func, { payload }] = data
  yield call(func, payload)
}

export default ({ baseType = '', actions = {}, service = {} }, listeners) => {
  const camel = toCamelLower(baseType)
  const snake = toSnake(camel)
  const _baseType = {
    camel,
    snake
  }
  return Object.keys(listeners).map((key) => {
    const { request, afterRequest, beforeRequest, handleError, generic } =
      listeners[key]
    const typeDefault = toSnakeAndCamel(key)
    const _key = groupSnake([_baseType, typeDefault])
    const type = groupCamel([_baseType, typeDefault])
    if (request) {
      return [
        watchRequest,
        _key,
        {
          type,
          actions,
          request: service[request],
          afterRequest,
          beforeRequest,
          handleError
        }
      ]
    } else {
      return [watchGeneric, _key, generic]
    }
  })
}
