import React, { useEffect, useState } from 'react'
import { getBelvoConsentStatus } from '@condofy/next/service/belvo'
import { useUserContext } from '@condofy/next/store/user'
import { IntegrationCard } from './integrationCard'
import { BankConnectionPopup } from './bankConnectionPopup'
import { BanksIntegrationDialogTrigger } from './belvoIntegrationsDialogTrigger'

export const BanksIntegrationsCard = () => {
  const {
    state: { currentCondominium }
  } = useUserContext()
  const [loading, setLoading] = useState(true)
  const [consentStatusList, setConsentStatusList] = useState({})

  useEffect(() => {
    if (currentCondominium) {
      getConsentStatusList()
    }
  }, [currentCondominium])

  const getConsentStatusList = () => {
    setLoading(true)
    getBelvoConsentStatus({ condominium: currentCondominium })
      .then(({ data }) => {
        setConsentStatusList(data.data)
      })
      .finally(() => setLoading(false))
  }

  return (
    <>
      <IntegrationCard
        loading={loading}
        ImageComponent={<img src={'/banks-logo.png'} style={{width: '75%', height: 'auto'}} />}
        title='Multi bancos - Extrato'
        description='Conecte sua conta bancária dos principais bancos do país com a Condofy.'
        showConnectButton={false}
        showDisconnectButton={false}
        AppendageComponent={
          consentStatusList.length > 0 ? (
            <BanksIntegrationDialogTrigger
              callbackFn={getConsentStatusList}
              banksList={consentStatusList}
            />
          ) : (
            <BankConnectionPopup
              callbackFn={getConsentStatusList}
              loading={loading}
            />
          )
        }
      />
    </>
  )
}
