import { condofyProtected } from '@condofy/next/service/instance'
import { jsonToFormData } from '@condofy/next/service/formData'
import { condofyProtectedMultiPart } from './instance'

export const search = ({
  page,
  generalSearch,
  recordsPerPage,
  currentCondominium,
  generalStatus
}) => {
  return condofyProtected.post(
    `/v1/condominiums/${
      currentCondominium.id
    }/announcements/search?PageNumber=${
      page === undefined ? 1 : page
    }&RecordsPerPage=${recordsPerPage === undefined ? 10 : recordsPerPage}`,
    {
      title: generalSearch === undefined ? '' : generalSearch,
      status: generalStatus === undefined ? '' : generalStatus,
    }
  )
}

export const createAnnouncement = ({ currentCondominium, payload }) => {
  condofyProtectedMultiPart.post(
    `/v1/condominiums/${currentCondominium.id}/announcements`,
    jsonToFormData(payload)
  )
}

export const editAnnouncement = ({ currentCondominium, payload }) =>
  condofyProtectedMultiPart.put(
    `/v1/condominiums/${currentCondominium.id}/announcements/${payload.id}`,
    jsonToFormData(payload)
  )

//   receivers(condominiumId) {
//     return condofyProtected.get(
//       `/v1/condominiums/${condominiumId}/announcements/receivers`
//     )
//   }

//   modelsType(condominiumId) {
//     return condofyProtected.get(
//       `/v1/condominiums/${condominiumId}/announcements-model/all`
//     )
//   }

//   modelSelected(condominiumId, id) {
//     return condofyProtected.get(
//       `/v1/condominiums/${condominiumId}/announcements-model/${id}`
//     )
//   }
// }

// export default new AnnouncementService()
