import React from 'react'
import { MenuItem } from '@mui/material'
import { TextField } from '@condofy/components'

export const TimeSelector = (props) => (
  <TextField select {...props}>
    <MenuItem value='2020-07-23T00:00:00.492Z'>00:00</MenuItem>
    <MenuItem value='2020-07-23T01:00:00.492Z'>01:00</MenuItem>
    <MenuItem value='2020-07-23T02:00:00.492Z'>02:00</MenuItem>
    <MenuItem value='2020-07-23T03:00:00.492Z'>03:00</MenuItem>
    <MenuItem value='2020-07-23T04:00:00.492Z'>04:00</MenuItem>
    <MenuItem value='2020-07-23T05:00:00.492Z'>05:00</MenuItem>
    <MenuItem value='2020-07-23T06:00:00.492Z'>06:00</MenuItem>
    <MenuItem value='2020-07-23T07:00:00.492Z'>07:00</MenuItem>
    <MenuItem value='2020-07-23T08:00:00.492Z'>08:00</MenuItem>
    <MenuItem value='2020-07-23T09:00:00.492Z'>09:00</MenuItem>
    <MenuItem value='2020-07-23T10:00:00.492Z'>10:00</MenuItem>
    <MenuItem value='2020-07-23T11:00:00.492Z'>11:00</MenuItem>
    <MenuItem value='2020-07-23T12:00:00.492Z'>12:00</MenuItem>
    <MenuItem value='2020-07-23T13:00:00.492Z'>13:00</MenuItem>
    <MenuItem value='2020-07-23T14:00:00.492Z'>14:00</MenuItem>
    <MenuItem value='2020-07-23T15:00:00.492Z'>15:00</MenuItem>
    <MenuItem value='2020-07-23T16:00:00.492Z'>16:00</MenuItem>
    <MenuItem value='2020-07-23T18:00:00.492Z'>18:00</MenuItem>
    <MenuItem value='2020-07-23T19:00:00.492Z'>19:00</MenuItem>
    <MenuItem value='2020-07-23T20:00:00.492Z'>20:00</MenuItem>
    <MenuItem value='2020-07-23T21:00:00.492Z'>21:00</MenuItem>
    <MenuItem value='2020-07-23T22:00:00.492Z'>22:00</MenuItem>
    <MenuItem value='2020-07-23T23:00:00.492Z'>23:00</MenuItem>
  </TextField>
)
