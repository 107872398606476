import React, { useState } from 'react'
import { DatePicker } from '@mui/x-date-pickers'
import { Flex } from '../Flex'
import { Button } from '../Button/Button'
import moment from 'moment'
import { Icon } from '../Icon/Icon'
import PropTypes from 'prop-types'
import { colors } from '../../styles'

const classes = {
  hidden: {
    display: 'flex',
    opacity: 0,
    position: 'absolute'
  },
  button: {
    '&:focus': {
      borderColor: 'transparent !important',
      background: 'transparent !important'
    }
  },
  icon: {
    color: colors.dark400,
    height: '1rem'
  },
  month: {
    color: `${colors.dark400} !important`,
    fontWeight: 'bold'
  }
}

export const MonthNavigator = ({
  okLabel = 'Selecionar',
  cancelLabel = 'Cancelar',
  onChange: emitChange,
  value
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [selectedMonth, setSelectedMonth] = useState(value)

  const dateChanged = (date) => {
    setSelectedMonth(date)
    emitChange(date)
  }
  const addMonth = (n) => {
    dateChanged(moment(selectedMonth).add(n, 'month').toDate())
  }
  return (
    <Flex>
      <DatePicker
        okLabel={okLabel}
        cancelLabel={cancelLabel}
        open={isOpen}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        style={{ display: 'none' }}
        openTo='year'
        views={['year', 'month']}
        sx = {{...classes.hidden}}
        className="hidden"
        onChange={dateChanged}
        autoOk
      />
      <Button
        sx = {{...classes.button}}
        variant='text'
        onClick={() => addMonth(-1)}
        className="button"
      >
        <Icon icon='chevron_left' sx = {{...classes.icon}} className="icon" />
      </Button>
      <Button
        variant='text'
        onClick={() => setIsOpen(true)}
        sx = {{...classes.button, ...classes.month}}
        className="button month"
      >
        {moment(selectedMonth).format('MMMM yyyy')}
      </Button>
      <Button
        variant='text'
        onClick={() => addMonth(1)}
        sx = {{...classes.button}}
        className="button"
      >
        <Icon icon='chevron_right' sx = {{...classes.icon}} className="icon" />
      </Button>
    </Flex>
  )
}
Button.propTypes = {
  okLabel: PropTypes.string,
  cancelLabel: PropTypes.string
}
