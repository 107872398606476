import React from 'react'

function Icon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='108'
      height='97'
      fill='none'
      viewBox='0 0 108 97'
    >
      <path
        fill='#F7CDBE'
        d='M41.36 35.456s.726-1.617-.885-1.876c-1.61-.259-.654-.7.245-.95 1.608-.447 1.309.124 1.466.236.866.617 1.187 1.031 1.187 1.031l-2.012 1.56z'
      ></path>
      <path
        fill='#B6DEFF'
        d='M16.671 58.82L5.353 95.987H3.587L15.055 58.33a.846.846 0 011.616.49zM36.036 55.258l-.128.42v.002L23.634 95.986h-1.766l12.552-41.22a.844.844 0 011.602-.038.83.83 0 01.014.53z'
      ></path>
      <path
        fill='#C5E3FF'
        d='M20.525 95.986h-1.689l-.501-37.223a.844.844 0 01.832-.857h.012a.844.844 0 01.845.833l.501 37.247zM38.593 95.986h-1.69l-.995-40.307-.012-.488a.843.843 0 111.688-.042l1.009 40.837z'
      ></path>
      <path
        fill='#DEEDFF'
        d='M15.863 3.804l21.529 7.588v41.26l-21.529 2.572V3.804z'
      ></path>
      <path
        fill='#fff'
        d='M17.942 6.741l17.37 6.124v37.943l-17.37 2.074V6.742z'
      ></path>
      <path fill='#B1D6FC' d='M15.863 3.804h-2.801v51.42h2.801V3.804z'></path>
      <path fill='#C5E3FF' d='M16.604 55.225h-4.995v5.59h4.995v-5.59z'></path>
      <path
        fill='#B1D6FC'
        d='M16.604 55.225l22.615-2.797v3.088l-22.615 5.3v-5.591z'
      ></path>
      <path
        fill='#C5E3FF'
        d='M23.688 4.958v2.738l9.174 3.257V8.419l-9.174-3.461z'
      ></path>
      <path
        fill='#E3EBFF'
        d='M92.143 96c4.114 0 7.45-.537 7.45-1.2 0-.662-3.336-1.2-7.45-1.2-4.114 0-7.45.538-7.45 1.2 0 .663 3.336 1.2 7.45 1.2z'
      ></path>
      <path
        fill='#BC863A'
        d='M89.715 82.87s1.312-.392 2.126-.302c.814.09.634.317 1.553.211.92-.105 2.067-.043 2.263-.067.196-.023.045.267-.407.325-.453.059-1.509.12-1.72.12-.21 0-3.815-.287-3.815-.287z'
      ></path>
      <path
        fill='#BC863A'
        d='M89.715 82.87s1.312-.392 2.126-.302c.814.09.634.317 1.553.211.92-.105 2.067-.043 2.263-.067.196-.023.045.267-.407.325-.453.059-1.509.12-1.72.12-.21 0-3.815-.287-3.815-.287z'
        opacity='0.3'
      ></path>
      <path
        fill='#DBA246'
        d='M97.534 84.215v4.513l-.281.134-.04.02-1.023.483-1.416.67-1.079.51-.046.022-.054.026a1.552 1.552 0 01-1.235.04l-.013-.006a1.129 1.129 0 01-.1-.045l-.746-.368c.386.138.81.117 1.181-.058l.054-.026.047-.023 1.079-.51 1.416-.67 1.022-.483.041-.02.281-.133v-3.84c.572-.066.912-.147.912-.236z'
      ></path>
      <path
        fill='#AFBDE0'
        d='M97.534 84.215v4.513l-.281.134-.04.02-1.023.483-1.416.67-1.079.51-.046.022-.054.026a1.552 1.552 0 01-1.235.04l-.013-.006a1.129 1.129 0 01-.1-.045l-.746-.368c.386.138.81.117 1.181-.058l.054-.026.047-.023 1.079-.51 1.416-.67 1.022-.483.041-.02.281-.133v-3.84c.572-.066.912-.147.912-.236z'
      ></path>
      <path
        fill='#D3DCF4'
        d='M96.622 84.45v3.84l-.281.134-.04.02-1.023.483-1.417.67-1.078.51-.047.022-.054.026c-.37.175-.795.197-1.18.06l-.232-.115-1.52-.749-1.016-.501-.247-.122v-4.513c0 .217 2.025.393 4.522.393 1.206.01 2.412-.042 3.613-.157z'
      ></path>
      <path
        fill='#C9D2EC'
        d='M98.963 94.842a.586.586 0 01-.376-.158l-2.264-5.382.75-.355 2.3 5.511a.543.543 0 01-.314.37.33.33 0 01-.096.014zM87.005 94.848a.332.332 0 01-.097-.013.545.545 0 01-.314-.37l2.31-5.54.745.367-2.268 5.398a.586.586 0 01-.376.158zM93.005 96a.508.508 0 01-.494-.52v-4.796a1.608 1.608 0 00.988-.046v4.843a.508.508 0 01-.494.52z'
      ></path>
      <path
        fill='#BC863A'
        d='M98.081 82.87v1.42c0 .244-2.27.441-5.07.441-2.8 0-5.07-.197-5.07-.44v-1.422h10.14z'
      ></path>
      <path
        fill='#C9D2EC'
        d='M98.081 82.87v1.42c0 .244-2.27.441-5.07.441-2.8 0-5.07-.197-5.07-.44v-1.422h10.14z'
      ></path>
      <path
        fill='#BC863A'
        d='M93.011 83.31c2.8 0 5.07-.197 5.07-.44 0-.244-2.27-.441-5.07-.441-2.8 0-5.07.197-5.07.44 0 .244 2.27.442 5.07.442z'
      ></path>
      <path
        fill='#AFBDE0'
        d='M93.011 83.31c2.8 0 5.07-.197 5.07-.44 0-.244-2.27-.441-5.07-.441-2.8 0-5.07.197-5.07.44 0 .244 2.27.442 5.07.442z'
      ></path>
      <path
        fill='#9EC5F4'
        d='M92.395 80.202s6.339-5.671 1.983-7.517c-4.356-1.845.527-4.514-1.207-7.051s-3.86 1.147-3.015-4.714c.59-4.097-3.744-.776-4.115-5.197-.274-3.266-3.88-3.504-4.034-.449-.154 3.056 1.603 4.02-.234 4.293-1.837.272-2 2.283.845 4.815 2.844 2.532.122 2.468-.554 3.47-.675 1.001.559 1.27 3.36 3.05 2.802 1.781-2 1.758-2.123 2.866-.123 1.108 2.383 7.909 9.094 6.434'
      ></path>
      <path
        fill='#7FA9E0'
        d='M91.97 82.653c.078-.54.128-1.082.15-1.627.023-.547.025-1.096.006-1.645a19.401 19.401 0 00-.394-3.264 15.92 15.92 0 00-.99-3.128l-.082-.189-.089-.185c-.06-.123-.117-.247-.179-.37-.13-.24-.252-.485-.396-.718a14.22 14.22 0 00-.913-1.364 25.858 25.858 0 01-3.251-5.84 30.985 30.985 0 01-1.849-6.416.12.12 0 01.02-.088.117.117 0 01.211.047l.001.007a30.749 30.749 0 001.892 6.337 25.537 25.537 0 003.262 5.733c.344.445.661.91.951 1.39.15.239.279.49.415.736.065.124.124.252.187.378l.093.19.086.192a16.344 16.344 0 011.047 3.198c.239 1.097.384 2.213.434 3.335.026.56.03 1.121.01 1.683a15.877 15.877 0 01-.14 1.688.245.245 0 01-.485-.07v-.001l.002-.009z'
      ></path>
      <path
        fill='#D9E7DE'
        d='M92.395 80.202s6.339-5.671 1.983-7.517c-4.356-1.845.527-4.514-1.207-7.051s-3.86 1.147-3.015-4.714c.59-4.097-3.744-.776-4.115-5.197-.274-3.266-3.88-3.504-4.034-.449-.154 3.056 1.603 4.02-.234 4.293-1.837.272-2 2.283.845 4.815 2.844 2.532.122 2.468-.554 3.47-.675 1.001.559 1.27 3.36 3.05 2.802 1.781-2 1.758-2.123 2.866-.123 1.108 2.383 7.909 9.094 6.434'
      ></path>
      <path
        fill='#8EACA8'
        d='M91.97 82.653c.078-.54.128-1.082.15-1.627.023-.547.025-1.096.006-1.645a19.401 19.401 0 00-.394-3.264 15.92 15.92 0 00-.99-3.128l-.082-.189-.089-.185c-.06-.123-.117-.247-.179-.37-.13-.24-.252-.485-.396-.718a14.22 14.22 0 00-.913-1.364 25.858 25.858 0 01-3.251-5.84 30.985 30.985 0 01-1.849-6.416.12.12 0 01.02-.088.117.117 0 01.211.047l.001.007a30.749 30.749 0 001.892 6.337 25.537 25.537 0 003.262 5.733c.344.445.661.91.951 1.39.15.239.279.49.415.736.065.124.124.252.187.378l.093.19.086.192a16.344 16.344 0 011.047 3.198c.239 1.097.384 2.213.434 3.335.026.56.03 1.121.01 1.683a15.877 15.877 0 01-.14 1.688.245.245 0 01-.485-.07v-.001l.002-.009z'
      ></path>
      <path
        fill='#C2DCCC'
        d='M94.683 81.364s-3.675-3.651-.967-4.635c2.708-.984-.177-2.763.958-4.25 1.135-1.489 2.311.82 1.983-2.772-.229-2.51 2.302-.353 2.668-3.03.27-1.978 2.472-2.009 2.468-.145-.003 1.863-1.102 2.394.006 2.618 1.109.224 1.144 1.452-.667 2.902-1.81 1.45-.152 1.497.227 2.128s-.381.755-2.14 1.75c-1.761.993 1.161 1.132 1.2 1.81.039.678-1.7 4.734-5.736 3.624z'
      ></path>
      <path
        fill='#8EACA8'
        d='M94.865 82.869a9.428 9.428 0 01-.04-.995c.003-.333.02-.667.05-1 .057-.666.172-1.326.342-1.973a9.716 9.716 0 01.701-1.871l.056-.112.06-.11c.04-.073.079-.147.12-.219.088-.142.17-.287.264-.424.184-.279.384-.546.6-.801a15.758 15.758 0 002.162-3.449 18.884 18.884 0 001.328-3.843.069.069 0 00-.011-.053.07.07 0 00-.043-.03.073.073 0 00-.053.008.07.07 0 00-.032.043l-.001.003a18.724 18.724 0 01-1.352 3.794 15.551 15.551 0 01-2.166 3.384c-.224.26-.431.532-.623.816-.1.14-.185.288-.275.434-.044.073-.084.149-.126.224l-.063.112-.058.114a9.95 9.95 0 00-.739 1.912c-.18.66-.303 1.334-.37 2.014-.033.34-.053.681-.059 1.024-.008.344.003.688.032 1.031a.147.147 0 00.053.101.149.149 0 00.244-.128v-.001l-.001-.005z'
      ></path>
      <path
        fill='#C9D2EC'
        d='M59.633 80.183h2.052v-7.25h-2.052v7.25zM55.29 72.132h10.578a1.97 1.97 0 000-3.938H55.29a1.969 1.969 0 000 3.938z'
      ></path>
      <path
        fill='#111'
        d='M55.29 72.132h10.578a1.97 1.97 0 000-3.938H55.29a1.969 1.969 0 000 3.938z'
        opacity='0.2'
      ></path>
      <path fill='#C9D2EC' d='M58.721 73.787h3.754v-2.575H58.72v2.575z'></path>
      <path
        fill='#111'
        d='M58.721 73.787h3.754v-2.575H58.72v2.575z'
        opacity='0.4'
      ></path>
      <path fill='#E5EDFD' d='M58.721 90.278h3.754V79.006H58.72v11.272z'></path>
      <path fill='#C9D2EC' d='M50.216 90.458h21.197V88.89H50.216v1.568z'></path>
      <path fill='#C9D2EC' d='M70.17 93.766h1.244V88.89H70.17v4.876z'></path>
      <path
        fill='#C9D2EC'
        d='M68.945 93.765a1.948 1.948 0 103.895 0 1.948 1.948 0 00-3.895 0zM50.216 93.766h1.244V88.89h-1.244v4.876z'
      ></path>
      <path
        fill='#C9D2EC'
        d='M48.991 93.765a1.948 1.948 0 103.896 0 1.948 1.948 0 00-3.896 0z'
      ></path>
      <path
        fill='#ED776B'
        d='M47.473 70.435a14.569 14.569 0 0014.552-14.553V38.055a1.837 1.837 0 10-3.675 0v17.828A10.89 10.89 0 0147.473 66.76a1.838 1.838 0 100 3.675z'
      ></path>
      <path
        fill='#C9D2EC'
        d='M60.188 36.217h19.621l-1.193 14.397.571 10.828-9.786 6.231-2.307 2.762H47.472l5.334-3.38 3.977-4.509 1.591-.972 1.326-8.75.088-10.519.4-6.088z'
      ></path>
      <path
        fill='#FDFFFF'
        d='M67.095 70.435a14.57 14.57 0 0014.552-14.552V38.055a1.838 1.838 0 00-3.676 0v17.828A10.889 10.889 0 0167.095 66.76a1.838 1.838 0 100 3.675z'
      ></path>
      <path
        fill='#4E67E7'
        d='M44.759 66.811l4.305 23.902 4.517 1.967s2.627-22.243 2.462-25.457c-.165-3.214-3.134-5.275-5.605-5.275-2.472 0-5.68 1.896-5.68 4.863z'
      ></path>
      <path
        fill='#111'
        d='M44.759 66.811l4.305 23.902 4.517 1.967s2.627-22.243 2.462-25.457c-.165-3.214-3.134-5.275-5.605-5.275-2.472 0-5.68 1.896-5.68 4.863z'
        opacity='0.2'
      ></path>
      <path
        fill='#F4B85D'
        d='M53.183 95.985l.143-1.126-11.716-.091-.03 1.043 11.603.174z'
      ></path>
      <path
        fill='#F4B85D'
        d='M53.581 92.68l-.266 2.178s-1.71.021-6.387.04c-4.661.018-5.607.498-5.283-.233.227-.511 1.843-1.4 3.509-2.205l.258-.125.708-.332.26-.119a75.344 75.344 0 011.702-.755h.001l.238-.102h.001c.458-.196.742-.314.742-.314l4.517 1.966z'
      ></path>
      <path
        fill='#fff'
        d='M47.734 92.316a.144.144 0 01-.096.197.146.146 0 01-.167-.082l-.38-.868.263-.116.38.869zM46.685 92.583a.143.143 0 01-.074.189.144.144 0 01-.189-.074l-.303-.694.26-.12.306.7zM45.637 92.85a.14.14 0 01.002.11.14.14 0 01-.076.079.143.143 0 01-.189-.073l-.22-.506.257-.125.226.516z'
      ></path>
      <path
        fill='#050F1B'
        d='M53.183 95.985l.143-1.126-11.716-.091-.03 1.043 11.603.174z'
        opacity='0.3'
      ></path>
      <path
        fill='#4E67E7'
        fillRule='evenodd'
        d='M73.21 52.545s-22.097-.285-26.997.78c-4.9 1.065-6.924 3.515-9.267 7.776-2.343 4.26-10.473 26.648-10.473 26.648l3.9 3.013L48.99 64.734s10.328 4.877 21.566.958c6.178-2.982 2.654-13.147 2.654-13.147z'
        clipRule='evenodd'
      ></path>
      <g opacity='0.2'>
        <path
          fill='#111'
          d='M73.21 52.546H55.15s16.3.887 18.676 3.105l-.614-3.105z'
          opacity='0.1'
        ></path>
      </g>
      <path
        fill='#F4B85D'
        fillRule='evenodd'
        d='M76.56 45.01h.01l-.057.003.04-.002h.007v-.001z'
        clipRule='evenodd'
      ></path>
      <path
        fill='#F4B85D'
        d='M29.177 93.87l.414-1.057-11.338-2.955-.284 1.004 11.208 3.008z'
      ></path>
      <path
        fill='#F4B85D'
        d='M30.372 90.762l-.792 2.047s-1.663-.397-6.202-1.523c-4.524-1.123-5.558-.889-5.066-1.518.345-.44 2.13-.907 3.942-1.28.093-.02.188-.04.28-.058l.768-.149.282-.052c.265-.049.524-.095.768-.138l.284-.048.783-.13.256-.04h.002c.491-.078.796-.123.796-.123l3.899 3.012z'
      ></path>
      <path
        fill='#fff'
        d='M24.791 88.978a.14.14 0 01-.024.107.142.142 0 01-.117.06.146.146 0 01-.142-.12l-.156-.934.283-.048.156.935zM23.709 88.981a.14.14 0 01-.025.108.142.142 0 01-.093.058.144.144 0 01-.165-.118l-.124-.748.282-.052.125.752zM22.627 88.985a.14.14 0 01-.025.107.142.142 0 01-.093.057.143.143 0 01-.165-.117l-.09-.544c.093-.02.187-.04.28-.058l.093.555z'
      ></path>
      <path
        fill='#050F1B'
        d='M29.177 93.87l.414-1.057-11.338-2.955-.284 1.004 11.208 3.008z'
        opacity='0.3'
      ></path>
      <path
        stroke='#111'
        strokeMiterlimit='10'
        strokeWidth='0.742'
        d='M30.372 88.912l18.33-26.624s23.061 4.447 25.51-3.196'
        opacity='0.3'
      ></path>
      <path
        stroke='#889CFF'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.854'
        d='M1.01 95.985h103.782'
      ></path>
      <path
        fill='#081D39'
        d='M75.352 27.106c-1.775-1.833-2.275-3.003-2.586-4.195-.75-2.877-1.476-4.06-2.466-5.06-.774-.783-1.702-1.049-2.458-1.263-1.182-.337-3.029-1.088-4.831-.215-2.834 1.374-.752 7.892-.752 7.892s-.751.934-.335 2.806c.417 1.873-1.433 2.5-1.433 2.5-4.194 2.587.92 6.506.92 6.506 8.94 4.587 13.992-.667 13.992-.667 4.102-3.429 1.394-6.814-.051-8.304z'
      ></path>
      <path
        fill='#F7CDBE'
        d='M51.767 42.274l1.11-2.598 5.21 1.045a17.635 17.635 0 01-.897 2.65c-.538 1.246-2.456 3.808-3.63 4.388l-1.793-5.485z'
      ></path>
      <path
        fill='#F4B85D'
        d='M60.812 28.396l4.455 5.8-6.962 7.554-1.048 1.68-4.44-3.806a.713.713 0 01-.21-.78c.59-1.668 2.8-7.178 3.701-8.214.878-1.008 2.643-1.658 3.575-1.935l.929-.299z'
      ></path>
      <path
        fill='#F7CDBE'
        d='M41.36 35.456l2.013-1.56 9.158 8.106c3.085 2.455 2.493 4.123 2.493 4.123-1.172 2.702-4 1.848-4 1.848-1.581-.52-6.51-7.175-9.663-12.517z'
      ></path>
      <path
        fill='#F4B85D'
        d='M73.31 52.906l-2.45.458-7.283 1.36a9.283 9.283 0 01-7.519-1.887.461.461 0 01-.1-.613c.303-.467.883-1.575 1.75-4.106.972-2.826.376-5.142-.388-7.313-.2-.565-.41-1.12-.607-1.672-.107-.298-.21-.593-.303-.891-.948-2.963-.237-5.192 2.541-6.816 2.776-1.623 1.86-3.029 1.86-3.029s.12-.045.346-.109c.32-.088.643-.16.97-.213l1.891-.26 4.28-.386c1.725.22 3.275.348 4.47.734 0 0 .66 3.53-.05 5.243a5.066 5.066 0 00-.117.306c-.066.192-.135.418-.21.674a23.259 23.259 0 00-.599 2.84c-.396 2.61-.479 5.84.63 7.88.183.335.34.682.472 1.04 1.182 3.172.415 6.76.415 6.76z'
      ></path>
      <path
        fill='#fff'
        d='M68.299 27.429l-4.28.386-1.289.177s-1.983 4.2 1.988 3.955c0 0 4.408 1.077 4.991-4.346l-1.41-.172'
      ></path>
      <path
        fill='#111'
        d='M68.299 27.689l-4.28.385-1.289.178s-1.983 4.2 1.988 3.955c0 0 4.408 1.077 4.991-4.347l-1.41-.171'
        opacity='0.2'
      ></path>
      <path
        fill='#111'
        d='M57.776 42.509l-.526-1.683a73.89 73.89 0 00-.331-.914c-.093-.252-.185-.504-.274-.754-.118-.33-.218-.623-.305-.895-.99-3.101-.173-5.293 2.574-6.899l.073.125c-2.678 1.566-3.475 3.704-2.51 6.73.088.27.187.561.304.89l.274.753c.112.304.224.61.333.919l.526 1.685-.139.043z'
        opacity='0.4'
      ></path>
      <path
        fill='#F4B85D'
        d='M72.77 28.164s1.75.592 2.666 1.433c.962.883 2.077 2.747 2.361 4.536.226 1.422.154 5.225-.036 6.956a.714.714 0 01-.883.614l-5.024-1.264-.838-6.242 1.753-6.033z'
      ></path>
      <path
        fill='#F7CDBE'
        d='M65.03 31.108s3.377-2.601 3.269-3.68l-.281-2.774.087-.05c.55-.316.882-1.129.846-1.69-.012-.184-.048-.743-.358-.898-.238-.118-.633-.003-1.143.333l-.055.036-.065-.015c-.087-.019-1.76-.408-2.722-1.593.316.857.946 1.618 1.883 2.27l.97.676-1.113-.4c-.032-.012-2.977-1.107-3.083-3.925-1.46 3.606-1.174 5.422-.644 6.32a2.124 2.124 0 001.54 1.061l.155.013-.013.156-.149.852a6.54 6.54 0 00.877 3.308z'
      ></path>
      <path
        stroke='#050F1B'
        strokeMiterlimit='10'
        strokeWidth='3'
        d='M64.154 27.802a6.54 6.54 0 00.877 3.306'
        opacity='0.6'
      ></path>
      <path
        fill='#050F1B'
        d='M64.317 26.794s1.214-.13 2.044-.857c0 0-.477 1.096-2.189 1.762l.145-.905z'
        opacity='0.6'
      ></path>
      <path
        fill='#F7CDBE'
        fillRule='evenodd'
        d='M60.193 49.664s-2.59-.421-2.72.151c-.075.334.264.373.965.706.392.186-2.256 1.535-1.906 1.82.338.274 1.437-.524 1.437-.524s-1.563 1.12-1.225 1.462c.257.26 1.754-1.037 1.754-1.037s-1.286 1.194-1.13 1.381c.32.382 1.814-.86 1.814-.86s-.125-.121-.687.541c-.584.69.403.635 1.414-.004 1.01-.64 1.662-1.092 1.662-1.092l-1.378-2.544z'
        clipRule='evenodd'
      ></path>
      <path
        fill='#111'
        d='M73.31 52.906l-2.449.457c.44-1.348.595-2.774.455-4.186-.434-4.019-1.18-5.647-1.166-9.214.013-3.486 2.345-6.131 2.451-6.251-.066.192-.135.418-.21.673a23.259 23.259 0 00-.599 2.84c-.397 2.61-.479 5.842.63 7.88.183.335.34.683.472 1.04 1.182 3.173.415 6.76.415 6.76z'
        opacity='0.4'
      ></path>
      <path
        fill='#F7CDBE'
        d='M73.953 48.798s2.684-.954 3.13-3.233c.067-.22.11-.445.133-.673v-3.165l-5.094-1.221.11 3.488 1.721 4.804z'
      ></path>
      <path
        fill='#F7CDBE'
        d='M72.238 43.997l-12.045 5.667 1.377 2.545 12.382-3.41-1.714-4.802z'
      ></path>
      <path
        fill='#9578FF'
        d='M61.664 53.275c5.627-.315 10.138-1.47 10.075-2.58-.062-1.11-4.673-1.754-10.3-1.44-5.626.315-10.136 1.47-10.074 2.58.062 1.11 4.673 1.754 10.3 1.44z'
      ></path>
      <path
        stroke='#1D1D1B'
        strokeMiterlimit='10'
        strokeWidth='1.85'
        d='M71.776 50.537c.062 1.11-4.448 2.265-10.075 2.58-5.626.314-10.237-.33-10.3-1.44'
        opacity='0.25'
      ></path>
      <path
        fill='#fff'
        d='M69.829 50.484c-.168-.256-.185-.436-.63-.288-.266.08-.521.19-.761.329-.216.118.309.347.278.544-.03.197.348.612.872.414.525-.198.86-.104.86-.383-.001-.28-.3-.386-.437-.453-.138-.067-.068.012-.182-.163z'
      ></path>
      <path
        fill='#71ABFF'
        d='M64.083 52.197c-.141.097.32.557.735.312.416-.246.504.04.773-.177s.476-.37-.092-.535c-.569-.164-.863-.17-.873-.391-.01-.22-1.226.121-1.048.355.179.234.69.308.505.436z'
      ></path>
      <path
        fill='#081D39'
        d='M58.16 52.138c.38-.362.86-.218 1.354-.336.494-.117.802.033.944.221.142.189.262.606-.128.546-.39-.06-.325-.076-.85.112-.526.188-.807.122-1.095.023-.288-.099-.554-.253-.225-.566z'
      ></path>
      <path
        fill='#F7CDBE'
        d='M54.816 51.316c-.004.244.865.094.722.371-.143.278-.509.114-.86.219-.35.104-1.307.004-1.442-.152s-.027-.349.448-.517c.475-.169 1.14-.352 1.133.079z'
      ></path>
      <path
        fill='#F4B85D'
        d='M58.266 50.071a11.661 11.661 0 011.842-.408c.392-.032.72.091.67.312-.053.221.297.322-.108.459-.406.137-.916.078-1.512.08-.44.001-1.384-.278-.892-.443z'
      ></path>
      <path
        fill='#5B3AF4'
        d='M64.087 49.707c.003-.123 1.036-.325 1.675-.302.64.024 1.022.278.761.526-.26.248-.697.386-1.209.254-.512-.133-1.237-.114-1.227-.478z'
      ></path>
      <path
        fill='#71ABFF'
        d='M39.456 34.304s-1.079.132-1.45.164c-.372.031-.958.764-.376 1.722.583.957 1.504.772 1.703.457.198-.315.81-1.213.81-1.213l-.687-1.13z'
      ></path>
      <path
        fill='#A9D0FF'
        d='M39.457 34.304l1.304-.794c.276-.168.936-.345.936-.345l.49.8s-.464.507-.735.673l-1.309.796-.686-1.13z'
      ></path>
      <path
        fill='#85C2FF'
        d='M41.697 33.165l3.615-2.202a.469.469 0 11.49.803l-3.13 1.906-.486.293-.49-.8z'
      ></path>
      <path
        stroke='#F5C6D6'
        strokeMiterlimit='10'
        strokeWidth='0.2'
        d='M39.456 34.304s-1.079.132-1.45.164c-.372.031-.958.764-.376 1.722'
      ></path>
      <path
        stroke='#F5C6D6'
        strokeMiterlimit='10'
        strokeWidth='0.2'
        d='M37.63 36.19c-.415-.684.219-1.339.565-1.411.347-.073 1.36-.314 1.36-.314'
      ></path>
      <path
        stroke='#F5C6D6'
        strokeMiterlimit='10'
        strokeWidth='0.2'
        d='M37.63 36.19c-.25-.41.433-.986.755-1.1.322-.112 1.267-.463 1.267-.463'
      ></path>
      <path
        stroke='#F5C6D6'
        strokeMiterlimit='10'
        strokeWidth='0.2'
        d='M37.63 36.19c-.083-.137.647-.635.945-.788l1.176-.614M37.63 36.19c.084.136.861-.284 1.134-.477.273-.194 1.085-.764 1.085-.764'
      ></path>
      <path
        stroke='#F5C6D6'
        strokeMiterlimit='10'
        strokeWidth='0.2'
        d='M37.63 36.19c.25.41 1.075.069 1.324-.166.248-.234.993-.913.993-.913'
      ></path>
      <path
        stroke='#F5C6D6'
        strokeMiterlimit='10'
        strokeWidth='0.2'
        d='M37.63 36.19c.417.683 1.29.42 1.513.145.224-.274.902-1.063.902-1.063'
      ></path>
      <path
        stroke='#F5C6D6'
        strokeMiterlimit='10'
        strokeWidth='0.2'
        d='M37.63 36.19c.583.957 1.504.772 1.703.457.198-.316.81-1.213.81-1.213'
      ></path>
      <path
        fill='#F7CDBE'
        d='M26.473 87.75s-1.366 1.994 3.898 3.012c-.762-1.877-2.032-2.93-3.898-3.012zM49.048 90.689s-.827 2.147 4.533 1.991c-.656-1.704-2.411-2.071-4.533-1.991zM58.756 53.264c-.274.38-.678.644-1.136.744-.789.185-2.577-.323-2.142-.669 0 0 2.697-.05 3.278-.075z'
      ></path>
      <path
        fill='#F7CDBE'
        d='M55.426 53.398s-.735.058-1.011-.24c-.237-.255-.364-.693.02-.901.444-.241 1.176.53 1.176.53s-.056-.63.363-.686c.568-.077.795.686.795.686s-.057-.713.416-.692c.472.021.66.76.66.76s.222-.573.568-.56c.615.025.333 1.149.333 1.149l-.938.443-2.382-.489z'
      ></path>
      <path
        fill='#A9D0FF'
        d='M17.942 37.28l5.746-11.343 5.282 11.488 3.871-6.532 2.471 6.044v13.87l-17.37 2.074V37.28z'
      ></path>
      <path
        fill='#A9D0FF'
        d='M29.522 28.966c.949 0 1.718-1.356 1.718-3.028s-.77-3.028-1.718-3.028c-.949 0-1.718 1.356-1.718 3.028s.769 3.028 1.718 3.028z'
      ></path>
      <path
        fill='#F7CDBE'
        d='M42.714 34.626s-2.51-.856-3.421-.816c-.912.04-.284 1.535 2.068 1.646 2.351.111 1.353-.83 1.353-.83z'
      ></path>
      <path
        stroke='#111'
        strokeMiterlimit='10'
        strokeWidth='0.742'
        d='M52.53 90.6l1.153-24.183'
        opacity='0.3'
      ></path>
      <path
        fill='#DCE8FF'
        d='M106.991 37.864v-13.94H87.667v13.94h19.324z'
      ></path>
      <path
        fill='#AFBDE0'
        d='M106.105 24.811H88.554v12.166h17.551V24.813z'
      ></path>
      <path
        stroke='#AFBDE0'
        strokeMiterlimit='10'
        strokeWidth='1.705'
        d='M105.87 25.046l1.121-1.121M88.789 25.046l-1.121-1.121M88.788 36.742l-1.12 1.121M105.87 36.742l1.121 1.121'
      ></path>
      <path
        fill='#fff'
        d='M96.452 25.254l-7.456 7.456v3.823h3.935l11.279-11.279h-7.758z'
      ></path>
      <path
        fill='#DCE8FF'
        d='M88.997 25.254v7.456l7.456-7.456h-7.456zM92.932 36.534h12.729v-11.28h-1.45l-11.28 11.28z'
      ></path>
      <path
        fill='#AFBDE0'
        d='M56.33 4.957a4.923 4.923 0 01-4.923 4.923l-.098-.001a4.923 4.923 0 000-9.843c.033-.002.065-.002.098-.002a4.923 4.923 0 014.923 4.923z'
      ></path>
      <path
        fill='#DCE8FF'
        d='M51.21 9.915a4.957 4.957 0 11.1-9.913 4.957 4.957 0 010 9.912h-.1zm0-9.154a4.197 4.197 0 10.084 8.392A4.196 4.196 0 0051.21.76z'
      ></path>
      <path
        stroke='#AFBDE0'
        strokeMiterlimit='10'
        strokeWidth='0.736'
        d='M51.21 1.155v.337M53.112 1.664l-.152.268M49.31 1.664l.15.268M54.504 6.858l-.268-.151M47.918 6.858l.268-.151M54.5 3.056l-.287.171M52.94 7.963l.172.287M49.481 7.963l-.171.287M47.918 3.056l.287.171M47.409 4.957h.435M51.21 8.76v-.337M55.013 4.957h-.337'
      ></path>
      <path
        fill='#DCE8FF'
        d='M51.21 5.295a.337.337 0 100-.675.337.337 0 000 .675z'
      ></path>
      <path
        fill='#DCE8FF'
        d='M51.502 4.957a.287.287 0 01-.094.215.291.291 0 11-.391-.432l.002-.001a.29.29 0 01.384 0l.002.001a.288.288 0 01.097.217z'
      ></path>
      <path
        fill='#AFBDE0'
        d='M51.438 3.043l-.033 1.697-.002-.002a.29.29 0 00-.384 0l-.002.002-.033-1.697.227-.43.227.43zM54.277 4.957l-.118.107h-2.677a.286.286 0 00.011-.18l2.666-.034.118.107z'
      ></path>
      <path fill='#B1D6FC' d='M23.035 6.331l.653-1.374v1.605l-.653-.23z'></path>
    </svg>
  )
}

export default Icon
