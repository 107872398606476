import React, { useEffect } from 'react'
import { FormField } from '../form/FormField'
import {
  Button,
  Flex,
  FormRow,
  Icon,
  PriceTextField,
  TextField
} from '@condofy/components'
import styled from 'styled-components'

const StyledFieldset = styled.fieldset`
  border: 0;
  padding: 0;
  margin: 0;
`

export const InvoiceItemsField = ({
  value = [],
  name: fieldName,
  onChange = () => {}
}) => {
  const [items, setItems] = React.useState([])

  const emitChange = () => {
    onChange({
      target: {
        name: fieldName,
        value: items
      }
    })
  }
  const addItem = () => {
    setItems((prevIndexes) => [...prevIndexes, {}])
    emitChange()
  }

  const removeItem = (index) => () => {
    setItems((prevIndexes) => [...prevIndexes.filter((item) => item !== index)])
    emitChange()
  }

  useEffect(() => {
    setItems(value)
  }, [])

  return (
    <Flex direction='column' spacingY={3}>
      {items.map((item, index) => {
        const name = `${fieldName}[${index}]`

        return (
          <StyledFieldset name={name} key={name}>
            <FormRow>
              <div>
                <FormField
                  defaultValue=''
                  name={`${name}.description`}
                  Component={<TextField label='Descrição' />}
                />
              </div>
              <div>
                <FormField
                  name={`${name}.value`}
                  defaultValue=''
                  Component={<PriceTextField label='Valor' />}
                />
              </div>
              <div>
                <FormField
                  name={`${name}.category`}
                  defaultValue=''
                  Component={<TextField label='Categoria' />}
                />
              </div>
              <div style={{flex: 0}}>
                <Button color='danger' onClick={removeItem(item)}>
                  <Icon icon='add_to_trash' />
                </Button>
              </div>
            </FormRow>
          </StyledFieldset>
        )
      })}
      <div>
        <Button
          variant='text'
          onClick={addItem}
          startIcon={<Icon icon='add_circle_outlined' />}
        >
          Adicionar item
        </Button>
      </div>
    </Flex>
  )
}
