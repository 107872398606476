import React, { useCallback, useEffect } from 'react'
import { Flex } from '@condofy/components'
import { AddCommentCard } from './AddCommentCard'
import { FeedToolbar } from './FeedToolbar'
import { CommentList } from './CommentList'

import { useUserContext } from '@condofy/next/store/user'
import { useCommunityContext } from '@condofy/next/store/community'

export const CommunityFeed = () => {
  const {
    state: { currentCondominium }
  } = useUserContext()

  const {
    state: {
      feedList: {
        items,
        metadata: { loading }
      }
    },
    actions: { loadFeedList }
  } = useCommunityContext()

  const stableLoadFeedList = useCallback(loadFeedList, [])

  useEffect(() => {
    stableLoadFeedList({ currentCondominium })
  }, [stableLoadFeedList, currentCondominium])

  return (
    <Flex spacingY={3} direction='column'>
      <AddCommentCard />
      <FeedToolbar />
      <CommentList items={items} loading={loading} />
    </Flex>
  )
}
