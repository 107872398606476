import { condofyProtected } from './instance'
import { normalizeObject } from '~/lib/util/normalizeObject'

export const getExistConnection = ({
  userId,
  onError: emitErrorEvent = (e) => e,
  onSuccess: emitSuccessEvent = (e) => e
}) => {
  condofyProtected
    .get(`/v1/connections/${userId}`)
    .then(({ data }) => emitSuccessEvent(normalizeObject(data)))
    .catch((error) => emitErrorEvent(error))
}

export const createUserConection = ({
  condominiumId,
  userTypeId,
  userId,
  unitId
}) => {
  return condofyProtected.post('/v1/connections', {
    condominiumId,
    userTypeId,
    userId,
    unitId
  })
}

export const updateUserConnection = ({ id, unitId, typeId, condominiumId }) =>
  condofyProtected.put(`/v1/connections/${id}`, {
    condominiumId,
    unitId,
    userTypeId: typeId
  })

export const delUserConnection = ({
  userId,
  onError: emitErrorEvent = (e) => e,
  onSuccess: emitSuccessEvent = (e) => e
}) => {
  condofyProtected
    .delete(`/v1/connections/${userId}`)
    .then(() => emitSuccessEvent())
    .catch((error) => emitErrorEvent(error))
}

export const listUserConnections = () => {
  return condofyProtected.get(`/v1/users/connections`)
}

export const removeUserConnection = ({ connectionId }) => {
  return condofyProtected.delete(`/v1/connections/${connectionId}`)
}
