import React, { useEffect, useState } from 'react'
import { format } from 'date-fns'
import {
  Button,
  DataTable,
  DataTableCell,
  DataTableRow,
  colors,
  Flex,
  Pagination
} from '@condofy/components'
import styled from 'styled-components'
import GroupIcon from '@mui/icons-material/Group'
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline'
import { useUserContext } from '@condofy/next/store/user'
import { getToken } from '@condofy/next/vendor/@condofy/token'
import { pt } from 'date-fns/locale'
import DeleteIcon from '@mui/icons-material/Delete'
import { Button as ButtonMui } from '@mui/material'
import { condofyProtected } from '@condofy/next/service/instance'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material'
import { useSnackbar } from '@condofy/next/hooks/useSnackbar'

export const AccountabilityReportsListing = ({
  reportFilter,
  setTask,
  listChange,
  setListChange,
  label
}) => {
  const token = getToken()

  const {
    state: { currentCondominium }
  } = useUserContext()

  const { enqueueSnackbar } = useSnackbar()

  const [loading, setLoading] = useState(false)
  const [officialList, setOfficialList] = useState([])
  const [activeList, setActiveList] = useState([])
  const [page, setPage] = useState(1)
  const [recordsPerPage /* , setRecordsPerPage */] = useState(10)
  const [totalRecords, setTotalRecords] = useState(0)
  const [isExcludeModalOpen, setIsExcludeModalOpen] = useState(false)
  const [excludeSelectedId, setExcludeSelectedId] = useState(null)

  const MetaButton = styled(Button)`
    &.MuiButton-contained {
      background: ${colors.light300};
      color: ${colors.primary};
    }
  `

  const MetaButtonDelete = styled(Button)`
    &.MuiButton-contained {
      background: ${colors.light300};
      color: ${colors.primary};
    }
  `

  useEffect(() => {
    updateDataSource()
  }, [])

  const deleteSignature = () => {
    setLoading(true)
    condofyProtected
      .delete(
        `/v1/condominiums/${currentCondominium.id}/document-signatures/${excludeSelectedId}`
      )
      .then(() => {
        updateDataSource()
        setIsExcludeModalOpen(false)
        setLoading(false)
        setExcludeSelectedId(null)
        enqueueSnackbar('Relatório removido com sucesso', {
          variant: 'success'
        })
      })
      .catch((err) => {
        setLoading(false)
        enqueueSnackbar('Erro ao remover o relatório', {
          variant: 'error'
        })
        console.log(err)
      })
  }

  useEffect(() => {
    updateDataSource()
    setListChange(false)
  }, [currentCondominium, listChange, page])

  useEffect(() => {
    if (officialList.length > 0) {
      let currentList = officialList

      //Apply filter based on header selection
      switch (reportFilter) {
        case 'open':
          currentList = currentList.filter(
            (report) => report.status.name === 'Aberto'
          )
          break
        case 'in progress':
          currentList = currentList.filter(
            (report) => report.status.name === 'Em progresso'
          )
          break
        case 'closed':
          currentList = currentList.filter(
            (report) => report.status.name === 'Finalizado'
          )
          break
        default:
          break
      }

      //Apply colors based on report status, calculate signatures and calculate reference dates
      currentList.forEach((report) => {
        switch (report.status.name) {
          case 'Aberto':
            report.status.color = '#CAD9F8'
            report.signatures = report.signers
              ? '0/' + report.signers.length
              : '0/0'
            report.referenceDate = format(
              new Date(report.createdAt),
              'dd/MM/yyyy'
            )
            break

          case 'Em progresso': {
            report.status.color = '#FAD084'
            const totalSigned = report.signers
              ? report.signers.filter((signer) => {
                  return !!signer.signedAt
                })
              : 0
            report.signatures = report.signers
              ? totalSigned.length + '/' + report.signers.length
              : '0/0'
            report.referenceDate = format(new Date(), 'dd/MM/yyyy')
            break
          }

          case 'Finalizado':
            report.status.color = '#9CE8B9'
            report.signatures = report.signers
              ? report.signers.length + '/' + report.signers.length
              : '0/0'
            report.referenceDate = format(
              new Date(report.completedAt),
              'dd/MM/yyyy'
            )
            break

          case 'Cancelado':
            report.status.color = '#F39F97'
            report.signatures = '-'
            report.referenceDate = format(
              new Date(report.cancelledAt),
              'dd/MM/yyyy'
            )
            break

          default:
            report.status.color = '#FFFFFF'
            report.signatures = '-'
            break
        }
      })

      setActiveList(
        currentList.sort((a, b) => (a.fileName > b.fileName ? -1 : 1))
      )
    } else {
      setActiveList([])
    }
  }, [officialList])

  async function updateDataSource() {
    if (label === 'ABERTO') {
      retrieveReportListOpen()
    } else if (label === 'EM PROGRESSO') {
      retrieveReportListInProgress()
    } else if (label === 'ENCERRADO') {
      await retrieveReportListClosed()
    } else {
      retrieveReportListAll()
    }
  }
  //4 cancelados
  //3 finalizados
  //2 em progresso
  //1 aberto
  async function retrieveReportListAll() {
    setLoading(true)
    const response = await fetch(
      `${process.env.REACT_APP_API_HOST}/v1/condominiums/${currentCondominium.id}/document-signatures/search?pageNumber=${page}&recordsPerPage=${recordsPerPage}`,
      {
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify({
          statuses: [1, 2, 3, 4]
        }),
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }
    )

    if (response.ok) {
      const responseData = await response.json()

      if (responseData.data !== undefined) {
        setOfficialList(responseData.data)
      } else {
        setOfficialList([])
      }

      setTotalRecords(responseData.paging.totalRecords)
      setLoading(false)
      return
    }
    setLoading(false)
    setOfficialList([])
  }

  useEffect(()=>{
    if(!isExcludeModalOpen){
      setExcludeSelectedId(null)
    }
  },[isExcludeModalOpen])

  async function retrieveReportListOpen() {
    setLoading(true)
    const response = await fetch(
      `${process.env.REACT_APP_API_HOST}/v1/condominiums/${currentCondominium.id}/document-signatures/search?pageNumber=${page}&recordsPerPage=${recordsPerPage}`,
      {
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify({
          statuses: [1]
        }),
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }
    )

    if (response.ok) {
      const responseData = await response.json()

      if (responseData.data !== undefined) {
        setOfficialList(responseData.data)
      } else {
        setOfficialList([])
      }

      setTotalRecords(responseData.paging.totalRecords)
      setLoading(false)
      return
    }
    setLoading(false)
    setOfficialList([])
  }

  async function retrieveReportListInProgress() {
    setLoading(true)
    const response = await fetch(
      `${process.env.REACT_APP_API_HOST}/v1/condominiums/${currentCondominium.id}/document-signatures/search?pageNumber=${page}&recordsPerPage=${recordsPerPage}`,
      {
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify({
          statuses: [2]
        }),
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }
    )

    if (response.ok) {
      const responseData = await response.json()

      if (responseData.data !== undefined) {
        setOfficialList(responseData.data)
      } else {
        setOfficialList([])
      }
      setLoading(false)
      setTotalRecords(responseData.paging.totalRecords)
      return
    }
    setLoading(false)
    setOfficialList([])
  }

  async function retrieveReportListClosed() {
    setLoading(true)
    const response = await fetch(
      `${process.env.REACT_APP_API_HOST}/v1/condominiums/${currentCondominium.id}/document-signatures/search?pageNumber=${page}&recordsPerPage=${recordsPerPage}`,
      {
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify({
          statuses: [3]
        }),
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }
    )
    if (response.ok) {
      const responseData = await response.json()

      if (responseData.data !== undefined) {
        setOfficialList(responseData.data)
      } else {
        setOfficialList([])
      }

      setTotalRecords(responseData.paging.totalRecords)
      setLoading(false)
      return
    }
    setLoading(false)
    setOfficialList([])
  }

  const columns = [
    { field: 'createdAt', headerName: 'Data Criação' },
    { field: 'fileName', headerName: 'Documento' },
    { field: 'month/year', headerName: 'Período' },
    { field: 'signatures', headerName: 'Assinaturas' },
    { field: 'status', headerName: 'Status' },
    { field: 'edit', headerName: '' },
    { field: 'download', headerName: '' }
  ]

  return (
    <>
      <div style={{ width: '100%', marginLeft: '25px' }}>
        <Dialog
          open={isExcludeModalOpen}
          onClose={() => setIsExcludeModalOpen(false)}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle id='alert-dialog-title'>Remover Modelo</DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-description'>
              Deseja remover o relatório?<br></br>
              <b>Atenção! Essa ação é irreversível.</b>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <div
              style={{
                justifyContent: 'space-between',
                display: 'flex',
                width: '100%',
                padding: '12px'
              }}
            >
              <Button
                color='secondary'
                style={{ height: '100%' }}
                disabled={false}
                onClick={() => setIsExcludeModalOpen(false)}
              >
                Cancelar
              </Button>

              <Button
                color='light'
                style={{ background: 'red', color: 'white', height: '100%' }}
                loading={loading}
                disabled={!excludeSelectedId}
                onClick={deleteSignature}
              >
                Remover
              </Button>
            </div>
          </DialogActions>
        </Dialog>
        <DataTable
          variant='borderless'
          size='small'
          width='100%'
          columns={columns}
          loading={loading}
          numColumns={activeList.length}
        >
          {activeList.length > 0 &&
            activeList.map((report, index) => (
              <DataTableRow key={report.id}>
                <DataTableCell width={'10%'}>
                  {format(new Date(report.createdAt), 'dd/MM/yyyy')}
                </DataTableCell>
                <DataTableCell width={'35%'}>{report.fileName}</DataTableCell>
                <DataTableCell width={'10%'}>
                  {format(
                    new Date(report.month + '/01/' + report.year),
                    'MMMM yyyy',
                    { locale: pt }
                  )[0].toUpperCase() +
                    format(
                      new Date(report.month + '/01/' + report.year),
                      'MMMM yyyy',
                      { locale: pt }
                    ).substring(1)}
                </DataTableCell>
                <DataTableCell width={'10%'}>{report.signatures}</DataTableCell>
                <DataTableCell width={'75%'}>
                  <span
                    style={{
                      color: '#000000',
                      backgroundColor: report.status.color,
                      padding: '2px 4px',
                      borderRadius: '3px'
                    }}
                  >
                    <b>{report.status.name}</b>
                  </span>
                  {report.status.name === 'Em progresso' ? ' até ' : ' em '}
                  {report.referenceDate}
                </DataTableCell>
                <div style={{ minWidth: 350 }}>
                  <DataTableCell style={{ minWidth: 200, padding: 0 }}>
                    <MetaButton
                      style={{ minWidth: 200 }}
                      color='light'
                      onClick={() =>
                        setTask({ report, action: 'select-signers' })
                      }
                      startIcon={<GroupIcon size='small' />}
                    >
                      {report.createdAt === null
                        ? 'Selecionar Signatários'
                        : 'Visualizar Signatários'}
                    </MetaButton>
                  </DataTableCell>
                  <DataTableCell width={'40px'}>
                    <MetaButton
                      color='light'
                      startIcon={<DownloadForOfflineIcon size='small' />}
                      onClick={() =>
                        setTask({
                          report,
                          action:
                            report.status.name !== 'Finalizado'
                              ? 'preview'
                              : 'download'
                        })
                      }
                    >
                      {report.status.name !== 'Finalizado'
                        ? 'Prévia'
                        : 'Baixar'}
                    </MetaButton>
                  </DataTableCell>
                  <DataTableCell width={'40px'}>
                    {report.status.name === 'Cancelado' ? (
                      <MetaButtonDelete
                        color='light'
                        startIcon={<DeleteIcon size='small' />}
                        onClick={() => {
                          setIsExcludeModalOpen(true)
                          setExcludeSelectedId(report.id)
                        }}
                      >
                        Excluir
                      </MetaButtonDelete>
                    ) : (
                      <ButtonMui
                        startIcon={<DeleteIcon size='small' />}
                        sx={{
                          cursor: 'default',
                          marginRight: 2,
                          color: index % 2 === 0 ? '#fafafa' : '#ffff',
                          ':hover': {
                            backgroundColor:
                              index % 2 === 0 ? '#fafafa' : '#fff'
                          }
                        }}
                      >
                        Excluir
                      </ButtonMui>
                    )}
                  </DataTableCell>
                </div>
              </DataTableRow>
            ))}
        </DataTable>
      </div>
      <div style={{ padding: '25px' }}>
        <Flex width='100%' justify='flex-end'>
          <Pagination
            totalResults={totalRecords}
            pageSize={recordsPerPage}
            page={page}
            disabled={loading}
            onChange={(event, page) => setPage(page)}
          />
        </Flex>
      </div>
    </>
  )
}
