import { unitActionTypes } from './unit'
import { userActionTypes } from './user'
import { ticketActionTypes } from './ticket'
import { ticketTypeActionTypes } from './ticket/type'
import { ticketCommentActionTypes } from './ticket/comment'
import { condominiumActionTypes } from './condominium'
import { connectionActionTypes } from './connection'
import { announcementActionTypes } from './announcement'
import { announcementModelActionTypes } from './announcementModel'
import { announcementReceiverActionTypes } from './announcementReceiver'
import { occurenceActionTypes } from './occurence'
import { areaActionTypes } from './area'

import { userConnectionActionTypes } from './user/connection'

const profile = {
  SET_LOADING_INDICATOR: 'SET_LOADING_INDICATOR',
  LOAD_PROFILE_DATA: 'LOAD_PROFILE_DATA',
  LOAD_PROFILE_DATA_SUCCESS: 'LOAD_PROFILE_DATA_SUCCESS',
  LOAD_PROFILE_DATA_ERROR: 'LOAD_PROFILE_DATA_ERROR',
  SET_ADMIN: 'SET_ADMIN',
  LOGOUT: 'LOGOUT'
}

const userList = {
  LOAD_USER_LIST: 'LOAD_USER_LIST_SUCCESS',
  LOAD_USER_LIST_SUCCESS: 'LOAD_USER_LIST_SUCCESS',
  LOAD_USER_LIST_ERROR: 'LOAD_USER_LIST_ERROR',
  SET_LIST_LOADING_INDICATOR: 'SET_LIST_LOADING_INDICATOR'
}

const user = {
  SELECT_USER: 'SELECT_USER',
  CLEAR_SELECTED_USER: 'CLEAR_SELECTED_USER',
  CHANGE_NAME: 'CHANGE_NAME',
  CHANGE_EMAIL: 'CHANGE_EMAIL',
  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
  CHANGE_PHONE_NUMBER: 'CHANGE_PHONE_NUMBER',
  SET_UPDATE_USER_MODAL_VISIBLE: 'SET_UPDATE_USER_MODAL_VISIBLE'
}

const appState = {
  SELECT_CURRENT_CONDOMINIUM: 'SELECT_CURRENT_CONDOMINIUM',
  CLOSE_NOTIFICATION: 'CLOSE_NOTIFICATION',
  LOAD_NOTIFICATION: 'LOAD_NOTIFICATION',
  LOAD_NOTIFICATION_SUCCESS: 'LOAD_NOTIFICATION_SUCCESS'
}

const temporaryData = {
  UPDATE_TEMPORARY_NAME: 'UPDATE_TEMPORARY_NAME',
  UPDATE_TEMPORARY_EMAIL: 'UPDATE_TEMPORARY_EMAIL',
  UPDATE_TEMPORARY_PHONE_NUMBER: 'UPDATE_TEMPORARY_PHONE_NUMBER',
  UPDATE_TEMPORARY_SOCIAL_SECURITY_NUMBER:
    'UPDATE_TEMPORARY_SOCIAL_SECURITY_NUMBER',
  SET_EMAIL_MODAL_VISIBLE: 'SET_EMAIL_MODAL_VISIBLE',
  SET_SMS_MODAL_VISIBLE: 'SET_SMS_MODAL_VISIBLE',
  SEND_PHONE_NUMBER_CONFIRMATION_CODE: 'SEND_PHONE_NUMBER_CONFIRMATION_CODE'
}

const connection = {
  SET_CONNECTION_MODAL_VISIBLE: 'SET_CONNECTION_MODAL_VISIBLE',
  SET_CREATE_CONNECTION_MODAL_VISIBLE: 'SET_CREATE_CONNECTION_MODAL_VISIBLE'
}

export default {
  ...user,
  ...userList,
  ...profile,
  ...appState,
  ...connection,
  ...temporaryData,

  ...unitActionTypes,
  ...userActionTypes,
  ...ticketActionTypes,
  ...ticketTypeActionTypes,
  ...ticketCommentActionTypes,
  ...condominiumActionTypes,
  ...connectionActionTypes,
  ...announcementActionTypes,
  ...announcementModelActionTypes,
  ...announcementReceiverActionTypes,
  ...occurenceActionTypes,
  ...userConnectionActionTypes,
  ...areaActionTypes
}
