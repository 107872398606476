import { condofyProtected } from './instance'
import moment from 'moment'
import { jsonToFormData } from '@condofy/next/service/formData'

export const getBalance = ({ currentCondominium, year: Year }) =>
  condofyProtected.post(
    `/v1/condominiums/${currentCondominium.id}/entries/annual-balance`,
    {},
    {
      params: {
        Year
      }
    }
  )

export const getExpectedValues = ({
  currentCondominium,
  //currentAccount,
  currentMonth
}) =>
  condofyProtected.post(
    `v1/condominiums/${currentCondominium.id}/entries/expected-realized`,
    {
      //bankAccountId: currentAccount.bankAccountId,
      startDate: moment(currentMonth)
        .startOf('month')
        .format('YYYY-MM-DD 00:00'),
      endDate: moment(currentMonth).endOf('month').format('YYYY-MM-DD 23:59')
    }
  )

export const downloadExcel = ({
  currentCondominium,
  // currentMonth,
  bankAccountId,
  entryType,
  balance = false,
  merge = false,
  RecordsPerPage,
  initialDate,
  finalDate
}) => {
  // const entryDateStart = startOfMonth(currentMonth)
  // const entryDateEnd = endOfMonth(currentMonth)
  const entryDateStart = moment(initialDate).format('YYYY-MM-DD 00:00')
  const entryDateEnd = moment(finalDate).format('YYYY-MM-DD 23:59')

  const body = {
    dueDateStart: entryDateStart,
    dueDateEnd: entryDateEnd,
    settleDateStart: entryDateStart,
    settleDateEnd: entryDateEnd,
    statuses: [3]
  }

  if (bankAccountId) body.bankIds = [bankAccountId]

  return condofyProtected.post(
    `/v1/condominiums/${currentCondominium.id}/entries/download-excel`,
    body,
    {
      responseType: 'arraybuffer',
      params: {
        typeDownload: entryType,
        IncludeBalance: balance,
        MergeDates: merge,
        RecordsPerPage
      }
    }
  )
}

export const downloadToPay = (payload) =>
  downloadExcel({ entryType: 0, ...payload })

export const downloadToReceive = (payload) =>
  downloadExcel({ entryType: 1, ...payload })

export const downloadToDetailed = (payload) =>
  downloadExcel({ entryType: 2, ...payload })

export const listEntriesBalance = ({ condominiumId }) =>
  condofyProtected.get(`/v1/condominiums/${condominiumId}/entries/balance`)

export const listEntries = ({ condominiumId }) =>
  condofyProtected.get(`/v1/condominiums/${condominiumId}/entries/search`)

export const listEntryCategories = () =>
  condofyProtected.get(`/v1/entry-categories`)

export const createFinanceEntry = ({ condominiumId, body }) =>
  condofyProtected.post(
    `/v1/condominiums/${condominiumId}/entries`,
    jsonToFormData(body),
    {
      headers: { 'Content-Type': 'multipart/form-data' }
    }
  )

export const updateFinanceEntry = ({ condominiumId, entryId, body }) =>
  condofyProtected.put(
    `/v1/condominiums/${condominiumId}/entries/${entryId}`,
    jsonToFormData(body),
    {
      headers: { 'Content-Type': 'multipart/form-data' }
    }
  )

export const deleteFinanceEntry = ({ condominiumId, entryId }) =>
  condofyProtected.delete(
    `/v1/condominiums/${condominiumId}/entries/${entryId}`
  )
