import React from 'react'
import PropTypes from 'prop-types'
import { Typography } from '../Typography'

const styles = {
  role: {
    fontSize: 'inherit !important',
    fontWeight: 'inherit',
    fontFamily: 'inherit !important',
    lineHeight: 'inherit'
  }
}

export const UserRoleText = ({ role = '', ...props }) => {
  const roles = {
    owner: 'Proprietário',
    renter: 'Inquilino',
    manager: 'Síndico'
  }

  return (
    <Typography component='span' className="role" {...props} sx = {{...styles.role}}>
      {roles[role.toLowerCase()] || role}
    </Typography>
  )
}

UserRoleText.propTypes = {
  role: PropTypes.oneOf(['owner', 'renter', 'manager'])
}
