import React from 'react'
import { Select } from '@condofy/components'
import { MenuItem } from '@mui/material'

export const UnitSelect = ({ label = 'Unidade', unities, ...props }) => {
  return (
    <Select label={label} {...props}>
      {unities.map((r) => (
        <MenuItem key={r.id} value={r.id}>
          {r.name}
        </MenuItem>
      ))}
    </Select>
  )
}
