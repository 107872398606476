import React, { useState } from 'react'
import { PremiumPage as PPage } from '@condofy/components'
import { useSnackbar } from '@condofy/next/hooks/useSnackbar'
import { useUserContext } from '@condofy/next/store/user'
import { receiveProposalPremium } from '@condofy/next/service/plan'
import Cookies from 'js-cookie'

export const PremiumPage = (args) => {
  const {
    state: { currentCondominium, isSuperAdmin, isAdmin }
  } = useUserContext()
  const { enqueueSnackbar } = useSnackbar()
  const [loading, setLoading] = useState(false)

  const onChangeReceiveProposal = () => {
    setLoading(true)
    receiveProposalPremium({
      id: currentCondominium.id,
      onSuccess: () => {
        Cookies.set('receiveProposal', 'true', { expires: 5 })
        enqueueSnackbar(`Solicitação da proposta enviada com sucesso!`, {
          variant: 'success'
        })
        setLoading(false)
      },
      onError: () => {
        setLoading(false)
      }
    })
  }

  return (
    <PPage
      proposalIsSent={Cookies.get('receiveProposal') !== undefined}
      loading={loading}
      onReceiveProposal={onChangeReceiveProposal}
      userIsAdmin={isSuperAdmin || isAdmin}
      {...args}
    />
  )
}
