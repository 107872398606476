import { store, userActionTypes, pending } from './index'

// ACTION TYPES
const USER_DETAILS_MODAL_VISIBLE = 'USER_DETAILS_MODAL_VISIBLE'

// ACTIONS
export const detailsActions = (state, dispatch) => ({
  closeDetailsUserModal: () => {
    dispatch({ type: userActionTypes.USER_SELECT, payload: false })
    dispatch({ type: USER_DETAILS_MODAL_VISIBLE, payload: false })
  },

  openDetailsUserModal: (user) => {
    dispatch({ type: userActionTypes.USER_SELECT, payload: user })
    dispatch({ type: USER_DETAILS_MODAL_VISIBLE, payload: true })
  }
})

// MIDDLEWARE
export const details = async (dispatch) => {
  try {
    pending(dispatch, true)
  } finally {
    pending(dispatch, false)
  }
}

// REDUCER
export const detailsReducer = (state, action) => {
  switch (action.type) {
    case USER_DETAILS_MODAL_VISIBLE:
      return store(state, { detailsModalOpen: action.payload })

    default:
      return state
  }
}

export const detailsActionTypes = {
  USER_DETAILS_MODAL_VISIBLE
}
