import React from 'react'
import PropTypes from 'prop-types'
import { Autocomplete as MuiAutocomplete } from '@mui/lab/'
import styled from 'styled-components'
import { TextField } from '../TextFields'
import { colors, pxToRem, typography } from '../../styles'
import { Icon } from '../Icon'
import { Box } from '@mui/material'

const StyledAutocomplete = styled(MuiAutocomplete)`
  .MuiAutocomplete-endAdornment,
  .MuiAutocomplete-startAdornment,
  .MuiAutocomplete-tag {
    z-index: 10;
  }
  .MuiAutocomplete-inputRoot {
    padding-top: 3px !important;
    padding-bottom: 0 !important;
  }
  .MuiOutlinedInput-input {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .MuiAutocomplete-option.Mui-focused {
    background-color: red !important;
  }
  .MuiAutocomplete-option[aria-selected='true'] {
    background-color: red;
  }
`

const Tag = styled(({ label, onDelete, ...props }) => (
  <div {...props}>
    <span>{label}</span>
    <Icon icon='clear' onClick={onDelete} />
  </div>
))`
  display: flex;
  align-items: center;
  background-color: ${colors.light300};
  border: solid 1px ${colors.light600};
  box-sizing: content-box;
  border-radius: 3px;
  color: ${colors.dark800};
  font-family: ${typography.type.tertiary} !important;
  font-size: ${pxToRem(12)}rem;
  line-height: 22px;
  margin: 2px;
  padding: 0 4px 0 10px;
  outline: 0;
  overflow: hidden;

  &:focus {
    border-color: #40a9ff;
    background-color: #e6f7ff;
  }

  & span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & svg {
    font-size: 16px;
    cursor: pointer;
    padding: 2px;
  }
`

const Option = ({ label }) => (
  <Box
    fontFamily={typography.tertiary}
    fontSize={`${pxToRem(14)}rem`}
    background='red'
  >
    {label}
  </Box>
)

export const Autocomplete = ({
  label,
  placeholder,
  getOptionLabel,
  clearText = 'Limpar tudo',
  ...props
}) => (
  <StyledAutocomplete
    renderTags={(tagValue, getTagProps) => {
      return tagValue.map((option, index) => (
        <Tag
          {...getTagProps({ index })}
          label={getOptionLabel ? getOptionLabel(option) : option}
          key={index}
        />
      ))
    }}
    renderInput={(inputProps) => (
      <TextField label={label} placeholder={placeholder} {...inputProps} />
    )}
    renderOption={(option, { selected }) => (
      <Option
        label={getOptionLabel ? getOptionLabel(option) : option}
        selected={selected}
      />
    )}
    getOptionLabel={getOptionLabel}
    clearText={clearText}
    {...props}
  />
)

Autocomplete.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string
}
