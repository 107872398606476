import { TableCell, TableRow } from '../Table'
import React from 'react'
import { Skeleton } from './Skeleton'
import PropTypes from 'prop-types'

export const SkeletonTableRow = ({ colSpan = 1, ...props }) => (
  <TableRow {...props}>
    {Array.apply(null, { length: colSpan }).map((e, i) => (
      <TableCell key={i}>
        <Skeleton />
      </TableCell>
    ))}
  </TableRow>
)
SkeletonTableRow.propTypes = {
  colSpan: PropTypes.number
}
