import { all, fork, put } from 'redux-saga/effects'
import Service from '~/lib/api/services/occurrence'
import { Actions } from '@Redux/occurrence'
import { toggleModal } from 'Actions/modals'
import { ADD_OCCURRENCE_MODAL, UPDATE_OCCURRENCE_MODAL } from 'Const'
import SagasUtil from '~/redux/sagas/util'
import { track } from '@condofy/next/service/segment'

export const base = {
  baseType: 'occurrence',
  actions: Actions,
  service: Service
}

export const search = {
  request: 'search',
  afterRequest: (response) => ({
    list: response.data.data,
    metadata: response.data.paging
  })
}

export const create = {
  request: 'create',
  afterRequest: function* () {
    track(window.analytics, 'Occurrences Created')
    yield put(toggleModal(ADD_OCCURRENCE_MODAL, false))
  }
}

export const update = {
  request: 'update',
  afterRequest: function* () {
    track(window.analytics, 'Occurrences Updated')
    yield put(toggleModal(UPDATE_OCCURRENCE_MODAL, false))
  }
}

export default function* root() {
  const sagas = SagasUtil(base, {
    create,
    update,
    search,
    searchStatus: search
  })
  yield all(sagas.map((saga) => fork(...saga)))
}
