import React from 'react'
import styled from 'styled-components'
import { FilePond as Pond, registerPlugin } from 'react-filepond'
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size'

import 'filepond/dist/filepond.min.css'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
import { colors, typography } from '@condofy/components'

registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateSize
)

const StyledPond = styled(Pond)`
  & .filepond--panel-root {
    background-color: ${colors.light300};
  }
  & label {
    color: ${colors.dark200};
    font-family: ${typography.type.tertiary};
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 0.5px;
  }

  &:hover,
  label:hover {
    cursor: pointer;
  }

  /* .filepond--credits {
    display: none;
  } */
`

export const FilePond = ({
  maxTotalFileSize = null,
  labelMaxTotalFileSizeExceeded,
  labelMaxTotalFileSize,
  setFile = () => {},
  label = 'Arraste ou clique para selecionar um arquivo',
  onupdatefiles,
  ...props
}) => {
  const onUpdate = (fileItems) =>
    setFile(fileItems.map((fileItem) => fileItem.file))

  const labelTotalExceeded = maxTotalFileSize
    ? 'O tamanho máximo de envio dos arquivos foi alcançado'
    : null

  const labelFileMax = maxTotalFileSize
    ? `O tamanho máximo do total de anexos é de ${maxTotalFileSize?.toLowerCase()}`
    : null

  return (
    <StyledPond
      labelMaxTotalFileSizeExceeded={
        labelMaxTotalFileSizeExceeded || labelTotalExceeded
      }
      labelMaxTotalFileSize={labelMaxTotalFileSize || labelFileMax}
      maxTotalFileSize={maxTotalFileSize}
      labelIdle={label}
      onupdatefiles={onupdatefiles || onUpdate}
      credits={false}
      {...props}
    />
  )
}
