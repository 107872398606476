// Exported from https://www.theiconof.com/
export const genericIcons = {
  add:
    'M19,11.5v1a.5.5,0,0,1-.5.5H13v5.5a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5V13H5.5a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5H11V5.5a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5V11h5.5A.5.5,0,0,1,19,11.5Z',
  add_circle:
    'M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm4,10.5a.5.5,0,0,1-.5.5H13v2.5a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5V13H8.5a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5H11V8.5a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5V11h2.5a.5.5,0,0,1,.5.5Z',
  add_circle_outlined:
    'M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20Zm3.5-9H13V8.5a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5V11H8.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5H11v2.5a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5V13h2.5a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,15.5,11Z',
  add_square:
    'M19,3H5A2,2,0,0,0,3,5V19a2,2,0,0,0,2,2H19a2,2,0,0,0,2-2V5A2,2,0,0,0,19,3Zm-3,9.5a.5.5,0,0,1-.5.5H13v2.5a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5V13H8.5a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5H11V8.5a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5V11h2.5a.5.5,0,0,1,.5.5Z',
  add_square_outlined:
    'M19,3H5A2,2,0,0,0,3,5V19a2,2,0,0,0,2,2H19a2,2,0,0,0,2-2V5A2,2,0,0,0,19,3Zm0,16H5V5H19ZM8.5,13H11v2.5a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5V13h2.5a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5H13V8.5a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5V11H8.5a.5.5,0,0,0-.5.5v1A.5.5,0,0,0,8.5,13Z',
  appointment:
    'M19,4H18V2.5a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5V4H8V2.5A.5.5,0,0,0,7.5,2h-1a.5.5,0,0,0-.5.5V4H5A2,2,0,0,0,3,6V19a2,2,0,0,0,2,2H19a2,2,0,0,0,2-2V6A2,2,0,0,0,19,4Zm0,15H5V8H19Zm-8.41-3.15a.48.48,0,0,0,.7,0l4.25-4.24a.51.51,0,0,0,0-.71L15,10.37a.51.51,0,0,0-.71,0l-3.36,3.36L9.7,12.49a.51.51,0,0,0-.71,0L8.46,13a.5.5,0,0,0,0,.7Z',
  appointment_cancelled:
    'M21,6a2,2,0,0,0-2-2H18V2.5a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5V4H8V2.5A.5.5,0,0,0,7.5,2h-1a.5.5,0,0,0-.5.5V4H5A2,2,0,0,0,3,6V19a2,2,0,0,0,2,2H19a2,2,0,0,0,2-2ZM19,19H5V8H19Zm-5.76-6,1.61-1.62a.48.48,0,0,0,0-.7l-.53-.53a.48.48,0,0,0-.7,0L12,11.76l-1.62-1.61a.48.48,0,0,0-.7,0l-.53.53a.48.48,0,0,0,0,.7L10.76,13,9.15,14.62a.48.48,0,0,0,0,.7l.53.53a.48.48,0,0,0,.7,0L12,14.24l1.62,1.61a.48.48,0,0,0,.7,0l.53-.53a.48.48,0,0,0,0-.7Z',
  archive:
    'M19,3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2V5C21,3.9,20.1,3,19,3z M15.9,14.3l-3.5,3.5 c-0.2,0.2-0.5,0.2-0.7,0c0,0,0,0,0,0l-3.5-3.5c-0.2-0.2-0.2-0.5,0-0.7l0.2-0.2c0.1-0.1,0.2-0.1,0.4-0.1H10V9.5C10,9.2,10.2,9,10.5,9 h3C13.8,9,14,9.2,14,9.5v3.8h1.3c0.1,0,0.3,0,0.4,0.1l0.2,0.2C16.1,13.8,16.1,14.1,15.9,14.3z M19,7H5V5h14V7z',
  autorenew:
    'M13.06,1.15,16.6,4.68a.51.51,0,0,1,0,.71L13.06,8.92a.5.5,0,0,1-.71,0l-.2-.2A.49.49,0,0,1,12,8.36V6a6,6,0,0,0-6,6,5.94,5.94,0,0,0,.69,2.79.5.5,0,0,1-.09.59l-.73.73a.5.5,0,0,1-.42.14A.52.52,0,0,1,5.08,16,8,8,0,0,1,12,4V1.71a.49.49,0,0,1,.15-.36l.2-.2A.5.5,0,0,1,13.06,1.15ZM10.94,15.08,7.4,18.61a.51.51,0,0,0,0,.71l3.54,3.53a.5.5,0,0,0,.71,0l.2-.2a.49.49,0,0,0,.15-.36V20A8,8,0,0,0,18.92,8a.52.52,0,0,0-.37-.24.5.5,0,0,0-.42.14l-.74.74a.51.51,0,0,0-.09.59A5.83,5.83,0,0,1,18,12a6,6,0,0,1-6,6V15.64a.49.49,0,0,0-.15-.36l-.2-.2A.5.5,0,0,0,10.94,15.08Z',
  block_ads:
    'M20.12,7.39,16.61,3.88A3,3,0,0,0,14.49,3h-5a3,3,0,0,0-2.12.88L3.88,7.39A3,3,0,0,0,3,9.51v5a3,3,0,0,0,.88,2.12l3.51,3.51A3,3,0,0,0,9.51,21h5a3,3,0,0,0,2.12-.88l3.51-3.51A3,3,0,0,0,21,14.49v-5A3,3,0,0,0,20.12,7.39ZM5,14.49v-5a1,1,0,0,1,.29-.7L8.81,5.29A1,1,0,0,1,9.51,5h5a1,1,0,0,1,.7.29l1.14,1.14-9.9,9.9L5.29,15.19A1,1,0,0,1,5,14.49Zm14,0a1,1,0,0,1-.29.7l-3.52,3.52a1,1,0,0,1-.7.29h-5a1,1,0,0,1-.7-.29L7.67,17.57l9.9-9.9,1.14,1.14a1,1,0,0,1,.29.7Z',
  bookmark:
    'M20,21a1,1,0,0,1-1.53.85l-5.94-3.72a1,1,0,0,0-1.06,0L5.53,21.85A1,1,0,0,1,4,21V4A2,2,0,0,1,6,2H18a2,2,0,0,1,2,2Z',
  bookmarks:
    'M18,21a1,1,0,0,1-1.53.85l-5.94-3.72a1,1,0,0,0-1.06,0L3.53,21.85A1,1,0,0,1,2,21V4A2,2,0,0,1,4,2H16a2,2,0,0,1,2,2ZM21,4H20V20h1a1,1,0,0,0,1-1V5A1,1,0,0,0,21,4Z',
  bookmarks_outlined:
    'M4,4H16V19.19l-4.41-2.75a3,3,0,0,0-3.18,0L4,19.19V4M4,2A2,2,0,0,0,2,4V21a1,1,0,0,0,1,1,1,1,0,0,0,.53-.15l5.94-3.72a1,1,0,0,1,1.06,0l5.94,3.72A1,1,0,0,0,17,22a1,1,0,0,0,1-1V4a2,2,0,0,0-2-2ZM21,20a1,1,0,0,0,1-1V5a1,1,0,0,0-1-1H20V20Z',
  bookmark_outlined:
    'M18,4V19.19l-4.41-2.75a3,3,0,0,0-3.18,0L6,19.19V4H18m0-2H6A2,2,0,0,0,4,4V21a1,1,0,0,0,1,1,1,1,0,0,0,.53-.15l5.94-3.72a1,1,0,0,1,1.06,0l5.94,3.72A1,1,0,0,0,19,22a1,1,0,0,0,1-1V4a2,2,0,0,0-2-2Z',
  browser_window:
    'M20,6m0,2V18H4V8Zm0-4H4A2,2,0,0,0,2,6V18a2,2,0,0,0,2,2H20a2,2,0,0,0,2-2V6a2,2,0,0,0-2-2Z',
  browser_windows:
    'M19,15V5a2,2,0,0,0-2-2H3A2,2,0,0,0,1,5V15a2,2,0,0,0,2,2H17A2,2,0,0,0,19,15ZM3,7H17v8H3ZM21,7V17a2,2,0,0,1-2,2H5a2,2,0,0,0,2,2H19a4,4,0,0,0,4-4V9A2,2,0,0,0,21,7Z',
  cached:
    'M22.85,11.65l-.2.2a.49.49,0,0,1-.36.15H20A8,8,0,0,1,8,18.92a.52.52,0,0,1-.24-.37.5.5,0,0,1,.14-.42l.73-.73a.5.5,0,0,1,.59-.09A5.94,5.94,0,0,0,12,18a6,6,0,0,0,6-6H15.64a.49.49,0,0,1-.36-.15l-.2-.2a.5.5,0,0,1,0-.71L18.61,7.4a.51.51,0,0,1,.71,0l3.53,3.54A.5.5,0,0,1,22.85,11.65Zm-13.93.7-.2-.2A.49.49,0,0,0,8.36,12H6a6,6,0,0,1,6-6,5.83,5.83,0,0,1,2.79.7.51.51,0,0,0,.59-.09l.74-.74a.5.5,0,0,0,.14-.42A.52.52,0,0,0,16,5.08,8,8,0,0,0,4,12H1.71a.49.49,0,0,0-.36.15l-.2.2a.5.5,0,0,0,0,.71L4.68,16.6a.51.51,0,0,0,.71,0l3.53-3.54A.5.5,0,0,0,8.92,12.35Z',
  calendar:
    'M19,4H18V2.5a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5V4H8V2.5A.5.5,0,0,0,7.5,2h-1a.5.5,0,0,0-.5.5V4H5A2,2,0,0,0,3,6V19a2,2,0,0,0,2,2H19a2,2,0,0,0,2-2V6A2,2,0,0,0,19,4Zm0,15H5V8H19Z',
  calendar_month:
    'M19,4H18V2.5a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5V4H8V2.5A.5.5,0,0,0,7.5,2h-1a.5.5,0,0,0-.5.5V4H5A2,2,0,0,0,3,6V19a2,2,0,0,0,2,2H19a2,2,0,0,0,2-2V6A2,2,0,0,0,19,4Zm0,15H5V8H19Zm-7.5-7h1a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5v1A.5.5,0,0,0,11.5,12Zm4,0h1a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5v1A.5.5,0,0,0,15.5,12Zm-8,0h1a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5v1A.5.5,0,0,0,7.5,12Zm4,4h1a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5v1A.5.5,0,0,0,11.5,16Zm-4,0h1a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5v1A.5.5,0,0,0,7.5,16Z',
  calendar_note:
    'M19,4H18V2.5a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5V4H8V2.5A.5.5,0,0,0,7.5,2h-1a.5.5,0,0,0-.5.5V4H5A2,2,0,0,0,3,6V19a2,2,0,0,0,2,2H19a2,2,0,0,0,2-2V6A2,2,0,0,0,19,4Zm0,15H5V8H19ZM7.5,16h5a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5h-5a.5.5,0,0,0-.5.5v1A.5.5,0,0,0,7.5,16Zm0-4h9a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5h-9a.5.5,0,0,0-.5.5v1A.5.5,0,0,0,7.5,12Z',
  carousel_horizontal:
    'M18,18V6a2,2,0,0,0-2-2H8A2,2,0,0,0,6,6V18a2,2,0,0,0,2,2h8A2,2,0,0,0,18,18Zm3,0a1,1,0,0,0,1-1V7a1,1,0,0,0-1-1H20V18ZM3,6A1,1,0,0,0,2,7V17a1,1,0,0,0,1,1H4V6Z',
  carousel_horizontal_outlined:
    'M16,6V18H8V6h8m0-2H8A2,2,0,0,0,6,6V18a2,2,0,0,0,2,2h8a2,2,0,0,0,2-2V6a2,2,0,0,0-2-2Zm5,14a1,1,0,0,0,1-1V7a1,1,0,0,0-1-1H20V18ZM3,6A1,1,0,0,0,2,7V17a1,1,0,0,0,1,1H4V6Z',
  carousel_vertical:
    'M18,6H6A2,2,0,0,0,4,8v8a2,2,0,0,0,2,2H18a2,2,0,0,0,2-2V8A2,2,0,0,0,18,6Zm0-3a1,1,0,0,0-1-1H7A1,1,0,0,0,6,3V4H18ZM6,21a1,1,0,0,0,1,1H17a1,1,0,0,0,1-1V20H6Z',
  carousel_vertical_outlined:
    'M18,8v8H6V8H18m0-2H6A2,2,0,0,0,4,8v8a2,2,0,0,0,2,2H18a2,2,0,0,0,2-2V8a2,2,0,0,0-2-2Zm0-3a1,1,0,0,0-1-1H7A1,1,0,0,0,6,3V4H18ZM6,21a1,1,0,0,0,1,1H17a1,1,0,0,0,1-1V20H6Z',
  checkmark_circle:
    'M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm4.35,7.73-5.62,5.62a.48.48,0,0,1-.7,0L7.65,13a.5.5,0,0,1,0-.71l.53-.53a.48.48,0,0,1,.7,0l1.5,1.49,4.74-4.74a.5.5,0,0,1,.7,0l.53.53A.5.5,0,0,1,16.35,9.73Z',
  checkmark_circle_outlined:
    'M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20ZM15.82,8.49a.5.5,0,0,0-.7,0l-4.74,4.74-1.5-1.49a.48.48,0,0,0-.7,0l-.53.53a.5.5,0,0,0,0,.71L10,15.35a.48.48,0,0,0,.7,0l5.62-5.62a.5.5,0,0,0,0-.71Z',
  checkmark_square:
    'M19,3H5A2,2,0,0,0,3,5V19a2,2,0,0,0,2,2H19a2,2,0,0,0,2-2V5A2,2,0,0,0,19,3ZM16.35,9.73l-5.62,5.62a.48.48,0,0,1-.7,0L7.65,13a.5.5,0,0,1,0-.71l.53-.53a.48.48,0,0,1,.7,0l1.5,1.49,4.74-4.74a.5.5,0,0,1,.7,0l.53.53A.5.5,0,0,1,16.35,9.73Z',
  checkmark_square_outlined:
    'M19,3H5A2,2,0,0,0,3,5V19a2,2,0,0,0,2,2H19a2,2,0,0,0,2-2V5A2,2,0,0,0,19,3Zm0,16H5V5H19Zm-9-3.65a.48.48,0,0,0,.7,0l5.62-5.62a.5.5,0,0,0,0-.71l-.53-.53a.5.5,0,0,0-.7,0l-4.74,4.74-1.5-1.49a.48.48,0,0,0-.7,0l-.53.53a.5.5,0,0,0,0,.71Z',
  clear:
    'M17.85,16.44a.5.5,0,0,1,0,.71l-.7.7a.5.5,0,0,1-.71,0L12,13.41,7.56,17.85a.5.5,0,0,1-.71,0l-.7-.7a.5.5,0,0,1,0-.71L10.59,12,6.15,7.56a.5.5,0,0,1,0-.71l.7-.7a.5.5,0,0,1,.71,0L12,10.59l4.44-4.44a.5.5,0,0,1,.71,0l.7.7a.5.5,0,0,1,0,.71L13.41,12Z',
  clear_circle:
    'M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm3.09,11.86a.48.48,0,0,1,0,.7l-.53.53a.48.48,0,0,1-.7,0L12,13.24l-1.86,1.85a.48.48,0,0,1-.7,0l-.53-.53a.48.48,0,0,1,0-.7L10.76,12,8.91,10.14a.48.48,0,0,1,0-.7l.53-.53a.48.48,0,0,1,.7,0L12,10.76l1.86-1.85a.48.48,0,0,1,.7,0l.53.53a.48.48,0,0,1,0,.7L13.24,12Z',
  clear_circle_outlined:
    'M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20ZM15.09,9.44l-.53-.53a.48.48,0,0,0-.7,0L12,10.76,10.14,8.91a.48.48,0,0,0-.7,0l-.53.53a.48.48,0,0,0,0,.7L10.76,12,8.91,13.86a.48.48,0,0,0,0,.7l.53.53a.48.48,0,0,0,.7,0L12,13.24l1.86,1.85a.48.48,0,0,0,.7,0l.53-.53a.48.48,0,0,0,0-.7L13.24,12l1.85-1.86A.48.48,0,0,0,15.09,9.44Z',
  clear_square:
    'M19,3H5A2,2,0,0,0,3,5V19a2,2,0,0,0,2,2H19a2,2,0,0,0,2-2V5A2,2,0,0,0,19,3ZM15.09,13.86a.48.48,0,0,1,0,.7l-.53.53a.48.48,0,0,1-.7,0L12,13.24l-1.86,1.85a.48.48,0,0,1-.7,0l-.53-.53a.48.48,0,0,1,0-.7L10.76,12,8.91,10.14a.48.48,0,0,1,0-.7l.53-.53a.48.48,0,0,1,.7,0L12,10.76l1.86-1.85a.48.48,0,0,1,.7,0l.53.53a.48.48,0,0,1,0,.7L13.24,12Z',
  clear_square_outlined:
    'M19,3H5A2,2,0,0,0,3,5V19a2,2,0,0,0,2,2H19a2,2,0,0,0,2-2V5A2,2,0,0,0,19,3Zm0,16H5V5H19ZM8.91,14.56l.53.53a.48.48,0,0,0,.7,0L12,13.24l1.86,1.85a.48.48,0,0,0,.7,0l.53-.53a.48.48,0,0,0,0-.7L13.24,12l1.85-1.86a.48.48,0,0,0,0-.7l-.53-.53a.48.48,0,0,0-.7,0L12,10.76,10.14,8.91a.48.48,0,0,0-.7,0l-.53.53a.48.48,0,0,0,0,.7L10.76,12,8.91,13.86A.48.48,0,0,0,8.91,14.56Z',
  column_view:
    'M22,18V6a2,2,0,0,0-2-2H15a2,2,0,0,0-2,2V18a2,2,0,0,0,2,2h5A2,2,0,0,0,22,18ZM9,20H4a2,2,0,0,1-2-2V6A2,2,0,0,1,4,4H9a2,2,0,0,1,2,2V18A2,2,0,0,1,9,20Z',
  column_view_outlined:
    'M20,6V18H15V6h5m0-2H15a2,2,0,0,0-2,2V18a2,2,0,0,0,2,2h5a2,2,0,0,0,2-2V6a2,2,0,0,0-2-2ZM9,6V18H4V6H9M9,4H4A2,2,0,0,0,2,6V18a2,2,0,0,0,2,2H9a2,2,0,0,0,2-2V6A2,2,0,0,0,9,4Z',
  command:
    'M19,3H5A2,2,0,0,0,3,5V19a2,2,0,0,0,2,2H19a2,2,0,0,0,2-2V5A2,2,0,0,0,19,3ZM15.85,9.38,9.38,15.85a.48.48,0,0,1-.7,0l-.53-.53a.48.48,0,0,1,0-.7l6.47-6.47a.48.48,0,0,1,.7,0l.53.53A.48.48,0,0,1,15.85,9.38Z',
  command_outlined:
    'M19,3H5A2,2,0,0,0,3,5V19a2,2,0,0,0,2,2H19a2,2,0,0,0,2-2V5A2,2,0,0,0,19,3Zm0,16H5V5H19ZM14.62,8.15a.48.48,0,0,1,.7,0l.53.53a.48.48,0,0,1,0,.7L9.38,15.85a.48.48,0,0,1-.7,0l-.53-.53a.48.48,0,0,1,0-.7Z',
  date_range:
    'M21,6a2,2,0,0,0-2-2H18V2.5a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5V4H8V2.5A.5.5,0,0,0,7.5,2h-1a.5.5,0,0,0-.5.5V4H5A2,2,0,0,0,3,6V19a2,2,0,0,0,2,2H19a2,2,0,0,0,2-2ZM19,19H5V8H19Zm-7.5-9a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h5a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5Zm-4,2h1a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5v1A.5.5,0,0,0,7.5,12Zm5,4a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5h-5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5Zm2.5-.5a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5Z',
  done:
    'M19.85,7.25,9.25,17.85a.5.5,0,0,1-.71,0L3.15,12.46a.5.5,0,0,1,0-.71l.7-.7a.5.5,0,0,1,.71,0l4.33,4.33,9.55-9.55a.51.51,0,0,1,.71,0l.7.71A.5.5,0,0,1,19.85,7.25Z',
  drag:
    'M11,6A2,2,0,1,1,9,4,2,2,0,0,1,11,6ZM9,10a2,2,0,1,0,2,2A2,2,0,0,0,9,10Zm0,6a2,2,0,1,0,2,2A2,2,0,0,0,9,16Zm6-8a2,2,0,1,0-2-2A2,2,0,0,0,15,8Zm0,2a2,2,0,1,0,2,2A2,2,0,0,0,15,10Zm0,6a2,2,0,1,0,2,2A2,2,0,0,0,15,16Z',
  eject:
    'M19,16.5v1a.5.5,0,0,1-.5.5H5.5a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h13A.5.5,0,0,1,19,16.5ZM5.35,13.85a.49.49,0,0,0,.36.15H18.29a.49.49,0,0,0,.36-.15l.2-.2a.5.5,0,0,0,0-.71l-6.5-6.79a.48.48,0,0,0-.7,0l-6.5,6.79a.5.5,0,0,0,0,.71Z',
  eject_circle:
    'M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm5,13.5a.5.5,0,0,1-.5.5h-9a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h9a.5.5,0,0,1,.5.5Zm-.15-3.85-.2.2a.49.49,0,0,1-.36.15H7.71a.49.49,0,0,1-.36-.15l-.2-.2a.5.5,0,0,1,0-.71l4.5-4.79a.48.48,0,0,1,.7,0l4.5,4.79A.5.5,0,0,1,16.85,11.65Z',
  eject_circle_outlined:
    'M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20Zm4.5-6h-9a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h9a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,16.5,14ZM12.35,6.15a.48.48,0,0,0-.7,0l-4.5,4.79a.5.5,0,0,0,0,.71l.2.2a.49.49,0,0,0,.36.15h8.58a.49.49,0,0,0,.36-.15l.2-.2a.5.5,0,0,0,0-.71Z',
  event:
    'M21,6a2,2,0,0,0-2-2H18V2.5a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5V4H8V2.5A.5.5,0,0,0,7.5,2h-1a.5.5,0,0,0-.5.5V4H5A2,2,0,0,0,3,6V19a2,2,0,0,0,2,2H19a2,2,0,0,0,2-2ZM19,19H5V8H19ZM8,14h2a1,1,0,0,0,1-1V11a1,1,0,0,0-1-1H8a1,1,0,0,0-1,1v2A1,1,0,0,0,8,14Z',
  exit_fullscreen_16_9:
    'M19,11h3.5a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5H20V6.5a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5V10A1,1,0,0,0,19,11ZM6,10V6.5A.5.5,0,0,0,5.5,6h-1a.5.5,0,0,0-.5.5V9H1.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5H5A1,1,0,0,0,6,10Zm14,7.5V15h2.5a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5H19a1,1,0,0,0-1,1v3.5a.5.5,0,0,0,.5.5h1A.5.5,0,0,0,20,17.5ZM4.5,18h1a.5.5,0,0,0,.5-.5V14a1,1,0,0,0-1-1H1.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5H4v2.5A.5.5,0,0,0,4.5,18Z',
  exit_fullscreen_1_1:
    'M16,9h4.5a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5H17V3.5a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5V8A1,1,0,0,0,16,9ZM9,8V3.5A.5.5,0,0,0,8.5,3h-1a.5.5,0,0,0-.5.5V7H3.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5H8A1,1,0,0,0,9,8Zm8,12.5V17h3.5a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5H16a1,1,0,0,0-1,1v4.5a.5.5,0,0,0,.5.5h1A.5.5,0,0,0,17,20.5ZM7.5,21h1a.5.5,0,0,0,.5-.5V16a1,1,0,0,0-1-1H3.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5H7v3.5A.5.5,0,0,0,7.5,21Z',
  exit_fullscreen_4_3:
    'M18,9h3.5a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5H19V4.5a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5V8A1,1,0,0,0,18,9ZM6,9A1,1,0,0,0,7,8V4.5A.5.5,0,0,0,6.5,4h-1a.5.5,0,0,0-.5.5V7H2.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5ZM19,19.5V17h2.5a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5H18a1,1,0,0,0-1,1v3.5a.5.5,0,0,0,.5.5h1A.5.5,0,0,0,19,19.5ZM5.5,20h1a.5.5,0,0,0,.5-.5V16a1,1,0,0,0-1-1H2.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5H5v2.5A.5.5,0,0,0,5.5,20Z',
  find_replace:
    'M3.29,8.81a.53.53,0,0,1-.1-.42,7,7,0,0,1,12-3.13l1-1a.49.49,0,0,1,.85.35V8.5a.52.52,0,0,1-.5.5H12.61a.5.5,0,0,1-.35-.85l1.46-1.47a5,5,0,0,0-8.53,2A.5.5,0,0,1,4.71,9h-1A.49.49,0,0,1,3.29,8.81ZM20.85,19.44l-4-4a2.44,2.44,0,0,0-.43-.35l-1-.69h0a7,7,0,0,0,1.38-2.8.53.53,0,0,0-.1-.42.49.49,0,0,0-.39-.19h-1a.5.5,0,0,0-.48.36,5,5,0,0,1-8.53,2l1.46-1.47A.5.5,0,0,0,7.39,11H3.5a.52.52,0,0,0-.5.5v3.88a.49.49,0,0,0,.85.35l1-1a7,7,0,0,0,9.52.73l.75,1a2.6,2.6,0,0,0,.3.36l4,4a.5.5,0,0,0,.71,0l.7-.7A.5.5,0,0,0,20.85,19.44Z',
  first_page:
    'M17.78,17.15l-.71.7a.5.5,0,0,1-.71,0l-5.14-5.13a.75.75,0,0,1-.22-.53v-.38a.77.77,0,0,1,.22-.53l5.14-5.13a.5.5,0,0,1,.71,0l.71.71a.49.49,0,0,1,0,.7L13.33,12l4.45,4.44A.5.5,0,0,1,17.78,17.15ZM8.5,6h-1a.5.5,0,0,0-.5.5v11a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5V6.5A.5.5,0,0,0,8.5,6Z',
  hashtag:
    'M20.5,8h-3L19,3.66A.5.5,0,0,0,18.5,3H17.42a.51.51,0,0,0-.48.34L15.34,8H11.4l1.49-4.34A.5.5,0,0,0,12.42,3H11.34a.51.51,0,0,0-.48.34L9.26,8H5.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5H8.58L7.2,14H3.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h3L5,20.34A.5.5,0,0,0,5.5,21H6.57a.51.51,0,0,0,.48-.34L8.65,16H12.6l-1.49,4.34a.5.5,0,0,0,.47.66h1.07a.51.51,0,0,0,.48-.34L14.73,16H18.5a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5H15.42l1.37-4H20.5a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,20.5,8Zm-7.22,6H9.34l1.37-4h3.95Z',
  heart:
    'M22,8.5A5.5,5.5,0,0,0,16.5,3,6.36,6.36,0,0,0,12,5.07,6.36,6.36,0,0,0,7.5,3,5.5,5.5,0,0,0,2,8.5C2,12.42,6.75,16.75,9,19l2.28,2.28a.75.75,0,0,0,.53.22h.38a.75.75,0,0,0,.53-.22L15,19C17.25,16.75,22,12.42,22,8.5Z',
  heart_outlined:
    'M16.5,3A6.36,6.36,0,0,0,12,5.07,6.36,6.36,0,0,0,7.5,3,5.5,5.5,0,0,0,2,8.5C2,12.42,6.75,16.75,9,19l2.28,2.28a.75.75,0,0,0,.53.22h.38a.75.75,0,0,0,.53-.22L15,19c2.25-2.25,7-6.58,7-10.5A5.5,5.5,0,0,0,16.5,3ZM14.2,17,12,19.17,9.8,17C7.77,15,4,11.26,4,8.5A3.5,3.5,0,0,1,7.5,5h0a5,5,0,0,1,3.71,2.21.77.77,0,0,0,.6.29h.45a.51.51,0,0,0,.4-.2A5.11,5.11,0,0,1,16.47,5h0A3.5,3.5,0,0,1,20,8.5C20,11.26,16.23,15,14.2,17Z',
  help:
    'M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm1,15.5a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5Zm2.88-7.94a2.89,2.89,0,0,1-2,2.73L13,12.6a.13.13,0,0,0-.08.12v.78a.5.5,0,0,1-.5.5h-.76a.5.5,0,0,1-.5-.5v-.78a1.88,1.88,0,0,1,1.29-1.78l.95-.31a1.13,1.13,0,0,0,.76-1.07V9A1.12,1.12,0,0,0,13,7.88H11A1.12,1.12,0,0,0,9.88,9v.5a.5.5,0,0,1-.5.5H8.62a.5.5,0,0,1-.5-.5V9A2.88,2.88,0,0,1,11,6.12h2A2.88,2.88,0,0,1,15.88,9Z',
  help_outlined:
    'M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20Zm.5-4h-1a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,12.5,16Zm3.38-7A2.88,2.88,0,0,0,13,6.12H11A2.88,2.88,0,0,0,8.12,9v.5a.5.5,0,0,0,.5.5h.76a.5.5,0,0,0,.5-.5V9A1.12,1.12,0,0,1,11,7.88h2A1.12,1.12,0,0,1,14.12,9v.56a1.13,1.13,0,0,1-.76,1.07l-.95.31a1.88,1.88,0,0,0-1.29,1.78v.78a.5.5,0,0,0,.5.5h.76a.5.5,0,0,0,.5-.5v-.78A.13.13,0,0,1,13,12.6l.95-.31a2.89,2.89,0,0,0,2-2.73Z',
  history:
    'M22,12a9,9,0,0,1-9,9,8.89,8.89,0,0,1-5.16-1.63A.51.51,0,0,1,7.63,19a.5.5,0,0,1,.14-.39l.72-.73a.5.5,0,0,1,.63-.06A6.91,6.91,0,0,0,13,19a7,7,0,1,0-7-7H8.36a.49.49,0,0,1,.36.15l.2.2a.5.5,0,0,1,0,.71L5.39,16.6a.51.51,0,0,1-.71,0L1.15,13.06a.5.5,0,0,1,0-.71l.2-.2A.49.49,0,0,1,1.71,12H4a9,9,0,0,1,18,0ZM13.38,7h-.76a.5.5,0,0,0-.5.5v4.66a.53.53,0,0,0,.15.35l3.29,3.29a.5.5,0,0,0,.71,0l.53-.53a.5.5,0,0,0,0-.71l-2.92-2.92V7.5A.5.5,0,0,0,13.38,7Z',
  incognito:
    'M5,7.42l.76-4.58a1,1,0,0,1,1-.84H7.2a2.94,2.94,0,0,1,1.34.32l1.71.83a4,4,0,0,0,3.5,0l1.71-.83A2.94,2.94,0,0,1,16.8,2h.44a1,1,0,0,1,1,.84L19,7.42A.5.5,0,0,1,18.5,8H5.5A.5.5,0,0,1,5,7.42Zm15.84,3.73L20,10.29a1,1,0,0,0-.71-.29H4.71a1,1,0,0,0-.71.29l-.85.86A.5.5,0,0,0,3.5,12h17A.5.5,0,0,0,20.85,11.15ZM17,14a4,4,0,0,0-3.2,1.62,2.92,2.92,0,0,0-3.6,0A4,4,0,1,0,11,18a1,1,0,0,1,2,0,4,4,0,1,0,4-4ZM7,20a2,2,0,1,1,2-2A2,2,0,0,1,7,20Zm10,0a2,2,0,1,1,2-2A2,2,0,0,1,17,20Z',
  info:
    'M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm1,13.5a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5v-3a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5Zm0-6a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5Z',
  info_outined:
    'M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20Zm1-7.5a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5v3a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5Zm0-4a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5Z',
  key:
    'M20,3H18.59a1,1,0,0,0-.7.29l-7.18,7.18A5.4,5.4,0,0,0,8.5,10,5.5,5.5,0,1,0,14,15.5a5.55,5.55,0,0,0-.46-2.2l.88-.88a1,1,0,0,0,.29-.7V11a.75.75,0,0,1,.22-.53l.26-.26a.75.75,0,0,1,.53-.22H17.5a.5.5,0,0,0,.5-.5V7.73a.75.75,0,0,1,.22-.53l.05,0A.49.49,0,0,1,18.63,7H20.5a.5.5,0,0,0,.5-.5V4A1,1,0,0,0,20,3ZM7.44,18.56a2,2,0,1,1,2-2A2,2,0,0,1,7.44,18.56Z',
  label:
    'M20.71,3.41l-.12-.12A1.05,1.05,0,0,0,19.88,3H11.93a2,2,0,0,0-1.42.59L3.29,10.81a1,1,0,0,0,0,1.41l8.49,8.49a1,1,0,0,0,1.41,0l7.22-7.22A2,2,0,0,0,21,12.07V4.12A1.05,1.05,0,0,0,20.71,3.41ZM16,10a2,2,0,1,1,2-2A2,2,0,0,1,16,10Z',
  labels:
    'M19,11.07V3.12a1.05,1.05,0,0,0-.29-.71l-.12-.12A1.05,1.05,0,0,0,17.88,2H9.93a2,2,0,0,0-1.42.59L1.29,9.81a1,1,0,0,0,0,1.41l8.49,8.49a1,1,0,0,0,1.41,0l7.22-7.22A2,2,0,0,0,19,11.07ZM14,9a2,2,0,1,1,2-2A2,2,0,0,1,14,9Zm8.71-2.59-.12-.12A1.05,1.05,0,0,0,21.88,6H21v5.9a2,2,0,0,1-.59,1.42l-7.58,7.57.89.89a.75.75,0,0,0,1.06,0l7-7A4,4,0,0,0,23,11.9V7.12A1.05,1.05,0,0,0,22.71,6.41Z',
  labels_outlined:
    'M17,4v7.07l-6.51,6.52L3.41,10.51,9.93,4H17m.88-2H9.93a2,2,0,0,0-1.42.59L1.29,9.81a1,1,0,0,0,0,1.41l8.49,8.49a1,1,0,0,0,1.41,0l7.22-7.22A2,2,0,0,0,19,11.07V3.12a1.05,1.05,0,0,0-.29-.71l-.12-.12A1.05,1.05,0,0,0,17.88,2ZM15,8a2,2,0,1,0-2,2A2,2,0,0,0,15,8Zm7.71-1.59-.12-.12A1.05,1.05,0,0,0,21.88,6H21v5.9a2,2,0,0,1-.59,1.42l-7.58,7.57.89.89a.75.75,0,0,0,1.06,0l7-7A4,4,0,0,0,23,11.9V7.12A1.05,1.05,0,0,0,22.71,6.41Z',
  label_important:
    'M20.71,3.41l-.12-.12a1,1,0,0,0-.7-.29H12a2,2,0,0,0-1.41.58L3.15,11a.49.49,0,0,0-.15.36v.2a.49.49,0,0,0,.15.36l.69.69a1,1,0,0,0,.7.29H10a1,1,0,0,1,.7.3l.12.12a1,1,0,0,1,.3.7v5.44a1,1,0,0,0,.29.7l.69.69a.49.49,0,0,0,.36.15h.2a.49.49,0,0,0,.36-.15l7.42-7.42A2,2,0,0,0,21,12V4.11A1,1,0,0,0,20.71,3.41Zm-4.78,6.65a2,2,0,1,1,2-2A2,2,0,0,1,15.93,10.06Z',
  label_off:
    'M20.85,19.62a.48.48,0,0,1,0,.7l-.53.53a.48.48,0,0,1-.7,0l-3.29-3.28-3.14,3.14a1,1,0,0,1-1.41,0L3.29,12.22a1,1,0,0,1,0-1.41L6.43,7.67,3.15,4.38a.48.48,0,0,1,0-.7l.53-.53a.48.48,0,0,1,.7,0ZM20.71,3.41l-.12-.12A1.05,1.05,0,0,0,19.88,3H11.93a2,2,0,0,0-1.42.59l-1.6,1.6,9.9,9.9,1.6-1.6A2,2,0,0,0,21,12.07V4.12A1.05,1.05,0,0,0,20.71,3.41Z',
  label_outlined:
    'M20.71,3.41l-.12-.12A1.05,1.05,0,0,0,19.88,3H11.93a2,2,0,0,0-1.42.59L3.29,10.81a1,1,0,0,0,0,1.41l8.49,8.49a1,1,0,0,0,1.41,0l7.22-7.22A2,2,0,0,0,21,12.07V4.12A1.05,1.05,0,0,0,20.71,3.41ZM19,12.07l-6.51,6.52L5.41,11.51,11.93,5H19ZM15,11a2,2,0,1,0-2-2A2,2,0,0,0,15,11Z',
  last_page:
    'M6.22,6.85l.71-.7a.5.5,0,0,1,.71,0l5.14,5.13a.75.75,0,0,1,.22.53v.38a.77.77,0,0,1-.22.53L7.64,17.85a.5.5,0,0,1-.71,0l-.71-.71a.49.49,0,0,1,0-.7L10.67,12,6.22,7.56A.5.5,0,0,1,6.22,6.85ZM15.5,18h1a.5.5,0,0,0,.5-.5V6.5a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5v11A.5.5,0,0,0,15.5,18Z',
  layout:
    'M10,5V19a1,1,0,0,1-1,1H3a1,1,0,0,1-1-1V5A1,1,0,0,1,3,4H9A1,1,0,0,1,10,5Zm5,8H13a1,1,0,0,0-1,1v5a1,1,0,0,0,1,1h2a1,1,0,0,0,1-1V14A1,1,0,0,0,15,13Zm6,0H19a1,1,0,0,0-1,1v5a1,1,0,0,0,1,1h2a1,1,0,0,0,1-1V14A1,1,0,0,0,21,13Zm0-9H13a1,1,0,0,0-1,1v5a1,1,0,0,0,1,1h8a1,1,0,0,0,1-1V5A1,1,0,0,0,21,4Z',
  move_page:
    'M9,11.5v1a.5.5,0,0,1-.5.5H6v2a.5.5,0,0,1-.85.35l-3-3a.51.51,0,0,1,0-.71l3-3A.5.5,0,0,1,6,9v2H8.5A.5.5,0,0,1,9,11.5ZM9,6h2V8.5a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5V6h2a.5.5,0,0,0,.36-.85l-3-3a.51.51,0,0,0-.71,0l-3,3A.5.5,0,0,0,9,6Zm6,12H13V15.5a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5V18H9a.5.5,0,0,0-.35.85l3,3a.51.51,0,0,0,.71,0l3-3A.5.5,0,0,0,15,18Zm6.86-6.35-3-3A.5.5,0,0,0,18,9v2H15.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5H18v2a.5.5,0,0,0,.85.35l3-3A.51.51,0,0,0,21.86,11.65Z',
  multi_direction:
    'M21.86,12.36l-3,3A.5.5,0,0,1,18,15V13H13v5h2a.5.5,0,0,1,.36.85l-3,3a.51.51,0,0,1-.71,0l-3-3A.5.5,0,0,1,9,18h2V13H6v2a.5.5,0,0,1-.85.35l-3-3a.51.51,0,0,1,0-.71l3-3A.5.5,0,0,1,6,9v2h5V6H9a.5.5,0,0,1-.35-.85l3-3a.51.51,0,0,1,.71,0l3,3A.5.5,0,0,1,15,6H13v5h5V9a.5.5,0,0,1,.85-.36l3,3A.51.51,0,0,1,21.86,12.36Z',
  multi_direction_diagonal:
    'M18.73,6.69,13.41,12l5.31,5.32,1.42-1.42a.5.5,0,0,1,.85.35V20.5a.52.52,0,0,1-.5.5H16.25a.5.5,0,0,1-.36-.85l1.42-1.42L12,13.41,6.68,18.72,8.1,20.14a.5.5,0,0,1-.35.85H3.5a.52.52,0,0,1-.5-.5V16.25a.5.5,0,0,1,.85-.36l1.42,1.42L10.59,12,5.28,6.68,3.86,8.1A.5.5,0,0,1,3,7.75V3.5A.52.52,0,0,1,3.5,3H7.75a.5.5,0,0,1,.36.85L6.69,5.27,12,10.59l5.32-5.31L15.9,3.86A.5.5,0,0,1,16.25,3H20.5a.52.52,0,0,1,.5.5V7.75a.5.5,0,0,1-.85.36Z',
  notebook_bookmarked:
    'M5,6v5H3.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5H5v5H3.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5H5a2,2,0,0,0,2,2H19a2,2,0,0,0,2-2V4a2,2,0,0,0-2-2H7A2,2,0,0,0,5,4H3.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5Zm8-1.5a.5.5,0,0,1,.5-.5h5a.5.5,0,0,1,.5.5v7a.5.5,0,0,1-.78.42l-1.94-1.3a.52.52,0,0,0-.56,0l-1.94,1.3A.5.5,0,0,1,13,11.5Z',
  notice:
    'M12,20.92v.58a.5.5,0,0,1-.5.5H9.57a1,1,0,0,1-.86-.49l-2.28-3.8a2.72,2.72,0,0,1-.36-1H8.51a4.14,4.14,0,0,1,1.57.3l.42,2.75a1,1,0,0,0,.75.57l.33.06A.5.5,0,0,1,12,20.92ZM22,11a4,4,0,0,1-.8,2.4.51.51,0,0,1-.75,0l-.72-.72a.5.5,0,0,1-.06-.63,2,2,0,0,0,0-2.2.5.5,0,0,1,.06-.63l.72-.72a.51.51,0,0,1,.39-.15.54.54,0,0,1,.36.2A4,4,0,0,1,22,11ZM18,3V19a1,1,0,0,1-1,1h-.59a1,1,0,0,1-.7-.29l-3-2.95A6,6,0,0,0,8.51,15H5a3,3,0,0,1-3-3V10A3,3,0,0,1,5,7H8.51a6,6,0,0,0,4.25-1.76l3-2.95a1,1,0,0,1,.7-.29H17A1,1,0,0,1,18,3Z',
  notice_outlined:
    'M12,20.92v.58a.5.5,0,0,1-.5.5H9.57a1,1,0,0,1-.86-.49l-2.28-3.8a2.72,2.72,0,0,1-.36-1H8.51a4.14,4.14,0,0,1,1.57.3l.42,2.75a1,1,0,0,0,.75.57l.33.06A.5.5,0,0,1,12,20.92ZM22,11a4,4,0,0,1-.8,2.4.51.51,0,0,1-.75,0l-.72-.72a.5.5,0,0,1-.06-.63,2,2,0,0,0,0-2.2.5.5,0,0,1,.06-.63l.72-.72a.51.51,0,0,1,.39-.15.54.54,0,0,1,.36.2A4,4,0,0,1,22,11ZM18,3V19a1,1,0,0,1-1,1h-.59a1,1,0,0,1-.7-.29l-3-2.95A6,6,0,0,0,8.51,15H5a3,3,0,0,1-3-3V10A3,3,0,0,1,5,7H8.51a6,6,0,0,0,4.25-1.76l3-2.95a1,1,0,0,1,.7-.29H17A1,1,0,0,1,18,3ZM16,4.83,14.17,6.66A7.92,7.92,0,0,1,10,8.86v4.28a7.92,7.92,0,0,1,4.17,2.2L16,17.17Z',
  open_in_new:
    'M21,13.5V19a2,2,0,0,1-2,2H5a2,2,0,0,1-2-2V5A2,2,0,0,1,5,3h5.5a.5.5,0,0,1,.5.5v1a.5.5,0,0,1-.5.5H5V19H19V13.5a.5.5,0,0,1,.5-.5h1A.5.5,0,0,1,21,13.5Zm-.22-10-.27-.27A.75.75,0,0,0,20,3H13.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h4.09L9.15,13.44a.5.5,0,0,0,0,.71l.7.7a.5.5,0,0,0,.71,0L19,6.42V10.5a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5V4A.75.75,0,0,0,20.78,3.49Z',
  pages:
    'M15,3H5A2,2,0,0,0,3,5V19a2,2,0,0,0,2,2H15a2,2,0,0,0,2-2V5A2,2,0,0,0,15,3Zm6,3V18a1,1,0,0,1-1,1H19V5h1A1,1,0,0,1,21,6Z',
  pages_outlined:
    'M15,5V19H5V5H15m0-2H5A2,2,0,0,0,3,5V19a2,2,0,0,0,2,2H15a2,2,0,0,0,2-2V5a2,2,0,0,0-2-2Zm6,3V18a1,1,0,0,1-1,1H19V5h1A1,1,0,0,1,21,6Z',
  publish:
    'M19,18.5v1a.5.5,0,0,1-.5.5H5.5a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h13A.5.5,0,0,1,19,18.5ZM6.06,9.85a.49.49,0,0,0,.36.15H9v5.5a.5.5,0,0,0,.5.5h5a.5.5,0,0,0,.5-.5V10h2.58a.49.49,0,0,0,.36-.15l.2-.2a.5.5,0,0,0,0-.71L12.35,3.15a.48.48,0,0,0-.7,0L5.86,8.94a.5.5,0,0,0,0,.71Z',
  record_screen:
    'M22,6V8.5a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5V6H17.5a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5H20A2,2,0,0,1,22,6ZM2.5,9h1A.5.5,0,0,0,4,8.5V6H6.5A.5.5,0,0,0,7,5.5v-1A.5.5,0,0,0,6.5,4H4A2,2,0,0,0,2,6V8.5A.5.5,0,0,0,2.5,9Zm19,6h-1a.5.5,0,0,0-.5.5V18H17.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5H20a2,2,0,0,0,2-2V15.5A.5.5,0,0,0,21.5,15Zm-15,3H4V15.5a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5V18a2,2,0,0,0,2,2H6.5a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,6.5,18Zm1-10A1.5,1.5,0,1,0,9,9.5,1.5,1.5,0,0,0,7.5,8Z',
  refresh:
    'M20.5,10h-5a.5.5,0,0,1-.5-.5V9.21a.49.49,0,0,1,.15-.36l1.78-1.78A6.93,6.93,0,0,0,12,5a7,7,0,1,0,7,7.47.5.5,0,0,1,.5-.47h1a.52.52,0,0,1,.36.16.5.5,0,0,1,.13.37,9,9,0,1,1-2.62-6.89l1.49-1.49A.49.49,0,0,1,20.21,4h.29a.5.5,0,0,1,.5.5v5A.5.5,0,0,1,20.5,10Z',
  remaining_time:
    'M2.66,8.43A.46.46,0,0,1,3,8.14a.49.49,0,0,1,.41,0l.89.51a.49.49,0,0,1,.22.61,8.35,8.35,0,0,0-.37,1.41.5.5,0,0,1-.49.42h-1A.52.52,0,0,1,2.22,11a.52.52,0,0,1-.11-.4A9.3,9.3,0,0,1,2.66,8.43ZM4.24,5.7a.48.48,0,0,0-.1.4.47.47,0,0,0,.24.34L5.27,7a.48.48,0,0,0,.63-.11,7,7,0,0,1,.94-.94A.48.48,0,0,0,7,5.27l-.51-.89a.47.47,0,0,0-.34-.24.48.48,0,0,0-.4.1A9.16,9.16,0,0,0,4.24,5.7ZM8.68,4.26a.5.5,0,0,0,.61.22,8.35,8.35,0,0,1,1.41-.37.5.5,0,0,0,.42-.49v-1A.52.52,0,0,0,11,2.22a.52.52,0,0,0-.4-.11,9.3,9.3,0,0,0-2.12.55.46.46,0,0,0-.29.3.49.49,0,0,0,0,.41Zm4.77-2.15a.5.5,0,0,0-.57.5v1a.5.5,0,0,0,.41.5,8,8,0,1,1-9.18,9.18.5.5,0,0,0-.5-.41h-1a.5.5,0,0,0-.5.57A10,10,0,1,0,13.45,2.11Zm-6.95,9a.5.5,0,0,0-.5.5v.76a.5.5,0,0,0,.5.5h5.88a.51.51,0,0,0,.5-.5V6.5a.5.5,0,0,0-.5-.5h-.76a.5.5,0,0,0-.5.5v4.62Z',
  remove:
    'M5.5,13a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h13a.5.5,0,0,1,.5.5v1a.5.5,0,0,1-.5.5Z',
  remove_circle:
    'M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2ZM8.5,13a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h7a.5.5,0,0,1,.5.5v1a.5.5,0,0,1-.5.5Z',
  remove_circle_outlined:
    'M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20Zm3.5-9h-7a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h7a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,15.5,11Z',
  remove_square:
    'M19,3H5A2,2,0,0,0,3,5V19a2,2,0,0,0,2,2H19a2,2,0,0,0,2-2V5A2,2,0,0,0,19,3Zm-3,9.5a.5.5,0,0,1-.5.5h-7a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h7a.5.5,0,0,1,.5.5Z',
  remove_square_outlined:
    'M21,5a2,2,0,0,0-2-2H5A2,2,0,0,0,3,5V19a2,2,0,0,0,2,2H19a2,2,0,0,0,2-2ZM19,19H5V5H19ZM8.5,13h7a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5h-7a.5.5,0,0,0-.5.5v1A.5.5,0,0,0,8.5,13Z',
  restore:
    'M22,12a9,9,0,0,1-9,9,8.89,8.89,0,0,1-5.16-1.63A.51.51,0,0,1,7.63,19a.5.5,0,0,1,.14-.39l.72-.73a.5.5,0,0,1,.63-.06A6.91,6.91,0,0,0,13,19a7,7,0,1,0-7-7H8.36a.49.49,0,0,1,.36.15l.2.2a.5.5,0,0,1,0,.71L5.39,16.6a.51.51,0,0,1-.71,0L1.15,13.06a.5.5,0,0,1,0-.71l.2-.2A.49.49,0,0,1,1.71,12H4a9,9,0,0,1,18,0Z',
  search:
    'M20.85,19.44l-4-4a2.44,2.44,0,0,0-.43-.35l-1-.69h0A7,7,0,1,0,10,17a7,7,0,0,0,4.37-1.53h0l.75,1a2.6,2.6,0,0,0,.3.36l4,4a.5.5,0,0,0,.71,0l.7-.7A.5.5,0,0,0,20.85,19.44ZM10,15a5,5,0,1,1,5-5A5,5,0,0,1,10,15Z',
  settings_back:
    'M22,12a9,9,0,0,1-9,9,8.89,8.89,0,0,1-5.16-1.63A.51.51,0,0,1,7.63,19a.5.5,0,0,1,.14-.39l.72-.73a.5.5,0,0,1,.63-.06A6.91,6.91,0,0,0,13,19a7,7,0,1,0-7-7H8.36a.49.49,0,0,1,.36.15l.2.2a.5.5,0,0,1,0,.71L5.39,16.6a.51.51,0,0,1-.71,0L1.15,13.06a.5.5,0,0,1,0-.71l.2-.2A.49.49,0,0,1,1.71,12H4a9,9,0,0,1,18,0Zm-7,0a2,2,0,1,0-2,2A2,2,0,0,0,15,12Z',
  shopping_basket:
    'M22.5,10h-4L12.73,2.3a.77.77,0,0,0-.6-.3h-.26a.77.77,0,0,0-.6.3L5.5,10h-4a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5H2l1.71,7.65A3,3,0,0,0,6.6,22H17.4a3,3,0,0,0,2.92-2.35L22,12h.47a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,22.5,10ZM8,10l4-5.33L16,10Z',
  shopping_basket_add:
    'M8,10l4-5.33L16,10Zm15,.5a.5.5,0,0,0-.5-.5h-4L12.73,2.3a.77.77,0,0,0-.6-.3h-.26a.77.77,0,0,0-.6.3L5.5,10h-4a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5H2l1.71,7.65A3,3,0,0,0,6.6,22H17.4a3,3,0,0,0,2.92-2.35L22,12h.47a.5.5,0,0,0,.5-.5Zm-8.5,6.38H12.88V18.5a.5.5,0,0,1-.5.5h-.76a.5.5,0,0,1-.5-.5V16.88H9.5a.5.5,0,0,1-.5-.5v-.76a.5.5,0,0,1,.5-.5h1.62V13.5a.5.5,0,0,1,.5-.5h.76a.5.5,0,0,1,.5.5v1.62H14.5a.5.5,0,0,1,.5.5v.76A.5.5,0,0,1,14.5,16.88Z',
  shopping_basket_clear:
    'M22.5,10h-4L12.73,2.3a.77.77,0,0,0-.6-.3h-.26a.77.77,0,0,0-.6.3L5.5,10h-4a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5H2l1.71,7.65A3,3,0,0,0,6.6,22H17.4a3,3,0,0,0,2.92-2.35L22,12h.47a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,22.5,10Zm-7.65,7.62a.48.48,0,0,1,0,.7l-.53.53a.48.48,0,0,1-.7,0L12,17.24l-1.62,1.61a.48.48,0,0,1-.7,0l-.53-.53a.48.48,0,0,1,0-.7L10.76,16,9.15,14.38a.48.48,0,0,1,0-.7l.53-.53a.48.48,0,0,1,.7,0L12,14.76l1.62-1.61a.48.48,0,0,1,.7,0l.53.53a.48.48,0,0,1,0,.7L13.24,16ZM8,10l4-5.33L16,10Z',
  shopping_basket_outlined:
    'M22.5,10h-4L12.73,2.3a.77.77,0,0,0-.6-.3h-.26a.77.77,0,0,0-.6.3L5.5,10h-4a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5H2l1.71,7.65A3,3,0,0,0,6.6,22H17.4a3,3,0,0,0,2.92-2.35L22,12h.47a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,22.5,10ZM12,4.67,16,10H8Zm6.37,14.55a1,1,0,0,1-1,.78H6.6a1,1,0,0,1-1-.78L4,12H20Z',
  shopping_basket_remove:
    'M23,10.5a.5.5,0,0,0-.5-.5h-4L12.73,2.3a.77.77,0,0,0-.6-.3h-.26a.77.77,0,0,0-.6.3L5.5,10h-4a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5H2l1.71,7.65A3,3,0,0,0,6.6,22H17.4a3,3,0,0,0,2.92-2.35L22,12h.47a.5.5,0,0,0,.5-.5ZM8,10l4-5.33L16,10Zm1.5,6.88a.5.5,0,0,1-.5-.5v-.76a.5.5,0,0,1,.5-.5h5a.5.5,0,0,1,.5.5v.76a.5.5,0,0,1-.5.5Z',
  shopping_cart:
    'M20,20a2,2,0,1,1-2-2A2,2,0,0,1,20,20ZM8,18a2,2,0,1,0,2,2A2,2,0,0,0,8,18ZM21.5,4H5.72L5.16,3A2,2,0,0,0,3.42,2H2.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h.92L7.7,11.49a2.19,2.19,0,0,0,.27.35l-1.86,3.7a.77.77,0,0,0,0,.71l.22.38A.76.76,0,0,0,7,17H19.5a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5H8.62l1-2.09a2.74,2.74,0,0,0,.64.09h6.23A2.94,2.94,0,0,0,19,11.49l2.35-4.13A4.76,4.76,0,0,0,22,5V4.5A.5.5,0,0,0,21.5,4Z',
  shopping_cart_add:
    'M20,20a2,2,0,1,1-2-2A2,2,0,0,1,20,20ZM8,18a2,2,0,1,0,2,2A2,2,0,0,0,8,18ZM21.5,4h-1a.5.5,0,0,0-.5.5V5a2.86,2.86,0,0,1-.36,1.37L17.28,10.5a1,1,0,0,1-.75.5H10.3a1,1,0,0,1-.86-.5L5.16,3A2,2,0,0,0,3.42,2H2.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h.92L7.7,11.49a2.19,2.19,0,0,0,.27.35l-1.86,3.7a.77.77,0,0,0,0,.71l.22.38A.76.76,0,0,0,7,17H19.5a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5H8.62l1-2.09a2.74,2.74,0,0,0,.64.09h6.23A2.94,2.94,0,0,0,19,11.49l2.35-4.13A4.76,4.76,0,0,0,22,5V4.5A.5.5,0,0,0,21.5,4ZM16,5.38V4.62a.5.5,0,0,0-.5-.5H13.88V2.5a.5.5,0,0,0-.5-.5h-.76a.5.5,0,0,0-.5.5V4.12H10.5a.5.5,0,0,0-.5.5v.76a.5.5,0,0,0,.5.5h1.62V7.5a.5.5,0,0,0,.5.5h.76a.5.5,0,0,0,.5-.5V5.88H15.5A.5.5,0,0,0,16,5.38Z',
  shopping_cart_off:
    'M3.38,2.15a.48.48,0,0,0-.7,0l-.53.53a.48.48,0,0,0,0,.7L4.3,5.53l3.4,6a2.19,2.19,0,0,0,.27.35l-1.86,3.7a.77.77,0,0,0,0,.71l.22.38A.76.76,0,0,0,7,17h8.76L17,18.26A2,2,0,1,0,19.74,21l.88.88a.48.48,0,0,0,.7,0l.53-.53a.48.48,0,0,0,0-.7ZM8.62,15l1-2.09a2.74,2.74,0,0,0,.64.09h1.46l2,2ZM21.5,4H7.71l9,9A2.94,2.94,0,0,0,19,11.49l2.35-4.13A4.76,4.76,0,0,0,22,5V4.5A.5.5,0,0,0,21.5,4ZM8,18a2,2,0,1,0,2,2A2,2,0,0,0,8,18Z',
  shopping_cart_outlined:
    'M21.5,4H5.72L5.16,3A2,2,0,0,0,3.42,2H2.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h.92L7.7,11.49a2.19,2.19,0,0,0,.27.35l-1.86,3.7a.77.77,0,0,0,0,.71l.22.38A.76.76,0,0,0,7,17H19.5a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5H8.62l1-2.09a2.74,2.74,0,0,0,.64.09h6.23A2.94,2.94,0,0,0,19,11.49l2.35-4.13A4.76,4.76,0,0,0,22,5V4.5A.5.5,0,0,0,21.5,4Zm-4.22,6.5a1,1,0,0,1-.75.5H10.3a1,1,0,0,1-.86-.5L6.87,6H19.79c-.05.12-.09.25-.15.37ZM18,18a2,2,0,1,0,2,2A2,2,0,0,0,18,18ZM8,18a2,2,0,1,0,2,2A2,2,0,0,0,8,18Z',
  shopping_cart_remove:
    'M20,20a2,2,0,1,1-2-2A2,2,0,0,1,20,20ZM8,18a2,2,0,1,0,2,2A2,2,0,0,0,8,18ZM22,4.5a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5V5a2.86,2.86,0,0,1-.36,1.37L17.28,10.5a1,1,0,0,1-.75.5H10.3a1,1,0,0,1-.86-.5L5.16,3A2,2,0,0,0,3.42,2H2.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h.92L7.7,11.49a2.19,2.19,0,0,0,.27.35l-1.86,3.7a.77.77,0,0,0,0,.71l.22.38A.76.76,0,0,0,7,17H19.5a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5H8.62l1-2.09a2.74,2.74,0,0,0,.64.09h6.23A2.94,2.94,0,0,0,19,11.49l2.35-4.13A4.76,4.76,0,0,0,22,5ZM10.5,4.12a.5.5,0,0,0-.5.5v.76a.5.5,0,0,0,.5.5h5a.5.5,0,0,0,.5-.5V4.62a.5.5,0,0,0-.5-.5Z',
  snooze:
    'M12,4a9,9,0,1,0,9,9A9,9,0,0,0,12,4Zm0,16a7,7,0,1,1,7-7A7,7,0,0,1,12,20ZM14.5,9h-5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h3.25L9.27,14.71A1,1,0,0,0,9,15.4v1.1a.5.5,0,0,0,.5.5h5a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5H11.25l3.48-3.71A1,1,0,0,0,15,10.6V9.5A.5.5,0,0,0,14.5,9ZM6.94,3.06A.24.24,0,0,0,7,2.88a.28.28,0,0,0-.09-.19A3,3,0,0,0,2.69,6.92.28.28,0,0,0,2.88,7a.24.24,0,0,0,.18-.07ZM19,2a3,3,0,0,0-1.92.69.28.28,0,0,0-.09.19.24.24,0,0,0,.07.18l3.88,3.88a.24.24,0,0,0,.18.07.28.28,0,0,0,.19-.09A3,3,0,0,0,19,2Z',
  snooze_notification:
    'M19.71,15.71l-.83-.83A3,3,0,0,1,18,12.76V10a6,6,0,0,0-1.2-3.6l-.9-1.2A3,3,0,0,0,13.5,4H13V2.5a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5V4h-.5A3,3,0,0,0,8.1,5.2L7.2,6.4A6,6,0,0,0,6,10v2.76a3,3,0,0,1-.88,2.12l-.83.83a1,1,0,0,0-.29.7V17a1,1,0,0,0,1,1H19a1,1,0,0,0,1-1v-.59A1,1,0,0,0,19.71,15.71ZM15,8.37a1,1,0,0,1-.25.66L11,13.25H14.5a.5.5,0,0,1,.5.5v.75a.5.5,0,0,1-.5.5h-5a.5.5,0,0,1-.5-.5v-.87A1,1,0,0,1,9.25,13L13,8.75H9.5a.5.5,0,0,1-.5-.5V7.5A.5.5,0,0,1,9.5,7h5a.5.5,0,0,1,.5.5ZM12,22a2,2,0,0,0,2-2H10A2,2,0,0,0,12,22Z',
  sort:
    'M21,11.5v1a.5.5,0,0,1-.5.5H7.5a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h13A.5.5,0,0,1,21,11.5ZM20.5,17h-4a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h4a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,20.5,17Zm0-12H10a7,7,0,0,0,0,14v2a.5.5,0,0,0,.85.35l3-3a.51.51,0,0,0,0-.71l-3-3A.5.5,0,0,0,10,15v2A5,5,0,0,1,10,7H20.5a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,20.5,5Z',
  star:
    'M2.18,9.86A.49.49,0,0,1,2,9.32l.08-.25a.49.49,0,0,1,.44-.35l6.39-.51,2.45-5.89A.51.51,0,0,1,11.87,2h.26a.49.49,0,0,1,.47.32l2.46,5.89,6.39.51a.49.49,0,0,1,.44.35l.08.25a.49.49,0,0,1-.15.54L17,14l1.48,6.21a.51.51,0,0,1-.19.53L18,20.9a.5.5,0,0,1-.56,0L12,17.6,6.53,20.93a.5.5,0,0,1-.56,0l-.22-.15a.51.51,0,0,1-.19-.53L7,14Z',
  star_half:
    'M22,9.32l-.08-.25a.49.49,0,0,0-.44-.35l-6.39-.51L12.6,2.32A.49.49,0,0,0,12.13,2h-.26a.51.51,0,0,0-.48.32L8.94,8.21l-6.39.51a.49.49,0,0,0-.44.35L2,9.32a.49.49,0,0,0,.15.54L7,14,5.56,20.22a.51.51,0,0,0,.19.53L6,20.9a.47.47,0,0,0,.3.1.48.48,0,0,0,.26-.07L12,17.6l5.47,3.33a.48.48,0,0,0,.26.07.47.47,0,0,0,.3-.1l.22-.15a.51.51,0,0,0,.19-.53L17,14l4.86-4.15A.49.49,0,0,0,22,9.32Zm-7,4.06,1.12,4.68L12,15.55V5.41l1.86,4.46,4.81.38Z',
  star_outlined:
    'M12,5.41m1.86,4.46,4.81.38L15,13.38l1.12,4.68L12,15.55,7.87,18.06,9,13.38,5.33,10.25l4.81-.38L12,5.41ZM11.87,2a.51.51,0,0,0-.48.32L8.94,8.21l-6.39.51a.49.49,0,0,0-.44.35L2,9.32a.49.49,0,0,0,.15.54L7,14,5.56,20.22a.51.51,0,0,0,.19.53L6,20.9a.47.47,0,0,0,.3.1.48.48,0,0,0,.26-.07L12,17.6l5.47,3.33a.48.48,0,0,0,.26.07.47.47,0,0,0,.3-.1l.22-.15a.51.51,0,0,0,.19-.53L17,14l4.86-4.15A.49.49,0,0,0,22,9.32l-.08-.25a.49.49,0,0,0-.44-.35l-6.39-.51L12.6,2.32A.49.49,0,0,0,12.13,2Z',
  subscribe:
    'M20,7H4A2,2,0,0,0,2,9V19a2,2,0,0,0,2,2H20a2,2,0,0,0,2-2V9A2,2,0,0,0,20,7Zm-4.94,7.62-3.89,2.6A.75.75,0,0,1,10,16.6V11.4a.75.75,0,0,1,1.17-.62l3.89,2.6A.74.74,0,0,1,15.06,14.62ZM17,3H7A2,2,0,0,0,5,5H19A2,2,0,0,0,17,3Z',
  support:
    'M21,13v4a2,2,0,0,1-2,2v1a3,3,0,0,1-3,3H13a1,1,0,0,1-1-1v-.5a.5.5,0,0,1,.5-.5H16a1,1,0,0,0,1-1V19a1,1,0,0,1-1-1V12a1,1,0,0,1,1-1V8A5,5,0,0,0,7,8v3a1,1,0,0,1,1,1v6a1,1,0,0,1-1,1H5a2,2,0,0,1-2-2V13a2,2,0,0,1,2-2V8A7,7,0,0,1,19,8v3A2,2,0,0,1,21,13Z',
  sync:
    'M11.85,1.35a.49.49,0,0,1,.15.36V4a8,8,0,0,1,6.92,12,.52.52,0,0,1-.37.24.5.5,0,0,1-.42-.14l-.73-.73a.5.5,0,0,1-.09-.59A5.94,5.94,0,0,0,18,12a6,6,0,0,0-6-6V8.36a.49.49,0,0,1-.15.36l-.2.2a.5.5,0,0,1-.71,0L7.4,5.39a.51.51,0,0,1,0-.71l3.54-3.53a.5.5,0,0,1,.71,0Zm1.21,13.73a.5.5,0,0,0-.71,0l-.2.2a.49.49,0,0,0-.15.36V18a6,6,0,0,1-6-6,5.83,5.83,0,0,1,.7-2.79.51.51,0,0,0-.09-.59l-.74-.74a.5.5,0,0,0-.42-.14A.52.52,0,0,0,5.08,8,8,8,0,0,0,12,20v2.29a.49.49,0,0,0,.15.36l.2.2a.5.5,0,0,0,.71,0l3.54-3.53a.51.51,0,0,0,0-.71Z',
  sync_off:
    'M10.05,6.34,8.52,4.81a7.59,7.59,0,0,1,1.88-.64.53.53,0,0,1,.42.1.53.53,0,0,1,.18.39v1a.51.51,0,0,1-.38.49C10.43,6.22,10.24,6.27,10.05,6.34ZM13.5,9h.29a.49.49,0,0,0,.36-.15L15.7,7.3a6,6,0,0,1,2,6.66l1.53,1.53a8,8,0,0,0-2.07-9.62l1.72-1.72A.49.49,0,0,0,19,3.79V3.5a.5.5,0,0,0-.5-.5h-5a.5.5,0,0,0-.5.5v5A.5.5,0,0,0,13.5,9Zm7.35,10.62L4.38,3.15a.48.48,0,0,0-.7,0l-.53.53a.48.48,0,0,0,0,.7L5.77,7a8,8,0,0,0,1.1,11.12L5.15,19.85a.49.49,0,0,0-.15.36v.29a.5.5,0,0,0,.5.5h5a.5.5,0,0,0,.5-.5v-5a.5.5,0,0,0-.5-.5h-.29a.49.49,0,0,0-.36.15L8.3,16.7A5.94,5.94,0,0,1,7.2,8.43l8.36,8.37a5.92,5.92,0,0,1-2.18,1,.51.51,0,0,0-.38.49v1a.53.53,0,0,0,.18.39.53.53,0,0,0,.42.1A7.94,7.94,0,0,0,17,18.23l2.63,2.62a.48.48,0,0,0,.7,0l.53-.53A.48.48,0,0,0,20.85,19.62Z',
  tab:
    'M20,4H4A2,2,0,0,0,2,6V18a2,2,0,0,0,2,2H20a2,2,0,0,0,2-2V6A2,2,0,0,0,20,4ZM4,18V7A1,1,0,0,1,5,6h5a1,1,0,0,1,1,1V9a1,1,0,0,0,1,1h8v8Z',
  tabs:
    'M20,4H4A2,2,0,0,0,2,6V18a2,2,0,0,0,2,2H20a2,2,0,0,0,2-2V6A2,2,0,0,0,20,4Zm0,14H4V7A1,1,0,0,1,5,6h5a1,1,0,0,1,1,1V9a1,1,0,0,0,1,1h8ZM20,8H13V7a1,1,0,0,1,1-1h5a1,1,0,0,1,1,1Z',
  time:
    'M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20Zm.88-8.36V6.5a.5.5,0,0,0-.5-.5h-.76a.5.5,0,0,0-.5.5v5.66a.53.53,0,0,0,.15.35l4,4a.51.51,0,0,0,.71,0l.53-.53a.51.51,0,0,0,0-.71Z',
  timer:
    'M19,7.37l1.13-1.13a.5.5,0,0,0,0-.7l-.71-.71a.5.5,0,0,0-.71,0L17.6,6A9,9,0,0,0,14,4.23V3a1,1,0,0,0-1-1H11a1,1,0,0,0-1,1V4.23A9,9,0,0,0,3,13,9.11,9.11,0,0,0,11.74,22,9,9,0,0,0,19,7.37ZM12,20a7,7,0,1,1,7-7A7,7,0,0,1,12,20Zm.38-12h-.76a.5.5,0,0,0-.5.5v5a.5.5,0,0,0,.5.5h.76a.5.5,0,0,0,.5-.5v-5A.5.5,0,0,0,12.38,8Z',
  timer_20_s:
    'M19,7.37l1.13-1.13a.5.5,0,0,0,0-.7l-.71-.71a.5.5,0,0,0-.71,0L17.6,6A9,9,0,0,0,14,4.23V3a1,1,0,0,0-1-1H11a1,1,0,0,0-1,1V4.23A9,9,0,0,0,3,13,9.11,9.11,0,0,0,11.74,22,9,9,0,0,0,19,7.37ZM12,20a7,7,0,1,1,7-7A7,7,0,0,1,12,20Zm.58-12a.51.51,0,0,0-.58.5V13l3.16,3.16a.51.51,0,0,0,.76-.05A5,5,0,0,0,12.58,8Z',
  timer_30_s:
    'M19,7.37l1.13-1.13a.5.5,0,0,0,0-.7l-.71-.71a.5.5,0,0,0-.71,0L17.6,6A9,9,0,0,0,14,4.23V3a1,1,0,0,0-1-1H11a1,1,0,0,0-1,1V4.23A9,9,0,0,0,3,13,9.11,9.11,0,0,0,11.74,22,9,9,0,0,0,19,7.37ZM12,20a7,7,0,1,1,7-7A7,7,0,0,1,12,20Zm.58-12a.51.51,0,0,0-.58.5v8.94a.51.51,0,0,0,.58.5,5,5,0,0,0,0-9.94Z',
  timer_40_s:
    'M19,7.37l1.13-1.13a.5.5,0,0,0,0-.7l-.71-.71a.5.5,0,0,0-.71,0L17.6,6A9,9,0,0,0,14,4.23V3a1,1,0,0,0-1-1H11a1,1,0,0,0-1,1V4.23A9,9,0,0,0,3,13,9.11,9.11,0,0,0,11.74,22,9,9,0,0,0,19,7.37ZM12,20a7,7,0,1,1,7-7A7,7,0,0,1,12,20Zm.53-12a.49.49,0,0,0-.53.5V13L8.84,16.16a.51.51,0,0,0,.06.76,5,5,0,0,0,3.95,1A5,5,0,0,0,12.53,8Z',
  timer_off:
    'M4.38,4.15a.48.48,0,0,0-.7,0l-.53.53a.48.48,0,0,0,0,.7L5.06,7.3A9,9,0,0,0,17.7,19.94l1.92,1.91a.48.48,0,0,0,.7,0l.53-.53a.48.48,0,0,0,0-.7ZM12,20a7,7,0,0,1-7-7A6.93,6.93,0,0,1,6.48,8.72l9.8,9.8A6.93,6.93,0,0,1,12,20Zm.32-14a7,7,0,0,1,6.13,9.72l1.49,1.49A9,9,0,0,0,19,7.37l1.13-1.13a.5.5,0,0,0,0-.7l-.71-.71a.5.5,0,0,0-.71,0L17.6,6A9,9,0,0,0,14,4.23V3a1,1,0,0,0-1-1H11a1,1,0,0,0-1,1V4.23a9,9,0,0,0-2.22.83L9.27,6.55A7,7,0,0,1,12.32,6Zm-.7,2a.49.49,0,0,0-.49.41l1.75,1.75V8.5a.5.5,0,0,0-.5-.5Z',
  time_20_s:
    'M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20Zm.53-14a.5.5,0,0,0-.53.51v5.52l3.84,3.85a.5.5,0,0,0,.73,0,6,6,0,0,0-4-9.86Z',
  time_30_s:
    'M12,2C6.5,2,2,6.5,2,12c0,5.5,4.5,10,10,10s10-4.5,10-10C22,6.5,17.5,2,12,2z M12,20c-4.4,0-8-3.6-8-8c0-4.4,3.6-8,8-8 s8,3.6,8,8C20,16.4,16.4,20,12,20z M12.5,6C12.2,6,12,6.2,12,6.5v10.9c0,0.3,0.2,0.5,0.5,0.5c3.1-0.3,5.5-2.8,5.5-6 C18,8.9,15.6,6.3,12.5,6z',
  time_40_s:
    'M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20Zm.52-14a.5.5,0,0,0-.52.5v5.53L8.16,15.89a.5.5,0,0,0,0,.73A6,6,0,1,0,12.52,6Z',
  turn_off:
    'M11,13.5V3.5a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5v10a.5.5,0,0,1-.5.5h-1A.5.5,0,0,1,11,13.5Zm7-5.77a.53.53,0,0,0-.36-.17.51.51,0,0,0-.37.15l-.71.71a.51.51,0,0,0,0,.68A5.92,5.92,0,0,1,18,13,6,6,0,0,1,6,13,5.92,5.92,0,0,1,7.45,9.1a.51.51,0,0,0,0-.68l-.71-.71a.51.51,0,0,0-.37-.15A.53.53,0,0,0,6,7.73a8,8,0,1,0,12,0Z',
  unarchive:
    'M19,3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2V5C21,3.9,20.1,3,19,3z M15.9,13.4l-0.2,0.2 c-0.1,0.1-0.2,0.1-0.4,0.1H14v3.8c0,0.3-0.2,0.5-0.5,0.5h-3c-0.3,0-0.5-0.2-0.5-0.5v-3.8H8.7c-0.1,0-0.3,0-0.4-0.1l-0.2-0.2 c-0.2-0.2-0.2-0.5,0-0.7l3.5-3.5c0.2-0.2,0.5-0.2,0.7,0c0,0,0,0,0,0l3.5,3.5C16.1,12.9,16.1,13.2,15.9,13.4z M19,7H5V5h14V7z',
  update:
    'M12.88,7.5v4.14l2.92,2.92a.5.5,0,0,1,0,.71l-.53.53a.5.5,0,0,1-.71,0l-3.29-3.29a.53.53,0,0,1-.15-.35V7.5a.5.5,0,0,1,.5-.5h.76A.5.5,0,0,1,12.88,7.5ZM21,9.5v-5a.5.5,0,0,0-.5-.5h-.29a.49.49,0,0,0-.36.15L18.36,5.64A9,9,0,1,0,21,12.53a.5.5,0,0,0-.13-.37.52.52,0,0,0-.36-.16h-1a.5.5,0,0,0-.5.47A7,7,0,1,1,12,5a6.93,6.93,0,0,1,4.93,2.07L15.15,8.85a.49.49,0,0,0-.15.36V9.5a.5.5,0,0,0,.5.5h5A.5.5,0,0,0,21,9.5Z',
  vibrations:
    'M16,3H8A2,2,0,0,0,6,5V19a2,2,0,0,0,2,2h8a2,2,0,0,0,2-2V5A2,2,0,0,0,16,3Zm0,16H8V5h8ZM3.12,7.27a.49.49,0,0,0-.67-.21l-.89.46a.49.49,0,0,0-.22.67L2,9.49l-.91,1.75A1.07,1.07,0,0,0,1,11.7v.58a1,1,0,0,0,.11.46L2,14.5l-.68,1.31a.5.5,0,0,0,.21.67l.89.46a.51.51,0,0,0,.68-.21l.77-1.49A1,1,0,0,0,4,14.78v-.57a1,1,0,0,0-.11-.46L3,12l.91-1.75A1,1,0,0,0,4,9.78V9.21a1,1,0,0,0-.11-.46Zm19.77,4L22,9.49l.68-1.3a.49.49,0,0,0-.22-.67l-.89-.46a.49.49,0,0,0-.67.21l-.77,1.48a1,1,0,0,0-.11.46v.57a1,1,0,0,0,.11.46L21,12l-.91,1.76a1,1,0,0,0-.11.46v.57a1,1,0,0,0,.11.46l.77,1.49a.51.51,0,0,0,.68.21l.89-.46a.5.5,0,0,0,.21-.67L22,14.5l.91-1.76a1,1,0,0,0,.11-.46V11.7A1.07,1.07,0,0,0,22.89,11.24Z',
  vibrations_off:
    'M4.38,3.15a.48.48,0,0,0-.7,0l-.53.53a.48.48,0,0,0,0,.7L6,7.24V19a2,2,0,0,0,2,2h8a2,2,0,0,0,2-1.78l1.64,1.63a.48.48,0,0,0,.7,0l.53-.53a.48.48,0,0,0,0-.7ZM16,19H8V9.24l8,8ZM3.1,7.27a.49.49,0,0,0-.67-.21l-.89.46a.49.49,0,0,0-.22.67L2,9.49l-.91,1.75A1.07,1.07,0,0,0,1,11.7v.58a1,1,0,0,0,.11.46L2,14.5l-.68,1.31a.5.5,0,0,0,.21.67l.89.46a.51.51,0,0,0,.68-.21l.77-1.49A1,1,0,0,0,4,14.78v-.57a1,1,0,0,0-.11-.46L3,12l.91-1.75A1,1,0,0,0,4,9.78V9.21a1,1,0,0,0-.11-.46Zm19.79,4L22,9.49l.68-1.3a.49.49,0,0,0-.22-.67l-.89-.46a.49.49,0,0,0-.67.21l-.77,1.48a1,1,0,0,0-.11.46v.57a1,1,0,0,0,.11.46L21,12l-.91,1.76a1,1,0,0,0-.11.46v.57a1,1,0,0,0,.11.46l.77,1.49a.51.51,0,0,0,.68.21l.89-.46a.5.5,0,0,0,.21-.67L22,14.5l.91-1.76a1,1,0,0,0,.11-.46V11.7A1.07,1.07,0,0,0,22.89,11.24ZM16,5v7.29l2,2V5a2,2,0,0,0-2-2H8a1.94,1.94,0,0,0-1,.28L8.71,5Z',
  watch_later:
    'M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm4.51,14-.53.53a.51.51,0,0,1-.71,0l-4-4a.49.49,0,0,1-.15-.36V6.5a.5.5,0,0,1,.5-.5h.76a.5.5,0,0,1,.5.5v5.14l3.63,3.63A.51.51,0,0,1,16.51,16Z',
  youtube_search:
    'M21.85,20.15l-.7.7a.5.5,0,0,1-.71,0l-4-4a2.6,2.6,0,0,1-.3-.36l-.75-1A7,7,0,0,1,11,17a6.91,6.91,0,0,1-3-.69A.49.49,0,0,1,7.7,16a.51.51,0,0,1,.14-.44l.76-.76a.51.51,0,0,1,.54-.11A5,5,0,1,0,6,10H7.75a.5.5,0,0,1,.35.85L5.36,13.6a.51.51,0,0,1-.71,0L1.9,10.85A.5.5,0,0,1,2.26,10H4a7,7,0,1,1,12.43,4.41h0l1,.69a2.44,2.44,0,0,1,.43.35l4,4A.5.5,0,0,1,21.85,20.15Z',
  battery_alert:
    'M17,6a2,2,0,0,0-2-2H14V3a1,1,0,0,0-1-1H11a1,1,0,0,0-1,1V4H9A2,2,0,0,0,7,6V20a2,2,0,0,0,2,2h6a2,2,0,0,0,2-2ZM13,16.5a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5Zm-.22-3.72a.25.25,0,0,1-.25.22H11.47a.25.25,0,0,1-.25-.22l-.4-3.22a.5.5,0,0,1,.5-.56h1.36a.5.5,0,0,1,.5.56Z',
  error:
    'M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm1,13.5a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5Zm-.22-3.72a.25.25,0,0,1-.25.22H11.47a.25.25,0,0,1-.25-.22l-.4-3.22a.5.5,0,0,1,.5-.56h1.36a.5.5,0,0,1,.5.56Z',
  error_outlined:
    'M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20Zm.5-6h-1a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,12.5,14Zm.18-6H11.32a.5.5,0,0,0-.5.56l.4,3.22a.25.25,0,0,0,.25.22h1.06a.25.25,0,0,0,.25-.22l.4-3.22A.5.5,0,0,0,12.68,8Z',
  message_failed:
    'M18,2H6A4,4,0,0,0,2,6V21.5a.5.5,0,0,0,.5.5.49.49,0,0,0,.35-.15l3-3A3,3,0,0,1,8,18H18a4,4,0,0,0,4-4V6A4,4,0,0,0,18,2ZM13,13.5a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5Zm-.22-3.72a.25.25,0,0,1-.25.22H11.47a.25.25,0,0,1-.25-.22l-.4-3.22a.5.5,0,0,1,.5-.56h1.36a.5.5,0,0,1,.5.56Z',
  message_failed_outlined:
    'M18,2H6A4,4,0,0,0,2,6V21.5a.5.5,0,0,0,.5.5.49.49,0,0,0,.35-.15l3-3A3,3,0,0,1,8,18H18a4,4,0,0,0,4-4V6A4,4,0,0,0,18,2Zm0,14H8a5,5,0,0,0-3.54,1.46L4,17.88V6A2,2,0,0,1,6,4H18a2,2,0,0,1,2,2v8A2,2,0,0,1,18,16Zm-5.5-4h-1a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,12.5,12Zm-1-2h1.06a.25.25,0,0,0,.25-.22l.4-3.22a.5.5,0,0,0-.5-.56H11.32a.5.5,0,0,0-.5.56l.4,3.22A.25.25,0,0,0,11.47,10Z',
  new_release:
    'M21.61,13.16a3.2,3.2,0,0,1,0-2.32l.17-.46a3.27,3.27,0,0,0-1.71-4.15L19.62,6A3.31,3.31,0,0,1,18,4.38l-.2-.45a3.26,3.26,0,0,0-4.15-1.71l-.46.17A3.3,3.3,0,0,1,12,2.6a3.3,3.3,0,0,1-1.16-.21l-.46-.17A3.26,3.26,0,0,0,6.23,3.93L6,4.38A3.31,3.31,0,0,1,4.38,6l-.45.2a3.27,3.27,0,0,0-1.71,4.15l.17.46a3.2,3.2,0,0,1,0,2.32l-.17.46a3.27,3.27,0,0,0,1.71,4.15l.45.2A3.31,3.31,0,0,1,6,19.62l.2.45a3.26,3.26,0,0,0,4.15,1.71l.46-.17a3.31,3.31,0,0,1,2.32,0l.46.17a3.26,3.26,0,0,0,4.15-1.71l.2-.45A3.31,3.31,0,0,1,19.62,18l.45-.2a3.27,3.27,0,0,0,1.71-4.15ZM13,15.5a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5Zm-.22-3.72a.25.25,0,0,1-.25.22H11.47a.25.25,0,0,1-.25-.22l-.4-3.22a.5.5,0,0,1,.5-.56h1.36a.5.5,0,0,1,.5.56Z',
  new_release_outlined:
    'M21.61,13.16a3.2,3.2,0,0,1,0-2.32l.17-.46a3.27,3.27,0,0,0-1.71-4.15L19.62,6A3.31,3.31,0,0,1,18,4.38l-.2-.45a3.26,3.26,0,0,0-4.15-1.71l-.46.17A3.3,3.3,0,0,1,12,2.6a3.3,3.3,0,0,1-1.16-.21l-.46-.17A3.26,3.26,0,0,0,6.23,3.93L6,4.38A3.31,3.31,0,0,1,4.38,6l-.45.2a3.27,3.27,0,0,0-1.71,4.15l.17.46a3.2,3.2,0,0,1,0,2.32l-.17.46a3.27,3.27,0,0,0,1.71,4.15l.45.2A3.31,3.31,0,0,1,6,19.62l.2.45a3.26,3.26,0,0,0,4.15,1.71l.46-.17a3.31,3.31,0,0,1,2.32,0l.46.17a3.26,3.26,0,0,0,4.15-1.71l.2-.45A3.31,3.31,0,0,1,19.62,18l.45-.2a3.27,3.27,0,0,0,1.71-4.15Zm-2.35,2.78-.46.21a5.3,5.3,0,0,0-2.66,2.66l-.19.44a1.27,1.27,0,0,1-1.16.75,1.45,1.45,0,0,1-.48-.09l-.43-.16A5.06,5.06,0,0,0,12,19.4a5,5,0,0,0-1.85.34l-.49.17a1.17,1.17,0,0,1-.45.09,1.25,1.25,0,0,1-1.15-.74l-.21-.46a5.3,5.3,0,0,0-2.66-2.66L4.75,16a1.29,1.29,0,0,1-.66-1.64l.16-.43a5.16,5.16,0,0,0,0-3.73l-.17-.49a1.25,1.25,0,0,1,.65-1.6l.46-.21A5.3,5.3,0,0,0,7.86,5.19l.19-.44A1.27,1.27,0,0,1,9.21,4a1.45,1.45,0,0,1,.48.09l.43.16A5.06,5.06,0,0,0,12,4.6a5,5,0,0,0,1.85-.34l.49-.17A1.17,1.17,0,0,1,14.79,4a1.25,1.25,0,0,1,1.15.74l.21.46a5.3,5.3,0,0,0,2.66,2.66l.44.19a1.29,1.29,0,0,1,.66,1.64l-.16.43a5.16,5.16,0,0,0,0,3.73l.17.49A1.25,1.25,0,0,1,19.26,15.94ZM12.5,14h-1a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,12.5,14Zm.18-6H11.32a.5.5,0,0,0-.5.56l.4,3.22a.25.25,0,0,0,.25.22h1.06a.25.25,0,0,0,.25-.22l.4-3.22A.5.5,0,0,0,12.68,8Z',
  notifications:
    'M10,20h4a2,2,0,0,1-4,0Zm9.71-4.29-.83-.83A3,3,0,0,1,18,12.76V10a6,6,0,0,0-1.2-3.6l-.9-1.2A3,3,0,0,0,13.5,4H13V2.5a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5V4h-.5A3,3,0,0,0,8.1,5.2L7.2,6.4A6,6,0,0,0,6,10v2.76a3,3,0,0,1-.88,2.12l-.83.83a1,1,0,0,0-.29.7V17a1,1,0,0,0,1,1H19a1,1,0,0,0,1-1v-.59A1,1,0,0,0,19.71,15.71Z',
  notifications_alert:
    'M19.71,15.71l-.83-.83A3,3,0,0,1,18,12.76V10a6,6,0,0,0-1.2-3.6l-.9-1.2A3,3,0,0,0,13.5,4H13V2.5a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5V4h-.5A3,3,0,0,0,8.1,5.2L7.2,6.4A6,6,0,0,0,6,10v2.76a3,3,0,0,1-.88,2.12l-.83.83a1,1,0,0,0-.29.7V17a1,1,0,0,0,1,1H19a1,1,0,0,0,1-1v-.59A1,1,0,0,0,19.71,15.71ZM13,14.5a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5Zm-.22-3.72a.25.25,0,0,1-.25.22H11.47a.25.25,0,0,1-.25-.22l-.4-3.22a.5.5,0,0,1,.5-.56h1.36a.5.5,0,0,1,.5.56ZM12,22a2,2,0,0,0,2-2H10A2,2,0,0,0,12,22Z',
  notifications_off:
    'M10,20h4a2,2,0,0,1-4,0Zm8-10a6,6,0,0,0-1.2-3.6l-.9-1.2A3,3,0,0,0,13.5,4H13V2.5a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5V4h-.5A2.92,2.92,0,0,0,9,4.43l9,9Zm2.85,8.79L5.38,3.32a.5.5,0,0,0-.7,0l-.53.53a.5.5,0,0,0,0,.71l2.6,2.6A5.88,5.88,0,0,0,6,10v2.76a3,3,0,0,1-.88,2.12l-.83.83a1,1,0,0,0-.29.7V17a1,1,0,0,0,1,1H17.59l2,2a.5.5,0,0,0,.7,0l.53-.53A.5.5,0,0,0,20.85,18.79Z',
  notifications_on:
    'M10,20h4a2,2,0,0,1-4,0Zm8.88-5.12A3,3,0,0,1,18,12.76V10a6,6,0,0,0-1.2-3.6l-.9-1.2A3,3,0,0,0,13.5,4H13V2.5a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5V4h-.5A3,3,0,0,0,8.1,5.2L7.2,6.4A6,6,0,0,0,6,10v2.76a3,3,0,0,1-.88,2.12l-.83.83a1,1,0,0,0-.29.7V17a1,1,0,0,0,1,1H19a1,1,0,0,0,1-1v-.59a1,1,0,0,0-.29-.7ZM5.59,5.21a.36.36,0,0,0-.06-.5l-1-.78a.4.4,0,0,0-.3-.09A.37.37,0,0,0,4,4,10.05,10.05,0,0,0,2,9.47a.47.47,0,0,0,.14.37.48.48,0,0,0,.36.16h1A.51.51,0,0,0,4,9.52,8.12,8.12,0,0,1,5.59,5.21ZM20,4a.38.38,0,0,0-.27-.16.44.44,0,0,0-.31.09l-1,.78a.36.36,0,0,0-.06.5A8.12,8.12,0,0,1,20,9.52a.5.5,0,0,0,.49.48h1a.5.5,0,0,0,.36-.16A.55.55,0,0,0,22,9.47,10.13,10.13,0,0,0,20,4Z',
  notifications_on_outlined:
    'M18.88,14.88A3,3,0,0,1,18,12.76V10a6,6,0,0,0-1.2-3.6l-.9-1.2A3,3,0,0,0,13.5,4H13V2.5a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5V4h-.5A3,3,0,0,0,8.1,5.2L7.2,6.4A6,6,0,0,0,6,10v2.76a3,3,0,0,1-.88,2.12l-.83.83a1,1,0,0,0-.29.7V17a1,1,0,0,0,1,1H19a1,1,0,0,0,1-1v-.59a1,1,0,0,0-.29-.7ZM16,16H8V10a4,4,0,0,1,.8-2.4l.9-1.2a1,1,0,0,1,.8-.4h3a1,1,0,0,1,.8.4l.9,1.2A4,4,0,0,1,16,10Zm-4,6a2,2,0,0,0,2-2H10A2,2,0,0,0,12,22ZM5.59,5.21a.36.36,0,0,0-.06-.5l-1-.78a.4.4,0,0,0-.3-.09A.37.37,0,0,0,4,4,10.05,10.05,0,0,0,2,9.47a.47.47,0,0,0,.14.37.48.48,0,0,0,.36.16h1A.51.51,0,0,0,4,9.52,8.12,8.12,0,0,1,5.59,5.21ZM20,4a.38.38,0,0,0-.27-.16.44.44,0,0,0-.31.09l-1,.78a.36.36,0,0,0-.06.5A8.12,8.12,0,0,1,20,9.52a.5.5,0,0,0,.49.48h1a.5.5,0,0,0,.36-.16A.55.55,0,0,0,22,9.47,10.13,10.13,0,0,0,20,4Z',
  notifications_outlined:
    'M19.71,15.71l-.83-.83A3,3,0,0,1,18,12.76V10a6,6,0,0,0-1.2-3.6l-.9-1.2A3,3,0,0,0,13.5,4H13V2.5a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5V4h-.5A3,3,0,0,0,8.1,5.2L7.2,6.4A6,6,0,0,0,6,10v2.76a3,3,0,0,1-.88,2.12l-.83.83a1,1,0,0,0-.29.7V17a1,1,0,0,0,1,1H19a1,1,0,0,0,1-1v-.59A1,1,0,0,0,19.71,15.71ZM16,16H8V10a4,4,0,0,1,.8-2.4l.9-1.2a1,1,0,0,1,.8-.4h3a1,1,0,0,1,.8.4l.9,1.2A4,4,0,0,1,16,10Zm-6,4h4a2,2,0,0,1-4,0Z',
  notification_add:
    'M19.71,15.71l-.83-.83A3,3,0,0,1,18,12.76V10a6,6,0,0,0-1.2-3.6l-.9-1.2A3,3,0,0,0,13.5,4H13V2.5a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5V4h-.5A3,3,0,0,0,8.1,5.2L7.2,6.4A6,6,0,0,0,6,10v2.76a3,3,0,0,1-.88,2.12l-.83.83a1,1,0,0,0-.29.7V17a1,1,0,0,0,1,1H19a1,1,0,0,0,1-1v-.59A1,1,0,0,0,19.71,15.71ZM14.5,11.88H12.88V13.5a.5.5,0,0,1-.5.5h-.76a.5.5,0,0,1-.5-.5V11.88H9.5a.5.5,0,0,1-.5-.5v-.76a.5.5,0,0,1,.5-.5h1.62V8.5a.5.5,0,0,1,.5-.5h.76a.5.5,0,0,1,.5.5v1.62H14.5a.5.5,0,0,1,.5.5v.76A.5.5,0,0,1,14.5,11.88ZM12,22a2,2,0,0,0,2-2H10A2,2,0,0,0,12,22Z',
  report:
    'M21,9.51a3,3,0,0,0-.88-2.12L16.61,3.88A3,3,0,0,0,14.49,3h-5a3,3,0,0,0-2.12.88L3.88,7.39A3,3,0,0,0,3,9.51v5a3,3,0,0,0,.88,2.12l3.51,3.51A3,3,0,0,0,9.51,21h5a3,3,0,0,0,2.12-.88l3.51-3.51A3,3,0,0,0,21,14.49Zm-8,6a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5Zm-.22-3.72a.25.25,0,0,1-.25.22H11.47a.25.25,0,0,1-.25-.22l-.4-3.22a.5.5,0,0,1,.5-.56h1.36a.5.5,0,0,1,.5.56Z',
  report_outlined:
    'M20.12,7.39,16.61,3.88A3,3,0,0,0,14.49,3h-5a3,3,0,0,0-2.12.88L3.88,7.39A3,3,0,0,0,3,9.51v5a3,3,0,0,0,.88,2.12l3.51,3.51A3,3,0,0,0,9.51,21h5a3,3,0,0,0,2.12-.88l3.51-3.51A3,3,0,0,0,21,14.49v-5A3,3,0,0,0,20.12,7.39ZM19,14.49a1,1,0,0,1-.29.7l-3.52,3.52a1,1,0,0,1-.7.29h-5a1,1,0,0,1-.7-.29L5.29,15.19a1,1,0,0,1-.29-.7v-5a1,1,0,0,1,.29-.7L8.81,5.29A1,1,0,0,1,9.51,5h5a1,1,0,0,1,.7.29l3.52,3.52a1,1,0,0,1,.29.7ZM12.5,14h-1a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,12.5,14Zm.18-6H11.32a.5.5,0,0,0-.5.56l.4,3.22a.25.25,0,0,0,.25.22h1.06a.25.25,0,0,0,.25-.22l.4-3.22A.5.5,0,0,0,12.68,8Z',
  security_warning:
    'M20.12,5.12,16.59,1.59A2,2,0,0,0,15.17,1H8.83a2,2,0,0,0-1.42.59L3.88,5.12A3,3,0,0,0,3,7.24v5.85A8,8,0,0,0,6.09,19.4l4.08,3.18A2,2,0,0,0,11.4,23h1.2a2,2,0,0,0,1.23-.42l4.08-3.18A8,8,0,0,0,21,13.09V7.24A3,3,0,0,0,20.12,5.12ZM13,15.5a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5Zm-.22-3.72a.25.25,0,0,1-.25.22H11.47a.25.25,0,0,1-.25-.22l-.4-3.22a.5.5,0,0,1,.5-.56h1.36a.5.5,0,0,1,.5.56Z',
  security_warning_outlined:
    'M21,7.24a3,3,0,0,0-.88-2.12L16.59,1.59A2,2,0,0,0,15.17,1H8.83a2,2,0,0,0-1.42.59L3.88,5.12A3,3,0,0,0,3,7.24v5.85A8,8,0,0,0,6.09,19.4l4.08,3.18A2,2,0,0,0,11.4,23h1.2a2,2,0,0,0,1.23-.42l4.08-3.18A8,8,0,0,0,21,13.09Zm-2,5.85a5.93,5.93,0,0,1-2.32,4.73L12.6,21H11.4L7.32,17.82A5.93,5.93,0,0,1,5,13.09V7.24a1,1,0,0,1,.29-.7L8.83,3h6.34l3.54,3.54a1,1,0,0,1,.29.7ZM12.5,14h-1a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,12.5,14Zm.18-6H11.32a.5.5,0,0,0-.5.56l.4,3.22a.25.25,0,0,0,.25.22h1.06a.25.25,0,0,0,.25-.22l.4-3.22A.5.5,0,0,0,12.68,8Z',
  warning:
    'M22.9,19.63,12.81,2.37A.76.76,0,0,0,12.16,2h-.3a.76.76,0,0,0-.65.37L1.1,19.63a.79.79,0,0,0,0,.76l.14.23a.73.73,0,0,0,.65.38H22.11a.73.73,0,0,0,.65-.38l.14-.23A.79.79,0,0,0,22.9,19.63ZM13,16.5a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5Zm-.22-3.72a.25.25,0,0,1-.25.22H11.47a.25.25,0,0,1-.25-.22l-.4-3.22a.5.5,0,0,1,.5-.56h1.36a.5.5,0,0,1,.5.56Z',
  warning_outlined:
    'M22.9,19.63,12.81,2.37A.76.76,0,0,0,12.16,2h-.3a.76.76,0,0,0-.65.37L1.1,19.63a.79.79,0,0,0,0,.76l.14.23a.73.73,0,0,0,.65.38H22.11a.73.73,0,0,0,.65-.38l.14-.23A.79.79,0,0,0,22.9,19.63ZM3.79,19,12,5l8.2,14Zm8.71-4h-1a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,12.5,15ZM10.82,9.56l.4,3.22a.25.25,0,0,0,.25.22h1.06a.25.25,0,0,0,.25-.22l.4-3.22a.5.5,0,0,0-.5-.56H11.32A.5.5,0,0,0,10.82,9.56Z',
  arrow_backward:
    'M22,11.5v1a.5.5,0,0,1-.5.5H5.33l4.45,4.44a.5.5,0,0,1,0,.71l-.71.7a.5.5,0,0,1-.71,0L2.22,12.72A.75.75,0,0,1,2,12.19v-.38a.77.77,0,0,1,.22-.53L8.36,5.15a.5.5,0,0,1,.71,0l.71.71a.49.49,0,0,1,0,.7L5.33,11H21.5A.5.5,0,0,1,22,11.5Z',
  arrow_doublesided_horizontal:
    'M18,11V9a.5.5,0,0,1,.85-.36l3,3a.51.51,0,0,1,0,.71l-3,3A.5.5,0,0,1,18,15V13H6v2a.5.5,0,0,1-.85.35l-3-3a.51.51,0,0,1,0-.71l3-3A.5.5,0,0,1,6,9v2Z',
  arrow_doublesided_vertical:
    'M13,18h2a.5.5,0,0,1,.36.85l-3,3a.51.51,0,0,1-.71,0l-3-3A.5.5,0,0,1,9,18h2V6H9a.5.5,0,0,1-.35-.85l3-3a.51.51,0,0,1,.71,0l3,3A.5.5,0,0,1,15,6H13Z',
  arrow_downward:
    'M12.5,2a.5.5,0,0,1,.5.5V18.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71l-6.13,6.14a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L5.15,15.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L11,18.67V2.5a.5.5,0,0,1,.5-.5Z',
  arrow_drop_down_circle:
    'M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm3.36,8.85-3,3a.51.51,0,0,1-.71,0l-3-3A.5.5,0,0,1,9,10h6A.5.5,0,0,1,15.36,10.85Z',
  arrow_drop_down_circle_outlined:
    'M12,4a8,8,0,1,1-8,8,8,8,0,0,1,8-8m0-2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm3,8H9a.5.5,0,0,0-.36.85l3,3a.51.51,0,0,0,.71,0l3-3A.5.5,0,0,0,15,10Z',
  arrow_drop_up_circle:
    'M12,22A10,10,0,1,0,2,12,10,10,0,0,0,12,22ZM8.64,12.15l3-3a.51.51,0,0,1,.71,0l3,3A.5.5,0,0,1,15,13H9A.5.5,0,0,1,8.64,12.15Z',
  arrow_drop_up_circle_outlined:
    'M12,4a8,8,0,1,1-8,8,8,8,0,0,1,8-8m0-2A10,10,0,1,0,22,12,10,10,0,0,0,12,2ZM9,13h6a.5.5,0,0,0,.36-.85l-3-3a.51.51,0,0,0-.71,0l-3,3A.5.5,0,0,0,9,13Z',
  arrow_forward:
    'M2,12.5v-1a.5.5,0,0,1,.5-.5H18.67L14.22,6.56a.5.5,0,0,1,0-.71l.71-.7a.5.5,0,0,1,.71,0l6.14,6.13a.75.75,0,0,1,.22.53v.38a.77.77,0,0,1-.22.53l-6.14,6.13a.5.5,0,0,1-.71,0l-.71-.71a.49.49,0,0,1,0-.7L18.67,13H2.5A.5.5,0,0,1,2,12.5Z',
  arrow_large_backward:
    'M10.94,4.86a.5.5,0,0,1,.71,0l.2.2a.49.49,0,0,1,.15.36V9h7.5a.5.5,0,0,1,.5.5v5a.5.5,0,0,1-.5.5H12v3.58a.49.49,0,0,1-.15.36l-.2.2a.5.5,0,0,1-.71,0L4.15,12.35a.48.48,0,0,1,0-.7Z',
  arrow_large_backward_outlined:
    'M10.5,13a.5.5,0,0,0-.5.5v1.88L6.62,12,10,8.62V10.5a.5.5,0,0,0,.5.5H18v2ZM4.15,11.65a.48.48,0,0,0,0,.7l6.79,6.79a.5.5,0,0,0,.71,0l.2-.2a.49.49,0,0,0,.15-.36V15h7.5a.5.5,0,0,0,.5-.5v-5a.5.5,0,0,0-.5-.5H12V5.42a.49.49,0,0,0-.15-.36l-.2-.2a.5.5,0,0,0-.71,0Z',
  arrow_large_downward:
    'M4.86,13.06a.5.5,0,0,1,0-.71l.2-.2A.49.49,0,0,1,5.42,12H9V4.5A.5.5,0,0,1,9.5,4h5a.5.5,0,0,1,.5.5V12h3.58a.49.49,0,0,1,.36.15l.2.2a.5.5,0,0,1,0,.71l-6.79,6.79a.48.48,0,0,1-.7,0Z',
  arrow_large_downward_outlined:
    'M13,13.5a.5.5,0,0,0,.5.5h1.88L12,17.38,8.62,14H10.5a.5.5,0,0,0,.5-.5V6h2Zm-1.35,6.35a.48.48,0,0,0,.7,0l6.79-6.79a.5.5,0,0,0,0-.71l-.2-.2a.49.49,0,0,0-.36-.15H15V4.5a.5.5,0,0,0-.5-.5h-5a.5.5,0,0,0-.5.5V12H5.42a.49.49,0,0,0-.36.15l-.2.2a.5.5,0,0,0,0,.71Z',
  arrow_large_forward:
    'M13.06,19.14a.5.5,0,0,1-.71,0l-.2-.2a.49.49,0,0,1-.15-.36V15H4.5a.5.5,0,0,1-.5-.5v-5A.5.5,0,0,1,4.5,9H12V5.42a.49.49,0,0,1,.15-.36l.2-.2a.5.5,0,0,1,.71,0l6.79,6.79a.48.48,0,0,1,0,.7Z',
  arrow_large_forward_outlined:
    'M13.5,11a.5.5,0,0,0,.5-.5V8.62L17.38,12,14,15.38V13.5a.5.5,0,0,0-.5-.5H6V11Zm6.35,1.35a.48.48,0,0,0,0-.7L13.06,4.86a.5.5,0,0,0-.71,0l-.2.2a.49.49,0,0,0-.15.36V9H4.5a.5.5,0,0,0-.5.5v5a.5.5,0,0,0,.5.5H12v3.58a.49.49,0,0,0,.15.36l.2.2a.5.5,0,0,0,.71,0Z',
  arrow_large_upward:
    'M19.14,10.94a.5.5,0,0,1,0,.71l-.2.2a.49.49,0,0,1-.36.15H15v7.5a.5.5,0,0,1-.5.5h-5a.5.5,0,0,1-.5-.5V12H5.42a.49.49,0,0,1-.36-.15l-.2-.2a.5.5,0,0,1,0-.71l6.79-6.79a.48.48,0,0,1,.7,0Z',
  arrow_large_upward_outlined:
    'M11,10.5a.5.5,0,0,0-.5-.5H8.62L12,6.62,15.38,10H13.5a.5.5,0,0,0-.5.5V18H11Zm-6.29.79a.51.51,0,0,0,.15.36l.2.2a.49.49,0,0,0,.36.15H9v7.5a.5.5,0,0,0,.5.5h5a.5.5,0,0,0,.5-.5V12h3.58a.49.49,0,0,0,.36-.15l.2-.2a.51.51,0,0,0,.15-.36.49.49,0,0,0-.15-.35L12.35,4.15a.48.48,0,0,0-.7,0L4.86,10.94a.49.49,0,0,0-.15.35Z',
  arrow_left_circle:
    'M22,12A10,10,0,1,0,12,22,10,10,0,0,0,22,12Zm-9.85,3.36-3-3a.51.51,0,0,1,0-.71l3-3A.5.5,0,0,1,13,9v6A.5.5,0,0,1,12.15,15.36Z',
  arrow_left_circle_outlined:
    'M12,4a8,8,0,1,1-8,8,8,8,0,0,1,8-8m0-2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm1,13V9a.5.5,0,0,0-.85-.36l-3,3a.51.51,0,0,0,0,.71l3,3A.5.5,0,0,0,13,15Z',
  arrow_long_down:
    'M12.5,2a.5.5,0,0,1,.5.5V18h2a.5.5,0,0,1,.35.85l-3,3a.51.51,0,0,1-.71,0l-3-3A.5.5,0,0,1,9,18h2V2.5a.5.5,0,0,1,.5-.5Z',
  arrow_long_left:
    'M22,11.5v1a.5.5,0,0,1-.5.5H6v2a.5.5,0,0,1-.85.35l-3-3a.51.51,0,0,1,0-.71l3-3A.5.5,0,0,1,6,9v2H21.5A.5.5,0,0,1,22,11.5Z',
  arrow_long_right:
    'M2,12.5v-1a.5.5,0,0,1,.5-.5H18V9a.5.5,0,0,1,.85-.35l3,3a.51.51,0,0,1,0,.71l-3,3A.5.5,0,0,1,18,15V13H2.5A.5.5,0,0,1,2,12.5Z',
  arrow_long_up:
    'M12.5,22h-1a.5.5,0,0,1-.5-.5V6H9a.5.5,0,0,1-.35-.85l3-3a.51.51,0,0,1,.71,0l3,3A.5.5,0,0,1,15,6H13V21.5A.5.5,0,0,1,12.5,22Z',
  arrow_right_circle:
    'M2,12A10,10,0,1,0,12,2,10,10,0,0,0,2,12Zm9.85-3.36,3,3a.51.51,0,0,1,0,.71l-3,3A.5.5,0,0,1,11,15V9A.5.5,0,0,1,11.85,8.64Z',
  arrow_right_circle_outlined:
    'M12,4a8,8,0,1,1-8,8,8,8,0,0,1,8-8m0-2A10,10,0,1,0,22,12,10,10,0,0,0,12,2ZM11,9v6a.5.5,0,0,0,.85.36l3-3a.51.51,0,0,0,0-.71l-3-3A.5.5,0,0,0,11,9Z',
  arrow_upward:
    'M12.5,22h-1a.5.5,0,0,1-.5-.5V5.33L6.56,9.78a.5.5,0,0,1-.71,0l-.7-.71a.5.5,0,0,1,0-.71l6.13-6.14A.75.75,0,0,1,11.81,2h.38a.77.77,0,0,1,.53.22l6.13,6.14a.5.5,0,0,1,0,.71l-.71.71a.49.49,0,0,1-.7,0L13,5.33V21.5A.5.5,0,0,1,12.5,22Z',
  back_ui:
    'M17.17,20.08a.5.5,0,0,1,0,.71l-1.06,1.06a.48.48,0,0,1-.7,0L6.22,12.66A.75.75,0,0,1,6,12.13v-.26a.75.75,0,0,1,.22-.53l9.19-9.19a.48.48,0,0,1,.7,0l1.06,1.06a.5.5,0,0,1,0,.71L9.09,12Z',
  caret_down:
    'M7.86,11.06a.5.5,0,0,1,0-.71l.2-.2A.49.49,0,0,1,8.42,10h7.16a.49.49,0,0,1,.36.15l.2.2a.5.5,0,0,1,0,.71l-3.79,3.79a.48.48,0,0,1-.7,0Z',
  caret_left:
    'M12.94,7.86a.5.5,0,0,1,.71,0l.2.2a.49.49,0,0,1,.15.36v7.16a.49.49,0,0,1-.15.36l-.2.2a.5.5,0,0,1-.71,0L9.15,12.35a.48.48,0,0,1,0-.7Z',
  caret_right:
    'M11.06,16.14a.5.5,0,0,1-.71,0l-.2-.2a.49.49,0,0,1-.15-.36V8.42a.49.49,0,0,1,.15-.36l.2-.2a.5.5,0,0,1,.71,0l3.79,3.79a.48.48,0,0,1,0,.7Z',
  caret_up:
    'M16.14,12.94a.5.5,0,0,1,0,.71l-.2.2a.49.49,0,0,1-.36.15H8.42a.49.49,0,0,1-.36-.15l-.2-.2a.5.5,0,0,1,0-.71l3.79-3.79a.48.48,0,0,1,.7,0Z',
  chevron_down:
    'M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z',
  chevron_left:
    'M8.22,12.72A.75.75,0,0,1,8,12.19v-.38a.77.77,0,0,1,.22-.53l5.14-5.13a.5.5,0,0,1,.71,0l.71.71a.49.49,0,0,1,0,.7L10.33,12l4.45,4.44a.5.5,0,0,1,0,.71l-.71.7a.5.5,0,0,1-.71,0Z',
  chevron_right:
    'M15.78,11.28a.75.75,0,0,1,.22.53v.38a.77.77,0,0,1-.22.53l-5.14,5.13a.5.5,0,0,1-.71,0l-.71-.71a.49.49,0,0,1,0-.7L13.67,12,9.22,7.56a.5.5,0,0,1,0-.71l.71-.7a.5.5,0,0,1,.71,0Z',
  chevron_up:
    'M11.28,8.22A.75.75,0,0,1,11.81,8h.38a.77.77,0,0,1,.53.22l5.13,5.14a.5.5,0,0,1,0,.71l-.71.71a.49.49,0,0,1-.7,0L12,10.33,7.56,14.78a.5.5,0,0,1-.71,0l-.7-.71a.5.5,0,0,1,0-.71Z',
  collapse_all:
    'M16.85,18.44a.48.48,0,0,1,0,.7l-.71.71a.5.5,0,0,1-.7,0L12,16.4,8.56,19.85a.5.5,0,0,1-.71,0l-.7-.71a.48.48,0,0,1,0-.7l4.13-4.15a.75.75,0,0,1,.53-.22h.38a.77.77,0,0,1,.53.22ZM11.28,9.78a.77.77,0,0,0,.53.22h.38a.75.75,0,0,0,.53-.22l4.13-4.14a.5.5,0,0,0,0-.71l-.7-.71a.5.5,0,0,0-.71,0L12,7.67,8.56,4.23a.49.49,0,0,0-.7,0l-.71.71a.5.5,0,0,0,0,.71Z',
  double_caret_horizontal:
    'M15.06,16.14a.5.5,0,0,1-.71,0l-.2-.2a.49.49,0,0,1-.15-.36V8.42a.49.49,0,0,1,.15-.36l.2-.2a.5.5,0,0,1,.71,0l3.79,3.79a.48.48,0,0,1,0,.7ZM8.94,7.86a.5.5,0,0,1,.71,0l.2.2a.49.49,0,0,1,.15.36v7.16a.49.49,0,0,1-.15.36l-.2.2a.5.5,0,0,1-.71,0L5.15,12.35a.48.48,0,0,1,0-.7Z',
  double_caret_vertical:
    'M16.14,8.94a.5.5,0,0,1,0,.71l-.2.2a.49.49,0,0,1-.36.15H8.42a.49.49,0,0,1-.36-.15l-.2-.2a.5.5,0,0,1,0-.71l3.79-3.79a.48.48,0,0,1,.7,0ZM7.86,15.06a.5.5,0,0,1,0-.71l.2-.2A.49.49,0,0,1,8.42,14h7.16a.49.49,0,0,1,.36.15l.2.2a.5.5,0,0,1,0,.71l-3.79,3.79a.48.48,0,0,1-.7,0Z',
  down_ui:
    'M20.08,6.83a.5.5,0,0,1,.71,0l1.06,1.06a.48.48,0,0,1,0,.7l-9.19,9.19a.75.75,0,0,1-.53.22h-.26a.75.75,0,0,1-.53-.22L2.15,8.59a.48.48,0,0,1,0-.7L3.21,6.83a.5.5,0,0,1,.71,0L12,14.91Z',
  expand_all:
    'M7.15,8.07a.5.5,0,0,1,0-.71l4.13-4.14A.75.75,0,0,1,11.81,3h.38a.77.77,0,0,1,.53.22l4.13,4.14a.5.5,0,0,1,0,.71l-.71.71a.49.49,0,0,1-.7,0L12,5.33,8.56,8.78a.5.5,0,0,1-.71,0Zm9,7.15a.5.5,0,0,0-.71,0L12,18.67,8.56,15.23a.49.49,0,0,0-.7,0l-.71.71a.5.5,0,0,0,0,.71l4.13,4.14a.77.77,0,0,0,.53.22h.38a.75.75,0,0,0,.53-.22l4.13-4.14a.5.5,0,0,0,0-.71Z',
  next_ui:
    'M6.83,3.92a.5.5,0,0,1,0-.71L7.89,2.15a.48.48,0,0,1,.7,0l9.19,9.19a.75.75,0,0,1,.22.53v.26a.75.75,0,0,1-.22.53L8.59,21.85a.48.48,0,0,1-.7,0L6.83,20.79a.5.5,0,0,1,0-.71L14.91,12Z',
  pull_right_left:
    'M15.93,7.15a.5.5,0,0,1,.71,0l4.14,4.13a.75.75,0,0,1,.22.53v.38a.77.77,0,0,1-.22.53l-4.14,4.13a.5.5,0,0,1-.71,0l-.71-.71a.49.49,0,0,1,0-.7L18.67,12,15.22,8.56a.5.5,0,0,1,0-.71Zm-7.15,9a.5.5,0,0,0,0-.71L5.33,12,8.77,8.56a.49.49,0,0,0,0-.7l-.71-.71a.5.5,0,0,0-.71,0L3.22,11.28a.77.77,0,0,0-.22.53v.38a.75.75,0,0,0,.22.53l4.14,4.13a.5.5,0,0,0,.71,0Z',
  subdirectory_left:
    'M20.5,3h-1a.5.5,0,0,0-.5.5V14H6.33l3.44-3.44a.49.49,0,0,0,0-.7l-.71-.71a.5.5,0,0,0-.71,0L3.22,14.28a.77.77,0,0,0-.22.53v.38a.75.75,0,0,0,.22.53l5.14,5.13a.5.5,0,0,0,.71,0l.71-.7a.5.5,0,0,0,0-.71L6.33,16H19a2,2,0,0,0,2-2V3.5A.5.5,0,0,0,20.5,3ZM5.33,15Z',
  subdirectory_right:
    'M3.5,3a.5.5,0,0,0-.5.5V14a2,2,0,0,0,2,2H17.67l-3.45,3.44a.5.5,0,0,0,0,.71l.71.7a.5.5,0,0,0,.71,0l5.14-5.13a.75.75,0,0,0,.22-.53v-.38a.77.77,0,0,0-.22-.53L15.64,9.15a.5.5,0,0,0-.71,0l-.71.71a.49.49,0,0,0,0,.7L17.67,14H5V3.5A.5.5,0,0,0,4.5,3ZM18.67,15Z',
  up_ui:
    'M3.92,17.17a.5.5,0,0,1-.71,0L2.15,16.11a.48.48,0,0,1,0-.7l9.19-9.19A.75.75,0,0,1,11.87,6h.26a.75.75,0,0,1,.53.22l9.19,9.19a.48.48,0,0,1,0,.7l-1.06,1.06a.5.5,0,0,1-.71,0L12,9.09Z',
  fast_forward:
    'M11.66,11.08a.75.75,0,0,1,.32.61v.62a.75.75,0,0,1-.32.61L4.29,18.1a.75.75,0,0,1-.8,0l-.12-.06A.77.77,0,0,1,3,17.42V6.57a.76.76,0,0,1,.37-.65l.12-.06a.73.73,0,0,1,.8,0Zm10,0L14.29,5.9a.73.73,0,0,0-.8,0l-.12.06a.76.76,0,0,0-.37.65V17.42a.77.77,0,0,0,.37.65l.12.06a.75.75,0,0,0,.8,0l7.37-5.18a.75.75,0,0,0,.32-.61v-.62A.75.75,0,0,0,21.66,11.08Z',
  fast_rewind:
    'M12.32,12.91A.75.75,0,0,1,12,12.3v-.62a.75.75,0,0,1,.32-.61L19.69,5.9a.73.73,0,0,1,.8,0l.12.06a.77.77,0,0,1,.37.65V17.42a.76.76,0,0,1-.37.65l-.12.06a.73.73,0,0,1-.8,0Zm-10,0,7.37,5.18a.73.73,0,0,0,.8,0l.12-.06a.76.76,0,0,0,.37-.65V6.57a.77.77,0,0,0-.37-.65l-.12-.06a.73.73,0,0,0-.8,0L2.32,11.07a.75.75,0,0,0-.32.61v.62A.75.75,0,0,0,2.32,12.91Z',
  forward:
    'M12,5V2.71a.49.49,0,0,1,.15-.36l.2-.2a.5.5,0,0,1,.71,0L16.6,5.68a.51.51,0,0,1,0,.71L13.06,9.92a.5.5,0,0,1-.71,0l-.2-.2A.49.49,0,0,1,12,9.36V7a6,6,0,1,0,6,6.46.5.5,0,0,1,.5-.46h1a.52.52,0,0,1,.37.16.51.51,0,0,1,.13.37A8,8,0,1,1,12,5Z',
  mic:
    'M8,10V6a4,4,0,0,1,8,0v4a4,4,0,0,1-8,0Zm11.08,3.45a.39.39,0,0,0-.22-.25l-1.05-.49a.42.42,0,0,0-.55.18A6,6,0,0,1,6.77,13a.49.49,0,0,0-.64-.21l-.91.42a.48.48,0,0,0-.26.3.51.51,0,0,0,0,.4,8,8,0,0,0,6,4.07V20H9.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h5a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5H13V17.93a8,8,0,0,0,6-4.07s0,0,0-.08A.38.38,0,0,0,19.08,13.45Z',
  mic_off:
    'M20.85,20.32l-.53.53a.48.48,0,0,1-.7,0L15.79,17a7.82,7.82,0,0,1-2.79.9V20h1.5a.5.5,0,0,1,.5.5v1a.5.5,0,0,1-.5.5h-5a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5H11V17.93h0a8.06,8.06,0,0,1-6-4.07.56.56,0,0,1,0-.4.53.53,0,0,1,.27-.3l.9-.42a.49.49,0,0,1,.65.2,6,6,0,0,0,7.52,2.59l-1.6-1.6A3.84,3.84,0,0,1,12,14a4,4,0,0,1-4-4V9.24L3.15,4.38a.48.48,0,0,1,0-.7l.53-.53a.48.48,0,0,1,.7,0L20.85,19.62A.48.48,0,0,1,20.85,20.32ZM16,10V6a4,4,0,0,0-4-4A4,4,0,0,0,8.28,4.57l7.27,7.27A3.94,3.94,0,0,0,16,10Zm3,3.86a.56.56,0,0,0,0-.4.53.53,0,0,0-.27-.3l-.9-.42a.49.49,0,0,0-.65.2c-.07.12-.14.24-.22.36l1.43,1.43A7.24,7.24,0,0,0,19,13.86Z',
  mic_off_outlined:
    'M10,6.29,8.28,4.57A4,4,0,0,1,12,2a4,4,0,0,1,4,4v4a3.94,3.94,0,0,1-.45,1.84L14,10.27A2.45,2.45,0,0,0,14,10V6a2,2,0,0,0-4,0Zm9,7.57a.56.56,0,0,0,0-.4.53.53,0,0,0-.27-.3l-.9-.42a.49.49,0,0,0-.65.2c-.07.12-.14.24-.22.36l1.43,1.43A7.24,7.24,0,0,0,19,13.86Zm1.84,6.46a.48.48,0,0,0,0-.7L4.38,3.15a.48.48,0,0,0-.7,0l-.53.53a.48.48,0,0,0,0,.7L8,9.24V10a4,4,0,0,0,4,4,3.84,3.84,0,0,0,.69-.07l1.6,1.6a6,6,0,0,1-7.52-2.59.49.49,0,0,0-.65-.2l-.9.42a.53.53,0,0,0-.27.3.56.56,0,0,0,0,.4,8.06,8.06,0,0,0,6,4.07h0V20H9.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h5a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5H13V17.93a7.82,7.82,0,0,0,2.79-.9l3.83,3.82a.48.48,0,0,0,.7,0Z',
  mic_outlined:
    'M12,14a4,4,0,0,0,4-4V6A4,4,0,0,0,8,6v4A4,4,0,0,0,12,14ZM10,6a2,2,0,0,1,4,0v4a2,2,0,0,1-4,0Zm9.08,7.45a.39.39,0,0,0-.22-.25l-1.05-.49a.42.42,0,0,0-.55.18A6,6,0,0,1,6.77,13a.49.49,0,0,0-.64-.21l-.91.42a.48.48,0,0,0-.26.3.51.51,0,0,0,0,.4,8,8,0,0,0,6,4.07V20H9.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h5a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5H13V17.93a8,8,0,0,0,6-4.07s0,0,0-.08A.38.38,0,0,0,19.08,13.45Z',
  music:
    'M18,10.24V11.5a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5v-.27a2.71,2.71,0,0,0-1.5-2.36c-.18-.1-.34-.22-.5-.33v9A3.78,3.78,0,0,1,10,21a3.78,3.78,0,0,1-4-3.5A3.78,3.78,0,0,1,10,14a4.29,4.29,0,0,1,2,.49V3.5a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5v.17a2.67,2.67,0,0,0,1.43,2.42A4.62,4.62,0,0,1,18,10.24Z',
  music_off:
    'M15,11.29l-2-2V3.5a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5v.17a2.67,2.67,0,0,0,1.43,2.42A4.62,4.62,0,0,1,19,10.24V11.5a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5v-.27a2.71,2.71,0,0,0-1.5-2.36c-.18-.1-.34-.22-.5-.33Zm5.85,8.33L4.38,3.15a.48.48,0,0,0-.7,0l-.53.53a.48.48,0,0,0,0,.7L13,14.24v.25A4.29,4.29,0,0,0,11,14a3.78,3.78,0,0,0-4,3.5A3.78,3.78,0,0,0,11,21a3.78,3.78,0,0,0,4-3.5V16.24l4.62,4.61a.48.48,0,0,0,.7,0l.53-.53A.48.48,0,0,0,20.85,19.62Z',
  pause:
    'M10,6.75v10.5a.76.76,0,0,1-.75.75H7.75A.76.76,0,0,1,7,17.25V6.75A.76.76,0,0,1,7.75,6h1.5A.76.76,0,0,1,10,6.75ZM16.25,6h-1.5a.76.76,0,0,0-.75.75v10.5a.76.76,0,0,0,.75.75h1.5a.76.76,0,0,0,.75-.75V6.75A.76.76,0,0,0,16.25,6Z',
  pause_circle:
    'M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2ZM11,15.5a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5v-7A.5.5,0,0,1,9.5,8h1a.5.5,0,0,1,.5.5Zm4,0a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5v-7a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5Z',
  pause_circle_outlined:
    'M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20ZM10.5,8h-1a.5.5,0,0,0-.5.5v7a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5v-7A.5.5,0,0,0,10.5,8Zm4,0h-1a.5.5,0,0,0-.5.5v7a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5v-7A.5.5,0,0,0,14.5,8Z',
  play:
    'M18,11.86v.28a.76.76,0,0,1-.37.65L8.68,18c-.54.32-.82.32-1.06.18l-.25-.14A.76.76,0,0,1,7,17.41V6.59a.76.76,0,0,1,.37-.65l.25-.14c.24-.14.52-.14,1.22.27l8.79,5.14A.76.76,0,0,1,18,11.86Z',
  play_circle:
    'M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm4,10.64-5.83,3.64A.75.75,0,0,1,9,15.65V8.35a.75.75,0,0,1,1.15-.63L16,11.36A.76.76,0,0,1,16,12.64Z',
  play_circle_outlined:
    'M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20ZM10.15,7.72A.75.75,0,0,0,9,8.35v7.3a.75.75,0,0,0,1.15.63L16,12.64a.76.76,0,0,0,0-1.28Z',
  play_outlined:
    'M9,8.48,15,12,9,15.52v-7M7.92,5.72a.58.58,0,0,0-.3.08l-.25.14A.76.76,0,0,0,7,6.59V17.41a.76.76,0,0,0,.37.65l.25.14a.59.59,0,0,0,.32.09A1.59,1.59,0,0,0,8.68,18l9-5.23a.76.76,0,0,0,.37-.65v-.28a.76.76,0,0,0-.37-.65L8.84,6.07a2,2,0,0,0-.92-.35Z',
  record: 'M19,12a7,7,0,1,1-7-7A7,7,0,0,1,19,12Z',
  record_outlined:
    'M12,7a5,5,0,1,1-5,5,5,5,0,0,1,5-5m0-2a7,7,0,1,0,7,7,7,7,0,0,0-7-7Z',
  record_voice:
    'M6.71,3.7a.47.47,0,0,1-.15-.35C6.56,2.73,9.14,1,12,1c2.69,0,5.44,1.64,5.44,2.35a.47.47,0,0,1-.15.35l-.71.71a.47.47,0,0,1-.35.15C15.76,4.56,14.71,3,12,3S8.26,4.56,7.77,4.56a.47.47,0,0,1-.35-.15ZM10.9,7.33a2,2,0,0,1,2.2,0,.5.5,0,0,0,.63-.07l.72-.71a.49.49,0,0,0,.14-.39.52.52,0,0,0-.19-.37,4,4,0,0,0-4.8,0,.52.52,0,0,0-.19.37.49.49,0,0,0,.14.39l.72.71A.5.5,0,0,0,10.9,7.33ZM9,16a3,3,0,0,0,6,0V12a3,3,0,0,0-6,0Zm9.13,3a.51.51,0,0,0-.26-.31L17,18.31a.5.5,0,0,0-.64.2,5,5,0,0,1-8.62,0,.5.5,0,0,0-.64-.2l-.92.42a.51.51,0,0,0-.26.31.48.48,0,0,0,0,.39,7,7,0,0,0,12.18,0A.48.48,0,0,0,18.13,19Z',
  record_voice_outlined:
    'M9,16a3,3,0,0,0,6,0V12a3,3,0,0,0-6,0Zm2-4a1,1,0,0,1,2,0v4a1,1,0,0,1-2,0ZM7.42,4.41a.47.47,0,0,0,.35.15C8.26,4.56,9.28,3,12,3s3.76,1.56,4.23,1.56a.47.47,0,0,0,.35-.15l.71-.71a.47.47,0,0,0,.15-.35C17.44,2.64,14.69,1,12,1,9.14,1,6.56,2.73,6.56,3.35a.47.47,0,0,0,.15.35ZM10.9,7.33a2,2,0,0,1,2.2,0,.5.5,0,0,0,.63-.07l.72-.71a.49.49,0,0,0,.14-.39.52.52,0,0,0-.19-.37,4,4,0,0,0-4.8,0,.52.52,0,0,0-.19.37.49.49,0,0,0,.14.39l.72.71A.5.5,0,0,0,10.9,7.33ZM18.13,19a.51.51,0,0,0-.26-.31L17,18.31a.5.5,0,0,0-.64.2,5,5,0,0,1-8.62,0,.5.5,0,0,0-.64-.2l-.92.42a.51.51,0,0,0-.26.31.48.48,0,0,0,0,.39,7,7,0,0,0,12.18,0A.48.48,0,0,0,18.13,19Z',
  repeat:
    'M19,12.5V17a2,2,0,0,1-2,2H7v2a.5.5,0,0,1-.85.36l-3-3a.51.51,0,0,1,0-.71l3-3A.5.5,0,0,1,7,15v2h9.94v0H17V12.5a.5.5,0,0,1,.5-.5h1A.5.5,0,0,1,19,12.5Zm1.86-6.86-3-3A.5.5,0,0,0,17,3V5H7A2,2,0,0,0,5,7v4.5a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5V7H17V9a.5.5,0,0,0,.85.36l3-3A.51.51,0,0,0,20.86,5.64Z',
  replay:
    'M20,13a8,8,0,0,1-16,.53.51.51,0,0,1,.13-.37A.52.52,0,0,1,4.52,13h1a.5.5,0,0,1,.5.46A6,6,0,1,0,12,7V9.36a.49.49,0,0,1-.15.36l-.2.2a.5.5,0,0,1-.71,0L7.4,6.39a.51.51,0,0,1,0-.71l3.54-3.53a.5.5,0,0,1,.71,0l.2.2a.49.49,0,0,1,.15.36V5A8,8,0,0,1,20,13Z',
  shuffle:
    'M9.77,14.64l-1.89,1.9A5,5,0,0,1,4.34,18H3.5a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h.84a3,3,0,0,0,2.12-.88l1.9-1.89Zm4.35,1.9A5.06,5.06,0,0,0,17,17.94V20a.5.5,0,0,0,.85.35l3-3a.51.51,0,0,0,0-.71l-3-3A.5.5,0,0,0,17,14v1.92a3,3,0,0,1-1.46-.8L12.41,12l3.13-3.12A3,3,0,0,1,17,8.08V10a.5.5,0,0,0,.85.35l3-3a.51.51,0,0,0,0-.71l-3-3A.5.5,0,0,0,17,4V6.06a5.06,5.06,0,0,0-2.88,1.4L11,10.59,7.88,7.46A5,5,0,0,0,4.34,6H3.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h.84a3,3,0,0,1,2.12.88Z',
  skip:
    'M8,6.5v11a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5V6.5A.5.5,0,0,1,6.5,6h1A.5.5,0,0,1,8,6.5Zm3.29-.6a.73.73,0,0,0-.8,0l-.12.06a.76.76,0,0,0-.37.65V17.42a.77.77,0,0,0,.37.65l.12.06a.75.75,0,0,0,.8,0l7.37-5.18a.75.75,0,0,0,.32-.61v-.62a.75.75,0,0,0-.32-.61Z',
  skip_back:
    'M8,6.82a.25.25,0,0,1,.43-.18h0l2.72,2.71A.5.5,0,0,0,12,9V7a6,6,0,1,1-6,6.46.5.5,0,0,0-.5-.46h-1a.52.52,0,0,0-.37.16.51.51,0,0,0-.13.37A8,8,0,1,0,12,5V3a.5.5,0,0,0-.85-.35L8.43,5.37h0A.26.26,0,0,1,8,5.19V3a.5.5,0,0,0-.85-.35l-3,3a.51.51,0,0,0,0,.71l3,3A.5.5,0,0,0,8,9Z',
  skip_forward:
    'M16,6.82a.25.25,0,0,0-.43-.18h0L12.85,9.35A.5.5,0,0,1,12,9V7a6,6,0,1,0,6,6.46.5.5,0,0,1,.5-.46h1a.52.52,0,0,1,.37.16.51.51,0,0,1,.13.37A8,8,0,1,1,12,5V3a.5.5,0,0,1,.85-.35l2.72,2.72h0A.26.26,0,0,0,16,5.19V3a.5.5,0,0,1,.85-.35l3,3a.51.51,0,0,1,0,.71l-3,3A.5.5,0,0,1,16,9Z',
  skip_next:
    'M18,6.5v11a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5V6.5a.5.5,0,0,1,.5-.5h1A.5.5,0,0,1,18,6.5ZM7.29,5.9a.73.73,0,0,0-.8,0l-.12.06A.76.76,0,0,0,6,6.57V17.42a.77.77,0,0,0,.37.65l.12.06a.75.75,0,0,0,.8,0l7.37-5.18a.75.75,0,0,0,.32-.61v-.62a.75.75,0,0,0-.32-.61Z',
  skip_previous:
    'M8,17.5a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5V6.5A.5.5,0,0,1,6.5,6h1a.5.5,0,0,1,.5.5ZM18,6.57a.77.77,0,0,0-.37-.65l-.12-.06a.73.73,0,0,0-.8,0L9.34,11.07a.75.75,0,0,0-.32.61v.62a.75.75,0,0,0,.32.61l7.37,5.18a.73.73,0,0,0,.8,0l.12-.06a.76.76,0,0,0,.37-.65Z',
  stop:
    'M16,18H8a2,2,0,0,1-2-2V8A2,2,0,0,1,8,6h8a2,2,0,0,1,2,2v8A2,2,0,0,1,16,18Z',
  stop_circle:
    'M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm4,12.75A1.25,1.25,0,0,1,14.75,16H9.25A1.25,1.25,0,0,1,8,14.75V9.25A1.25,1.25,0,0,1,9.25,8h5.5A1.25,1.25,0,0,1,16,9.25Z',
  stop_circle_outlined:
    'M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20ZM14.75,8H9.25A1.25,1.25,0,0,0,8,9.25v5.5A1.25,1.25,0,0,0,9.25,16h5.5A1.25,1.25,0,0,0,16,14.75V9.25A1.25,1.25,0,0,0,14.75,8Z',
  stop_outlined:
    'M16,8v8H8V8h8m0-2H8A2,2,0,0,0,6,8v8a2,2,0,0,0,2,2h8a2,2,0,0,0,2-2V8a2,2,0,0,0-2-2Z',
  tune:
    'M5,19H7v1.5a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5Zm6,1.5a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5V9H11Zm6,0a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5V15H17ZM8.5,15H7V3.5A.5.5,0,0,0,6.5,3h-1a.5.5,0,0,0-.5.5V15H3.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h5a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,8.5,15ZM15,5.5a.5.5,0,0,0-.5-.5H13V3.5a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5V5H9.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h5a.5.5,0,0,0,.5-.5ZM20.5,11H19V3.5a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5V11H15.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h5a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,20.5,11Z',
  tune_horizontal:
    'M20.5,5H8.82a3,3,0,1,0,0,2H20.5a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,20.5,5ZM6,7A1,1,0,1,1,7,6,1,1,0,0,1,6,7Zm14.5,4H14.82a3,3,0,0,0-5.64,0H3.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5H9.18a3,3,0,0,0,5.64,0H20.5a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,20.5,11ZM12,13a1,1,0,1,1,1-1A1,1,0,0,1,12,13Zm6,2a3,3,0,0,0-2.82,2H3.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5H15.18A3,3,0,1,0,18,15Zm0,4a1,1,0,1,1,1-1A1,1,0,0,1,18,19Z',
  tune_vertical:
    'M5,3.5V15.18a3,3,0,1,0,2,0V3.5A.5.5,0,0,0,6.5,3h-1A.5.5,0,0,0,5,3.5ZM7,18a1,1,0,1,1-1-1A1,1,0,0,1,7,18ZM11,3.5V9.18a3,3,0,0,0,0,5.64V20.5a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5V14.82a3,3,0,0,0,0-5.64V3.5a.5.5,0,0,0-.5-.5h-1A.5.5,0,0,0,11,3.5ZM13,12a1,1,0,1,1-1-1A1,1,0,0,1,13,12Zm2-6a3,3,0,0,0,2,2.82V20.5a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5V8.82A3,3,0,1,0,15,6Zm4,0a1,1,0,1,1-1-1A1,1,0,0,1,19,6Z',
  voice:
    'M13,8.5v7a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5v-7a.5.5,0,0,1,.5-.5h1A.5.5,0,0,1,13,8.5ZM16.5,3h-1a.5.5,0,0,0-.5.5v17a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5V3.5A.5.5,0,0,0,16.5,3Zm4,7h-1a.5.5,0,0,0-.5.5v3a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5v-3A.5.5,0,0,0,20.5,10Zm-16,1h-1a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,4.5,11Zm4-5h-1a.5.5,0,0,0-.5.5v11a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5V6.5A.5.5,0,0,0,8.5,6Z',
  voice_line:
    'M22,13.5v1a.5.5,0,0,1-.5.5h-.68a2,2,0,0,1-2-1.61L17.21,5.17a.22.22,0,0,0-.41,0l-2.63,14a2.24,2.24,0,0,1-4.37.13L7.26,9.16A.21.21,0,0,0,7.06,9H6.87a.22.22,0,0,0-.2.14L5.18,13.63A2,2,0,0,1,3.28,15H2.5a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h.78l1.5-4.49A2.2,2.2,0,0,1,6.87,7h.19A2.21,2.21,0,0,1,9.2,8.67l2.54,10.17a.21.21,0,0,0,.2.16c.16,0,.24-.07.26-.17l2.63-14a2.21,2.21,0,0,1,4.34,0L20.82,13h.68A.5.5,0,0,1,22,13.5Z',
  volume_down:
    'M16,19a1,1,0,0,1-1,1h-.59a1,1,0,0,1-.7-.29L9.88,15.88A3,3,0,0,0,7.76,15h0A1.76,1.76,0,0,1,6,13.24V10.76A1.76,1.76,0,0,1,7.76,9a3,3,0,0,0,2.12-.88l3.83-3.83a1,1,0,0,1,.7-.29H15a1,1,0,0,1,1,1Z',
  volume_low:
    'M15,5V19a1,1,0,0,1-1,1h-.59a1,1,0,0,1-.7-.29L8.88,15.88A3,3,0,0,0,6.76,15h0A1.76,1.76,0,0,1,5,13.24V10.76A1.76,1.76,0,0,1,6.76,9a3,3,0,0,0,2.12-.88l3.83-3.83a1,1,0,0,1,.7-.29H14A1,1,0,0,1,15,5Zm3.2,4.6a.54.54,0,0,0-.36-.2.51.51,0,0,0-.39.15l-.72.72a.5.5,0,0,0-.06.63,2,2,0,0,1,0,2.2.5.5,0,0,0,.06.63l.72.72a.51.51,0,0,0,.75,0,4,4,0,0,0,0-4.8Z',
  volume_minus:
    'M13,19a1,1,0,0,1-1,1h-.59a1,1,0,0,1-.7-.29L6.88,15.88A3,3,0,0,0,4.76,15h0A1.76,1.76,0,0,1,3,13.24V10.76A1.76,1.76,0,0,1,4.76,9a3,3,0,0,0,2.12-.88l3.83-3.83a1,1,0,0,1,.7-.29H12a1,1,0,0,1,1,1Zm7.5-7.88h-5a.5.5,0,0,0-.5.5v.76a.5.5,0,0,0,.5.5h5a.5.5,0,0,0,.5-.5v-.76A.5.5,0,0,0,20.5,11.12Z',
  volume_mute:
    'M13,5V19a1,1,0,0,1-1,1h-.59a1,1,0,0,1-.7-.29L6.88,15.88A3,3,0,0,0,4.76,15h0A1.76,1.76,0,0,1,3,13.24V10.76A1.76,1.76,0,0,1,4.76,9a3,3,0,0,0,2.12-.88l3.83-3.83a1,1,0,0,1,.7-.29H12A1,1,0,0,1,13,5Zm6.24,7,1.61-1.62a.48.48,0,0,0,0-.7l-.53-.53a.48.48,0,0,0-.7,0L18,10.76,16.38,9.15a.48.48,0,0,0-.7,0l-.53.53a.48.48,0,0,0,0,.7L16.76,12l-1.61,1.62a.48.48,0,0,0,0,.7l.53.53a.48.48,0,0,0,.7,0L18,13.24l1.62,1.61a.48.48,0,0,0,.7,0l.53-.53a.48.48,0,0,0,0-.7Z',
  volume_off:
    'M19.85,19.32l-.53.53a.48.48,0,0,1-.7,0L15,16.24V19a1,1,0,0,1-1,1h-.59a1,1,0,0,1-.7-.29L8.88,15.88A3,3,0,0,0,6.76,15h0A1.76,1.76,0,0,1,5,13.24V10.76A1.76,1.76,0,0,1,6.76,9h0a3.16,3.16,0,0,0,.86-.14L4.15,5.38a.48.48,0,0,1,0-.7l.53-.53a.48.48,0,0,1,.7,0L19.85,18.62A.48.48,0,0,1,19.85,19.32ZM15,5a1,1,0,0,0-1-1h-.59a1,1,0,0,0-.7.29L10.36,6.64,15,11.29Z',
  volume_plus:
    'M13,5V19a1,1,0,0,1-1,1h-.59a1,1,0,0,1-.7-.29L6.88,15.88A3,3,0,0,0,4.76,15h0A1.76,1.76,0,0,1,3,13.24V10.76A1.76,1.76,0,0,1,4.76,9a3,3,0,0,0,2.12-.88l3.83-3.83a1,1,0,0,1,.7-.29H12A1,1,0,0,1,13,5Zm7.5,6.12H18.88V9.5a.5.5,0,0,0-.5-.5h-.76a.5.5,0,0,0-.5.5v1.62H15.5a.5.5,0,0,0-.5.5v.76a.5.5,0,0,0,.5.5h1.62V14.5a.5.5,0,0,0,.5.5h.76a.5.5,0,0,0,.5-.5V12.88H20.5a.5.5,0,0,0,.5-.5v-.76A.5.5,0,0,0,20.5,11.12Z',
  volume_up:
    'M13,5V19a1,1,0,0,1-1,1h-.59a1,1,0,0,1-.7-.29L6.88,15.88A3,3,0,0,0,4.76,15h0A1.76,1.76,0,0,1,3,13.24V10.76A1.76,1.76,0,0,1,4.76,9a3,3,0,0,0,2.12-.88l3.83-3.83a1,1,0,0,1,.7-.29H12A1,1,0,0,1,13,5Zm6,1.73a.58.58,0,0,0-.36-.17.5.5,0,0,0-.37.15l-.71.71a.5.5,0,0,0,0,.68,6,6,0,0,1,0,7.8.5.5,0,0,0,0,.68l.71.71a.5.5,0,0,0,.37.15.56.56,0,0,0,.36-.17A8,8,0,0,0,19,6.73ZM15.84,9.4a.53.53,0,0,0-.39.15l-.71.72a.5.5,0,0,0-.07.63,2,2,0,0,1,0,2.2.5.5,0,0,0,.07.63l.71.72a.52.52,0,0,0,.39.14.52.52,0,0,0,.37-.19,4,4,0,0,0,0-4.8A.57.57,0,0,0,15.84,9.4Z',
  adaptive_lighting:
    'M8,14.19a.5.5,0,0,1-.31.63L2.9,16.41a.52.52,0,0,1-.64-.32L2,15.38a.49.49,0,0,1,.31-.63L7.1,13.16a.5.5,0,0,1,.64.32ZM7.74,9.91a.52.52,0,0,0-.64-.32L2.34,11.18a.5.5,0,0,0-.31.63l.23.71a.5.5,0,0,0,.64.32l4.76-1.59A.49.49,0,0,0,8,10.62Zm0,7.13a.5.5,0,0,0-.64-.31L2.34,18.31A.5.5,0,0,0,2,19l.23.71A.5.5,0,0,0,2.9,20l4.76-1.58A.5.5,0,0,0,8,17.75ZM21.8,13.51a.51.51,0,0,0-.48-.63H20.27a.51.51,0,0,0-.48.34A5.76,5.76,0,0,1,14,17h-.6a1,1,0,0,1-1-.82A23.36,23.36,0,0,1,12,12a23.36,23.36,0,0,1,.42-4.18,1,1,0,0,1,1-.82h.35a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5H13.4a3,3,0,0,0-3,2.46A26.31,26.31,0,0,0,10,12a26.31,26.31,0,0,0,.45,4.54,3,3,0,0,0,3,2.46H14A7.77,7.77,0,0,0,21.8,13.51ZM7.74,6.34A.5.5,0,0,0,7.1,6L2.34,7.61A.5.5,0,0,0,2,8.25L2.26,9a.5.5,0,0,0,.64.31L7.66,7.69A.5.5,0,0,0,8,7.05Zm9.11,3.4,1.47-1.46a5,5,0,0,1,1.51,2.46.5.5,0,0,0,.48.38h1a.51.51,0,0,0,.39-.19.52.52,0,0,0,.1-.42,6.88,6.88,0,0,0-2.1-3.65l1-1A.49.49,0,0,0,20.38,5H16.5a.52.52,0,0,0-.5.5V9.39A.5.5,0,0,0,16.85,9.74Z',
  airbag_deactivated:
    'M17,5a2,2,0,1,1,2,2A2,2,0,0,1,17,5ZM11.42,15A3,3,0,0,0,8.7,16.73L7.05,20.29A.49.49,0,0,0,7.5,21H8.61a.49.49,0,0,0,.45-.29l1.33-2.84A1.49,1.49,0,0,1,11.75,17h4l-2-2ZM19,8h0a2,2,0,0,0-1.81,1.15L15,13.79l2.68,2.68a2.87,2.87,0,0,0,1-1.2l2.07-4.44A2,2,0,0,0,19,8Zm.61,12.85a.48.48,0,0,0,.7,0l.53-.53a.48.48,0,0,0,0-.7l-3.14-3.15A3,3,0,0,1,16,17h-.26ZM8,3a5,5,0,1,0,5,5A5,5,0,0,0,8,3Zm2.39,6.15a.48.48,0,0,1,0,.7l-.54.54a.48.48,0,0,1-.7,0L8,9.24,6.85,10.39a.48.48,0,0,1-.7,0l-.54-.54a.48.48,0,0,1,0-.7L6.76,8,5.61,6.85a.48.48,0,0,1,0-.7l.54-.54a.48.48,0,0,1,.7,0L8,6.76,9.15,5.61a.48.48,0,0,1,.7,0l.54.54a.48.48,0,0,1,0,.7L9.24,8Z',
  airbag_warning:
    'M17,5a2,2,0,1,1,2,2A2,2,0,0,1,17,5ZM11.42,15A3,3,0,0,0,8.7,16.73L7.05,20.29A.49.49,0,0,0,7.5,21H8.61a.49.49,0,0,0,.45-.29l1.33-2.84A1.49,1.49,0,0,1,11.75,17h4l-2-2ZM19,8h0a2,2,0,0,0-1.81,1.15L15,13.79l2.68,2.68a2.87,2.87,0,0,0,1-1.2l2.07-4.44A2,2,0,0,0,19,8ZM8,3a5,5,0,1,0,5,5A5,5,0,0,0,8,3ZM19.62,20.85a.48.48,0,0,0,.7,0l.53-.53a.48.48,0,0,0,0-.7l-3.14-3.15A3,3,0,0,1,16,17h-.26Z',
  alternator_warning:
    'M20,6V4.5a.5.5,0,0,0-.5-.5h-4a.5.5,0,0,0-.5.5V6H9V4.5A.5.5,0,0,0,8.5,4h-4a.5.5,0,0,0-.5.5V6A2,2,0,0,0,2,8V18a2,2,0,0,0,2,2H20a2,2,0,0,0,2-2V8A2,2,0,0,0,20,6ZM4,12.88v-.76a.5.5,0,0,1,.5-.5H5.62V10.5a.5.5,0,0,1,.5-.5h.75a.5.5,0,0,1,.5.5v1.12H8.5a.5.5,0,0,1,.5.5v.76a.5.5,0,0,1-.5.5H7.37V14.5a.5.5,0,0,1-.5.5H6.12a.5.5,0,0,1-.5-.5V13.38H4.5A.5.5,0,0,1,4,12.88Zm11.5.5a.5.5,0,0,1-.5-.5v-.76a.5.5,0,0,1,.5-.5h4a.5.5,0,0,1,.5.5v.76a.5.5,0,0,1-.5.5Z',
  alternator_warning_outlined:
    'M20,6V4.5a.5.5,0,0,0-.5-.5h-3a.5.5,0,0,0-.5.5V6H8V4.5A.5.5,0,0,0,7.5,4h-3a.5.5,0,0,0-.5.5V6A2,2,0,0,0,2,8V18a2,2,0,0,0,2,2H20a2,2,0,0,0,2-2V8A2,2,0,0,0,20,6Zm0,12H4V8H20ZM6,12.88v-.76a.5.5,0,0,1,.5-.5H7.62V10.5a.5.5,0,0,1,.5-.5h.76a.5.5,0,0,1,.5.5v1.12H10.5a.5.5,0,0,1,.5.5v.76a.5.5,0,0,1-.5.5H9.38V14.5a.5.5,0,0,1-.5.5H8.12a.5.5,0,0,1-.5-.5V13.38H6.5A.5.5,0,0,1,6,12.88Zm7,0v-.76a.5.5,0,0,1,.5-.5h4a.5.5,0,0,1,.5.5v.76a.5.5,0,0,1-.5.5h-4A.5.5,0,0,1,13,12.88Z',
  bonnet_open:
    'M23,10H20.62a1,1,0,0,1-.9-.55L18.55,7.11A2,2,0,0,0,16.76,6H11a2,2,0,0,0-1.6.8L7.49,9.35a2,2,0,0,1-1.3.77l-4.41.68a2,2,0,0,0-1.78,2V15a1,1,0,0,0,1,1H2a2,2,0,0,0,4,0H17a2,2,0,0,0,4,0h.06a3.92,3.92,0,0,0,1.78-.42l.61-.3a1,1,0,0,0,.55-.9V11A1,1,0,0,0,23,10ZM9.5,10,11,8h5.76l1,2ZM0,7.5v-1A.5.5,0,0,1,.5,6h2A5,5,0,0,1,6.42,7.85l-.33.45a.26.26,0,0,1-.16.09l-1.57.24A3,3,0,0,0,2.53,8H.5A.5.5,0,0,1,0,7.5Z',
  boot_open:
    'M23,10H20.62a1,1,0,0,1-.9-.55L18.55,7.11A2,2,0,0,0,16.76,6H11a2,2,0,0,0-1.6.8L7.49,9.35a2,2,0,0,1-1.3.77l-4.41.68a2,2,0,0,0-1.78,2V15a1,1,0,0,0,1,1H2a2,2,0,0,0,4,0H17a2,2,0,0,0,4,0h.06a3.92,3.92,0,0,0,1.78-.42l.61-.3a1,1,0,0,0,.55-.9V11A1,1,0,0,0,23,10ZM11,8h5.76l1,2H9.5ZM24,6.5v1a.5.5,0,0,1-.5.5h-1a2.9,2.9,0,0,0-1.18.25h-.21l-.86-1.72A4.91,4.91,0,0,1,22.47,6h1A.5.5,0,0,1,24,6.5Z',
  brake_pad_warning:
    'M1.66,13.5l-1,.23a.52.52,0,0,1-.4-.08.5.5,0,0,1-.21-.35A11.2,11.2,0,0,1,0,12a10.69,10.69,0,0,1,.07-1.27.5.5,0,0,1,.61-.44l1,.23a.5.5,0,0,1,.38.53c0,.31,0,.63,0,.95a7.78,7.78,0,0,0,0,1A.5.5,0,0,1,1.66,13.5Zm1,2a.51.51,0,0,0-.58-.32l-1,.23a.56.56,0,0,0-.33.25.49.49,0,0,0,0,.41,12.51,12.51,0,0,0,.64,1.45.5.5,0,0,0,.3.25.49.49,0,0,0,.39-.05l.87-.5a.5.5,0,0,0,.19-.66C2.94,16.24,2.78,15.88,2.64,15.52Zm18.73-7a.49.49,0,0,0,.58.31l1-.23a.49.49,0,0,0,.35-.65,10.71,10.71,0,0,0-.64-1.47.5.5,0,0,0-.3-.25.53.53,0,0,0-.39,0l-.87.51a.5.5,0,0,0-.19.66A8.83,8.83,0,0,1,21.37,8.5ZM20,12a8,8,0,1,1-8-8A8,8,0,0,1,20,12Zm-2,0a6,6,0,1,0-6,6A6,6,0,0,0,18,12Zm4.92,3.43-1-.23a.51.51,0,0,0-.58.32c-.14.36-.3.72-.47,1.06a.5.5,0,0,0,.19.66l.87.5a.49.49,0,0,0,.39.05.5.5,0,0,0,.3-.25,12.51,12.51,0,0,0,.64-1.45.49.49,0,0,0,0-.41A.56.56,0,0,0,22.92,15.43Zm-20-8.67-.87-.51a.53.53,0,0,0-.39,0,.5.5,0,0,0-.3.25A10.71,10.71,0,0,0,.72,7.93a.49.49,0,0,0,.35.65l1,.23a.49.49,0,0,0,.58-.31,8.83,8.83,0,0,1,.48-1.08A.5.5,0,0,0,2.92,6.76Zm21,4a.5.5,0,0,0-.61-.44l-1,.23a.5.5,0,0,0-.38.53c0,.31,0,.63,0,.95A7.78,7.78,0,0,1,22,13a.5.5,0,0,0,.39.53l1,.23a.52.52,0,0,0,.4-.08.5.5,0,0,0,.21-.35A11.2,11.2,0,0,0,24,12,10.69,10.69,0,0,0,23.93,10.73Z',
  cruise_control_on:
    'M22,13A9,9,0,0,0,7,6.31V4.21a.51.51,0,0,0-.86-.36l-.52.53L3.38,2.15a.48.48,0,0,0-.7,0l-.53.53a.48.48,0,0,0,0,.7L4.38,5.62l-.53.52A.51.51,0,0,0,4.21,7h2.1A9,9,0,1,0,22,13Zm-3.48,4.29-1-1a.5.5,0,0,0-.71,0l-.53.53a.5.5,0,0,0,0,.7l1,1a6.85,6.85,0,0,1-3.4,1.42V18.5a.5.5,0,0,0-.5-.5h-.76a.5.5,0,0,0-.5.5v1.44a7,7,0,0,1-3.41-1.42l1-1a.5.5,0,0,0,0-.7l-.53-.53a.5.5,0,0,0-.71,0l-1,1a6.85,6.85,0,0,1-1.42-3.4H7.5a.5.5,0,0,0,.5-.5v-.76a.5.5,0,0,0-.5-.5H6.06a7,7,0,0,1,6.06-6.06V7.5a.5.5,0,0,0,.5.5h.76a.5.5,0,0,0,.5-.5V6.06a6.85,6.85,0,0,1,3.4,1.42l-1,1a.5.5,0,0,0,0,.7l.53.53a.5.5,0,0,0,.71,0l1-1a7,7,0,0,1,1.42,3.41H18.49a.5.5,0,0,0-.5.5v.76a.5.5,0,0,0,.5.5h1.45A7,7,0,0,1,18.52,17.29ZM14,12.73a.5.5,0,0,1,0,.71l-.53.53a.5.5,0,0,1-.71,0L9.2,10.44a.5.5,0,0,1,0-.71l.53-.53a.5.5,0,0,1,.71,0Z',
  dipped_beam_headlights:
    'M22,12c0,3.31-3,7-8,7h-.6a3,3,0,0,1-3-2.46A26.31,26.31,0,0,1,10,12a26.31,26.31,0,0,1,.45-4.54A3,3,0,0,1,13.4,5H14C19,5,22,8.69,22,12Zm-2,0c0-2-1.91-5-6-5h-.6a1,1,0,0,0-1,.82A23.36,23.36,0,0,0,12,12a23.36,23.36,0,0,0,.42,4.18,1,1,0,0,0,1,.82H14C18.09,17,20,14,20,12ZM2.34,7.61A.5.5,0,0,0,2,8.25L2.26,9a.5.5,0,0,0,.64.31L7.66,7.69A.5.5,0,0,0,8,7.05l-.23-.71A.5.5,0,0,0,7.1,6Zm0,3.57a.5.5,0,0,0-.31.63l.23.71a.5.5,0,0,0,.64.32l4.76-1.59A.49.49,0,0,0,8,10.62l-.23-.71a.52.52,0,0,0-.64-.32Zm0,3.57a.49.49,0,0,0-.31.63l.23.71a.52.52,0,0,0,.64.32l4.76-1.59A.5.5,0,0,0,8,14.19l-.23-.71a.5.5,0,0,0-.64-.32Zm0,3.56A.5.5,0,0,0,2,19l.23.71A.5.5,0,0,0,2.9,20l4.76-1.58A.5.5,0,0,0,8,17.75L7.74,17a.5.5,0,0,0-.64-.31Z',
  direction_indicators:
    'M6.94,5.86a.5.5,0,0,1,.71,0l.2.2A.49.49,0,0,1,8,6.42V9h2.5a.5.5,0,0,1,.5.5v5a.5.5,0,0,1-.5.5H8v2.58a.49.49,0,0,1-.15.36l-.2.2a.5.5,0,0,1-.71,0L1.15,12.35a.48.48,0,0,1,0-.7Zm15.91,5.79a.48.48,0,0,1,0,.7l-5.79,5.79a.5.5,0,0,1-.71,0l-.2-.2a.49.49,0,0,1-.15-.36V15H13.5a.5.5,0,0,1-.5-.5v-5a.5.5,0,0,1,.5-.5H16V6.42a.49.49,0,0,1,.15-.36l.2-.2a.5.5,0,0,1,.71,0Z',
  door_open:
    'M15,18.76a1,1,0,0,1-.29.7l-.25.25a1,1,0,0,1-.7.29H10.24a1,1,0,0,1-.7-.29l-.25-.25a1,1,0,0,1-.29-.7V17.67l0,0a1,1,0,0,0,.7.29h4.52a1,1,0,0,0,.7-.29l0,0Zm0-7.3A10.17,10.17,0,0,0,12,11a10,10,0,0,0-3,.46V8.58a1.06,1.06,0,0,1,.43-.84,4.8,4.8,0,0,1,5.12,0,1.07,1.07,0,0,1,.44.92Zm3,6.19-.89-.88a.5.5,0,0,1,.71-.71l2.8,2.79a.48.48,0,0,0,.7,0l.53-.53a.48.48,0,0,0,0-.7L18,13.76V11.65l-.89-.88a.5.5,0,1,1,.71-.71l2.8,2.79a.48.48,0,0,0,.7,0l.53-.53a.48.48,0,0,0,0-.7L18,7.76V4.66a3,3,0,0,0-1.75-2.73,10.18,10.18,0,0,0-8.5,0A3,3,0,0,0,6,4.66v3.1L2.15,11.62a.48.48,0,0,0,0,.7l.53.53a.48.48,0,0,0,.7,0l2.8-2.79a.5.5,0,1,1,.71.71L6,11.65v2.11L2.15,17.62a.48.48,0,0,0,0,.7l.53.53a.48.48,0,0,0,.7,0l2.8-2.79a.5.5,0,1,1,.71.71L6,17.65v2.11a3,3,0,0,0,.88,2.12l.24.24A3,3,0,0,0,9.24,23h5.52a3,3,0,0,0,2.12-.88l.24-.24A3,3,0,0,0,18,19.76Z',
  engine_warning:
    'M15.5,6a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5h-5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5ZM10.83,8a2,2,0,0,0-1.42.59L8.29,9.71a1,1,0,0,1-.7.29H6.5a.5.5,0,0,0-.5.5V13H4V10.5a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5v7a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5V15H6v2.5a.5.5,0,0,0,.5.5H7.59a1,1,0,0,1,.7.29l1.42,1.42a1,1,0,0,0,.7.29H19a1,1,0,0,0,1-1V18h1.5a.5.5,0,0,0,.5-.5v-6a.5.5,0,0,0-.5-.5H18v-.17a2,2,0,0,0-.59-1.42l-.82-.82A2,2,0,0,0,15.17,8Z',
  engine_warning_outlined:
    'M20,13v3H18.5a.5.5,0,0,0-.5.5V18H10.83L9.71,16.88A3,3,0,0,0,8,16V12a3,3,0,0,0,1.71-.85L10.83,10h4.34l.83.83V12.5a.5.5,0,0,0,.5.5ZM15.5,6a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5h-5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5ZM10.83,8a2,2,0,0,0-1.42.59L8.29,9.71a1,1,0,0,1-.7.29H6.5a.5.5,0,0,0-.5.5V13H4V10.5a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5v7a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5V15H6v2.5a.5.5,0,0,0,.5.5H7.59a1,1,0,0,1,.7.29l1.42,1.42a1,1,0,0,0,.7.29H19a1,1,0,0,0,1-1V18h1.5a.5.5,0,0,0,.5-.5v-6a.5.5,0,0,0-.5-.5H18v-.17a2,2,0,0,0-.59-1.42l-.82-.82A2,2,0,0,0,15.17,8Z',
  exterior_light_fault:
    'M3.5,13h-2a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h2a.5.5,0,0,1,.5.5v1A.5.5,0,0,1,3.5,13ZM5.28,6.7A.51.51,0,0,0,6,6.7L6.7,6a.51.51,0,0,0,0-.71L5.28,3.87a.48.48,0,0,0-.7,0l-.71.71a.48.48,0,0,0,0,.7ZM18,6.7a.51.51,0,0,0,.71,0l1.41-1.42a.48.48,0,0,0,0-.7l-.71-.71a.48.48,0,0,0-.7,0L17.3,5.28a.51.51,0,0,0,0,.71ZM6,17.3a.51.51,0,0,0-.71,0L3.87,18.72a.48.48,0,0,0,0,.7l.71.71a.48.48,0,0,0,.7,0L6.7,18.72a.51.51,0,0,0,0-.71ZM14,2.41a1,1,0,0,0-.29-.7l-.42-.42a1,1,0,0,0-.7-.29H11.41a1,1,0,0,0-.7.29l-.42.42a1,1,0,0,0-.29.7V3h4ZM18,12A6,6,0,1,1,8.8,6.93a1.48,1.48,0,0,0,.7-1.27V5.5A.5.5,0,0,1,10,5h4a.5.5,0,0,1,.5.5v.16a1.5,1.5,0,0,0,.7,1.27A6,6,0,0,1,18,12Zm-5,2.5a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5Zm.18-5.94a.5.5,0,0,0-.5-.56H11.32a.5.5,0,0,0-.5.56l.4,3.22a.25.25,0,0,0,.25.22h1.06a.25.25,0,0,0,.25-.22ZM22.5,11h-2a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h2a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,22.5,11Zm-10,9h-1a.5.5,0,0,0-.5.5v2a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5v-2A.5.5,0,0,0,12.5,20Zm6.22-2.7a.51.51,0,0,0-.71,0L17.3,18a.51.51,0,0,0,0,.71l1.42,1.41a.48.48,0,0,0,.7,0l.71-.71a.48.48,0,0,0,0-.7Z',
  fog_light_front:
    'M14,5h-.6a3,3,0,0,0-3,2.46A26.31,26.31,0,0,0,10,12a26.31,26.31,0,0,0,.45,4.54,3,3,0,0,0,3,2.46H14c5,0,8-3.69,8-7S19,5,14,5Zm0,12h-.6a1,1,0,0,1-1-.82A23.36,23.36,0,0,1,12,12a23.36,23.36,0,0,1,.42-4.18,1,1,0,0,1,1-.82H14c4.09,0,6,3,6,5S18.09,17,14,17ZM5.75,8.32V10L7.1,9.59a.52.52,0,0,1,.64.32l.23.71a.49.49,0,0,1-.31.63l-1.84.61.08,1.7,1.2-.4a.5.5,0,0,1,.64.32l.23.71a.5.5,0,0,1-.31.63L6,15.37v1.72l1.1-.36a.5.5,0,0,1,.64.31l.23.71a.5.5,0,0,1-.31.64L6,18.94V20.5a.5.5,0,0,1-.5.5H4.75a.5.5,0,0,1-.5-.5v-1L2.9,20a.5.5,0,0,1-.64-.31L2,19a.5.5,0,0,1,.31-.64l1.91-.63V16l-1.35.45a.52.52,0,0,1-.64-.32L2,15.38a.49.49,0,0,1,.31-.63l1.84-.61-.08-1.7-1.2.4a.5.5,0,0,1-.64-.32L2,11.81a.5.5,0,0,1,.31-.63L4,10.63V8.91l-1.1.36A.5.5,0,0,1,2.26,9L2,8.25a.5.5,0,0,1,.31-.64L4,7.06V5.5A.5.5,0,0,1,4.5,5h.75a.5.5,0,0,1,.5.5v1L7.1,6a.5.5,0,0,1,.64.31L8,7.05a.5.5,0,0,1-.31.64Z',
  fog_light_rear:
    'M2,12c0,3.31,3,7,8,7h.6a3,3,0,0,0,3-2.46A26.31,26.31,0,0,0,14,12a26.31,26.31,0,0,0-.45-4.54A3,3,0,0,0,10.6,5H10C5,5,2,8.69,2,12Zm2,0c0-2,1.91-5,6-5h.6a1,1,0,0,1,1,.82A23.36,23.36,0,0,1,12,12a23.36,23.36,0,0,1-.42,4.18,1,1,0,0,1-1,.82H10C5.91,17,4,14,4,12ZM16.34,7.69A.5.5,0,0,1,16,7.05l.23-.71A.5.5,0,0,1,16.9,6l1.35.45v-1a.5.5,0,0,1,.5-.5h.75a.5.5,0,0,1,.5.5V7.06l1.66.55a.5.5,0,0,1,.31.64L21.74,9a.5.5,0,0,1-.64.31L20,8.91v1.72l1.66.55a.5.5,0,0,1,.31.63l-.23.71a.5.5,0,0,1-.64.32l-1.2-.4-.08,1.7,1.84.61a.49.49,0,0,1,.31.63l-.23.71a.52.52,0,0,1-.64.32L19.75,16v1.72l1.91.63A.5.5,0,0,1,22,19l-.23.71a.5.5,0,0,1-.64.31l-1.35-.45v1a.5.5,0,0,1-.5.5H18.5a.5.5,0,0,1-.5-.5V18.94l-1.66-.55a.5.5,0,0,1-.31-.64l.23-.71a.5.5,0,0,1,.64-.31l1.1.36V15.37l-1.66-.55a.5.5,0,0,1-.31-.63l.23-.71a.5.5,0,0,1,.64-.32l1.2.4.08-1.7-1.84-.61a.49.49,0,0,1-.31-.63l.23-.71a.52.52,0,0,1,.64-.32l1.35.45V8.32Z',
  glow_plug_warning:
    'M22,5.74a.47.47,0,0,0-.06-.4.49.49,0,0,0-.34-.22A8.54,8.54,0,0,0,20.12,5a6.46,6.46,0,0,0-4,1.29,7.35,7.35,0,0,0-8.32,0A6.46,6.46,0,0,0,3.88,5a8.54,8.54,0,0,0-1.46.12.49.49,0,0,0-.34.22.47.47,0,0,0-.06.4l.26,1a.5.5,0,0,0,.55.37,7.2,7.2,0,0,1,1-.08,4.56,4.56,0,0,1,2.41.64A9.59,9.59,0,0,0,4,14c0,2.48,1.2,5,3.5,5S11,16.42,11,14A10.6,10.6,0,0,0,9.24,7.74a5.52,5.52,0,0,1,5.52,0A10.6,10.6,0,0,0,13,14c0,2.42,1.23,5,3.5,5S20,16.48,20,14a9.59,9.59,0,0,0-2.29-6.36A4.56,4.56,0,0,1,20.12,7a7.2,7.2,0,0,1,1.05.08.5.5,0,0,0,.55-.37ZM7.5,17C6.6,17,6,15.45,6,14A7.64,7.64,0,0,1,7.7,9,9.74,9.74,0,0,1,9,14C9,15.63,8.31,17,7.5,17Zm9,0c-.81,0-1.5-1.37-1.5-3a9.74,9.74,0,0,1,1.3-5A7.64,7.64,0,0,1,18,14C18,15.45,17.4,17,16.5,17Z',
  handbrake_warning:
    'M3.11,16.58a.5.5,0,0,1-.19.66l-.87.5a.49.49,0,0,1-.39.05.5.5,0,0,1-.3-.25,12,12,0,0,1,0-11.08.5.5,0,0,1,.3-.25.53.53,0,0,1,.39,0l.87.51a.5.5,0,0,1,.19.66,10,10,0,0,0,0,9.16ZM12.5,14h-1a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,12.5,14ZM20,12a8,8,0,1,1-8-8A8,8,0,0,1,20,12Zm-2,0a6,6,0,1,0-6,6A6,6,0,0,0,18,12Zm4.64-5.54a.5.5,0,0,0-.3-.25.53.53,0,0,0-.39,0l-.87.51a.5.5,0,0,0-.19.66,10,10,0,0,1,0,9.16.5.5,0,0,0,.19.66l.87.5a.49.49,0,0,0,.39.05.5.5,0,0,0,.3-.25,12,12,0,0,0,0-11.08ZM12.68,8H11.32a.5.5,0,0,0-.5.56l.4,3.22a.25.25,0,0,0,.25.22h1.06a.25.25,0,0,0,.25-.22l.4-3.22A.5.5,0,0,0,12.68,8Z',
  hazard_warning:
    'M20.21,19H3.79L12,5Zm-8-17h-.3a.76.76,0,0,0-.65.37L1.1,19.63a.79.79,0,0,0,0,.76l.14.23a.73.73,0,0,0,.65.38H22.11a.73.73,0,0,0,.65-.38l.14-.23a.79.79,0,0,0,0-.76L12.81,2.37A.76.76,0,0,0,12.16,2Zm1.08,13H10.77L12,12.89ZM12,9.42a.48.48,0,0,0-.43.25L7.72,16.25a.5.5,0,0,0,.43.75h7.7a.5.5,0,0,0,.44-.75L12.44,9.67A.5.5,0,0,0,12,9.42Z',
  main_beam_headlights:
    'M7.5,6a.5.5,0,0,1,.5.5v.75a.5.5,0,0,1-.5.5h-5a.5.5,0,0,1-.5-.5V6.5A.5.5,0,0,1,2.5,6Zm0,3.42h-5a.5.5,0,0,0-.5.5v.75a.5.5,0,0,0,.5.5h5a.5.5,0,0,0,.5-.5V9.92A.5.5,0,0,0,7.5,9.42Zm0,3.41h-5a.5.5,0,0,0-.5.5v.75a.5.5,0,0,0,.5.5h5a.5.5,0,0,0,.5-.5v-.75A.5.5,0,0,0,7.5,12.83Zm0,3.42h-5a.5.5,0,0,0-.5.5v.75a.5.5,0,0,0,.5.5h5a.5.5,0,0,0,.5-.5v-.75A.5.5,0,0,0,7.5,16.25ZM22,12c0,3.31-3,7-8,7h-.6a3,3,0,0,1-3-2.46A26.31,26.31,0,0,1,10,12a26.31,26.31,0,0,1,.45-4.54A3,3,0,0,1,13.4,5H14C19,5,22,8.69,22,12Zm-2,0c0-2-1.91-5-6-5h-.6a1,1,0,0,0-1,.82A23.36,23.36,0,0,0,12,12a23.36,23.36,0,0,0,.42,4.18,1,1,0,0,0,1,.82H14C18.09,17,20,14,20,12Z',
  oil_pressure_low:
    'M22.22,13.08C22.81,13.75,24,15.22,24,16a2,2,0,0,1-4,0c0-.78,1.17-2.24,1.77-2.91A.29.29,0,0,1,22,13H22A.27.27,0,0,1,22.22,13.08ZM6,9a1,1,0,0,0-1,1v1H3.41L1.56,9.15a.5.5,0,0,0-.71,0l-.7.7a.5.5,0,0,0,0,.71L2,12.41A2,2,0,0,0,3.41,13H5v4a2,2,0,0,0,2,2h7a4,4,0,0,0,4-4v-.34a3,3,0,0,1,.88-2.12L20,11.44A1.54,1.54,0,0,1,21,11h3V9H20.83a3,3,0,0,0-2.12.88L17,11.59l-2-2A2,2,0,0,0,13.59,9Zm6.5-2a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5h-5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5Z',
  oil_pressure_low_outlined:
    'M22.22,13.08C22.81,13.75,24,15.22,24,16a2,2,0,0,1-4,0c0-.78,1.17-2.24,1.77-2.91A.29.29,0,0,1,22,13H22A.27.27,0,0,1,22.22,13.08ZM6,9a1,1,0,0,0-1,1v1H3.41L1.56,9.15a.5.5,0,0,0-.71,0l-.7.7a.5.5,0,0,0,0,.71L2,12.41A2,2,0,0,0,3.41,13H5v4a2,2,0,0,0,2,2h7a4,4,0,0,0,4-4v-.34a3,3,0,0,1,.88-2.12L20,11.44A1.54,1.54,0,0,1,21,11h3V9H20.83a3,3,0,0,0-2.12.88L17,11.59l-2-2A2,2,0,0,0,13.59,9Zm6.5-2a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5h-5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5ZM14,17H7V11h6.59L16,13.41V15A2,2,0,0,1,14,17Z',
  parking_brake_light:
    'M3.11,16.58a.5.5,0,0,1-.19.66l-.87.5a.49.49,0,0,1-.39.05.5.5,0,0,1-.3-.25,12,12,0,0,1,0-11.08.5.5,0,0,1,.3-.25.53.53,0,0,1,.39,0l.87.51a.5.5,0,0,1,.19.66,10,10,0,0,0,0,9.16ZM20,12a8,8,0,1,1-8-8A8,8,0,0,1,20,12Zm-2,0a6,6,0,1,0-6,6A6,6,0,0,0,18,12Zm4.64-5.54a.5.5,0,0,0-.3-.25.53.53,0,0,0-.39,0l-.87.51a.5.5,0,0,0-.19.66,10,10,0,0,1,0,9.16.5.5,0,0,0,.19.66l.87.5a.49.49,0,0,0,.39.05.5.5,0,0,0,.3-.25,12,12,0,0,0,0-11.08ZM12,8H10.5a.5.5,0,0,0-.5.5v7a.5.5,0,0,0,.5.5h.75a.5.5,0,0,0,.5-.5V14H12a3,3,0,0,0,0-6Zm0,4.25h-.25V9.75H12a1.25,1.25,0,0,1,0,2.5Z',
  rain_sensor:
    'M14,19a2,2,0,0,1-4,0,2,2,0,0,1,.56-1.39l-.84-2.32A9.15,9.15,0,0,0,8,15.83a2,2,0,0,1-.8.17H6.42A.51.51,0,0,1,6,15.71L2.1,7.53A.9.9,0,0,1,2,7.11V6.82a.48.48,0,0,1,.27-.44A20.91,20.91,0,0,1,11.21,4a5,5,0,0,0-.93,2.06A19.12,19.12,0,0,0,4.34,7.6l3,6.34A12.35,12.35,0,0,1,9,13.39L7.4,8.93a.5.5,0,0,1,.3-.64L8.41,8a.5.5,0,0,1,.64.3L12.21,17A2,2,0,0,1,14,19ZM21.73,6.38A20.8,20.8,0,0,0,18.36,5a3.4,3.4,0,0,1,.39,1.52,3.35,3.35,0,0,1-.07.71c.33.12.66.25,1,.39l-3,6.34a11.88,11.88,0,0,0-4-.91l.76,2.08a9.76,9.76,0,0,1,2.63.72,2,2,0,0,0,.8.17h.77a.51.51,0,0,0,.46-.29L21.9,7.53a.9.9,0,0,0,.1-.42V6.82A.48.48,0,0,0,21.73,6.38ZM14.5,9A2.5,2.5,0,0,0,17,6.5c0-1-1.48-2.56-2.23-3.4a.33.33,0,0,0-.23-.1h-.06a.32.32,0,0,0-.26.12C13.46,4,12,5.53,12,6.5A2.5,2.5,0,0,0,14.5,9Z',
  rear_window_defrost:
    'M15.8,14.49a4.11,4.11,0,0,1-.21-1.3V11h.66a.25.25,0,0,0,.21-.39l-1.54-2.3a.24.24,0,0,0-.41,0L13,10.61a.25.25,0,0,0,.2.39h.66v2.19a5.54,5.54,0,0,0,.07.81H12.05l.47,1.4c.07.2.1.4.15.6h1.79l.17.51a4.11,4.11,0,0,1,.21,1.3V19.5a.5.5,0,0,0,.5.5h.75a.5.5,0,0,0,.5-.5V17.81a5.89,5.89,0,0,0-.3-1.86Z M22,6a2,2,0,0,0-2-2H4A2,2,0,0,0,2,6v8a2,2,0,0,0,2,2H9l.17.51a4.11,4.11,0,0,1,.21,1.3V19.5a.5.5,0,0,0,.5.5h.75a.5.5,0,0,0,.5-.5V17.81a5.89,5.89,0,0,0-.3-1.86l-.49-1.46a4.11,4.11,0,0,1-.21-1.3V11h.66a.25.25,0,0,0,.2-.39L9.49,8.31a.24.24,0,0,0-.41,0l-1.54,2.3a.25.25,0,0,0,.21.39h.66v2.19a7.36,7.36,0,0,0,.06.81H4V6H20v8H17.48L18,15.4c.07.2.1.4.15.6H20a2,2,0,0,0,2-2Z',
  recirculation:
    'M23,10H20.62a1,1,0,0,1-.9-.55L18.55,7.11A2,2,0,0,0,16.76,6H11a2,2,0,0,0-1.6.8L7.49,9.35a2,2,0,0,1-1.3.77l-4.41.68a2,2,0,0,0-1.78,2V15a1,1,0,0,0,1,1H2a2,2,0,0,0,4,0H17a2,2,0,0,0,4,0h.06a3.92,3.92,0,0,0,1.78-.42l.61-.3a1,1,0,0,0,.55-.9V11A1,1,0,0,0,23,10Zm-4.31,2.46L16.39,14a.25.25,0,0,1-.39-.21V13H12.5a2.5,2.5,0,0,1,0-5h3a.5.5,0,0,1,.5.5V9a.5.5,0,0,1-.5.5h-3a1,1,0,0,0,0,2H16v-.78a.25.25,0,0,1,.39-.21L18.69,12A.25.25,0,0,1,18.69,12.46Z',
  seatbelt_not_on:
    'M8,13.56V10.78a2,2,0,0,1,.59-1.42l.77-.77A2,2,0,0,1,10.78,8h2.44a1.92,1.92,0,0,1,1.16.38L8.85,13.91A.49.49,0,0,1,8,13.56ZM12,7a2,2,0,1,0-2-2A2,2,0,0,0,12,7Zm3.62,2.62L10.23,15H16V10.78A1.92,1.92,0,0,0,15.62,9.62ZM3.5,15a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5H8V15Zm17,0H16v2h4.5a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,20.5,15Zm-.65-9.62a.48.48,0,0,0,0-.7l-.53-.53a.48.48,0,0,0-.7,0L14.38,8.38l1.24,1.24ZM8,20.5a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5V17H8Zm6,0a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5V17H14Z',
  sidelight_indicator:
    'M2.5,13H.5a.5.5,0,0,1-.5-.5v-1A.5.5,0,0,1,.5,11h2a.5.5,0,0,1,.5.5v1A.5.5,0,0,1,2.5,13ZM7.21,9C8.27,9.19,9,10.67,9,12s-.73,2.81-1.79,3A24.51,24.51,0,0,1,7,12a24.51,24.51,0,0,1,.21-3M7,7A1.77,1.77,0,0,0,5.25,8.53,28,28,0,0,0,5,12a28,28,0,0,0,.25,3.47A1.77,1.77,0,0,0,7,17c2.5,0,4-2.63,4-5S9.5,7,7,7Zm9.79,2A24.51,24.51,0,0,1,17,12a24.51,24.51,0,0,1-.21,3C15.73,14.81,15,13.33,15,12s.73-2.81,1.79-3M17,7c-2.5,0-4,2.63-4,5s1.5,5,4,5a1.77,1.77,0,0,0,1.75-1.53A28,28,0,0,0,19,12a28,28,0,0,0-.25-3.47A1.77,1.77,0,0,0,17,7ZM3.52,18.05a.51.51,0,0,0,.19-.68l-.5-.87a.51.51,0,0,0-.69-.18L1,17.2a.49.49,0,0,0-.18.68l.5.87a.49.49,0,0,0,.68.18ZM2,5.07a.49.49,0,0,0-.68.18l-.5.87A.49.49,0,0,0,1,6.8l1.53.88a.51.51,0,0,0,.69-.18l.5-.87A.51.51,0,0,0,3.52,6ZM21.5,13a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h2a.5.5,0,0,1,.5.5v1a.5.5,0,0,1-.5.5ZM22,18.93a.49.49,0,0,0,.68-.18l.5-.87A.49.49,0,0,0,23,17.2l-1.53-.88a.51.51,0,0,0-.69.18l-.5.87a.51.51,0,0,0,.19.68ZM20.48,6a.51.51,0,0,0-.19.68l.5.87a.51.51,0,0,0,.69.18L23,6.8a.49.49,0,0,0,.18-.68l-.5-.87A.49.49,0,0,0,22,5.07Z',
  sidelight_information:
    'M12,18A6,6,0,0,0,15.2,6.93a1.5,1.5,0,0,1-.7-1.27V5.5A.5.5,0,0,0,14,5H10a.5.5,0,0,0-.5.5v.16a1.48,1.48,0,0,1-.7,1.27A6,6,0,0,0,12,18ZM12,8a4,4,0,1,1-4,4A4,4,0,0,1,12,8Zm2-5.59a1,1,0,0,0-.29-.7l-.42-.42a1,1,0,0,0-.7-.29H11.41a1,1,0,0,0-.7.29l-.42.42a1,1,0,0,0-.29.7V3h4ZM13,20.5a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5v2a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5ZM3.87,19.42l.71.71a.48.48,0,0,0,.7,0L6.7,18.72a.51.51,0,0,0,0-.71L6,17.3a.51.51,0,0,0-.71,0L3.87,18.72A.48.48,0,0,0,3.87,19.42Zm13.43-.7,1.42,1.41a.48.48,0,0,0,.7,0l.71-.71a.48.48,0,0,0,0-.7L18.72,17.3a.51.51,0,0,0-.71,0L17.3,18A.51.51,0,0,0,17.3,18.72ZM1,12.5a.5.5,0,0,0,.5.5h2a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5h-2a.5.5,0,0,0-.5.5Zm19.5.5h2a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5h-2a.5.5,0,0,0-.5.5v1A.5.5,0,0,0,20.5,13ZM3.87,4.58a.48.48,0,0,0,0,.7L5.28,6.7A.51.51,0,0,0,6,6.7L6.7,6a.51.51,0,0,0,0-.71L5.28,3.87a.48.48,0,0,0-.7,0ZM18.72,6.7l1.41-1.42a.48.48,0,0,0,0-.7l-.71-.71a.48.48,0,0,0-.7,0L17.3,5.28a.51.51,0,0,0,0,.71L18,6.7A.51.51,0,0,0,18.72,6.7Z',
  stability_control_off:
    'M19.85,7.46l-1.44-5A2,2,0,0,0,16.49,1h-9A2,2,0,0,0,5.59,2.45l-1.44,5A4.11,4.11,0,0,0,4,8.56V14a1,1,0,0,0,1,1H6a1,1,0,0,0,1-1V13H17v1a1,1,0,0,0,1,1h1a1,1,0,0,0,1-1V8.56A4.11,4.11,0,0,0,19.85,7.46ZM6.11,7,7.27,2.93a.25.25,0,0,1,.24-.18h9a.25.25,0,0,1,.24.18L17.89,7ZM7.19,23H3.5a.5.5,0,0,1-.5-.5v-.26a3,3,0,0,1,.88-2.12l.61-.61a1,1,0,0,0,.19-1.15l-.32-.64A.5.5,0,0,1,4.81,17H6.69a.51.51,0,0,1,.45.28l.41.83a2,2,0,0,1,0,1.78l-.33.66a1,1,0,0,0,0,.9l.42.83A.5.5,0,0,1,7.19,23Zm13.24,0H16.74a.5.5,0,0,1-.5-.5v-.26a3,3,0,0,1,.87-2.12l.62-.61a1,1,0,0,0,.18-1.15l-.31-.64a.49.49,0,0,1,.45-.72h1.88a.49.49,0,0,1,.44.28l.42.83a2,2,0,0,1,0,1.78l-.33.66a1,1,0,0,0,0,.9l.41.83A.49.49,0,0,1,20.43,23Z',
  temperature_warning:
    'M2.54,16a.5.5,0,0,1-.54-.5v-1a.5.5,0,0,1,.45-.5c1.74-.12,2.09-1,4.55-1a8.77,8.77,0,0,1,1.11.07A4.08,4.08,0,0,0,8,14a4.23,4.23,0,0,0,.16,1.13A4.82,4.82,0,0,0,7,15C5,15,4.69,15.86,2.54,16ZM22,18.48a.5.5,0,0,0-.45-.5c-1.72-.12-2.1-1-4.55-1-2.65,0-2.85,1-5,1s-2.28-1-5-1c-2.46,0-2.81.86-4.55,1a.5.5,0,0,0-.45.5v1a.5.5,0,0,0,.54.5C4.69,19.86,5,19,7,19s2.35,1,5,1,2.85-1,5-1c2,0,2.31.86,4.46,1a.5.5,0,0,0,.54-.49ZM21.55,14c-1.72-.12-2.1-1-4.55-1a8.77,8.77,0,0,0-1.11.07A4.08,4.08,0,0,1,16,14a4.15,4.15,0,0,1-.16,1.12A5.37,5.37,0,0,1,17,15c2,0,2.31.86,4.46,1a.5.5,0,0,0,.54-.49v-1A.5.5,0,0,0,21.55,14ZM12,16a2,2,0,0,0,2-2,2,2,0,0,0-1-1.72V4.5a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5v7.78A2,2,0,0,0,10,14,2,2,0,0,0,12,16Zm3.5-6h3a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5h-3a.5.5,0,0,0-.5.5v1A.5.5,0,0,0,15.5,10Zm0-4h3a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5h-3a.5.5,0,0,0-.5.5v1A.5.5,0,0,0,15.5,6Z',
  tyre_pressure_low:
    'M12.5,13a.5.5,0,0,1,.5.5v1a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5Zm-1-2h1.06a.25.25,0,0,0,.25-.22l.4-3.22a.5.5,0,0,0-.5-.56H11.32a.5.5,0,0,0-.5.56l.4,3.22A.25.25,0,0,0,11.47,11Zm8.16-2.12A4.08,4.08,0,0,1,19,6.74V3.5a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5V6.74a6.1,6.1,0,0,0,.92,3.18A7.83,7.83,0,0,1,19,14a4.38,4.38,0,0,1-1,3H6a4.38,4.38,0,0,1-1-3A7.83,7.83,0,0,1,6.08,9.92,6.1,6.1,0,0,0,7,6.74V3.5A.5.5,0,0,0,6.5,3h-1a.5.5,0,0,0-.5.5V6.74a4.08,4.08,0,0,1-.63,2.14A9.88,9.88,0,0,0,3,14a6.4,6.4,0,0,0,1.57,4.47A2,2,0,0,1,5,19.71v.79a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,1,1,0v1a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,1,1,0v1a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,1,1,0v1a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,1,1,0v1a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5v-.79a2,2,0,0,1,.43-1.24,7.68,7.68,0,0,0,1-1.63A6.62,6.62,0,0,0,21,14,9.88,9.88,0,0,0,19.63,8.88Z',
  washer_fluid_low:
    'M21.73,11.38a20.7,20.7,0,0,0-7-2.18v2a19,19,0,0,1,4.91,1.38L18.07,16c-.11,0-.21,0-.32,0a2.7,2.7,0,0,1-1.91-.79.78.78,0,0,0-.52-.21h-.79a.74.74,0,0,0-.51.2,3,3,0,0,1-1,.62V7.5a2.5,2.5,0,0,1,5-.42.5.5,0,0,0,.49.42h1a.48.48,0,0,0,.37-.17A.47.47,0,0,0,20,6.94a4.49,4.49,0,0,0-8-2.25A4.49,4.49,0,0,0,4,6.94a.47.47,0,0,0,.12.39.48.48,0,0,0,.37.17h1A.5.5,0,0,0,6,7.08a2.5,2.5,0,0,1,5,.42V9a21,21,0,0,0-8.73,2.35.48.48,0,0,0-.27.44v.29a.9.9,0,0,0,.1.42L6,20.71a.51.51,0,0,0,.46.29h.77a2,2,0,0,0,.8-.17,10.1,10.1,0,0,1,8,0,2,2,0,0,0,.8.17h.77a.51.51,0,0,0,.46-.29l3.86-8.18a.9.9,0,0,0,.1-.42v-.29A.48.48,0,0,0,21.73,11.38ZM11,11.05v4.77a3,3,0,0,1-1-.62.74.74,0,0,0-.51-.2H8.68a.78.78,0,0,0-.52.21A2.7,2.7,0,0,1,6.25,16c-.11,0-.21,0-.32,0L4.34,12.6A19.11,19.11,0,0,1,11,11.05Z',
  windscreen_defrost:
    'M16.29,16a5.78,5.78,0,0,1,.3,1.85V19.5a.5.5,0,0,1-.5.5h-.75a.5.5,0,0,1-.5-.5V17.81a4.11,4.11,0,0,0-.21-1.3l-.41-1.24A10,10,0,0,0,12.39,15L12,13.94a2.4,2.4,0,0,1-.12-.75V13H12a12.34,12.34,0,0,1,1.84.16V11h-.66a.25.25,0,0,1-.2-.39l1.53-2.3a.24.24,0,0,1,.41,0l1.54,2.3a.25.25,0,0,1-.21.39h-.66v2.19a4.11,4.11,0,0,0,.21,1.3Zm5.44-9.58a21.08,21.08,0,0,0-19.46,0A.48.48,0,0,0,2,6.82v.29a.9.9,0,0,0,.1.42L6,15.71a.51.51,0,0,0,.46.29h.75a1.86,1.86,0,0,0,.77-.15c.3-.13.62-.22.93-.32l.33,1a4.11,4.11,0,0,1,.21,1.3V19.5a.5.5,0,0,0,.5.5h.75a.5.5,0,0,0,.5-.5V17.81a5.89,5.89,0,0,0-.3-1.86l-.49-1.46a4.11,4.11,0,0,1-.21-1.3V11h.66a.25.25,0,0,0,.2-.39L9.49,8.31a.24.24,0,0,0-.41,0l-1.54,2.3a.25.25,0,0,0,.21.39h.66v2.19c0,.12,0,.24,0,.36a11.07,11.07,0,0,0-1.1.39l-3-6.34a19.14,19.14,0,0,1,15.32,0l-2.13,4.49a2.11,2.11,0,0,0-.19.86v.26a2.28,2.28,0,0,0,.12.73L18,15.4c0,.1.07.21.09.31L21.9,7.53a.9.9,0,0,0,.1-.42V6.82A.48.48,0,0,0,21.73,6.38Z',
  call:
    'M17,21a10.07,10.07,0,0,1-4.74-1.19l-.45-.25a18,18,0,0,1-7.33-7.33l-.25-.45A10.07,10.07,0,0,1,3,7V6.33a2,2,0,0,1,.59-1.42L5.28,3.22a.75.75,0,0,1,1.18.15L8.71,7.23a1,1,0,0,1-.16,1.21L6.66,10.33a.5.5,0,0,0-.09.59l.35.66a13.53,13.53,0,0,0,5.5,5.49l.66.36a.5.5,0,0,0,.59-.09l1.89-1.89a1,1,0,0,1,1.21-.16l3.86,2.25a.75.75,0,0,1,.15,1.18l-1.69,1.69a2,2,0,0,1-1.42.59Z',
  call_cancelled:
    'M12.91,9.86,14.76,8,12.91,6.14a.48.48,0,0,1,0-.7l.53-.53a.48.48,0,0,1,.7,0L16,6.76l1.86-1.85a.48.48,0,0,1,.7,0l.53.53a.48.48,0,0,1,0,.7L17.24,8l1.85,1.86a.48.48,0,0,1,0,.7l-.53.53a.48.48,0,0,1-.7,0L16,9.24l-1.86,1.85a.48.48,0,0,1-.7,0l-.53-.53A.48.48,0,0,1,12.91,9.86Zm7.72,7.68-3.86-2.25a1,1,0,0,0-1.21.16l-1.89,1.89a.5.5,0,0,1-.59.09l-.66-.36a13.53,13.53,0,0,1-5.5-5.49l-.35-.66a.5.5,0,0,1,.09-.59L8.55,8.44a1,1,0,0,0,.16-1.21L6.46,3.37a.75.75,0,0,0-1.18-.15L3.59,4.91A2,2,0,0,0,3,6.33V7a10.07,10.07,0,0,0,1.19,4.74l.25.45a18,18,0,0,0,7.33,7.33l.45.25A10.07,10.07,0,0,0,17,21h.71a2,2,0,0,0,1.42-.59l1.69-1.69A.75.75,0,0,0,20.63,17.54Z',
  call_ended:
    'M23,12.33v2.92a.75.75,0,0,1-.94.72l-4.32-1.13a1,1,0,0,1-.74-1V10.68a.49.49,0,0,0-.36-.48L15.93,10a13.83,13.83,0,0,0-7.86,0l-.71.22a.49.49,0,0,0-.36.48v3.18a1,1,0,0,1-.74,1L1.94,16A.75.75,0,0,1,1,15.25V12.34a2,2,0,0,1,.59-1.42l.5-.5A10.06,10.06,0,0,1,6.28,7.91l.5-.15a18.4,18.4,0,0,1,10.45,0l.49.15a10.06,10.06,0,0,1,4.19,2.51l.5.5A2,2,0,0,1,23,12.33Z',
  call_forward:
    'M17,21a10.07,10.07,0,0,1-4.74-1.19l-.45-.25a18,18,0,0,1-7.33-7.33l-.25-.45A10.07,10.07,0,0,1,3,7V6.33a2,2,0,0,1,.59-1.42L5.28,3.22a.75.75,0,0,1,1.18.15L8.71,7.23a1,1,0,0,1-.16,1.21L6.66,10.33a.5.5,0,0,0-.09.59l.35.66a13.53,13.53,0,0,0,5.5,5.49l.66.36a.5.5,0,0,0,.59-.09l1.89-1.89a1,1,0,0,1,1.21-.16l3.86,2.25a.75.75,0,0,1,.15,1.18l-1.69,1.69a2,2,0,0,1-1.42.59Zm1.9-13.35-3-3A.5.5,0,0,0,15,5V7H12.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5H15v2a.5.5,0,0,0,.85.35l3-3A.51.51,0,0,0,18.86,7.65Z',
  call_talking:
    'M13,4.5v-1a.51.51,0,0,1,.15-.37A.53.53,0,0,1,13.55,3,8,8,0,0,1,21,10.45a.53.53,0,0,1-.13.38.51.51,0,0,1-.37.15h-1a.5.5,0,0,1-.5-.46A6,6,0,0,0,13.48,5,.5.5,0,0,1,13,4.5Zm.17,2.64a.5.5,0,0,0-.17.38v1a.49.49,0,0,0,.4.49A2,2,0,0,1,15,10.58a.49.49,0,0,0,.49.4h1a.5.5,0,0,0,.38-.17.52.52,0,0,0,.12-.4A4.05,4.05,0,0,0,13.59,7,.52.52,0,0,0,13.19,7.14ZM17.67,21a2,2,0,0,0,1.42-.59l1.69-1.69a.75.75,0,0,0-.15-1.18l-3.86-2.25a1,1,0,0,0-1.21.16l-1.89,1.89a.5.5,0,0,1-.59.09l-.66-.36a13.53,13.53,0,0,1-5.5-5.49l-.35-.66a.5.5,0,0,1,.09-.59L8.55,8.44a1,1,0,0,0,.16-1.21L6.46,3.37a.75.75,0,0,0-1.18-.15L3.59,4.91A2,2,0,0,0,3,6.33V7a10.07,10.07,0,0,0,1.19,4.74l.25.45a18,18,0,0,0,7.33,7.33l.45.25A10.07,10.07,0,0,0,17,21Z',
  call_talking_quiet:
    'M13.59,7A4.05,4.05,0,0,1,17,10.41a.52.52,0,0,1-.12.4.5.5,0,0,1-.38.17h-1a.49.49,0,0,1-.49-.4A2,2,0,0,0,13.42,9a.49.49,0,0,1-.4-.49v-1a.5.5,0,0,1,.17-.38A.52.52,0,0,1,13.59,7Zm4.08,14a2,2,0,0,0,1.42-.59l1.69-1.69a.75.75,0,0,0-.15-1.18l-3.86-2.25a1,1,0,0,0-1.21.16l-1.89,1.89a.5.5,0,0,1-.59.09l-.66-.36a13.53,13.53,0,0,1-5.5-5.49l-.35-.66a.5.5,0,0,1,.09-.59L8.55,8.44a1,1,0,0,0,.16-1.21L6.46,3.37a.75.75,0,0,0-1.18-.15L3.59,4.91A2,2,0,0,0,3,6.33V7a10.07,10.07,0,0,0,1.19,4.74l.25.45a18,18,0,0,0,7.33,7.33l.45.25A10.07,10.07,0,0,0,17,21Z',
  chat:
    'M15,16a4,4,0,0,0,4-4V6a4,4,0,0,0-4-4H5A4,4,0,0,0,1,6V18.5a.5.5,0,0,0,.5.5.49.49,0,0,0,.35-.15l2-2A3,3,0,0,1,6,16ZM21,6v8a4,4,0,0,1-4,4H5a2,2,0,0,0,2,2H18a3,3,0,0,1,2.13.88l2,2a.49.49,0,0,0,.35.15.5.5,0,0,0,.5-.5V8A2,2,0,0,0,21,6Z',
  chat_outlined:
    'M15,16a4,4,0,0,0,4-4V6a4,4,0,0,0-4-4H5A4,4,0,0,0,1,6V18.5a.5.5,0,0,0,.5.5.49.49,0,0,0,.35-.15l2-2A3,3,0,0,1,6,16ZM3,15V6A2,2,0,0,1,5,4H15a2,2,0,0,1,2,2v6a2,2,0,0,1-2,2H6A5,5,0,0,0,3,15ZM21,6v8a4,4,0,0,1-4,4H5a2,2,0,0,0,2,2H18a3,3,0,0,1,2.13.88l2,2a.49.49,0,0,0,.35.15.5.5,0,0,0,.5-.5V8A2,2,0,0,0,21,6Z',
  comment:
    'M20,3H4A2,2,0,0,0,2,5V17a2,2,0,0,0,2,2H9.09a1,1,0,0,1,.7.29l1.49,1.49a.75.75,0,0,0,.53.22h.38a.75.75,0,0,0,.53-.22l1.49-1.49a1,1,0,0,1,.7-.29H20a2,2,0,0,0,2-2V5A2,2,0,0,0,20,3Z',
  comment_image:
    'M20,3H4A2,2,0,0,0,2,5V17a2,2,0,0,0,2,2H9.09a1,1,0,0,1,.7.29l1.49,1.49a.75.75,0,0,0,.53.22h.38a.75.75,0,0,0,.53-.22l1.49-1.49a1,1,0,0,1,.7-.29H20a2,2,0,0,0,2-2V5A2,2,0,0,0,20,3ZM9.5,6A1.5,1.5,0,1,1,8,7.5,1.5,1.5,0,0,1,9.5,6ZM19,15.5a.5.5,0,0,1-.5.5H5.5a.5.5,0,0,1-.5-.5v-.34a.46.46,0,0,1,.11-.3L8,11.19A.52.52,0,0,1,8.35,11a.5.5,0,0,1,.39.18l1.45,1.75a.49.49,0,0,0,.38.18.51.51,0,0,0,.4-.2l2.88-3.72a.51.51,0,0,1,.8,0l4.25,5.67a.51.51,0,0,1,.1.3Z',
  comment_image_outlined:
    'M20,3H4A2,2,0,0,0,2,5V17a2,2,0,0,0,2,2H9.09a1,1,0,0,1,.7.29l1.49,1.49a.75.75,0,0,0,.53.22h.38a.75.75,0,0,0,.53-.22l1.49-1.49a1,1,0,0,1,.7-.29H20a2,2,0,0,0,2-2V5A2,2,0,0,0,20,3Zm0,12.83a.49.49,0,0,1-.5.5.48.48,0,0,1-.4-.2L14.65,10.2a.51.51,0,0,0-.8,0L11,13.91a.51.51,0,0,1-.4.2.49.49,0,0,1-.38-.18L8.74,12.18A.5.5,0,0,0,8.35,12a.52.52,0,0,0-.39.19L4.89,16.13a.48.48,0,0,1-.39.19.49.49,0,0,1-.5-.5V5H20ZM9.5,10A1.5,1.5,0,1,0,8,8.5,1.5,1.5,0,0,0,9.5,10Z',
  comment_outlined:
    'M20,5V17H14.91a3,3,0,0,0-2.12.88l-.79.79-.79-.79A3,3,0,0,0,9.09,17H4V5H20m0-2H4A2,2,0,0,0,2,5V17a2,2,0,0,0,2,2H9.09a1,1,0,0,1,.7.29l1.49,1.49a.75.75,0,0,0,.53.22h.38a.75.75,0,0,0,.53-.22l1.49-1.49a1,1,0,0,1,.7-.29H20a2,2,0,0,0,2-2V5a2,2,0,0,0-2-2Z',
  comment_play:
    'M20,3H4A2,2,0,0,0,2,5V17a2,2,0,0,0,2,2H9.09a1,1,0,0,1,.7.29l1.49,1.49a.75.75,0,0,0,.53.22h.38a.75.75,0,0,0,.53-.22l1.49-1.49a1,1,0,0,1,.7-.29H20a2,2,0,0,0,2-2V5A2,2,0,0,0,20,3Zm-4.94,8.62-3.89,2.6A.75.75,0,0,1,10,13.6V8.4a.75.75,0,0,1,1.17-.62l3.89,2.6A.74.74,0,0,1,15.06,11.62Z',
  comment_play_outlined:
    'M22,5a2,2,0,0,0-2-2H4A2,2,0,0,0,2,5V17a2,2,0,0,0,2,2H9.09a1,1,0,0,1,.7.29l1.49,1.49a.75.75,0,0,0,.53.22h.38a.75.75,0,0,0,.53-.22l1.49-1.49a1,1,0,0,1,.7-.29H20a2,2,0,0,0,2-2ZM20,17H14.91a3,3,0,0,0-2.12.88l-.79.79-.79-.79A3,3,0,0,0,9.09,17H4V5H20ZM10,13.6a.75.75,0,0,0,1.17.62l3.89-2.6a.74.74,0,0,0,0-1.24l-3.89-2.6A.75.75,0,0,0,10,8.4Z',
  comment_text:
    'M22,5a2,2,0,0,0-2-2H4A2,2,0,0,0,2,5V17a2,2,0,0,0,2,2H9.09a1,1,0,0,1,.7.29l1.49,1.49a.75.75,0,0,0,.53.22h.38a.75.75,0,0,0,.53-.22l1.49-1.49a1,1,0,0,1,.7-.29H20a2,2,0,0,0,2-2Zm-8,8.5a.5.5,0,0,1-.5.5h-7a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h7a.5.5,0,0,1,.5.5Zm4-4a.5.5,0,0,1-.5.5H6.5A.5.5,0,0,1,6,9.5v-1A.5.5,0,0,1,6.5,8h11a.5.5,0,0,1,.5.5Z',
  comment_text_outlined:
    'M20,3H4A2,2,0,0,0,2,5V17a2,2,0,0,0,2,2H9.09a1,1,0,0,1,.7.29l1.49,1.49a.75.75,0,0,0,.53.22h.38a.75.75,0,0,0,.53-.22l1.49-1.49a1,1,0,0,1,.7-.29H20a2,2,0,0,0,2-2V5A2,2,0,0,0,20,3Zm0,14H14.91a3,3,0,0,0-2.12.88l-.79.79-.79-.79A3,3,0,0,0,9.09,17H4V5H20ZM6.5,14h7a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5h-7a.5.5,0,0,0-.5.5v1A.5.5,0,0,0,6.5,14Zm0-4h11a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5H6.5a.5.5,0,0,0-.5.5v1A.5.5,0,0,0,6.5,10Z',
  comment_video:
    'M20,3H4A2,2,0,0,0,2,5V17a2,2,0,0,0,2,2H9.09a1,1,0,0,1,.7.29l1.49,1.49a.75.75,0,0,0,.53.22h.38a.75.75,0,0,0,.53-.22l1.49-1.49a1,1,0,0,1,.7-.29H20a2,2,0,0,0,2-2V5A2,2,0,0,0,20,3ZM19,14.5a.5.5,0,0,1-.5.5h-.09a1,1,0,0,1-.7-.29L15,12v2.5A1.5,1.5,0,0,1,13.5,16h-7A1.5,1.5,0,0,1,5,14.5v-7A1.5,1.5,0,0,1,6.5,6h7A1.5,1.5,0,0,1,15,7.5V10l2.71-2.71a1,1,0,0,1,.7-.29h.09a.5.5,0,0,1,.5.5Z',
  comment_vs:
    'M18,2H6A4,4,0,0,0,2,6V21.5a.5.5,0,0,0,.5.5.49.49,0,0,0,.35-.15l3-3A3,3,0,0,1,8,18H18a4,4,0,0,0,4-4V6A4,4,0,0,0,18,2ZM14,12.5a.5.5,0,0,1-.5.5h-7a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h7a.5.5,0,0,1,.5.5Zm4-4a.5.5,0,0,1-.5.5H6.5A.5.5,0,0,1,6,8.5v-1A.5.5,0,0,1,6.5,7h11a.5.5,0,0,1,.5.5Z',
  comment_vs_outlined:
    'M22,6a4,4,0,0,0-4-4H6A4,4,0,0,0,2,6V21.5a.5.5,0,0,0,.5.5.49.49,0,0,0,.35-.15l3-3A3,3,0,0,1,8,18H18a4,4,0,0,0,4-4ZM18,16H8a5,5,0,0,0-3.54,1.46L4,17.88V6A2,2,0,0,1,6,4H18a2,2,0,0,1,2,2v8A2,2,0,0,1,18,16ZM6.5,13h7a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5h-7a.5.5,0,0,0-.5.5v1A.5.5,0,0,0,6.5,13Zm0-4h11a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5H6.5a.5.5,0,0,0-.5.5v1A.5.5,0,0,0,6.5,9Z',
  conference_call:
    'M23.5,18H22V5a1,1,0,0,0-1-1H3A1,1,0,0,0,2,5V18H.5a.5.5,0,0,0-.5.5V19a1,1,0,0,0,1,1H23a1,1,0,0,0,1-1v-.5A.5.5,0,0,0,23.5,18ZM12,6.75A2.25,2.25,0,1,1,9.75,9,2.24,2.24,0,0,1,12,6.75ZM16.25,17H7.75a.75.75,0,0,1-.67-1.09L8,14.1A2,2,0,0,1,9.77,13h4.46A2,2,0,0,1,16,14.1l.9,1.81A.75.75,0,0,1,16.25,17Z',
  contacts:
    'M20,4H4A2,2,0,0,0,2,6V18a2,2,0,0,0,2,2H20a2,2,0,0,0,2-2V6A2,2,0,0,0,20,4ZM12,6.75A2.25,2.25,0,1,1,9.75,9,2.24,2.24,0,0,1,12,6.75ZM16.25,17H7.75a.75.75,0,0,1-.67-1.09L8,14.1A2,2,0,0,1,9.77,13h4.46A2,2,0,0,1,16,14.1l.9,1.81A.75.75,0,0,1,16.25,17ZM20,1a1,1,0,0,0-1-1H5A1,1,0,0,0,4,1V2H20ZM4,23a1,1,0,0,0,1,1H19a1,1,0,0,0,1-1V22H4Z',
  contacts_book:
    'M5,6v5H3.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5H5v5H3.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5H5a2,2,0,0,0,2,2H19a2,2,0,0,0,2-2V4a2,2,0,0,0-2-2H7A2,2,0,0,0,5,4H3.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5Zm9,7a2,2,0,1,1-2-2A2,2,0,0,1,14,13ZM8.05,18.28l.73-1.45A1.5,1.5,0,0,1,10.12,16h3.76a1.5,1.5,0,0,1,1.34.83L16,18.28a.5.5,0,0,1-.45.72h-7A.5.5,0,0,1,8.05,18.28Z',
  contact_notebook:
    'M16,2H4A2,2,0,0,0,2,4V20a2,2,0,0,0,2,2H16a2,2,0,0,0,2-2V4A2,2,0,0,0,16,2ZM10,6.75A2.25,2.25,0,1,1,7.75,9,2.24,2.24,0,0,1,10,6.75ZM14.25,17H5.75a.75.75,0,0,1-.67-1.09L6,14.1A2,2,0,0,1,7.77,13h4.61A1.8,1.8,0,0,1,14,14.1l.9,1.81A.75.75,0,0,1,14.25,17ZM21,4H20V20h1a1,1,0,0,0,1-1V5A1,1,0,0,0,21,4Z',
  draft:
    'M21.15,7.77l-7.5-5.25a2.88,2.88,0,0,0-3.3,0L2.85,7.77A2,2,0,0,0,2,9.41V20a2,2,0,0,0,2,2H20a2,2,0,0,0,2-2V9.41A2,2,0,0,0,21.15,7.77ZM12.5,14.66a.91.91,0,0,1-.5.15.85.85,0,0,1-.5-.16L4,9.41l7.5-5.25a.86.86,0,0,1,1,0L20,9.41Z',
  email:
    'M20,4H4A2,2,0,0,0,2,6V18a2,2,0,0,0,2,2H20a2,2,0,0,0,2-2V6A2,2,0,0,0,20,4Zm0,7-6.35,4.45a2.88,2.88,0,0,1-3.3,0L4,11V8.9l7.35,5.15a1.14,1.14,0,0,0,1.3,0L20,8.9Z',
  email_outlined:
    'M20,4H4A2,2,0,0,0,2,6V18a2,2,0,0,0,2,2H20a2,2,0,0,0,2-2V6A2,2,0,0,0,20,4Zm0,2V8.9l-7.35,5.15a1.14,1.14,0,0,1-1.3,0L4,8.9V6ZM4,18V11l6.35,4.44a2.88,2.88,0,0,0,3.3,0L20,11v7Z',
  favorite_comment:
    'M22,5a2,2,0,0,0-2-2H4A2,2,0,0,0,2,5V17a2,2,0,0,0,2,2H9.09a1,1,0,0,1,.7.29l1.49,1.49a.75.75,0,0,0,.53.22h.38a.75.75,0,0,0,.53-.22l1.49-1.49a1,1,0,0,1,.7-.29H20a2,2,0,0,0,2-2Zm-8.5,9.81-1,1a.75.75,0,0,1-1.06,0l-1-1C9.38,13.69,7,11.5,7,9.75A2.75,2.75,0,0,1,9.75,7,3.18,3.18,0,0,1,12,8a3.2,3.2,0,0,1,2.25-1A2.75,2.75,0,0,1,17,9.75C17,11.5,14.62,13.69,13.5,14.81Z',
  import_export:
    'M14.64,21.86l-3-3A.5.5,0,0,1,12,18h2V10.5a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5V18h2a.5.5,0,0,1,.36.85l-3,3A.51.51,0,0,1,14.64,21.86ZM10,13.5V6h2a.5.5,0,0,0,.36-.85l-3-3a.51.51,0,0,0-.71,0l-3,3A.5.5,0,0,0,6,6H8v7.5a.5.5,0,0,0,.5.5h1A.5.5,0,0,0,10,13.5Z',
  inbox:
    'M20,4H4A2,2,0,0,0,2,6V18a2,2,0,0,0,2,2H20a2,2,0,0,0,2-2V6A2,2,0,0,0,20,4Zm0,2v8H16.24a1.5,1.5,0,0,0-1.41,1,3,3,0,0,1-5.66,0,1.5,1.5,0,0,0-1.41-1H4V6Z',
  inboxes:
    'M21,5a2,2,0,0,0-2-2H5A2,2,0,0,0,3,5v6a2,2,0,0,0,2,2H19a2,2,0,0,0,2-2ZM19,5V7H16.24a1.5,1.5,0,0,0-1.41,1A3,3,0,0,1,9.17,8,1.5,1.5,0,0,0,7.76,7H5V5ZM16.24,15a1.5,1.5,0,0,0-1.41,1,3,3,0,0,1-5.66,0,1.5,1.5,0,0,0-1.41-1H3v4a2,2,0,0,0,2,2H19a2,2,0,0,0,2-2V15Z',
  made_call:
    'M20,5V13.5a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5V7.42L5.56,19.85a.5.5,0,0,1-.71,0l-.7-.7a.5.5,0,0,1,0-.71L16.59,6H10.5a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5H19a.75.75,0,0,1,.53.22l.27.27A.75.75,0,0,1,20,5Z',
  message:
    'M18,18a4,4,0,0,0,4-4V6a4,4,0,0,0-4-4H6A4,4,0,0,0,2,6V21.5a.5.5,0,0,0,.5.5.49.49,0,0,0,.35-.15l3-3A3,3,0,0,1,8,18Z',
  messages:
    'M19,15V5a2,2,0,0,0-2-2H3A2,2,0,0,0,1,5V15a2,2,0,0,0,2,2H17A2,2,0,0,0,19,15Zm-9-2a2.83,2.83,0,0,1-1.65-.52L3,8.73V6.6l6.35,4.45a1.14,1.14,0,0,0,1.3,0L17,6.6V8.73l-5.35,3.75A2.83,2.83,0,0,1,10,13ZM21,7V17a2,2,0,0,1-2,2H5a2,2,0,0,0,2,2H19a4,4,0,0,0,4-4V9A2,2,0,0,0,21,7Z',
  messages_outlined:
    'M17,3H3A2,2,0,0,0,1,5V15a2,2,0,0,0,2,2H17a2,2,0,0,0,2-2V5A2,2,0,0,0,17,3Zm0,12H3V8.73l5.35,3.75a2.88,2.88,0,0,0,3.3,0L17,8.73Zm0-8.4-6.35,4.45a1.14,1.14,0,0,1-1.3,0L3,6.6V5H17ZM21,7V17a2,2,0,0,1-2,2H5a2,2,0,0,0,2,2H19a4,4,0,0,0,4-4V9A2,2,0,0,0,21,7Z',
  message_add:
    'M18,2H6A4,4,0,0,0,2,6V21.5a.5.5,0,0,0,.5.5.49.49,0,0,0,.35-.15l3-3A3,3,0,0,1,8,18H18a4,4,0,0,0,4-4V6A4,4,0,0,0,18,2Zm-2,8.5a.5.5,0,0,1-.5.5H13v2.5a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5V11H8.5a.5.5,0,0,1-.5-.5v-1A.5.5,0,0,1,8.5,9H11V6.5a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5V9h2.5a.5.5,0,0,1,.5.5Z',
  message_add_outlined:
    'M22,6a4,4,0,0,0-4-4H6A4,4,0,0,0,2,6V21.5a.5.5,0,0,0,.5.5.49.49,0,0,0,.35-.15l3-3A3,3,0,0,1,8,18H18a4,4,0,0,0,4-4ZM18,16H8a5,5,0,0,0-3.54,1.46L4,17.88V6A2,2,0,0,1,6,4H18a2,2,0,0,1,2,2v8A2,2,0,0,1,18,16ZM8.5,11H11v2.5a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5V11h2.5a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5H13V6.5a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5V9H8.5a.5.5,0,0,0-.5.5v1A.5.5,0,0,0,8.5,11Z',
  message_outlined:
    'M18,4a2,2,0,0,1,2,2v8a2,2,0,0,1-2,2H8a5,5,0,0,0-3.54,1.46L4,17.88V6A2,2,0,0,1,6,4H18m0-2H6A4,4,0,0,0,2,6V21.5a.5.5,0,0,0,.5.5.49.49,0,0,0,.35-.15l3-3A3,3,0,0,1,8,18H18a4,4,0,0,0,4-4V6a4,4,0,0,0-4-4Z',
  message_play:
    'M18,2H6A4,4,0,0,0,2,6V21.5a.5.5,0,0,0,.5.5.49.49,0,0,0,.35-.15l3-3A3,3,0,0,1,8,18H18a4,4,0,0,0,4-4V6A4,4,0,0,0,18,2Zm-2.94,8.62-3.89,2.6A.75.75,0,0,1,10,12.6V7.4a.75.75,0,0,1,1.17-.62l3.89,2.6A.74.74,0,0,1,15.06,10.62Z',
  message_play_outlined:
    'M18,2H6A4,4,0,0,0,2,6V21.5a.5.5,0,0,0,.5.5.49.49,0,0,0,.35-.15l3-3A3,3,0,0,1,8,18H18a4,4,0,0,0,4-4V6A4,4,0,0,0,18,2Zm0,14H8a5,5,0,0,0-3.54,1.46L4,17.88V6A2,2,0,0,1,6,4H18a2,2,0,0,1,2,2v8A2,2,0,0,1,18,16Z M11.17,13.22l3.89-2.6a.74.74,0,0,0,0-1.24l-3.89-2.6A.75.75,0,0,0,10,7.4v5.2A.75.75,0,0,0,11.17,13.22Z',
  missed_call:
    'M20.85,8.56l-8.22,8.22a.75.75,0,0,1-.53.22h-.2a.75.75,0,0,1-.53-.22L5,10.42V14.5a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5V8a.75.75,0,0,1,.22-.53l.27-.27A.75.75,0,0,1,4,7H10.5a.5.5,0,0,1,.5.5v1a.5.5,0,0,1-.5.5H6.41L12,14.59l7.44-7.44a.5.5,0,0,1,.71,0l.7.7A.5.5,0,0,1,20.85,8.56Z',
  missed_call_outgoing:
    'M21,8V14.5a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5V10.42l-6.37,6.36a.75.75,0,0,1-.53.22h-.2a.75.75,0,0,1-.53-.22L3.15,8.56a.5.5,0,0,1,0-.71l.7-.7a.5.5,0,0,1,.71,0L12,14.59,17.59,9H13.5a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5H20a.75.75,0,0,1,.53.22l.27.27A.75.75,0,0,1,21,8Z',
  mms:
    'M18,2H6A4,4,0,0,0,2,6V21.5a.5.5,0,0,0,.5.5.49.49,0,0,0,.35-.15l3-3A3,3,0,0,1,8,18H18a4,4,0,0,0,4-4V6A4,4,0,0,0,18,2ZM9.5,5A1.5,1.5,0,1,1,8,6.5,1.5,1.5,0,0,1,9.5,5ZM19,14.5a.5.5,0,0,1-.5.5H5.5a.5.5,0,0,1-.5-.5v-.34a.46.46,0,0,1,.11-.3L8,10.19A.52.52,0,0,1,8.35,10a.5.5,0,0,1,.39.18l1.45,1.75a.49.49,0,0,0,.38.18.51.51,0,0,0,.4-.2l2.88-3.72a.51.51,0,0,1,.8,0l4.25,5.67a.51.51,0,0,1,.1.3Z',
  mms_outlined:
    'M18,2H6A4,4,0,0,0,2,6V21.5a.5.5,0,0,0,.5.5.49.49,0,0,0,.35-.15l3-3A3,3,0,0,1,8,18H18a4,4,0,0,0,4-4V6A4,4,0,0,0,18,2Zm2,12.83a.49.49,0,0,1-.5.5.48.48,0,0,1-.4-.2L14.65,9.2a.51.51,0,0,0-.8,0L11,12.91a.51.51,0,0,1-.4.2.49.49,0,0,1-.38-.18L8.74,11.18A.5.5,0,0,0,8.35,11a.52.52,0,0,0-.39.19L4.89,15.13a.48.48,0,0,1-.39.19.49.49,0,0,1-.5-.5V6A2,2,0,0,1,6,4H18a2,2,0,0,1,2,2ZM9.5,9A1.5,1.5,0,1,0,8,7.5,1.5,1.5,0,0,0,9.5,9Z',
  new_email:
    'M21.15,7.77,20,7V6a2,2,0,0,0-2-2H15.76L13.65,2.52a2.88,2.88,0,0,0-3.3,0L8.24,4H6A2,2,0,0,0,4,6V7l-1.15.81A2,2,0,0,0,2,9.41V20a2,2,0,0,0,2,2H20a2,2,0,0,0,2-2V9.41A2,2,0,0,0,21.15,7.77ZM6,6H18v4.81l-5.5,3.85a.91.91,0,0,1-.5.15.85.85,0,0,1-.5-.16L6,10.81Z',
  received_call:
    'M4,19V10.5a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5v6.08L18.44,4.15a.5.5,0,0,1,.71,0l.7.7a.5.5,0,0,1,0,.71L7.41,18H13.5a.5.5,0,0,1,.5.5v1a.5.5,0,0,1-.5.5H5a.75.75,0,0,1-.53-.22l-.27-.27A.75.75,0,0,1,4,19Z',
  reply:
    'M10,16.58a.49.49,0,0,1-.15.36l-.2.2a.5.5,0,0,1-.71,0L3.15,11.35a.48.48,0,0,1,0-.7L8.94,4.86a.5.5,0,0,1,.71,0l.2.2a.49.49,0,0,1,.15.36V8A11.22,11.22,0,0,1,21,18.54a.5.5,0,0,1-.12.33.46.46,0,0,1-.32.13h-.1a.5.5,0,0,1-.49-.41C19.5,16,14.72,14,10,14Z',
  reply_all:
    'M3,11.35l4,4v1.2a.49.49,0,0,1-.15.36l-.2.2a.5.5,0,0,1-.71,0L.15,11.35a.48.48,0,0,1,0-.7L5.94,4.86a.5.5,0,0,1,.71,0l.2.2A.49.49,0,0,1,7,5.42v1.2l-4,4A.48.48,0,0,0,3,11.35ZM13,8V5.42a.49.49,0,0,0-.15-.36l-.2-.2a.5.5,0,0,0-.71,0L6.15,10.65a.48.48,0,0,0,0,.7l5.79,5.79a.5.5,0,0,0,.71,0l.2-.2a.49.49,0,0,0,.15-.36V14c4.72,0,9.5,2,10,4.59a.5.5,0,0,0,.49.41h.1a.46.46,0,0,0,.32-.13.5.5,0,0,0,.12-.33A11.22,11.22,0,0,0,13,8Z',
  send:
    'M21,3.92,15,20.67a.5.5,0,0,1-.47.33h-.16a.51.51,0,0,1-.46-.29l-2.36-5a2,2,0,0,1,.34-2.21l3-3.28a.5.5,0,0,0,0-.69l-.38-.38a.5.5,0,0,0-.69,0l-3.28,3a2,2,0,0,1-2.21.34l-5-2.36A.51.51,0,0,1,3,9.67V9.51A.5.5,0,0,1,3.33,9L20.08,3a.5.5,0,0,1,.52.11l.26.26A.5.5,0,0,1,21,3.92Z',
  send_outlined:
    'M20.86,3.4l-.26-.26A.5.5,0,0,0,20.25,3a.47.47,0,0,0-.17,0L3.33,9A.5.5,0,0,0,3,9.51v.16a.51.51,0,0,0,.29.46l7.19,3.39,3.39,7.19a.51.51,0,0,0,.46.29h.16a.5.5,0,0,0,.47-.33L21,3.92A.5.5,0,0,0,20.86,3.4ZM14.23,16.78,13,14.12a2,2,0,0,1,.34-2.21l1.56-1.69a.5.5,0,0,0,0-.69l-.38-.38a.5.5,0,0,0-.69,0l-1.69,1.56A2,2,0,0,1,9.88,11L7.22,9.77,18.15,5.85Z',
  share_message:
    'M22,6a4,4,0,0,0-4-4H6A4,4,0,0,0,2,6V21.5a.5.5,0,0,0,.5.5.49.49,0,0,0,.35-.15l3-3A3,3,0,0,1,8,18H18a4,4,0,0,0,4-4Zm-4.15,4.35-3.79,3.79a.5.5,0,0,1-.71,0l-.2-.2a.49.49,0,0,1-.15-.36V11.82c-3,0-6,1.28-6.35,2.92a.31.31,0,0,1-.31.26H6.28a.25.25,0,0,1-.2-.09.28.28,0,0,1-.08-.2A7.14,7.14,0,0,1,13,8V6.42a.49.49,0,0,1,.15-.36l.2-.2a.5.5,0,0,1,.71,0l3.79,3.79A.48.48,0,0,1,17.85,10.35Z',
  share_screen:
    'M23.5,18H22V5a1,1,0,0,0-1-1H3A1,1,0,0,0,2,5V18H.5a.5.5,0,0,0-.5.5V19a1,1,0,0,0,1,1H23a1,1,0,0,0,1-1v-.5A.5.5,0,0,0,23.5,18Zm-5.65-6.65-3.79,3.79a.5.5,0,0,1-.71,0l-.2-.2a.49.49,0,0,1-.15-.36V12.82c-3,0-6,1.28-6.35,2.92a.31.31,0,0,1-.31.26H6.28a.25.25,0,0,1-.2-.09.28.28,0,0,1-.08-.2A7.14,7.14,0,0,1,13,9V7.42a.49.49,0,0,1,.15-.36l.2-.2a.5.5,0,0,1,.71,0l3.79,3.79A.48.48,0,0,1,17.85,11.35Z',
  sms:
    'M18,2H6A4,4,0,0,0,2,6V21.5a.5.5,0,0,0,.5.5.49.49,0,0,0,.35-.15l3-3A3,3,0,0,1,8,18H18a4,4,0,0,0,4-4V6A4,4,0,0,0,18,2ZM9,10.5a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5v-1A.5.5,0,0,1,7.5,9h1a.5.5,0,0,1,.5.5Zm4,0a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5Zm4,0a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5Z',
  sms_active:
    'M18,2H6A4,4,0,0,0,2,6V21.5a.5.5,0,0,0,.5.5.49.49,0,0,0,.35-.15l3-3A3,3,0,0,1,8,18H18a4,4,0,0,0,4-4V6A4,4,0,0,0,18,2ZM9,10.5a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5v-1A.5.5,0,0,1,7.5,9h1a.5.5,0,0,1,.5.5Zm4-1a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5Zm4,1a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5Z',
  sms_active_outlined:
    'M18,2H6A4,4,0,0,0,2,6V21.5a.5.5,0,0,0,.5.5.49.49,0,0,0,.35-.15l3-3A3,3,0,0,1,8,18H18a4,4,0,0,0,4-4V6A4,4,0,0,0,18,2Zm0,14H8a5,5,0,0,0-3.54,1.46L4,17.88V6A2,2,0,0,1,6,4H18a2,2,0,0,1,2,2v8A2,2,0,0,1,18,16ZM7.5,11h1a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,8.5,9h-1a.5.5,0,0,0-.5.5v1A.5.5,0,0,0,7.5,11Zm4-1h1a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5v1A.5.5,0,0,0,11.5,10Zm4,1h1a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5v1A.5.5,0,0,0,15.5,11Z',
  sms_chat:
    'M15,16a4,4,0,0,0,4-4V6a4,4,0,0,0-4-4H5A4,4,0,0,0,1,6V18.5a.5.5,0,0,0,.5.5.49.49,0,0,0,.35-.15l2-2A3,3,0,0,1,6,16ZM13,8.5a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5v1a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5Zm-4,0A.5.5,0,0,1,9.5,8h1a.5.5,0,0,1,.5.5v1a.5.5,0,0,1-.5.5h-1A.5.5,0,0,1,9,9.5Zm-4,1v-1A.5.5,0,0,1,5.5,8h1a.5.5,0,0,1,.5.5v1a.5.5,0,0,1-.5.5h-1A.5.5,0,0,1,5,9.5ZM21,6v8a4,4,0,0,1-4,4H5a2,2,0,0,0,2,2H18a3,3,0,0,1,2.13.88l2,2a.49.49,0,0,0,.35.15.5.5,0,0,0,.5-.5V8A2,2,0,0,0,21,6Z',
  sms_chat_outlined:
    'M15,16a4,4,0,0,0,4-4V6a4,4,0,0,0-4-4H5A4,4,0,0,0,1,6V18.5a.5.5,0,0,0,.5.5.49.49,0,0,0,.35-.15l2-2A3,3,0,0,1,6,16ZM3,15V6A2,2,0,0,1,5,4H15a2,2,0,0,1,2,2v6a2,2,0,0,1-2,2H6A5,5,0,0,0,3,15Zm10.5-5h1a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5v1A.5.5,0,0,0,13.5,10Zm-4,0h1a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5v1A.5.5,0,0,0,9.5,10ZM7,9.5v-1A.5.5,0,0,0,6.5,8h-1a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h1A.5.5,0,0,0,7,9.5ZM21,6v8a4,4,0,0,1-4,4H5a2,2,0,0,0,2,2H18a3,3,0,0,1,2.13.88l2,2a.49.49,0,0,0,.35.15.5.5,0,0,0,.5-.5V8A2,2,0,0,0,21,6Z',
  sms_outlined:
    'M18,2H6A4,4,0,0,0,2,6V21.5a.5.5,0,0,0,.5.5.49.49,0,0,0,.35-.15l3-3A3,3,0,0,1,8,18H18a4,4,0,0,0,4-4V6A4,4,0,0,0,18,2Zm0,14H8a5,5,0,0,0-3.54,1.46L4,17.88V6A2,2,0,0,1,6,4H18a2,2,0,0,1,2,2v8A2,2,0,0,1,18,16ZM7.5,11h1a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,8.5,9h-1a.5.5,0,0,0-.5.5v1A.5.5,0,0,0,7.5,11Zm4,0h1a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5v1A.5.5,0,0,0,11.5,11Zm4,0h1a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5v1A.5.5,0,0,0,15.5,11Z',
  swap:
    'M5,7a4,4,0,0,1,8,0V17a2,2,0,0,0,4,0V6H15a.5.5,0,0,1-.35-.85l3-3a.51.51,0,0,1,.71,0l3,3A.5.5,0,0,1,21,6H19V17a4,4,0,0,1-8,0V7A2,2,0,0,0,7,7V18H9a.5.5,0,0,1,.36.85l-3,3a.51.51,0,0,1-.71,0l-3-3A.5.5,0,0,1,3,18H5Z',
  swap_horizontal:
    'M21.86,9.36l-3,3A.5.5,0,0,1,18,12V10H10.5a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5H18V6a.5.5,0,0,1,.85-.36l3,3A.51.51,0,0,1,21.86,9.36ZM13.5,14H6V12a.5.5,0,0,0-.85-.36l-3,3a.51.51,0,0,0,0,.71l3,3A.5.5,0,0,0,6,18V16h7.5a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,13.5,14Z',
  swap_vs:
    'M7,19a4,4,0,0,1,0-8H17a2,2,0,0,0,0-4H6V9a.5.5,0,0,1-.85.35l-3-3a.51.51,0,0,1,0-.71l3-3A.5.5,0,0,1,6,3V5H17a4,4,0,0,1,0,8H7a2,2,0,0,0,0,4H18V15a.5.5,0,0,1,.85-.36l3,3a.51.51,0,0,1,0,.71l-3,3A.5.5,0,0,1,18,21V19Z',
  unsubscribe:
    'M22,9a5,5,0,0,0-5-5,4.91,4.91,0,0,0-3,1H4A2,2,0,0,0,2,7V18a2,2,0,0,0,2,2H19a2,2,0,0,0,2-2V12h0A4.91,4.91,0,0,0,22,9ZM4,7h8.42a4.94,4.94,0,0,0,1.43,5.85l-1.7,1.2a1.14,1.14,0,0,1-1.3,0L4,9.25ZM19,18H4V11.39l5.85,4.09a2.88,2.88,0,0,0,3.3,0l2.44-1.71A4.73,4.73,0,0,0,17,14a4.94,4.94,0,0,0,2-.42Zm1-8.62a.5.5,0,0,1-.5.5h-5a.5.5,0,0,1-.5-.5V8.62a.5.5,0,0,1,.5-.5h5a.5.5,0,0,1,.5.5Z',
  voicemail:
    'M18,7a5,5,0,0,0-5,5,4.92,4.92,0,0,0,1,3H10a4.92,4.92,0,0,0,1-3,5,5,0,1,0-5,5H18A5,5,0,0,0,18,7ZM3,12a3,3,0,1,1,3,3A3,3,0,0,1,3,12Zm15,3a3,3,0,1,1,3-3A3,3,0,0,1,18,15Z',
  voice_chat:
    'M22,6a4,4,0,0,0-4-4H6A4,4,0,0,0,2,6V21.5a.5.5,0,0,0,.5.5.49.49,0,0,0,.35-.15l3-3A3,3,0,0,1,8,18H18a4,4,0,0,0,4-4Zm-3,7.5a.5.5,0,0,1-.5.5h-.09a1,1,0,0,1-.7-.29L15,11v2.5A1.5,1.5,0,0,1,13.5,15h-7A1.5,1.5,0,0,1,5,13.5v-7A1.5,1.5,0,0,1,6.5,5h7A1.5,1.5,0,0,1,15,6.5V9l2.71-2.71a1,1,0,0,1,.7-.29h.09a.5.5,0,0,1,.5.5Z',
  airpods:
    'M11,19.5V20a1,1,0,0,1-1,1H9a1,1,0,0,1-1-1v-.5a.5.5,0,0,1,.5-.5h2A.5.5,0,0,1,11,19.5ZM6.5,3A4.72,4.72,0,0,0,3.33,4.17,1,1,0,0,0,3,4.91V9.09a1,1,0,0,0,.34.75A4.76,4.76,0,0,0,6.5,11H8V16.5a.5.5,0,0,0,.5.5h2a.5.5,0,0,0,.5-.5V9C11,6,9.89,3,6.5,3Zm9,16h-2a.5.5,0,0,0-.5.5V20a1,1,0,0,0,1,1h1a1,1,0,0,0,1-1v-.5A.5.5,0,0,0,15.5,19ZM20.67,4.17A4.72,4.72,0,0,0,17.5,3C14.11,3,13,6,13,9v7.5a.5.5,0,0,0,.5.5h2a.5.5,0,0,0,.5-.5V11h1.5a4.76,4.76,0,0,0,3.16-1.15A1,1,0,0,0,21,9.09V4.91A1,1,0,0,0,20.67,4.17Z',
  android_phone:
    'M16,1H8A3,3,0,0,0,5,4V20a3,3,0,0,0,3,3h8a3,3,0,0,0,3-3V4A3,3,0,0,0,16,1Zm0,3h0a1,1,0,0,1,1,1V19a1,1,0,0,1-1,1H8a1,1,0,0,1-1-1V5A1,1,0,0,1,8,4Z',
  android_phones:
    'M14,1H6A3,3,0,0,0,3,4V20a3,3,0,0,0,3,3h8a3,3,0,0,0,3-3V4A3,3,0,0,0,14,1Zm1,18a1,1,0,0,1-1,1H6a1,1,0,0,1-1-1V5A1,1,0,0,1,6,4h8a1,1,0,0,1,1,1ZM20,4H19V20h1a1,1,0,0,0,1-1V5A1,1,0,0,0,20,4Z',
  android_phone_vs:
    'M16,1H8A3,3,0,0,0,5,4V20a3,3,0,0,0,3,3h8a3,3,0,0,0,3-3V4A3,3,0,0,0,16,1Zm1,19a1,1,0,0,1-1,1H8a1,1,0,0,1-1-1V4A1,1,0,0,1,8,3H9.72a1,1,0,0,1,1,.76l.12.48a1,1,0,0,0,1,.76h.44a1,1,0,0,0,1-.76l.12-.48a1,1,0,0,1,1-.76H16a1,1,0,0,1,1,1Z',
  apple_watch:
    'M15.68,5.08l-.54-3.24a1,1,0,0,0-1-.84H9.85a1,1,0,0,0-1,.84L8.32,5.08A3,3,0,0,0,6,8v8a3,3,0,0,0,2.41,2.94L9,22.16a1,1,0,0,0,1,.84h4.3a1,1,0,0,0,1-.84l.54-3.27A3,3,0,0,0,18,16V8A3,3,0,0,0,15.68,5.08ZM16,16a1,1,0,0,1-1,1H9a1,1,0,0,1-1-1V8A1,1,0,0,1,9,7h6a1,1,0,0,1,1,1Z',
  batery_vertical_3_quarters:
    'M15,4H14V3a1,1,0,0,0-1-1H11a1,1,0,0,0-1,1V4H9A2,2,0,0,0,7,6V20a2,2,0,0,0,2,2h6a2,2,0,0,0,2-2V6A2,2,0,0,0,15,4Zm0,6H9V6h6Z',
  battery_horizontal:
    'M4,17a2,2,0,0,1-2-2V9A2,2,0,0,1,4,7H18a2,2,0,0,1,2,2v1h1a1,1,0,0,1,1,1v2a1,1,0,0,1-1,1H20v1a2,2,0,0,1-2,2Z',
  battery_horizontal_3_quarters:
    'M20,15V14h1a1,1,0,0,0,1-1V11a1,1,0,0,0-1-1H20V9a2,2,0,0,0-2-2H4A2,2,0,0,0,2,9v6a2,2,0,0,0,2,2H18A2,2,0,0,0,20,15Zm-6,0V9h4v6Z',
  battery_horizontal_half:
    'M20,15V14h1a1,1,0,0,0,1-1V11a1,1,0,0,0-1-1H20V9a2,2,0,0,0-2-2H4A2,2,0,0,0,2,9v6a2,2,0,0,0,2,2H18A2,2,0,0,0,20,15Zm-9,0V9h7v6Z',
  battery_horizontal_outlined:
    'M20,15V14h1a1,1,0,0,0,1-1V11a1,1,0,0,0-1-1H20V9a2,2,0,0,0-2-2H4A2,2,0,0,0,2,9v6a2,2,0,0,0,2,2H18A2,2,0,0,0,20,15ZM4,15V9H18v6Z',
  battery_horizontal_quarter:
    'M20,15V14h1a1,1,0,0,0,1-1V11a1,1,0,0,0-1-1H20V9a2,2,0,0,0-2-2H4A2,2,0,0,0,2,9v6a2,2,0,0,0,2,2H18A2,2,0,0,0,20,15ZM8,15V9H18v6Z',
  battery_vertical:
    'M17,6V20a2,2,0,0,1-2,2H9a2,2,0,0,1-2-2V6A2,2,0,0,1,9,4h1V3a1,1,0,0,1,1-1h2a1,1,0,0,1,1,1V4h1A2,2,0,0,1,17,6Z',
  battery_vertical_half:
    'M15,4H14V3a1,1,0,0,0-1-1H11a1,1,0,0,0-1,1V4H9A2,2,0,0,0,7,6V20a2,2,0,0,0,2,2h6a2,2,0,0,0,2-2V6A2,2,0,0,0,15,4Zm0,9H9V6h6Z',
  battery_vertical_outlined:
    'M15,4H14V3a1,1,0,0,0-1-1H11a1,1,0,0,0-1,1V4H9A2,2,0,0,0,7,6V20a2,2,0,0,0,2,2h6a2,2,0,0,0,2-2V6A2,2,0,0,0,15,4Zm0,16H9V6h6Z',
  battery_vertical_quarter:
    'M15,4H14V3a1,1,0,0,0-1-1H11a1,1,0,0,0-1,1V4H9A2,2,0,0,0,7,6V20a2,2,0,0,0,2,2h6a2,2,0,0,0,2-2V6A2,2,0,0,0,15,4Zm0,12H9V6h6Z',
  calculator:
    'M20,4a2,2,0,0,0-2-2H6A2,2,0,0,0,4,4V20a2,2,0,0,0,2,2H18a2,2,0,0,0,2-2ZM9,18.5a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5Zm0-4a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5Zm0-4a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5v-1A.5.5,0,0,1,7.5,9h1a.5.5,0,0,1,.5.5Zm4,8a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5Zm0-4a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5Zm0-4a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5Zm4,8a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5v-5a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5Zm0-8a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5Zm0-4a.5.5,0,0,1-.5.5h-9A.5.5,0,0,1,7,6.5v-2A.5.5,0,0,1,7.5,4h9a.5.5,0,0,1,.5.5Z',
  charging:
    'M15,4H14V3a1,1,0,0,0-1-1H11a1,1,0,0,0-1,1V4H9A2,2,0,0,0,7,6V20a2,2,0,0,0,2,2h6a2,2,0,0,0,2-2V6A2,2,0,0,0,15,4Zm0,7.68a.59.59,0,0,1-.07.26l-3,4.84a.44.44,0,0,1-.39.22H11.5a.48.48,0,0,1-.35-.16.47.47,0,0,1-.1-.36l.34-2.41H9.5a.5.5,0,0,1-.5-.5v-.16a.59.59,0,0,1,.07-.26l3-4.91A.51.51,0,0,1,12.52,8a.48.48,0,0,1,.35.16.47.47,0,0,1,.1.36L12.61,11H14.5a.5.5,0,0,1,.5.5Z',
  devices_1:
    'M22,10v8H18V10h4m0-2H18a2,2,0,0,0-2,2v8a2,2,0,0,0,2,2h4a2,2,0,0,0,2-2V10a2,2,0,0,0-2-2ZM4,6V17H14v3H1a1,1,0,0,1-1-1v-.5A.5.5,0,0,1,.5,18H2V5A1,1,0,0,1,3,4H21a1,1,0,0,1,1,1V6Z',
  devices_2:
    'M22,10H18a2,2,0,0,0-2,2v8a2,2,0,0,0,2,2h4a2,2,0,0,0,2-2V12A2,2,0,0,0,22,10Zm0,10H18V12h4ZM23,2H1A1,1,0,0,0,0,3V17a1,1,0,0,0,1,1H9v2H7.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5H14V16H2V4H22V8h2V3A1,1,0,0,0,23,2Z',
  devices_apple:
    'M22,10H18a2,2,0,0,0-2,2v8a2,2,0,0,0,2,2h4a2,2,0,0,0,2-2V12A2,2,0,0,0,22,10Zm0,10H18V12h4Zm.5-18H1.5A1.5,1.5,0,0,0,0,3.5v13A1.5,1.5,0,0,0,1.5,18H10l-.45.89A2,2,0,0,1,7.74,20H7.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5H14V14H2V4H22V8h2V3.5A1.5,1.5,0,0,0,22.5,2Z',
  disc:
    'M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,17.47a.5.5,0,0,1-.52.5A8,8,0,0,1,6.73,18a.5.5,0,0,1,0-.73l2.13-2.13a.51.51,0,0,1,.68,0,4.12,4.12,0,0,0,2,.84.5.5,0,0,1,.44.51ZM12,14a2,2,0,1,1,2-2A2,2,0,0,1,12,14Zm5.29-7.29L15.16,8.84a.51.51,0,0,1-.68,0,4.12,4.12,0,0,0-2-.84A.5.5,0,0,1,12,7.51v-3a.5.5,0,0,1,.52-.5,8,8,0,0,1,4.75,2A.5.5,0,0,1,17.29,6.71Z',
  hdmi:
    'M19,8V3a1,1,0,0,0-1-1H6A1,1,0,0,0,5,3V8A1,1,0,0,0,4,9v5.34a4,4,0,0,0,1.17,2.83l.66.66a3.69,3.69,0,0,0,.78.6A2.65,2.65,0,0,1,8,20.69V21a1,1,0,0,0,1,1h6a1,1,0,0,0,1-1v-.31a2.65,2.65,0,0,1,1.39-2.26,3.69,3.69,0,0,0,.78-.6l.66-.66A4,4,0,0,0,20,14.34V9A1,1,0,0,0,19,8ZM7,4H17V8H15V6.5a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5V8H11V6.5a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5V8H7Z',
  hdmi_outlined:
    'M19,8V3a1,1,0,0,0-1-1H6A1,1,0,0,0,5,3V8A1,1,0,0,0,4,9v5.34a4,4,0,0,0,1.17,2.83l.66.66a3.69,3.69,0,0,0,.78.6A2.65,2.65,0,0,1,8,20.69V21a1,1,0,0,0,1,1h6a1,1,0,0,0,1-1v-.31a2.65,2.65,0,0,1,1.39-2.26,3.69,3.69,0,0,0,.78-.6l.66-.66A4,4,0,0,0,20,14.34V9A1,1,0,0,0,19,8ZM7,4H17V8H15V6.5a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5V8H11V6.5a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5V8H7ZM18,14.34a2,2,0,0,1-.59,1.42l-.65.65a1.85,1.85,0,0,1-.39.3A4.71,4.71,0,0,0,14.05,20H10a4.71,4.71,0,0,0-2.32-3.29,1.85,1.85,0,0,1-.39-.3l-.65-.65A2,2,0,0,1,6,14.34V10H18Z',
  headphones:
    'M21,15v4a2,2,0,0,1-2,2H17a1,1,0,0,1-1-1V14a1,1,0,0,1,1-1V10A5,5,0,0,0,7,10v3a1,1,0,0,1,1,1v6a1,1,0,0,1-1,1H5a2,2,0,0,1-2-2V15a2,2,0,0,1,2-2V10a7,7,0,0,1,14,0v3A2,2,0,0,1,21,15Z',
  imac:
    'M22.5,2H1.5A1.5,1.5,0,0,0,0,3.5v13A1.5,1.5,0,0,0,1.5,18H10l-.45.89A2,2,0,0,1,7.76,20H7.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h9a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5h-.26a2,2,0,0,1-1.79-1.11L14,18h8.5A1.5,1.5,0,0,0,24,16.5V3.5A1.5,1.5,0,0,0,22.5,2ZM22,14H2V4H22Z',
  iphone:
    'M16,1H8A3,3,0,0,0,5,4V20a3,3,0,0,0,3,3h8a3,3,0,0,0,3-3V4A3,3,0,0,0,16,1Zm0,20H8a1,1,0,0,1-1-1V4A1,1,0,0,1,8,3H8A1,1,0,0,1,9,4H9a1,1,0,0,0,1,1h4a1,1,0,0,0,1-1h0a1,1,0,0,1,1-1h0a1,1,0,0,1,1,1V20A1,1,0,0,1,16,21Z',
  iphones:
    'M14,1H6A3,3,0,0,0,3,4V20a3,3,0,0,0,3,3h8a3,3,0,0,0,3-3V4A3,3,0,0,0,14,1Zm1,19a1,1,0,0,1-1,1H6a1,1,0,0,1-1-1V4A1,1,0,0,1,7,4,1,1,0,0,0,8,5h4a1,1,0,0,0,1-1,1,1,0,0,1,2,0ZM20,4H19V20h1a1,1,0,0,0,1-1V5A1,1,0,0,0,20,4Z',
  joystick:
    'M22.4,9.61l-.83-3.34A3,3,0,0,0,18.66,4H15.81a.51.51,0,0,0-.45.28l-.22.44a.51.51,0,0,1-.45.28H9.31a.51.51,0,0,1-.45-.28l-.22-.44A.51.51,0,0,0,8.19,4H5.34A3,3,0,0,0,2.43,6.27L1.6,9.61A19.88,19.88,0,0,0,1,14.46V18a2,2,0,0,0,2,2h.63a3,3,0,0,0,3-2.51l.27-1.65a1,1,0,0,1,1-.84h8.3a1,1,0,0,1,1,.84l.27,1.65a3,3,0,0,0,3,2.51H21a2,2,0,0,0,2-2V14.46A19.88,19.88,0,0,0,22.4,9.61ZM6.5,12A2.5,2.5,0,1,1,9,9.5,2.5,2.5,0,0,1,6.5,12ZM21,10.38a.5.5,0,0,1-.5.5H18.88V12.5a.5.5,0,0,1-.5.5h-.76a.5.5,0,0,1-.5-.5V10.88H15.5a.5.5,0,0,1-.5-.5V9.62a.5.5,0,0,1,.5-.5h1.62V7.5a.5.5,0,0,1,.5-.5h.76a.5.5,0,0,1,.5.5V9.12H20.5a.5.5,0,0,1,.5.5Z',
  keyboard:
    'M3,5A2,2,0,0,0,1,7V17a2,2,0,0,0,2,2H21a2,2,0,0,0,2-2V7a2,2,0,0,0-2-2Zm8,2.5a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5v1a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5Zm0,4a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5v1a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5Zm-4-4A.5.5,0,0,1,7.5,7h1a.5.5,0,0,1,.5.5v1a.5.5,0,0,1-.5.5h-1A.5.5,0,0,1,7,8.5Zm0,4a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5v1a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5Zm-2,1a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5Zm0-4a.5.5,0,0,1-.5.5h-1A.5.5,0,0,1,3,8.5v-1A.5.5,0,0,1,3.5,7h1a.5.5,0,0,1,.5.5Zm12,8a.5.5,0,0,1-.5.5h-9a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h9a.5.5,0,0,1,.5.5Zm0-4a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5Zm0-4a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5Zm4,4a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5Zm0-4a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5Z',
  keyboard_hide:
    'M21,2H3A2,2,0,0,0,1,4V14a2,2,0,0,0,2,2H21a2,2,0,0,0,2-2V4A2,2,0,0,0,21,2ZM11,4.5a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5v1a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5Zm0,4a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5v1a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5Zm-4-4A.5.5,0,0,1,7.5,4h1a.5.5,0,0,1,.5.5v1a.5.5,0,0,1-.5.5h-1A.5.5,0,0,1,7,5.5Zm0,4A.5.5,0,0,1,7.5,8h1a.5.5,0,0,1,.5.5v1a.5.5,0,0,1-.5.5h-1A.5.5,0,0,1,7,9.5Zm-2,1a.5.5,0,0,1-.5.5h-1A.5.5,0,0,1,3,9.5v-1A.5.5,0,0,1,3.5,8h1a.5.5,0,0,1,.5.5Zm0-4a.5.5,0,0,1-.5.5h-1A.5.5,0,0,1,3,5.5v-1A.5.5,0,0,1,3.5,4h1a.5.5,0,0,1,.5.5Zm12,8a.5.5,0,0,1-.5.5h-9a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h9a.5.5,0,0,1,.5.5Zm0-4a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5Zm0-4a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5Zm4,4a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5Zm0-4a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5ZM14.94,18.15a.47.47,0,0,0-.35-.15H9.41a.47.47,0,0,0-.35.15l-.21.2a.51.51,0,0,0,0,.71l2.8,2.79a.48.48,0,0,0,.7,0l2.8-2.79a.5.5,0,0,0,0-.71Z',
  keyboard_vs:
    'M21,7H13V6h.5A1.5,1.5,0,0,0,15,4.5v-1a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5V4h-.5A1.5,1.5,0,0,0,11,5.5V7H3A2,2,0,0,0,1,9V19a2,2,0,0,0,2,2H21a2,2,0,0,0,2-2V9A2,2,0,0,0,21,7ZM11,9.5a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5v1a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5Zm0,4a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5v1a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5Zm-4-4A.5.5,0,0,1,7.5,9h1a.5.5,0,0,1,.5.5v1a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5Zm0,4a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5v1a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5Zm-2,1a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5Zm0-4a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5v-1A.5.5,0,0,1,3.5,9h1a.5.5,0,0,1,.5.5Zm12,8a.5.5,0,0,1-.5.5h-9a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h9a.5.5,0,0,1,.5.5Zm0-4a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5Zm0-4a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5Zm4,4a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5Zm0-4a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5Z',
  laptop:
    'M23.5,18H22V5a1,1,0,0,0-1-1H3A1,1,0,0,0,2,5V18H.5a.5.5,0,0,0-.5.5V19a1,1,0,0,0,1,1H23a1,1,0,0,0,1-1v-.5A.5.5,0,0,0,23.5,18ZM4,6H20V17H4Z',
  light_battery:
    'M17,10.5a.5.5,0,0,0-.5-.5h-9a.5.5,0,0,0-.5.5v1.67a2,2,0,0,0,.59,1.42l1.12,1.12a1,1,0,0,1,.29.7V22.5a.5.5,0,0,0,.5.5h5a.5.5,0,0,0,.5-.5V15.41a1,1,0,0,1,.29-.7l1.12-1.12A2,2,0,0,0,17,12.17Zm-4,8a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5ZM11.5,5h1a.5.5,0,0,0,.5-.5v-3a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5v3A.5.5,0,0,0,11.5,5Zm8.35-.15-.7-.7a.5.5,0,0,0-.71,0L16.32,6.27a.48.48,0,0,0,0,.7l.71.71a.48.48,0,0,0,.7,0l2.12-2.12A.5.5,0,0,0,19.85,4.85ZM7.68,6.27,5.56,4.15a.5.5,0,0,0-.71,0l-.7.7a.5.5,0,0,0,0,.71L6.27,7.68a.48.48,0,0,0,.7,0L7.68,7A.48.48,0,0,0,7.68,6.27Z',
  microphone:
    'M21,8a5,5,0,0,1-4.25,4.94,1,1,0,0,1-.85-.28L11.34,8.1a1,1,0,0,1-.28-.85A5,5,0,0,1,21,8Zm-7.25,5.08a.51.51,0,0,1,0,.71l-2.5,2.49a5.82,5.82,0,0,1-1.56,1.13l-1.63.81a.5.5,0,0,1-.58-.09l-.1-.1L4.56,20.85a.5.5,0,0,1-.71,0l-.7-.7a.5.5,0,0,1,0-.71L6,16.62l-.1-.1a.5.5,0,0,1-.09-.58l.81-1.63a5.82,5.82,0,0,1,1.13-1.56l2.49-2.5a.51.51,0,0,1,.71,0Zm-2.9.77-.7-.7a.5.5,0,0,0-.71,0l-.71.7a.51.51,0,0,0,0,.71l.71.71a.51.51,0,0,0,.71,0l.7-.71A.5.5,0,0,0,10.85,13.85Z',
  monitor:
    'M23,2H1A1,1,0,0,0,0,3V17a1,1,0,0,0,1,1H9v2H7.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h9a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5H15V18h8a1,1,0,0,0,1-1V3A1,1,0,0,0,23,2ZM22,16H2V4H22Z',
  mouse:
    'M12,2A7,7,0,0,0,5,9v6a7,7,0,0,0,14,0V9A7,7,0,0,0,12,2Zm1,8.5a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5v-3a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5Z',
  mouse_outlined:
    'M12,2A7,7,0,0,0,5,9v6a7,7,0,0,0,14,0V9A7,7,0,0,0,12,2Zm5,13A5,5,0,0,1,7,15V9A5,5,0,0,1,17,9ZM12.5,7h-1a.5.5,0,0,0-.5.5v3a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5v-3A.5.5,0,0,0,12.5,7Z',
  mouse_pad:
    'M13,6.09V5h.5A1.5,1.5,0,0,0,15,3.5v-1a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5V3h-.5A1.5,1.5,0,0,0,11,4.5V6.09A6,6,0,0,0,6,12v4a6,6,0,0,0,12,0V12A6,6,0,0,0,13,6.09Zm0,6.41a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5v-2a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5Z',
  mouse_pad_outlined:
    'M13,6.09V5h.5A1.5,1.5,0,0,0,15,3.5v-1a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5V3h-.5A1.5,1.5,0,0,0,11,4.5V6.09A6,6,0,0,0,6,12v4a6,6,0,0,0,12,0V12A6,6,0,0,0,13,6.09ZM16,16a4,4,0,0,1-8,0V12a4,4,0,0,1,8,0Zm-3.5-6h-1a.5.5,0,0,0-.5.5v2a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5v-2A.5.5,0,0,0,12.5,10Z',
  no_sim_card:
    'M20.85,20.32a.48.48,0,0,0,0-.7L4.38,3.15a.48.48,0,0,0-.7,0l-.53.53a.48.48,0,0,0,0,.7L4.88,6.12,3.59,7.41A2,2,0,0,0,3,8.83V20a2,2,0,0,0,2,2H17a2,2,0,0,0,2-1.78l.64.63a.48.48,0,0,0,.7,0ZM8,18.5a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5v-2a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5Zm0-5a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5v-2a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5Zm4,5a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5ZM11.5,15h-1a.5.5,0,0,1-.5-.5v-3a.46.46,0,0,1,.05-.21l1.95,2V14.5A.5.5,0,0,1,11.5,15ZM16,18.5a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5v-2a.5.5,0,0,1,.5-.5h.26L16,17.24Zm3-3.21V4a2,2,0,0,0-2-2H9.83a2,2,0,0,0-1.42.59l-1,1.05Z',
  photo_camera:
    'M22,9a2,2,0,0,0-2-2H18.83a2,2,0,0,1-1.42-.59L15.88,4.88A3,3,0,0,0,13.76,4H10.24a3,3,0,0,0-2.12.88L6.59,6.41A2,2,0,0,1,5.17,7H4A2,2,0,0,0,2,9v9a2,2,0,0,0,2,2H20a2,2,0,0,0,2-2ZM12,17a4,4,0,1,1,4-4A4,4,0,0,1,12,17Z',
  plug:
    'M20,8.5v1a.5.5,0,0,1-.5.5H19v2.34a4,4,0,0,1-1.17,2.83l-1.95,2A3,3,0,0,1,14,18V21.5a.5.5,0,0,1-.5.5h-3a.5.5,0,0,1-.5-.5V18a3,3,0,0,1-1.88-.86l-1.95-2A4,4,0,0,1,5,12.34V10H4.5A.5.5,0,0,1,4,9.5v-1A.5.5,0,0,1,4.5,8H7V2.5A.5.5,0,0,1,7.5,2h1a.5.5,0,0,1,.5.5V8h6V2.5a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5V8h2.5A.5.5,0,0,1,20,8.5Z',
  plug_outlined:
    'M19.5,8H17V2.5a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5V8H9V2.5A.5.5,0,0,0,8.5,2h-1a.5.5,0,0,0-.5.5V8H4.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5H5v2.34a4,4,0,0,0,1.17,2.83l1.95,2A3,3,0,0,0,10,18V21.5a.5.5,0,0,0,.5.5h3a.5.5,0,0,0,.5-.5V18a3,3,0,0,0,1.88-.86l1.95-2A4,4,0,0,0,19,12.34V10h.5a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,19.5,8ZM17,12.34a2,2,0,0,1-.59,1.42l-1.8,1.8a1.5,1.5,0,0,1-1.06.44h-3.1a1.5,1.5,0,0,1-1.06-.44l-1.8-1.8A2,2,0,0,1,7,12.34V10H17Z',
  radio:
    'M22,7H8l7.78-4.63a.5.5,0,0,0,.17-.68l-.25-.44A.51.51,0,0,0,15,1.06L4.4,7H2A1,1,0,0,0,1,8V20a1,1,0,0,0,1,1H22a1,1,0,0,0,1-1V8A1,1,0,0,0,22,7ZM8,19a5,5,0,1,1,5-5A5,5,0,0,1,8,19Zm12-.5a.5.5,0,0,1-.5.5h-3a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h3a.5.5,0,0,1,.5.5Zm0-4a.5.5,0,0,1-.5.5h-3a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h3a.5.5,0,0,1,.5.5Zm0-4a.5.5,0,0,1-.5.5h-3a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h3a.5.5,0,0,1,.5.5ZM8,12a2,2,0,1,0,2,2A2,2,0,0,0,8,12Z',
  remote_controller:
    'M15,1H9A2,2,0,0,0,7,3V21a2,2,0,0,0,2,2h6a2,2,0,0,0,2-2V3A2,2,0,0,0,15,1ZM11,20.5a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5Zm0-4a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5Zm4,4a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5Zm0-4a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5Zm0-4a.5.5,0,0,1-.5.5h-5a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h5a.5.5,0,0,1,.5.5ZM12,9a3,3,0,1,1,3-3A3,3,0,0,1,12,9Z',
  router:
    'M21,12H19.85l-.08-5.51a.51.51,0,0,0-.5-.49h-.55a.51.51,0,0,0-.5.49L18.14,12H5.9L5.77,2.49A.51.51,0,0,0,5.27,2H4.72a.51.51,0,0,0-.5.49L4.09,12H3a1,1,0,0,0-1,1v6a1,1,0,0,0,1,1H21a1,1,0,0,0,1-1V13A1,1,0,0,0,21,12ZM10,16.5a.5.5,0,0,1-.5.5h-5a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h5a.5.5,0,0,1,.5.5Zm10,0a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5Z',
  sim_card:
    'M18,2H10.83a2,2,0,0,0-1.42.59L4.59,7.41A2,2,0,0,0,4,8.83V20a2,2,0,0,0,2,2H18a2,2,0,0,0,2-2V4A2,2,0,0,0,18,2ZM9,18.5a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5v-2a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5Zm0-5a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5v-2a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5Zm4,5a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5Zm0-4a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5v-3a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5Zm4,4a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5v-2a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5Zm0-5a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5v-2a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5Z',
  speaker:
    'M17,2H7A2,2,0,0,0,5,4V20a2,2,0,0,0,2,2H17a2,2,0,0,0,2-2V4A2,2,0,0,0,17,2ZM12,4a2,2,0,1,1-2,2A2,2,0,0,1,12,4Zm0,16a5,5,0,1,1,5-5A5,5,0,0,1,12,20Zm0-7a2,2,0,1,0,2,2A2,2,0,0,0,12,13Z',
  surveillance_camera:
    'M20,3H4A1,1,0,0,0,3,4V9a1,1,0,0,0,1,1H20a1,1,0,0,0,1-1V4A1,1,0,0,0,20,3ZM10,6.5a.5.5,0,0,1-.5.5h-4A.5.5,0,0,1,5,6.5v-1A.5.5,0,0,1,5.5,5h4a.5.5,0,0,1,.5.5ZM5,14a7,7,0,0,0,14,0V12H5Zm7,1a2,2,0,1,1-2,2A2,2,0,0,1,12,15Z',
  tablet:
    'M18,2H6A2,2,0,0,0,4,4V20a2,2,0,0,0,2,2H18a2,2,0,0,0,2-2V4A2,2,0,0,0,18,2Zm0,17H6V4H18Z',
  tablet_phone:
    'M20,7H15a2,2,0,0,0-2,2V19a2,2,0,0,0,2,2h5a2,2,0,0,0,2-2V9A2,2,0,0,0,20,7Zm0,12H15V9h5ZM4,5H17a2,2,0,0,0-2-2H4A2,2,0,0,0,2,5V19a2,2,0,0,0,2,2h7V18H4Z',
  tv_new:
    'M23,3H1A1,1,0,0,0,0,4V18a1,1,0,0,0,1,1H7v1.5a.5.5,0,0,0,.5.5h9a.5.5,0,0,0,.5-.5V19h6a1,1,0,0,0,1-1V4A1,1,0,0,0,23,3ZM22,17H2V5H22Z',
  tv_old:
    'M23.19,4.23a.51.51,0,0,0,0-.71l-.36-.36a.5.5,0,0,0-.7,0l-3.3,3.21-.06-4.88a.51.51,0,0,0-.5-.49h-.52a.51.51,0,0,0-.5.49L17.17,7H3A2,2,0,0,0,1,9V20a2,2,0,0,0,2,2H21a2,2,0,0,0,2-2V9a2,2,0,0,0-2-2h-.51ZM17,19a1,1,0,0,1-1,1H4a1,1,0,0,1-1-1V10A1,1,0,0,1,4,9H16a1,1,0,0,1,1,1Zm4-4.5a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5ZM20.5,9a.5.5,0,0,1,.5.5v1a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5Z',
  usb_c:
    'M16,8V4a2,2,0,0,0-2-2H10A2,2,0,0,0,8,4V8A1,1,0,0,0,7,9v9a1,1,0,0,0,1,1H9v2a1,1,0,0,0,1,1h4a1,1,0,0,0,1-1V19h1a1,1,0,0,0,1-1V9A1,1,0,0,0,16,8ZM10,4h4V8H10Z',
  usb_c_outlined:
    'M16,8V4a2,2,0,0,0-2-2H10A2,2,0,0,0,8,4V8A1,1,0,0,0,7,9v9a1,1,0,0,0,1,1H9v2a1,1,0,0,0,1,1h4a1,1,0,0,0,1-1V19h1a1,1,0,0,0,1-1V9A1,1,0,0,0,16,8ZM10,4h4V8H10Zm5,13H9V10h6Z',
  usb_port:
    'M19,11V3a1,1,0,0,0-1-1H6A1,1,0,0,0,5,3v8a1,1,0,0,0-1,1v5.34a4,4,0,0,0,1.17,2.83l.66.66A4,4,0,0,0,8.66,22h6.68a4,4,0,0,0,2.83-1.17l.66-.66A4,4,0,0,0,20,17.34V12A1,1,0,0,0,19,11ZM7,4H17v7H7Zm3.5,2h-1a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,10.5,6Zm4,0h-1a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,14.5,6Z',
  usb_port_outlined:
    'M19,11V3a1,1,0,0,0-1-1H6A1,1,0,0,0,5,3v8a1,1,0,0,0-1,1v5.34a4,4,0,0,0,1.17,2.83l.66.66A4,4,0,0,0,8.66,22h6.68a4,4,0,0,0,2.83-1.17l.66-.66A4,4,0,0,0,20,17.34V12A1,1,0,0,0,19,11ZM7,4H17v7H7ZM18,17.34a2,2,0,0,1-.59,1.42l-.65.65a2,2,0,0,1-1.42.59H8.66a2,2,0,0,1-1.42-.59l-.65-.65A2,2,0,0,1,6,17.34V13H18ZM10.5,6h-1a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,10.5,6Zm4,0h-1a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,14.5,6Z',
  video_camera:
    'M21.5,7h-.09a1,1,0,0,0-.7.29L18,10V7a2,2,0,0,0-2-2H4A2,2,0,0,0,2,7V17a2,2,0,0,0,2,2H16a2,2,0,0,0,2-2V14l2.71,2.71a1,1,0,0,0,.7.29h.09a.5.5,0,0,0,.5-.5v-9A.5.5,0,0,0,21.5,7Zm-16,3.88a.5.5,0,0,1-.5-.5V9.62a.5.5,0,0,1,.5-.5h4a.5.5,0,0,1,.5.5v.76a.5.5,0,0,1-.5.5Z',
  video_camera_off:
    'M18,14,9,5h7a2,2,0,0,1,2,2v3l2.71-2.71a1,1,0,0,1,.7-.29h.09a.5.5,0,0,1,.5.5v9a.5.5,0,0,1-.5.5h-.09a1,1,0,0,1-.7-.29L18,14Zm3.85,7-.53.53a.5.5,0,0,1-.7,0l-3.19-3.19A2,2,0,0,1,16,19H4a2,2,0,0,1-2-2V7A2,2,0,0,1,4,5h0L2.44,3.38a.48.48,0,0,1,0-.7L3,2.15a.48.48,0,0,1,.7,0L21.85,20.33A.5.5,0,0,1,21.85,21Zm-12-10.28L8.17,9.12H5.5a.5.5,0,0,0-.5.5v.76a.5.5,0,0,0,.5.5h4A.48.48,0,0,0,9.81,10.76Z',
  video_camera_outlined:
    'M16,7V17H4V7H16m0-2H4A2,2,0,0,0,2,7V17a2,2,0,0,0,2,2H16a2,2,0,0,0,2-2V14l2.71,2.71a1,1,0,0,0,.7.29h.09a.5.5,0,0,0,.5-.5v-9a.5.5,0,0,0-.5-.5h-.09a1,1,0,0,0-.7.29L18,10V7a2,2,0,0,0-2-2ZM6.5,10.88a.5.5,0,0,1-.5-.5V9.62a.5.5,0,0,1,.5-.5h4a.5.5,0,0,1,.5.5v.76a.5.5,0,0,1-.5.5Z',
  vinyl:
    'M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,17.47a.5.5,0,0,1-.52.5A8,8,0,0,1,6.73,18a.5.5,0,0,1,0-.73l.71-.71a.5.5,0,0,1,.69,0A6,6,0,0,0,11.52,18a.5.5,0,0,1,.48.49ZM12,16a4,4,0,1,1,4-4A4,4,0,0,1,12,16Zm5.29-9.29-.71.71a.5.5,0,0,1-.69,0A6,6,0,0,0,12.48,6,.5.5,0,0,1,12,5.53v-1a.5.5,0,0,1,.52-.5,8,8,0,0,1,4.75,2A.5.5,0,0,1,17.29,6.71ZM12,11a1,1,0,1,0,1,1A1,1,0,0,0,12,11Z',
  watches:
    'M19,12a7,7,0,0,0-3.14-5.84l-.72-4.32a1,1,0,0,0-1-.84H9.85a1,1,0,0,0-1,.84L8.14,6.16a7,7,0,0,0,0,11.68l.72,4.32a1,1,0,0,0,1,.84h4.3a1,1,0,0,0,1-.84l.72-4.32A7,7,0,0,0,19,12ZM7,12a5,5,0,1,1,5,5A5,5,0,0,1,7,12Z',
  document:
    'M18,2H6A2,2,0,0,0,4,4V20a2,2,0,0,0,2,2h7.17a2,2,0,0,0,1.42-.59l4.82-4.82A2,2,0,0,0,20,15.17V4A2,2,0,0,0,18,2ZM13,20.18V16a1,1,0,0,1,1-1h4.18Z',
  documents:
    'M11.59,18.41l4.82-4.82A2,2,0,0,0,17,12.17V3a2,2,0,0,0-2-2H4A2,2,0,0,0,2,3V17a2,2,0,0,0,2,2h6.17A2,2,0,0,0,11.59,18.41ZM10,13a1,1,0,0,1,1-1h4.18L10,17.18Zm9-8V19a2,2,0,0,1-2,2H6a2,2,0,0,0,2,2h9a4,4,0,0,0,4-4V7A2,2,0,0,0,19,5Z',
  documents_outlined:
    'M11.88,18.12l4.24-4.24A3,3,0,0,0,17,11.76V3a2,2,0,0,0-2-2H4A2,2,0,0,0,2,3V17a2,2,0,0,0,2,2H9.76A3,3,0,0,0,11.88,18.12ZM9,12v5H4V3H15v8H10A1,1,0,0,0,9,12ZM19,5V19a2,2,0,0,1-2,2H6a2,2,0,0,0,2,2h9a4,4,0,0,0,4-4V7A2,2,0,0,0,19,5Z',
  document_outlined:
    'M18,2H6A2,2,0,0,0,4,4V20a2,2,0,0,0,2,2h6.76a3,3,0,0,0,2.12-.88l4.24-4.24A3,3,0,0,0,20,14.76V4A2,2,0,0,0,18,2ZM6,4H18V14H13a1,1,0,0,0-1,1v5H6Z',
  document_text:
    'M18,2H6A2,2,0,0,0,4,4V20a2,2,0,0,0,2,2h7.17a2,2,0,0,0,1.42-.59l4.82-4.82A2,2,0,0,0,20,15.17V4A2,2,0,0,0,18,2ZM7,6.5A.5.5,0,0,1,7.5,6h9a.5.5,0,0,1,.5.5v1a.5.5,0,0,1-.5.5h-9A.5.5,0,0,1,7,7.5ZM12.5,12h-5a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h5a.5.5,0,0,1,.5.5v1A.5.5,0,0,1,12.5,12Zm.5,8.18V16a1,1,0,0,1,1-1h4.18Z',
  document_text_outlined:
    'M18,2H6A2,2,0,0,0,4,4V20a2,2,0,0,0,2,2h6.76a3,3,0,0,0,2.12-.88l4.24-4.24A3,3,0,0,0,20,14.76V4A2,2,0,0,0,18,2Zm0,12H13a1,1,0,0,0-1,1v5H6V4H18ZM8.5,8h7a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5h-7a.5.5,0,0,0-.5.5v1A.5.5,0,0,0,8.5,8Zm0,4h4a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5h-4a.5.5,0,0,0-.5.5v1A.5.5,0,0,0,8.5,12Z',
  file:
    'M6,22H18a2,2,0,0,0,2-2V4a2,2,0,0,0-2-2H10.83a2,2,0,0,0-1.42.59L4.59,7.41A2,2,0,0,0,4,8.83V20A2,2,0,0,0,6,22ZM11,3.82V8a1,1,0,0,1-1,1H5.82Z',
  files:
    'M17,17V3a2,2,0,0,0-2-2H8.83a2,2,0,0,0-1.42.59L2.59,6.41A2,2,0,0,0,2,7.83V17a2,2,0,0,0,2,2H15A2,2,0,0,0,17,17ZM9,7A1,1,0,0,1,8,8H3.82L9,2.82ZM19,5V19a2,2,0,0,1-2,2H6a2,2,0,0,0,2,2h9a4,4,0,0,0,4-4V7A2,2,0,0,0,19,5Z',
  files_labeled:
    'M15,1H8.83a2,2,0,0,0-1.42.59L2.59,6.41A2,2,0,0,0,2,7.83V17a2,2,0,0,0,2,2H15a2,2,0,0,0,2-2V3A2,2,0,0,0,15,1ZM3.82,8,9,2.82V7A1,1,0,0,1,8,8ZM14,13.5a.5.5,0,0,1-.5.5h-8a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h8a.5.5,0,0,1,.5.5ZM19,5V19a2,2,0,0,1-2,2H6a2,2,0,0,0,2,2h9a4,4,0,0,0,4-4V7A2,2,0,0,0,19,5Z',
  files_labeled_outlined:
    'M17,17V3a2,2,0,0,0-2-2H9.24a3,3,0,0,0-2.12.88L2.88,6.12A3,3,0,0,0,2,8.24V17a2,2,0,0,0,2,2H15A2,2,0,0,0,17,17ZM4,9H9a1,1,0,0,0,1-1V3h5V17H4Zm8.5,3h-6a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h6a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,12.5,12ZM19,5V19a2,2,0,0,1-2,2H6a2,2,0,0,0,2,2h9a4,4,0,0,0,4-4V7A2,2,0,0,0,19,5Z',
  files_outlined:
    'M17,17V3a2,2,0,0,0-2-2H9.24a3,3,0,0,0-2.12.88L2.88,6.12A3,3,0,0,0,2,8.24V17a2,2,0,0,0,2,2H15A2,2,0,0,0,17,17ZM4,9H9a1,1,0,0,0,1-1V3h5V17H4ZM19,5V19a2,2,0,0,1-2,2H6a2,2,0,0,0,2,2h9a4,4,0,0,0,4-4V7A2,2,0,0,0,19,5Z',
  file_add:
    'M18,2H10.83a2,2,0,0,0-1.42.59L4.59,7.41A2,2,0,0,0,4,8.83V20a2,2,0,0,0,2,2H18a2,2,0,0,0,2-2V4A2,2,0,0,0,18,2ZM5.82,9,11,3.82V8a1,1,0,0,1-1,1Zm8.68,6.88H12.88V17.5a.5.5,0,0,1-.5.5h-.76a.5.5,0,0,1-.5-.5V15.88H9.5a.5.5,0,0,1-.5-.5v-.76a.5.5,0,0,1,.5-.5h1.62V12.5a.5.5,0,0,1,.5-.5h.76a.5.5,0,0,1,.5.5v1.62H14.5a.5.5,0,0,1,.5.5v.76A.5.5,0,0,1,14.5,15.88Z',
  file_add_outlined:
    'M18,2H11.24a3,3,0,0,0-2.12.88L4.88,7.12A3,3,0,0,0,4,9.24V20a2,2,0,0,0,2,2H18a2,2,0,0,0,2-2V4A2,2,0,0,0,18,2Zm0,18H6V10h5a1,1,0,0,0,1-1V4h6Zm-3-4.62v-.76a.5.5,0,0,0-.5-.5H12.88V12.5a.5.5,0,0,0-.5-.5h-.76a.5.5,0,0,0-.5.5v1.62H9.5a.5.5,0,0,0-.5.5v.76a.5.5,0,0,0,.5.5h1.62V17.5a.5.5,0,0,0,.5.5h.76a.5.5,0,0,0,.5-.5V15.88H14.5A.5.5,0,0,0,15,15.38Z',
  file_outlined:
    'M6,22H18a2,2,0,0,0,2-2V4a2,2,0,0,0-2-2H11.24a3,3,0,0,0-2.12.88L4.88,7.12A3,3,0,0,0,4,9.24V20A2,2,0,0,0,6,22Zm12-2H6V10h5a1,1,0,0,0,1-1V4h6Z',
  file_text:
    'M4,20a2,2,0,0,0,2,2H18a2,2,0,0,0,2-2V4a2,2,0,0,0-2-2H10.83a2,2,0,0,0-1.42.59L4.59,7.41A2,2,0,0,0,4,8.83Zm9-2.5a.5.5,0,0,1-.5.5h-5a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h5a.5.5,0,0,1,.5.5Zm4-4a.5.5,0,0,1-.5.5h-9a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h9a.5.5,0,0,1,.5.5ZM5.82,9,11,3.82V8a1,1,0,0,1-1,1Z',
  file_text_outlined:
    'M18,2H11.24a3,3,0,0,0-2.12.88L4.88,7.12A3,3,0,0,0,4,9.24V20a2,2,0,0,0,2,2H18a2,2,0,0,0,2-2V4A2,2,0,0,0,18,2Zm0,18H6V10h5a1,1,0,0,0,1-1V4h6ZM8,12.5v1a.5.5,0,0,0,.5.5h7a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5h-7A.5.5,0,0,0,8,12.5ZM12.5,16h-4a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h4a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,12.5,16Z',
  folder:
    'M20,6H14.41a1,1,0,0,1-.7-.29L12.29,4.29a1,1,0,0,0-.7-.29H4A2,2,0,0,0,2,6V18a2,2,0,0,0,2,2H20a2,2,0,0,0,2-2V8A2,2,0,0,0,20,6Z',
  folders:
    'M17,18a2,2,0,0,0,2-2V7a2,2,0,0,0-2-2H12.41a1,1,0,0,1-.7-.29L10.29,3.29A1,1,0,0,0,9.59,3H3A2,2,0,0,0,1,5V16a2,2,0,0,0,2,2Zm4-9v9a2,2,0,0,1-2,2H5a2,2,0,0,0,2,2H19a4,4,0,0,0,4-4V11A2,2,0,0,0,21,9Z',
  folders_outlined:
    'M9.17,5l1.12,1.12A3,3,0,0,0,12.41,7H17v9H3V5H9.17m.42-2H3A2,2,0,0,0,1,5V16a2,2,0,0,0,2,2H17a2,2,0,0,0,2-2V7a2,2,0,0,0-2-2H12.41a1,1,0,0,1-.7-.29L10.29,3.29A1,1,0,0,0,9.59,3ZM21,9v9a2,2,0,0,1-2,2H5a2,2,0,0,0,2,2H19a4,4,0,0,0,4-4V11A2,2,0,0,0,21,9Z',
  folder_add:
    'M20,6H14.41a1,1,0,0,1-.7-.29L12.29,4.29a1,1,0,0,0-.7-.29H4A2,2,0,0,0,2,6V18a2,2,0,0,0,2,2H20a2,2,0,0,0,2-2V8A2,2,0,0,0,20,6Zm-7,7.5a.5.5,0,0,1-.5.5H10v2.5a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5V14H5.5a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5H8V9.5A.5.5,0,0,1,8.5,9h1a.5.5,0,0,1,.5.5V12h2.5a.5.5,0,0,1,.5.5Z',
  folder_bookmarked:
    'M20,6H14.41a1,1,0,0,1-.7-.29L12.29,4.29a1,1,0,0,0-.7-.29H4A2,2,0,0,0,2,6V18a2,2,0,0,0,2,2H20a2,2,0,0,0,2-2V8A2,2,0,0,0,20,6Zm-9,8.5a.5.5,0,0,1-.78.42l-1.94-1.3a.52.52,0,0,0-.56,0l-1.94,1.3A.5.5,0,0,1,5,14.5v-7A.5.5,0,0,1,5.5,7h5a.5.5,0,0,1,.5.5Z',
  folder_labeled:
    'M20,6H14.41a1,1,0,0,1-.7-.29L12.29,4.29a1,1,0,0,0-.7-.29H4A2,2,0,0,0,2,6V18a2,2,0,0,0,2,2H20a2,2,0,0,0,2-2V8A2,2,0,0,0,20,6ZM11,16.5a.5.5,0,0,1-.5.5h-5a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h5a.5.5,0,0,1,.5.5Zm2-4a.5.5,0,0,1-.5.5h-7a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h7a.5.5,0,0,1,.5.5Z',
  folder_labeled_outlined:
    'M20,6H14.41a1,1,0,0,1-.7-.29L12.29,4.29a1,1,0,0,0-.7-.29H4A2,2,0,0,0,2,6V18a2,2,0,0,0,2,2H20a2,2,0,0,0,2-2V8A2,2,0,0,0,20,6Zm0,12H4V6h7.17L12.3,7.12A3,3,0,0,0,14.41,8H20Zm-8-3.5v1a.5.5,0,0,1-.5.5h-5a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h5A.5.5,0,0,1,12,14.5Zm2-4v1a.5.5,0,0,1-.5.5h-7a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h7A.5.5,0,0,1,14,10.5Z',
  folder_opened:
    'M20,8V18H4V8ZM11.59,4H4A2,2,0,0,0,2,6V18a2,2,0,0,0,2,2H20a2,2,0,0,0,2-2V8a2,2,0,0,0-2-2H14.41a1,1,0,0,1-.7-.29L12.29,4.29a1,1,0,0,0-.7-.29Z',
  folder_opened_labeled:
    'M22,8a2,2,0,0,0-2-2H14.41a1,1,0,0,1-.7-.29L12.29,4.29a1,1,0,0,0-.7-.29H4A2,2,0,0,0,2,6V18a2,2,0,0,0,2,2H20a2,2,0,0,0,2-2ZM20,18H4V8H20ZM6.5,16h5a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5h-5a.5.5,0,0,0-.5.5v1A.5.5,0,0,0,6.5,16Zm0-4h7a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5h-7a.5.5,0,0,0-.5.5v1A.5.5,0,0,0,6.5,12Z',
  folder_outlined:
    'M11.17,6,12.3,7.12A3,3,0,0,0,14.41,8H20V18H4V6h7.17m.42-2H4A2,2,0,0,0,2,6V18a2,2,0,0,0,2,2H20a2,2,0,0,0,2-2V8a2,2,0,0,0-2-2H14.41a1,1,0,0,1-.7-.29L12.29,4.29a1,1,0,0,0-.7-.29Z',
  folder_photo:
    'M20,6H14.41a1,1,0,0,1-.7-.29L12.29,4.29a1,1,0,0,0-.7-.29H4A2,2,0,0,0,2,6V18a2,2,0,0,0,2,2H20a2,2,0,0,0,2-2V8A2,2,0,0,0,20,6ZM9.5,7A1.5,1.5,0,1,1,8,8.5,1.5,1.5,0,0,1,9.5,7ZM19,16.5a.5.5,0,0,1-.5.5H5.5a.5.5,0,0,1-.5-.5v-.34a.46.46,0,0,1,.11-.3L8,12.19A.52.52,0,0,1,8.35,12a.5.5,0,0,1,.39.18l1.45,1.75a.49.49,0,0,0,.38.18.51.51,0,0,0,.4-.2l2.88-3.72a.51.51,0,0,1,.8,0l4.25,5.67a.51.51,0,0,1,.1.3Z',
  folder_shared:
    'M20,6H14.83a2,2,0,0,1-1.42-.59l-.82-.82A2,2,0,0,0,11.17,4H4A2,2,0,0,0,2,6V18a2,2,0,0,0,2,2H20a2,2,0,0,0,2-2V8A2,2,0,0,0,20,6ZM9,9a2,2,0,1,1-2,2A2,2,0,0,1,9,9Zm3.5,8h-7a.5.5,0,0,1-.45-.72l.73-1.45A1.5,1.5,0,0,1,7.12,14h3.76a1.5,1.5,0,0,1,1.34.83L13,16.28A.5.5,0,0,1,12.5,17Z',
  folder_special:
    'M20,6H14.41a1,1,0,0,1-.7-.29L12.29,4.29a1,1,0,0,0-.7-.29H4A2,2,0,0,0,2,6V18a2,2,0,0,0,2,2H20a2,2,0,0,0,2-2V8A2,2,0,0,0,20,6Zm-6.09,6.14-2,1.7.61,2.54a.5.5,0,0,1-.74.55L9.54,15.56,7.3,16.93a.51.51,0,0,1-.75-.55l.61-2.54-2-1.7a.5.5,0,0,1,.28-.88l2.61-.2,1-2.43a.5.5,0,0,1,.92,0l1,2.43,2.6.2A.5.5,0,0,1,13.91,12.14Z',
  avocado:
    'M18,8c-1.45-3.08-3.34-6-6-6S7.45,4.92,6,8c-1,2-2,3.84-2,6a8,8,0,0,0,16,0C20,11.84,19,10,18,8ZM12,18a4,4,0,0,1-4-4c0-2.21,1.79-5,4-5s4,2.79,4,5A4,4,0,0,1,12,18Z',
  banana:
    'M6.73,15.54,3.62,14.26A1,1,0,0,1,3,13.33V12.5a.5.5,0,0,1,.5-.5H4.81a6,6,0,0,0,2.29-.46l7.67-3.17L8,15.11a4.93,4.93,0,0,1-.73.63A5.31,5.31,0,0,1,6.73,15.54ZM20.5,3h-1a.5.5,0,0,0-.5.5V4.76a2.07,2.07,0,0,1-.28,1L17.56,7.72a7.83,7.83,0,0,1-1.21,1.54L9.26,16.35a7.83,7.83,0,0,1-1.54,1.21L5.24,19a.51.51,0,0,0-.24.43V20a1,1,0,0,0,1,1H8.37a9,9,0,0,0,6.37-2.64l3.62-3.62A9,9,0,0,0,21,8.37V3.5A.5.5,0,0,0,20.5,3Z',
  beer_bottle:
    'M15.58,11.16l-.95-1.89A6.11,6.11,0,0,1,14,6.58V5.5a.5.5,0,0,0-.5-.5h-3a.5.5,0,0,0-.5.5V6.58a6.11,6.11,0,0,1-.63,2.69l-.95,1.89A3.92,3.92,0,0,0,8,12.94V22a1,1,0,0,0,1,1h6a1,1,0,0,0,1-1V12.94A3.92,3.92,0,0,0,15.58,11.16ZM14,2.5V2a1,1,0,0,0-1-1H11a1,1,0,0,0-1,1v.5a.5.5,0,0,0,.5.5h3A.5.5,0,0,0,14,2.5Z',
  beer_bottle_labeled:
    'M15.58,11.16l-.95-1.89A6.11,6.11,0,0,1,14,6.58V5.5a.5.5,0,0,0-.5-.5h-3a.5.5,0,0,0-.5.5V6.58a6.11,6.11,0,0,1-.63,2.69l-.95,1.89A3.92,3.92,0,0,0,8,12.94V22a1,1,0,0,0,1,1h6a1,1,0,0,0,1-1V12.94A3.92,3.92,0,0,0,15.58,11.16ZM13.5,20h-3a.5.5,0,0,1-.5-.5v-5a.5.5,0,0,1,.5-.5h3a.5.5,0,0,1,.5.5v5A.5.5,0,0,1,13.5,20ZM14,2.5V2a1,1,0,0,0-1-1H11a1,1,0,0,0-1,1v.5a.5.5,0,0,0,.5.5h3A.5.5,0,0,0,14,2.5Z',
  chicken:
    'M15,3a6.08,6.08,0,0,0-2.22.43,5.93,5.93,0,0,0-2.45,1.81C7.79,8.29,9.5,11,7.49,13a.88.88,0,0,0,0,1.24l.08.08L6,15.85a1,1,0,0,1-1,.23A1.57,1.57,0,0,0,4.5,16a1.5,1.5,0,0,0,0,3,.51.51,0,0,1,.5.5,1.5,1.5,0,0,0,3,0A1.57,1.57,0,0,0,7.92,19a1,1,0,0,1,.23-1L9.7,16.43l.08.08a.88.88,0,0,0,1.24,0c2-2,4.69-.3,7.74-2.84A6,6,0,0,0,15,3Z',
  chicken_outlined:
    'M15,3a6.08,6.08,0,0,0-2.22.43,5.93,5.93,0,0,0-2.45,1.81C7.79,8.29,9.5,11,7.49,13a.88.88,0,0,0,0,1.24l.08.08L6,15.85a1,1,0,0,1-1,.23A1.57,1.57,0,0,0,4.5,16a1.5,1.5,0,0,0,0,3,.51.51,0,0,1,.5.5,1.5,1.5,0,0,0,3,0A1.57,1.57,0,0,0,7.92,19a1,1,0,0,1,.23-1L9.7,16.43l.08.08a.88.88,0,0,0,1.24,0c2-2,4.69-.3,7.74-2.84A6,6,0,0,0,15,3Zm2.83,8.83a4,4,0,0,1-.35.31,5.48,5.48,0,0,1-3.39,1.18,7.81,7.81,0,0,0-3.61,1.06l-.86-.86a7.81,7.81,0,0,0,1.06-3.61,5.53,5.53,0,0,1,1.2-3.42c.1-.12.19-.22.29-.32a4,4,0,1,1,5.66,5.66Z',
  citrus_fruits:
    'M18.15,4.45,17.06,3.29a1,1,0,0,0-.7-.29,1,1,0,0,0-.71.29L3.29,15.65a1,1,0,0,0,0,1.41l.85.92a10.29,10.29,0,0,0,7.26,3,9.46,9.46,0,0,0,6.27-2.33A9.75,9.75,0,0,0,18.15,4.45ZM18,11.56a6.68,6.68,0,0,1-1.36,3.84l-4.53-4.52H18C18,11.1,18,11.33,18,11.56Zm-2.6,5.08a6.49,6.49,0,0,1-4,1.36,4.41,4.41,0,0,1-.52,0V12.11ZM16,6.57a6.79,6.79,0,0,1,1.6,2.55h-5l3-3ZM9.12,17.62a7.2,7.2,0,0,1-2.83-1.73l-.23-.18,3.06-3.06Z',
  drink:
    'M15.5,22a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5H13V12l6.41-6.41A2,2,0,0,0,20,4.17V3a1,1,0,0,0-1-1H5A1,1,0,0,0,4,3V4.17a2,2,0,0,0,.59,1.42L11,12h0v8H8.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5Z',
  drink_outlined:
    'M15.5,22a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5H13V12l6.41-6.41A2,2,0,0,0,20,4.17V3a1,1,0,0,0-1-1H5A1,1,0,0,0,4,3V4.17a2,2,0,0,0,.59,1.42L11,12h0v8H8.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5ZM18,4.17,15.17,7H8.83L6,4.17V4H18Z',
  egg:
    'M12,4c2.86,0,6,5.23,6,10A6,6,0,0,1,6,14C6,9.23,9.14,4,12,4m0-2C7.58,2,4,8.58,4,14a8,8,0,0,0,16,0c0-5.42-3.58-12-8-12Zm0,8a4,4,0,1,0,4,4A4,4,0,0,0,12,10Z',
  fastfood:
    'M3,11a8,8,0,0,1,8-8h2a8,8,0,0,1,8,8,1,1,0,0,1-1,1H4A1,1,0,0,1,3,11Zm17,7H18.3a1,1,0,0,0-.7.29L16,19.85a.5.5,0,0,1-.71,0l-1.56-1.56a1,1,0,0,0-.7-.29H4a1,1,0,0,0-1,1v1a1,1,0,0,0,1,1H20a1,1,0,0,0,1-1V19A1,1,0,0,0,20,18Zm0-4H11.3a1,1,0,0,0-.7.29L9,15.85a.5.5,0,0,1-.71,0L6.77,14.29a1,1,0,0,0-.7-.29H4a1,1,0,0,0-1,1v1a1,1,0,0,0,1,1H20a1,1,0,0,0,1-1V15A1,1,0,0,0,20,14Z',
  fish:
    'M19.71,12l2.14-2.15A.5.5,0,0,0,21.5,9h-2a2,2,0,0,0-1.41.59l-.2.19L15.6,7.46A5,5,0,0,0,14,6.39V4.5a.5.5,0,0,0-.5-.5H11.83a2,2,0,0,0-1.42.59L8.94,6.06A5,5,0,0,0,6,7.46L2.29,11.21a1,1,0,0,0-.29.7v.18a1,1,0,0,0,.29.7L6,16.54A5,5,0,0,0,9.57,18H10l1.41,1.41a2,2,0,0,0,1.42.59h.67a.5.5,0,0,0,.5-.5V17.61a5,5,0,0,0,1.6-1.07l2.32-2.32.2.19a2,2,0,0,0,1.41.59h2a.5.5,0,0,0,.35-.85ZM8,11.5a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5Z',
  fish_outlined:
    'M19.71,12l2.14-2.15A.5.5,0,0,0,21.5,9h-2a2,2,0,0,0-1.41.59l-.2.19L15.6,7.46A5,5,0,0,0,14,6.39V4.5a.5.5,0,0,0-.5-.5H11.83a2,2,0,0,0-1.42.59L8.94,6.06A5,5,0,0,0,6,7.46L2.29,11.21a1,1,0,0,0-.29.7v.18a1,1,0,0,0,.29.7L6,16.54A5,5,0,0,0,9.57,18H10l1.41,1.41a2,2,0,0,0,1.42.59h.67a.5.5,0,0,0,.5-.5V17.61a5,5,0,0,0,1.6-1.07l2.32-2.32.2.19a2,2,0,0,0,1.41.59h2a.5.5,0,0,0,.35-.85Zm-5.52,3.12a3,3,0,0,1-2.12.88H9.57a3,3,0,0,1-2.12-.88L4.33,12,7.45,8.88A3,3,0,0,1,9.57,8h2.5a3,3,0,0,1,2.12.88L17.31,12Z',
  fruit_apple:
    'M21,11.25c0,6.1-4,9.69-5.39,9.69A3.08,3.08,0,0,1,15,21c-1.42,0-1.58-.81-3-.81S10.42,21,9,21a3.08,3.08,0,0,1-.61-.06C6.12,20.94,3,16,3,11.25A5.18,5.18,0,0,1,8,6a5.74,5.74,0,0,1,3.21,1.09A7,7,0,0,1,11.35,6,7,7,0,0,1,12.7,3.18a.49.49,0,0,1,.73,0l.69.68a.51.51,0,0,1,0,.7,4.9,4.9,0,0,0-1.07,1.88,4.61,4.61,0,0,0-.16.64A5.3,5.3,0,0,1,16,6,5.18,5.18,0,0,1,21,11.25Z',
  fruit_apple_outlined:
    'M16,6a5.3,5.3,0,0,0-3.11,1,4.61,4.61,0,0,1,.16-.64,4.9,4.9,0,0,1,1.07-1.88.51.51,0,0,0,0-.7l-.69-.68a.49.49,0,0,0-.73,0A7,7,0,0,0,11.35,6a7.14,7.14,0,0,0-.14,1.12A5.7,5.7,0,0,0,8,6a5.18,5.18,0,0,0-5,5.25C3,16,6.12,20.94,8.39,20.94A3.08,3.08,0,0,0,9,21c1.42,0,1.58-.81,3-.81s1.58.81,3,.81a3.08,3.08,0,0,0,.61-.06C17,20.94,21,17.35,21,11.25A5.18,5.18,0,0,0,16,6Zm-.55,12.94h0l-.2,0L15,19a1,1,0,0,1-.59-.18A4.85,4.85,0,0,0,12,18.19a5,5,0,0,0-2.41.62A1,1,0,0,1,9,19l-.21,0-.2,0H8.43c-1-.27-3.43-3.8-3.43-7.69A3.2,3.2,0,0,1,8,8a2.45,2.45,0,0,1,1.59.49A4.42,4.42,0,0,0,12,9.28a4.42,4.42,0,0,0,2.41-.79A2.43,2.43,0,0,1,16,8a3.2,3.2,0,0,1,3,3.25C19,15.94,16.28,18.58,15.45,18.94Z',
  ice_cream:
    'M17,7.05a.09.09,0,0,0,0,0A5,5,0,0,0,7,7a.09.09,0,0,0,0,0,3.49,3.49,0,0,0,.49,7h.09l2.55,7a1.5,1.5,0,0,0,1.41,1h.9a1.5,1.5,0,0,0,1.41-1l2.55-7h.09A3.49,3.49,0,0,0,17,7.05ZM12.1,20h-.2L9.72,14h4.56Z',
  ice_cream_cup:
    'M18.64,6.36A4,4,0,0,0,15,4a2.14,2.14,0,0,1-1.88-1.35A1,1,0,0,0,12.18,2H12A3,3,0,0,0,9.18,4H9A4,4,0,0,0,5.36,6.36a4,4,0,0,0,.2,7.36A7,7,0,0,0,11,17.91V20H9.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h5a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5H13V17.91a7,7,0,0,0,5.44-4.19,4,4,0,0,0,.2-7.36ZM17,12H7a2,2,0,0,1-.82-3.82L6.7,8A.51.51,0,0,0,7,7.7l.23-.52A2,2,0,0,1,9,6h1.24a.5.5,0,0,0,.47-.33l.36-1a1,1,0,0,1,.54-.59A4.09,4.09,0,0,0,15,6a2,2,0,0,1,1.82,1.18l.23.52A.51.51,0,0,0,17.3,8l.52.23A2,2,0,0,1,17,12Z',
  meal:
    'M21.5,17H21V16a9,9,0,0,0-8-8.94V5.5a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5V7.06A9,9,0,0,0,3,16v1H2.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h19a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,21.5,17Z',
  meal_outlined:
    'M21.5,17H21V16a9,9,0,0,0-8-8.94V5.5a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5V7.06A9,9,0,0,0,3,16v1H2.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h19a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,21.5,17ZM5,16a7,7,0,0,1,14,0v1H5Z',
  mushroom:
    'M12,3a9,9,0,0,0-9,9,1,1,0,0,0,1,1H8.32A32.77,32.77,0,0,0,8,17a4,4,0,0,0,8,0,32.77,32.77,0,0,0-.32-4H20a1,1,0,0,0,1-1A9,9,0,0,0,12,3ZM8,9a2,2,0,0,1,2,2H6A2,2,0,0,1,8,9Zm6,8a2,2,0,0,1-4,0,28.77,28.77,0,0,1,.34-4h3.32A28.77,28.77,0,0,1,14,17Zm1-8a3,3,0,0,1-3-3,2.72,2.72,0,0,1,.18-1A7,7,0,0,1,17.5,7.67,3,3,0,0,1,15,9Z',
  pizza:
    'M20.78,3.48l-.26-.26A.75.75,0,0,0,20,3l-.21,0L3.72,7.72a1,1,0,0,0-.72,1v.07C3,12.32,11.18,21,15.25,21h.07a1,1,0,0,0,1-.72L21,4.22A.75.75,0,0,0,20.78,3.48ZM9.5,12A1.5,1.5,0,1,1,11,10.5,1.5,1.5,0,0,1,9.5,12ZM14,16a2,2,0,1,1,2-2A2,2,0,0,1,14,16Zm.5-8a1.5,1.5,0,0,1-1.39-.94L16,6.23a1.24,1.24,0,0,1,0,.27A1.5,1.5,0,0,1,14.5,8Z',
  pizza_outlined:
    'M20.78,3.48l-.26-.26A.75.75,0,0,0,20,3l-.21,0L3.72,7.72a1,1,0,0,0-.72,1v.07C3,12.32,11.18,21,15.25,21h.07a1,1,0,0,0,1-.72L21,4.22A.75.75,0,0,0,20.78,3.48ZM14.85,18C12,16.85,7.22,11.87,6,9.14l7.11-2.08A1.5,1.5,0,0,0,16,6.5a1.24,1.24,0,0,0,0-.27l2.55-.75ZM13,10a2,2,0,1,0,2,2A2,2,0,0,0,13,10Z',
  soda:
    'M17,2a4,4,0,0,0-4,4H6A1,1,0,0,0,5,7.06l.82,13.06a2,2,0,0,0,2,1.88h6.3a2,2,0,0,0,2-1.88L16.78,10,17,10a4,4,0,0,0,0-8Zm-2.28,9H7.28L7.1,8h7.8ZM17,8h-.09L17,7.06A1,1,0,0,0,16,6H15a2,2,0,1,1,2,2Z',
  strawberry:
    'M16,5H13.2a5,5,0,0,1,.92-1.47.51.51,0,0,0,0-.7l-.69-.68A.49.49,0,0,0,13.07,2a.46.46,0,0,0-.37.18A7,7,0,0,0,11.35,5V5H8a6,6,0,0,0-4.55,9.91l4.91,5.7a4,4,0,0,0,3,1.39h1.22a4,4,0,0,0,3-1.39l4.91-5.7A6,6,0,0,0,16,5Zm3,8.6-4.9,5.7a2,2,0,0,1-1.52.7H11.39a2,2,0,0,1-1.52-.7L5,13.6A4,4,0,0,1,4,11,4,4,0,0,1,6.54,7.28,3,3,0,0,0,6,9v2.5a.5.5,0,0,0,.5.5h.27a2,2,0,0,0,1.29-.47l2.12-1.79A3.24,3.24,0,0,0,11.63,12h.71a3.32,3.32,0,0,0,1.47-2.28l2.13,1.81a2,2,0,0,0,1.29.47h.27a.5.5,0,0,0,.5-.5V9a3,3,0,0,0-.54-1.72A4,4,0,0,1,20,11,4,4,0,0,1,19,13.6Z',
  water_bottle:
    'M16,10V7a2,2,0,0,0-2-2H10A2,2,0,0,0,8,7v3a1,1,0,0,0,1,1v1.94a1,1,0,0,0-1,1V22a1,1,0,0,0,1,1h6a1,1,0,0,0,1-1V13.94a1,1,0,0,0-1-1V11A1,1,0,0,0,16,10Z M10.5,3h3a.5.5,0,0,0,.5-.5V2a1,1,0,0,0-1-1H11a1,1,0,0,0-1,1v.5A.5.5,0,0,0,10.5,3Z',
  water_bottle_labeled:
    'M16,10V7a2,2,0,0,0-2-2H10A2,2,0,0,0,8,7v3a1,1,0,0,0,1,1v1.94a1,1,0,0,0-1,1V22a1,1,0,0,0,1,1h6a1,1,0,0,0,1-1V13.94a1,1,0,0,0-1-1V11A1,1,0,0,0,16,10Zm-2.5,9h-3a.5.5,0,0,1-.5-.5v-3a.5.5,0,0,1,.5-.5h3a.5.5,0,0,1,.5.5v3A.5.5,0,0,1,13.5,19Zm-3-16h3a.5.5,0,0,0,.5-.5V2a1,1,0,0,0-1-1H11a1,1,0,0,0-1,1v.5A.5.5,0,0,0,10.5,3Z',
  wine_bottle:
    'M13.5,3h-3a.5.5,0,0,1-.5-.5V2a1,1,0,0,1,1-1h2a1,1,0,0,1,1,1v.5A.5.5,0,0,1,13.5,3ZM14,8V5.5a.5.5,0,0,0-.5-.5h-3a.5.5,0,0,0-.5.5V8a2,2,0,0,0-2,2V22a1,1,0,0,0,1,1h6a1,1,0,0,0,1-1V10A2,2,0,0,0,14,8Z',
  wine_bottle_labeled:
    'M14,8V5.5a.5.5,0,0,0-.5-.5h-3a.5.5,0,0,0-.5.5V8a2,2,0,0,0-2,2V22a1,1,0,0,0,1,1h6a1,1,0,0,0,1-1V10A2,2,0,0,0,14,8Zm-.5,10h-3a.5.5,0,0,1-.5-.5v-5a.5.5,0,0,1,.5-.5h3a.5.5,0,0,1,.5.5v5A.5.5,0,0,1,13.5,18ZM14,2.5V2a1,1,0,0,0-1-1H11a1,1,0,0,0-1,1v.5a.5.5,0,0,0,.5.5h3A.5.5,0,0,0,14,2.5Z',
  clap:
    'M3.5,14.24v4.1a1.62,1.62,0,0,0,0,.22l-.63-.62A3.29,3.29,0,0,1,2,15.51v-4.1c0-1.9,1.37-3.2,1.73-5.24h0l.45-2.59a.5.5,0,0,1,.58-.4A1.5,1.5,0,0,1,6,4.28a.94.94,0,0,1,0,.41l-1,5.85A5.49,5.49,0,0,0,3.5,14.24ZM15,2.71a1,1,0,0,0-1.42-1.42l-4,4a3,3,0,0,1,.94,1.88Zm5.34,10.38-2,2a.5.5,0,0,1-.7,0,.5.5,0,0,1,0-.71l4.12-4.12a1,1,0,0,0,0-1.41,1,1,0,0,0-1.42,0L16.17,13a.5.5,0,0,1-.7,0,.5.5,0,0,1,0-.71L20.78,7a1,1,0,0,0,0-1.41,1,1,0,0,0-1.42,0l-5.31,5.31a.5.5,0,1,1-.71-.71L18,5.54a1,1,0,1,0-1.42-1.42L9,11.66V7.45A1.49,1.49,0,0,0,7.76,6a.5.5,0,0,0-.58.4L6.73,9C6.37,11,5,12.34,5,14.24v4.1a3.29,3.29,0,0,0,.88,2.43l1.36,1.35A3,3,0,0,0,9.36,23h.05a6.06,6.06,0,0,0,2.91-.75l3.09-1.72a5.07,5.07,0,0,0,1.11-.84l5.19-5.18a1,1,0,0,0-1.42-1.42Z',
  expressionless:
    'M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20Zm2.5-6h-5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h5a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,14.5,14ZM11,10.5v-1a.5.5,0,0,0-.5-.5h-3a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h3A.5.5,0,0,0,11,10.5ZM16.5,9h-3a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h3a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,16.5,9Z',
  face_female:
    'M20.66,19.43a.48.48,0,0,0,0-.7A2,2,0,0,1,20,17.27V8a6,6,0,0,0-6-6H10A6,6,0,0,0,4,8v9.27a2,2,0,0,1-.63,1.46.48.48,0,0,0,0,.7A2,2,0,0,0,4.73,20H6.55c.15.14.29.27.45.4l.36.29A6,6,0,0,0,11.1,22h1.8a6,6,0,0,0,3.74-1.31L17,20.4c.16-.13.3-.26.45-.4h1.82A2,2,0,0,0,20.66,19.43ZM18,14.16a6,6,0,0,1-2.25,4.68l-.36.29A4,4,0,0,1,12.9,20H11.1a4,4,0,0,1-2.49-.87l-.36-.29A6,6,0,0,1,6,14.16V9.74a8,8,0,0,0,4.52-3.11A10,10,0,0,0,18,10Zm-3.58,1.92a.23.23,0,0,0-.18-.08H9.76a.23.23,0,0,0-.18.08.25.25,0,0,0-.06.2,2.5,2.5,0,0,0,5,0A.25.25,0,0,0,14.42,16.08ZM9.48,11.5A1.49,1.49,0,0,0,8,12.7a.25.25,0,0,0,.24.3H10.7a.22.22,0,0,0,.19-.09A.27.27,0,0,0,11,12.7,1.51,1.51,0,0,0,9.48,11.5Zm5,0a1.51,1.51,0,0,0-1.48,1.2.27.27,0,0,0,.06.21.22.22,0,0,0,.19.09h2.45a.22.22,0,0,0,.19-.09A.23.23,0,0,0,16,12.7,1.49,1.49,0,0,0,14.53,11.5Z',
  face_male:
    'M4.5,2a.5.5,0,0,0-.5.53A6,6,0,0,0,4.81,5,6,6,0,0,0,4,8v6.16A8,8,0,0,0,7,20.4l.36.29A6,6,0,0,0,11.1,22h1.8a6,6,0,0,0,3.74-1.31L17,20.4a8,8,0,0,0,3-6.24V8a6,6,0,0,0-6-6ZM18,14.16a6,6,0,0,1-2.25,4.68l-.36.29A4,4,0,0,1,12.9,20H11.1a4,4,0,0,1-2.49-.87l-.36-.29A6,6,0,0,1,6,14.16V9.74a8,8,0,0,0,4.52-3.11A10,10,0,0,0,18,10ZM9.48,11.5A1.49,1.49,0,0,0,8,12.7a.25.25,0,0,0,.24.3H10.7a.23.23,0,0,0,.19-.09A.27.27,0,0,0,11,12.7,1.51,1.51,0,0,0,9.48,11.5Zm5,0a1.51,1.51,0,0,0-1.48,1.2.27.27,0,0,0,.06.21.23.23,0,0,0,.19.09h2.45a.23.23,0,0,0,.19-.09A.27.27,0,0,0,16,12.7,1.51,1.51,0,0,0,14.53,11.5Zm0,4.78a.25.25,0,0,0-.06-.2.23.23,0,0,0-.18-.08H9.76a.23.23,0,0,0-.18.08.25.25,0,0,0-.06.2,2.5,2.5,0,0,0,5,0Z',
  frowning:
    'M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20Zm-1.5-9a.5.5,0,0,0,.5-.5V10a2,2,0,0,0-4,0v.5a.5.5,0,0,0,.5.5ZM15,8a2,2,0,0,0-2,2v.5a.5.5,0,0,0,.5.5h3a.5.5,0,0,0,.5-.5V10A2,2,0,0,0,15,8Zm-3,5a4,4,0,0,0-4,3.43.49.49,0,0,0,.12.4.49.49,0,0,0,.38.17h6.92a.49.49,0,0,0,.38-.17.49.49,0,0,0,.12-.4A4,4,0,0,0,12,13Z',
  gesture_hand_1f:
    'M21,13.74v1.67a6,6,0,0,1-.79,3L17.58,23a2,2,0,0,1-1.74,1H11.7a2,2,0,0,1-1.25-.44L7.36,20.37a6,6,0,0,1-.67-.84L5.57,17.86A2.94,2.94,0,0,0,4,16.68l-1.34-.45h0l-.36-.12A.49.49,0,0,1,2,15.63v-.14A1.49,1.49,0,0,1,3.5,14h.84a4,4,0,0,1,1.28.2L8,15V2.5a1.5,1.5,0,0,1,3,0v8a.5.5,0,0,0,1,0V8h3.26a3,3,0,0,1,2.12.88l2.74,2.74A3,3,0,0,1,21,13.74Z',
  gesture_hand_2f:
    'M15,10.5a.5.5,0,0,0,1,0V8.1a3,3,0,0,1,1.38.78l2.74,2.74A3,3,0,0,1,21,13.74v1.67a6,6,0,0,1-.79,3L17.58,23a2,2,0,0,1-1.74,1H11.7a2,2,0,0,1-1.25-.44L7.36,20.37a6,6,0,0,1-.67-.84L5.57,17.86A2.94,2.94,0,0,0,4,16.68l-1.34-.45h0l-.36-.12A.49.49,0,0,1,2,15.63v-.14A1.49,1.49,0,0,1,3.5,14h.84a4,4,0,0,1,1.28.2L8,15V2.5a1.5,1.5,0,0,1,3,0v8a.5.5,0,0,0,1,0v-9a1.5,1.5,0,0,1,3,0Z',
  gesture_hover_1f:
    'M11.49,24a3,3,0,0,1-2.12-.88L5.75,19.5A5,5,0,0,1,4,15.7V14a2,2,0,0,1,2-2H6v1.65a2,2,0,0,0,.69,1.51l.31.31V2.5a1.5,1.5,0,0,1,3,0v8a.5.5,0,0,0,1,0V8h3.26a3,3,0,0,1,2.12.88l2.74,2.74A3,3,0,0,1,20,13.74v1.67a6,6,0,0,1-.79,3L16.58,23a2,2,0,0,1-1.74,1Z',
  gesture_scroll_down:
    'M18,14.47v.27a4.87,4.87,0,0,1-.19,1.37l-1,3.39a6,6,0,0,1-1.52,2.59l0,0a3,3,0,0,1-2.12.88H10.58a3,3,0,0,1-2.12-.88L6.08,19.76c-1.34-1.35-1.3-3.22-2.48-4.91L2.09,12.7a.51.51,0,0,1,.12-.7,1.52,1.52,0,0,1,1.9.15L7,15V4A1,1,0,0,1,9,4v6.5a.5.5,0,0,0,1,0V8.74h2.25a3,3,0,0,1,2.13.88l2.73,2.72A3.09,3.09,0,0,1,18,14.47ZM18.5,1a.5.5,0,0,0-.5.5V7h-.94a.5.5,0,0,0-.36.85l2,2a.37.37,0,0,0,.53,0l2-2A.49.49,0,0,0,21,7H20V1.5a.5.5,0,0,0-.5-.5Z',
  gesture_scroll_down_2f:
    'M17.11,12.34A3.09,3.09,0,0,1,18,14.47v.27a4.87,4.87,0,0,1-.19,1.37l-1,3.39a6,6,0,0,1-1.52,2.59l0,0a3,3,0,0,1-2.12.88H10.58a3,3,0,0,1-2.12-.88L6.08,19.76c-1.34-1.35-1.3-3.22-2.48-4.91L2.09,12.7a.51.51,0,0,1,.12-.7,1.52,1.52,0,0,1,1.9.15L7,15V4A1,1,0,0,1,9,4v6.5a.5.5,0,0,0,1,0V3a1,1,0,0,1,2,0v7.5a.5.5,0,0,0,1,0V8.84a3,3,0,0,1,1.38.78ZM18.5,1a.5.5,0,0,0-.5.5V7h-.94a.5.5,0,0,0-.36.85l2,2a.37.37,0,0,0,.53,0l2-2A.49.49,0,0,0,21,7H20V1.5a.5.5,0,0,0-.5-.5Z',
  gesture_scroll_up:
    'M18,14.74a4.87,4.87,0,0,1-.19,1.37l-1,3.39a6,6,0,0,1-1.52,2.59l0,0a3,3,0,0,1-2.12.88H10.58a3,3,0,0,1-2.12-.88L6.08,19.76c-1.34-1.35-1.3-3.22-2.48-4.91L2.09,12.7a.51.51,0,0,1,.12-.7,1.52,1.52,0,0,1,1.9.15L7,15V4A1,1,0,0,1,9,4v6.5a.5.5,0,0,0,1,0V8.74h2.25a3,3,0,0,1,2.13.88l2.73,2.72A3.09,3.09,0,0,1,18,14.47ZM19.5,10a.5.5,0,0,0,.5-.5V4H21a.49.49,0,0,0,.35-.85l-2-2a.37.37,0,0,0-.53,0l-2,2a.5.5,0,0,0,.36.85H18V9.5a.5.5,0,0,0,.5.5Z',
  gesture_scroll_up_2f:
    'M17.11,12.34A3.09,3.09,0,0,1,18,14.47v.27a4.87,4.87,0,0,1-.19,1.37l-1,3.39a6,6,0,0,1-1.52,2.59l0,0a3,3,0,0,1-2.12.88H10.58a3,3,0,0,1-2.12-.88L6.08,19.76c-1.34-1.35-1.3-3.22-2.48-4.91L2.09,12.7a.51.51,0,0,1,.12-.7,1.52,1.52,0,0,1,1.9.15L7,15V4A1,1,0,0,1,9,4v6.5a.5.5,0,0,0,1,0V3a1,1,0,0,1,2,0v7.5a.5.5,0,0,0,1,0V8.84a3,3,0,0,1,1.38.78ZM19.5,10a.5.5,0,0,0,.5-.5V4H21a.49.49,0,0,0,.35-.85l-2-2a.37.37,0,0,0-.53,0l-2,2a.5.5,0,0,0,.36.85H18V9.5a.5.5,0,0,0,.5.5Z',
  gesture_scroll_up_down:
    'M18,14.47v.27a4.87,4.87,0,0,1-.19,1.37l-1,3.39a6,6,0,0,1-1.52,2.59l0,0a3,3,0,0,1-2.12.88H10.58a3,3,0,0,1-2.12-.88L6.08,19.76c-1.34-1.35-1.3-3.22-2.48-4.91L2.09,12.7a.51.51,0,0,1,.12-.7,1.52,1.52,0,0,1,1.9.15L7,15V4A1,1,0,0,1,9,4v6.5a.5.5,0,0,0,1,0V8.74h2.25a3,3,0,0,1,2.13.88l2.73,2.72A3.09,3.09,0,0,1,18,14.47ZM21,8H20V4H21a.49.49,0,0,0,.35-.85l-2-2a.37.37,0,0,0-.53,0l-2,2a.5.5,0,0,0,.36.85H18V8h-.94a.5.5,0,0,0-.36.85l2.05,2a.37.37,0,0,0,.53,0l2-2A.5.5,0,0,0,21,8Z',
  gesture_scroll_up_down_2f:
    'M21.31,8.85l-2,2a.37.37,0,0,1-.53,0l-2.05-2A.5.5,0,0,1,17.06,8H18V4h-.94a.5.5,0,0,1-.36-.85l2-2a.37.37,0,0,1,.53,0l2,2A.49.49,0,0,1,21,4H20V8H21A.5.5,0,0,1,21.31,8.85Zm-6.93.77A3,3,0,0,0,13,8.84V10.5a.5.5,0,0,1-1,0V3a1,1,0,0,0-2,0v7.5a.5.5,0,0,1-1,0V4A1,1,0,0,0,7,4V15L4.11,12.15A1.52,1.52,0,0,0,2.21,12a.51.51,0,0,0-.12.7L3.6,14.85c1.18,1.69,1.14,3.56,2.48,4.91l2.38,2.36a3,3,0,0,0,2.12.88h2.57a3,3,0,0,0,2.12-.88l0,0a6,6,0,0,0,1.52-2.59l1-3.39A4.87,4.87,0,0,0,18,14.74v-.27a3.09,3.09,0,0,0-.89-2.13Z',
  gesture_swipe_left:
    'M18,14.47v.27a4.87,4.87,0,0,1-.19,1.37l-1,3.39a6,6,0,0,1-1.52,2.59l0,0a3,3,0,0,1-2.12.88H10.58a3,3,0,0,1-2.12-.88L6.08,19.76c-1.34-1.35-1.3-3.22-2.48-4.91L2.09,12.7a.51.51,0,0,1,.12-.7,1.52,1.52,0,0,1,1.9.15L7,15V4A1,1,0,0,1,9,4v6.5a.5.5,0,0,0,1,0V8.74h2.25a3,3,0,0,1,2.13.88l2.73,2.72A3.09,3.09,0,0,1,18,14.47ZM21.5,5a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5H16v-1a.51.51,0,0,0-.86-.36l-2,2a.37.37,0,0,0,0,.53l2,2.05A.51.51,0,0,0,16,5.94V5Z',
  gesture_swipe_left_2f:
    'M17,14.47v.27a4.87,4.87,0,0,1-.19,1.37l-1,3.39a6,6,0,0,1-1.52,2.59l0,0a3,3,0,0,1-2.12.88H9.58a3,3,0,0,1-2.12-.88L5.08,19.76c-1.34-1.35-1.3-3.22-2.48-4.91L1.09,12.7a.51.51,0,0,1,.12-.7,1.52,1.52,0,0,1,1.9.15L6,15V4A1,1,0,0,1,8,4v6.5a.5.5,0,0,0,1,0V3a1,1,0,0,1,2,0v7.5a.5.5,0,0,0,1,0V8.84a3,3,0,0,1,1.38.78l2.73,2.72A3.09,3.09,0,0,1,17,14.47ZM21.5,5a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5H16v-1a.51.51,0,0,0-.86-.36l-2,2a.37.37,0,0,0,0,.53l2,2.05A.51.51,0,0,0,16,5.94V5Z',
  gesture_swipe_left_right:
    'M18,14.47v.27a4.87,4.87,0,0,1-.19,1.37l-1,3.39a6,6,0,0,1-1.52,2.59l0,0a3,3,0,0,1-2.12.88H10.58a3,3,0,0,1-2.12-.88L6.08,19.76c-1.34-1.35-1.3-3.22-2.48-4.91L2.09,12.7a.51.51,0,0,1,.12-.7,1.52,1.52,0,0,1,1.9.15L7,15V4A1,1,0,0,1,9,4v6.5a.5.5,0,0,0,1,0V8.74h2.25a3,3,0,0,1,2.13.88l2.73,2.72A3.09,3.09,0,0,1,18,14.47ZM21.89,3.72l-2-2a.51.51,0,0,0-.86.36V3H15v-1a.49.49,0,0,0-.85-.35l-2,2a.39.39,0,0,0,0,.53l2,2A.5.5,0,0,0,15,5.94V5h4v.94a.51.51,0,0,0,.86.36l2-2A.37.37,0,0,0,21.89,3.72Z',
  gesture_swipe_left_right_2f:
    'M17,14.47v.27a4.87,4.87,0,0,1-.19,1.37l-1,3.39a6,6,0,0,1-1.52,2.59l0,0a3,3,0,0,1-2.12.88H9.58a3,3,0,0,1-2.12-.88L5.08,19.76c-1.34-1.35-1.3-3.22-2.48-4.91L1.09,12.7a.51.51,0,0,1,.12-.7,1.52,1.52,0,0,1,1.9.15L6,15V4A1,1,0,0,1,8,4v6.5a.5.5,0,0,0,1,0V3a1,1,0,0,1,2,0v7.5a.5.5,0,0,0,1,0V8.84a3,3,0,0,1,1.38.78l2.73,2.72A3.09,3.09,0,0,1,17,14.47ZM22.89,3.72l-2-2a.51.51,0,0,0-.86.36V3H16v-1a.49.49,0,0,0-.85-.35l-2,2a.39.39,0,0,0,0,.53l2,2A.5.5,0,0,0,16,5.94V5h4v.94a.51.51,0,0,0,.86.36l2-2A.37.37,0,0,0,22.89,3.72Z',
  gesture_swipe_right:
    'M18,14.47v.27a4.87,4.87,0,0,1-.19,1.37l-1,3.39a6,6,0,0,1-1.52,2.59l0,0a3,3,0,0,1-2.12.88H10.58a3,3,0,0,1-2.12-.88L6.08,19.76c-1.34-1.35-1.3-3.22-2.48-4.91L2.09,12.7a.51.51,0,0,1,.12-.7,1.52,1.52,0,0,1,1.9.15L7,15V4A1,1,0,0,1,9,4v6.5a.5.5,0,0,0,1,0V8.74h2.25a3,3,0,0,1,2.13.88l2.73,2.72A3.09,3.09,0,0,1,18,14.47Zm-5-10a.5.5,0,0,0,.5.5H19v.94a.51.51,0,0,0,.86.36l2-2a.37.37,0,0,0,0-.53l-2-2a.51.51,0,0,0-.86.36V3H13.5a.5.5,0,0,0-.5.5Z',
  gesture_swipe_right_2f:
    'M17,14.47v.27a4.87,4.87,0,0,1-.19,1.37l-1,3.39a6,6,0,0,1-1.52,2.59l0,0a3,3,0,0,1-2.12.88H9.58a3,3,0,0,1-2.12-.88L5.08,19.76c-1.34-1.35-1.3-3.22-2.48-4.91L1.09,12.7a.51.51,0,0,1,.12-.7,1.52,1.52,0,0,1,1.9.15L6,15V4A1,1,0,0,1,8,4v6.5a.5.5,0,0,0,1,0V3a1,1,0,0,1,2,0v7.5a.5.5,0,0,0,1,0V8.84a3,3,0,0,1,1.38.78l2.73,2.72A3.09,3.09,0,0,1,17,14.47Zm-4-10a.5.5,0,0,0,.5.5H19v.94a.51.51,0,0,0,.86.36l2-2a.37.37,0,0,0,0-.53l-2-2a.51.51,0,0,0-.86.36V3H13.5a.5.5,0,0,0-.5.5Z',
  hand_basic:
    'M21,5.5v9.91a6,6,0,0,1-.79,3L17.58,23a2,2,0,0,1-1.74,1H10.48a4,4,0,0,1-2.6-1L3.75,19.5A5,5,0,0,1,2,15.7V10a1,1,0,0,1,.91-1A2,2,0,0,1,5,11v2.65a2,2,0,0,0,.69,1.51l.31.27V2.5a1.5,1.5,0,0,1,3,0v8a.5.5,0,0,0,1,0v-9a1.5,1.5,0,0,1,3,0v9a.5.5,0,0,0,1,0v-8a1.5,1.5,0,0,1,3,0v8a.5.5,0,0,0,1,0v-5a1.5,1.5,0,0,1,3,0Z',
  hand_draw:
    'M18,13.47v.27a4.87,4.87,0,0,1-.19,1.37l-1,3.39a6,6,0,0,1-1.52,2.59l0,0a3,3,0,0,1-2.12.88H10.58a3,3,0,0,1-2.12-.88L6.08,18.76c-1.34-1.35-1.3-3.22-2.48-4.91L2.09,11.7a.51.51,0,0,1,.12-.7,1.52,1.52,0,0,1,1.9.15L7,14V3A1,1,0,0,1,9,3V9.5a.5.5,0,0,0,1,0V7.74h2.25a3,3,0,0,1,2.13.88l2.73,2.72A3.09,3.09,0,0,1,18,13.47ZM10.5,4h1a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5v1A.5.5,0,0,0,10.5,4Zm3.67.59.15.14a.5.5,0,0,0,.71,0l.81-.81a.34.34,0,0,0,0-.49l-.25-.26a3.84,3.84,0,0,0-1.28-.84.37.37,0,0,0-.31,0,.39.39,0,0,0-.17.26l-.16,1.27a.53.53,0,0,0,.22.48A1.23,1.23,0,0,1,14.17,4.59ZM21.5,6h-1a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,21.5,6Zm-3.67-.59-.15-.14a.5.5,0,0,0-.71,0l-.81.81a.34.34,0,0,0,0,.49l.25.26a3.82,3.82,0,0,0,1.35.87.32.32,0,0,0,.3,0,.32.32,0,0,0,.17-.25l.16-1.26a.51.51,0,0,0-.23-.48A1.7,1.7,0,0,1,17.83,5.41Z',
  hand_metal_horns:
    'M22,5.5v9.9c0,1.1-0.3,2.1-0.8,3L18.6,23c-0.4,0.6-1,1-1.7,1h-4.1c-0.5,0-0.9-0.2-1.2-0.4l-3.7-3c-0.5-0.4-0.9-0.8-1.2-1.4 l-0.9-1.4c-0.4-0.6-0.9-1-1.6-1.1l-1.3-0.5l0,0l-0.4-0.1C2.1,16,2,15.8,2,15.6v-0.1C2,14.7,2.7,14,3.5,14c0,0,0,0,0,0h0.8 c0.4,0,0.9,0.1,1.3,0.2L8,15V2.5C8,1.7,8.7,1,9.5,1S11,1.7,11,2.5v8c0,0.3,0.2,0.5,0.5,0.5s0.5-0.2,0.5-0.5V9c0-0.6,0.4-1,1-1h4 c0.6,0,1,0.4,1,1v1.5c0,0.3,0.2,0.5,0.5,0.5s0.5-0.2,0.5-0.5v-5C19,4.7,19.7,4,20.5,4S22,4.7,22,5.5z',
  hand_middle_finger:
    'M20,12.74v2.67a6,6,0,0,1-.79,3L16.58,23a2,2,0,0,1-1.74,1H11.49a3,3,0,0,1-2.12-.88L5.75,19.5A5,5,0,0,1,4,15.7V14a2,2,0,0,1,2-2H6v1.65a2,2,0,0,0,.69,1.51l.31.31V10A2,2,0,0,1,9,8h1V10.5a.5.5,0,0,0,1,0v-9a1.5,1.5,0,0,1,3,0v9a.5.5,0,0,0,1,0V8h.48a3,3,0,0,1,1.9.86l1.74,1.74A3,3,0,0,1,20,12.74Z',
  hand_ok:
    'M20,5.89l-1.81,6.76a4.07,4.07,0,0,0-.14,1V22a1,1,0,0,1-1,1H11a1,1,0,0,1-1-1v-.11a1.51,1.51,0,0,0-.78-1.32L6,18.83a4,4,0,0,1-1.95-2.51l-1-4a1,1,0,0,1,.61-1.2,2,2,0,0,1,2.54,1.39l.3,1.11a1,1,0,0,0,.72.71l1.19.3a2,2,0,0,0,2.3-1.09l.24-.51a2,2,0,0,0-.81-2.58l-.32-.18A2,2,0,0,0,8.29,10L4.89,11a1.5,1.5,0,1,1-.78-2.9l4.2-1.13a3,3,0,0,1,2.28.3l.66.39a.5.5,0,1,0,.5-.86l-1.65-1L9.09,3a1.5,1.5,0,0,1,1.14-2,1.57,1.57,0,0,1,1.71,1l2.4,6.61a.5.5,0,0,0,.94-.34L14,4.82V2.5a1.5,1.5,0,0,1,3,0v5a.5.5,0,0,0,1,0V4.09a1.49,1.49,0,0,1,.89,0A1.51,1.51,0,0,1,20,5.89Z',
  hand_piece:
    'M11.49,24a3,3,0,0,1-2.12-.88L5.75,19.5A5,5,0,0,1,4,15.7V14a2,2,0,0,1,2-2H6v1.65a2,2,0,0,0,.69,1.51l.31.31V10.76L5.66,3.14a1.5,1.5,0,1,1,2.95-.52l1.41,8a.49.49,0,0,0,.49.41.48.48,0,0,0,.47-.4l1.41-8a1.5,1.5,0,1,1,2.95.52L14.48,8a3,3,0,0,1,1.9.86l2.74,2.74A3,3,0,0,1,20,13.74v1.67a6,6,0,0,1-.79,3L16.58,23a2,2,0,0,1-1.74,1Z',
  hand_wave:
    'M18.71,12.09a1,1,0,0,1,0,1.42l-5.19,5.18a5.07,5.07,0,0,1-1.11.84L9.32,21.25A6,6,0,0,1,6.41,22h0a3,3,0,0,1-2.12-.88L2.88,19.77A3.29,3.29,0,0,1,2,17.34v-4.1C2,11.34,3.37,10,3.73,8l.45-2.59A.5.5,0,0,1,4.76,5,1.49,1.49,0,0,1,6,6.45v4.21l7.37-7.37a1,1,0,0,1,1.41,0,1,1,0,0,1,0,1.42L10.34,9.14a.5.5,0,1,0,.71.71l5.31-5.31a1,1,0,0,1,1.42,0,1,1,0,0,1,0,1.41l-5.31,5.31a.5.5,0,0,0,0,.71.5.5,0,0,0,.7,0l4.12-4.12a1,1,0,0,1,1.42,0,1,1,0,0,1,0,1.41l-4.12,4.12a.5.5,0,0,0,0,.71.5.5,0,0,0,.7,0l2-2A1,1,0,0,1,18.71,12.09Zm3.16,3.59a.52.52,0,0,0-.37-.16h-1A.5.5,0,0,0,20,16a4.51,4.51,0,0,1-4,4,.49.49,0,0,0-.45.5v1a.52.52,0,0,0,.16.37.53.53,0,0,0,.38.13A6.5,6.5,0,0,0,22,16.06.53.53,0,0,0,21.87,15.68ZM7.65,5A4.34,4.34,0,0,1,10,4a.49.49,0,0,0,.45-.5v-1a.52.52,0,0,0-.16-.37A.53.53,0,0,0,9.94,2,6.44,6.44,0,0,0,6.31,3.49,3.54,3.54,0,0,1,7.65,5Z',
  neutral_face:
    'M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20ZM15,8a2,2,0,0,0-2,2v.5a.5.5,0,0,0,.5.5h3a.5.5,0,0,0,.5-.5V10A2,2,0,0,0,15,8Zm-4,2.5V10a2,2,0,0,0-4,0v.5a.5.5,0,0,0,.5.5h3A.5.5,0,0,0,11,10.5ZM14.5,14h-5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h5a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,14.5,14Z',
  protection:
    'M20.71,12.29a1,1,0,0,0-1.42,0L16,15.59h0A2.5,2.5,0,0,1,13.5,18h-4a.5.5,0,0,1,0-1h4l.23,0h0A1.49,1.49,0,0,0,15,15.35,1.57,1.57,0,0,0,13.4,14H8.71a3.32,3.32,0,0,0-2.59.91L3.29,17.82a1,1,0,0,0,0,1.41l2.48,2.48a1,1,0,0,0,1.41,0L8.6,20.29A1,1,0,0,1,9.3,20h4.08a2.51,2.51,0,0,0,1.77-.73l5.56-5.56A1,1,0,0,0,20.71,12.29ZM3.29,11.71a1,1,0,0,0,1.42,0L8,8.41H8A2.5,2.5,0,0,1,10.5,6h4a.5.5,0,0,1,0,1h-4l-.23,0h0A1.49,1.49,0,0,0,9,8.65,1.57,1.57,0,0,0,10.6,10h4.69a3.32,3.32,0,0,0,2.59-.91l2.83-2.91a1,1,0,0,0,0-1.41L18.23,2.29a1,1,0,0,0-1.41,0L15.4,3.71a1,1,0,0,1-.7.29H10.62a2.51,2.51,0,0,0-1.77.73L3.29,10.29A1,1,0,0,0,3.29,11.71Z',
  sad:
    'M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20ZM15,8a2,2,0,0,0-2,2v.5a.5.5,0,0,0,.5.5h3a.5.5,0,0,0,.5-.5V10A2,2,0,0,0,15,8Zm-4.5,3a.5.5,0,0,0,.5-.5V10a2,2,0,0,0-4,0v.5a.5.5,0,0,0,.5.5ZM12,13a5.51,5.51,0,0,0-4.46,2.29.49.49,0,0,0-.08.39A.51.51,0,0,0,7.7,16l.87.51a.51.51,0,0,0,.65-.13,3.47,3.47,0,0,1,5.56,0,.51.51,0,0,0,.65.13L16.3,16a.51.51,0,0,0,.24-.33.49.49,0,0,0-.08-.39A5.51,5.51,0,0,0,12,13Z',
  slap:
    'M14.59,15.38a.5.5,0,0,0,0,.71.5.5,0,0,0,.7,0l2-2a1,1,0,0,1,1.42,1.42l-5.19,5.18a5.07,5.07,0,0,1-1.11.84L9.32,23.25A6,6,0,0,1,6.41,24h0a3,3,0,0,1-2.12-.88L2.88,21.77A3.29,3.29,0,0,1,2,19.34V14.07a4,4,0,0,1,1.17-2.83l4-4a.51.51,0,0,1,.71,0,1.51,1.51,0,0,1,0,2.12L6.71,10.53a2,2,0,0,0-.58,1.41v.59h0l7.24-7.24a1,1,0,0,1,1.41,0,1,1,0,0,1,0,1.42l-4.44,4.43a.5.5,0,1,0,.71.71l5.31-5.31a1,1,0,0,1,1.42,0,1,1,0,0,1,0,1.41l-5.31,5.31a.5.5,0,0,0,0,.71.5.5,0,0,0,.7,0l4.12-4.12a1,1,0,0,1,1.42,0,1,1,0,0,1,0,1.41ZM15.5,4h1a.5.5,0,0,0,.5-.5v-2a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5v2A.5.5,0,0,0,15.5,4Zm5.8-.6-.7-.7a.5.5,0,0,0-.71,0L18.47,4.11a.51.51,0,0,0,0,.71l.71.71a.51.51,0,0,0,.71,0L21.3,4.11A.5.5,0,0,0,21.3,3.4ZM22.5,7h-2a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h2a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,22.5,7Z',
  slightly_smilling:
    'M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20ZM15,8a2,2,0,0,0-2,2v.5a.5.5,0,0,0,.5.5h3a.5.5,0,0,0,.5-.5V10A2,2,0,0,0,15,8Zm-4.5,3a.5.5,0,0,0,.5-.5V10a2,2,0,0,0-4,0v.5a.5.5,0,0,0,.5.5Zm5.8,3-.87-.51a.51.51,0,0,0-.65.13,3.48,3.48,0,0,1-5.56,0,.51.51,0,0,0-.65-.13L7.7,14a.51.51,0,0,0-.24.33.49.49,0,0,0,.08.39,5.49,5.49,0,0,0,8.92,0,.49.49,0,0,0,.08-.39A.51.51,0,0,0,16.3,14Z',
  smile:
    'M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20Zm4-6.43a.49.49,0,0,0-.12-.4.49.49,0,0,0-.38-.17H8.54a.49.49,0,0,0-.38.17.49.49,0,0,0-.12.4,4,4,0,0,0,7.92,0ZM15,8a2,2,0,0,0-2,2v.5a.5.5,0,0,0,.5.5h3a.5.5,0,0,0,.5-.5V10A2,2,0,0,0,15,8Zm-4,2.5V10a2,2,0,0,0-4,0v.5a.5.5,0,0,0,.5.5h3A.5.5,0,0,0,11,10.5Z',
  thumbs_down:
    'M19,4.5a.5.5,0,0,1,.5-.5h2a.5.5,0,0,1,.5.5v9a.5.5,0,0,1-.5.5h-2a.5.5,0,0,1-.5-.5ZM4,14H9L7.45,17.11a4.15,4.15,0,0,0-.45,2v.37A1.5,1.5,0,0,0,8.5,21h.14a.5.5,0,0,0,.47-.34l.6-1.78a2,2,0,0,1,1.15-1.22l.43-.17a4.06,4.06,0,0,0,1.9-1.6l1.22-2a2,2,0,0,1,1.7-.94H17V5h-.59A3.41,3.41,0,0,1,14,4h0a3.41,3.41,0,0,0-2.41-1H5.35A2,2,0,0,0,3.5,4.26L2.43,6.93A6.06,6.06,0,0,0,2,9.16V12A2,2,0,0,0,4,14Z',
  thumbs_up:
    'M5,19.5a.5.5,0,0,1-.5.5h-2a.5.5,0,0,1-.5-.5v-9a.5.5,0,0,1,.5-.5h2a.5.5,0,0,1,.5.5ZM20,10H15l1.55-3.11a4.15,4.15,0,0,0,.45-2V4.5A1.5,1.5,0,0,0,15.5,3h-.14a.5.5,0,0,0-.47.34l-.6,1.78a2,2,0,0,1-1.15,1.22l-.43.17a4.06,4.06,0,0,0-1.9,1.6l-1.22,2a2,2,0,0,1-1.7.94H7v8h.59A3.41,3.41,0,0,1,10,20h0a3.41,3.41,0,0,0,2.41,1h6.24a2,2,0,0,0,1.85-1.26l1.07-2.67A6.06,6.06,0,0,0,22,14.84V12A2,2,0,0,0,20,10Z',
  thumbs_up_down:
    'M1.57,14.42l1.72-1.76a4.74,4.74,0,0,1,1.34-1l.77-.39a2.06,2.06,0,0,0,.9-.9l.42-.85A1,1,0,0,1,7.62,9H8a1,1,0,0,1,1,1v.35a3.93,3.93,0,0,1-.21,1.27L8,14h3.5A1.5,1.5,0,0,1,13,15.5v2.56a3.92,3.92,0,0,1-.42,1.78l-1,2.05A2,2,0,0,1,9.76,23H4.91a3.11,3.11,0,0,1-1.67-.5l-1.35-.91A2,2,0,0,1,1,19.93V15.82A2,2,0,0,1,1.57,14.42ZM23,8.18V4.07a2,2,0,0,0-.89-1.66L20.76,1.5A3.11,3.11,0,0,0,19.09,1H14.24a2,2,0,0,0-1.79,1.11l-1,2.05A3.92,3.92,0,0,0,11,5.94V8.5A1.5,1.5,0,0,0,12.5,10H16l-.79,2.38A3.93,3.93,0,0,0,15,13.65V14a1,1,0,0,0,1,1h.38a1,1,0,0,0,.9-.55l.42-.85a2.06,2.06,0,0,1,.9-.9l.77-.39a4.74,4.74,0,0,0,1.34-1l1.72-1.76A2,2,0,0,0,23,8.18Z',
  wink:
    'M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20ZM15,8a2,2,0,0,0-2,2v.5a.5.5,0,0,0,.5.5h3a.5.5,0,0,0,.5-.5V10A2,2,0,0,0,15,8Zm1.3,6-.87-.51a.51.51,0,0,0-.65.13,3.48,3.48,0,0,1-5.56,0,.51.51,0,0,0-.65-.13L7.7,14a.51.51,0,0,0-.24.33.49.49,0,0,0,.08.39,5.49,5.49,0,0,0,8.92,0,.49.49,0,0,0,.08-.39A.51.51,0,0,0,16.3,14Zm-5.8-3a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5h-3a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5Z',
  blood_transfusion:
    'M19.5,11h-1a.5.5,0,0,0-.5.5V19a1,1,0,0,1-1,1H14a1,1,0,0,1-1-1V18h1a2,2,0,0,0,2-2V6a2,2,0,0,0-2-2H12A2,2,0,0,0,8,4H6A2,2,0,0,0,4,6V16a2,2,0,0,0,2,2H7v1.5a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5V18h2v1a3,3,0,0,0,3,3h3a3,3,0,0,0,3-3V11.5A.5.5,0,0,0,19.5,11ZM6,16V6h8V16Zm4-3a2,2,0,0,0,2-2,6.27,6.27,0,0,0-2-3h0a6.09,6.09,0,0,0-2,3A2,2,0,0,0,10,13Z',
  corona_virus:
    'M21.5,10h-1a.5.5,0,0,0-.5.5.5.5,0,0,1-.5.5H18.32a.51.51,0,0,1-.49-.38,5.76,5.76,0,0,0-.73-1.77.49.49,0,0,1,.08-.61L18,7.4a.51.51,0,0,1,.71,0,.48.48,0,0,0,.7,0l.71-.7a.5.5,0,0,0,0-.71L18,3.87a.5.5,0,0,0-.71,0l-.7.71a.48.48,0,0,0,0,.7.51.51,0,0,1,0,.71l-.84.83a.49.49,0,0,1-.61.08A6,6,0,0,0,13,6.09V4.5a.5.5,0,0,1,.5-.5.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5h-3a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5.5.5,0,0,1,.5.5V5.68a.51.51,0,0,1-.38.49,5.76,5.76,0,0,0-1.77.73.49.49,0,0,1-.61-.08L7.4,6a.51.51,0,0,1,0-.71.48.48,0,0,0,0-.7l-.7-.71a.5.5,0,0,0-.71,0L3.87,6a.5.5,0,0,0,0,.71l.71.7a.48.48,0,0,0,.7,0A.51.51,0,0,1,6,7.4l.83.84a.49.49,0,0,1,.08.61,5.76,5.76,0,0,0-.73,1.77.51.51,0,0,1-.49.38H4.5a.5.5,0,0,1-.5-.5.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5v3a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5.5.5,0,0,1,.5-.5H5.68a.51.51,0,0,1,.49.38,5.76,5.76,0,0,0,.73,1.77.49.49,0,0,1-.08.61L6,16.6a.51.51,0,0,1-.71,0,.48.48,0,0,0-.7,0l-.71.7a.5.5,0,0,0,0,.71L6,20.13a.5.5,0,0,0,.71,0l.7-.71a.48.48,0,0,0,0-.7.51.51,0,0,1,0-.71l.84-.83a.49.49,0,0,1,.61-.08,5.76,5.76,0,0,0,1.77.73.51.51,0,0,1,.38.49V19.5a.5.5,0,0,1-.5.5.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h3a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5.5.5,0,0,1-.5-.5V18.32a.51.51,0,0,1,.38-.49,5.76,5.76,0,0,0,1.77-.73.49.49,0,0,1,.61.08l.84.83a.51.51,0,0,1,0,.71.48.48,0,0,0,0,.7l.7.71a.5.5,0,0,0,.71,0L20.13,18a.5.5,0,0,0,0-.71l-.71-.7a.48.48,0,0,0-.7,0,.51.51,0,0,1-.71,0l-.83-.84a.49.49,0,0,1-.08-.61,5.76,5.76,0,0,0,.73-1.77.51.51,0,0,1,.49-.38H19.5a.5.5,0,0,1,.5.5.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5v-3A.5.5,0,0,0,21.5,10Zm-11,2A1.5,1.5,0,1,1,12,10.5,1.5,1.5,0,0,1,10.5,12Z',
  corona_virus_stop:
    'M20.85,20.62a.48.48,0,0,1,0,.7l-.53.53a.48.48,0,0,1-.7,0L15,17.21a6.12,6.12,0,0,1-1.59.62.51.51,0,0,0-.38.49V19.5a.5.5,0,0,0,.5.5.5.5,0,0,1,.5.5v1a.5.5,0,0,1-.5.5h-3a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5.5.5,0,0,0,.5-.5V18.32a.51.51,0,0,0-.38-.49,5.76,5.76,0,0,1-1.77-.73.49.49,0,0,0-.61.08L7.4,18a.51.51,0,0,0,0,.71.48.48,0,0,1,0,.7l-.7.71a.5.5,0,0,1-.71,0L3.87,18a.5.5,0,0,1,0-.71l.71-.7a.48.48,0,0,1,.7,0,.51.51,0,0,0,.71,0l.83-.84a.49.49,0,0,0,.08-.61,5.76,5.76,0,0,1-.73-1.77A.51.51,0,0,0,5.68,13H4.5a.5.5,0,0,0-.5.5.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5v-3a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5.5.5,0,0,0,.5.5H5.68a.51.51,0,0,0,.49-.38A5.74,5.74,0,0,1,6.8,9L3.15,5.38a.48.48,0,0,1,0-.7l.53-.53a.48.48,0,0,1,.7,0ZM21.5,10h-1a.5.5,0,0,0-.5.5.5.5,0,0,1-.5.5H18.32a.51.51,0,0,1-.49-.38,5.76,5.76,0,0,0-.73-1.77.49.49,0,0,1,.08-.61L18,7.4a.51.51,0,0,1,.71,0,.48.48,0,0,0,.7,0l.71-.7a.5.5,0,0,0,0-.71L18,3.87a.5.5,0,0,0-.71,0l-.7.71a.48.48,0,0,0,0,.7.51.51,0,0,1,0,.71l-.84.83a.49.49,0,0,1-.61.08,5.76,5.76,0,0,0-1.77-.73A.51.51,0,0,1,13,5.68V4.5a.5.5,0,0,1,.5-.5.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5h-3a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5.5.5,0,0,1,.5.5V5.68a.51.51,0,0,1-.38.49,5.52,5.52,0,0,0-1.27.47l8,8a5.52,5.52,0,0,0,.47-1.27.51.51,0,0,1,.49-.38H19.5a.5.5,0,0,1,.5.5.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5v-3A.5.5,0,0,0,21.5,10Z',
  cover_safety_glasses:
    'M22.53,10.75l-2.6-5.6A2,2,0,0,0,18.11,4H16.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h1.61l1.73,3.71-4.22-.38c-1.2-.11-2.41-.17-3.62-.17s-2.42.06-3.62.17l-4.22.38L5.89,6H7.5A.5.5,0,0,0,8,5.5v-1A.5.5,0,0,0,7.5,4H5.89A2,2,0,0,0,4.07,5.15l-2.6,5.6A5,5,0,0,0,1,12.86v.59a5.67,5.67,0,0,0,.1,1.09l.53,2.88A3,3,0,0,0,4.6,20H8.75a2,2,0,0,0,1.8-1.11l.17-.34a1,1,0,0,1,.9-.55h.76a1,1,0,0,1,.9.55l.16.32A2,2,0,0,0,15.24,20H19.4a3,3,0,0,0,3-2.58l.53-2.88a5.67,5.67,0,0,0,.1-1.09v-.59A5,5,0,0,0,22.53,10.75ZM21,13.82l-.6,3.24v.08a1,1,0,0,1-1,.86H15.24l-.45-.89A2,2,0,0,0,13,16H11a2,2,0,0,0-1.79,1.11L8.76,18H4.6a1,1,0,0,1-1-.86v-.08L3,13.82v-.08a1,1,0,0,1,.91-1l4.65-.42c1.14-.1,2.3-.16,3.44-.16s2.3.06,3.44.16l4.65.42a1,1,0,0,1,.91,1Z',
  disinfectant:
    'M15.26,8.87a1,1,0,0,0-1-.87H11a1,1,0,0,0-1,1v.76a3,3,0,0,1-.88,2.12L7.88,13.12A3,3,0,0,0,7,15.24V22a1,1,0,0,0,1,1h8a1,1,0,0,0,1-1.13ZM14,19H9.5a.5.5,0,0,1-.5-.5v-3a.5.5,0,0,1,.5-.5h4.12a.51.51,0,0,1,.5.43l.4,3A.51.51,0,0,1,14,19ZM7,2.5v-1A.5.5,0,0,1,7.5,1h4.79a2.94,2.94,0,0,1,1.34.32l1.09.54a.51.51,0,0,1,.28.45V3.5a.5.5,0,0,1-.5.5H14a1,1,0,0,0-1,1V6H11V3.92A6.84,6.84,0,0,1,9.74,5.55L8.36,6.86a.5.5,0,0,1-.7,0L7.14,6.3a.5.5,0,0,1,0-.7L8.53,4.28A5,5,0,0,0,9.48,3h-2A.5.5,0,0,1,7,2.5Z',
  face_mask:
    'M20.5,6a.5.5,0,0,0-.5.5V8h-.3a1,1,0,0,1-.62-.22L16,5.31A6,6,0,0,0,12.25,4h-.5A6,6,0,0,0,8,5.31L4.92,7.78A1,1,0,0,1,4.3,8H4V6.5A.5.5,0,0,0,3.5,6h-1a.5.5,0,0,0-.5.5v4.63A5,5,0,0,0,3.78,15l4.47,3.89A5,5,0,0,0,11.45,20h1.1a5,5,0,0,0,3.22-1.17L20.21,15A5,5,0,0,0,22,11.13V6.5a.5.5,0,0,0-.5-.5ZM11,13.5v-3a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5v3a.5.5,0,0,1-.5.5h-1A.5.5,0,0,1,11,13.5Zm0-6v-1a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5v1a.5.5,0,0,1-.5.5h-1A.5.5,0,0,1,11,7.5Zm0,10v-1a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5v1a.5.5,0,0,1-.5.5h-1A.5.5,0,0,1,11,17.5Z',
  face_mask_outlined:
    'M20.5,6a.5.5,0,0,0-.5.5V8h-.3a1,1,0,0,1-.62-.22L16,5.31A6,6,0,0,0,12.25,4h-.5A6,6,0,0,0,8,5.31L4.92,7.78A1,1,0,0,1,4.3,8H4V6.5A.5.5,0,0,0,3.5,6h-1a.5.5,0,0,0-.5.5v4.63A5,5,0,0,0,3.78,15l4.47,3.89A5,5,0,0,0,11.45,20h1.1a5,5,0,0,0,3.22-1.17L20.21,15A5,5,0,0,0,22,11.13V6.5a.5.5,0,0,0-.5-.5ZM20,11.13a3,3,0,0,1-1.08,2.3L14.47,17.3A2.9,2.9,0,0,1,13,18V16.5a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5V18a3,3,0,0,1-1.46-.64L5.08,13.43A3,3,0,0,1,4,11.13V10h.3a3,3,0,0,0,1.87-.66L9.26,6.88A3.91,3.91,0,0,1,11,6.08V7.5a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5V6.08a3.91,3.91,0,0,1,1.74.8l3.09,2.46A3,3,0,0,0,19.7,10H20ZM11,13.5v-3a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5v3a.5.5,0,0,1-.5.5h-1A.5.5,0,0,1,11,13.5Z',
  glove:
    'M21.71,10.68a1,1,0,1,0-1.42-1.42l-2,2a.5.5,0,0,1-.7,0,.5.5,0,0,1,0-.71l3.12-3.12a1,1,0,0,0,0-1.41,1,1,0,0,0-1.42,0L16.17,9.14a.5.5,0,0,1-.7,0,.5.5,0,0,1,0-.71l4.31-4.31a1,1,0,0,0,0-1.41,1,1,0,0,0-1.42,0L14.05,7a.5.5,0,0,1-.71-.71L17,2.71a1,1,0,0,0-1.42-1.42L10,6.83V2.62A1.49,1.49,0,0,0,8.76,1.18a.5.5,0,0,0-.58.4L7.73,4.17C7.37,6.21,6,7.51,6,9.41v1.32A3,3,0,0,1,4.94,13L2.35,15.21A1,1,0,0,0,2,16v.61a1,1,0,0,0,.54.89,3.3,3.3,0,0,1,1.3.72c.74.75.39,1.1,1.13,1.84s1.1.39,1.84,1.14a3.21,3.21,0,0,1,.72,1.29,1,1,0,0,0,.89.54H9a1,1,0,0,0,.7-.29l12-12h0Z',
  hand_disinfection:
    'M20.71,12.29a1,1,0,0,0-1.42,0L16,15.59h0A2.5,2.5,0,0,1,13.5,18h-4a.5.5,0,0,1,0-1h4l.23,0h0A1.49,1.49,0,0,0,15,15.35,1.57,1.57,0,0,0,13.4,14H8.71a3.32,3.32,0,0,0-2.59.91L3.29,17.82a1,1,0,0,0,0,1.41l2.48,2.48a1,1,0,0,0,1.41,0L8.6,20.29A1,1,0,0,1,9.3,20h4.08a2.51,2.51,0,0,0,1.77-.73l5.56-5.56A1,1,0,0,0,20.71,12.29ZM15,12a2,2,0,0,0,2-2,6.27,6.27,0,0,0-2-3h0a6.09,6.09,0,0,0-2,3A2,2,0,0,0,15,12ZM8.48,2h.06a.33.33,0,0,1,.23.1C9.52,2.94,11,4.52,11,5.5a2.5,2.5,0,0,1-5,0C6,4.53,7.46,3,8.22,2.12A.32.32,0,0,1,8.48,2Z',
  lungs:
    'M19.73,10.47A11.88,11.88,0,0,1,21,15.83V20a1,1,0,0,1-1,1h-.34a4,4,0,0,1-2.83-1.17l-1.49-1.49A8,8,0,0,1,13,12.69V10a1,1,0,0,0-2,0v2.69a8,8,0,0,1-2.34,5.65L7.17,19.83A4,4,0,0,1,4.34,21H4a1,1,0,0,1-1-1V15.83a11.88,11.88,0,0,1,1.27-5.36L6.45,6.11A2,2,0,0,1,8.24,5h.35a1,1,0,0,1,.7.29l.86.86a.49.49,0,0,0,.54.1A.48.48,0,0,0,11,5.79V3.5a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5V5.79a.48.48,0,0,0,.31.46.49.49,0,0,0,.54-.1l.86-.86a1,1,0,0,1,.7-.29h.35a2,2,0,0,1,1.79,1.11Z',
  microscope:
    'M4,17.5v-1a.5.5,0,0,1,.5-.5H9.77l-1,2H4.5A.5.5,0,0,1,4,17.5ZM20,13v8a1,1,0,0,1-1,1H4.5a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5H10l3.72-7.45a1,1,0,0,1,.9-.55H16V10a3,3,0,0,0-3-3H10v4a1,1,0,0,1-1,1H8v1.5a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5V12H5a1,1,0,0,1-1-1V3A1,1,0,0,1,5,2H9a1,1,0,0,1,1,1V5h3a5,5,0,0,1,5,5v2h1A1,1,0,0,1,20,13ZM8,4H6v6H8ZM18,17.5v-1a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h1A.5.5,0,0,0,18,17.5Z',
  quarantine:
    'M19,21a1,1,0,0,0,1-1V13h1.5a.5.5,0,0,0,.5-.5V11.41a1,1,0,0,0-.29-.7L14.29,3.29a1,1,0,0,0-.7-.29H10.41a1,1,0,0,0-.7.29L2.29,10.71a1,1,0,0,0-.29.7V12.5a.5.5,0,0,0,.5.5H4v7a1,1,0,0,0,1,1ZM12,7.75A2.25,2.25,0,1,1,9.75,10,2.24,2.24,0,0,1,12,7.75ZM16.25,18H7.75a.75.75,0,0,1-.67-1.09L8,15.1A2,2,0,0,1,9.77,14h4.46A2,2,0,0,1,16,15.1l.9,1.81A.75.75,0,0,1,16.25,18Z',
  quarantine_outlined:
    'M19,21a1,1,0,0,0,1-1V14h1.5a.5.5,0,0,0,.5-.5V11.41a1,1,0,0,0-.29-.7L14.29,3.29a1,1,0,0,0-.7-.29H10.41a1,1,0,0,0-.7.29L2.29,10.71a1,1,0,0,0-.29.7V13.5a.5.5,0,0,0,.5.5H4v6a1,1,0,0,0,1,1ZM6,19V12.5a.5.5,0,0,0-.5-.5H4.05a.89.89,0,0,1,.24-.46L10.83,5h2.34l6.54,6.54A.89.89,0,0,1,20,12H18.5a.5.5,0,0,0-.5.5V19Zm8-8a2,2,0,1,1-2-2A2,2,0,0,1,14,11ZM8.05,16.28l.73-1.45A1.5,1.5,0,0,1,10.12,14h3.76a1.5,1.5,0,0,1,1.34.83L16,16.28a.5.5,0,0,1-.45.72h-7A.5.5,0,0,1,8.05,16.28Z',
  respirator:
    'M20,9.41l1.85-1.85a.5.5,0,0,0,0-.71l-.7-.7a.5.5,0,0,0-.71,0L18.32,8.26c-.21-.11-.41-.22-.64-.32a6.09,6.09,0,0,1-2-1.45L14.34,5a3,3,0,0,0-2.28-1h-.12A3,3,0,0,0,9.66,5L8.32,6.49a6,6,0,0,1-2,1.45c-.23.1-.43.21-.64.32L3.56,6.15a.5.5,0,0,0-.71,0l-.7.7a.5.5,0,0,0,0,.71L4,9.41a5.33,5.33,0,0,0-2,3.95C2,17,6.49,20,12,20s10-3,10-6.64A5.33,5.33,0,0,0,20,9.41ZM12,18c-4.34,0-8-2.13-8-4.64C4,12,5.17,10.64,7.14,9.76A7.92,7.92,0,0,0,9.81,7.83l1.33-1.49a1.09,1.09,0,0,1,.8-.34h.12a1.09,1.09,0,0,1,.8.34l1.33,1.49a8.21,8.21,0,0,0,2.67,1.93c2,.88,3.14,2.23,3.14,3.6C20,15.87,16.34,18,12,18Zm1.5-6h-3a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h3a.5.5,0,0,1,.5.5v1A.5.5,0,0,1,13.5,12Zm0,4h-3a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h3a.5.5,0,0,1,.5.5v1A.5.5,0,0,1,13.5,16Z',
  saline:
    'M19.5,11h-1a.5.5,0,0,0-.5.5V19a1,1,0,0,1-1,1H14a1,1,0,0,1-1-1V18h1a2,2,0,0,0,2-2V6a2,2,0,0,0-2-2H12A2,2,0,0,0,8,4H6A2,2,0,0,0,4,6V16a2,2,0,0,0,2,2H7v1.5a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5V18h2v1a3,3,0,0,0,3,3h3a3,3,0,0,0,3-3V11.5A.5.5,0,0,0,19.5,11ZM6,16V14H7.5a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5H6V10H7.5A.5.5,0,0,0,8,9.5v-1A.5.5,0,0,0,7.5,8H6V6h8V16Z',
  soap:
    'M17,10V9a1,1,0,0,0-1-1H12a1,1,0,0,0-1,1v1a2,2,0,0,0-2,2v9a1,1,0,0,0,1,1h8a1,1,0,0,0,1-1V12A2,2,0,0,0,17,10Zm0,7.5a.5.5,0,0,1-.5.5h-5a.5.5,0,0,1-.5-.5v-5a.5.5,0,0,1,.5-.5h5a.5.5,0,0,1,.5.5ZM9.24,4a2,2,0,0,0-1.41.59L6.56,5.85a.5.5,0,0,1-.71,0l-.7-.7a.5.5,0,0,1,0-.71L6.41,3.17A4,4,0,0,1,9.24,2H16.5a.5.5,0,0,1,.5.5v1a.5.5,0,0,1-.5.5H15V6H13V4ZM7,10a2,2,0,0,1-4,0A6.09,6.09,0,0,1,5,7H5A6.27,6.27,0,0,1,7,10Z',
  thermometer:
    'M19,15.89a8.08,8.08,0,0,0-.84-3.58l-.95-1.89A2,2,0,0,1,17,9.53V4a2,2,0,0,0-2-2H6A1,1,0,0,0,5,3V7.28a1,1,0,0,0,.68,1L9.43,9.48a4,4,0,0,1,2.31,2l.72,1.44a8,8,0,0,1,.74,2.27l.59,3.55a4,4,0,0,0,1.12,2.17l.5.5a2,2,0,0,0,1.42.59H18a1,1,0,0,0,1-1ZM15,8H11.32L9,7.23V4h6Zm-4.33,5.82A5.4,5.4,0,0,1,11.11,15H9a1,1,0,0,1-1-1V11.11l.76.25a2,2,0,0,1,1.19,1Z',
  vaccine:
    'M20.85,6.68,17.32,3.15a.5.5,0,0,0-.71,0l-.71.7a.51.51,0,0,0,0,.71L17,5.62l-2.2,2.21L12.28,5.35a.5.5,0,0,0-.7,0l-.71.71a.5.5,0,0,0,0,.71l.71.7-6.7,6.7a2,2,0,0,0-.48,2.06l.42,1.24L2.15,20.15A.5.5,0,0,0,2.5,21H3.7a1.05,1.05,0,0,0,.71-.29L6.09,19l1.68.57a2.05,2.05,0,0,0,.64.11,2,2,0,0,0,1.42-.59l6.7-6.7.7.71a.5.5,0,0,0,.71,0l.71-.71a.5.5,0,0,0,0-.7L16.17,9.24,18.38,7,19.44,8.1a.51.51,0,0,0,.71,0l.7-.71A.5.5,0,0,0,20.85,6.68ZM13.12,13H8.88L13,8.91l0,0L15.11,11Z',
  washing_hands:
    'M21.71,13.09a1,1,0,0,1,0,1.42l-5.19,5.18a5.07,5.07,0,0,1-1.11.84l-3.09,1.72A6.06,6.06,0,0,1,9.41,23H9.36a3,3,0,0,1-2.12-.88L5.88,20.77A3.29,3.29,0,0,1,5,18.34v-4.1C5,12.34,6.37,11,6.73,9l.45-2.59A.5.5,0,0,1,7.76,6,1.49,1.49,0,0,1,9,7.45v4.21l7.53-7.54A1,1,0,1,1,18,5.54l-4.61,4.6a.5.5,0,1,0,.71.71l5.31-5.31a1,1,0,0,1,1.42,0,1,1,0,0,1,0,1.41l-5.31,5.31a.5.5,0,0,0,0,.71.5.5,0,0,0,.7,0l4.12-4.12a1,1,0,0,1,1.42,0,1,1,0,0,1,0,1.41l-4.12,4.12a.5.5,0,0,0,0,.71.5.5,0,0,0,.7,0l2-2A1,1,0,0,1,21.71,13.09ZM4.93,10.54,6,4.69a.94.94,0,0,0,0-.41,1.5,1.5,0,0,0-1.19-1.1.5.5,0,0,0-.58.4L3.73,6.17h0C3.37,8.21,2,9.51,2,11.41v4.1a3.29,3.29,0,0,0,.88,2.43l.63.62a1.62,1.62,0,0,1,0-.22v-4.1A5.49,5.49,0,0,1,4.93,10.54ZM12,6a2,2,0,0,0,2-2,6.27,6.27,0,0,0-2-3h0a6.09,6.09,0,0,0-2,3A2,2,0,0,0,12,6Z',
  alt:
    'M22,17.5v1a.5.5,0,0,1-.5.5h-5a2,2,0,0,1-1.67-.89L7.46,7h-5A.5.5,0,0,1,2,6.5v-1A.5.5,0,0,1,2.5,5h5a2,2,0,0,1,1.67.89L16.54,17h5A.5.5,0,0,1,22,17.5ZM21.5,5h-5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h5a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,21.5,5Z',
  backspace:
    'M21,4H10.33a2,2,0,0,0-1.42.59L2.29,11.21a1,1,0,0,0-.29.7v.18a1,1,0,0,0,.29.7l6.62,6.62a2,2,0,0,0,1.42.59H21a1,1,0,0,0,1-1V5A1,1,0,0,0,21,4Zm-3.91,9.86a.48.48,0,0,1,0,.7l-.53.53a.48.48,0,0,1-.7,0L14,13.24l-1.86,1.85a.48.48,0,0,1-.7,0l-.53-.53a.48.48,0,0,1,0-.7L12.76,12l-1.85-1.86a.48.48,0,0,1,0-.7l.53-.53a.48.48,0,0,1,.7,0L14,10.76l1.86-1.85a.48.48,0,0,1,.7,0l.53.53a.48.48,0,0,1,0,.7L15.24,12Z',
  backspace_outlined:
    'M21,4H10.33a2,2,0,0,0-1.42.59L2.29,11.21a1,1,0,0,0-.29.7v.18a1,1,0,0,0,.29.7l6.62,6.62a2,2,0,0,0,1.42.59H21a1,1,0,0,0,1-1V5A1,1,0,0,0,21,4ZM20,18H10.33l-6-6,6-6H20Zm-9.09-3.44.53.53a.48.48,0,0,0,.7,0L14,13.24l1.86,1.85a.48.48,0,0,0,.7,0l.53-.53a.48.48,0,0,0,0-.7L15.24,12l1.85-1.86a.48.48,0,0,0,0-.7l-.53-.53a.48.48,0,0,0-.7,0L14,10.76,12.14,8.91a.48.48,0,0,0-.7,0l-.53.53a.48.48,0,0,0,0,.7L12.76,12l-1.85,1.86A.48.48,0,0,0,10.91,14.56Z',
  caps_lock:
    'M6.85,12.85l-.7-.71a.48.48,0,0,1,0-.7l5.13-5.15a.75.75,0,0,1,.53-.22h.38a.77.77,0,0,1,.53.22l5.13,5.15a.48.48,0,0,1,0,.7l-.71.71a.5.5,0,0,1-.7,0L12,8.4,7.56,12.85A.5.5,0,0,1,6.85,12.85ZM6,16.5v1a.5.5,0,0,0,.5.5h11a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5H6.5A.5.5,0,0,0,6,16.5Z',
  command_key:
    'M17.5,14H16V10h1.5A3.5,3.5,0,1,0,14,6.5V8H10V6.5A3.5,3.5,0,1,0,6.5,10H8v4H6.5A3.5,3.5,0,1,0,10,17.5V16h4v1.5A3.5,3.5,0,1,0,17.5,14ZM16,6.5A1.5,1.5,0,1,1,17.5,8H16ZM6.5,8A1.5,1.5,0,1,1,8,6.5V8ZM8,17.5A1.5,1.5,0,1,1,6.5,16H8ZM10,14V10h4v4Zm7.5,5A1.5,1.5,0,0,1,16,17.5V16h1.5a1.5,1.5,0,0,1,0,3Z',
  enter:
    'M21,6.5a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5V11H6.33L9.77,7.56a.49.49,0,0,0,0-.7l-.71-.71a.5.5,0,0,0-.71,0L3.22,11.28a.77.77,0,0,0-.22.53v.38a.75.75,0,0,0,.22.53l5.14,5.13a.5.5,0,0,0,.71,0l.71-.7a.5.5,0,0,0,0-.71L6.33,13H19a2,2,0,0,0,2-2ZM5.33,12Z',
  keyboard_brightness_1:
    'M23,15.5v1a.5.5,0,0,1-.5.5h-3a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h3A.5.5,0,0,1,23,15.5ZM4.5,15h-3a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h3a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,4.5,15Zm12,0h-9a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h9a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,16.5,15Zm-5-6h1a.5.5,0,0,0,.5-.5v-3a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5v3A.5.5,0,0,0,11.5,9ZM7.4,10,5.28,7.87a.48.48,0,0,0-.7,0l-.71.71a.48.48,0,0,0,0,.7L6,11.4a.5.5,0,0,0,.71,0l.7-.7A.5.5,0,0,0,7.4,10ZM20.13,8.58l-.71-.71a.48.48,0,0,0-.7,0L16.6,10a.5.5,0,0,0,0,.71l.7.7a.5.5,0,0,0,.71,0l2.12-2.12A.48.48,0,0,0,20.13,8.58Z',
  keyboard_brightness_2:
    'M21,15.5v1a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h1A.5.5,0,0,1,21,15.5ZM4.5,15h-1a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,4.5,15Zm12,0h-9a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h9a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,16.5,15Zm-5-6h1a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5v1A.5.5,0,0,0,11.5,9ZM18,9.28a.51.51,0,0,0-.71,0l-.7.71a.5.5,0,0,0,0,.71l.7.7a.5.5,0,0,0,.71,0l.71-.7a.51.51,0,0,0,0-.71ZM7.4,10l-.7-.71a.51.51,0,0,0-.71,0L5.28,10a.51.51,0,0,0,0,.71l.71.7a.5.5,0,0,0,.71,0l.7-.7A.5.5,0,0,0,7.4,10Z',
  space_bar:
    'M5,15V11.5a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5V15a2,2,0,0,0,2,2H19a2,2,0,0,0,2-2V11.5a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5V15Z',
  tab_key:
    'M2.5,13H14.67l-3.45,3.44a.5.5,0,0,0,0,.71l.71.7a.5.5,0,0,0,.71,0l5.14-5.13a.75.75,0,0,0,.22-.53v-.38a.77.77,0,0,0-.22-.53L12.64,6.15a.5.5,0,0,0-.71,0l-.71.71a.49.49,0,0,0,0,.7L14.67,11H2.5a.5.5,0,0,0-.5.5v1A.5.5,0,0,0,2.5,13Zm13.17-1ZM22,17.5V6.5a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5v11a.5.5,0,0,0,.5.5h1A.5.5,0,0,0,22,17.5Z',
  '360_rotate':
    'M12,4C6.48,4,2,7.13,2,11a6.4,6.4,0,0,0,3.43,5.28,9,9,0,0,0,4.68,1.22H11v2a.5.5,0,0,0,.85.35l3-3a.5.5,0,0,0,0-.7l-3-3a.5.5,0,0,0-.85.35v2h-.89a7,7,0,0,1-3.64-.93A4.45,4.45,0,0,1,4,11c0-2.71,3.66-5,8-5s8,2.29,8,5a4.35,4.35,0,0,1-2.3,3.45.49.49,0,0,0-.15.68l.53.86a.51.51,0,0,0,.69.16A6.27,6.27,0,0,0,22,11C22,7.13,17.52,4,12,4Z',
  beijing:
    'M23,19.28l-1.87-3.73a1,1,0,0,0-.89-.55H19V13h1.88a2,2,0,0,0,1.41-.59l.56-.56A.5.5,0,0,0,22.5,11H18V9h1.88a2,2,0,0,0,1.41-.59l.56-.56A.5.5,0,0,0,21.5,7H19a2,2,0,0,1-2-2V4.5a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5v1a.5.5,0,0,1-.5.5h-5A.5.5,0,0,1,9,5.5v-1A.5.5,0,0,0,8.5,4h-1a.5.5,0,0,0-.5.5V5A2,2,0,0,1,5,7H2.5a.5.5,0,0,0-.35.85l.56.56A2,2,0,0,0,4.12,9H6v2H1.5a.5.5,0,0,0-.35.85l.56.56A2,2,0,0,0,3.12,13H5v2H3.81a1,1,0,0,0-.89.55L1.05,19.28A.5.5,0,0,0,1.5,20h21A.5.5,0,0,0,23,19.28ZM16,9v2H8V9ZM7,13H17v2H7Z',
  bratislava:
    'M23,6.7a1.94,1.94,0,0,0-.46-1.26L20.72,3.27A.74.74,0,0,0,20.15,3h-.3a.74.74,0,0,0-.57.27L18,4.8,16.72,3.27A.74.74,0,0,0,16.15,3h-.3a.74.74,0,0,0-.57.27L13.46,5.44A1.94,1.94,0,0,0,13,6.7V8H7V6.7a1.94,1.94,0,0,0-.46-1.26L4.72,3.27A.74.74,0,0,0,4.15,3h-.3a.74.74,0,0,0-.57.27L1.46,5.44A1.94,1.94,0,0,0,1,6.7V20a1,1,0,0,0,1,1H22a1,1,0,0,0,1-1ZM19,12h2v7H19Zm-2,7H3V7H5V9.5a.5.5,0,0,0,.5.5h9a.5.5,0,0,0,.5-.5V7h2m2,3V7h2v3ZM5.5,14h1a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5v1A.5.5,0,0,0,5.5,14Zm8,0h1a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5v1A.5.5,0,0,0,13.5,14Zm-4,0h1a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5v1A.5.5,0,0,0,9.5,14Z',
  broadleaf_tree:
    'M13.16,17.91l.29,3.55A.49.49,0,0,1,13,22H11a.49.49,0,0,1-.49-.54l.29-3.55a8,8,0,1,1,2.32,0Z',
  broadleaf_tree_outlined:
    'M20,10a8,8,0,1,0-9.16,7.91l-.29,3.55A.49.49,0,0,0,11,22H13a.49.49,0,0,0,.49-.54l-.29-3.55A8,8,0,0,0,20,10Zm-8,6a6,6,0,1,1,6-6A6,6,0,0,1,12,16Z',
  cafe:
    'M19,5H17V4a1,1,0,0,0-1-1H4A1,1,0,0,0,3,4V5.46a5.46,5.46,0,0,0,.1,1.07l1.45,8A3,3,0,0,0,7.5,17h5a3,3,0,0,0,2.95-2.46L16.09,11H19a2,2,0,0,0,2-2V7A2,2,0,0,0,19,5Zm0,4H16.45l.37-2H19ZM17.5,19H3.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h14a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,17.5,19Z',
  cafe_outlined:
    'M19,5H17V4a1,1,0,0,0-1-1H4A1,1,0,0,0,3,4V5.46a5.46,5.46,0,0,0,.1,1.07l1.45,8A3,3,0,0,0,7.5,17h5a3,3,0,0,0,2.95-2.46L16.09,11H19a2,2,0,0,0,2-2V7A2,2,0,0,0,19,5Zm-4,.46a4.17,4.17,0,0,1-.06.71l-1.46,8a1,1,0,0,1-1,.82h-5a1,1,0,0,1-1-.82l-1.46-8A4.17,4.17,0,0,1,5,5.46V5H15ZM19,9H16.45l.37-2H19ZM17.5,19H3.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h14a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,17.5,19Z',
  cairo:
    'M22.86,18.45l-5.71-7.14a.75.75,0,0,0-.6-.31h-.32a.78.78,0,0,0-.61.31l-1.83,2.3L10,8.31A.75.75,0,0,0,9.36,8H9.05a.77.77,0,0,0-.61.31L1.14,18.45a.74.74,0,0,0,0,.81l.21.36A.74.74,0,0,0,2,20H22a.74.74,0,0,0,.65-.38l.21-.36A.74.74,0,0,0,22.86,18.45ZM3.93,18l5.28-7.33L11.49,18ZM18,8a2,2,0,1,0-2-2A2,2,0,0,0,18,8Z',
  castle:
    'M20,3.5V8.76a3,3,0,0,1-.88,2.12l-1.83,1.83a1,1,0,0,0-.29.7V20.5a.5.5,0,0,1-.5.5h-2a.5.5,0,0,1-.5-.5V16.41a1,1,0,0,0-.29-.7l-.42-.42a1,1,0,0,0-.7-.29H11.41a1,1,0,0,0-.7.29l-.42.42a1,1,0,0,0-.29.7V20.5a.5.5,0,0,1-.5.5h-2a.5.5,0,0,1-.5-.5V13.41a1,1,0,0,0-.29-.7L4.88,10.88A3,3,0,0,1,4,8.76V3.5A.5.5,0,0,1,4.5,3h2a.5.5,0,0,1,.5.5v2a.5.5,0,0,0,.5.5h2a.5.5,0,0,0,.5-.5v-2a.5.5,0,0,1,.5-.5h3a.5.5,0,0,1,.5.5v2a.5.5,0,0,0,.5.5h2a.5.5,0,0,0,.5-.5v-2a.5.5,0,0,1,.5-.5h2A.5.5,0,0,1,20,3.5Z',
  city:
    'M23,19.5a.5.5,0,0,0-.5-.5H20V8a1,1,0,0,0-1-1H15V4a1,1,0,0,0-1-1H10A1,1,0,0,0,9,4v7H5a1,1,0,0,0-1,1v7H1.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h21a.5.5,0,0,0,.5-.5ZM16.5,9h1a.5.5,0,0,1,.5.5v1a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5v-1A.5.5,0,0,1,16.5,9Zm0,4h1a.5.5,0,0,1,.5.5v1a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5v-1A.5.5,0,0,1,16.5,13Zm0,4h1a.5.5,0,0,1,.5.5v1a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5v-1A.5.5,0,0,1,16.5,17Zm-5-8h1a.5.5,0,0,1,.5.5v1a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5v-1A.5.5,0,0,1,11.5,9Zm0,4h1a.5.5,0,0,1,.5.5v1a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5v-1A.5.5,0,0,1,11.5,13ZM13,5.5v1a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h1A.5.5,0,0,1,13,5.5ZM7.5,19h-1a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5v1A.5.5,0,0,1,7.5,19Zm0-4h-1a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5v1A.5.5,0,0,1,7.5,15ZM11,18.5v-1a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5v1a.5.5,0,0,1-.5.5h-1A.5.5,0,0,1,11,18.5Z',
  clinic:
    'M19,3H5A2,2,0,0,0,3,5V19a2,2,0,0,0,2,2H19a2,2,0,0,0,2-2V5A2,2,0,0,0,19,3ZM18,13.5a.5.5,0,0,1-.5.5H14v3.5a.5.5,0,0,1-.5.5h-3a.5.5,0,0,1-.5-.5V14H6.5a.5.5,0,0,1-.5-.5v-3a.5.5,0,0,1,.5-.5H10V6.5a.5.5,0,0,1,.5-.5h3a.5.5,0,0,1,.5.5V10h3.5a.5.5,0,0,1,.5.5Z',
  company:
    'M22.5,19H19V4a1,1,0,0,0-1-1H6A1,1,0,0,0,5,4V19H1.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h21a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,22.5,19Zm-6-8h-1a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5v1A.5.5,0,0,1,16.5,11Zm-8,0h-1a.5.5,0,0,1-.5-.5v-1A.5.5,0,0,1,7.5,9h1a.5.5,0,0,1,.5.5v1A.5.5,0,0,1,8.5,11ZM9,13.5v1a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h1A.5.5,0,0,1,9,13.5Zm6.5-.5h1a.5.5,0,0,1,.5.5v1a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5v-1A.5.5,0,0,1,15.5,13Zm1-6h-1a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5v1A.5.5,0,0,1,16.5,7ZM9,5.5v1a.5.5,0,0,1-.5.5h-1A.5.5,0,0,1,7,6.5v-1A.5.5,0,0,1,7.5,5h1A.5.5,0,0,1,9,5.5ZM7.5,17h9a.5.5,0,0,1,.5.5V19H7V17.5A.5.5,0,0,1,7.5,17Zm5-6h-1a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5v1A.5.5,0,0,1,12.5,11Zm-1,2h1a.5.5,0,0,1,.5.5v1a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5v-1A.5.5,0,0,1,11.5,13Zm1-6h-1a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5v1A.5.5,0,0,1,12.5,7Z',
  conifer:
    'M18.91,17.38l-.14.25a.76.76,0,0,1-.65.37h-5l.28,3.46A.49.49,0,0,1,13,22H11a.49.49,0,0,1-.49-.54L10.83,18h-5a.76.76,0,0,1-.65-.37l-.14-.25c-.14-.24-.14-.52.27-1.22L7.79,12H7.17a.76.76,0,0,1-.65-.37l-.14-.25c-.14-.24-.14-.52.27-1.22l4.56-7.79A.74.74,0,0,1,11.86,2h.28a.74.74,0,0,1,.64.37l4.65,8c.32.54.32.82.18,1.06l-.14.25a.76.76,0,0,1-.65.37H16.2l2.53,4.32C19.05,16.86,19.05,17.14,18.91,17.38Z',
  conifer_outlined:
    'M18.73,16.32,16.2,12h.62a.76.76,0,0,0,.65-.37l.14-.25c.14-.24.14-.52-.18-1.06L12.78,2.37A.74.74,0,0,0,12.14,2h-.28a.74.74,0,0,0-.65.37L6.65,10.16c-.41.7-.41,1-.27,1.22l.14.25a.76.76,0,0,0,.65.37h.62L5.35,16.16c-.41.7-.41,1-.27,1.22l.14.25a.76.76,0,0,0,.65.37h5l-.28,3.46A.49.49,0,0,0,11,22H13a.49.49,0,0,0,.49-.54L13.17,18h5a.76.76,0,0,0,.65-.37l.14-.25C19.05,17.14,19.05,16.86,18.73,16.32ZM7.77,16l3.29-5.62a.26.26,0,0,0-.22-.38H9.06L12,5l2.93,5H13.15a.25.25,0,0,0-.21.38L16.23,16Z',
  direction:
    'M22.71,11.21,12.79,1.29a1,1,0,0,0-.7-.29h-.18a1,1,0,0,0-.7.29L1.29,11.21a1,1,0,0,0-.29.7v.18a1,1,0,0,0,.29.7l9.92,9.92a1,1,0,0,0,.7.29h.18a1,1,0,0,0,.7-.29l9.92-9.92a1,1,0,0,0,.29-.7v-.18A1,1,0,0,0,22.71,11.21Zm-4.85.14-3,3A.5.5,0,0,1,14,14V12H9v2.5a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5V11a1,1,0,0,1,1-1h6V8a.5.5,0,0,1,.85-.35l3,3A.51.51,0,0,1,17.86,11.35Z',
  dubai:
    'M19.5,21H18a19.45,19.45,0,0,0-2.64-10H17.5a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5H13.93A11,11,0,0,0,8,5.23V1.5A.5.5,0,0,0,7.5,1h-1a.5.5,0,0,0-.5.5V9H4.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5H6V21H4.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h15a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,19.5,21ZM9.4,15H15a17.79,17.79,0,0,1,.56,2h-6Zm4.79-2h-5L9,11h4A14.55,14.55,0,0,1,14.19,13ZM8,7.29A8.61,8.61,0,0,1,11.13,9H8ZM9.8,19h6.09c.06.65.11,1.32.11,2H10Z',
  earth_east:
    'M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18A8,8,0,0,1,11,4.07V4.5a.5.5,0,0,1-.5.5.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h3a.48.48,0,0,1,.5.48.51.51,0,0,0,.5.52A1.5,1.5,0,0,1,16,9.5V11h1a1,1,0,0,0,1-1V9h1.41A7.9,7.9,0,0,1,20,12,8,8,0,0,1,12,20Zm2-10V9.5a.5.5,0,0,0-.5-.5h-2a.5.5,0,0,1-.5-.5.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5V9a1,1,0,0,1-1,1,1,1,0,0,0-1,1v1a1,1,0,0,0,1,1H9.59a1,1,0,0,1,.7.29l.42.42a1,1,0,0,1,.29.7V17a1,1,0,0,0,1,1h.5a.5.5,0,0,0,.5-.5.5.5,0,0,1,.5-.5A1.5,1.5,0,0,0,15,15.5v-2a.49.49,0,0,1,.45-.5.52.52,0,0,0,.55-.48V11H15A1,1,0,0,1,14,10Z',
  earth_west:
    'M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm6,15.27V17a1,1,0,0,0-1-1h-.5a.5.5,0,0,1-.5-.5.5.5,0,0,0-.5-.5h-1a.5.5,0,0,1-.5-.5.5.5,0,0,0-.5-.5H12v1.5a.51.51,0,0,0,.52.5.48.48,0,0,1,.48.5v3.43A8.26,8.26,0,0,1,12,20,8,8,0,0,1,5.08,8H5.5a.5.5,0,0,1,.5.5V11a1,1,0,0,0,1,1h.5a.5.5,0,0,1,.5.5V13a1,1,0,0,0,1,1h3V12.5a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,1,0-1H11a1,1,0,0,0,1-1V9a1,1,0,0,1,1-1h.5a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5H6.73A8,8,0,0,1,18,17.27Z',
  education:
    'M22,9.92V20.5a.5.5,0,0,1-.5.5h-.75a.5.5,0,0,1-.5-.5V11.6l-7.14,5.14a1.49,1.49,0,0,1-.84.26h-.54a1.49,1.49,0,0,1-.84-.26L2.22,10.49A.49.49,0,0,1,2,10.08V9.92a.49.49,0,0,1,.22-.41l8.67-6.25A1.49,1.49,0,0,1,11.73,3h.54a1.49,1.49,0,0,1,.84.26l8.67,6.25A.49.49,0,0,1,22,9.92Zm-9.73,8.83h-.54a3.33,3.33,0,0,1-1.87-.59L6,15.37V17c0,2.21,2.69,4,6,4s6-1.79,6-4V15.37l-3.9,2.81A3.25,3.25,0,0,1,12.27,18.75Z',
  education_outlined:
    'M21.78,9.51,13.11,3.26A1.49,1.49,0,0,0,12.27,3h-.54a1.49,1.49,0,0,0-.84.26L2.22,9.51A.49.49,0,0,0,2,9.92v.16a.49.49,0,0,0,.22.41l8.67,6.25a1.49,1.49,0,0,0,.84.26h.54a1.49,1.49,0,0,0,.84-.26l7.14-5.14v8.9a.5.5,0,0,0,.5.5h.75a.5.5,0,0,0,.5-.5V9.92A.49.49,0,0,0,21.78,9.51ZM12.11,15H11.9L5,10l6.93-5h.21L19,10Zm.16,3.75h-.54a3.33,3.33,0,0,1-1.87-.59L6,15.37V17c0,2.21,2.69,4,6,4s6-1.79,6-4V15.37l-3.9,2.81A3.25,3.25,0,0,1,12.27,18.75Z',
  factory:
    'M7.5,20h-1a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5v1A.5.5,0,0,1,7.5,20Zm0-4h-1a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5v1A.5.5,0,0,1,7.5,16Zm5,4h-1a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5v1A.5.5,0,0,1,12.5,20Zm0-4h-1a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5v1A.5.5,0,0,1,12.5,16Zm5,4h-1a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5v1A.5.5,0,0,1,17.5,20Zm0-4h-1a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5v1A.5.5,0,0,1,17.5,16Zm3-13a1,1,0,0,0-1-1H16.53a1,1,0,0,0-1,1l-.25,8L14,11.77V9.5a.5.5,0,0,0-.5-.5h0a1,1,0,0,0-.55.17L9,11.77V9.5A.5.5,0,0,0,8.5,9h0a1,1,0,0,0-.55.17L3.22,12.29a.5.5,0,0,0-.22.42V20H1.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h21a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5H21Z',
  gas_station:
    'M20.56,7.22,16.48,3.15a.48.48,0,0,0-.7,0l-.36.35a.51.51,0,0,0,0,.71L17.5,6.29V8.66a1.5,1.5,0,0,0,.44,1.06l1.56,1.57V17.5a1,1,0,0,1-2,0V13a2,2,0,0,0-2-2H14V5a2,2,0,0,0-2-2H6A2,2,0,0,0,4,5V19a1,1,0,0,0,1,1h8a1,1,0,0,0,1-1V12.5h1.5a.5.5,0,0,1,.5.5v4.5a2.5,2.5,0,0,0,5,0V8.29A1.54,1.54,0,0,0,20.56,7.22ZM12,9H6V5h6Z',
  globe:
    'M12,3a9,9,0,1,0,9,9A9,9,0,0,0,12,3Zm6.92,8H16.35a14.12,14.12,0,0,0-1.18-5.23A7,7,0,0,1,18.92,11Zm-6.47,8L12,19l-.45,0c-.91-.57-2-2.76-2.14-6h5.18C14.41,16.22,13.36,18.41,12.45,19Zm-3-8c.18-3.22,1.23-5.41,2.14-6L12,5l.45,0c.91.57,2,2.76,2.14,6ZM8.83,5.77A14.12,14.12,0,0,0,7.65,11H5.08A7,7,0,0,1,8.83,5.77ZM5.08,13H7.65a14.12,14.12,0,0,0,1.18,5.23A7,7,0,0,1,5.08,13Zm10.09,5.23A14.12,14.12,0,0,0,16.35,13h2.57A7,7,0,0,1,15.17,18.23Z',
  gym:
    'M20.71,7.78a1,1,0,0,1,.29.71V9.66a1,1,0,0,1-.29.7L19.05,12a1,1,0,0,1-.71.29H17.17a1,1,0,0,1-.71-.29l-1.53-1.53-4.44,4.44L12,16.46a1,1,0,0,1,.29.71v1.17a1,1,0,0,1-.29.71l-1.66,1.66a1,1,0,0,1-.7.29H8.49a1,1,0,0,1-.71-.29L6.24,19.17,4.56,20.85a.5.5,0,0,1-.71,0l-.7-.7a.5.5,0,0,1,0-.71l1.68-1.68L3.29,16.22A1,1,0,0,1,3,15.51V14.34a1,1,0,0,1,.29-.7L5,12a1,1,0,0,1,.71-.29H6.83a1,1,0,0,1,.71.29l1.53,1.53,4.44-4.44L12,7.54a1,1,0,0,1-.29-.71V5.66A1,1,0,0,1,12,5l1.66-1.66a1,1,0,0,1,.7-.29h1.17a1,1,0,0,1,.71.29l1.54,1.54,1.68-1.68a.5.5,0,0,1,.71,0l.7.7a.5.5,0,0,1,0,.71L19.17,6.24Z',
  hospital:
    'M21.5,19H19V10a1,1,0,0,0-1-1H17V4a1,1,0,0,0-1-1H8A1,1,0,0,0,7,4V9H6a1,1,0,0,0-1,1v9H2.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h19a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,21.5,19Zm-13,0h-1a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5v1A.5.5,0,0,1,8.5,19Zm0-4h-1a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5v1A.5.5,0,0,1,8.5,15Zm4,4h-1a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5v1A.5.5,0,0,1,12.5,19Zm0-4h-1a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5v1A.5.5,0,0,1,12.5,15Zm2-6.12H12.88V10.5a.5.5,0,0,1-.5.5h-.76a.5.5,0,0,1-.5-.5V8.88H9.5a.5.5,0,0,1-.5-.5V7.62a.5.5,0,0,1,.5-.5h1.62V5.5a.5.5,0,0,1,.5-.5h.76a.5.5,0,0,1,.5.5V7.12H14.5a.5.5,0,0,1,.5.5v.76A.5.5,0,0,1,14.5,8.88ZM16.5,19h-1a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5v1A.5.5,0,0,1,16.5,19Zm0-4h-1a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5v1A.5.5,0,0,1,16.5,15Z',
  hotel:
    'M22,19.5a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5V16H4v3.5a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5V4.5A.5.5,0,0,1,2.5,4h1a.5.5,0,0,1,.5.5V13H21a1,1,0,0,1,1,1ZM20,8H12a2,2,0,0,0-2,2v1.5a.5.5,0,0,0,.5.5h11a.5.5,0,0,0,.5-.5V10A2,2,0,0,0,20,8ZM5.5,12h3a.5.5,0,0,0,.5-.5V11a2,2,0,0,0-4,0v.5A.5.5,0,0,0,5.5,12Z',
  location_off:
    'M4.38,3.15a.48.48,0,0,0-.7,0l-.53.53a.48.48,0,0,0,0,.7L5.77,7a7.84,7.84,0,0,0-1.7,4H2.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5H4.07A8,8,0,0,0,11,19.93V21.5a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5V19.93a7.84,7.84,0,0,0,4-1.7l2.63,2.62a.48.48,0,0,0,.7,0l.53-.53a.48.48,0,0,0,0-.7ZM12,18a6,6,0,0,1-6-6A5.92,5.92,0,0,1,7.19,8.43l8.38,8.38A5.92,5.92,0,0,1,12,18Zm9.5-7H19.93A8,8,0,0,0,13,4.07V2.5a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5V4.07a7.75,7.75,0,0,0-2.48.74L10,6.33A5.76,5.76,0,0,1,12,6a6,6,0,0,1,6,6,5.76,5.76,0,0,1-.33,2l1.52,1.52A7.75,7.75,0,0,0,19.93,13H21.5a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,21.5,11Z',
  location_search:
    'M21.5,11H19.93A8,8,0,0,0,13,4.07V2.5a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5V4.07A8,8,0,0,0,4.07,11H2.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5H4.07A8,8,0,0,0,11,19.93V21.5a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5V19.93A8,8,0,0,0,19.93,13H21.5a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,21.5,11ZM12,18a6,6,0,1,1,6-6A6,6,0,0,1,12,18Z',
  'machu picchu':
    'M22.23,13.84a1,1,0,0,0-1-.84H19.76l-.53-3.16a1,1,0,0,0-1-.84H16V5h.5a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5h-9a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5H8V9H5.76a1,1,0,0,0-1,.84L4.24,13H2.76a1,1,0,0,0-1,.84L1,18.42A.5.5,0,0,0,1.5,19H8v1.5a.5.5,0,0,0,.5.5h7a.5.5,0,0,0,.5-.5V19h6.5a.5.5,0,0,0,.49-.58ZM17.73,13H14.8l-.4-2h3ZM10,9V6.41a1,1,0,0,1,.29-.7l.42-.42a1,1,0,0,1,.7-.29h1.18a1,1,0,0,1,.7.29l.42.42a1,1,0,0,1,.29.7V9ZM6.6,11h3l-.4,2H6.27ZM3.27,17l.33-2H8.8l-.4,2ZM10,19l1.6-8h.72L14,19Zm5.56-2-.4-2h5.2l.33,2Z',
  map:
    'M20.5,3h-.3a2,2,0,0,0-.77.15L15,5,10.75,3.23A3,3,0,0,0,9.6,3H8.4a3,3,0,0,0-1.15.23L3.62,4.74A1,1,0,0,0,3,5.67V20.5a.5.5,0,0,0,.5.5h.3a2,2,0,0,0,.77-.15L9,19l4.25,1.77A3,3,0,0,0,14.4,21h1.2a3,3,0,0,0,1.15-.23l3.63-1.51a1,1,0,0,0,.62-.93V3.5A.5.5,0,0,0,20.5,3ZM14,18.92,9.77,17.15,9,16.83V5h.6a1,1,0,0,1,.38.08l4.25,1.77.77.32V19h-.6A1,1,0,0,1,14,18.92Z',
  map_outlined:
    'M20.5,3h-.3a2,2,0,0,0-.77.15L15,5,10.75,3.23A3,3,0,0,0,9.6,3H8.4a3,3,0,0,0-1.15.23L3.62,4.74A1,1,0,0,0,3,5.67V20.5a.5.5,0,0,0,.5.5h.3a2,2,0,0,0,.77-.15L9,19l4.25,1.77A3,3,0,0,0,14.4,21h1.2a3,3,0,0,0,1.15-.23l3.63-1.51a1,1,0,0,0,.62-.93V3.5A.5.5,0,0,0,20.5,3ZM5,6.33,8,5.08V17.25L5,18.5Zm5,10.92V5.08l4,1.67V18.92Zm9,.42-3,1.25V6.75L19,5.5Z',
  map_zoom_out:
    'M9.85,8.44a.5.5,0,0,1,0,.71l-.7.7a.5.5,0,0,1-.71,0L5.28,6.68,3.86,8.1A.5.5,0,0,1,3,7.75V3.5A.52.52,0,0,1,3.5,3H7.75a.5.5,0,0,1,.36.85L6.69,5.27Zm-.7,5.71a.5.5,0,0,0-.71,0L5.27,17.31,3.85,15.89a.5.5,0,0,0-.85.36V20.5a.52.52,0,0,0,.5.5H7.75a.5.5,0,0,0,.35-.85L6.68,18.72l3.17-3.16a.5.5,0,0,0,0-.71Zm11,1.75-1.42,1.42-3.16-3.17a.5.5,0,0,0-.71,0l-.7.7a.5.5,0,0,0,0,.71l3.16,3.17-1.42,1.42a.5.5,0,0,0,.36.85H20.5a.52.52,0,0,0,.5-.5V16.25A.5.5,0,0,0,20.14,15.9ZM20.5,3H16.25a.5.5,0,0,0-.35.85l1.42,1.42L14.15,8.44a.5.5,0,0,0,0,.71l.7.7a.5.5,0,0,0,.71,0l3.17-3.16,1.42,1.42A.5.5,0,0,0,21,7.75V3.5A.52.52,0,0,0,20.5,3Z',
  museum:
    'M4.5,17h15a.5.5,0,0,0,.5-.5v-2a.5.5,0,0,0-.5-.5H18V7h1.5a.5.5,0,0,0,.5-.5V4.67a1,1,0,0,0-.62-.92L13,1.15A2,2,0,0,0,12.22,1h-.44a2,2,0,0,0-.76.15l-6.4,2.6A1,1,0,0,0,4,4.67V6.5a.5.5,0,0,0,.5.5H6v7H4.5a.5.5,0,0,0-.5.5v2A.5.5,0,0,0,4.5,17ZM13,7h3v7H13ZM8,7h3v7H8ZM20.5,19H3.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h17a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,20.5,19Z',
  my_location:
    'M21.5,11H19.93A8,8,0,0,0,13,4.07V2.5a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5V4.07A8,8,0,0,0,4.07,11H2.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5H4.07A8,8,0,0,0,11,19.93V21.5a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5V19.93A8,8,0,0,0,19.93,13H21.5a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,21.5,11ZM12,18a6,6,0,1,1,6-6A6,6,0,0,1,12,18Zm0-8a2,2,0,1,0,2,2A2,2,0,0,0,12,10Z',
  navigate:
    'M19,19.93a.51.51,0,0,1-.12.54l-.38.38a.49.49,0,0,1-.68,0,8.43,8.43,0,0,0-11.58,0,.49.49,0,0,1-.68,0l-.38-.38A.51.51,0,0,1,5,19.93L11.38,3.32A.51.51,0,0,1,11.85,3h.3a.51.51,0,0,1,.47.32Z',
  navigate_outlined:
    'M12,7.3l3.82,10a10.1,10.1,0,0,0-7.64,0L12,7.3M12.15,3h-.3a.51.51,0,0,0-.47.32L5,19.93a.51.51,0,0,0,.12.54l.38.38a.49.49,0,0,0,.35.15.46.46,0,0,0,.33-.13A8.61,8.61,0,0,1,12,18.56a8.61,8.61,0,0,1,5.79,2.31.46.46,0,0,0,.33.13.49.49,0,0,0,.35-.15l.38-.38a.51.51,0,0,0,.12-.54L12.62,3.32A.51.51,0,0,0,12.15,3Z',
  neighborhood:
    'M23.5,19H22V15.29a1,1,0,0,0-.45-.84l-2-1.37a.57.57,0,0,0-.28-.08h-.45a.57.57,0,0,0-.28.08l-1.5,1V6.62a1,1,0,0,0-.44-.83l-4-2.71A.57.57,0,0,0,12.23,3h-.45a.57.57,0,0,0-.28.08l-4,2.7A1,1,0,0,0,7,6.62v7.46l-1.49-1A.57.57,0,0,0,5.23,13H4.78a.57.57,0,0,0-.28.08l-2,1.37a1,1,0,0,0-.45.84V19H.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h23a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,23.5,19ZM6,18.5a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5Zm5,0a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5Zm0-4a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5Zm0-4a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5v-1A.5.5,0,0,1,9.5,9h1a.5.5,0,0,1,.5.5Zm4,8a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5Zm0-4a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5Zm0-4a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5Zm5,8a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5Z',
  new_york:
    'M4,3.5A1.5,1.5,0,0,1,5.5,2V1.35A.35.35,0,0,1,5.85,1,2,2,0,0,1,7.5,3a2,2,0,0,1-2,2A1.5,1.5,0,0,1,4,3.5Zm6,7.12v1.67A1.71,1.71,0,0,0,11.71,14h.58A1.71,1.71,0,0,0,14,12.29V10.62l1.79-1.29a.51.51,0,0,0,.12-.7l-.29-.42a.51.51,0,0,0-.7-.12L14,8.71a.79.79,0,0,1-1.23-.64l0-1.58a.51.51,0,0,0-.5-.49h-.52a.51.51,0,0,0-.5.49l0,1.58A.79.79,0,0,1,10,8.71l-.91-.62a.51.51,0,0,0-.7.12l-.29.42a.51.51,0,0,0,.12.7Zm10.78,8.93A.75.75,0,0,0,21,19v-.38a.75.75,0,0,0-.22-.53l-1.4-1.4a1,1,0,0,0-1.42,0l-1,1a2,2,0,0,0-.58-1.32l-.77-.77A2,2,0,0,0,14.22,15H10.37a3,3,0,0,1-2.3-1.08l-.61-.73a3,3,0,0,1-.65-1.42L6.18,8H6.5A.5.5,0,0,0,7,7.5v-1A.5.5,0,0,0,6.5,6h-3a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h.65l.68,4.09a5.09,5.09,0,0,0,1.09,2.38l.08.09v1.91a1,1,0,0,0,.23.64L8,19.23V22.5a.5.5,0,0,0,.5.5h8.41a1,1,0,0,0,.71-.29Z',
  paris:
    'M19.88,22.36a30.81,30.81,0,0,1-3-4.36h.62a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5H15.84A30.23,30.23,0,0,1,14,11h.5a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5h-1a30,30,0,0,1-.44-3.29c0-.23,0-.47,0-.71h.46a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5H13V1.5a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5V3h-.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5H11c0,.24,0,.48,0,.71A30,30,0,0,1,10.48,9h-1a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5H10a30.23,30.23,0,0,1-1.84,5H6.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h.62a30.81,30.81,0,0,1-3,4.36.47.47,0,0,0-.12.32v.07a.25.25,0,0,0,.25.25H6.47a1,1,0,0,0,.82-.43c.33-.48.64-1,.93-1.46a4.44,4.44,0,0,1,7.56,0c.29.5.6,1,.93,1.46a1,1,0,0,0,.82.43h2.22a.25.25,0,0,0,.25-.25v-.07A.47.47,0,0,0,19.88,22.36Zm-7-6.36H11.08a.25.25,0,0,1-.23-.33c.25-.67.47-1.34.68-2a.49.49,0,0,1,.94,0c.21.68.43,1.35.68,2A.25.25,0,0,1,12.92,16Z',
  parking:
    'M18,2H6A2,2,0,0,0,4,4V20a2,2,0,0,0,2,2H18a2,2,0,0,0,2-2V4A2,2,0,0,0,18,2Zm-2,9a3,3,0,0,1-3,3H10v2.5a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5v-9A.5.5,0,0,1,8.5,7H13a3,3,0,0,1,3,3ZM13,9H10v3h3a1,1,0,0,0,1-1V10A1,1,0,0,0,13,9Z',
  parking_outlined:
    'M18,2H6A2,2,0,0,0,4,4V20a2,2,0,0,0,2,2H18a2,2,0,0,0,2-2V4A2,2,0,0,0,18,2Zm0,18H6V4H18ZM8.5,17h1a.5.5,0,0,0,.5-.5V14h3a3,3,0,0,0,3-3V10a3,3,0,0,0-3-3H8.5a.5.5,0,0,0-.5.5v9A.5.5,0,0,0,8.5,17ZM10,9h3a1,1,0,0,1,1,1v1a1,1,0,0,1-1,1H10Z',
  pharmacy:
    'M20.71,9.48a2,2,0,0,0,.29-1V8a1,1,0,0,0-1-1H18.14L19,3.89a.5.5,0,0,0-.35-.61l-1-.26a.51.51,0,0,0-.62.35L16.06,7H4A1,1,0,0,0,3,8v.45a2,2,0,0,0,.29,1L5.38,13a2,2,0,0,1,0,2.06L3.29,18.52a2,2,0,0,0-.29,1V20a1,1,0,0,0,1,1H20a1,1,0,0,0,1-1v-.45a2,2,0,0,0-.29-1L18.62,15a2,2,0,0,1,0-2.06ZM15,14.38a.5.5,0,0,1-.5.5H12.88V16.5a.5.5,0,0,1-.5.5h-.76a.5.5,0,0,1-.5-.5V14.88H9.5a.5.5,0,0,1-.5-.5v-.76a.5.5,0,0,1,.5-.5h1.62V11.5a.5.5,0,0,1,.5-.5h.76a.5.5,0,0,1,.5.5v1.62H14.5a.5.5,0,0,1,.5.5Z',
  pharmacy_outlined:
    'M20.71,9.48a2,2,0,0,0,.29-1V8a1,1,0,0,0-1-1H18.14L19,3.89a.5.5,0,0,0-.35-.61l-1-.26a.51.51,0,0,0-.62.35L16.06,7H4A1,1,0,0,0,3,8v.45a2,2,0,0,0,.29,1L5.38,13a2,2,0,0,1,0,2.06L3.29,18.52a2,2,0,0,0-.29,1V20a1,1,0,0,0,1,1H20a1,1,0,0,0,1-1v-.45a2,2,0,0,0-.29-1L18.62,15a2,2,0,0,1,0-2.06ZM16.9,16.06,18.67,19H5.33L7.1,16.06a4,4,0,0,0,0-4.12L5.33,9H18.67L16.9,11.94A4,4,0,0,0,16.9,16.06Zm-2.4-2.94H12.88V11.5a.5.5,0,0,0-.5-.5h-.76a.5.5,0,0,0-.5.5v1.62H9.5a.5.5,0,0,0-.5.5v.76a.5.5,0,0,0,.5.5h1.62V16.5a.5.5,0,0,0,.5.5h.76a.5.5,0,0,0,.5-.5V14.88H14.5a.5.5,0,0,0,.5-.5v-.76A.5.5,0,0,0,14.5,13.12Z',
  pin:
    'M20.34,9.14l-1.44.51a2,2,0,0,0-1,.78l-2.13,3.2a3,3,0,0,0-.52,1.69v1.49a1,1,0,0,1-.29.71l-.42.41a1,1,0,0,1-1.41,0L6.07,10.86a1,1,0,0,1,0-1.41L6.48,9a1,1,0,0,1,.71-.29H8.68a3,3,0,0,0,1.69-.52l3.2-2.13a2,2,0,0,0,.78-1l.51-1.44a1,1,0,0,1,1.65-.37l4.2,4.2A1,1,0,0,1,20.34,9.14ZM4.43,17.44a3,3,0,0,0-.67,1L3,20.27a.5.5,0,0,0,.11.54l0,0a.5.5,0,0,0,.54.11l1.81-.72a3,3,0,0,0,1-.67l2.91-2.91L7.35,14.52Z',
  pin_outlined:
    'M20.71,7.49l-4.2-4.2a1,1,0,0,0-.7-.29,1,1,0,0,0-1,.66L14.35,5.1a2,2,0,0,1-.78,1l-3.2,2.13a3,3,0,0,1-1.69.52H7.19A1,1,0,0,0,6.48,9l-.41.42a1,1,0,0,0,0,1.41l7.07,7.07a1,1,0,0,0,.71.29,1,1,0,0,0,.7-.29l.42-.41a1,1,0,0,0,.29-.71V15.32a3,3,0,0,1,.52-1.69l2.13-3.2a2,2,0,0,1,1-.78l1.44-.51A1,1,0,0,0,20.71,7.49ZM16.54,8.87l-3.85,5.79L9.34,11.31l5.79-3.85L16.49,6.1,17.9,7.51ZM4.43,17.44a3,3,0,0,0-.67,1L3,20.27a.5.5,0,0,0,.11.54l0,0a.5.5,0,0,0,.54.11l1.81-.72a3,3,0,0,0,1-.67l2.91-2.91L7.35,14.52Z',
  poi:
    'M12,2a8,8,0,0,0-8,8c0,6.22,5.05,6.17,6.26,9.78l.51,1.54a1,1,0,0,0,1,.68h.56a1,1,0,0,0,1-.68l.51-1.54C15,16.17,20,16.22,20,10A8,8,0,0,0,12,2Zm0,11a3,3,0,1,1,3-3A3,3,0,0,1,12,13Z',
  poi_add:
    'M16,10.5a.5.5,0,0,1-.5.5H13v2.5a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5V11H8.5a.5.5,0,0,1-.5-.5v-1A.5.5,0,0,1,8.5,9H11V6.5a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5V9h2.5a.5.5,0,0,1,.5.5ZM12,2a8,8,0,0,0-8,8c0,6.22,5.05,6.17,6.26,9.78l.51,1.54a1,1,0,0,0,1,.68h.56a1,1,0,0,0,1-.68l.51-1.54C15,16.17,20,16.22,20,10a8,8,0,0,0-8-8Z',
  poi_add_outlined:
    'M16,9.5v1a.5.5,0,0,1-.5.5H13v2.5a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5V11H8.5a.5.5,0,0,1-.5-.5v-1A.5.5,0,0,1,8.5,9H11V6.5a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5V9h2.5A.5.5,0,0,1,16,9.5ZM12,4a6,6,0,0,0-6,6c0,2.8,1.18,3.74,2.82,5A9.17,9.17,0,0,1,12,18.73,9.25,9.25,0,0,1,15.18,15C16.82,13.74,18,12.8,18,10a6,6,0,0,0-6-6m0-2a8,8,0,0,1,8,8c0,6.22-5.05,6.17-6.26,9.78l-.51,1.54a1,1,0,0,1-1,.68h-.56a1,1,0,0,1-1-.68l-.51-1.54C9.05,16.17,4,16.22,4,10a8,8,0,0,1,8-8Z',
  poi_delete:
    'M15.09,11.86a.48.48,0,0,1,0,.7l-.53.53a.48.48,0,0,1-.7,0L12,11.24l-1.86,1.85a.48.48,0,0,1-.7,0l-.53-.53a.48.48,0,0,1,0-.7L10.76,10,8.91,8.14a.48.48,0,0,1,0-.7l.53-.53a.48.48,0,0,1,.7,0L12,8.76l1.86-1.85a.48.48,0,0,1,.7,0l.53.53a.48.48,0,0,1,0,.7L13.24,10ZM12,2a8,8,0,0,0-8,8c0,6.22,5.05,6.17,6.26,9.78l.51,1.54a1,1,0,0,0,1,.68h.56a1,1,0,0,0,1-.68l.51-1.54C15,16.17,20,16.22,20,10a8,8,0,0,0-8-8Z',
  poi_delete_outlined:
    'M15.09,8.14,13.24,10l1.85,1.86a.48.48,0,0,1,0,.7l-.53.53a.48.48,0,0,1-.7,0L12,11.24l-1.86,1.85a.48.48,0,0,1-.7,0l-.53-.53a.48.48,0,0,1,0-.7L10.76,10,8.91,8.14a.48.48,0,0,1,0-.7l.53-.53a.48.48,0,0,1,.7,0L12,8.76l1.86-1.85a.48.48,0,0,1,.7,0l.53.53A.48.48,0,0,1,15.09,8.14ZM12,4a6,6,0,0,0-6,6c0,2.8,1.18,3.74,2.82,5A9.17,9.17,0,0,1,12,18.73,9.25,9.25,0,0,1,15.18,15C16.82,13.74,18,12.8,18,10a6,6,0,0,0-6-6m0-2a8,8,0,0,1,8,8c0,6.22-5.05,6.17-6.26,9.78l-.51,1.54a1,1,0,0,1-1,.68h-.56a1,1,0,0,1-1-.68l-.51-1.54C9.05,16.17,4,16.22,4,10a8,8,0,0,1,8-8Z',
  poi_outlined:
    'M14,10a2,2,0,1,1-2-2A2,2,0,0,1,14,10ZM12,4a6,6,0,0,0-6,6c0,2.8,1.18,3.74,2.82,5A9.17,9.17,0,0,1,12,18.73,9.25,9.25,0,0,1,15.18,15C16.82,13.74,18,12.8,18,10a6,6,0,0,0-6-6m0-2a8,8,0,0,1,8,8c0,6.22-5.05,6.17-6.26,9.78l-.51,1.54a1,1,0,0,1-1,.68h-.56a1,1,0,0,1-1-.68l-.51-1.54C9.05,16.17,4,16.22,4,10a8,8,0,0,1,8-8Z',
  poi_remove:
    'M8.5,11a.5.5,0,0,1-.5-.5v-1A.5.5,0,0,1,8.5,9h7a.5.5,0,0,1,.5.5v1a.5.5,0,0,1-.5.5ZM12,2a8,8,0,0,0-8,8c0,6.22,5.05,6.17,6.26,9.78l.51,1.54a1,1,0,0,0,1,.68h.56a1,1,0,0,0,1-.68l.51-1.54C15,16.17,20,16.22,20,10a8,8,0,0,0-8-8Z',
  poi_remove_outlined:
    'M16,9.5v1a.5.5,0,0,1-.5.5h-7a.5.5,0,0,1-.5-.5v-1A.5.5,0,0,1,8.5,9h7A.5.5,0,0,1,16,9.5ZM12,4a6,6,0,0,0-6,6c0,2.8,1.18,3.74,2.82,5A9.17,9.17,0,0,1,12,18.73,9.25,9.25,0,0,1,15.18,15C16.82,13.74,18,12.8,18,10a6,6,0,0,0-6-6m0-2a8,8,0,0,1,8,8c0,6.22-5.05,6.17-6.26,9.78l-.51,1.54a1,1,0,0,1-1,.68h-.56a1,1,0,0,1-1-.68l-.51-1.54C9.05,16.17,4,16.22,4,10a8,8,0,0,1,8-8Z',
  poi_user:
    'M12,4.75A2.25,2.25,0,1,1,9.75,7,2.24,2.24,0,0,1,12,4.75ZM12,16a6,6,0,0,1-4.86-2.51l.69-1.39A2,2,0,0,1,9.62,11h4.76a2,2,0,0,1,1.79,1.1l.69,1.39A6,6,0,0,1,12,16ZM12,2a8,8,0,0,0-8,8c0,6.22,5.05,6.17,6.26,9.78l.51,1.54a1,1,0,0,0,1,.68h.56a1,1,0,0,0,1-.68l.51-1.54C15,16.17,20,16.22,20,10A8,8,0,0,0,12,2Z',
  pyramid:
    'M21.82,14.35,12.65,3.76a.73.73,0,0,0-.56-.26h-.18a.71.71,0,0,0-.56.26L2.18,14.33a.78.78,0,0,0-.18.49v.66a2.39,2.39,0,0,0,.69.61l8,4.59a2.32,2.32,0,0,0,1.18.32h.34a2.32,2.32,0,0,0,1.18-.32l8-4.6a2.15,2.15,0,0,0,.46-.35.73.73,0,0,0,.23-.54v-.35A.72.72,0,0,0,21.82,14.35ZM13,18.58V7.22l6.55,7.57Z',
  restaurant:
    'M12,2.5v8.26a3,3,0,0,1-.88,2.12l-.83.83a1,1,0,0,1-.7.29H9v7.5a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5V14H6.41a1,1,0,0,1-.7-.29l-.83-.83A3,3,0,0,1,4,10.76V2.5A.5.5,0,0,1,4.5,2h.75a.5.5,0,0,1,.5.5v7a.5.5,0,0,0,.5.5h.37a.5.5,0,0,0,.5-.5v-7a.5.5,0,0,1,.5-.5h.76a.5.5,0,0,1,.5.5v7a.5.5,0,0,0,.5.5h.37a.5.5,0,0,0,.5-.5v-7a.5.5,0,0,1,.5-.5h.75A.5.5,0,0,1,12,2.5Zm8,0a.5.5,0,0,0-.5-.5H19a4,4,0,0,0-4,4v7a1,1,0,0,0,1,1h2v7.5a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5Z',
  rome:
    'M22,14H17.14l-1.62-3.79A2,2,0,0,0,13.68,9H9.15L7.1,4.21A2,2,0,0,0,5.26,3H1.5a.5.5,0,0,0-.5.5v17a.5.5,0,0,0,.5.5h21a.5.5,0,0,0,.5-.5V15A1,1,0,0,0,22,14ZM5,19H3V17a1,1,0,0,1,2,0Zm0-5H3V12a1,1,0,0,1,2,0ZM5,9H3V7A1,1,0,0,1,5,7ZM9,19H7V17a1,1,0,0,1,2,0Zm0-5H7V12a1,1,0,0,1,2,0Zm4,5H11V17a1,1,0,0,1,2,0Zm0-5H11V12a1,1,0,0,1,2,0Zm4,5H15V17a1,1,0,0,1,2,0Zm4,0H19V17a1,1,0,0,1,2,0Z',
  route:
    'M11,13.5a4.5,4.5,0,1,0-6.87,3.82A2,2,0,0,1,5,18.38l.31.94a1,1,0,0,0,1,.68h.56a1,1,0,0,0,1-.68L8,18.38a2,2,0,0,1,.83-1.06A4.5,4.5,0,0,0,11,13.5Zm-4.5,2a2,2,0,1,1,2-2A2,2,0,0,1,6.5,15.5ZM17.24,9.42a2,2,0,0,1,.73,1l.42,1.25a.5.5,0,0,0,.47.34h.28a.5.5,0,0,0,.47-.34L20,10.41a2,2,0,0,1,.73-1,3,3,0,1,0-3.52,0ZM19,6a1,1,0,1,1-1,1A1,1,0,0,1,19,6Zm-3,6-.5-.43h.68a.25.25,0,0,0,.2-.1.27.27,0,0,0,0-.23l-.31-.92a.51.51,0,0,0-.47-.34H13.93a1,1,0,0,0-.91.6l-.08.19a1,1,0,0,0,.19,1.08L18.7,18H10.89a1,1,0,0,0-1,.68l-.22.66a.5.5,0,0,0,.47.66H20.92a1,1,0,0,0,.92-.61l.08-.19a1,1,0,0,0-.19-1.08Z',
  route_dashed:
    'M10.5,5H8.82a3,3,0,1,0,0,2H10.5a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,10.5,5ZM6,7A1,1,0,1,1,7,6,1,1,0,0,1,6,7Zm-.33,4.23a4,4,0,0,0-2.44,2.44.25.25,0,0,0,0,.22.26.26,0,0,0,.21.11H5a.47.47,0,0,0,.39-.2,2.52,2.52,0,0,1,.4-.4A.47.47,0,0,0,6,13V11.47a.26.26,0,0,0-.11-.21A.25.25,0,0,0,5.67,11.23Zm-.27,5A.47.47,0,0,0,5,16H3.47a.26.26,0,0,0-.21.11.25.25,0,0,0,0,.22,4,4,0,0,0,2.44,2.44.25.25,0,0,0,.22,0A.26.26,0,0,0,6,18.53V17a.47.47,0,0,0-.2-.39A2.52,2.52,0,0,1,5.4,16.2Zm5.1.8h-2a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h2a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,10.5,17Zm0-6h-2a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h2a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,10.5,11Zm3-4h2a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5h-2a.5.5,0,0,0-.5.5v1A.5.5,0,0,0,13.5,7Zm0,6h2a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5h-2a.5.5,0,0,0-.5.5v1A.5.5,0,0,0,13.5,13Zm4.83-.23a4,4,0,0,0,2.44-2.44.25.25,0,0,0,0-.22.26.26,0,0,0-.21-.11H19a.47.47,0,0,0-.39.2,2.52,2.52,0,0,1-.4.4A.47.47,0,0,0,18,11v1.54a.26.26,0,0,0,.11.21A.25.25,0,0,0,18.33,12.77Zm.27-5A.47.47,0,0,0,19,8h1.54a.26.26,0,0,0,.21-.11.25.25,0,0,0,0-.22,4,4,0,0,0-2.44-2.44.25.25,0,0,0-.22,0,.26.26,0,0,0-.11.21V7a.47.47,0,0,0,.2.39A2.52,2.52,0,0,1,18.6,7.8ZM18,15a3,3,0,0,0-2.82,2H13.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h1.68A3,3,0,1,0,18,15Zm0,4a1,1,0,1,1,1-1A1,1,0,0,1,18,19Z',
  san_francisco:
    'M22.7,11.87a.3.3,0,0,0,.21,0,.25.25,0,0,0,.09-.2V10.54a.5.5,0,0,0-.36-.48A5.07,5.07,0,0,1,19,5.12V3.5a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5V5.12a5,5,0,1,1-10,0V3.5A.5.5,0,0,0,6.5,3h-1a.5.5,0,0,0-.5.5V5.12a5.07,5.07,0,0,1-3.64,4.94.5.5,0,0,0-.36.48v1.08a.25.25,0,0,0,.09.2.3.3,0,0,0,.21,0A6.83,6.83,0,0,0,5,9.81V13H1.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5H5v4H4.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h3a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5H7V15H17v4h-.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h3a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5H19V15h3.5a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5H19V9.81A6.83,6.83,0,0,0,22.7,11.87ZM7,13V9.83a6.85,6.85,0,0,0,10,0V13Z',
  shop:
    'M4,4.5v-1A.5.5,0,0,1,4.5,3h15a.5.5,0,0,1,.5.5v1a.5.5,0,0,1-.5.5H4.5A.5.5,0,0,1,4,4.5Zm17.79,6.08-1.51-3a1,1,0,0,0-.9-.55H4.62a1,1,0,0,0-.9.55l-1.51,3a2,2,0,0,0-.21.89V12a1,1,0,0,0,1,1H4v7a1,1,0,0,0,1,1H9a1,1,0,0,0,1-1V15.25a.25.25,0,0,1,.25-.25h3.5a.25.25,0,0,1,.25.25V20a1,1,0,0,0,1,1h4a1,1,0,0,0,1-1V13h1a1,1,0,0,0,1-1v-.53A2,2,0,0,0,21.79,10.58Z',
  shopping:
    'M20,7H17A5,5,0,0,0,7,7H4A1,1,0,0,0,3,8V20a2,2,0,0,0,2,2H19a2,2,0,0,0,2-2V8A1,1,0,0,0,20,7ZM9,10.5a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5v-1A.5.5,0,0,1,7.5,9h1a.5.5,0,0,1,.5.5ZM12,4a3,3,0,0,1,3,3H9A3,3,0,0,1,12,4Zm5,6.5a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5Z',
  shopping_icon:
    'M21,8a1,1,0,0,0-1-1H17A5,5,0,0,0,7,7H4A1,1,0,0,0,3,8V20a2,2,0,0,0,2,2H19a2,2,0,0,0,2-2ZM12,4a3,3,0,0,1,3,3H9A3,3,0,0,1,12,4Zm7,16H5V9H7v1.5a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5V9h6v1.5a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5V9h2Z',
  shop_outlined:
    'M21.79,10.58l-1.51-3a1,1,0,0,0-.9-.55H4.62a1,1,0,0,0-.9.55l-1.51,3a2,2,0,0,0-.21.89V12a1,1,0,0,0,1,1H4v7a1,1,0,0,0,1,1h5a1,1,0,0,0,1-1V17.25a.25.25,0,0,1,.25-.25h1.5a.25.25,0,0,1,.25.25V20a1,1,0,0,0,1,1h5a1,1,0,0,0,1-1V13h1a1,1,0,0,0,1-1v-.53A2,2,0,0,0,21.79,10.58ZM18,19H15V16a1,1,0,0,0-1-1H10a1,1,0,0,0-1,1v3H6V13H18ZM4.5,5h15a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5H4.5a.5.5,0,0,0-.5.5v1A.5.5,0,0,0,4.5,5Z',
  singapore:
    'M22.5,18H20V8h1.5a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5H19V4.5a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5V6H7V4.5A.5.5,0,0,0,6.5,4h-1a.5.5,0,0,0-.5.5V6H1.5a.5.5,0,0,0-.35.85l.56.56A2,2,0,0,0,3.12,8H4V18H1.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h21a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,22.5,18ZM16,18H14V8h2ZM8,8h2V18H8Z',
  spa:
    'M12,11.38A12.48,12.48,0,0,0,7.6,8.71a12.06,12.06,0,0,1,3.62-6.47A.89.89,0,0,1,11.84,2h.32a.89.89,0,0,1,.62.24A12.06,12.06,0,0,1,16.4,8.71,12.48,12.48,0,0,0,12,11.38Zm9.75-.93-.19-.2A.77.77,0,0,0,21,10,10.56,10.56,0,0,0,13.1,13.1,11.32,11.32,0,0,0,12,14.42a11.32,11.32,0,0,0-1.1-1.32A10.56,10.56,0,0,0,3,10a.77.77,0,0,0-.55.24l-.19.2A.73.73,0,0,0,2,11,10.59,10.59,0,0,0,12,22,10.59,10.59,0,0,0,22,11,.73.73,0,0,0,21.75,10.45Z',
  spa_outlined:
    'M21.75,10.45l-.19-.2A.77.77,0,0,0,21,10h-.39a10.68,10.68,0,0,0-4,.78,12,12,0,0,0-3.83-8.54A.89.89,0,0,0,12.16,2h-.32a.89.89,0,0,0-.62.24,12,12,0,0,0-3.83,8.54,10.68,10.68,0,0,0-4-.78H3a.77.77,0,0,0-.55.24l-.19.2A.73.73,0,0,0,2,11,10.59,10.59,0,0,0,12,22,10.59,10.59,0,0,0,22,11,.73.73,0,0,0,21.75,10.45ZM9.38,11A9.87,9.87,0,0,1,12,4.29,9.87,9.87,0,0,1,14.62,11c0,.3,0,.59,0,.88A10.65,10.65,0,0,0,13.1,13.1,11.32,11.32,0,0,0,12,14.42a11.32,11.32,0,0,0-1.1-1.32,10.65,10.65,0,0,0-1.48-1.22C9.4,11.59,9.38,11.3,9.38,11Zm8.11,6.5A8.61,8.61,0,0,1,12,20a8.59,8.59,0,0,1-8-8,8.56,8.56,0,0,1,5.47,2.5,8.83,8.83,0,0,1,.89,1.07l.71,1a1,1,0,0,0,.81.42h.2a1,1,0,0,0,.81-.42l.71-1a8.83,8.83,0,0,1,.89-1.07A8.56,8.56,0,0,1,20,12,8.6,8.6,0,0,1,17.49,17.5Z',
  sydney:
    'M22.5,18H21.07L23,10.86a.57.57,0,0,0-.43-.72,8.55,8.55,0,0,0-1.5-.14,8,8,0,0,0-3.18.67,5.44,5.44,0,0,0-.15-.7A8,8,0,0,0,10,4a8.55,8.55,0,0,0-1.5.14A.58.58,0,0,0,8,4.86L9,8.63A8,8,0,0,0,6,8a8.55,8.55,0,0,0-1.5.14A.58.58,0,0,0,4,8.86l.91,3.4A7.68,7.68,0,0,0,3,12a8.55,8.55,0,0,0-1.5.14.58.58,0,0,0-.43.72L2.4,18H1.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h21a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,22.5,18Zm-18,0L3.4,14a5.93,5.93,0,0,1,2.16.59l.27,1a.5.5,0,0,0,.49.37H7.68a.25.25,0,0,0,.24-.31L6.4,10a6.08,6.08,0,0,1,3.37,1.36L11.54,18ZM10.4,6a6.06,6.06,0,0,1,5.39,4.47l.06.24a3,3,0,0,1-.29,2l-2.23,4.17ZM19,18H15l2.23-4.17a3,3,0,0,1,1.34-1.3,5.84,5.84,0,0,1,2-.51Z',
  theatre:
    'M18,2H6A2,2,0,0,0,4,4V14a8,8,0,0,0,16,0V4A2,2,0,0,0,18,2Zm-4.89,8.33a2,2,0,0,1,3.78,0,.5.5,0,0,1-.07.46.49.49,0,0,1-.41.21H13.59a.49.49,0,0,1-.41-.21A.5.5,0,0,1,13.11,10.33Zm-6,0a2,2,0,0,1,3.78,0,.5.5,0,0,1-.07.46.49.49,0,0,1-.41.21H7.59a.49.49,0,0,1-.41-.21A.5.5,0,0,1,7.11,10.33ZM12,19a5,5,0,0,1-5-4.44.46.46,0,0,1,.13-.39A.48.48,0,0,1,7.53,14h8.94a.52.52,0,0,1,.38.17.49.49,0,0,1,.12.39A5,5,0,0,1,12,19Z',
  toilets:
    'M5.5,4a2,2,0,1,1,2,2A2,2,0,0,1,5.5,4ZM9.64,7.59A2,2,0,0,0,8.22,7H6.78a2,2,0,0,0-1.42.59l-.77.77A2,2,0,0,0,4,9.78V14.5a.5.5,0,0,0,.5.5h1v6.5A.5.5,0,0,0,6,22H9a.5.5,0,0,0,.5-.5V15h1a.5.5,0,0,0,.5-.5V9.78a2,2,0,0,0-.59-1.42ZM16.5,6a2,2,0,1,0-2-2A2,2,0,0,0,16.5,6Zm3.89,9.37L18.27,7.73a1,1,0,0,0-1-.73H15.76a1,1,0,0,0-1,.73l-2.28,7.64A.5.5,0,0,0,13,16h2v5.5a.5.5,0,0,0,.5.5h2a.5.5,0,0,0,.5-.5V16h1.91A.5.5,0,0,0,20.39,15.37Z',
  trencin:
    'M21,18v2H12V18Zm-2-4v2H17V14ZM13,7h2v5H13ZM4,14H15v2H11a1,1,0,0,0-1,1v3H4ZM17,7h.5a.5.5,0,0,0,.5-.5V5.7a1.94,1.94,0,0,0-.46-1.26L15.72,2.27A.76.76,0,0,0,15.14,2H12.86a.76.76,0,0,0-.58.27L10.46,4.44A1.94,1.94,0,0,0,10,5.7v.8a.5.5,0,0,0,.5.5H11v5H3a1,1,0,0,0-1,1v7H1.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h21a.5.5,0,0,0,.5-.5V17a1,1,0,0,0-1-1H21V13.83a2,2,0,0,0-.59-1.42l-1.12-1.12a1,1,0,0,0-.7-.29H17ZM7.5,16h-1a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,7.5,16Z',
  zoom_in:
    'M20.85,19.44l-4-4a2.44,2.44,0,0,0-.43-.35l-1-.69h0A7,7,0,1,0,10,17a7,7,0,0,0,4.37-1.53h0l.75,1a2.6,2.6,0,0,0,.3.36l4,4a.5.5,0,0,0,.71,0l.7-.7A.5.5,0,0,0,20.85,19.44ZM10,15a5,5,0,1,1,5-5A5,5,0,0,1,10,15Zm3-4.62V9.62a.5.5,0,0,0-.5-.5H10.88V7.5a.5.5,0,0,0-.5-.5H9.62a.5.5,0,0,0-.5.5V9.12H7.5a.5.5,0,0,0-.5.5v.76a.5.5,0,0,0,.5.5H9.12V12.5a.5.5,0,0,0,.5.5h.76a.5.5,0,0,0,.5-.5V10.88H12.5A.5.5,0,0,0,13,10.38Z',
  zoom_out:
    'M20.85,19.44l-4-4a2.44,2.44,0,0,0-.43-.35l-1-.69h0A7,7,0,1,0,10,17a7,7,0,0,0,4.37-1.53h0l.75,1a2.6,2.6,0,0,0,.3.36l4,4a.5.5,0,0,0,.71,0l.7-.7A.5.5,0,0,0,20.85,19.44ZM10,15a5,5,0,1,1,5-5A5,5,0,0,1,10,15ZM7.5,9.12a.5.5,0,0,0-.5.5v.76a.5.5,0,0,0,.5.5h5a.5.5,0,0,0,.5-.5V9.62a.5.5,0,0,0-.5-.5Z',
  add_photo:
    'M20,7H18.83a2,2,0,0,1-1.42-.59L15.88,4.88A3,3,0,0,0,13.76,4H10.24a3,3,0,0,0-2.12.88L6.59,6.41A2,2,0,0,1,5.17,7H4A2,2,0,0,0,2,9v9a2,2,0,0,0,2,2H20a2,2,0,0,0,2-2V9A2,2,0,0,0,20,7Zm-4,6.5a.5.5,0,0,1-.5.5H13v2.5a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5V14H8.5a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5H11V9.5a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5V12h2.5a.5.5,0,0,1,.5.5Z',
  adjust:
    'M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20Zm3-8a3,3,0,1,1-3-3A3,3,0,0,1,15,12Z',
  align_to_bottom:
    'M21,20.5v-1a.5.5,0,0,0-.5-.5H19V10a1,1,0,0,0-1-1H14a1,1,0,0,0-1,1v9H11V4a1,1,0,0,0-1-1H6A1,1,0,0,0,5,4V19H3.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h17A.5.5,0,0,0,21,20.5Z',
  align_to_bottom_outlined:
    'M21,20.5v-1a.5.5,0,0,0-.5-.5H19V10a1,1,0,0,0-1-1H14a1,1,0,0,0-1,1v9H11V4a1,1,0,0,0-1-1H6A1,1,0,0,0,5,4V19H3.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h17A.5.5,0,0,0,21,20.5ZM15,11h2v8H15ZM7,5H9V19H7Z',
  align_to_bottom_vs:
    'M20.5,21a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5H16V4a1,1,0,0,0-1-1H9A1,1,0,0,0,8,4V19H3.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5ZM14,19H10V5h4Z',
  align_to_center:
    'M20,13H13V11h4a1,1,0,0,0,1-1V6a1,1,0,0,0-1-1H13V3.5a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5V5H7A1,1,0,0,0,6,6v4a1,1,0,0,0,1,1h4v2H4a1,1,0,0,0-1,1v4a1,1,0,0,0,1,1h7v1.5a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5V19h7a1,1,0,0,0,1-1V14A1,1,0,0,0,20,13Z',
  align_to_center_outlined:
    'M20,13H13V11h4a1,1,0,0,0,1-1V6a1,1,0,0,0-1-1H13V3.5a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5V5H7A1,1,0,0,0,6,6v4a1,1,0,0,0,1,1h4v2H4a1,1,0,0,0-1,1v4a1,1,0,0,0,1,1h7v1.5a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5V19h7a1,1,0,0,0,1-1V14A1,1,0,0,0,20,13ZM8,9V7h8V9ZM5,17V15H19v2Z',
  align_to_center_vs:
    'M11,20.5a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5V16h7a1,1,0,0,0,1-1V9a1,1,0,0,0-1-1H13V3.5a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5V8H4A1,1,0,0,0,3,9v6a1,1,0,0,0,1,1h7ZM19,10v4H5V10Z',
  align_to_left:
    'M20,13H5V11h9a1,1,0,0,0,1-1V6a1,1,0,0,0-1-1H5V3.5A.5.5,0,0,0,4.5,3h-1a.5.5,0,0,0-.5.5v17a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5V19H20a1,1,0,0,0,1-1V14A1,1,0,0,0,20,13Z',
  align_to_left_outlined:
    'M20,13H5V11h9a1,1,0,0,0,1-1V6a1,1,0,0,0-1-1H5V3.5A.5.5,0,0,0,4.5,3h-1a.5.5,0,0,0-.5.5v17a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5V19H20a1,1,0,0,0,1-1V14A1,1,0,0,0,20,13ZM13,7V9H5V7Zm6,8v2H5V15Z',
  align_to_left_vs:
    'M3,20.5a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5V16H20a1,1,0,0,0,1-1V9a1,1,0,0,0-1-1H5V3.5A.5.5,0,0,0,4.5,3h-1a.5.5,0,0,0-.5.5ZM19,10v4H5V10Z',
  align_to_middle:
    'M11,20V13h2v4a1,1,0,0,0,1,1h4a1,1,0,0,0,1-1V13h1.5a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5H19V7a1,1,0,0,0-1-1H14a1,1,0,0,0-1,1v4H11V4a1,1,0,0,0-1-1H6A1,1,0,0,0,5,4v7H3.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5H5v7a1,1,0,0,0,1,1h4A1,1,0,0,0,11,20Z',
  align_to_middle_outlined:
    'M11,20V13h2v4a1,1,0,0,0,1,1h4a1,1,0,0,0,1-1V13h1.5a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5H19V7a1,1,0,0,0-1-1H14a1,1,0,0,0-1,1v4H11V4a1,1,0,0,0-1-1H6A1,1,0,0,0,5,4v7H3.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5H5v7a1,1,0,0,0,1,1h4A1,1,0,0,0,11,20ZM15,8h2v8H15ZM7,5H9V19H7Z',
  align_to_middle_vs:
    'M3.5,11a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5H8v7a1,1,0,0,0,1,1h6a1,1,0,0,0,1-1V13h4.5a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5H16V4a1,1,0,0,0-1-1H9A1,1,0,0,0,8,4v7ZM14,19H10V5h4Z',
  align_to_right:
    'M20.5,3h-1a.5.5,0,0,0-.5.5V5H10A1,1,0,0,0,9,6v4a1,1,0,0,0,1,1h9v2H4a1,1,0,0,0-1,1v4a1,1,0,0,0,1,1H19v1.5a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5V3.5A.5.5,0,0,0,20.5,3Z',
  align_to_right_outlined:
    'M20.5,3h-1a.5.5,0,0,0-.5.5V5H10A1,1,0,0,0,9,6v4a1,1,0,0,0,1,1h9v2H4a1,1,0,0,0-1,1v4a1,1,0,0,0,1,1H19v1.5a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5V3.5A.5.5,0,0,0,20.5,3ZM11,9V7h8V9ZM5,17V15H19v2Z',
  align_to_right_vs:
    'M21,3.5a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5V8H4A1,1,0,0,0,3,9v6a1,1,0,0,0,1,1H19v4.5a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5ZM19,10v4H5V10Z',
  align_to_top:
    'M20.5,3H3.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5H5V20a1,1,0,0,0,1,1h4a1,1,0,0,0,1-1V5h2v9a1,1,0,0,0,1,1h4a1,1,0,0,0,1-1V5h1.5a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,20.5,3Z',
  align_to_top_outlined:
    'M20.5,3H3.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5H5V20a1,1,0,0,0,1,1h4a1,1,0,0,0,1-1V5h2v9a1,1,0,0,0,1,1h4a1,1,0,0,0,1-1V5h1.5a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,20.5,3ZM15,5h2v8H15ZM7,5H9V19H7Z',
  align_to_top_vs:
    'M3.5,3a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5H8V20a1,1,0,0,0,1,1h6a1,1,0,0,0,1-1V5h4.5a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5ZM14,19H10V5h4Z',
  artboard:
    'M7,20a2,2,0,0,0,2,2H20a2,2,0,0,0,2-2V13.83a2,2,0,0,0-.59-1.42L16.59,7.59A2,2,0,0,0,15.17,7H7.5a.5.5,0,0,0-.5.5Zm9-6a1,1,0,0,1-1-1V8.82L20.18,14ZM5,7H2.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5H5ZM8.5,2h-1a.5.5,0,0,0-.5.5V5H9V2.5A.5.5,0,0,0,8.5,2Z',
  artboard_outlined:
    'M7,20a2,2,0,0,0,2,2H20a2,2,0,0,0,2-2V14.24a3,3,0,0,0-.88-2.12L16.88,7.88A3,3,0,0,0,14.76,7H7.5a.5.5,0,0,0-.5.5Zm13,0H9V9h5v5a1,1,0,0,0,1,1h5ZM5,7H2.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5H5ZM8.5,2h-1a.5.5,0,0,0-.5.5V5H9V2.5A.5.5,0,0,0,8.5,2Z',
  black_white:
    'M21,5a2,2,0,0,0-2-2H5A2,2,0,0,0,3,5V19a2,2,0,0,0,2,2H19a2,2,0,0,0,2-2ZM12,19H5V5h7Z',
  blend_tool:
    'M16,10V9a3,3,0,0,0-3-3V4a2,2,0,0,0-2-2H4A2,2,0,0,0,2,4v7a2,2,0,0,0,2,2H6a3,3,0,0,0,3,3h1a6,6,0,1,0,6-6ZM4,11V4h7V6H9A3,3,0,0,0,6,9v2Zm6.35,3H9a1,1,0,0,1-1-1V9A1,1,0,0,1,9,8h4a1,1,0,0,1,1,1v1.35A6,6,0,0,0,10.35,14ZM16,20a4,4,0,1,1,4-4A4,4,0,0,1,16,20Z',
  bold:
    'M16.5,10.9A4,4,0,0,0,17,9V8a4,4,0,0,0-4-4H7A1,1,0,0,0,6,5V18a1,1,0,0,0,1,1h7a4,4,0,0,0,4-4V14A4,4,0,0,0,16.5,10.9ZM13,7a1,1,0,0,1,1,1V9a1,1,0,0,1-1,1H9V7Zm2,8a1,1,0,0,1-1,1H9V13h5a1,1,0,0,1,1,1Z',
  brightness_1:
    'M17,12a5,5,0,1,1-5-5A5,5,0,0,1,17,12ZM11.5,5h1a.5.5,0,0,0,.5-.5v-2a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5v2A.5.5,0,0,0,11.5,5Zm1,14h-1a.5.5,0,0,0-.5.5v2a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5v-2A.5.5,0,0,0,12.5,19Zm9-8h-2a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h2a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,21.5,11ZM5,12.5v-1a.5.5,0,0,0-.5-.5h-2a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h2A.5.5,0,0,0,5,12.5ZM17.3,7.4a.5.5,0,0,0,.71,0L19.42,6a.5.5,0,0,0,0-.71l-.7-.7a.5.5,0,0,0-.71,0L16.6,6a.5.5,0,0,0,0,.71ZM6.7,16.6a.5.5,0,0,0-.71,0L4.58,18a.5.5,0,0,0,0,.71l.7.7a.5.5,0,0,0,.71,0L7.4,18a.5.5,0,0,0,0-.71ZM18,16.6a.5.5,0,0,0-.71,0l-.7.7a.5.5,0,0,0,0,.71L18,19.42a.5.5,0,0,0,.71,0l.7-.7a.5.5,0,0,0,0-.71ZM6,7.4a.5.5,0,0,0,.71,0l.7-.7A.5.5,0,0,0,7.4,6L6,4.58a.5.5,0,0,0-.71,0l-.7.7a.5.5,0,0,0,0,.71Z',
  brightness_1_outlined:
    'M12,9a3,3,0,1,1-3,3,3,3,0,0,1,3-3m0-2a5,5,0,1,0,5,5,5,5,0,0,0-5-5Zm-.5-2h1a.5.5,0,0,0,.5-.5v-2a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5v2A.5.5,0,0,0,11.5,5Zm1,14h-1a.5.5,0,0,0-.5.5v2a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5v-2A.5.5,0,0,0,12.5,19Zm9-8h-2a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h2a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,21.5,11ZM5,12.5v-1a.5.5,0,0,0-.5-.5h-2a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h2A.5.5,0,0,0,5,12.5ZM17.3,7.4a.5.5,0,0,0,.71,0L19.42,6a.5.5,0,0,0,0-.71l-.7-.7a.5.5,0,0,0-.71,0L16.6,6a.5.5,0,0,0,0,.71ZM6.7,16.6a.5.5,0,0,0-.71,0L4.58,18a.5.5,0,0,0,0,.71l.7.7a.5.5,0,0,0,.71,0L7.4,18a.5.5,0,0,0,0-.71ZM18,16.6a.5.5,0,0,0-.71,0l-.7.7a.5.5,0,0,0,0,.71L18,19.42a.5.5,0,0,0,.71,0l.7-.7a.5.5,0,0,0,0-.71ZM6,7.4a.5.5,0,0,0,.71,0l.7-.7A.5.5,0,0,0,7.4,6L6,4.58a.5.5,0,0,0-.71,0l-.7.7a.5.5,0,0,0,0,.71Z',
  brightness_2:
    'M17,12a5,5,0,1,1-5-5A5,5,0,0,1,17,12ZM11.5,5h1a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5v1A.5.5,0,0,0,11.5,5Zm1,14h-1a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,12.5,19Zm8-8h-1a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,20.5,11Zm-16,0h-1a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,4.5,11ZM18,5.28a.51.51,0,0,0-.71,0L16.6,6a.5.5,0,0,0,0,.71l.7.7a.5.5,0,0,0,.71,0l.71-.7a.51.51,0,0,0,0-.71ZM6.7,16.6a.5.5,0,0,0-.71,0l-.71.7a.51.51,0,0,0,0,.71l.71.71a.51.51,0,0,0,.71,0L7.4,18a.5.5,0,0,0,0-.71ZM18,16.6a.5.5,0,0,0-.71,0l-.7.7a.5.5,0,0,0,0,.71l.7.71a.51.51,0,0,0,.71,0l.71-.71a.51.51,0,0,0,0-.71ZM6,7.4a.5.5,0,0,0,.71,0l.7-.7A.5.5,0,0,0,7.4,6l-.7-.71a.51.51,0,0,0-.71,0L5.28,6a.51.51,0,0,0,0,.71Z',
  brightness_2_outlined:
    'M11,4.5v-1a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5v1a.5.5,0,0,1-.5.5h-1A.5.5,0,0,1,11,4.5ZM12.5,19h-1a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,12.5,19Zm8-8h-1a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,20.5,11Zm-16,0h-1a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,4.5,11ZM18,5.28a.51.51,0,0,0-.71,0L16.6,6a.5.5,0,0,0,0,.71l.7.7a.5.5,0,0,0,.71,0l.71-.7a.51.51,0,0,0,0-.71ZM6.7,16.6a.5.5,0,0,0-.71,0l-.71.7a.51.51,0,0,0,0,.71l.71.71a.51.51,0,0,0,.71,0L7.4,18a.5.5,0,0,0,0-.71ZM18,16.6a.5.5,0,0,0-.71,0l-.7.7a.5.5,0,0,0,0,.71l.7.71a.51.51,0,0,0,.71,0l.71-.71a.51.51,0,0,0,0-.71ZM6,7.4a.5.5,0,0,0,.71,0l.7-.7A.5.5,0,0,0,7.4,6l-.7-.71a.51.51,0,0,0-.71,0L5.28,6a.51.51,0,0,0,0,.71ZM12,9a3,3,0,1,0,3,3,3,3,0,0,0-3-3m0-2a5,5,0,1,1-5,5,5,5,0,0,1,5-5Z',
  brightness_3:
    'M12,9v6a3,3,0,0,1,0-6m0-2a5,5,0,1,0,5,5,5,5,0,0,0-5-5Zm-.5-2h1a.5.5,0,0,0,.5-.5v-2a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5v2A.5.5,0,0,0,11.5,5Zm1,14h-1a.5.5,0,0,0-.5.5v2a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5v-2A.5.5,0,0,0,12.5,19Zm9-8h-2a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h2a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,21.5,11ZM5,12.5v-1a.5.5,0,0,0-.5-.5h-2a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h2A.5.5,0,0,0,5,12.5ZM17.3,7.4a.5.5,0,0,0,.71,0L19.42,6a.5.5,0,0,0,0-.71l-.7-.7a.5.5,0,0,0-.71,0L16.6,6a.5.5,0,0,0,0,.71ZM6.7,16.6a.5.5,0,0,0-.71,0L4.58,18a.5.5,0,0,0,0,.71l.7.7a.5.5,0,0,0,.71,0L7.4,18a.5.5,0,0,0,0-.71ZM18,16.6a.5.5,0,0,0-.71,0l-.7.7a.5.5,0,0,0,0,.71L18,19.42a.5.5,0,0,0,.71,0l.7-.7a.5.5,0,0,0,0-.71ZM6,7.4a.5.5,0,0,0,.71,0l.7-.7A.5.5,0,0,0,7.4,6L6,4.58a.5.5,0,0,0-.71,0l-.7.7a.5.5,0,0,0,0,.71Z',
  bring_forward:
    'M20,11H18V8a2,2,0,0,0-2-2H13V4a2,2,0,0,0-2-2H4A2,2,0,0,0,2,4v7a2,2,0,0,0,2,2H6v3a2,2,0,0,0,2,2h3v2a2,2,0,0,0,2,2h7a2,2,0,0,0,2-2V13A2,2,0,0,0,20,11ZM4,11V4h7V6H8A2,2,0,0,0,6,8v3Zm16,9H13V18h3a2,2,0,0,0,2-2V13h2Z',
  bring_to_front:
    'M20,8H16V4a2,2,0,0,0-2-2H4A2,2,0,0,0,2,4V14a2,2,0,0,0,2,2H8v4a2,2,0,0,0,2,2H20a2,2,0,0,0,2-2V10A2,2,0,0,0,20,8Zm0,12H10V16h4a2,2,0,0,0,2-2V10h4Z',
  brush:
    'M20.87,5.28l-7.5,8.15a.5.5,0,0,1-.72,0l-2.09-2.09a.5.5,0,0,1,0-.72l8.15-7.5a.5.5,0,0,1,.69,0l1.44,1.44A.5.5,0,0,1,20.87,5.28Zm-9.18,9.66L9.08,12.32A1.09,1.09,0,0,0,8.49,12,3.91,3.91,0,0,0,8,12a4,4,0,0,0-2.83,1.17c-2,2-.47,4.19-2.09,6.6a.5.5,0,0,0,.06.63h0A2.9,2.9,0,0,0,4.92,21a7.76,7.76,0,0,0,5.91-2.16A4,4,0,0,0,12,16c0-.16,0-.33,0-.49A1,1,0,0,0,11.69,14.94Z',
  camera_enhance:
    'M20,7H18.83a2,2,0,0,1-1.42-.59L15.88,4.88A3,3,0,0,0,13.76,4H10.24a3,3,0,0,0-2.12.88L6.59,6.41A2,2,0,0,1,5.17,7H4A2,2,0,0,0,2,9v9a2,2,0,0,0,2,2H20a2,2,0,0,0,2-2V9A2,2,0,0,0,20,7ZM12,17a4,4,0,1,1,4-4A4,4,0,0,1,12,17Zm2.26-4.4a4,4,0,0,1-1.85-1.85.46.46,0,0,0-.82,0A4,4,0,0,1,9.74,12.6.44.44,0,0,0,9.5,13V13a.44.44,0,0,0,.24.37,4,4,0,0,1,1.86,1.86.44.44,0,0,0,.37.24H12a.44.44,0,0,0,.37-.24,4,4,0,0,1,1.86-1.86A.44.44,0,0,0,14.5,13V13A.44.44,0,0,0,14.26,12.6Z',
  camera_roll:
    'M18,8.5a.5.5,0,0,1,.5-.5h1a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5H16a2,2,0,0,0-2-2H13V3a1,1,0,0,0-1-1H8A1,1,0,0,0,7,3V4H6A2,2,0,0,0,4,6V20a2,2,0,0,0,2,2h8a2,2,0,0,0,2-2h3.5a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h1a.5.5,0,0,0,.5-.5v-5a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,1-.5-.5Zm-2,0v1a.5.5,0,0,1-.5.5h-3a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h3A.5.5,0,0,1,16,8.5ZM9.5,10h-1A.5.5,0,0,1,8,9.5v-1A.5.5,0,0,1,8.5,8h1a.5.5,0,0,1,.5.5v1A.5.5,0,0,1,9.5,10Zm.5,6.5v1a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h1A.5.5,0,0,1,10,16.5ZM15.5,18h-3a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h3a.5.5,0,0,1,.5.5v1A.5.5,0,0,1,15.5,18Z',
  canvas:
    'M22,16.5a.5.5,0,0,0-.5-.5H21V6a2,2,0,0,0-2-2H13V2.5a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5V4H5A2,2,0,0,0,3,6V16H2.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5H7.31L5.14,21.1a.76.76,0,0,0-.14.43v.22a.25.25,0,0,0,.25.25H6.43a1,1,0,0,0,.82-.43L9.75,18h4.5l2.5,3.57a1,1,0,0,0,.82.43h1.18a.25.25,0,0,0,.25-.25v-.22a.76.76,0,0,0-.14-.43L16.69,18H21.5a.5.5,0,0,0,.5-.5ZM19,16H5V6H19Z',
  canvas_graphics:
    'M21.5,16H21V6a2,2,0,0,0-2-2H13V2.5a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5V4H5A2,2,0,0,0,3,6V16H2.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5H7.31L5.14,21.1a.76.76,0,0,0-.14.43v.22a.25.25,0,0,0,.25.25H6.43a1,1,0,0,0,.82-.43L9.75,18h4.5l2.5,3.57a1,1,0,0,0,.82.43h1.18a.25.25,0,0,0,.25-.25v-.22a.76.76,0,0,0-.14-.43L16.69,18H21.5a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,21.5,16ZM19,15.36a.64.64,0,0,1-.64.64h0a.67.67,0,0,1-.5-.24l-3.67-4.57a.5.5,0,0,0-.78,0l-2.29,2.88a.5.5,0,0,1-.79,0l-1-1.36a.51.51,0,0,0-.81,0l-2.23,3a.75.75,0,0,1-.6.3A.64.64,0,0,1,5,15.36V6H19ZM9.5,11A1.5,1.5,0,1,0,8,9.5,1.5,1.5,0,0,0,9.5,11Z',
  canvas_text:
    'M22,16.5a.5.5,0,0,0-.5-.5H21V6a2,2,0,0,0-2-2H13V2.5a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5V4H5A2,2,0,0,0,3,6V16H2.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5H7.31L5.14,21.1a.76.76,0,0,0-.14.43v.22a.25.25,0,0,0,.25.25H6.43a1,1,0,0,0,.82-.43L9.75,18h4.5l2.5,3.57a1,1,0,0,0,.82.43h1.18a.25.25,0,0,0,.25-.25v-.22a.76.76,0,0,0-.14-.43L16.69,18H21.5a.5.5,0,0,0,.5-.5ZM19,16H5V6H19ZM7.5,14h5a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5h-5a.5.5,0,0,0-.5.5v1A.5.5,0,0,0,7.5,14Zm0-4h9a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5h-9a.5.5,0,0,0-.5.5v1A.5.5,0,0,0,7.5,10Z',
  color:
    'M20,14A8,8,0,0,1,4,14C4,10.19,6.86,6.64,9.5,4l1.82-1.82A.75.75,0,0,1,11.85,2h.3a.75.75,0,0,1,.53.22L14.5,4C17.14,6.64,20,10.19,20,14Z',
  color_off:
    'M19.49,16.78,8.14,5.43c.45-.5.91-1,1.36-1.43l1.82-1.82A.75.75,0,0,1,11.85,2h.3a.75.75,0,0,1,.53.22L14.5,4C17.14,6.64,20,10.19,20,14A7.79,7.79,0,0,1,19.49,16.78Zm1.36,3.84L4.38,4.15a.48.48,0,0,0-.7,0l-.53.53a.48.48,0,0,0,0,.7L6,8.22A10.78,10.78,0,0,0,4,14a8,8,0,0,0,13.53,5.77l2.09,2.08a.48.48,0,0,0,.7,0l.53-.53A.48.48,0,0,0,20.85,20.62Z',
  color_off_outlined:
    'M20.85,20.62,4.38,4.15a.48.48,0,0,0-.7,0l-.53.53a.48.48,0,0,0,0,.7L6,8.22A10.78,10.78,0,0,0,4,14a8,8,0,0,0,13.53,5.77l2.09,2.08a.48.48,0,0,0,.7,0l.53-.53A.48.48,0,0,0,20.85,20.62ZM12,20a6,6,0,0,1-6-6A8.38,8.38,0,0,1,7.44,9.67l8.67,8.68A6,6,0,0,1,12,20ZM10.91,5.41,12,4.33l1.09,1.08C16.39,8.72,18,11.53,18,14a5.66,5.66,0,0,1-.12,1.17l1.61,1.61A7.79,7.79,0,0,0,20,14c0-3.81-2.86-7.36-5.5-10L12.68,2.18A.75.75,0,0,0,12.15,2h-.3a.75.75,0,0,0-.53.22L9.5,4c-.45.45-.91.93-1.36,1.43L9.56,6.85C10,6.38,10.42,5.91,10.91,5.41Z',
  color_outlined:
    'M12,4.33l1.09,1.08C16.39,8.72,18,11.53,18,14A6,6,0,0,1,6,14c0-2.47,1.61-5.28,4.91-8.59L12,4.33M12.15,2h-.3a.75.75,0,0,0-.53.22L9.5,4C6.86,6.64,4,10.19,4,14a8,8,0,0,0,16,0c0-3.81-2.86-7.36-5.5-10L12.68,2.18A.75.75,0,0,0,12.15,2Z',
  color_picker:
    'M20.71,4.25l-1-1a1,1,0,0,0-.7-.29H17.87a1,1,0,0,0-.7.29L13.69,6.77,12.28,5.35a.5.5,0,0,0-.7,0l-.71.71a.5.5,0,0,0,0,.71l.71.7-7.7,7.7a2,2,0,0,0-.48,2.06l.5,1.46-.75.75a.5.5,0,0,0,0,.71l.7.7a.5.5,0,0,0,.71,0l.75-.75,1.46.5a2.05,2.05,0,0,0,.64.11,2,2,0,0,0,1.42-.59l7.7-7.7.7.71a.5.5,0,0,0,.71,0l.71-.71a.5.5,0,0,0,0-.7l-1.42-1.41,3.48-3.48a1,1,0,0,0,.29-.7V5A1,1,0,0,0,20.71,4.25ZM12.12,14H7.88L13,8.91l0,0L15.11,11Z',
  color_picker_empty:
    'M20.71,4.25l-1-1a1,1,0,0,0-.7-.29H17.87a1,1,0,0,0-.7.29L13.69,6.77,12.28,5.35a.5.5,0,0,0-.7,0l-.71.71a.5.5,0,0,0,0,.71l.71.7-7.7,7.7a2,2,0,0,0-.48,2.06l.5,1.46-.75.75a.5.5,0,0,0,0,.71l.7.7a.5.5,0,0,0,.71,0l.75-.75,1.46.5a2.05,2.05,0,0,0,.64.11,2,2,0,0,0,1.42-.59l7.7-7.7.7.71a.5.5,0,0,0,.71,0l.71-.71a.5.5,0,0,0,0-.7l-1.42-1.41,3.48-3.48a1,1,0,0,0,.29-.7V5A1,1,0,0,0,20.71,4.25ZM7.53,18.6,5.4,16.47,13,8.91l0,0L15.11,11Z',
  color_picker_point:
    'M20.71,4.25l-1-1a1,1,0,0,0-.7-.29H17.87a1,1,0,0,0-.7.29L13.69,6.77,12.28,5.35a.5.5,0,0,0-.7,0l-.71.71a.5.5,0,0,0,0,.71l.71.7-7.7,7.7a2,2,0,0,0-.48,2.06l.5,1.46-.75.75a.5.5,0,0,0,0,.71l.7.7a.5.5,0,0,0,.71,0l.75-.75,1.46.5a2.05,2.05,0,0,0,.64.11,2,2,0,0,0,1.42-.59l7.7-7.7.7.71a.5.5,0,0,0,.71,0l.71-.71a.5.5,0,0,0,0-.7l-1.42-1.41,3.48-3.48a1,1,0,0,0,.29-.7V5A1,1,0,0,0,20.71,4.25ZM7.53,18.6,5.4,16.47,13,8.91l0,0L15.11,11ZM4.5,7h2A.5.5,0,0,0,7,6.5v-2A.5.5,0,0,0,6.5,4h-2a.5.5,0,0,0-.5.5v2A.5.5,0,0,0,4.5,7Z',
  copy:
    'M18,16V4a2,2,0,0,0-2-2H4A2,2,0,0,0,2,4V16a2,2,0,0,0,2,2H16A2,2,0,0,0,18,16ZM4,4H16V16H4ZM20,6V18a2,2,0,0,1-2,2H6a2,2,0,0,0,2,2H18a4,4,0,0,0,4-4V8A2,2,0,0,0,20,6Z',
  crop:
    'M2.5,7A.5.5,0,0,1,2,6.5v-1A.5.5,0,0,1,2.5,5H5V2.5A.5.5,0,0,1,5.5,2h1a.5.5,0,0,1,.5.5V5H17a2,2,0,0,1,2,2v8H17V7Zm19,10H7V9H5v8a2,2,0,0,0,2,2H17v2.5a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5V19h2.5a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,21.5,17Z',
  cut:
    'M8.83,13.17a4,4,0,1,0,1,1.83h1a1,1,0,0,0,.7-.29l.47-.47.47.47a1,1,0,0,0,.7.29h1a4,4,0,1,0,1-1.83l-1.05-1.05,5-5A3,3,0,0,0,20,5V3.5a.5.5,0,0,0-.5-.5h-.29a.49.49,0,0,0-.36.15Zm10.58,1.42A2,2,0,1,1,18,14,2,2,0,0,1,19.41,14.59ZM4.59,17.41a2,2,0,1,1,2.82-2.82,2,2,0,1,1-2.82,2.82ZM5.15,3.15A.49.49,0,0,0,4.79,3H4.5a.5.5,0,0,0-.5.5V5a3,3,0,0,0,.88,2.12l3.76,3.77,2.12-2.12Z',
  cut_in_half:
    'M8.83,8.83A4,4,0,1,0,7,9.86v1a1,1,0,0,0,.29.7l.47.47-.47.47a1,1,0,0,0-.29.7v1a4,4,0,1,0,1.83,1l1.05-1.05,6,6A3,3,0,0,0,18,21h1.5a.5.5,0,0,0,.5-.5v-.29a.49.49,0,0,0-.15-.36ZM7.41,19.41A2,2,0,1,1,8,18,2,2,0,0,1,7.41,19.41Zm0-12A2,2,0,1,1,4.59,4.59,2,2,0,1,1,7.41,7.41ZM19.5,3H18a3,3,0,0,0-2.12.88L11.11,8.64l2.12,2.12,6.62-6.61A.49.49,0,0,0,20,3.79V3.5A.5.5,0,0,0,19.5,3Zm0,8h-1a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,19.5,11ZM16,12.5v-1a.5.5,0,0,0-.5-.5h-2l2,2h0A.5.5,0,0,0,16,12.5Z',
  difference:
    'M2,10V20a2,2,0,0,0,2,2H14a2,2,0,0,0,2-2V16h4a2,2,0,0,0,2-2V4a2,2,0,0,0-2-2H10A2,2,0,0,0,8,4V8H4A2,2,0,0,0,2,10Zm12,0v4H10V10Z',
  eraser:
    'M20.5,19H16.31l-2,2H20.5a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,20.5,19Z M20.41,9.24,14.76,3.59a2,2,0,0,0-2.83,0l-8,8.05A3,3,0,0,0,3,13.76V16a3,3,0,0,0,.88,2.12l2,2A3,3,0,0,0,8,21h2.28a3,3,0,0,0,2.12-.88l8.05-8A2,2,0,0,0,20.41,9.24Zm-9.47,9.47a1,1,0,0,1-.7.29H8a1,1,0,0,1-.7-.29l-2-2A1,1,0,0,1,5,16V13.76a1,1,0,0,1,.29-.7L8.39,10,14,15.61Z',
  exposure:
    'M19,3H5A2,2,0,0,0,3,5V19a2,2,0,0,0,2,2H19a2,2,0,0,0,2-2V5A2,2,0,0,0,19,3ZM18,16.38a.5.5,0,0,1-.5.5h-5a.5.5,0,0,1-.5-.5v-.76a.5.5,0,0,1,.5-.5h5a.5.5,0,0,1,.5.5ZM5,19V5H19ZM6.5,9.88H8.12V11.5a.5.5,0,0,0,.5.5h.76a.5.5,0,0,0,.5-.5V9.88H11.5a.5.5,0,0,0,.5-.5V8.62a.5.5,0,0,0-.5-.5H9.88V6.5a.5.5,0,0,0-.5-.5H8.62a.5.5,0,0,0-.5.5V8.12H6.5a.5.5,0,0,0-.5.5v.76A.5.5,0,0,0,6.5,9.88Z',
  flare:
    'M16.27,8.37l-.64-.64a.5.5,0,0,1,0-.74L17.19,5.7a.51.51,0,0,1,.68,0l.41.41a.51.51,0,0,1,0,.68L17,8.34A.5.5,0,0,1,16.27,8.37ZM7,15.66,5.7,17.19a.51.51,0,0,0,0,.68l.41.41a.51.51,0,0,0,.68,0L8.34,17a.5.5,0,0,0,0-.74l-.64-.64A.5.5,0,0,0,7,15.66ZM15.66,17l1.53,1.29a.51.51,0,0,0,.68,0l.41-.41a.51.51,0,0,0,0-.68L17,15.66a.5.5,0,0,0-.74,0l-.64.64A.5.5,0,0,0,15.66,17ZM8.34,7,6.81,5.7a.51.51,0,0,0-.68,0l-.41.41a.51.51,0,0,0,0,.68L7,8.34a.5.5,0,0,0,.74,0l.64-.64A.5.5,0,0,0,8.34,7ZM19.55,11.2l-4.91-.54a1,1,0,0,1-.61-.29l-.38-.4a1,1,0,0,1-.28-.61l-.58-6.9a.5.5,0,0,0-.5-.46h-.58a.5.5,0,0,0-.5.46l-.58,6.9a1,1,0,0,1-.28.61l-.38.4a1,1,0,0,1-.61.29l-4.91.54a.5.5,0,0,0-.45.5v.6a.5.5,0,0,0,.45.5l4.91.54a1,1,0,0,1,.61.29l.38.4a1,1,0,0,1,.28.61l.58,6.9a.5.5,0,0,0,.5.46h.58a.5.5,0,0,0,.5-.46l.58-6.9a1,1,0,0,1,.28-.61l.38-.4a1,1,0,0,1,.61-.29l4.91-.54a.5.5,0,0,0,.45-.5v-.6A.5.5,0,0,0,19.55,11.2Z',
  flash:
    'M18.09,10.22l-7,11.42a.76.76,0,0,1-.64.36h-.2a.51.51,0,0,1-.37-.16.49.49,0,0,1-.13-.38L10.31,15H6.74a.76.76,0,0,1-.65-.38l-.19-.33a.49.49,0,0,1,0-.51l7-11.42A.76.76,0,0,1,13.56,2h.2a.51.51,0,0,1,.37.16.49.49,0,0,1,.13.38L13.69,9h3.57a.76.76,0,0,1,.65.38l.19.33A.49.49,0,0,1,18.09,10.22Z',
  flash_off:
    'M19.85,19.32l-.53.53a.48.48,0,0,1-.7,0l-4-4-3.55,5.78a.76.76,0,0,1-.64.36h-.2a.51.51,0,0,1-.37-.16.49.49,0,0,1-.13-.38L10.31,15H6.74a.76.76,0,0,1-.65-.38L6,14.42a.78.78,0,0,1,0-.77l2.45-4L4.15,5.38a.48.48,0,0,1,0-.7l.53-.53a.48.48,0,0,1,.7,0L19.85,18.62A.48.48,0,0,1,19.85,19.32Zm-1.83-9a.78.78,0,0,0,0-.77l-.12-.2A.76.76,0,0,0,17.26,9H13.69l.57-6.46a.49.49,0,0,0-.13-.38A.51.51,0,0,0,13.76,2h-.2a.76.76,0,0,0-.64.36L10.32,6.6l6.19,6.2Z',
  focus:
    'M15.5,13H13v2.5a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5V13H8.5a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5H11V8.5a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5V11h2.5a.5.5,0,0,1,.5.5v1A.5.5,0,0,1,15.5,13ZM3.5,9h1A.5.5,0,0,0,5,8.5V5H8.5A.5.5,0,0,0,9,4.5v-1A.5.5,0,0,0,8.5,3H5A2,2,0,0,0,3,5V8.5A.5.5,0,0,0,3.5,9Zm5,10H5V15.5a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5V19a2,2,0,0,0,2,2H8.5a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,8.5,19ZM19,3H15.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5H19V8.5a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5V5A2,2,0,0,0,19,3Zm1.5,12h-1a.5.5,0,0,0-.5.5V19H15.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5H19a2,2,0,0,0,2-2V15.5A.5.5,0,0,0,20.5,15Z',
  formats:
    'M20,4H4A2,2,0,0,0,2,6V18a2,2,0,0,0,2,2H20a2,2,0,0,0,2-2V6A2,2,0,0,0,20,4ZM14,18H10V12h4Zm6,0H16V11a1,1,0,0,0-1-1H4V6H20Z',
  format_16_9:
    'M21,8v8H3V8H21m0-2H3A2,2,0,0,0,1,8v8a2,2,0,0,0,2,2H21a2,2,0,0,0,2-2V8a2,2,0,0,0-2-2Z',
  format_1_1:
    'M19,5V19H5V5H19m0-2H5A2,2,0,0,0,3,5V19a2,2,0,0,0,2,2H19a2,2,0,0,0,2-2V5a2,2,0,0,0-2-2Z',
  format_4_3:
    'M20,6V18H4V6H20m0-2H4A2,2,0,0,0,2,6V18a2,2,0,0,0,2,2H20a2,2,0,0,0,2-2V6a2,2,0,0,0-2-2Z',
  free_transform:
    'M21,14H19.42l-.8-4H19a1,1,0,0,0,1-1V5a1,1,0,0,0-1-1H15a1,1,0,0,0-1,1V6H10V5A1,1,0,0,0,9,4H5A1,1,0,0,0,4,5V9a1,1,0,0,0,1,1h.38l-.8,4H3a1,1,0,0,0-1,1v4a1,1,0,0,0,1,1H7a1,1,0,0,0,1-1V18h8v1a1,1,0,0,0,1,1h4a1,1,0,0,0,1-1V15A1,1,0,0,0,21,14ZM16,6h2V8H16ZM6,6H8V8H6ZM6,18H4V16H6Zm10-3v1H8V15a1,1,0,0,0-1-1H6.62l.8-4H9a1,1,0,0,0,1-1V8h4V9a1,1,0,0,0,1,1h1.58l.8,4H17A1,1,0,0,0,16,15Zm4,3H18V16h2Z',
  fullscreen_16_9:
    'M23,8v2.5a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5V8H18.5a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5H21A2,2,0,0,1,23,8ZM5.5,6H3A2,2,0,0,0,1,8v2.5a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5V8H5.5A.5.5,0,0,0,6,7.5v-1A.5.5,0,0,0,5.5,6Zm17,7h-1a.5.5,0,0,0-.5.5V16H18.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5H21a2,2,0,0,0,2-2V13.5A.5.5,0,0,0,22.5,13Zm-17,3H3V13.5a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5V16a2,2,0,0,0,2,2H5.5a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,5.5,16Z',
  fullscreen_1_1:
    'M19,3H15.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5H19V8.5a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5V5A2,2,0,0,0,19,3ZM3,5V8.5a.5.5,0,0,0,.5.5h1A.5.5,0,0,0,5,8.5V5H8.5A.5.5,0,0,0,9,4.5v-1A.5.5,0,0,0,8.5,3H5A2,2,0,0,0,3,5ZM19,15.5V19H15.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5H19a2,2,0,0,0,2-2V15.5a.5.5,0,0,0-.5-.5h-1A.5.5,0,0,0,19,15.5ZM4.5,15h-1a.5.5,0,0,0-.5.5V19a2,2,0,0,0,2,2H8.5a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5H5V15.5A.5.5,0,0,0,4.5,15Z',
  fullscreen_4_3:
    'M22,6V8.5a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5V6H17.5a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5H20A2,2,0,0,1,22,6ZM7,4.5A.5.5,0,0,0,6.5,4H4A2,2,0,0,0,2,6V8.5a.5.5,0,0,0,.5.5h1A.5.5,0,0,0,4,8.5V6H6.5A.5.5,0,0,0,7,5.5ZM21.5,15h-1a.5.5,0,0,0-.5.5V18H17.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5H20a2,2,0,0,0,2-2V15.5A.5.5,0,0,0,21.5,15ZM7,18.5a.5.5,0,0,0-.5-.5H4V15.5a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5V18a2,2,0,0,0,2,2H6.5a.5.5,0,0,0,.5-.5Z',
  gesture:
    'M20.5,13H19V12a4,4,0,0,0-4-4c-2.64,0-4,2-5,3.55S8.24,14,7,14a2,2,0,0,1-2-2c0-1.25.89-1.75,2.77-2.52,1.52-.63,3.41-1.4,3.41-3.47a3,3,0,0,0-3-3A5.08,5.08,0,0,0,5.05,4.08a3.39,3.39,0,0,1-1.61.73A.5.5,0,0,0,3,5.3v1a.52.52,0,0,0,.16.37.56.56,0,0,0,.39.13A5.36,5.36,0,0,0,6.18,5.74a3.07,3.07,0,0,1,2-.74,1,1,0,0,1,1,1c0,.66-.7,1-2.17,1.61C5.32,8.32,3,9.26,3,12a4,4,0,0,0,4,4c2.36,0,3.56-1.77,4.62-3.33S13.53,10,15,10a2,2,0,0,1,2,2v1H15.16A4.11,4.11,0,0,0,11,17a4,4,0,0,0,4,4,4.11,4.11,0,0,0,4-4.16V15h1.5a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,20.5,13ZM17,16.84c0,.43-.36,2.16-2,2.16a2,2,0,0,1-2-2,2.12,2.12,0,0,1,2.16-2H17Z',
  gradient:
    'M19,3H5A2,2,0,0,0,3,5V19a2,2,0,0,0,2,2H19a2,2,0,0,0,2-2V5A2,2,0,0,0,19,3Zm0,2v7H17v2H15V12H13v2H11V12H9v2H7V12H5V5Zm-2.25,5h-1.5a.25.25,0,0,0-.25.25V12h2V10.25A.25.25,0,0,0,16.75,10Zm-4,0h-1.5a.25.25,0,0,0-.25.25V12h2V10.25A.25.25,0,0,0,12.75,10Zm-4,0H7.25a.25.25,0,0,0-.25.25V12H9V10.25A.25.25,0,0,0,8.75,10Z',
  grid:
    'M20,4H4A2,2,0,0,0,2,6V18a2,2,0,0,0,2,2H20a2,2,0,0,0,2-2V6a2,2,0,0,0-2-2ZM16,9V6h4V9ZM9.75,9V6h4.5V9ZM4,9V6H8V9Zm12,4.25v-2.5h4v2.5Zm-6.25,0v-2.5h4.5v2.5ZM4,13.25v-2.5H8v2.5ZM16,18V15h4v3ZM9.75,18V15h4.5v3ZM4,18V15H8v3Z',
  image:
    'M6,7A2,2,0,1,1,8,9,2,2,0,0,1,6,7Zm15.8,9.73-6-8a.76.76,0,0,0-1.2,0l-4,5.17a.5.5,0,0,1-.78,0L7.63,11.24a.75.75,0,0,0-1.17,0L2.21,16.73a1,1,0,0,0-.21.61v.91a.76.76,0,0,0,.75.75h18.5a.76.76,0,0,0,.75-.75v-.92A1,1,0,0,0,21.8,16.73Z',
  image_rotate_left:
    'M18,10H6a2,2,0,0,0-2,2v8a2,2,0,0,0,2,2H18a2,2,0,0,0,2-2V12A2,2,0,0,0,18,10Zm0,10H6V12H18ZM6,7.5V3.62a.49.49,0,0,1,.85-.35l1,1a7,7,0,0,1,12,3.13.53.53,0,0,1-.1.42.49.49,0,0,1-.39.19h-1a.5.5,0,0,1-.48-.36,5,5,0,0,0-8.53-2l1.46,1.47a.5.5,0,0,1-.35.85H6.5A.52.52,0,0,1,6,7.5Z',
  image_rotate_right:
    'M18,10H6a2,2,0,0,0-2,2v8a2,2,0,0,0,2,2H18a2,2,0,0,0,2-2V12A2,2,0,0,0,18,10Zm0,10H6V12H18ZM4.29,7.81a.53.53,0,0,1-.1-.42,7,7,0,0,1,12-3.13l1-1a.49.49,0,0,1,.85.35V7.5a.52.52,0,0,1-.5.5H13.61a.5.5,0,0,1-.35-.85l1.46-1.47a5,5,0,0,0-8.53,2A.5.5,0,0,1,5.71,8h-1A.49.49,0,0,1,4.29,7.81Z',
  intersect:
    'M2,10V20a2,2,0,0,0,2,2H14a2,2,0,0,0,2-2V16h4a2,2,0,0,0,2-2V4a2,2,0,0,0-2-2H10A2,2,0,0,0,8,4V8H4A2,2,0,0,0,2,10Zm14,4V10a2,2,0,0,0-2-2H10V4H20V14ZM4,10H8v4a2,2,0,0,0,2,2h4v4H4Z',
  invert:
    'M21,5a2,2,0,0,0-2-2H5A2,2,0,0,0,3,5V19a2,2,0,0,0,2,2H19a2,2,0,0,0,2-2ZM12,17v2H5V5h7V7a5,5,0,0,1,0,10ZM7,12a5,5,0,0,0,5,5V7A5,5,0,0,0,7,12Z',
  invert_colors:
    'M12,20a6,6,0,0,1-6-6c0-2.47,1.61-5.28,4.91-8.59L12,4.33Zm.15-18h-.3a.75.75,0,0,0-.53.22L9.5,4C6.86,6.64,4,10.19,4,14a8,8,0,0,0,16,0c0-3.81-2.86-7.36-5.5-10L12.68,2.18A.75.75,0,0,0,12.15,2Z',
  invert_colors_off:
    'M20.85,20.62,4.38,4.15a.48.48,0,0,0-.7,0l-.53.53a.48.48,0,0,0,0,.7L6,8.22A10.78,10.78,0,0,0,4,14a8,8,0,0,0,13.53,5.77l2.09,2.08a.48.48,0,0,0,.7,0l.53-.53A.48.48,0,0,0,20.85,20.62ZM12,20a6,6,0,0,1-6-6A8.38,8.38,0,0,1,7.44,9.67L12,14.24ZM10.91,5.41,12,4.33v5l7.49,7.49A7.79,7.79,0,0,0,20,14c0-3.81-2.86-7.36-5.5-10L12.68,2.18A.75.75,0,0,0,12.15,2h-.3a.75.75,0,0,0-.53.22L9.5,4c-.45.45-.91.93-1.36,1.43L9.56,6.85C10,6.38,10.42,5.91,10.91,5.41Z',
  italic:
    'M16.5,6h-.83a1,1,0,0,0-.95.67L11.4,16.34a.5.5,0,0,0,.47.66h.63a.5.5,0,0,1,.5.5v1a.5.5,0,0,1-.5.5h-5a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h.83a1,1,0,0,0,.95-.67L12.6,6.66A.5.5,0,0,0,12.12,6H11.5a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h5a.5.5,0,0,1,.5.5v1A.5.5,0,0,1,16.5,6Z',
  keyframe_bezier_in:
    'M10.67,3a2,2,0,0,1,1.42.59l7.62,7.62a1,1,0,0,1,.29.7v.18a1,1,0,0,1-.29.7l-7.62,7.62a2,2,0,0,1-1.42.59H4a1,1,0,0,1-1-1V18.51a1,1,0,0,1,.42-.81l3.32-2.37A3,3,0,0,0,8,12.88V11.12A3,3,0,0,0,6.74,8.67L3.42,6.3A1,1,0,0,1,3,5.49V4A1,1,0,0,1,4,3Z',
  keyframe_bezier_out:
    'M19,3a1,1,0,0,1,1,1V5.49a1,1,0,0,1-.42.81L16.26,8.67A3,3,0,0,0,15,11.12v1.76a3,3,0,0,0,1.26,2.45l3.32,2.37a1,1,0,0,1,.42.81V20a1,1,0,0,1-1,1H12.33a2,2,0,0,1-1.42-.59L3.29,12.79a1,1,0,0,1-.29-.7v-.18a1,1,0,0,1,.29-.7l7.62-7.62A2,2,0,0,1,12.33,3Z',
  keyframe_cont_bezier:
    'M19.58,6.3,16.26,8.67A3,3,0,0,0,15,11.12v1.76a3,3,0,0,0,1.26,2.45l3.32,2.37a1,1,0,0,1,.42.81V20a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V18.51a1,1,0,0,1,.42-.81l3.32-2.37A3,3,0,0,0,9,12.88V11.12A3,3,0,0,0,7.74,8.67L4.42,6.3A1,1,0,0,1,4,5.49V4A1,1,0,0,1,5,3H19a1,1,0,0,1,1,1V5.49A1,1,0,0,1,19.58,6.3Z',
  keyframe_linear:
    'M21,11.91v.18a1,1,0,0,1-.29.7l-7.92,7.92a1,1,0,0,1-.7.29h-.18a1,1,0,0,1-.7-.29L3.29,12.79a1,1,0,0,1-.29-.7v-.18a1,1,0,0,1,.29-.7l7.92-7.92a1,1,0,0,1,.7-.29h.18a1,1,0,0,1,.7.29l7.92,7.92A1,1,0,0,1,21,11.91Z',
  keyframe_linear_in:
    'M21,4V20a1,1,0,0,1-1,1H12.33a2,2,0,0,1-1.42-.59L3.29,12.79a1,1,0,0,1-.29-.7v-.18a1,1,0,0,1,.29-.7l7.62-7.62A2,2,0,0,1,12.33,3H20A1,1,0,0,1,21,4Z',
  keyframe_linear_out:
    'M4,3h7.67a2,2,0,0,1,1.42.59l7.62,7.62a1,1,0,0,1,.29.7v.18a1,1,0,0,1-.29.7l-7.62,7.62a2,2,0,0,1-1.42.59H4a1,1,0,0,1-1-1V4A1,1,0,0,1,4,3Z',
  knife:
    'M4.61,14.15A5.5,5.5,0,0,0,3,18V20.5a.5.5,0,0,0,.5.5H9.74a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,1,.5-.5h1a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,1,.5-.5h1a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,1,.5-.5h1a.5.5,0,0,0,.5-.5V13.41a1,1,0,0,1,.29-.7l1.26-1.26a.5.5,0,0,0,0-.7l-.86-.86L20.85,6a.48.48,0,0,0,0-.7l-2-2A1,1,0,0,0,18.17,3h-2a1,1,0,0,0-.7.29Zm9.92-2.27a1,1,0,0,0-.29.71V13h-1a1,1,0,0,0-1,1v.5a.5.5,0,0,1-.5.5h-.5a1,1,0,0,0-1,1v.5a.5.5,0,0,1-.5.5h-.5a1,1,0,0,0-1,1v.5a.5.5,0,0,1-.5.5H5V18a3.5,3.5,0,0,1,1-2.48L12.9,8.69l2.41,2.41Zm3.32-5.32-.7.71a.51.51,0,0,1-.71,0l-.71-.71a.51.51,0,0,1,0-.71l.71-.7a.5.5,0,0,1,.71,0l.7.7A.5.5,0,0,1,17.85,6.56Z',
  lasso:
    'M12,2C6.49,2,2,6,2,11a8.73,8.73,0,0,0,4,7.17A2,2,0,0,1,4,20H2.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5H4a4,4,0,0,0,3.42-2A3.94,3.94,0,0,0,12.65,20c5.21-.31,9.35-4.21,9.35-9C22,6,17.51,2,12,2Zm-.28,16H11a1,1,0,0,0-1,1,2,2,0,1,1,1.72-1Zm2.2-.22a4,4,0,0,0-7.72-2A6.54,6.54,0,0,1,4,11c0-3.86,3.59-7,8-7s8,3.14,8,7C20,14.28,17.41,17,13.92,17.78Z',
  layers:
    'M21,8.92v.16a.49.49,0,0,1-.22.41l-7.67,5.25a1.49,1.49,0,0,1-.84.26h-.54a1.49,1.49,0,0,1-.84-.26L3.22,9.49A.49.49,0,0,1,3,9.08V8.92a.49.49,0,0,1,.22-.41l7.67-5.25A1.49,1.49,0,0,1,11.73,3h.54a1.49,1.49,0,0,1,.84.26l7.67,5.25A.49.49,0,0,1,21,8.92Zm-2.33,4.14L16.9,14.27,18,15l-5.85,4h-.22L6,15l1.06-.73L5.33,13.06,3.22,14.51a.49.49,0,0,0-.22.41v.16a.49.49,0,0,0,.22.41l7.67,5.25a1.49,1.49,0,0,0,.84.26h.54a1.49,1.49,0,0,0,.84-.26l7.67-5.25a.49.49,0,0,0,.22-.41v-.16a.49.49,0,0,0-.22-.41Z',
  layers_off:
    'M21,8.92v.16a.49.49,0,0,1-.22.41l-4.5,3.08L8.56,4.85l2.33-1.59A1.49,1.49,0,0,1,11.73,3h.54a1.49,1.49,0,0,1,.84.26l7.67,5.25A.49.49,0,0,1,21,8.92Zm-2.33,4.14-1.13.77,2.3,2.3.94-.64a.49.49,0,0,0,.22-.41v-.16a.49.49,0,0,0-.22-.41ZM4.38,3.15a.48.48,0,0,0-.7,0l-.53.53a.48.48,0,0,0,0,.7L5.63,6.87,3.22,8.51A.49.49,0,0,0,3,8.92v.16a.49.49,0,0,0,.22.41l7.67,5.25a1.49,1.49,0,0,0,.84.26h.54a1.49,1.49,0,0,0,.84-.26l.23-.16,2.13,2.13L12.11,19h-.22L6,15l1.06-.73L5.33,13.06,3.22,14.51a.49.49,0,0,0-.22.41v.16a.49.49,0,0,0,.22.41l7.67,5.25a1.49,1.49,0,0,0,.84.26h.54a1.49,1.49,0,0,0,.84-.26l3.8-2.59,2.71,2.7a.48.48,0,0,0,.7,0l.53-.53a.48.48,0,0,0,0-.7Z',
  layers_outlined:
    'M20.78,8.51,13.11,3.26A1.49,1.49,0,0,0,12.27,3h-.54a1.49,1.49,0,0,0-.84.26L3.22,8.51A.49.49,0,0,0,3,8.92v.16a.49.49,0,0,0,.22.41l7.67,5.25a1.49,1.49,0,0,0,.84.26h.54a1.49,1.49,0,0,0,.84-.26l7.67-5.25A.49.49,0,0,0,21,9.08V8.92A.49.49,0,0,0,20.78,8.51ZM12.11,13h-.22L6,9l5.85-4h.22L18,9Zm8.67,1.51-2.11-1.45L16.9,14.27,18,15l-5.85,4h-.22L6,15l1.06-.73L5.33,13.06,3.22,14.51a.49.49,0,0,0-.22.41v.16a.49.49,0,0,0,.22.41l7.67,5.25a1.49,1.49,0,0,0,.84.26h.54a1.49,1.49,0,0,0,.84-.26l7.67-5.25a.49.49,0,0,0,.22-.41v-.16A.49.49,0,0,0,20.78,14.51Z',
  ligature:
    'M17,11a1,1,0,0,0-1-1H11V8a2,2,0,0,1,2-2,6.17,6.17,0,0,1,2.65.73.5.5,0,0,0,.67-.22l.45-.89a.5.5,0,0,0,0-.38A.48.48,0,0,0,16.55,5,8.24,8.24,0,0,0,13,4,4,4,0,0,0,9,8v2H7.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5H9v6.5a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5V12h4v6.5a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5Z',
  macro:
    'M19.73,14h.77a.5.5,0,0,1,.5.5V16a6,6,0,0,1-6,6H9a6,6,0,0,1-6-6V14.5a.5.5,0,0,1,.5-.5h.77a2,2,0,0,1,1.29.47L10.91,19H11V13A6,6,0,0,1,5,7V3.5A.5.5,0,0,1,5.5,3h.38a1.5,1.5,0,0,1,1.06.44L8.5,5l2.78-2.78A.75.75,0,0,1,11.81,2h.38a.75.75,0,0,1,.53.22L15.5,5l1.56-1.56A1.5,1.5,0,0,1,18.12,3h.38a.5.5,0,0,1,.5.5V7a6,6,0,0,1-6,6v6h.09l5.35-4.53A2,2,0,0,1,19.73,14Z',
  macro_outlined:
    'M21,14.5a.5.5,0,0,0-.5-.5h-.77a2,2,0,0,0-1.29.47L13.09,19H13V13a6,6,0,0,0,6-6V3.5a.5.5,0,0,0-.5-.5h-.38a1.5,1.5,0,0,0-1.06.44L15.5,5,12.72,2.22A.75.75,0,0,0,12.19,2h-.38a.75.75,0,0,0-.53.22L8.5,5,6.94,3.44A1.5,1.5,0,0,0,5.88,3H5.5a.5.5,0,0,0-.5.5V7a6,6,0,0,0,6,6v6h-.09L5.56,14.47A2,2,0,0,0,4.27,14H3.5a.5.5,0,0,0-.5.5V16a6,6,0,0,0,6,6h6a6,6,0,0,0,6-6ZM17,6.34h0V7a4,4,0,0,1-4,4h-.67ZM9.72,10.78A4,4,0,0,1,7,7V6.33H7l3.58,3.57Zm.19-4.37h0L12,4.33l2.09,2.08h0L12,8.5Z',
  magic_wand:
    'M16.85,9.27,5.27,20.85a.5.5,0,0,1-.71,0L3.15,19.44a.5.5,0,0,1,0-.71L14.73,7.15a.5.5,0,0,1,.71,0l1.41,1.41A.5.5,0,0,1,16.85,9.27ZM8.24,5.9A4,4,0,0,1,10.1,7.76a.44.44,0,0,0,.37.24h.06a.44.44,0,0,0,.37-.24A4,4,0,0,1,12.76,5.9.44.44,0,0,0,13,5.53V5.47a.44.44,0,0,0-.24-.37,4,4,0,0,1-1.85-1.85.46.46,0,0,0-.82,0A4,4,0,0,1,8.24,5.1.44.44,0,0,0,8,5.47v.06A.44.44,0,0,0,8.24,5.9ZM20.82,4.68A3.19,3.19,0,0,1,19.33,3.2.38.38,0,0,0,19,3h0a.38.38,0,0,0-.33.2,3.17,3.17,0,0,1-1.48,1.48h0A.3.3,0,0,0,17,5V5a.3.3,0,0,0,.19.29h0a3.21,3.21,0,0,1,1.49,1.49A.34.34,0,0,0,19,7H19a.34.34,0,0,0,.3-.18,3.17,3.17,0,0,1,1.49-1.49A.34.34,0,0,0,21,5V5A.34.34,0,0,0,20.82,4.68ZM16.24,13.9a4,4,0,0,1,1.86,1.86.44.44,0,0,0,.37.24h.06a.44.44,0,0,0,.37-.24,4,4,0,0,1,1.86-1.86.44.44,0,0,0,.24-.37v-.06a.44.44,0,0,0-.24-.37,4,4,0,0,1-1.85-1.85.46.46,0,0,0-.82,0,4,4,0,0,1-1.85,1.85.44.44,0,0,0-.24.37v.06A.44.44,0,0,0,16.24,13.9Z',
  marker:
    'M20.76,7.58l-6.69,7.94a.49.49,0,0,1-.73,0L8.45,10.66a.49.49,0,0,1,0-.73l7.94-6.69a1,1,0,0,1,1.36,0l2.93,2.93A1,1,0,0,1,20.76,7.58ZM7.18,11.86a.5.5,0,0,0-.7,0L4.41,13.93a1,1,0,0,0-.29.7v1.42a1,1,0,0,0,.29.7l.57.57L2.15,20.15A.5.5,0,0,0,2.5,21H5.12a1.05,1.05,0,0,0,.71-.29L7.1,19.44l.27.27a1,1,0,0,0,.7.29H9.25a1,1,0,0,0,.7-.29l2.19-2.19a.5.5,0,0,0,0-.7Z',
  marker_outlined:
    'M17,5.36,18.64,7l-5,6L11,10.4l6-5M17.07,3a1,1,0,0,0-.65.24L8.48,9.93a.49.49,0,0,0,0,.73l4.89,4.89a.49.49,0,0,0,.35.15.49.49,0,0,0,.38-.18l6.69-7.94a1,1,0,0,0-.05-1.36L17.78,3.29A1,1,0,0,0,17.07,3ZM7.18,11.86a.5.5,0,0,0-.7,0L4.41,13.93a1,1,0,0,0-.29.7v1.42a1,1,0,0,0,.29.7l.57.57L2.15,20.15A.5.5,0,0,0,2.5,21H5.12a1.05,1.05,0,0,0,.71-.29L7.1,19.44l.27.27a1,1,0,0,0,.7.29H9.25a1,1,0,0,0,.7-.29l2.19-2.19a.5.5,0,0,0,0-.7Z',
  mask:
    'M18.65,9a6.69,6.69,0,0,0-2-.84h0c-.21,0-.43-.08-.64-.11V4a2,2,0,0,0-2-2H4A2,2,0,0,0,2,4V14a2,2,0,0,0,2,2H8.08c0,.21.06.43.11.64h0a6.69,6.69,0,0,0,.84,2,.5.5,0,0,0,.79.11l.73-.73a.51.51,0,0,0,.07-.61,5.6,5.6,0,0,1-.44-1h0c0-.12,0-.25-.08-.37H14a2,2,0,0,0,2-2V10.11l.37.08h0a5.6,5.6,0,0,1,1,.44.51.51,0,0,0,.61-.07l.73-.73A.5.5,0,0,0,18.65,9ZM4,4H14V8.08a6.91,6.91,0,0,0-2.65,1h0A7.08,7.08,0,0,0,9,11.35H9a6.91,6.91,0,0,0-1,2.65H4ZM21.32,16h-1a.5.5,0,0,0-.48.38,5.6,5.6,0,0,1-.44,1,.51.51,0,0,0,.07.61l.73.73a.5.5,0,0,0,.79-.11,6.69,6.69,0,0,0,.84-2A.5.5,0,0,0,21.32,16Zm-7.7,3.81a5.6,5.6,0,0,1-1-.44.51.51,0,0,0-.61.07l-.73.73a.5.5,0,0,0,.11.79,6.69,6.69,0,0,0,2,.84.5.5,0,0,0,.63-.48v-1A.5.5,0,0,0,13.62,19.81Zm6.19-6.19a.5.5,0,0,0,.48.38h1a.5.5,0,0,0,.48-.63,6.69,6.69,0,0,0-.84-2,.5.5,0,0,0-.79-.11l-.73.73a.51.51,0,0,0-.07.61A5.6,5.6,0,0,1,19.81,13.62ZM18,19.44a.51.51,0,0,0-.61-.07,5.6,5.6,0,0,1-1,.44.5.5,0,0,0-.38.48v1a.5.5,0,0,0,.63.48,6.69,6.69,0,0,0,2-.84.5.5,0,0,0,.11-.79Z',
  mask_vs:
    'M19,3H5A2,2,0,0,0,3,5V19a2,2,0,0,0,2,2H19a2,2,0,0,0,2-2V5A2,2,0,0,0,19,3ZM12,17a5,5,0,1,1,5-5A5,5,0,0,1,12,17Z',
  material:
    'M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2ZM4,12h8V4a8,8,0,0,1,8,8H12v8A8,8,0,0,1,4,12Z',
  media_photo:
    'M17,18a2,2,0,0,0,2-2V7a2,2,0,0,0-2-2H12.41a1,1,0,0,1-.7-.29L10.29,3.29A1,1,0,0,0,9.59,3H3A2,2,0,0,0,1,5V16a2,2,0,0,0,2,2ZM7.5,6A1.5,1.5,0,1,1,6,7.5,1.5,1.5,0,0,1,7.5,6ZM17,15.5a.5.5,0,0,1-.5.5H3.5a.5.5,0,0,1-.5-.5v-.34a.46.46,0,0,1,.11-.3L6,11.19A.52.52,0,0,1,6.35,11a.5.5,0,0,1,.39.18l1.45,1.75a.49.49,0,0,0,.38.18.51.51,0,0,0,.4-.2l2.88-3.72a.51.51,0,0,1,.8,0l4.25,5.67a.51.51,0,0,1,.1.3ZM21,9v9a2,2,0,0,1-2,2H5a2,2,0,0,0,2,2H19a4,4,0,0,0,4-4V11A2,2,0,0,0,21,9Z',
  mesh_grid:
    'M20,4H4A2,2,0,0,0,2,6V18a2,2,0,0,0,2,2H20a2,2,0,0,0,2-2V6A2,2,0,0,0,20,4Zm0,5.35a13.42,13.42,0,0,1-2.46.37A18,18,0,0,0,16.71,6H20ZM4,10.83A9,9,0,0,1,8,10a11.13,11.13,0,0,1,1.82.14A18,18,0,0,1,9.92,12s0,0,0,.06A9.07,9.07,0,0,0,9,12a14.24,14.24,0,0,0-5,.9Zm7.61-.28,1,.35a12.94,12.94,0,0,0,3.27.8c0,.1,0,.2,0,.3a18,18,0,0,1-.2,2.65,10.89,10.89,0,0,0-4.08-2.26c0-.13,0-.26,0-.39C11.67,11.5,11.64,11,11.61,10.55ZM11.33,8.4A18.06,18.06,0,0,0,10.71,6h4.14a15.63,15.63,0,0,1,.91,3.68A11.68,11.68,0,0,1,13.31,9C12.71,8.81,12.06,8.58,11.33,8.4Zm3.88,8.54c-.12.38-.24.74-.36,1.06H9.78a11.41,11.41,0,0,0,1.59-3.6A10,10,0,0,1,15.21,16.94ZM8.85,6a14.94,14.94,0,0,1,.63,2.08A13.49,13.49,0,0,0,8,8a11.59,11.59,0,0,0-4,.66V6ZM4,15.05A12.47,12.47,0,0,1,9,14c.23,0,.44,0,.66,0a10.23,10.23,0,0,1-2,4H4ZM18.57,18a15.07,15.07,0,0,0-1.35-1.84A19.4,19.4,0,0,0,17.67,12c0-.1,0-.19,0-.29A15.15,15.15,0,0,0,20,11.4V18Z',
  mouse_cursor:
    'M18.75,9.22,6.27,2.1A.75.75,0,0,0,5.89,2a.69.69,0,0,0-.37.1l-.12.07a.77.77,0,0,0-.4.65V17.16a.5.5,0,0,0,.33.47h0a.5.5,0,0,0,.54-.13l3.06-3.38,4.28,7.41a.77.77,0,0,0,.46.35L14,22l.19,0a.75.75,0,0,0,.38-.1l3.43-2a.75.75,0,0,0,.35-.45l.1-.37a.75.75,0,0,0-.07-.57l-4.28-7.41,4.46-1a.49.49,0,0,0,.39-.4h0A.49.49,0,0,0,18.75,9.22Z',
  mouse_cursor_outlined:
    'M18.75,9.22,6.27,2.1A.75.75,0,0,0,5.89,2a.69.69,0,0,0-.37.1l-.12.07a.77.77,0,0,0-.4.65V17.16a.5.5,0,0,0,.33.47h0a.5.5,0,0,0,.54-.13l3.06-3.38,4.28,7.41a.77.77,0,0,0,.46.35L14,22l.19,0a.75.75,0,0,0,.38-.1l3.43-2a.75.75,0,0,0,.35-.45l.1-.37a.75.75,0,0,0-.07-.57l-4.28-7.41,4.46-1a.49.49,0,0,0,.39-.4h0A.49.49,0,0,0,18.75,9.22Zm-7,.38a.5.5,0,0,0-.33.74l4.81,8.34-1.73,1L9.65,11.34a.51.51,0,0,0-.81-.08L7,13.28V4.83L14.37,9Z',
  opacity:
    'M19,3H5A2,2,0,0,0,3,5V19a2,2,0,0,0,2,2H19a2,2,0,0,0,2-2V5A2,2,0,0,0,19,3ZM5,19V16.14L7.86,19Zm5.33,0L5,13.67V11.19L12.81,19Zm4.95,0L5,8.71V6.24L17.76,19ZM19,17.76,6.24,5H8.71L19,15.29Zm0-5L11.19,5h2.47L19,10.34Zm0-4.95L16.14,5H19Z',
  paint:
    'M20,5.5v4A1.5,1.5,0,0,1,18.5,11H12v2h.5a.5.5,0,0,1,.5.5v8a.5.5,0,0,1-.5.5h-3a.5.5,0,0,1-.5-.5v-8a.5.5,0,0,1,.5-.5H10V11a1.5,1.5,0,0,1,1.5-1.5h7v-4H17V7a1,1,0,0,1-1,1H5A1,1,0,0,1,4,7V3A1,1,0,0,1,5,2H16a1,1,0,0,1,1,1V4h1.5A1.5,1.5,0,0,1,20,5.5Z',
  paint_bucket:
    'M2,12.55v1.76a1.5,1.5,0,0,0,.44,1.06l6.19,6.19A1.5,1.5,0,0,0,9.69,22h.76a1.5,1.5,0,0,0,1.06-.44l8.33-8.33a1,1,0,0,0,0-1.41L10.19,2.15A.51.51,0,0,0,9.83,2a.5.5,0,0,0-.35.15l-.71.7a.51.51,0,0,0,0,.71l.8.8L2.44,11.49A1.5,1.5,0,0,0,2,12.55Zm9-6.77L17.19,12H4.76ZM19.48,16h.06a.33.33,0,0,1,.23.1c.75.84,2.23,2.42,2.23,3.4a2.5,2.5,0,0,1-5,0c0-1,1.46-2.55,2.22-3.38A.32.32,0,0,1,19.48,16Z',
  paint_outlined:
    'M18.5,4H17V3a1,1,0,0,0-1-1H5A1,1,0,0,0,4,3V7A1,1,0,0,0,5,8H16a1,1,0,0,0,1-1V5.5h1.5v4h-7A1.5,1.5,0,0,0,10,11v2H9.5a.5.5,0,0,0-.5.5v8a.5.5,0,0,0,.5.5h3a.5.5,0,0,0,.5-.5v-8a.5.5,0,0,0-.5-.5H12V11h6.5A1.5,1.5,0,0,0,20,9.5v-4A1.5,1.5,0,0,0,18.5,4ZM15,6H6V4h9Z',
  palette:
    'M19.36,5.36,17.71,3.71A2.41,2.41,0,0,0,16,3h0a2,2,0,0,0-2,2V6.59a1,1,0,0,1-1.71.7l-.12-.12A4,4,0,0,0,9.34,6H8a6,6,0,0,0-6,6v1a8,8,0,0,0,8,8h4a8,8,0,0,0,8-8V11.73A9,9,0,0,0,19.36,5.36ZM6.5,14A1.5,1.5,0,1,1,8,12.5,1.5,1.5,0,0,1,6.5,14Zm3,4A1.5,1.5,0,1,1,11,16.5,1.5,1.5,0,0,1,9.5,18Zm5,0A1.5,1.5,0,1,1,16,16.5,1.5,1.5,0,0,1,14.5,18Zm3-4A1.5,1.5,0,1,1,19,12.5,1.5,1.5,0,0,1,17.5,14Z',
  palette_outlined:
    'M16,5c0.1,0,0.2,0,0.3,0.1l1.7,1.7c1.3,1.3,2.1,3.1,2.1,4.9V13c0,3.3-2.7,6-6,6h-4c-3.3,0-6-2.7-6-6v-1 c0-1.3,0.6-2.4,1.6-3.2C6.3,8.3,7.1,8,8,8h1.3c0.5,0,1,0.2,1.4,0.6l0.1,0.1c0.6,0.6,1.3,0.9,2.1,0.9c1.7,0,3-1.3,3-3L16,5L16,5 M16,3L16,3c-1.1,0-2,0.9-2,2v1.6c0,0.6-0.5,1-1,1c-0.2,0-0.5-0.1-0.7-0.3l-0.1-0.1C11.4,6.4,10.4,6,9.3,6H8C6.7,6,5.4,6.4,4.4,7.2 h0C2.9,8.3,2,10.1,2,12v1c0,4.4,3.6,8,8,8h4c4.4,0,8-3.6,8-8v-1.3c0-2.4-0.9-4.7-2.6-6.4l-1.7-1.7C17.3,3.3,16.6,3,16,3L16,3z M7.5,13C6.7,13,6,12.3,6,11.5S6.7,10,7.5,10S9,10.7,9,11.5S8.3,13,7.5,13z M9.5,17C8.7,17,8,16.3,8,15.5S8.7,14,9.5,14 s1.5,0.7,1.5,1.5S10.3,17,9.5,17z M14.5,17c-0.8,0-1.5-0.7-1.5-1.5s0.7-1.5,1.5-1.5s1.5,0.7,1.5,1.5S15.3,17,14.5,17z M16.5,13 c-0.8,0-1.5-0.7-1.5-1.5s0.7-1.5,1.5-1.5s1.5,0.7,1.5,1.5S17.3,13,16.5,13z',
  panorama:
    'M22,5h-.56a3.69,3.69,0,0,0-.87.1l-2.5.55A28.22,28.22,0,0,1,12,6.32a28.22,28.22,0,0,1-6.07-.67L3.43,5.1A3.69,3.69,0,0,0,2.56,5H2A1,1,0,0,0,1,6V18a1,1,0,0,0,1,1h.56a3.69,3.69,0,0,0,.87-.1l2.5-.55a27.88,27.88,0,0,1,12.15,0l2.49.55a3.69,3.69,0,0,0,.87.1H22a1,1,0,0,0,1-1V6a1,1,0,0,0-1-1Z',
  panorama_image:
    'M22,5h-.56a3.69,3.69,0,0,0-.87.1l-2.5.55A28.22,28.22,0,0,1,12,6.32a28.22,28.22,0,0,1-6.07-.67L3.43,5.1A3.69,3.69,0,0,0,2.56,5H2A1,1,0,0,0,1,6V18a1,1,0,0,0,1,1h.56a3.69,3.69,0,0,0,.87-.1l2.5-.55a27.88,27.88,0,0,1,12.15,0l2.49.55a3.69,3.69,0,0,0,.87.1H22a1,1,0,0,0,1-1V6A1,1,0,0,0,22,5ZM21,16a.74.74,0,0,1-.28.58.76.76,0,0,1-.63.15l-.46-.1a2,2,0,0,1-1.17-.76L16.07,12.7a.5.5,0,0,0-.8,0l-1,1.36a.5.5,0,0,1-.79,0l-2.31-2.88a.5.5,0,0,0-.78,0L8.09,14.07a.51.51,0,0,1-.8,0l-1-1.36a.51.51,0,0,0-.81,0L3.89,14.81A.48.48,0,0,1,3.5,15a.49.49,0,0,1-.5-.5V7.05l2.49.55A29.72,29.72,0,0,0,12,8.32a29.72,29.72,0,0,0,6.51-.72L21,7.05Zm-4.5-5.27A1.25,1.25,0,1,0,17.75,9.5,1.25,1.25,0,0,0,16.5,10.75Z',
  panorama_outlined:
    'M21,7.05V17l-2.49-.55a29.79,29.79,0,0,0-13,0L3,17V7.05l2.49.55A29.72,29.72,0,0,0,12,8.32a29.72,29.72,0,0,0,6.51-.72L21,7.05m1-2h-.56a3.69,3.69,0,0,0-.87.1l-2.5.55A28.22,28.22,0,0,1,12,6.32a28.22,28.22,0,0,1-6.07-.67L3.43,5.1A3.69,3.69,0,0,0,2.56,5H2A1,1,0,0,0,1,6V18a1,1,0,0,0,1,1h.56a3.69,3.69,0,0,0,.87-.1l2.5-.55a27.88,27.88,0,0,1,12.15,0l2.49.55a3.69,3.69,0,0,0,.87.1H22a1,1,0,0,0,1-1V6a1,1,0,0,0-1-1Z',
  panorama_vs:
    'M7,6.69V17.31a1,1,0,0,1-.8,1l-.27.06-2.5.55a3.69,3.69,0,0,1-.87.1H2a1,1,0,0,1-1-1V6A1,1,0,0,1,2,5h.56a3.69,3.69,0,0,1,.87.1l2.5.55.27.06A1,1,0,0,1,7,6.69ZM22,5h-.56a3.69,3.69,0,0,0-.87.1l-2.5.55-.27.06a1,1,0,0,0-.8,1V17.31a1,1,0,0,0,.8,1l.28.06,2.49.55a3.69,3.69,0,0,0,.87.1H22a1,1,0,0,0,1-1V6A1,1,0,0,0,22,5ZM13.93,6.25c-.64,0-1.29.07-1.93.07s-1.29,0-1.93-.07a1,1,0,0,0-.75.27A1,1,0,0,0,9,7.25v9.5a1,1,0,0,0,.32.73,1,1,0,0,0,.75.27c.64,0,1.29-.07,1.93-.07s1.29,0,1.93.07a1,1,0,0,0,.75-.27,1,1,0,0,0,.32-.73V7.25a1,1,0,0,0-.32-.73A1,1,0,0,0,13.93,6.25Z',
  panorama_vs_outlined:
    'M22,5h-.56a3.69,3.69,0,0,0-.87.1l-2.5.55A28.22,28.22,0,0,1,12,6.32a28.22,28.22,0,0,1-6.07-.67L3.43,5.1A3.69,3.69,0,0,0,2.56,5H2A1,1,0,0,0,1,6V18a1,1,0,0,0,1,1h.56a3.69,3.69,0,0,0,.87-.1l2.5-.55a27.88,27.88,0,0,1,12.15,0l2.49.55a3.69,3.69,0,0,0,.87.1H22a1,1,0,0,0,1-1V6A1,1,0,0,0,22,5ZM5.49,16.4,3,17V7.05l2.49.55c.5.11,1,.21,1.51.29v8.22C6.5,16.19,6,16.29,5.49,16.4ZM9,15.84V8.16c1,.1,2,.16,3,.16s2-.06,3-.16v7.68c-1-.1-2-.16-3-.16S10,15.74,9,15.84ZM21,17l-2.49-.55c-.5-.11-1-.21-1.51-.29V7.89c.51-.08,1-.18,1.51-.29L21,7.05Z',
  pan_tool:
    'M22,5.5v9.91a6,6,0,0,1-.79,3L18.58,23a2,2,0,0,1-1.74,1H11.7a2,2,0,0,1-1.25-.44l-3.72-3a5.94,5.94,0,0,1-1.25-1.35l-.91-1.37A2.94,2.94,0,0,0,3,16.68l-1.34-.45h0l-.36-.12A.49.49,0,0,1,1,15.63v-.14A1.49,1.49,0,0,1,2.5,14h.84a3.94,3.94,0,0,1,1.28.2L7,15V2.5a1.5,1.5,0,0,1,3,0v8a.5.5,0,0,0,1,0v-9a1.5,1.5,0,0,1,3,0v9a.5.5,0,0,0,1,0v-8a1.5,1.5,0,0,1,3,0v8a.5.5,0,0,0,1,0v-5a1.5,1.5,0,0,1,3,0Z',
  paste:
    'M16,2H4A2,2,0,0,0,2,4V16a2,2,0,0,0,2,2H16a2,2,0,0,0,2-2V4A2,2,0,0,0,16,2Zm0,14H4V4H16ZM8.5,20H6a2,2,0,0,0,2,2h.5a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,8.5,20Zm4,0h-1a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,12.5,20Zm4,0h-1a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,16.5,20Zm5-1H20a.47.47,0,0,0-.39.2,2.52,2.52,0,0,1-.4.4A.47.47,0,0,0,19,20v1.54a.26.26,0,0,0,.11.21.25.25,0,0,0,.22,0,4,4,0,0,0,2.44-2.44.25.25,0,0,0,0-.22A.26.26,0,0,0,21.53,19ZM20,6V8.5a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5V8A2,2,0,0,0,20,6Zm1.5,5h-1a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,21.5,11Zm0,4h-1a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,21.5,15Z',
  pattern:
    'M21,5a2,2,0,0,0-2-2H5A2,2,0,0,0,3,5V19a2,2,0,0,0,2,2H19a2,2,0,0,0,2-2Zm-4.3,7L19,9.7v4.6L16.7,12l-2.35,2.35L16.7,16.7,19,14.4V19l-2.3-2.3L14.4,19h-.1L12,16.7,9.7,19H9.6L7.3,16.7,5,19V14.4l2.3,2.3,2.35-2.35L7.3,12,5,14.3V9.7L7.3,12,9.65,9.65,7.3,7.3,5,9.6V5L7.3,7.3,9.6,5h.1L12,7.3,14.3,5h.1l2.3,2.3L19,5V9.6L16.7,7.3,14.35,9.65Zm-7-2.35L12,12l2.35-2.35L12,7.3Zm0,4.7L12,16.7l2.35-2.35L12,12Z',
  pen:
    'M20.85,9.51,14.49,3.15a.5.5,0,0,0-.71,0l-2.7,2.7a.5.5,0,0,0,0,.71l.59.6L6.24,9.33A2,2,0,0,0,5,10.7L3,18.76a1,1,0,0,0,.26.95l1,1A1,1,0,0,0,5,21a1,1,0,0,0,.24,0l8.06-2a2,2,0,0,0,1.37-1.2l2.17-5.43.6.59a.5.5,0,0,0,.71,0l2.7-2.7A.5.5,0,0,0,20.85,9.51Zm-8,7.51L6.69,18.55l2.86-2.86A.64.64,0,0,1,10,15.5,1.5,1.5,0,1,0,8.5,14a.64.64,0,0,1-.19.45L5.45,17.31,7,11.18l6.23-2.49,2.1,2.1Z',
  photo_album:
    'M18,2H6A2,2,0,0,0,4,4V20a2,2,0,0,0,2,2H18a2,2,0,0,0,2-2V4A2,2,0,0,0,18,2ZM6,4.5A.5.5,0,0,1,6.5,4h5a.5.5,0,0,1,.5.5v5a.5.5,0,0,1-.78.42L9.28,8.62a.52.52,0,0,0-.56,0L6.78,9.92A.5.5,0,0,1,6,9.5Zm12,15a.5.5,0,0,1-.5.5H6.5a.5.5,0,0,1-.5-.5v-.33a.51.51,0,0,1,.11-.31l2.74-3.53a.5.5,0,0,1,.78,0l1.09,1.31a.5.5,0,0,0,.78,0l2-2.57a.5.5,0,0,1,.79,0l3.61,4.81a.51.51,0,0,1,.1.3Z',
  photo_filter:
    'M19,3H5A2,2,0,0,0,3,5V19a2,2,0,0,0,2,2H19a2,2,0,0,0,2-2V5A2,2,0,0,0,19,3Zm0,16H5V5H19ZM7.29,14.48a4.82,4.82,0,0,1,2.23,2.23A.51.51,0,0,0,10,17H10a.51.51,0,0,0,.44-.29,4.82,4.82,0,0,1,2.23-2.23A.51.51,0,0,0,13,14V14a.51.51,0,0,0-.29-.44,4.8,4.8,0,0,1-2.22-2.21.54.54,0,0,0-1,0,4.8,4.8,0,0,1-2.22,2.21A.51.51,0,0,0,7,14V14A.51.51,0,0,0,7.29,14.48Zm5.9-5.16h0a3.21,3.21,0,0,1,1.49,1.49A.34.34,0,0,0,15,11h0a.34.34,0,0,0,.3-.18,3.17,3.17,0,0,1,1.49-1.49A.34.34,0,0,0,17,9V9a.34.34,0,0,0-.18-.3A3.19,3.19,0,0,1,15.33,7.2.38.38,0,0,0,15,7h0a.38.38,0,0,0-.33.2,3.17,3.17,0,0,1-1.48,1.48h0A.3.3,0,0,0,13,9V9A.3.3,0,0,0,13.19,9.32Z',
  photo_gallery:
    'M19,17V3a2,2,0,0,0-2-2H3A2,2,0,0,0,1,3V17a2,2,0,0,0,2,2H17A2,2,0,0,0,19,17ZM7.5,6A1.5,1.5,0,1,1,6,7.5,1.5,1.5,0,0,1,7.5,6ZM16,15.5a.5.5,0,0,1-.5.5H4.5a.5.5,0,0,1-.5-.5v-.33a.51.51,0,0,1,.1-.3L6.47,11.7a.51.51,0,0,1,.81,0l1,1.36a.5.5,0,0,0,.79,0l2.29-2.88a.5.5,0,0,1,.78,0l3.74,4.67a.52.52,0,0,1,.11.32ZM21,5V19a2,2,0,0,1-2,2H5a2,2,0,0,0,2,2H19a4,4,0,0,0,4-4V7A2,2,0,0,0,21,5Z',
  photo_gallery_outlined:
    'M7.5,6A1.5,1.5,0,1,0,9,7.5,1.5,1.5,0,0,0,7.5,6ZM17,1H3A2,2,0,0,0,1,3V17a2,2,0,0,0,2,2H17a2,2,0,0,0,2-2V3A2,2,0,0,0,17,1Zm0,13.82a.5.5,0,0,1-.5.51.49.49,0,0,1-.39-.19l-4-5a.49.49,0,0,0-.39-.19.48.48,0,0,0-.39.19L9.08,13.07a.48.48,0,0,1-.39.19.51.51,0,0,1-.4-.2l-1-1.36a.54.54,0,0,0-.41-.2.51.51,0,0,0-.4.2L3.9,15.13a.48.48,0,0,1-.4.2.49.49,0,0,1-.5-.5V3H17ZM7.5,9A1.5,1.5,0,1,0,6,7.5,1.5,1.5,0,0,0,7.5,9Zm0-3A1.5,1.5,0,1,0,9,7.5,1.5,1.5,0,0,0,7.5,6ZM21,5V19a2,2,0,0,1-2,2H5a2,2,0,0,0,2,2H19a4,4,0,0,0,4-4V7A2,2,0,0,0,21,5Z',
  photo_landscape:
    'M22,6a2,2,0,0,0-2-2H4A2,2,0,0,0,2,6V18a2,2,0,0,0,2,2H20a2,2,0,0,0,2-2ZM9.5,7A1.5,1.5,0,1,1,8,8.5,1.5,1.5,0,0,1,9.5,7ZM19,16.5a.5.5,0,0,1-.5.5H5.5a.5.5,0,0,1-.5-.5v-.34a.46.46,0,0,1,.11-.3L8,12.19A.52.52,0,0,1,8.35,12a.5.5,0,0,1,.39.18l1.45,1.75a.49.49,0,0,0,.38.18.51.51,0,0,0,.4-.2l2.88-3.72a.51.51,0,0,1,.8,0l4.25,5.67a.51.51,0,0,1,.1.3Z',
  photo_landscape_outlined:
    'M20,4H4A2,2,0,0,0,2,6V18a2,2,0,0,0,2,2H20a2,2,0,0,0,2-2V6A2,2,0,0,0,20,4Zm0,12.83a.49.49,0,0,1-.5.5.48.48,0,0,1-.4-.2L14.65,11.2a.51.51,0,0,0-.8,0L11,14.91a.51.51,0,0,1-.4.2.49.49,0,0,1-.38-.18L8.74,13.18A.5.5,0,0,0,8.35,13a.52.52,0,0,0-.39.19L4.89,17.13a.48.48,0,0,1-.39.19.49.49,0,0,1-.5-.5V6H20ZM8,9.5A1.5,1.5,0,1,1,9.5,11,1.5,1.5,0,0,1,8,9.5Z',
  photo_square:
    'M21,5a2,2,0,0,0-2-2H5A2,2,0,0,0,3,5V19a2,2,0,0,0,2,2H19a2,2,0,0,0,2-2ZM9.5,8A1.5,1.5,0,1,1,8,9.5,1.5,1.5,0,0,1,9.5,8ZM18,17.5a.5.5,0,0,1-.5.5H6.5a.5.5,0,0,1-.5-.5v-.33a.51.51,0,0,1,.1-.3L8.47,13.7a.51.51,0,0,1,.81,0l1,1.36a.5.5,0,0,0,.79,0l2.29-2.88a.5.5,0,0,1,.78,0l3.74,4.67a.52.52,0,0,1,.11.32Z',
  photo_square_outlined:
    'M21,5a2,2,0,0,0-2-2H5A2,2,0,0,0,3,5V19a2,2,0,0,0,2,2H19a2,2,0,0,0,2-2ZM19,16.82a.5.5,0,0,1-.5.51.49.49,0,0,1-.39-.19l-4-5a.49.49,0,0,0-.39-.19.48.48,0,0,0-.39.19l-2.29,2.88a.48.48,0,0,1-.39.19.51.51,0,0,1-.4-.2l-1-1.36a.54.54,0,0,0-.41-.2.51.51,0,0,0-.4.2L5.9,17.13a.48.48,0,0,1-.4.2.49.49,0,0,1-.5-.5V5H19ZM9.5,11A1.5,1.5,0,1,0,8,9.5,1.5,1.5,0,0,0,9.5,11Z',
  podcast:
    'M12,14h0M12,4A10,10,0,0,0,2,14v.5a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5V14a8,8,0,0,1,16,0v.5a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5V14A10,10,0,0,0,12,4Zm0,4a6,6,0,0,0-6,6v.5a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5V14a4,4,0,0,1,8,0v.5a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5V14a6,6,0,0,0-6-6Zm0,4a2,2,0,1,0,2,2,2,2,0,0,0-2-2Zm1.88,5H10.12a1.5,1.5,0,0,0-1.34.83l-.73,1.45A.5.5,0,0,0,8.5,20h7a.5.5,0,0,0,.45-.72l-.73-1.45A1.5,1.5,0,0,0,13.88,17Z',
  print:
    'M19,7V5a2,2,0,0,0-2-2H7A2,2,0,0,0,5,5V7A2,2,0,0,0,3,9v5.5a.5.5,0,0,0,.5.5H5v4a2,2,0,0,0,2,2H17a2,2,0,0,0,2-2V15h1.5a.5.5,0,0,0,.5-.5V9A2,2,0,0,0,19,7ZM7,5H17V7H7ZM17,19H7V13H17Zm2-8.5a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5Z',
  print_disabled:
    'M4.38,3.15a.48.48,0,0,0-.7,0l-.53.53a.48.48,0,0,0,0,.7L5,6.24V7A2,2,0,0,0,3,9v5.5a.5.5,0,0,0,.5.5H5v4a2,2,0,0,0,2,2H17a2,2,0,0,0,1.74-1l.88.88a.48.48,0,0,0,.7,0l.53-.53a.48.48,0,0,0,0-.7ZM17,19H7V13h4.76L17,18.24ZM19,7V5a2,2,0,0,0-2-2H7a2.3,2.3,0,0,0-.26,0l2,2H17V7H10.71l8,8H20.5a.5.5,0,0,0,.5-.5V9A2,2,0,0,0,19,7Zm0,3.5a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5Z',
  redo:
    'M11.48,8a10.37,10.37,0,0,1,7,2.57l2.42-2.42A.49.49,0,0,1,21.21,8h.29a.5.5,0,0,1,.5.5v7a.5.5,0,0,1-.5.5h-7a.5.5,0,0,1-.5-.5v-.29a.49.49,0,0,1,.15-.36l2.52-2.52a7.9,7.9,0,0,0-5.19-1.83c-3.67,0-6.69,2.23-7,5.05A.49.49,0,0,1,4,16H2.5a.5.5,0,0,1-.5-.54C2.33,11.29,6.45,8,11.48,8Z',
  reflect_horizontal:
    'M3.29,5.9a.67.67,0,0,0-.43-.14.69.69,0,0,0-.37.1l-.11.06A.74.74,0,0,0,2,6.57V17.42a.74.74,0,0,0,.38.65l.11.06a.69.69,0,0,0,.37.1.73.73,0,0,0,.43-.13l7.39-5.18a.75.75,0,0,0,.32-.61v-.62a.75.75,0,0,0-.32-.61ZM4,15.16V8.83L8.51,12ZM21.62,5.92l-.11-.06a.73.73,0,0,0-.8,0l-7.39,5.18a.75.75,0,0,0-.32.61v.62a.75.75,0,0,0,.32.61l7.39,5.18a.75.75,0,0,0,.8,0l.11-.06a.74.74,0,0,0,.38-.65V6.57A.74.74,0,0,0,21.62,5.92ZM12.5,3h-1a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,12.5,3Zm0,4h-1a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,12.5,7Zm0,8h-1a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,12.5,15Zm0,4h-1a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,12.5,19Z',
  reflect_vertical:
    'M18.1,3.29a.67.67,0,0,0,.14-.43.69.69,0,0,0-.1-.37l-.06-.11A.74.74,0,0,0,17.43,2H6.58a.74.74,0,0,0-.65.38l-.06.11a.69.69,0,0,0-.1.37.73.73,0,0,0,.13.43l5.18,7.39a.75.75,0,0,0,.61.32h.62a.75.75,0,0,0,.61-.32ZM8.84,4h6.33L12,8.51Zm8.59,18H6.58a.74.74,0,0,1-.65-.38l-.06-.11a.75.75,0,0,1,0-.8l5.18-7.39a.75.75,0,0,1,.61-.32h.62a.75.75,0,0,1,.61.32l5.18,7.39a.73.73,0,0,1,0,.8l-.06.11A.74.74,0,0,1,17.43,22Zm3.07-9h-1a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5v1A.5.5,0,0,1,20.5,13Zm-4,0h-1a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5v1A.5.5,0,0,1,16.5,13Zm-8,0h-1a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5v1A.5.5,0,0,1,8.5,13Zm-4,0h-1a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5v1A.5.5,0,0,1,4.5,13Z',
  rounded_corner:
    'M11,4.52v-1a.5.5,0,0,1,.16-.36A.55.55,0,0,1,11.53,3,10,10,0,0,1,21,12.47a.55.55,0,0,1-.14.37.5.5,0,0,1-.36.16h-1a.49.49,0,0,1-.49-.47A8,8,0,0,0,11.47,5,.49.49,0,0,1,11,4.52ZM20.5,15h-1a.5.5,0,0,0-.5.5V19H5V5H8.5A.5.5,0,0,0,9,4.5v-1A.5.5,0,0,0,8.5,3H5A2,2,0,0,0,3,5V19a2,2,0,0,0,2,2H19a2,2,0,0,0,2-2V15.5A.5.5,0,0,0,20.5,15Zm-9-1A1.5,1.5,0,1,0,10,12.5,1.5,1.5,0,0,0,11.5,14Z',
  ruler:
    'M21.71,5.81,18.19,2.29a1,1,0,0,0-.7-.29h-.73a1,1,0,0,0-.71.29L2.29,16.05a1,1,0,0,0-.29.71v.73a1,1,0,0,0,.29.7l3.52,3.52a1,1,0,0,0,.7.29h.73A1,1,0,0,0,8,21.71L21.71,8A1,1,0,0,0,22,7.24V6.51A1,1,0,0,0,21.71,5.81ZM6.88,20,4.05,17.12l1-.95,1.06,1.06a.5.5,0,0,0,.7,0l.54-.54a.5.5,0,0,0,0-.7L6.25,14.93l1.24-1.24.56.56a.5.5,0,0,0,.7,0l.54-.54a.5.5,0,0,0,0-.7l-.56-.56L10,11.21,11,12.27a.5.5,0,0,0,.7,0l.54-.54a.5.5,0,0,0,0-.7L11.21,10l1.24-1.24.56.56a.5.5,0,0,0,.7,0l.54-.54a.5.5,0,0,0,0-.7l-.56-.56,1.24-1.24L16,7.31a.5.5,0,0,0,.7,0l.54-.54a.5.5,0,0,0,0-.7L16.17,5l.95-1L20,6.88Z',
  save:
    'M20.41,6.41,17.59,3.59A2,2,0,0,0,16.17,3H5A2,2,0,0,0,3,5V19a2,2,0,0,0,2,2H19a2,2,0,0,0,2-2V7.83A2,2,0,0,0,20.41,6.41ZM9,6.5V5h6V6.5a.5.5,0,0,1-.5.5h-5A.5.5,0,0,1,9,6.5ZM17,19H7V11.5a.5.5,0,0,1,.5-.5h9a.5.5,0,0,1,.5.5Zm-2.5-4h-5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h5a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,14.5,15Z',
  save_outlined:
    'M20.41,6.41,17.59,3.59A2,2,0,0,0,16.17,3H5A2,2,0,0,0,3,5V19a2,2,0,0,0,2,2H19a2,2,0,0,0,2-2V7.83A2,2,0,0,0,20.41,6.41ZM9,16.5v-1a.5.5,0,0,1,.5-.5h5a.5.5,0,0,1,.5.5v1a.5.5,0,0,1-.5.5h-5A.5.5,0,0,1,9,16.5ZM19,19H17V11.5a.5.5,0,0,0-.5-.5h-9a.5.5,0,0,0-.5.5V19H5V5H9V6.5a.5.5,0,0,0,.5.5h5a.5.5,0,0,0,.5-.5V5h1.17L19,7.83Z',
  scan_document:
    'M4,20a2,2,0,0,0,2,2h7.17a2,2,0,0,0,1.42-.59l4.82-4.82A2,2,0,0,0,20,15.17V13H4Zm9-4a1,1,0,0,1,1-1h4.18L13,20.18Zm8.5-7H20V4a2,2,0,0,0-2-2H6A2,2,0,0,0,4,4V9H2.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h19a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,21.5,9Z',
  scan_document_icon:
    'M21.5,8H20V4a2,2,0,0,0-2-2H6A2,2,0,0,0,4,4V8H2.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h19a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,21.5,8ZM6,4H18V8H6ZM18,14H13a1,1,0,0,0-1,1v5H6V12H4v8a2,2,0,0,0,2,2h6.76a3,3,0,0,0,2.12-.88l4.24-4.24A3,3,0,0,0,20,14.76V12H18Z',
  selection:
    'M9,19.5v1a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h1A.5.5,0,0,1,9,19.5Zm3.5-.5h-1a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,12.5,19ZM13,3.5a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5ZM8.5,3h-1a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h1A.5.5,0,0,0,9,4.5v-1A.5.5,0,0,0,8.5,3ZM4.71,3A2,2,0,0,0,3,4.71a.23.23,0,0,0,.06.2A.25.25,0,0,0,3.27,5H4.5A.5.5,0,0,0,5,4.5V3.27a.25.25,0,0,0-.09-.19A.23.23,0,0,0,4.71,3ZM4.5,7h-1a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h1A.5.5,0,0,0,5,8.5v-1A.5.5,0,0,0,4.5,7Zm0,4h-1a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,4.5,11Zm0,4h-1a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,4.5,15Zm0,4H3.27a.25.25,0,0,0-.19.09.23.23,0,0,0-.06.2A2,2,0,0,0,4.71,21a.23.23,0,0,0,.2-.06A.25.25,0,0,0,5,20.73V19.5A.5.5,0,0,0,4.5,19Zm16.23,0H19.5a.5.5,0,0,0-.5.5v1.23a.25.25,0,0,0,.09.19.23.23,0,0,0,.2.06A2,2,0,0,0,21,19.29a.23.23,0,0,0-.06-.2A.25.25,0,0,0,20.73,19ZM19,4.5a.5.5,0,0,0,.5.5h1.23a.25.25,0,0,0,.19-.09.23.23,0,0,0,.06-.2A2,2,0,0,0,19.29,3a.23.23,0,0,0-.2.06.25.25,0,0,0-.09.19ZM20.5,7h-1a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,20.5,7Zm0,4h-1a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,20.5,11Zm0,4h-1a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,20.5,15ZM17,3.5a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5ZM16.5,19h-1a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,16.5,19Z',
  send_backward:
    'M20,11H18V8a2,2,0,0,0-2-2H13V4a2,2,0,0,0-2-2H4A2,2,0,0,0,2,4v7a2,2,0,0,0,2,2H6v3a2,2,0,0,0,2,2h3v2a2,2,0,0,0,2,2h7a2,2,0,0,0,2-2V13A2,2,0,0,0,20,11ZM4,4h7v7H4ZM20,20H13V13h7Z',
  send_to_back:
    'M4,16H8v4a2,2,0,0,0,2,2H20a2,2,0,0,0,2-2V10a2,2,0,0,0-2-2H16V4a2,2,0,0,0-2-2H4A2,2,0,0,0,2,4V14A2,2,0,0,0,4,16ZM4,4H14V14H4Z',
  sharpness:
    'M22.9,3.37a.79.79,0,0,0,0-.76l-.14-.23A.73.73,0,0,0,22.11,2H1.89a.73.73,0,0,0-.65.38l-.14.23a.79.79,0,0,0,0,.76L11.21,20.63a.76.76,0,0,0,.65.37h.3a.76.76,0,0,0,.65-.37ZM12,18,3.79,4H12Z',
  shear:
    'M21.5,5h-9a2,2,0,0,0-1.63.84L10,7.06V6A1,1,0,0,0,9,5H3A1,1,0,0,0,2,6V18a1.42,1.42,0,0,0,0,.2H2A1,1,0,0,0,3,19h8.44a2,2,0,0,0,1.63-.84L21.91,5.79A.5.5,0,0,0,21.5,5ZM4,7H8V9.86l-4,5.6Z',
  shutter:
    'M9,9.75,6,4.58a.52.52,0,0,1,.13-.66A10,10,0,0,1,12,2a10.17,10.17,0,0,1,1.47.11.5.5,0,0,1,.35.74l-4,6.9A.5.5,0,0,1,9,9.75ZM12.87,9h8a.5.5,0,0,0,.47-.67,10,10,0,0,0-5.25-5.46.49.49,0,0,0-.63.21l-3,5.17A.5.5,0,0,0,12.87,9ZM5,5.86a.49.49,0,0,0-.81-.07A9.91,9.91,0,0,0,2,12a9.28,9.28,0,0,0,.07,1.06.49.49,0,0,0,.5.44h6A.5.5,0,0,0,9,12.75ZM11.13,15h-8a.5.5,0,0,0-.47.67A10,10,0,0,0,8,21.13a.49.49,0,0,0,.63-.21l3-5.17A.5.5,0,0,0,11.13,15ZM19,18.14a.49.49,0,0,0,.81.07A9.91,9.91,0,0,0,22,12a9.28,9.28,0,0,0-.07-1.06.49.49,0,0,0-.5-.44h-6a.5.5,0,0,0-.43.75Zm-4.84-3.89-4,6.9a.5.5,0,0,0,.35.74A10.17,10.17,0,0,0,12,22a10,10,0,0,0,5.89-1.92.52.52,0,0,0,.13-.66l-3-5.17A.5.5,0,0,0,14.17,14.25Z',
  shutter_outlined:
    'M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm3,2.61a8,8,0,0,1,4,3.64.51.51,0,0,1-.44.75H12.87a.52.52,0,0,1-.44-.25.5.5,0,0,1,0-.5l2-3.42A.49.49,0,0,1,15,4.61ZM7.11,5.68A8,8,0,0,1,12,4h.31a.48.48,0,0,1,.41.26.5.5,0,0,1,0,.49l-2.89,5a.5.5,0,0,1-.86,0L7,6.33A.52.52,0,0,1,7.11,5.68Zm-3,7.4A7.62,7.62,0,0,1,4,12,7.88,7.88,0,0,1,5.23,7.76a.5.5,0,0,1,.86,0L8.54,12l.43.75a.5.5,0,0,1,0,.5.52.52,0,0,1-.43.25h-4A.5.5,0,0,1,4.08,13.08ZM9,19.39a8,8,0,0,1-4-3.64A.51.51,0,0,1,5.38,15h5.75a.52.52,0,0,1,.44.25.5.5,0,0,1,0,.5l-2,3.42A.49.49,0,0,1,9,19.39Zm7.92-1.07A8,8,0,0,1,12,20h-.31a.48.48,0,0,1-.41-.26.5.5,0,0,1,0-.49l2.89-5a.5.5,0,0,1,.86,0l2,3.42A.51.51,0,0,1,16.89,18.32Zm1.88-2.08a.5.5,0,0,1-.86,0l-2.88-5a.5.5,0,0,1,.43-.75h4a.5.5,0,0,1,.49.42A7.62,7.62,0,0,1,20,12,7.88,7.88,0,0,1,18.77,16.24Z',
  slideshow:
    'M19,3H5A2,2,0,0,0,3,5V19a2,2,0,0,0,2,2H19a2,2,0,0,0,2-2V5A2,2,0,0,0,19,3Zm-3.94,9.62-3.89,2.6A.75.75,0,0,1,10,14.6V9.4a.75.75,0,0,1,1.17-.62l3.89,2.6A.74.74,0,0,1,15.06,12.62Z',
  slideshow_outlined:
    'M19,3H5A2,2,0,0,0,3,5V19a2,2,0,0,0,2,2H19a2,2,0,0,0,2-2V5A2,2,0,0,0,19,3Zm0,16H5V5H19Zm-7.83-3.78,3.89-2.6a.74.74,0,0,0,0-1.24l-3.89-2.6A.75.75,0,0,0,10,9.4v5.2A.75.75,0,0,0,11.17,15.22Z',
  small_caps:
    'M9,18.5a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5V6H2.5A.5.5,0,0,1,2,5.5v-1A.5.5,0,0,1,2.5,4h11a.5.5,0,0,1,.5.5v1a.5.5,0,0,1-.5.5H9ZM18,10h3.5a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5h-9a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5H16v8.5a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5Z',
  snap_to:
    'M18.65,9.06h-.48a1,1,0,0,0-.7.29l-6,6a2.09,2.09,0,0,1-2.65.3,1.38,1.38,0,0,1-.26-.22,2,2,0,0,1,0-2.83l6.1-6a1,1,0,0,0,.3-.71V5.34a1,1,0,0,0-.29-.7L12.29,2.29a1,1,0,0,0-.7-.29h-.51a1,1,0,0,0-.7.29L4.48,8.15a8.18,8.18,0,0,0-.56,11.12l.36.39a8,8,0,0,0,11.32,0l6.1-6.06a1,1,0,0,0,.3-.71v-.48a1,1,0,0,0-.29-.7L19.35,9.35A1,1,0,0,0,18.65,9.06Zm-4.47,9.18a6,6,0,0,1-8.48,0A3.83,3.83,0,0,1,5.42,18a6.17,6.17,0,0,1,.47-8.39l2.8-2.73L10.1,8.24l-3,2.93a4,4,0,0,0,0,5.66,3.6,3.6,0,0,0,.52.43,4.1,4.1,0,0,0,5.22-.52l2.91-2.84,1.41,1.41Z',
  spiral:
    'M21,16.35a.54.54,0,0,1-.11.37A12.8,12.8,0,0,1,12,21h0A9,9,0,0,1,12,3a7,7,0,0,1,0,14A5,5,0,0,1,12,7a3,3,0,0,1,.6,5.94.53.53,0,0,1-.42-.1.53.53,0,0,1-.18-.39v-1a.5.5,0,0,1,.33-.47A1,1,0,0,0,12,9a3,3,0,0,0,0,6A5,5,0,0,0,12,5a7,7,0,0,0,0,14h0a10.78,10.78,0,0,0,7.32-3.53.5.5,0,0,1,.7-.08l.79.62A.58.58,0,0,1,21,16.35Z',
  stamp:
    'M6,20H18v1.5a.5.5,0,0,1-.5.5H6.5a.5.5,0,0,1-.5-.5Zm12.55-6.41-3.82-1.1a1,1,0,0,1-.73-1V10a1,1,0,0,1,.4-.8,4,4,0,1,0-4.8,0,1,1,0,0,1,.4.8v1.55a1,1,0,0,1-.73,1l-3.82,1.1A2,2,0,0,0,4,15.51v2a.5.5,0,0,0,.5.5h15a.5.5,0,0,0,.5-.5v-2A2,2,0,0,0,18.55,13.59Z',
  strikethrough:
    'M20,10.5v1a.5.5,0,0,1-.5.5H4.5a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5H11V6H6.5A.5.5,0,0,1,6,5.5v-1A.5.5,0,0,1,6.5,4h11a.5.5,0,0,1,.5.5v1a.5.5,0,0,1-.5.5H13v4h6.5A.5.5,0,0,1,20,10.5Zm-9,8a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5V14H11Z',
  stroke_weight:
    'M4,21.5v-1a.5.5,0,0,1,.5-.5h15a.5.5,0,0,1,.5.5v1a.5.5,0,0,1-.5.5H4.5A.5.5,0,0,1,4,21.5ZM4.5,18h15a.5.5,0,0,0,.5-.5v-2a.5.5,0,0,0-.5-.5H4.5a.5.5,0,0,0-.5.5v2A.5.5,0,0,0,4.5,18Zm0-5h15a.5.5,0,0,0,.5-.5v-3a.5.5,0,0,0-.5-.5H4.5a.5.5,0,0,0-.5.5v3A.5.5,0,0,0,4.5,13Zm0-6h15a.5.5,0,0,0,.5-.5v-4a.5.5,0,0,0-.5-.5H4.5a.5.5,0,0,0-.5.5v4A.5.5,0,0,0,4.5,7Z',
  substract:
    'M2,10V20a2,2,0,0,0,2,2H14a2,2,0,0,0,2-2V16h4a2,2,0,0,0,2-2V4a2,2,0,0,0-2-2H10A2,2,0,0,0,8,4V8H4A2,2,0,0,0,2,10Zm2,0H14V20H4Z',
  switch_camera:
    'M20,7H18.83a2,2,0,0,1-1.42-.59L15.88,4.88A3,3,0,0,0,13.76,4H10.24a3,3,0,0,0-2.12.88L6.59,6.41A2,2,0,0,1,5.17,7H4A2,2,0,0,0,2,9v9a2,2,0,0,0,2,2H20a2,2,0,0,0,2-2V9A2,2,0,0,0,20,7Zm-8.41,3.5a2.25,2.25,0,0,0-1.84,2.21h.81a.25.25,0,0,1,.21.39L9.23,15.4a.24.24,0,0,1-.41,0L7.28,13.1a.25.25,0,0,1,.21-.39h.76A3.75,3.75,0,0,1,11.41,9,.5.5,0,0,1,12,9.5V10A.5.5,0,0,1,11.59,10.5Zm4.92,2.79h-.76A3.75,3.75,0,0,1,12.59,17,.5.5,0,0,1,12,16.5V16a.5.5,0,0,1,.41-.49,2.25,2.25,0,0,0,1.84-2.21h-.81a.25.25,0,0,1-.21-.39l1.54-2.3a.24.24,0,0,1,.41,0l1.54,2.3A.25.25,0,0,1,16.51,13.29Z',
  switch_video:
    'M20.5,7h-.09a1,1,0,0,0-.7.29L17,10V8a2,2,0,0,0-2-2H5A2,2,0,0,0,3,8v8a2,2,0,0,0,2,2H15a2,2,0,0,0,2-2V14l2.71,2.71a1,1,0,0,0,.7.29h.09a.5.5,0,0,0,.5-.5v-9A.5.5,0,0,0,20.5,7ZM9.59,9.5a2.25,2.25,0,0,0-1.84,2.21h.81a.25.25,0,0,1,.21.39L7.23,14.4a.24.24,0,0,1-.41,0L5.28,12.1a.25.25,0,0,1,.21-.39h.76A3.75,3.75,0,0,1,9.41,8,.5.5,0,0,1,10,8.5V9A.5.5,0,0,1,9.59,9.5Zm4.92,2.79h-.76A3.75,3.75,0,0,1,10.59,16,.5.5,0,0,1,10,15.5V15a.5.5,0,0,1,.41-.49,2.25,2.25,0,0,0,1.84-2.21h-.81a.25.25,0,0,1-.21-.39l1.54-2.3a.24.24,0,0,1,.41,0l1.54,2.3A.25.25,0,0,1,14.51,12.29Z',
  text:
    'M13,18.5a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5V6H6.5A.5.5,0,0,1,6,5.5v-1A.5.5,0,0,1,6.5,4h11a.5.5,0,0,1,.5.5v1a.5.5,0,0,1-.5.5H13Z',
  texture:
    'M3,5V19a2,2,0,0,0,2,2H19a2,2,0,0,0,2-2V5a2,2,0,0,0-2-2H5A2,2,0,0,0,3,5ZM5,8H8V5h4V8h4V5h3V8H16v4h3v4H16v3H12V16H8v3H5V16H8V12H5Zm11,4v4H12V12ZM8,12V8h4v4Z',
  text_center:
    'M21,11.5v1a.5.5,0,0,1-.5.5H3.5a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h17A.5.5,0,0,1,21,11.5ZM7.5,9h9a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5h-9a.5.5,0,0,0-.5.5v1A.5.5,0,0,0,7.5,9Zm13-6H3.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h17a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,20.5,3Zm0,16H3.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h17a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,20.5,19Zm-13-2h9a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5h-9a.5.5,0,0,0-.5.5v1A.5.5,0,0,0,7.5,17Z',
  text_left:
    'M21,11.5v1a.5.5,0,0,1-.5.5H3.5a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h17A.5.5,0,0,1,21,11.5ZM3.5,9h9a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5h-9a.5.5,0,0,0-.5.5v1A.5.5,0,0,0,3.5,9Zm17-6H3.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h17a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,20.5,3Zm0,16H3.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h17a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,20.5,19Zm-17-2h9a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5h-9a.5.5,0,0,0-.5.5v1A.5.5,0,0,0,3.5,17Z',
  text_right:
    'M21,11.5v1a.5.5,0,0,1-.5.5H3.5a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h17A.5.5,0,0,1,21,11.5ZM11.5,9h9a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5h-9a.5.5,0,0,0-.5.5v1A.5.5,0,0,0,11.5,9Zm9-6H3.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h17a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,20.5,3Zm0,16H3.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h17a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,20.5,19Zm-9-2h9a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5h-9a.5.5,0,0,0-.5.5v1A.5.5,0,0,0,11.5,17Z',
  tools:
    'M3.3,18.6C3.1,18.8,3,19,3,19.3v0.2c0,0.3,0.1,0.5,0.3,0.7l0.5,0.5C4,20.9,4.3,21,4.5,21h0.2c0.3,0,0.5-0.1,0.7-0.3l5.7-5.7 L9,12.9L3.3,18.6z M17,11c2.2,0,4-1.8,4-4V6.7c0-0.1-0.1-0.3-0.1-0.4l-0.2-0.2C20.5,6,20.2,6,20,6.1c0,0,0,0,0,0l-1.6,1.6 c-0.2,0.2-0.5,0.2-0.7,0l-1.4-1.4c-0.2-0.2-0.2-0.5,0-0.7L17.9,4c0.2-0.2,0.2-0.5,0-0.7c0,0,0,0,0,0l-0.2-0.2C17.6,3,17.5,3,17.3,3 H17c-2.2,0-4,1.8-4,4c0,0.5,0.1,1.1,0.3,1.6l-1.7,1.7L8.2,6.8L7.3,5.2C7.2,4.9,7,4.6,6.7,4.4L4.6,3.1C4.4,3,4.2,3,4,3.1L3.2,4 C3,4.2,3,4.4,3.1,4.6l1.4,2C4.6,7,4.9,7.2,5.2,7.3l1.6,0.9l0,0l6.8,6.8l-0.5,0.5c-0.2,0.2-0.2,0.5,0,0.7l4.5,4.5 c0.2,0.2,0.4,0.3,0.7,0.3h1.2c0.3,0,0.5-0.1,0.7-0.3l0.5-0.5c0.2-0.2,0.3-0.4,0.3-0.7v-1.2c0-0.3-0.1-0.5-0.3-0.7l-4.5-4.5 c-0.2-0.2-0.5-0.2-0.7,0L15,13.6l-1.2-1.2l1.7-1.7C15.9,10.9,16.5,11,17,11z',
  transform:
    'M20,9a1,1,0,0,0,1-1V4a1,1,0,0,0-1-1H16a1,1,0,0,0-1,1V5H9V4A1,1,0,0,0,8,3H4A1,1,0,0,0,3,4V8A1,1,0,0,0,4,9H5v6H4a1,1,0,0,0-1,1v4a1,1,0,0,0,1,1H8a1,1,0,0,0,1-1V19h6v1a1,1,0,0,0,1,1h4a1,1,0,0,0,1-1V16a1,1,0,0,0-1-1H19V9ZM17,5h2V7H17ZM5,5H7V7H5ZM7,19H5V17H7Zm12,0H17V17h2Zm-2-4H16a1,1,0,0,0-1,1v1H9V16a1,1,0,0,0-1-1H7V9H8A1,1,0,0,0,9,8V7h6V8a1,1,0,0,0,1,1h1Z',
  transform_artboard:
    'M5,9H2.5A.5.5,0,0,1,2,8.5v-1A.5.5,0,0,1,2.5,7H5ZM8.5,2h-1a.5.5,0,0,0-.5.5V5H9V2.5A.5.5,0,0,0,8.5,2ZM17,5V2.5a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5V5Zm5,3.5v-1a.5.5,0,0,0-.5-.5H19V9h2.5A.5.5,0,0,0,22,8.5ZM19,17h2.5a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5H19Zm-3.5,5h1a.5.5,0,0,0,.5-.5V19H15v2.5A.5.5,0,0,0,15.5,22ZM7,19v2.5a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5V19ZM2,15.5v1a.5.5,0,0,0,.5.5H5V15H2.5A.5.5,0,0,0,2,15.5ZM15,9H9v6h6V9m1.5-2a.5.5,0,0,1,.5.5v9a.5.5,0,0,1-.5.5h-9a.5.5,0,0,1-.5-.5v-9A.5.5,0,0,1,7.5,7Z',
  underline:
    'M19,17.5v1a.5.5,0,0,1-.5.5H5.5a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h13A.5.5,0,0,1,19,17.5ZM16.5,4h-1a.5.5,0,0,0-.5.5V10a3,3,0,0,1-6,0V4.5A.5.5,0,0,0,8.5,4h-1a.5.5,0,0,0-.5.5V10a5,5,0,0,0,10,0V4.5A.5.5,0,0,0,16.5,4Z',
  undo:
    'M22,15.46a.5.5,0,0,1-.5.54H20a.49.49,0,0,1-.49-.45c-.29-2.82-3.31-5.05-7-5.05a7.9,7.9,0,0,0-5.19,1.83l2.52,2.52a.49.49,0,0,1,.15.36v.29a.5.5,0,0,1-.5.5h-7a.5.5,0,0,1-.5-.5v-7A.5.5,0,0,1,2.5,8h.29a.49.49,0,0,1,.36.15l2.42,2.42a10.37,10.37,0,0,1,7-2.57C17.55,8,21.67,11.29,22,15.46Z',
  union:
    'M2,10V20a2,2,0,0,0,2,2H14a2,2,0,0,0,2-2V16h4a2,2,0,0,0,2-2V4a2,2,0,0,0-2-2H10A2,2,0,0,0,8,4V8H4A2,2,0,0,0,2,10Z',
  vector:
    'M22,13H20.1a11.86,11.86,0,0,0-4-4.25h3.73A1.75,1.75,0,1,0,19.67,7H15V6a1,1,0,0,0-1-1H10A1,1,0,0,0,9,6V7H4.33a1.75,1.75,0,1,0-.15,1.75H7.91A11.86,11.86,0,0,0,3.9,13H2a1,1,0,0,0-1,1v4a1,1,0,0,0,1,1H6a1,1,0,0,0,1-1V14a1,1,0,0,0-1-1H5.93A11.08,11.08,0,0,1,9,10.12.49.49,0,0,1,9,10a1,1,0,0,0,1,1h4a1,1,0,0,0,1-1,.49.49,0,0,1,0,.12A11.08,11.08,0,0,1,18.07,13H18a1,1,0,0,0-1,1v4a1,1,0,0,0,1,1h4a1,1,0,0,0,1-1V14A1,1,0,0,0,22,13ZM5,17H3V15H5Zm8-8H11V7h2Zm8,8H19V15h2Z',
  vertical_align_bottom:
    'M8.64,13.85A.5.5,0,0,1,9,13h2V3.5a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5V13h2a.5.5,0,0,1,.35.85l-3,3a.51.51,0,0,1-.71,0ZM3.5,21h17a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5H3.5a.5.5,0,0,0-.5.5v1A.5.5,0,0,0,3.5,21Z',
  vertical_align_middle:
    'M15.36,18.15A.5.5,0,0,1,15,19H13v2.5a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5V19H9a.5.5,0,0,1-.35-.85l3-3a.51.51,0,0,1,.71,0ZM20.5,11H3.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h17a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,20.5,11ZM11.65,8.86a.51.51,0,0,0,.71,0l3-3A.5.5,0,0,0,15,5H13V2.5a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5V5H9a.5.5,0,0,0-.35.85Z',
  vertical_align_top:
    'M15.36,10.15A.5.5,0,0,1,15,11H13v9.5a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5V11H9a.5.5,0,0,1-.35-.85l3-3a.51.51,0,0,1,.71,0ZM20.5,3H3.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h17a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,20.5,3Z',
  video_add:
    'M21.5,7h-.09a1,1,0,0,0-.7.29L18,10V7a2,2,0,0,0-2-2H4A2,2,0,0,0,2,7V17a2,2,0,0,0,2,2H16a2,2,0,0,0,2-2V14l2.71,2.71a1,1,0,0,0,.7.29h.09a.5.5,0,0,0,.5-.5v-9A.5.5,0,0,0,21.5,7ZM14,12.5a.5.5,0,0,1-.5.5H11v2.5a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5V13H6.5a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5H9V8.5A.5.5,0,0,1,9.5,8h1a.5.5,0,0,1,.5.5V11h2.5a.5.5,0,0,1,.5.5Z',
  video_gallery:
    'M17,1H3A2,2,0,0,0,1,3V17a2,2,0,0,0,2,2H17a2,2,0,0,0,2-2V3A2,2,0,0,0,17,1Zm-3.94,9.62-3.89,2.6A.75.75,0,0,1,8,12.6V7.4a.75.75,0,0,1,1.17-.62l3.89,2.6A.74.74,0,0,1,13.06,10.62ZM21,5V19a2,2,0,0,1-2,2H5a2,2,0,0,0,2,2H19a4,4,0,0,0,4-4V7A2,2,0,0,0,21,5Z',
  video_gallery_outlined:
    'M17,3V17H3V3H17m0-2H3A2,2,0,0,0,1,3V17a2,2,0,0,0,2,2H17a2,2,0,0,0,2-2V3a2,2,0,0,0-2-2Zm4,4V19a2,2,0,0,1-2,2H5a2,2,0,0,0,2,2H19a4,4,0,0,0,4-4V7A2,2,0,0,0,21,5ZM9.17,13.22l3.89-2.6a.74.74,0,0,0,0-1.24L9.17,6.78A.75.75,0,0,0,8,7.4v5.2A.75.75,0,0,0,9.17,13.22Z',
  video_horizontal:
    'M20,4H4A2,2,0,0,0,2,6V18a2,2,0,0,0,2,2H20a2,2,0,0,0,2-2V6A2,2,0,0,0,20,4Zm-4.94,8.62-3.89,2.6A.75.75,0,0,1,10,14.6V9.4a.75.75,0,0,1,1.17-.62l3.89,2.6A.74.74,0,0,1,15.06,12.62Z',
  video_horizontal_outlined:
    'M20,4H4A2,2,0,0,0,2,6V18a2,2,0,0,0,2,2H20a2,2,0,0,0,2-2V6A2,2,0,0,0,20,4Zm0,14H4V6H20Zm-8.83-2.78,3.89-2.6a.74.74,0,0,0,0-1.24l-3.89-2.6A.75.75,0,0,0,10,9.4v5.2A.75.75,0,0,0,11.17,15.22Z',
  video_vertical:
    'M20,20V4a2,2,0,0,0-2-2H6A2,2,0,0,0,4,4V20a2,2,0,0,0,2,2H18A2,2,0,0,0,20,20Zm-4.94-7.38-3.89,2.6A.75.75,0,0,1,10,14.6V9.4a.75.75,0,0,1,1.17-.62l3.89,2.6A.74.74,0,0,1,15.06,12.62Z',
  video_vertical_outlined:
    'M20,20V4a2,2,0,0,0-2-2H6A2,2,0,0,0,4,4V20a2,2,0,0,0,2,2H18A2,2,0,0,0,20,20ZM6,20V4H18V20Zm4-5.4V9.4a.75.75,0,0,1,1.17-.62l3.89,2.6a.74.74,0,0,1,0,1.24l-3.89,2.6A.75.75,0,0,1,10,14.6Z',
  american_football:
    'M18.14,5h0l.86.86V6c0,1.82,0,5.21-1.61,8-1.93,3.33-5.79,5-11.48,5h0L5,18.1V18c0-1.81,0-5.19,1.62-8,1.93-3.33,5.81-5,11.52-5m0-2C2.85,3,3,14.22,3,18.1a2,2,0,0,0,.59,1.42l.89.89A2,2,0,0,0,5.9,21C21.17,21,21,9.73,21,5.86a2,2,0,0,0-.59-1.42l-.85-.85A2,2,0,0,0,18.14,3Z',
  anchor:
    'M19.5,11h-.43a1,1,0,0,0-.71.29l-1.29,1.28a.26.26,0,0,0-.05.28.25.25,0,0,0,.23.15h.36a.25.25,0,0,1,.19.09.23.23,0,0,1,.05.21,6,6,0,0,1-4.55,4.55.23.23,0,0,1-.21-.05.25.25,0,0,1-.09-.19V10.5a.5.5,0,0,1,.5-.5h1a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5H13.1a.09.09,0,0,1-.1-.1h0a.13.13,0,0,1,.06-.1,3,3,0,1,0-2.12,0,.13.13,0,0,1,.06.1h0a.09.09,0,0,1-.1.1H9.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h1a.5.5,0,0,1,.5.5v7.11a.25.25,0,0,1-.09.19.23.23,0,0,1-.21.05A6,6,0,0,1,6.15,13.3a.23.23,0,0,1,0-.21A.25.25,0,0,1,6.39,13h.36A.25.25,0,0,0,7,12.85a.26.26,0,0,0-.05-.28L5.64,11.29A1,1,0,0,0,4.93,11H4.5a.5.5,0,0,0-.5.5v.73H4a8,8,0,0,0,6.3,7.58,1,1,0,0,1,.78.86l.11.89a.5.5,0,0,0,.49.44h.62a.5.5,0,0,0,.49-.44l.11-.89a1,1,0,0,1,.78-.86A8,8,0,0,0,20,12.23h0V11.5A.5.5,0,0,0,19.5,11ZM12,6a1,1,0,1,1,1-1A1,1,0,0,1,12,6Z',
  armchair:
    'M22,10v3a1,1,0,0,1-1,1H20v6.5a.5.5,0,0,1-.5.5h-2a.5.5,0,0,1-.5-.5V18H7v2.5a.5.5,0,0,1-.5.5h-2a.5.5,0,0,1-.5-.5V14H3a1,1,0,0,1-1-1V10A1,1,0,0,1,3,9H6a1,1,0,0,1,1,1v4H17V10a1,1,0,0,1,1-1h3A1,1,0,0,1,22,10ZM6,7.25A2.75,2.75,0,0,1,8.75,10v2h6.5V10A2.75,2.75,0,0,1,18,7.25h1V5a2,2,0,0,0-2-2H7A2,2,0,0,0,5,5V7.25Z',
  baseball:
    'M15,14.21a8.09,8.09,0,0,1-3.13,1.94l-1.11.37A6,6,0,0,0,8.45,18L6.33,20.09l.35.35a.5.5,0,0,1,0,.71l-.71.7a.48.48,0,0,1-.7,0L3.15,19.73a.48.48,0,0,1,0-.7l.7-.71a.5.5,0,0,1,.71,0l.35.35L7,16.55a6,6,0,0,0,1.45-2.34l.37-1.11A8.09,8.09,0,0,1,10.79,10l6.38-6.38a2,2,0,0,1,2.83,0L21.41,5a2,2,0,0,1,0,2.83ZM5.5,2A3.5,3.5,0,1,0,9,5.5,3.49,3.49,0,0,0,5.5,2Zm0,5.25A1.75,1.75,0,1,1,7.25,5.5,1.75,1.75,0,0,1,5.5,7.25Z',
  basketball:
    'M2,12A10,10,0,1,0,12,2,10,10,0,0,0,2,12Zm2.06,1H7a5.08,5.08,0,0,1-1.91,3.07A8,8,0,0,1,4.06,13ZM5.11,7.93A5.08,5.08,0,0,1,7,11H4.07A8.16,8.16,0,0,1,5.11,7.93ZM11,4.06V11H8.8A6.82,6.82,0,0,0,6.16,6.53,8.07,8.07,0,0,1,11,4.06Zm6.84,2.48A6.86,6.86,0,0,0,15.21,11H13V4.07A8,8,0,0,1,17.84,6.54ZM19.94,11H17a5.12,5.12,0,0,1,1.91-3.06A8,8,0,0,1,19.94,11Zm-1.05,5.07A5.08,5.08,0,0,1,17,13h2.95A8.16,8.16,0,0,1,18.89,16.07ZM13,19.94V13h2.2a6.82,6.82,0,0,0,2.64,4.47A8.07,8.07,0,0,1,13,19.94ZM6.16,17.47A6.82,6.82,0,0,0,8.8,13H11v6.93A8,8,0,0,1,6.16,17.47Z',
  birthday:
    'M19,9H13V6.72A2,2,0,0,0,14,5a6.65,6.65,0,0,0-1.46-2.8.48.48,0,0,0-.4-.2h-.28a.48.48,0,0,0-.4.2A6.65,6.65,0,0,0,10,5a2,2,0,0,0,1,1.72V9H5a2,2,0,0,0-2,2v6a1,1,0,0,0,1,1v3a1,1,0,0,0,1,1H19a1,1,0,0,0,1-1V18a1,1,0,0,0,1-1V11A2,2,0,0,0,19,9ZM18,20H6V17.87a4,4,0,0,0,2.26-1.59,4.93,4.93,0,0,0,7.48,0A4,4,0,0,0,18,17.87Z',
  birthday_outlined:
    'M19,9H13V6.72A2,2,0,0,0,14,5a6.65,6.65,0,0,0-1.46-2.8.48.48,0,0,0-.4-.2h-.28a.48.48,0,0,0-.4.2A6.65,6.65,0,0,0,10,5a2,2,0,0,0,1,1.72V9H5a2,2,0,0,0-2,2v6a1,1,0,0,0,1,1v3a1,1,0,0,0,1,1H19a1,1,0,0,0,1-1V18a1,1,0,0,0,1-1V11A2,2,0,0,0,19,9ZM5,11H19v5a2,2,0,0,1-1.62-.86l-.65-.93a.51.51,0,0,0-.41-.21h-1a.5.5,0,0,0-.38.17l-.69.79a2.93,2.93,0,0,1-4.48,0l-.69-.79A.5.5,0,0,0,8.69,14h-1a.51.51,0,0,0-.41.21l-.65.93A2,2,0,0,1,5,16Zm1,9V17.87a4,4,0,0,0,2.26-1.59,4.93,4.93,0,0,0,7.48,0A4,4,0,0,0,18,17.87V20Z',
  block:
    'M20.73,6.94l-.14-.25A2.13,2.13,0,0,0,19.86,6L15.14,3.27a2,2,0,0,0-1-.27h-.28a1.92,1.92,0,0,0-1,.27L4.14,8.32a2,2,0,0,0-.73.73l-.14.25a2,2,0,0,0-.27,1v5.76a2,2,0,0,0,.27,1l.14.25a2.13,2.13,0,0,0,.73.73l4.72,2.69a2,2,0,0,0,1,.27h.28a1.92,1.92,0,0,0,1-.27l8.72-5.05a2,2,0,0,0,.73-.73l.14-.25a2,2,0,0,0,.27-1V7.94A2,2,0,0,0,20.73,6.94ZM13.86,5h.28L18,7.2l-8,4.63L6,9.55Zm5,9L11,18.5V13.57l8-4.64V13.7Z',
  book:
    'M19,2H7A3,3,0,0,0,4,5V19a3,3,0,0,0,3,3H19.5a.5.5,0,0,0,.5-.5V3A1,1,0,0,0,19,2ZM7,6.5A.5.5,0,0,1,7.5,6h9a.5.5,0,0,1,.5.5v1a.5.5,0,0,1-.5.5h-9A.5.5,0,0,1,7,7.5Zm0,4a.5.5,0,0,1,.5-.5h5a.5.5,0,0,1,.5.5v1a.5.5,0,0,1-.5.5h-5a.5.5,0,0,1-.5-.5ZM6,19a1,1,0,0,1,1-1H18v2H7A1,1,0,0,1,6,19Z',
  book_bookmarked:
    'M19,2H7A3,3,0,0,0,4,5V19a3,3,0,0,0,3,3H19.5a.5.5,0,0,0,.5-.5V3A1,1,0,0,0,19,2ZM6,19a1,1,0,0,1,1-1H18v2H7A1,1,0,0,1,6,19Zm6-7.5a.5.5,0,0,1-.78.42l-1.94-1.3a.52.52,0,0,0-.56,0l-1.94,1.3A.5.5,0,0,1,6,11.5v-7A.5.5,0,0,1,6.5,4h5a.5.5,0,0,1,.5.5Z',
  book_opened:
    'M21,4H15a4,4,0,0,0-3,1.37A4,4,0,0,0,9,4H3A1,1,0,0,0,2,5V18a1,1,0,0,0,1,1H8.59a1,1,0,0,1,.7.29l.42.42a1,1,0,0,0,.7.29h3.18a1,1,0,0,0,.7-.29l.42-.42a1,1,0,0,1,.7-.29H21a1,1,0,0,0,1-1V5A1,1,0,0,0,21,4ZM11,16.54A4,4,0,0,0,9,16H4V6H9a2,2,0,0,1,2,2ZM20,16H15a4,4,0,0,0-2,.54V8a2,2,0,0,1,2-2h5Z',
  book_outlined:
    'M19,2H7A3,3,0,0,0,4,5V19a3,3,0,0,0,3,3H19.5a.5.5,0,0,0,.5-.5V3A1,1,0,0,0,19,2ZM6,5A1,1,0,0,1,7,4H18V16H7a3,3,0,0,0-1,.17ZM6,19a1,1,0,0,1,1-1H18v2H7A1,1,0,0,1,6,19ZM8.5,8h7a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5h-7a.5.5,0,0,0-.5.5v1A.5.5,0,0,0,8.5,8Zm0,4h3a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5h-3a.5.5,0,0,0-.5.5v1A.5.5,0,0,0,8.5,12Z',
  brain:
    'M11,19a2,2,0,0,1-3.87.7.47.47,0,0,0-.33-.3A2.5,2.5,0,0,1,5,17a2.36,2.36,0,0,1,.14-.8.49.49,0,0,0-.25-.6,3.49,3.49,0,0,1,0-6.2.49.49,0,0,0,.25-.6A2.36,2.36,0,0,1,5,8,2.49,2.49,0,0,1,6.67,5.65.52.52,0,0,0,7,5.13.53.53,0,0,1,7,5a2,2,0,0,1,4,0Zm2,0a2,2,0,0,0,3.87.7.47.47,0,0,1,.33-.3A2.5,2.5,0,0,0,19,17a2.36,2.36,0,0,0-.14-.8.49.49,0,0,1,.25-.6,3.49,3.49,0,0,0,0-6.2.49.49,0,0,1-.25-.6A2.36,2.36,0,0,0,19,8a2.49,2.49,0,0,0-1.67-2.35A.52.52,0,0,1,17,5.13.53.53,0,0,0,17,5a2,2,0,0,0-4,0Z',
  brick_wall:
    'M2,7.5v-3A.5.5,0,0,1,2.5,4h8a.5.5,0,0,1,.5.5v3a.5.5,0,0,1-.5.5h-8A.5.5,0,0,1,2,7.5ZM21.5,4h-8a.5.5,0,0,0-.5.5v3a.5.5,0,0,0,.5.5h8a.5.5,0,0,0,.5-.5v-3A.5.5,0,0,0,21.5,4ZM2.5,14h2a.5.5,0,0,0,.5-.5v-3a.5.5,0,0,0-.5-.5h-2a.5.5,0,0,0-.5.5v3A.5.5,0,0,0,2.5,14ZM7,10.5v3a.5.5,0,0,0,.5.5h9a.5.5,0,0,0,.5-.5v-3a.5.5,0,0,0-.5-.5h-9A.5.5,0,0,0,7,10.5ZM21.5,10h-2a.5.5,0,0,0-.5.5v3a.5.5,0,0,0,.5.5h2a.5.5,0,0,0,.5-.5v-3A.5.5,0,0,0,21.5,10Zm-11,6h-8a.5.5,0,0,0-.5.5v3a.5.5,0,0,0,.5.5h8a.5.5,0,0,0,.5-.5v-3A.5.5,0,0,0,10.5,16Zm11,0h-8a.5.5,0,0,0-.5.5v3a.5.5,0,0,0,.5.5h8a.5.5,0,0,0,.5-.5v-3A.5.5,0,0,0,21.5,16Z',
  briefcase:
    'M20,7H17V5a2,2,0,0,0-2-2H9A2,2,0,0,0,7,5V7H4A2,2,0,0,0,2,9V19a2,2,0,0,0,2,2H20a2,2,0,0,0,2-2V9A2,2,0,0,0,20,7Zm-6,7a1,1,0,0,1-1,1H11a1,1,0,0,1-1-1v-.5a.5.5,0,0,1,.5-.5h3a.5.5,0,0,1,.5.5Zm1-7H9V5h6Z',
  briefcase_not_accessible:
    'M3.38,2.15a.48.48,0,0,0-.7,0l-.53.53a.48.48,0,0,0,0,.7L4.76,6H4A2,2,0,0,0,2,8V18a2,2,0,0,0,2,2H18.76l1.86,1.85a.48.48,0,0,0,.7,0l.53-.53a.48.48,0,0,0,0-.7ZM11,14a1,1,0,0,1-1-1v-.5a.5.5,0,0,1,.5-.5h.26l2,2Zm9-8H17V4a2,2,0,0,0-2-2H9A2,2,0,0,0,7.1,3.39L9,5.29V4h6V6H9.71L22,18.29V8A2,2,0,0,0,20,6Z',
  briefcase_outlined:
    'M10,14v-.5a.5.5,0,0,1,.5-.5h3a.5.5,0,0,1,.5.5V14a1,1,0,0,1-1,1H11A1,1,0,0,1,10,14ZM22,9V19a2,2,0,0,1-2,2H4a2,2,0,0,1-2-2V9A2,2,0,0,1,4,7H7V5A2,2,0,0,1,9,3h6a2,2,0,0,1,2,2V7h3A2,2,0,0,1,22,9ZM9,7h6V5H9ZM20,9H4V19H20Z',
  chair:
    'M18,14a1,1,0,0,1,1,1v6.5a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5V18H7v3.5a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5V15a1,1,0,0,1,1-1Zm1.78-9.63a14,14,0,0,0-15.56,0A.49.49,0,0,0,4,4.78v.14a.69.69,0,0,0,.1.37L5,6.78A.56.56,0,0,0,5.31,7,.54.54,0,0,0,5.74,7c.34-.23.69-.44,1-.64L7.5,12h2L8.69,5.51A10.4,10.4,0,0,1,11,5.05v7h2V5.05a10.6,10.6,0,0,1,2.32.46L14.5,12h2l.71-5.68a11.06,11.06,0,0,1,1,.63.54.54,0,0,0,.43.09.6.6,0,0,0,.36-.26l.85-1.49a.69.69,0,0,0,.1-.37V4.78A.49.49,0,0,0,19.78,4.37Z',
  circle: 'M22,12A10,10,0,1,1,12,2,10,10,0,0,1,22,12Z',
  circle_outlined:
    'M12,4a8,8,0,1,1-8,8,8,8,0,0,1,8-8m0-2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Z',
  cricket:
    'M18.5,22A3.5,3.5,0,1,0,15,18.5,3.49,3.49,0,0,0,18.5,22Zm0-5.25a1.75,1.75,0,1,1-1.75,1.75A1.75,1.75,0,0,1,18.5,16.75Zm-12,4a1,1,0,0,1-1.42,0L2.29,17.88a1,1,0,0,1,0-1.42l9.9-9.89a1,1,0,0,1,1.19-.17l.84.46a.52.52,0,0,0,.6-.09l2.85-2.86-.35-.35a.5.5,0,0,1,0-.71l.71-.7a.48.48,0,0,1,.7,0l2.12,2.12a.48.48,0,0,1,0,.7l-.7.71a.5.5,0,0,1-.71,0l-.35-.35L16.23,8.18a.52.52,0,0,0-.09.6l.46.84a1,1,0,0,1-.17,1.19Z',
  crossfit:
    'M19,7c0-3.87-3.13-5-7-5S5,3.13,5,7a7,7,0,0,0,1.64,4.5A7,7,0,0,0,7.81,21.6,2,2,0,0,0,9,22h6a2,2,0,0,0,1.19-.4,7,7,0,0,0,1.17-10.1A7,7,0,0,0,19,7ZM12,4c5,0,5,2,5,3a5,5,0,0,1-1.13,3.16,7,7,0,0,0-7.74,0A5,5,0,0,1,7,7C7,6,7,4,12,4Zm0,15a3,3,0,1,1,3-3A3,3,0,0,1,12,19Z',
  cube:
    'M20.73,7.12l-.14-.25a2.07,2.07,0,0,0-.74-.73L13.14,2.27a2,2,0,0,0-1-.27h-.29a2,2,0,0,0-1,.27L4.14,6.15a2,2,0,0,0-.73.73l-.14.25a2,2,0,0,0-.27,1v7.75a2,2,0,0,0,.27,1l.14.25a2.13,2.13,0,0,0,.73.73l6.72,3.87a1.92,1.92,0,0,0,1,.27h.28a2,2,0,0,0,1-.27l6.71-3.88a1.9,1.9,0,0,0,.73-.73l.15-.25a2.05,2.05,0,0,0,.27-1V8.12A2,2,0,0,0,20.73,7.12ZM11.85,4h.29L18,7.38l-6,3.46L6,7.38Zm7,12.12L13,19.5V12.58l6-3.47v6.76Z',
  dining_table:
    'M15,18.5v1a.5.5,0,0,1-.5.5h-5a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5H11V15.26l.08-.09a1.23,1.23,0,0,1,.92-.42,1.26,1.26,0,0,1,.84.33l.16.13V18h1.5A.5.5,0,0,1,15,18.5Zm7-5.05L21.14,4.9a1,1,0,0,0-1-.9H3.85a1,1,0,0,0-1,.9L2,13.45a.5.5,0,0,0,.5.55H3.82a.77.77,0,0,0,.55-.24,2.7,2.7,0,0,1,3.79,0,.78.78,0,0,0,.52.21h.79a.74.74,0,0,0,.51-.2,3,3,0,0,1,4,0,.74.74,0,0,0,.51.2h.79a.78.78,0,0,0,.52-.21,2.7,2.7,0,0,1,3.79,0,.77.77,0,0,0,.55.24H21.5A.5.5,0,0,0,22,13.45Z',
  dining_table_outlined:
    'M15,18.5v1a.5.5,0,0,1-.5.5h-5a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5H11V15.26l.08-.09a1.23,1.23,0,0,1,.92-.42,1.26,1.26,0,0,1,.84.33l.16.13V18h1.5A.5.5,0,0,1,15,18.5ZM19.24,6l.55,5.47a4.68,4.68,0,0,0-4.87.48,5,5,0,0,0-5.84,0,4.68,4.68,0,0,0-4.87-.48L4.76,6H19.24m.91-2H3.85a1,1,0,0,0-1,.9L2,13.45a.5.5,0,0,0,.5.55H3.82a.77.77,0,0,0,.55-.24,2.7,2.7,0,0,1,3.79,0,.78.78,0,0,0,.52.21h.79a.74.74,0,0,0,.51-.2,3,3,0,0,1,4,0,.74.74,0,0,0,.51.2h.79a.78.78,0,0,0,.52-.21,2.7,2.7,0,0,1,3.79,0,.77.77,0,0,0,.55.24H21.5a.5.5,0,0,0,.5-.55L21.14,4.9a1,1,0,0,0-1-.9Z',
  diving:
    'M19.5,2h-1a.5.5,0,0,0-.5.5V6.55A4,4,0,0,0,16,6H8a4,4,0,0,0-4,4v1a4,4,0,0,0,4,4h8a4,4,0,0,0,2-.55V18a2,2,0,0,1-2,2H14a1,1,0,0,1-1-1h.5a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5h-3a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5H11a3,3,0,0,0,3,3h2a4,4,0,0,0,4-4V2.5A.5.5,0,0,0,19.5,2ZM16,13H15a2,2,0,0,1-1.94-1.52l0-.1a.49.49,0,0,0-.48-.38H11.44a.49.49,0,0,0-.48.38l0,.1A2,2,0,0,1,9,13H8a2,2,0,0,1-2-2V10A2,2,0,0,1,8,8h8a2,2,0,0,1,2,2v1A2,2,0,0,1,16,13Z',
  door:
    'M20.5,19H18V4a1,1,0,0,0-1-1H7A1,1,0,0,0,6,4V19H3.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h17a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,20.5,19ZM14,14.5v-1a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5v1a.5.5,0,0,1-.5.5h-1A.5.5,0,0,1,14,14.5ZM8,5h8V9H8Z',
  flag:
    'M20,6.5v9a.5.5,0,0,1-.5.5H14a2,2,0,0,1-2-2H6v7.5a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5V2.5A.5.5,0,0,1,4.5,2h1a.5.5,0,0,1,.5.5V4h6a2,2,0,0,1,2,2h5.5A.5.5,0,0,1,20,6.5Z',
  flag_cross_1:
    'M19.5,6H13a2,2,0,0,0-2-2H6V2.5A.5.5,0,0,0,5.5,2h-1a.5.5,0,0,0-.5.5v19a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5V14h5a2,2,0,0,0,2,2h6.5a.5.5,0,0,0,.5-.5v-9A.5.5,0,0,0,19.5,6ZM18,8v2H13V8ZM11,6V8H6V6ZM6,12V10h5v2Zm7,2V12h5v2Z',
  flag_cross_2:
    'M19.5,6H13a2,2,0,0,0-2-2H6V2.5A.5.5,0,0,0,5.5,2h-1a.5.5,0,0,0-.5.5v19a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5V14h5a2,2,0,0,0,2,2h6.5a.5.5,0,0,0,.5-.5v-9A.5.5,0,0,0,19.5,6ZM11,10H6V8h5Zm7,2H13V10h5Z',
  flag_half_1:
    'M19.5,6H13a2,2,0,0,0-2-2H6V2.5A.5.5,0,0,0,5.5,2h-1a.5.5,0,0,0-.5.5v19a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5V14h5a2,2,0,0,0,2,2h6.5a.5.5,0,0,0,.5-.5v-9A.5.5,0,0,0,19.5,6ZM13,8h5v6H13Z',
  flag_half_2:
    'M19.5,6H13a2,2,0,0,0-2-2H6V2.5A.5.5,0,0,0,5.5,2h-1a.5.5,0,0,0-.5.5v19a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5V14h5a2,2,0,0,0,2,2h6.5a.5.5,0,0,0,.5-.5v-9A.5.5,0,0,0,19.5,6ZM11,12H6V6h5Z',
  flag_outlined:
    'M13,6a2,2,0,0,0-2-2H6V2.5A.5.5,0,0,0,5.5,2h-1a.5.5,0,0,0-.5.5v19a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5V14h5a2,2,0,0,0,2,2h6.5a.5.5,0,0,0,.5-.5v-9a.5.5,0,0,0-.5-.5ZM6,12V6h5V7.5a.5.5,0,0,0,.5.5H18v6H13V12.5a.5.5,0,0,0-.5-.5Z',
  flag_quarter_1:
    'M19.5,6H13a2,2,0,0,0-2-2H6V2.5A.5.5,0,0,0,5.5,2h-1a.5.5,0,0,0-.5.5v19a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5V14h5a2,2,0,0,0,2,2h6.5a.5.5,0,0,0,.5-.5v-9A.5.5,0,0,0,19.5,6ZM11,12H6V9h5Zm2-4h5v3H13Z',
  flag_quarter_2:
    'M19.5,6H13a2,2,0,0,0-2-2H6V2.5A.5.5,0,0,0,5.5,2h-1a.5.5,0,0,0-.5.5v19a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5V14h5a2,2,0,0,0,2,2h6.5a.5.5,0,0,0,.5-.5v-9A.5.5,0,0,0,19.5,6ZM11,9H6V6h5Zm2,2h5v3H13Z',
  flask:
    'M13,7.52a4,4,0,0,0,1.78,3.33A5,5,0,0,1,17,15H7a5,5,0,0,1,2.22-4.15A4,4,0,0,0,11,7.52V4h2ZM15.5,2h-7a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5H9V7.52a2,2,0,0,1-.89,1.67,7,7,0,1,0,7.78,0A2,2,0,0,1,15,7.52V4h.5a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5Z',
  flower:
    'M20.18,12.33a.49.49,0,0,1,0-.66,4,4,0,0,0-3.8-6.58.51.51,0,0,1-.58-.34,4,4,0,0,0-7.6,0,.51.51,0,0,1-.58.34,4,4,0,0,0-3.8,6.58.49.49,0,0,1,0,.66,4,4,0,0,0,3.8,6.58.51.51,0,0,1,.58.34,4,4,0,0,0,7.6,0,.51.51,0,0,1,.58-.34,4,4,0,0,0,3.8-6.58ZM12,16a4,4,0,1,1,4-4A4,4,0,0,1,12,16Z',
  foot_print:
    'M12,3a1,1,0,1,1,1,1A1,1,0,0,1,12,3Zm3,3a1,1,0,1,0-1-1A1,1,0,0,0,15,6Zm2,0a1,1,0,1,0,1,1A1,1,0,0,0,17,6ZM9,5a2,2,0,0,0,2-2V2A2,2,0,0,0,7,2V3A2,2,0,0,0,9,5Zm4.71,2.17A4,4,0,0,0,10.88,6H9.5a2.5,2.5,0,0,0,0,5H11a2,2,0,0,1,2,2c0,2.81-4,4.5-4,8a3,3,0,0,0,6,0c0-3.72,2-7.55,2-9.5a2.5,2.5,0,0,0-.73-1.77Z',
  gift:
    'M19,8h-.54A4,4,0,0,0,19,6a3,3,0,0,0-3-3,5.67,5.67,0,0,0-4,2.44A5.67,5.67,0,0,0,8,3,3,3,0,0,0,5,6a4,4,0,0,0,.54,2H5a2,2,0,0,0-2,2v3a1,1,0,0,0,1,1H5v5a2,2,0,0,0,2,2H17a2,2,0,0,0,2-2V14h1a1,1,0,0,0,1-1V10A2,2,0,0,0,19,8ZM16,5a1,1,0,0,1,1,1,2,2,0,0,1-.88,1.66A2,2,0,0,1,15,8H13C13,6.91,14.91,5,16,5ZM7,6A1,1,0,0,1,8,5c1.09,0,3,1.91,3,3H9A2,2,0,0,1,7,6ZM5,10h6v2H5Zm2,4h4v5H7Zm10,5H13V14h4Zm2-7H13V10h6Z',
  glasses:
    'M22.51,12.87l0,0-3.6-7.74A2,2,0,0,0,17.11,4H15.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h1.61L19,10.11a4.94,4.94,0,0,0-5.35,2.42A3,3,0,0,0,12,12a2.94,2.94,0,0,0-1.67.54A4.95,4.95,0,0,0,5,10.11L6.89,6H8.5A.5.5,0,0,0,9,5.5v-1A.5.5,0,0,0,8.5,4H6.89A2,2,0,0,0,5.07,5.15l-3.6,7.74,0,0A4.93,4.93,0,0,0,1,15a5,5,0,0,0,10,0,1,1,0,0,1,2,0,5,5,0,0,0,10,0A4.93,4.93,0,0,0,22.51,12.87ZM6,18a2.93,2.93,0,0,1-1.55-.45l4.1-4.1A2.93,2.93,0,0,1,9,15,3,3,0,0,1,6,18Zm12,0a2.93,2.93,0,0,1-1.55-.45l4.1-4.1A2.93,2.93,0,0,1,21,15,3,3,0,0,1,18,18Z',
  glasses_outlined:
    'M22.51,12.87l0,0-3.6-7.74A2,2,0,0,0,17.11,4H15.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h1.61L19,10.11a4.94,4.94,0,0,0-5.35,2.42A3,3,0,0,0,12,12a2.94,2.94,0,0,0-1.67.54A4.95,4.95,0,0,0,5,10.11L6.89,6H8.5A.5.5,0,0,0,9,5.5v-1A.5.5,0,0,0,8.5,4H6.89A2,2,0,0,0,5.07,5.15l-3.6,7.74,0,0A4.93,4.93,0,0,0,1,15a5,5,0,0,0,10,0,1,1,0,0,1,2,0,5,5,0,0,0,10,0A4.93,4.93,0,0,0,22.51,12.87ZM6,18a3,3,0,1,1,3-3A3,3,0,0,1,6,18Zm12,0a3,3,0,1,1,3-3A3,3,0,0,1,18,18Z',
  hourglass:
    'M18,7V4h.5a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5H5.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5H6V7A3,3,0,0,0,7.13,9.38L10.4,12,7.13,14.62A3,3,0,0,0,6,17v3H5.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h13a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5H18V17a3,3,0,0,0-1.13-2.34L13.6,12l3.27-2.62A3,3,0,0,0,18,7Z',
  hourglass_half:
    'M18,7V4h.5a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5H5.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5H6V7A3,3,0,0,0,7.13,9.38L10.4,12,7.13,14.62A3,3,0,0,0,6,17v3H5.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h13a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5H18V17a3,3,0,0,0-1.13-2.34L13.6,12l3.27-2.62A3,3,0,0,0,18,7ZM16,4V7H8V4Zm-.38,12.18A1,1,0,0,1,16,17v1H8V17a1,1,0,0,1,.38-.78L12,13.28Z',
  hourglass_outlined:
    'M18,7V4h.5a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5H5.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5H6V7A3,3,0,0,0,7.13,9.38L10.4,12,7.13,14.62A3,3,0,0,0,6,17v3H5.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h13a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5H18V17a3,3,0,0,0-1.13-2.34L13.6,12l3.27-2.62A3,3,0,0,0,18,7Zm-2.38,9.14A1,1,0,0,1,16,17v3H8V17a1,1,0,0,1,.38-.78L12,13.28Zm0-8.36L12,10.72,8.38,7.82A1,1,0,0,1,8,7V4h8V7A1,1,0,0,1,15.62,7.82Z',
  hourglass_quarter:
    'M18,7V4h.5a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5H5.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5H6V7A3,3,0,0,0,7.13,9.38L10.4,12,7.13,14.62A3,3,0,0,0,6,17v3H5.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h13a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5H18V17a3,3,0,0,0-1.13-2.34L13.6,12l3.27-2.62A3,3,0,0,0,18,7Zm-2.38,9.14A1,1,0,0,1,16,17v0H8v0a1,1,0,0,1,.38-.78L12,13.28ZM16,7a1,1,0,0,1-.38.78L14.15,9H9.85L8.38,7.82A1,1,0,0,1,8,7V4h8Z',
  ice_hockey:
    'M4,13a2,2,0,0,0-2,2v3a2,2,0,0,0,2,2h9.26A4,4,0,0,0,17,17.45L22,4.68A.5.5,0,0,0,21.5,4H20.43a.5.5,0,0,0-.46.32l-3.13,8a1,1,0,0,1-.93.64Zm9.26,5H12V15h3.8l-.67,1.73A2,2,0,0,1,13.26,18ZM4,15H6v3H4Zm7-4H5a1,1,0,0,1-1-1V8A1,1,0,0,1,5,7h6a1,1,0,0,1,1,1v2A1,1,0,0,1,11,11Z',
  infinity:
    'M18,7a6.86,6.86,0,0,0-4.79,2.24c.29.32.58.63.86.95s.32.35.48.53A5.1,5.1,0,0,1,18,9a3,3,0,0,1,0,6c-1.8,0-3.23-1.38-4.67-3h0l-.58-.66L12,10.49h0C10.35,8.66,8.56,7,6,7A5,5,0,0,0,6,17c2.56,0,4.35-1.66,6-3.49C13.65,15.34,15.44,17,18,17A5,5,0,0,0,18,7ZM6,15A3,3,0,0,1,6,9c1.8,0,3.23,1.38,4.67,3C9.23,13.62,7.8,15,6,15Z',
  justice:
    'M22,6.5v-1a.5.5,0,0,0-.5-.5H18.35A5.6,5.6,0,0,0,15,4a6,6,0,0,0-2.08.35l-.12-.91A.5.5,0,0,0,12.31,3h-.62a.5.5,0,0,0-.49.44l-.12.91A6,6,0,0,0,9,4,5.6,5.6,0,0,0,5.65,5H2.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h1L2.07,12.71a2,2,0,0,0,0,.74,3.5,3.5,0,0,0,6.94,0,2,2,0,0,0,0-.74L7.33,6.33A4.09,4.09,0,0,1,9,6a3.85,3.85,0,0,1,2,.5V19H9.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h5a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5H13V6.5A3.85,3.85,0,0,1,15,6a4.09,4.09,0,0,1,1.67.33l-1.6,6.38a2,2,0,0,0,0,.74,3.5,3.5,0,0,0,6.94,0,2,2,0,0,0,0-.74L20.5,7h1A.5.5,0,0,0,22,6.5ZM3.8,13,5.26,7.19A.24.24,0,0,1,5.5,7h0a.24.24,0,0,1,.24.19L7.2,13Zm13,0,1.46-5.81A.24.24,0,0,1,18.5,7h0a.24.24,0,0,1,.24.19L20.2,13Z',
  lab:
    'M19.76,19.11,15.32,12.2A2,2,0,0,1,15,11.12V4h.5a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5h-7a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5H9v7.13a2,2,0,0,1-.32,1.08L4.24,19.12a1.51,1.51,0,0,0,0,1.56l.32.57a1.52,1.52,0,0,0,1.3.75H18.17a1.52,1.52,0,0,0,1.3-.75l.33-.58A1.49,1.49,0,0,0,19.76,19.11Zm-9.39-5.82A4.06,4.06,0,0,0,11,11.13V4h2v7.12a3.93,3.93,0,0,0,.64,2.16L15.38,16H8.62Z',
  leaf:
    'M19,12.33c0-4.57-4.15-8.52-6.06-10.1A1,1,0,0,0,12.3,2h-.6a1,1,0,0,0-.64.23C9.15,3.81,5,7.76,5,12.33a7,7,0,0,0,6,6.92V21.5a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5V19.25A7,7,0,0,0,19,12.33Zm-6,4.9V10.5a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5v6.73a5,5,0,0,1-4-4.9C7,9.06,10,5.78,12,4c2,1.74,5,5,5,8.29A5,5,0,0,1,13,17.23Z',
  leaf_diagonal:
    'M20.89,4.44a1,1,0,0,0-.29-.61l-.43-.44a1,1,0,0,0-.61-.28A22.38,22.38,0,0,0,17.3,3c-2.68,0-6.72.46-9.24,3a7,7,0,0,0-.65,9.2L3.15,19.44a.5.5,0,0,0,0,.71l.7.7a.5.5,0,0,0,.71,0l4.27-4.26A7,7,0,0,0,18,15.94C21.27,12.69,21.13,6.92,20.89,4.44ZM16.6,14.52a5,5,0,0,1-6.35.64l3.6-3.6a.5.5,0,0,0,0-.71l-.7-.7a.5.5,0,0,0-.71,0l-3.6,3.6A5,5,0,0,1,9.48,7.4C11.56,5.31,15.27,5,17.3,5c.55,0,1.1,0,1.64.06C19.08,7.07,19.14,12,16.6,14.52Z',
  lightbulb:
    'M19,9a7,7,0,0,0-7-7h-.12A7.1,7.1,0,0,0,5,8.74a7,7,0,0,0,3.08,6A2,2,0,0,1,9,16.48V17a1,1,0,0,0,1,1h4a1,1,0,0,0,1-1v-.52a2,2,0,0,1,.91-1.68A7,7,0,0,0,19,9Zm-4.21,4.14A4,4,0,0,0,13.29,15H10.71A4.08,4.08,0,0,0,9.2,13.14,5,5,0,0,1,7,8.81,5.12,5.12,0,0,1,11.91,4H12a5,5,0,0,1,2.79,9.14ZM10,20.59a1,1,0,0,0,.29.7l.42.42a1,1,0,0,0,.7.29h1.18a1,1,0,0,0,.7-.29l.42-.42a1,1,0,0,0,.29-.7V20H10Z',
  light_on:
    'M12,6A6,6,0,0,0,8.8,17.07a1.5,1.5,0,0,1,.7,1.27v.16a.5.5,0,0,0,.5.5h4a.5.5,0,0,0,.5-.5v-.16a1.48,1.48,0,0,1,.7-1.27A6,6,0,0,0,12,6Zm0,10a4,4,0,1,1,4-4A4,4,0,0,1,12,16Zm-2,5.59a1,1,0,0,0,.29.7l.42.42a1,1,0,0,0,.7.29h1.18a1,1,0,0,0,.7-.29l.42-.42a1,1,0,0,0,.29-.7V21H10ZM11.5,4h1a.5.5,0,0,0,.5-.5v-2a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5v2A.5.5,0,0,0,11.5,4Zm8.63.58-.71-.71a.48.48,0,0,0-.7,0L17.3,5.28a.51.51,0,0,0,0,.71L18,6.7a.51.51,0,0,0,.71,0l1.41-1.42A.48.48,0,0,0,20.13,4.58ZM6.7,5.28,5.28,3.87a.48.48,0,0,0-.7,0l-.71.71a.48.48,0,0,0,0,.7L5.28,6.7A.51.51,0,0,0,6,6.7L6.7,6A.51.51,0,0,0,6.7,5.28ZM22.5,11h-2a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h2a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,22.5,11Zm-19,0h-2a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h2a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,3.5,11Z',
  medicaments:
    'M18.11,9.41,16.45,8.3a1.75,1.75,0,0,0-1-.3H8.54a1.75,1.75,0,0,0-1,.3L5.89,9.41A2,2,0,0,0,5,11.07V20a2,2,0,0,0,2,2H17a2,2,0,0,0,2-2V11.07A2,2,0,0,0,18.11,9.41ZM17,17.5a.5.5,0,0,1-.5.5h-9a.5.5,0,0,1-.5-.5v-5a.5.5,0,0,1,.5-.5h9a.5.5,0,0,1,.5.5ZM7,5.5v-3A.5.5,0,0,1,7.5,2h9a.5.5,0,0,1,.5.5v3a.5.5,0,0,1-.5.5h-9A.5.5,0,0,1,7,5.5Z',
  mma:
    'M19.5,6A1.5,1.5,0,0,0,18,7.5a.5.5,0,0,1-1,0v-3a1.5,1.5,0,0,0-3,0v3a.5.5,0,0,1-1,0v-4a1.5,1.5,0,0,0-3,0v4a.5.5,0,0,1-1,0v-3a1.5,1.5,0,0,0-3,0v9l-.31-.31A2,2,0,0,1,5,11.65V10a2,2,0,0,0-2,2v1.7a5,5,0,0,0,1.75,3.8l3.62,3.62a3,3,0,0,0,2.12.88h4.14a2,2,0,0,0,1.73-1H17a3,3,0,0,0,2.61-1.51l.63-1.12a5.91,5.91,0,0,0,.79-3V7.5A1.5,1.5,0,0,0,19.5,6ZM19,15.41a4,4,0,0,1-.52,2l-.64,1.12A1,1,0,0,1,17,19H9.84A1.84,1.84,0,0,1,8,17.16V11a1,1,0,0,1,1-1h9a1,1,0,0,1,1,1ZM16.5,12a.5.5,0,0,1,.5.5v1a.5.5,0,0,1-.5.5h-6a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5Z',
  moon:
    'M4,12A10,10,0,0,1,18.74,3.19a.51.51,0,0,1,.26.44v.13a.51.51,0,0,1-.19.4,10,10,0,0,0,0,15.68.51.51,0,0,1,.19.4v.13a.51.51,0,0,1-.26.44A10,10,0,0,1,4,12Z',
  moon_outlined:
    'M14,4a7.7,7.7,0,0,1,1.86.22,12,12,0,0,0,0,15.56A7.7,7.7,0,0,1,14,20,8,8,0,0,1,14,4m0-2a10,10,0,1,0,4.74,18.81.51.51,0,0,0,.26-.44v-.13a.51.51,0,0,0-.19-.4,10,10,0,0,1,0-15.68.51.51,0,0,0,.19-.4V3.63a.51.51,0,0,0-.26-.44A10,10,0,0,0,14,2Z',
  moon_vs:
    'M22,14.73A10.21,10.21,0,1,1,9.27,2h0a.54.54,0,0,1,.51.13l.09.09a.55.55,0,0,1,.15.42A10.18,10.18,0,0,0,21.34,14a.55.55,0,0,1,.42.15l.09.09a.54.54,0,0,1,.13.51Z',
  moon_vs_outlined:
    'M8,4.77a12.13,12.13,0,0,0,3.54,7.72A12.1,12.1,0,0,0,19.23,16,8.2,8.2,0,1,1,8,4.77M9.41,2,9.27,2h0A10.21,10.21,0,1,0,22,14.73h0a.54.54,0,0,0-.13-.51l-.09-.09A.51.51,0,0,0,21.4,14h-.06a10,10,0,0,1-1.22.08A10.19,10.19,0,0,1,10,2.66a.55.55,0,0,0-.15-.42l-.09-.09A.51.51,0,0,0,9.41,2Z',
  movie:
    'M20.5,3h-1a.5.5,0,0,0-.5.5v1a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,0-.5-.5h-9a.5.5,0,0,0-.5.5v1a.5.5,0,0,1-.5.5h-1A.5.5,0,0,1,5,4.5v-1A.5.5,0,0,0,4.5,3h-1a.5.5,0,0,0-.5.5v17a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5v1a.5.5,0,0,0,.5.5h9a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5v1a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5V3.5A.5.5,0,0,0,20.5,3ZM7,16.5a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5v-3a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5Zm0-6a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5v-3A.5.5,0,0,1,5.5,7h1a.5.5,0,0,1,.5.5Zm12,6a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5v-3a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5Zm0-6a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5v-3a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5Z',
  node:
    'M19,14a3,3,0,0,0-2.82,2H15a2,2,0,0,1-2-2V10A4,4,0,0,0,9,6H7.82a3,3,0,1,0,0,2H9a2,2,0,0,1,2,2v4a4,4,0,0,0,4,4h1.18A3,3,0,1,0,19,14Z',
  node_multiple:
    'M22,17a3,3,0,0,0-5.82-1H15a2,2,0,0,1-2-2,3,3,0,0,0-.78-2h0A3,3,0,0,0,10,11H7.82a3,3,0,1,0,0,2H10a1,1,0,0,1,1,1,4,4,0,0,0,4,4h1.18A3,3,0,0,0,22,17Zm-9-6.68c0-.11,0-.21,0-.32a2,2,0,0,1,2-2h1.18a3,3,0,1,0,0-2H15a4,4,0,0,0-3.94,3.37A4.77,4.77,0,0,1,13,10.32Z',
  node_multiple_outlined:
    'M22,17a3,3,0,0,0-5.82-1H15a2,2,0,0,1-2-2,3,3,0,0,0-.78-2h0A3,3,0,0,0,10,11H7.82a3,3,0,1,0,0,2H10a1,1,0,0,1,1,1,4,4,0,0,0,4,4h1.18A3,3,0,0,0,22,17ZM5,13a1,1,0,1,1,1-1A1,1,0,0,1,5,13Zm14,5a1,1,0,1,1,1-1A1,1,0,0,1,19,18Zm-6-7.68c0-.11,0-.21,0-.32a2,2,0,0,1,2-2h1.18a3,3,0,1,0,0-2H15a4,4,0,0,0-3.94,3.37A4.77,4.77,0,0,1,13,10.32ZM19,6a1,1,0,1,1-1,1A1,1,0,0,1,19,6Z',
  node_outlined:
    'M19,14a3,3,0,0,0-2.82,2H15a2,2,0,0,1-2-2V10A4,4,0,0,0,9,6H7.82a3,3,0,1,0,0,2H9a2,2,0,0,1,2,2v4a4,4,0,0,0,4,4h1.18A3,3,0,1,0,19,14ZM5,8A1,1,0,1,1,6,7,1,1,0,0,1,5,8ZM19,18a1,1,0,1,1,1-1A1,1,0,0,1,19,18Z',
  pet:
    'M7,7V5a2,2,0,0,1,4,0V7A2,2,0,0,1,7,7Zm8,2a2,2,0,0,0,2-2V5a2,2,0,0,0-4,0V7A2,2,0,0,0,15,9ZM7,12V11a2,2,0,0,0-4,0v1a2,2,0,0,0,4,0ZM19,9a2,2,0,0,0-2,2v1a2,2,0,0,0,4,0V11A2,2,0,0,0,19,9Zm-2.2,6.8A7,7,0,0,1,15.49,14l-.94-1.87A2,2,0,0,0,12.76,11H11.24a2,2,0,0,0-1.79,1.11L8.51,14A7,7,0,0,1,7.2,15.8L5.59,17.41A2,2,0,0,0,5,18.83V19a2,2,0,0,0,2,2H17a2,2,0,0,0,2-2v-.17a2,2,0,0,0-.59-1.42Z',
  pill:
    'M4.46,19.54a5,5,0,0,0,7.08,0l8-8a5,5,0,0,0-7.08-7.08l-8,8A5,5,0,0,0,4.46,19.54Zm1.42-5.66,4-4,4.24,4.24-4,4a3,3,0,1,1-4.24-4.24Z',
  puzzle:
    'M21,15H20a1,1,0,0,0-1,1v3a2,2,0,0,1-2,2H14a1,1,0,0,1-1-1V19a2,2,0,0,0-4,0v1a1,1,0,0,1-1,1H5a2,2,0,0,1-2-2V16a1,1,0,0,1,1-1H5a2,2,0,0,0,0-4H4a1,1,0,0,1-1-1V7A2,2,0,0,1,5,5H8A1,1,0,0,0,9,4V3a2,2,0,0,1,4,0V4a1,1,0,0,0,1,1h3a2,2,0,0,1,2,2v3a1,1,0,0,0,1,1h1a2,2,0,0,1,0,4Z',
  quotes:
    'M11,7v7a5,5,0,0,1-4.44,5,.49.49,0,0,1-.39-.12A.52.52,0,0,1,6,18.47v-2A.5.5,0,0,1,6.39,16,2,2,0,0,0,8,14V13H5a2,2,0,0,1-2-2V7A2,2,0,0,1,5,5H9A2,2,0,0,1,11,7Zm8-2H15a2,2,0,0,0-2,2v4a2,2,0,0,0,2,2h3v1a2,2,0,0,1-1.61,2,.5.5,0,0,0-.39.49v2a.52.52,0,0,0,.17.38.49.49,0,0,0,.39.12A5,5,0,0,0,21,14V7A2,2,0,0,0,19,5Z',
  recycling:
    'M12,6.08l-1.15,2a.51.51,0,0,1-.69.18l-1.73-1a.5.5,0,0,1-.18-.68L9.75,4a2,2,0,0,1,1.73-1h1a2,2,0,0,1,1.73,1L16,6.89l1.2-.71a.51.51,0,0,1,.38,0l.24.06A.28.28,0,0,1,18,6.5l-1.1,4.22a.51.51,0,0,1-.62.36L12,10a.28.28,0,0,1-.18-.32l.07-.23a.47.47,0,0,1,.22-.31l1.21-.71Zm8.72,9L19.24,12.5a.5.5,0,0,0-.68-.18l-1.73,1a.49.49,0,0,0-.18.68l1.16,2H15V14.6a.48.48,0,0,0-.16-.35l-.17-.18a.27.27,0,0,0-.36,0l-3.08,3.08a.48.48,0,0,0,0,.7l3.08,3.08a.27.27,0,0,0,.36,0l.17-.18A.48.48,0,0,0,15,20.4V19h3.48a2,2,0,0,0,1.72-1l.53-.94A2,2,0,0,0,20.72,15ZM8.47,9.85a.5.5,0,0,0-.61-.36L3.64,10.6a.26.26,0,0,0-.18.31l.07.23a.46.46,0,0,0,.23.31L5,12.16l-1.7,2.9a2,2,0,0,0,0,2l.52.91a2,2,0,0,0,1.75,1h3a.5.5,0,0,0,.5-.5v-2a.5.5,0,0,0-.5-.5H6.2l1.36-2.32,1.21.7a.46.46,0,0,0,.38,0l.24-.06a.27.27,0,0,0,.18-.31Z',
  rugby:
    'M21,5.86a2,2,0,0,0-.59-1.42l-.85-.85A2,2,0,0,0,18.14,3C2.85,3,3,14.22,3,18.1a2,2,0,0,0,.59,1.42l.89.89A2,2,0,0,0,5.9,21C21.17,21,21,9.73,21,5.86ZM17.39,14c-1.89,3.26-5.64,4.93-11.14,5L9.5,15.74l.53.53a.51.51,0,0,0,.7,0l.53-.54a.48.48,0,0,0,0-.7L9,12.74a.47.47,0,0,0-.35-.15.51.51,0,0,0-.36.15l-.53.53a.5.5,0,0,0,0,.7l.53.53L5,17.76c0-1.85.06-5.07,1.62-7.75,1.89-3.25,5.64-4.92,11.13-5L14.5,8.26,14,7.74a.47.47,0,0,0-.35-.15.51.51,0,0,0-.36.15l-.53.53a.5.5,0,0,0,0,.7l2.3,2.3a.51.51,0,0,0,.7,0l.53-.54a.48.48,0,0,0,0-.7l-.52-.53L19,6.24C19,8.1,18.94,11.31,17.39,14Zm-3.63-1.45a.48.48,0,0,1,0,.7l-.53.54a.51.51,0,0,1-.7,0l-2.3-2.3a.5.5,0,0,1,0-.7l.53-.53a.51.51,0,0,1,.36-.15.47.47,0,0,1,.35.15Z',
  seat:
    'M20,9.19l-2.3,12a1,1,0,0,1-1,.81H7A2,2,0,0,1,5,20.53L4,16.89a1,1,0,0,1,.25-1l.47-.46a1,1,0,0,1,.85-.29l8,1.14-.12-.75a4,4,0,0,1,.62-2.88l2.82-4.23A1,1,0,0,1,17.74,8H19A1,1,0,0,1,20,9.19ZM19,2H17a1,1,0,0,0-1,1.24l.5,2a1,1,0,0,0,1,.76H19a1,1,0,0,0,1-1V3A1,1,0,0,0,19,2Z',
  seat_outlined:
    'M19,8H16.74a1,1,0,0,0-.83.45l-2.82,4.23a4,4,0,0,0-.65,2.46l-6.83-1H5.48a1,1,0,0,0-.72.3l-.47.46a1,1,0,0,0-.25,1L5.3,20.53A2,2,0,0,0,7.23,22h9.46a1,1,0,0,0,1-.81l2.3-12A1,1,0,0,0,19,8ZM15.87,20H7.23l-1-3.72,6.42.91a1,1,0,0,0,.83-.27l.69-.66a1,1,0,0,0,.31-.78l0-.46a2,2,0,0,1,.31-1.23L17.28,10h.51ZM19,2H17a1,1,0,0,0-1,1.24l.5,2a1,1,0,0,0,1,.76H19a1,1,0,0,0,1-1V3A1,1,0,0,0,19,2Z',
  shoe_print:
    'M17,8a18.17,18.17,0,0,1-.75,5.3,1,1,0,0,1-.95.7H9.05a1,1,0,0,1-.88-.54A12.35,12.35,0,0,1,7,8c0-3,1-8,5-8S17,5,17,8Zm-2.43,9.49-4.92-.87a1,1,0,0,0-1.16.81l-.44,2.46h0A4.07,4.07,0,0,0,8,20.5a3.5,3.5,0,0,0,6.94.61h0l.43-2.46A1,1,0,0,0,14.57,17.49Z',
  shoe_print_outlined:
    'M12,2c.65,0,1.49.2,2.19,1.72A10.85,10.85,0,0,1,15,8a17.42,17.42,0,0,1-.46,4H9.69A11.12,11.12,0,0,1,9,8a10.85,10.85,0,0,1,.81-4.28C10.51,2.2,11.35,2,12,2m0-2C8,0,7,5,7,8a12.35,12.35,0,0,0,1.17,5.46,1,1,0,0,0,.88.54H15.3a1,1,0,0,0,.95-.7A18.17,18.17,0,0,0,17,8c0-3-1-8-5-8ZM10.28,18.76l3,.52L13,20.76a1.5,1.5,0,0,1-3-.26,1.56,1.56,0,0,1,0-.26l.25-1.48M9.47,16.6a1,1,0,0,0-1,.83l-.44,2.46h0A4.07,4.07,0,0,0,8,20.5a3.5,3.5,0,0,0,6.94.61h0l.43-2.46a1,1,0,0,0-.81-1.16l-4.92-.87-.18,0Z',
  shower:
    'M9,19a2,2,0,0,1-4,0,6.09,6.09,0,0,1,2-3H7A6.22,6.22,0,0,1,9,19ZM15,3A6,6,0,0,0,9.09,8H7.83a2,2,0,0,0-1.42.59L3.59,11.41A2,2,0,0,0,3,12.83v.67a.5.5,0,0,0,.5.5h13a.5.5,0,0,0,.5-.5v-.67a2,2,0,0,0-.59-1.42L13.59,8.59A2,2,0,0,0,12.17,8h-1A4,4,0,0,1,15,5a4.11,4.11,0,0,1,4,4.18V20.5a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5V9.21C21,8.1,20.12,3,15,3ZM13,16h0a6.09,6.09,0,0,0-2,3,2,2,0,0,0,4,0A6.27,6.27,0,0,0,13,16Z',
  sign_man:
    'M20.85,3.6l-.45-.45A.49.49,0,0,0,20,3H15.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h2.08l-3.4,3.39A7.05,7.05,0,1,0,15.59,9.8L19,6.41V8.5a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5V4A.49.49,0,0,0,20.85,3.6ZM10,19a5,5,0,1,1,5-5A5,5,0,0,1,10,19Z',
  sign_woman:
    'M19,9a7,7,0,1,0-8,6.92V18H9.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5H11v1.5a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5V20h1.5a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5H13V15.92A7,7,0,0,0,19,9ZM7,9a5,5,0,1,1,5,5A5,5,0,0,1,7,9Z',
  skull:
    'M12,2a8,8,0,0,0-8,8v5.17a2,2,0,0,0,.59,1.42l1.12,1.12a1,1,0,0,1,.29.7v1.68a1,1,0,0,0,.29.7l1.06,1.06a.49.49,0,0,0,.36.15h.58a.49.49,0,0,0,.36-.15l1-1a.5.5,0,0,1,.7,0l1,1a.49.49,0,0,0,.36.15h.58a.49.49,0,0,0,.36-.15l1-1a.5.5,0,0,1,.7,0l1,1a.49.49,0,0,0,.36.15h.58a.49.49,0,0,0,.36-.15l1.06-1.06a1,1,0,0,0,.29-.7V18.41a1,1,0,0,1,.29-.7l1.12-1.12A2,2,0,0,0,20,15.17V10A8,8,0,0,0,12,2ZM9.35,15.63a1,1,0,0,1-.78.37H7a1,1,0,0,1-1-1V13a1,1,0,0,1,1-1h3a1,1,0,0,1,1,1v.24a1,1,0,0,1-.22.63Zm4.23,2.17a.35.35,0,0,1-.31.2H10.73a.35.35,0,0,1-.27-.57l1-1.24a.49.49,0,0,1,.39-.19h.28a.49.49,0,0,1,.39.19l1,1.24A.35.35,0,0,1,13.58,17.8ZM18,15a1,1,0,0,1-1,1H15.43a1,1,0,0,1-.78-.37l-1.43-1.76a1,1,0,0,1-.22-.63V13a1,1,0,0,1,1-1h3a1,1,0,0,1,1,1Z',
  smoke_free:
    'M22,12.5v2a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5v-2a.5.5,0,0,1,.5-.5h1A.5.5,0,0,1,22,12.5ZM15.71,12l3,3A.49.49,0,0,0,19,14.5v-2a.5.5,0,0,0-.5-.5Zm.73-5A4,4,0,0,1,20,10.56a.49.49,0,0,0,.49.44h1a.52.52,0,0,0,.37-.16.56.56,0,0,0,.13-.39,6,6,0,0,0-3.08-4.7A4,4,0,0,0,20,3.57a.49.49,0,0,0-.12-.4A.49.49,0,0,0,19.46,3h-1a.5.5,0,0,0-.49.4A2,2,0,0,1,16.4,5a.5.5,0,0,0-.4.49V6.53A.49.49,0,0,0,16.44,7Zm1,4a.51.51,0,0,0,.37-.13.52.52,0,0,0,.16-.37v-1a.5.5,0,0,0-.46-.5A6,6,0,0,1,12,3.46a.5.5,0,0,0-.5-.46h-1a.52.52,0,0,0-.37.16.51.51,0,0,0-.13.37A8,8,0,0,0,17.47,11ZM4.38,3.15a.48.48,0,0,0-.7,0l-.53.53a.48.48,0,0,0,0,.7L10.76,12H2.5a.5.5,0,0,0-.5.5v2a.5.5,0,0,0,.5.5H13.76l5.86,5.85a.48.48,0,0,0,.7,0l.53-.53a.48.48,0,0,0,0-.7Z',
  smoking:
    'M22,14.5v2a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5v-2a.5.5,0,0,1,.5-.5h1A.5.5,0,0,1,22,14.5ZM18.5,14H2.5a.5.5,0,0,0-.5.5v2a.5.5,0,0,0,.5.5h16a.5.5,0,0,0,.5-.5v-2A.5.5,0,0,0,18.5,14Zm2-1h1a.52.52,0,0,0,.37-.16.56.56,0,0,0,.13-.39,6,6,0,0,0-3.08-4.7A4,4,0,0,0,20,5.57a.49.49,0,0,0-.12-.4A.49.49,0,0,0,19.46,5h-1a.5.5,0,0,0-.49.4A2,2,0,0,1,16.4,7a.5.5,0,0,0-.4.49V8.53a.49.49,0,0,0,.44.49A4,4,0,0,1,20,12.56.49.49,0,0,0,20.47,13Zm-3,0a.51.51,0,0,0,.37-.13.52.52,0,0,0,.16-.37v-1a.5.5,0,0,0-.46-.5A6,6,0,0,1,12,5.46a.5.5,0,0,0-.5-.46h-1a.52.52,0,0,0-.37.16.51.51,0,0,0-.13.37A8,8,0,0,0,17.47,13Z',
  snowflake:
    'M21,11.69v.5a.5.5,0,0,1-.5.5H16.42l2.43,2.43a.48.48,0,0,1,0,.7l-.35.36a.51.51,0,0,1-.71,0L14.3,12.69H12.69v1.49l3.49,3.49a.5.5,0,0,1,0,.7l-.36.36a.5.5,0,0,1-.7,0L12.69,16.3v4.23a.47.47,0,0,1-.47.47h-.57a.47.47,0,0,1-.46-.47v-4l-2.31,2.3a.48.48,0,0,1-.7,0l-.36-.35a.51.51,0,0,1,0-.71l3.37-3.36V12.69H9.82L6.33,16.18a.5.5,0,0,1-.7,0l-.36-.36a.5.5,0,0,1,0-.7L7.7,12.69H3.47A.47.47,0,0,1,3,12.22v-.57a.47.47,0,0,1,.47-.46h4L5.15,8.88a.48.48,0,0,1,0-.7l.35-.36a.51.51,0,0,1,.71,0l3.36,3.37h1.62V9.7L7.82,6.33a.5.5,0,0,1,0-.7l.36-.36a.5.5,0,0,1,.7,0l2.31,2.3V3.5a.5.5,0,0,1,.5-.5h.5a.5.5,0,0,1,.5.5V7.58l2.43-2.43a.48.48,0,0,1,.7,0l.36.35a.51.51,0,0,1,0,.71L12.69,9.7v1.49H14.3l3.37-3.37a.5.5,0,0,1,.7,0l.36.36a.5.5,0,0,1,0,.7l-2.3,2.31H20.5A.5.5,0,0,1,21,11.69Z',
  sport:
    'M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm6.46,14.7-2.32,0a1,1,0,0,0-1,.72l-.67,2.22a7.89,7.89,0,0,1-5,0l-.67-2.22a1,1,0,0,0-1-.72l-2.32,0A8,8,0,0,1,4,12H4l1.91-1.34A1,1,0,0,0,6.29,9.5L5.53,7.32a8,8,0,0,1,4-2.93L11.39,5.8a1,1,0,0,0,1.22,0l1.85-1.41a8,8,0,0,1,4,2.93L17.71,9.5a1,1,0,0,0,.38,1.16L20,12h0A8,8,0,0,1,18.46,16.7ZM15.1,10.44a.88.88,0,0,1,.35,1l-1,2.89a1,1,0,0,1-1,.65h-3a1,1,0,0,1-1-.65l-1-2.89a.88.88,0,0,1,.35-1l2.49-1.75a1.09,1.09,0,0,1,1.22,0Z',
  square:
    'M19,3H5A2,2,0,0,0,3,5V19a2,2,0,0,0,2,2H19a2,2,0,0,0,2-2V5A2,2,0,0,0,19,3Z',
  square_outlined:
    'M19,5m0,0V19H5V5Zm0-2H5A2,2,0,0,0,3,5V19a2,2,0,0,0,2,2H19a2,2,0,0,0,2-2V5a2,2,0,0,0-2-2Z',
  stairs_down:
    'M22,6.5v1a.5.5,0,0,1-.5.5H19v3a1,1,0,0,1-1,1H15v3a1,1,0,0,1-1,1H11v3a1,1,0,0,1-1,1H2.5a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5H9V15a1,1,0,0,1,1-1h3V11a1,1,0,0,1,1-1h3V7a1,1,0,0,1,1-1h3.5A.5.5,0,0,1,22,6.5ZM2.5,13H6.75a.5.5,0,0,0,.35-.85L5.68,10.72l5.17-5.16a.5.5,0,0,0,0-.71l-.7-.7a.5.5,0,0,0-.71,0L4.27,9.31,2.85,7.89A.5.5,0,0,0,2,8.25V12.5A.52.52,0,0,0,2.5,13Z',
  stairs_up:
    'M22,6.5v1a.5.5,0,0,1-.5.5H19v3a1,1,0,0,1-1,1H15v3a1,1,0,0,1-1,1H11v3a1,1,0,0,1-1,1H2.5a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5H9V15a1,1,0,0,1,1-1h3V11a1,1,0,0,1,1-1h3V7a1,1,0,0,1,1-1h3.5A.5.5,0,0,1,22,6.5ZM2.85,12.86a.51.51,0,0,0,.71,0L8.73,7.69l1.42,1.42A.5.5,0,0,0,11,8.75V4.5a.52.52,0,0,0-.5-.5H6.25a.5.5,0,0,0-.35.85L7.32,6.28,2.15,11.44a.5.5,0,0,0,0,.71Z',
  survey:
    'M20,5a2,2,0,0,0-2-2H14a2,2,0,0,0-4,0H6A2,2,0,0,0,4,5V21a2,2,0,0,0,2,2H18a2,2,0,0,0,2-2ZM18,21H6V5H8V6.5a.5.5,0,0,0,.5.5h7a.5.5,0,0,0,.5-.5V5h2ZM8.5,11h1a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,9.5,9h-1a.5.5,0,0,0-.5.5v1A.5.5,0,0,0,8.5,11Zm0,4h1a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5v1A.5.5,0,0,0,8.5,15Zm0,4h1a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5v1A.5.5,0,0,0,8.5,19Z',
  table_tennis:
    'M19.1,4.9A6.67,6.67,0,0,0,14.34,3,8.69,8.69,0,0,0,8.25,5.63,6.71,6.71,0,0,0,6.89,14a1,1,0,0,1-.17,1.2L3.15,18.73a.5.5,0,0,0,0,.71l1.41,1.41a.5.5,0,0,0,.35.15.51.51,0,0,0,.36-.15l3.57-3.57a1,1,0,0,1,.71-.3,1.05,1.05,0,0,1,.49.13,6.73,6.73,0,0,0,3.3.89,7.08,7.08,0,0,0,5-2.25C21.57,12.56,21.89,7.7,19.1,4.9ZM17,14.34a5.69,5.69,0,0,1-2.22,1.4l-6.5-6.5A6,6,0,0,1,9.66,7a6.71,6.71,0,0,1,4.68-2,4.66,4.66,0,0,1,3.34,1.32C19.7,8.33,19.37,11.93,17,14.34Z',
  temperature:
    'M13,12.13V5A3,3,0,0,0,7,5v7.13a2,2,0,0,1-.57,1.4A4.91,4.91,0,0,0,5,17a5,5,0,0,0,10,0,4.91,4.91,0,0,0-1.43-3.47A2,2,0,0,1,13,12.13ZM9,5a1,1,0,0,1,2,0V8H9Zm1,14a2,2,0,1,1,2-2A2,2,0,0,1,10,19Z',
  tennis:
    'M18.5,15A3.5,3.5,0,1,0,22,18.5,3.49,3.49,0,0,0,18.5,15Zm0,5.25a1.75,1.75,0,1,1,1.75-1.75A1.75,1.75,0,0,1,18.5,20.25ZM14.87,2a6.8,6.8,0,0,0-4.68,2.19C6.25,8.13,9.07,11.66,6.26,14.5l0,0-.89.89a.5.5,0,0,0,0,.7l.54.54a.5.5,0,0,0,.7,0l.89-.89c2.8-2.8,6.42,0,10.33-3.95a9.36,9.36,0,0,0,1.12-1.38A6.08,6.08,0,0,0,20,7.13,5.31,5.31,0,0,0,14.87,2ZM13.14,12.25A3.56,3.56,0,0,1,9.75,8.86c0-2.47,2.9-5.11,5.11-5.11a3.56,3.56,0,0,1,3.39,3.4C18.25,9.61,15.36,12.25,13.14,12.25Zm-11,6.37A.47.47,0,0,0,2,19a.49.49,0,0,0,.15.35l.53.53a.48.48,0,0,0,.7,0l1.26-1.26a.49.49,0,0,0,0-.7l-.54-.54a.49.49,0,0,0-.7,0Z',
  'test-tube':
    'M17,2.5a.5.5,0,0,0-.5-.5h-9a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5H8V18a4,4,0,0,0,8,0V4h.5a.5.5,0,0,0,.5-.5ZM14,4v6H10V4Z',
  ticket:
    'M21.71,8.2l-.63-.63A1,1,0,0,0,20,7.31a2.57,2.57,0,0,1-1,.19A2.5,2.5,0,0,1,16.5,5a2.57,2.57,0,0,1,.19-1,1,1,0,0,0-.26-1.12l-.63-.63A1,1,0,0,0,15.1,2H13.92a1,1,0,0,0-.7.29L2.29,13.22a1,1,0,0,0-.29.7V15.1a1,1,0,0,0,.29.7l.63.63A1,1,0,0,0,4,16.69a2.57,2.57,0,0,1,1-.19A2.5,2.5,0,0,1,7.5,19a2.57,2.57,0,0,1-.19,1,1,1,0,0,0,.26,1.12l.63.63a1,1,0,0,0,.7.29h1.18a1,1,0,0,0,.7-.29L21.71,10.78a1,1,0,0,0,.29-.7V8.9A1,1,0,0,0,21.71,8.2ZM12,14a2,2,0,1,1,2-2A2,2,0,0,1,12,14Z',
  triangle:
    'M22.9,19.63,12.81,2.37A.76.76,0,0,0,12.16,2h-.3a.76.76,0,0,0-.65.37L1.1,19.63a.79.79,0,0,0,0,.76l.14.23a.73.73,0,0,0,.65.38H22.11a.73.73,0,0,0,.65-.38l.14-.23A.79.79,0,0,0,22.9,19.63Z',
  triangle_outlined:
    'M22.9,19.63,12.81,2.37A.76.76,0,0,0,12.16,2h-.3a.76.76,0,0,0-.65.37L1.1,19.63a.79.79,0,0,0,0,.76l.14.23a.73.73,0,0,0,.65.38H22.11a.73.73,0,0,0,.65-.38l.14-.23A.79.79,0,0,0,22.9,19.63ZM3.79,19,12,5l8.2,14Z',
  t_shirt:
    'M7,8.59l-2,2a.48.48,0,0,1-.7,0L2.15,8.49a.48.48,0,0,1,0-.7L5.86,4.07a2,2,0,0,1,.9-.52L8.81,3a.25.25,0,0,1,.29.15A3.79,3.79,0,0,0,12,5a3.79,3.79,0,0,0,2.9-1.84A.25.25,0,0,1,15.19,3l2,.54a2,2,0,0,1,.9.52l3.71,3.72a.48.48,0,0,1,0,.7l-2.12,2.12a.48.48,0,0,1-.7,0l-2-2V20a1,1,0,0,1-1,1H8a1,1,0,0,1-1-1Z',
  umbrella:
    'M17,18.5V19a3,3,0,0,1-6,0V15.26l.08-.09a1.23,1.23,0,0,1,.92-.42,1.26,1.26,0,0,1,.84.33l.16.13V19a1,1,0,0,0,2,0v-.5a.5.5,0,0,1,.5-.5h1A.5.5,0,0,1,17,18.5ZM13,4.06V2.5a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5V4.06A9,9,0,0,0,3,13v.5a.5.5,0,0,0,.5.5h.32a.78.78,0,0,0,.52-.21,2.7,2.7,0,0,1,3.82,0,.78.78,0,0,0,.52.21h.79a.74.74,0,0,0,.51-.2,3,3,0,0,1,4,0,.74.74,0,0,0,.51.2h.79a.78.78,0,0,0,.52-.21,2.7,2.7,0,0,1,3.82,0,.78.78,0,0,0,.52.21h.32a.5.5,0,0,0,.5-.5V13A9,9,0,0,0,13,4.06Z',
  umbrella_outlined:
    'M16.5,18h-1a.5.5,0,0,0-.5.5V19a1,1,0,0,1-2,0V15.21l-.16-.13a1.26,1.26,0,0,0-.84-.33,1.23,1.23,0,0,0-.92.42l-.08.09V19a3,3,0,0,0,6,0v-.5A.5.5,0,0,0,16.5,18ZM13,4.06V2.5a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5V4.06A9,9,0,0,0,3,13v.5a.5.5,0,0,0,.5.5h.32a.78.78,0,0,0,.52-.21,2.7,2.7,0,0,1,3.82,0,.78.78,0,0,0,.52.21h.79a.74.74,0,0,0,.51-.2,3,3,0,0,1,4,0,.74.74,0,0,0,.51.2h.79a.78.78,0,0,0,.52-.21,2.7,2.7,0,0,1,3.82,0,.78.78,0,0,0,.52.21h.32a.5.5,0,0,0,.5-.5V13A9,9,0,0,0,13,4.06ZM17.75,11a4.64,4.64,0,0,0-2.83.95,5,5,0,0,0-5.84,0A4.64,4.64,0,0,0,6.25,11a5.36,5.36,0,0,0-1,.1,7,7,0,0,1,13.48,0A5.36,5.36,0,0,0,17.75,11Z',
  volleyball:
    'M12,2A9.93,9.93,0,0,0,6,4H6a10,10,0,1,0,6-2ZM8,5.08a8.17,8.17,0,0,1,2-.82v8.89L8,14.31Zm4,2.3L20,12a8,8,0,0,1-.3,2.14L12,9.69Zm7.58,2.07L12,5.07V4A8,8,0,0,1,19.58,9.45ZM6,6.72v8.74L5.08,16A8,8,0,0,1,6,6.72ZM6.29,17.6,14,13.15l2,1.16L8,18.92A7.87,7.87,0,0,1,6.29,17.6ZM12,20a8.22,8.22,0,0,1-1.58-.16L18,15.46l.92.54A8,8,0,0,1,12,20Z',
  weight:
    'M20.43,10.7a2,2,0,0,0-2-1.7H14.62A4,4,0,1,0,9.38,9H5.55a2,2,0,0,0-2,1.7L2,20.85A1,1,0,0,0,3,22H21a1,1,0,0,0,1-1.15ZM12,17a2,2,0,1,1,2-2A2,2,0,0,1,12,17Zm0-9a2,2,0,1,1,2-2A2,2,0,0,1,12,8Z',
  weight_outlined:
    'M20.43,10.7a2,2,0,0,0-2-1.7H14.62A4,4,0,1,0,9.38,9H5.55a2,2,0,0,0-2,1.7L2,20.85A1,1,0,0,0,3,22H21a1,1,0,0,0,1-1.15ZM12,4a2,2,0,1,1-2,2A2,2,0,0,1,12,4ZM4.17,20l1.38-9h12.9l1.38,9ZM14,15a2,2,0,1,1-2-2A2,2,0,0,1,14,15Z',
  wishlist:
    'M21,4a2,2,0,0,0-2-2H7A2,2,0,0,0,5,4H3.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5H5v5H3.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5H5v5H3.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5H5a2,2,0,0,0,2,2H19a2,2,0,0,0,2-2ZM14.2,15.08l-.78.75a.6.6,0,0,1-.84,0l-.78-.75C10.9,14.2,9,12.5,9,11.14A2.17,2.17,0,0,1,11.2,9a2.59,2.59,0,0,1,1.8.8A2.59,2.59,0,0,1,14.8,9,2.17,2.17,0,0,1,17,11.14C17,12.5,15.1,14.2,14.2,15.08Z',
  wishlist_outlined:
    'M7,4H19V20H7ZM19,2H7A2,2,0,0,0,5,4H3.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5H5v5H3.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5H5v5H3.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5H5a2,2,0,0,0,2,2H19a2,2,0,0,0,2-2V4a2,2,0,0,0-2-2ZM14.2,15.08l-.78.75a.6.6,0,0,1-.84,0l-.78-.75C10.9,14.2,9,12.5,9,11.14A2.17,2.17,0,0,1,11.2,9a2.59,2.59,0,0,1,1.8.8A2.59,2.59,0,0,1,14.8,9,2.17,2.17,0,0,1,17,11.14C17,12.5,15.1,14.2,14.2,15.08Z',
  wrench:
    'M21,6.68V7a4,4,0,0,1-4,4,3.89,3.89,0,0,1-1.56-.32l-10,10a1,1,0,0,1-.7.29H4.53a1,1,0,0,1-.7-.29l-.54-.54a1,1,0,0,1-.29-.7v-.18a1,1,0,0,1,.29-.7l10-10A3.89,3.89,0,0,1,13,7a4,4,0,0,1,4-4h.32a.51.51,0,0,1,.36.15l.17.17a.5.5,0,0,1,0,.71L16.29,5.59a.5.5,0,0,0,0,.7l1.42,1.42a.51.51,0,0,0,.7,0L20,6.15a.5.5,0,0,1,.71,0l.17.17A.51.51,0,0,1,21,6.68Z',
  american_express:
    'M20,4H4A2,2,0,0,0,2,6V18a2,2,0,0,0,2,2H20a2,2,0,0,0,2-2V6A2,2,0,0,0,20,4ZM4,10.5v-2A.5.5,0,0,1,4.5,8h11a.5.5,0,0,1,.5.5v2a.5.5,0,0,1-.5.5H4.5A.5.5,0,0,1,4,10.5Zm16,5a.5.5,0,0,1-.5.5H8.5a.5.5,0,0,1-.5-.5v-2a.5.5,0,0,1,.5-.5h11a.5.5,0,0,1,.5.5Z',
  atm:
    'M20,4H4A2,2,0,0,0,2,6V8.5A1.5,1.5,0,0,0,3.5,10,.5.5,0,0,0,4,9.5V6H6V18a2,2,0,0,0,2,2h8a2,2,0,0,0,2-2V6h2V9.5a.5.5,0,0,0,.5.5A1.5,1.5,0,0,0,22,8.5V6A2,2,0,0,0,20,4ZM14,17.5a.5.5,0,0,1-.5.5h-2a.5.5,0,0,1-.5-.5V6.5a.5.5,0,0,1,.5-.5h2a.5.5,0,0,1,.5.5Z',
  atm_outlined:
    'M2,6V8.5A1.5,1.5,0,0,0,3.5,10h0A.5.5,0,0,0,4,9.5V6H6V18a2,2,0,0,0,2,2h8a2,2,0,0,0,2-2V6h2V9.5a.5.5,0,0,0,.5.5h0A1.5,1.5,0,0,0,22,8.5V6a2,2,0,0,0-2-2H4A2,2,0,0,0,2,6ZM8,18V6h3V18Zm8,0H14V6h2Z',
  bitcoin:
    'M10.75,9.75v1.37h3V9.75Zm0,3.13v1.37h3.5V12.88Zm0,0v1.37h3.5V12.88Zm0-3.13v1.37h3V9.75Zm0,0v1.37h3V9.75Zm0,3.13v1.37h3.5V12.88Zm0,0v1.37h3.5V12.88Zm3-3.13h-3v1.37h3ZM12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm4,12a2,2,0,0,1-2,2H13v1.5a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5V16H8.5a.5.5,0,0,1-.5-.5v-.75a.5.5,0,0,1,.5-.5h.25A.25.25,0,0,0,9,14V10a.25.25,0,0,0-.25-.25H8.5a.5.5,0,0,1-.5-.5V8.5A.5.5,0,0,1,8.5,8H11V6.5a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5V8h.5a2,2,0,0,1,2,2v.88a2,2,0,0,1-.16.77A2,2,0,0,1,16,13.12Zm-5.25.25h3.5V12.88h-3.5Zm3-4.5h-3v1.37h3Zm-3,0v1.37h3V9.75Zm0,3.13v1.37h3.5V12.88Zm0,0v1.37h3.5V12.88Zm0-3.13v1.37h3V9.75Z',
  bitcoin_outlined:
    'M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20Zm3.5-9.12V10a2,2,0,0,0-2-2H13V6.5a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5V8H8.5a.5.5,0,0,0-.5.5v.75a.5.5,0,0,0,.5.5h.25A.25.25,0,0,1,9,10v4a.25.25,0,0,1-.25.25H8.5a.5.5,0,0,0-.5.5v.75a.5.5,0,0,0,.5.5H11v1.5a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5V16h1a2,2,0,0,0,2-2v-.88a2,2,0,0,0-.66-1.47A2,2,0,0,0,15.5,10.88ZM10.75,9.75h3v1.37h-3Zm3.5,4.5h-3.5V12.88h3.5Z',
  coin:
    'M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20ZM11.47,6A6,6,0,0,0,6,11.45a.56.56,0,0,0,.13.39.52.52,0,0,0,.37.16h1A.49.49,0,0,0,8,11.56,4,4,0,0,1,11.56,8,.49.49,0,0,0,12,7.53v-1A.5.5,0,0,0,11.47,6Z',
  credit_card:
    'M22,6a2,2,0,0,0-2-2H4A2,2,0,0,0,2,6V18a2,2,0,0,0,2,2H20a2,2,0,0,0,2-2ZM10,16.5a.5.5,0,0,1-.5.5h-3a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h3a.5.5,0,0,1,.5.5Zm10-5a.5.5,0,0,1-.5.5H4.5a.5.5,0,0,1-.5-.5v-2A.5.5,0,0,1,4.5,9h15a.5.5,0,0,1,.5.5Z',
  credit_cards:
    'M17,3H3A2,2,0,0,0,1,5V16a2,2,0,0,0,2,2H17a2,2,0,0,0,2-2V5A2,2,0,0,0,17,3Zm0,2V8H3V5ZM3,16V11H17v5ZM21,7V18a2,2,0,0,1-2,2H5a2,2,0,0,0,2,2H19a4,4,0,0,0,4-4V9A2,2,0,0,0,21,7Z',
  credit_card_add:
    'M22,9a5,5,0,0,0-5-5,4.94,4.94,0,0,0-3,1H4A2,2,0,0,0,2,7V18a2,2,0,0,0,2,2H19a2,2,0,0,0,2-2V12A4.94,4.94,0,0,0,22,9ZM16.12,6.5a.5.5,0,0,1,.5-.5h.76a.5.5,0,0,1,.5.5V8.12H19.5a.5.5,0,0,1,.5.5v.76a.5.5,0,0,1-.5.5H17.88V11.5a.5.5,0,0,1-.5.5h-.76a.5.5,0,0,1-.5-.5V9.88H14.5a.5.5,0,0,1-.5-.5V8.62a.5.5,0,0,1,.5-.5h1.62ZM4,7h8.42A4.94,4.94,0,0,0,12,9a5.47,5.47,0,0,0,.1,1H4ZM4,18V13H14a4.93,4.93,0,0,0,5,.58V18Z',
  credit_card_outlined:
    'M20,4H4A2,2,0,0,0,2,6V18a2,2,0,0,0,2,2H20a2,2,0,0,0,2-2V6A2,2,0,0,0,20,4Zm0,2V9H4V6ZM4,18V12H20v6Zm5.5-4h-3a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h3a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,9.5,14Z',
  credit_card_remove:
    'M22,9a5,5,0,0,0-5-5,4.94,4.94,0,0,0-3,1H4A2,2,0,0,0,2,7V18a2,2,0,0,0,2,2H19a2,2,0,0,0,2-2V12A4.94,4.94,0,0,0,22,9Zm-7.5.88a.5.5,0,0,1-.5-.5V8.62a.5.5,0,0,1,.5-.5h5a.5.5,0,0,1,.5.5v.76a.5.5,0,0,1-.5.5ZM4,7h8.42A4.94,4.94,0,0,0,12,9a5.47,5.47,0,0,0,.1,1H4ZM4,18V13H14a4.93,4.93,0,0,0,5,.58V18Z',
  discover:
    'M20,4H4A2,2,0,0,0,2,6V18a2,2,0,0,0,2,2H20a2,2,0,0,0,2-2V6A2,2,0,0,0,20,4ZM4,18V6H20v6c0,3.31-6,6-12,6Zm8-9a3,3,0,1,0,3,3A3,3,0,0,0,12,9Z',
  dollar:
    'M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm3,7.75a.5.5,0,0,1-.5.5h-.75a.5.5,0,0,1-.5-.5v-1h-2.5v.88a.22.22,0,0,0,.09.19l3.43,2.83A2,2,0,0,1,15,14.19V15a2,2,0,0,1-2,2v.5a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5V17a2,2,0,0,1-2-2v-.75a.5.5,0,0,1,.5-.5h.75a.5.5,0,0,1,.5.5v1h2.5v-.88a.22.22,0,0,0-.09-.19L9.73,11.35A2,2,0,0,1,9,9.81V9a2,2,0,0,1,2-2V6.5a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5V7a2,2,0,0,1,2,2Z',
  dollar_outlined:
    'M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20ZM13,7V6.5a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5V7A2,2,0,0,0,9,9v.81a2,2,0,0,0,.73,1.54l3.43,2.83a.22.22,0,0,1,.09.19v.88h-2.5v-1a.5.5,0,0,0-.5-.5H9.5a.5.5,0,0,0-.5.5V15a2,2,0,0,0,2,2v.5a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5V17a2,2,0,0,0,2-2v-.81a2,2,0,0,0-.73-1.54L10.84,9.82a.22.22,0,0,1-.09-.19V8.75h2.5v1a.5.5,0,0,0,.5.5h.75a.5.5,0,0,0,.5-.5V9A2,2,0,0,0,13,7Z',
  ethereum:
    'M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm3.64,12.13-3.18,3.7a.5.5,0,0,1-.38.17h-.16a.5.5,0,0,1-.38-.17l-3.18-3.7a.11.11,0,0,1,0-.13.09.09,0,0,1,.13,0l3.27,1.76a.51.51,0,0,0,.48,0L15.51,14a.09.09,0,0,1,.13,0A.11.11,0,0,1,15.64,14.13Zm.12-2L12.24,14a.51.51,0,0,1-.48,0L8.24,12.15a.26.26,0,0,1-.09-.36l3.39-5.55A.51.51,0,0,1,12,6H12a.51.51,0,0,1,.43.24l3.39,5.55A.26.26,0,0,1,15.76,12.15Z',
  ethereum_outlined:
    'M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20Zm.46-13.76A.51.51,0,0,0,12,6H12a.51.51,0,0,0-.43.24L8.15,11.79a.26.26,0,0,0,.09.36L11.76,14a.51.51,0,0,0,.48,0l3.52-1.89a.26.26,0,0,0,.09-.36Zm-.22,9.5a.51.51,0,0,1-.48,0L8.49,14a.09.09,0,0,0-.13,0,.11.11,0,0,0,0,.13l3.18,3.7a.5.5,0,0,0,.38.17h.16a.5.5,0,0,0,.38-.17l3.18-3.7a.11.11,0,0,0,0-.13.09.09,0,0,0-.13,0Z',
  euro:
    'M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm1.6,9a.25.25,0,0,1,.21.12.25.25,0,0,1,0,.24l-.61,1.23a.76.76,0,0,1-.67.41H8.92A3.26,3.26,0,0,0,12,15.25a3.21,3.21,0,0,0,2-.67.5.5,0,0,1,.66.05l.53.54a.46.46,0,0,1,.15.38.52.52,0,0,1-.19.36A5,5,0,0,1,7.1,13H6.5a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h.6a5,5,0,0,1,8-2.91.52.52,0,0,1,.19.36.46.46,0,0,1-.15.38l-.53.54a.5.5,0,0,1-.66.05,3.21,3.21,0,0,0-2-.67A3.26,3.26,0,0,0,8.92,11Z',
  euro_outlined:
    'M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20ZM12,8.75a3.21,3.21,0,0,1,2,.67.5.5,0,0,0,.66-.05l.53-.54a.46.46,0,0,0,.15-.38.52.52,0,0,0-.19-.36A5,5,0,0,0,7.1,11H6.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h.6a5,5,0,0,0,8,2.91.52.52,0,0,0,.19-.36.46.46,0,0,0-.15-.38l-.53-.54a.5.5,0,0,0-.66-.05,3.21,3.21,0,0,1-2,.67A3.26,3.26,0,0,1,8.92,13h3.62a.76.76,0,0,0,.67-.41l.61-1.23a.25.25,0,0,0,0-.24A.25.25,0,0,0,13.6,11H8.92A3.26,3.26,0,0,1,12,8.75Z',
  insurance:
    'M24,7v7.53a2.47,2.47,0,0,1-.94,1.95l-3.69,3a1,1,0,0,0-.37.77V21a1,1,0,0,1-1,1H14a1,1,0,0,1-1-1V18.05a3,3,0,0,1,1.46-2.57l5.3-3.18a1.4,1.4,0,0,1,.75-.21,1.36,1.36,0,0,1,.49.08,1.46,1.46,0,0,1,.81.64,1.5,1.5,0,0,1-.51,2.06L17.7,17a.56.56,0,0,0-.23.31.5.5,0,0,0,.75.55l3.59-2.16A2.47,2.47,0,0,0,23,14.19,2.48,2.48,0,0,0,22,11.58V7a1,1,0,0,1,1-1h0A1,1,0,0,1,24,7ZM9.54,15.48,4.24,12.3a1.4,1.4,0,0,0-.75-.21,1.36,1.36,0,0,0-.49.08,1.46,1.46,0,0,0-.81.64,1.5,1.5,0,0,0,.51,2.06L6.3,17a.56.56,0,0,1,.23.31.5.5,0,0,1-.75.55L2.19,15.73a2.47,2.47,0,0,1-1.14-1.54,2.41,2.41,0,0,1,.28-1.89A2.34,2.34,0,0,1,2,11.57V7A1,1,0,0,0,1,6H1A1,1,0,0,0,0,7v7.54a2.47,2.47,0,0,0,.94,1.95l3.69,3A1,1,0,0,1,5,20.2V21a1,1,0,0,0,1,1h4a1,1,0,0,0,1-1V18.05A3,3,0,0,0,9.54,15.48ZM12,4a3,3,0,1,0,3,3,3,3,0,0,0-3-3m0-2A5,5,0,1,1,7,7a5,5,0,0,1,5-5Zm8.64,9.1.37,0V7.75l-.15-.26a1,1,0,0,0-1.35-.36h0a1,1,0,0,0-.36,1.35ZM4.88,8.49a1,1,0,0,0-.36-1.35h0a1,1,0,0,0-1.35.36L3,7.75v3.39l.37,0Z',
  litecoin:
    'M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm3.73,14.6a.5.5,0,0,1-.49.4H9.3a.25.25,0,0,1-.24-.3l.64-3.18-1.38.39A.26.26,0,0,1,8,13.67v-1a.5.5,0,0,1,.36-.48l1.71-.49.85-4.26a.5.5,0,0,1,.49-.4H13.7a.25.25,0,0,1,.24.3l-.69,3.45,1.43-.41a.26.26,0,0,1,.32.24v1a.5.5,0,0,1-.36.48l-1.76.51-.53,2.64H15.7a.25.25,0,0,1,.24.3Z',
  litecoin_outlined:
    'M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20Zm3.7-4.75H12.35l.53-2.64,1.76-.51a.5.5,0,0,0,.36-.48v-1a.26.26,0,0,0-.32-.24l-1.43.41.69-3.45A.25.25,0,0,0,13.7,7H11.41a.5.5,0,0,0-.49.4l-.85,4.26-1.71.49a.5.5,0,0,0-.36.48v1a.26.26,0,0,0,.32.24l1.38-.39L9.06,16.7a.25.25,0,0,0,.24.3h5.94a.5.5,0,0,0,.49-.4l.21-1.05A.25.25,0,0,0,15.7,15.25Z',
  mastercard:
    'M17,5a7,7,0,0,0-5,2.11,7,7,0,1,0,0,9.78A7,7,0,1,0,17,5Zm0,12a5,5,0,0,1-3.79-1.78,7,7,0,0,0,0-6.44A5,5,0,1,1,17,17Z',
  money:
    'M21,4H3A1,1,0,0,0,2,5V19a1,1,0,0,0,1,1H21a1,1,0,0,0,1-1V5A1,1,0,0,0,21,4ZM8,18a4,4,0,0,0-4-4V10A4,4,0,0,0,8,6h8a4,4,0,0,0,4,4v4a4,4,0,0,0-4,4Zm4-9a3,3,0,1,0,3,3A3,3,0,0,0,12,9Z',
  money_vs:
    'M21,4H15.89a9.08,9.08,0,0,0-4,1l-.62.31A6.93,6.93,0,0,1,8.11,6H3A1,1,0,0,0,2,7V19a1,1,0,0,0,1,1H8.11a9.08,9.08,0,0,0,4-.95l.62-.31A6.93,6.93,0,0,1,15.89,18H21a1,1,0,0,0,1-1V5A1,1,0,0,0,21,4Zm-1,9a3,3,0,0,0-3,3H15.89a9.11,9.11,0,0,0-4,.95l-.62.31A7.06,7.06,0,0,1,8.11,18H7a3,3,0,0,0-3-3V11A3,3,0,0,0,7,8H8.11a9.11,9.11,0,0,0,4-1l.62-.31A7.06,7.06,0,0,1,15.89,6H17a3,3,0,0,0,3,3Zm-5-1a3,3,0,1,1-3-3A3,3,0,0,1,15,12Z',
  paypal:
    'M20,10.05l-.14.89A6,6,0,0,1,13.89,16H12.75a.75.75,0,0,0-.74.63l-.75,4.74a.76.76,0,0,1-.75.63H8.7a.49.49,0,0,1-.49-.58l1.13-7.16a.75.75,0,0,1,.7-.51h1.7a7.75,7.75,0,0,0,7.6-6.26A3.45,3.45,0,0,1,20,10.05ZM11.74,12a6,6,0,0,0,5.93-5.06l.14-.89a3.5,3.5,0,0,0-3.46-4h-7a1,1,0,0,0-1,.84L4,17.42A.49.49,0,0,0,4.5,18H6.31a.74.74,0,0,0,.74-.63l.76-4.74A.74.74,0,0,1,8.55,12Z',
  pound:
    'M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm4,13a2,2,0,0,1-2,2H9.68a.52.52,0,0,1-.37-.16l-.26-.29A.51.51,0,0,1,9,16a8.31,8.31,0,0,0,.79-2.51,2.2,2.2,0,0,0-.06-.5H8.5a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h.64A4.38,4.38,0,0,1,9,10a3,3,0,0,1,5.94-.6.53.53,0,0,1-.1.42.53.53,0,0,1-.39.18h-.77a.5.5,0,0,1-.48-.36,1.25,1.25,0,0,0-2.45.36,4.08,4.08,0,0,0,.2,1H12.5a.5.5,0,0,1,.5.5v1a.5.5,0,0,1-.5.5h-1a4.07,4.07,0,0,1,0,.5,6,6,0,0,1-.36,1.75H14a.25.25,0,0,0,.25-.25v-.25a.5.5,0,0,1,.5-.5h.75a.5.5,0,0,1,.5.5Z',
  pound_outlined:
    'M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20Zm3.5-5.75h-.75a.5.5,0,0,0-.5.5V15a.25.25,0,0,1-.25.25H11.14a6,6,0,0,0,.36-1.75,4.07,4.07,0,0,0,0-.5h1a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5H11a4.08,4.08,0,0,1-.2-1,1.25,1.25,0,0,1,2.45-.36.5.5,0,0,0,.48.36h.77a.53.53,0,0,0,.39-.18.53.53,0,0,0,.1-.42A3,3,0,0,0,9,10a4.38,4.38,0,0,0,.14,1H8.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5H9.69a2.2,2.2,0,0,1,.06.5A8.31,8.31,0,0,1,9,16a.51.51,0,0,0,.09.54l.26.29a.52.52,0,0,0,.37.16H14a2,2,0,0,0,2-2v-.25A.5.5,0,0,0,15.5,14.25Z',
  receipt:
    'M6.18,21.85a.47.47,0,0,0,.35.15h.29a.47.47,0,0,0,.35-.15L9,20a.51.51,0,0,1,.71,0l1.81,1.81a.47.47,0,0,0,.35.15h.29a.47.47,0,0,0,.35-.15L14.32,20A.51.51,0,0,1,15,20l1.81,1.81a.47.47,0,0,0,.35.15h.29a.47.47,0,0,0,.35-.15L19.71,20a1,1,0,0,0,.29-.7V3a1,1,0,0,0-1-1H5A1,1,0,0,0,4,3V19.26a1.05,1.05,0,0,0,.29.71ZM13,13.5a.5.5,0,0,1-.5.5h-4a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h4a.5.5,0,0,1,.5.5Zm3-4a.5.5,0,0,1-.5.5h-7A.5.5,0,0,1,8,9.5v-1A.5.5,0,0,1,8.5,8h7a.5.5,0,0,1,.5.5Z',
  receipt_outlined:
    'M13,13.5a.5.5,0,0,1-.5.5h-4a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h4a.5.5,0,0,1,.5.5Zm3-4a.5.5,0,0,1-.5.5h-7A.5.5,0,0,1,8,9.5v-1A.5.5,0,0,1,8.5,8h7a.5.5,0,0,1,.5.5ZM19,2H5A1,1,0,0,0,4,3V19.26a1.05,1.05,0,0,0,.29.71l1.89,1.88a.47.47,0,0,0,.35.15h.29a.47.47,0,0,0,.35-.15L9,20a.5.5,0,0,1,.35-.15A.51.51,0,0,1,9.7,20l1.81,1.81a.47.47,0,0,0,.35.15h.29a.47.47,0,0,0,.35-.15L14.32,20a.5.5,0,0,1,.35-.15A.51.51,0,0,1,15,20l1.81,1.81a.47.47,0,0,0,.35.15h.29a.47.47,0,0,0,.35-.15L19.71,20a1,1,0,0,0,.29-.7V3A1,1,0,0,0,19,2ZM18,18.86l-.66.66h0l-.74-.73-.16-.16h0l-.34-.34a1,1,0,0,0-.7-.29H14a1,1,0,0,0-.71.29L12,19.53l-1.24-1.24a1,1,0,0,0-.7-.29H8.61a1,1,0,0,0-.7.29L6.68,19.52h0L6,18.84V4H18Z',
  saving:
    'M21,10a2,2,0,0,0-2-2H15.45A4,4,0,0,0,16,6h3a2,2,0,0,0-2-2H15.45a4,4,0,0,0-6.9,0H6A3,3,0,0,0,3,7V20a2,2,0,0,0,2,2H19a2,2,0,0,0,2-2ZM12,4a2,2,0,1,1-2,2A2,2,0,0,1,12,4ZM5,7A1,1,0,0,1,6,6H8a4,4,0,0,0,.55,2H6A1,1,0,0,1,5,7ZM19,18H12a1,1,0,0,1-1-1V13a1,1,0,0,1,1-1h7Zm-6-2.5a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5Z',
  savings:
    'M9,10A4,4,0,1,0,5,6,4,4,0,0,0,9,10ZM9,4A2,2,0,1,1,7,6,2,2,0,0,1,9,4Zm6.75,10.42L17,13.17V10a1,1,0,0,0-2,0v3.5A2.48,2.48,0,0,1,15.75,14.42Zm5-2.13a1,1,0,0,0-1.42,0L16,15.59h0A2.5,2.5,0,0,1,13.5,18h-4a.5.5,0,0,1,0-1h4l.23,0h0A1.49,1.49,0,0,0,15,15.35,1.57,1.57,0,0,0,13.4,14H8.71a3.32,3.32,0,0,0-2.59.91L3.29,17.82a1,1,0,0,0,0,1.41l2.48,2.48a1,1,0,0,0,1.41,0L8.6,20.29A1,1,0,0,1,9.3,20h4.08a2.51,2.51,0,0,0,1.77-.73l5.56-5.56A1,1,0,0,0,20.71,12.29Z',
  saving_outlined:
    'M19,8H15.45A4,4,0,0,0,16,6h3a2,2,0,0,0-2-2H15.45a4,4,0,0,0-6.9,0H6A3,3,0,0,0,3,7V20a2,2,0,0,0,2,2H19a2,2,0,0,0,2-2V10A2,2,0,0,0,19,8ZM12,4a2,2,0,1,1-2,2A2,2,0,0,1,12,4ZM6,6H8a4,4,0,0,0,.55,2H6A1,1,0,0,1,6,6Zm13,6H12a1,1,0,0,0-1,1v4a1,1,0,0,0,1,1h7v2H5V10H19Zm-4,2.5v1a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h1A.5.5,0,0,1,15,14.5Z',
  visa:
    'M21,4.7,14.38,19.41a1,1,0,0,1-.92.59H10.09a1,1,0,0,1-1-.73L5.78,7.36a3,3,0,0,0-1.6-1.91,20,20,0,0,0-2-.79A.26.26,0,0,1,2,4.42V4.25A.25.25,0,0,1,2.25,4H7.92c1,0,1.77.27,2,1.33l1.59,7.51.44,2.3L16.24,4.62A1,1,0,0,1,17.17,4H20.5A.5.5,0,0,1,21,4.7Z',
  wallet:
    'M19,7H6A1,1,0,0,1,6,5H19a2,2,0,0,0-2-2H6A3,3,0,0,0,3,6V19a2,2,0,0,0,2,2H19a2,2,0,0,0,2-2V9A2,2,0,0,0,19,7Zm0,10H12a1,1,0,0,1-1-1V12a1,1,0,0,1,1-1h7Zm-5.5-2h1a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5v1A.5.5,0,0,0,13.5,15Z',
  wallet_outlined:
    'M19,7H6A1,1,0,0,1,6,5H19a2,2,0,0,0-2-2H6A3,3,0,0,0,3,6V19a2,2,0,0,0,2,2H19a2,2,0,0,0,2-2V9A2,2,0,0,0,19,7ZM5,19V9H19v2H12a1,1,0,0,0-1,1v4a1,1,0,0,0,1,1h7v2Zm10-5.5v1a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h1A.5.5,0,0,1,15,13.5Z',
  accessibility:
    'M21,6.5v1a.5.5,0,0,1-.5.5H15V22.5a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5V15H11v7.5a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5V8H3.5A.5.5,0,0,1,3,7.5v-1A.5.5,0,0,1,3.5,6h17A.5.5,0,0,1,21,6.5ZM12,5a2,2,0,1,0-2-2A2,2,0,0,0,12,5Z',
  accessibility_vs:
    'M10,3a2,2,0,1,1,2,2A2,2,0,0,1,10,3ZM21.16,5.47l-.32-.94a.49.49,0,0,0-.63-.32L15.92,5.64A7,7,0,0,1,13.7,6H10.29a7,7,0,0,1-2.21-.36L3.79,4.21a.49.49,0,0,0-.63.32l-.32.94a.51.51,0,0,0,.32.64L7.45,7.54A8.49,8.49,0,0,0,9,7.9V22.5a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5V15h2v7.5a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5V7.9a9.4,9.4,0,0,0,1.55-.36l4.29-1.43A.51.51,0,0,0,21.16,5.47Z',
  accessible:
    'M9,4c0-1.1,0.9-2,2-2s2,0.9,2,2s-0.9,2-2,2S9,5.1,9,4z M20,21.4l-1.5-5.2C18.1,14.9,17,14,15.6,14H13v-3 c0.7,0.6,1.6,1,2.6,1h1.9c0.3,0,0.5-0.2,0.5-0.5v-1c0-0.3-0.2-0.5-0.5-0.5h-1.9c-0.7,0-1.3-0.3-1.7-0.9l-0.8-1.2 c-0.4-0.6-1-0.9-1.7-0.9H11C9.9,7,9,7.9,9,9v5.5c0,0.8,0.7,1.5,1.5,1.5l0,0h4.7c0.7,0,1.3,0.4,1.5,1.1l1.3,4.5 c0.1,0.2,0.3,0.4,0.5,0.4h1c0.3,0,0.5-0.2,0.5-0.5C20,21.5,20,21.4,20,21.4z M12,17c0,1.7-1.3,3-3,3s-3-1.3-3-3c0-1.3,0.8-2.4,2-2.8 v-2.1c-2.7,0.6-4.5,3.2-3.9,5.9s3.2,4.5,5.9,3.9c2.3-0.5,4-2.5,4-4.9H12z',
  accessible_forward:
    'M15,4.5a2,2,0,1,1,2,2A2,2,0,0,1,15,4.5Zm1.12,5.34A2,2,0,0,0,14.3,7H10.53A3,3,0,0,0,7.81,8.73l-.73,1.56a.5.5,0,0,0,.46.71h1.1a.48.48,0,0,0,.45-.29l.53-1.13A1,1,0,0,1,10.53,9H12.1l-1.94,4.15A2,2,0,0,0,12,16H16.5a.5.5,0,0,1,.5.5v5a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5V16a2,2,0,0,0-2-2H14.18ZM10,20a3,3,0,0,1-1-5.82V12.1A5,5,0,1,0,15,17H13A3,3,0,0,1,10,20Z',
  hiking:
    'M7.7,15.18l1.75,1.17-.24.84a3.06,3.06,0,0,1-.49,1l-2,2.62a.5.5,0,0,1-.7.1l-.79-.61a.51.51,0,0,1-.1-.7l2-2.62a1,1,0,0,0,.16-.33ZM11,5A2,2,0,1,0,9,3,2,2,0,0,0,11,5ZM5.83,5H4.12a1,1,0,0,0-1,.72L2,9.7a1,1,0,0,0,.46,1.14l1.45.84a.5.5,0,0,0,.73-.3L6.31,5.64A.5.5,0,0,0,5.83,5ZM18.49,4h-1a.5.5,0,0,0-.5.51L17.07,9h-.84a2.06,2.06,0,0,1-1.1-.34L11.92,6.51A3,3,0,0,0,10.25,6H9.8A2,2,0,0,0,7.87,7.45L6.69,11.57a1,1,0,0,0,.41,1.11l4.57,3a.78.78,0,0,1,.33.63V22.5a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5V16a2,2,0,0,0-.89-1.67l-2.55-1.7h0L11.65,8.8l2.22,1.44a4.46,4.46,0,0,0,2.34.76h.88l.15,11.51a.51.51,0,0,0,.5.49h.52a.51.51,0,0,0,.5-.49l.23-18A.5.5,0,0,0,18.49,4Z',
  man:
    'M10,3a2,2,0,1,1,2,2A2,2,0,0,1,10,3Zm5.41,4.36-.77-.77A2,2,0,0,0,13.22,6H10.78a2,2,0,0,0-1.42.59l-.77.77A2,2,0,0,0,8,8.78V13.5a.5.5,0,0,0,.5.5H9v8.5a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5V15h2v7.5a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5V14h.5a.5.5,0,0,0,.5-.5V8.78A2,2,0,0,0,15.41,7.36Z',
  pregnancy:
    'M8,10a3,3,0,0,1,3-3,3,3,0,0,1,3,3.11v.07A3,3,0,0,1,16,13v3.5a.5.5,0,0,1-.5.5H13v5.5a.5.5,0,0,1-.5.5h-2a.5.5,0,0,1-.5-.5V17H8.5a.5.5,0,0,1-.5-.5ZM9.5,5h3a.5.5,0,0,0,.5-.5V3A2,2,0,0,0,9,3V4.5A.5.5,0,0,0,9.5,5Z',
  running:
    'M15,4a2,2,0,1,1,2,2A2,2,0,0,1,15,4Zm5.6,6.75-1.93-.39A1,1,0,0,1,18.1,10L15.88,7.41A4,4,0,0,0,12.83,6H9.77a2,2,0,0,0-1.61.82L6.48,9.13a.5.5,0,0,0,.12.7l.8.58a.49.49,0,0,0,.7-.11l1.45-2A.77.77,0,0,1,10.16,8h1.55L9.29,11.21l-.16.22a1,1,0,0,0,.24,1.43l4.3,2.86a.78.78,0,0,1,.33.63V21.5a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5V16a2,2,0,0,0-.89-1.67L13,12.89l2.3-3.05,1.25,1.48a3,3,0,0,0,1.7,1l2.12.42a.5.5,0,0,0,.6-.49v-1A.5.5,0,0,0,20.6,10.75ZM8.37,15.7a.74.74,0,0,1-.6.3H4.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5H8.15a2,2,0,0,0,1.6-.8l1-1.33L9.06,14.76Z',
  standing:
    'M10,3a2,2,0,1,1,2,2A2,2,0,0,1,10,3Zm9.45,9L15.67,7.44A4,4,0,0,0,12.59,6H11.41A4,4,0,0,0,8.33,7.44L4.55,12a.5.5,0,0,0,.07.7l.76.64a.51.51,0,0,0,.71-.06L9,9.76V22.5a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5V15h2v7.5a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5V9.76l2.91,3.5a.51.51,0,0,0,.71.06l.76-.64A.5.5,0,0,0,19.45,12Z',
  swimming:
    'M15,6.5A2.5,2.5,0,1,1,17.5,9,2.51,2.51,0,0,1,15,6.5ZM21.46,15c-2.15.12-2.49,1-4.46,1-2.16,0-2.35-1-5-1s-2.87,1-5,1-2.31-.86-4.46-1a.5.5,0,0,0-.54.5v1a.5.5,0,0,0,.45.5c1.74.12,2.09,1,4.55,1,2.71,0,2.77-1,5-1s2.36,1,5,1c2.45,0,2.83-.86,4.55-1a.5.5,0,0,0,.45-.5v-1A.5.5,0,0,0,21.46,15Zm0,4c-2.15.12-2.49,1-4.46,1-2.16,0-2.35-1-5-1s-2.87,1-5,1-2.31-.86-4.46-1a.5.5,0,0,0-.54.5v1a.5.5,0,0,0,.45.5c1.74.12,2.09,1,4.55,1,2.71,0,2.77-1,5-1s2.36,1,5,1c2.45,0,2.83-.86,4.55-1a.5.5,0,0,0,.45-.5v-1A.5.5,0,0,0,21.46,19ZM5.5,7.5H8.61A2,2,0,0,1,10,8.09L11,9,6.11,13.89A3.22,3.22,0,0,0,7,14c1.4,0,2.11-1,5-1s3.56,1,5,1a5,5,0,0,0,2-.51l-7-7A5,5,0,0,0,8.41,5H5.5a.5.5,0,0,0-.5.5V7A.5.5,0,0,0,5.5,7.5Z',
  walking:
    'M19,10.23v1a.5.5,0,0,1-.6.49l-2.14-.43a3.07,3.07,0,0,1-1.69-1l-1-1.12-.81,4.69,1.45,1.38a2.07,2.07,0,0,1,.58,1l1.27,6.09a.49.49,0,0,1-.49.6h-1a.49.49,0,0,1-.49-.4L12.94,17a1,1,0,0,0-.3-.54L9.31,13.32a1,1,0,0,1-.3-.9l.64-3.71-1.26.84a1,1,0,0,0-.41.56l-.72,2.53a.5.5,0,0,1-.48.36h-1a.5.5,0,0,1-.48-.64L6.16,9.2A2,2,0,0,1,7,8.08L8.87,6.83A5,5,0,0,1,11.63,6h.51a3,3,0,0,1,2.29,1.06L16.1,9a1,1,0,0,0,.57.34l1.93.38A.5.5,0,0,1,19,10.23ZM9.42,15.84l0,.11a1,1,0,0,1-.23.49L6.46,19.51a.5.5,0,0,0,0,.7l.76.67a.49.49,0,0,0,.7-.05l2.89-3.28a1.56,1.56,0,0,0,.16-.2ZM13,5a2,2,0,1,0-2-2A2,2,0,0,0,13,5Z',
  woman:
    'M15,17v5.5a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5V17H11v5.5a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5V17H7.5A.5.5,0,0,1,7,16.36L9.61,7.71a1,1,0,0,1,1-.71h2.86a1,1,0,0,1,1,.71L17,16.36a.5.5,0,0,1-.48.64ZM10.5,5h3a.5.5,0,0,0,.5-.5V3a2,2,0,0,0-4,0V4.5A.5.5,0,0,0,10.5,5Z',
  award:
    'M20,4H17V3a1,1,0,0,0-1-1H8A1,1,0,0,0,7,3V4H4A1,1,0,0,0,3,5V9a4,4,0,0,0,4,4h.14A4,4,0,0,0,11,16v4H8.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h7a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5H13V16a4,4,0,0,0,3.86-3H17a4,4,0,0,0,4-4V5A1,1,0,0,0,20,4ZM5,9V6H7v5A2,2,0,0,1,5,9ZM19,9a2,2,0,0,1-2,2V6h2Z',
  award_outlined:
    'M20,4H17V3a1,1,0,0,0-1-1H8A1,1,0,0,0,7,3V4H4A1,1,0,0,0,3,5V9a4,4,0,0,0,4,4h.14A4,4,0,0,0,11,16v4H8.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h7a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5H13V16a4,4,0,0,0,3.86-3H17a4,4,0,0,0,4-4V5A1,1,0,0,0,20,4ZM5,9V6H7v5A2,2,0,0,1,5,9Zm10,3a2,2,0,0,1-2,2H11a2,2,0,0,1-2-2V4h6Zm4-3a2,2,0,0,1-2,2V6h2Z',
  boost:
    'M22.9,20.39a.79.79,0,0,0,0-.76L12.81,2.37A.76.76,0,0,0,12.16,2h-.3a.76.76,0,0,0-.65.37L1.1,19.63a.79.79,0,0,0,0,.76l.14.23a.73.73,0,0,0,.65.38H22.11a.73.73,0,0,0,.65-.38ZM15,12.68a.59.59,0,0,1-.07.26l-3,4.84a.44.44,0,0,1-.39.22H11.5a.48.48,0,0,1-.35-.16.47.47,0,0,1-.1-.36l.34-2.41H9.5a.5.5,0,0,1-.5-.5v-.16a.59.59,0,0,1,.07-.26l3-4.91A.51.51,0,0,1,12.52,9a.48.48,0,0,1,.35.16.47.47,0,0,1,.1.36L12.61,12H14.5a.5.5,0,0,1,.5.5Z',
  crown:
    'M21.5,5h-.38a1.5,1.5,0,0,0-1.06.44L17,8.5,12.72,4.22A.75.75,0,0,0,12.19,4h-.38a.75.75,0,0,0-.53.22L7,8.5,3.94,5.44A1.5,1.5,0,0,0,2.88,5H2.5a.5.5,0,0,0-.5.5v10a.5.5,0,0,0,.5.5h19a.5.5,0,0,0,.5-.5V5.5A.5.5,0,0,0,21.5,5ZM12,13a2,2,0,1,1,2-2A2,2,0,0,1,12,13Zm9.5,5H2.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h19a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,21.5,18Z',
  crown_outlined:
    'M22,18.5v1a.5.5,0,0,1-.5.5H2.5a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h19A.5.5,0,0,1,22,18.5Zm0-13v10a.5.5,0,0,1-.5.5H2.5a.5.5,0,0,1-.5-.5V5.5A.5.5,0,0,1,2.5,5h.38a1.5,1.5,0,0,1,1.06.44L7,8.5l4.28-4.28A.75.75,0,0,1,11.81,4h.38a.75.75,0,0,1,.53.22L17,8.5l3.06-3.06A1.5,1.5,0,0,1,21.12,5h.38A.5.5,0,0,1,22,5.5ZM20,8.33l-2,2a.49.49,0,0,1-.36.15H16.38a.49.49,0,0,1-.36-.15l-4-4-4,4a.49.49,0,0,1-.36.15H6.38A.49.49,0,0,1,6,10.35l-2-2V14H20Z',
  diamond:
    'M21.74,8.16,17.59,3.65A2,2,0,0,0,16.12,3H7.88a2,2,0,0,0-1.47.65L2.26,8.16A1,1,0,0,0,2,8.84v.3a1,1,0,0,0,.23.64l9,10.86A1,1,0,0,0,12,21H12a1,1,0,0,0,.77-.36l9-10.86A1,1,0,0,0,22,9.14v-.3A1,1,0,0,0,21.74,8.16ZM11.18,17.47l-6.65-8A.25.25,0,0,1,4.72,9H8.27a1,1,0,0,1,1,.69l2.39,7.55A.25.25,0,0,1,11.18,17.47ZM19.24,9H13.07a.25.25,0,0,1-.19-.42L15.94,5.2a.25.25,0,0,1,.37,0l3.11,3.38A.25.25,0,0,1,19.24,9Z',
  diamond_outlined:
    'M21.74,8.16,17.59,3.65A2,2,0,0,0,16.12,3H7.88a2,2,0,0,0-1.47.65L2.26,8.16A1,1,0,0,0,2,8.84v.3a1,1,0,0,0,.23.64l9,10.86A1,1,0,0,0,12,21H12a1,1,0,0,0,.77-.36l9-10.86A1,1,0,0,0,22,9.14v-.3A1,1,0,0,0,21.74,8.16ZM12,18.46,9.22,9.7a1,1,0,0,0-1-.7H4.21L7.88,5h8.24l0,0L13.26,8.16a.5.5,0,0,0,.37.84h6.16l0,0Z',
  dna_spiral:
    'M16,9.46a7,7,0,0,0,2.05-5v-2a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5v2A3.87,3.87,0,0,1,16,5H8.05A3.87,3.87,0,0,1,8,4.51v-2A.5.5,0,0,0,7.5,2h-1a.5.5,0,0,0-.5.5v2a7,7,0,0,0,2.05,5L10.59,12,8.05,14.54A7,7,0,0,0,6,19.49v2a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5v-2A3.87,3.87,0,0,1,8.05,19H16a3.87,3.87,0,0,1,.05.49v2a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5v-2A7,7,0,0,0,16,14.54l-1.3-1.3-1.41,1.41,1.3,1.3A5,5,0,0,1,15.32,17H8.69A4.65,4.65,0,0,1,9.46,16ZM9.46,8.05A5,5,0,0,1,8.68,7h6.63a4.65,4.65,0,0,1-.77,1.05L12,10.59Z',
  luck:
    'M20,12.12a2.27,2.27,0,0,1-2.13,2.25h0a.25.25,0,0,0-.24.25v.13a2.25,2.25,0,0,1-4.44.49h0L13,14.57a.11.11,0,0,0-.11-.08.11.11,0,0,0-.09.1l0,1.47,0,1.48v.77a5.51,5.51,0,0,1-.1.9,5,5,0,0,1-1.48,2.65.51.51,0,0,1-.77-.07L10,21a.5.5,0,0,1,.06-.65A3.08,3.08,0,0,0,11,18.81a2.1,2.1,0,0,0,.08-.56l0-.71,0-1.48,0-1.47a.11.11,0,0,0-.09-.1.11.11,0,0,0-.11.08l-.15.67h0a2.25,2.25,0,0,1-4.44-.49v-.12h0a.25.25,0,0,0-.24-.25A2.25,2.25,0,0,1,6,9.89H6c.17-.05.61,0,.61-.29s-.43-.26-.73-.37h0a2.39,2.39,0,0,1,.52-4.72h.15a.24.24,0,0,0,.25-.25,2.39,2.39,0,0,1,4.76-.15h0l.2,1.71a.25.25,0,0,0,.5,0l.2-1.71h0a2.39,2.39,0,0,1,4.76.16.25.25,0,0,0,.25.24h.15a2.39,2.39,0,0,1,.52,4.72h0c-.31.11-.73,0-.73.37s.45.25.61.29h0A2.25,2.25,0,0,1,20,12.12Z',
  medal:
    'M19.81,9.05a2.43,2.43,0,0,0-.49-.75L19.07,8a2.39,2.39,0,0,1-.49-.74,2.44,2.44,0,0,1-.19-.88V6a2.35,2.35,0,0,0-.19-.88A2.46,2.46,0,0,0,16,3.62h-.37A2.5,2.5,0,0,1,14,2.93l-.27-.26a2.48,2.48,0,0,0-3.4,0L10,2.93a2.45,2.45,0,0,1-1.62.68H8A2.48,2.48,0,0,0,5.62,6v.37A2.5,2.5,0,0,1,4.93,8l-.26.27A2.5,2.5,0,0,0,4.19,11a2.43,2.43,0,0,0,.49.75l.25.28a2.32,2.32,0,0,1,.49.75,2.34,2.34,0,0,1,.19.87V14a2.35,2.35,0,0,0,.19.88A2.48,2.48,0,0,0,8,16.38V21.5a.5.5,0,0,0,.5.5h.26a1,1,0,0,0,.45-.11l2.57-1.28a.49.49,0,0,1,.44,0l2.57,1.28a1,1,0,0,0,.45.11h.26a.5.5,0,0,0,.5-.5V16.38A2.48,2.48,0,0,0,18.38,14v-.37A2.5,2.5,0,0,1,19.07,12l.26-.27A2.5,2.5,0,0,0,19.81,9.05Zm-1.93,1.27-.27.28a4.55,4.55,0,0,0-1.22,3v.37a.48.48,0,0,1-.49.46l-.34,0a4.39,4.39,0,0,0-2.93,1.2l-.29.27A.48.48,0,0,1,12,16a.45.45,0,0,1-.32-.12l-.28-.27a4.55,4.55,0,0,0-3-1.22H8.08a.45.45,0,0,1-.42-.29.37.37,0,0,1,0-.2l0-.34A4.35,4.35,0,0,0,7.27,12a4.25,4.25,0,0,0-.87-1.34l-.27-.29A.6.6,0,0,1,6,10.18a.46.46,0,0,1,.08-.5l.27-.28A4.55,4.55,0,0,0,7.61,6.45V6.08a.48.48,0,0,1,.49-.46h.34A4.45,4.45,0,0,0,11.38,4.4l.29-.27A.48.48,0,0,1,12,4a.45.45,0,0,1,.32.12l.28.27a4.55,4.55,0,0,0,3,1.22h.37a.45.45,0,0,1,.42.29.37.37,0,0,1,0,.2l0,.34A4.35,4.35,0,0,0,16.73,8a4.25,4.25,0,0,0,.87,1.34l.27.29a.6.6,0,0,1,.09.15A.46.46,0,0,1,17.88,10.32Z',
  planet:
    'M22.88,7.57C22.53,6.62,21.52,6,19.57,6a16,16,0,0,0-3.34.43A7,7,0,0,0,5.17,10.48c-3.58,1.92-4.55,3.59-4,4.95.63,1.71,3.09,1.79,5.76,1.32A7,7,0,0,0,19,12.45C22.43,10.56,23.37,8.91,22.88,7.57ZM5.05,12.81a4.93,4.93,0,0,0,.11.67,18.22,18.22,0,0,0,2-.34A5.38,5.38,0,0,1,7,12a5,5,0,0,1,9.92-.75c-1.24.6-2.72,1.23-4.34,1.82A43.23,43.23,0,0,1,7.7,14.55h0a18.84,18.84,0,0,1-2.06.38h0C2.85,15.3,2.07,14.58,5.05,12.81ZM12,17a4.94,4.94,0,0,1-2.69-.8c1.52-.41,2.95-.9,3.95-1.26,1.31-.48,2.46-.94,3.47-1.39A5,5,0,0,1,12,17Zm6.78-6.73a7.34,7.34,0,0,0-.94-2.14C22,7.43,21.57,8.63,18.78,10.27Z',
  premium:
    'M22,8.84v.3a1,1,0,0,1-.23.64l-9,10.86A1,1,0,0,1,12,21H12a1,1,0,0,1-.77-.36l-9-10.86A1,1,0,0,1,2,9.14v-.3a1,1,0,0,1,.26-.68L6.41,3.65A2,2,0,0,1,7.88,3h8.24a2,2,0,0,1,1.47.65l4.15,4.51A1,1,0,0,1,22,8.84Z',
  premium_outlined:
    'M16.12,5h0l3.69,4L12,18.46,4.19,9,7.88,5h8.24m0-2H7.88a2,2,0,0,0-1.47.65L2.26,8.16A1,1,0,0,0,2,8.84v.3a1,1,0,0,0,.23.64l9,10.86A1,1,0,0,0,12,21H12a1,1,0,0,0,.77-.36l9-10.86A1,1,0,0,0,22,9.14v-.3a1,1,0,0,0-.26-.68L17.59,3.65A2,2,0,0,0,16.12,3Z',
  robot:
    'M6,11A2,2,0,0,1,8,9h8a2,2,0,0,1,2,2v7a2,2,0,0,1-2,2H8a2,2,0,0,1-2-2Zm6-9a2,2,0,0,0-2,2,2,2,0,0,0,1,1.72V7H8a4,4,0,0,0-4,4v2a2,2,0,0,0,0,4v1a4,4,0,0,0,4,4h8a4,4,0,0,0,4-4V17a2,2,0,0,0,0-4V11a4,4,0,0,0-4-4H13V5.72a2,2,0,0,0-.57-3.67A1.58,1.58,0,0,0,12,2ZM11,12.5a1.5,1.5,0,0,0-3,0,.5.5,0,0,0,.5.5h2A.5.5,0,0,0,11,12.5ZM14.5,11A1.5,1.5,0,0,0,13,12.5a.5.5,0,0,0,.5.5h2a.5.5,0,0,0,.5-.5A1.5,1.5,0,0,0,14.5,11ZM14,15H10a1,1,0,0,0-1,1H9a2,2,0,0,0,2,2h2a2,2,0,0,0,2-2h0A1,1,0,0,0,14,15Z',
  sticker:
    'M12,2C6.5,2,2,6.5,2,12c0,5.2,4,9.5,9,10c0.1,0,0.1,0,0.2,0c0.5,0,1-0.2,1.4-0.6l8.8-8.8c0.4-0.4,0.6-1,0.6-1.6 C21.5,6,17.2,2,12,2z M12,19.2V18c0-3.3,2.7-6,6-6h1.3L12,19.2z',
  sticker_outlined:
    'M12,2C6.5,2,2,6.5,2,12c0,5.2,4,9.5,9,10c0.1,0,0.1,0,0.2,0c0.5,0,1-0.2,1.4-0.6l8.8-8.8c0.4-0.4,0.6-1,0.6-1.6 C21.5,6,17.2,2,12,2z M4,12c0-4.4,3.6-8,8-8c3.7,0,6.8,2.5,7.8,6H18c-4.4,0-8,3.6-8,8v1.8C6.5,18.9,4,15.7,4,12z M12,19.2V18 c0-3.3,2.7-6,6-6h1.3L12,19.2z',
  top_security:
    'M20.12,5.12,16.59,1.59A2,2,0,0,0,15.17,1H8.83a2,2,0,0,0-1.42.59L3.88,5.12A3,3,0,0,0,3,7.24v5.85A8,8,0,0,0,6.09,19.4l4.08,3.18A2,2,0,0,0,11.4,23h1.2a2,2,0,0,0,1.23-.42l4.08-3.18A8,8,0,0,0,21,13.09V7.24A3,3,0,0,0,20.12,5.12Zm-3.75,5-2,1.7L15,14.38a.5.5,0,0,1-.74.55L12,13.56,9.75,14.93A.5.5,0,0,1,9,14.38l.61-2.54-2-1.7a.51.51,0,0,1,.29-.88l2.6-.2,1-2.43a.5.5,0,0,1,.92,0l1,2.43,2.6.2A.51.51,0,0,1,16.37,10.14Z',
  top_security_outlined:
    'M20.12,5.12,16.59,1.59A2,2,0,0,0,15.17,1H8.83a2,2,0,0,0-1.42.59L3.88,5.12A3,3,0,0,0,3,7.24v5.85A8,8,0,0,0,6.09,19.4l4.08,3.18A2,2,0,0,0,11.4,23h1.2a2,2,0,0,0,1.23-.42l4.08-3.18A8,8,0,0,0,21,13.09V7.24A3,3,0,0,0,20.12,5.12Zm-1.12,8a5.93,5.93,0,0,1-2.32,4.73L12.6,21H11.4L7.32,17.82A5.93,5.93,0,0,1,5,13.09V7.24a1,1,0,0,1,.29-.7L8.83,3h6.34l3.54,3.54a1,1,0,0,1,.29.7ZM16.08,9.26l-2.6-.2-1-2.43a.5.5,0,0,0-.92,0l-1,2.43-2.6.2a.51.51,0,0,0-.29.88l2,1.7L9,14.38a.5.5,0,0,0,.74.55L12,13.56l2.25,1.37a.5.5,0,0,0,.74-.55l-.61-2.54,2-1.7A.51.51,0,0,0,16.08,9.26Z',
  verified:
    'M21.61,13.16a3.2,3.2,0,0,1,0-2.32l.17-.46a3.27,3.27,0,0,0-1.71-4.15L19.62,6A3.31,3.31,0,0,1,18,4.38l-.2-.45a3.27,3.27,0,0,0-4.15-1.71l-.46.17a3.2,3.2,0,0,1-2.32,0l-.46-.17A3.27,3.27,0,0,0,6.23,3.93L6,4.38A3.31,3.31,0,0,1,4.38,6l-.45.2a3.27,3.27,0,0,0-1.71,4.15l.17.46a3.2,3.2,0,0,1,0,2.32l-.17.46a3.27,3.27,0,0,0,1.71,4.15l.45.2A3.31,3.31,0,0,1,6,19.62l.2.45a3.27,3.27,0,0,0,4.15,1.71l.46-.17a3.2,3.2,0,0,1,2.32,0l.46.17a3.27,3.27,0,0,0,4.15-1.71l.2-.45A3.31,3.31,0,0,1,19.62,18l.45-.2a3.27,3.27,0,0,0,1.71-4.15ZM16.35,9.73l-5.62,5.62a.48.48,0,0,1-.7,0L7.65,13a.5.5,0,0,1,0-.71l.53-.53a.48.48,0,0,1,.7,0l1.5,1.49,4.74-4.74a.5.5,0,0,1,.7,0l.53.53A.5.5,0,0,1,16.35,9.73Z',
  verified_outlined:
    'M14.79,4a1.25,1.25,0,0,1,1.15.74l.21.46a5.3,5.3,0,0,0,2.66,2.66l.44.19a1.29,1.29,0,0,1,.66,1.64l-.16.43a5.16,5.16,0,0,0,0,3.73l.17.49a1.25,1.25,0,0,1-.65,1.6l-.46.21a5.3,5.3,0,0,0-2.66,2.66l-.19.44a1.27,1.27,0,0,1-1.16.75,1.45,1.45,0,0,1-.48-.09l-.43-.16A5.06,5.06,0,0,0,12,19.4a5,5,0,0,0-1.85.34l-.49.17a1.17,1.17,0,0,1-.45.09,1.25,1.25,0,0,1-1.15-.74l-.21-.46a5.3,5.3,0,0,0-2.66-2.66L4.75,16a1.29,1.29,0,0,1-.66-1.64l.16-.43a5.16,5.16,0,0,0,0-3.73l-.17-.49a1.25,1.25,0,0,1,.65-1.6l.46-.21A5.3,5.3,0,0,0,7.86,5.19l.19-.44A1.27,1.27,0,0,1,9.21,4a1.45,1.45,0,0,1,.48.09l.43.16A5.06,5.06,0,0,0,12,4.6a5,5,0,0,0,1.85-.34l.49-.17A1.17,1.17,0,0,1,14.79,4m0-2a3.23,3.23,0,0,0-1.17.22l-.46.17A3.3,3.3,0,0,1,12,2.6a3.3,3.3,0,0,1-1.16-.21l-.46-.17A3.26,3.26,0,0,0,6.23,3.93L6,4.38A3.31,3.31,0,0,1,4.38,6l-.45.2a3.27,3.27,0,0,0-1.71,4.15l.17.46a3.2,3.2,0,0,1,0,2.32l-.17.46a3.27,3.27,0,0,0,1.71,4.15l.45.2A3.31,3.31,0,0,1,6,19.62l.2.45a3.26,3.26,0,0,0,4.15,1.71l.46-.17a3.31,3.31,0,0,1,2.32,0l.46.17a3.26,3.26,0,0,0,4.15-1.71l.2-.45A3.31,3.31,0,0,1,19.62,18l.45-.2a3.27,3.27,0,0,0,1.71-4.15l-.17-.46a3.2,3.2,0,0,1,0-2.32l.17-.46a3.27,3.27,0,0,0-1.71-4.15L19.62,6A3.31,3.31,0,0,1,18,4.38l-.2-.45a3.26,3.26,0,0,0-3-1.93Zm.68,6.35a.5.5,0,0,0-.35.14l-4.74,4.74-1.5-1.49a.48.48,0,0,0-.7,0l-.53.53a.5.5,0,0,0,0,.71L10,15.35a.47.47,0,0,0,.35.15.49.49,0,0,0,.35-.15l5.62-5.62a.5.5,0,0,0,0-.71l-.53-.53a.5.5,0,0,0-.35-.14Z',
  appstore:
    'M4.2,19l-0.1,0.1c-0.4,0.6-0.2,1.4,0.4,1.7s1.4,0.2,1.7-0.4c0,0,0.1-0.1,0.1-0.1l1.2-2.1h-2C5,18.2,4.5,18.5,4.2,19z M11.7,14.5h-2l5.6-9.6c0.4-0.6,0.2-1.4-0.4-1.7s-1.4-0.2-1.7,0.4c0,0-0.1,0.1-0.1,0.1L12,5.6l-1.1-1.9C10.6,3,9.8,2.8,9.2,3.1 S8.3,4.1,8.7,4.8c0,0,0,0.1,0.1,0.1l1.8,3.2l-3.7,6.4H3.2c-0.7,0-1.2,0.6-1.2,1.2C2,16.4,2.6,17,3.2,17h11l-0.9-1.5 C13,14.9,12.4,14.5,11.7,14.5z M20.8,14.5h-3.6l-3-5.2l-0.6,1c-0.5,0.9-0.5,2.1,0,3l4.1,7.1c0.3,0.6,1.1,0.9,1.7,0.6 c0.6-0.3,0.9-1.1,0.6-1.7c0,0,0-0.1-0.1-0.1L18.6,17h2.1c0.7,0,1.2-0.6,1.2-1.2C22,15.1,21.4,14.5,20.8,14.5z',
  behance:
    'M10.28,11.32a2.94,2.94,0,0,0,1-.71,2.78,2.78,0,0,0,.57-1.83,3.17,3.17,0,0,0-.57-1.9A3.8,3.8,0,0,0,8,5.56H2.75A.75.75,0,0,0,2,6.31V17.25a.76.76,0,0,0,.75.75H7.6a8.76,8.76,0,0,0,1.76-.17,3.48,3.48,0,0,0,1.41-.62,3.6,3.6,0,0,0,.88-1,3.66,3.66,0,0,0,.55-2,3.24,3.24,0,0,0-.49-1.82A2.76,2.76,0,0,0,10.28,11.32ZM4.87,7.72H7.19a4.73,4.73,0,0,1,1.47.19,1.12,1.12,0,0,1,.66,1.15,1.16,1.16,0,0,1-.5,1.09,2.48,2.48,0,0,1-1.32.31H4.87Zm3.89,8.05A2.73,2.73,0,0,1,7.5,16H4.87V12.5H7.54a3.18,3.18,0,0,1,1.25.22,1.44,1.44,0,0,1,.79,1.41A1.69,1.69,0,0,1,8.76,15.77ZM15.5,7.5h4A.5.5,0,0,0,20,7V6.5a.5.5,0,0,0-.5-.5h-4a.5.5,0,0,0-.5.5V7A.5.5,0,0,0,15.5,7.5Zm6.36,6.24a.5.5,0,0,0,.14-.35,8.44,8.44,0,0,0-.08-1.27,4.32,4.32,0,0,0-.71-1.84A3.62,3.62,0,0,0,19.67,9a5,5,0,0,0-2.09-.42,4.28,4.28,0,0,0-3.19,1.24,4.82,4.82,0,0,0-1.23,3.55,4.31,4.31,0,0,0,1.36,3.57A4.87,4.87,0,0,0,17.66,18,4.31,4.31,0,0,0,21,16.7a3.28,3.28,0,0,0,.8-1.29.24.24,0,0,0,0-.22.23.23,0,0,0-.2-.1H19.78a.51.51,0,0,0-.42.22,1.64,1.64,0,0,1-.34.39,2,2,0,0,1-1.3.41,2.29,2.29,0,0,1-1.28-.34,2.09,2.09,0,0,1-.93-1.88h6A.51.51,0,0,0,21.86,13.74Zm-6.3-1.24a2.39,2.39,0,0,1,.62-1.38,1.87,1.87,0,0,1,1.4-.52,2,2,0,0,1,1.38.49,1.94,1.94,0,0,1,.62,1.41Z',
  dribbble:
    'M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm8,10v0a12.31,12.31,0,0,0-3.53-.52,12.49,12.49,0,0,0-2.12.21c-.25-.61-.51-1.2-.79-1.79A16.92,16.92,0,0,0,18,6.69,7.94,7.94,0,0,1,20,12ZM16.87,5.67a15.15,15.15,0,0,1-4,2.91,32.65,32.65,0,0,0-2.79-4.34A8.06,8.06,0,0,1,12,4,8,8,0,0,1,16.87,5.67ZM8.62,4.76A32.16,32.16,0,0,1,11.51,9.2,15.33,15.33,0,0,1,6,10.25a17.34,17.34,0,0,1-1.77-.11A8,8,0,0,1,8.62,4.76ZM4,12c0-.13,0-.25,0-.38a16.32,16.32,0,0,0,2,.13,16.68,16.68,0,0,0,6.19-1.21c.24.5.48,1,.69,1.52a12.22,12.22,0,0,0-6.82,5.28A8,8,0,0,1,4,12Zm3.18,6.37a10.71,10.71,0,0,1,6.23-4.91,29.83,29.83,0,0,1,1.49,6,7.93,7.93,0,0,1-7.72-1.08Zm9.13.36a32.7,32.7,0,0,0-1.42-5.61A11.88,11.88,0,0,1,16.47,13a10.73,10.73,0,0,1,3.38.55A8,8,0,0,1,16.31,18.73Z',
  dropbox:
    'M18.39,8.49l2.53,3.1a.37.37,0,0,1,.08.22V12a.34.34,0,0,1-.16.29l-5.21,3.48a.5.5,0,0,1-.67-.1l-3-3.76,5.32-3.44L12,5l2.85-2.85A.49.49,0,0,1,15.21,2h.28a.5.5,0,0,1,.27.08l5.08,3.29a.34.34,0,0,1,.16.29v.07a.36.36,0,0,1-.1.25ZM15,18H15a.52.52,0,0,1-.39-.18L12,14.65,9.43,17.82A.52.52,0,0,1,9,18H9a.5.5,0,0,1-.27-.08L6,16.19v1.89a1,1,0,0,0,.46.84L11,21.84a1,1,0,0,0,.54.16h1a1,1,0,0,0,.54-.16l4.52-2.92a1,1,0,0,0,.46-.84V16.19l-2.69,1.73A.5.5,0,0,1,15,18ZM12,5,9.15,2.15A.49.49,0,0,0,8.79,2H8.51a.5.5,0,0,0-.27.08L3.16,5.37A.34.34,0,0,0,3,5.66v.07A.36.36,0,0,0,3.1,6L5.61,8.49l-2.53,3.1a.37.37,0,0,0-.08.22V12a.34.34,0,0,0,.16.29l5.21,3.38A.51.51,0,0,0,9,15.53l3-3.65L6.68,8.44Z',
  facebook:
    'M16.5,6h-3a1,1,0,0,0-1,1v3h4a.35.35,0,0,1,.34.46l-.74,2.2a.5.5,0,0,1-.47.34H12.5v7.5a.5.5,0,0,1-.5.5H9.5a.5.5,0,0,1-.5-.5V13H7.5a.5.5,0,0,1-.5-.5v-2a.5.5,0,0,1,.5-.5H9V7a4,4,0,0,1,4-4h3.5a.5.5,0,0,1,.5.5v2A.5.5,0,0,1,16.5,6Z',
  facebook_square:
    'M21,5V19a2,2,0,0,1-2,2H15.25a.5.5,0,0,1-.5-.5v-6h2.34a.5.5,0,0,0,.49-.4l.36-1.8a.25.25,0,0,0-.24-.3H14.75V8.75A.25.25,0,0,1,15,8.5h2.5A.5.5,0,0,0,18,8V6.5a.5.5,0,0,0-.5-.5H15a3,3,0,0,0-3,3v3H10.77a.5.5,0,0,0-.5.5V14a.5.5,0,0,0,.5.5H12v6a.5.5,0,0,1-.5.5H5a2,2,0,0,1-2-2V5A2,2,0,0,1,5,3H19A2,2,0,0,1,21,5Z',
  github:
    'M22,12a10,10,0,0,1-6.84,9.48.52.52,0,0,1-.45-.07A.51.51,0,0,1,14.5,21V18.33A4,4,0,0,0,14,16.27.25.25,0,0,1,14,16a.27.27,0,0,1,.2-.14C16.64,15.61,18,14.64,18,12a4.71,4.71,0,0,0-1.19-3.36A4.3,4.3,0,0,0,17,7.44a4.33,4.33,0,0,0-.15-1.08A.49.49,0,0,0,16.32,6a4.45,4.45,0,0,0-2.44,1.18,9.91,9.91,0,0,0-3.76,0A4.45,4.45,0,0,0,7.68,6a.49.49,0,0,0-.53.36A4.33,4.33,0,0,0,7,7.44a4.3,4.3,0,0,0,.19,1.2A4.71,4.71,0,0,0,6,12c0,2.78,1.51,3.71,4.28,3.94a3.71,3.71,0,0,0-.69,1.53v0s0,.07,0,.12a.68.68,0,0,0,0,.14.59.59,0,0,1-.64.51h0a1,1,0,0,1-.43-.12A5.34,5.34,0,0,1,7.1,16.86a10.09,10.09,0,0,0-.8-.81,2.74,2.74,0,0,0-.59-.39.49.49,0,0,0-.48,0,.5.5,0,0,0-.23.42v.06a.5.5,0,0,0,.23.42,4.54,4.54,0,0,1,.92,1.05,6.61,6.61,0,0,0,1.59,1.72,2.48,2.48,0,0,0,1.4.42l.36,0V21a.51.51,0,0,1-.21.41.52.52,0,0,1-.45.07A10,10,0,1,1,22,12Z',
  gmail:
    'M22,6a2,2,0,0,0-2-2H4A2,2,0,0,0,2,6V18a2,2,0,0,0,2,2H20a2,2,0,0,0,2-2ZM19.5,18h-1a.5.5,0,0,1-.5-.5V8.37l-5.58,4.47A.81.81,0,0,1,12,13h-.18a.81.81,0,0,1-.47-.16L6,8.59V17.5a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5V6.5A.5.5,0,0,1,4.5,6H5.61a1,1,0,0,1,.62.22l5.63,4.5,5.64-4.5A1,1,0,0,1,18.12,6H19.5a.5.5,0,0,1,.5.5v11A.5.5,0,0,1,19.5,18Z',
  gmail_outlined:
    'M22,6a2,2,0,0,0-2-2H4A2,2,0,0,0,2,6V18a2,2,0,0,0,2,2H20a2,2,0,0,0,2-2ZM17.1,6,12,10.08,6.9,6ZM19,18H5V8.32l6.21,5a1,1,0,0,0,.62.22h.35a1,1,0,0,0,.62-.22l6.2-5Z',
  google:
    'M21,11.79C21,15.94,18.79,21,12.13,21A9.07,9.07,0,0,1,3,12a9.07,9.07,0,0,1,9.13-9,9.22,9.22,0,0,1,5.7,2,.52.52,0,0,1,.19.37.55.55,0,0,1-.16.39A26.4,26.4,0,0,0,16,7.64a.49.49,0,0,1-.66.06,5.11,5.11,0,0,0-3.2-1,5.35,5.35,0,0,0,0,10.7c3,0,4.27-1.28,4.93-3.55H12.5a.5.5,0,0,1-.5-.5V10.7a.5.5,0,0,1,.5-.5h8a.45.45,0,0,1,.45.4A11.56,11.56,0,0,1,21,11.79Z',
  google_drive:
    'M21.77,15.75,19.34,20a2,2,0,0,1-1.74,1H8.4A.5.5,0,0,1,8,20.25L10.37,16a2,2,0,0,1,1.74-1h9.23A.5.5,0,0,1,21.77,15.75Zm-11.54-5.7L7.77,5.78a.49.49,0,0,0-.86,0L2.2,14a1.53,1.53,0,0,0-.2.75v.22a1.53,1.53,0,0,0,.2.75l2.53,4.42a.51.51,0,0,0,.87,0L10.23,12A2,2,0,0,0,10.23,10.05ZM13.75,12a2,2,0,0,0,1.73,1h4.89a.5.5,0,0,0,.44-.75L16.1,4a2,2,0,0,0-1.74-1H9.47A.5.5,0,0,0,9,3.75Z',
  google_play:
    'M16.6,15.7l5-2.9c0.2-0.1,0.4-0.4,0.4-0.6v-0.3c0-0.3-0.1-0.5-0.4-0.6l-5-2.9L13.3,12L16.6,15.7z M12.5,11.1l3-3.4L4.4,1.1 C4.2,1,4,1,3.8,1.1L12.5,11.1z M12.5,13L3.8,23c0.2,0.1,0.4,0.1,0.6,0l11.1-6.5L12.5,13z M3,2.1v19.8l8.7-9.9L3,2.1z',
  hangouts:
    'M11.5,2C6.8,2,3,5.8,3,10.5c0,4.5,3.5,8.2,8,8.5v2.5c0,0.3,0.3,0.6,0.7,0.5c4.8-1.7,8.3-5.4,8.3-11.5 C20,5.8,16.2,2,11.5,2z M10.8,11.8c0,1.5-1,2.7-2.4,2.9c-0.1,0-0.3,0-0.4-0.1s-0.2-0.2-0.2-0.4v-0.5c0-0.2,0.2-0.4,0.4-0.5 c0.6-0.2,1.1-0.8,1.1-1.5H7.5c-0.3,0-0.5-0.2-0.5-0.5V8.5C7,8.2,7.2,8,7.5,8h2.8c0.3,0,0.5,0.2,0.5,0.5V11.8z M16,11.8 c0,1.5-1,2.7-2.4,2.9c-0.1,0-0.3,0-0.4-0.1S13,14.4,13,14.2v-0.5c0-0.2,0.2-0.4,0.4-0.5c0.6-0.2,1.1-0.8,1.1-1.5h-1.8 c-0.3,0-0.5-0.2-0.5-0.5V8.5c0-0.3,0.2-0.5,0.5-0.5h2.8C15.8,8,16,8.2,16,8.5V11.8z',
  hangouts_outlined:
    'M11.5,2C6.8,2,3,5.8,3,10.5c0,4.5,3.5,8.2,8,8.5v2.5c0,0.3,0.2,0.5,0.5,0.5c0.1,0,0.1,0,0.2,0 c4.8-1.7,8.3-5.4,8.3-11.5C20,5.8,16.2,2,11.5,2z M13,19.2V19v-1.4c0-0.3-0.2-0.5-0.5-0.5L11.1,17C7.7,16.8,5,13.9,5,10.5 C5,6.9,7.9,4,11.5,4S18,6.9,18,10.5C18,15.2,15.6,17.8,13,19.2z M10.2,8H7.5C7.2,8,7,8.2,7,8.5v2.8c0,0.3,0.2,0.5,0.5,0.5h1.8 c0,0.7-0.5,1.3-1.1,1.5c-0.2,0.1-0.4,0.3-0.4,0.5v0.5c0,0.2,0.1,0.3,0.2,0.4s0.3,0.1,0.4,0.1c1.4-0.3,2.4-1.5,2.4-2.9V8.5 C10.8,8.2,10.5,8,10.2,8z M15.5,8h-2.8c-0.3,0-0.5,0.2-0.5,0.5v2.8c0,0.3,0.2,0.5,0.5,0.5h1.8c0,0.7-0.5,1.3-1.1,1.5 c-0.2,0.1-0.4,0.3-0.4,0.5v0.5c0,0.2,0.1,0.3,0.2,0.4s0.3,0.1,0.4,0.1c1.4-0.3,2.4-1.5,2.4-2.9V8.5C16,8.2,15.8,8,15.5,8z',
  instagram:
    'M16,3H8A5,5,0,0,0,3,8v8a5,5,0,0,0,5,5h8a5,5,0,0,0,5-5V8A5,5,0,0,0,16,3Zm3.25,13A3.26,3.26,0,0,1,16,19.25H8A3.26,3.26,0,0,1,4.75,16V8A3.26,3.26,0,0,1,8,4.75h8A3.26,3.26,0,0,1,19.25,8Zm-1.5-8.75a1,1,0,1,1-1-1A1,1,0,0,1,17.75,7.25ZM12,7.5A4.5,4.5,0,1,0,16.5,12,4.49,4.49,0,0,0,12,7.5Zm0,7.25A2.75,2.75,0,1,1,14.75,12,2.75,2.75,0,0,1,12,14.75Z',
  linkedin:
    'M7.5,9.5v10A.5.5,0,0,1,7,20H4.5a.5.5,0,0,1-.5-.5V9.5A.5.5,0,0,1,4.5,9H7A.5.5,0,0,1,7.5,9.5ZM21,13.41a4.61,4.61,0,0,0-3.55-4.56A4.46,4.46,0,0,0,13.5,9.92V9.5A.5.5,0,0,0,13,9H10.5a.5.5,0,0,0-.5.5v10a.5.5,0,0,0,.5.5H13a.5.5,0,0,0,.5-.5V13.86a2.07,2.07,0,0,1,1.66-2.08,2,2,0,0,1,2.34,2V19.5a.5.5,0,0,0,.5.5h2.5a.5.5,0,0,0,.5-.5ZM5.5,3a2,2,0,1,0,2,2A2,2,0,0,0,5.5,3Z',
  linkedin_square:
    'M19,3H5A2,2,0,0,0,3,5V19a2,2,0,0,0,2,2H19a2,2,0,0,0,2-2V5A2,2,0,0,0,19,3ZM8.5,17.5A.5.5,0,0,1,8,18H6.5a.5.5,0,0,1-.5-.5v-7a.5.5,0,0,1,.5-.5H8a.5.5,0,0,1,.5.5ZM7.25,9a1.5,1.5,0,1,1,1.5-1.5A1.5,1.5,0,0,1,7.25,9ZM18,17.5a.5.5,0,0,1-.5.5H16a.5.5,0,0,1-.5-.5V13.75a1.5,1.5,0,0,0-3,0V17.5a.5.5,0,0,1-.5.5H10.5a.5.5,0,0,1-.5-.5v-7a.5.5,0,0,1,.5-.5H12a.5.5,0,0,1,.5.5v.6a3,3,0,0,1,2.78-1.34A3.1,3.1,0,0,1,18,12.9Z',
  medium:
    'M21.85,19.15a.5.5,0,0,1-.35.85h-7a.5.5,0,0,1-.35-.85l1.56-1.57a1,1,0,0,0,.29-.7V8.81a.11.11,0,0,0-.08-.1.11.11,0,0,0-.11.06l-4.56,11a.35.35,0,0,1-.32.22h-.18a.35.35,0,0,1-.33-.22L5.69,8.15a.1.1,0,0,0-.11-.06.1.1,0,0,0-.08.1v8.19a1,1,0,0,0,.29.7l2.06,2.07A.5.5,0,0,1,7.5,20h-5a.5.5,0,0,1-.35-.85l2.06-2.07a1,1,0,0,0,.29-.7V7.62a1,1,0,0,0-.29-.7L2.15,4.85A.5.5,0,0,1,2.5,4H7.66a.5.5,0,0,1,.47.31l4.18,10.28L16.57,4.31A.5.5,0,0,1,17,4H21.5a.5.5,0,0,1,.35.85L20.29,6.42a1,1,0,0,0-.29.7v9.76a1,1,0,0,0,.29.7Z',
  medium_square:
    'M21,5a2,2,0,0,0-2-2H5A2,2,0,0,0,3,5V19a2,2,0,0,0,2,2H19a2,2,0,0,0,2-2ZM16.94,15.57a.49.49,0,0,0,.15.36l.67.64a.25.25,0,0,1,0,.27.24.24,0,0,1-.23.16h-3.9a.25.25,0,0,1-.23-.16.25.25,0,0,1,.06-.27l.68-.66a.49.49,0,0,0,.15-.36V10.66a.1.1,0,0,0-.08-.1.09.09,0,0,0-.11.06l-2.47,6.26a.16.16,0,0,1-.14.1h-.16a.14.14,0,0,1-.14-.09L8.33,10.55a.1.1,0,0,0-.11-.06.1.1,0,0,0-.08.1v4.28a.51.51,0,0,0,.11.31l1.14,1.39a.25.25,0,0,1,0,.27A.26.26,0,0,1,9.2,17H6.36a.27.27,0,0,1-.23-.14.27.27,0,0,1,0-.27l1.14-1.38a.49.49,0,0,0,.11-.31V9.38a.47.47,0,0,0-.12-.32l-1-1.18a.26.26,0,0,1,0-.27.26.26,0,0,1,.22-.14h2.8a.49.49,0,0,1,.45.29l2.56,5.61,2.23-5.59A.5.5,0,0,1,15,7.47h2.57a.25.25,0,0,1,.23.16.25.25,0,0,1-.06.27l-.66.63a.52.52,0,0,0-.16.36Z',
  messenger:
    'M12,2A9.76,9.76,0,0,0,2,11.5,9.22,9.22,0,0,0,4.71,18a1,1,0,0,1,.29.7V21a1,1,0,0,0,.45.84h0a1,1,0,0,0,.94.09L9.1,20.79a1,1,0,0,1,.61-.05A10.18,10.18,0,0,0,12,21a9.76,9.76,0,0,0,10-9.5A9.76,9.76,0,0,0,12,2Zm5.5,7.21-2.62,4a1.49,1.49,0,0,1-1.25.68H13.2a1.49,1.49,0,0,1-.91-.31L10.1,11.89l-3,2.25a.43.43,0,0,1-.56,0,.5.5,0,0,1-.07-.63L9.1,9.54a1.51,1.51,0,0,1,1.26-.67h.21a1.48,1.48,0,0,1,.91.3l2.3,1.76,3.06-2.34a.43.43,0,0,1,.56,0l0,0A.43.43,0,0,1,17.5,9.21Z',
  messenger_outlined:
    'M12,2A9.76,9.76,0,0,0,2,11.5,9.22,9.22,0,0,0,4.71,18a1,1,0,0,1,.29.7V21a1,1,0,0,0,.45.84h0A1,1,0,0,0,6,22a1,1,0,0,0,.38-.08L9.1,20.79a1.09,1.09,0,0,1,.39-.08.83.83,0,0,1,.22,0A10.18,10.18,0,0,0,12,21a9.76,9.76,0,0,0,10-9.5A9.76,9.76,0,0,0,12,2Zm0,17a8.47,8.47,0,0,1-1.85-.21,3,3,0,0,0-.66-.08A2.9,2.9,0,0,0,8.33,19L7,19.51V18.7a3,3,0,0,0-.87-2.11A7.24,7.24,0,0,1,4,11.5,7.77,7.77,0,0,1,12,4a7.77,7.77,0,0,1,8,7.5A7.77,7.77,0,0,1,12,19ZM17.4,8.62a.43.43,0,0,0-.56,0l-3.06,2.34-2.3-1.76a1.48,1.48,0,0,0-.91-.3h-.21a1.51,1.51,0,0,0-1.26.67L6.51,13.48a.5.5,0,0,0,.07.63.43.43,0,0,0,.56,0l3-2.25,2.19,1.67a1.49,1.49,0,0,0,.91.31h.43a1.49,1.49,0,0,0,1.25-.68l2.62-4a.43.43,0,0,0-.06-.55Z',
  pinterest:
    'M20,9c0,4.19-2.41,7-6,7a4.56,4.56,0,0,1-3.42-1.27l-.66,3.11A8.24,8.24,0,0,1,9.09,20l-1,1.78a.35.35,0,0,1-.31.18.27.27,0,0,1-.2-.09.3.3,0,0,1-.08-.2l.08-2a8.87,8.87,0,0,1,.17-1.36L9.18,11.7A13.06,13.06,0,0,1,9,9.43C9,8.86,9.09,6,10.79,6a1.24,1.24,0,0,1,1,.44A2.07,2.07,0,0,1,12,8.18l-.76,3.55A2.52,2.52,0,0,0,14,14c2.95,0,4-2.58,4-5,0-2.9-2.52-5-6-5C8.69,4,6,6.24,6,9a4.26,4.26,0,0,0,1.15,2.85.5.5,0,0,1,.12.44L7,13.46a.49.49,0,0,1-.34.38.49.49,0,0,1-.49-.12A6.48,6.48,0,0,1,4,9c0-3.86,3.59-7,8-7S20,5,20,9Z',
  pinterest_circle:
    'M22,12A10,10,0,0,1,12,22a9.82,9.82,0,0,1-2.25-.26.48.48,0,0,1-.34-.28.49.49,0,0,1,0-.44l.15-.29a5.91,5.91,0,0,0,.56-1.54l.66-3.1a3.94,3.94,0,0,0,2.92,1.07c3.09,0,5.17-2.42,5.17-6,0-3.43-3-6-6.88-6s-6.88,2.7-6.88,6a5.48,5.48,0,0,0,1.78,4,.49.49,0,0,0,.49.12.47.47,0,0,0,.34-.37l.2-.9a.52.52,0,0,0-.11-.43,3.61,3.61,0,0,1-.94-2.38c0-2.35,2.29-4.26,5.12-4.26s5.12,1.79,5.12,4.26c0,2.07-.89,4.27-3.41,4.27a2.14,2.14,0,0,1-2.36-1.93l.65-3a1.8,1.8,0,0,0-.21-1.51A1.06,1.06,0,0,0,11,8.55c-1.47,0-1.55,2.47-1.55,3a10.42,10.42,0,0,0,.16,1.94L8.33,19.26a5.74,5.74,0,0,0-.13,1.49.34.34,0,0,1-.14.28.31.31,0,0,1-.31,0A10,10,0,1,1,22,12Z',
  reddit:
    'M22,11.5A2.5,2.5,0,0,0,19.5,9,2.46,2.46,0,0,0,18,9.53,11.4,11.4,0,0,0,12.93,8l.62-3.55a.24.24,0,0,1,.24-.21h.07L17,5.2a2,2,0,1,0,.42-1.44l-3.16-.92a2,2,0,0,0-.49-.07,1.75,1.75,0,0,0-1.72,1.45L11.41,8A11.52,11.52,0,0,0,6,9.53,2.46,2.46,0,0,0,4.5,9,2.49,2.49,0,0,0,3,13.52,3.88,3.88,0,0,0,3,14c0,3.31,4,6,9,6s9-2.69,9-6a3.88,3.88,0,0,0,0-.48A2.5,2.5,0,0,0,22,11.5Zm-15,1A1.5,1.5,0,1,1,8.5,14,1.5,1.5,0,0,1,7,12.5Zm8.21,4.44a.51.51,0,0,1-.25.32,6.29,6.29,0,0,1-5.92,0,.51.51,0,0,1-.25-.32.52.52,0,0,1,.08-.4l.29-.41A.49.49,0,0,1,9.8,16a5.07,5.07,0,0,0,4.4,0,.49.49,0,0,1,.64.16l.29.41A.52.52,0,0,1,15.21,16.94ZM15.5,14A1.5,1.5,0,1,1,17,12.5,1.5,1.5,0,0,1,15.5,14Z',
  rss:
    'M19,3H5A2,2,0,0,0,3,5V19a2,2,0,0,0,2,2H19a2,2,0,0,0,2-2V5A2,2,0,0,0,19,3ZM7.75,18A1.75,1.75,0,1,1,9.5,16.25,1.75,1.75,0,0,1,7.75,18Zm6.1-.16a.52.52,0,0,1-.37.16h-1a.5.5,0,0,1-.5-.46A6,6,0,0,0,6.46,12a.5.5,0,0,1-.46-.5v-1a.52.52,0,0,1,.16-.37A.51.51,0,0,1,6.53,10,8,8,0,0,1,14,17.47.51.51,0,0,1,13.85,17.84Zm4,0a.51.51,0,0,1-.36.15h-1a.5.5,0,0,1-.5-.48A10,10,0,0,0,6.48,8,.5.5,0,0,1,6,7.51v-1a.51.51,0,0,1,.15-.36A.57.57,0,0,1,6.52,6,12,12,0,0,1,18,17.48.57.57,0,0,1,17.85,17.85Z',
  skype:
    'M20.91,13.22A9.73,9.73,0,0,0,21,12a9,9,0,0,0-9-9,9.73,9.73,0,0,0-1.22.09,5.5,5.5,0,0,0-7.69,7.69A9.73,9.73,0,0,0,3,12a9,9,0,0,0,9,9,9.73,9.73,0,0,0,1.22-.09,5.5,5.5,0,0,0,7.69-7.69Zm-4.51,3a3.8,3.8,0,0,1-1.73,1.32A6.77,6.77,0,0,1,12,18,6.44,6.44,0,0,1,9,17.35a3.86,3.86,0,0,1-1.4-1.26A2.72,2.72,0,0,1,7,14.53a1.07,1.07,0,0,1,.36-.81,1.21,1.21,0,0,1,.89-.34,1.11,1.11,0,0,1,.75.27,2,2,0,0,1,.5.74,3.91,3.91,0,0,0,.49.87,1.76,1.76,0,0,0,.71.56,2.89,2.89,0,0,0,1.24.23,3,3,0,0,0,1.72-.46,1.24,1.24,0,0,0,.63-1.07A1.08,1.08,0,0,0,14,13.7a2.35,2.35,0,0,0-.91-.51q-.59-.18-1.56-.39a12,12,0,0,1-2.24-.68A3.7,3.7,0,0,1,7.79,11a2.72,2.72,0,0,1-.56-1.73,2.87,2.87,0,0,1,.58-1.75A3.63,3.63,0,0,1,9.47,6.4,7.05,7.05,0,0,1,12,6a6.56,6.56,0,0,1,2,.27A4.15,4.15,0,0,1,15.37,7a2.87,2.87,0,0,1,.83.94,2,2,0,0,1,.27,1,1.25,1.25,0,0,1-1.23,1.21,1,1,0,0,1-.73-.24A2.59,2.59,0,0,1,14,9.19a2.91,2.91,0,0,0-.77-1,2.37,2.37,0,0,0-1.42-.33,2.62,2.62,0,0,0-1.48.37,1,1,0,0,0-.53.82A.8.8,0,0,0,10,9.6a1.5,1.5,0,0,0,.51.39,4.12,4.12,0,0,0,.69.27l1.18.29a18,18,0,0,1,1.88.5,6,6,0,0,1,1.46.67,3,3,0,0,1,1,1A3.13,3.13,0,0,1,17,14.27,3.29,3.29,0,0,1,16.4,16.2Z',
  slack:
    'M12.75,9.38V4.12a1.87,1.87,0,0,1,1.87-1.87h0A1.87,1.87,0,0,1,16.5,4.12V9.38a1.87,1.87,0,0,1-1.87,1.87h0A1.87,1.87,0,0,1,12.75,9.38Zm7.13,3.37H14.62a1.87,1.87,0,0,0-1.87,1.87h0a1.87,1.87,0,0,0,1.87,1.87h5.26a1.87,1.87,0,0,0,1.87-1.87h0A1.87,1.87,0,0,0,19.88,12.75Zm-10.5,0h0A1.87,1.87,0,0,0,7.5,14.62v5.26a1.87,1.87,0,0,0,1.87,1.87h0a1.87,1.87,0,0,0,1.87-1.87V14.62A1.87,1.87,0,0,0,9.38,12.75Zm0-5.25H4.12A1.87,1.87,0,0,0,2.25,9.37h0a1.87,1.87,0,0,0,1.87,1.87H9.38a1.87,1.87,0,0,0,1.87-1.87h0A1.87,1.87,0,0,0,9.38,7.5Zm0-5.25h0A1.87,1.87,0,0,0,7.5,4.12h0A1.87,1.87,0,0,0,9.37,6h1.38a.5.5,0,0,0,.5-.5V4.12A1.87,1.87,0,0,0,9.38,2.25ZM19.88,7.5h0A1.87,1.87,0,0,0,18,9.37v1.38a.5.5,0,0,0,.5.5h1.38a1.87,1.87,0,0,0,1.87-1.87h0A1.87,1.87,0,0,0,19.88,7.5ZM14.63,18H13.25a.5.5,0,0,0-.5.5v1.38a1.87,1.87,0,0,0,1.87,1.87h0a1.87,1.87,0,0,0,1.87-1.87h0A1.87,1.87,0,0,0,14.63,18ZM5.5,12.75H4.12a1.87,1.87,0,0,0-1.87,1.87h0A1.87,1.87,0,0,0,4.12,16.5h0A1.87,1.87,0,0,0,6,14.63V13.25A.5.5,0,0,0,5.5,12.75Z',
  snapchat:
    'M22,17a1,1,0,0,1-1,1c-1.3,0-2,.41-2,1a1,1,0,0,1-1,1c-.83,0-1-.47-1.77-.47a1.28,1.28,0,0,0-.65.28,6.54,6.54,0,0,1-.84.52,5,5,0,0,1-2,.62,5.35,5.35,0,0,1-1.46,0,5,5,0,0,1-2-.62,6.54,6.54,0,0,1-.84-.52,1.28,1.28,0,0,0-.65-.28C7,19.53,6.83,20,6,20a1,1,0,0,1-1-1c0-.59-.7-1-2-1a1,1,0,0,1,0-2,3.34,3.34,0,0,0,3-2.71.49.49,0,0,0-.27-.59,14.89,14.89,0,0,1-2.13-1.2A1.36,1.36,0,1,1,5.13,9.24l.5.32a.24.24,0,0,0,.26,0A.25.25,0,0,0,6,9.33C6,9.22,6,9.11,6,9A6,6,0,0,1,18,9c0,.11,0,.22,0,.33a.25.25,0,0,0,.12.23.24.24,0,0,0,.26,0l.5-.32a1.36,1.36,0,0,1,1.52,2.26,14.89,14.89,0,0,1-2.13,1.2.49.49,0,0,0-.27.59A3.34,3.34,0,0,0,21,16,1,1,0,0,1,22,17Z',
  snapchat_outlined:
    'M21,16c-2.33,0-3-2.7-3-2.85,0-.85,3-1.09,3-2.78A1.36,1.36,0,0,0,19.63,9c-.73,0-1.11.58-1.39.58A.25.25,0,0,1,18,9.34S18,9.2,18,9A6,6,0,0,0,6,9c0,.2,0,.31,0,.34a.25.25,0,0,1-.25.25C5.5,9.59,5.08,9,4.37,9A1.36,1.36,0,0,0,3,10.37c0,1.69,3,2,3,2.78C6,13.27,5.33,16,3,16a1,1,0,0,0,0,2c1.3,0,2,.41,2,1a1,1,0,0,0,1,1c.83,0,1-.47,1.77-.47a1.28,1.28,0,0,1,.65.28,6.54,6.54,0,0,0,.84.52,5,5,0,0,0,2,.62,5.35,5.35,0,0,0,1.46,0,5,5,0,0,0,2-.62,6.54,6.54,0,0,0,.84-.52,1.28,1.28,0,0,1,.65-.28c.73,0,.94.47,1.77.47a1,1,0,0,0,1-1c0-.59.7-1,2-1a1,1,0,0,0,0-2Zm-3.36.77,0,.05a.21.21,0,0,1-.18.12C14.93,17.19,14.23,19,12,19s-2.93-1.81-5.44-2.06a.21.21,0,0,1-.18-.12l0-.05a.23.23,0,0,1,0-.25A5.76,5.76,0,0,0,8,13.16V9a4,4,0,0,1,8,0v4.16a5.63,5.63,0,0,0,1.6,3.36A.23.23,0,0,1,17.64,16.77Z',
  steam:
    'M22,12c0-5.5-4.5-10-10.1-9.9c-4.9,0-9,3.6-9.8,8.4c0,0.2,0.1,0.4,0.3,0.5l4.4,2c0.5-0.6,1.2-0.9,1.9-1 c0.2,0,0.3-0.1,0.4-0.2l0.8-1.2c0.1-0.1,0.1-0.2,0.1-0.3c0-0.1,0-0.2,0-0.2c0-2.2,1.8-4,4-4s4,1.8,4,4s-1.8,4-4,4h-0.2 c-0.1,0-0.2,0-0.3,0.1l-1.2,0.8C12.1,15,12,15.1,12,15.3c-0.1,1.7-1.6,2.9-3.2,2.7C7.2,17.9,6,16.6,6,15c0-0.1,0-0.1,0-0.2l-3.5-1.6 c-0.1-0.1-0.3,0-0.3,0.1c0,0,0,0.1,0,0.1c0.8,5.5,5.9,9.2,11.4,8.4C18.4,21.1,22,16.9,22,12z M9,14c0.6,0,1,0.4,1,1s-0.4,1-1,1 s-1-0.4-1-1S8.4,14,9,14z M14,8c1.1,0,2,0.9,2,2s-0.9,2-2,2s-2-0.9-2-2S12.9,8,14,8z',
  telegram:
    'M21.38,3.61l0,0A2,2,0,0,0,20,3a2,2,0,0,0-.78.16L2.62,10.35a1,1,0,0,0-.62,1V12a1,1,0,0,0,.69,1L7,14.44,8.3,18.83a1.78,1.78,0,0,0,1.32,1.25,1.47,1.47,0,0,0,.36,0,1.73,1.73,0,0,0,1.19-.48l1.59-1.5,3.08,2.43a2,2,0,0,0,2.11.22l.33-.17a2.07,2.07,0,0,0,1.09-1.47L22,5.51A2.11,2.11,0,0,0,21.38,3.61ZM17.9,18.87a.59.59,0,0,1-.3.41l-.33.17a.46.46,0,0,1-.21.05.46.46,0,0,1-.29-.11l-3.76-3a.49.49,0,0,0-.65,0l-2.22,2.09a.26.26,0,0,1-.14.05V15a.51.51,0,0,1,.16-.37c3.19-3,5.1-4.68,6.24-5.64a.37.37,0,0,0,.12-.26.32.32,0,0,0-.1-.26h0A.35.35,0,0,0,16,8.37L8.44,13.14a.5.5,0,0,1-.43,0L3.5,11.6,19.77,4.54a.39.39,0,0,1,.18,0,.52.52,0,0,1,.38.18.63.63,0,0,1,.16.55Z',
  tik_tok:
    'M21,7.48v2a.52.52,0,0,1-.16.37.51.51,0,0,1-.37.13A7.91,7.91,0,0,1,16,8.24V16a6,6,0,1,1-6-6,5.07,5.07,0,0,1,.55,0,.49.49,0,0,1,.45.49v2a.48.48,0,0,1-.17.37.49.49,0,0,1-.4.12A3.11,3.11,0,0,0,10,13a3,3,0,1,0,3,3V2.5a.5.5,0,0,1,.5-.5h2a.5.5,0,0,1,.5.45A5,5,0,0,0,20.55,7,.5.5,0,0,1,21,7.48Z',
  twitch:
    'M10.59,20.41,12,19h3.17a2,2,0,0,0,1.42-.59l3.82-3.82A2,2,0,0,0,21,13.17V4a1,1,0,0,0-1-1H5.19a1,1,0,0,0-.93.65l-1,2.67A4.23,4.23,0,0,0,3,7.73V18a1,1,0,0,0,1,1H7v1a1,1,0,0,0,1,1H9.17A2,2,0,0,0,10.59,20.41ZM6,16V5H19v8.17L16.17,16h-4l-2,2H9V16Zm4.5-3h1a.5.5,0,0,0,.5-.5v-4a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5v4A.5.5,0,0,0,10.5,13Zm5,0h1a.5.5,0,0,0,.5-.5v-4a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5v4A.5.5,0,0,0,15.5,13Z',
  twitter:
    'M22,6.45a8,8,0,0,1-1.85,1.78c0,.18,0,.36,0,.55A12.2,12.2,0,0,1,7.78,21a12.24,12.24,0,0,1-5.45-1.26.25.25,0,0,1-.15-.23V19.4a.26.26,0,0,1,.26-.26A8.86,8.86,0,0,0,7.56,17.3a4.37,4.37,0,0,1-3.89-2.56.26.26,0,0,1,.26-.36,4,4,0,0,0,1.52-.14A4.36,4.36,0,0,1,2,10.43a.26.26,0,0,1,.36-.26,4,4,0,0,0,1.58.34A4.28,4.28,0,0,1,2.23,5.57a.54.54,0,0,1,.91-.18,12.36,12.36,0,0,0,8.41,3.88,4,4,0,0,1-.12-1,4.36,4.36,0,0,1,7.51-2.94,8.69,8.69,0,0,0,2.39-.83.17.17,0,0,1,.18,0,.17.17,0,0,1,0,.18,4.37,4.37,0,0,1-1.76,2,8.58,8.58,0,0,0,2-.48.16.16,0,0,1,.18,0A.17.17,0,0,1,22,6.45Z',
  viber:
    'M12,2C5.37,2,3,4.24,3,10.5c0,4.31,1.13,6.72,4,7.8v3.2a.5.5,0,0,0,.5.5h.31a.51.51,0,0,0,.33-.13L11.34,19H12c6.63,0,9-2.24,9-8.5S18.63,2,12,2Zm-.25,6.56a.48.48,0,0,1,.19-.39.46.46,0,0,1,.42-.1,2.74,2.74,0,0,1,2.07,2.07.46.46,0,0,1-.1.42.48.48,0,0,1-.39.19h-.51a.5.5,0,0,1-.48-.36,1.26,1.26,0,0,0-.84-.84.5.5,0,0,1-.36-.48Zm3.68,6a2.11,2.11,0,0,1-1,.83,1.15,1.15,0,0,1-.91,0,11.38,11.38,0,0,1-5.78-5.14,15.26,15.26,0,0,1-.67-1.56A2.14,2.14,0,0,1,7,8.34,1.55,1.55,0,0,1,8.32,6.87l.19,0A.78.78,0,0,1,9,7.08a5.74,5.74,0,0,1,1.23,1.7.58.58,0,0,1-.15.73l-.15.13a.86.86,0,0,0-.32,1.28A4.15,4.15,0,0,0,11.86,13a.62.62,0,0,0,.73-.16l.07-.09c.4-.67,1-.6,1.52-.22l1,.77C15.75,13.7,15.75,14.06,15.43,14.59Zm1.42-4a.5.5,0,0,1-.38.17H16a.51.51,0,0,1-.5-.44A3.74,3.74,0,0,0,12.19,7a.51.51,0,0,1-.44-.5V6a.5.5,0,0,1,.17-.38.47.47,0,0,1,.38-.12A5.26,5.26,0,0,1,17,10.2.47.47,0,0,1,16.85,10.58Z',
  viber_outlined:
    'M12,2C5.37,2,3,4.24,3,10.5c0,4.31,1.13,6.72,4,7.8v3.2a.5.5,0,0,0,.5.5h.31a.51.51,0,0,0,.33-.13L11.34,19H12c6.63,0,9-2.24,9-8.5S18.63,2,12,2Zm0,15h-.61l-.6,0a.51.51,0,0,0-.35.13l-.44.4-1,.9V17.26a.5.5,0,0,0-.32-.47l-1-.37C6.4,15.93,5,15,5,10.5,5,5.34,6.44,4,12,4s7,1.34,7,6.5S17.56,17,12,17Zm3.22-3.69-1-.77c-.54-.38-1.12-.45-1.52.22l-.07.09a.62.62,0,0,1-.73.16,4.15,4.15,0,0,1-2.27-2.09.86.86,0,0,1,.32-1.28l.15-.13a.58.58,0,0,0,.15-.73A5.74,5.74,0,0,0,9,7.08a.78.78,0,0,0-.47-.23l-.19,0A1.55,1.55,0,0,0,7,8.34a2.14,2.14,0,0,0,.06.35,15.26,15.26,0,0,0,.67,1.56,11.38,11.38,0,0,0,5.78,5.14,1.15,1.15,0,0,0,.91,0,2.11,2.11,0,0,0,1-.83C15.75,14.06,15.75,13.7,15.22,13.31ZM12.3,5.53a.47.47,0,0,0-.38.12.5.5,0,0,0-.17.38v.5a.51.51,0,0,0,.44.5,3.74,3.74,0,0,1,3.28,3.28.51.51,0,0,0,.5.44h.5a.5.5,0,0,0,.38-.17A.47.47,0,0,0,17,10.2,5.26,5.26,0,0,0,12.3,5.53Zm-.19,4a1.26,1.26,0,0,1,.84.84.5.5,0,0,0,.48.36h.51a.48.48,0,0,0,.39-.19.46.46,0,0,0,.1-.42,2.74,2.74,0,0,0-2.07-2.07.46.46,0,0,0-.42.1.48.48,0,0,0-.19.39v.51A.5.5,0,0,0,12.11,9.55Z',
  vimeo:
    'M17.08,16.09Q13.17,21,10.46,21c-1.12,0-2.07-1-2.84-3L6.07,12.53c-.57-2-1.18-3-1.85-3a4.2,4.2,0,0,0-1.14.63.47.47,0,0,1-.63-.1l-.34-.42A.45.45,0,0,1,2.16,9C3,8.31,3.82,7.6,4.62,6.9A6.32,6.32,0,0,1,7.47,5.24Q9.69,5,10.22,8.19c.37,2.27.63,3.68.78,4.24q.63,2.82,1.41,2.82c.41,0,1-.61,1.82-1.84a7.2,7.2,0,0,0,1.29-2.78C15.63,9.58,15.19,9,14.23,9a3.73,3.73,0,0,0-.8.1A.3.3,0,0,1,13.1,9,.33.33,0,0,1,13,8.7c1-2.54,2.72-3.76,5.18-3.7,2,.06,2.9,1.3,2.78,3.71C20.91,10.51,19.6,13,17.08,16.09Z',
  vimeo_square:
    'M19.2,3H4.8A1.81,1.81,0,0,0,3,4.8V19.2A1.81,1.81,0,0,0,4.8,21H19.2A1.81,1.81,0,0,0,21,19.2V4.8A1.81,1.81,0,0,0,19.2,3ZM16.11,14.32Q13.23,18,11.23,18c-.82,0-1.52-.75-2.09-2.24L8,11.65c-.42-1.49-.87-2.24-1.36-2.24a3.14,3.14,0,0,0-.84.47.34.34,0,0,1-.47-.07l-.25-.32a.34.34,0,0,1,0-.48C5.73,8.48,6.34,8,6.93,7.42A4.62,4.62,0,0,1,9,6.18c1.09-.11,1.77.63,2,2.21s.46,2.76.57,3.18c.31,1.41.66,2.12,1,2.12s.74-.46,1.34-1.38a5.44,5.44,0,0,0,1-2.09C15,9.43,14.72,9,14,9a3.06,3.06,0,0,0-.59.07A.22.22,0,0,1,13.18,9a.25.25,0,0,1,0-.26A3.75,3.75,0,0,1,16.94,6c1.45,0,2.14,1,2,2.78Q18.9,10.81,16.11,14.32Z',
  vkontakte:
    'M22,17.43a.45.45,0,0,1-.07.39.44.44,0,0,1-.35.18h-3a1,1,0,0,1-.89-.54,8.93,8.93,0,0,0-1.5-2.13A1.05,1.05,0,0,0,15.4,15H15a.94.94,0,0,0-.71.29A1,1,0,0,0,14,16v1a1,1,0,0,1-1,1H11.94C6.73,18,2.42,8.84,2,7.61a0,0,0,0,0,0,0A.44.44,0,0,1,2.44,7h3a1,1,0,0,1,.89.55,27.92,27.92,0,0,0,2.32,3.64A.75.75,0,0,0,10,10.74V8.47a1.51,1.51,0,0,0-.86-1.33.26.26,0,0,1-.06-.4A3.1,3.1,0,0,1,11.28,6H12.5A1.5,1.5,0,0,1,14,7.5V10a1,1,0,0,0,1,1,1,1,0,0,0,.83-.42,22.75,22.75,0,0,0,1.84-3,1,1,0,0,1,.9-.55h3a.41.41,0,0,1,.39.53s0,0,0,0a21.38,21.38,0,0,1-2.45,4.71.75.75,0,0,0,.06.9A13.76,13.76,0,0,1,22,17.36Z',
  whatsapp:
    'M12,2A10,10,0,0,0,3.9,17.86L3,20.6a.49.49,0,0,0,.12.51l0,0a.51.51,0,0,0,.51.13l2.85-.92A10,10,0,1,0,12,2Zm5.82,14.12a2.81,2.81,0,0,1-2,1.41c-.52.11-1.21.2-3.5-.75a12.43,12.43,0,0,1-5-4.4,5.69,5.69,0,0,1-1.19-3,3.21,3.21,0,0,1,1-2.44,1.49,1.49,0,0,1,1-.36l.33,0c.29,0,.44,0,.64.49s.82,2,.89,2.16a.57.57,0,0,1,.05.54,1.85,1.85,0,0,1-.33.46c-.14.17-.29.3-.43.48a.47.47,0,0,0-.12.63,8.94,8.94,0,0,0,1.62,2,7.34,7.34,0,0,0,2.35,1.45.62.62,0,0,0,.7-.11,10,10,0,0,0,.78-1,.56.56,0,0,1,.72-.21c.27.09,1.7.8,2,.94s.49.22.56.34A2.5,2.5,0,0,1,17.82,16.12Z',
  whatsapp_outlined:
    'M12,2A10,10,0,0,0,3.9,17.86L3,20.6a.49.49,0,0,0,.12.51l0,0a.51.51,0,0,0,.36.15l.15,0,2.85-.92A10,10,0,1,0,12,2Zm8,10a8,8,0,1,1-8-8A8,8,0,0,1,20,12Zm-3.07,3.49a2.33,2.33,0,0,1-1.65,1.19c-.44.1-1,.17-3-.63a10.41,10.41,0,0,1-4.2-3.72,4.77,4.77,0,0,1-1-2.54A2.74,2.74,0,0,1,8,7.73a1.26,1.26,0,0,1,.87-.3h.28c.25,0,.37,0,.54.42s.69,1.7.75,1.82a.47.47,0,0,1,0,.46,1.7,1.7,0,0,1-.27.39c-.12.14-.25.25-.37.4a.4.4,0,0,0-.1.53,7.54,7.54,0,0,0,1.37,1.7,6.33,6.33,0,0,0,2,1.23.55.55,0,0,0,.6-.1,7.76,7.76,0,0,0,.65-.88.48.48,0,0,1,.61-.17c.23.07,1.44.67,1.68.79s.42.19.48.29A2,2,0,0,1,16.93,15.49Z',
  youtube:
    'M20,4.49l-1.46-.15a64.12,64.12,0,0,0-13.14,0L4,4.49A3.38,3.38,0,0,0,1,7.9v8.2a3.38,3.38,0,0,0,3,3.41l1.46.15a64.12,64.12,0,0,0,13.14,0L20,19.51a3.38,3.38,0,0,0,3-3.41V7.9A3.38,3.38,0,0,0,20,4.49Zm-5,8.13-3.89,2.6A.75.75,0,0,1,10,14.6V9.4a.75.75,0,0,1,1.17-.62l3.89,2.6A.74.74,0,0,1,15.06,12.62Z',
  youtube_outlined:
    'M20,4.49l-1.46-.15a63.65,63.65,0,0,0-13.14,0L4,4.49A3.38,3.38,0,0,0,1,7.9v8.2a3.38,3.38,0,0,0,3,3.41l1.46.15A62.85,62.85,0,0,0,12,20a62.85,62.85,0,0,0,6.57-.34L20,19.51a3.38,3.38,0,0,0,3-3.41V7.9A3.38,3.38,0,0,0,20,4.49ZM21,16.1a1.38,1.38,0,0,1-1.18,1.42l-1.46.15A61.29,61.29,0,0,1,12,18a61.29,61.29,0,0,1-6.36-.33l-1.46-.15A1.38,1.38,0,0,1,3,16.1V7.9A1.38,1.38,0,0,1,4.18,6.48l1.46-.15a61.45,61.45,0,0,1,12.72,0l1.46.15A1.38,1.38,0,0,1,21,7.9ZM11.17,8.78A.75.75,0,0,0,10,9.4v5.2a.75.75,0,0,0,1.17.62l3.89-2.6a.74.74,0,0,0,0-1.24Z',
  airdrop:
    'M22,13a10,10,0,0,1-2.57,6.69.51.51,0,0,1-.72,0l-.36-.36a.51.51,0,0,1,0-.68,8.5,8.5,0,1,0-12.66,0,.51.51,0,0,1,0,.68l-.36.36a.51.51,0,0,1-.72,0A10,10,0,1,1,22,13ZM12,6A7,7,0,0,0,6.68,17.56a.56.56,0,0,0,.36.17.54.54,0,0,0,.38-.15l.35-.35a.5.5,0,0,0,0-.68,5.5,5.5,0,1,1,8.4,0,.5.5,0,0,0,0,.68l.35.35a.54.54,0,0,0,.38.15.56.56,0,0,0,.36-.17A7,7,0,0,0,12,6Zm2,8.45a.5.5,0,0,0,.05.64l.36.36a.5.5,0,0,0,.39.14.47.47,0,0,0,.36-.2,4,4,0,1,0-6.4,0,.47.47,0,0,0,.36.2.5.5,0,0,0,.39-.14l.36-.36a.5.5,0,0,0,.05-.64,2.5,2.5,0,1,1,4.08,0ZM11,13a1,1,0,1,0,1-1A1,1,0,0,0,11,13Zm1.75,3.22a.75.75,0,0,0-.53-.22h-.44a.75.75,0,0,0-.53.22L7.52,19.94a.51.51,0,0,0,0,.71l.21.2a.47.47,0,0,0,.35.15h7.83a.47.47,0,0,0,.35-.15l.21-.2a.5.5,0,0,0,0-.71Z',
  airplay:
    'M18.48,19.94a.51.51,0,0,1,0,.71l-.21.2a.47.47,0,0,1-.35.15H6.08a.47.47,0,0,1-.35-.15l-.21-.2a.5.5,0,0,1,0-.71l5.73-5.72a.75.75,0,0,1,.53-.22h.44a.75.75,0,0,1,.53.22ZM23,3H1A1,1,0,0,0,0,4V18a1,1,0,0,0,1,1H4l2-2H2V5H22V17H18l2,2h3a1,1,0,0,0,1-1V4A1,1,0,0,0,23,3Z',
  android:
    'M20,9c-0.6,0-1,0.4-1,1v5c0,0.6,0.4,1,1,1s1-0.4,1-1v-5C21,9.4,20.6,9,20,9z M17.5,8h-11C6.2,8,6,8.2,6,8.5V16 c0,0.6,0.4,1,1,1h1v4c0,0.6,0.4,1,1,1s1-0.4,1-1v-4h4v4c0,0.6,0.4,1,1,1s1-0.4,1-1v-4h1c0.6,0,1-0.4,1-1V8.5C18,8.2,17.8,8,17.5,8z M6.7,7h10.6c0.3,0,0.5-0.2,0.5-0.5c0,0,0-0.1,0-0.1c-0.3-0.9-0.8-1.8-1.5-2.5l0.6-1.1c0.1-0.2,0.1-0.5-0.2-0.7S16.2,2,16.1,2.2l0,0 l-0.6,0.9c-2.1-1.5-4.9-1.5-7,0L8,2.2C7.8,2,7.5,1.9,7.3,2.1S7,2.5,7.1,2.8l0.6,1.1C7,4.5,6.5,5.4,6.2,6.3C6.2,6.6,6.3,6.9,6.6,7 C6.6,7,6.7,7,6.7,7z M13.7,4c0.4,0,0.8,0.3,0.8,0.8s-0.3,0.8-0.8,0.8S13,5.2,13,4.8C13,4.3,13.3,4,13.7,4C13.7,4,13.7,4,13.7,4 L13.7,4z M10.2,4C10.6,4,11,4.3,11,4.8s-0.3,0.8-0.8,0.8S9.5,5.2,9.5,4.8C9.5,4.3,9.8,4,10.2,4C10.2,4,10.2,4,10.2,4L10.2,4z M4,9 c-0.6,0-1,0.4-1,1v5c0,0.6,0.4,1,1,1s1-0.4,1-1v-5C5,9.4,4.6,9,4,9z',
  apple:
    'M11.76,6.69a.52.52,0,0,1-.59-.52,4.37,4.37,0,0,1,1-2.61A4.82,4.82,0,0,1,14.64,2a.51.51,0,0,1,.63.51,4.66,4.66,0,0,1-1,2.63A4.07,4.07,0,0,1,11.76,6.69Zm5.42,5.82c0-2.72,2-3.33,2-3.92s-1.73-1.91-3.56-1.91-2.54.86-3.78.86-2.25-.86-3.79-.86A5.16,5.16,0,0,0,3.89,9.21,6.64,6.64,0,0,0,3,12.75C3,17.14,6.15,22,8.47,22c1.3,0,1.63-.85,3.42-.85S14,22,15.22,22c2.52,0,4.62-5,4.62-5.07a.51.51,0,0,0-.3-.46A4.44,4.44,0,0,1,17.18,12.51Z',
  apple_outlined:
    'M14.76,2a.52.52,0,0,1,.51.53,4.66,4.66,0,0,1-1,2.63,4.07,4.07,0,0,1-2.51,1.53h-.08a.52.52,0,0,1-.51-.53,4.37,4.37,0,0,1,1-2.61A4.82,4.82,0,0,1,14.64,2l.12,0Zm.86,6.68A3.82,3.82,0,0,0,14.09,9a6.14,6.14,0,0,1-2.25.5A6.82,6.82,0,0,1,9.45,9a4.13,4.13,0,0,0-1.4-.34,3.17,3.17,0,0,0-2.47,1.61A4.59,4.59,0,0,0,5,12.75C5,16.36,7.54,19.83,8.5,20c.11,0,.18,0,.56-.19a6.22,6.22,0,0,1,2.83-.66,6,6,0,0,1,2.78.66c.35.16.42.19.55.19.49-.06,1.48-1.33,2.18-2.58a6.45,6.45,0,0,1-2.22-4.91,5.93,5.93,0,0,1,1.17-3.68,2.12,2.12,0,0,0-.73-.15m0-2c1.83,0,3.56,1.46,3.56,1.91s-2,1.2-2,3.92a4.44,4.44,0,0,0,2.36,4,.51.51,0,0,1,.3.46c0,.08-2.1,5.07-4.62,5.07-1.27,0-1.61-.85-3.33-.85S9.77,22,8.47,22C6.15,22,3,17.14,3,12.75a6.64,6.64,0,0,1,.89-3.54A5.16,5.16,0,0,1,8.05,6.68c1.54,0,2.51.86,3.79.86s2-.86,3.78-.86Z',
  augmented_reality:
    'M21,15.5V19a2,2,0,0,1-2,2H15.5a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5H19V15.5a.5.5,0,0,1,.5-.5h1A.5.5,0,0,1,21,15.5ZM8.5,19H5V15.5a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5V19a2,2,0,0,0,2,2H8.5a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,8.5,19ZM3.5,9h1A.5.5,0,0,0,5,8.5V5H8.5A.5.5,0,0,0,9,4.5v-1A.5.5,0,0,0,8.5,3H5A2,2,0,0,0,3,5V8.5A.5.5,0,0,0,3.5,9ZM19,3H15.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5H19V8.5a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5V5A2,2,0,0,0,19,3ZM16.76,13.6V10.4a2,2,0,0,0-1-1.73l-2.43-1.4a2,2,0,0,0-1-.27h-.66a2,2,0,0,0-1,.27L8.24,8.67a2,2,0,0,0-1,1.73v3.2a2,2,0,0,0,1,1.73l2.43,1.4a2,2,0,0,0,1,.27h.66a2,2,0,0,0,1-.27l2.43-1.4A2,2,0,0,0,16.76,13.6ZM12.33,15H12V12.58a1,1,0,0,0-.5-.87L9.24,10.4,11.67,9h.66l2.43,1.4v3.2Z',
  barcode:
    'M4,18.5a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5V5.5A.5.5,0,0,1,2.5,5h1a.5.5,0,0,1,.5.5ZM21.5,5h-1a.5.5,0,0,0-.5.5v13a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5V5.5A.5.5,0,0,0,21.5,5Zm-8,0a.5.5,0,0,0-.5.5v11a.5.5,0,0,0,1,0V5.5A.5.5,0,0,0,13.5,5Zm-7,0a.5.5,0,0,0-.5.5v11a.5.5,0,0,0,1,0V5.5A.5.5,0,0,0,6.5,5Zm11,0h-1a.5.5,0,0,0-.5.5v11a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5V5.5A.5.5,0,0,0,17.5,5ZM11,5.5a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5v11a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5Z',
  bluetooth:
    'M17.65,16.23,13.41,12l4.24-4.23a.51.51,0,0,0,0-.71L12.88,2.29A1,1,0,0,0,12.17,2H11.5a.5.5,0,0,0-.5.5V9.59L7.56,6.15a.5.5,0,0,0-.71,0l-.7.71a.48.48,0,0,0,0,.7L10.58,12,6.15,16.44a.48.48,0,0,0,0,.7l.7.71a.5.5,0,0,0,.71,0L11,14.41V21.5a.5.5,0,0,0,.5.5h.67a1,1,0,0,0,.71-.29l4.77-4.77A.51.51,0,0,0,17.65,16.23ZM13,5.24l2.17,2.17h0L13,9.58Zm0,13.52V14.42l2.17,2.17h0Z',
  bluetooth_connect:
    'M9.41,12l4.24-4.23a.51.51,0,0,0,0-.71L8.88,2.29A1,1,0,0,0,8.17,2H7.5a.5.5,0,0,0-.5.5V9.59L3.56,6.15a.5.5,0,0,0-.71,0l-.7.71a.48.48,0,0,0,0,.7L6.58,12,2.15,16.44a.48.48,0,0,0,0,.7l.7.71a.5.5,0,0,0,.71,0L7,14.41V21.5a.5.5,0,0,0,.5.5h.67a1,1,0,0,0,.71-.29l4.77-4.77a.51.51,0,0,0,0-.71ZM9,5.24l2.17,2.17h0L9,9.58ZM9,18.76V14.42l2.17,2.17h0ZM13.5,11h-1a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,13.5,11Zm4,0h-1a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,17.5,11Zm4,0h-1a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,21.5,11Z',
  bluetooth_off:
    'M20.85,20.15a.5.5,0,0,0,0-.71L4.56,3.15a.5.5,0,0,0-.71,0l-.7.7a.5.5,0,0,0,0,.71L10.6,12h0L6.15,16.44a.48.48,0,0,0,0,.7l.7.71a.5.5,0,0,0,.71,0L11,14.41V21.5a.5.5,0,0,0,.5.5h.67a1,1,0,0,0,.71-.29L16.59,18l2.85,2.85a.5.5,0,0,0,.71,0ZM13,18.76V14.42l2.17,2.17h0ZM13,5.24l2.17,2.17h0L13.24,9.35l1.41,1.41,3-3a.51.51,0,0,0,0-.71L12.88,2.29A1,1,0,0,0,12.17,2H11.5a.5.5,0,0,0-.5.5V7.11l2,2Z',
  bluetooth_search:
    'M10.41,12l4.24-4.23a.51.51,0,0,0,0-.71L9.88,2.29A1,1,0,0,0,9.17,2H8.5a.5.5,0,0,0-.5.5V9.59L4.56,6.15a.5.5,0,0,0-.71,0l-.7.71a.48.48,0,0,0,0,.7L7.58,12,3.15,16.44a.48.48,0,0,0,0,.7l.7.71a.5.5,0,0,0,.71,0L8,14.41V21.5a.5.5,0,0,0,.5.5h.67a1,1,0,0,0,.71-.29l4.77-4.77a.51.51,0,0,0,0-.71ZM10,5.24l2.17,2.17h0L10,9.58Zm0,13.52V14.42l2.17,2.17h0Zm9-12a.58.58,0,0,0-.36-.17.5.5,0,0,0-.37.15l-.71.71a.5.5,0,0,0,0,.68,6,6,0,0,1,0,7.8.5.5,0,0,0,0,.68l.71.71a.5.5,0,0,0,.37.15.56.56,0,0,0,.36-.17A8,8,0,0,0,19,6.73ZM15.84,9.4a.53.53,0,0,0-.39.15l-.71.72a.5.5,0,0,0-.07.63,2,2,0,0,1,0,2.2.5.5,0,0,0,.07.63l.71.72a.52.52,0,0,0,.39.14.52.52,0,0,0,.37-.19,4,4,0,0,0,0-4.8A.57.57,0,0,0,15.84,9.4Z',
  chart_bar_1:
    'M7,16H5a1,1,0,0,1-1-1V13a1,1,0,0,1,1-1H7a1,1,0,0,1,1,1v2A1,1,0,0,1,7,16Zm7-1V9a1,1,0,0,0-1-1H11a1,1,0,0,0-1,1v6a1,1,0,0,0,1,1h2A1,1,0,0,0,14,15Zm8,4.5v-1a.5.5,0,0,0-.5-.5H2.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h19A.5.5,0,0,0,22,19.5ZM20,15V5a1,1,0,0,0-1-1H17a1,1,0,0,0-1,1V15a1,1,0,0,0,1,1h2A1,1,0,0,0,20,15Z',
  chart_bar_2:
    'M4,15V13a1,1,0,0,1,1-1H7a1,1,0,0,1,1,1v2a1,1,0,0,1-1,1H5A1,1,0,0,1,4,15Zm7,1h2a1,1,0,0,0,1-1V5a1,1,0,0,0-1-1H11a1,1,0,0,0-1,1V15A1,1,0,0,0,11,16Zm6,0h2a1,1,0,0,0,1-1V9a1,1,0,0,0-1-1H17a1,1,0,0,0-1,1v6A1,1,0,0,0,17,16Zm4.5,2H2.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h19a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,21.5,18Z',
  chart_bar_3:
    'M7,20H5a1,1,0,0,1-1-1V13a1,1,0,0,1,1-1H7a1,1,0,0,1,1,1v6A1,1,0,0,1,7,20Zm7-1V9a1,1,0,0,0-1-1H11a1,1,0,0,0-1,1V19a1,1,0,0,0,1,1h2A1,1,0,0,0,14,19Zm6,0V5a1,1,0,0,0-1-1H17a1,1,0,0,0-1,1V19a1,1,0,0,0,1,1h2A1,1,0,0,0,20,19Z',
  chart_bar_4:
    'M8,13v6a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V13a1,1,0,0,1,1-1H7A1,1,0,0,1,8,13Zm5-9H11a1,1,0,0,0-1,1V19a1,1,0,0,0,1,1h2a1,1,0,0,0,1-1V5A1,1,0,0,0,13,4Zm6,4H17a1,1,0,0,0-1,1V19a1,1,0,0,0,1,1h2a1,1,0,0,0,1-1V9A1,1,0,0,0,19,8Z',
  chart_bubble:
    'M14,19a2,2,0,1,1-2-2A2,2,0,0,1,14,19Zm3.5-8A3.5,3.5,0,1,0,21,14.5,3.5,3.5,0,0,0,17.5,11ZM14,8.5A5.5,5.5,0,1,0,8.5,14,5.5,5.5,0,0,0,14,8.5Z',
  chart_donut_1:
    'M21,10.47a.51.51,0,0,1-.13.37.52.52,0,0,1-.37.16h-1a.5.5,0,0,1-.5-.48A6,6,0,0,0,13.47,5,.5.5,0,0,1,13,4.53v-1a.52.52,0,0,1,.16-.37A.51.51,0,0,1,13.53,3,8,8,0,0,1,21,10.47ZM18.48,13h-1a.5.5,0,0,0-.5.48A6,6,0,1,1,10.54,7a.5.5,0,0,0,.46-.5v-1a.5.5,0,0,0-.52-.5,8,8,0,1,0,8.5,8.5A.5.5,0,0,0,18.48,13Z',
  chart_donut_2:
    'M13,4.66v-2a.46.46,0,0,1,.18-.38.48.48,0,0,1,.4-.12,10,10,0,0,1,8.3,8.3.48.48,0,0,1-.12.4.46.46,0,0,1-.38.18h-2a.5.5,0,0,1-.49-.4A7,7,0,0,0,13.4,5.15.5.5,0,0,1,13,4.66ZM16.48,17.9a.49.49,0,0,0-.63-.06A6.9,6.9,0,0,1,12,19,7,7,0,0,1,10.6,5.15a.5.5,0,0,0,.4-.49v-2a.46.46,0,0,0-.18-.38.48.48,0,0,0-.4-.12,10,10,0,1,0,7.45,18,.53.53,0,0,0,.2-.37.5.5,0,0,0-.14-.39Zm4.9-4.9h-2a.5.5,0,0,0-.49.4,6.63,6.63,0,0,1-1,2.45.5.5,0,0,0,.06.63l1.45,1.45a.5.5,0,0,0,.39.14.51.51,0,0,0,.37-.21,10.17,10.17,0,0,0,1.77-4.28.48.48,0,0,0-.12-.4A.46.46,0,0,0,21.38,13Z',
  chart_line_down:
    'M2.15,6.56a.5.5,0,0,1,0-.71l.7-.7a.5.5,0,0,1,.71,0l6.94,6.94,2.65-2.65a.48.48,0,0,1,.7,0l8,8a.5.5,0,0,1,0,.71l-.7.7a.5.5,0,0,1-.71,0L13.5,11.91l-2.65,2.65a.48.48,0,0,1-.7,0Z',
  chart_line_up:
    'M10.15,9.44a.48.48,0,0,1,.7,0l2.65,2.65,6.94-6.94a.5.5,0,0,1,.71,0l.7.7a.5.5,0,0,1,0,.71l-8,8a.48.48,0,0,1-.7,0L10.5,11.91,3.56,18.85a.5.5,0,0,1-.71,0l-.7-.7a.5.5,0,0,1,0-.71Z',
  chart_pie_1:
    'M21,10.47a.51.51,0,0,1-.13.37.52.52,0,0,1-.37.16H13V3.52a.52.52,0,0,1,.16-.37A.5.5,0,0,1,13.53,3,8,8,0,0,1,21,10.47ZM18.48,13H11V5.52a.5.5,0,0,0-.52-.5,8,8,0,1,0,8.5,8.5A.5.5,0,0,0,18.48,13Z',
  chart_pie_2:
    'M13,10.5V2.62a.46.46,0,0,1,.18-.38.45.45,0,0,1,.4-.11,10,10,0,0,1,8.29,8.29.45.45,0,0,1-.11.4.46.46,0,0,1-.38.18H13.5A.5.5,0,0,1,13,10.5ZM21.38,13H13.5a.5.5,0,0,0-.5.5v7.88a.46.46,0,0,0,.18.38.45.45,0,0,0,.4.11,10,10,0,0,0,8.29-8.29.45.45,0,0,0-.11-.4A.46.46,0,0,0,21.38,13Zm-11-10.87a10,10,0,0,0,0,19.74.45.45,0,0,0,.4-.11.46.46,0,0,0,.18-.38V2.62a.46.46,0,0,0-.18-.38A.45.45,0,0,0,10.42,2.13Z',
  cloud:
    'M6,10c0-3.3,2.7-6,6-6c3,0,5.4,2.2,5.9,5c2.8,0.2,5.1,2.6,5.1,5.5c0,3-2.5,5.5-5.5,5.5H6c-2.8,0-5-2.2-5-5S3.2,10,6,10z',
  cloud_disabled:
    'M17.9,9C17.4,6.2,15,4,12,4c-3.3,0-6,2.7-6,6c-2.8,0-5,2.2-5,5s2.2,5,5,5h11.5c3,0,5.5-2.5,5.5-5.5C23,11.6,20.8,9.2,17.9,9 z M14.8,14.6c0.2,0.2,0.2,0.5,0,0.7l-0.5,0.5c-0.2,0.2-0.5,0.2-0.7,0L12,14.2l-1.6,1.6c-0.2,0.2-0.5,0.2-0.7,0l-0.5-0.5 c-0.2-0.2-0.2-0.5,0-0.7l1.6-1.6l-1.6-1.6c-0.2-0.2-0.2-0.5,0-0.7l0.5-0.5c0.2-0.2,0.5-0.2,0.7,0l1.6,1.6l1.6-1.6 c0.2-0.2,0.5-0.2,0.7,0l0.5,0.5c0.2,0.2,0.2,0.5,0,0.7L13.2,13L14.8,14.6z',
  cloud_off:
    'M23,14.5a5.49,5.49,0,0,1-1.76,4L8.13,5.42A5.92,5.92,0,0,1,12,4a6,6,0,0,1,5.91,5A5.49,5.49,0,0,1,23,14.5ZM4.38,4.15a.48.48,0,0,0-.7,0l-.53.53a.48.48,0,0,0,0,.7L6.21,8.45A6,6,0,0,0,6,10,5,5,0,0,0,6,20H17.5l.25,0,1.87,1.87a.48.48,0,0,0,.7,0l.53-.53a.48.48,0,0,0,0-.7Z',
  cloud_off_outlined:
    'M4.38,4.15a.48.48,0,0,0-.7,0l-.53.53a.48.48,0,0,0,0,.7L6.2,8.44A6.18,6.18,0,0,0,6,10,5,5,0,0,0,6,20H17.5l.25,0,1.87,1.87a.48.48,0,0,0,.7,0l.53-.53a.48.48,0,0,0,0-.7ZM6,18a3,3,0,0,1-2.94-3.6A3.09,3.09,0,0,1,6.13,12H7.5a.5.5,0,0,0,.5-.5V10.24L15.76,18ZM17.91,9A6,6,0,0,0,12,4,5.92,5.92,0,0,0,8.13,5.42L9.55,6.84a4,4,0,0,1,6.39,2.51l.2,1.16a.49.49,0,0,0,.45.42l1.17.09A3.5,3.5,0,0,1,21,14.5a3.45,3.45,0,0,1-1.18,2.61l1.42,1.42A5.49,5.49,0,0,0,17.91,9Z',
  cloud_on:
    'M17.9,9C17.4,6.2,15,4,12,4c-3.3,0-6,2.7-6,6c-2.8,0-5,2.2-5,5s2.2,5,5,5h11.5c3,0,5.5-2.5,5.5-5.5C23,11.6,20.8,9.2,17.9,9 z M15.5,11.6l-4.2,4.2c-0.2,0.2-0.5,0.2-0.7,0l-2.1-2.1c-0.2-0.2-0.2-0.5,0-0.7L9,12.5c0.2-0.2,0.5-0.2,0.7,0l1.2,1.2l3.4-3.4 c0.2-0.2,0.5-0.2,0.7,0l0.5,0.5C15.7,11.1,15.7,11.4,15.5,11.6z',
  cloud_outlined:
    'M12,6c2,0,3.6,1.4,3.9,3.3l0.2,1.2c0,0.2,0.2,0.4,0.5,0.4l1.1,0.1c1.6,0.1,3,1.3,3.3,2.9c0.4,2.2-1.3,4.1-3.4,4.1L6.1,18 c-1.5,0-2.8-1-3.1-2.4C2.7,13.7,4.2,12,6,12h1.5C7.8,12,8,11.8,8,11.5V10C8,7.8,9.8,6,12,6 M12,4c-3.3,0-6,2.7-6,6c-2.8,0-5,2.2-5,5 s2.2,5,5,5h11.5c3,0,5.5-2.5,5.5-5.5c0-2.9-2.2-5.3-5.1-5.5C17.4,6.2,15,4,12,4L12,4z',
  code:
    'M22,11.81v.38a.77.77,0,0,1-.22.53l-4.14,4.13a.5.5,0,0,1-.71,0l-.71-.71a.49.49,0,0,1,0-.7L19.67,12,16.22,8.56a.5.5,0,0,1,0-.71l.71-.7a.5.5,0,0,1,.71,0l4.14,4.13A.75.75,0,0,1,22,11.81ZM4.33,12,7.77,8.56a.49.49,0,0,0,0-.7l-.71-.71a.5.5,0,0,0-.71,0L2.22,11.28a.77.77,0,0,0-.22.53v.38a.75.75,0,0,0,.22.53l4.14,4.13a.5.5,0,0,0,.71,0l.71-.7a.5.5,0,0,0,0-.71Zm11-7.63L14.36,4a.52.52,0,0,0-.65.3L8.4,19a.5.5,0,0,0,.3.64l.94.34a.51.51,0,0,0,.64-.3L15.59,5A.5.5,0,0,0,15.29,4.37Z',
  data_sharing:
    'M18.91,9.09A5,5,0,0,0,14,5H6a5,5,0,0,0-.91,9.91A5,5,0,0,0,10,19h8a5,5,0,0,0,.91-9.91ZM3,10A3,3,0,0,1,6,7h8a3,3,0,0,1,0,6H7.18A3,3,0,0,1,10,11h4.7a1.19,1.19,0,0,0,.55-1,1.23,1.23,0,0,0-.54-1H10a5,5,0,0,0-4.86,3.86A3,3,0,0,1,3,10Zm15,7H10a3,3,0,0,1-2.82-2H14a5,5,0,0,0,4.86-3.86A3,3,0,0,1,18,17Z',
  download_from_cloud:
    'M17.9,9C17.4,6.2,15,4,12,4c-3.3,0-6,2.7-6,6c-2.8,0-5,2.2-5,5s2.2,5,5,5h11.5c3,0,5.5-2.5,5.5-5.5C23,11.6,20.8,9.2,17.9,9 z M15.9,13.3l-3.5,3.5c-0.2,0.2-0.5,0.2-0.7,0l-3.5-3.5c-0.2-0.2-0.2-0.5,0-0.7l0.2-0.2c0.1-0.1,0.2-0.1,0.4-0.1H10V8.5 C10,8.2,10.2,8,10.5,8h3C13.8,8,14,8.2,14,8.5v3.8h1.3c0.1,0,0.3,0.1,0.4,0.1l0.2,0.2C16.1,12.8,16.1,13.1,15.9,13.3z',
  face_id:
    'M21,6V7.5a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5V6a1,1,0,0,0-1-1H16.5a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5H18A3,3,0,0,1,21,6ZM7.5,19H6a1,1,0,0,1-1-1V16.5a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5V18a3,3,0,0,0,3,3H7.5a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,7.5,19ZM3.5,8h1A.5.5,0,0,0,5,7.5V6A1,1,0,0,1,6,5H7.5A.5.5,0,0,0,8,4.5v-1A.5.5,0,0,0,7.5,3H6A3,3,0,0,0,3,6V7.5A.5.5,0,0,0,3.5,8Zm17,8h-1a.5.5,0,0,0-.5.5V18a1,1,0,0,1-1,1H16.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5H18a3,3,0,0,0,3-3V16.5A.5.5,0,0,0,20.5,16ZM9,10.5v-2A.5.5,0,0,0,8.5,8h-1a.5.5,0,0,0-.5.5v2a.5.5,0,0,0,.5.5h1A.5.5,0,0,0,9,10.5Zm8,0v-2a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5v2a.5.5,0,0,0,.5.5h1A.5.5,0,0,0,17,10.5ZM10.5,14A2.5,2.5,0,0,0,13,11.5v-3a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5v3a.5.5,0,0,1-.5.5.5.5,0,0,0-.5.5v1A.5.5,0,0,0,10.5,14Zm4.18,1.57a.51.51,0,0,0-.67-.11,4.11,4.11,0,0,1-4,0,.51.51,0,0,0-.67.11l-.6.81a.49.49,0,0,0,.11.72A6,6,0,0,0,12,18a5.92,5.92,0,0,0,3.16-.9.49.49,0,0,0,.12-.72Z',
  fingerprint:
    'M5.75,11.75C5.75,16.58,8,19.15,8,19.6A.49.49,0,0,1,7.8,20l-.42.28a.47.47,0,0,1-.28.09c-.38,0-1.08-1.08-1.7-2.68a17.32,17.32,0,0,1-1.15-5.84V10A7.87,7.87,0,0,1,12,2a8.11,8.11,0,0,1,1.21.09.49.49,0,0,1,.35.24.5.5,0,0,1,0,.43l-.18.48a.49.49,0,0,1-.54.33A5.14,5.14,0,0,0,12,3.5c-4.91,0-6.21,4.7-6.21,6.43ZM15.7,3a.51.51,0,0,0-.42,0,.51.51,0,0,0-.29.3l-.18.49a.5.5,0,0,0,.22.6,6.19,6.19,0,0,1,3.15,5.39v1.65a.5.5,0,0,0,.5.5h.5a.51.51,0,0,0,.5-.5V9.71A7.74,7.74,0,0,0,15.7,3ZM12.25,9.75h-.5a.5.5,0,0,0-.5.5v1.5a8.48,8.48,0,0,0,.57,3.06.49.49,0,0,0,.29.29.51.51,0,0,0,.42,0l.43-.24a.49.49,0,0,0,.23-.61,7.06,7.06,0,0,1-.44-2.47v-1.5A.5.5,0,0,0,12.25,9.75Zm4.82,8.64a7.08,7.08,0,0,1-2.73-2.13.5.5,0,0,0-.63-.13l-.45.25a.5.5,0,0,0-.16.74,8.72,8.72,0,0,0,3.42,2.66.5.5,0,0,0,.66-.29l.18-.48A.49.49,0,0,0,17.07,18.39ZM9.25,11.93a.47.47,0,0,0-.5-.43h-.5a.5.5,0,0,0-.5.46v0c.13,6,4.74,9.89,5.53,9.89a.5.5,0,0,0,.43-.24l.27-.42a.48.48,0,0,0,.07-.26C14.05,20.35,9.34,18.14,9.25,11.93Zm9.31,3.28a3.76,3.76,0,0,1-2.31-3.46V9.94A4.37,4.37,0,0,0,12,5.5a4.26,4.26,0,0,0-4.24,4v0a.49.49,0,0,0,.5.48h.51a.5.5,0,0,0,.5-.48,2.74,2.74,0,0,1,5.48.23v2a5.13,5.13,0,0,0,3.51,4.92.5.5,0,0,0,.48-.37l.13-.48a.32.32,0,0,0,0-.13A.53.53,0,0,0,18.56,15.21Z',
  link:
    'M8.65,13.94l5.29-5.29a.5.5,0,0,1,.71,0l.7.7a.5.5,0,0,1,0,.71l-5.29,5.29a.5.5,0,0,1-.71,0l-.7-.7A.5.5,0,0,1,8.65,13.94ZM21,7.66V8a4,4,0,0,1-1.18,2.84L17,13.67a1.35,1.35,0,0,1-1.9,0L15,13.56a.5.5,0,0,1,0-.7l3.44-3.44A2,2,0,0,0,19,8V7.66a2,2,0,0,0-.59-1.42l-.65-.65A2,2,0,0,0,16.34,5H16a2,2,0,0,0-1.42.59L11.14,9a.5.5,0,0,1-.7,0l-.11-.11a1.35,1.35,0,0,1,0-1.9l2.83-2.84A4,4,0,0,1,16,3h.34a4,4,0,0,1,2.83,1.17l.66.66A4,4,0,0,1,21,7.66ZM12.86,15a.5.5,0,0,1,.7,0l.11.11a1.35,1.35,0,0,1,0,1.9l-2.85,2.85A4,4,0,0,1,8,21H7.66a4,4,0,0,1-2.83-1.17l-.66-.66A4,4,0,0,1,3,16.35V16a4,4,0,0,1,1.18-2.84L7,10.33a1.35,1.35,0,0,1,1.9,0l.11.11a.5.5,0,0,1,0,.7L5.59,14.58A2,2,0,0,0,5,16v.34a2,2,0,0,0,.59,1.42l.65.65A2,2,0,0,0,7.66,19H8a2,2,0,0,0,1.43-.59Z',
  link_off:
    'M4.38,3.15a.48.48,0,0,0-.7,0l-.53.53a.48.48,0,0,0,0,.7l4.9,4.91h0L4.18,13.16A4,4,0,0,0,3,16v.34a4,4,0,0,0,1.17,2.83l.66.66A4,4,0,0,0,7.66,21H8a4,4,0,0,0,2.83-1.17l3.89-3.89h0l4.91,4.9a.48.48,0,0,0,.7,0l.53-.53a.48.48,0,0,0,0-.7Zm5,15.26A2,2,0,0,1,8,19H7.66a2,2,0,0,1-1.42-.59l-.65-.65A2,2,0,0,1,5,16.34V16a2,2,0,0,1,.59-1.42L9.47,10.7l1.21,1.22-2,2a.5.5,0,0,0,0,.71l.7.7a.5.5,0,0,0,.71,0l2-2,1.21,1.2ZM19.83,4.83l-.66-.66A4,4,0,0,0,16.34,3H16a4,4,0,0,0-2.84,1.18L10.53,6.81,12,8.23l2.63-2.64A2,2,0,0,1,16,5h.34a2,2,0,0,1,1.42.59l.65.65A2,2,0,0,1,19,7.66V8a2,2,0,0,1-.59,1.42l-2.63,2.64,1.41,1.41,2.63-2.63A4,4,0,0,0,21,8V7.66A4,4,0,0,0,19.83,4.83ZM15.35,9.35l-.7-.7a.5.5,0,0,0-.71,0l-.78.79,1.41,1.41.78-.79A.5.5,0,0,0,15.35,9.35Z',
  memory_chip:
    'M20.5,10a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5H19V7a2,2,0,0,0-2-2H16V3.5a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5V5H10V3.5A.5.5,0,0,0,9.5,3h-1a.5.5,0,0,0-.5.5V5H7A2,2,0,0,0,5,7V8H3.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5H5v4H3.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5H5v1a2,2,0,0,0,2,2H8v1.5a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5V19h4v1.5a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5V19h1a2,2,0,0,0,2-2V16h1.5a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5H19V10ZM17,17H7V7H17Zm-6.5-3h3a.5.5,0,0,0,.5-.5v-3a.5.5,0,0,0-.5-.5h-3a.5.5,0,0,0-.5.5v3A.5.5,0,0,0,10.5,14Z',
  mobile_data:
    'M5.31,16a.5.5,0,0,1,0,.69l-.71.71a.51.51,0,0,1-.72,0,11,11,0,0,1,0-14.8.51.51,0,0,1,.72,0l.71.71a.5.5,0,0,1,0,.69,9,9,0,0,0,0,12ZM20.14,2.6a.51.51,0,0,0-.72,0l-.71.71a.5.5,0,0,0,0,.69,9,9,0,0,1,0,12,.5.5,0,0,0,0,.69l.71.71a.51.51,0,0,0,.72,0,11,11,0,0,0,0-14.8ZM17.32,5.45A.52.52,0,0,0,17,5.27a.54.54,0,0,0-.38.15l-.71.71a.5.5,0,0,0,0,.67,5,5,0,0,1,0,6.4.5.5,0,0,0,0,.67l.71.71a.54.54,0,0,0,.38.15.52.52,0,0,0,.36-.18A7,7,0,0,0,17.32,5.45Zm-9.19.68-.71-.71A.54.54,0,0,0,7,5.27a.52.52,0,0,0-.36.18,7,7,0,0,0,0,9.1.52.52,0,0,0,.36.18.54.54,0,0,0,.38-.15l.71-.71a.5.5,0,0,0,0-.67,5,5,0,0,1,0-6.4A.5.5,0,0,0,8.13,6.13ZM9.88,7.88A3,3,0,0,0,11,12.82V21.5a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5V12.82A3,3,0,1,0,9.88,7.88Z',
  mobile_data_low:
    'M17.32,14.55a.52.52,0,0,1-.36.18.54.54,0,0,1-.38-.15l-.71-.71a.5.5,0,0,1,0-.67,5,5,0,0,0,0-6.4.5.5,0,0,1,0-.67l.71-.71A.54.54,0,0,1,17,5.27a.52.52,0,0,1,.36.18A7,7,0,0,1,17.32,14.55ZM8.13,6.13l-.71-.71A.54.54,0,0,0,7,5.27a.52.52,0,0,0-.36.18,7,7,0,0,0,0,9.1.52.52,0,0,0,.36.18.54.54,0,0,0,.38-.15l.71-.71a.5.5,0,0,0,0-.67,5,5,0,0,1,0-6.4A.5.5,0,0,0,8.13,6.13ZM9.88,7.88A3,3,0,0,0,11,12.82V21.5a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5V12.82A3,3,0,1,0,9.88,7.88Z',
  online:
    'M12,2a10,10,0,0,0,0,20c1.91,0,4.81-.78,4.81-1.53a.48.48,0,0,0-.07-.26l-.5-.87a.51.51,0,0,0-.43-.24A9.93,9.93,0,0,1,12,20a8,8,0,1,1,8-8c0,1-.72,3.27-2.88,3.27a2.58,2.58,0,0,1-.39,0c-.82-.17-1.34-.88-1.11-2.13h0l1-5.51A.5.5,0,0,0,16.13,7h-1a.5.5,0,0,0-.49.41l-.08.45A3.69,3.69,0,0,0,12.13,7a5,5,0,0,0-4.81,4.16l-.25,1.46a3.89,3.89,0,0,0,.16,2,3.57,3.57,0,0,0,3.47,2.32,4.86,4.86,0,0,0,3.35-1.47,3.18,3.18,0,0,0,3.07,1.83C20.45,17.27,22,14.12,22,12A10,10,0,0,0,12,2ZM9,13.39A2,2,0,0,1,9,13l.27-1.46a3.13,3.13,0,0,1,3-2.52c1,0,1.89.71,1.73,1.62h0l-.46,2.5a2.8,2.8,0,0,1-2.22,1.93,3.88,3.88,0,0,1-.48,0A1.71,1.71,0,0,1,9,13.39Z',
  plugin:
    'M20,15v6a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V15a1,1,0,0,1,1-1H7V12.5a.5.5,0,0,1,.5-.5h2a.5.5,0,0,1,.5.5V14h4V12.5a.5.5,0,0,1,.5-.5h2a.5.5,0,0,1,.5.5V14h2A1,1,0,0,1,20,15ZM19,2H5A1,1,0,0,0,4,3V9a1,1,0,0,0,1,1H19a1,1,0,0,0,1-1V3A1,1,0,0,0,19,2Z',
  plugin_outlined:
    'M19,14H17V12.5a.5.5,0,0,0-.5-.5h-2a.5.5,0,0,0-.5.5V14H10V12.5a.5.5,0,0,0-.5-.5h-2a.5.5,0,0,0-.5.5V14H5a1,1,0,0,0-1,1v6a1,1,0,0,0,1,1H19a1,1,0,0,0,1-1V15A1,1,0,0,0,19,14Zm-1,6H6V16H18ZM19,2H5A1,1,0,0,0,4,3V9a1,1,0,0,0,1,1H19a1,1,0,0,0,1-1V3A1,1,0,0,0,19,2ZM18,8H6V4H18Z',
  poll:
    'M19,3H5A2,2,0,0,0,3,5V19a2,2,0,0,0,2,2H19a2,2,0,0,0,2-2V5A2,2,0,0,0,19,3ZM9,17.5a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5v-5a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5Zm4,0a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5V6.5a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5Zm4,0a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5v-7a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5Z',
  qr_code:
    'M4,9H8A1,1,0,0,0,9,8V4A1,1,0,0,0,8,3H4A1,1,0,0,0,3,4V8A1,1,0,0,0,4,9ZM5,5H7V7H5ZM3.5,13h1a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5v1A.5.5,0,0,0,3.5,13ZM8,15H4a1,1,0,0,0-1,1v4a1,1,0,0,0,1,1H8a1,1,0,0,0,1-1V16A1,1,0,0,0,8,15ZM7,19H5V17H7Zm10.5,2h3a.5.5,0,0,0,.5-.5v-3a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5V19H17v1.5A.5.5,0,0,0,17.5,21ZM20,3H16a1,1,0,0,0-1,1V8a1,1,0,0,0,1,1h4a1,1,0,0,0,1-1V4A1,1,0,0,0,20,3ZM19,7H17V5h2ZM7.5,11a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5H11V11ZM11,14.5a.5.5,0,0,0,.5.5H13v1.5a.5.5,0,0,0,.5.5H15V13H11Zm0,5v1a.5.5,0,0,0,.5.5h3a.5.5,0,0,0,.5-.5V19H11.5A.5.5,0,0,0,11,19.5ZM20.5,11h-5a.5.5,0,0,0-.5.5V13h2v1.5a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5V13h1.5a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,20.5,11ZM17,19V17H15v2ZM11.5,7h1a.5.5,0,0,0,.5-.5v-3a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5v3A.5.5,0,0,0,11.5,7ZM13,10.5v-1a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5V11h1.5A.5.5,0,0,0,13,10.5Z',
  scan:
    'M3,8.5V5A2,2,0,0,1,5,3H8.5a.5.5,0,0,1,.5.5v1a.5.5,0,0,1-.5.5H5V8.5a.5.5,0,0,1-.5.5h-1A.5.5,0,0,1,3,8.5ZM15.5,5H19V8.5a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5V5a2,2,0,0,0-2-2H15.5a.5.5,0,0,0-.5.5v1A.5.5,0,0,0,15.5,5Zm-7,14H5V15.5a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5V19a2,2,0,0,0,2,2H8.5a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,8.5,19Zm12-4h-1a.5.5,0,0,0-.5.5V19H15.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5H19a2,2,0,0,0,2-2V15.5A.5.5,0,0,0,20.5,15Zm1-4H2.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h19a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,21.5,11Z',
  security:
    'M20.12,5.12,16.59,1.59A2,2,0,0,0,15.17,1H8.83a2,2,0,0,0-1.42.59L3.88,5.12A3,3,0,0,0,3,7.24v5.85A8,8,0,0,0,6.09,19.4l4.08,3.18A2,2,0,0,0,11.4,23h1.2a2,2,0,0,0,1.23-.42l4.08-3.18A8,8,0,0,0,21,13.09V7.24A3,3,0,0,0,20.12,5.12Zm-1.12,8a5.93,5.93,0,0,1-2.32,4.73L12.6,21H12V12H5V7.24a1,1,0,0,1,.29-.7L8.83,3H12v9h7Z',
  security_off:
    'M21,7.24a3,3,0,0,0-.88-2.12L16.59,1.59A2,2,0,0,0,15.17,1H8.83a2,2,0,0,0-1.42.59L3.88,5.12A3,3,0,0,0,3,7.24v5.85A8,8,0,0,0,6.09,19.4l4.08,3.18A2,2,0,0,0,11.4,23h1.2a2,2,0,0,0,1.23-.42l4.08-3.18A8,8,0,0,0,21,13.09Zm-5.91,5.62a.48.48,0,0,1,0,.7l-.53.53a.48.48,0,0,1-.7,0L12,12.24l-1.86,1.85a.48.48,0,0,1-.7,0l-.53-.53a.48.48,0,0,1,0-.7L10.76,11,8.91,9.14a.48.48,0,0,1,0-.7l.53-.53a.48.48,0,0,1,.7,0L12,9.76l1.86-1.85a.48.48,0,0,1,.7,0l.53.53a.48.48,0,0,1,0,.7L13.24,11Z',
  security_off_outlined:
    'M20.12,5.12,16.59,1.59A2,2,0,0,0,15.17,1H8.83a2,2,0,0,0-1.42.59L3.88,5.12A3,3,0,0,0,3,7.24v5.85A8,8,0,0,0,6.09,19.4l4.08,3.18A2,2,0,0,0,11.4,23h1.2a2,2,0,0,0,1.23-.42l4.08-3.18A8,8,0,0,0,21,13.09V7.24A3,3,0,0,0,20.12,5.12Zm-1.12,8a5.93,5.93,0,0,1-2.32,4.73L12.6,21H11.4L7.32,17.82A5.93,5.93,0,0,1,5,13.09V7.24a1,1,0,0,1,.29-.7L8.83,3h6.34l3.54,3.54a1,1,0,0,1,.29.7ZM15.09,8.44l-.53-.53a.48.48,0,0,0-.7,0L12,9.76,10.14,7.91a.48.48,0,0,0-.7,0l-.53.53a.48.48,0,0,0,0,.7L10.76,11,8.91,12.86a.48.48,0,0,0,0,.7l.53.53a.48.48,0,0,0,.7,0L12,12.24l1.86,1.85a.48.48,0,0,0,.7,0l.53-.53a.48.48,0,0,0,0-.7L13.24,11l1.85-1.86A.48.48,0,0,0,15.09,8.44Z',
  security_on:
    'M21,7.24a3,3,0,0,0-.88-2.12L16.59,1.59A2,2,0,0,0,15.17,1H8.83a2,2,0,0,0-1.42.59L3.88,5.12A3,3,0,0,0,3,7.24v5.85A8,8,0,0,0,6.09,19.4l4.08,3.18A2,2,0,0,0,11.4,23h1.2a2,2,0,0,0,1.23-.42l4.08-3.18A8,8,0,0,0,21,13.09Zm-4.65,2-5.62,5.62a.48.48,0,0,1-.7,0L7.65,12.48a.5.5,0,0,1,0-.71l.53-.53a.48.48,0,0,1,.7,0l1.5,1.49L15.12,8a.5.5,0,0,1,.7,0l.53.53A.5.5,0,0,1,16.35,9.23Z',
  security_on_outlined:
    'M20.12,5.12,16.59,1.59A2,2,0,0,0,15.17,1H8.83a2,2,0,0,0-1.42.59L3.88,5.12A3,3,0,0,0,3,7.24v5.85A8,8,0,0,0,6.09,19.4l4.08,3.18A2,2,0,0,0,11.4,23h1.2a2,2,0,0,0,1.23-.42l4.08-3.18A8,8,0,0,0,21,13.09V7.24A3,3,0,0,0,20.12,5.12Zm-1.12,8a5.93,5.93,0,0,1-2.32,4.73L12.6,21H11.4L7.32,17.82A5.93,5.93,0,0,1,5,13.09V7.24a1,1,0,0,1,.29-.7L8.83,3h6.34l3.54,3.54a1,1,0,0,1,.29.7ZM15.82,8a.5.5,0,0,0-.7,0l-4.74,4.74-1.5-1.49a.48.48,0,0,0-.7,0l-.53.53a.5.5,0,0,0,0,.71L10,14.85a.48.48,0,0,0,.7,0l5.62-5.62a.5.5,0,0,0,0-.71Z',
  touch_id:
    'M12,6a5,5,0,0,0-5,5V21.5a.5.5,0,0,0,.5.5h9a.5.5,0,0,0,.5-.5V11A5,5,0,0,0,12,6Zm3,6a3,3,0,0,1-6,0V10a1,1,0,0,1,1-1h4a1,1,0,0,1,1,1ZM11.74,2A9,9,0,0,0,3.63,14.32a.5.5,0,0,0,.68.26l.91-.43a.49.49,0,0,0,.25-.64,6.89,6.89,0,0,1-.45-2A7.1,7.1,0,0,1,11.79,4,7,7,0,0,1,19,11a6.79,6.79,0,0,1-.47,2.51.49.49,0,0,0,.25.64l.91.43a.5.5,0,0,0,.68-.26A9,9,0,0,0,11.74,2Z',
  trending_down:
    'M21.5,19h-5a.5.5,0,0,1-.5-.5v-.29a.49.49,0,0,1,.15-.36l1.64-1.64-4.29-4.3-2.65,2.65a.48.48,0,0,1-.7,0l-8-8a.5.5,0,0,1,0-.71l.7-.7a.5.5,0,0,1,.71,0l6.94,6.94,2.65-2.65a.48.48,0,0,1,.7,0l5.36,5.35,1.64-1.64a.49.49,0,0,1,.36-.15h.29a.5.5,0,0,1,.5.5v5A.5.5,0,0,1,21.5,19Z',
  trending_up:
    'M22,5.5v5a.5.5,0,0,1-.5.5h-.29a.49.49,0,0,1-.36-.15L19.21,9.21l-5.36,5.35a.48.48,0,0,1-.7,0L10.5,11.91,3.56,18.85a.5.5,0,0,1-.71,0l-.7-.7a.5.5,0,0,1,0-.71l8-8a.48.48,0,0,1,.7,0l2.65,2.65,4.29-4.3L16.15,6.15A.49.49,0,0,1,16,5.79V5.5a.5.5,0,0,1,.5-.5h5A.5.5,0,0,1,22,5.5Z',
  upload_on_cloud:
    'M17.9,9C17.4,6.2,15,4,12,4c-3.3,0-6,2.7-6,6c-2.8,0-5,2.2-5,5s2.2,5,5,5h11.5c3,0,5.5-2.5,5.5-5.5C23,11.6,20.8,9.2,17.9,9 z M15.9,12.4l-0.2,0.2c-0.1,0.1-0.2,0.1-0.4,0.1H14v3.8c0,0.3-0.2,0.5-0.5,0.5h-3c-0.3,0-0.5-0.2-0.5-0.5v-3.8H8.7 c-0.1,0-0.3-0.1-0.4-0.1l-0.2-0.2c-0.2-0.2-0.2-0.5,0-0.7l3.5-3.5c0.2-0.2,0.5-0.2,0.7,0l3.5,3.5C16.1,11.9,16.1,12.2,15.9,12.4z',
  usb:
    'M18,9.5v3a2,2,0,0,1-.75,1.56L13,17.48v4a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5v-2l-4.25-3.4A2,2,0,0,1,6,14.52v-3a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5v3l3,2.4V6.77H9.82A.74.74,0,0,1,9.2,5.61l2.18-3.28a.75.75,0,0,1,1.24,0L14.8,5.61a.74.74,0,0,1-.62,1.16H13v8.15l3-2.4v-3a.5.5,0,0,1,.5-.5h1A.5.5,0,0,1,18,9.5Z',
  virtual_reality:
    'M22.11,8.87,19,5.76A6,6,0,0,0,14.76,4H9.24A6,6,0,0,0,5,5.76L1.89,8.87A3,3,0,0,0,1,11v6a3,3,0,0,0,3,3H8.76a2,2,0,0,0,1.79-1.11l.17-.34a1,1,0,0,1,.9-.55h.76a1,1,0,0,1,.9.55l.17.34A2,2,0,0,0,15.24,20H20a3,3,0,0,0,3-3V11A3,3,0,0,0,22.11,8.87ZM6.42,7.17A4,4,0,0,1,9.24,6h5.52a4,4,0,0,1,2.82,1.17l.83.83H5.59ZM19,13.5a.5.5,0,0,1-.5.5H5.5a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h13a.5.5,0,0,1,.5.5Z',
  virtual_reality_outlined:
    'M22.11,8.87,19,5.76A6,6,0,0,0,14.76,4H9.24A6,6,0,0,0,5,5.76L1.89,8.87A3,3,0,0,0,1,11v6a3,3,0,0,0,3,3H8.76a2,2,0,0,0,1.79-1.11l.17-.34a1,1,0,0,1,.9-.55h.76a1,1,0,0,1,.9.55l.17.34A2,2,0,0,0,15.24,20H20a3,3,0,0,0,3-3V11A3,3,0,0,0,22.11,8.87ZM6.42,7.17A4,4,0,0,1,9.24,6h5.52a4,4,0,0,1,2.82,1.17l.83.83H5.59ZM21,17a1,1,0,0,1-1,1H15.24l-.45-.89A2,2,0,0,0,13,16H11a2,2,0,0,0-1.79,1.11L8.76,18H4a1,1,0,0,1-1-1V11a1,1,0,0,1,1-1H20a1,1,0,0,1,1,1Zm-2.5-5H5.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h13a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,18.5,12Z',
  website:
    'M20,4H4A2,2,0,0,0,2,6V18a2,2,0,0,0,2,2H20a2,2,0,0,0,2-2V6A2,2,0,0,0,20,4Zm0,14H4V8H20ZM6.5,16h4a.5.5,0,0,0,.5-.5v-5a.5.5,0,0,0-.5-.5h-4a.5.5,0,0,0-.5.5v5A.5.5,0,0,0,6.5,16Zm7-4h4a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5h-4a.5.5,0,0,0-.5.5v1A.5.5,0,0,0,13.5,12Zm0,4h2a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5h-2a.5.5,0,0,0-.5.5v1A.5.5,0,0,0,13.5,16Z',
  wifi:
    'M22.78,9.25a.46.46,0,0,1-.13.33l-1.32,1.5a.48.48,0,0,1-.36.16c-.52,0-3.29-3.24-9-3.24s-8.45,3.24-9,3.24a.48.48,0,0,1-.36-.16L1.35,9.58a.46.46,0,0,1-.13-.33C1.22,8.55,6,5,12,5,17.81,5,22.78,8.48,22.78,9.25ZM12,11c-3.61,0-6.81,2.08-6.81,2.74a.55.55,0,0,0,.13.34l1.33,1.5a.48.48,0,0,0,.36.16c.53,0,1.77-1.74,5-1.74s4.5,1.74,5,1.74a.48.48,0,0,0,.36-.16l1.33-1.5a.55.55,0,0,0,.13-.34C18.81,13.09,15.6,11,12,11Zm0,6c-1.09,0-2.82.58-2.82,1.26a.52.52,0,0,0,.12.33l1,1.16a.73.73,0,0,0,.56.25h2.22a.73.73,0,0,0,.56-.25l1-1.16a.52.52,0,0,0,.12-.33C14.82,17.59,13.11,17,12,17Z',
  wifi_off:
    'M3.4,3.2C3.2,3,2.9,2.9,2.7,3.1c0,0,0,0,0,0L2.2,3.7C2,3.9,1.9,4.2,2.1,4.4c0,0,0,0,0,0l2.4,2.4c-2,1-3.3,2.1-3.3,2.5 c0,0.1,0,0.2,0.1,0.3l1.3,1.5c0.1,0.1,0.2,0.2,0.4,0.2c0.3,0,1.5-1.2,3.8-2.2l2.4,2.4c-2.3,0.6-4,1.9-4,2.3c0,0.1,0,0.2,0.1,0.3 l1.3,1.5c0.1,0.1,0.2,0.2,0.4,0.2c0.5,0,1.7-1.6,4.8-1.7l6.8,6.8c0.2,0.2,0.5,0.2,0.7,0c0,0,0,0,0,0l0.5-0.5c0.2-0.2,0.2-0.5,0-0.7 c0,0,0,0,0,0L3.4,3.2z M14.8,18.3c0,0.1,0,0.2-0.1,0.3l-1,1.2c-0.1,0.2-0.3,0.3-0.6,0.2h-2.2c-0.2,0-0.4-0.1-0.6-0.2l-1-1.2 c-0.1-0.1-0.1-0.2-0.1-0.3c0-0.7,1.7-1.3,2.8-1.3S14.8,17.6,14.8,18.3z M12,5c-1.3,0-2.6,0.2-3.8,0.5l2.6,2.6C11.2,8,11.6,8,12,8 c5.7,0,8.5,3.2,9,3.2c0.1,0,0.3-0.1,0.4-0.2l1.3-1.5c0.1-0.1,0.1-0.2,0.1-0.3C22.8,8.5,17.8,5,12,5z M18.8,13.7c0-0.5-2.1-2-4.9-2.6 l3.9,3.9l0.9-1C18.8,14,18.8,13.9,18.8,13.7z',
  windows:
    'M10,11a.5.5,0,0,1-.5.5h-6A.5.5,0,0,1,3,11V6.33a.5.5,0,0,1,.42-.49l6-1a.5.5,0,0,1,.58.49ZM21,3.5A.5.5,0,0,0,20.42,3l-9,1.5A.5.5,0,0,0,11,5v6a.5.5,0,0,0,.5.5h9A.5.5,0,0,0,21,11ZM10,13a.5.5,0,0,0-.5-.5h-6A.5.5,0,0,0,3,13v4.67a.5.5,0,0,0,.42.49l6,1a.5.5,0,0,0,.58-.49Zm11,0a.5.5,0,0,0-.5-.5h-9a.5.5,0,0,0-.5.5v6a.5.5,0,0,0,.42.49l9,1.5A.5.5,0,0,0,21,20.5Z',
  arrival:
    'M22,19.5v1a.5.5,0,0,1-.5.5H2.5a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h19A.5.5,0,0,1,22,19.5ZM3.44,10l3.12,2.11a6,6,0,0,0,1.31.66L19.1,16.88a2,2,0,0,0,1.53-.07l.08,0a.5.5,0,0,0,.24-.67l-.46-1a3,3,0,0,0-1.69-1.55l-3.74-1.31L11.9,3.85a1,1,0,0,0-.6-.59L10.67,3A.5.5,0,0,0,10,3.5v7L5.71,9,4.9,6.85a1,1,0,0,0-.6-.59L3.67,6A.5.5,0,0,0,3,6.5V9.19A1,1,0,0,0,3.44,10Z',
  bike:
    'M10.4,16c0.8,0,1.4-0.4,1.8-1.1L15.1,9h0.8l0.6,1.7C15,11.6,14,13.2,14,15c0,2.8,2.2,5,5,5s5-2.2,5-5s-2.2-5-5-5 c-0.2,0-0.4,0-0.6,0.1l-1-3.1H19c0.4,0,0.8,0.3,0.9,0.7C20,7.9,20.2,8,20.4,8h1c0.2,0,0.3-0.1,0.4-0.2s0.1-0.3,0.1-0.4 C21.7,6,20.4,5,19,5h-2.7c-0.4,0-0.8,0.2-1,0.5c-0.2,0.3-0.3,0.7-0.2,1.1L15.3,7h-7C7.6,7,7,7.6,7,8.2v0.3C7,8.7,7,8.8,6.9,9 l-0.6,1.2C5.9,10.1,5.4,10,5,10c-2.8,0-5,2.2-5,5s2.2,5,5,5c2,0,3.8-1.2,4.6-3H7.2c-0.5,0.6-1.3,1-2.2,1c-1.7,0-3-1.3-3-3s1.3-3,3-3 c0.1,0,0.2,0,0.4,0l-0.9,1.8c-0.2,0.5-0.2,1,0.1,1.5S5.3,16,5.8,16H10.4z M6.6,14l0.5-1.1c0.3,0.3,0.5,0.7,0.7,1.1H6.6z M10.4,14 H9.9c-0.2-1.2-0.9-2.2-1.8-2.9l0.6-1.2C8.8,9.6,8.9,9.3,9,9h3.9L10.4,14z M19,18c-1.7,0-3-1.3-3-3c0-1,0.5-1.8,1.2-2.4l0.7,2.2 c0.1,0.3,0.4,0.5,0.7,0.5h1.1c0.2,0,0.4-0.2,0.3-0.5l-1-2.9c1.6,0,2.9,1.4,2.9,3C22,16.7,20.7,18,19,18z M5.5,5h3C8.8,5,9,4.8,9,4.5 v-1C9,3.2,8.8,3,8.5,3h-3C5.2,3,5,3.2,5,3.5v1C5,4.8,5.2,5,5.5,5z',
  boat:
    'M20.64,10.51,19,10V5a2.49,2.49,0,0,0-.73-1.77l-.54-.54A2.49,2.49,0,0,0,16,2H8a2.49,2.49,0,0,0-1.77.73l-.54.54A2.49,2.49,0,0,0,5,5v5l-1.64.49a.5.5,0,0,0-.34.61l1.77,6.56A6.63,6.63,0,0,0,7,18c2.71,0,2.77-1,5-1s2.36,1,5,1a6.63,6.63,0,0,0,2.21-.33L21,11.12h0A.49.49,0,0,0,20.64,10.51ZM17,9.41l-.83-.25-3.6-1.08a2,2,0,0,0-1.14,0L7,9.41V5H17Zm5,10.1v1a.5.5,0,0,1-.45.5c-1.72.12-2.1,1-4.55,1-2.65,0-2.85-1-5-1s-2.28,1-5,1c-2.46,0-2.81-.86-4.55-1a.5.5,0,0,1-.45-.5v-1a.5.5,0,0,1,.54-.5C4.69,19.14,5,20,7,20s2.35-1,5-1,2.85,1,5,1c2,0,2.31-.86,4.46-1A.5.5,0,0,1,22,19.51Z',
  bus:
    'M19.27,3.27l-.54-.54A2.49,2.49,0,0,0,17,2H7a2.49,2.49,0,0,0-1.77.73l-.54.54A2.49,2.49,0,0,0,4,5V21a1,1,0,0,0,1,1H6a1,1,0,0,0,1-1V20H17v1a1,1,0,0,0,1,1h1a1,1,0,0,0,1-1V5A2.49,2.49,0,0,0,19.27,3.27ZM9,17a1,1,0,0,1-1,1H7a1,1,0,0,1-1-1V16a1,1,0,0,1,1-1H8a1,1,0,0,1,1,1Zm9,0a1,1,0,0,1-1,1H16a1,1,0,0,1-1-1V16a1,1,0,0,1,1-1h1a1,1,0,0,1,1,1Zm0-5H6V6H18Z',
  car:
    'M21,11.56a4.11,4.11,0,0,0-.15-1.1l-1.44-5A2,2,0,0,0,17.49,4h-11A2,2,0,0,0,4.59,5.45l-1.44,5A4.11,4.11,0,0,0,3,11.56V19a1,1,0,0,0,1,1H5a1,1,0,0,0,1-1V18H18v1a1,1,0,0,0,1,1h1a1,1,0,0,0,1-1ZM6.27,5.93a.25.25,0,0,1,.24-.18h11a.25.25,0,0,1,.24.18L18.89,10H5.11ZM8,14a1,1,0,0,1-1,1H6a1,1,0,0,1-1-1V13a1,1,0,0,1,1-1H7a1,1,0,0,1,1,1Zm11,0a1,1,0,0,1-1,1H17a1,1,0,0,1-1-1V13a1,1,0,0,1,1-1h1a1,1,0,0,1,1,1Z',
  car_police:
    'M20.85,12.46l-1.44-5A2,2,0,0,0,17.49,6h-11A2,2,0,0,0,4.59,7.45l-1.44,5A4.11,4.11,0,0,0,3,13.56V21a1,1,0,0,0,1,1H5a1,1,0,0,0,1-1V20H18v1a1,1,0,0,0,1,1h1a1,1,0,0,0,1-1V13.56A4.11,4.11,0,0,0,20.85,12.46ZM6.27,7.93a.25.25,0,0,1,.24-.18h11a.25.25,0,0,1,.24.18L18.89,12H5.11ZM8,16a1,1,0,0,1-1,1H6a1,1,0,0,1-1-1V15a1,1,0,0,1,1-1H7a1,1,0,0,1,1,1Zm11,0a1,1,0,0,1-1,1H17a1,1,0,0,1-1-1V15a1,1,0,0,1,1-1h1a1,1,0,0,1,1,1ZM11,2.5a.5.5,0,0,0-.5-.5h-3a.5.5,0,0,0-.5.5V4h4Zm6,0a.5.5,0,0,0-.5-.5h-3a.5.5,0,0,0-.5.5V4h4Z',
  departure:
    'M22,19.5v1a.5.5,0,0,1-.5.5H2.5a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h19A.5.5,0,0,1,22,19.5ZM3.9,14.64a1,1,0,0,0,.87.35l4.49-.46a6.91,6.91,0,0,0,1.43-.33l10.1-3.68a2,2,0,0,0,1.13-1l0-.08a.5.5,0,0,0-.24-.67l-1-.46a3.05,3.05,0,0,0-2.3-.1L14.65,9.56,6.8,5.13A1,1,0,0,0,6,5.06l-.63.23a.49.49,0,0,0-.21.79l4.46,5.33L5.89,12.75h0L3.8,11.57A1,1,0,0,0,3,11.5l-.63.23a.49.49,0,0,0-.21.79Z',
  flight:
    'M22.94,2.86l-.28.77a4,4,0,0,1-.93,1.47L17.41,9.42l2.05,10.23a2.58,2.58,0,0,1,0,.38,1.89,1.89,0,0,1-.57,1.36l-.17.17a.44.44,0,0,1-.34.14.48.48,0,0,1-.41-.23L13.82,13,9.67,16.45l.44,5.2a1,1,0,0,1-.28.68l-.53.53a.48.48,0,0,1-.77-.13L6.11,17.89,1.27,15.47a.48.48,0,0,1-.13-.77l.53-.53a1,1,0,0,1,.68-.28l5.13.43L11,10.18,2.53,6a.48.48,0,0,1-.23-.41.44.44,0,0,1,.14-.34l.17-.17A1.89,1.89,0,0,1,4,4.5a2.58,2.58,0,0,1,.38,0L14.58,6.59,18.9,2.27a4,4,0,0,1,1.47-.93l.77-.28a1,1,0,0,1,1.05.23l.52.52A1,1,0,0,1,22.94,2.86Z',
  flight_cancelled:
    'M22.94,2.86l-.28.77a4,4,0,0,1-.93,1.47L17.41,9.42l1.07,5.35L9.23,5.52l5.35,1.07L18.9,2.27a4,4,0,0,1,1.47-.93l.77-.28a1,1,0,0,1,1.05.23l.52.52A1,1,0,0,1,22.94,2.86ZM3.38,2.15a.48.48,0,0,0-.7,0l-.53.53a.48.48,0,0,0,0,.7L3.37,4.61a1.81,1.81,0,0,0-.76.46l-.17.17a.44.44,0,0,0-.14.34A.48.48,0,0,0,2.53,6l4.4,2.18,3.12,3.12-2.57,3-5.13-.43a1,1,0,0,0-.68.28l-.53.53a.48.48,0,0,0,.13.77l4.84,2.42,2.42,4.84a.48.48,0,0,0,.77.13l.53-.53a1,1,0,0,0,.28-.68l-.44-5.2,3-2.51,3.13,3.13L18,21.47a.48.48,0,0,0,.41.23.44.44,0,0,0,.34-.14l.17-.17a1.81,1.81,0,0,0,.46-.76l1.23,1.22a.48.48,0,0,0,.7,0l.53-.53a.48.48,0,0,0,0-.7Z',
  metro:
    'M19.27,3.27l-.54-.54A2.49,2.49,0,0,0,17,2H7a2.49,2.49,0,0,0-1.77.73l-.54.54A2.49,2.49,0,0,0,4,5V17a2.49,2.49,0,0,0,.73,1.77l.54.54A2.49,2.49,0,0,0,7,20H8L7,21.62a.26.26,0,0,0,.22.38H8.69a.73.73,0,0,0,.65-.38L10.28,20h3.44l.94,1.62a.73.73,0,0,0,.65.38h1.44a.26.26,0,0,0,.22-.38L16,20H17a2.49,2.49,0,0,0,1.77-.73l.54-.54A2.49,2.49,0,0,0,20,17V5A2.49,2.49,0,0,0,19.27,3.27ZM10,16a2,2,0,1,1,2,2A2,2,0,0,1,10,16Zm8-4H6V6H18Z',
  motocycle:
    'M19,10h-1.4l-2.2-4.3c-0.5-1-1.5-1.7-2.7-1.7h-2.3C10.2,4,10,4.2,10,4.5v1C10,5.8,10.2,6,10.5,6h2.3c0.4,0,0.7,0.2,0.9,0.6 l1.7,3.4H5c-2.8,0-5,2.2-5,5s2.2,5,5,5c2,0,3.8-1.2,4.6-3H7.2c-0.5,0.6-1.3,1-2.2,1c-1.7,0-3-1.3-3-3s1.3-3,3-3c1.3,0,2.4,0.8,2.8,2 H4.5C4.2,14,4,14.2,4,14.5v1C4,15.8,4.2,16,4.5,16h3.3H9c0.3,0,0.6,0,0.9-0.1c0,0,0,0,0,0c1.8-0.4,3.1-2,3.1-3.9l2,0 c-0.6,0.8-1,1.9-1,3c0,2.8,2.2,5,5,5s5-2.2,5-5S21.8,10,19,10z M19,18c-1.7,0-3-1.3-3-3c0-0.8,0.3-1.5,0.8-2.1l1,2 c0.1,0.3,0.4,0.4,0.7,0.4h1.2c0.1,0,0.2-0.1,0.3-0.2s0.1-0.2,0-0.3L18.6,12c0.1,0,0.2,0,0.4,0c1.7,0,3,1.3,3,3S20.7,18,19,18z',
  taxi:
    'M21,13.56a4.11,4.11,0,0,0-.15-1.1l-1.44-5A2,2,0,0,0,17.49,6h-11A2,2,0,0,0,4.59,7.45l-1.44,5A4.11,4.11,0,0,0,3,13.56V21a1,1,0,0,0,1,1H5a1,1,0,0,0,1-1V20H18v1a1,1,0,0,0,1,1h1a1,1,0,0,0,1-1ZM6.27,7.93a.25.25,0,0,1,.24-.18h11a.25.25,0,0,1,.24.18L18.89,12H5.11ZM8,16a1,1,0,0,1-1,1H6a1,1,0,0,1-1-1V15a1,1,0,0,1,1-1H7a1,1,0,0,1,1,1Zm11,0a1,1,0,0,1-1,1H17a1,1,0,0,1-1-1V15a1,1,0,0,1,1-1h1a1,1,0,0,1,1,1ZM15,4H9V2.5A.5.5,0,0,1,9.5,2h5a.5.5,0,0,1,.5.5Z',
  traffic_light:
    'M19,6V4.5a.5.5,0,0,0-.5-.5H17a2,2,0,0,0-2-2H9A2,2,0,0,0,7,4H5.5a.5.5,0,0,0-.5.5V6A2,2,0,0,0,7,8v2H5.5a.5.5,0,0,0-.5.5V12a2,2,0,0,0,2,2v2H5.5a.5.5,0,0,0-.5.5V18a2,2,0,0,0,2,2,2,2,0,0,0,2,2h6a2,2,0,0,0,2-2,2,2,0,0,0,2-2V16.5a.5.5,0,0,0-.5-.5H17V14a2,2,0,0,0,2-2V10.5a.5.5,0,0,0-.5-.5H17V8A2,2,0,0,0,19,6ZM12,20a2,2,0,1,1,2-2A2,2,0,0,1,12,20Zm0-6a2,2,0,1,1,2-2A2,2,0,0,1,12,14Zm0-6a2,2,0,1,1,2-2A2,2,0,0,1,12,8Z',
  train:
    'M19.27,3.27l-.54-.54A2.49,2.49,0,0,0,17,2H7a2.49,2.49,0,0,0-1.77.73l-.54.54A2.49,2.49,0,0,0,4,5V17a2.49,2.49,0,0,0,.73,1.77l.54.54A2.49,2.49,0,0,0,7,20H8L7,21.62a.26.26,0,0,0,.22.38H8.69a.73.73,0,0,0,.65-.38L10.28,20h3.44l.94,1.62a.73.73,0,0,0,.65.38h1.44a.26.26,0,0,0,.22-.38L16,20H17a2.49,2.49,0,0,0,1.77-.73l.54-.54A2.49,2.49,0,0,0,20,17V5A2.49,2.49,0,0,0,19.27,3.27ZM6,6h5v6H6ZM6,16.5V16a1,1,0,0,1,1-1H8a1,1,0,0,1,1,1v1a1,1,0,0,1-1,1H7.5A1.5,1.5,0,0,1,6,16.5Zm12,0A1.5,1.5,0,0,1,16.5,18H16a1,1,0,0,1-1-1V16a1,1,0,0,1,1-1h1a1,1,0,0,1,1,1ZM18,12H13V6h5Z',
  tram:
    'M19.27,3.27l-.54-.54A2.49,2.49,0,0,0,17,2H7a2.49,2.49,0,0,0-1.77.73l-.54.54A2.49,2.49,0,0,0,4,5V17a2.49,2.49,0,0,0,.73,1.77l.54.54A2.49,2.49,0,0,0,7,20H8L7,21.62a.26.26,0,0,0,.22.38H8.69a.73.73,0,0,0,.65-.38L10.28,20h3.44l.94,1.62a.73.73,0,0,0,.65.38h1.44a.26.26,0,0,0,.22-.38L16,20H17a2.49,2.49,0,0,0,1.77-.73l.54-.54A2.49,2.49,0,0,0,20,17V5A2.49,2.49,0,0,0,19.27,3.27ZM6,16.5V16a1,1,0,0,1,1-1H8a1,1,0,0,1,1,1v1a1,1,0,0,1-1,1H7.5A1.5,1.5,0,0,1,6,16.5Zm12,0A1.5,1.5,0,0,1,16.5,18H16a1,1,0,0,1-1-1V16a1,1,0,0,1,1-1h1a1,1,0,0,1,1,1ZM18,12H6V6H18Z',
  truck:
    'M19.27,7.27l-.54-.54A2.49,2.49,0,0,0,17,6H7a2.49,2.49,0,0,0-1.77.73l-.54.54A2.49,2.49,0,0,0,4,9V21a1,1,0,0,0,1,1H6a1,1,0,0,0,1-1V20H17v1a1,1,0,0,0,1,1h1a1,1,0,0,0,1-1V9A2.49,2.49,0,0,0,19.27,7.27ZM9,17a1,1,0,0,1-1,1H7a1,1,0,0,1-1-1V16a1,1,0,0,1,1-1H8a1,1,0,0,1,1,1Zm9,0a1,1,0,0,1-1,1H16a1,1,0,0,1-1-1V16a1,1,0,0,1,1-1h1a1,1,0,0,1,1,1Zm0-5H6V9H18ZM17,3a1,1,0,0,0-1-1H8A1,1,0,0,0,7,3V4H17Z',
  add_event:
    'M21,6a2,2,0,0,0-2-2H18V2.5a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5V4H8V2.5A.5.5,0,0,0,7.5,2h-1a.5.5,0,0,0-.5.5V4H5A2,2,0,0,0,3,6V19a2,2,0,0,0,2,2H19a2,2,0,0,0,2-2ZM19,19H5V8H19Zm-4-5.62v-.76a.5.5,0,0,0-.5-.5H12.88V10.5a.5.5,0,0,0-.5-.5h-.76a.5.5,0,0,0-.5.5v1.62H9.5a.5.5,0,0,0-.5.5v.76a.5.5,0,0,0,.5.5h1.62V15.5a.5.5,0,0,0,.5.5h.76a.5.5,0,0,0,.5-.5V13.88H14.5A.5.5,0,0,0,15,13.38Z',
  add_to_trash:
    'M5,8l.87,12.14a2,2,0,0,0,2,1.86h8.28a2,2,0,0,0,2-1.86L19,8Zm11,7.5a.5.5,0,0,1-.5.5H13v2.5a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5V16H8.5a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5H11V11.5a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5V14h2.5a.5.5,0,0,1,.5.5ZM19.5,4H15V3a1,1,0,0,0-1-1H10A1,1,0,0,0,9,3V4H4.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h15a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,19.5,4Z',
  agenda_view:
    'M18,2H6A2,2,0,0,0,4,4V9a2,2,0,0,0,2,2H18a2,2,0,0,0,2-2V4A2,2,0,0,0,18,2Zm2,13v5a2,2,0,0,1-2,2H6a2,2,0,0,1-2-2V15a2,2,0,0,1,2-2H18A2,2,0,0,1,20,15Z',
  agenda_view_outlined:
    'M18,15v5H6V15H18m0-2H6a2,2,0,0,0-2,2v5a2,2,0,0,0,2,2H18a2,2,0,0,0,2-2V15a2,2,0,0,0-2-2Zm0-9V9H6V4H18m0-2H6A2,2,0,0,0,4,4V9a2,2,0,0,0,2,2H18a2,2,0,0,0,2-2V4a2,2,0,0,0-2-2Z',
  alarm:
    'M12,4a9,9,0,1,0,9,9A9,9,0,0,0,12,4Zm0,16a7,7,0,1,1,7-7A7,7,0,0,1,12,20ZM6.94,3.06A.24.24,0,0,0,7,2.88a.28.28,0,0,0-.09-.19A3,3,0,0,0,2.69,6.92.28.28,0,0,0,2.88,7a.24.24,0,0,0,.18-.07ZM19,2a3,3,0,0,0-1.92.69.28.28,0,0,0-.09.19.24.24,0,0,0,.07.18l3.88,3.88a.24.24,0,0,0,.18.07.28.28,0,0,0,.19-.09A3,3,0,0,0,19,2ZM12.88,12.64V8.5a.5.5,0,0,0-.5-.5h-.76a.5.5,0,0,0-.5.5v4.66a.53.53,0,0,0,.15.35l3.29,3.29a.5.5,0,0,0,.71,0l.53-.53a.5.5,0,0,0,0-.71Z',
  alarm_add:
    'M12,4a9,9,0,1,0,9,9A9,9,0,0,0,12,4Zm0,16a7,7,0,1,1,7-7A7,7,0,0,1,12,20Zm3.5-8H13V9.5a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5V12H8.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5H11v2.5a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5V14h2.5a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,15.5,12ZM6.94,3.06A.24.24,0,0,0,7,2.88a.28.28,0,0,0-.09-.19A3,3,0,0,0,2.69,6.92.28.28,0,0,0,2.88,7a.24.24,0,0,0,.18-.07ZM19,2a3,3,0,0,0-1.92.69.28.28,0,0,0-.09.19.24.24,0,0,0,.07.18l3.88,3.88a.24.24,0,0,0,.18.07.28.28,0,0,0,.19-.09A3,3,0,0,0,19,2Z',
  alarm_alert:
    'M12,4a9,9,0,1,0,9,9A9,9,0,0,0,12,4Zm0,16a7,7,0,1,1,7-7A7,7,0,0,1,12,20Zm-.53-7h1.06a.25.25,0,0,0,.25-.22l.4-3.22a.5.5,0,0,0-.5-.56H11.32a.5.5,0,0,0-.5.56l.4,3.22A.25.25,0,0,0,11.47,13Zm1,2h-1a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,12.5,15ZM19,2a3,3,0,0,0-1.92.69.28.28,0,0,0-.09.19.24.24,0,0,0,.07.18l3.88,3.88a.24.24,0,0,0,.18.07.28.28,0,0,0,.19-.09A3,3,0,0,0,19,2ZM6.92,2.69A3,3,0,0,0,2.69,6.92.28.28,0,0,0,2.88,7a.24.24,0,0,0,.18-.07L6.94,3.06A.24.24,0,0,0,7,2.88.28.28,0,0,0,6.92,2.69Z',
  alarm_off:
    'M2.38,2.14a.51.51,0,0,0-.71,0l-.53.53a.51.51,0,0,0,0,.71l.94.94A3.08,3.08,0,0,0,2,5a3,3,0,0,0,.69,1.92A.28.28,0,0,0,2.88,7a.24.24,0,0,0,.18-.07l.82-.82L5.06,7.29A8.91,8.91,0,0,0,3,13a9,9,0,0,0,14.71,7l1.91,1.9a.48.48,0,0,0,.7,0l.53-.53a.48.48,0,0,0,0-.7ZM12,20A7,7,0,0,1,6.47,8.71l9.82,9.82A7,7,0,0,1,12,20ZM6.94,3.06A.24.24,0,0,0,7,2.88a.28.28,0,0,0-.09-.19A3,3,0,0,0,5,2a1.14,1.14,0,0,0-.26,0L6.36,3.64ZM19,2a3,3,0,0,0-1.92.69.28.28,0,0,0-.09.19.24.24,0,0,0,.07.18l3.88,3.88a.24.24,0,0,0,.18.07.28.28,0,0,0,.19-.09A3,3,0,0,0,19,2ZM12,4A8.87,8.87,0,0,0,7.77,5.06l1.5,1.49A7,7,0,0,1,19,13a6.9,6.9,0,0,1-.56,2.73l1.5,1.5A8.87,8.87,0,0,0,21,13,9,9,0,0,0,12,4Z',
  alarm_on:
    'M12,4a9,9,0,1,0,9,9A9,9,0,0,0,12,4Zm0,16a7,7,0,1,1,7-7A7,7,0,0,1,12,20ZM6.94,3.06A.24.24,0,0,0,7,2.88a.28.28,0,0,0-.09-.19A3,3,0,0,0,2.69,6.92.28.28,0,0,0,2.88,7a.24.24,0,0,0,.18-.07ZM19,2a3,3,0,0,0-1.92.69.28.28,0,0,0-.09.19.24.24,0,0,0,.07.18l3.88,3.88a.24.24,0,0,0,.18.07.28.28,0,0,0,.19-.09A3,3,0,0,0,19,2ZM7.65,14a.5.5,0,0,1,0-.71l.53-.53a.48.48,0,0,1,.7,0l1.5,1.49,4.74-4.74a.5.5,0,0,1,.7,0l.53.53a.5.5,0,0,1,0,.71l-5.62,5.62a.48.48,0,0,1-.7,0Z',
  album:
    'M18,2H6A2,2,0,0,0,4,4V20a2,2,0,0,0,2,2H18a2,2,0,0,0,2-2V4A2,2,0,0,0,18,2Zm-6,9.5a.5.5,0,0,1-.78.42l-1.94-1.3a.52.52,0,0,0-.56,0l-1.94,1.3A.5.5,0,0,1,6,11.5v-7A.5.5,0,0,1,6.5,4h5a.5.5,0,0,1,.5.5Z',
  albums:
    'M16,2H4A2,2,0,0,0,2,4V20a2,2,0,0,0,2,2H16a2,2,0,0,0,2-2V4A2,2,0,0,0,16,2Zm-6,9.5a.5.5,0,0,1-.78.42l-1.94-1.3a.52.52,0,0,0-.56,0l-1.94,1.3A.5.5,0,0,1,4,11.5v-7A.5.5,0,0,1,4.5,4h5a.5.5,0,0,1,.5.5ZM21,4H20V20h1a1,1,0,0,0,1-1V5A1,1,0,0,0,21,4Z',
  all_done:
    'M2.56,11.05l5.75,5.74L7.25,17.85a.5.5,0,0,1-.71,0L1.15,12.46a.5.5,0,0,1,0-.71l.7-.7A.5.5,0,0,1,2.56,11.05ZM22.85,6.54l-.7-.71a.51.51,0,0,0-.71,0l-9.55,9.55L10.81,14.3l7-7a.5.5,0,0,0,0-.71l-.7-.71a.51.51,0,0,0-.71,0l-7,7.05L7.56,11.05a.5.5,0,0,0-.71,0l-.7.7a.5.5,0,0,0,0,.71l5.39,5.39a.5.5,0,0,0,.71,0l10.6-10.6A.5.5,0,0,0,22.85,6.54Z',
  apps:
    'M8,4.5v3a.5.5,0,0,1-.5.5h-3A.5.5,0,0,1,4,7.5v-3A.5.5,0,0,1,4.5,4h3A.5.5,0,0,1,8,4.5ZM13.5,4h-3a.5.5,0,0,0-.5.5v3a.5.5,0,0,0,.5.5h3a.5.5,0,0,0,.5-.5v-3A.5.5,0,0,0,13.5,4Zm6,0h-3a.5.5,0,0,0-.5.5v3a.5.5,0,0,0,.5.5h3a.5.5,0,0,0,.5-.5v-3A.5.5,0,0,0,19.5,4Zm-12,6h-3a.5.5,0,0,0-.5.5v3a.5.5,0,0,0,.5.5h3a.5.5,0,0,0,.5-.5v-3A.5.5,0,0,0,7.5,10Zm6,0h-3a.5.5,0,0,0-.5.5v3a.5.5,0,0,0,.5.5h3a.5.5,0,0,0,.5-.5v-3A.5.5,0,0,0,13.5,10Zm6,0h-3a.5.5,0,0,0-.5.5v3a.5.5,0,0,0,.5.5h3a.5.5,0,0,0,.5-.5v-3A.5.5,0,0,0,19.5,10Zm-12,6h-3a.5.5,0,0,0-.5.5v3a.5.5,0,0,0,.5.5h3a.5.5,0,0,0,.5-.5v-3A.5.5,0,0,0,7.5,16Zm6,0h-3a.5.5,0,0,0-.5.5v3a.5.5,0,0,0,.5.5h3a.5.5,0,0,0,.5-.5v-3A.5.5,0,0,0,13.5,16Zm6,0h-3a.5.5,0,0,0-.5.5v3a.5.5,0,0,0,.5.5h3a.5.5,0,0,0,.5-.5v-3A.5.5,0,0,0,19.5,16Z',
  attachment:
    'M18,9.5V17A6,6,0,0,1,6,17V5.5a4.5,4.5,0,0,1,9,0V16a3,3,0,0,1-6,0V9.5A.5.5,0,0,1,9.5,9h.75a.5.5,0,0,1,.5.5V16a1.25,1.25,0,0,0,2.5,0V5.5a2.75,2.75,0,0,0-5.5,0V17a4.25,4.25,0,0,0,8.5,0V9.5a.5.5,0,0,1,.5-.5h.75A.5.5,0,0,1,18,9.5Z',
  attachment_diagonal:
    'M13.31,20.24a6.05,6.05,0,0,1-8.54,0,6,6,0,0,1,0-8.48l8.49-8.44a4.55,4.55,0,0,1,6.41,0,4.47,4.47,0,0,1,0,6.36l-7.79,7.74a3,3,0,0,1-4.27,0,3,3,0,0,1,0-4.25l4.94-4.9a.5.5,0,0,1,.71,0l.54.53a.5.5,0,0,1,0,.71l-4.94,4.9a1.26,1.26,0,0,0,1.78,1.77l7.79-7.73a2.74,2.74,0,0,0,0-3.89,2.78,2.78,0,0,0-3.92,0L6,13a4.25,4.25,0,0,0,0,6,4.32,4.32,0,0,0,6.05,0l5.65-5.61a.51.51,0,0,1,.72,0l.53.53a.48.48,0,0,1,0,.7Z',
  blocked:
    'M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm8,10a7.92,7.92,0,0,1-1.69,4.9L7.1,5.69A7.92,7.92,0,0,1,12,4,8,8,0,0,1,20,12ZM4,12A7.92,7.92,0,0,1,5.69,7.1L16.9,18.31A7.92,7.92,0,0,1,12,20,8,8,0,0,1,4,12Z',
  category:
    'M21,16a1,1,0,0,0-1-1H18V13a2,2,0,0,0-2-2H13V9h2a1,1,0,0,0,1-1V4a1,1,0,0,0-1-1H9A1,1,0,0,0,8,4V8A1,1,0,0,0,9,9h2v2H8a2,2,0,0,0-2,2v2H4a1,1,0,0,0-1,1v4a1,1,0,0,0,1,1h6a1,1,0,0,0,1-1V16a1,1,0,0,0-1-1H8V13h8v2H14a1,1,0,0,0-1,1v4a1,1,0,0,0,1,1h6a1,1,0,0,0,1-1Z',
  category_outlined:
    'M20,15H18V13a2,2,0,0,0-2-2H13V9h2a1,1,0,0,0,1-1V4a1,1,0,0,0-1-1H9A1,1,0,0,0,8,4V8A1,1,0,0,0,9,9h2v2H8a2,2,0,0,0-2,2v2H4a1,1,0,0,0-1,1v4a1,1,0,0,0,1,1h6a1,1,0,0,0,1-1V16a1,1,0,0,0-1-1H8V13h8v2H14a1,1,0,0,0-1,1v4a1,1,0,0,0,1,1h6a1,1,0,0,0,1-1V16A1,1,0,0,0,20,15ZM10,5h4V7H10ZM9,19H5V17H9Zm10,0H15V17h4Z',
  clock:
    'M5.5,18.65a.5.5,0,0,1-.09.69l-.57.46a.9.9,0,0,1-.57.2.51.51,0,0,1-.41-.21,10.06,10.06,0,0,1-1.73-4.21.48.48,0,0,1,.12-.4A.46.46,0,0,1,2.63,15h1a.5.5,0,0,1,.49.41A7.82,7.82,0,0,0,5.5,18.65ZM2.62,13h1a.49.49,0,0,0,.49-.41,8.07,8.07,0,0,1,6.47-6.47A.49.49,0,0,0,11,5.63v-1a.46.46,0,0,0-.18-.38.48.48,0,0,0-.4-.12,10,10,0,0,0-8.3,8.3.48.48,0,0,0,.12.4A.46.46,0,0,0,2.62,13ZM11,9.5a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5ZM7.5,13h-1a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,7.5,13Zm6.08-8.88a.48.48,0,0,0-.4.12.46.46,0,0,0-.18.38v1a.49.49,0,0,0,.41.49A8,8,0,0,1,18.5,18.65a.5.5,0,0,0,.09.69l.57.46a.9.9,0,0,0,.57.2.51.51,0,0,0,.41-.21A10,10,0,0,0,13.58,4.12ZM17.5,13h-1a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,17.5,13Zm-1.05-3L16,9.55a.49.49,0,0,0-.67,0l-5,4.13a1,1,0,0,0-.07,1.48l.57.57a1,1,0,0,0,1.48-.07l4.13-5A.49.49,0,0,0,16.45,10Z',
  dashboard:
    'M11,4V8a1,1,0,0,1-1,1H4A1,1,0,0,1,3,8V4A1,1,0,0,1,4,3h6A1,1,0,0,1,11,4Zm-1,7H4a1,1,0,0,0-1,1v8a1,1,0,0,0,1,1h6a1,1,0,0,0,1-1V12A1,1,0,0,0,10,11Zm10,4H14a1,1,0,0,0-1,1v4a1,1,0,0,0,1,1h6a1,1,0,0,0,1-1V16A1,1,0,0,0,20,15ZM20,3H14a1,1,0,0,0-1,1v8a1,1,0,0,0,1,1h6a1,1,0,0,0,1-1V4A1,1,0,0,0,20,3Z',
  dashboard_outlined:
    'M10,3H4A1,1,0,0,0,3,4V8A1,1,0,0,0,4,9h6a1,1,0,0,0,1-1V4A1,1,0,0,0,10,3ZM9,7H5V5H9Zm1,4H4a1,1,0,0,0-1,1v8a1,1,0,0,0,1,1h6a1,1,0,0,0,1-1V12A1,1,0,0,0,10,11ZM9,19H5V13H9Zm11-4H14a1,1,0,0,0-1,1v4a1,1,0,0,0,1,1h6a1,1,0,0,0,1-1V16A1,1,0,0,0,20,15Zm-1,4H15V17h4ZM20,3H14a1,1,0,0,0-1,1v8a1,1,0,0,0,1,1h6a1,1,0,0,0,1-1V4A1,1,0,0,0,20,3Zm-1,8H15V5h4Z',
  dashboard_vs:
    'M11,4V20a1,1,0,0,1-1,1H4a1,1,0,0,1-1-1V4A1,1,0,0,1,4,3h6A1,1,0,0,1,11,4Zm3,5h6a1,1,0,0,0,1-1V4a1,1,0,0,0-1-1H14a1,1,0,0,0-1,1V8A1,1,0,0,0,14,9Zm0,12h6a1,1,0,0,0,1-1V12a1,1,0,0,0-1-1H14a1,1,0,0,0-1,1v8A1,1,0,0,0,14,21Z',
  dashboard_vs_outlined:
    'M9,5V19H5V5H9m1-2H4A1,1,0,0,0,3,4V20a1,1,0,0,0,1,1h6a1,1,0,0,0,1-1V4a1,1,0,0,0-1-1Zm9,2V7H15V5h4m1-2H14a1,1,0,0,0-1,1V8a1,1,0,0,0,1,1h6a1,1,0,0,0,1-1V4a1,1,0,0,0-1-1ZM19,13v6H15V13h4m1-2H14a1,1,0,0,0-1,1v8a1,1,0,0,0,1,1h6a1,1,0,0,0,1-1V12a1,1,0,0,0-1-1Z',
  delete:
    'M20,4.5v1a.5.5,0,0,1-.5.5H4.5A.5.5,0,0,1,4,5.5v-1A.5.5,0,0,1,4.5,4H9V3a1,1,0,0,1,1-1h4a1,1,0,0,1,1,1V4h4.5A.5.5,0,0,1,20,4.5ZM5.87,20.14a2,2,0,0,0,2,1.86h8.28a2,2,0,0,0,2-1.86L19,8H5Z',
  delete_outlined:
    'M20,4.5v1a.5.5,0,0,1-.5.5H4.5A.5.5,0,0,1,4,5.5v-1A.5.5,0,0,1,4.5,4H9V3a1,1,0,0,1,1-1h4a1,1,0,0,1,1,1V4h4.5A.5.5,0,0,1,20,4.5ZM16.28,18H7.72L7,8H5l.87,12.14a2,2,0,0,0,2,1.86h8.28a2,2,0,0,0,2-1.86L19,8H17Z',
  download:
    'M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm3.85,12.64-3.13,3.14a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L8.15,14.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L11,14.67V6.5a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5v8.17l1.44-1.45a.5.5,0,0,1,.71,0l.7.71A.5.5,0,0,1,15.85,14.64Z',
  download_outlined:
    'M12,22A10,10,0,1,0,2,12,10,10,0,0,0,12,22ZM12,4a8,8,0,1,1-8,8A8,8,0,0,1,12,4Zm-.72,13.78a.77.77,0,0,0,.53.22h.38a.75.75,0,0,0,.53-.22l3.13-3.14a.5.5,0,0,0,0-.71l-.7-.71a.5.5,0,0,0-.71,0L13,14.67V6.5a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5v8.17L9.56,13.23a.49.49,0,0,0-.7,0l-.71.71a.5.5,0,0,0,0,.71Z',
  download_to:
    'M19,18.5v1a.5.5,0,0,1-.5.5H5.5a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h13A.5.5,0,0,1,19,18.5Zm-7.35-2.65a.48.48,0,0,0,.7,0l5.79-5.79a.5.5,0,0,0,0-.71l-.2-.2A.49.49,0,0,0,17.58,9H15V3.5a.5.5,0,0,0-.5-.5h-5a.5.5,0,0,0-.5.5V9H6.42a.49.49,0,0,0-.36.15l-.2.2a.5.5,0,0,0,0,.71Z',
  edit:
    'M16.81,10a.5.5,0,0,1,0,.7L8.75,18.79a2,2,0,0,1-.68.45L3.73,21a.5.5,0,0,1-.54-.11l0,0A.5.5,0,0,1,3,20.27l1.72-4.34a2,2,0,0,1,.45-.68l8.07-8.07a.5.5,0,0,1,.71,0ZM20.56,4.1l-.66-.66A1.5,1.5,0,0,0,18.84,3h-.76A1.5,1.5,0,0,0,17,3.44L15.19,5.27a.5.5,0,0,0,0,.7L18,8.82a.5.5,0,0,0,.71,0L20.56,7A1.5,1.5,0,0,0,21,5.92V5.16A1.5,1.5,0,0,0,20.56,4.1Z',
  explore:
    'M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm4.47,6.42-2,4.93a2,2,0,0,1-1.09,1.09l-4.93,2a.49.49,0,0,1-.54-.11l-.24-.24a.49.49,0,0,1-.11-.54l2-4.93a2,2,0,0,1,1.09-1.09l4.93-2a.49.49,0,0,1,.54.11l.24.24A.49.49,0,0,1,16.47,8.42Z',
  explore_off:
    'M4.38,3.15a.48.48,0,0,0-.7,0l-.53.53a.48.48,0,0,0,0,.7l1.19,1.2A10,10,0,0,0,18.42,19.66l1.2,1.19a.48.48,0,0,0,.7,0l.53-.53a.48.48,0,0,0,0-.7Zm4,13.32a.49.49,0,0,1-.54-.11l-.24-.24a.49.49,0,0,1-.11-.54l2-4.82,3.72,3.72Zm7.16-8.94a.49.49,0,0,1,.54.11l.24.24a.49.49,0,0,1,.11.54L15.2,11.49,20.67,17A10,10,0,0,0,7,3.33L12.51,8.8Z',
  explore_off_outlined:
    'M4.38,3.15a.48.48,0,0,0-.7,0l-.53.53a.48.48,0,0,0,0,.7l1.19,1.2A10,10,0,0,0,18.42,19.66l1.2,1.19a.48.48,0,0,0,.7,0l.53-.53a.48.48,0,0,0,0-.7ZM12,20A8,8,0,0,1,5.77,7l3.75,3.74-2,4.83a.49.49,0,0,0,.11.54l.24.24a.49.49,0,0,0,.54.11l4.83-2L17,18.23A7.94,7.94,0,0,1,12,20ZM12,4a8,8,0,0,1,7.2,11.49L20.68,17A10,10,0,0,0,7,3.32L8.51,4.8A8,8,0,0,1,12,4Zm3.58,3.53L12.51,8.8l2.69,2.69,1.27-3.07a.49.49,0,0,0-.11-.54l-.24-.24A.49.49,0,0,0,15.58,7.53Z',
  explore_outlined:
    'M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20ZM16.12,7.64a.49.49,0,0,0-.54-.11l-4.93,2a2,2,0,0,0-1.09,1.09l-2,4.93a.49.49,0,0,0,.11.54l.24.24a.49.49,0,0,0,.54.11l4.93-2a2,2,0,0,0,1.09-1.09l2-4.93a.49.49,0,0,0-.11-.54Z',
  feed:
    'M21.5,6h-1a.5.5,0,0,0-.5.5V17a1,1,0,0,1-2,0V6a2,2,0,0,0-2-2H4A2,2,0,0,0,2,6V17a3,3,0,0,0,3,3H19a3,3,0,0,0,3-3V6.5A.5.5,0,0,0,21.5,6ZM15,15.5a.5.5,0,0,1-.5.5h-9a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h9a.5.5,0,0,1,.5.5Zm0-4a.5.5,0,0,1-.5.5h-9a.5.5,0,0,1-.5-.5v-3A.5.5,0,0,1,5.5,8h9a.5.5,0,0,1,.5.5Z',
  feed_outlined:
    'M21.5,6h-1a.5.5,0,0,0-.5.5V17a1,1,0,0,1-2,0V6a2,2,0,0,0-2-2H4A2,2,0,0,0,2,6V17a3,3,0,0,0,3,3H19a3,3,0,0,0,3-3V6.5A.5.5,0,0,0,21.5,6ZM5,18a1,1,0,0,1-1-1V6H16V17a3,3,0,0,0,.18,1ZM13.5,8h-7a.5.5,0,0,0-.5.5v3a.5.5,0,0,0,.5.5h7a.5.5,0,0,0,.5-.5v-3A.5.5,0,0,0,13.5,8Zm0,6h-7a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h7a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,13.5,14Z',
  filter:
    'M10,13.83a2,2,0,0,0-.59-1.42L3.59,6.59A2,2,0,0,1,3,5.17V4A1,1,0,0,1,4,3H20a1,1,0,0,1,1,1V5.17a2,2,0,0,1-.59,1.42l-5.82,5.82A2,2,0,0,0,14,13.83V17.5a2,2,0,0,1-.8,1.6l-2.4,1.8a.5.5,0,0,1-.8-.4Z',
  filter_list:
    'M20.5,7H3.5A.5.5,0,0,1,3,6.5v-1A.5.5,0,0,1,3.5,5h17a.5.5,0,0,1,.5.5v1A.5.5,0,0,1,20.5,7ZM18,12.5v-1a.5.5,0,0,0-.5-.5H6.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h11A.5.5,0,0,0,18,12.5Zm-3,6v-1a.5.5,0,0,0-.5-.5h-5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h5A.5.5,0,0,0,15,18.5Z',
  filter_outlined:
    'M20,3H4A1,1,0,0,0,3,4V5.17a2,2,0,0,0,.59,1.42l5.82,5.82A2,2,0,0,1,10,13.83V20.5a.5.5,0,0,0,.8.4l2.4-1.8a2,2,0,0,0,.8-1.6V13.83a2,2,0,0,1,.59-1.42l5.82-5.82A2,2,0,0,0,21,5.17V4A1,1,0,0,0,20,3ZM19,5.17,16.17,8H7.83L5,5.17V5H19Z',
  format_bullets:
    'M21,5.5v1a.5.5,0,0,1-.5.5H9.5A.5.5,0,0,1,9,6.5v-1A.5.5,0,0,1,9.5,5h11A.5.5,0,0,1,21,5.5ZM20.5,11H9.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h11a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,20.5,11Zm0,6H9.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h11a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,20.5,17ZM5,4A2,2,0,1,0,7,6,2,2,0,0,0,5,4Zm0,6a2,2,0,1,0,2,2A2,2,0,0,0,5,10Zm0,6a2,2,0,1,0,2,2A2,2,0,0,0,5,16Z',
  format_points:
    'M3.5,5h1a.5.5,0,0,1,.5.5v1a.5.5,0,0,1-.5.5h-1A.5.5,0,0,1,3,6.5v-1A.5.5,0,0,1,3.5,5Zm17,0H7.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h13a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,20.5,5ZM3,11.5v1a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5h-1A.5.5,0,0,0,3,11.5ZM20.5,11H7.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h13a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,20.5,11ZM3,17.5v1a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5h-1A.5.5,0,0,0,3,17.5ZM20.5,17H7.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h13a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,20.5,17Z',
  hidden:
    'M4.38,3.15a.48.48,0,0,0-.7,0l-.53.53a.48.48,0,0,0,0,.7L5.75,7a9.66,9.66,0,0,0-3.69,4.68h0A1,1,0,0,0,2,12a.81.81,0,0,0,.06.31h0A10.65,10.65,0,0,0,12,19a11,11,0,0,0,4.67-1.09l2.95,2.94a.48.48,0,0,0,.7,0l.53-.53a.48.48,0,0,0,0-.7ZM12,15a3,3,0,0,1-3-3,2.89,2.89,0,0,1,.37-1.4l4,4A2.89,2.89,0,0,1,12,15Zm10-3.3v0A10.67,10.67,0,0,0,9.12,5.41L19.61,15.9a9.41,9.41,0,0,0,2.33-3.57h0A.81.81,0,0,0,22,12v0A.93.93,0,0,0,22,11.7Z',
  hidden_outlined:
    'M4.38,3.15a.48.48,0,0,0-.7,0l-.53.53a.48.48,0,0,0,0,.7L5.75,7a9.66,9.66,0,0,0-3.69,4.68h0A1,1,0,0,0,2,12a.81.81,0,0,0,.06.31h0A10.65,10.65,0,0,0,12,19a10.82,10.82,0,0,0,4.67-1.09l2.95,2.94a.48.48,0,0,0,.7,0l.53-.53a.48.48,0,0,0,0-.7ZM12,17a8.82,8.82,0,0,1-7.93-5A8.11,8.11,0,0,1,7.2,8.44L9.36,10.6a3,3,0,0,0,4,4l1.76,1.76A9,9,0,0,1,12,17Zm10-5.3v0A10.71,10.71,0,0,0,9.12,5.4l1.69,1.69A8,8,0,0,1,12,7a8.82,8.82,0,0,1,7.93,5,7.26,7.26,0,0,1-1.74,2.47l1.42,1.42a9.11,9.11,0,0,0,2.33-3.56h0A.81.81,0,0,0,22,12v0A.93.93,0,0,0,22,11.7Z',
  home:
    'M21,12.24V20a1,1,0,0,1-1,1H16a1,1,0,0,1-1-1V14.5a.5.5,0,0,0-.5-.5h-5a.5.5,0,0,0-.5.5V20a1,1,0,0,1-1,1H4a1,1,0,0,1-1-1V12.24a3,3,0,0,1,.88-2.12l6.83-6.83a1,1,0,0,1,.7-.29h1.18a1,1,0,0,1,.7.29l6.83,6.83A3,3,0,0,1,21,12.24Z',
  home_outlined:
    'M12.17,5l6.54,6.54a1,1,0,0,1,.29.7V19H16V13a1,1,0,0,0-1-1H9a1,1,0,0,0-1,1v6H5V12.24a1,1,0,0,1,.29-.7L11.83,5h.34m.42-2H11.41a1,1,0,0,0-.7.29L3.88,10.12A3,3,0,0,0,3,12.24V20a1,1,0,0,0,1,1H9a1,1,0,0,0,1-1V14.25a.25.25,0,0,1,.25-.25h3.5a.25.25,0,0,1,.25.25V20a1,1,0,0,0,1,1h5a1,1,0,0,0,1-1V12.24a3,3,0,0,0-.88-2.12L13.29,3.29a1,1,0,0,0-.7-.29Z',
  home_vs:
    'M21.71,10.71,14.29,3.29a1,1,0,0,0-.7-.29H10.41a1,1,0,0,0-.7.29L2.29,10.71a1,1,0,0,0-.29.7V12.5a.5.5,0,0,0,.5.5H4v7a1,1,0,0,0,1,1H8a1,1,0,0,0,1-1V14.5a.5.5,0,0,1,.5-.5h5a.5.5,0,0,1,.5.5V20a1,1,0,0,0,1,1h3a1,1,0,0,0,1-1V13h1.5a.5.5,0,0,0,.5-.5V11.41A1,1,0,0,0,21.71,10.71Z M21.71,10.71,14.29,3.29a1,1,0,0,0-.7-.29H10.41a1,1,0,0,0-.7.29L2.29,10.71a1,1,0,0,0-.29.7V12.5a.5.5,0,0,0,.5.5H4v7a1,1,0,0,0,1,1H8a1,1,0,0,0,1-1V14.5a.5.5,0,0,1,.5-.5h5a.5.5,0,0,1,.5.5V20a1,1,0,0,0,1,1h3a1,1,0,0,0,1-1V13h1.5a.5.5,0,0,0,.5-.5V11.41A1,1,0,0,0,21.71,10.71Z',
  home_vs_1_outlined:
    'M2,13.5a.5.5,0,0,0,.5.5H4v6a1,1,0,0,0,1,1h5a1,1,0,0,0,1-1V15.25a.25.25,0,0,1,.25-.25h1.5a.25.25,0,0,1,.25.25V20a1,1,0,0,0,1,1h5a1,1,0,0,0,1-1V14h1.5a.5.5,0,0,0,.5-.5V11.41a1,1,0,0,0-.29-.7L14.29,3.29a1,1,0,0,0-.7-.29H10.41a1,1,0,0,0-.7.29L2.29,10.71a1,1,0,0,0-.29.7ZM15,19V14a1,1,0,0,0-1-1H10a1,1,0,0,0-1,1v5H6V12.5a.5.5,0,0,0-.5-.5H4.05a.89.89,0,0,1,.24-.46L10.83,5h2.34l6.54,6.54A.89.89,0,0,1,20,12H18.5a.5.5,0,0,0-.5.5V19Z',
  home_vs_2_outlined:
    'M21.71,10.71,14.29,3.29a1,1,0,0,0-.7-.29H10.41a1,1,0,0,0-.7.29L2.29,10.71a1,1,0,0,0-.29.7V12.5a.5.5,0,0,0,.5.5H4v7a1,1,0,0,0,1,1h5a1,1,0,0,0,1-1V15.25a.25.25,0,0,1,.25-.25h1.5a.25.25,0,0,1,.25.25V20a1,1,0,0,0,1,1h5a1,1,0,0,0,1-1V13h1.5a.5.5,0,0,0,.5-.5V11.41A1,1,0,0,0,21.71,10.71ZM18,19H15V14a1,1,0,0,0-1-1H10a1,1,0,0,0-1,1v5H6V9.83L10.83,5h2.34L18,9.83Z',
  hot:
    'M12.13,2.15A1,1,0,0,0,11.6,2h-.1a.5.5,0,0,0-.5.5V7.12a.5.5,0,0,1-.92.27A7.17,7.17,0,0,0,7.64,5.64a.47.47,0,0,0-.43,0A.49.49,0,0,0,7,6.05c-.31,2.83-3,4-3,8a8,8,0,0,0,16,0C20,7.77,14.54,3.66,12.13,2.15ZM12,20a4,4,0,0,1-4-4c0-2.7,2.72-4.26,3-5.78a.25.25,0,0,1,.28-.2A6.59,6.59,0,0,1,16,16,4,4,0,0,1,12,20Z',
  import:
    'M11.28,16.78a.75.75,0,0,0,.53.22h.38a.77.77,0,0,0,.53-.22l5.13-5.14a.5.5,0,0,0,0-.71l-.71-.71a.49.49,0,0,0-.7,0L13,13.67V3.5a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5V13.67L7.56,10.22a.5.5,0,0,0-.71,0l-.7.71a.5.5,0,0,0,0,.71ZM12,14.67ZM21,19V15.5a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5V19H5V15.5a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5V19a2,2,0,0,0,2,2H19A2,2,0,0,0,21,19Z',
  invisible:
    'M22,11.7v0A10.65,10.65,0,0,0,12,5a10.65,10.65,0,0,0-9.94,6.67h0A1,1,0,0,0,2,12a.81.81,0,0,0,.06.31h0A10.65,10.65,0,0,0,12,19a10.65,10.65,0,0,0,9.94-6.67h0A.81.81,0,0,0,22,12v0A.93.93,0,0,0,22,11.7ZM12,17a8.82,8.82,0,0,1-7.93-5,8.79,8.79,0,0,1,15.86,0A8.82,8.82,0,0,1,12,17Zm0-7a2,2,0,1,1-2,2,2,2,0,0,1,2-2m0-2a4,4,0,1,0,4,4,4,4,0,0,0-4-4Z',
  launch:
    'M20.71,3.72l-.43-.43A1,1,0,0,0,19.39,3l-5.5,1a3.89,3.89,0,0,0-2.36,1.41L9.37,8.19A3,3,0,0,0,5.91,9.28l-2.82,4a.5.5,0,0,0,.12.69l.41.29a3,3,0,0,0,4.18-.74l.63-.89,2.91,2.91-.89.63a3,3,0,0,0-.74,4.18l.29.41a.5.5,0,0,0,.69.12l4-2.82a3,3,0,0,0,1.09-3.46l2.75-2.16A3.89,3.89,0,0,0,20,10.11l1-5.5A1,1,0,0,0,20.71,3.72ZM16.56,9.56a1.5,1.5,0,1,1,0-2.12A1.49,1.49,0,0,1,16.56,9.56ZM7.82,16.88a.5.5,0,0,1,0,.71L4.56,20.85a.5.5,0,0,1-.71,0l-.7-.7a.5.5,0,0,1,0-.71l3.26-3.26a.5.5,0,0,1,.71,0Z',
  launch_outlined:
    'M20.71,3.72l-.43-.43A1,1,0,0,0,19.39,3l-5.5,1a3.89,3.89,0,0,0-2.36,1.41L9.37,8.19A3,3,0,0,0,5.91,9.28l-2.82,4a.5.5,0,0,0,.12.69l.41.29a3,3,0,0,0,4.18-.74l.63-.89,2.91,2.91-.89.63a3,3,0,0,0-.74,4.18l.29.41a.5.5,0,0,0,.69.12l4-2.82a3,3,0,0,0,1.09-3.46l2.75-2.16A3.89,3.89,0,0,0,20,10.11l1-5.5A1,1,0,0,0,20.71,3.72ZM9.32,11.07,12.9,6.52a2.24,2.24,0,0,1,1.3-.78l1.53-.28,2.81,2.81L18.26,9.8a2.23,2.23,0,0,1-.77,1.29l-4.56,3.59Zm4.12-2.63a1.5,1.5,0,0,0,2.12,2.12,1.5,1.5,0,0,0-2.12-2.12ZM7.12,16.18a.5.5,0,0,0-.71,0L3.15,19.44a.5.5,0,0,0,0,.71l.7.7a.5.5,0,0,0,.71,0l3.26-3.26a.5.5,0,0,0,0-.71Z',
  launch_vs:
    'M20.41,3.83l-.24-.24A2,2,0,0,0,18.76,3H17.24a8,8,0,0,0-5.65,2.34L10.37,6.56,10,7H8.2a2,2,0,0,0-1.6.8L3.1,12.47a.49.49,0,0,0,0,.65l1.36,1.36a1.5,1.5,0,0,0,2.21-.1l.09-.11,2.92,2.92-.11.09a1.5,1.5,0,0,0-.1,2.21l1.36,1.36a.49.49,0,0,0,.65,0l4.67-3.5a2,2,0,0,0,.8-1.6V14.05l.44-.42,1.22-1.22A8,8,0,0,0,21,6.76V5.24A2,2,0,0,0,20.41,3.83Zm-5,4.76a2,2,0,1,1-2.82,0A2,2,0,0,1,15.41,8.59ZM7.21,17.5a.48.48,0,0,1,0,.7L4.56,20.85a.5.5,0,0,1-.71,0l-.7-.7a.5.5,0,0,1,0-.71L5.8,16.79a.48.48,0,0,1,.7,0Z',
  launch_vs_outlined:
    'M20.41,3.83l-.24-.24A2,2,0,0,0,18.76,3H17.24a8,8,0,0,0-5.65,2.34L10.37,6.56,10,7H8.2a2,2,0,0,0-1.6.8L3.1,12.47a.49.49,0,0,0,0,.65l1.36,1.36a1.5,1.5,0,0,0,2.21-.1l.09-.11,2.92,2.92-.11.09a1.5,1.5,0,0,0-.1,2.21l1.36,1.36a.49.49,0,0,0,.65,0l4.67-3.5a2,2,0,0,0,.8-1.6V14.05l.44-.42,1.22-1.22A8,8,0,0,0,21,6.76V5.24A2,2,0,0,0,20.41,3.83ZM17.24,11,16,12.21a28.11,28.11,0,0,1-4.27,3.5l-.4.27L8,12.64l.27-.4A28.11,28.11,0,0,1,11.79,8L13,6.76a6,6,0,0,1,1.29-1l3.93,3.93A6,6,0,0,1,17.24,11ZM14.56,9.44a1.5,1.5,0,1,1-2.12,0A1.49,1.49,0,0,1,14.56,9.44ZM7.21,17.5a.48.48,0,0,1,0,.7L4.56,20.85a.5.5,0,0,1-.71,0l-.7-.7a.5.5,0,0,1,0-.71L5.8,16.79a.48.48,0,0,1,.7,0Z',
  lock:
    'M18,10V8A6,6,0,0,0,6,8v2a2,2,0,0,0-2,2v8a2,2,0,0,0,2,2H18a2,2,0,0,0,2-2V12A2,2,0,0,0,18,10ZM8,8a4,4,0,0,1,8,0v2H8Zm6,9a1,1,0,0,1-1,1H11a1,1,0,0,1-1-1V15a1,1,0,0,1,1-1h2a1,1,0,0,1,1,1Z',
  lock_opened:
    'M18,10H8V8a4,4,0,0,1,8,0h2A6,6,0,0,0,6,8v2a2,2,0,0,0-2,2v8a2,2,0,0,0,2,2H18a2,2,0,0,0,2-2V12A2,2,0,0,0,18,10Zm-4,7a1,1,0,0,1-1,1H11a1,1,0,0,1-1-1V15a1,1,0,0,1,1-1h2a1,1,0,0,1,1,1Z',
  lock_open_outlined:
    'M18,10H8V8a4,4,0,0,1,8,0h2A6,6,0,0,0,6,8v2a2,2,0,0,0-2,2v8a2,2,0,0,0,2,2H18a2,2,0,0,0,2-2V12A2,2,0,0,0,18,10Zm0,10H6V12H18Z',
  lock_outlined:
    'M18,12m0,0v8H6V12ZM12,2A6,6,0,0,0,6,8v2a2,2,0,0,0-2,2v8a2,2,0,0,0,2,2H18a2,2,0,0,0,2-2V12a2,2,0,0,0-2-2V8a6,6,0,0,0-6-6ZM8,10V8a4,4,0,0,1,8,0v2Z',
  menu_hamburger:
    'M20.5,7H3.5A.5.5,0,0,1,3,6.5v-1A.5.5,0,0,1,3.5,5h17a.5.5,0,0,1,.5.5v1A.5.5,0,0,1,20.5,7Zm.5,5.5v-1a.5.5,0,0,0-.5-.5H3.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h17A.5.5,0,0,0,21,12.5Zm0,6v-1a.5.5,0,0,0-.5-.5H3.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h17A.5.5,0,0,0,21,18.5Z',
  menu_left:
    'M20.5,7H3.5A.5.5,0,0,1,3,6.5v-1A.5.5,0,0,1,3.5,5h17a.5.5,0,0,1,.5.5v1A.5.5,0,0,1,20.5,7ZM15,12.5v-1a.5.5,0,0,0-.5-.5H3.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h11A.5.5,0,0,0,15,12.5Zm-6,6v-1a.5.5,0,0,0-.5-.5h-5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h5A.5.5,0,0,0,9,18.5Z',
  menu_left_right:
    'M20.5,7H3.5A.5.5,0,0,1,3,6.5v-1A.5.5,0,0,1,3.5,5h17a.5.5,0,0,1,.5.5v1A.5.5,0,0,1,20.5,7Zm.5,5.5v-1a.5.5,0,0,0-.5-.5H9.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h11A.5.5,0,0,0,21,12.5Zm0,6v-1a.5.5,0,0,0-.5-.5h-5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h5A.5.5,0,0,0,21,18.5Z',
  menu_vs:
    'M10,5V8a2,2,0,0,1-2,2H5A2,2,0,0,1,3,8V5A2,2,0,0,1,5,3H8A2,2,0,0,1,10,5Zm9-2H16a2,2,0,0,0-2,2V8a2,2,0,0,0,2,2h3a2,2,0,0,0,2-2V5A2,2,0,0,0,19,3ZM8,14H5a2,2,0,0,0-2,2v3a2,2,0,0,0,2,2H8a2,2,0,0,0,2-2V16A2,2,0,0,0,8,14Zm11,0H16a2,2,0,0,0-2,2v3a2,2,0,0,0,2,2h3a2,2,0,0,0,2-2V16A2,2,0,0,0,19,14Z',
  menu_vs_outlined:
    'M8,3H5A2,2,0,0,0,3,5V8a2,2,0,0,0,2,2H8a2,2,0,0,0,2-2V5A2,2,0,0,0,8,3ZM8,8H5V5H8ZM19,3H16a2,2,0,0,0-2,2V8a2,2,0,0,0,2,2h3a2,2,0,0,0,2-2V5A2,2,0,0,0,19,3Zm0,5H16V5h3Zm-9,8a2,2,0,0,0-2-2H5a2,2,0,0,0-2,2v3a2,2,0,0,0,2,2H8a2,2,0,0,0,2-2ZM8,19H5V16H8Zm11-5H16a2,2,0,0,0-2,2v3a2,2,0,0,0,2,2h3a2,2,0,0,0,2-2V16A2,2,0,0,0,19,14Zm0,5H16V16h3Z',
  more_horizontal:
    'M18,10a2,2,0,1,1-2,2A2,2,0,0,1,18,10Zm-4,2a2,2,0,1,0-2,2A2,2,0,0,0,14,12ZM8,12a2,2,0,1,0-2,2A2,2,0,0,0,8,12Z',
  more_vertical:
    'M10,6a2,2,0,1,1,2,2A2,2,0,0,1,10,6Zm2,4a2,2,0,1,0,2,2A2,2,0,0,0,12,10Zm0,6a2,2,0,1,0,2,2A2,2,0,0,0,12,16Z',
  new_message:
    'M21,11.5V19a2,2,0,0,1-2,2H5a2,2,0,0,1-2-2V5A2,2,0,0,1,5,3h7.5a.5.5,0,0,1,.5.5v1a.5.5,0,0,1-.5.5H5V19H19V11.5a.5.5,0,0,1,.5-.5h1A.5.5,0,0,1,21,11.5Zm-.29-7.79-.42-.42a1,1,0,0,0-.7-.29h-.3a1,1,0,0,0-.7.29l-1.8,1.8a.5.5,0,0,0,0,.7l1.42,1.42a.5.5,0,0,0,.7,0l1.8-1.8a1,1,0,0,0,.29-.7v-.3A1,1,0,0,0,20.71,3.71ZM7.83,14.29l-.79,2a.5.5,0,0,0,.11.54l0,0a.5.5,0,0,0,.54.11l2-.79a2,2,0,0,0,.68-.44L17,9.15a.5.5,0,0,0,0-.7L15.55,7a.5.5,0,0,0-.7,0L8.27,13.61A2,2,0,0,0,7.83,14.29Z',
  password:
    'M18,10V8A6,6,0,0,0,6,8v2a2,2,0,0,0-2,2v8a2,2,0,0,0,2,2H18a2,2,0,0,0,2-2V12A2,2,0,0,0,18,10ZM8,8a4,4,0,0,1,8,0v2H8Zm7,7.5a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5v1a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5Zm-4,0a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5v1a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5Zm-4,1v-1a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5v1a.5.5,0,0,1-.5.5h-1A.5.5,0,0,1,7,16.5Z',
  password_open:
    'M8,10V8a4,4,0,0,1,8,0h2A6,6,0,0,0,6,8v2a2,2,0,0,0-2,2v8a2,2,0,0,0,2,2H18a2,2,0,0,0,2-2V12a2,2,0,0,0-2-2Zm7,5.5a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5v1a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5Zm-4,0a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5v1a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5Zm-4,1v-1a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5v1a.5.5,0,0,1-.5.5h-1A.5.5,0,0,1,7,16.5Z',
  remove_from_trash:
    'M5,8l.87,12.14a2,2,0,0,0,2,1.86h8.28a2,2,0,0,0,2-1.86L19,8Zm10.09,8.86a.48.48,0,0,1,0,.7l-.53.53a.48.48,0,0,1-.7,0L12,16.24l-1.86,1.85a.48.48,0,0,1-.7,0l-.53-.53a.48.48,0,0,1,0-.7L10.76,15,8.91,13.14a.48.48,0,0,1,0-.7l.53-.53a.48.48,0,0,1,.7,0L12,13.76l1.86-1.85a.48.48,0,0,1,.7,0l.53.53a.48.48,0,0,1,0,.7L13.24,15ZM19.5,4H15V3a1,1,0,0,0-1-1H10A1,1,0,0,0,9,3V4H4.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h15a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,19.5,4Z',
  settings:
    'M21,14.73l-.8-.66a2,2,0,0,1-.72-1.64c0-.14,0-.29,0-.43s0-.29,0-.43a2,2,0,0,1,.72-1.64l.8-.66A1,1,0,0,0,21.24,8L20.08,6a1,1,0,0,0-1.21-.43l-1,.36a2,2,0,0,1-1.78-.19,5.48,5.48,0,0,0-.74-.43,2,2,0,0,1-1.06-1.45l-.17-1a1,1,0,0,0-1-.84h-2.3a1,1,0,0,0-1,.84l-.17,1A2,2,0,0,1,8.63,5.31a5.48,5.48,0,0,0-.74.43,2,2,0,0,1-1.78.19l-1-.36A1,1,0,0,0,3.92,6L2.76,8A1,1,0,0,0,3,9.27l.8.66a2,2,0,0,1,.72,1.64c0,.14,0,.29,0,.43s0,.29,0,.43a2,2,0,0,1-.72,1.64l-.8.66A1,1,0,0,0,2.76,16l1.16,2a1,1,0,0,0,1.21.43l1-.36a2,2,0,0,1,1.78.19,5.48,5.48,0,0,0,.74.43,2,2,0,0,1,1.06,1.45l.17,1a1,1,0,0,0,1,.84h2.3a1,1,0,0,0,1-.84l.17-1a2,2,0,0,1,1.06-1.45,5.48,5.48,0,0,0,.74-.43,2,2,0,0,1,1.78-.19l1,.36A1,1,0,0,0,20.08,18l1.16-2A1,1,0,0,0,21,14.73ZM12,15a3,3,0,1,1,3-3A3,3,0,0,1,12,15Z',
  settings_outlined:
    'M20.21,14.07a2,2,0,0,1-.72-1.64c0-.14,0-.29,0-.43s0-.29,0-.43a2,2,0,0,1,.72-1.64l.8-.66A1,1,0,0,0,21.24,8L20.08,6a1,1,0,0,0-.86-.5.84.84,0,0,0-.35.07l-1,.36a1.89,1.89,0,0,1-.7.13,2,2,0,0,1-1.08-.32,5.48,5.48,0,0,0-.74-.43,2,2,0,0,1-1.06-1.45l-.17-1a1,1,0,0,0-1-.84h-2.3a1,1,0,0,0-1,.84l-.17,1A2,2,0,0,1,8.63,5.31a5.48,5.48,0,0,0-.74.43,2,2,0,0,1-1.08.32,1.89,1.89,0,0,1-.7-.13l-1-.36a.84.84,0,0,0-.35-.07,1,1,0,0,0-.86.5L2.76,8A1,1,0,0,0,3,9.27l.8.66a2,2,0,0,1,.72,1.64c0,.14,0,.29,0,.43s0,.29,0,.43a2,2,0,0,1-.72,1.64l-.8.66A1,1,0,0,0,2.76,16l1.16,2a1,1,0,0,0,.86.5.84.84,0,0,0,.35-.07l1-.36a1.89,1.89,0,0,1,.7-.13,2,2,0,0,1,1.08.32,5.48,5.48,0,0,0,.74.43,2,2,0,0,1,1.06,1.45l.17,1a1,1,0,0,0,1,.84h2.3a1,1,0,0,0,1-.84l.17-1a2,2,0,0,1,1.06-1.45,5.48,5.48,0,0,0,.74-.43,2,2,0,0,1,1.08-.32,1.89,1.89,0,0,1,.7.13l1,.36a.84.84,0,0,0,.35.07,1,1,0,0,0,.86-.5l1.16-2A1,1,0,0,0,21,14.73ZM12,17.5A5.5,5.5,0,1,1,17.5,12,5.5,5.5,0,0,1,12,17.5ZM12,10a2,2,0,1,0,2,2A2,2,0,0,0,12,10Z',
  settings_vs:
    'M21,10.06l-2.78-5A4,4,0,0,0,14.72,3H9.28a4,4,0,0,0-3.5,2.06L3,10.06a4,4,0,0,0,0,3.88l2.78,5A4,4,0,0,0,9.28,21h5.44a4,4,0,0,0,3.5-2.06l2.78-5A4,4,0,0,0,21,10.06ZM12,15a3,3,0,1,1,3-3A3,3,0,0,1,12,15Z',
  settings_vs_outlined:
    'M21,10.1l-2.8-5c-0.7-1.3-2-2.1-3.5-2.1H9.3C7.8,3,6.5,3.8,5.8,5.1l-2.8,5c-0.7,1.2-0.7,2.7,0,3.9l2.8,5 c0.7,1.3,2,2.1,3.5,2.1h5.4c1.5,0,2.8-0.8,3.5-2.1l2.8-5C21.7,12.7,21.7,11.3,21,10.1z M19.2,13l-2.8,5c-0.4,0.6-1,1-1.7,1H9.3 c-0.7,0-1.4-0.4-1.7-1l-2.8-5c-0.3-0.6-0.3-1.3,0-1.9l2.8-5c0.4-0.6,1-1,1.7-1h5.4c0.7,0,1.4,0.4,1.7,1l2.8,5 C19.6,11.6,19.6,12.4,19.2,13z M12,9c-1.7,0-3,1.3-3,3s1.3,3,3,3s3-1.3,3-3S13.7,9,12,9z',
  share:
    'M21,19a3,3,0,0,1-6,0,2.09,2.09,0,0,1,0-.25.51.51,0,0,0-.24-.48L8.22,14.45a.5.5,0,0,0-.54,0A2.94,2.94,0,0,1,6,15,3,3,0,0,1,6,9a2.94,2.94,0,0,1,1.68.53.5.5,0,0,0,.54,0l6.55-3.82A.51.51,0,0,0,15,5.25,2.09,2.09,0,0,1,15,5a3,3,0,1,1,1.07,2.28l-6.84,4a.51.51,0,0,0-.24.48,3.13,3.13,0,0,1,0,.5.51.51,0,0,0,.24.48l6.55,3.82a.5.5,0,0,0,.54,0A2.94,2.94,0,0,1,18,16,3,3,0,0,1,21,19Z',
  share_vs:
    'M14,14c-4.72,0-9.5,2-10,4.59a.5.5,0,0,1-.49.41h-.1a.46.46,0,0,1-.32-.13A.5.5,0,0,1,3,18.54,11.22,11.22,0,0,1,14,8V5.42a.49.49,0,0,1,.15-.36l.2-.2a.5.5,0,0,1,.71,0l5.79,5.79a.48.48,0,0,1,0,.7l-5.79,5.79a.5.5,0,0,1-.71,0l-.2-.2a.49.49,0,0,1-.15-.36Z',
  sign_in:
    'M17,12.19v-.38a.77.77,0,0,0-.22-.53L11.64,6.15a.5.5,0,0,0-.71,0l-.71.71a.49.49,0,0,0,0,.7L13.67,11H3.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5H13.67l-3.45,3.44a.5.5,0,0,0,0,.71l.71.7a.5.5,0,0,0,.71,0l5.14-5.13A.75.75,0,0,0,17,12.19ZM14.67,12ZM19,3H13.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5H19V19H13.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5H19a2,2,0,0,0,2-2V5A2,2,0,0,0,19,3Z',
  sign_out:
    'M20.78,11.28,15.64,6.15a.5.5,0,0,0-.71,0l-.71.71a.49.49,0,0,0,0,.7L17.67,11H7.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5H17.67l-3.45,3.44a.5.5,0,0,0,0,.71l.71.7a.5.5,0,0,0,.71,0l5.14-5.13a.75.75,0,0,0,.22-.53v-.38A.77.77,0,0,0,20.78,11.28ZM18.67,12ZM10.5,19H5V5h5.5a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5H5A2,2,0,0,0,3,5V19a2,2,0,0,0,2,2h5.5a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,10.5,19Z',
  support_vs:
    'M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm8,10H17a5,5,0,0,0-2.51-4.31v0L16,5.08A8,8,0,0,1,20,12ZM9,12a3,3,0,1,1,3,3A3,3,0,0,1,9,12ZM8,5.08,9.5,7.67v0A5,5,0,0,0,7,12H4A8,8,0,0,1,8,5.08ZM8,18.92l1.5-2.59v0a4.84,4.84,0,0,0,5,0v0L16,18.92a7.95,7.95,0,0,1-8,0Z',
  table:
    'M20,4H4A2,2,0,0,0,2,6V18a2,2,0,0,0,2,2H20a2,2,0,0,0,2-2V6A2,2,0,0,0,20,4ZM4,9V6H20V9Zm12,1.75h4V18H16Zm-6.25,0h4.5V18H9.75ZM4,10.75H8V18H4Z',
  toggle_off:
    'M16,6H8A6,6,0,0,0,8,18h8A6,6,0,0,0,16,6ZM8,16a4,4,0,1,1,4-4A4,4,0,0,1,8,16Z',
  toggle_on:
    'M16,6H8A6,6,0,0,0,8,18h8A6,6,0,0,0,16,6Zm0,10a4,4,0,1,1,4-4A4,4,0,0,1,16,16Z',
  upload:
    'M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm3.85,8.07-.71.71a.49.49,0,0,1-.7,0L13,9.33V17.5a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5V9.33L9.56,10.78a.5.5,0,0,1-.71,0l-.7-.71a.5.5,0,0,1,0-.71l3.13-3.14A.75.75,0,0,1,11.81,6h.38a.77.77,0,0,1,.53.22l3.13,3.14A.5.5,0,0,1,15.85,10.07Z',
  upload_outlined:
    'M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20Zm.72-13.78A.77.77,0,0,0,12.19,6h-.38a.75.75,0,0,0-.53.22L8.15,9.36a.5.5,0,0,0,0,.71l.7.71a.5.5,0,0,0,.71,0L11,9.33V17.5a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5V9.33l1.44,1.44a.49.49,0,0,0,.7,0l.71-.71a.5.5,0,0,0,0-.71Z',
  visible:
    'M22,11.7v0A10.65,10.65,0,0,0,12,5a10.65,10.65,0,0,0-9.94,6.67h0A1,1,0,0,0,2,12a.81.81,0,0,0,.06.31h0A10.65,10.65,0,0,0,12,19a10.65,10.65,0,0,0,9.94-6.67h0A.81.81,0,0,0,22,12v0A.93.93,0,0,0,22,11.7ZM12,15a3,3,0,1,1,3-3A3,3,0,0,1,12,15Z',
  visible_outlined:
    'M22,11.7v0A10.65,10.65,0,0,0,12,5a10.65,10.65,0,0,0-9.94,6.67h0A1,1,0,0,0,2,12a.81.81,0,0,0,.06.31h0A10.65,10.65,0,0,0,12,19a10.65,10.65,0,0,0,9.94-6.67h0A.81.81,0,0,0,22,12v0A.93.93,0,0,0,22,11.7ZM12,17a8.82,8.82,0,0,1-7.93-5,8.79,8.79,0,0,1,15.86,0A8.82,8.82,0,0,1,12,17Zm0-8a3,3,0,1,0,3,3A3,3,0,0,0,12,9Z',
  account_circle:
    'M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,3A3,3,0,1,1,9,8,3,3,0,0,1,12,5Zm5.62,11.16a7,7,0,0,1-11.24,0,1,1,0,0,1-.07-1l.21-.44A3,3,0,0,1,9.23,13h5.54a3,3,0,0,1,2.69,1.68l.23.47A1,1,0,0,1,17.62,16.16Z',
  account_square:
    'M19,3H5A2,2,0,0,0,3,5V19a2,2,0,0,0,2,2H19a2,2,0,0,0,2-2V5A2,2,0,0,0,19,3ZM12,6A3,3,0,1,1,9,9,3,3,0,0,1,12,6Zm5.25,12H6.75a.75.75,0,0,1-.67-1.09L7,15.1A2,2,0,0,1,8.78,14h6.44A2,2,0,0,1,17,15.1l.91,1.81A.75.75,0,0,1,17.25,18Z',
  account_square_outlined:
    'M19,3H5A2,2,0,0,0,3,5V19a2,2,0,0,0,2,2H19a2,2,0,0,0,2-2V5A2,2,0,0,0,19,3Zm0,16H5V5H19Zm-7-7.75A2.25,2.25,0,1,0,9.75,9,2.24,2.24,0,0,0,12,11.25ZM7.75,17h8.5a.75.75,0,0,0,.67-1.09L16,14.1A2,2,0,0,0,14.23,13H9.77A2,2,0,0,0,8,14.1l-.9,1.81A.75.75,0,0,0,7.75,17Z',
  group_add:
    'M11.89,16.55A1,1,0,0,1,11,18H1a1,1,0,0,1-.89-1.45l1-1.9A3,3,0,0,1,3.74,13H8.26a3,3,0,0,1,2.68,1.65ZM6,11A3,3,0,1,0,3,8,3,3,0,0,0,6,11Zm17.5-1H21V7.5a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5V10H16.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5H19v2.5a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5V12h2.5a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,23.5,10ZM11,11a3,3,0,1,0-.9-5.86,5,5,0,0,1,0,5.72A3,3,0,0,0,11,11Zm4.94,3.65A3,3,0,0,0,13.26,13h-1a4.89,4.89,0,0,1,.48.76l.95,1.89A3,3,0,0,1,13.82,18H16a1,1,0,0,0,.89-1.45Z',
  group_equal:
    'M5,8a3,3,0,1,1,3,3A3,3,0,0,1,5,8Zm11,3a3,3,0,1,0-3-3A3,3,0,0,0,16,11Zm6.89,6.55-1.44-2.89A3,3,0,0,0,18.77,13h-4a4.89,4.89,0,0,1,.48.76l1.45,2.9A3,3,0,0,1,16.83,19H22A1,1,0,0,0,22.89,17.55Zm-9.44-2.89A3,3,0,0,0,10.76,13H5.24a3,3,0,0,0-2.69,1.66L1.11,17.55A1,1,0,0,0,2,19H14a1,1,0,0,0,.89-1.45Z',
  group_junior:
    'M16.5,12A2.5,2.5,0,1,0,14,9.5,2.49,2.49,0,0,0,16.5,12ZM9,11A3,3,0,1,0,6,8,3,3,0,0,0,9,11Zm2.76,2a4.91,4.91,0,0,0-1.49,1.76l-.95,1.89A3,3,0,0,0,9.18,19H3a1,1,0,0,1-.89-1.45l1.44-2.89A3,3,0,0,1,6.24,13ZM12,19a1,1,0,0,1-.89-1.45l1-1.9A3,3,0,0,1,14.74,14h3.52a3,3,0,0,1,2.68,1.65l.95,1.9A1,1,0,0,1,21,19Z',
  group_senior:
    'M14,9.5A2.5,2.5,0,1,1,16.5,12,2.5,2.5,0,0,1,14,9.5ZM9,11A3,3,0,1,0,6,8,3,3,0,0,0,9,11Zm5.45,3.66A3,3,0,0,0,11.76,13H6.24a3,3,0,0,0-2.69,1.66L2.11,17.55A1,1,0,0,0,3,19H15a1,1,0,0,0,.89-1.45Zm7.44,2.89-.95-1.9A3,3,0,0,0,18.26,14H16.35l1.33,2.66A3,3,0,0,1,17.83,19H21A1,1,0,0,0,21.89,17.55Z',
  user:
    'M8,8a4,4,0,1,1,4,4A4,4,0,0,1,8,8ZM19.89,18.55l-1.44-2.89A3,3,0,0,0,15.76,14H8.24a3,3,0,0,0-2.69,1.66L4.11,18.55A1,1,0,0,0,5,20H19A1,1,0,0,0,19.89,18.55Z',
  users_switch:
    'M6,4.5A2.5,2.5,0,1,1,8.5,7,2.5,2.5,0,0,1,6,4.5Zm7.89,8.05-1-1.9A3,3,0,0,0,10.26,9H6.74a3,3,0,0,0-2.68,1.65l-.95,1.9A1,1,0,0,0,4,14h9A1,1,0,0,0,13.89,12.55ZM15.5,7A2.5,2.5,0,1,0,13,4.5,2.49,2.49,0,0,0,15.5,7Zm5.39,5.55-.95-1.9A3,3,0,0,0,17.26,9h-3a4.89,4.89,0,0,1,.48.76l.95,1.89A3,3,0,0,1,15.82,14H20A1,1,0,0,0,20.89,12.55ZM17.5,16H13.61a.5.5,0,0,0-.35.85l1.46,1.47a5,5,0,0,1-8.53-2A.5.5,0,0,0,5.71,16h-1a.49.49,0,0,0-.39.19.53.53,0,0,0-.1.42,7,7,0,0,0,12,3.13l1,1a.49.49,0,0,0,.85-.35V16.5A.52.52,0,0,0,17.5,16Z',
  user_add:
    'M16.89,18.55A1,1,0,0,1,16,20H2a1,1,0,0,1-.89-1.45l1.44-2.89A3,3,0,0,1,5.24,14h7.52a3,3,0,0,1,2.69,1.66ZM9,12A4,4,0,1,0,5,8,4,4,0,0,0,9,12Zm13.5-2H20V7.5a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5V10H15.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5H18v2.5a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5V12h2.5a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,22.5,10Z',
  user_big:
    'M7,7a5,5,0,1,1,5,5A5,5,0,0,1,7,7ZM21.89,20.55,20,16.76A5,5,0,0,0,15.53,14H8.47A5,5,0,0,0,4,16.76L2.11,20.55A1,1,0,0,0,3,22H21A1,1,0,0,0,21.89,20.55Z',
  user_big_outlined:
    'M12,12A5,5,0,1,0,7,7,5,5,0,0,0,12,12Zm0-8A3,3,0,1,1,9,7,3,3,0,0,1,12,4Zm9.89,16.55L20,16.76A5,5,0,0,0,15.53,14H8.47A5,5,0,0,0,4,16.76L2.11,20.55A1,1,0,0,0,3,22H21A1,1,0,0,0,21.89,20.55ZM4.62,20l1.17-2.34A3,3,0,0,1,8.47,16h7.06a3,3,0,0,1,2.68,1.66L19.38,20Z',
  user_outlined:
    'M12,12A4,4,0,1,0,8,8,4,4,0,0,0,12,12Zm0-6a2,2,0,1,1-2,2A2,2,0,0,1,12,6Zm7.89,12.55L18,14.66A3,3,0,0,0,15.26,13H8.74a3,3,0,0,0-2.69,1.66L4.11,18.55A1,1,0,0,0,5,20H19A1,1,0,0,0,19.89,18.55ZM6.62,18l1.22-2.45a1,1,0,0,1,.9-.55h6.52a1,1,0,0,1,.9.55L17.38,18Z',
  user_switch:
    'M9,4a3,3,0,1,1,3,3A3,3,0,0,1,9,4ZM7,14H17a1,1,0,0,0,.89-1.45l-.95-1.9A3,3,0,0,0,14.26,9H9.74a3,3,0,0,0-2.68,1.65l-.95,1.9A1,1,0,0,0,7,14Zm11.5,2H14.61a.5.5,0,0,0-.35.85l1.46,1.47a5,5,0,0,1-8.53-2A.5.5,0,0,0,6.71,16h-1a.49.49,0,0,0-.39.19.53.53,0,0,0-.1.42,7,7,0,0,0,12,3.13l1,1a.49.49,0,0,0,.85-.35V16.5A.52.52,0,0,0,18.5,16Z'
}

export const condofyIcons = {
  platform: genericIcons.table,
  admin: genericIcons.settings,
  billing: genericIcons.receipt,
  schedule: genericIcons.book_bookmarked,
  calendar: genericIcons.calendar_month,
  announcements: genericIcons.notice_outlined,
  tickets: genericIcons.sms_chat,
  files: genericIcons.folder_opened,
  surveys: genericIcons.poll,
  finance: genericIcons.chart_bar_2,
  complains: genericIcons.report,
  community: genericIcons.users_switch
}

export const icons = {
  ...genericIcons,
  ...condofyIcons
}
