import React from 'react'
import { Skeleton } from './Skeleton'
import PropTypes from 'prop-types'

export const Skeletonize = ({
  condition = false,
  variant = 'text',
  children,
  ...props
}) => {
  return (
    <React.Fragment>
      {condition ? <Skeleton variant={variant} {...props} /> : children}
    </React.Fragment>
  )
}
Skeletonize.propTypes = {
  condition: PropTypes.bool,
  variant: PropTypes.oneOf(['text', 'circle', 'rect'])
}
