import { combineReducers } from '@condofy/next/store/user/combineReducers'
import { listActions, listReducer, list, listActionTypes } from './list'
import { createActions, create, createActionTypes } from './create'

// ACTION TYPES
const TICKET_COMMENT_SET_LOADING_INDICATOR =
  'TICKET_COMMENT_SET_LOADING_INDICATOR'

export const ticketCommentActionTypes = {
  TICKET_COMMENT_SET_LOADING_INDICATOR,

  ...listActionTypes,
  ...createActionTypes
}

// ACTIONS
export const ticketCommentActions = (state, dispatch) => ({
  ...listActions(state, dispatch),
  ...createActions(state, dispatch)
})

export const pending = (dispatch, payload) =>
  dispatch({ type: TICKET_COMMENT_SET_LOADING_INDICATOR, payload })

// MIDDLEWARE
export const ticketCommentMiddlewares = (dispatch, action) => {
  switch (action.type) {
    case listActionTypes.TICKET_COMMENT_LIST:
      return list(dispatch, action.payload)

    case createActionTypes.TICKET_COMMENT_CREATE:
      return create(dispatch, action.payload)

    default:
      return false
  }
}

// REDUCER
const mainReducer = (state, action) => {
  switch (action.type) {
    case TICKET_COMMENT_SET_LOADING_INDICATOR:
      return store(state, {
        metadata: {
          ...state.ticketComment.metadata,
          loading: action.payload
        }
      })

    default:
      return state
  }
}

export const ticketCommentReducer = combineReducers({
  mainReducer,
  listReducer
})

export const store = (state, value) => ({
  ...state,
  ticketComment: { ...state.ticketComment, ...value }
})

export const ticketComment = {
  items: [],
  metadata: {
    pageNumber: 1,
    totalRecords: 0,
    recordsPerPage: 10,
    loading: false
  }
}
