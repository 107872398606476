import React from 'react'
import { Flex, PageSubTitle } from '@condofy/components'

import {
  Bs2Integration,
  BanksIntegrationsCard
} from '@condofy/next/containers/'

export const IntegrationTab = () => {
  return (
    <Flex direction='column' spacingY={3}>
      <PageSubTitle>Integrações bancárias</PageSubTitle>
      <Bs2Integration />
      <BanksIntegrationsCard />
    </Flex>
  )
}
