import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { theme } from '../../styles'
import { CircularProgress } from '@mui/material'
import { ProgressOverlay } from '../Progress'

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  position: relative;
  padding: ${theme.spacing(3)};
  width: 100%;
`

export const ChartContainer = ({ loading, children, ...args }) => (
  <Container {...args}>
    {children}
    {loading && (
      <ProgressOverlay>
        <CircularProgress />
      </ProgressOverlay>
    )}
  </Container>
)

ChartContainer.propTypes = {
  loading: PropTypes.bool
}
