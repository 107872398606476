import React, {
  useSnackbar,
  handleError
} from '@condofy/next/hooks/useSnackbar'
import { useUserContext } from '@condofy/next/store/user'
import { useState } from 'react'
import { usePopup } from '@condofy/next/hooks'
import { LoadingButton } from '@condofy/components'
import { getBelvoConsentUrl } from '@condofy/next/service/belvo'

// const baseURL = 'http://localhost:3000' // enable to test on localhost

const baseURL =
  process.env.REACT_APP_ENVIRONMENT === 'staging'
    ? 'https://staging.condofy.vercel.app'
    : 'https://app.condofy.com.br'

const ConsentSuccessCallbackUrl = baseURL + '/belvo-finished?status=success'
const ConsentExitCallbackUrl = baseURL + '/belvo-finished?status=exit'
const ConsentEventCallbackUrl = baseURL + '/belvo-finished?status=event'
const params = {
  ConsentSuccessCallbackUrl,
  ConsentExitCallbackUrl,
  ConsentEventCallbackUrl
}

function getStagingConsentUrl(url) {
  // return url.replace('&integration_type=openfinance', '') // enable to test on localhost
  if (process.env.REACT_APP_ENVIRONMENT === 'staging') {
    return url.replace('&integration_type=openfinance', '')
  }
  return url
}

export const BankConnectionPopup = ({ callbackFn, loading: propLoading }) => {
  const { enqueueSnackbar } = useSnackbar()
  const {
    state: { currentCondominium }
  } = useUserContext()

  const [loading, setLoading] = useState(false)
  const [popState, setPopState] = useState({
    url: '',
    open: false,
    w: 900,
    h: 800,
    title: ''
  })

  const handleClosePopup = () => {
    setPopState({})
    if (callbackFn) {
      callbackFn()
    }
  }

  usePopup({
    ...popState,
    onClose: handleClosePopup
  })

  const openBelvoPopup = () => {
    setLoading(true)
    getBelvoConsentUrl({ condominium: currentCondominium, params })
      .then(({ data }) => {
        setPopState((prev) => ({
          ...prev,
          url: getStagingConsentUrl(data.data.url),
          open: true
        }))
      })
      .catch(handleError(enqueueSnackbar))
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <LoadingButton loading={loading || propLoading} onClick={openBelvoPopup}>
      Conectar
    </LoadingButton>
  )
}
