import React from 'react'
import { Icon } from '../Icon'
import { TableRow } from './TableRow'
import { IconButton } from '../Button'
import { TableCell } from './TableCell'
import PropTypes from 'prop-types'

export const ExpandableTableRow = ({
  children,
  expandComponent,
  buttonPlacement = 'end',
  paddingLeft,
  onClick = () => {},
  ...props
}) => {
  const [isExpanded, setIsExpanded] = React.useState(false)

  const expandChanged = () => {
    setIsExpanded(!isExpanded)
    onClick(isExpanded)
  }

  const ToggleButtonCell = (
    <TableCell
      padding='none'
      sx={{ paddingLeft: paddingLeft ? paddingLeft : 0 }}
      className='expand'
    >
      <IconButton onClick={expandChanged}>
        {isExpanded ? <Icon icon='chevron_up' /> : <Icon icon='chevron_down' />}
      </IconButton>
    </TableCell>
  )

  return (
    <React.Fragment>
      <TableRow {...props}>
        {buttonPlacement === 'start' && ToggleButtonCell}
        {children}
        {buttonPlacement === 'end' && ToggleButtonCell}
      </TableRow>
      {isExpanded && (
        <>
          {React.Children.map(expandComponent, (child, index) =>
            React.cloneElement(child, { key: index })
          )}
        </>
      )}
    </React.Fragment>
  )
}
ExpandableTableRow.propTypes = {
  buttonPlacement: PropTypes.oneOf(['start', 'end'])
}
