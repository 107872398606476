export const config = {
  search: (condominiumId, page, generalSearch) => ({
    url: `/v1/condominiums/${condominiumId}/announcements-model?PageNumber=${page}&GeneralSearch=${generalSearch}`,
    error: 'Ocorreu um problema ao acessar a lista de comunicados'
  }),
  create: (condominiumId) => ({
    url: `/v1/condominiums/${condominiumId}/announcements-model`,
    error: 'Ocorreu um problema ao criar este comunicado'
  }),
  update: (condominiumId, id) => ({
    url: `/v1/condominiums/${condominiumId}/announcements-model/${id}`,
    error: 'Ocorreu um problema ao alterar este comunicado'
  }),
  receivers: (condominiumId) => ({
    url: `/v1/condominiums/${condominiumId}/announcements-model/receivers`,
    error: 'Ocorreu um problema ao criar este comunicado'
  })
}
