import React from 'react'
import {
  Card,
  CardContent,
  typography,
  Heading6,
  LoadingButton,
  CardActions,
  Flex
} from '@condofy/components'
import styled from 'styled-components'

const StyledCard = styled(Card)`
  height: 100%;

  * {
    font-family: ${typography.type.tertiary} !important;
  }

  .MuiCardContent-root {
    flex-grow: 1;
    line-height: 1;
    text-align: center;
  }
`

const StyledLoadingButton = styled(LoadingButton)`
  width: 100%;
`

export const DataCard = ({
  title,
  avatar,
  actions,
  button,
  buttonText = 'Editar',
  children,
  onClick,
  loading,
  disabled,
  ...props
}) => {
  return (
    <StyledCard {...props}>
      <CardContent>
        <Flex direction='column' alignItems='center' spacing={1}>
          {avatar}
          <Heading6 color='textPrimary'>{title}</Heading6>
          {children}
        </Flex>
      </CardContent>
      <CardActions>
        {!actions && (
          <>
            {button ? (
              { button }
            ) : (
              <StyledLoadingButton
                fullWidth
                color='secondary'
                loading={loading}
                onClick={onClick}
                disabled={disabled}
              >
                {buttonText}
              </StyledLoadingButton>
            )}
          </>
        )}
        {actions}
      </CardActions>
    </StyledCard>
  )
}
