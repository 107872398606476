import { List, Detail, Modal } from '../core'
import { searchFeeds } from '@condofy/next/service/community'

const key = 'FEED'

const feedList = new List({ key, statePropName: 'feedList' })
const feedDetail = new Detail({ key, statePropName: 'feedDetail' })
const feedDetailModal = new Modal({ key, statePropName: 'feedDetail' })
const feedEditModal = new Modal({
  key: `${key}_EDIT`,
  statePropName: 'edit'
})
const feedCreateModal = new Modal({
  key: `${key}_CREATE`,
  statePropName: 'create'
})

export const initialState = {
  feedList: { ...feedList.initialState },
  feedDetail: { ...feedDetailModal.initialState, ...feedDetail.initialState },
  feedEdit: { ...feedEditModal.initialState },
  feedCreate: { ...feedCreateModal.initialState }
}

export const createMiddleware = () => {
  return {
    ...feedList.middleware(searchFeeds),
    ...feedDetail.middleware(),
    ...feedDetailModal.middleware(),
    ...feedEditModal.middleware(),
    ...feedCreateModal.middleware()
  }
}

export const useActions = (state, dispatch) => ({
  loadFeedList: feedList.actions(state, dispatch),
  openFeedDetailModal: (data) => {
    feedDetail.actions(state, dispatch)(data)
    feedDetailModal.actionOpen(state, dispatch)()
  },
  closeFeedDetailModal: feedDetailModal.actionClose(state, dispatch),
  openEditFeedModal: (data) => {
    feedDetail.actions(state, dispatch)(data)
    feedEditModal.actionOpen(state, dispatch)(data)
  },
  closeEditFeedModal: feedEditModal.actionClose(state, dispatch),
  openCreateFeedModal: feedCreateModal.actionOpen(state, dispatch),
  closeCreateFeedModal: feedCreateModal.actionClose(state, dispatch)
})

export const createReducers = () => {
  return {
    ...feedList.reducers(),
    ...feedDetail.reducers(),
    ...feedDetailModal.reducers(),
    ...feedEditModal.reducers(),
    ...feedCreateModal.reducers()
  }
}
