import React, { useEffect, useCallback } from 'react'
import { Form, FormRow, TextField, MenuItem } from '@condofy/components'
import { useUserContext } from '@condofy/next/store/user'
import { userTypeListWithNoManager } from '@condofy/next/model/user'

export const UserConnectionForm = ({
  value: initialValue = {},
  onChange: emitChangeEvent = () => {}
}) => {
  const {
    state: {
      unit: { allItems: unitList },
      condominium: { items: condominiumList }
    },

    actions: { listAllUnits }
  } = useUserContext()
  const stableListAllUnits = useCallback(listAllUnits, [])

  useEffect(() => {
    if (initialValue.condominiumId)
      stableListAllUnits({
        currentCondominium: { id: initialValue.condominiumId },
        includeUsers: true
      })
  }, [initialValue.condominiumId, stableListAllUnits])

  const handleUpdate = (event) => {
    const { name, value } = event.target
    emitChangeEvent({ ...initialValue, [name]: value })
  }

  return (
    <Form>
      <FormRow>
        <TextField
          select
          label='Condomínio'
          name='condominiumId'
          value={initialValue.condominiumId || ''}
          onChange={handleUpdate}
        >
          {condominiumList.map((condominium) => (
            <MenuItem key={condominium.id} value={condominium.id}>
              {condominium.name}
            </MenuItem>
          ))}
        </TextField>
      </FormRow>
      <FormRow>
        {' '}
        <TextField
          select
          label='Papel no condomínio'
          name='typeId'
          value={initialValue.typeId || ''}
          onChange={handleUpdate}
        >
          {userTypeListWithNoManager.map(({ key, value, children }) => (
            <MenuItem key={key} value={value}>
              {children}
            </MenuItem>
          ))}
        </TextField>
      </FormRow>
      <FormRow>
        {' '}
        <TextField
          select
          label='Unidade'
          name='unitId'
          value={initialValue.unitId || ''}
          onChange={handleUpdate}
          disabled={!unitList.length}
        >
          {unitList.map(({ id, block, number }) => (
            <MenuItem key={id} value={id}>
              {`${block} ${number}`}
            </MenuItem>
          ))}
        </TextField>
      </FormRow>
    </Form>
  )
}
