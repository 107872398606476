import React from 'react'
import {
  CommunityOnboardStep,
  CommunityOnboardStepTitle,
  CommunityOnboardStepBody
} from './CommunityOnboardStep'
import Graphic2 from 'Assets/community/work-paint'
import { FormProvider } from 'react-hook-form'
import { Checkbox, Flex, Form, SmallBody } from '@condofy/components'
import styled from 'styled-components'
import { FormField as Field } from '@condofy/next/containers/'

const Label = styled.span`
  font-weight: bold;
`

const options = [
  {
    id: 1,
    title: 'Serviços',
    description: 'faxina, pintura, dogwalkers, babá e outros'
  },
  {
    id: 2,
    title: 'Objetos',
    description: 'ex: furadeira, aspirador de pó, bicicleta e outros.'
  },
  {
    id: 3,
    title: 'Companhia',
    description:
      'ex: alguém para treinar e correr junto, jogar, estudar e outros.'
  },
  {
    id: 4,
    title: 'Outros'
  }
]

const InterestsCheckbox = ({ label, description, ...args }) => {
  return (
    <Checkbox
      variant='outlined'
      label={
        <SmallBody>
          {label && <Label>{label}</Label>} {description}
        </SmallBody>
      }
      {...args}
    />
  )
}

export const CommunityOnboardInterestsStep = ({ args, form }) => {
  return (
    <CommunityOnboardStep Image={<Graphic2 />} {...args}>
      <CommunityOnboardStepTitle>
        Quais são seus interesses?
      </CommunityOnboardStepTitle>
      <CommunityOnboardStepBody>
        Você tem interesse em companhia para fazer exercicios? Ou costuma
        precisar de objetos emprestados? como furadeira, aspirador de pó e
        outros? Conta pra gente!
      </CommunityOnboardStepBody>
      <FormProvider {...form}>
        <Form>
          <Flex spacingY={1} direction='column'>
            {options.map((option, index) => (
              <Field
                key={index}
                name={`interests.${index}`}
                Component={
                  <InterestsCheckbox
                    label={option.title}
                    description={option.description}
                  />
                }
              />
            ))}
          </Flex>
        </Form>
      </FormProvider>
    </CommunityOnboardStep>
  )
}
