import React, { useState } from 'react'
import { TabsPanelItem, TabsPanel, Page } from '@condofy/components'
import { UsersTab } from './UsersTab'
import { UnitsTab } from './UnitsTab'
import { AreasTab } from './AreasTab'
import { IntegrationTab } from './IntegrationTab'
// import { InvoicesTab } from './InvoicesTab'
import { ReportsTab } from './ReportsTab'
import { AccountabilityReportsTab } from './AccountabilityReports/AccountabilityReportsTab'

export const AdminCondominiumPage = () => {

  const [mainReportsTab, setMainReportsTab] = useState(true);

  return (
    <Page>
      <TabsPanel>
        <TabsPanelItem label='Unidades'>
          <UnitsTab />
        </TabsPanelItem>
        <TabsPanelItem label='Usuários'>
          <UsersTab />
        </TabsPanelItem>
        <TabsPanelItem label='Áreas do Condomínio'>
          <AreasTab />
        </TabsPanelItem>
        <TabsPanelItem label='Integrações'>
          <IntegrationTab />
        </TabsPanelItem>
          <TabsPanelItem label='Relatórios'>
            {mainReportsTab ?
              <ReportsTab setMainReportsTab={setMainReportsTab} />
              : <AccountabilityReportsTab setMainReportsTab={setMainReportsTab} />
            }
          </TabsPanelItem>
      </TabsPanel>
    </Page>
  )
}