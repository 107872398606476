import React from 'react'
import { Page, PageTitle } from '@condofy/components'
import { OccurrenceList } from '@condofy/next/containers/occurrence'

export const ComplainsPage = () => (
  <Page>
    <PageTitle title='Ocorrências' icon='complains' />
    <OccurrenceList />
  </Page>
)
