import React from 'react'
import { Avatar } from '@condofy/components'
import { DataCard } from '@condofy/next/components/DataCard'
import { useUserContext } from '@condofy/next/store/user'

export const AreaCard = ({ area, ...props }) => {
  const {
    actions: { openEditAreaModal }
  } = useUserContext()

  return (
    <DataCard
      title={area.name}
      avatar={<Avatar size='large'>{area.name.substring(0, 2)}</Avatar>}
      onClick={() => openEditAreaModal(area)}
      {...props}
    />
  )
}
