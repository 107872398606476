import React from 'react'
import { Card, Flex, InfoTooltip, PageSubTitle } from '@condofy/components'
import styled from 'styled-components'

const StyledCard = styled(Card)`
  flex-grow: 1;

  & .MuiCardContent-root {
    height: 100%;
  }
`

export const ChartBlock = ({
  title,
  tooltip = 'Os dados apresentados poderão sofrer alterações ao longo do dia.',
  children
}) => {
  return (
    <Flex direction='column'>
      <Flex
        justify='space-between'
        alignItems='center'
        style={{ alignItems: 'center' }}
      >
        <PageSubTitle>{title}</PageSubTitle>
        <InfoTooltip title={tooltip} />
      </Flex>
      <StyledCard marginBottom={0}>{children}</StyledCard>
    </Flex>
  )
}
