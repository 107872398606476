import types from './actionTypes'

export const initialState = {
  list: {
    items: [],
    metadata: {
      pageNumber: 1,
      totalRecords: 0,
      recordsPerPage: 10,
      loading: false
    }
  },
  detail: {
    modal: false,
    items: [],
    metadata: {
      loading: false
    }
  },
  declaration: {
    modal: false,
    units: [],
    motadata: {
      loading: false
    }
  }
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOAD_BILLING_DETAIL_SUCCESS:
      return {
        ...state,
        detail: {
          ...state.detail,
          ...action.payload.billing,
          ...action.payload.data.data
        }
      }

    case types.LOAD_BILLING_DECLARATION_SUCCESS:
      return {
        ...state,
        declaration: {
          ...state.declaration,
          ...action.payload
        }
      }

    case types.LOAD_BILLING_LIST_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          items: action.payload.data,
          metadata: {
            ...state.list.metadata,
            ...action.payload.paging,
            pageNumber:
              action.payload.paging.pageNumber === 0
                ? 1
                : action.payload.paging.pageNumber
          }
        }
      }

    case types.LOAD_BILLING_LIST_PENDING:
      return {
        ...state,
        list: {
          ...state.list,
          metadata: { ...state.list.metadata, loading: action.payload }
        }
      }

    case types.LOAD_BILLING_DETAIL_MODAL_VISIBLE:
      return {
        ...state,
        detail: { ...state.detail, modal: action.payload }
      }

    case types.LOAD_BILLING_DETAIL_PENDING:
      return {
        ...state,
        detail: {
          ...state.detail,
          metadata: { ...state.detail.metadata, loading: action.payload }
        }
      }

    case types.LOAD_BILLING_DECLARATION_MODAL_VISIBLE:
      return {
        ...state,
        declaration: { ...state.declaration, modal: action.payload }
      }

    case types.LOAD_BILLING_DECLARATION_PENDING:
      return {
        ...state,
        declaration: {
          ...state.declaration,
          metadata: { ...state.declaration.metadata, loading: action.payload }
        }
      }

    default:
      return state
  }
}
