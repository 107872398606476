import React from 'react'
import styled from 'styled-components'
import {
  Dialog as MuiDialog,
  DialogTitle as MuiDialogTitle
} from '@mui/material'
import { colors, theme } from '../../styles'
import { Button } from '../Button'
import PropTypes from 'prop-types'
import { Icon } from '../Icon'
import { DialogTitle } from './DialogTitle'
import { DialogActions } from './DialogActions'

const DialogOverlay = styled(MuiDialog)`
  background: rgba(0, 25, 71, 0.65);

  & .MuiPaper-root {
    background-color: ${colors.light100};
    border: solid 1px ${colors.light400};
    border-radius: 3px;
    box-shadow: 0px 24px 18px rgba(23, 43, 77, 0.04),
      0px 35px 24px rgba(23, 43, 77, 0.08);
    padding: ${theme.spacing(3)};
    outline: none;

    ${({ maxWidth }) =>
      !maxWidth &&
      `
    max-width: 90vw;
      max-height: 90vh;

      ${theme.breakpoints.up('md')} {
        min-width: 35vw;
        max-width: 60vw;
      }
    `}
  }
`
const DialogIcon = styled.div`
  margin-right: 10px;
  color: ${colors.secondary};
`
const DialogHeader = styled(MuiDialogTitle)`
  &.MuiDialogTitle-root {
    border-bottom: solid 1px ${colors.light500};
    display: flex;
    padding: 0 0 ${theme.spacing(3)} 0;
    text-transform: capitalize;
  }
  & + .MuiDialogContent-root {
  }
`

const CloseIcon = styled(Icon)`
  color: ${colors.light500} !important;
`

export const DialogCloseButton = ({ onClose }) => (
  <Button color='clear' onClick={onClose}>
    <CloseIcon icon='clear_circle' />
  </Button>
)

export const Dialog = ({
  title,
  icon,
  actions,
  children,
  onClose,
  open = false,
  hideHeader = false,
  maxWidth = false,
  ...other
}) => {
  return (
    <DialogOverlay open={open} onClose={onClose} maxWidth={maxWidth} {...other}>
      {!hideHeader && (
        <DialogHeader>
          {icon && <DialogIcon>{icon}</DialogIcon>}
          {title && <DialogTitle variant='h6'>{title}</DialogTitle>}
          <DialogCloseButton onClose={onClose} />
        </DialogHeader>
      )}
      {children}
      {actions && <DialogActions>{actions}</DialogActions>}
    </DialogOverlay>
  )
}

Dialog.propTypes = {
  title: PropTypes.node,
  icon: PropTypes.node,
  footer: PropTypes.node,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  hideHeader: PropTypes.bool,
  maxWidth: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs', false])
}
