import React from 'react'
import { LoadingButton } from '@condofy/components'
import { Grid } from '@mui/material'
import styled from 'styled-components'

const StyledButton = styled(LoadingButton)`
  width: 100%;

  .MuiButtonBase-root {
    width: 100%;
  }
`

const Children = styled(Grid)`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`

export const ActionRow = ({
  cancelText = 'Cancelar',
  confirmText = 'Salvar',
  loading,
  children,
  disabled,
  fullWidth = false,
  cancelColor = 'secondary',
  onCancel: emitCancelEvent = () => {},
  onSubmit: emitSubmitEvent = () => {},
  ...props
}) => {
  const colSize = fullWidth ? 6 : 'auto'
  return (
    <Grid container spacing={1} {...props}>
      {cancelText && (
        <Grid item xs={colSize}>
          <StyledButton
            disabled={loading}
            color={cancelColor}
            onClick={emitCancelEvent}
          >
            {cancelText}
          </StyledButton>
        </Grid>
      )}
      {confirmText && (
        <Grid item xs={colSize}>
          <StyledButton
            loading={loading}
            disabled={disabled || disabled}
            onClick={emitSubmitEvent}
          >
            {confirmText}
          </StyledButton>
        </Grid>
      )}
      {children && (
        <Children item container alignItems='flex-end'>
          {children}
        </Children>
      )}
    </Grid>
  )
}
