export const capitalizeLowerKey = (payload) => {
  if (typeof payload === 'object') {
    var newObj = {}
    Object.keys(payload).map(
      (a) => (newObj[a.charAt(0).toLowerCase() + a.slice(1)] = payload[a])
    )
    return newObj
  } else return payload
}

export const toCamelLower = (str) =>
  str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => {
      return index === 0 ? word.toLowerCase() : word.toUpperCase()
    })
    .replace(/\s+/g, '')

export const toCamel = (str) =>
  str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, (word) => word.toUpperCase())
    .replace(/\s+/g, '')

export const toSnake = (str) =>
  str
    .replace(/\.?([A-Z])/g, (x, y) => '_' + y.toUpperCase())
    .replace(/^_/, '')
    .toUpperCase()

export const toSnakeAndCamel = (str) => {
  const camel = toCamel(str)
  const snake = toSnake(camel)
  return { camel, snake }
}

export const groupCamel = (arr) =>
  arr
    .map((a) => a.camel)
    .filter(Boolean)
    .join('')

export const groupSnake = (arr) =>
  arr
    .map((a) => a.snake)
    .filter(Boolean)
    .join('_')
