import { Chip } from '@condofy/components'
import React from 'react'

export const AnnouncementStatusChip = ({ status }) => {
  const statuses = {
    A: {
      label: 'Rascunho',
      color: 'light'
    },
    P: {
      label: 'Enviado',
      color: 'success'
    },
    F: {
      label: 'Finalizado',
      color: 'secondary'
    }
  }
  const values = statuses[status]

  return values ? <Chip color={values.color} label={values.label} /> : <></>
}
