import React from 'react'
import { Chip as MuiChip } from '@mui/material'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { colors } from '../../styles/colors'
import { typography } from '../../styles/typography'
import { theme } from '../../styles'

const StyledChip = styled(MuiChip)`
  font-family: ${typography.type.tertiary} !important;
  font-weight: 600;
  font-size: 10px !important;
  height: 24px !important;
  letter-spacing: 2px;
  text-transform: uppercase;
  padding: 3px 0;

  & .MuiChip-label {
    padding-left: ${theme.spacing(1)};
    padding-right: ${theme.spacing(1)};
  }
`

const styles = {
  danger: {
    backgroundColor: colors.danger300,
    color: '#fff'
  },
  success: {
    backgroundColor: colors.success300,
    color: '#fff'
  },
  warning: {
    backgroundColor: colors.warning300,
    color: '#fff'
  },
  light: {
    backgroundColor: colors.light600,
    color: '#fff'
  }
}

export const Chip = ({ color = 'light', maxWidth = 'auto', ...props }) => {
  // const styles = additionalColors()
  const className = styles[color] ? color : "";
  const chipColor = styles[color] ? 'default' : color;

  return (
    <StyledChip
      sx = {{ ...styles[color] || {}}}
      style={{ maxWidth }}
      className={{className}}
      color={chipColor}
      {...props}
    />
  )
}

Chip.propTypes = {
  disabled: PropTypes.bool,
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'danger',
    'light',
    'success',
    'warning'
  ]),
  startIcon: PropTypes.object,
  maxWidth: PropTypes.any
}
