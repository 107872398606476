import yup from '@condofy/next/service/yup'

export const listingSchema = yup.object().shape({
  title: yup.string().required(),
  description: yup.string(),
  chargerType: yup.string(),
  price: yup.mixed(),
  // yup.mixed().when('chargerType', {
  //   is: (val) => Boolean(val),
  //   then: yup
  //     .number()
  //     .required()
  //     .typeError('Campo deve ser um número')
  //     .positive(),
  //   otherwise: yup.mixed().notRequired().nullable()
  // }),
  files: yup.array().nullable()
})
