export const combineReducers = (reducers) => {
  return (state = undefined, action) => {
    let nextState = state

    for (let reducer in reducers) {
      nextState = reducers[reducer](nextState, action)
    }

    return nextState
  }
}
