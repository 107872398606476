import { condofyProtected } from './instance'

export const getBs2ConsentUrl = ({ condominium }) => {
  return condofyProtected.get(
    `v1/condominiums/${condominium.id}/bs2/consent-url`
  )
}

export const getConsentStatus = ({ condominium }) => {
  return condofyProtected.get(`v1/condominiums/${condominium.id}/bs2/consent`)
}

export const cancelConsent = ({ condominium }) => {
  return condofyProtected.delete(
    `v1/condominiums/${condominium.id}/bs2/consent`
  )
}

export const createConsent = ({ condominium, authorizationCode }) => {
  return condofyProtected.post(
    `v1/condominiums/${condominium.id}/bs2/consent`,
    { authorizationCode }
  )
}
