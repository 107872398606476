import React, { useReducer } from 'react'

export const withProvider = ({
  reducer,
  initialState,
  useActions,
  applyMiddleware
}) => (Context) => (props) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const actions = useActions(state, applyMiddleware(dispatch))

  return <Context.Provider value={{ state, actions }} {...props} />
}
