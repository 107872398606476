import styled from 'styled-components'
import { Button } from '@condofy/components'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  padding: 10px 0px;
`

export const SendButton = styled(Button)`
  &.MuiButton-contained {
    background-color: #90ee90;
    width: 35px;
    min-width: 35px;
    cursor: pointer;
    height: 100px;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;

    &:hover {
      opacity: 0.8;
    }

    svg {
      color: #2e8b57;
      font-size: 20px;
    }
  }
`

export const SuccessButton = styled(Button)`
  &.MuiButton-contained {
    background-color: #90ee90;
    width: 35px;
    min-width: 35px;
    cursor: default;
    height: 100px;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;

    svg {
      color: #2e8b57;
      font-size: 20px;
    }
  }
`

export const WaitingButton = styled(Button)`
  &.MuiButton-contained {
    background-color: #ffebcd;
    width: 35px;
    min-width: 35px;
    cursor: default;
    height: 100px;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;

    svg {
      color: #ffa500;
      font-size: 20px;
    }
  }
`

export const DisabledButton = styled(Button)`
  &.MuiButton-contained {
    background-color: #f8f8ff;
    width: 35px;
    min-width: 35px;
    cursor: default;
    height: 100px;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
  }
`
