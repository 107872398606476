import React, { useState } from 'react'
import styled from 'styled-components'
import {
  Dialog,
  DialogContent,
  DialogSection,
  colors,
  FlexColumn,
  Body,
  Button,
  FormRow,
  DatePicker
} from '@condofy/components'

const UnitRow = styled.div`
  background-color: ${colors.light200};
  color: ${colors.dark300};
  margin: 0 auto !important;
  max-width: 700px;
  padding: 1px 7px;
`

export const RestrictionCreateDatesModal = ({
  isOpen = false,
  onSubmit: emitSubmitEvent = () => {},
  onClose: emitCloseEvent = () => {}
}) => {
  const [initialDate, setInitialDate] = useState(null)
  const [finalDate, setFinalDate] = useState(null)

  return (
    <Dialog open={isOpen} onClose={emitCloseEvent} title='Restringir Datas'>
      <DialogContent>
        <FlexColumn>
          <UnitRow>
            <Body>
              <FormRow>
                <DatePicker
                  label='Dia Inicial'
                  value={initialDate}
                  variant='inline'
                  onChange={setInitialDate}
                  autoOk
                />
                <DatePicker
                  label='Dia Final'
                  value={finalDate}
                  variant='inline'
                  onChange={setFinalDate}
                  minDate={initialDate}
                  autoOk
                />
              </FormRow>
            </Body>
          </UnitRow>
        </FlexColumn>
      </DialogContent>
      <DialogSection border={1}>
        <Button onClick={() => emitSubmitEvent(initialDate, finalDate)}>
          Restringir
        </Button>
      </DialogSection>
    </Dialog>
  )
}
