import React, { useEffect, useCallback } from 'react'
import {
  approveConnection,
  disapproveConnection
} from '@condofy/next/service/connection'
import { useUserContext } from '@condofy/next/store/user'
import { useSnackbar, handleError } from '@condofy/next/hooks/useSnackbar'
import { track } from '@condofy/next/service/segment'
import { CardGrid } from '@condofy/components'
import { ConnectionRequestCard } from './ConnectionRequestCard'

export const ConnectionRequestList = () => {
  const {
    state: {
      currentCondominium,
      connection: { items, metadata }
    },
    actions: { listConnection }
  } = useUserContext()
  const stableListConnection = useCallback(listConnection, [])

  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    if (currentCondominium) {
      stableListConnection({ currentCondominium })
    }
  }, [currentCondominium, stableListConnection])

  const handleApprove = (connection) => () => {
    approveConnection({ connection })
      .then(() => {
        track(window.analytics, 'Connection Approved', {
          condominium_id: currentCondominium.id,
          ...connection
        })
        enqueueSnackbar('Conexão aprovada', { variant: 'success' })
        listConnection({ currentCondominium })
      })
      .catch(handleError(enqueueSnackbar))
  }

  const handleDisapprove = (connection) => () => {
    disapproveConnection({ connection })
      .then(() => {
        track(window.analytics, 'Connection Rejected', {
          condominium_id: currentCondominium.id,
          ...connection
        })
        enqueueSnackbar('Conexão reprovada', { variant: 'success' })
        listConnection({ currentCondominium })
      })
      .catch(handleError(enqueueSnackbar))
  }
  return (
    <>
      <CardGrid loading={metadata.loading}>
        {items.map((connection) => (
          <ConnectionRequestCard
            key={connection.id}
            handleApprove={handleApprove}
            handleDisapprove={handleDisapprove}
            connection={connection}
          />
        ))}
      </CardGrid>
    </>
  )
}
