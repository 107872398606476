import React from 'react'
import { differenceInDays } from 'date-fns'

export const SurveyDateFromNow = ({ date }) => {
  const days = differenceInDays(new Date(date), new Date())
  let dateStr =
    days < 0
      ? 'Encerrado'
      : days === 0
      ? 'Termina hoje'
      : `Termina em ${days} ${days > 1 ? 'dias' : 'dia'}`

  return <>{dateStr}</>
}
