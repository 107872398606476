import types from './actionTypes'

const reduceTotal = (accumulator, item) => accumulator + item.balance
const initialTotalValue = 0

export const initialState = {
  currentAccount: {
    nickName: 'Carregando...',
    bankAccountId: false
  },
  metadata: {
    loading: false
  },
  items: [],
  total: 0
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOAD_BANK_ACCOUNT_LIST_SUCCESS:
      return {
        ...state,
        items: action.payload.data,
        total: action.payload.data.reduce(reduceTotal, initialTotalValue)
      }

    case types.LOAD_BANK_ACCOUNT_LIST_ERROR:
      return { ...state, error: action.payload }

    case types.SET_LOADING_INDICATOR:
      return {
        ...state,
        metadata: { ...state.metadata, loading: action.payload }
      }

    case types.SELECT_BANK_ACCOUNT:
      return {
        ...state,
        currentAccount: {
          ...action.payload
        }
      }

    default:
      return state
  }
}
