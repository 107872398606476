import { create as save } from '@condofy/next/service/occurence'
import { store, pending } from './index'

// ACTION TYPES
const OCCURENCE_CREATE = 'OCCURENCE_CREATE'
const OCCURENCE_CREATE_MODAL_VISIBLE = 'OCCURENCE_CREATE_MODAL_VISIBLE'

export const createActionTypes = {
  OCCURENCE_CREATE,
  OCCURENCE_CREATE_MODAL_VISIBLE
}

// ACTIONS
export const createActions = (state, dispatch) => ({
  createOccurence: (payload) => dispatch({ type: OCCURENCE_CREATE, payload }),

  closeCreateOccurenceModal: () =>
    dispatch({ type: OCCURENCE_CREATE_MODAL_VISIBLE, payload: false }),

  openCreateOccurenceModal: () =>
    dispatch({ type: OCCURENCE_CREATE_MODAL_VISIBLE, payload: true })
})

// MIDDLEWARE
export const create = async (dispatch, payload) => {
  try {
    pending(dispatch, true)
    const response = await save(payload)
    return response
  } finally {
    pending(dispatch, false)
  }
}

// REDUCER
export const createReducer = (state, action) => {
  switch (action.type) {
    case OCCURENCE_CREATE_MODAL_VISIBLE:
      return store(state, {
        createModalOpen: action.payload
      })

    default:
      return state
  }
}
