import { TOGGLE_MODAL } from 'Actions/modals'

const initialState = {
  isModalOpen: false,
  modalOptions: {}
}

const modalsReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_MODAL:
      return {
        ...state,
        isModalOpen: action.isModalOpen,
        modalOptions: action.isModalOpen
          ? { type: action.modalType, ...action.options }
          : {}
      }

    default:
      return state
  }
}

export default modalsReducer
