import React, { useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import {
  SearchTextField,
  MenuItem,
  Select,
  DatePicker,
  CardActions,
  IconButton,
  Icon,
  Button
} from '@condofy/components'
import { useUserContext } from '@condofy/next/store/user'
import { useTheme, useMediaQuery } from '@mui/material'

const StyledSelect = styled(Select)`
  min-width: ${(props) => `${props.maxWidth} !important` || '120px !important'};
  &.MuiFormControl-root {
    margin-bottom: ${(props) => props.marginBottom || '0'};
    margin-right: ${(props) => props.marginRight || '0'};
  }
`

const StyledDatePicker = styled(DatePicker)`
  &.MuiFormControl-root {
    width: ${(props) => props.width || '200px'};
    margin-bottom: ${(props) => props.marginBottom || '0'};
    margin-right: ${(props) => props.marginRight || '0'};
  }
  & .MuiFormLabel-root {
    margin-top: -6px;
  }
  & .MuiIconButton-root {
    padding: 6px;
  }
`

const StyledSearchTextField = styled(SearchTextField)`
  &.MuiFormControl-root {
    width: ${(props) => props.width || 'auto'};
  }
`

export const BillingListFilters = ({
  filters = {},
  condominium,
  onFilterChange = () => {}
}) => {
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const [search, setSearch] = useState(filters.search)
  const [status, setStatus] = useState(filters.status)
  const [units, setUnits] = useState(filters.units)
  const [initialDate, setInitialDate] = useState(filters.initialDate)
  const [finalDate, setFinalDate] = useState(filters.finalDate)
  const [canViewAllFilters, setCanViewAllFilters] = useState(false)

  const {
    state: {
      isAdmin,
      isSuperAdmin,
      unit: {
        allItems: unitList,
        metadata: { loading }
      }
    },
    actions: { listAllUnits }
  } = useUserContext()
  const stableListAllUnits = useCallback(listAllUnits, [])

  const handleStatusChange = (event) => setStatus(event.target.value)
  const handleUnitsChange = (event) => {
    const value = event.target.value ? [event.target.value] : []
    setUnits(value)
  }
  const handleSearch = (event) => setSearch(event.target.value)
  const handleInitialDateChange = (date) => setInitialDate(date)
  const handleFinalDateChange = (date) => setFinalDate(date)

  useEffect(() => {
    setCanViewAllFilters(isAdmin || isSuperAdmin)
  }, [isAdmin, isSuperAdmin])

  useEffect(() => {
    onFilterChange({
      search,
      status,
      units,
      initialDate: initialDate,
      finalDate: finalDate
    })
  }, [search, status, units, finalDate, initialDate])

  useEffect(() => {
    if (condominium.id) {
      stableListAllUnits({ currentCondominium: { id: condominium.id } })
    }
  }, [condominium, stableListAllUnits])

  const cleanFilters = () => {
    setSearch('')
    setStatus('')
    setUnits([])
    setInitialDate(null)
    setFinalDate(null)
  }

  // const [search, setSearch] = useState(filters.search)
  // const [status, setStatus] = useState(filters.status)
  // const [units, setUnits] = useState(filters.units)
  // const [initialDate, setInitialDate] = useState(filters.initialDate)
  // const [finalDate, setFinalDate] = useState(filters.finalDate)

  return (
    <CardActions
      style={{
        justifyContent: isSmallScreen ? 'center' : 'flex-end',
        flexWrap: 'wrap',
        paddingRight: isSmallScreen ? '0' : '25px'
      }}
    >
      <Button
        onClick={cleanFilters}
        disabled={
          !search && !status && !units.length && !initialDate && !finalDate
        }
        sx={{
          marginRight: isSmallScreen ? 0 : 1,
          width: isSmallScreen ? '90%' : 'auto',
          marginBottom: isSmallScreen ? 1 : 0
        }}
      >
        Limpar Filtros
      </Button>
      {canViewAllFilters && (
        <>
          <StyledDatePicker
            label='Vencimento desde'
            value={initialDate}
            variant='inline'
            onChange={handleInitialDateChange}
            maxDate={finalDate}
            autoOk
            width={isSmallScreen ? '90%' : '200px'}
            marginBottom={isSmallScreen ? '10px' : 0}
            marginRight={isSmallScreen ? '' : '10px'}
            InputProps={{
              endAdornment: initialDate && (
                <IconButton onClick={() => setInitialDate(null)}>
                  <Icon icon='clear' />
                </IconButton>
              )
            }}
          />
          <StyledDatePicker
            label='Vencimento até'
            value={finalDate}
            variant='inline'
            onChange={handleFinalDateChange}
            minDate={initialDate}
            autoOk
            width={isSmallScreen ? '90%' : '200px'}
            marginBottom={isSmallScreen ? '10px' : 0}
            marginRight={isSmallScreen ? '0' : '10px'}
            InputProps={{
              endAdornment: finalDate && (
                <IconButton onClick={() => setFinalDate(null)}>
                  <Icon icon='clear' />
                </IconButton>
              )
            }}
          />
          <StyledSelect
            select
            maxWidth={isSmallScreen ? '90%' : '120px'}
            marginBottom={isSmallScreen ? '10px' : 0}
            marginRight={isSmallScreen ? '0' : '10px'}
            sx={{
              '.MuiSelect-select': {
                height: '1.1876em',
                lineHeight: '1.1876em',
                minHeight: '1.1876em'
              }
            }}
            label='Unidade'
            value={units}
            onChange={handleUnitsChange}
            disabled={loading}
          >
            <MenuItem value=''>Todas</MenuItem>
            {unitList.map((unit) => (
              <MenuItem key={unit.id} value={unit.id}>
                {unit.number}
                {unit.block && unit.block.length ? ` - ${unit.block}` : ''}
              </MenuItem>
            ))}
          </StyledSelect>
        </>
      )}
      <StyledSelect
        select
        maxWidth={isSmallScreen ? '90%' : '120px'}
        marginBottom={isSmallScreen ? '10px' : 0}
        marginRight={isSmallScreen ? '10px' : 0}
        sx={{
          '.MuiSelect-select': {
            height: '1.1876em',
            lineHeight: '1.1876em',
            minHeight: '1.1876em'
          }
        }}
        label='Situação'
        value={status}
        onChange={handleStatusChange}
      >
        <MenuItem value=''>Todos</MenuItem>
        <MenuItem value={0}>Pendente</MenuItem>
        <MenuItem value={1}>Expirado</MenuItem>
        <MenuItem value={2}>Pago</MenuItem>
      </StyledSelect>
      <StyledSearchTextField
        width={isSmallScreen ? '90%' : 'auto'}
        size='small'
        onChange={handleSearch}
        value={search}
      />
    </CardActions>
  )
}
