import React, { useCallback, useEffect, useState } from 'react'
import {
  DataTable,
  Card,
  CardHeader,
  CardContent,
  Pagination,
  Flex,
  DateText,
  CurrencyText
} from '@condofy/components'
import { useBillingContext } from '@condofy/next/store/billing'
import { useUserContext } from '@condofy/next/store/user'
import { track } from '@condofy/next/service/segment'
import { InvoiceStatusChip } from './InvoiceStatusChip'
import { BillingListFilters } from './BillingListFilters'
import moment from 'moment'
import { useBankAccountContext } from '@condofy/next/store/bank-account'
import { listUserConnections } from '@condofy/next/service/userConnection'
import { handleError, useSnackbar } from '@condofy/next/hooks/useSnackbar'
import styled from 'styled-components'
import { Box, Modal, RadioGroup, Radio, FormControlLabel } from '@mui/material'
import { colors, theme } from '../../../@condofy/styles'
import { DialogCloseButton } from '../../../@condofy/components/Dialog/Dialog'
import { Heading5, Heading6, Button } from '@condofy/components'
import { condofyPfd } from '../../service/instance'
import { token } from '@condofy/next/service/user'
import { saveAs } from 'file-saver'
import DownloadIcon from '@mui/icons-material/Download'
import { OptionsButton } from '../../../@condofy/components/OptionsButton'

const DialogOverlay = styled(Modal)`
  background: rgba(0, 25, 71, 0.65);
  display: flex;
  justify-content: center;
  align-items: center;

  & .MuiPaper-root {
    background-color: ${colors.light100};
    border: solid 1px ${colors.light400};
    border-radius: 3px;
    box-shadow:
      0px 24px 18px rgba(23, 43, 77, 0.04),
      0px 35px 24px rgba(23, 43, 77, 0.08);
    padding: ${theme.spacing(3)};
    outline: none;

    ${({ maxWidth }) =>
      !maxWidth &&
      `
    max-width: 90vw;
      max-height: 90vh;

      ${theme.breakpoints.up('md')} {
        min-width: 35vw;
        max-width: 60vw;
      }
    `}
  }
`

export const BillingList = (props) => {
  const [filters, setFilters] = useState({
    search: '',
    status: '',
    initialDate: null,
    finalDate: null,
    units: []
  })
  const [page, setPage] = useState(1)
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = React.useState(true)
  const [unitId, setUnitId] = React.useState(null)
  const [unitList, setUnitList] = React.useState([])
  const [selectedCondominiumId, setSelectedCondominiumId] = React.useState(null)
  const [declarationModal, setDeclarationModal] = React.useState(false)
  const [errorModal, setErrorModal] = React.useState(false)
  const { enqueueSnackbar } = useSnackbar()

  const {
    actions: { loadBankAccountList }
  } = useBankAccountContext()

  const stableLoadBankAccountList = useCallback(loadBankAccountList, [])

  const {
    state: { currentCondominium, id }
  } = useUserContext()

  const {
    state: {
      list: { metadata, items }
    },
    actions: { loadBillingList, openDetailModal, openDeclarationModal }
  } = useBillingContext()
  const stableLoadBillingList = useCallback(loadBillingList, [])

  useEffect(() => {
    if (currentCondominium.id) {
      stableLoadBankAccountList({ condominiumId: currentCondominium.id })
    }
  }, [currentCondominium.id, stableLoadBankAccountList])

  const listConnections = () => {
    setLoading(true)
    listUserConnections()
      .catch(handleError(enqueueSnackbar))
      .then((res) => setUnitList(res?.data?.data || []))
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    listConnections()
  }, [])

  useEffect(() => {
    if (currentCondominium.id) {
      stableLoadBillingList({
        generalSearch: filters.search,
        status: filters.status,
        condominiumId: currentCondominium.id,
        initialDate:
          filters.initialDate &&
          moment(filters.initialDate).format('YYYY-MM-DD 00:00'),
        finalDate:
          filters.finalDate &&
          moment(filters.finalDate).format('YYYY-MM-DD 23:59'),
        unitIds: filters.units,
        PageNumber: page,
        RecordsPerPage: 10
      })
    }
  }, [currentCondominium.id, page, filters, stableLoadBillingList])

  const openDetail = (billing) => {
    track(window.analytics, 'Billing Details Opened', billing)
    openDetailModal({
      billing,
      condominiumId: currentCondominium.id
    })
  }

  const openDeclaration = () => {
    track(window.analytics, 'Billing Declarations Opened', unitList)
    openDeclarationModal({
      unitList,
      condominiumId: currentCondominium.id
    })
  }

  const columns = [
    {
      field: 'dueDate',
      headerName: 'Vencimento',
      renderCell: ({ row }) => <DateText date={row.dueDate} />
    },
    {
      field: 'unit',
      headerName: 'Unidade',
      valueGetter: ({ row }) => {
        return (
          `${row.unit?.name}${`${
            row.unit?.block ? ` - ${row.unit?.block}` : ''
          }`}` || '-'
        )
      }
    },
    { field: 'recipientName', headerName: 'Sacado' },
    {
      field: 'value',
      headerName: 'Valor',
      renderCell: ({ row }) => <CurrencyText value={row.totalAmount} />
    },
    {
      field: 'status',
      headerName: 'Status',
      renderCell: ({ row }) => <InvoiceStatusChip status={row.status} />
    }
  ]
  const handleFilterChange = (values) => setFilters(values)

  const downloadDeclaration = async () => {
    try {
      let today = new Date()
      let dd = String(today.getDate()).padStart(2, '0')
      let mm = String(today.getMonth() + 1).padStart(2, '0')
      let yyyy = String(today.getFullYear())
      const response = await condofyPfd.post(
        `/v1/condominiums/${selectedCondominiumId}/units/${unitId}/download-clearance-certificate-report`,
        {
          responseType: 'blob',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            Accept: 'application/pdf'
          }
        }
      )
      const blob = new Blob([response.data], { type: 'blob' })

      track(window.analytics, 'Clarence Report Download', {
        user: id,
        condominiumId: currentCondominium.id,
        unitId: unitId
      })

      saveAs(blob, `Declaração-de-quitação-${dd}-${mm}-${yyyy}.pdf`)
      closeModal()
    } catch (error) {
      console.log(error)
      closeModal()
      setErrorModal(true)
    }
  }

  const closeModal = () => {
    setDeclarationModal(false)
    setUnitId(null)
  }

  return (
    <>
      <Card {...props}>
        <DialogOverlay open={declarationModal} onClose={closeModal}>
          <Box
            sx={{
              backgroundColor: 'white',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              minHeight: 'cover',
              maxHeight: '70%',
              width: '22%',
              borderRadius: 1,
              paddingX: '2%',
              paddingBottom: '2%',
              paddingTop: '1.5%'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                width: '100%'
              }}
            >
              <DialogCloseButton onClose={closeModal} />
            </Box>
            <Heading5 className='center' style={{ marginBottom: 30 }}>
              Selecione uma unidade xxx
            </Heading5>
            <Box sx={{ overflow: 'auto', width: '100%' }}>
              <RadioGroup
                value={unitId}
                onChange={(e) => setUnitId(Number(e.target.value))}
                aria-labelledby='demo-customized-radios'
                name='customized-radios'
              >
                {unitList.map((row, index) => {
                  let test = unitList.some(
                    (obj) =>
                      obj.condominium &&
                      obj.condominium.id === currentCondominium.id
                  )
                  if (!test && index === 0) {
                    return (
                      <Box
                        key={index}
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center'
                        }}
                      >
                        <p>Nenhuma unidade encontrada no condomínio atual</p>
                      </Box>
                    )
                  }
                  if (
                    test &&
                    row &&
                    row.unit &&
                    row.unit.number &&
                    row.condominium &&
                    row.condominium.id === currentCondominium.id
                  ) {
                    return (
                      <FormControlLabel
                        onClick={() =>
                          setSelectedCondominiumId(row.condominium.id)
                        }
                        key={index}
                        value={
                          row && row.unit && row.unit.id ? row.unit.id : null
                        }
                        control={<Radio />}
                        label={`${
                          row && row.unit && row.condominium.name
                            ? row.condominium.name
                            : 'Sem condomínio'
                        } - ${
                          row &&
                          row.unit &&
                          row.unit.block &&
                          row.unit.block + ` - `
                        }${
                          row && row.unit && row.unit.number
                            ? row.unit.number
                            : 'Sem unidade'
                        }`}
                      />
                    )
                  }
                })}
              </RadioGroup>
            </Box>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: 5
              }}
            >
              <Button
                onClick={() => downloadDeclaration()}
                startIcon={<DownloadIcon size='small' />}
                disabled={!unitId || !selectedCondominiumId}
              >
                Download
              </Button>
            </Box>
          </Box>
        </DialogOverlay>
        <DialogOverlay open={errorModal} onClose={() => setErrorModal(false)}>
          <Box
            sx={{
              backgroundColor: 'white',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              minHeight: 'cover',
              maxHeight: '70%',
              width: '22%',
              borderRadius: 1,
              paddingX: '2%',
              paddingBottom: '2%',
              paddingTop: '1.5%'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                width: '100%'
              }}
            >
              <DialogCloseButton onClose={() => setErrorModal(false)} />
            </Box>
            <Heading6
              className='center'
              style={{ marginTop: 10, textAlign: 'center' }}
            >
              Foram encontradas pendências no seu cadastro. Pedimos que entre em
              contato com nosso atendimento para maiores esclarecimentos.
              <br />
              <a href='mailto:atendimento@condofy.com.br'>
                atendimento@condofy.com.br
              </a>
            </Heading6>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: 5
              }}
            >
              <Button onClick={() => setErrorModal(false)}>Ok</Button>
            </Box>
          </Box>
        </DialogOverlay>
        <CardHeader
          title='Faturas'
          action={
            <OptionsButton
              options={[
                {
                  label: 'Declaração de quitação',
                  id: 0,
                  onClick: () => openDeclaration()
                }
              ]}
            />
          }
        />
        <BillingListFilters
          condominium={currentCondominium}
          filters={filters}
          onFilterChange={handleFilterChange}
        />
        <CardContent>
          <DataTable
            variant='borderless'
            size='small'
            loading={metadata.loading}
            onRowClick={openDetail}
            columns={columns}
            rows={items}
          />
        </CardContent>
      </Card>
      <Flex width='100%' justify='flex-end'>
        <Pagination
          page={page}
          totalResults={metadata.totalRecords}
          pageSize={metadata.recordsPerPage}
          onChange={(event, page) => setPage(page)}
          disabled={metadata.loading}
        />
      </Flex>
    </>
  )
}
