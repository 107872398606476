import { createContext, useContext } from 'react'
import { initialState, reducer } from './reducer'
import { useActions } from './actions'
import { applyMiddleware } from './middleware'
import { withProvider } from '../core/'

export const BankAccountContext = createContext()

export const BankAccountProvider = withProvider({
  reducer,
  initialState,
  useActions,
  applyMiddleware
})(BankAccountContext)

export const useBankAccountContext = () => useContext(BankAccountContext)
