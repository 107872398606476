import React from 'react'
import { isAndroid, isIOS } from 'react-device-detect'
import { useAppContext } from '@condofy/next/store/app'
import { Modal } from '@condofy/next/components'
import {
  DialogCloseButton,
  Flex,
  Heading2,
  SmallBody,
  typography
} from '@condofy/components'
import { Box, Link } from '@mui/material'
import styled from 'styled-components'
import MobileApp from './MobileApp'
import GooglePlay from './GooglePlay'
import AppStore from './AppStore'

const StyledModal = styled(Modal)`
  & .MuiPaper-root {
    max-width: 340px !important;
    text-align: center;
  }
  * {
    font-family: ${typography.type.tertiary} !important;
  }
`

const StyledLink = styled(Link)`
  color: #fff !important;
`

export const MobileAppModal = () => {
  const {
    state: {
      mobile: {
        modal: { open }
      }
    },
    actions: { closeMobileDeviceModal }
  } = useAppContext()

  const handleClose = () => {
    closeMobileDeviceModal()
  }
  const showBoth = !isAndroid && !isIOS

  return (
    <StyledModal maxWidth='xs' hideHeader={true} open={open}>
      <Box position='relative'>
        <Box position='absolute' top='0' right='0'>
          <DialogCloseButton onClose={handleClose} />
        </Box>
        <Flex
          spacingY={3}
          direction='column'
          justify='center'
          alignItems='center'
        >
          <div>
            <MobileApp />
          </div>
          <Heading2 variant='h5'>
            Conheça nosso aplicativo para celular!
          </Heading2>
          <SmallBody>
            Identificamos que você está acessando através do browser do seu
            celular. Sabia que temos um aplicativo que vai facilitar o seu uso?
            Baixe aqui:
          </SmallBody>
          <Flex spacingX={1}>
            {(isAndroid || showBoth) && (
              <StyledLink
                target='_blank'
                href='https://play.google.com/store/apps/details?id=br.com.condofy'
                style={{ color: '#fff !important' }}
              >
                <GooglePlay />
              </StyledLink>
            )}
            {(isIOS || showBoth) && (
              <Link
                target='_blank'
                href='https://apps.apple.com/br/app/condofy-administradora-digital/id1412658826'
              >
                <AppStore />
              </Link>
            )}
          </Flex>
        </Flex>
      </Box>
    </StyledModal>
  )
}
