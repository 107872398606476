import { updateUserConnection } from '@condofy/next/service/userConnection'
import { store, userConnectionActionTypes, pending } from './index'

// ACTION TYPES
const USER_CONNECTION_EDIT = 'USER_CONNECTION_EDIT'
const USER_CONNECTION_UPDATE_SELECTED = 'USER_CONNECTION_UPDATE_SELECTED'
const USER_CONNECTION_EDIT_MODAL_VISIBLE = 'USER_CONNECTION_EDIT_MODAL_VISIBLE'

// ACTIONS
export const editActions = (state, dispatch) => ({
  updateSelectedUserConnection: (data) =>
    dispatch({ type: USER_CONNECTION_UPDATE_SELECTED, payload: data }),

  editUserConnection: (payload) =>
    dispatch({ type: USER_CONNECTION_EDIT, payload }),

  closeEditUserConnectionModal: () => {
    dispatch({
      type: userConnectionActionTypes.USER_CONNECTION_SELECT,
      payload: false
    })
    dispatch({ type: USER_CONNECTION_EDIT_MODAL_VISIBLE, payload: false })
  },

  openEditUserConnectionModal: ({ condominium, user, unit }) => {
    dispatch({
      type: userConnectionActionTypes.USER_CONNECTION_SELECT,
      payload: {
        ...user,
        typeId: user.userType.id,
        condominiumId: condominium.id,
        unitId: unit.id
      }
    })
    dispatch({ type: USER_CONNECTION_EDIT_MODAL_VISIBLE, payload: true })
  }
})

// MIDDLEWARE
export const edit = async (dispatch, payload) => {
  try {
    pending(dispatch, true)
    return updateUserConnection(payload)
  } finally {
    pending(dispatch, false)
  }
}

// REDUCER
export const editReducer = (state, action) => {
  switch (action.type) {
    case USER_CONNECTION_UPDATE_SELECTED:
      return store(state, { selected: action.payload })

    case USER_CONNECTION_EDIT_MODAL_VISIBLE:
      return store(state, { editModalOpen: action.payload })

    default:
      return state
  }
}

export const editActionTypes = {
  USER_CONNECTION_EDIT,
  USER_CONNECTION_UPDATE_SELECTED,
  USER_CONNECTION_EDIT_MODAL_VISIBLE
}
