import React from 'react'
import { Select } from '@condofy/components'
import { MenuItem } from '@mui/material'

const recurrence = [
  {
    value: 'D',
    label: 'Diariamente'
  },
  {
    value: 'W',
    label: 'Semanalmente'
  },
  {
    value: 'M',
    label: 'Mensalmente'
  },
  {
    value: 'Y',
    label: 'Anualmente'
  },
  {
    value: 'U',
    label: 'Apenas uma vez'
  }
]

export const RecurrenceSelect = ({ label = 'Recorrência', ...props }) => {
  return (
    <Select label={label} {...props}>
      {recurrence.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </Select>
  )
}
