import { deleteSchedulingArea } from '@condofy/next/service/area'
// import { store, areaActionTypes } from './index'

// ACTION TYPES
const AREA_DELETE = 'AREA_DELETE'
// const AREA_DELETE_MODAL_VISIBLE = 'AREA_DELETE_MODAL_VISIBLE'

// ACTIONS
export const deleteActions = (state, dispatch) => ({
  deleteArea: (payload) => dispatch({ type: AREA_DELETE, payload })

  // closeDeleteAreaModal: () => {
  //   dispatch({
  //     type: areaActionTypes.AREA_SELECT,
  //     payload: false
  //   })
  //   dispatch({ type: AREA_DELETE_MODAL_VISIBLE, payload: false })
  // },

  // openDeleteAreaModal: (area) => {
  //   dispatch({
  //     type: areaActionTypes.AREA_SELECT,
  //     payload: area
  //   })
  //   dispatch({ type: AREA_DELETE_MODAL_VISIBLE, payload: true })
  // }
})

// MIDDLEWARE
export const remove = (dispatch, payload) => deleteSchedulingArea(payload)

// REDUCER
export const deleteActionTypes = {
  AREA_DELETE
  // AREA_DELETE_MODAL_VISIBLE
}

// REDUCER
export const deleteReducer = (state, action) => {
  switch (action.type) {
    // case AREA_DELETE_MODAL_VISIBLE:
    //   return store(state, {
    //     deleteModalOpen: action.payload
    //   })

    default:
      return state
  }
}
