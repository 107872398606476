import { colors, DataTableRow, theme } from '@condofy/components'
import React from 'react'
import styled from 'styled-components'
// import { DataTableCell, ButtonMenu, MenuItem } from '@condofy/components'

const StyledDataTableRow = styled(DataTableRow)`
  background: ${colors.light200};
  .MuiTableCell-root {
    padding-top: ${theme.spacing(1)};
    padding-bottom: ${theme.spacing(1)};
  }

  & .MuiChip-root {
    max-width: initial !important;
  }
`

export const FinancialStatementItemDetail = ({ item, columns }) => {
  const rows = item.items.map((i) => {
    return {
      items: [
        {
          category: i.category,
          description: i.description
        }
      ],
      amount: i.amount,
      level: 2,
      parent: item
    }
  })
  return (
    <>
      {rows.map((i, index) => (
        <StyledDataTableRow key={index} row={i} columns={[...columns, {}]} />
      ))}
    </>
  )
}
