import { createAnnouncement } from '@condofy/next/service/announcement'
import { store, pending } from './index'

// ACTION TYPES
const ANNOUNCEMENT_CREATE = 'ANNOUNCEMENT_CREATE'
const ANNOUNCEMENT_CREATE_STEP = 'ANNOUNCEMENT_CREATE_STEP'
const ANNOUNCEMENT_CREATE_MODAL_VISIBLE = 'ANNOUNCEMENT_CREATE_MODAL_VISIBLE'

export const createActionTypes = {
  ANNOUNCEMENT_CREATE,
  ANNOUNCEMENT_CREATE_STEP,
  ANNOUNCEMENT_CREATE_MODAL_VISIBLE
}

// ACTIONS
export const createActions = (state, dispatch) => ({
  createAnnouncement: (payload) =>
    dispatch({ type: ANNOUNCEMENT_CREATE, payload }),

  announcementUpdateStep: (payload) =>
    dispatch({ type: ANNOUNCEMENT_CREATE_STEP, payload }),

  closeCreateAnnouncementModal: () => {
    dispatch({ type: ANNOUNCEMENT_CREATE_STEP, payload: 1 })
    dispatch({ type: ANNOUNCEMENT_CREATE_MODAL_VISIBLE, payload: false })
  },

  openCreateAnnouncementModal: () =>
    dispatch({ type: ANNOUNCEMENT_CREATE_MODAL_VISIBLE, payload: true })
})

// MIDDLEWARE
export const create = async (dispatch, payload) => {
  try {
    pending(dispatch, true)
    if (payload.payload.Status !== 'A') {
      payload.payload.ReceiversEmail = payload.payload.ReceiversEmail.filter((email) => email !== -1)
    }
    const response = await createAnnouncement(payload)
    return response
  } finally {
    pending(dispatch, false)
  }
}

// REDUCER
export const createReducer = (state, action) => {
  switch (action.type) {
    case ANNOUNCEMENT_CREATE_MODAL_VISIBLE:
      return store(state, { createModalOpen: action.payload })

    case ANNOUNCEMENT_CREATE_STEP:
      return store(state, { createStep: action.payload })

    default:
      return state
  }
}
