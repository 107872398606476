import React, { useCallback, useEffect } from 'react'
import { Modal, ActionRow, ModalContent } from '@condofy/next/components'
import { useCommunityContext } from '@condofy/next/store/community'
import { useUserContext } from '@condofy/next/store/user'
import { MarketListingDetail } from './MarketListingDetail'
import { Box } from '@mui/material'
import { useSnackbar } from '@condofy/next/hooks/useSnackbar'
import { track } from '@condofy/next/service/segment'

export const MarketListingDetailModal = () => {
  const {
    state: {
      detailListing: {
        modal: { open },
        data,
        metadata,
        error
      },
      orderCreated
    },
    actions: { closeDetailModal, createOrder, openCreatedOrderModal }
  } = useCommunityContext()
  const {
    state: { currentCondominium, id }
  } = useUserContext()
  const { enqueueSnackbar } = useSnackbar()
  const handleClose = useCallback(closeDetailModal, [])

  useEffect(() => {
    if (error) {
      enqueueSnackbar('Ocorreu um erro', { variant: 'error' })
      handleClose()
    }
  }, [error, enqueueSnackbar, handleClose])

  useEffect(() => {
    if (orderCreated.error) {
      enqueueSnackbar('Ocorreu um erro', { variant: 'error' })
    }
  }, [orderCreated.error, enqueueSnackbar])

  const handleSubmit = () => {
    createOrder({ currentCondominium, id: data.id })
      .then(() => {
        openCreatedOrderModal()
        handleClose()

        track(window.analytics, 'Community Send Interest on Offer', {
          condominiumId: currentCondominium.id,
          offerId: data.id,
          userId: id
        })
      })
      .catch((error) => {
        const createError = error.response.data.reports[0].message
        enqueueSnackbar(createError, { variant: 'error' })
        handleClose()
      })
  }

  return (
    <Modal
      title={data.item}
      open={open}
      onClose={handleClose}
      maxWidth='xs'
      footer={
        <ActionRow
          fullWidth
          loading={orderCreated.metadata?.loading}
          confirmText='Confirmar'
          onSubmit={handleSubmit}
          disabled={metadata.loading}
          onCancel={handleClose}
        />
      }
    >
      <ModalContent spacing={0}>
        <Box overflow='hidden'>
          <MarketListingDetail listing={data} />
        </Box>
      </ModalContent>
    </Modal>
  )
}
