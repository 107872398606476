import React, { useEffect, useState } from 'react'
import { Page, Body, theme } from '@condofy/components'
import { CondofyLogo } from '@condofy/next/components'
import styled from 'styled-components'
import { useQuery } from '@condofy/next/hooks'
import { createBelvoConsent } from '@condofy/next/service/belvo'
import { useUserContext } from '@condofy/next/store/user'

const StyledPage = styled(Page)`
  align-items: center !important;
  max-width: 600px !important;
  margin: 0 auto;
  text-align: center !important;
`
const Text = styled(Body)`
  margin-top: ${theme.spacing(4)} !important;
`

export const BelvoConsentFinishedPage = () => {
  const status = useQuery().get('status');
  const linkId = useQuery().get('link');
  const institutionName = useQuery().get('institution');
  const [isLoading, setIsLoading] = useState(true);
  const { state: { currentCondominium } } = useUserContext();

  useEffect(() => {
    if (status === 'success' && linkId && institutionName) {
      createBelvoConsent({
        condominium: currentCondominium,
        body: { linkId, institutionName }
      }).then(() => {
        setIsLoading(false);
        setTimeout(() => window.close(), 3000);
      });
      return;
    }
    setIsLoading(false);
    setTimeout(() => window.close(), 3000);
  }, [status, linkId, institutionName]);

  return (
    <StyledPage>
      <CondofyLogo />
      {
        isLoading ?
        (<Text>Processando...</Text>) :
        (<Text>
            {
              status !== 'success' ?
                'O processo de integração foi cancelado. Esta janela será automaticamente fechada em breve.' :
                'O processo de integração está finalizado. Esta janela será automaticamente fechada em breve.'
            }
        </Text>)
      }
    </StyledPage>
  )
}
