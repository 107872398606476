import React from 'react'
import { DatePicker, CurrencyTextField, FormRow } from '@condofy/components'
import { FilePond } from '@condofy/next/components'
import { TextField } from '@mui/material'
import { FundingTypeSelect } from './FundingTypeSelect'
import { CategorySelect } from './CategorySelect'
import { UnitSelect } from './UnitSelect'
import { Controller, useFormContext } from 'react-hook-form'
import { styled } from 'styled-components'

const FieldContainer = styled.div`
  margin-bottom: 20px; /* Margem entre os campos */
`

export const FinancialStatementNewEntryForm = ({ categories, unities }) => {
  const { setValue } = useFormContext()
  const { control, watch } = useFormContext()
  const fundingType = watch('fundingType')
  const sortedCategories = categories.sort((a, b) =>
    a.name.localeCompare(b.name)
  )
  const sortedUnities = unities.sort((a, b) => a.name.localeCompare(b.name))
  const handleFiles = (files) => {
    setValue('receipt', files)
  }

  return (
    <>
      <FieldContainer>
        <Controller
          name='fundingType'
          control={control}
          sx={{
            marginBottom: '10px'
          }}
          render={({ field }) => (
            <FundingTypeSelect {...field} required fullWidth />
          )}
        />
      </FieldContainer>

      <FieldContainer>
        <Controller
          name='categoryId'
          control={control}
          render={({ field }) => (
            <CategorySelect
              {...field}
              categories={sortedCategories}
              required
              fullWidth
            />
          )}
        />
      </FieldContainer>

      <FieldContainer>
        <Controller
          name='unitId'
          control={control}
          render={({ field }) => (
            <UnitSelect {...field} unities={sortedUnities} required fullWidth />
          )}
        />
      </FieldContainer>

      <FieldContainer>
        <Controller
          name='amount'
          control={control}
          render={({ field }) => (
            <CurrencyTextField
              {...field}
              fundingType={fundingType}
              label='Valor'
              required
              fullWidth
            />
          )}
        />
      </FieldContainer>

      <FieldContainer>
        <Controller
          name='paidAt'
          control={control}
          render={({ field }) => (
            <DatePicker
              {...field}
              label='Data de pagamento'
              required
              autoOk
              clearable
              fullWidth
            />
          )}
        />
      </FieldContainer>

      <FieldContainer>
        <Controller
          name='description'
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label='Descrição'
              required
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
          )}
        />
      </FieldContainer>

      <FieldContainer>
        <FormRow>
          <FilePond maxTotalFileSize='20MB' setFile={handleFiles} />
        </FormRow>
      </FieldContainer>
    </>
  )
}
