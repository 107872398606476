import React from 'react'
import styled from 'styled-components'
import {
  Dialog,
  DialogContent,
  DialogSection,
  colors,
  FlexColumn,
  Body,
  Checkbox,
  Button
} from '@condofy/components'

const UnitRow = styled.div`
  background-color: ${colors.light200};
  color: ${colors.dark300};
  margin: 4px 0;
  padding: 1px 7px;
`

export const RestrictionCreateAreaModal = ({
  isOpen = false,
  onSubmit: emitSubmitEvent = () => {},
  onSelect: emitSelectEvent = () => {},
  onClose: emitCloseEvent = () => {},
  unitList = []
}) => (
  <Dialog open={isOpen} onClose={emitCloseEvent} title='Restringir Unidade'>
    <DialogContent>
      <FlexColumn>
        {unitList.map((unit, index) => (
          <UnitRow key={unit.id}>
            <Checkbox
              checked={
                Boolean(unit.restriction) || Boolean(unit.toBeRestricted)
              }
              disabled={Boolean(unit.restriction)}
              color='primary'
              onChange={() => emitSelectEvent(unit, index)}
            />
            <Body>{unit.name}</Body>
          </UnitRow>
        ))}
      </FlexColumn>
    </DialogContent>
    <DialogSection border={1}>
      <Button onClick={() => emitSubmitEvent()}>Restringir</Button>
    </DialogSection>
  </Dialog>
)
