import { condofyProtected, condofyProtectedMultiPart } from './instance'
import { jsonToFormData } from '@condofy/next/service/formData'

export const search = (
  payload = {
    currentCondominium: { id: '' },
    PageNumber: 1,
    generalSearch: '',
    recordsPerPage: 10
  }
) =>
  condofyProtected.get(
    `/v1/condominiums/${payload.currentCondominium.id}/occurrences`,
    {
      params: {
        PageNumber: payload.PageNumber,
        GeneralSearch: payload.generalSearch,
        RecordsPerPage: payload.recordsPerPage
      }
    }
  )

export const create = ({ currentCondominium, payload }) =>
  condofyProtectedMultiPart.post(
    `/v2/condominiums/${currentCondominium.id}/occurrences`,
    jsonToFormData(payload)
  )

export const update = ({ currentCondominium, payload }) =>
  condofyProtectedMultiPart.put(
    `/v2/condominiums/${currentCondominium.id}/occurrences/${payload.id}`,
    jsonToFormData(payload)
  )
