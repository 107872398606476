import PropTypes from 'prop-types'
import { css } from 'styled-components'
import { controls, colors } from '../../styles'

export const options = {
  propTypes: {
    inputVariant: PropTypes.oneOf(['standard', 'outlined', 'filled'])
  }
}

export const dateTimeStyles = css`
  font-family: ${controls.field.fontFamily};
  .MuiOutlinedInput-root:not(.Mui-error) {
    .MuiOutlinedInput-notchedOutline {
      border-color: ${controls.field.borderColor};
    }
    &:hover {
      .MuiOutlinedInput-notchedOutline {
        border-color: ${colors.primary} !important;
      }
    }
  }
  .MuiInputBase-root {
    background: ${controls.field.backgroundColor};
    border-radius: ${controls.field.borderRadius};
    font-size: ${controls.field.fontSize};
  }
  .MuiInputBase-input {
    padding: ${controls.field.padding};
  }
  .MuiFormLabel-root {
    font-family: ${controls.field.fontFamily};
    font-size: ${controls.field.fontSize};

    &:not(.Mui-error) {
      color: ${controls.field.labelColor};
    }
  }
  .MuiInputAdornment-root {
    color: ${controls.field.adornmentColor};
  }
`
