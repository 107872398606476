import React from 'react'
import { Page, PageTitle } from '@condofy/components'
import { PremiumPage, PremiumToggle } from '@condofy/next/components'
import { TicketList } from '@condofy/next/containers/ticket'
import TicketImage from '~/assets/chamados.svg'

const StarterContent = () => (
  <PremiumPage
    title='Chamados'
    text='Precisa de ajuda? Através de chamados você fala diretamente com nosso time para resolver qualquer problema de forma simples.'
    image={<TicketImage />}
  />
)

export const TicketsPage = () => (
  <Page>
    <PremiumToggle fallback={<StarterContent />}>
      <PageTitle title='Chamados' icon='tickets' />
      <TicketList />
    </PremiumToggle>
  </Page>
)
