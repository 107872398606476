import styled from 'styled-components'
import { colors } from '@condofy/components'
import { Row } from 'reactstrap'
import { typography } from '@condofy/components'

export const RowCall = styled(Row)`
  margin-bottom: 20px;
`

export const Call = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const AuthorCall = styled.span`
  font-family: ${typography.type.secondary};
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  color: ${colors.dark300};
`

export const DateCall = styled.span`
  font-family: ${typography.type.secondary};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.dark300};
`

export const CommentCall = styled.span`
  font-family: ${typography.type.secondary};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.dark200};
`

export const ConteinerFileCall = styled.span`
  margin-left: 42px;
  font-family: ${typography.type.secondary};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.dark200};
`

export const TitleFileCall = styled.span`
  margin-left: 5px;
  font-family: ${typography.type.secondary};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: ${colors.dark300};
`
