import React from 'react'
import { TextField } from '../TextFields'
import styled from 'styled-components'

const StyledSelect = styled(TextField)`
  & .MuiSelect-select {
    height: 1.1876em!important;
    line-height: 1.1876em!important;
    min-height: 1.1876em!important;
  }
`
export const Select = (args) => <StyledSelect select {...args} />
