import React, { useState } from 'react'
import {
  Form,
  FormRow,
  PriceTextField,
  Select,
  TextField,
  MenuItem
} from '@condofy/components'
import { useFormContext } from 'react-hook-form'
import { FilePond } from '@condofy/next/components'
import { FormField as Field } from '@condofy/next/containers/'

const styles = {
  fullWidth: {
    width: '100%'
  }
}

export const offerTypes = [
  { id: 1, name: 'Grátis' },
  { id: 2, name: 'Por Mês' },
  { id: 3, name: 'Por Unidade' },
  { id: 4, name: 'Por Uso/Dia' }
]

export const ListingForm = () => {
  const [files, setFiles] = useState([])
  const [disable, setDisable] = useState(false)
  const [newOffer, setNewOffer] = useState({
    title: '',
    description: ''
  })
  const { setValue } = useFormContext()

  const onFilesChange = (files) => {
    setValue('files', files)
    setFiles(files)
  }

  const onChangePrice = (value) => () => {
    if (value === 1) {
      setDisable(true)
    } else {
      setDisable(false)
    }
  }

  const handleChange = (event) => {
    const { name, value } = event.target
    
    setValue(name, value)

    setNewOffer({ ...newOffer, [name]: value })
  }

  return (
    <Form className="fullWidth" sx = {{...styles}}>
      <FormRow>
        <Field
          name='title'
          Component={
            <>
              <TextField
                className="fullWidth"
                name='title'
                label='Objeto, serviço ou outros.'
                placeholder='O que você está ofertando?'
                inputProps={{ maxLength: 30 }}
                onChange={handleChange}
                required
              />
              <h4
                style={
                  newOffer.title.length === 30
                    ? {
                        position: 'absolute',
                        right: 20,
                        fontSize: 11,
                        color: 'red'
                      }
                    : {
                        position: 'absolute',
                        right: 20,
                        fontSize: 11,
                        color: 'blue'
                      }
                }
              >
                {newOffer.title.length}/30
              </h4>
            </>
          }
        />
      </FormRow>
      <FormRow>
        <Field
          name='description'
          Component={
            <>
              <TextField
                name='description'
                multiline
                rows={3}
                className="fullWidth"
                label='Descrição'
                placeholder='Descreva sua oferta'
                inputProps={{ maxLength: 250 }}
                onChange={handleChange}
              />
              <h4
                style={
                  newOffer.description.length === 250
                    ? {
                        position: 'absolute',
                        right: 20,
                        fontSize: 11,
                        top: 50,
                        color: 'red'
                      }
                    : {
                        position: 'absolute',
                        right: 20,
                        fontSize: 11,
                        top: 50,
                        color: 'blue'
                      }
                }
              >
                {newOffer.description.length}/250
              </h4>
            </>
          }
        />
      </FormRow>
      <FormRow itemsGrid={6}>
        <Field
          name='chargerType'
          Component={
            <Select
              className="fullWidth"
              label='Cobrança'
              placeholder='Por mês'
            >
              {offerTypes.map((offerType) => (
                <MenuItem
                  key={offerType.id}
                  value={offerType.id}
                  onClick={onChangePrice(offerType.id)}
                >
                  {offerType.name}
                </MenuItem>
              ))}
            </Select>
          }
        />
        {!disable && (
          <Field
            name='price'
            passRef={false}
            defaultValue=''
            Component={
              <PriceTextField
                className="fullWidth"
                label='Preço'
                placeholder='R$'
                disabled={disable}
              />
            }
          />
        )}
      </FormRow>
      <FormRow>
        <FilePond
          files={files}
          allowMultiple={true}
          label='Adicione Imagem'
          setFile={onFilesChange}
        />
      </FormRow>
    </Form>
  )
}
