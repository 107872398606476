import React, { useEffect, useState } from 'react'
import {
  TabsPanelItem,
  TabsPanel,
  Page,
  SkeletonCard
} from '@condofy/components'
import { MarketTab } from './MarketTab'
import { MyListingsTab } from './MyListingsTab'
import {
  CreateListingModal,
  EditListingModal,
  MarketListingDetailModal,
  CommunityOnboardModal,
  CommunityOnboardPhoneModal,
  CommunityOnboardOfferModal,
  OrderCreatedModal
} from '@condofy/next/containers/'
import { useCommunityContext } from '@condofy/next/store/community'
import { useUserContext } from '@condofy/next/store/user'

import {
  onboardIsPhoneConfirmed,
  hasDoneOnboarding
} from '@condofy/next/service/community'

export const CommunityPage = () => {
  const {
    state: { currentCondominium }
  } = useUserContext()
  const {
    actions: {
      openOnboardingModal,
      openOnboardingPhoneModal
    }
  } = useCommunityContext()

  const [hasPermissions, setHasPermission] = useState(false)

  const checkForUserOnboarding = async () => {
    const phoneConfirmed = await onboardIsPhoneConfirmed()

    if (!phoneConfirmed) {
      openOnboardingPhoneModal()
      return
    }
    const onboardingDone = await hasDoneOnboarding({ currentCondominium })

    if (!onboardingDone) {
      openOnboardingModal()
      return
    }

    setHasPermission(true)
  }

  useEffect(() => {
    checkForUserOnboarding();
  }, [hasPermissions])

  return (
    <Page>
      {hasPermissions ? (
        <>
          <TabsPanel>
            <TabsPanelItem label='Marketplace'>
              <MarketTab />
            </TabsPanelItem>
            <TabsPanelItem label='Minhas Ofertas'>
              <MyListingsTab />
            </TabsPanelItem>
          </TabsPanel>
          <MarketListingDetailModal />
          <OrderCreatedModal />
          <CreateListingModal />
          <EditListingModal />
        </>
      ) : (
        <>
          <TabsPanel>
            <TabsPanelItem label='Marketplace'>
              <SkeletonCard />
              <SkeletonCard />
            </TabsPanelItem>
            <TabsPanelItem label='Minhas Ofertas'>
              <SkeletonCard />
            </TabsPanelItem>
          </TabsPanel>
        </>
      )}
      <CommunityOnboardPhoneModal />
      <CommunityOnboardModal />
      <CommunityOnboardOfferModal />
    </Page>
  )
}
