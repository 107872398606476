import React, { useEffect, useState } from 'react'
import {
  listUserConnections,
  removeUserConnection
} from '@condofy/next/service/userConnection'
import { useSnackbar, handleError } from '@condofy/next/hooks/useSnackbar'
import {
  Chip,
  Button,
  DataTable,
  DialogContent,
  Dialog
} from '@condofy/components'

export const MyConnections = () => {
  const { enqueueSnackbar } = useSnackbar()
  const [loading, setLoading] = useState(false)
  const [selectedForRemoval, setSelectedForRemoval] = useState(null)
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
  const [connectionList, setConnectionList] = useState([])

  useEffect(() => {
    listConnections()
  }, [])

  const listConnections = () => {
    setLoading(true)
    listUserConnections()
      .catch(handleError(enqueueSnackbar))
      .then((res) => setConnectionList(res?.data?.data || []))
      .finally(() => setLoading(false))
  }

  const confirmRemoval = (row) => {
    setSelectedForRemoval(row)
    setConfirmDialogOpen(true)
  }

  const removeSelectedConnection = () => {
    removeUserConnection({ connectionId: selectedForRemoval.id })
      .then(() => {
        enqueueSnackbar('Conexão cancelada com sucesso', {
          variant: 'success'
        })
        listConnections()
      })
      .catch(handleError(enqueueSnackbar))
      .finally(() => {
        setConfirmDialogOpen(false)
        setSelectedForRemoval(null)
      })
  }

  const columns = [
    {
      field: 'condominium',
      headerName: 'Condomínio',
      valueGetter: ({ row }) => row.condominium?.name || ''
    },
    {
      field: 'unit',
      headerName: 'Unidade',
      valueGetter: ({ row }) => row.unit?.number || ''
    },
    {
      field: 'isApproved',
      headerName: 'Estado',
      renderCell: ({ row }) =>
        row.isApproved ? (
          <Chip color='success' label='Aprovado' />
        ) : (
          <Chip color='danger' label='A aguardar aprovação' />
        )
    },
    {
      headerName: '',
      renderCell: ({ row }) =>
        row.isApproved ? (
          <Button color='danger' onClick={() => confirmRemoval(row)}>
            Cancelar a conexão
          </Button>
        ) : (
          <></>
        )
    }
  ]
  return (
    <>
      <DataTable
        variant='borderless'
        size='small'
        loading={loading}
        columns={columns}
        rows={connectionList}
      />
      <Dialog
        title='Confirmar'
        open={confirmDialogOpen}
        onClose={() => setConfirmDialogOpen(false)}
        actions={
          <>
            <Button
              color='secondary'
              onClick={() => setConfirmDialogOpen(false)}
            >
              Cancelar
            </Button>
            <Button onClick={removeSelectedConnection}>Confirmar</Button>
          </>
        }
      >
        <DialogContent>
          <div>
            <p>
              Tem a certeza que pretende cancelar a conexão com a unidade{' '}
              <b>{selectedForRemoval?.unit?.number}</b> do condomínio{' '}
              <b>{selectedForRemoval?.condominium?.name}</b>?
            </p>
          </div>
        </DialogContent>
      </Dialog>
    </>
  )
}
