import React from 'react'
import { Avatar, SmallBody } from '@condofy/components'
import { useUserContext } from '@condofy/next/store/user'
import { DataCard } from '@condofy/next/components/DataCard'

export const UserCard = ({ user, ...props }) => {
  const {
    state: { currentCondominium, isSuperAdmin },
    actions: { openEditUserModal, openDetailsUserModal }
  } = useUserContext()

  const handleClick = () => {
    isSuperAdmin ? openEditUserModal(user) : openDetailsUserModal(user)
  }

  const userCondominium =
    user.condominiums &&
    user.condominiums.find(
      (condominium) => condominium.id === currentCondominium.id
    )
  const userUnits =
    userCondominium &&
    userCondominium.units.map((unit) => unit?.name).join(', ')

  return (
    <DataCard
      avatar={<Avatar size='large' label={user.name}></Avatar>}
      title={user.name}
      buttonText={isSuperAdmin ? 'Editar' : 'Visualizar'}
      onClick={handleClick}
      {...props}
    >
      <SmallBody color='textSecondary'>{userUnits}</SmallBody>
      <SmallBody color='textSecondary'>{user.role}</SmallBody>
    </DataCard>
  )
}
