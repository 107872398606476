import React, { useState } from 'react'
import { Modal, ModalContent } from '@condofy/next/components'
import { useBillingContext } from '@condofy/next/store/billing'
import { Box, Card, Link, Divider } from '@mui/material'
import { colors } from '../../../@condofy/styles'
import { Caption } from '@condofy/components'
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined'
import { Button } from '../../../@condofy/components/Button'
import DownloadIcon from '@mui/icons-material/Download'
import { condofyPfd } from '../../service/instance'
import { token } from '@condofy/next/service/user'
import { track } from '@condofy/next/service/segment'
import { saveAs } from 'file-saver'
import { useSnackbar } from 'notistack'
import { useUserContext } from '@condofy/next/store/user'

export const BillingDeclarationModal = () => {
  const { enqueueSnackbar } = useSnackbar()
  const [loading, setLoading] = useState(false)

  const {
    state: { id }
  } = useUserContext()

  const {
    state: { declaration },
    actions: { closeDeclarationModal }
  } = useBillingContext()

  const handleClose = () => {
    closeDeclarationModal()
  }

  const downloadDeclaration = async (condominiumId, unitId) => {
    setLoading(true)
    try {
      let today = new Date()
      let dd = String(today.getDate()).padStart(2, '0')
      let mm = String(today.getMonth() + 1).padStart(2, '0')
      let yyyy = String(today.getFullYear())
      const response = await condofyPfd.post(
        `/v1/condominiums/${condominiumId}/units/${unitId}/download-clearance-certificate-report`,
        {
          responseType: 'blob',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            Accept: 'application/pdf'
          }
        }
      )
      const blob = new Blob([response.data], { type: 'blob' })

      track(window.analytics, 'Clarence Report Download', {
        user: id,
        condominiumId: condominiumId,
        unitId: unitId
      })

      saveAs(blob, `Declaração-de-quitação-${dd}-${mm}-${yyyy}.pdf`)
      setLoading(false)
      handleClose()
    } catch (error) {
      setLoading(false)
      console.log(error)
      handleClose
      enqueueSnackbar(
        'Foram encontradas pendências no seu cadastro. Pedimos que entre em contato com nosso atendimento para maiores esclarecimentos. atendimento@condofy.com.br',
        {
          variant: 'error',
          anchorOrigin: { vertical: 'bottom', horizontal: 'center' }
        }
      )
    }
  }

  return (
    <Modal
      title='Declaração de Quitação'
      open={declaration.modal}
      onClose={handleClose}
    >
      <ModalContent>
        {declaration.units.map((u, index) => (
          <Box key={u.unit.id}>
            <Card
              sx={{
                marginBottom: '10px',
                backgroundColor: colors.light100
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'flex-start'
                  }}
                >
                  <Box
                    sx={{
                      backgroundColor: colors.brandBlue75,
                      width: '32px',
                      height: '32px',
                      borderRadius: 50,

                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',

                      marginRight: '10px'
                    }}
                  >
                    {index + 1}
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column'
                    }}
                  >
                    <Caption sx={{ fontWeight: '700' }}>
                      {u.condominium.name}
                    </Caption>
                    <Caption>
                      {u.unit.block ? `${u.unit.block} -` : ''} {u.unit.number}
                    </Caption>
                  </Box>
                </Box>
                <Box>
                  <Button
                    disabled={u.hasOutstandingInvoices || loading}
                    onClick={() =>
                      downloadDeclaration(u.condominium.id, u.unit.id)
                    }
                  >
                    <DownloadIcon fontSize='small' />
                    Baixar Declaração
                  </Button>
                </Box>
              </Box>
              {u.hasOutstandingInvoices && (
                <Box
                  sx={{
                    width: '100%'
                  }}
                >
                  <Divider
                    sx={{
                      marginTop: '10px',
                      marginBottom: '10px'
                    }}
                  />
                  <Box
                    sx={{
                      width: '100%',
                      backgroundColor: colors.yellow450,
                      marginBottom: '10px',
                      padding: '10px',

                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <WarningAmberOutlinedIcon
                      fontSize='small'
                      sx={{ marginRight: '10px' }}
                    />
                    <Caption>
                      Identificamos pendências no cadastro de sua unidade. Por
                      gentileza, entre em contato conosco para resolvermos
                      juntos! Envie um e-mail para{' '}
                      <Link
                        sx={{
                          color: colors.dark900,
                          textDecoration: 'none'
                        }}
                        href='mailto:atendimento@condofy.com.br'
                      >
                        atendimento@condofy.com.br
                      </Link>
                      .
                    </Caption>
                  </Box>
                </Box>
              )}
            </Card>
          </Box>
        ))}
      </ModalContent>
    </Modal>
  )
}
