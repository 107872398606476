import { condofy } from './instance'
import { jsonToFormData } from '@condofy/next/service/formData'

export const search = ({
  condominiumId,
  page = 1,
  search = '',
  recordsPerPage = 10
}) => {
  return condofy.get(`/v1/condominiums/${condominiumId}/surveys`, {
    params: {
      PageNumber: page,
      Status: search,
      RecordsPerPage: recordsPerPage
    }
  })
}

export const getById = ({ condominiumId, id }) => {
  // this api call doesnt return the attachments
  return condofy.get(`/v1/condominiums/${condominiumId}/surveys/${id}`)
}

export const createSurvey = ({ condominiumId, body }) => {
  return condofy.post(
    `/v2/condominiums/${condominiumId}/surveys`,
    jsonToFormData(body),{
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  )
}

export const updateSurvey = ({ condominiumId, surveyId, body }) => {
  return condofy.put(
    `/v2/condominiums/${condominiumId}/surveys/${surveyId}`,
    jsonToFormData(body),{
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  )
}

export const createVote = ({ condominiumId, surveyId, body }) => {
  return condofy.post(
    `/v1/condominiums/${condominiumId}/surveys/${surveyId}/vote`,
    body
  )
}
