import React, {
  createContext,
  useReducer,
  useEffect,
  useContext,
  useState,
  useCallback
} from 'react'
import { initialState, reducer } from './reducer'
import { useActions } from './actions'
import { applyMiddleware } from './middleware'
import { token } from '@condofy/next/service/user'

export const UserContext = createContext()

export const UserProvider = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const [pending, setPending] = useState(true)
  const actions = useActions(state, applyMiddleware(dispatch))
  const { logout, loadProfile } = actions
  const stableLogout = useCallback(logout, [])
  const stableLoadProfile = useCallback(loadProfile, [])

  useEffect(() => {
    if (!token) stableLogout()

    if (token && !state.id) {
      stableLoadProfile()
        .catch((error) => {
          console.log(error)
          stableLogout()
        })
        .finally(() => {
          setPending(false)
        })
    }
  }, [state.id, stableLogout, stableLoadProfile])

  if (pending || !state.id) {
    return <></>
  } else {
    return <UserContext.Provider value={{ state, actions }} {...props} />
  }
}

export const useUserContext = () => useContext(UserContext)
