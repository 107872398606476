import React, { useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import {
  TextField,
  Checkbox,
  Icon,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Flex
} from '@condofy/components'
import { Modal, ActionRow, ModalContent } from '@condofy/next/components'
import { useUserContext } from '@condofy/next/store/user'
import { useSnackbar, handleError } from '@condofy/next/hooks/useSnackbar'

const TableRowNoSelection = styled(TableRow)`
  user-select: none;
`

export const PublishListModal = ({ open }) => {
  const { enqueueSnackbar } = useSnackbar()
  const {
    state: {
      currentCondominium,
      announcement: { selected },
      announcementReceiver: { items }
    },
    actions: {
      listAnnouncement,
      closeEditAnnouncementModal: close,
      listAnnouncementReceiver,
      editAnnouncement
    }
  } = useUserContext()
  const stableListAnnouncementReceiver = useCallback(
    listAnnouncementReceiver,
    []
  )

  useEffect(() => {
    if (currentCondominium.id)
      stableListAnnouncementReceiver({ currentCondominium })
  }, [currentCondominium, stableListAnnouncementReceiver])

  const [searchReceivers, setSearchReceivers] = useState('')
  const [receiversSelected, setReceiversSelected] = useState([])
  const [loading, setLoading] = useState(false)

  const isSelected = (value) =>
    Boolean(receiversSelected.find((a) => a === value))

  const selectAll = (email) =>
    setReceiversSelected([...items.map((item) => item.email), email])
  const deselectAll = () => setReceiversSelected([])
  const alreadyOnTheList = (email) => receiversSelected.find((a) => a === email)
  const addToTheList = (email) =>
    setReceiversSelected([...receiversSelected, email])
  const removeFromTheList = (email) => {
    const newList = receiversSelected.filter((a) => a !== email && a !== -1)
    setReceiversSelected([...newList])
  }

  const selectReceiver = (email) => {
    if (email < 0) {
      alreadyOnTheList(email) ? deselectAll() : selectAll(email)
    } else {
      alreadyOnTheList(email) ? removeFromTheList(email) : addToTheList(email)
    }
  }

  const handlePublish = () => {
    setLoading(true)
    if (selected.status) delete selected.status

    editAnnouncement({
      currentCondominium,
      payload: {
        ...selected,
        condominiumId: currentCondominium.id,
        ReceiversEmail: receiversSelected,
        Status: 'P'
      }
    })
      .then(() => {
        enqueueSnackbar('Comunicado publicado', { variant: 'success' })
        close()
        deselectAll()
        listAnnouncement({ currentCondominium })
      })
      .catch(handleError(enqueueSnackbar))
      .finally(() => setLoading(false))
  }

  return (
    <Modal
      icon={<Icon icon='announcements' />}
      title='Novo comunicado'
      open={open}
      onClose={close}
      footer={
        <ActionRow
          confirmText='Publicar'
          loading={loading}
          onCancel={close}
          onSubmit={handlePublish}
        />
      }
    >
      <ModalContent>
        <Flex direction='column' spacingY={3}>
          <TextField
            label='Filtrar condôminos'
            onChange={(event) => setSearchReceivers(event.target.value)}
          />
          <TableContainer>
            <Table>
              <TableBody>
                {[{ email: -1, name: 'Todos' }, ...items]
                  .filter((a) => a.name.includes(searchReceivers))
                  .map(({ email, name, unitNumber }) => (
                    <TableRowNoSelection
                      key={`${email}${unitNumber}`}
                      onClick={() => selectReceiver(email)}
                    >
                      <TableCell>
                        <Checkbox
                          color='primary'
                          checked={isSelected(email)}
                          onChange={() => selectReceiver(email)}
                        />
                        <span>{name}</span>
                      </TableCell>
                      <TableCell>
                        <span>{unitNumber}</span>
                      </TableCell>
                    </TableRowNoSelection>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Flex>
      </ModalContent>
    </Modal>
  )
}
