import { condofyProtected } from './instance'

export const schedulingAreaListPromise = ({
  currentCondominium,
  UserName,
  UserEmail
}) =>
  condofyProtected.get(
    `/v1/condominiums/${currentCondominium.id}/scheduling-area`,
    {
      params: {
        UserName,
        UserEmail
      }
    }
  )

export const fetchSchedulingAreaList = ({
  currentCondominium,
  token,
  UserName,
  UserEmail
}) =>
  schedulingAreaListPromise({
    currentCondominium,
    token,
    UserName,
    UserEmail
  })

export const schedulingAreaByIdPromise = ({
  schedulingAreaId,
  currentCondominium
}) => {
  return condofyProtected.get(
    `/v1/condominiums/${currentCondominium.id}/scheduling-area/${schedulingAreaId}`
  )
}

export const fetchSchedulingArea = ({ currentCondominium, schedulingAreaId }) =>
  schedulingAreaByIdPromise({
    currentCondominium,
    schedulingAreaId
  })

export const createSchedulingArea = ({ area, currentCondominium }) =>
  condofyProtected.post(
    `/v1/condominiums/${currentCondominium.id}/scheduling-area`,
    area
  )

export const editSchedulingArea = ({ area, currentCondominium }) =>
  condofyProtected.put(
    `/v1/condominiums/${currentCondominium.id}/scheduling-area/${area.id}`,
    area
  )

export const deleteSchedulingArea = ({ areaId, currentCondominium }) =>
  condofyProtected.delete(
    `/v1/condominiums/${currentCondominium.id}/scheduling-area/${areaId}`
  )
