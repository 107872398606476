import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import { BrowserRouter as Router } from 'react-router-dom'
import { ReduxProvider } from '~/redux/config'
import { Compose } from '@condofy/next/components'
import {
  UserProvider,
  BillingProvider,
  BankAccountProvider,
  AppProvider,
  CommunityProvider,
  SurveyProvider,
  FinancialProvider,
  CalendarProvider,
  IntegrationProvider
} from '@condofy/next/store/'


const providers = [
  UserProvider,
  BillingProvider,
  BankAccountProvider,
  AppProvider,
  FinancialProvider,
  SurveyProvider,
  CommunityProvider,
  CalendarProvider,
  IntegrationProvider
].map((Component, index) => <Component key={index} />)

const Providers = (props) => <Compose components={providers} {...props} />

const domNode = document.createElement('div');
const root = createRoot(domNode);

root.render(
  <ReduxProvider>
    <Providers>
      <Router>
        <App />
      </Router>
    </Providers>
  </ReduxProvider>
)

document.body.appendChild(domNode);
