import { useContext } from 'react'
import { createStore } from '../core/'
import * as listings from './listings'
import * as onboarding from './onboarding'
import * as order from './order'
import * as feeds from './feeds'

const subStores = [listings, onboarding, order, feeds]

const [CommunityContext, CommunityProvider] = createStore(subStores)

export const useCommunityContext = () => useContext(CommunityContext)

export { CommunityContext, CommunityProvider }
