import styled from 'styled-components'
import { DialogActions as MuiDialogActions } from '@mui/material'
import { colors, theme } from '../../styles'

export const DialogActions = styled(MuiDialogActions)`
  &.MuiDialogActions-root {
    border-top: solid 1px ${colors.light500};
    padding: ${theme.spacing(3)} 0 0 0;
    justify-content: flex-start;
  }
`
