import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { FormControl, FormHelperText } from '@mui/material'
import styled from 'styled-components'
import _ from 'lodash'

const StyledFormControl = styled(FormControl)`
  width: 100%;
`

export const FormField = ({ Component, name, passRef = true, ...props }) => {
  const {
    control,
    formState: { errors = {} }
  } = useFormContext()

  const existingErrors = _.get(errors, name)

  return (
    <StyledFormControl error={Boolean(errors[name])}>
      <Controller
        fullWidth
        control={control}
        name={name}
        render={({ field: { ref, ...other } }) =>
          React.cloneElement(Component, {
            inputRef: (passRef && ref) || other.inputRef,
            error: Boolean(existingErrors),
            label: Component.props.label,
            ...other
          })
        }
        {...props}
      />
      {existingErrors && (
        <FormHelperText error={true}>{existingErrors.message}</FormHelperText>
      )}
    </StyledFormControl>
  )
}
