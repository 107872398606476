import { css } from 'styled-components'
import { colors, pxToRem, theme, typography } from '../../styles'

const borderColor = colors.light500

export const calendar = css`
  &.rbc-calendar {
    font-family: ${typography.type.tertiary};
    font-size: ${pxToRem(12)}rem;
    .rbc-month-view .rbc-month-row {
      &,
      .rbc-row-content,
      .rbc-row-content .rbc-date-cell {
        flex: 1;
      }
    }
  }
  .rbc-today {
    background: ${colors.brandBlue50};
  }
`

export const month = css`
  .rbc-month-row + .rbc-month-row {
    border-top-color: ${borderColor};
  }
  .rbc-month-view {
    border-color: ${borderColor};
  }
`

export const agenda = css`
  .rbc-agenda-view table.rbc-agenda-table {
    border-color: ${borderColor};
  }
  .rbc-agenda-view table.rbc-agenda-table thead > tr > th {
    border-bottom-color: ${borderColor};
  }
  .rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td {
    border-left-color: ${borderColor};
  }
  .rbc-agenda-view table.rbc-agenda-table tbody > tr + tr {
    border-top-color: ${borderColor};
  }
  .rbc-agenda-empty {
    color: ${colors.dark400};
    font-family: ${typography.type.tertiary};
    font-size: ${pxToRem(16)}rem;
    line-height: 1.25;
    text-align: center;
  }
`

export const week = css`
  .rbc-time-view .rbc-row {
    min-height: auto;
  }
  .rbc-time-view {
    border-color: ${borderColor};
  }
  .rbc-time-content > * + * > *,
  .rbc-time-header-content {
    border-left-color: ${borderColor};
  }
  .rbc-timeslot-group {
    border-bottom-color: ${borderColor};
  }
  .rbc-time-content {
    border-top-color: ${borderColor};
  }
  .rbc-current-time-indicator {
    height: 2px;
    background-color: ${colors.green700};
  }
`

export const toolbar = css`
  &.rbc-toolbar {
    margin-bottom: ${theme.spacing(1)};
    min-height: 25px;
    position: relative;

    .rbc-btn-group {
      position: absolute;

      &.left {
        left: 0;
      }
      &.right {
        right: 0;
      }
    }
  }

  & .rbc-btn-group button {
    background: ${colors.light200};
    border-color: ${colors.light500};
    color: ${colors.dark400};
    font-family: ${typography.type.tertiary};
    font-size: ${pxToRem(12)}rem;

    &:hover {
      background: ${colors.light300};
      border-color: ${colors.light500};
      color: ${colors.dark400};
      cursor: pointer;
    }
    &:active {
      box-shadow: none !important;
    }
  }

  .rbc-toolbar-label {
    font-family: ${typography.type.tertiary};
    font-size: ${pxToRem(14)}rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .MuiButtonBase-root {
    background: transparent;
    border: 0;
    color: ${colors.dark100};
    padding: ${theme.spacing(1)};
    margin-left: ${theme.spacing(1)};

    &:hover,
    &:focus,
    &:active {
      color: ${colors.dark200};
      box-shadow: none !important;
      background: transparent !important;
    }

    .MuiIconButton-label {
      font-size: 10px;
    }
  }

  &.rbc-toolbar button {
    &:active {
      box-shadow: none !important;
    }
    &.rbc-active {
      box-shadow: none !important;
      color: #fff;
      background-color: ${colors.secondary};
      border-color: ${colors.secondary};
    }
  }
`

export const header = css`
  .rbc-header {
    border-right-color: transparent;
    border-left-color: transparent;
    border-bottom-color: ${borderColor};
    font-size: ${pxToRem(10)}rem;
    font-weight: bold;
    padding: ${theme.spacing(1)} 0;
    letter-spacing: 0.75px;
    text-transform: uppercase;
  }
`

export const calendarCells = css`
  .rbc-off-range-bg {
    background: ${colors.light400};
  }
  .rbc-day-bg + .rbc-day-bg {
    border-left-color: ${borderColor};
  }
`

export const event = css`
  .rbc-event,
  .rbc-day-slot .rbc-background-event {
    background: ${colors.brandBlue400};
  }
  .rbc-show-more {
    color: ${colors.brandBlue500};
    padding-top: ${theme.spacing(1)};
    padding-right: ${theme.spacing(1)};
    text-align: right;
  }

  .rbc-event:focus,
  .rbc-day-slot .rbc-background-event:focus {
    outline: 0;
    background: ${colors.brandBlue600};
  }
`
