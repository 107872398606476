import React from 'react'
import styled from 'styled-components'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  colors,
  Comment,
  Flex,
  theme,
  SkeletonCard
} from '@condofy/components'
import moment from 'moment'
import { searchFeeds } from '@condofy/next/service/community'
import { MD5 } from '@condofy/next/service/md5'

const StyledAccordion = styled(Accordion)`
  &.Mui-expanded {
    margin-top: 0;
  }

  & .MuiAccordionSummary-content {
    color: ${colors.primary};
    display: block;
    margin: ${theme.spacing(4)} auto;
    text-align: center;
  }

  & .MuiAccordionSummary-expandIcon {
    display: none;
  }

  & .MuiCollapse-container {
    padding: 0 ${theme.spacing(4)};
  }
`

const SubComment = ({ item }) => {
  const date = moment(item?.publishDate, 'DD/MM/YYYY HH:mm:ss').local()
  const gravatarId = MD5(item?.user?.email)
  const gravatarImage = `https://www.gravatar.com/avatar/${gravatarId}?d=retro`

  return (
    <Comment
      avatar={gravatarImage}
      dateFormat='ago'
      name={item?.user?.name}
      date={date}
    >
      {item.description}
    </Comment>
  )
}

export const ReplyList = ({ id, currentCondominium }) => {
  const [subComments, setSubComments] = React.useState([])
  const [seeComments, setSeeComments] = React.useState(true)
  const [loading, setLoading] = React.useState(false)
  const toggle = () => setSeeComments(!seeComments)

  const handleSubComments = () => {
    toggle()
    if (seeComments) {
      setLoading(false)
      searchFeeds({ currentCondominium, userCommentsIdParent: id })
        .then(({ data }) => {
          setSubComments(data.data)
          setLoading(true)
        })
        .catch(({ data }) => console.error(data))
      return
    }
  }

  return (
    <StyledAccordion>
      <AccordionSummary onClick={handleSubComments}>
        {seeComments ? 'Ver comentários' : 'Ocultar comentários'}
      </AccordionSummary>
      <AccordionDetails>
        {loading ? (
          <Flex direction='column' spacingY={3}>
            {subComments.map((item) => (
              <SubComment key={item.id} item={item} />
            ))}
          </Flex>
        ) : (
          <SkeletonCard style={{ width: '100%' }} />
        )}
      </AccordionDetails>
    </StyledAccordion>
  )
}
