import React from 'react'
import {
  colors,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  TableRow,
  Table,
  TableBody,
  TableHead,
  EmptyListMessage
} from '@condofy/components'
import moment from 'moment'
import { BalanceSheetItemRow } from './BalanceSheetItemRow'
import { BalanceSheetTableCell as TableCell } from './BalanceSheetTableCell'
import { BalanceSheetCurrencyText } from './BalanceSheetCurrencyText'

const TransferRow = ({ year, transfers = [] }) => (
  <TableRow>
    <TableCell />
    <TableCell type='rowHeader'>Transferências</TableCell>
    {transfers.map(({ month, amount }) => (
      <TableCell key={month}>
        <BalanceSheetCurrencyText
          value={amount}
          date={moment(`${year}-${month}`, 'YYYY-MM')}
        />
      </TableCell>
    ))}
  </TableRow>
)

const BalanceRow = ({ balance = [], year }) => (
  <TableRow>
    <TableCell />
    <TableCell type='rowHeader' style={{ fontWeight: 'bold' }}>
      Saldo
    </TableCell>
    {balance.map(({ month, amount }) => {
      return (
        <TableCell key={month} style={{ fontWeight: 'bold' }}>
          <BalanceSheetCurrencyText
            value={amount}
            date={moment(`${year}-${month}`, 'YYYY-MM')}
          />
        </TableCell>
      )
    })}
  </TableRow>
)

const styles = {
  '.title': {
    color: colors.dark400,
    fontSize: `15px`
  },
  '&': {
    marginLeft: (theme) => `-${theme.spacing(3)} !important`,
    marginRight: (theme) => `-${theme.spacing(3)} !important`
  }
}

export const BalanceSheetItem = ({ account, year }) => {
  const columns = [{ expandable: true }, { headerName: '' }].concat(
    moment.monthsShort().map((headerName) => {
      return { headerName }
    })
  )
  return (
    <Accordion sx = {{...styles}} className="root">
      <AccordionSummary>
        <Typography className="title">
          {account.bankAccount.name}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Table columns={columns} padding='none'>
          <TableHead>
            <TableRow>
              <TableCell style={{ width: '45px' }} />
              <TableCell />
              {moment.monthsShort().map((m, i) => (
                <TableCell key={i}>{m}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <BalanceSheetItemRow
              year={year}
              account={account}
              type='income'
              header='Receitas'
            />
            <BalanceSheetItemRow
              year={year}
              account={account}
              type='expenses'
              header='Despesas'
            />
            <TransferRow year={year} transfers={account.transferBalance} />
            <BalanceRow year={year} balance={account.monthBalance} />
            {!account.monthBalance.length && (
              <TableRow colSpan={13}>
                <EmptyListMessage />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </AccordionDetails>
    </Accordion>
  )
}
