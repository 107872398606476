import React from 'react'
import {
  colors,
  Typography,
  Button,
  Icon,
  typography
} from '@condofy/components'
import { Breadcrumbs } from '@mui/material'
import styled from 'styled-components'

const Node = styled(Button)`
  font-family: ${typography.type.secondary}!important;
  font-size: ${typography.size.h5}px!important;
  font-weight: 600!important;
  background: transparent;
  margin: 0!important;
  padding: 0!important;

  &:hover,
  &:focus {
    background: transparent;
  }
`

const Leaf = styled(Typography)`
  color: ${colors.dark200};
  font-size: ${typography.size.h5}px!important;
  font-weight: 600!important;
  font-family: ${typography.type.secondary} !important;
`

export const Breadcrumb = ({
  path = [],
  onPathChange: emitPathChangeEvent = () => {}
}) => {
  const handleDirectoryNavigation = (directory) => () => {
    const sliceIndex = path.indexOf(directory)
    emitPathChangeEvent(path.slice(0, sliceIndex + 1))
  }
  return (
    <Breadcrumbs separator={<Icon icon='chevron_right' />}>
      {path.map((directory, index, array) =>
        index === array.length - 1 ? (
          <Leaf key={directory}>{directory}</Leaf>
        ) : (
          <Node
            key={directory}
            color='clear'
            onClick={handleDirectoryNavigation(directory)}
          >
            {directory}
          </Node>
        )
      )}
    </Breadcrumbs>
  )
}
