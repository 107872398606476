import React, { useCallback, useEffect, useState } from 'react'
import { Form, FormRow, TextField, MenuItem } from '@condofy/components'
import { Modal, ActionRow } from '@condofy/next/components'
import { userTypeListWithNoManager } from '@condofy/next/model/user'
import { useUserContext } from '@condofy/next/store/user'
import { useSnackbar, handleError } from '@condofy/next/hooks/useSnackbar'
import { ModalContent } from '../../../next/components/Modal'

export const AddUserConnection = () => {
  const {
    state: {
      connection: { userModalOpen, metadata, selected },
      unit: { allItems: unitList },
      condominium: { allItems: condominiumList }
    },
    actions: {
      closeCreateUserConnectionModal: close,
      listAllUnits,
      listAllCondominiums,
      requestConnection,
      listConnection
    }
  } = useUserContext()
  const stableListAllCondominiums = useCallback(listAllCondominiums, [])
  const stableListAllUnits = useCallback(listAllUnits, [])

  const { enqueueSnackbar } = useSnackbar()

  const [condominiumId, setCondominiumId] = useState('')
  const [userTypeId, setUserTypeId] = useState('')
  const [unitId, setUnitId] = useState('')

  const resetFields = () => {
    setCondominiumId('')
    setUserTypeId('')
    setUnitId('')
  }

  const handleClose = () => {
    resetFields()
    close()
  }

  useEffect(() => {
    stableListAllCondominiums()
  }, [stableListAllCondominiums])

  useEffect(() => {
    if (condominiumId) {
      stableListAllUnits({ currentCondominium: { id: condominiumId } })
    }
  }, [condominiumId, stableListAllUnits])

  const sendConnection = () => {
    requestConnection({
      user: selected,
      condominium: { id: condominiumId },
      userTypeId,
      unitId
    })
      .then(() => {
        handleClose()
        enqueueSnackbar('Pedido de conexão enviado para o síndico.', {
          variant: 'success'
        })
        listConnection()
      })
      .catch(handleError(enqueueSnackbar))
  }

  return (
    <Modal
      title='Nova Conexão'
      open={userModalOpen}
      onClose={handleClose}
      footer={
        <ActionRow
          loading={metadata.loading}
          confirmText='Adicionar'
          onSubmit={sendConnection}
          onCancel={handleClose}
        />
      }
    >
      <ModalContent>
        <Form>
          <FormRow>
            <TextField
              select
              label='Condomínio'
              value={condominiumId}
              onChange={(event) => setCondominiumId(event.target.value)}
            >
              {condominiumList.map((condominium) => (
                <MenuItem key={condominium.id} value={condominium.id}>
                  {condominium.name}
                </MenuItem>
              ))}
            </TextField>
          </FormRow>
          <TextField
            select
            label='Papel no condomínio'
            value={userTypeId}
            onChange={(event) => setUserTypeId(event.target.value)}
          >
            {userTypeListWithNoManager.map(({ key, value, children }) => (
              <MenuItem key={key} value={value}>
                {children}
              </MenuItem>
            ))}
          </TextField>
          <FormRow>
            <TextField
              select
              label='Unidade'
              value={unitId}
              onChange={(event) => setUnitId(event.target.value)}
              disabled={!unitList.length}
            >
              {unitList.map(({ id, block, number }) => (
                <MenuItem key={id} value={id}>
                  {`${block} ${number}`}
                </MenuItem>
              ))}
            </TextField>
          </FormRow>
        </Form>
      </ModalContent>
    </Modal>
  )
}
