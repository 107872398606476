import React from 'react'
import { TextareaAutosize } from '@mui/material'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { TextField } from '../TextFields/TextField'

const StyledTextarea = styled(TextareaAutosize)`
  ${({ $allowResize }) => !$allowResize && `resize: none`}
`

function TextAreaInput(props) {
  const { inputRef, allowResize, ...other } = props

  React.useImperativeHandle(inputRef, () => ({
    focus: () => {
      inputRef.current.focus()
    }
  }))
  return <StyledTextarea $allowResize={allowResize} {...other} />
}

export const Textarea = ({
  rows,
  rowsMax,
  rowsMin,
  allowResize = false,
  ...props
}) => {
  return (
    <TextField
      InputProps={{
        inputComponent: TextAreaInput,
        inputProps: { rows, rowsMax, rowsMin, allowResize }
      }}
      {...props}
    />
  )
}
TextareaAutosize.propTypes = {
  rows: PropTypes.number,
  rowsMax: PropTypes.number,
  rowsMin: PropTypes.number,
  label: PropTypes.node,
  allowResize: PropTypes.bool
}
