import React from 'react'
import { Button, LoadingButton } from '../Button'
import { Flex } from '../Flex'
import { Icon } from '../Icon'
import { SmallBody, Heading3, Heading4 } from '../Typography'
import styled from 'styled-components'
import { colors, theme } from '../../styles'
import PropTypes from 'prop-types'

const LeftSide = styled(Flex)`
  background: ${colors.light300};
  padding: ${theme.spacing(5)};
  text-align: center;

  > * {
    max-width: 400px;
  }
`

const BodyHeading = styled(Heading3)`
  max-width: 495px;
`

const RightSide = styled(Flex)`
  background: ${colors.light100};
  padding: ${theme.spacing(5)};
  text-align: center;

  > * {
    max-width: 400px;
  }
`

export const PremiumPage = ({
  userIsAdmin = false,
  proposalIsSent = false,
  loading = false,
  bodyText = `Com nosso plano completo você tem acesso a funcionalidades que vão
  tornar a administração do seu condominio muito mais transparente e
  eficiente.`,
  bodyTitle = 'Tenha mais facilidade com nosso plano premium.',
  nonAdminText = 'Quer ter acesso a mais funcionalidades? Fale com o seu Sindíco.',
  onReceiveProposal = () => {},
  buttonText = 'Solicitar Proposta',
  proposalSentText = 'Solicitação enviada',
  title,
  text,
  image,
  ...args
}) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }} {...args}>
      <LeftSide
        direction='column'
        justify='center'
        alignItems='center'
        spacingY={4}
      >
        <BodyHeading>{bodyTitle}</BodyHeading>
        <SmallBody>{bodyText}</SmallBody>
        {!userIsAdmin && <SmallBody>{nonAdminText}</SmallBody>}
        {userIsAdmin && (
          <React.Fragment>
            {proposalIsSent ? (
              <Button
                color='primary'
                endIcon={<Icon icon='checkmark_circle_outlined' />}
                disabled
              >
                {proposalSentText}
              </Button>
            ) : (
              <LoadingButton
                color='primary'
                loading={loading}
                onClick={onReceiveProposal}
              >
                {buttonText}
              </LoadingButton>
            )}
          </React.Fragment>
        )}
      </LeftSide>
      <RightSide
        direction='column'
        justify='center'
        alignItems='center'
        spacingY={4}
      >
        <Heading4 variant='h5'>{title}</Heading4>
        {image}
        <SmallBody>{text}</SmallBody>
      </RightSide>
    </div>
  )
}
PremiumPage.propTypes = {
  userIsAdmin: PropTypes.bool,
  proposalIsSent: PropTypes.bool,
  loading: PropTypes.bool,
  body: PropTypes.string,
  title: PropTypes.string,
  nonAdminText: PropTypes.string,
  image: PropTypes.element,
  onReceiveProposal: PropTypes.func
}
