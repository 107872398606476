import { List } from '../core/'
import { getCashflow } from '@condofy/next/service/balance'

const key = 'FINANCIAL_CASHFLOW'

const cashflow = new List({
  key: `${key}_LIST`,
  statePropName: 'cashflow'
})

export const initialState = {
  cashflow: { ...cashflow.initialState }
}

export const createMiddleware = () => {
  return {
    ...cashflow.middleware(getCashflow)
  }
}

export const useActions = (state, dispatch) => ({
  listCashflow: cashflow.actions(state, dispatch)
})

export const createReducers = () => {
  return {
    ...cashflow.reducers()
  }
}
