import React, { useState } from 'react'
import { Menu } from './Menu'
import styled from 'styled-components'
import { Icon } from '../Icon'
import { Button as Btn } from '../Button'
import { colors } from '../../styles'
import PropTypes from 'prop-types'

const StyledButton = styled(Btn)`
  &.MuiButton-root {
    color: ${colors.dark100};
  }
`

const MenuWrapper = styled.div`
  display: flex;
  align-items: stretch;
`
export const ButtonMenu = ({ children, id, Button, ...other }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const isOpen = Boolean(anchorEl)
  const open = (element) => setAnchorEl(element)
  const close = () => setAnchorEl(null)

  const handleClick = (event) => {
    event.preventDefault()
    event.stopPropagation()
    open(event.currentTarget)
  }

  const handleClose = (event) => {
    event.preventDefault()
    event.stopPropagation()
    close()
  }

  const CustomButton =
    Button &&
    React.cloneElement(Button, {
      onClick: handleClick,
      open: isOpen
    })

  const modifiedChildren = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        onClick: (event) => {
          handleClose(event)
          if (child.props.onClick) child.props.onClick(event)
        }
      })
    }
  })

  return (
    <MenuWrapper {...other}>
      {CustomButton ? (
        CustomButton
      ) : (
        <StyledButton
          aria-controls={id}
          aria-haspopup='true'
          onClick={handleClick}
          variant='text'
        >
          <Icon icon='more_vertical' />
        </StyledButton>
      )}
      <Menu
        id={id}
        variant='menu'
        anchorEl={anchorEl}
        keepMounted
        open={isOpen || false}
        onClose={handleClose}
      >
        {modifiedChildren}
      </Menu>
    </MenuWrapper>
  )
}

ButtonMenu.propTypes = {
  Button: PropTypes.any
}
