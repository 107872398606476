import React, { useEffect, useState, useCallback } from 'react'
import {
  Form,
  FormRow,
  TextField,
  CondominiumUnitText,
  MenuItem,
  FormNote,
  Checkbox,
} from '@condofy/components'
import { useUserContext } from '@condofy/next/store/user'
import { userTypeListWithNoManager } from '@condofy/next/model/user'
import { condominiumFullList } from '@condofy/next/service/condo'
import { phoneMask, cpfMask } from '@condofy/next/containers'

export const UserForm = ({
  value: initialValue = {},
  onChange: emitChangeEvent = () => { }
}) => {
  const {
    state: {
      isSuperAdmin,
      unit: { allItems: unitList }
    },
    actions: { listAllUnits }
  } = useUserContext()
  const handleUpdate = (event) => {
    const { name, value } = event.target
    emitChangeEvent({ ...initialValue, [name]: value })

  }
  const stableListAllUnits = useCallback(listAllUnits, [])
  const [condominiumList, setCondominiumList] = useState([])
  const [isManager, setIsManager] = useState(initialValue.isManager || false)

  useEffect(() => {
    condominiumFullList().then((response) => {
      setCondominiumList(response.data.data)
    })
  }, [])

  useEffect(() => {
    emitChangeEvent({ ...initialValue, isManager })
  }, [isManager])

  useEffect(() => {
    const currentCondominium = { id: initialValue.condominiumId }
    if (currentCondominium.id) stableListAllUnits({ currentCondominium })
  }, [initialValue.condominiumId, stableListAllUnits])

  return (
    <Form>
      <FormRow>
        <TextField
          label='Nome'
          name='name'
          value={initialValue.name || ''}
          onChange={handleUpdate}
          required
          disabled={!isSuperAdmin}
        />
      </FormRow>
      <FormRow>
        <TextField
          label='CPF'
          disabled={initialValue.id}
          name='socialSecurityNumber'
          value={
            initialValue.socialSecurityNumber
              ? cpfMask(initialValue.socialSecurityNumber)
              : ''
          }
          onChange={handleUpdate}
          required
        />
      </FormRow>
      {!initialValue.id && (
        <FormRow>

          <TextField
            select
            label='Condomínio'
            name='condominiumId'
            value={initialValue.condominiumId || ''}
            onChange={handleUpdate}
            required
          >
            {condominiumList.map((condominium) => (
              <MenuItem key={condominium.id} value={condominium.id}>
                {condominium.name}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            select
            label='Unidade'
            disabled={!initialValue.condominiumId}
            name='unitId'
            value={initialValue.unitId || ''}
            onChange={handleUpdate}
            required
          >
            {unitList.map((unit) => (
              <MenuItem key={unit.id} value={unit.id}>
                <CondominiumUnitText block={unit.block} number={unit.number} />
              </MenuItem>
            ))}
          </TextField>
        </FormRow>
      )}
      {!initialValue.id && (
        <FormRow >
          <TextField
            select
            label='Vínculo'
            name='userTypeId'
            value={initialValue.userTypeId || ''}
            onChange={handleUpdate}
            required
          >
            {userTypeListWithNoManager.map((option) => (
              <MenuItem key={option.key} {...option} />
            ))}
          </TextField>
          <div style={{ display: 'flex', flexFlow: 'row', alignItems: 'center', justifyContent: 'left' }}>
            <FormNote >Tornar síndico</FormNote>
            <Checkbox
              style={{ marginLeft: '8px' }}
              color='primary'
              name='isManager'
              checked={isManager}
              onChange={() => setIsManager(!isManager)}
            />
          </div>
        </FormRow>
      )}

      <FormRow>
        <TextField
          label='E-mail'
          name='email'
          value={initialValue.email || ''}
          onChange={handleUpdate}
          required
        />
      </FormRow>
      <FormRow>
        <TextField
          label='Número de Telefone'
          name='phoneNumber'
          value={
            initialValue.phoneNumber ? phoneMask(initialValue.phoneNumber) : ''
          }
          onChange={handleUpdate}
          required
        />
      </FormRow>
      {initialValue.id && (
        <FormRow>
          <TextField
            label='Placa do Veículo'
            name='numberPlate'
            value={initialValue.numberPlate || ''}
            onChange={handleUpdate}
            required
          />
        </FormRow>
      )}
      {initialValue.id &&
        initialValue.condominiums.map((condominium) => (
          <FormRow key={condominium.id}>
            <TextField
              label='Condomínio'
              disabled
              value={condominium.name || ''}
            />
            <TextField
              label='Unidade'
              disabled
              value={condominium.units.map((unit) => unit?.name) || ''}
            />
          </FormRow>
        ))}
      {!initialValue.id && (
        <FormRow>
          <TextField
            label='Senha'
            name='password'
            value={initialValue.password || ''}
            onChange={handleUpdate}
            required
            type='password'
          />
          <TextField
            label='Confirmar Senha'
            name='confirmPassword'
            value={initialValue.confirmPassword || ''}
            onChange={handleUpdate}
            required
            type='password'
          />
        </FormRow>
      )}
    </Form>
  )
}
