import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '../Button'
import { Flex } from '../Flex'
import { Box } from '@mui/material'
import { colors } from '../../styles'
import styled from 'styled-components'

const BackButton = styled(Button)`
  &.MuiButton-text {
    color: ${colors.primary} !important;
  }
`

export const WizardStep = ({
  backButtonText = 'Voltar',
  showBackButton = true,
  disabledBackButton = false,
  onBack = () => {},
  canceButtonText = 'Cancelar',
  showCancelButton = false,
  disabledCancelButton = false,
  onCancel = () => {},
  nextButtonText = 'Próximo',
  disabledNextButton = false,
  onNext = () => {},
  isVisible = false,
  step,
  children,
  ...args
}) => {
  return (
    <React.Fragment>
      {!isVisible && <React.Fragment></React.Fragment>}
      {isVisible && (
        <React.Fragment>
          {children}
          <Box marginTop={4}>
            <Flex spacingX={1} justify='center' width='100%' {...args}>
              {showCancelButton && (
                <Button
                  disabled={disabledCancelButton}
                  color='secondary'
                  onClick={onCancel}
                >
                  {canceButtonText}
                </Button>
              )}
              {showBackButton && (
                <BackButton
                  variant='text'
                  disabled={disabledBackButton || step === 0}
                  onClick={onBack}
                >
                  {backButtonText}
                </BackButton>
              )}
              <Button
                disabled={disabledNextButton}
                variant='contained'
                color='primary'
                onClick={onNext}
              >
                {nextButtonText}
              </Button>
            </Flex>
          </Box>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}
WizardStep.propTypes = {
  backButtonText: PropTypes.string,
  showBackButton: PropTypes.bool,
  disabledBackButton: PropTypes.bool,
  onBack: PropTypes.func,
  canceButtonText: PropTypes.string,
  showCancelButton: PropTypes.bool,
  disabledCancelButton: PropTypes.bool,
  onCancel: PropTypes.func,
  nextButtonText: PropTypes.string,
  disabledNextButton: PropTypes.bool,
  onNext: PropTypes.func,
  step: PropTypes.number,
  isVisible: PropTypes.bool,
  __TYPE: PropTypes.string
}
WizardStep.defaultProps = {
  __TYPE: 'WizardStep'
}
