import React, { useEffect, useCallback } from 'react'
import { ButtonMenu, Button, MenuItem } from '@condofy/components'
import { useBankAccountContext } from '@condofy/next/store/bank-account'
import { useUserContext } from '@condofy/next/store/user'
import DownloadIcon from '@mui/icons-material/Download'
import { token } from '@condofy/next/service/user'
import { saveAs } from 'file-saver'
import { condofy } from '../../vendor/@condofy/instance'
import { track } from '@condofy/next/service/segment'

export const BankAccountSelectorDownload = ({
  hideIfOneOption = false,
  year
}) => {
  const {
    state: { items },
    actions: { loadBankAccountList }
  } = useBankAccountContext()
  const stableLoadBankAccountList = useCallback(loadBankAccountList, [])

  const {
    state: { currentCondominium, id }
  } = useUserContext()

  useEffect(() => {
    if (currentCondominium.id) {
      stableLoadBankAccountList({ condominiumId: currentCondominium.id })
    }
  }, [currentCondominium.id, stableLoadBankAccountList])

  const downloadBalance = async ({ accountId, bankName }) => {
    try {
      const response = await condofy.post(
        `/v1/condominiums/${currentCondominium.id}/entries/download-annual-balance`,
        {
          year: year,
          bankAccountId: accountId
        },
        {
          responseType: 'blob',
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      )
      const blob = new Blob([response.data], { type: 'blob' })
      track(window.analytics, 'Balancesheet Download', {
        user: id,
        condominiumId: currentCondominium.id,
        bankAccountId: accountId,
        bankName,
        year
      })
      saveAs(blob, `Balancete-${String(year)}-${bankName}.pdf`)
      track(window.analytics, 'Balancesheet Download', {
        user: id,
        condominiumId: currentCondominium.id,
        bankAccountId: accountId,
        bankName,
        year
      })
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      {hideIfOneOption && items.length <= 1 ? (
        <></>
      ) : (
        <ButtonMenu
          id='bankAccount-menu'
          Button={
            <Button startIcon={<DownloadIcon size='small' />} disabled={false}>
              Download
            </Button>
          }
        >
          {items.map((bankAccount) => (
            <MenuItem
              key={bankAccount.bankAccountId}
              value={bankAccount.bankAccountId}
              onClick={async () =>
                await downloadBalance({
                  accountId: bankAccount.bankAccountId,
                  bankName: bankAccount.nickName
                })
              }
            >
              {bankAccount.nickName}
            </MenuItem>
          ))}
        </ButtonMenu>
      )}
    </>
  )
}
