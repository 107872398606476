import React from 'react'
import Graphic1 from 'Assets/community/work-connect'
import {
  CommunityOnboardStep,
  CommunityOnboardStepTitle,
  CommunityOnboardStepBody
} from './CommunityOnboardStep'

export const CommunityOnboardWelcomeStep = (args) => {
  return (
    <CommunityOnboardStep {...args} title='' Image={<Graphic1 />}>
      <CommunityOnboardStepTitle>
        Seja bem vindo a comunidade!
      </CommunityOnboardStepTitle>
      <CommunityOnboardStepBody>
        Aqui você pode pedir ajuda para seus vizinhos, combinar carona, pedir
        itens empretados e também oferecer ajuda.
      </CommunityOnboardStepBody>
    </CommunityOnboardStep>
  )
}
