import React from 'react'
import { Button, colors, Flex } from '@condofy/components'
import styled from 'styled-components'
import { PollOptions } from './PollOptions'
import { Vote } from './Vote'

const VoteButton = styled(Button)`
  background-color: ${colors.light300} !important;
  border-color: ${colors.light300} !important;
  border-radius: 10px;
  height: 156px;
  margin: 8px;
  width: 156px;

  &:hover {
    background-color: ${colors.light400} !important;
  }
`

const PollOption = ({ option = '', onClick = () => {} }) => (
  <VoteButton color='light' onClick={onClick}>
    <Vote vote={option} />
  </VoteButton>
)

export const Poll = ({ onChange }) => {
  const handleVote = (vote) => () => onChange(vote)

  return (
    <Flex spacingX={2} itemsGrid={4}>
      {Object.keys(PollOptions).map((k) => (
        <PollOption
          key={k}
          option={PollOptions[k]}
          onClick={handleVote(PollOptions[k])}
        />
      ))}
    </Flex>
  )
}
