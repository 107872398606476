const listInitialState = {
  items: [],
  metadata: {
    pageNumber: 1,
    totalRecords: 0,
    recordsPerPage: 10,
    loading: false
  }
}

export class List {
  constructor({ key, statePropName = 'list' }) {
    if (!key) {
      throw Error('List constructor must be passed a key')
    }
    this._key = key
    this._stateProp = statePropName
    this._action = {
      load: `LOAD_${key}_LIST`,
      pending: `LOAD_${key}_LIST_PENDING`,
      success: `LOAD_${key}_LIST_SUCCESS`,
      error: `LOAD_${key}_LIST_ERROR`
    }
    this._actionTypes = Object.keys(this._action).map((k) => {
      return { [k]: k }
    })
    this._initialState = listInitialState
  }

  get initialState() {
    return this._initialState
  }

  set initialState(initialState) {
    this._initialState = initialState
  }

  get action() {
    return this._action
  }

  reducers() {
    return {
      [this._action.success]: (state = this._initialState, action) =>
        this._successReducer(state, action),
      [this._action.pending]: (state = this._initialState, action) =>
        this._pendingReducer(state, action),
      [this._action.error]: (state = this._initialState, action) =>
        this._errorReducer(state, action)
    }
  }

  middleware(listFunc) {
    return {
      [this._action.load]: this._loadList(listFunc)
    }
  }

  actions(state, dispatch) {
    return (data) => dispatch({ type: this._action.load, payload: data })
  }

  _successReducer(state, action) {
    return {
      ...state,
      [this._stateProp]: {
        ...state[this._stateProp],
        items: action.payload.data,
        metadata: {
          ...state[this._stateProp].metadata,
          ...action.payload.paging,
          pageNumber:
            action.payload?.paging?.pageNumber === 0
              ? 1
              : action.payload?.paging?.pageNumber
        }
      }
    }
  }

  _pendingReducer(state, action) {
    return {
      ...state,
      [this._stateProp]: {
        ...state[this._stateProp],
        metadata: {
          ...state[this._stateProp].metadata,
          loading: action.payload
        }
      }
    }
  }

  _errorReducer(state, action) {
    return {
      ...state,
      [this._stateProp]: {
        ...state[this._stateProp],
        error: action.payload
      }
    }
  }

  _pendingList(dispatch, payload) {
    dispatch({ type: this._action.pending, payload })
  }

  _loadList(callback) {
    return async (dispatch, payload) => {
      this._pendingList(dispatch, true)

      try {
        const response = await callback(payload)
        dispatch({
          type: this._action.success,
          payload: response.data
        })
      } catch (error) {
        dispatch({ type: this._action.error, payload: error })
      } finally {
        this._pendingList(dispatch, false)
      }
    }
  }
}
