import React, { useCallback, useEffect, useState } from 'react'
import { useSnackbar } from '@condofy/next/hooks/useSnackbar'
import {
  ConnectionRequestList,
  UserCard,
  UserList
} from '@condofy/next/containers/'
import { useUserContext } from '@condofy/next/store/user'
import { NewButton } from '@condofy/next/components'
import {
  SearchTextField,
  Pagination,
  Flex,
  PageSubTitle,
  TabToolbar,
  CardGrid,
  Card,
  CardHeader,
  CardContent,
  IconButton,
  Icon,
  Tooltip
} from '@condofy/components'

export const UsersTab = () => {
  const { enqueueSnackbar } = useSnackbar()
  const {
    state: {
      currentCondominium,
      isSuperAdmin,
      user: { metadata, items, loading }
    },
    actions: { listUser, openCreateUserModal }
  } = useUserContext()
  const stableListUser = useCallback(listUser, [])

  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')
  const [showGrid, setShowGrid] = useState(true)

  useEffect(() => {
    const handleError = () => (message) =>
      enqueueSnackbar(message, { variant: 'error' })

    if (!currentCondominium.id) {
      handleError('Erro ao listar usuários')
    }
    stableListUser({
      page,
      search,
      currentCondominium,
      recordsPerPage: 12
    }).catch(handleError('Erro ao listar usuários'))
  }, [page, search, currentCondominium, stableListUser, enqueueSnackbar])

  const handleSearch = (event) => {
    setSearch(event.target.value)
    setPage(1)
  }
  return (
    <>
      <TabToolbar>
        <SearchTextField size='small' onChange={handleSearch} value={search} />

        <div>
          {showGrid ? (
            <Tooltip title='Lista'>
              <IconButton color='primary' onClick={() => setShowGrid(false)}>
                <Icon icon='table' />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title='Grade'>
              <IconButton color='primary' onClick={() => setShowGrid(true)}>
                <Icon icon='grid' />
              </IconButton>
            </Tooltip>
          )}

          {isSuperAdmin && (
            <NewButton onClick={openCreateUserModal}>Novo</NewButton>
          )}
        </div>
      </TabToolbar>
      <Flex direction='column' spacingY={3}>
        <PageSubTitle>Pedidos de Conexão</PageSubTitle>
        <div>
          <ConnectionRequestList />
        </div>
        {showGrid && <PageSubTitle>Usuários</PageSubTitle>}
        <div>
          {showGrid ? (
            <CardGrid loading={loading}>
              {items.map((user) => (
                <UserCard key={user.id} user={user} />
              ))}
            </CardGrid>
          ) : (
            <Card>
              <CardHeader title='Usuários' />
              <CardContent>
                <UserList
                  loading={loading}
                  items={items}
                  page={page}
                  setPage={setPage}
                  totalRecords={metadata.totalRecords}
                  recordsPerPage={metadata.recordsPerPage}
                />
              </CardContent>
            </Card>
          )}
        </div>
        {!loading && Boolean(items.length) && showGrid && (
          <Flex width='100%' justify='flex-end'>
            <Pagination
              page={page}
              totalResults={metadata.totalRecords}
              pageSize={metadata.recordsPerPage}
              onChange={(event, page) => setPage(page)}
            />
          </Flex>
        )}
      </Flex>
    </>
  )
}
