import { Modal } from '../core/'

const key = 'INTEGRATION'

const welcomeModal = new Modal({
  key: `${key}_WELCOME_MODAL`,
  statePropName: 'welcome'
})

const finalModal = new Modal({
  key: `${key}_FINAL_MODAL`,
  statePropName: 'final'
})

export const initialState = {
  welcome: { ...welcomeModal.initialState },
  final: { ...finalModal.initialState }
}

export const createMiddleware = () => {
  return {
    ...welcomeModal.middleware(),
    ...finalModal.middleware()
  }
}

export const useActions = (state, dispatch) => ({
  openWelcomeIntegrationModal: welcomeModal.actionOpen(state, dispatch),
  openFinalIntegrationModal: finalModal.actionOpen(state, dispatch),
  closeWelcomeIntegrationModal: welcomeModal.actionClose(state, dispatch),
  closeFinalIntegrationModal: finalModal.actionClose(state, dispatch)
})

export const createReducers = () => {
  return {
    ...welcomeModal.reducers(),
    ...finalModal.reducers()
  }
}
