import React, { useEffect, useCallback } from 'react'
import { ButtonMenu, Button, MenuItem, Icon } from '@condofy/components'
import { useBankAccountContext } from '@condofy/next/store/bank-account'
import { useUserContext } from '@condofy/next/store/user'

export const BankAccountSelector = ({ hideIfOneOption = false }) => {
  const {
    state: { items, currentAccount },
    actions: { updateCurrentAccount, loadBankAccountList }
  } = useBankAccountContext()
  const stableLoadBankAccountList = useCallback(loadBankAccountList, [])

  const {
    state: { currentCondominium }
  } = useUserContext()

  useEffect(() => {
    if (currentCondominium.id) {
      stableLoadBankAccountList({ condominiumId: currentCondominium.id })
    }
  }, [currentCondominium.id, stableLoadBankAccountList])

  const handleChange = (value) => () => {
    const account = items.find((account) => account.bankAccountId === value)
    updateCurrentAccount(account)
  }

  return (
    <>
      {hideIfOneOption && items.length <= 1 ? (
        <></>
      ) : (
        <ButtonMenu
          id='bankAccount-menu'
          Button={
            <Button color='primary' endIcon={<Icon icon='chevron_down' />}>
              {currentAccount.nickName}
            </Button>
          }
        >
          {items.map((bankAccount) => (
            <MenuItem
              key={bankAccount.bankAccountId}
              value={bankAccount.bankAccountId}
              onClick={handleChange(bankAccount.bankAccountId)}
            >
              {bankAccount.nickName}
            </MenuItem>
          ))}
        </ButtonMenu>
      )}
    </>
  )
}
