import { store, occurenceActionTypes } from './index'

// ACTION TYPES
const OCCURENCE_EDIT = 'OCCURENCE_EDIT'
const OCCURENCE_UPDATE_SELECTED = 'OCCURENCE_UPDATE_SELECTED'
const OCCURENCE_EDIT_MODAL_VISIBLE = 'OCCURENCE_EDIT_MODAL_VISIBLE'

// ACTIONS
export const editActions = (state, dispatch) => ({
  updateSelectedOccurence: (data) =>
    dispatch({ type: OCCURENCE_UPDATE_SELECTED, payload: data }),

  closeEditOccurenceModal: () => {
    dispatch({ type: occurenceActionTypes.OCCURENCE_SELECT, payload: false })
    dispatch({ type: OCCURENCE_EDIT_MODAL_VISIBLE, payload: false })
  },

  openEditOccurenceModal: (occurence) => {
    dispatch({
      type: occurenceActionTypes.OCCURENCE_SELECT,
      payload: occurence
    })
    dispatch({ type: OCCURENCE_EDIT_MODAL_VISIBLE, payload: true })
  }
})

// REDUCER
export const editReducer = (state, action) => {
  switch (action.type) {
    case OCCURENCE_UPDATE_SELECTED:
      return store(state, { selectedOccurence: action.payload })

    case OCCURENCE_EDIT_MODAL_VISIBLE:
      return store(state, { editModalOpen: action.payload })

    default:
      return state
  }
}

export const editActionTypes = {
  OCCURENCE_EDIT,
  OCCURENCE_UPDATE_SELECTED,
  OCCURENCE_EDIT_MODAL_VISIBLE
}
