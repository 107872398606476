import React, { forwardRef, useEffect, useState } from 'react'
import {
  ButtonMenu,
  Flex,
  Icon,
  ListItemIcon,
  ListItemText,
  MenuItem,
  colors,
  theme
} from '@condofy/components'
import styled from 'styled-components'
import { IconButton } from '@mui/material'
import { useCommunityContext } from '@condofy/next/store/community'
import { useUserContext } from '@condofy/next/store/user'
import { deleteOffer, statusOffer } from '@condofy/next/service/community'
import { track } from '@condofy/next/service/segment'

const status = [
  {
    label: 'Indisponível',
    color: colors.yellow500,
    icon: 'pause_circle_outlined'
  },
  {
    label: 'Disponível',
    color: colors.brandBlue600,
    icon: 'play_circle_outlined'
  }
]

const StyledMenuItem = styled(MenuItem)`
  .MuiListItemIcon-root,
  .MuiListItemText-primary {
    ${({ color }) => color && ` color: ${color}`}
  }
`

const ActionMenuItem = forwardRef(({ label, icon, color, ...props }, ref) => (
  <StyledMenuItem ref={ref} color={color} {...props}>
    <ListItemIcon>
      <Icon fontSize='small' icon={icon} />
    </ListItemIcon>
    <ListItemText primary={label} />
  </StyledMenuItem>
))

const Status = styled.div`
  background: ${({ available }) =>
    available ? colors.brandBlue50 : colors.warning100};
  border-radius: 4px;
  color: ${({ available }) =>
    available ? colors.brandBlue700 : colors.warning700};
  font-weight: 700;
  padding: ${theme.spacing(1)} 0;
  text-align: center;
  width: 100%;
`

const MenuButton = ({ onClick = () => {} }) => (
  <IconButton onClick={onClick}>
    <Icon size='small' icon='more_vertical' />
  </IconButton>
)

export const ListingStatusActionMenu = ({ listing }) => {
  const ref = React.createRef()
  const [selected, setSelected] = useState(status[Number(0)])
  const [availabilityMenuItem, setAvailabilityMenuItem] = useState(status[0])
  const {
    actions: { openUpdateListingModal, loadListingList }
  } = useCommunityContext()
  const {
    state: { currentCondominium, id }
  } = useUserContext()

  useEffect(() => {
    const available = Boolean(listing.status === 1)
    setSelected(status[Number(available)])
    setAvailabilityMenuItem(status[Number(!available)])
  }, [listing.status])

  const handleEdit = () => {
    openUpdateListingModal({ item: listing, currentCondominium })
  }

  const handleUnavailable = () => {
    statusOffer({
      currentCondominium,
      payload: { id: listing.id, status: 2 }
    }).then(() => loadListingList({ currentCondominium, membershipId: id }))
  }

  const handleAvailable = () => {
    statusOffer({
      currentCondominium,
      payload: { id: listing.id, status: 1 }
    }).then(() => loadListingList({ currentCondominium, membershipId: id }))
  }

  const handleDelete = () => {
    const deleteConfirmation = window.confirm(
      'Tem certeza que deseja remover essa oferta?\n\nEsta ação não pode ser revertida.'
    )
    if (deleteConfirmation) {
      deleteOffer({ currentCondominium, id: listing.id }).then(() => {
          track(window.analytics, 'Community Delete Offer', {
            condominiumId: currentCondominium.id,
            offerId: listing.id,
            userId: id
          })
          loadListingList({ currentCondominium, membershipId: id })
        }
      )
    }
  }

  return (
    <Flex width='100%' alignItems='center'>
      <Status available={listing.status === 1} flex='1 1 auto'>
        {selected.label}
      </Status>
      <ButtonMenu Button={<MenuButton />}>
        <ActionMenuItem
          ref={ref}
          label='Editar'
          icon='edit'
          onClick={handleEdit}
        />
        <ActionMenuItem
          ref={ref}
          label={availabilityMenuItem.label}
          icon={availabilityMenuItem.icon}
          color={availabilityMenuItem.color}
          onClick={listing.status === 1 ? handleUnavailable : handleAvailable}
        />
        <ActionMenuItem
          ref={ref}
          label='Remover'
          icon='delete_outlined'
          color={colors.danger400}
          onClick={handleDelete}
        />
      </ButtonMenu>
    </Flex>
  )
}
