import { getUnitsByCondo } from 'Actions/condominium'
import { condofyProtected } from '@condofy/next/service/instance'

import { listAllUsers } from '@condofy/next/service/user'

export const SET_MANAGERS_FIELD = 'SET_MANAGERS_FIELD'
export const SET_ERRORS = 'SET_ERRORS'
export const OPEN_DELETE_MODAL = 'OPEN_DELETE_MODAL'
export const CLOSE_DELETE_MODAL = 'CLOSE_DELETE_MODAL'

export const setManagersField = (key, value) => ({
  type: SET_MANAGERS_FIELD,
  key,
  value
})

export const setErrorMessage = (errors) => ({
  type: SET_ERRORS,
  errors
})

export const openDeleteModal = (managerToDelete) => ({
  type: OPEN_DELETE_MODAL,
  managerToDelete
})

export const closeDeleteModal = () => ({
  type: CLOSE_DELETE_MODAL
})

export const fetchManagers = (condoId) => (dispatch) =>
  condofyProtected
    .get(`/v1/condominiums/${condoId}/managers`)
    .then((response) => {
      dispatch(setManagersField('managers', response.data.data))
    })

export const disassignManager = (condoId, userId) => (dispatch) =>
  condofyProtected
    .delete(`/v1/condominiums/${condoId}/managers/${userId}`)
    .then(() => dispatch(closeDeleteModal()))
    .catch(async (response) => {
      const data = await response
      dispatch(setErrorMessage(data.errors))
      dispatch(getUnitsByCondo(condoId))

      return Promise.reject(data.errors)
    })

export const assignNewManager = (condoId, userId) => (dispatch) =>
  condofyProtected
    .post(`/v1/condominiums/${condoId}/managers/${userId}`)
    .then(() => {
      dispatch(fetchManagers(condoId))
      dispatch(getUnitsByCondo(condoId))
    })

export const fetchFilteredUsers = ({ currentCondominium, search }) =>
  listAllUsers({ currentCondominium, excludeManagers: true }).then(
    (response) => {
      const userList = response.data.data || []

      return userList
        .filter(
          ({ name }) => name.toLowerCase().indexOf(search.toLowerCase()) > -1
        )
        .map((user) => ({
          value: user.id,
          label: user.name
        }))
    }
  )
