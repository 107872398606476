import { condofyProtected } from '@condofy/next/service/instance'

class StorageService {
  search({ currentCondominium }) {
    return condofyProtected.get(
      `/v1/condominiums/${currentCondominium.id}/attachments`
    )
  }

  download({ currentCondominium, payload }) {
    return condofyProtected.post(
      `/v1/condominiums/${currentCondominium.id}/attachments/download-signedlink`,
      payload
    )
  }
}

export default new StorageService()
