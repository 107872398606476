import { combineReducers } from 'redux'
import { Reducer as ticket } from './ticket'
import { Reducer as storage } from './storage'
import { Reducer as occurrence } from './occurrence'
import { Reducer as announcement } from './announcement'
import { Reducer as announcementModel } from './announcementModel'
import { Reducer as finance } from './finance'
import condominiumSection from 'Reducers/condominiumSection'
import connection from 'Reducers/connection'
import units from 'Reducers/units'
import modal from 'Reducers/modals'
import managers from 'Reducers/managers'
import users from 'Reducers/users'

export default combineReducers({
  announcement,
  announcementModel,
  finance,
  ticket,
  storage,
  occurrence,
  condominiumSection,
  connection,
  units,
  managers,
  modal,
  users
})
