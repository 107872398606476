import { useContext } from 'react'
import { createStore } from '../core/'
import * as calendar from './calendar'

const subStores = [calendar]

const [CalendarContext, CalendarProvider] = createStore(subStores)

export const useCalendarContext = () => useContext(CalendarContext)

export { CalendarContext, CalendarProvider }
