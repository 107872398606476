import React from 'react'
import {
  DataTableRow,
  DataTableCell,
  ButtonMenu,
  MenuItem
} from '@condofy/components'
import { track } from '@condofy/next/service/segment'
import { deleteScheduling } from '@condofy/next/service/scheduling'
import { useUserContext } from '@condofy/next/store/user'
import { useSnackbar } from 'notistack'

export const ScheduleListRow = ({
  schedule,
  handleError,
  onDelete = () => {}
}) => {
  const initialDate = new Date(schedule.initialDate)
  const finalDate = new Date(schedule.finalDate)
  const timeOptions = { hour: '2-digit', minute: '2-digit' }

  const {
    state: { currentCondominium }
  } = useUserContext()
  const { enqueueSnackbar } = useSnackbar()

  const handleDelete = (schedule) => (event) => {
    event.preventDefault()
    track(window.analytics, 'Booking Removed', schedule)
    const deleteConfirmation = window.confirm(
      'Tem certeza que deseja exculir esta reserva?\n\nEsta ação não pode ser revertida.'
    )
    if (deleteConfirmation) {
      deleteScheduling({
        currentCondominium,
        schedulingId: schedule.id,
        onSuccess: () => {
          enqueueSnackbar('Reserva excluída com sucesso.', {
            variant: 'success'
          })
          onDelete()
        },
        onError: handleError
      })
    }
  }

  return (
    <DataTableRow hover tabIndex={-1} key={schedule.id}>
      <DataTableCell component='th' scope='row'>
        {schedule.name ? schedule.name : 'Não informado'}
      </DataTableCell>
      <DataTableCell component='th' scope='row'>
        {schedule.area && schedule.area.name ? schedule.area.name : 'Não informado'}
      </DataTableCell>
      <DataTableCell component='th' scope='row'>
        {schedule.unit && schedule.unit.name ? schedule.unit.name : 'Não informado'}
      </DataTableCell>
      <DataTableCell component='th' scope='row'>
        {initialDate.toLocaleDateString()}
      </DataTableCell>
      <DataTableCell component='th' scope='row'>
        {initialDate.toLocaleTimeString([], timeOptions)} -{' '}
        {finalDate.toLocaleTimeString([], timeOptions)}
      </DataTableCell>
      <DataTableCell align='right'>
        {initialDate.getTime() > new Date().getTime() && (
          <ButtonMenu>
            <MenuItem onClick={handleDelete(schedule)}>Excluir</MenuItem>
          </ButtonMenu>
        )}
      </DataTableCell>
    </DataTableRow>
  )
}
