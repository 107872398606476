import { errorParser } from 'Middleware/parsers'

import {
  SET_MANAGERS_FIELD,
  SET_ERRORS,
  CLOSE_DELETE_MODAL,
  OPEN_DELETE_MODAL
} from 'Actions/managers'

const initialState = {
  managers: [],
  selectedUser: null,
  errors: {},
  managerToDelete: '',
  isOpenDeleteModal: false
}

const managers = (state = initialState, action) => {
  switch (action.type) {
    case SET_MANAGERS_FIELD:
      return {
        ...state,
        [action.key]: action.value
      }
    case SET_ERRORS:
      return {
        ...state,
        errors: action.errors.length > 0 ? errorParser(action.errors) : {}
      }
    case OPEN_DELETE_MODAL:
      return {
        ...state,
        managerToDelete: action.managerToDelete,
        isOpenDeleteModal: true
      }
    case CLOSE_DELETE_MODAL:
      return {
        ...state,
        isOpenDeleteModal: false,
        managerToDelete: ''
      }
    default:
      return {
        ...state
      }
  }
}

export default managers
