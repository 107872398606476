import React, { useEffect, useState } from 'react'
import {
  CardGrid,
  Icon,
  colors,
  TextField,
  TableContainer,
  Flex,
  Table,
  TableBody,
  TableRow,
  Checkbox,
  TableCell,
  ProgressBar,
  Tooltip
} from '@condofy/components'
import { DataCard } from '@condofy/next/components/DataCard'
import styled from 'styled-components'
import {
  handleError,
  useSnackbar,
  useDownloadDefaultingReport
} from '../../next/hooks'
import { useUserContext } from '@condofy/next/store/user'
import { Modal, ActionRow, ModalContent } from '@condofy/next/components'
import Premium from 'Assets/icons/premium-tag.svg'

const IconWrapper = styled.div`
  position: relative;

  .MuiSvgIcon-root:first-child {
    color: ${colors.brandBlue100} !important;
  }
  .MuiSvgIcon-root:last-child {
    background: #fff;
    bottom: -4px;
    color: ${colors.danger500} !important;
    position: absolute;
    right: 0;
  }
`

const StyledCardContainer = styled.div`
  position: relative;
  height: 100%;
`

const StyledTooltip = styled(Tooltip)`
  position: absolute;
  top: 10px;
  right: 10px;
`

const IconPremium = styled(Premium)`
  margin-left: 15px;
  z-index: 2;
`

const TableRowNoSelection = styled(TableRow)`
  user-select: none;
`

const DefaultingIcon = () => (
  <IconWrapper>
    <Icon icon='document_text_outlined' fontSize='large' />
    <Icon icon='error' fontSize='small' />
  </IconWrapper>
)

const AccountabilityIcon = () => (
  <IconWrapper>
    <Icon icon='document_text_outlined' fontSize='large' />
    <Icon icon='billing' fontSize='small' />
  </IconWrapper>
)

export const ReportsTab = ({ setMainReportsTab }) => {
  const [loading, setLoading] = useState(false)
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [searchReceivers, setSearchReceivers] = useState('')
  const [receiversSelected, setReceiversSelected] = useState([])
  const [allSelected, setAllSelected] = useState(false)
  const [progress, setProgress] = useState(1)

  useEffect(() => {
    let progressValue = progress
    const intervalId = setInterval(() => {
      if (progressValue < 98) {
        setProgress(progressValue + 1)
        progressValue += 1
      } else {
        clearInterval(intervalId)
      }
    }, 50)

    return () => clearInterval(intervalId) // cleanup on unmount
  }, [loading])

  const {
    state: {
      currentCondominium,
      unit: { allItems: unitList }
    },
    actions: { listAllUnits }
  } = useUserContext()

  const userIsPremium = !(
    currentCondominium &&
    currentCondominium.plan &&
    currentCondominium.plan.id === '2'
  )

  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    listAllUnits({ currentCondominium: { id: currentCondominium.id } })
  }, [])

  const finishDownload = () => {
    setProgress(99)
    setLoading(false)
    setProgress(1)
  }

  const DownloadReport = () => {
    try {
      setLoading(true)
      setTimeout(
        useDownloadDefaultingReport({
          currentCondominium,
          selected: receiversSelected,
          stopLoading: () => finishDownload()
        }),
        200
      )
    } catch (error) {
      handleError(enqueueSnackbar)
    } finally {
      deselectAll()
      onModalClose()
    }
  }

  const onModalClose = () => {
    setModalIsOpen(false)
  }

  const deselectAll = () => {
    setAllSelected(false)
    setReceiversSelected([])
  }

  const alreadyOnTheList = (id) => receiversSelected.find((a) => a === id)

  const selectAll = (id) => {
    setReceiversSelected([...unitList.map((item) => item.id), id])
    setAllSelected(true)
  }

  const isSelected = (id) => Boolean(receiversSelected.find((a) => a === id))

  const addToTheList = (id) => setReceiversSelected([...receiversSelected, id])

  const removeFromTheList = (id) => {
    const newList = receiversSelected.filter((a) => a !== id && a !== -1)
    setReceiversSelected([...newList])
  }

  const selectReceiver = (id) => {
    if (id === -1) {
      if (alreadyOnTheList(id)) {
        deselectAll()
        setAllSelected(false)
      } else {
        if (allSelected) {
          setAllSelected(false)
        }
        selectAll(id)
        setAllSelected(true)
      }
    } else {
      if (alreadyOnTheList(id)) {
        removeFromTheList(id)
        setAllSelected(false)
      } else {
        addToTheList(id)
      }
    }
  }

  return (
    <CardGrid recordCount={2}>
      <StyledCardContainer>
        <DataCard
        title='Inadimplentes'
        avatar={<DefaultingIcon />}
        buttonText='Baixar'
        disabled={loading || !userIsPremium}
        onClick={() => setModalIsOpen(true)}
        />
        {!userIsPremium && <StyledTooltip title='Este item está incluso no plano premium.' arrow>
          <span>
            <IconPremium />
          </span>
        </StyledTooltip>}
      </StyledCardContainer>
      <DataCard
        title='Prestação de Contas'
        avatar={<AccountabilityIcon />}
        buttonText='Abrir'
        onClick={() => setMainReportsTab(false)}
      />
      <Modal
        icon={<Icon icon='download' />}
        title='Unidades'
        open={modalIsOpen}
        onClose={onModalClose}
        footer={
          <ActionRow
            disabled={!receiversSelected.length}
            confirmText='Download'
            loading={loading}
            onCancel={onModalClose}
            onSubmit={DownloadReport}
          />
        }
      >
        <ModalContent>
          <Flex direction='column' spacingY={3}>
            <TextField
              label='Filtrar unidades'
              onChange={(event) => setSearchReceivers(event.target.value)}
            />
            <TableContainer>
              <Table>
                <TableBody>
                  {[{ id: -1, number: 'Todas', block: '' }, ...unitList]
                    .filter(
                      (a) =>
                        String(a.number).includes(searchReceivers) ||
                        String(a.block).includes(searchReceivers)
                    )
                    .map((item) => (
                      <TableRowNoSelection
                        key={`${item.id}${item.number}`}
                        onClick={() => selectReceiver(item.id)}
                      >
                        <TableCell padding='none'>
                          {item.id === -1 ? (
                            <>
                              <Checkbox
                                color='primary'
                                checked={allSelected}
                                onChange={() => {
                                  if (allSelected) {
                                    deselectAll()
                                  } else {
                                    selectAll()
                                  }
                                }}
                              />
                              <span>{item.number}</span>
                            </>
                          ) : (
                            <>
                              <Checkbox
                                color='primary'
                                checked={isSelected(item.id)}
                                onChange={() => selectReceiver(item.id)}
                              />
                              <span>
                                {item.number}
                                {item.block.length ? ` - ${item.block}` : ''}
                              </span>
                            </>
                          )}
                        </TableCell>
                      </TableRowNoSelection>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Flex>
        </ModalContent>
      </Modal>
      <ProgressBar active={loading} progress={progress} />
    </CardGrid>
  )
}
