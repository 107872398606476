export const updateInitialState = {
  data: {},
  metadata: {
    loading: false
  },
  error: null
}

export class Update {
  constructor({ key, statePropName = 'selected' }) {
    if (!key) {
      throw Error('Update constructor must be passed a key')
    }
    this._stateProp = statePropName
    this._key = key
    this._action = {
      update: `LOAD_${key}_UPDATE`,
      pending: `LOAD_${key}_UPDATE_PENDING`,
      error: `LOAD_${key}}_UPDATE_ERROR`
    }
    this._initialState = updateInitialState
  }

  get initialState() {
    return this._initialState
  }

  set initialState(initialState) {
    this._initialState = initialState
  }

  get actionTypes() {
    return this._action
  }

  reducers() {
    return {
      [this._action.update]: (state = this._initialState) => {
        return {
          ...state,
          [this._stateProp]: {
            ...state[this._stateProp]
          }
        }
      },
      [this._action.success]: (state = this._initialState, action) =>
        this._successReducer(state, action),
      [this._action.pending]: (state = this._initialState, action) =>
        this._pendingReducer(state, action),
      [this._action.error]: (state = this._initialState, action) =>
        this._errorReducer(state, action)
    }
  }

  middleware(updateFunc) {
    if (!updateFunc) {
      throw Error('Middleware must receive a valid function')
    }
    return {
      [this._action.update]: this._update(updateFunc)
    }
  }

  actions(state, dispatch) {
    return (data) => dispatch({ type: this._action.update, payload: data })
  }

  _pending(dispatch, payload) {
    dispatch({ type: this._action.pending, payload })
  }

  _update(callback) {
    return async (dispatch, payload) => {
      this._pending(dispatch, true)
      try {
        const response = await callback(payload)
        return response.data
      } catch (error) {
        dispatch({ type: this._action.error, payload: error })
      } finally {
        this._pending(dispatch, false)
      }
    }
  }

  _pendingReducer(state, action) {
    return {
      ...state,
      [this._stateProp]: {
        ...state[this._stateProp],
        metadata: {
          ...state[this._stateProp]?.metadata,
          loading: action.payload
        }
      }
    }
  }

  _errorReducer(state, action) {
    return {
      ...state,
      [this._stateProp]: {
        ...state[this._stateProp],
        error: action.payload
      }
    }
  }
}
