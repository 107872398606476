import React from 'react'
import {
  SmsCodeModal,
  EmailConfirmationModal,
  CreateConnectionModal,
  CondominiumCreateModal,
  CondominiumEditModal,
  CondominiumDeleteModal,
  CondominiumInactiveModal,
  CondominiumActiveModal,
  UserCreateModal,
  UserEditModal,
  UserDetailsModal,
  UserDeleteModal,
  UserConnectionEditModal,
  TicketEditModal,
  TicketCreateModal,
  AnnouncementCreateModal,
  AnnouncementEditModal,
  AnnouncementeModelCreateModal,
  AnnouncementeModelEditModal,
  BillingDetailModal,
  CreateAreaModal,
  EditAreaModal
} from '@condofy/next/containers/'
import { AddUserConnection } from 'Components/admin/modals/AddUserConnection'
import { MobileAppModal } from './mobile/MobileAppModal'
import { BillingDeclarationModal } from './billing/BillingDeclarationModal'

export const ModalContainer = () => (
  <>
    <BillingDetailModal />
    <BillingDeclarationModal />

    <SmsCodeModal />
    <EmailConfirmationModal />
    <CreateConnectionModal />

    <AddUserConnection />

    <CondominiumCreateModal />
    <CondominiumEditModal />
    <CondominiumDeleteModal />
    <CondominiumInactiveModal />
    <CondominiumActiveModal />

    <UserCreateModal />
    <UserEditModal />
    <UserDetailsModal />
    <UserDeleteModal />

    <TicketCreateModal />
    <TicketEditModal />

    <AnnouncementCreateModal />
    <AnnouncementEditModal />

    <AnnouncementeModelCreateModal />
    <AnnouncementeModelEditModal />

    <UserConnectionEditModal />

    <CreateAreaModal />
    <EditAreaModal />
    <MobileAppModal />
  </>
)
