import React, { useState } from 'react'
import {
  LoadingButton,
  Dialog,
  DialogActions,
  DialogContent
} from '@condofy/components'
import { Typography, Button, TextField } from '@condofy/components'

export const BankDisconnectionPopup = ({
  acceptAction,
  cancelAction,
  loading,
  deleteDialogState: { isOpen, id }
}) => {
  const [cancelText, setCancelText] = useState('')
  const [canCancel, setCanCancel] = useState(false)

  const changeCancelText = (event) => {
    setCancelText(event.target.value)
    setCanCancel(event.target.value === 'CANCELAR')
  }

  return (
    <Dialog open={isOpen} title='Aviso' onClose={cancelAction}>
      <DialogContent>
        <p>
          Ao desconectar a integração, a Condofy perderá toda a conexão com sua
          conta corrente e não poderá mais realizar nenhuma transação.
        </p>
        <p>
          Confirme abaixo que deseja realmente cancelar digitando{' '}
          <Typography color='error'>CANCELAR</Typography> no campo abaixo.
        </p>
        <p>
          <TextField label='' value={cancelText} onChange={changeCancelText} />
        </p>
      </DialogContent>
      <DialogActions>
        <Button color='secondary' onClick={cancelAction}>
          Cancelar
        </Button>
        <LoadingButton
          loading={loading}
          onClick={() => acceptAction(id)}
          disabled={!canCancel}
        >
          Confirmar
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
