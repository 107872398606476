import { condominiumPending, store } from './index'
import {
  listCondominium,
  condominiumFullList
} from '@condofy/next/service/condo'

// ACTION TYPES
const CONDOMINIUM_LIST = 'CONDOMINIUM_LIST'
const CONDOMINIUM_LIST_FULL = 'CONDOMINIUM_LIST_FULL'
const CONDOMINIUM_LIST_SUCCESS = 'CONDOMINIUM_LIST_SUCCESS'
const CONDOMINIUM_LIST_FULL_SUCCESS = 'CONDOMINIUM_LIST_FULL_SUCCESS'

export const listActionTypes = {
  CONDOMINIUM_LIST,
  CONDOMINIUM_LIST_SUCCESS,
  CONDOMINIUM_LIST_FULL,
  CONDOMINIUM_LIST_FULL_SUCCESS
}

// ACTIONS
export const condominiumListActions = (state, dispatch) => ({
  listCondominium: (payload) => dispatch({ type: CONDOMINIUM_LIST, payload }),
  listAllCondominiums: (payload) =>
    dispatch({ type: CONDOMINIUM_LIST_FULL, payload })
})

// MIDDLEWARE
export const loadCondominiumList = async (dispatch, payload) => {
  condominiumPending(dispatch, true)
  try {
    const response = await listCondominium(payload)
    dispatch({
      type: CONDOMINIUM_LIST_SUCCESS,
      payload: response.data
    })

    return response
  } finally {
    condominiumPending(dispatch, false)
  }
}

export const listAll = async (dispatch, payload) => {
  condominiumPending(dispatch, true)
  try {
    const response = await condominiumFullList(payload)

    dispatch({ type: CONDOMINIUM_LIST_FULL_SUCCESS, payload: response.data })

    return response
  } finally {
    condominiumPending(dispatch, false)
  }
}

// REDUCER
export const condominiumListReducer = (state, action) => {
  switch (action.type) {
    case CONDOMINIUM_LIST_SUCCESS:
      return store(state, {
        items: action.payload.data,
        metadata: {
          ...state.condominium.metadata,
          ...action.payload.paging
        }
      })

    case CONDOMINIUM_LIST_FULL_SUCCESS:
      return store(state, {
        allItems: action.payload.data
      })

    default:
      return state
  }
}
