import React from 'react'
import { useUserContext } from '@condofy/next/store/user'
import {
  TextField,
  FormRow,
  Form,
  DatePicker,
  TimePicker
} from '@condofy/components'
import { FormField as Field } from '@condofy/next/containers/form/FormField'
import { RecurrenceSelect } from './RecurrenceSelect'
import { ReminderSelect } from './ReminderSelect'
import { VisibilitySelect } from './VisibilitySelect'

export const CalendarEventForm = ({ isCreate = true, isDisabled = false }) => {
  const {
    state: { isSuperAdmin, isAdmin }
  } = useUserContext()
  const administratorUser = isSuperAdmin || isAdmin
  const enabled = administratorUser && !isDisabled
  return (
    <Form>
      <FormRow>
        <Field
          name='summary'
          disabled={!enabled}
          Component={<TextField label='Título' required />}
        />
      </FormRow>
      <FormRow>
        <Field
          name='visibility'
          Component={<VisibilitySelect disabled={!enabled} required/>}
        />
      </FormRow>
      <FormRow>
        <Field
          name='day'
          passRef={false}
          Component={
            <DatePicker
              okLabel='Selecionar'
              label='Dia'
              disabled={!enabled}
            />
          }
        />
      </FormRow>
      <FormRow>
        <Field
          name='start'
          passRef={false}
          Component={
            <TimePicker
              okLabel='Selecionar'
              format='HH:mm'
              label='Hora Inicial'
              disabled={!enabled}
            />
          }
        />
      </FormRow>
      <FormRow>
        <Field
          name='end'
          passRef={false}
          Component={
            <TimePicker
              okLabel='Selecionar'
              label='Hora Final'
              disabled={!enabled}
            />
          }
        />
      </FormRow>
      <FormRow>
        <Field
          name='recurrence'
          Component={<RecurrenceSelect disabled={!enabled} />}
        />
      </FormRow>
      {isCreate && (
        <FormRow>
          <Field
            name='reminderMinutes'
            Component={<ReminderSelect disabled={!enabled} />}
          />
        </FormRow>
      )}
      <FormRow>
        <Field
          name='description'
          Component={
            <TextField
              label='Detalhes'
              multiline
              rows={4}
              disabled={!enabled}
              required
            />
          }
        />
      </FormRow>
    </Form>
  )
}
