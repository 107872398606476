import React, { useEffect } from 'react'

import { useSnackbar, handleError } from '@condofy/next/hooks/useSnackbar'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { financialEventSchema } from './financialEventSchema'
import { ActionRow, ProcessDialog } from '@condofy/next/components'
import { FinancialStatementNewEntryForm } from './FinancialStatementNewEntryForm'
import moment from 'moment'

export const CreateFinancialEntryEventModal = ({
  error,
  loading,
  open,
  onClose,
  onSave,
  categories = [],
  unities = []
}) => {
  const { enqueueSnackbar } = useSnackbar()

  const form = useForm({
    mode: 'onChange',
    resolver: yupResolver(financialEventSchema),
    defaultValues: {
      fundingType: '',
      paidAt: new Date(),
      categoryId: '',
      description: '',
      amount: null,
      receipt: '',
      unitId: ''
    }
  })

  const handleClose = () => {
    form.reset()
    onClose()
  }

  const handleSaveEntry = (values) => {
    const paidAtFormatted = moment(values.paidAt).format('YYYY-MM-DD')
    const amount =
      values.fundingType === '2' ? -Math.abs(values.amount) : values.amount

    onSave({
      ...values,
      amount: amount,
      paidAt: paidAtFormatted
    })
  }

  useEffect(() => {
    if (error) {
      handleError(enqueueSnackbar)('Ocorreu um erro ao fazer o lançamento')
    }
  }, [error])

  useEffect(() => {
    form.reset()
    form.clearErrors()
  }, [form, open])

  return (
    <>
      <ProcessDialog
        open={open}
        onClose={onClose}
        title='Lançamento'
        actions={
          <ActionRow
            loading={loading}
            confirmText='Salvar'
            cancelText='Cancelar'
            onCancel={handleClose}
            onSubmit={form.handleSubmit(handleSaveEntry)}
          />
        }
      >
        <FormProvider {...form}>
          <FinancialStatementNewEntryForm
            categories={categories}
            unities={unities}
          />
        </FormProvider>
      </ProcessDialog>
    </>
  )
}
