import React from 'react'
import { Flex, TextField } from '@condofy/components'
import { Vote } from './Vote'

export const ConfirmVote = ({ vote, rows = 3, label = 'Motivo', ...props }) => (
  <Flex direction='column' spacingY={3}>
    <Vote vote={vote} />
    <TextField flex='1' multiline rows={rows} label={label} {...props} />
  </Flex>
)
