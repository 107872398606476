export const messages = {
  agenda: 'Agenda',
  allDay: 'Todo o dia',
  date: 'Data',
  day: 'Dia',
  event: 'Evento',
  month: 'Mês',
  next: 'Próximo',
  noEventsInRange: 'Não existem eventos neste intervalo.',
  previous: 'Anterior',
  showMore: (total) => `+ ${total} eventos`,
  time: 'Hora',
  today: 'Hoje',
  tomorrow: 'Amanhã',
  week: 'Semana',
  work_week: 'Dias úteis',
  yesterday: 'Ontem'
}
