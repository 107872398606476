import React from 'react'
import styled, { css } from 'styled-components'
import { colors, controls } from '../../styles'
import { TextField as MaterialTextField, Typography } from '@mui/material'

const fieldPadding = css`
  .MuiOutlinedInput {
    &-input {
      padding: ${controls.field.padding} !important;
    }
  }
  .MuiInputLabel-outlined {
    margin-top: -6px;

    &.MuiInputLabel-shrink {
      margin-top: 0;
    }
  }
  .MuiOutlinedInput-multiline {
    padding: 0;
  }
  .MuiSelect-outlined.MuiSelect-outlined {
    padding-right: 32px !important;
  }
`

const fieldBorder = css`
  .MuiOutlinedInput {
    &-input {
      z-index: 1;
    }
    &-notchedOutline {
      border-color: ${controls.field.borderColor};
      background-color: ${controls.field.backgroundColor};
      z-index: 0;
    }
  }
  &:hover {
    .MuiInputBase-root:not(.Mui-error) {
      .MuiOutlinedInput-notchedOutline {
        border-color: ${colors.primary};
      }
    }
  }
`

const fieldLabelColor = css`
  .MuiInputLabel-root:not(.Mui-focused):not(.Mui-error) {
    color: ${controls.field.labelColor};
  }
  .MuiFormLabel-root {
    white-space: nowrap;
  }
`

const fieldAdornmentStyle = css`
  .MuiInputAdornment-root {
    color: ${controls.field.adornmentColor};
    z-index: 3;
  }
  .MuiSelect-icon {
    z-index: 3;
  }
`

const fieldTypography = css`
  .MuiFormLabel-root,
  .MuiInputBase-input {
    font-family: ${controls.field.fontFamily} !important;
    font-size: ${controls.field.fontSize};
  }
  .MuiInputBase-root {
    min-height: 40px;
    line-height: 1.75;
  }
`

const StyledTextField = styled(MaterialTextField)`
  ${fieldPadding}
  ${fieldBorder}
  ${fieldLabelColor}
  ${fieldTypography}
  ${fieldAdornmentStyle}
`

export const TextField = ({ select, ...props }) => {
  const selectProps = select && {
    MenuProps: {
      anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
      transformOrigin: { vertical: 'top', horizontal: 'left' }
    }
  }
  return (
    <StyledTextField
      select={select}
      SelectProps={selectProps}
      color='primary'
      variant='outlined'
      {...props}
    />
  )
}

export const TextFieldCounter = ({ select, maxLength, value, ...props }) => {
  const selectProps =
    select ||
    (value &&
      value.length && {
        MenuProps: {
          anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
          transformOrigin: { vertical: 'top', horizontal: 'left' }
        }
      })
  return (
    <div style={{ width: '100%' }}>
      <StyledTextField
        select={select}
        sx={{ width: '100%' }}
        SelectProps={selectProps}
        inputProps={{
          maxLength: maxLength
        }}
        color='primary'
        variant='outlined'
        value={value}
        {...props}
      />
      <div>
        <Typography
          sx={{
            color: value && value.length === maxLength ? '#c62924' : '#686868',
            fontSize: 12,
            marginLeft: 1
          }}
        >
          {value && value.length ? value.length : 0}/{maxLength}
        </Typography>
      </div>
    </div>
  )
}
