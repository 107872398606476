import yup from '@condofy/next/service/yup'

export const calendarEventSchema = yup.object().shape({
  summary: yup.string().required(),
  description: yup.string().required(),
  day: yup.date(),
  end: yup
    .date()
    .default(null)
    .when(
      'start',
      (start, yup) =>
        start && yup.min(start, 'Hora final não pode ser antes da hora inicial')
    ),
  start: yup.date(),
  visibility: yup.string().nullable().oneOf(['pv', 'pb', '', undefined]).required().default('pb'),
  reminderMinutes: yup.mixed().oneOf([60, 1440, 10080, '', undefined]),
  recurrence: yup.string().oneOf(['D', 'W', 'M', 'Y', 'U', '', undefined])
})
