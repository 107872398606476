import React, { useCallback, useEffect } from 'react'
import { PageSubTitle } from '@condofy/components'
import { useUserContext } from '@condofy/next/store/user'
import { useCommunityContext } from '@condofy/next/store/community'
import { MyListingsList } from '@condofy/next/containers/community/'

export const MyListingsTab = () => {
  const {
    state: { currentCondominium, id }
  } = useUserContext()
  const {
    state: {
      listListings: {
        metadata: { loading },
        items
      }
    },
    actions: { loadListingList }
  } = useCommunityContext()

  const stableLoadListingList = useCallback(loadListingList, [])

  useEffect(() => {
    stableLoadListingList({ currentCondominium, membershipId: id })
  }, [stableLoadListingList, currentCondominium, id])

  return (
    <>
      <PageSubTitle>Seus Itens</PageSubTitle>
      <MyListingsList loading={loading} listings={items} />
    </>
  )
}
