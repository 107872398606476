import React from 'react'
import { ExpandableDataTableRow } from '@condofy/components'
import { track } from '@condofy/next/service/segment'
import { FinancialStatementItemDetail } from './FinancialStatementItemDetail'
import { useFinancialContext } from '@condofy/next/store/financial'
import { useSnackbar, handleError } from '@condofy/next/hooks/useSnackbar'

export const FinancialStatementItem = ({
  item,
  columns,
  currentCondominium,
  reloadEntryList,
  onEdit
}) => {
  const { enqueueSnackbar } = useSnackbar()

  const {
    actions: { deleteEntry }
  } = useFinancialContext()

  const onClick = () => {
    track(window.analytics, 'Balance Item Details Opened', item)
  }

  const { id } = item
  const handleDelete = () => {
    deleteEntry({
      condominiumId: currentCondominium.id,
      entryId: id
    })
      .then(() => {
        const successMessage = 'Lançamento deletado com sucesso'
        reloadEntryList()
        track(window.analytics, successMessage, {
          condominium_id: currentCondominium.id,
          condominium_name: name
        })
        enqueueSnackbar(successMessage, {
          variant: 'success'
        })
      })
      .catch((err) => {
        let errorMessage = err.response.data.reports[0].message
        handleError(
          enqueueSnackbar(errorMessage, {
            variant: 'error'
          })
        )
      })
  }

  const handleEdit = () => {
    onEdit(item)
  }

  return (
    <ExpandableDataTableRow
      onClick={onClick}
      onDeleteRow={handleDelete}
      onEditRow={handleEdit}
      expandComponent={
        <FinancialStatementItemDetail item={item} columns={columns} />
      }
      row={item}
      columns={columns}
    />
  )
}
