import yup from '@condofy/next/service/yup'

const currencySchema = yup
  .number()
  .positive()
  .typeError('O valor deve ser um número')
  .nullable()

export const invoiceSchema = yup.object().shape({
  cpf: yup.string().required(),
  dueDate: yup.date().required().min(new Date()),
  value: yup.number().required().concat(currencySchema),
  clientNumber: yup.number().required().positive().integer(),
  allowLatePayment: yup.boolean(),
  hasDiscount: yup.boolean(),
  discount: yup.object().when('hasDiscount', {
    is: true,
    then: yup.object().shape({
      type: yup.string(),
      interest: yup.string().required(),
      limit: yup.date().min(new Date())
    })
  }),
  items: yup
    .array()
    .min(1, 'O boleto deve ter pelo menos um item')
    .of(
      yup.object().shape({
        value: yup.number().required().concat(currencySchema),
        description: yup.string().required(),
        category: yup.string().required()
      })
    )
    .default([]),
  invoiceName: yup.string(),
  description: yup.string()
})
