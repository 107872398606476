import React, { useState } from 'react'
import { useSnackbar, handleError } from '@condofy/next/hooks/useSnackbar'
import { useUserContext } from '@condofy/next/store/user'
import { Modal, ActionRow, ModalContent } from '@condofy/next/components'
import { track } from '@condofy/next/service/segment'

export const CondominiumActiveModal = () => {
  const { enqueueSnackbar } = useSnackbar()
  const [loading, setLoading] = useState(false)

  const {
    state: {
      condominium: { selectedCondominium, activeModalOpen }
    },
    actions
  } = useUserContext()

  const handleSubmit = () => {
    setLoading(true)
    actions
      .activeCondominium(selectedCondominium)
      .then(() => {
        track(window.analytics, 'Condominium Actived', selectedCondominium)
        enqueueSnackbar('Condomínio ativado com sucesso', {
          variant: 'success'
        })
        actions.listCondominium()
        actions.closeActiveCondominiumModal()
      })
      .catch(handleError(enqueueSnackbar))
      .finally(() => setLoading(false))
  }

  return (
    <Modal
      title='Ativar condomínio'
      open={activeModalOpen}
      onClose={actions.closeActiveCondominiumModal}
      footer={
        <ActionRow
          loading={loading}
          confirmText='Ativar'
          onSubmit={handleSubmit}
          onCancel={actions.closeActiveCondominiumModal}
        />
      }
    >
      <ModalContent>
        Você tem certeza que quer ativar {selectedCondominium.name}?
      </ModalContent>
    </Modal>
  )
}
