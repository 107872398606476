import React, { useCallback, useEffect, useState } from 'react'
import { NewButton } from '@condofy/next/components'
import { downloadUsersList } from '@condofy/next/service/user'
import { useSnackbar } from '@condofy/next/hooks/useSnackbar'
import { useUserContext } from '@condofy/next/store/user'
import {
  Button,
  Card,
  CardHeader,
  CardContent,
  SearchTextField,
  Icon,
  Flex,
  TabToolbar
} from '@condofy/components'
import { UserList } from '@condofy/next/containers/'

export const UserListTab = (props) => {
  const { enqueueSnackbar } = useSnackbar()
  const {
    state: {
      user: { metadata, items, loading }
    },
    actions: { listUser, openCreateUserModal }
  } = useUserContext()
  const stableListUser = useCallback(listUser, [])

  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')

  useEffect(() => {
    stableListUser({ page, search, currentCondominium: {} }).catch(() =>
      enqueueSnackbar('Erro ao listar usuários', { variant: 'error' })
    )
  }, [page, search, stableListUser, enqueueSnackbar])

  const handleSearch = (event) => {
    setSearch(event.target.value)
    setPage(1)
  }

  const downloadUsers = () => {
    downloadUsersList({
      search,
      onSuccess: (response) => {
        var file = new Blob([response], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        })
        var fileURL = URL.createObjectURL(file)
        const link = document.createElement('a')
        link.href = fileURL
        link.download = `Usuários.xlsx`
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      },
      onError: () => {
        enqueueSnackbar('Erro ao baixar a lista usuários', {
          variant: 'error'
        })
      }
    })
  }

  return (
    <>
      <TabToolbar>
        <SearchTextField size='small' onChange={handleSearch} value={search} />
        <Flex spacing={2} alignItems='center'>
          <NewButton onClick={openCreateUserModal}>Novo</NewButton>
          <Button color='secondary' onClick={downloadUsers}>
            <Icon icon='download_to' />
          </Button>
        </Flex>
      </TabToolbar>
      <Card {...props}>
        <CardHeader title='Usuários' />
        <CardContent>
          <UserList
            loading={loading}
            items={items}
            page={page}
            setPage={setPage}
            totalRecords={metadata.totalRecords}
            recordsPerPage={metadata.recordsPerPage}
          />
        </CardContent>
      </Card>
    </>
  )
}
