import React from 'react'
import { Quill } from 'react-quill'

const Size = Quill.import('formats/size')
Size.whitelist = ['extra-small', 'small', 'medium', 'large']
Quill.register(Size, true)
// Quill.register({'formats/size': Size}, true)



const Font = Quill.import('formats/font')
Font.whitelist = 'arial'
Quill.register(Font, true)
// Quill.register({ 'formats/font' : Font }, true)

export const modules = {
  toolbar: {
    container: '#toolbar'
  },
  history: {
    delay: 500,
    maxStack: 100,
    userOnly: true
  }
}

export const formats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'align',
  'strike',
  'script',
  'blockquote',
  'background',
  'list',
  'bullet',
  'indent',
  'link',
  'color'
]

export const QuillToolbar = () => (
  <div id='toolbar'>
    <span className='ql-formats'>
      <select className='ql-size' defaultValue='medium'>
        <option value='extra-small'>Size 1</option>
        <option value='small'>Size 2</option>
        <option value='medium'>Size 3</option>
        <option value='large'>Size 4</option>
      </select>
      <select className='ql-header' defaultValue='3'>
        <option value='1'>Heading</option>
        <option value='2'>Subheading</option>
        <option value='3'>Normal</option>
      </select>
    </span>
    <span className='ql-formats'>
      <button className='ql-bold' />
      <button className='ql-italic' />
      <button className='ql-underline' />
      <button className='ql-strike' />
      <button className='ql-link' />
    </span>
    <span className='ql-formats'>
      <button className='ql-list' value='ordered' />
      <button className='ql-list' value='bullet' />
    </span>
    <span className='ql-formats'>
      <select className='ql-align' />
      <select className='ql-color' />
      <select className='ql-background' />
    </span>
  </div>
)

export default QuillToolbar
