import { Detail, List, Modal, Create, Update, Delete } from '../core/'
import {
  listEvent,
  getById,
  createEvent,
  updateEvent,
  deleteEvent
} from '@condofy/next/service/calendar'

const key = 'CALENDAR'

const calendar = new List({
  key: `${key}_LIST`,
  statePropName: 'events'
})

const detail = new Detail({ key })
const detailModal = new Modal({ key, statePropName: 'detail' })

const create = new Create({ key })
const createEventModal = new Modal({
  key: `${key}_CREATE_EVENT_MODAL`,
  statePropName: 'create'
})

const update = new Update({ key })

const deleteEv = new Delete({ key })

export const initialState = {
  events: { ...calendar.initialState },
  detail: { ...detailModal.initialState, ...detail.initialState },
  create: { ...create.initialState, ...createEventModal.initialState },
  update: { ...update.initialState },
  delete: { ...deleteEv.initialState }
}

export const createMiddleware = () => {
  return {
    ...calendar.middleware(listEvent),
    ...detail.middleware(getById),
    ...detailModal.middleware(),
    ...create.middleware(createEvent),
    ...createEventModal.middleware(),
    ...update.middleware(updateEvent),
    ...deleteEv.middleware(deleteEvent)
  }
}

export const useActions = (state, dispatch) => ({
  listEvents: calendar.actions(state, dispatch),

  createEvent: create.actions(state, dispatch),
  openCreateEventModal: createEventModal.actionOpen(state, dispatch),
  closeCreateEventModal: createEventModal.actionClose(state, dispatch),

  openEditEventModal: (data) => {
    detail.actions(state, dispatch)(data)
    detailModal.actionOpen(state, dispatch)(data)
  },
  closeEditEventModal: detailModal.actionClose(state, dispatch),

  updateEvent: update.actions(state, dispatch),

  deleteEvent: deleteEv.actions(state, dispatch)
})

export const createReducers = () => {
  return {
    ...calendar.reducers(),
    ...detail.reducers(),
    ...detailModal.reducers(),
    ...create.reducers(),
    ...createEventModal.reducers(),
    ...update.reducers(),
    ...deleteEv.reducers()
  }
}
