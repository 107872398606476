import React from 'react'

export const Copy = (props) => (
  <svg
    {...props}
    viewBox='0 0 14 14'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M11.0002 9.66659V1.66659C11.0002 0.930206 10.4032 0.333252 9.66683 0.333252H1.66683C0.93045 0.333252 0.333496 0.930206 0.333496 1.66659V9.66659C0.333496 10.403 0.93045 10.9999 1.66683 10.9999H9.66683C10.4032 10.9999 11.0002 10.403 11.0002 9.66659ZM1.66683 1.66659H9.66683V9.66659H1.66683V1.66659ZM12.3335 10.9999V2.99992C13.0699 2.99992 13.6668 3.59687 13.6668 4.33325V10.9999C13.6668 12.4727 12.4729 13.6666 11.0002 13.6666H4.3335C3.59712 13.6666 3.00016 13.0696 3.00016 12.3333H11.0002C11.7365 12.3333 12.3335 11.7363 12.3335 10.9999Z'
      fill='black'
    />
  </svg>
)
