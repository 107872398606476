import React from 'react'
import { Dialog, DialogContent, Wizard, WizardStep } from '@condofy/components'
import { CommunityOnboardWelcomeStep } from './CommunityOnboardWelcomeStep'
import { CommunityOnboardInterestsStep } from './CommunityOnboardInterestsStep'
import { CommunityOnboardTermsStep } from './CommunityOnboardTermsStep'
import { CommunityOnboardFirstOfferStep } from './CommunityOnboardFirstOfferStep'
import { useCommunityContext } from '@condofy/next/store/community'
import { useUserContext } from '@condofy/next/store/user'

import { createOnboard, createOffer } from '@condofy/next/service/community'
import { useForm, useFormState } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import yup from '@condofy/next/service/yup'
import { listingSchema } from '../listing/listingSchema'

const interestsFormSchema = yup.object().shape({
  interests: yup
    .array()
    .of(yup.boolean().optional())
    .atLeastOne((value) => value === true, 'Deve escolher pelo menos uma opção')
})

const termsFormSchema = yup.object().shape({
  agreeUseTerms: yup
    .boolean()
    .oneOf([true], 'Deve aceitar para continuar')
    .default(false)
})

export const CommunityOnboardModal = () => {
  const {
    state: { currentCondominium }
  } = useUserContext()
  const {
    state: {
      onboarding: {
        modal: { open }
      }
    },
    actions: { closeOnboardingModal, loadListingList }
  } = useCommunityContext()

  const interestsForm = useForm({
    mode: 'onChange',
    resolver: yupResolver(interestsFormSchema)
  })

  const interestsFormState = useFormState({ control: interestsForm.control })

  const termsForm = useForm({
    mode: 'onChange',
    resolver: yupResolver(termsFormSchema)
  })

  const termsFormState = useFormState({ control: termsForm.control })

  const offerForm = useForm({
    mode: 'onChange',
    resolver: yupResolver(listingSchema),
    defaultValues: {
      title: '',
      description: '',
      chargerType: '',
      price: '',
      files: []
    }
  })

  const handleClose = () => {
    offerForm.reset()
    closeOnboardingModal()
  }

  const onSubmit = () => {
    const interests = interestsForm
      .getValues()
      .interests.map((value, index) => (value ? index + 1 : undefined))
      .filter((value) => value)
    createOnboard({
      currentCondominium,
      interests,
      ...termsForm.getValues()
    }).then(() => {
      interestsForm.reset()
      termsForm.reset()
    })
  }

  const createFirstOffer = () => {
    createOffer({
      currentCondominium,
      payload: {
        ...offerForm.getValues(),
        price:
          offerForm.getValues().chargerType === 1
            ? 0
            : offerForm.getValues().price.toString().replace('.', ','),
        status: 1
      }
    }).then(() => {
      closeOnboardingModal()
      offerForm.reset()
      loadListingList({ currentCondominium })
    })
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      hideHeader={true}
      maxWidth='sm'
    >
      <DialogContent>
        <Wizard>
          <WizardStep
            showCancelButton={true}
            showBackButton={false}
            onCancel={handleClose}
          >
            <CommunityOnboardWelcomeStep />
          </WizardStep>
          <WizardStep
            showCancelButton={true}
            showBackButton={false}
            disabledNextButton={!interestsFormState.isValid}
            onCancel={handleClose}
          >
            <CommunityOnboardInterestsStep form={interestsForm} />
          </WizardStep>
          <WizardStep
            showCancelButton={true}
            showBackButton={false}
            nextButtonText='Entendi!'
            onNext={onSubmit}
            disabledNextButton={!termsFormState.isValid}
            onCancel={handleClose}
          >
            <CommunityOnboardTermsStep form={termsForm} />
          </WizardStep>
          <WizardStep
            showCancelButton={true}
            showBackButton={false}
            nextButtonText='Concluir'
            onNext={() => createFirstOffer()}
            onCancel={handleClose}
          >
            <CommunityOnboardFirstOfferStep form={offerForm} />
          </WizardStep>
        </Wizard>
      </DialogContent>
    </Dialog>
  )
}
