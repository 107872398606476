export const PollOptions = {
  YES: 'S',
  NO: 'N',
  ABSTAIN: 'A'
}

export const PollOptionsConfig = {
  [PollOptions.YES]: {
    label: 'A Favor',
    icon: 'thumbs_up',
    resultLabel: (percent) => `A Favor - ${percent}%`
  },
  [PollOptions.NO]: {
    label: 'Contra',
    icon: 'thumbs_down',
    resultLabel: (percent) => `Contra - ${percent}%`
  },
  [PollOptions.ABSTAIN]: {
    label: 'Abstenção',
    icon: 'thumbs_up_down',
    resultLabel: (percent) => `Abstenção - ${percent}%`
  }
}
