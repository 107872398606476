import React from 'react'
import { TabsPanelItem, TabsPanel, Page } from '@condofy/components'
import { UserListTab } from './UserListTab'
import { CondominiumsTab } from './CondominiumsTab'
import {
  CalendarCard,
  EditCalendarEventModal
 } from 'Modules/calendar'

export const AdminPlatformPage = () => (
  <Page>
    <TabsPanel>
      <TabsPanelItem label='Condomínios'>
        <CondominiumsTab />
      </TabsPanelItem>
      <TabsPanelItem label='Usuários'>
        <UserListTab />
      </TabsPanelItem>
      <TabsPanelItem label='Calendario'>
        <CalendarCard isFull={true} />
        <EditCalendarEventModal isFull={true} />
      </TabsPanelItem>
    </TabsPanel>
  </Page>
)
