import React, { useState, useEffect, useCallback } from 'react'
import {
  theme,
  colors,
  typography,
  Carousel,
  SearchTextField,
  Flex
} from '@condofy/components'
import { LoadingIconButton, Icon, LightTooltip } from '@condofy/components'
import styled from 'styled-components'
import { Box } from '@mui/material'
import { useUserContext } from '@condofy/next/store/user'
import { useCommunityContext } from '@condofy/next/store/community'

import { ToggleButton } from '@mui/material';
import { ToggleButtonGroup } from '@mui/material';
import { track } from '@condofy/next/service/segment'

const Label = styled(ToggleButton)`
  display: flex !important;
  max-height: 40px;
  background: ${colors.light100};
  border-radius: 3px;
  color: ${colors.dark400};
  font-family: ${typography.type.tertiary};
  padding: 10px ${theme.spacing(2)};
  text-align: center;
  text-transform: capitalize;

  &.MuiToggleButton-root.Mui-selected,
  &.MuiToggleButton-root:hover {
    background: ${colors.brandBlue400};
    color: ${colors.light100};
  }
`

export const subjectType = [
  { id: '1', name: 'Dúvidas' },
  { id: '2', name: 'Compartilhar algo legal' },
  { id: '3', name: 'Anúncio' },
  { id: '4', name: 'Outros' }
]

export const FeedToolbar = () => {
  const [generalSearch, setGeneralSearch] = useState('')
  const [subject, setSubject] = React.useState('')

  const {
    state: { currentCondominium, id }
  } = useUserContext()

  const {
    actions: { loadFeedList }
  } = useCommunityContext()

  const stableLoadFeedList = useCallback(loadFeedList, [])

  useEffect(() => {
    stableLoadFeedList({ currentCondominium, generalSearch, subject })

    track(window.analytics, 'Community Search', {
      condominiumId: currentCondominium.id,
      searchTerm: generalSearch,
      userId: id
    })
  }, [stableLoadFeedList, generalSearch, currentCondominium, subject])

  const handleSubject = (event, newSubject) => {
    if (newSubject !== null) {
      setSubject(newSubject)
    }
  }

  const hendleClear = () => {
    setSubject('')
    setGeneralSearch('')
  }

  return (
    <Flex
      spacingX={2}
      width='100%'
      justify='flex-start'
      alignItems='flex-start'
    >
      <div  style={{flex: 1}}>
        <Box position='relative' width='100%' height='100%'>
          <Box position='absolute' top='0' left='0' right='0' bottom='0'>
            <Carousel slidesToShow={1} infinite={false} variableWidth={true}>
              {subjectType.map((type) => (
                <ToggleButtonGroup
                  key={type.id}
                  value={subject}
                  exclusive
                  onChange={handleSubject}
                >
                  <Label value={type.id}>{type.name}</Label>
                </ToggleButtonGroup>
              ))}
            </Carousel>
          </Box>
        </Box>
      </div>
      <LightTooltip title='Limpar filtros'>
        <LoadingIconButton onClick={hendleClear}>
          <Icon icon='clear' color='#FF3B30' />
        </LoadingIconButton>
      </LightTooltip>
      <SearchTextField
        onChange={(event) => setGeneralSearch(event.target.value)}
        value={generalSearch}
      />
    </Flex>
  )
}
