import { get } from 'lodash'

export { useSnackbar, SnackbarProvider } from 'notistack'

export const getErrorMessage = (error) => {
  return (
    get(error, 'response.data.reports[0].message', null) ||
    get(error, 'message', null) ||
    error ||
    'Ocorreu um erro inesperado. Entre em contato com o suporte técnico.'
  )
}

export const handleError = (enqueueSnackbar) => (error) => {
  const message = getErrorMessage(error)

  if (message) {
    enqueueSnackbar(message, { variant: 'error' })
  }
}
