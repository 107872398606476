import React from 'react'
import {
  Switch as MuiSwitch,
  FormControlLabel
} from '@mui/material'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { colors, typography } from '../../styles'

const styles = {
  '&': {
    alignSelf: 'flex-start',
    height: 16,
    padding: 0,
    margin: (theme) => theme.spacing(1),
    width: 28
  },
  switchBase: {
    padding: '1px !important',
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: colors.light100,

      '& + $track': {
        backgroundColor: colors.light500,
        opacity: 1,
        border: 'none'
      },
      '&:not(.Mui-disabled) + $track': {
        backgroundColor: colors.primary
      }
    },
    '&$focusVisible $thumb': {
      color: colors.primary,
      border: '6px solid #fff'
    }
  },
  thumb: {
    width: 14,
    height: 14,
    '.Mui-disabled &': {
      backgroundColor: colors.light400
    }
  },
  track: {
    borderRadius: 16 / 2,
    border: `1px solid ${colors.light500}`,
    backgroundColor: colors.light500,
    opacity: 1,
    transition: (theme) => theme.transitions.create(['background-color', 'border'])
  },
  checked: {},
  focusVisible: {}
}

const StyledFormControlLabel = styled(FormControlLabel)`
  .MuiFormControlLabel-label {
    font-family: ${typography.type.tertiary};
    font-size: 14px;
    font-weight: normal;
    padding-right: 9px;
  }
  &.outlined {
    background: ${colors.light300};
    border-radius: 4px;
  }
  .MuiButtonBase-root {
    padding: 9px;
  }
`

export const Switch = ({
  color = 'primary',
  variant = 'standard',
  label,
  labelPlacement = 'end',
  value,
  // eslint-disable-next-line no-unused-vars
  error, // ignore incoming errors
  ...props
}) => {
  
  return (
    <StyledFormControlLabel
      value={value}
      className={variant}
      control={
        <MuiSwitch
          disableRipple
          focusVisibleClassName="focusVisible"
          sx={{
            ...styles
          }}
          color={color}
          {...props}
        />
      }
      label={label}
      labelPlacement={labelPlacement}
    />
  )
}

Switch.propTypes = {
  color: PropTypes.oneOf(['primary', 'secondary']),
  variant: PropTypes.oneOf(['standard', 'outlined']),
  label: PropTypes.node,
  labelPlacement: PropTypes.oneOf(['bottom', 'end', 'start', 'top'])
}
