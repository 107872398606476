import React, { useCallback, useEffect, useState } from 'react'
import { DataTable, Flex } from '@condofy/components'
import {
  Button,
  Card,
  CardHeader,
  CardContent,
  Pagination,
  MenuItem,
  Select,
  SearchTextField
} from '@condofy/components'
import { TicketStatusChip } from './TicketStatusChip'
import { useUserContext } from '@condofy/next/store/user'

export const TicketList = (props) => {
  const [page, setPage] = useState(1)
  const [status, setStatus] = useState('')
  const [search, setSearch] = useState('')
  const {
    state: {
      currentCondominium,
      ticket: { items, metadata }
    },
    actions: { listTicket, openCreateTicketModal, openEditTicketModal }
  } = useUserContext()
  const stableListTicket = useCallback(listTicket, [])

  useEffect(() => {
    if (currentCondominium.id)
      stableListTicket({
        currentCondominium,
        page,
        statuses: status,
        generalSearch: search,
        RecordsPerPage: 10
      })
  }, [currentCondominium, page, status, stableListTicket, search])

  const handleStatusChange = (value) => {
    setStatus(value)
    setPage(1)
  }

  const handleSearchChange = (value) => {
    setSearch(value)
    setPage(1)
  }

  const columns = [
    {
      headerName: 'Número',
      field: 'id'
    },
    {
      headerName: 'Descrição',
      field: 'subject'
    },
    {
      headerName: 'Requisitante',
      field: 'requester'
    },
    {
      headerName: 'Status',
      renderCell: ({ row }) => <TicketStatusChip status={row.status} />
    }
  ]

  const cleanFilters = () => {
    handleStatusChange('')
    handleSearchChange('')
  }

  const handleSearch = (event) => {
    handleSearchChange(event.target.value)
  }

  return (
    <>
      <Card {...props}>
        <CardHeader
          title='Chamados'
          action={
            <>
              <Button onClick={cleanFilters} disabled={!status && !search}>
                Limpar Filtros
              </Button>
              <Select
                select
                sx={{
                  minWidth: '232px'
                }}
                label='Filtrar chamados'
                value={status}
                onChange={(event) => handleStatusChange(event.target.value)}
              >
                <MenuItem value=''>Todos</MenuItem>
                <MenuItem value='new'>Novo</MenuItem>
                <MenuItem value='open'>Aberto</MenuItem>
                <MenuItem value='pending'>Pendente</MenuItem>
                <MenuItem value='solved'>Resolvido</MenuItem>
                <MenuItem value='closed'>Fechado</MenuItem>
              </Select>
              <SearchTextField
                size='small'
                onChange={handleSearch}
                value={search}
              />
              <Button onClick={openCreateTicketModal}>+ Novo</Button>
            </>
          }
        />
        <CardContent>
          <DataTable
            size='small'
            variant='borderless'
            loading={metadata.loading}
            columns={columns}
            rows={items}
            onRowClick={openEditTicketModal}
          />
        </CardContent>
      </Card>
      <Flex width='100%' justify='flex-end'>
        <Pagination
          page={page}
          totalResults={metadata.totalRecords}
          pageSize={metadata.recordsPerPage}
          onChange={(event, page) => setPage(page)}
          disabled={metadata.loading}
        />
      </Flex>
    </>
  )
}
