import React, { useState, useEffect, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import {
  Button,
  Card,
  CardHeader,
  CardContent,
  TextField,
  Form,
  FormRow,
  FormSectionHeader,
  FormSectionTitle,
  FormSection,
  Flex,
  MenuItem,
  DatePicker,
  LoadingButton,
  Page,
  PageTitle,
  PageHeader,
  Calendar
} from '@condofy/components'
import { fetchIntervalRestrictionsList } from '@condofy/next/service/restriction'
import { Link, TimeSelector, ExpandableText } from '@condofy/next/components'
import {
  createScheduling,
  fetchSchedulingList
} from '@condofy/next/service/scheduling'
import { fetchSchedulingArea } from '@condofy/next/service/area'
// import '@condofy/components/Calendar/react-big-calendar.css'
import 'moment/locale/pt-br'
import { useSnackbar } from '@condofy/next/hooks/useSnackbar'
import { useUserContext } from '@condofy/next/store/user'
import { track } from '@condofy/next/service/segment'

const mergeTime = (date, time) => {
  const newTime = new Date(time)
  let newDate = new Date(date)

  newDate.setHours(newTime.getHours())
  newDate.setMinutes(newTime.getMinutes())
  newDate.setSeconds(newTime.getSeconds())

  return newDate
}

export const NewReservationPage = () => {
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const {
    state: {
      currentCondominium,
      id: userId,
      area: { items: areaList, metadata: areaMetadata }
    },
    actions: { listAreas }
  } = useUserContext()
  const stableListAreas = useCallback(listAreas, [])
  const stableFetchSchedulingArea = useCallback(fetchSchedulingArea, [])

  const [loading, setLoading] = useState(false)
  const [schedulingList, setSchedulingList] = useState([])
  const [name, setName] = useState('')
  const [areaId, setAreaId] = useState('')
  const [areaDetails, setAreaDetails] = useState({})
  const [date, setDate] = useState(new Date())
  const [initialTime, setInitialTime] = useState('2020-07-23T00:00:00.492Z')
  const [finalTime, setFinalTime] = useState('2020-07-23T23:00:00.492Z')
  const [events, setEvents] = useState([])
  const [datesList, setDatesList] = useState([])
  const [blockedList, setBlocketList] = useState([])

  useEffect(() => {
    if (currentCondominium.id) {
      stableListAreas({ currentCondominium })
    }
  }, [currentCondominium, stableListAreas])

  useEffect(() => {
    fetchSchedulingList({
      GeneralSearch: areaId,
      currentCondominium,
      onSuccess: (response) => {
        setSchedulingList(response.data)
      },
      onError: (error) => {
        const errorMessage =
          error.response.data.reports[0].message || error.message
        enqueueSnackbar(errorMessage, { variant: 'error' })
      }
    })
  }, [areaId, enqueueSnackbar, currentCondominium])

  useEffect(() => {
    fetchIntervalRestrictionsList({
      currentCondominium,
      onSuccess: ({ data }) => {
        setDatesList(data)
      },
      onError: (error) => {
        const errorMessage =
          error.response.data.reports[0].message || error.message
        enqueueSnackbar(errorMessage, { variant: 'error' })
      }
    })
  }, [areaId, currentCondominium])

  useEffect(() => {
    setEvents(
      schedulingList.map((scheduling) => ({
        start: new Date(scheduling.initialDate),
        end: new Date(scheduling.finalDate),
        title: scheduling.name
      }))
    )
  }, [schedulingList])

  useEffect(() => {
    if (areaId) {
      stableFetchSchedulingArea({
        currentCondominium,
        schedulingAreaId: areaId
      }).then((response) => {
        setAreaDetails(response?.data?.data)
      })
    } else {
      setAreaDetails({})
    }
  }, [areaId, setAreaDetails, stableFetchSchedulingArea, currentCondominium])

  const areaChanged = (event) => setAreaId(event.target.value)

  const handleSubmit = () => {
    const initialDate = mergeTime(date, initialTime)
    const finalDate = mergeTime(date, finalTime)
    setLoading(true)
    createScheduling({
      currentCondominium,
      scheduling: {
        name,
        areaId,
        userId: userId,
        initialDate: initialDate.toISOString(),
        finalDate: finalDate.toISOString()
      },
      onSuccess: () => {
        setLoading(false)
        track(window.analytics, 'Booking Created', {
          name,
          areaId,
          userId: userId,
          initialDate: initialDate.toISOString(),
          finalDate: finalDate.toISOString()
        })
        enqueueSnackbar('Reserva criada com sucesso!', { variant: 'success' })
        navigate('/schedule')
      },
      onError: (error) => {
        setLoading(false)
        const errorMessage =
          error.response.data.reports[0].message || error.message
        enqueueSnackbar(errorMessage, { variant: 'error' })
      }
    })
  }

  useEffect(() => {
    if (datesList.length) {
      let days = []
      days.push(
        datesList.map((date) =>
          transformDates(date.initialDate, date.finalDate)
        )
      )

      const allDates = [].concat(...days[0])

      setBlocketList(allDates)
    }
  }, [datesList])

  const transformDates = (startDate, endDate) => {
    const dates = []
    const startTimestamp = new Date(startDate).getTime()
    const endTimestamp = new Date(endDate).getTime()
    const difference = endTimestamp - startTimestamp

    for (let i = 0; i <= difference; i += 86400000) {
      dates.push(moment(new Date(startTimestamp + i)).format('YYYY-MM-DD'))
    }

    return dates
  }

  return (
    <Page>
      <PageHeader style={{ justifyContent: 'space-between' }}>
        <PageTitle title='Reservas > Nova Reserva' icon='schedule' />
        <Link to='/schedule'>
          <Button color='secondary'>Voltar para o início</Button>
        </Link>
      </PageHeader>
      <Card>
        <CardHeader title='Nova Reserva' />
        <CardContent>
          <Form>
            <FormSection>
              <FormSectionHeader>
                <FormSectionTitle>Informações da Reserva</FormSectionTitle>
              </FormSectionHeader>
              <FormRow>
                <TextField
                  value={name}
                  label='Nome da reserva'
                  onChange={(event) => setName(event.target.value)}
                />
              </FormRow>
              <FormRow>
                <TextField
                  select
                  value={areaId}
                  disabled={areaMetadata.loading}
                  label='Area'
                  onChange={areaChanged}
                >
                  {areaList.map((schedulingArea) => (
                    <MenuItem key={schedulingArea.id} value={schedulingArea.id}>
                      {schedulingArea.name}
                    </MenuItem>
                  ))}
                </TextField>
              </FormRow>
            </FormSection>

            <FormSection>
              <FormSectionHeader>
                <FormSectionTitle>Informações da Reserva</FormSectionTitle>
              </FormSectionHeader>
              <FormRow></FormRow>
              <FormRow>
                <DatePicker
                  autoOk
                  value={date}
                  label='Data da Reserva'
                  placeholder='Escolha a data'
                  onChange={setDate}
                  disablePast={true}
                  shouldDisableDate={(dateParam) => {
                    const day = dateParam.toISOString().slice(0, 10)
                    return blockedList.find((element) => element === day)
                      ? true
                      : false
                  }}
                />
                <TimeSelector
                  required
                  value={initialTime}
                  label='Início'
                  onChange={(event) => setInitialTime(event.target.value)}
                />
                <TimeSelector
                  select
                  required
                  value={finalTime}
                  label='Fim'
                  onChange={(event) => setFinalTime(event.target.value)}
                />
              </FormRow>
            </FormSection>
            <FormSection>
              <FormSectionHeader>
                <FormSectionTitle>Disponibilidade de Reserva</FormSectionTitle>
              </FormSectionHeader>
              <FormRow>
                <Calendar
                  defaultDate={new Date()}
                  defaultView='week'
                  views={['week']}
                  events={events}
                  datePickerProps={{ disablePast: true }}
                />
              </FormRow>
            </FormSection>
            {areaDetails.regulation && (
              <FormSection>
                <FormSectionHeader>
                  <FormSectionTitle>Regulamento</FormSectionTitle>
                </FormSectionHeader>
                <FormRow>
                  <ExpandableText maxHeight={100}>
                    {areaDetails.regulation}
                  </ExpandableText>
                </FormRow>
              </FormSection>
            )}
          </Form>
          <Flex spacing={2}>
            <Link to='/schedule'>
              <Button color='secondary'>Cancelar</Button>
            </Link>
            <LoadingButton
              loading={loading}
              disabled={!(name && areaId && date && initialTime && finalTime)}
              onClick={handleSubmit}
            >
              Reservar
            </LoadingButton>
          </Flex>
        </CardContent>
      </Card>
    </Page>
  )
}
