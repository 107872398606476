import React from 'react'
import { Select } from '@condofy/components'
import { MenuItem } from '@mui/material'

const alertType = [
  {
    value: 60,
    label: '1 hora antes'
  },
  {
    value: 1440,
    label: '1 dia antes'
  },
  {
    value: 10080,
    label: '1 semana antes'
  }
]

export const ReminderSelect = ({ label = 'Alerta', ...props }) => {
  return (
    <Select label={label} {...props}>
      {alertType.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </Select>
  )
}
