export const createInitialState = {
  metadata: {
    loading: false
  },
  error: null
}

export class Create {
  constructor({ key, statePropName = 'create' }) {
    if (!key) {
      throw Error('Create constructor must be passed a key')
    }
    this._stateProp = statePropName
    this._key = key
    this._action = {
      create: `LOAD_${key}_CREATE`,
      pending: `LOAD_${key}_CREATE_PENDING`,
      error: `LOAD_${key}_CREATE_ERROR`
    }
    this._initialState = createInitialState
  }

  get initialState() {
    return this._initialState
  }

  set initialState(initialState) {
    this._initialState = initialState
  }

  get actionTypes() {
    return this._action
  }

  reducers() {
    return {
      [this._action.create]: (state = this._initialState) => {
        return {
          ...state,
          [this._stateProp]: {
            ...state[this._stateProp]
          }
        }
      },
      [this._action.success]: (state = this._initialState, action) =>
        this._successReducer(state, action),
      [this._action.pending]: (state = this._initialState, action) =>
        this._pendingReducer(state, action),
      [this._action.error]: (state = this._initialState, action) =>
        this._errorReducer(state, action)
    }
  }

  middleware(createFunc) {
    if (!createFunc) {
      throw Error('Middleware must receive a valid function')
    }
    return {
      [this._action.create]: this._create(createFunc)
    }
  }

  actions(state, dispatch) {
    return (data) => dispatch({ type: this._action.create, payload: data })
  }

  _pending(dispatch, payload) {
    dispatch({ type: this._action.pending, payload })
  }

  _create(callback) {
    return async (dispatch, payload) => {
      this._pending(dispatch, true)
      try {
        const response = await callback(payload)
        return response.data
      } catch (error) {
        dispatch({ type: this._action.error, payload: error })
        throw error
      } finally {
        this._pending(dispatch, false)
      }
    }
  }

  _pendingReducer(state, action) {
    return {
      ...state,
      [this._stateProp]: {
        ...state[this._stateProp],
        metadata: {
          ...state[this._stateProp]?.metadata,
          loading: action.payload
        }
      }
    }
  }

  _errorReducer(state, action) {
    return {
      ...state,
      [this._stateProp]: {
        ...state[this._stateProp],
        error: action.payload
      }
    }
  }
}
