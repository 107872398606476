import React from 'react'
import { Page, PageTitle } from '@condofy/components'
import { StorageSection } from '../modules/storage/Section'

export const FilesPage = () => {
  return (
    <Page>
      <PageTitle title='Arquivos' icon='files' />
      <StorageSection />
    </Page>
  )
}
