import React from 'react'
import { Avatar, SmallBody, Button } from '@condofy/components'
import { useDispatch } from 'react-redux'
import { toggleModal } from 'Actions/modals'
import { PROCESS_UNIT_MODAL, DETAILS_UNIT_MODAL } from 'Const'
import { useUserContext } from '@condofy/next/store/user'
import { DataCard } from '@condofy/next/components/'

export const UnitCard = ({ unit, condominium, search, page, ...props }) => {
  const dispatch = useDispatch()
  const {
    state: { isSuperAdmin }
  } = useUserContext()

  const onEdit = (unit) => {
    isSuperAdmin
      ? dispatch(
          toggleModal(PROCESS_UNIT_MODAL, true, {
            unit,
            condominium,
            search,
            page
          })
        )
      : dispatch(toggleModal(DETAILS_UNIT_MODAL, true, { unit, condominium }))
  }

  const userCount = (unit.users && unit.users.length) || 0

  return (
    <DataCard
      title={unit.block ? `${unit.block} - ${unit.number}` : unit.number}
      avatar={<Avatar size='large'>{unit.number}</Avatar>}
      actions={
        <Button fullWidth color='secondary' onClick={() => onEdit(unit)}>
          {isSuperAdmin ? 'Editar' : 'Visualizar'}
        </Button>
      }
      {...props}
    >
      <SmallBody color='textSecondary'>{userCount} moradores</SmallBody>
    </DataCard>
  )
}
