import { condofyProtected } from './instance'

export const fetchRestrictionList = ({
  currentCondominium,
  onError: emitErrorEvent = (e) => e,
  onSuccess: emitSuccessEvent = (e) => e
}) => {
  condofyProtected
    .get(`/v1/condominiums/${currentCondominium.id}/scheduling-restriction`)
    .then(({ data }) => emitSuccessEvent(data))
    .catch((error) => emitErrorEvent(error))
}

export const createRestrictionPromise = ({ currentCondominium, unitId }) => {
  return condofyProtected.post(
    `/v1/condominiums/${currentCondominium.id}/scheduling-restriction`,
    { unitId }
  )
}

export const createRestriction = ({
  currentCondominium,
  unitId,
  onError: emitErrorEvent = (e) => e,
  onSuccess: emitSuccessEvent = (e) => e
}) => {
  createRestrictionPromise({ currentCondominium, unitId })
    .then(({ data }) => emitSuccessEvent(data))
    .catch((error) => emitErrorEvent(error))
}

export const removeRestriction = ({
  currentCondominium,
  restrictionId,
  onError: emitErrorEvent = (e) => e,
  onSuccess: emitSuccessEvent = (e) => e
}) => {
  condofyProtected
    .delete(
      `/v1/condominiums/${currentCondominium.id}/scheduling-restriction/${restrictionId}`
    )
    .then(({ data }) => emitSuccessEvent(data))
    .catch((error) => emitErrorEvent(error))
}

export const fetchIntervalRestrictionsList = ({
  currentCondominium,
  onError: emitErrorEvent = (e) => e,
  onSuccess: emitSuccessEvent = (e) => e
}) => {
  condofyProtected
    .get(
      `/v1/condominiums/${currentCondominium.id}/scheduling/interval-restrictions`
    )
    .then(({ data }) => emitSuccessEvent(data))
    .catch((error) => emitErrorEvent(error))
}

export const createIntervalRestrictionsPromise = ({
  currentCondominium,
  initialDate,
  finalDate
}) => {
  return condofyProtected.post(
    `/v1/condominiums/${currentCondominium.id}/scheduling/interval-restrictions`,
    { initialDate, finalDate }
  )
}

export const createIntervalRestrictions = ({
  currentCondominium,
  onError: emitErrorEvent = (e) => e,
  onSuccess: emitSuccessEvent = (e) => e
}) => {
  createRestrictionPromise({ currentCondominium })
    .then(({ data }) => emitSuccessEvent(data))
    .catch((error) => emitErrorEvent(error))
}

export const removeIntervalRestrictions = ({
  currentCondominium,
  id,
  onError: emitErrorEvent = (e) => e,
  onSuccess: emitSuccessEvent = (e) => e
}) => {
  condofyProtected
    .delete(
      `/v1/condominiums/${currentCondominium.id}/scheduling/interval-restrictions/${id}`
    )
    .then(({ data }) => emitSuccessEvent(data))
    .catch((error) => emitErrorEvent(error))
}
