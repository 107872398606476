import { callApi } from 'Middleware/api'
import { normalizeObject } from '~/lib/util/normalizeObject'
import { condofyProtected } from '@condofy/next/service/instance'

export const PROCESS_CONDOMINIUM_SUCCESS = 'PROCESS_CONDOMINIUM_SUCCESS'
export const PROCESS_CONDOMINIUM = 'PROCESS_CONDOMINIUM'
export const PROCESS_CONDOMINIUM_FAIL = 'PROCESS_CONDOMINIUM_FAIL'
export const CONDONIUM_FILED_CHANGE = 'CONDONIUM_FILED_CHANGE'
export const INITIAL_CONDOMINIUM_STATE = 'INITIAL_CONDOMINIUM_STATE'
export const SET_BANK_LIST = 'SET_BANK_LIST'
export const SET_CONDOMINIUMS_LIST = 'SET_CONDOMINIUMS_LIST'
export const SET_CONDOMINIUM = 'SET_CONDOMINIUM'
export const SET_CONDOMINIUM_SEARCH = 'SET_CONDOMINIUM_SEARCH'
export const SET_UNITS_LIST = 'SET_UNITS_LIST'
export const CLEAR_UNITS_LIST = 'CLEAR_UNITS_LIST'
export const SET_CONDOMINIUMS_META = 'SET_CONDOMINIUMS_META'
export const SET_CONDOMINIUM_ERROR = 'SET_CONDOMINIUM_ERROR'

export const processCondominiumStart = () => ({
  type: PROCESS_CONDOMINIUM
})

export const processCondominiumSuccess = () => ({
  type: PROCESS_CONDOMINIUM_SUCCESS
})

export const processCondominiumFail = (errors) => ({
  type: PROCESS_CONDOMINIUM_FAIL,
  errors
})

export const changeCondominiumField = (name, value) => ({
  type: CONDONIUM_FILED_CHANGE,
  name,
  value
})

export const initialCondominiumState = () => ({
  type: INITIAL_CONDOMINIUM_STATE
})

export const setBankList = (bankList) => ({
  type: SET_BANK_LIST,
  bankList
})

export const setCondominiums = (condominiumsList) => ({
  type: SET_CONDOMINIUMS_LIST,
  condominiumsList
})

export const setCondominiumsMeta = (payload) => ({
  type: SET_CONDOMINIUMS_META,
  payload
})

export const setCondominium = (condominium) => ({
  type: SET_CONDOMINIUM,
  condominium
})

export const setCondominiumSearch = (search) => ({
  type: SET_CONDOMINIUM_SEARCH,
  search
})

export const setUnits = (condoId, units) => ({
  type: SET_UNITS_LIST,
  condoId,
  units
})

export const clearUnitsOfCondo = (condoId) => ({
  type: CLEAR_UNITS_LIST,
  condoId
})

export const setCondominiumError = (errors) => ({
  type: SET_CONDOMINIUM_ERROR,
  errors
})

export const getUnitsByCondo = (condoId) => (dispatch) => {
  return callApi(`/v1/condominiums/${condoId}/units?includeUsers=true`)
    .then((resp) => resp.json()) // TODOR handle errors properly
    .then((_data) => {
      const { data } = normalizeObject(_data)
      dispatch(setUnits(condoId, data))
    })
    .catch(() => {
      dispatch(setUnits(condoId, 'error'))
    })
}

export const getCondominiumsList = (page = 1) => (dispatch, getState) => {
  const { condominiumSection } = getState()

  condofyProtected
    .post(`/v1/condominiums/search?PageNumber=${page}&includeManagers=true`, {
      generalSearch: condominiumSection.search
    })
    .then((response) => {
      dispatch(setCondominiums(response.data.data))

      dispatch(
        setCondominiumsMeta({
          ...response.data.paging,
          pageNumber:
            response.data.paging.pageNumber === 0
              ? 1
              : response.data.paging.pageNumber
        })
      )
    })
}

export const createNewCondominium = () => (dispatch, getState) => {
  dispatch(processCondominiumStart())
  const { condominiumSection } = getState()
  const { condominium } = condominiumSection

  condominium.cnpj = condominium.cnpj.replace(/[//.-]/gi, '')
  condominium.zipCode = condominium.zipCode.replace('-', '').replace('.', '')

  return condofyProtected
    .post('/v1/condominiums', condominium)
    .then(() => dispatch(getCondominiumsList()))
}

export const deleteCondo = (id) => (dispatch) => {
  // TODOR handle errors properly
  return callApi(`/v1/condominiums/${id}`, {
    method: 'DELETE'
  }).then(() => dispatch(getCondominiumsList()))
}
