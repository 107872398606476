import { registerFull } from '@condofy/next/service/user'
import { pending, store } from './index'

// ACTION TYPES
const USER_CREATE = 'USER_CREATE'
const USER_CREATE_MODAL_VISIBLE = 'USER_CREATE_MODAL_VISIBLE'

export const createActionTypes = {
  USER_CREATE,
  USER_CREATE_MODAL_VISIBLE
}

// ACTIONS
export const createActions = (state, dispatch) => ({
  createUser: (payload) => dispatch({ type: USER_CREATE, payload }),

  closeCreateUserModal: () =>
    dispatch({ type: USER_CREATE_MODAL_VISIBLE, payload: false }),

  openCreateUserModal: () =>
    dispatch({ type: USER_CREATE_MODAL_VISIBLE, payload: true })
})

// MIDDLEWARE
export const create = async (dispatch, payload) => {
  try {
    pending(dispatch, true)
    return registerFull({ payload })
  } finally {
    pending(dispatch, false)
  }
}

// REDUCER
export const createReducer = (state, action) => {
  switch (action.type) {
    case USER_CREATE_MODAL_VISIBLE:
      return store(state, {
        createModalOpen: action.payload
      })

    default:
      return state
  }
}
