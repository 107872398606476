import React from 'react'
import { useUserContext } from '@condofy/next/store/user'
import { DataTable, Flex } from '@condofy/components'
import { Card, CardHeader, CardContent } from '@condofy/components'

import { useDispatch } from 'react-redux'
import { toggleModal } from 'Actions/modals'
import { PROCESS_UNIT_MODAL, DETAILS_UNIT_MODAL } from 'Const'

export const UnitsList = ({
  items = [],
  condominium,
  search,
  page,
  metadata
}) => {
  const dispatch = useDispatch()
  const {
    state: { isSuperAdmin }
  } = useUserContext()

  const handleClick = (row) => {
    isSuperAdmin
      ? dispatch(
          toggleModal(PROCESS_UNIT_MODAL, true, {
            unit: row,
            condominium,
            search,
            page
          })
        )
      : dispatch(
          toggleModal(DETAILS_UNIT_MODAL, true, {
            unit: row,
            condominium
          })
        )
  }

  const columns = [
    { field: 'block', headerName: 'Bloco' },
    { field: 'number', headerName: 'Número' },
    { field: 'observations', headerName: 'Observação' },
    {
      field: 'users',
      headerName: 'Moradores',
      renderCell: ({ row }) => <div>{row.users.length}</div>
    }
  ]

  return (
    <Card>
      <CardHeader title='Unidades' />
      <CardContent>
        <Flex direction='column' spacingY={3}>
          <DataTable
            variant='borderless'
            size='small'
            columns={columns}
            loading={metadata.loading}
            rows={items}
            onRowClick={handleClick}
          />
        </Flex>
      </CardContent>
    </Card>
  )
}
