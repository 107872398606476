const plans = [
  {
    label: 'Free',
    value: '1'
  },
  {
    label: 'Starter',
    value: '2'
  },
  {
    label: 'Premium',
    value: '3'
  }
]

module.exports = {
  plans,
  getValues() {
    return plans.map((t) => t.value)
  }
}
