import React from 'react'
import styled from 'styled-components'
import { Typography, colors, theme, Icon } from '@condofy/components'
import { PollOptionsConfig } from './PollOptions'

const VoteWrapper = styled.span`
  text-align: center;

  .MuiTypography-root {
    color: ${colors.darkBlue500};
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .MuiSvgIcon-root {
    fill: ${colors.darkBlue500};
    font-size: 2.4rem;
    margin-bottom: ${theme.spacing(2)};
  }
`

export const Vote = ({ vote }) => {
  const { label, icon } = PollOptionsConfig[vote] || {}

  return (
    <VoteWrapper>
      <Icon icon={icon} />
      <Typography component='div' variant='h6'>
        {label}
      </Typography>
    </VoteWrapper>
  )
}
