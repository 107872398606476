import React, { useEffect, useState } from 'react'
import {
  Button,
  Icon,
  typography,
  Tooltip,
  Popover,
  SearchTextField,
  List,
  ListItem,
  colors
} from '@condofy/components'
import { useUserContext } from '@condofy/next/store/user'
import { track } from '@condofy/next/service/segment'
import styled from 'styled-components'
import { SidebarNavItemText } from './SidebarNavItemText'
import { Box } from '@mui/material'
// import { withStyles } from 'tss-react/mui';

const StyledIcon = styled(Icon)``

const SelectorButton = styled(Button)`
  font-family: ${typography.type.primary};
  padding: 12px 12px 12px 30px!important;
  transition: padding 0.7s ease!important;
  width: 100%!important;
  display: inline-flex!important;
  justify-content: space-between!important;

  &,
  &:hover,
  &:focus {
    background-color: transparent;
    color: inherit;
  }

  ${StyledIcon} {
    margin-right: 0;
    transition: margin 0.7s ease;
  }

  ${({ collapsed }) =>
    collapsed &&
    `
    padding-left: 12px;

    ${StyledIcon} {
      margin-right: 10px;
    }
  `}
`
/* const StyledTooltip = withStyles({
  tooltipPlacementBottom: {
    margin: '0 0 0 20px'
  }
})(Tooltip) */

const tooltipStyles = {
  '&.MuiTooltip-tooltipPlacementBottom' : {
    margin: '0 0 0 20px'
  }
}


const StyledListIcon = styled(ListItem)`
  &.MuiListItem-root:not(.Mui-selected):hover {
    background-color: ${colors.light300};
    cursor: pointer;
  }
`

const Selector = ({ value, tooltip = false, collapsed = false, ...props }) => (
  <Box width='100%'>
    <Tooltip
      sx = {{ ...tooltipStyles }}
      title={tooltip ? value : ''}
      placement='bottom-start'
      disableTouchListener
    >
      <SelectorButton color='clear' collapsed={collapsed ? 1 : 0} {...props}>
        <SidebarNavItemText collapsed={collapsed}>{value}</SidebarNavItemText>
        <StyledIcon icon='expand_all' />
      </SelectorButton>
    </Tooltip>
  </Box>
)

export const CondominiumSelect = ({ collapsed = false, ...props }) => {
  const {
    state: { condominiumList, currentCondominium, isSuperAdmin },
    actions
  } = useUserContext()
  const administratorUser = isSuperAdmin
  const [searchTerm, setSearchTerm] = useState('')
  const [filteredCondominiumList, setFilteredCondominiumList] = useState([])

  const handleChange = (value) => {
    track(window.analytics, 'Condominium Switched', {
      condominium_id: value.id,
      Chave: value.code,
      name: value.name,
      plan: value.plan.name
    })
    actions.updateCurrentCondominium(value)
  }

  const handleSearch = (event) => {
    setSearchTerm(event.target.value)
  }

  useEffect(() => {
    if (searchTerm) {
      const list = condominiumList.filter(
        (c) => c.name.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1
      )
      setFilteredCondominiumList(list)
    } else {
      setFilteredCondominiumList(condominiumList)
    }
  }, [condominiumList, searchTerm])

  const selectedValue =
    currentCondominium.isInactive === false
      ? currentCondominium.name
      : `(Inativo) ${currentCondominium.name}`

  return (
    <Box marginTop={2} marginBottom={3} {...props}>
      <Popover
        Button={
          <Selector collapsed={collapsed} value={selectedValue} tooltip />
        }
      >
        <Box
          display='flex'
          alignItems='stretch'
          padding={2}
          flexDirection='column'
        >
          <SearchTextField value={searchTerm} onChange={handleSearch} />
        </Box>
        <List closeOnClick>
          {filteredCondominiumList.map((condominium) => (
            <StyledListIcon
              key={condominium.id}
              onClick={() => handleChange(condominium)}
              disabled={condominium.isInactive === true && !administratorUser}
              selected={condominium.id === currentCondominium.id}
            >
              {condominium.isInactive === false
                ? condominium.name
                : `(Inativo) ${condominium.name}`}
            </StyledListIcon>
          ))}
        </List>
      </Popover>
    </Box>
  )
}
