import React from 'react'
import {
  Avatar,
  Flex,
  Card,
  CardContent,
  TextHighlight,
  Button,
  pxToRem,
  theme,
  Select,
  MenuItem,
  TextField
} from '@condofy/components'
import styled from 'styled-components'
import { useUserContext } from '@condofy/next/store/user'
import { useCommunityContext } from '@condofy/next/store/community'
import { createComment } from '@condofy/next/service/community'
import { MD5 } from '@condofy/next/service/md5'
import { useSnackbar } from '@condofy/next/hooks/useSnackbar'
import { track } from '@condofy/next/service/segment'

const StyledSelect = styled(Select)`
  min-width: 220px;
`

const StyledAvatar = styled(Avatar)`
  &.MuiAvatar-root {
    margin-right: ${theme.spacing(3)} !important;
  }
`

const StyledTextField = styled(TextField)`
  &.MuiTextField-root {
    margin: ${-theme.spacing(2 / 2)} 0 ${theme.spacing(2)}
      ${-theme.spacing(3 / 2)} !important;
  }

  .MuiInputBase-input {
    font-size: ${pxToRem(20)}rem !important;
    padding: ${theme.spacing(2 / 2)} ${theme.spacing(3 / 2)} !important;
  }
  .MuiOutlinedInput {
    &-notchedOutline {
      border-color: transparent !important;
      background-color: transparent !important;
      z-index: 0;
    }
  }
`

export const subjectType = [
  { id: 1, name: 'Dúvidas' },
  { id: 2, name: 'Compartilhar algo legal' },
  { id: 3, name: 'Anúncio' },
  { id: 4, name: 'Outros' }
]

export const AddCommentCard = () => {
  const {
    state: { currentCondominium, email, id }
  } = useUserContext()

  const {
    actions: { loadFeedList }
  } = useCommunityContext()
  const { enqueueSnackbar } = useSnackbar()

  const gravatarId = MD5(email)
  const gravatarImage = `https://www.gravatar.com/avatar/${gravatarId}?d=retro`

  const [newComment, setNewComment] = React.useState({
    description: '',
    subject: ''
  })

  const handleAddComment = () => {
    createComment({ currentCondominium, payload: { ...newComment } })
      .then(() => {
        setNewComment({
          description: '',
          subject: ''
        })
        loadFeedList({ currentCondominium })
        track(window.analytics, 'Community Publish Comment', {
          condominiumId: currentCondominium.id,
          subject: newComment.subject,
          userId: id
        })
      })
      .catch(() =>
        enqueueSnackbar('Erro ao criar postagem.', { variant: 'error' })
      )
  }

  const handleChange = (event) => {
    const { name, value } = event.target
    setNewComment({ ...newComment, [name]: value })
  }

  return (
    <Card>
      <CardContent>
        <Flex direction='column' spacingY={3} width='100%'>
          <Flex>
            <StyledAvatar src={gravatarImage} />
            <StyledTextField
              name='description'
              value={newComment.description}
              onChange={handleChange}
              multiline
              rows={2}
              flex='1'
              placeholder='No que você está pensando?'
            />
          </Flex>
          <div>
            <Flex spacingX={2}>
              <TextHighlight color='secondary' flex='1'>
                Antes de postar leia as{' '}
                <a href='/'>politicas de uso e regras da plataforma</a>
              </TextHighlight>
              <StyledSelect
                label='Selecione o assunto'
                name='subject'
                value={newComment.subject}
                onChange={handleChange}
              >
                {subjectType.map((type) => (
                  <MenuItem key={type.id} value={type.id}>
                    {type.name}
                  </MenuItem>
                ))}
              </StyledSelect>
              <Button onClick={handleAddComment} color='primary'>
                Publicar
              </Button>
            </Flex>
          </div>
        </Flex>
      </CardContent>
    </Card>
  )
}
