import React, { useEffect, useState } from 'react'
import {
  Heading6,
  Button,
  Avatar,
  theme,
  colors,
  SmallBody,
  Flex
} from '@condofy/components'
import { Box } from '@mui/material'
import styled from 'styled-components'
import _ from 'lodash'
import { PollOptions } from './PollOptions'

const VoteAvatar = styled(Avatar)`
  &.MuiAvatar-root {
    height: ${theme.spacing(3)};
    margin-right: ${theme.spacing(1)};
    width: ${theme.spacing(3)};

    ${({ $vote }) =>
      $vote === PollOptions.YES && `background-color: ${colors.success100};`}
    ${({ $vote }) =>
      $vote === PollOptions.NO && `background-color: ${colors.danger100};`}
  ${({ $vote }) =>
      $vote === PollOptions.ABSTAIN &&
      `background-color: ${colors.brandBlue200};`}
  }
`

const CommentWrapper = styled.div`
  align-items: center;
  display: flex;
  width: 100%;
`

const Comment = ({ vote, comment, user }) => {
  return (
    <CommentWrapper>
      <VoteAvatar label='&nbsp;' $vote={vote} />
      <SmallBody>
        {comment ? comment : 'Voto sem comentário.'} {user && `- ${user.name}`}
      </SmallBody>
    </CommentWrapper>
  )
}

export const PollComments = ({ votes = [] }) => {
  const [showAll, setShowAll] = useState(false)
  const [visibleVotes, setVisibleVotes] = useState([])

  const handleShowMore = () => {
    setShowAll(true)
    setVisibleVotes(votes)
  }

  useEffect(() => {
    const show = votes.length < 3
    setShowAll(show)
    setVisibleVotes(votes.slice(0, show ? votes.length : 3))
  }, [votes])

  return !_.isEmpty(votes) ? (
    <Flex direction='column' spacingY={2}>
      <Heading6>Comentários</Heading6>
      {visibleVotes.map((vote) => (
        <Comment key={vote.vote} {...vote} />
      ))}
      {!showAll && (
        <Box textAlign='center'>
          <Button color='light' onClick={handleShowMore}>
            Mais comentários
          </Button>
        </Box>
      )}
    </Flex>
  ) : (
    <></>
  )
}
