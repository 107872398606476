import React, { useCallback, useEffect } from 'react'
import { isMobile } from 'react-device-detect'
import { useAppContext } from '@condofy/next/store/app'

export const MobileDeviceChecker = () => {
  const {
    actions: { openMobileDeviceModal }
  } = useAppContext()
  const stableOpenMobileDeviceModal = useCallback(openMobileDeviceModal, [])

  useEffect(() => {
    if (isMobile) {
      stableOpenMobileDeviceModal()
    }
  }, [stableOpenMobileDeviceModal])

  return <></>
}
