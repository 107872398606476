import { CircularProgress } from '@mui/material'
import React from 'react'
import { IconButton } from './IconButton'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const StyledProgress = styled(CircularProgress)`
  width: 16px !important;
  height: 16px !important;
`

export const LoadingIconButton = ({ loading, disabled, children, ...args }) => {
  return (
    <IconButton disabled={disabled || loading} {...args}>
      {loading ? <StyledProgress /> : children}
    </IconButton>
  )
}
LoadingIconButton.propTypes = {
  loading: PropTypes.bool
}
