import React from 'react'
import styled from 'styled-components'
import {
  Card,
  CardContent,
  typography,
  Chip,
  Flex,
  theme,
  colors,
  Heading2,
  Typography
} from '@condofy/components'
import { PremiumToggle } from './PremiumToggle'

const SyledCard = styled(Card)`
  background: ${({ backgroundImage }) =>
    `#fff url(${backgroundImage}) no-repeat scroll center center`};
  background-size: cover;
  height: 400px;
`

const Wrapper = styled(Flex)`
  font-family: ${typography.type.tertiary};
  color: ${colors.dark500};
`

const PremiumChip = styled(Chip)`
  background: #ffcc0015;
  border: 1px solid #ffcc0015;
  color: #f4bf00;
  padding: ${theme.spacing(1)} ${theme.spacing(2)};
`

const Title = styled(Heading2)`
  margin-bottom: ${theme.spacing(2)} !important;
`

const Text = styled(Typography)`
  text-align: center;
`

const Fallback = ({ title, text, backgroundImage, ...other }) => {
  return (
    <SyledCard
      style={{ backgroundImage: `url(${backgroundImage})` }}
      {...other}
    >
      <CardContent>
        <Wrapper
          direction='column'
          alignItems='center'
          justify='space-between'
          height='100%'
        >
          <PremiumChip label='Funcionalidade Premium' />
          <Flex direction='column' alignItems='center' height='60%'>
            <Title variant='h4'>{title}</Title>
            <Text variant='body2'>{text}</Text>
          </Flex>
        </Wrapper>
      </CardContent>
    </SyledCard>
  )
}

export const PremiumCard = ({
  fallbackTitle,
  fallbackText,
  fallbackBackgroundImage = '/dashboard-img.png',
  children,
  ...other
}) => {
  return (
    <PremiumToggle
      fallback={
        <Fallback
          title={fallbackTitle}
          backgroundImage={fallbackBackgroundImage}
          text={fallbackText}
          {...other}
        />
      }
    >
      <Card {...other}>{children}</Card>
    </PremiumToggle>
  )
}
