import React, { useEffect, useState } from 'react'
import { colors, ExpandableTableRow, TableRow } from '@condofy/components'
import { BalanceSheetTableCell as TableCell } from './BalanceSheetTableCell'
import { BalanceSheetCurrencyText } from './BalanceSheetCurrencyText'
import moment from 'moment'

const isArrayEmpty = (array) =>
  array.reduce(
    (accumulator, currentValue) => accumulator + currentValue.amount,
    0
  ) === 0

export const BalanceSheetItemRow = ({ account, type, header, year }) => {
  const [incomeList, setIncomeList] = useState([])

  useEffect(() => {
    const result = account.categories
      .flatMap((mainCategory) => {
        return isArrayEmpty(mainCategory[type])
          ? null
          : {
              id: mainCategory.category.id,
              name: mainCategory.category.name,
              transactions: mainCategory[type],
              subCategory: mainCategory.categories
                .map((subCategory) => {
                  return isArrayEmpty(subCategory[type])
                    ? null
                    : {
                        id: subCategory.category.id,
                        name: subCategory.category.name,
                        transactions: subCategory[type]
                      }
                })
                .filter((item) => item !== null)
            }
      })
      .filter((item) => item !== null)
    setIncomeList(result)
  }, [account, type])

  const expandComponent = incomeList.map((category, index) => (
    <ExpandableTableRow
      sx={{ background: colors.light300 }}
      paddingLeft={2}
      buttonPlacement='start'
      expandComponent={
        <>
          {category.subCategory.map((subCategory) => (
            <TableRow key={subCategory.id}>
              <TableCell type='rowHeader' colSpan={2} style={{ paddingLeft: 85 }}>
                {subCategory.name}
              </TableCell>
              {subCategory &&
                subCategory.transactions &&
                subCategory.transactions.map((transaction) => {
                  return (
                    <TableCell category={type} key={transaction.month}>
                      <BalanceSheetCurrencyText
                        value={transaction.amount}
                        date={moment(`${year}-${transaction.month}`, 'YYYY-MM')}
                      />
                    </TableCell>
                  )
                })}
            </TableRow>
          ))}
        </>
      }
      key={index}
    >
      <TableCell type='rowHeader' colSpan={1}>
        {category.name}
      </TableCell>
      {category.transactions.map(({ amount, month }) => (
        <TableCell category={type} key={month}>
          <BalanceSheetCurrencyText
            value={amount}
            date={moment(`${year}-${month}`, 'YYYY-MM')}
          />
        </TableCell>
      ))}
    </ExpandableTableRow>
  ))

  const balance = type === 'expenses' ? `expenseBalance` : `incomeBalance`

  return (
    <ExpandableTableRow
      buttonPlacement='start'
      expandComponent={expandComponent}
    >
      <TableCell type='rowHeader'>{header}</TableCell>
      {account[balance].map(({ month, amount }) => (
        <TableCell category={type} key={month}>
          <BalanceSheetCurrencyText
            value={amount}
            date={moment(`${year}-${month}`, 'YYYY-MM')}
          />
        </TableCell>
      ))}
    </ExpandableTableRow>
  )
}
