import React, { useState } from 'react'
import {
  Flex,
  Typography,
  Select,
  MenuItem,
  theme,
  colors,
  pxToRem
} from '@condofy/components'
import styled from 'styled-components'
import {} from '@mui/material'

const StyledSelect = styled(Select)`
  .MuiSelect-root {
    color: ${colors.dark100};
    padding: ${theme.spacing(1)} !important;
  }
`

const Label = styled(Typography)`
  font-weight: 700;
  font-size: ${pxToRem(14)}rem;
  margin-bottom: ${theme.spacing(1)} !important;
`

export const RowsPerPage = ({ onChange, title = 'Itens por lista' }) => {
  const totalRows = [10, 25, 50, 100]
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const selectionChange = (event) => {
    setRowsPerPage(parseInt(event.target.value))
    onChange(parseInt(event.target.value))
  }

  return (
    <Flex direction='column'>
      <Label component='p'>{title}</Label>
      <StyledSelect value={rowsPerPage} onChange={selectionChange}>
        {totalRows.map((item) => (
          <MenuItem key={item} value={item}>
            {item}
          </MenuItem>
        ))}
      </StyledSelect>
    </Flex>
  )
}
