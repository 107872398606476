import React from 'react'
// import { Route } from 'react-router-dom'
import styled from 'styled-components'
import { FlexRow, FlexColumn } from '@condofy/components'
import {
  ModalContainer,
  ModalsManager,
  Sidebar,
  TopNavbar
} from '@condofy/next/containers'

const Layout = styled(FlexColumn)`
  flex: 1 1;
  height: 100vh !important;
  max-height: 100vh;
  overflow: hidden;
`

const MainRow = styled(FlexRow)`
  flex: 1 1;
  overflow: hidden;
  width: 100% !important;
`

const Main = styled.main`
  display: flex;
  flex-direction: column;
  flex: 1 1;
  max-height: 100vh;
  overflow: hidden;
`

const Content = styled(FlexColumn)`
  flex: 1 1;
  overflow: auto;
`

export const DashboardLayout = ({ children }) => (
  <>
    <Layout>
      <TopNavbar />
      <MainRow>
        <Sidebar />
        <Main role='main'>
          <Content>{children}</Content>
        </Main>
      </MainRow>
    </Layout>
    <ModalsManager />
    <ModalContainer />
  </>
)
/* 
export const DashboardLayoutRoute = ({ component: Component, ...other }) => {
  return (
    <Route
      {...other}
      render={(props) => (
        <DashboardLayout>
          <Component {...props} />
        </DashboardLayout>
      )}
    />
  )
} */
