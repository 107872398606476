import {
  SET_USERS,
  SET_METADATA,
  SET_USER_FOR_EDIT_FIELD,
  SET_USER_INFO,
  SET_ERROR
} from 'Actions/users'

import { errorParser } from 'Middleware/parsers'

const initialState = {
  list: [],
  userEdit: {
    name: '',
    email: '',
    phoneNumber: '',
    socialSecurityNumber: ''
  },
  errors: {
    name: '',
    email: '',
    phoneNumber: '',
    socialSecurityNumber: '',
    global: []
  },
  metadata: {
    limit: 10,
    count: 1,
    page: 1,
    search: ''
  }
}

const user = (state = initialState, action) => {
  switch (action.type) {
    case SET_USERS:
      return {
        ...state,
        list: action.users,
        metadata: {
          ...state.metadata,
          ...action.metadata
        }
      }
    case SET_METADATA:
      return {
        ...state,
        metadata: {
          ...state.metadata,
          ...action.metadata
        }
      }
    case SET_USER_FOR_EDIT_FIELD:
      return {
        ...state,
        userEdit: {
          ...state.userEdit,
          [action.key]: action.value
        }
      }
    case SET_USER_INFO:
      return {
        ...state,
        userEdit: action.user
      }
    case SET_ERROR:
      return {
        ...state,
        errors: errorParser(action.errors)
      }
    default:
      return state
  }
}

export default user
