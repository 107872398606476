import { all, fork, select, put, delay } from 'redux-saga/effects'
import Service from '~/lib/api/services/ticket'
import { Actions } from '@Redux/ticket'
import { toggleModal } from 'Actions/modals'
import { ADD_TICKET_MODAL } from 'Const'
import SagasUtil from '~/redux/sagas/util'

export const base = {
  baseType: 'ticket',
  actions: Actions,
  service: Service
}

export const search = {
  request: 'search',
  afterRequest: (response) => ({
    list: response.data.data,
    metadata: response.data.paging
  })
}

export const comments = {
  beforeRequest: function* ({ ticketId }) {
    const condominiumId = yield select(
      ({ auth }) => auth.currentCondominium.condominium.id
    )
    return [condominiumId, ticketId]
  },
  request: 'getComments',
  afterRequest: ({ data }) => ({
    comments: data
  })
}

export const create = {
  request: 'createTicket',
  afterRequest: function* () {
    yield put(Actions.ticketSearch())
    yield put(toggleModal(ADD_TICKET_MODAL, false))
  }
}

export const createComment = {
  beforeRequest: function ({ ticketId, currentCondominium, ...payload }) {
    const condominiumId = currentCondominium.id
    return [condominiumId, ticketId, payload]
  },
  request: 'createComment',
  afterRequest: function* () {
    // addToast('Comentario criado com sucesso', { appearance: 'success' })
    const ticketId = yield select(({ modal }) => modal.modalOptions.id)
    yield delay(2000)
    yield put(Actions.ticketComments({ ticketId }))
  }
}

export const types = {
  request: 'getTicketType',
  afterRequest: ({ data }) => {
    return { types: data.data }
  }
}

export default function* root() {
  const sagas = SagasUtil(base, {
    create,
    createComment,
    search,
    searchStatus: search,
    comments,
    types
  })
  yield all(sagas.map((saga) => fork(...saga)))
}
