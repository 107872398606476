import {
  Heading5,
  SmallBody,
  theme,
  colors,
  typography,
  pxToRem
} from '@condofy/components'
import { Box } from '@mui/material'
import React from 'react'
import styled from 'styled-components'
import { MarketListingPrice } from './MarketListingPrice'

const ListingImage = styled.img`
  border-radius: 3px;
  object-fit: contain;
  display: block;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  max-width: 200px;
  max-height: 200px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
`

const ListingBlank = styled.div`
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  object-fit: contain;
  display: flex;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  max-width: 200px;
  max-height: 200px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  > * {
    margin-bottom: ${theme.spacing(3)};

    &:last-child {
      margin-bottom: 0;
    }
  }
`
const ImageWrapper = styled.div`
  position: relative;
  min-height: 200px;
`

const ListingType = styled.div`
  background: ${colors.brandBlue400};
  border-radius: 2px;
  color: #fff;
  font-family: ${typography.type.tertiary};
  font-size: ${pxToRem(12)}rem;
  padding: 4px 6px;
  position: absolute;
  top: 8px;
  left: 8px;
`

const types = (value) => {
  switch (value) {
    case 1:
      return 'Grátis'
    case 2:
      return 'Por mês'
    case 3:
      return 'Por unidade'
    case 4:
      return 'Por uso/dia'
    default:
      return ''
  }
}

export const MarketListingDetail = ({ listing }) => {
  return (
    <Wrapper>
      <ImageWrapper>
        <ListingType>{types(listing.chargerType)}</ListingType>
        {!listing.imageUrl ? (
          <ListingBlank>Sem imagem disponível</ListingBlank>
        ) : (
          <ListingImage src={listing.imageUrl} alt={listing.title} />
        )}
      </ImageWrapper>
      <Heading5 color='textPrimary'>{listing.title}</Heading5>
      <Box flex='1'>
        <SmallBody>{listing.description}</SmallBody>
      </Box>
      <MarketListingPrice
        price={listing.price}
        paymentType={listing.paymentType}
      />
    </Wrapper>
  )
}
