import React, { useEffect, useState } from 'react'
import {
  Button,
  Card,
  CardHeader,
  TabsPanel,
  TabsPanelItem,
  TabToolbar,
  ProgressBar
} from '@condofy/components'
import { NewButton } from '@condofy/next/components'
import { AccountabilityReportsListing } from './AccountabilityReportsListing'
import { CreateAccountabilityReportModal } from './Modals/CreateAccountabilityReportModal'
import { AccountabilityReportModal } from './Modals/AccountabilityReportModal'
import { useUserContext } from '@condofy/next/store/user'
import {
  previewReport,
  downloadReport
} from '@condofy/next/service/accountabilityReport'

export const AccountabilityReportsTab = ({ setMainReportsTab }) => {
  const {
    state: { currentCondominium }
  } = useUserContext()

  const [task, setTask] = useState('')
  const [reportData, setReportData] = useState([])
  const [openCreate, setOpenCreate] = useState(false)
  const [openEdit, setOpenEdit] = useState(false)
  const [listChange, setListChange] = useState(false)
  const [progress, setProgress] = useState(1)
  const [loading, setLoading] = useState(false)


  useEffect(() => {
    let progressValue = progress
    const intervalId = setInterval(() => {
      if (progressValue < 98) {
        setProgress(progressValue + 1)
        progressValue += 1
      } else {
        clearInterval(intervalId)
      }
    }, 50)

    return () => clearInterval(intervalId)
  }, [loading])

  useEffect(() => {
    switch (task.action) {
      case 'open-create-modal':
        setOpenCreate(true)
        break

      case 'create-report':
        setReportData({
          condominiumCode: currentCondominium.code,
          condominiumId: currentCondominium.id,
          year: task.year,
          month: task.month,
          signInSequence: false,
          preliminaryList: task.preliminaryList
        })
        break

      case 'select-signers': {
        const tempData = task.report
        tempData.condominiumCode = currentCondominium.code
        tempData.condominiumId = currentCondominium.id
        setReportData(tempData)
        break
      }

      case 'preview':
        console.log(78)
        setLoading(true)
        if (task.month === undefined && !!task.report) {
          setReportData(task.report)
        } else {
          setReportData({
            condominiumCode: task.condominiumCode,
            year: task.year,
            month: task.month
          })
        }
        break

      case 'download':
        console.log(92)
        setLoading(true)
        setReportData(task.report)
        break

      default:
        break
    }
  }, [task])

  useEffect(() => {
    if (
      (task.action === 'create-report' || task.action === 'select-signers') &&
      reportData !== ''
    ) {
      setOpenEdit(true)
    }
    if (task.action === 'preview' && reportData !== '') {
      previewReportRequest()
    }
    if (task.action === 'download' && reportData !== '') {
      downloadReportRequest()
    }
  }, [reportData])

  const previewReportRequest = () => {
    const bodyData = {
      month: parseInt(reportData.month),
      year: parseInt(reportData.year)
    }

    previewReport(currentCondominium, reportData, bodyData).finally(() => {
      setTask('')
      setReportData([])
      console.log(126)
      setLoading(false)
    })
  }

  const downloadReportRequest = () => {
    downloadReport(currentCondominium, task, reportData).finally(() => {
      setTask('')
      setReportData([])
      console.log(135)
      setLoading(false)
    })
  }

  return (
    <>
      <div>
        <TabToolbar
          style={{ display: 'flex', with: '100%', justifyContent: 'right' }}
        >
          <div
            style={{
              display: 'flex',
              alignItens: 'right',
              width: 180,
              justifyContent: 'space-between',
              flexFlow: 'row wrap'
            }}
          >
            <Button color='secondary' onClick={() => setMainReportsTab(true)}>
              Voltar
            </Button>
            <NewButton
              onClick={() =>
                setTask({ report: null, action: 'open-create-modal' })
              }
            >
              Novo
            </NewButton>
          </div>
        </TabToolbar>
        {!!openCreate && (
          <CreateAccountabilityReportModal
            openCreate={openCreate}
            setOpenCreate={setOpenCreate}
            setTask={setTask}
            setListChange={setListChange}
          />
        )}
        {!!openEdit && (
          <AccountabilityReportModal
            openEdit={openEdit}
            setOpenEdit={setOpenEdit}
            reportData={reportData}
            setReportData={setReportData}
            setListChange={setListChange}
          />
        )}
      </div>
      <Card>
        <CardHeader title='Prestações de Contas' />
        <TabsPanel>
          <TabsPanelItem label='TUDO'>
            <AccountabilityReportsListing
              label='TUDO'
              reportFilter={'all'}
              setTask={setTask}
              listChange={listChange}
              setListChange={setListChange}
            />
          </TabsPanelItem>
          <TabsPanelItem label='ABERTO'>
            <AccountabilityReportsListing
              label='ABERTO'
              reportFilter={'open'}
              setTask={setTask}
              listChange={listChange}
              setListChange={setListChange}
            />
          </TabsPanelItem>
          <TabsPanelItem label='EM PROGRESSO'>
            <AccountabilityReportsListing
              label='EM PROGRESSO'
              reportFilter={'in progress'}
              setTask={setTask}
              listChange={listChange}
              setListChange={setListChange}
            />
          </TabsPanelItem>
          <TabsPanelItem label='ENCERRADO'>
            <AccountabilityReportsListing
              label='ENCERRADO'
              reportFilter={'closed'}
              setTask={setTask}
              listChange={listChange}
              setListChange={setListChange}
            />
          </TabsPanelItem>
        </TabsPanel>
      </Card>
      <ProgressBar active={loading} progress={progress} />
    </>
  )
}
