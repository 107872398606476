import { colors, CurrencyText, pxToRem } from '@condofy/components'
import React from 'react'
import styled from 'styled-components'

const Price = styled.div`
  color: ${colors.dark700};
  font-size: ${pxToRem(14)}rem;
  font-weight: 500;
  white-space: nowrap;

  .price,
  .free {
    font-size: ${pxToRem(16)}rem;
    font-weight: 600;
  }
`

export const MarketListingPrice = ({ price, paymentType, props }) => {
  return (
    <>
      {price === 0 ? (
        <Price {...props}>
          <span className='free'>Gratuito</span>
        </Price>
      ) : (
        <Price {...props}>
          <CurrencyText className='price' value={Number(price)} />
          &nbsp;
          {paymentType && `/ ${paymentType}`}
        </Price>
      )}
    </>
  )
}
