import React, { useEffect, useState } from 'react'
import { useFinancialContext } from '@condofy/next/store/financial'
import {
  Dialog,
  DialogContent,
  Autocomplete,
  DialogSection,
  Button
} from '@condofy/components'
import { ActionRow } from '@condofy/next/components'

export const EditStatementItemCategoryModal = () => {
  const [categories, setCategories] = useState([])
  const [selected, setSelected] = useState([])
  const {
    state: {
      statementItemCategory: {
        modal: { open }
      },
      statementItemDetail: {
        data: { unit, items }
      }
    },
    actions: { closeStatementItemCategoryModal }
  } = useFinancialContext()

  useEffect(() => {
    if (items) {
      const cat = items.map((item) => item.category?.name)
      setSelected(cat)
      setCategories(cat)
    }
  }, [items])

  const handleClose = () => {
    closeStatementItemCategoryModal()
  }

  const handleChange = (event, newValue) => {
    setSelected(newValue)
  }

  const handleClear = () => setSelected([])

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth='md'
      title={unit?.name}
      actions={<ActionRow onCancel={handleClose} onSubmit={() => {}} />}
    >
      <DialogContent style={{ width: '400px' }}>
        <DialogSection>
          <Autocomplete
            multiple
            disableClearable
            label='Categoria'
            options={categories}
            // getOptionLabel={(option) => option.label}
            value={selected}
            onChange={handleChange}
          />
        </DialogSection>
        <DialogSection border={1}>
          <Button variant='text' color='danger' onClick={handleClear}>
            Limpar tudo
          </Button>
        </DialogSection>
      </DialogContent>
    </Dialog>
  )
}
