import { condofyProtected } from './instance'
import { jsonToFormData } from '@condofy/next/service/formData'

export const searchOffers = ({
  currentCondominium,
  PageNumber = 1,
  RecordsPerPage = 12,
  membershipId,
  chargerType,
  generalSearch,
  status
}) => {
  return condofyProtected.post(
    `/v1/condominiums/${currentCondominium.id}/community-user-offers/search`,
    { membershipId, generalSearch, chargerType, status },
    {
      params: {
        PageNumber,
        RecordsPerPage
      }
    }
  )
}

export const detailOffer = ({ currentCondominium, item }) => {
  return condofyProtected.get(
    `/v1/condominiums/${currentCondominium.id}/community-user-offers/${item.id}`
  )
}

export const createOffer = ({ currentCondominium, payload }) => {
  return condofyProtected.post(
    `/v1/condominiums/${currentCondominium.id}/community-user-offers`,
    jsonToFormData(payload)
  )
}

export const updateOffer = ({ currentCondominium, payload }) => {
  return condofyProtected.put(
    `/v1/condominiums/${currentCondominium.id}/community-user-offers/${payload.id}`,
    jsonToFormData(payload)
  )
}

export const statusOffer = ({ currentCondominium, payload }) => {
  return condofyProtected.patch(
    `/v1/condominiums/${currentCondominium.id}/community-user-offers/${payload.id}/change-status?status=${payload.status}`
  )
}

export const deleteOffer = ({ currentCondominium, id }) => {
  return condofyProtected.delete(
    `/v1/condominiums/${currentCondominium.id}/community-user-offers/${id}`
  )
}

export const interestOffer = ({ currentCondominium, id }) => {
  return condofyProtected.post(
    `/v1/condominiums/${currentCondominium.id}/community-user-offers/${id}/send-interest`
  )
}

export const createOrder = () => {
  return new Promise((resolve) =>
    setTimeout(resolve, 0, { data: { hello: 'world' } })
  )
}

export const searchFeeds = ({
  currentCondominium,
  PageNumber = 1,
  RecordsPerPage = 10,
  membershipId,
  subject,
  userCommentsIdParent,
  generalSearch
}) => {
  return condofyProtected.post(
    `/v1/condominiums/${currentCondominium.id}/community-user-comments/search`,
    { membershipId, generalSearch, subject, userCommentsIdParent },
    {
      params: {
        PageNumber,
        RecordsPerPage
      }
    }
  )
}

export const createComment = ({ currentCondominium, payload }) => {
  return condofyProtected.post(
    `/v1/condominiums/${currentCondominium.id}/community-user-comments`,
    { ...payload }
  )
}

export const deleteComment = ({ currentCondominium, id }) => {
  console.log({ currentCondominium, id })
  return condofyProtected.delete(
    `/v1/condominiums/${currentCondominium.id}/community-user-comments/${id}`
  )
}

export const createSubcomment = ({ currentCondominium, payload }) => {
  return condofyProtected.post(
    `/v1/condominiums/${currentCondominium.id}/community-user-comments/${payload.id}/sub-comment`,
    { ...payload }
  )
}

export const feedLike = ({ condominiumId, id }) => {
  return condofyProtected.patch(
    `/v1/condominiums/${condominiumId}/community-user-comments/${id}/like`
  )
}

export const onboardIsPhoneConfirmed = () => {
  return condofyProtected
    .head(`/users/phone-number-status`)
    .then(() => true)
    .catch(() => false)
}

export const hasDoneOnboarding = ({ currentCondominium }) => {
  return condofyProtected
    .head(`/v1/condominiums/${currentCondominium.id}/community-onboards/status`)
    .then(() => true)
    .catch(() => false)
}

export const userHasAlreadyPosted = ({ currentCondominium }) => {
  return condofyProtected
    .head(
      `/v1/condominiums/${currentCondominium.id}/community-user-offers/already-posted`
    )
    .then(() => true)
    .catch(() => false)
}

export const createOnboard = ({
  currentCondominium,
  interests = [],
  agreeUseTerms
}) =>
  condofyProtected.post(
    `/v1/condominiums/${currentCondominium.id}/community-onboards`,
    {
      interests,
      agreeUseTerms
    }
  )
