import { useEffect, useRef } from 'react'

export const usePopup = ({
  url = '',
  title = '',
  w = 900,
  h = 800,
  onClose,
  open
}) => {
  const popupRef = useRef(null)

  useEffect(() => {
    if (!open) return

    const left = window.screen.width / 2 - w / 2
    const top = window.screen.height / 2 - h / 2

    const newWindow = window.open(
      url,
      title,
      `
        toolbar=no, location=no, directories=no, status=no, menubar=no,
        scrollbars=no, resizable=no, copyhistory=no,
        width=${w},
        height=${h},
        top=${top},
        left=${left}
        `
    )

    popupRef.current = newWindow

    const handlePopupClose = () => {
      if (onClose) onClose()
    }

    newWindow.onbeforeunload = handlePopupClose

    const checkPopup = setInterval(() => {
      if (newWindow.closed) {
        clearInterval(checkPopup)
        handlePopupClose()
      }
    }, 800)

    return () => {
      if (popupRef.current) {
        popupRef.current.close()
        popupRef.current = null
      }
      clearInterval(checkPopup)
    }
  }, [open, url, title, w, h, onClose])

  return popupRef
}
