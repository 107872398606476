import React, { useEffect, useState } from 'react'
import { LoadingIconButton, Icon } from '@condofy/components'
import { useUserContext } from '@condofy/next/store/user'
import { downloadExcelFile } from '@condofy/next/service/billings'
import { DialogContent, Modal, Box, CircularProgress } from '@mui/material'
import DownloadIcon from '@mui/icons-material/Download'
import { colors, theme } from '../../../@condofy/styles'
import styled from 'styled-components'
import { DialogCloseButton } from '../../../@condofy/components/Dialog/Dialog'
import { Heading5, ButtonDownload } from '@condofy/components'
import { Document, Page } from 'react-pdf'
import { condofyProtected } from '../../service/instance'
import { pdfjs } from 'react-pdf'
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry'
import 'react-pdf/dist/esm/Page/TextLayer.css'

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  pdfjsWorker,
  import.meta.url
).toString()

const DialogOverlay = styled(Modal)`
  background: rgba(0, 25, 71, 0.65);
  display: flex;
  justify-content: center;
  align-items: center;

  & .MuiPaper-root {
    background-color: ${colors.light100};
    border: solid 1px ${colors.light400};
    border-radius: 3px;
    box-shadow:
      0px 24px 18px rgba(23, 43, 77, 0.04),
      0px 35px 24px rgba(23, 43, 77, 0.08);
    padding: ${theme.spacing(3)};
    outline: none;

    ${({ maxWidth }) =>
      !maxWidth &&
      `
    max-width: 90vw;
      max-height: 90vh;

      ${theme.breakpoints.up('md')} {
        min-width: 35vw;
        max-width: 60vw;
      }
    `}
  }
`

export const FinancialStatementDowloadItemButton = ({ item }) => {
  const [loading, setLoading] = useState()
  const [isDownloading, setIsDownloading] = useState(false)
  const [open, setOpen] = useState(false)
  const [receiptUrl, setReceiptUrl] = useState(null)
  const [numPages, setNumPages] = useState(null)

  const handleClickOpen = () => {
    setOpen(true)

    if (!receiptUrl) {
      urlLoad()
    }
  }

  useEffect(() => {
    if (open) {
      urlLoad()
    }
  }, [open])

  useEffect(() => {
    if (receiptUrl) {
      setLoading(false)
    }
  }, [receiptUrl])

  const handleClose = () => {
    setOpen(false)
    setNumPages(null)
    setReceiptUrl(null)
  }

  const {
    state: { currentCondominium }
  } = useUserContext()
  const entryId = item.id

  const download = () => {
    setIsDownloading(true)
    downloadExcelFile({
      currentCondominium,
      entryId,
      type: 'application/pdf',
      name: `entry-${entryId}-documents.pdf`
    }).finally(() => {
      setIsDownloading(false)
      handleClose()
    })
  }

  const urlLoad = async () => {
    try {
      setLoading(true)
      const response = await condofyProtected.get(
        `/v1/condominiums/${currentCondominium.id}/entries/download-concatenate?entryId=${entryId}`,
        {
          responseType: 'arraybuffer'
        }
      )

      var blob = new Blob([response.data], { type: 'application/pdf' })
      const url = URL.createObjectURL(blob)
      setReceiptUrl(url)
    } catch (error) {
      console.error('Failed to download and prepare PDF for preview:', error)
    }
  }

  const onDocumentLoadSuccess = async ({ numPages }) => {
    setNumPages(numPages)
  }

  return (
    <>
      {Boolean(item.billetUrl || item.receiptUrl) && (
        <LoadingIconButton loading={loading} onClick={handleClickOpen}>
          <Icon icon='receipt_outlined' />
        </LoadingIconButton>
      )}
      <DialogOverlay open={open} onClose={handleClose}>
        <Box
          sx={{
            backgroundColor: 'white',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            height: '90%',
            width: '40%',
            borderRadius: 1,
            paddingX: '2%',
            paddingBottom: '2%',
            paddingTop: '1.5%'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              width: '100%'
            }}
          >
            <DialogCloseButton onClose={handleClose} />
          </Box>
          <Heading5 className='center' style={{ marginBottom: 15 }}>
            Pré-visualização
          </Heading5>
          <DialogContent sx={{ width: '100%' }}>
            {!loading ? (
              <Document file={receiptUrl} onLoadSuccess={onDocumentLoadSuccess}>
                {Array.from(new Array(numPages), (el, index) => (
                  <Page
                    key={`page_${index + 1}`}
                    pageNumber={index + 1}
                    renderTextLayer={false}
                    renderMode='canvas'
                    style={{ height: 100, backgroundColor: 'red' }}
                  />
                ))}
              </Document>
            ) : (
              <Box
                sx={{
                  height: '100%',
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <CircularProgress size={40} />
              </Box>
            )}
          </DialogContent>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: 5
            }}
          >
            <ButtonDownload
              onClick={download}
              loading={isDownloading}
              startIcon={<DownloadIcon size='small' />}
              disabled={false}
            >
              Download
            </ButtonDownload>
          </Box>
        </Box>
      </DialogOverlay>
    </>
  )
}
