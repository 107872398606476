import React from 'react'
import { Card, CardContent, typography, CardActions } from '@condofy/components'
import { MarketListingDetail } from './MarketListingDetail'
import styled from 'styled-components'

const StyledCard = styled(Card)`
  height: 100%;

  * {
    font-family: ${typography.type.tertiary} !important;
  }

  .MuiCardContent-root {
    flex-grow: 1;
    line-height: 1;
    text-align: center;

    & > div {
      height: 100%;
    }
  }
`

export const ListingCard = ({ listing, Actions, ...props }) => {
  return (
    <StyledCard {...props}>
      <CardContent>
        <MarketListingDetail listing={listing} />
      </CardContent>
      {Actions && <CardActions>{Actions}</CardActions>}
    </StyledCard>
  )
}
