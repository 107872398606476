import { condofyProtected } from './instance'

export const search = ({ condominiumId }) =>
  condofyProtected.get(`/v1/condominiums/${condominiumId}/bank-accounts`)

export const fetchAccountList = ({
  onError: emitErrorEvent = (e) => e,
  onSuccess: emitSuccessEvent = (e) => e
}) => {
  condofyProtected
    .get(`/v1/bank-accounts`)
    .then(({ data }) => emitSuccessEvent(data))
    .catch((error) => emitErrorEvent(error))
}
