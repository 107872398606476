import { condofyProtected } from './instance'
import { startOfMonth, lastDayOfMonth } from 'date-fns'

export const schedulingListPromise = ({
  currentCondominium,
  GeneralSearch,
  IncludeUnit,
  UserName,
  UserEmail,
  RecordsPerPage,
  page,
  currentMonth
}) => {
  return condofyProtected.get(
    `/v1/condominiums/${currentCondominium.id}/scheduling`,
    {
      params: {
        UserName,
        GeneralSearch,
        IncludeUnit,
        RecordsPerPage,
        PageNumber: page,
        UserEmail,
        startDate: currentMonth ? startOfMonth(currentMonth) : null,
        finalDate: currentMonth ? lastDayOfMonth(currentMonth).toJSON() : null
      }
    }
  )
}

export const fetchSchedulingList = ({
  currentCondominium,
  GeneralSearch,
  IncludeUnit,
  UserName,
  RecordsPerPage,
  page,
  UserEmail,
  currentMonth,
  onError: emitErrorEvent = (e) => e,
  onSuccess: emitSuccessEvent = (e) => e
}) => {
  return schedulingListPromise({
    currentCondominium,
    IncludeUnit,
    RecordsPerPage,
    page,
    GeneralSearch,
    UserName,
    UserEmail,
    currentMonth
  })
    .then((response) => emitSuccessEvent(response.data))
    .catch((error) => emitErrorEvent(error))
}

export const fetchSchedulingById = ({
  schedulingId,
  currentCondominium,
  onError: emitErrorEvent = (e) => e,
  onSuccess: emitSuccessEvent = (e) => e
}) => {
  condofyProtected
    .get(`v1/condominiums/${currentCondominium.id}/scheduling/${schedulingId}`)
    .then(({ data }) => emitSuccessEvent(data))
    .catch((error) => emitErrorEvent(error))
}

export const createScheduling = ({
  scheduling,
  currentCondominium,
  onError: emitErrorEvent = (e) => e,
  onSuccess: emitSuccessEvent = (e) => e
}) => {
  condofyProtected
    .post(`/v1/condominiums/${currentCondominium.id}/scheduling`, scheduling)
    .then(({ data }) => emitSuccessEvent(data))
    .catch((error) => emitErrorEvent(error))
}

export const deleteScheduling = ({
  currentCondominium,
  schedulingId,
  onError: emitErrorEvent = (e) => e,
  onSuccess: emitSuccessEvent = (e) => e
}) => {
  condofyProtected
    .delete(
      `/v1/condominiums/${currentCondominium.id}/scheduling/${schedulingId}`
    )
    .then(({ data }) => emitSuccessEvent(data))
    .catch((error) => emitErrorEvent(error))
}

export const updateScheduling = ({
  scheduling,
  currentCondominium,
  onError: emitErrorEvent = (e) => e,
  onSuccess: emitSuccessEvent = (e) => e
}) => {
  condofyProtected
    .put(
      `/v1/condominiums/${currentCondominium.id}/scheduling/${scheduling.id}`
    )
    .then(({ data }) => emitSuccessEvent(data))
    .catch((error) => emitErrorEvent(error))
}
