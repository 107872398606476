import React from 'react'
import styled from 'styled-components'
import { colors, typography } from '../../styles'
import { Flex } from '../Flex'
import { Heading6 } from '../Typography'
import { theme } from '../../styles'
import PropTypes from 'prop-types'

const FormRowSpacingY = 5
const FormRowSpacingX = 2

export const Form = styled.form`
  font-family: ${typography.type.tertiary};
  padding-left: ${theme.spacing(FormRowSpacingX / 2)};
  padding-right: ${theme.spacing(FormRowSpacingX / 2)};
`

export const FormSection = styled.div`
  margin-bottom: ${theme.spacing(3)};
`

export const FormSectionHeader = styled.div`
  margin-bottom: ${theme.spacing(3)};
`

export const FormSectionTitle = styled(Heading6)`
  color: ${colors.dark400};
  font-family: ${typography.type.tertiary} !important;
`

export const FormNote = styled.div`
  font-size: 12px !important;
  font-family: ${typography.type.tertiary} !important;
`

export const FormRow = (args) => (
  <Flex
    minWidth='100%'
    itemsGrid={true}
    spacingX={FormRowSpacingX}
    spacingY={FormRowSpacingY}
    {...args}
  />
)

export const FormContainer = styled.div`
  padding-top: ${theme.spacing(FormRowSpacingY / 2)};
  padding-bottom: ${theme.spacing(FormRowSpacingY / 2)};
`

FormRow.propTypes = {
  wrap: PropTypes.oneOf(['nowrap', 'wrap', 'wrap-reverse']),
  itemsGrid: PropTypes.oneOf([
    false,
    true,
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    9,
    10,
    11,
    12
  ])
}
