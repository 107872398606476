import { createContext, useContext } from 'react'
import { withProvider, Modal } from '../core/'

const mobileModal = new Modal({ key: 'MOBILE_DEVICE', statePropName: 'mobile' })

const initialState = {
  mobile: { ...mobileModal.initialState }
}

const applyMiddleware = (dispatch) => (action) => {
  const middleware = {
    ...mobileModal.middleware()
  }

  return middleware[action.type]
    ? middleware[action.type](dispatch, action.payload)
    : dispatch(action)
}

const useActions = (state, dispatch) => ({
  openMobileDeviceModal: mobileModal.actionOpen(state, dispatch),
  closeMobileDeviceModal: mobileModal.actionClose(state, dispatch)
})

const reducer = (state = initialState, action) => {
  const reducers = {
    ...mobileModal.reducers()
  }
  return reducers[action.type] ? reducers[action.type](state, action) : state
}

export const AppContext = createContext()

export const AppProvider = withProvider({
  reducer,
  initialState,
  useActions,
  applyMiddleware
})(AppContext)

export const useAppContext = () => useContext(AppContext)
