import { getToken } from '@condofy/next/vendor/@condofy/token'
import { condofy, condofyProtected } from './instance'

export const condominiumId = localStorage.getItem('currentCondominium')
export const token = getToken()
export const currentCondominium = {
  id: localStorage.getItem('currentCondominium')
}
export const email = localStorage.getItem('userEmail')
export const id = localStorage.getItem('userId')

export const getCondominium = () => currentCondominium
export const getUserEmail = () => email
export const getUserToken = () => token
export const getUserId = () => id

export const getProfile = () => condofyProtected.get(`/accounts/profile`)

export const changePassword = (payload) =>
  condofyProtected.put('/accounts/change-password', payload)

export const changeName = (payload) =>
  condofyProtected.put('/accounts/change-name', payload)

export const changeEmail = (payload) =>
  condofyProtected.put('/accounts/change-email', payload)

export const changePhoneNumber = (payload) =>
  condofyProtected.put('/accounts/change-phone-number', payload)

export const listUser = (
  payload = { page: 1, search: '', currentCondominium: { id: '' } }
) =>
  condofyProtected.post(
    `/users/search`,
    {
      generalSearch: payload.search,
      condominiumId: payload.currentCondominium.id
    },
    {
      params: {
        PageNumber: payload.page,
        RecordsPerPage: payload.recordsPerPage
      }
    }
  )

export const listAllUsers = (
  payload = { currentCondominium: { id: '' }, excludeManagers: false }
) =>
  condofyProtected.get(`/users`, {
    params: {
      excludeManagers: payload.excludeManagers,
      condominiumId: payload.currentCondominium.id
    }
  })

export const confirmPhoneNumber = async (payload) => {
  var phoneNumber = payload.phoneNumber.replace(/\D/g, '')
  if (phoneNumber.length > 11) {
    phoneNumber = phoneNumber.substring(0, 11)
  }
  console.log({
    first: payload
    // second: phoneNumber
  })
  let userId = localStorage.getItem('userId')
  const response = await condofy.post(
    `/accounts/${userId}/confirm-phone-number`,
    {
      phoneNumber,
      token: payload.token
    }
  )
  return response
}

export const resendEmail = ({
  email,
  onError: emitErrorEvent = (e) => e,
  onSuccess: emitSuccessEvent = (e) => e
}) =>
  condofy
    .post(`/accounts/${email}/resend-email-confirmation-token`)
    .then(({ data }) => emitSuccessEvent(data))
    .catch((error) => emitErrorEvent(error))

export const resendSMSCode = ({
  email,
  onError: emitErrorEvent = (e) => e,
  onSuccess: emitSuccessEvent = (e) => e
}) =>
  condofy
    .post(`/accounts/${email}/resend-phone-number-confirmation-token`)
    .then(({ data }) => emitSuccessEvent(data))
    .catch((error) => emitErrorEvent(error))

export const downloadUsersList = ({
  search,
  onError: emitErrorEvent = (e) => e,
  onSuccess: emitSuccessEvent = (e) => e
}) =>
  condofyProtected
    .post(
      `/users/export`,
      {
        generalSearch: search
      },
      {
        responseType: 'arraybuffer'
      }
    )
    .then(({ data }) => emitSuccessEvent(data))
    .catch((error) => emitErrorEvent(error))

export const deleteUser = ({ payload }) => {
  if (payload.isPhysicalDeletion) {
    return condofyProtected.delete(
      `/users/${payload.id}?isPhysicalDeletion=${payload.isPhysicalDeletion}`
    )
  } else {
    return condofyProtected.delete(`/users/${payload.id}`)
  }
}

export const restoreDeletedUser = ({ payload }) =>
  condofyProtected.post(`/users/${payload.userId}/reactive`)

export const registerFull = ({ payload }) =>
  condofyProtected.post('/accounts/register-full', payload)

export const updateUser = ({ payload }) =>
  condofyProtected.put(`/users/${payload.id}`, payload)

export const promoteSuperAdmin = (payload) =>
  condofyProtected.post(`/users/${payload}/promote-sa`)

export const removeSuperAdmin = (payload) =>
  condofyProtected.post(`/users/${payload}/remove-sa`)

export const userLocked = (payload) =>
  condofyProtected.post(`/users/${payload}/lock`)

export const userUnlocked = (payload) =>
  condofyProtected.post(`/users/${payload}/unlock`)
