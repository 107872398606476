import { createComment } from '@condofy/next/service/ticket'
import { pending } from './index'

// ACTION TYPES
const TICKET_COMMENT_CREATE = 'TICKET_COMMENT_CREATE'

export const createActionTypes = { TICKET_COMMENT_CREATE }

// ACTIONS
export const createActions = (state, dispatch) => ({
  createTicketComment: (payload) =>
    dispatch({ type: TICKET_COMMENT_CREATE, payload })
})

// MIDDLEWARE
export const create = async (dispatch, payload) => {
  try {
    pending(dispatch, true)
    const response = await createComment(payload)
    return response
  } finally {
    pending(dispatch, false)
  }
}

// REDUCER
