import { createSchedulingArea } from '@condofy/next/service/area'
import { store, pending } from './index'

// ACTION TYPES
const AREA_CREATE = 'AREA_CREATE'
const AREA_CREATE_MODAL_VISIBLE = 'AREA_CREATE_MODAL_VISIBLE'

export const createActionTypes = {
  AREA_CREATE,
  AREA_CREATE_MODAL_VISIBLE
}

// ACTIONS
export const createActions = (state, dispatch) => ({
  createArea: (payload) => dispatch({ type: AREA_CREATE, payload }),

  closeCreateAreaModal: () =>
    dispatch({ type: AREA_CREATE_MODAL_VISIBLE, payload: false }),

  openCreateAreaModal: () =>
    dispatch({ type: AREA_CREATE_MODAL_VISIBLE, payload: true })
})

// MIDDLEWARE
export const create = async (dispatch, payload) => {
  try {
    pending(dispatch, true)
    const response = await createSchedulingArea(payload)
    return response
  } finally {
    pending(dispatch, false)
  }
}

// REDUCER
export const createReducer = (state, action) => {
  switch (action.type) {
    case AREA_CREATE_MODAL_VISIBLE:
      return store(state, {
        createModalOpen: action.payload
      })

    default:
      return state
  }
}
