import types from './actionTypes'

export const useActions = (state, dispatch) => ({
  loadBillingList: (data) => {
    dispatch({ type: types.LOAD_BILLING_LIST, payload: data })
  },
  openDetailModal: (data) => {
    dispatch({ type: types.LOAD_BILLING_DETAIL, payload: data })
    dispatch({ type: types.LOAD_BILLING_DETAIL_MODAL_VISIBLE, payload: true })
  },
  closeDetailModal: () => {
    dispatch({ type: types.LOAD_BILLING_DETAIL_MODAL_VISIBLE, payload: false })
  },
  openDeclarationModal: (data) => {
    dispatch({ type: types.LOAD_BILLING_DECLARATION, payload: data })
    dispatch({
      type: types.LOAD_BILLING_DECLARATION_MODAL_VISIBLE,
      payload: true
    })
  },
  closeDeclarationModal: () => {
    dispatch({
      type: types.LOAD_BILLING_DECLARATION_MODAL_VISIBLE,
      payload: false
    })
  }
})
