import { condofy } from './instance'

export const listEvent = ({ condominiumId, start, end, Visibility, Summary }) =>
  condofy.get(
    `/v1/condominiums/${condominiumId}/calendar-event?StartDate=${start}&EndDate=${end}
      ${Summary ? `&Summary=${Summary}` : ''}${Visibility ? `&Visibility=${Visibility}` : ''}`
  )

export const getById = ({ condominiumId, eventId }) =>
  condofy.get(`/v1/condominiums/${condominiumId}/calendar-event/${eventId}`)

export const createEvent = ({ condominiumId, body }) =>
  condofy.post(`/v1/condominiums/${condominiumId}/calendar-event`, body)

export const updateEvent = async ({ condominiumId, eventId, body }) =>
  condofy.put(
    `/v1/condominiums/${condominiumId}/calendar-event/${eventId}`,
    body
  )

export const deleteEvent = ({ condominiumId, eventId }) =>
  condofy.delete(`/v1/condominiums/${condominiumId}/calendar-event/${eventId}`)
