import React, { useState } from 'react'
import styled from 'styled-components'
import { Container, Col as BaseCol } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Actions } from '@Redux/occurrence'

import { Modal, ActionRow } from '@condofy/next/components'
import { RecordVoiceOver } from '@mui/icons-material'
import { useUserContext } from '@condofy/next/store/user'
import { format } from 'date-fns'
import {
  colors,
  Body,
  FlexRowCentered as Flex,
  Heading6,
  typography,
  TextField,
  SkeletonTableRow
} from '@condofy/components'
import CloudDownloadRoundedIcon from '@mui/icons-material/CloudDownloadRounded'

import {
  Call,
  AuthorCall,
  DateCall,
  CommentCall,
  RowCall
} from './UpdateModalStyles'
import { ModalContent } from '../../next/components/Modal'

const ComentCall = styled.div`
  em,
  strong,
  h1,
  h2,
  h3,
  h4,
  h5,
  li,
  span,
  ul,
  ol,
  u,
  em,
  a,
  h6 {
    all: revert;
  }
`

const FlexRowCentered = styled(Flex)`
  margin: 12px 0 0;
`

const TitleFileCall = styled(Body)`
  margin-left: 5px;
  line-height: 24px;
  color: ${colors.dark300};
`

const Spacer = styled.span`
  width: 5px;
`

const ColFlex = styled(BaseCol)`
  padding: 0;
  display: flex;
  flex-direction: column;
`
const TextFieldWithoutMargin = styled(TextField)`
  margin: unset !important;
`

const EmptyState = styled.div`
  text-align: start;
  font-size: 27px;
  line-height: 28px;
  font-family: ${typography.type.secondary};
  color: ${colors.dark300};
  font-weight: 600;
`

export const OccurrenceUpdateModal = ({ options, close }) => {
  const {
    state: { isAdmin, isSuperAdmin, currentCondominium },
    actions: { listOccurence }
  } = useUserContext()

  const admnistratorUser = isAdmin || isSuperAdmin

  const { isOpen, pending } = useSelector(({ occurrence, modal }) => ({
    pending: occurrence.pending.create,
    isOpen: modal.isModalOpen
  }))

  const updateOccurenceList = () => {
    setTimeout(() => {
      if (currentCondominium.id) listOccurence({ currentCondominium })
    }, 1000)
  }

  const dispatch = useDispatch()
  const { title, userName, date, occurrence, comment, status, attachmentUrl } =
    options
  const [comments, setComments] = useState('')

  const onSubmit = () => {
    dispatch(
      Actions.occurrenceUpdate({
        payload: {
          ...options,
          comment: comments,
          status: 'F'
        },
        currentCondominium
      })
    )

    updateOccurenceList()
  }

  const canIncludeResponse = () =>
    status === 'P' && admnistratorUser && !comment

  return (
    <Modal
      icon={<RecordVoiceOver />}
      title='Detalhes da ocorrência'
      open={isOpen}
      onClose={close}
      footer={
        canIncludeResponse() && (
          <ActionRow
            loading={pending}
            confirmText='Enviar Mensagem'
            onSubmit={onSubmit}
            onCancel={close}
          />
        )
      }
    >
      <ModalContent>
        {pending && <SkeletonTableRow />}
        {!pending && (
          <Container className='themed-container' fluid={true}>
            <RowCall>
              <ColFlex>
                <Call>
                  <Call>
                    <AuthorCall>{userName}</AuthorCall>
                  </Call>
                  <DateCall>{format(new Date(date), 'dd/MM/yyyy')}</DateCall>
                </Call>
                <Heading6>{title}</Heading6>
                <ComentCall
                  dangerouslySetInnerHTML={{ __html: `${occurrence}` }}
                />
                {attachmentUrl && attachmentUrl.length ? (
                  <a
                    href={attachmentUrl[0].url}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <FlexRowCentered>
                      <CloudDownloadRoundedIcon />
                      <Spacer />
                      <TitleFileCall>Baixar Anexo</TitleFileCall>
                    </FlexRowCentered>
                  </a>
                ) : (
                  <span />
                )}
              </ColFlex>
              <br />
            </RowCall>
          </Container>
        )}
        {canIncludeResponse() && (
          <TextFieldWithoutMargin
            name='comment'
            multiline
            rows={4}
            label='Resposta para a ocorrência'
            onChange={(event) => {
              setComments(event.target.value)
            }}
          />
        )}
        {comment ? (
          <Container className='themed-container' fluid={true}>
            <ColFlex>
              <AuthorCall>Resposta do sindico</AuthorCall>
            </ColFlex>
            <ColFlex>
              <CommentCall>{comment}</CommentCall>
            </ColFlex>
          </Container>
        ) : (
          !canIncludeResponse() && (
            <EmptyState>Sem resposta do sindico</EmptyState>
          )
        )}
      </ModalContent>
    </Modal>
  )
}
