import { condofyProtected } from '@condofy/next/service/instance'

class FinanceService {
  detailedExtract({ condominiumId, body, page = 1, rowsPerPage }) {
    return condofyProtected.post(
      `/v1/condominiums/${condominiumId}/balance/detailed-extract`,
      body,
      {
        params: {
          PageNumber: page,
          RecordsPerPage: rowsPerPage
        }
      }
    )
  }

  toPay({ condominiumId, body, page = 1, rowsPerPage }) {
    return condofyProtected.post(
      `/v1/condominiums/${condominiumId}/balance/to-pay`,
      body,
      {
        params: {
          PageNumber: page,
          RecordsPerPage: rowsPerPage
        }
      }
    )
  }

  toReceive({ condominiumId, body, page = 1, rowsPerPage }) {
    return condofyProtected.post(
      `/v1/condominiums/${condominiumId}/balance/to-receive`,
      body,
      {
        params: {
          PageNumber: page,
          RecordsPerPage: rowsPerPage
        }
      }
    )
  }

  download(condominiumId, entryId) {
    return condofyProtected.get(
      `/v1/condominiums/${condominiumId}/entries/download-concatenate?entryId=${entryId}`,
      {
        responseType: 'arraybuffer'
      }
    )
  }

  downloadExcel({
    condominiumId,
    body,
    entryType,
    balance = false,
    merge = false,
    recordsPerPage
  }) {
    return condofyProtected.post(
      `/v1/condominiums/${condominiumId}/entries/download-excel`,
      body,
      {
        params: {
          typeDownload: entryType,
          IncludeBalance: balance,
          MergeDates: merge,
          RecordsPerPage: recordsPerPage
        }
      }
    )
  }
}

export default new FinanceService()
