import React from 'react'

import { Modal, ActionRow, ModalContent } from '@condofy/next/components'
import { useUserContext } from '@condofy/next/store/user'
import { useSnackbar, handleError } from '@condofy/next/hooks/useSnackbar'
import { UserConnectionForm } from './UserConnectionForm'
import { PROCESS_UNIT_USER_MODAL } from 'Const'
import { useDispatch } from 'react-redux'
import { toggleModal } from 'Actions/modals'
import { track } from '@condofy/next/service/segment'

export const UserConnectionEditModal = () => {
  const {
    state: {
      userConnection: { editModalOpen, selected, metadata }
    },
    actions: {
      closeEditUserConnectionModal: close,
      updateSelectedUserConnection,
      listConnection,
      editUserConnection
    }
  } = useUserContext()

  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch()

  const closeUnitListModal = () => {
    dispatch(toggleModal(PROCESS_UNIT_USER_MODAL, false))
  }

  const sendConnection = () => {
    editUserConnection(selected)
      .then(() => {
        close()
        closeUnitListModal()
        track(window.analytics, 'Connection Edited', selected)
        enqueueSnackbar('Conexão alterada com sucesso.', {
          variant: 'success'
        })
        listConnection()
      })
      .catch(handleError(enqueueSnackbar))
  }

  return (
    <Modal
      title='Editar Conexão'
      open={editModalOpen}
      onClose={close}
      footer={
        <ActionRow
          loading={metadata.loading}
          confirmText='Adicionar'
          onSubmit={sendConnection}
          onCancel={close}
        />
      }
    >
      <ModalContent>
        <UserConnectionForm
          value={selected}
          onChange={updateSelectedUserConnection}
        />
      </ModalContent>
    </Modal>
  )
}
