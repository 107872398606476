import React from 'react'
// import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ptBR from 'date-fns/locale/pt-BR';
import { ThemeProvider } from '@mui/material/styles';
import { TssCacheProvider } from "tss-react";
// import { ptBR } from '@mui/x-date-pickers/locales';
import { theme, ChartJsConfig } from '@condofy/components'
import { SnackbarProvider } from '@condofy/next/hooks/useSnackbar'
import { usePageViews } from '@condofy/next/hooks'
import { MainProvider } from 'Layout/Main'
import { CssBaseline } from '@mui/material'
import { MobileDeviceChecker } from './next/containers/mobile/MobileDeviceChecker'
import Routes from './Routes'

const App = () => {
  const snackbarPosition = { horizontal: 'right', vertical: 'bottom' }

  usePageViews()

  return (
    <TssCacheProvider options={{ key: "css" }}>
      <ThemeProvider theme={theme}>
        <ChartJsConfig />
        <CssBaseline />
        <MobileDeviceChecker />
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR} locale={ptBR}>
          <SnackbarProvider anchorOrigin={snackbarPosition}>
            <MainProvider>
              <Routes />
            </MainProvider>
          </SnackbarProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </TssCacheProvider>
  )
}

export default App
