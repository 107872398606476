import { Detail, Modal } from '../core/'

const key = 'FINANCIAL_STATEMENT_ITEM'

const statementItemCategoryModal = new Modal({
  key: `${key}_CATEGORY_MODAL`,
  statePropName: 'statementItemCategory'
})
const statementItemDetail = new Detail({
  key,
  statePropName: 'statementItemDetail'
})

export const initialState = {
  statementItemCategory: { ...statementItemCategoryModal.initialState },
  statementItemDetail: { ...statementItemDetail.initialState }
}

export const createMiddleware = () => {
  return {
    ...statementItemCategoryModal.middleware(),
    ...statementItemDetail.middleware((data) => data)
  }
}

export const useActions = (state, dispatch) => ({
  openStatementItemCategoryModal: (data) => {
    statementItemDetail.actions(state, dispatch)(data)
    statementItemCategoryModal.actionOpen(state, dispatch)(data)
  },
  closeStatementItemCategoryModal: statementItemCategoryModal.actionClose(
    state,
    dispatch
  )
})

export const createReducers = () => {
  return {
    ...statementItemCategoryModal.reducers(),
    ...statementItemDetail.reducers()
  }
}
