import webfontloader from 'webfontloader'

export const fonts = webfontloader.load({
  google: {
    families: [
      'Nunito+Sans:ital,wght@0,400;0,600;0,700;0,800;1,400',
      'Inter:wght@ital,wght@0,400;0,600;0,700;1,400'
    ]
  }
})
