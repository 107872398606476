import { deleteUser as removeUser } from '@condofy/next/service/user'
import { store, userActionTypes, pending } from './index'

// ACTION TYPES
const USER_DELETE = 'USER_DELETE'
const USER_DELETE_MODAL_VISIBLE = 'USER_DELETE_MODAL_VISIBLE'

export const deleteActionTypes = {
  USER_DELETE,
  USER_DELETE_MODAL_VISIBLE
}

// ACTIONS
export const deleteActions = (state, dispatch) => ({
  deleteUser: (payload) => dispatch({ type: USER_DELETE, payload }),

  closeDeleteUserModal: () => {
    dispatch({ type: userActionTypes.USER_SELECT, payload: false })
    dispatch({ type: USER_DELETE_MODAL_VISIBLE, payload: false })
  },

  openDeleteUserModal: (user) => {
    dispatch({ type: userActionTypes.USER_SELECT, payload: user })
    dispatch({ type: USER_DELETE_MODAL_VISIBLE, payload: true })
  }
})

// MIDDLEWARE
export const remove = async (dispatch, payload) => {
  try {
    pending(dispatch, true)
    return removeUser({ payload })
  } finally {
    pending(dispatch, false)
  }
}

// REDUCER
export const deleteReducer = (state, action) => {
  switch (action.type) {
    case USER_DELETE_MODAL_VISIBLE:
      return store(state, {
        deleteModalOpen: action.payload
      })

    default:
      return state
  }
}
