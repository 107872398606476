import React, { useState, useEffect } from 'react'
import { addMonths, differenceInMonths, format, startOfMonth } from 'date-fns'
import { Modal, ModalContent } from '@condofy/next/components'
import {
  Button,
  FormRow,
  MenuItem,
  Form,
  FormSection,
  Select
} from '@condofy/components'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material'
import DownloadIcon from '@mui/icons-material/Download'
import CreateIcon from '@mui/icons-material/Create'
import { useUserContext } from '@condofy/next/store/user'
import { getToken } from '@condofy/next/vendor/@condofy/token'
import moment from 'moment'

export const CreateAccountabilityReportModal = ({
  openCreate,
  setOpenCreate,
  setTask,
  setListChange
}) => {
  const token = getToken()

  const {
    state: { currentCondominium }
  } = useUserContext()

  const [selectedDate, setSelectedDate] = useState('')
  const [buttonDisabled, setButtonDisabled] = useState(true)
  const [startDate, setStartDate] = useState('')
  const [reportDatesList, setReportDatesList] = useState([])
  const [dateOptions, setDateOptions] = useState([])
  const [preliminaryList, setPreliminaryList] = useState([])
  const [readyToCreate, setReadyToCreate] = useState(false)
  const [openMessage, setOpenMessage] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  useEffect(() => {
    retrieveCondominiumCreationDate()
    retrieveReportDatesList()
  }, [])

  useEffect(() => {
    if (startDate.length > 0) {
      const totalOfMonths = differenceInMonths(
        startOfMonth(new Date()),
        startOfMonth(new Date(startDate))
      )
      const datesList = []
      for (let i = totalOfMonths - 1; i >= 0; i--) {
        const currentDate = format(
          addMonths(startOfMonth(new Date(startDate)), i),
          'MM/yyyy'
        )
        if (reportDatesList.length > 0) {
          const found = reportDatesList.find((date) => date === currentDate)
          if (found === undefined) {
            datesList.push({ date: currentDate, available: true })
          } else {
            datesList.push({ date: currentDate, available: false })
          }
        } else {
          datesList.push({ date: currentDate, available: true })
        }
      }
      setDateOptions(datesList)
    } else {
      setDateOptions([])
    }
  }, [startDate, reportDatesList])

  useEffect(() => {
    if (selectedDate && selectedDate.available) {
      setButtonDisabled(false)
    } else {
      setButtonDisabled(true)
    }
  }, [selectedDate])

  useEffect(() => {
    if (readyToCreate) {
      createReport()
    }
  }, [readyToCreate])

  async function retrieveCondominiumCreationDate() {
    const response = await fetch(
      `${process.env.REACT_APP_API_HOST}/v1/condominiums/${currentCondominium.id}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    )

    if (response.ok) {
      const responseData = await response.json()
      setStartDate(responseData.data.createdAt)
      return
    }

    setStartDate('')
  }

  async function retrieveReportDatesList() {
    const response = await fetch(
      `${process.env.REACT_APP_API_HOST}/v1/condominiums/${currentCondominium.id}/document-signatures/search`,
      {
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify({}),
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }
    )

    if (response.ok) {
      const responseData = await response.json()
      const datesList = []
      if (responseData.data !== undefined) {
        responseData.data.forEach((report) => {
          if (report.status.name !== 'Cancelado') {
            datesList.push(('0' + report.month).slice(-2) + '/' + report.year)
          }
        })
      }

      setReportDatesList(datesList)
      return
    }

    setReportDatesList([])
  }

  async function retrievePreliminarySignersList() {
    const response = await fetch(
      `${process.env.REACT_APP_API_HOST}/v1/condominiums/${currentCondominium.id}/erp-managers`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    )

    if (response.ok) {
      const responseData = await response.json()
      setPreliminaryList(responseData.data)
      setReadyToCreate(true)

      return
    } else {
      setButtonDisabled(false)
    }
    createReport()
  }

  async function createReport() {
    setReadyToCreate(false)
    const bodyData = {
      year: parseInt(selectedDate.date.substring(3)),
      month: parseInt(selectedDate.date.substring(0, 2)),
      signInSequence: false
    }

    if (preliminaryList.length > 0) {
      const signers = []
      preliminaryList.forEach((signer) => {
        signers.push({
          name: signer.name,
          email: signer.email
        })
      })
      bodyData.signers = signers
    }

    const response = await fetch(
      `${process.env.REACT_APP_API_HOST}/v1/condominiums/${currentCondominium.id}/document-signatures`,
      {
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify(bodyData),
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }
    )

    if (response.ok) {
      const responseData = await response.json()
      setListChange(true)
      setOpenCreate(false)
      setTask({ report: responseData.data, action: 'select-signers' })

      return
    }
    const responseData = await response.json()
    setErrorMessage(responseData.reports[0].message)
    setOpenMessage(true)
  }

  function handleChangeDate(event) {
    if (event.target.value.available) {
      setSelectedDate(event.target.value)
    }
  }

  const handleCreate = () => {
    if (selectedDate) {
      setButtonDisabled(true)
      retrievePreliminarySignersList()
    }
  }

  const handlePreview = () => {
    setButtonDisabled(true)
    setTask({
      report: null,
      action: 'preview',
      year: selectedDate.date.substring(3),
      month: selectedDate.date.substring(0, 2),
      condominiumCode: currentCondominium.code
    })
    setOpenCreate(false)
  }

  function isLessThanSixDaysFromNextMonth(dateString) {
    // eslint-disable-next-line no-unused-vars
    const [month, year] = dateString.split('/')
    const currentDate = moment()
    const nextMonthDate = moment(`01/${month}/${year}`, 'DD/MM/YYYY').add(
      1,
      'months'
    )

    const isAfterFifthDayOfNextMonth = currentDate.isAfter(
      nextMonthDate.startOf('month').add(4, 'days')
    )

    return isAfterFifthDayOfNextMonth
  }

  return (
    <Modal
      title={'Gerar Relatório'}
      open={openCreate}
      maxWidth='400px'
      onClose={() => setOpenCreate(false)}
      footer={
        <>
          <div style={{ width: '20%' }}>
            <Button color='secondary' onClick={() => setOpenCreate(false)}>
              Cancelar
            </Button>
          </div>
          <div
            style={{ width: '80%', display: 'flex', justifyContent: 'right' }}
          >
            <div
              style={{
                width: '195px',
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              <Button
                loading={buttonDisabled && selectedDate.date ? true : false}
                startIcon={
                  buttonDisabled && selectedDate.date ? null : (
                    <CreateIcon size='small' />
                  )
                }
                onClick={handleCreate}
                disabled={buttonDisabled}
              >
                Criar
              </Button>
              <Button
                startIcon={<DownloadIcon size='small' />}
                onClick={handlePreview}
                disabled={buttonDisabled}
              >
                Prévia
              </Button>
            </div>
          </div>
        </>
      }
    >
      <ModalContent>
        <div>
          <Dialog
            open={openMessage}
            onClose={() => setOpenMessage(false)}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
          >
            <DialogTitle id='alert-dialog-title'>Erro!</DialogTitle>
            <DialogContent>
              <DialogContentText id='alert-dialog-description'>
                {errorMessage}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenMessage(false)}>Ok</Button>
            </DialogActions>
          </Dialog>
        </div>
        <Form>
          <FormSection>
            <FormRow>
              <Select
                select
                label='Mês / Ano'
                value={selectedDate}
                onChange={handleChangeDate}
              >
                {dateOptions.map((date, index) => (
                  <MenuItem
                    key={index}
                    value={date}
                    disabled={
                      !date.available ||
                      !isLessThanSixDaysFromNextMonth(date.date)
                    }
                  >
                    {date.date}
                  </MenuItem>
                ))}
              </Select>
            </FormRow>
          </FormSection>
        </Form>
      </ModalContent>
    </Modal>
  )
}
