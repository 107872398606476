/* eslint-disable no-unused-vars */
import React from 'react'
import {
  FormRow,
  Form,
  FormSection,
  FormSectionTitle,
  FormSectionHeader,
  FormNote,
  TextField,
  MenuItem,
  NumberTextField,
  PriceTextField
} from '@condofy/components'
import { WeekDaySelector } from './WeekDaySelector'
import { HourAvailabilitySelector } from './HourAvailabilitySelector'
import { BookingRestrictionsSelector } from './BookingRestrictionsSelector'

export const AreaForm = ({ area, onChange: emitChangeEvent = () => {} }) => {
  const emitChange = (value) => emitChangeEvent({ ...area, ...value })

  const valueChanged = ({ target: { name, value } }) =>
    emitChange({ [name]: value })

  return (
    <Form>
      <FormSection>
        <FormSectionHeader>
          <FormSectionTitle>Informações</FormSectionTitle>
        </FormSectionHeader>
        <FormRow>
          <TextField
            required
            fullWidth
            name='name'
            value={area.name || ''}
            label='Nome da area'
            onChange={valueChanged}
          />
          <TextField
            required
            fullWidth
            name='description'
            value={area.description || ''}
            label='Descrição'
            onChange={valueChanged}
          />
        </FormRow>
      </FormSection>
      <FormSection>
        <FormSectionHeader>
          <FormSectionTitle>Configurações de Area</FormSectionTitle>
        </FormSectionHeader>
        <BookingRestrictionsSelector
          name='restrictions'
          value={area.restrictions}
          onChange={valueChanged}
        />
      </FormSection>
      <FormSection>
        <FormSectionHeader>
          <FormSectionTitle>Disponibilidade de Uso</FormSectionTitle>
          <FormNote>Número máximo de reservas para unidade por mês</FormNote>
          <FormNote>
            Deixe o campo de disponibilidade em branco ou com zero se não tem
            limite máximo.
          </FormNote>
        </FormSectionHeader>
        <WeekDaySelector
          name='weekdaysAvailable'
          value={area.weekdaysAvailable}
          onChange={valueChanged}
        />
      </FormSection>
      <FormSection>
        <FormSectionHeader>
          <FormSectionTitle>
            Número máximo de reservas para unidade por mês
          </FormSectionTitle>
          <FormNote>
            Deixe o campo de disponibilidade em branco ou com zero se não tem
            limite máximo.
          </FormNote>
        </FormSectionHeader>
        <FormRow>
          <NumberTextField
            name='maxTimesPerMonth'
            value={area.maxTimesPerMonth}
            label='Número de disponibilidade mensal'
            allowNegative={false}
            onChange={valueChanged}
          />
          <div>&nbsp;</div>
        </FormRow>
      </FormSection>
      <FormSection>
        <FormSectionHeader>
          <FormSectionTitle>Disponibilidade de Horário </FormSectionTitle>
        </FormSectionHeader>
        <FormRow>
          <HourAvailabilitySelector
            name='hoursAvailable'
            onChange={valueChanged}
            value={area.hoursAvailable}
          />
        </FormRow>
      </FormSection>
      <FormSection>
        <FormSectionHeader>
          <FormSectionTitle>Configuração de Cobrança</FormSectionTitle>
          <FormNote>
            Deixe os campos de cobrança em branco se a reserva for gratuita.
          </FormNote>
        </FormSectionHeader>
        <FormRow>
          <PriceTextField
            required
            value={area.price}
            label='Preço de Reserva'
            onChange={valueChanged}
            name='price'
            fullWidth
          />
          <TextField
            required
            select
            value={area.paymentType || 0}
            label='Escolha a forma de cobrança'
            name='paymentType'
            onChange={valueChanged}
            fullWidth
          >
            <MenuItem value={0}>Pré uso</MenuItem>
            <MenuItem value={1}>Pós uso</MenuItem>
          </TextField>
        </FormRow>
      </FormSection>
      <FormSection>
        <FormSectionHeader>
          <FormSectionTitle>Regulamento</FormSectionTitle>
        </FormSectionHeader>
        <FormRow>
          <TextField
            fullWidth
            multiline
            value={area.regulation}
            name='regulation'
            label='Escreva o regulamento da area'
            onChange={valueChanged}
          />
        </FormRow>
      </FormSection>
    </Form>
  )
}
