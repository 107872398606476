import React from 'react'
import Slider from 'react-slick'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { slick, slickTheme } from './slick'
import { theme, colors } from '../../styles'
import { Icon } from '../Icon'

const Arrow = styled.div`
  &,
  &:hover {
    background: ${colors.light100};
    border: solid 1px ${colors.light400};
    border-radius: 3px;
    height: auto;
    padding: ${theme.spacing(1)};
    width: 42px;
  }

  ${({ left }) => left && `left: ${-42 - theme.spacing(1)};`}
  ${({ right }) =>
    right && `right: ${-42 - theme.spacing(1)};`}

  .MuiSvgIcon-root {
    color: ${colors.dark300} !important;
  }

  &:before {
    display: none;
  }
`
const StyledSlider = styled(Slider)`
  ${slick}
  ${slickTheme}
`

const SliderWrapper = styled.div`
  padding: 0 21px;

  & .slick-list {
    margin: 0 -7px;

    & .slick-slide > div {
      padding: 0 ${theme.spacing(1 / 2)};
    }
  }
`

const NextArrow = (props) => (
  <Arrow right={true} {...props}>
    <Icon icon='chevron_right' />
  </Arrow>
)

const PrevArrow = (props) => (
  <Arrow left={true} {...props}>
    <Icon icon='chevron_left' />
  </Arrow>
)

export const Carousel = (props) => {
  return (
    <SliderWrapper>
      <StyledSlider
        nextArrow={<NextArrow />}
        prevArrow={<PrevArrow />}
        {...props}
      />
    </SliderWrapper>
  )
}

Carousel.propTypes = {
  slidesToScroll: PropTypes.number,
  slidesToShow: PropTypes.number,
  infinite: PropTypes.bool
}
Carousel.defaultProps = {
  infinite: true,
  slidesToScroll: 1,
  slidesToShow: 1
}
