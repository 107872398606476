import React from 'react'
import { Typography } from '@mui/material'
import PropTypes from 'prop-types'
import { colors } from '../../styles'

const defaultstyles = {
  '&.currency': {
    fontSize: 'inherit !important',
    fontWeight: 'inherit',
    fontFamily: 'inherit !important',
    lineHeight: 'inherit'
  },
  '&.sign1': {
    color: colors.success400
  },
  '&.sign-1': {
    color: colors.danger400
  }
}

export const CurrencyText = ({
  value = 0,
  currency = 'BRL',
  showSymbol = true,
  decimalPlaces = 2,
  locale = 'pt-br',
  replaceZero,
  styles = {},
  colorSign = false,
  ...args
}) => {
  // const signClass = colorSign ? `sign${Math.sign(value)}` : ''
  const signClass = colorSign ? `sign${Math.sign(value)}` : ''

  const toParts = new Intl.NumberFormat(locale, {
    currency: currency,
    minimumFractionDigits: decimalPlaces,
    style: 'currency'
  }).formatToParts(value)

  const minusSign = toParts
    .filter((p) => p.type === 'minusSign')
    .map((p) => p.value)

  const symbol = toParts
    .filter((p) => p.type === 'currency')
    .map((p) => p.value)

  const valueFormatted = new Intl.NumberFormat(locale, {
    currency: currency,
    minimumFractionDigits: decimalPlaces,
    style: 'decimal'
  }).format(Math.abs(value))

  return replaceZero && value === 0 ? (
    <Typography component='span' sx = {{...defaultstyles, ...styles}} className="currency" {...args}>
      {' '}
      {replaceZero}
    </Typography>
  ) : (
    <Typography
      noWrap
      component='span'
      sx = {{...defaultstyles, ...styles}}
      className={`currency ${signClass}`}
      {...args}
    >
      {minusSign && <span className={'minus'}>{minusSign}</span>}
      {showSymbol && <span className={'symbol'}>{symbol}&nbsp;</span>}
      <span className={'value'}>{valueFormatted}</span>
    </Typography>
  )
}
CurrencyText.propTypes = {
  showSymbol: PropTypes.bool,
  currency: PropTypes.string,
  decimalPlaces: PropTypes.number,
  locale: PropTypes.string,
  replaceZero: PropTypes.string,
  colorSign: PropTypes.bool
}
