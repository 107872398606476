class UserTypes {
  constructor() {
    // TODO: manager need to remove
    this.types = [
      // { label: "Manager", value: "2" },
      { label: 'Proprietário', value: '3' },
      { label: 'Inquilino', value: '4' },
      { label: 'Imobiliaria', value: '5' }
      // { label: 'Supplier', value: 'supplier' },
      // { label: 'Employee', value: 'employee' },
    ]
  }

  getValues() {
    return [...new Set(this.types.map(({ value }) => value))]
  }

  getValuesAvoidManager() {
    return this.types.filter(({ value }) => value !== 'manager')
  }
}

export default new UserTypes()
