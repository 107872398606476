import React, { useState, useEffect } from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import styled from 'styled-components'

const AbsoluteDiv = styled.div`
  position: absolute;
  bottom: 3px;
  right: 3px;
`

export const ProgressCharacter = ({ maxCharacterLimit, characterLimit }) => {
  const [progress, setProgress] = useState(0)
  const [limit, setLimit] = useState()

  useEffect(() => {
    setProgress((characterLimit * 100) / maxCharacterLimit)
    setLimit(maxCharacterLimit - characterLimit)
  }, [characterLimit, maxCharacterLimit])

  return (
    <AbsoluteDiv>
      <Box position='relative' display='inline-flex'>
        <CircularProgress variant='determinate' value={progress} />
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position='absolute'
          display='flex'
          alignItems='center'
          justifyContent='center'
        >
          <Typography variant='caption' component='div' color='textSecondary'>
            {limit}
          </Typography>
        </Box>
      </Box>
    </AbsoluteDiv>
  )
}
