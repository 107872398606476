import React from 'react'
import { CircularProgress } from '../Progress'
import { Button } from './Button'
import { Box } from '@mui/material'
import PropTypes from 'prop-types'
import clsx from 'clsx'

const styles = {
  wrapper: {
    display: 'inline-flex',
    position: 'relative'
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  },
  buttonProgressIcon: {
    color: 'white'
  }
}

export const LoadingButton = ({ disabled, className, loading, ...props }) => {
  return (
    <Box className={clsx('wrapper', className)} sx={{ ...styles.wrapper }}>
      <Button
        disabled={disabled || loading}
        {...props}
        startIcon={
          props.startIcon && !loading ? (
            props.startIcon
          ) : props.startIcon && loading ? (
            <CircularProgress
              size={24}
              className='buttonProgress'
              sx={{ ...styles.buttonProgressIcon }}
            />
          ) : null
        }
      />
      {loading && !props.startIcon && (
        <CircularProgress
          size={24}
          className='buttonProgress'
          sx={{ ...styles.buttonProgress }}
        />
      )}
    </Box>
  )
}
LoadingButton.propTypes = {
  loading: PropTypes.bool
}
