import React from 'react'
import { useUserContext } from '@condofy/next/store/user'
import { Page, PageTitle, PageSection } from '@condofy/components'
import { AnnouncementList } from '@condofy/next/containers/announcement'
import { AnnouncementModelList } from '@condofy/next/containers/announcementModel'

export const AnnouncementsPage = () => {
  const {
    state: { isSuperAdmin, isAdmin }
  } = useUserContext()
  const administratorUser = isSuperAdmin || isAdmin

  return (
    <Page>
      <PageTitle title='Comunicados' icon='announcements' />
      <PageSection>
        <AnnouncementList />
      </PageSection>
      {administratorUser && (
        <PageSection>
          <AnnouncementModelList />
        </PageSection>
      )}
    </Page>
  )
}
