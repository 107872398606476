import React from 'react'
import { Select } from '@condofy/components'
import { MenuItem } from '@mui/material'

const visibility = [
  {
    value: '1',
    label: 'Recebimento'
  },
  {
    value: '2',
    label: 'Pagamento'
  }
]

export const FundingTypeSelect = ({
  label = 'Tipo de Pagamento',
  ...props
}) => {
  return (
    <Select label={label} {...props}>
      {visibility.map((r) => (
        <MenuItem key={r.value} value={r.value}>
          {r.label}
        </MenuItem>
      ))}
    </Select>
  )
}
