import { combineReducers } from '@condofy/next/store/user/combineReducers'
import { listActions, listReducer, list, listActionTypes } from './list'
import { editActions, editReducer, editActionTypes } from './edit'
import {
  createActions,
  createReducer,
  createActionTypes,
  create
} from './create'

// ACTION TYPES
const TICKET_SET_LOADING_INDICATOR = 'TICKET_SET_LOADING_INDICATOR'
const TICKET_SELECT = 'TICKET_SELECT'

// ACTIONS
export const ticketActions = (state, dispatch) => ({
  ...createActions(state, dispatch),
  ...listActions(state, dispatch),
  ...editActions(state, dispatch),

  selectTicket: (payload) => dispatch({ type: TICKET_SELECT, payload })
})

export const pending = (dispatch, payload) =>
  dispatch({ type: TICKET_SET_LOADING_INDICATOR, payload })

// MIDDLEWARE
export const ticketMiddlewares = (dispatch, action) => {
  switch (action.type) {
    case createActionTypes.TICKET_CREATE:
      return create(dispatch, action.payload)

    case listActionTypes.TICKET_LIST:
      return list(dispatch, action.payload)

    default:
      return false
  }
}

// REDUCER
const ticketMainReducer = (state, action) => {
  switch (action.type) {
    case TICKET_SELECT:
      return store(state, { selected: action.payload })

    case TICKET_SET_LOADING_INDICATOR:
      return store(state, {
        metadata: {
          ...state.ticket.metadata,
          loading: action.payload
        }
      })

    default:
      return state
  }
}

export const ticketReducer = combineReducers({
  ticketMainReducer,
  createReducer,
  editReducer,
  listReducer
})

export const store = (state, value) => ({
  ...state,
  ticket: { ...state.ticket, ...value }
})

export const ticket = {
  createModalOpen: false,
  deleteModalOpen: false,
  editModalOpen: false,
  selected: {},
  items: [],
  metadata: {
    pageNumber: 1,
    totalRecords: 0,
    recordsPerPage: 10,
    loading: false
  }
}

export const ticketActionTypes = {
  TICKET_SET_LOADING_INDICATOR,
  TICKET_SELECT,

  ...createActionTypes,
  ...listActionTypes,
  ...editActionTypes
}
