import React, { useEffect, useCallback } from 'react'
import styled from 'styled-components'
import { Alert } from '@condofy/components'
import { useUserContext } from '@condofy/next/store/user'

const StyledAlert = styled(Alert)`
  margin: 0 30px;
  max-width: 100%;
  overflow: hidden;

  & .MuiAlert-message {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }
`

export const NavigationNotification = () => {
  const {
    state: { notification },
    actions: { loadNotifications, saveNotificationClosed }
  } = useUserContext()
  const stableLoadNotifications = useCallback(loadNotifications, [])

  useEffect(() => {
    stableLoadNotifications()
  }, [stableLoadNotifications])

  const handleClose = () => {
    saveNotificationClosed(notification.id)
  }

  return (
    Boolean(notification.id) && (
      <StyledAlert severity='info' onClose={handleClose}>
        {notification.message}
      </StyledAlert>
    )
  )
}
