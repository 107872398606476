import React from 'react'
import { CurrencyText } from '@condofy/components'
import PropTypes from 'prop-types'
import moment from 'moment'

export const BalanceSheetCurrencyText = ({ date, ...args }) => {
  const isFutureValue = date && moment(date).isAfter(moment(), 'month')
  return (
    <CurrencyText
      showSymbol={false}
      replaceZero={isFutureValue ? '-' : null}
      {...args}
    />
  )
}
BalanceSheetCurrencyText.propTypes = {
  value: PropTypes.number,
  date: PropTypes.object
}
