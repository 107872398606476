import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import moment from 'moment'
import { useSnackbar } from '@condofy/next/hooks/useSnackbar'
import {
  fetchIntervalRestrictionsList,
  removeIntervalRestrictions,
  createIntervalRestrictionsPromise
} from '@condofy/next/service/restriction'
import { DeleteOutline } from '@mui/icons-material'
import {
  Dialog,
  DialogContent,
  DialogSection as Section,
  FlexRowCentered,
  IconButton,
  colors,
  Button,
  Skeletonize
} from '@condofy/components'
import { track } from '@condofy/next/service/segment'
import { useUserContext } from '@condofy/next/store/user'
import { RestrictionCreateDatesModal } from './'

const DialogSection = styled(Section)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  background: ${colors.light300};
  padding: 2px 0px 0 7px;
  margin-bottom: 6px;
`

const Text = styled.span`
  color: ${colors.dark400};
`

const AlignEndRow = styled(FlexRowCentered)`
  place-content: unset !important;
  justify-content: flex-end !important;
`

const Restriction = ({ label, onDelete: emitDeleteEvent = () => {} }) => (
  <DialogSection>
    <Text>{label}</Text>
    <AlignEndRow>
      <IconButton onClick={emitDeleteEvent}>
        <DeleteOutline />
      </IconButton>
    </AlignEndRow>
  </DialogSection>
)

export const RestrictionDatesModal = ({ isOpen, onClose }) => {
  const {
    state: { currentCondominium }
  } = useUserContext()

  const [datesList, setDatesList] = useState([])
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    updateDatesList()
  }, [isOpen])

  const updateDatesList = () => {
    setLoading(true)
    fetchIntervalRestrictionsList({
      currentCondominium,
      onSuccess: ({ data }) => {
        setDatesList(data)
        setLoading(false)
      },
      onError: (error) => {
        const errorMessage =
          error.response.data.reports[0].message || error.message
        enqueueSnackbar(errorMessage, { variant: 'error' })
        setLoading(false)
      }
    })
  }

  const handleRemoveRestriction = (id) => () => {
    removeIntervalRestrictions({
      currentCondominium,
      id,
      onSuccess: () => {
        track(window.analytics, 'Booking Restriction Deleted', id)
        enqueueSnackbar('Restrição removida com sucesso.', {
          variant: 'success'
        })
        updateDatesList()
      },
      onError: (error) => {
        const errorMessage =
          error.response.data.reports[0].message || error.message
        enqueueSnackbar(errorMessage, { variant: 'error' })
      }
    })
  }

  const handleSubmit = (initialDate, finalDate) => {
    createIntervalRestrictionsPromise({
      currentCondominium,
      initialDate,
      finalDate
    })
      .then(() => {
        updateDatesList()
      })
      .catch((error) => {
        const errorMessage =
          error.response.data.reports[0].message || error.message
        enqueueSnackbar(errorMessage, { variant: 'error' })
      })
      .finally(() => {
        setOpen(false)
      })
  }

  const createNewRestriction = () => {
    setOpen(true)
  }

  return (
    <>
      <Dialog title='Restrição de Dias' open={isOpen} onClose={onClose}>
        <Skeletonize condition={loading}>
          <DialogContent>
            {datesList.map((date) => (
              <Restriction
                key={date.id}
                label={`${moment(new Date(date.initialDate)).format(
                  'DD/MM/YYYY'
                )} - ${moment(new Date(date.finalDate)).format('DD/MM/YYYY')}`}
                onDelete={handleRemoveRestriction(date.id)}
              />
            ))}
          </DialogContent>
        </Skeletonize>
        <Section border={1}>
          <Button onClick={() => createNewRestriction()}>Adicionar</Button>
        </Section>
      </Dialog>
      {open && (
        <RestrictionCreateDatesModal
          isOpen={open}
          onSubmit={handleSubmit}
          onClose={() => setOpen(false)}
        />
      )}
    </>
  )
}
