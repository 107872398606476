import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { ADD_OCCURRENCE_MODAL, UPDATE_OCCURRENCE_MODAL } from 'Const'
import { toggleModal } from 'Actions/modals'
import { useUserContext } from '@condofy/next/store/user'
import { track } from '@condofy/next/service/segment'
import { OccurenceStatusChip } from './OccurrenceStatusChip'
import {
  Button,
  Card,
  CardHeader,
  SearchTextField,
  CardContent,
  Pagination,
  Flex,
  DataTable,
  DateText
} from '@condofy/components'

export const OccurrenceList = (props) => {
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')

  const {
    state: {
      isSuperAdmin,
      isAdmin,
      currentCondominium,
      occurence: { items, metadata }
    },
    actions: { listOccurence }
  } = useUserContext()
  const administratorUser = isSuperAdmin || isAdmin
  const stableListOccurence = useCallback(listOccurence, [])

  const dispatch = useDispatch()

  useEffect(() => {
    if (currentCondominium.id)
      stableListOccurence({
        currentCondominium,
        PageNumber: page,
        generalSearch: search,
        recordsPerPage: 10
      })
  }, [currentCondominium, search, page, stableListOccurence])

  const handleSearch = (event) => {
    track(window.analytics, 'Occurrences Filtered', event.target.value)
    setSearch(event.target.value)
    setPage(1)
  }

  const openCreateModal = (occurence) => {
    dispatch(toggleModal(ADD_OCCURRENCE_MODAL, true, occurence))
  }

  const openEditModal = (occurence) => {
    dispatch(toggleModal(UPDATE_OCCURRENCE_MODAL, true, occurence))
  }

  const occurrenceEdit = (occurence) => {
    if (occurence.status === 'A') {
      openCreateModal(occurence)
    } else {
      openEditModal(occurence)
    }
  }

  const handleClick = (occurrence) => {
    track(window.analytics, 'Occurrences Details Opened', occurrence)
    occurrenceEdit(occurrence)
  }

  const columns = [
    { headerName: 'Titulo', field: 'title' },
    { headerName: 'Descrição', field: 'occurrence' },
    {
      headerName: 'Data',
      renderCell: ({ row }) => <DateText date={row.date} format='long' />
    },
    {
      headerName: 'Status',
      renderCell: ({ row }) => <OccurenceStatusChip status={row.status} />
    }
  ]
  return (
    <>
      <Card {...props}>
        <CardHeader
          title='Ocorrências'
          action={
            <>
              <SearchTextField
                size='small'
                onChange={handleSearch}
                value={search}
              />
              {!administratorUser && (
                <Button onClick={() => openCreateModal()}>+ Novo</Button>
              )}
            </>
          }
        />
        <CardContent>
          <DataTable
            variant='borderless'
            size='small'
            loading={metadata.loading}
            onRowClick={handleClick}
            columns={columns}
            rows={items}
          />
        </CardContent>
      </Card>
      <Flex width='100%' justify='flex-end'>
        <Pagination
          page={page}
          totalResults={metadata.totalRecords}
          pageSize={metadata.recordsPerPage}
          onChange={(event, page) => setPage(page)}
          disabled={metadata.loading}
        />
      </Flex>
    </>
  )
}
