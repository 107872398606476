import React from 'react'
import { Dialog, DialogContent, Wizard, WizardStep } from '@condofy/components'
import { CommunityOnboardFirstOfferStep } from './CommunityOnboardFirstOfferStep'
import { useCommunityContext } from '@condofy/next/store/community'
import { useUserContext } from '@condofy/next/store/user'
import { createOffer } from '@condofy/next/service/community'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { listingSchema } from '../listing/listingSchema'

export const CommunityOnboardOfferModal = () => {
  const {
    state: { currentCondominium }
  } = useUserContext()
  const {
    state: {
      onboardingOffer: {
        modal: { open }
      }
    },
    actions: { closeOnboardingOfferModal, loadListingList }
  } = useCommunityContext()

  const [files, setFiles] = React.useState([])

  const form = useForm({
    mode: 'onChange',
    resolver: yupResolver(listingSchema)
  })

  const handleClose = () => {
    closeOnboardingOfferModal()
  }

  const handleSubmit = () => {
    createOffer({
      currentCondominium,
      payload: {
        ...form.getValues(),
        price: form.getValues().price.toString().replace('.', ','),
        files,
        status: 1
      }
    }).then(() => {
      closeOnboardingOfferModal()
      loadListingList({ currentCondominium })
    })
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      hideHeader={true}
      maxWidth='sm'
    >
      <DialogContent>
        <Wizard>
          <WizardStep
            showCancelButton={true}
            showBackButton={false}
            onCancel={handleClose}
            nextButtonText='Concluir'
            onNext={handleSubmit}
          >
            <CommunityOnboardFirstOfferStep
              form={form}
              files={files}
              setFiles={setFiles}
            />
          </WizardStep>
        </Wizard>
      </DialogContent>
    </Dialog>
  )
}
