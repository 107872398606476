import { condofyProtected, condofyProtectedMultiPart } from '@condofy/next/service/instance'
import { jsonToFormData } from '@condofy/next/service/formData'
class OccurrenceService {
  search(
    payload = {
      currentCondominium: { id: '' },
      page: 1,
      generalSearch: '',
      recordsPerPage: 10
    }
  ) {
    return condofyProtected.get(
      `/v1/condominiums/${payload.currentCondominium.id}/occurrences`,
      {
        params: {
          PageNumber: payload.page,
          GeneralSearch: payload.generalSearch,
          RecordsPerPage: payload.recordsPerPage
        }
      }
    )
  }

  create({ currentCondominium, payload }) {
    return condofyProtectedMultiPart.post(
      `/v2/condominiums/${currentCondominium.id}/occurrences`,
      jsonToFormData(payload)
    )
  }

  update({ currentCondominium, payload }) {
    return condofyProtectedMultiPart.put(
      `/v2/condominiums/${currentCondominium.id}/occurrences/${payload.id}`,
      jsonToFormData(payload)
    )
  }
}
export default new OccurrenceService()
