import React from 'react'
import Graphic1 from 'Assets/community/work-connect'
import {
  CommunityOnboardStep,
  CommunityOnboardStepTitle,
  CommunityOnboardStepBody
} from './CommunityOnboardStep'

export const CommunityOnboardAuthPhone = (args) => {
  return (
    <CommunityOnboardStep {...args} title='' Image={<Graphic1 />}>
      <CommunityOnboardStepTitle>
        Seja bem vindo a comunidade!
      </CommunityOnboardStepTitle>
      <CommunityOnboardStepBody>
        Antes de continuar o seu cadastro, seu telefone precisa ser validado.
      </CommunityOnboardStepBody>
    </CommunityOnboardStep>
  )
}
