import React from 'react'
import { compose, createStore, applyMiddleware } from 'redux'
//reducer
import reducers from '../store'
//saga
import root from '../sagas'
import createSagaMiddleware from 'redux-saga'
import { Provider } from 'react-redux'

import thunk from 'redux-thunk'

import api from 'Middleware/api'

import { useContext } from 'react'
import { ReactReduxContext } from 'react-redux'

const devtool = '__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'
const composeEnhancers =
  typeof window === 'object' && window[devtool] ? window[devtool]({}) : compose

const sagaMiddleware = createSagaMiddleware()

const store = createStore(
  reducers,
  composeEnhancers(applyMiddleware(thunk, api), applyMiddleware(sagaMiddleware))
)

sagaMiddleware.run(root)

export const ReduxProvider = ({ children }) => {
  const reduxContext = useContext(ReactReduxContext)

  return (
    <Provider store={store} context={reduxContext}>
      {children}
    </Provider>
  )
}
