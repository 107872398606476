import { Button, Icon } from '@condofy/components'
import { Box } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

const Div = styled.div`
  overflow: hidden;
  position: relative;

  &:not(.expanded):after {
    content: '';
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    pointer-events: none;
    background-image: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 1) 90%
    );
    width: 100%;
    height: 4em;
  }
`

export const ExpandableText = ({
  maxHeight = 500,
  children,
  buttonText = 'Ler mais'
}) => {
  const ref = useRef()
  const [shouldShowExpand, setShouldShowExpand] = useState(false)
  const [expanded, setExpanded] = useState(true)

  useEffect(() => {
    if (ref.current.scrollHeight > maxHeight) {
      setShouldShowExpand(true)
      setExpanded(false)
    }
  }, [maxHeight])

  const toggleExpand = () => setExpanded(!expanded)

  return (
    <Box component='div' ref={ref}>
      <Div
        className={`inner ${expanded ? 'expanded' : ''}`}
        style={{ maxHeight: expanded ? 'none' : maxHeight }}
      >
        {children}
      </Div>
      {shouldShowExpand && !expanded && (
        <Button
          color='clear'
          onClick={toggleExpand}
          startIcon={<Icon icon='add' />}
        >
          {buttonText}
        </Button>
      )}
    </Box>
  )
}
