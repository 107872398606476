import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { colors, theme } from '../../styles'

const Highlight = styled.div`
  background: rgba(0, 25, 71, 0.05);
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-left: solid 2px ${colors.dark300};
  font-weight: bold;
  line-height: 1.7;
  padding: ${theme.spacing(1)} ${theme.spacing(3)};
  ${({ color }) => color && `border-color: ${colors[color]}`};
`

export const TextHighlight = (args) => {
  return <Highlight {...args} />
}
TextHighlight.propTypes = {
  color: PropTypes.oneOf(['primary', 'secondary'])
}
