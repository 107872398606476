import { colors } from './colors'
import { theme } from './theme'
import { typography } from './typography'

const field = {
  backgroundColor: colors.light200,
  borderColor: colors.light500,
  borderRadius: '4px',
  fontFamily: typography.type.tertiary,
  fontSize: '14px',
  labelColor: colors.dark100,
  adornmentColor: colors.dark100,
  padding: `12px ${theme.spacing(2)}`,
  textColor: colors.dark800
}

export const controls = {
  field
}
