import React from 'react'
import { Avatar as MuiAvatar } from '@mui/material'
import styled from 'styled-components'
import { colors, theme } from '../../styles'
import PropTypes from 'prop-types'

const StyledAvatar = styled(MuiAvatar)`
  &.MuiAvatar-colorDefault {
    background-color: ${colors.brandBlue50};
    color: ${colors.primary};
    font-size: 20px;
  }
`

const initials = (label = '') =>
  label
    .split(' ')
    .slice(0, 2)
    .map((n) => n[0])
    .join('')
    .toUpperCase()

const sizeStyles = {
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3)
  },
  large: {
    width: theme.spacing(7), 
    height: theme.spacing(7)
  }
}

export const Avatar = ({ size = 'medium', label, children, ...props }) => {
  // const className = sizeStyles[size] || ''
  return (
    <StyledAvatar className={size} {...props} sx={{ ...(sizeStyles[size] || {}) }}>
      {label ? initials(label) : children}
    </StyledAvatar>
  )
}

Avatar.propTypes = {
  label: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  alt: PropTypes.string
}
