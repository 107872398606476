import React from 'react'
import { useUserContext } from '@condofy/next/store/user'
import { PremiumToggle as PToggle } from '@condofy/components'

export const PremiumToggle = ({ children = null, fallback = null }) => {
  const {
    state: { currentCondominium }
  } = useUserContext()
  const userIsPremium = !(
    currentCondominium &&
    currentCondominium.plan &&
    currentCondominium.plan.id === '2'
  )

  return (
    <PToggle userIsPremium={userIsPremium} fallback={fallback}>
      {children}
    </PToggle>
  )
}
