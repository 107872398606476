import React from 'react'
import { NumberTextField } from './NumberTextField'

export const PriceTextField = (props) => (
  <NumberTextField
    thousandSeparator='.'
    decimalSeparator=','
    decimalScale={2}
    fixedDecimalScale
    isNumericString
    {...props}
  />
)
