import React from 'react'

function Icon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='134'
      height='96'
      fill='none'
      viewBox='0 0 134 96'
    >
      <path
        stroke='#C9D2EC'
        strokeDasharray='3.1 3.1'
        strokeMiterlimit='10'
        strokeWidth='1.551'
        d='M69.034 10.749s-26.012.722-31.495 13.325c-6.933 15.933 10.558 26.12 30.287 29.735 19.728 3.614 22.668 9.596 22.668 9.596'
      ></path>
      <path
        fill='#ABD2F4'
        d='M24.199 68.47L22 66.64c.425 6.614 4.105 5.876 4.105 5.876 1.352-6.782 5.521-13.352 6.8-15.365 1.277-2.012 4.86-5.993 7.562-8.595 2.702-2.603 3.86-4.935 3.86-4.935-1.636 1.665-5.796 2.611-5.796 2.611 0 .747-.747 3.445-.747 3.445 0-1.032-.574-2.986-.574-2.986-1.062.144-2.26.855-2.26.855l.804 4.54-2.127-3.831c-2.41.85-6.192 2.93-6.003 4.68.182 1.694 2.806 4.832 2.969 5.026l-5.003-4.08-1.134 6.476c.567 1.56 3.83 3.047 3.83 3.047-1.654.258-5.057-2.48-5.057-2.48S22 64.422 22 65.319c.001.871 2.07 3.018 2.2 3.151z'
      ></path>
      <path
        fill='#C2DCCC'
        d='M24.199 68.47L22 66.64c.425 6.614 4.105 5.876 4.105 5.876 1.352-6.782 5.521-13.352 6.8-15.365 1.277-2.012 4.86-5.993 7.562-8.595 2.702-2.603 3.86-4.935 3.86-4.935-1.636 1.665-5.796 2.611-5.796 2.611 0 .747-.747 3.445-.747 3.445 0-1.032-.574-2.986-.574-2.986-1.062.144-2.26.855-2.26.855l.804 4.54-2.127-3.831c-2.41.85-6.192 2.93-6.003 4.68.182 1.694 2.806 4.832 2.969 5.026l-5.003-4.08-1.134 6.476c.567 1.56 3.83 3.047 3.83 3.047-1.654.258-5.057-2.48-5.057-2.48S22 64.422 22 65.319c.001.871 2.07 3.018 2.2 3.151z'
      ></path>
      <path
        fill='#ABD2F4'
        d='M26.105 72.517c2.2.148 3.5-3.928 3.845-5.134.344-1.205 1.234-2.668 3.07-4.217 1.837-1.549 3.328-4.133 3.328-4.133-1.578 1.09-3.7.66-3.7.66 3.988-1.95 4.303-4.132 4.303-4.132 2.468-1.348 5.223-7.548 5.223-7.548 1.35-1.52 2.152-4.39 2.152-4.39s-1.16 2.33-3.86 4.934c-2.7 2.603-6.287 6.58-7.563 8.595-1.275 2.014-5.447 8.582-6.798 15.365z'
      ></path>
      <path
        fill='#8EACA8'
        d='M26.105 72.517c2.2.148 3.5-3.928 3.845-5.134.344-1.205 1.234-2.668 3.07-4.217 1.837-1.549 3.328-4.133 3.328-4.133-1.578 1.09-3.7.66-3.7.66 3.988-1.95 4.303-4.132 4.303-4.132 2.468-1.348 5.223-7.548 5.223-7.548 1.35-1.52 2.152-4.39 2.152-4.39s-1.16 2.33-3.86 4.934c-2.7 2.603-6.287 6.58-7.563 8.595-1.275 2.014-5.447 8.582-6.798 15.365z'
      ></path>
      <path
        stroke='#8EACA8'
        strokeMiterlimit='10'
        strokeWidth='0.674'
        d='M32.903 57.15l-3.54-6.684M33.827 55.883l-3.295-6.223'
      ></path>
      <path
        fill='#8EACA8'
        d='M27.772 92.761c-.335-.72-.726-1.43-1.135-2.12a25.753 25.753 0 00-1.327-2.014l-.36-.484c-.123-.16-.251-.314-.377-.47-.245-.32-.523-.609-.786-.913a14.717 14.717 0 00-1.764-1.627l-.237-.184-.118-.092-.126-.083-.501-.327a5.62 5.62 0 00-.52-.299c-.178-.091-.349-.197-.532-.277-.366-.162-.727-.338-1.108-.464-.19-.065-.373-.146-.567-.197l-.578-.167a13.385 13.385 0 00-4.776-.377 9.492 9.492 0 00-2.336.54l-.279.11-.267.133c-.087.047-.18.085-.263.14l-.25.162a3.29 3.29 0 00-.838.829c-.23.325-.406.685-.524 1.065-.122.381-.198.775-.226 1.174-.05.803.048 1.61.286 2.378a8.616 8.616 0 001.012 2.187c.436.68.946 1.31 1.522 1.879a14.574 14.574 0 003.984 2.736 14.6 14.6 0 01-4.021-2.7 10.914 10.914 0 01-1.552-1.873 8.706 8.706 0 01-1.046-2.199 6.78 6.78 0 01-.317-2.417c.025-.41.098-.816.22-1.21.118-.395.298-.77.532-1.111.235-.343.53-.64.87-.88l.258-.172c.086-.058.182-.098.272-.147l.275-.142.285-.114a9.694 9.694 0 012.38-.58 13.619 13.619 0 014.866.332l.592.164c.198.051.387.131.582.196.39.124.761.3 1.138.461.188.08.365.187.548.279.184.09.363.19.537.3l.52.332.13.083.122.093.244.187c.652.497 1.261 1.047 1.823 1.644.27.306.557.599.81.92.13.158.262.314.389.474l.37.487c.486.656.948 1.33 1.37 2.029.421.698.823 1.405 1.18 2.15l-.386.176z'
      ></path>
      <path
        fill='#8EACA8'
        d='M24.568 92.543a182.615 182.615 0 01-1.816-4.996c-.577-1.674-1.135-3.357-1.595-5.072-.06-.213-.106-.43-.16-.646a11.44 11.44 0 01-.14-.652 6.96 6.96 0 01-.15-1.328c-.01-.447.031-.894.125-1.331.09-.44.256-.86.488-1.244.233-.385.557-.708.943-.939.39-.221.828-.346 1.276-.364.895-.043 1.764.238 2.566.601-.807-.351-1.68-.617-2.564-.561a2.76 2.76 0 00-1.247.375 2.68 2.68 0 00-.906.93c-.45.755-.588 1.659-.562 2.53.014.44.07.876.167 1.304.045.216.09.431.149.644.058.213.105.428.169.64.482 1.7 1.065 3.37 1.666 5.034a169.62 169.62 0 001.887 4.96l-.296.115z'
      ></path>
      <path
        stroke='#8EACA8'
        strokeMiterlimit='10'
        d='M26.105 72.517s-2.951 13.89-1.389 19.969M28.41 92.672V85.21'
      ></path>
      <path
        fill='#ABD2F4'
        d='M44.327 66.041s-5.746 1.6-8.387 1.188c-2.641-.41-1.997 2.57-1.997 2.57s-4.546 1.857-4.546 3.673c0 1.817 1.361 3.513 1.361 3.513s-1.074-.215-2.244-2.15c0 0-1.878 3.233-1.655 4.575.146.827.476 1.61.966 2.292 0 0-.62-.199-.872-.735 0 0 .803 3.438 1.458 4.243 0 0 2.282-1.403 3.137-2.898.855-1.496 4.535-4.997 6.058-7.79 1.523-2.791 5.234-7.655 6.72-8.481z'
      ></path>
      <path
        fill='#8EACA8'
        d='M44.327 66.041s-5.746 1.6-8.387 1.188c-2.641-.41-1.997 2.57-1.997 2.57s-4.546 1.857-4.546 3.673c0 1.817 1.361 3.513 1.361 3.513s-1.074-.215-2.244-2.15c0 0-1.878 3.233-1.655 4.575.146.827.476 1.61.966 2.292 0 0-.62-.199-.872-.735 0 0 .803 3.438 1.458 4.243 0 0 2.282-1.403 3.137-2.898.855-1.496 4.535-4.997 6.058-7.79 1.523-2.791 5.234-7.655 6.72-8.481z'
      ></path>
      <path
        fill='#ABD2F4'
        d='M12.815 70.64s-1.244-1.593-1.464-3.401c0 0-3.661-.265-4.808-.927 0 0 3.149 3.099 6.272 4.328z'
      ></path>
      <path
        fill='#8EACA8'
        d='M12.815 70.64s-1.244-1.593-1.464-3.401c0 0-3.661-.265-4.808-.927 0 0 3.149 3.099 6.272 4.328z'
      ></path>
      <path
        fill='#ABD2F4'
        d='M7.156 65.773c-1.452-1.477-1.945-3.891-1.945-3.891-.67-.699-1.754-2.978-1.754-2.978-.945 3.644 3.45 6.7 3.699 6.869z'
      ></path>
      <path
        stroke='#7F51A8'
        strokeMiterlimit='10'
        strokeWidth='0.27'
        d='M7.156 65.772a.053.053 0 00.01.009'
      ></path>
      <path
        stroke='#8EACA8'
        strokeMiterlimit='10'
        strokeWidth='0.674'
        d='M20.388 82.05S21.942 86.975 22 92.486'
      ></path>
      <path
        fill='#D9E7DE'
        d='M5.211 61.881s.493 2.415 1.945 3.892a3.48 3.48 0 002.325 1.114s2.41 4.292 6.566 5.386c0 0-2.511 1.096-4.702.137 0 0 2.648 4.657 4.474 6.94.773.966 1.34 1.647 1.894 2.092.758.607 1.49.772 2.675.609 0 0 6.92-4.622 2.125-13.056l-3.242 4.581 2.363-6.223s-3.218-6.128-8.802-9.019l-1.906 2.694 1.249-5.387s-5.849-3.022-9.395-4.074l3.612 4.872s3.596 6.554 4.12 7.934c0 0-3.724-.454-5.301-2.492z'
      ></path>
      <path
        fill='#ABD2F4'
        d='M17.713 81.442c-.555-.444-1.122-1.126-1.894-2.09-1.826-2.284-4.474-6.941-4.474-6.941s-1.414-2.025-1.949-2.144c-.535-.118-3.093 6.898 8.317 11.175z'
      ></path>
      <path
        stroke='#8EACA8'
        strokeMiterlimit='10'
        strokeWidth='0.674'
        d='M20.388 82.051s-3.708-11.652-9.875-17.679M14.78 69.876l4.12-6.472M13.492 67.924l3.805-6.12'
      ></path>
      <path
        fill='#ABD2F4'
        d='M10.513 64.373s-.918-5.47-.834-6.264c0 0-6.973-2.985-9.31-6.543 0 .001 3.925 7.117 10.144 12.806z'
      ></path>
      <path
        fill='#8EACA8'
        d='M7.156 65.773c-1.452-1.477-1.945-3.891-1.945-3.891-.67-.699-1.754-2.978-1.754-2.978-.945 3.644 3.45 6.7 3.699 6.869zM17.713 81.442c-.555-.444-1.122-1.126-1.894-2.09-1.826-2.284-4.474-6.941-4.474-6.941s-1.414-2.025-1.949-2.144c-.535-.118-3.093 6.898 8.317 11.175zM10.513 64.373s-.918-5.47-.834-6.264c0 0-6.973-2.985-9.31-6.543 0 .001 3.925 7.117 10.144 12.806z'
      ></path>
      <path
        fill='#fff'
        d='M33.526 52.782a.528.528 0 100-1.055.528.528 0 000 1.055zM28.41 59.326a.422.422 0 100-.845.422.422 0 000 .845zM13.492 61.806a.338.338 0 100-.676.338.338 0 000 .675zM31.471 74.701a.338.338 0 100-.676.338.338 0 000 .676zM30.451 80.51a.27.27 0 100-.541.27.27 0 000 .54zM17.93 68.747a.27.27 0 100-.54.27.27 0 000 .54zM11.489 76.104a.122.122 0 100-.244.122.122 0 000 .244z'
      ></path>
      <path
        fill='#75A2C0'
        d='M47.685 8.765h15.52a4.77 4.77 0 010 9.538H51.021l-3.263 2.97v-2.97h-.073a4.769 4.769 0 010-9.538z'
      ></path>
      <path
        fill='#fff'
        d='M60.447 14.44a.837.837 0 100-1.674.837.837 0 000 1.674zM55.445 14.44a.837.837 0 100-1.674.837.837 0 000 1.674zM50.442 14.44a.837.837 0 100-1.674.837.837 0 000 1.674z'
      ></path>
      <mask
        id='mask0'
        width='53'
        height='71'
        x='25'
        y='23'
        maskUnits='userSpaceOnUse'
      >
        <path fill='#C4C4C4' d='M25.243 23.084H77.518V93.412H25.243z'></path>
      </mask>
      <g mask='url(#mask0)'>
        <path
          fill='#081D39'
          d='M33.706 48.174c3.074-3.17 3.94-5.199 4.478-7.263 1.299-4.98 2.556-7.03 4.27-8.762 1.34-1.355 2.948-1.813 4.255-2.185 2.048-.583 5.245-1.884 8.365-.372 4.907 2.378 1.302 13.664 1.302 13.664s1.3 1.617.58 4.859c-.722 3.242 2.48 4.328 2.48 4.328 7.263 4.479-1.592 11.265-1.592 11.265-15.478 7.94-24.226-1.156-24.226-1.156-7.103-5.937-2.413-11.797.088-14.378z'
        ></path>
        <path
          fill='#F09473'
          d='M73.774 72.642l-1.154-2.703-9.02 1.81a30.537 30.537 0 001.551 4.59c.933 2.156 2.291 4.502 4.096 5.653l4.527-9.35z'
        ></path>
        <path
          fill='#F4B85D'
          d='M58.882 50.41l-7.714 10.042L63.221 73.53l1.816 2.907 7.688-6.588a1.235 1.235 0 00.361-1.35c-1.022-2.89-4.845-12.429-6.407-14.223-1.52-1.745-4.575-2.87-6.19-3.35l-1.607-.517z'
        ></path>
        <path
          fill='#F4B85D'
          d='M37.242 92.844l4.24.793 12.611 2.355a16.075 16.075 0 0013.017-3.267.8.8 0 00.176-1.06c-.526-.811-1.53-2.728-3.032-7.11-1.682-4.894-.65-8.903.672-12.663.346-.977.71-1.94 1.051-2.893.184-.516.364-1.028.525-1.544 1.64-5.129.41-8.99-4.4-11.8-4.806-2.812-3.221-5.244-3.221-5.244s-.207-.078-.6-.188a15.777 15.777 0 00-1.676-.369l-3.277-.451-7.41-.669c-2.986.382-5.668.603-7.74 1.272 0 0-1.143 6.11.087 9.077a8.763 8.763 0 01.201.53h.002c.115.332.235.724.364 1.166a40.306 40.306 0 011.036 4.917c.687 4.52.83 10.114-1.092 13.644a13.72 13.72 0 00-.816 1.801c-2.045 5.492-.718 11.703-.718 11.703z'
        ></path>
        <path
          stroke='#050F1B'
          strokeMiterlimit='10'
          strokeWidth='1.6'
          d='M62.103 55.656c4.81 2.811 6.04 6.669 4.402 11.798a48.07 48.07 0 01-.527 1.545c-.34.956-.705 1.916-1.05 2.894l-.91 2.913'
          opacity='0.3'
        ></path>
        <path
          fill='#F4B85D'
          d='M38.178 50.006s-3.03 1.025-4.617 2.481c-1.664 1.528-3.596 4.755-4.088 7.853-.39 2.462-.265 9.047.063 12.043a1.235 1.235 0 001.53 1.064l8.343-1.97.123-9.672-1.354-11.8z'
        ></path>
        <path
          fill='#F7CDBE'
          d='M54.633 34.832c2.528 6.242 2.033 9.386 1.116 10.942a3.674 3.674 0 01-2.665 1.838l-.271.023.023.271.404 2.308a3.18 3.18 0 01-3.476 3.708l-.948-.103a3.416 3.416 0 01-3.031-3.74l.62-6.15-.151-.086c-.954-.547-1.527-1.955-1.465-2.927.02-.318.083-1.285.62-1.554.413-.205 1.096-.006 1.979.576l.096.063.112-.026c.15-.034 3.048-.706 4.712-2.76-.547 1.485-1.638 2.802-3.26 3.932l-1.68 1.171 1.927-.693c.057-.02 5.154-1.916 5.338-6.793z'
        ></path>
        <path
          fill='#1E1E1E'
          d='M52.813 47.635s-2.104-.225-3.54-1.483c0 0 .826 1.898 3.79 3.05l-.25-1.567z'
        ></path>
        <path
          fill='#050F1B'
          d='M37.242 92.844l4.24.793a17.694 17.694 0 01-.788-7.248c.75-6.958 2.04-9.778 2.018-15.953-.023-6.036-4.06-10.616-4.244-10.824.115.332.235.724.364 1.166a40.312 40.312 0 011.036 4.917c.687 4.52.83 10.114-1.092 13.644a13.72 13.72 0 00-.816 1.801c-2.045 5.493-.718 11.704-.718 11.704z'
          opacity='0.3'
        ></path>
        <path
          fill='#F7CDBE'
          d='M30.456 73.446l8.839-1.941-.158 4.985 12.072 10.292-2.183 3.21-17.559-9.449a1.507 1.507 0 01-.792-1.27l-.22-5.827z'
        ></path>
      </g>
      <path
        fill='#8DC1FF'
        d='M44.563 91.714c0 .36-.061.716-.18 1.054h-7.79a3.175 3.175 0 01-.18-1.054c0-1.974 1.823-3.575 4.074-3.575 2.252 0 4.075 1.601 4.075 3.575z'
      ></path>
      <path
        fill='#000'
        d='M44.563 91.714c0 .36-.061.716-.18 1.054h-7.79a3.175 3.175 0 01-.18-1.054c0-1.974 1.823-3.575 4.074-3.575 2.252 0 4.075 1.601 4.075 3.575z'
        opacity='0.5'
      ></path>
      <path
        fill='#8DC1FF'
        d='M44.41 92.37c0 .133-.013.267-.04.398h-7.765a1.975 1.975 0 01-.039-.399c0-1.614 1.755-2.92 3.922-2.92 2.166 0 3.921 1.306 3.921 2.92z'
      ></path>
      <path
        fill='#141414'
        d='M40.78 89.163a.293.293 0 10-.585 0v1.26a.293.293 0 00.586 0v-1.26z'
        opacity='0.5'
      ></path>
      <path
        fill='#fff'
        d='M61.285 24.073a1.11 1.11 0 100-2.219 1.11 1.11 0 000 2.22z'
      ></path>
      <path
        fill='#DEEDFF'
        d='M98.475 3.115a1.558 1.558 0 100-3.116 1.558 1.558 0 000 3.116zM22.01 48.058a.997.997 0 100-1.993.997.997 0 000 1.993z'
      ></path>
      <path
        fill='#BDB0FF'
        d='M120.727 92.544H9.917a.926.926 0 000 1.851h110.81a.926.926 0 000-1.851z'
      ></path>
      <path
        fill='#F7CDBE'
        fillRule='evenodd'
        d='M51.21 86.782s3.512-.212 3.608.57c.057.455-.402.462-1.382.81-.548.196 2.803 2.356 2.298 2.688-.488.32-1.848-.894-1.848-.894s1.935 1.707 1.438 2.117c-.377.313-2.2-1.62-2.2-1.62s1.556 1.767 1.322 1.996c-.479.467-2.305-1.394-2.305-1.394s-.14-.131.52.829c.688 1-.3.78-1.563-.21-1.26-.989-2.072-1.682-2.072-1.682l2.183-3.21z'
        clipRule='evenodd'
      ></path>
      <path
        stroke='#050F1B'
        strokeMiterlimit='10'
        strokeWidth='3'
        d='M63.1 69.632s-2.1 5.117-7.974 1.397c0 0-5.244 3.82-8.445-.684'
        opacity='0.15'
      ></path>
      <path
        fill='#9981EF'
        d='M46.569 92.544h12.183v-1.437H48.27a1.352 1.352 0 00-1.111.584l-.589.853z'
      ></path>
      <path
        fill='#8DC1FF'
        d='M46.569 92.544h12.183v-1.437H48.27a1.352 1.352 0 00-1.111.584l-.589.853z'
      ></path>
      <path
        fill='#8DC1FF'
        d='M100.216 65.93v23.564a3.05 3.05 0 01-3.05 3.05H57.142a3.05 3.05 0 01-3.05-3.05V64.855a1.973 1.973 0 011.971-1.974h41.103a3.05 3.05 0 013.05 3.05z'
      ></path>
      <path
        fill='#000'
        d='M100.216 65.93v23.564a3.05 3.05 0 01-3.05 3.05H57.142a3.05 3.05 0 01-3.05-3.05V64.855a1.973 1.973 0 011.971-1.974h41.103a3.05 3.05 0 013.05 3.05z'
        opacity='0.5'
      ></path>
      <path
        fill='#8DC1FF'
        d='M100.216 65.93v23.563a3.05 3.05 0 01-3.05 3.05H57.939a3.05 3.05 0 01-3.048-3.05v-24.64a1.974 1.974 0 011.974-1.973h40.3a3.051 3.051 0 013.051 3.05z'
      ></path>
      <path
        fill='#fff'
        d='M77.553 79.901a2.6 2.6 0 100-5.2 2.6 2.6 0 000 5.2z'
      ></path>
      <path
        fill='#000'
        d='M100.216 65.93v23.563a3.05 3.05 0 01-3.05 3.051H77.938l-.386-29.664h19.614a3.051 3.051 0 013.05 3.05z'
        opacity='0.1'
      ></path>
      <path
        fill='#C9D2EC'
        d='M125.439 52.194a45.717 45.717 0 01-.471 6.56H99.04a1.185 1.185 0 01-1.12-1.576l2.895-8.312a1.184 1.184 0 011.12-.796h23.319c.122 1.358.184 2.733.185 4.124z'
      ></path>
      <path
        fill='#000'
        d='M125.439 52.194a45.717 45.717 0 01-.471 6.56H99.04a1.185 1.185 0 01-1.12-1.576l2.895-8.312a1.184 1.184 0 011.12-.796h23.319c.122 1.358.184 2.733.185 4.124z'
        opacity='0.3'
      ></path>
      <path
        fill='#C9D2EC'
        d='M101.934 48.856h31.296a.401.401 0 01.373.55l-3.29 8.311a.402.402 0 01-.373.253h-30.9a.399.399 0 01-.397-.344.401.401 0 01.018-.188l2.895-8.313a.397.397 0 01.378-.269z'
      ></path>
      <path
        stroke='#fff'
        strokeMiterlimit='10'
        d='M105.189 52.228h23.42M104.148 54.833h19.756'
      ></path>
      <path
        fill='#161616'
        d='M100.217 65.93v6.788h-4.921a1.093 1.093 0 01-1.033-1.454l2.234-6.414a1.094 1.094 0 011.033-.735h2.086c.391.524.602 1.16.601 1.815z'
        opacity='0.1'
      ></path>
      <path
        fill='#C9D2EC'
        d='M122.697 65.613l-2.539 6.414a1.089 1.089 0 01-1.017.69h-18.924V65.93a3.027 3.027 0 00-.601-1.815h22.064a1.094 1.094 0 011.017 1.498z'
      ></path>
      <path
        fill='#000'
        d='M122.697 65.613l-2.539 6.414a1.089 1.089 0 01-1.017.69h-18.924V65.93a3.027 3.027 0 00-.601-1.815h22.064a1.094 1.094 0 011.017 1.498z'
        opacity='0.3'
      ></path>
      <path
        fill='#C9D2EC'
        d='M97.53 64.9h24.149a.306.306 0 01.256.136c.029.042.047.09.052.14a.3.3 0 01-.02.148l-2.539 6.414a.313.313 0 01-.288.196H95.296a.31.31 0 01-.293-.411l2.234-6.414a.31.31 0 01.292-.209z'
      ></path>
      <path
        stroke='#fff'
        strokeMiterlimit='10'
        strokeWidth='0.772'
        d='M100.041 67.504h18.072M99.237 69.513h15.246'
      ></path>
      <path
        fill='#C9D2EC'
        d='M123.287 38.357H79.825a.785.785 0 01-.785-.785V6.92a45.294 45.294 0 0144.247 31.439z'
      ></path>
      <path
        fill='#000'
        d='M123.287 38.357H79.825a.785.785 0 01-.785-.785V6.92a45.294 45.294 0 0144.247 31.439z'
        opacity='0.3'
      ></path>
      <path
        fill='#fff'
        d='M126.641 7.41V36.4a.535.535 0 01-.537.538H81.008a.785.785 0 01-.785-.785V7.41a1.802 1.802 0 011.802-1.803h42.814a1.803 1.803 0 011.802 1.803z'
      ></path>
      <path
        fill='#C9D2EC'
        d='M80.223 10.784h46.418v25.25a.905.905 0 01-.903.904h-44.73a.785.785 0 01-.785-.785V10.784zM126.641 7.41v1.398H80.223V7.41a1.802 1.802 0 011.802-1.803h42.814a1.803 1.803 0 011.802 1.803z'
      ></path>
      <path
        fill='#fff'
        d='M83.086 8.093a.884.884 0 100-1.769.884.884 0 000 1.77zM86.748 7.21a.88.88 0 01-.318.677.88.88 0 01-1.243-.11.875.875 0 010-1.135.88.88 0 011.354 0c.134.159.207.36.207.568z'
      ></path>
      <path
        fill='#AFBDE0'
        d='M86.282 7.56a.392.392 0 01-.069.067l-.35-.35-.068-.068-.35-.35a.427.427 0 01.068-.069l.35.35.069.07.35.35z'
      ></path>
      <path
        fill='#AFBDE0'
        d='M86.282 6.858l-.35.351-.068.068-.351.35a.427.427 0 01-.068-.068l.419-.418.35-.351c.025.02.047.043.068.068zM83.63 7.257H82.54a.433.433 0 01.001-.096h1.087a.436.436 0 010 .096z'
      ></path>
      <path
        stroke='#fff'
        strokeMiterlimit='10'
        strokeWidth='0.943'
        d='M126.641 7.41v28.744a.786.786 0 01-.785.785H81.008a.785.785 0 01-.785-.785V7.41a1.802 1.802 0 011.802-1.803h42.814a1.804 1.804 0 011.802 1.803v0z'
      ></path>
      <path
        fill='#fff'
        d='M102.973 13.104h-17.84c-.75 0-1.358.609-1.358 1.359v9.627c0 .75.608 1.358 1.358 1.358h17.84a1.36 1.36 0 001.359-1.358v-9.627a1.36 1.36 0 00-1.359-1.358z'
      ></path>
      <path
        fill='#C9D2EC'
        d='M96.829 19.43a.178.178 0 000-.307l-2.222-1.283-2.222-1.283a.177.177 0 00-.243.065.178.178 0 00-.024.09v5.13a.177.177 0 00.267.155l2.222-1.283 2.222-1.283z'
      ></path>
      <path
        fill='#E9E6FF'
        d='M85.974 33.843a2.2 2.2 0 100-4.399 2.2 2.2 0 000 4.4z'
      ></path>
      <path
        fill='#C9D2EC'
        d='M85.974 31.645a.62.62 0 100-1.24.62.62 0 000 1.24z'
      ></path>
      <path
        fill='#C9D2EC'
        d='M84.737 32.881a1.237 1.237 0 012.474 0h-2.474z'
      ></path>
      <path
        stroke='#fff'
        strokeMiterlimit='10'
        d='M88.974 30.405h15.358M88.974 32.882h9.306'
      ></path>
      <path
        fill='#fff'
        d='M112.277 13.104h-4.512a.344.344 0 00-.344.344v2.435c0 .19.154.344.344.344h4.512a.344.344 0 00.343-.344v-2.435a.344.344 0 00-.343-.344z'
      ></path>
      <path
        fill='#C9D2EC'
        d='M110.723 14.704a.042.042 0 00.016-.016.046.046 0 00.007-.023.047.047 0 00-.007-.023.042.042 0 00-.016-.016l-.562-.325-.562-.324a.047.047 0 00-.023-.006.046.046 0 00-.039.022.045.045 0 00-.006.023v1.297a.044.044 0 00.023.04.043.043 0 00.022.006.048.048 0 00.023-.006l.562-.325.562-.324z'
      ></path>
      <path
        stroke='#fff'
        strokeMiterlimit='10'
        d='M114.591 13.972h9.195M114.591 15.36h4.435'
      ></path>
      <path
        fill='#fff'
        d='M112.277 17.349h-4.512a.344.344 0 00-.344.343v2.435c0 .19.154.344.344.344h4.512a.344.344 0 00.343-.344v-2.435a.344.344 0 00-.343-.343z'
      ></path>
      <path
        fill='#C9D2EC'
        d='M110.723 18.948a.042.042 0 00.016-.016.038.038 0 00.007-.023.038.038 0 00-.007-.023.042.042 0 00-.016-.016l-.562-.325-.562-.324a.048.048 0 00-.023-.006.043.043 0 00-.022.006.045.045 0 00-.017.016.045.045 0 00-.006.023v1.298c0 .008.002.015.006.022.004.007.01.013.017.017a.044.044 0 00.022.006.046.046 0 00.023-.006l.562-.325.562-.324z'
      ></path>
      <path
        stroke='#fff'
        strokeMiterlimit='10'
        d='M114.591 18.216h9.195M114.591 19.604h4.435'
      ></path>
      <path
        fill='#fff'
        d='M112.277 21.594h-4.512a.344.344 0 00-.344.343v2.435c0 .19.154.344.344.344h4.512a.344.344 0 00.343-.344v-2.435a.344.344 0 00-.343-.343z'
      ></path>
      <path
        fill='#C9D2EC'
        d='M110.723 23.194a.042.042 0 00.016-.016.038.038 0 00.007-.023.038.038 0 00-.007-.023.042.042 0 00-.016-.016l-.562-.325-.562-.324a.048.048 0 00-.023-.006.043.043 0 00-.022.006.044.044 0 00-.023.039v1.298a.044.044 0 00.023.039.044.044 0 00.022.006.048.048 0 00.023-.006l.562-.325.562-.324z'
      ></path>
      <path
        stroke='#fff'
        strokeMiterlimit='10'
        d='M114.591 22.46h9.195M114.591 23.849h4.435'
      ></path>
      <path
        fill='#fff'
        d='M112.277 25.838h-4.512a.344.344 0 00-.344.343v2.435c0 .19.154.344.344.344h4.512a.344.344 0 00.343-.344v-2.434a.344.344 0 00-.343-.344z'
      ></path>
      <path
        fill='#C9D2EC'
        d='M110.723 27.438a.041.041 0 00.016-.017.038.038 0 00.007-.023.038.038 0 00-.007-.023.042.042 0 00-.016-.016l-.562-.324-.562-.325a.048.048 0 00-.023-.006.044.044 0 00-.022.006.044.044 0 00-.023.039v1.298a.045.045 0 00.045.045.047.047 0 00.023-.006l.562-.325.562-.323z'
      ></path>
      <path
        stroke='#fff'
        strokeMiterlimit='10'
        d='M114.591 26.704h9.195M114.591 28.092h4.435'
      ></path>
      <path
        fill='#fff'
        d='M112.277 30.083h-4.512a.344.344 0 00-.344.344v2.434c0 .19.154.344.344.344h4.512a.344.344 0 00.343-.344v-2.434a.344.344 0 00-.343-.344z'
      ></path>
      <path
        fill='#C9D2EC'
        d='M110.723 31.683a.042.042 0 00.016-.017.038.038 0 00.007-.023.038.038 0 00-.007-.022.042.042 0 00-.016-.017l-.562-.324-.562-.325a.048.048 0 00-.023-.006.043.043 0 00-.022.006.044.044 0 00-.023.039v1.298a.045.045 0 00.023.04.044.044 0 00.022.005.047.047 0 00.023-.006l.562-.324.562-.324z'
      ></path>
      <path
        stroke='#fff'
        strokeMiterlimit='10'
        d='M114.591 30.95h9.195M114.591 32.337h4.435'
      ></path>
    </svg>
  )
}

export default Icon
