import React, { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useCalendarContext } from '@condofy/next/store/calendar'
import { CalendarEventForm } from './CalendarEventForm'
import { useUserContext } from '@condofy/next/store/user'
import { ActionRow, ProcessDialog } from '@condofy/next/components'
import { calendarEventSchema } from './calendarEventSchema'
import moment from 'moment'
import { CalendarEventList } from './CalendarEventList'
import { track } from '@condofy/next/service/segment'
import { useSnackbar, handleError } from '@condofy/next/hooks/useSnackbar'
import { Button, DialogSection } from '@condofy/components'

export const EditCalendarEventModal = ({isFull = false}) => {
  const { enqueueSnackbar } = useSnackbar()
  const {
    state: {
      detail: {
        data,
        metadata: { loading },
        modal: { open }
      },
      update: {
        metadata: { loading: updateLoading },
        error: errorUpdating
      },
      delete: { error: errorDeleting }
    },
    actions: { closeEditEventModal, listEvents, updateEvent, deleteEvent }
  } = useCalendarContext()

  const {
    state: {
      isSuperAdmin,
      isAdmin,
      currentCondominium: { id: condominiumId, name: condominiumName }
    }
  } = useUserContext()

  const isAdministrator = isSuperAdmin || isAdmin

  const form = useForm({
    mode: 'onChange',
    resolver: yupResolver(calendarEventSchema),
    defaultValues: {
      date: moment().format('DD/MM/YYYY'),
      start: moment().toDate('HH:mm'),
      end: moment().toDate('HH:mm')
    }
  })
  const { reset } = form

  useEffect(() => {
    if (data) {

      const {
        summary,
        description,
        recurrence,
        recurrenceNumber,
        visibility,
        reminderMinutes,
        start,
        end
      } = data


      reset({
        summary,
        description,
        recurrence,
        recurrenceNumber,
        visibility,
        reminderMinutes,
        day:start,
        start,
        end
      })
    }
  }, [data, reset])

  useEffect(() => {
    if (errorUpdating) {
      handleError(enqueueSnackbar)('Ocorreu um problema ao editar o evento')
    }
  }, [errorUpdating, enqueueSnackbar])

  useEffect(() => {
    if (errorDeleting) {
      handleError(enqueueSnackbar)('Ocorreu um problema ao excluir o evento')
    }
  }, [errorDeleting, enqueueSnackbar])

  const handleClose = () => {
    closeEditEventModal()
  }

  const handleDelete = () => {
    deleteEvent({
      condominiumId,
      eventId: data.id
    }).then(() => {
      handleClose()
      listEvents({ condominiumId })
      track(window.analytics, 'Calendar Event Removed', {
        condominium_id: condominiumId,
        condominium_name: condominiumName,
        ...data
      })
    })
  }

  const handleSubmit = () => {
    form.handleSubmit(({ day, ...values }) => {
      const body = {
        ...values,
        start: CalendarEventList.mergeDates(day, values.start),
        end: CalendarEventList.mergeDates(day, values.end)
      }

      updateEvent({
        condominiumId,
        eventId: data.id,
        body
      }).then(() => {
        handleClose()
        listEvents({ condominiumId })
        track(window.analytics, 'Calendar Event Edited', {
          condominium_id: condominiumId,
          condominium_name: condominiumName,
          ...data.payload
        })
      })
    })()
  }

  return (
    <ProcessDialog
      open={!loading && open}
      onClose={handleClose}
      title={data.summary}
      actions={
        isAdministrator && !isFull && (
          <ActionRow
            loading={updateLoading}
            confirmText='Salvar'
            cancelText='Cancelar'
            onCancel={handleClose}
            onSubmit={handleSubmit}
          />
        )
      }
    >
      <DialogSection>
        <FormProvider {...form}>
          <CalendarEventForm isCreate={false} isDisabled={isFull} />
        </FormProvider>
      </DialogSection>
      {!isFull && (<DialogSection border={1}>
        <Button variant='text' color='danger' onClick={handleDelete}>
          Excluir Evento
        </Button>
      </DialogSection>)}
    </ProcessDialog>
  )
}
