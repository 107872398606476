import { search } from '@condofy/next/service/announcement'
import { pending, store } from './index'

// ACTION TYPES
const ANNOUNCEMENT_LIST = 'ANNOUNCEMENT_LIST'
const ANNOUNCEMENT_LIST_SUCCESS = 'ANNOUNCEMENT_LIST_SUCCESS'

export const listActionTypes = {
  ANNOUNCEMENT_LIST,
  ANNOUNCEMENT_LIST_SUCCESS
}

// ACTIONS
export const listActions = (state, dispatch) => ({
  listAnnouncement: (payload) => dispatch({ type: ANNOUNCEMENT_LIST, payload })
})

// MIDDLEWARE
export const list = async (dispatch, payload) => {
  pending(dispatch, true)
  try {
    const response = await search(payload)

    dispatch({ type: ANNOUNCEMENT_LIST_SUCCESS, payload: response.data })

    return response
  } finally {
    pending(dispatch, false)
  }
}

// REDUCER
export const listReducer = (state, action) => {
  switch (action.type) {
    case ANNOUNCEMENT_LIST_SUCCESS:
      return store(state, {
        items: action.payload.data,
        metadata: {
          ...state.announcement.metadata,
          ...action.payload.paging
        }
      })

    default:
      return state
  }
}
