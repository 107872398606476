import React from 'react'
import { Alert as MuiAlert } from '@mui/material'
import {
  AlertTitle as MatAlertTitle
} from '@mui/lab'
import PropTypes from 'prop-types'
import { Icon } from '../Icon/Icon'
import { colors, shadows, typography } from '../../styles'
import styled from 'styled-components'

const defaultIcons = {
  error: <Icon icon='warning_outlined' />,
  info: <Icon icon='info_outined'></Icon>,
  success: <Icon icon='checkmark_circle_outlined'></Icon>,
  warning: <Icon icon='warning_outlined'></Icon>
}

const StyledAlert = styled(MuiAlert)`
  &.MuiAlert-root {
    box-shadow: ${shadows[0]};
    color: ${colors.dark900};
    font-family: ${typography.type.tertiary};
  }
  &.MuiAlert-standardInfo {
    background-color: ${colors.light100};

    & .MuiAlert-icon {
      color: ${colors.brandBlue300};
    }
  }
  &.MuiAlert-standardWarning {
    background-color: ${colors.warning300};
    color: ${colors.dark900};

    & .MuiAlert-icon {
      color: ${colors.dark900};
    }
  }
  &.MuiAlert-standardError {
    background-color: ${colors.danger300};
    color: ${colors.light100};

    & .MuiAlert-icon {
      color: ${colors.light100};
    }
  }
  &.MuiAlert-standardSuccess {
    background-color: ${colors.success300};
    color: ${colors.light100};

    & .MuiAlert-icon {
      color: ${colors.light100};
    }
  }
  &.MuiAlert-outlined {
    &Warning {
      box-shadow: none;
      background-color: ${colors.warning100};
      color: ${colors.dark700};
      border-color: ${colors.warning700};

      & .MuiAlert-icon {
        color: ${colors.warning700};
      }
    }
    &Info {
      box-shadow: none;
      background-color: ${colors.light100};
      border-color: ${colors.brandBlue300};

      & .MuiAlert-icon {
        color: ${colors.brandBlue300};
      }
    }
    &Error {
      box-shadow: none;
      background-color: ${colors.danger100};
      border-color: ${colors.danger700};

      & .MuiAlert-icon {
        color: ${colors.danger700};
      }
    }
    &Success {
      box-shadow: none;
      background-color: ${colors.success100};
      border-color: ${colors.success700};

      & .MuiAlert-icon {
        color: ${colors.success700};
      }
    }
  }
`

export const Alert = ({ iconMapping = {}, ...props }) => {
  const im = Object.assign(defaultIcons, iconMapping)

  return <StyledAlert iconMapping={im} {...props} />
}
export const AlertTitle = (props) => <MatAlertTitle {...props} />

Alert.propTypes = {
  action: PropTypes.node,
  severity: PropTypes.oneOf(['error', 'warning', 'success', 'info']),
  icon: PropTypes.node,
  iconMapping: PropTypes.shape({
    error: PropTypes.element,
    info: PropTypes.element,
    success: PropTypes.element,
    warning: PropTypes.element
  }),
  variant: PropTypes.oneOf(['filled', 'outlined', 'standard'])
}

Alert.defaultProps = {
  severity: 'info'
}
