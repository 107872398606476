import React from 'react'
import { track } from '@condofy/next/service/segment'
import { Actions } from '@Redux/finance'
import { MonthNavigator } from '@condofy/components'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

const capitalize = (s) =>
  typeof s !== 'string' ? '' : s.charAt(0).toUpperCase() + s.slice(1)

export const FinancialStatementDateNavigator = ({ type }) => {
  const dispatch = useDispatch()
  const { currentMonth } = useSelector(({ finance }) => ({
    currentMonth: finance[`${type}Month`]
  }))

  const onChange = (date) => {
    track(window.analytics, 'Balance Filtered', { date, type })
    dispatch(
      Actions[`finance${capitalize(type)}SelectMonth`]({
        date: date,
        type
      })
    )
  }

  return <MonthNavigator value={currentMonth} onChange={onChange} />
}
FinancialStatementDateNavigator.propTypes = {
  type: PropTypes.oneOf(['toDetailed', 'toPay', 'toReceive'])
}
