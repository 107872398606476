import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { Modal } from '@condofy/next/components'
import {
  colors,
  Heading4,
  Body,
  SmallBody,
  FlexRowCentered
} from '@condofy/components'
import CloudDownloadRoundedIcon from '@mui/icons-material/CloudDownloadRounded'
import { RecordVoiceOver } from '@mui/icons-material'
import { ModalContent } from '../../next/components/Modal'

import { Heading6, Button, Flex as BaseFlex } from '@condofy/components'
import { Box } from '@mui/material'
import _ from 'lodash'

const ComentCall = styled.div`
  em,
  strong,
  h1,
  h2,
  h3,
  h4,
  h5,
  li,
  span,
  ul,
  ol,
  u,
  em,
  a,
  h6 {
    all: revert;
  }
`

const TitleFileCall = styled(Body)`
  margin-left: 5px;
  line-height: 24px;
  color: ${colors.dark300};
`

const Spacer = styled.span`
  width: 5px;
`

const Flex = styled(BaseFlex)`
  margin-top: 12px;
`

export const AnnouncementMessageModal = ({ close, options }) => {
  const { isOpen } = useSelector(({ modal }) => ({
    isOpen: modal.isModalOpen
  }))
  const [showAll, setShowAll] = useState(false)
  const [visibleReceivers, setVisibleReceivers] = useState([])

  const handleShowMore = () => {
    setShowAll(true)
    setVisibleReceivers(options.receivers)
  }

  useEffect(() => {
    const show = options.receivers.length < 2
    setShowAll(show)
    setVisibleReceivers(
      options.receivers.slice(0, show ? options.receivers.length : 2)
    )
  }, [options.receivers])

  return (
    <Modal
      icon={<RecordVoiceOver />}
      title='Comunicado'
      open={isOpen}
      onClose={close}
    >
      <ModalContent>
        <Heading4>{options.title}</Heading4>
        <SmallBody>{options.subject ? options.subject : <span />}</SmallBody>
        <ComentCall
          dangerouslySetInnerHTML={{ __html: `${options.content}` }}
        />
        {options.attachmentUrl.length ? (
          <a
            href={options.attachmentUrl[0].url}
            target='_blank'
            rel='noopener noreferrer'
          >
            <FlexRowCentered>
              <CloudDownloadRoundedIcon />
              <Spacer />
              <TitleFileCall>Baixar Anexo</TitleFileCall>
            </FlexRowCentered>
          </a>
        ) : (
          <span />
        )}
        {!_.isEmpty(options.receivers) ? (
          <Flex direction='column' spacingY={1}>
            <Heading6>Informação dos recebedores</Heading6>
            {visibleReceivers.map((receiver) => (
              <span key={receiver.id}>
                {receiver.name} - {receiver.id}
              </span>
            ))}
            {!showAll && (
              <Box textAlign='center'>
                <Button color='light' onClick={handleShowMore}>
                  Mais informações
                </Button>
              </Box>
            )}
          </Flex>
        ) : (
          <Flex direction='column' spacingY={1}>
            <Heading6>Informação dos recebedores</Heading6>
            <span>Comunicado não foi enviado a nenhum recebedor.</span>
          </Flex>
        )}
      </ModalContent>
    </Modal>
  )
}
