import React, { forwardRef, useCallback, useEffect, useState } from 'react'
import {
  Button,
  Card,
  CardHeader,
  CardContent,
  Flex,
  Pagination,
  DataTable,
  DateText,
  MenuItem,
  ListItemIcon,
  Icon,
  ListItemText,
  colors
} from '@condofy/components'
import { useUserContext } from '@condofy/next/store/user'
import { track } from '@condofy/next/service/segment'
import styled from 'styled-components'
import { condofyProtected } from '../../service/instance'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material'
import { useSnackbar, handleError } from '@condofy/next/hooks/useSnackbar'

const ActionMenuItem = forwardRef(({ label, icon, color, ...props }, ref) => (
  <StyledMenuItem ref={ref} color={color} {...props}>
    <ListItemIcon
      style={{
        width: '100%',
        justifyContent: 'center',
        alignContent: 'right',
        alignItems: 'right'
      }}
    >
      <Icon fontSize='small' icon={icon} />
    </ListItemIcon>
    <ListItemText primary={label} />
  </StyledMenuItem>
))

const StyledMenuItem = styled(MenuItem)`
  .MuiListItemIcon-root,
  .MuiListItemText-primary {
    ${({ color }) => color && ` color: ${color}`}
  }
`

export const AnnouncementModelList = (props) => {
  const [page, setPage] = useState(1)
  const [openDeleteSurvey, setOpenDeleteSurvey] = useState(false)
  const [surveyData, setSurveyData] = useState()
  const ref = React.createRef()
  const { enqueueSnackbar } = useSnackbar()

  const {
    state: {
      currentCondominium,
      id,
      isSuperAdmin,
      isAdmin,
      announcementModel: { items, metadata }
    },
    actions: {
      listAnnouncementModel,
      openCreateAnnouncementModelModal,
      openEditAnnouncementModelModal
    }
  } = useUserContext()
  const stableListAnnouncementModel = useCallback(listAnnouncementModel, [])

  const handleAnnouncementClick = (announcementModel) => {
    track(
      window.analytics,
      'Announcement Template Detail Open',
      announcementModel
    )
    openEditAnnouncementModelModal(announcementModel)
  }

  const onSubmit = async () => {
    condofyProtected
      .delete(
        `/v1/condominiums/${currentCondominium.id}/announcements-model/${surveyData.id}`
      )
      .then(() => {
        setSurveyData()
        setOpenDeleteSurvey(false)
        enqueueSnackbar('Modelo removido com sucesso', {
          variant: 'success'
        })
        listAnnouncementModel({ currentCondominium })
      })
      .catch(handleError(enqueueSnackbar))
  }

  useEffect(() => {
    stableListAnnouncementModel({ currentCondominium, PageNumber: page })
  }, [currentCondominium, page, stableListAnnouncementModel])

  const columns = [
    {
      headerName: 'Nome',
      field: 'title'
    },
    {
      headerName: 'Autor',
      valueGetter: ({ row }) => row.createdBy?.name
    },
    {
      headerName: 'Data',
      renderCell: ({ row }) => <DateText format='long' date={row.createdAt} />
    },
    {
      headerName: '',
      renderCell: ({ row }) => {
        if (id === row.createdBy.id || isSuperAdmin || isAdmin) {
          return (
            <ActionMenuItem
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '25%',
                marginLeft: '75%',
                borderRadius: 5
              }}
              ref={ref}
              icon='delete_outlined'
              color={colors.danger400}
              onClick={(e) => {
                e.stopPropagation()
                setSurveyData(row)
                setOpenDeleteSurvey(true)
              }}
            />
          )
        }
      }
    }
  ]
  return (
    <>
      <Card {...props}>
        <Dialog
          open={openDeleteSurvey}
          onClose={() => setOpenDeleteSurvey(false)}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle id='alert-dialog-title'>Remover Modelo</DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-description'>
              Deseja remover a modelo?<br></br>
              <b>Atenção! Essa ação é irreversível.</b>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <div
              style={{
                justifyContent: 'space-between',
                display: 'flex',
                width: '100%',
                padding: '12px'
              }}
            >
              <Button
                color='secondary'
                style={{ height: '100%' }}
                disabled={false}
                onClick={() => setOpenDeleteSurvey(false)}
              >
                Cancelar
              </Button>

              <Button
                color='light'
                style={{ background: 'red', color: 'white', height: '100%' }}
                disabled={false}
                onClick={onSubmit}
              >
                Remover
              </Button>
            </div>
          </DialogActions>
        </Dialog>
        <CardHeader
          title='Modelos'
          action={
            <Button onClick={openCreateAnnouncementModelModal}>+ Novo</Button>
          }
        />
        <CardContent>
          <DataTable
            size='small'
            variant='borderless'
            loading={metadata.loading}
            columns={columns}
            rows={items}
            onRowClick={handleAnnouncementClick}
          />
        </CardContent>
      </Card>
      <Flex width='100%' justify='flex-end'>
        <Pagination
          page={page}
          totalResults={metadata.count}
          pageSize={metadata.limit}
          onChange={(event, page) => setPage(page)}
          disabled={metadata.loading}
        />
      </Flex>
    </>
  )
}
