import React from 'react'
import { DateTimePicker as MuiDateTimePicker } from '@mui/x-date-pickers'
import { options, dateTimeStyles as styles } from './config'
import styled from 'styled-components'

const StyledDateTimePicker = styled(MuiDateTimePicker)`
  ${styles}
`

export const DateTimePicker = ({
  inputVariant = 'outlined',
  format = 'dd/MM/yyyy HH:mm',
  cancelLabel = 'Cancelar',
  ...args
}) => (
  <StyledDateTimePicker
    inputVariant={inputVariant}
    cancelLabel={cancelLabel}
    format={format}
    {...args}
  />
)
DateTimePicker.propTypes = { ...options.propTypes }
