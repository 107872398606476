import React from 'react'
import { useUserContext } from '@condofy/next/store/user'
import { UserListItem } from './UserListItem'
import { DataTable, Flex, Pagination } from '@condofy/components'

export const UserList = ({
  items = [],
  loading,
  page,
  setPage,
  totalRecords,
  recordsPerPage
}) => {
  const {
    state: { isSuperAdmin },
    actions: { openEditUserModal, openDetailsUserModal }
  } = useUserContext()

  const handleItemClick = (user) => () => {
    isSuperAdmin ? openEditUserModal(user) : openDetailsUserModal(user)
  }
  const columns = [
    { field: 'name', headerName: 'Nome' },
    { field: 'condominiums', headerName: 'Condomínios' },
    { field: 'units', headerName: 'Unidades' },
    { field: 'cpf', headerName: 'CPF' },
    { field: 'email', headerName: 'Email' },
    { field: 'phone', headerName: 'Telefone' },
    { expand: true }
  ]

  return (
    <Flex direction='column' spacingY={3}>
      <DataTable
        variant='borderless'
        size='small'
        columns={columns}
        loading={loading}
      >
        {items.map((user) => (
          <UserListItem
            onClick={handleItemClick(user)}
            key={user.id}
            user={user}
          />
        ))}
      </DataTable>
      <Flex width='100%' justify='flex-end'>
        <Pagination
          totalResults={totalRecords}
          pageSize={recordsPerPage}
          page={page}
          disabled={loading}
          onChange={(event, page) => setPage(page)}
        />
      </Flex>
    </Flex>
  )
}
