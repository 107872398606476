import axios from 'axios'
import { normalizeObject } from '~/lib/util/normalizeObject'
import { token } from '@condofy/next/service/user'

const baseURL = process.env.REACT_APP_API_HOST

export default class Service {
  constructor() {
    this.baseURL = baseURL
  }

  async post({ url, error }, body = {}, isAuth = true) {
    try {
      const { data } = await axios.post(
        this.baseURL + url,
        body,
        this.setHeader(isAuth)
      )
      return this.handlingResponse(data)
    } catch (e) {
      throw this.handlingError(e, error)
    }
  }

  async put({ url, error }, body = {}, isAuth = true) {
    try {
      const { data } = await axios.put(
        this.baseURL + url,
        body,
        this.setHeader(isAuth)
      )
      return this.handlingResponse(data)
    } catch (e) {
      throw this.handlingError(e, error)
    }
  }

  async get({ url, error }, params = {}, isAuth = true) {
    try {
      const { data } = await axios.get(this.baseURL + url, {
        params,
        ...this.setHeader(isAuth)
      })
      return this.handlingResponse(data)
    } catch (e) {
      throw this.handlingError(e, error)
    }
  }

  async delete({ url, error }, params = {}, isAuth = true) {
    try {
      const { data } = await axios.delete(this.baseURL + url, {
        params,
        ...this.setHeader(isAuth)
      })
      return this.handlingResponse(data)
    } catch (e) {
      throw this.handlingError(e, error)
    }
  }

  async formData({ url, error }, body = {}, isAuth = true) {
    try {
      const { data } = await axios.post(
        this.baseURL + url,
        this.jsonToFormData(body),
        this.setHeader(isAuth, true)
      )
      return this.handlingResponse(data)
    } catch (e) {
      throw this.handlingError(e, error)
    }
  }

  async updateFormData({ url, error }, body = {}, isAuth = true) {
    try {
      const { data } = await axios.put(
        this.baseURL + url,
        this.jsonToFormData(body),
        this.setHeader(isAuth, true)
      )
      return this.handlingResponse(data)
    } catch (e) {
      throw this.handlingError(e, error)
    }
  }

  jsonToFormData(object) {
    const formData = new FormData()
    Object.keys(object).forEach((key) =>
      Array.isArray(object[key])
        ? object[key].map((a) => formData.append(key, a))
        : formData.append(key, object[key])
    )
    return formData
  }

  setHeader(isAuth, isFormData = false) {
    return {
      headers: {
        'Content-Type': isFormData ? 'multipart/form-data' : 'application/json',
        Accept: 'application/json',
        Authorization: isAuth ? `Bearer ${token}` : ''
      }
    }
  }

  handlingResponse(unnormalizedResponse) {
    const { reports, ...response } = normalizeObject(unnormalizedResponse)
    if (reports && reports.length) throw reports
    else return response || {}
  }

  handlingError(_err, defaultError) {
    const err = _err?.response?.data?.reports
    if (err && err.length) return err
    else
      return [
        {
          code: 0,
          field: 'global',
          message: defaultError
        }
      ]
  }
}
