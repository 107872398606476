import axios from 'axios'
import { token } from '@condofy/next/service/user'
import { removeToken } from '@condofy/next/vendor/@condofy/token'
import { redirectToAuth } from '@condofy/next/vendor/@condofy/auth'
import { reset } from '@condofy/next/service/segment'

const baseURL = process.env.REACT_APP_API_HOST

export const logoutWithInvalidToken = () => {
  reset(window.analytics)
  localStorage.clear()
  removeToken()
  redirectToAuth()
}

export const condofy = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`
  }
})

export const condofyPfd = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/pdf',
    Authorization: `Bearer ${token}`
  },
  responseType: 'blob'
})

const condofyProtected = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`
  }
})

export const condofyProtectedMultiPart = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'multipart/form-data',
    Authorization: `Bearer ${token}`
  }
})

condofyProtected.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    if (error.response.status === 401) {
      logoutWithInvalidToken()
    }

    return Promise.reject(error)
  }
)

export { condofyProtected }
