import React from 'react'
import { Typography as MuiTypography } from '@mui/material'
import styled from 'styled-components'
import { typography, pxToRem, colors } from '../../styles'
import PropTypes from 'prop-types'

export const Typography = styled(MuiTypography)`
  &.MuiTypography {
    &-root {
      ${({ width }) => width && ` width: ${width};`}
      font-family: inherit;
    }
    &-h1 {
      font-family: ${typography.type.secondary};
      font-size: ${pxToRem(typography.size.h1)}rem;
      font-weight: 600;
    }
    &-h2 {
      font-family: ${typography.type.secondary};
      font-size: ${pxToRem(typography.size.h2)}rem;
      font-weight: 600;
    }
    &-h3 {
      font-family: ${typography.type.secondary};
      font-size: ${pxToRem(typography.size.h3)}rem;
      font-weight: 600;
    }
    &-h4 {
      font-family: ${typography.type.secondary};
      font-size: ${pxToRem(typography.size.h4)}rem;
      font-weight: 600;
    }
    &-h5 {
      font-family: ${typography.type.secondary};
      font-size: ${pxToRem(typography.size.h5)}rem;
      font-weight: 600;
    }
    &-h6 {
      font-family: ${typography.type.secondary};
      font-size: ${pxToRem(typography.size.h6)}rem;
      font-weight: 600;
    }
    &-button {
      text-transform: none;
      font-size: ${pxToRem(typography.size.button)}rem;
    }
    &-colorTextPrimary {
      color: ${colors.primaryDarker};
    }
    &-colorTextSecondary {
      color: ${colors.light600};
    }
    &-colorError {
      color: ${colors.danger500};
    }
  }
`
Typography.propTypes = {
  width: PropTypes.any,
  noWrap: PropTypes.bool
}

export const Heading1 = (args) => <Typography variant='h1' {...args} />

export const Heading2 = (args) => <Typography variant='h2' {...args} />

export const Heading3 = (args) => <Typography variant='h3' {...args} />

export const Heading4 = (args) => <Typography variant='h4' {...args} />

export const Heading5 = (args) => <Typography variant='h5' {...args} />

export const Heading6 = (args) => <Typography variant='h6' {...args} />

export const Body = (args) => <Typography variant='body1' {...args} />

export const SmallBody = (args) => <Typography variant='body2' {...args} />

export const Caption = (args) => <Typography variant='caption' {...args} />

export const MediumLabel = styled(Typography)`
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.2px;
`

export const LargeLabel = styled(Typography)`
  font-size: ${typography.size.smallBody}px;
  font-weight: 600;
`

export const SmallLabel = styled(Typography)`
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 0.75px;
  text-transform: uppercase;
`
