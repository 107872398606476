import React from 'react'
import {
  EmptyListMessage,
  CardGrid,
  Button,
  Pagination,
  Flex
} from '@condofy/components'
import { ListingCard } from './ListingCard'
import { useCommunityContext } from '@condofy/next/store/community'
import { useUserContext } from '@condofy/next/store/user'
import { ListingStatusActionMenuAdmin } from './ListingStatusActionMenuAdmin'

const EmptyFeedback = () => (
  <EmptyListMessage
    message={
      <>
        <p style={{ fontWeight: 600 }}>Objeto não encontrado</p>
        <p>
          Você pode criar uma postagem na comunidade para verificar se algum
          vizinho pode lhe ajudar.
        </p>
      </>
    }
  />
)

const Actions = ({ listing, text }) => {
  const {
    actions: { openDetailModal }
  } = useCommunityContext()

  const {
    state: { currentCondominium, isAdmin, isSuperAdmin }
  } = useUserContext()

  const handleOpen = () => {
    openDetailModal({ currentCondominium, item: listing })
  }

  return (
    <>
      <Button color='primary' fullWidth onClick={handleOpen}>
        {text === 1 ? 'Eu quero' : text === 2 ? 'Contratar' : 'Comprar'}
      </Button>
      {(isAdmin || isSuperAdmin) && (
        <ListingStatusActionMenuAdmin listing={listing} />
      )}
    </>
  )
}

export const MarketList = ({
  listings = [],
  loading,
  page,
  onPageChange,
  args
}) => {
  const {
    state: {
      listListings: {
        metadata: { totalRecords, recordsPerPage }
      }
    }
  } = useCommunityContext()

  return (
    <Flex direction='column' spacingY={3}>
      <div>
        <CardGrid loading={loading} EmptyFeedback={<EmptyFeedback />} {...args}>
          {listings.map((item, index) => (
            <ListingCard
              listing={item}
              key={index}
              Actions={<Actions listing={item} text={item.chargerType} />}
              // Actions={<ListingStatusActionMenu listing={item} />}
            />
          ))}
        </CardGrid>
      </div>
      <Flex width='100%' justify='flex-end'>
        <Pagination
          page={page}
          totalResults={totalRecords}
          pageSize={recordsPerPage}
          onChange={(event, page) => onPageChange(page)}
          disabled={loading}
        />
      </Flex>
    </Flex>
  )
}
