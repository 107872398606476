import { combineReducers } from '@condofy/next/store/user/combineReducers'
import {
  createActions,
  create,
  createReducer,
  createActionTypes
} from './create'
import { listActions, list, listReducer, listActionTypes } from './list'

// ACTION TYPES
const CONNECTION_SET_LOADING_INDICATOR = 'CONNECTION_SET_LOADING_INDICATOR'
const CONNECTION_SELECT = 'CONNECTION_SELECT'

// ACTIONS
export const connectionActions = (state, dispatch) => ({
  ...createActions(state, dispatch),
  ...listActions(state, dispatch),

  selectConnection: (payload) => dispatch({ type: CONNECTION_SELECT, payload })
})

export const pending = (dispatch, payload) =>
  dispatch({ type: CONNECTION_SET_LOADING_INDICATOR, payload })

// MIDDLEWARE
export const connectionMiddlewares = (dispatch, action) => {
  switch (action.type) {
    case createActionTypes.CONNECTION_CREATE:
      return create(dispatch, action.payload)

    case listActionTypes.CONNECTION_LIST:
      return list(dispatch, action.payload)

    default:
      return false
  }
}

// REDUCER
const connectionMainReducer = (state, action) => {
  switch (action.type) {
    case CONNECTION_SELECT:
      return store(state, { selected: action.payload })

    case CONNECTION_SET_LOADING_INDICATOR:
      return store(state, {
        metadata: {
          ...state.connection.metadata,
          loading: action.payload
        }
      })

    default:
      return state
  }
}

export const connectionReducer = combineReducers({
  connectionMainReducer,
  createReducer,
  listReducer
})

export const store = (state, value) => ({
  ...state,
  connection: { ...state.connection, ...value }
})

export const connection = {
  userModalOpen: false,
  createModalOpen: false,
  deleteModalOpen: false,
  editModalOpen: false,
  selected: {},
  items: [],
  metadata: {
    pageNumber: 1,
    totalRecords: 0,
    recordsPerPage: 10,
    loading: false
  }
}

export const connectionActionTypes = {
  CONNECTION_SET_LOADING_INDICATOR,
  CONNECTION_SELECT,

  ...createActionTypes
}
