import React from 'react'
import { MenuItem } from '@mui/material'
import { TextField } from '@condofy/components'

const itemArray = [
  { children: 'Acre', value: 'AC', key: 'AC' },
  { children: 'Alagoas', value: 'AL', key: 'AL' },
  { children: 'Amapá', value: 'AP', key: 'AP' },
  { children: 'Amazonas', value: 'AM', key: 'AM' },
  { children: 'Bahia', value: 'BA', key: 'BA' },
  { children: 'Ceará', value: 'CE', key: 'CE' },
  { children: 'Distrito Federal', value: 'DF', key: 'DF' },
  { children: 'Espírito Santo', value: 'ES', key: 'ES' },
  { children: 'Goiás', value: 'GO', key: 'GO' },
  { children: 'Maranhão', value: 'MA', key: 'MA' },
  { children: 'Mato Grosso', value: 'MT', key: 'MT' },
  { children: 'Mato Grosso do Sul', value: 'MS', key: 'MS' },
  { children: 'Minas Gerais', value: 'MG', key: 'MG' },
  { children: 'Pará', value: 'PA', key: 'PA' },
  { children: 'Paraíba', value: 'PB', key: 'PB' },
  { children: 'Paraná', value: 'PR', key: 'PR' },
  { children: 'Pernambuco', value: 'PE', key: 'PE' },
  { children: 'Piauí', value: 'PI', key: 'PI' },
  { children: 'Rio de Janeiro', value: 'RJ', key: 'RJ' },
  { children: 'Rio Grande do Norte', value: 'RN', key: 'RN' },
  { children: 'Rio Grande do Sul', value: 'RS', key: 'RS' },
  { children: 'Rondônia', value: 'RO', key: 'RO' },
  { children: 'Roraima', value: 'RR', key: 'RR' },
  { children: 'Santa Catarina', value: 'SC', key: 'SC' },
  { children: 'São Paulo', value: 'SP', key: 'SP' },
  { children: 'Sergipe', value: 'SE', key: 'SE' },
  { children: 'Tocantins', value: 'TO', key: 'TO' }
]

export const ProvinceSelector = (props) => (
  <TextField select {...props}>
    {itemArray.map((item) => (
      <MenuItem key={item.key} {...item} />
    ))}
  </TextField>
)
