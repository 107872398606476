import { condofyProtected } from './instance'

export const createConnection = ({ user, condominium, unitId, userTypeId }) =>
  condofyProtected.post('/v1/connections/requests', {
    userId: user.id,
    condominiumId: condominium.id,
    unitId,
    userTypeId
  })

export const search = (
  payload = { currentCondominium: {}, PageNumber: 1, generalSearch: '' }
) =>
  condofyProtected.post(
    '/v1/connections/search',
    {
      condominiumId: payload.currentCondominium.id,
      isApproved: false,
      generalSearch: payload.generalSearch
    },
    {
      params: {
        PageNumber: payload.PageNumber
      }
    }
  )

export const approveConnection = ({ connection }) =>
  condofyProtected.post(`/v1/connections/${connection.id}/approve`)

export const disapproveConnection = ({ connection }) =>
  condofyProtected.post(`/v1/connections/${connection.id}/disapprove`)
