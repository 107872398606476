import React, { useState } from 'react'
import { LoadingButton } from '@condofy/components'

import { BanksIntegrationDialog } from './belvoIntegrationsDialog'

export const BanksIntegrationDialogTrigger = ({
  callbackFn,
  loading,
  banksList = []
}) => {
  const [isOpen, setIsOpen] = useState()

  return (
    <>
      <LoadingButton
        color='green'
        style={{
          flexShrink: 0
        }}
        onClick={() => setIsOpen(!isOpen)}
      >
        Ver integrações
      </LoadingButton>
      {isOpen && (
        <BanksIntegrationDialog
          setIsOpen={setIsOpen}
          callbackFn={callbackFn}
          loading={loading}
          banksList={banksList}
        />
      )}
    </>
  )
}
