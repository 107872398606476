import React, { useState } from 'react'
import {
  Calendar as BigCalendar,
  momentLocalizer,
  Views
} from 'react-big-calendar'
import moment from 'moment'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import './react-big-calendar.css'
import { CalendarToolbar } from './CalendarToolbar'
import {
  calendar,
  header,
  calendarCells,
  month,
  agenda,
  week,
  event
} from './styles'
import { messages as ptMessages } from './messages'
import { CircularProgress } from '@mui/material'
import { ProgressOverlay } from '../Progress'

const Container = styled.div`
  position: relative;
`

const StyledCalendar = styled(BigCalendar)`
  ${calendar}
  ${header}
  ${calendarCells}
  ${month}
  ${agenda}
  ${week}
  ${event}

  ${({ $multiDaySelection }) =>
    !$multiDaySelection &&
    `
    & .rbc-month-view {
      & .rbc-selected-cell {
        background-color: #fff;
      }

      & .rbc-today.rbc-selected-cell {
        background-color: '#eaf6ff';
      }
      & .rbc-off-range-bg.rbc-selected-cell {
        background: #e6e6e6;
      }
      & .rbc-month-row {
        cursor: pointer;
      }
    }
  `}
`

const localizer = momentLocalizer(moment)

export const Calendar = ({
  events = [],
  culture = 'pt-br',
  defaultDate = new Date(),
  defaultView = 'month',
  components = {},
  messages = ptMessages,
  multiDaySelection = true,
  onSelectSlot = () => {},
  onView = () => {},
  showDatePicker = true,
  datePickerProps = {},
  loading = false,
  ...props
}) => {
  const [view, setView] = useState(props.view || defaultView)
  let handleSelectSlot = multiDaySelection
    ? onSelectSlot
    : (slotInfo) => {
        if (
          view !== Views.MONTH ||
          (view === Views.MONTH && slotInfo.action === 'click')
        ) {
          onSelectSlot(slotInfo)
        }
      }
  const handleViewChange = (view) => {
    setView(view)
    onView(view)
  }

  return (
    <Container>
      <StyledCalendar
        localizer={localizer}
        startAccessor='start'
        endAccessor='end'
        defaultDate={defaultDate}
        culture={culture}
        events={events}
        defaultView={defaultView}
        components={{
          // eslint-disable-next-line react/display-name
          toolbar: (props) => (
            <CalendarToolbar
              showDatePicker={showDatePicker}
              datePickerProps={datePickerProps}
              {...props}
            />
          ),
          ...components
        }}
        messages={messages}
        onSelectSlot={handleSelectSlot}
        onView={handleViewChange}
        $multiDaySelection={multiDaySelection}
        {...props}
      />
      {loading && (
        <ProgressOverlay>
          <CircularProgress />
        </ProgressOverlay>
      )}
    </Container>
  )
}

const allViews = ['month', 'week', 'work_week', 'day', 'agenda']

Calendar.propTypes = {
  events: PropTypes.array,
  views: PropTypes.arrayOf(PropTypes.oneOf(allViews)),
  defaultView: PropTypes.oneOf(allViews),
  components: PropTypes.shape({
    event: PropTypes.elementType,
    eventWrapper: PropTypes.elementType,
    eventContainerWrapper: PropTypes.elementType,
    dateCellWrapper: PropTypes.elementType,
    dayColumnWrapper: PropTypes.elementType,
    timeSlotWrapper: PropTypes.elementType,
    timeGutterHeader: PropTypes.elementType,
    resourceHeader: PropTypes.elementType,
    toolbar: PropTypes.elementType,
    agenda: PropTypes.shape({
      date: PropTypes.elementType,
      time: PropTypes.elementType,
      event: PropTypes.elementType
    }),
    day: PropTypes.shape({
      header: PropTypes.elementType,
      event: PropTypes.elementType
    }),
    week: PropTypes.shape({
      header: PropTypes.elementType,
      event: PropTypes.elementType
    }),
    month: PropTypes.shape({
      header: PropTypes.elementType,
      dateHeader: PropTypes.elementType,
      event: PropTypes.elementType
    })
  }),
  multiDaySelection: PropTypes.bool,
  showDatePicker: PropTypes.bool,
  datePickerProps: PropTypes.object,
  loading: PropTypes.bool
}

export { Views }
