import React from 'react'
import { Typography } from '../Typography'
import { DataTableCell } from './DataTableCell'
import { DataTableRow } from './DataTableRow'

export const EmptyRow = ({ colSpan, value }) => (
  <DataTableRow>
    <DataTableCell colSpan={colSpan}>
      <Typography variant='body2' color='textSecondary'>
        {value}
      </Typography>
    </DataTableCell>
  </DataTableRow>
)
