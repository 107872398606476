const levels = [
  {
    label: 'Comercial',
    value: '1'
  },
  {
    label: 'Residencial',
    value: '2'
  },
  {
    label: 'Outro',
    value: '3'
  }
]

module.exports = {
  levels,
  getValues() {
    return levels.map((t) => t.value)
  }
}
