import React from 'react'
import styled from 'styled-components'
import { Mobile, Email } from 'Common/Icons'
import {
  FlexRowCentered,
  DataTableRow,
  DataTableCell,
  TextTruncate,
  ButtonMenu as Menu,
  MenuItem
} from '@condofy/components'
import { useSnackbar, handleError } from '@condofy/next/hooks/useSnackbar'
import { useUserContext } from '@condofy/next/store/user'
import {
  promoteSuperAdmin,
  removeSuperAdmin,
  userLocked,
  userUnlocked,
  restoreDeletedUser
} from '@condofy/next/service/user'
import { phoneMask, cpfMask } from '@condofy/next/containers'

const Description = styled.span`
  margin-left: 8px;
  white-space: nowrap;
`

export const UserListItem = ({ user, ...other }) => {
  const {
    state: { isSuperAdmin, currentCondominium },
    actions: {
      openEditUserModal,
      openDeleteUserModal,
      openCreateUserConnectionModal
    }
  } = useUserContext()
  const { enqueueSnackbar } = useSnackbar()

  const isPhysicalDeletion = true

  const onDelete = () => openDeleteUserModal(user)
  const onDeletePhysical = () =>
    openDeleteUserModal((user = { ...user, isPhysicalDeletion }))
  const onEdit = () => openEditUserModal(user)

  const handleNewConnection = () => {
    openCreateUserConnectionModal(user)
  }

  const handleGiveAdmin = () => {
    promoteSuperAdmin(user.id)
      .then(() => {
        enqueueSnackbar('Usuário alterado para Super Admin com sucesso.', {
          variant: 'success'
        })
      })
      .catch(handleError(enqueueSnackbar))
  }

  const handletakeAdmin = () => {
    removeSuperAdmin(user.id)
      .then(() => {
        enqueueSnackbar('Usuário removido do Super Admin com sucesso.', {
          variant: 'success'
        })
      })
      .catch(handleError(enqueueSnackbar))
  }

  const handleLocked = () => {
    userLocked(user.id)
      .then(() => {
        enqueueSnackbar('Usuário bloqueado com sucesso.', {
          variant: 'success'
        })
      })
      .catch(handleError(enqueueSnackbar))
  }

  const handleUnlocked = () => {
    userUnlocked(user.id)
      .then(() => {
        enqueueSnackbar('Usuário desbloqueado com sucesso.', {
          variant: 'success'
        })
      })
      .catch(handleError(enqueueSnackbar))
  }

  const handleReactivate = () => {
    restoreDeletedUser({ payload: { id: currentCondominium.id, userId: user.id } })
      .then(() => {
        enqueueSnackbar('Usuário reativado com sucesso.', {
          variant: 'success'
        })
      })
      .catch(handleError(enqueueSnackbar))
  }

  const userCondminiums = user.condominiums.map(
    (currentCondominium) => currentCondominium.name
  )

  return (
    <DataTableRow {...other}>
      <DataTableCell>{user.name}</DataTableCell>
      <DataTableCell>
        <TextTruncate width='300px'>{userCondminiums.join(', ')}</TextTruncate>
      </DataTableCell>
      <DataTableCell>
        {user.condominiums &&
          user.condominiums.map((condominium) => (
            <TextTruncate width='300px' key={condominium.id}>
              {condominium.units.length
                ? condominium.units.map((unit) => unit?.name)
                : ''}
            </TextTruncate>
          ))}
      </DataTableCell>
      <DataTableCell>
        {user.socialSecurityNumber ? cpfMask(user.socialSecurityNumber) : ''}
      </DataTableCell>
      <DataTableCell>
        <FlexRowCentered>
          <Email color={user.emailConfirmed && !user.isDeleted ? '#6cb543' : user.isDeleted ? '#99a3b5' : '#ff6868'} />{' '}
          <Description>{user.email}</Description>
        </FlexRowCentered>
      </DataTableCell>
      <DataTableCell>
        <FlexRowCentered>
          <Mobile color={user.phoneNumberConfirmed && !user.isDeleted ? '#6cb543' : user.isDeleted ? '#99a3b5' : '#ff6868'} />{' '}
          <Description>
            {user.phoneNumber ? phoneMask(user.phoneNumber) : ''}
          </Description>
        </FlexRowCentered>
      </DataTableCell>
      {isSuperAdmin && (
        <DataTableCell align='right'>
          <Menu>
            <MenuItem onClick={handleNewConnection}>Nova conexão</MenuItem>
            {user.isSuperAdmin ? (
              <MenuItem onClick={handletakeAdmin}>Remover Admin</MenuItem>
            ) : (
              <MenuItem onClick={handleGiveAdmin}>Tornar Admin</MenuItem>
            )}
            {!user.isLocked ? (
              <MenuItem onClick={handleLocked}>Bloquear</MenuItem>
            ) : (
              <MenuItem onClick={handleUnlocked}>Desbloquear</MenuItem>
            )}
            <MenuItem onClick={onEdit}>Editar</MenuItem>
            {!user.isDeleted && <MenuItem onClick={onDelete}>Excluir</MenuItem>}
            {user.isDeleted && <MenuItem onClick={handleReactivate}>Reativar</MenuItem>}
            {isSuperAdmin && (
              <MenuItem onClick={onDeletePhysical}>Exclusão Física</MenuItem>
            )}
          </Menu>
        </DataTableCell>
      )}
    </DataTableRow>
  )
}
