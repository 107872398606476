import { DialogContent as MuiDialogContent } from '@mui/material'
import styled from 'styled-components'
import { theme } from '../../styles'

export const DialogContent = styled(MuiDialogContent)`
  &.MuiDialogContent-root {
    padding: 0 0 ${theme.spacing(3)} 0;
    display: flex;
    flex-direction: column;

    &:first-child {
      padding: 0;
    }
  }

  .MuiDialogTitle-root + & {
    padding-top: ${theme.spacing(3)}!important;
  }
`
