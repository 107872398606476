import React from 'react'
import { useUserContext } from '@condofy/next/store/user'

import { UpdateMessageModal } from './UpdateMessageModal'
import { PublishListModal } from './PublishListModal'

export const AnnouncementEditModal = () => {
  const {
    state: {
      announcement: { editModalOpen, editStep }
    },
    actions: { announcementUpdateEditStep }
  } = useUserContext()
  return (
    <>
      <UpdateMessageModal
        open={editModalOpen && editStep === 1}
        onStepChange={announcementUpdateEditStep}
      />
      <PublishListModal open={editModalOpen && editStep === 2} />
    </>
  )
}
