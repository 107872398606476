import React, { forwardRef, useEffect, useState } from 'react'
import {
  ButtonMenu,
  Flex,
  Icon,
  ListItemIcon,
  ListItemText,
  MenuItem,
  colors
} from '@condofy/components'
import styled from 'styled-components'
import { IconButton } from '@mui/material'
import { useCommunityContext } from '@condofy/next/store/community'
import { useUserContext } from '@condofy/next/store/user'
import { deleteOffer, statusOffer } from '@condofy/next/service/community'

const status = [
  {
    label: 'Indisponível',
    color: colors.yellow500,
    icon: 'pause_circle_outlined'
  },
  {
    label: 'Disponível',
    color: colors.brandBlue600,
    icon: 'play_circle_outlined'
  }
]

const StyledMenuItem = styled(MenuItem)`
  .MuiListItemIcon-root,
  .MuiListItemText-primary {
    ${({ color }) => color && ` color: ${color}`}
  }
`

const ActionMenuItem = forwardRef(({ label, icon, color, ...props }, ref) => (
  <StyledMenuItem ref={ref} color={color} {...props}>
    <ListItemIcon>
      <Icon fontSize='small' icon={icon} />
    </ListItemIcon>
    <ListItemText primary={label} />
  </StyledMenuItem>
))

const MenuButton = ({ onClick = () => {} }) => (
  <IconButton onClick={onClick}>
    <Icon size='small' icon='more_vertical' />
  </IconButton>
)

export const ListingStatusActionMenuAdmin = ({ listing }) => {
  const ref = React.createRef()
  const [availabilityMenuItem, setAvailabilityMenuItem] = useState(status[0])
  const {
    actions: { loadListingList }
  } = useCommunityContext()
  const {
    state: { currentCondominium }
  } = useUserContext()

  useEffect(() => {
    const available = Boolean(listing.status === 1)
    setAvailabilityMenuItem(status[Number(!available)])
  }, [listing.status])

  const handleUnavailable = () => {
    statusOffer({
      currentCondominium,
      payload: { id: listing.id, status: 2 }
    }).then(() => loadListingList({ currentCondominium, status: 1 }))
  }

  const handleDelete = () => {
    const deleteConfirmation = window.confirm(
      'Tem certeza que deseja remover essa oferta?\n\nEsta ação não pode ser revertida.'
    )
    if (deleteConfirmation) {
      deleteOffer({ currentCondominium, id: listing.id }).then(() =>
        loadListingList({ currentCondominium })
      )
    }
  }

  return (
    <Flex width='30%' alignItems='center'>
      <ButtonMenu Button={<MenuButton />}>
        <ActionMenuItem
          ref={ref}
          label={availabilityMenuItem.label}
          icon={availabilityMenuItem.icon}
          color={availabilityMenuItem.color}
          onClick={handleUnavailable}
        />
        <ActionMenuItem
          ref={ref}
          label='Remover'
          icon='delete_outlined'
          color={colors.danger400}
          onClick={handleDelete}
        />
      </ButtonMenu>
    </Flex>
  )
}
