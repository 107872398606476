import { Tooltip } from './Tooltip'
// import { withStyles } from 'tss-react/mui';
import { colors, shadows, theme } from '../../styles'

/* export const LightTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: colors.light100,
    border: `solid 1px ${colors.light400}`,
    boxShadow: shadows[1],
    color: colors.text,
    fontSize: 12,
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`
  },
  arrow: {
    color: colors.light100,
    '&:before': {
      border: `solid 1px ${colors.light400}`
    }
  }
}))(Tooltip) */

const styles = {
  tooltip: {
    backgroundColor: colors.light100,
    border: `solid 1px ${colors.light400}`,
    boxShadow: shadows[1],
    color: colors.text,
    fontSize: 12,
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`
  },
  arrow: {
    color: colors.light100,
    '&:before': {
      border: `solid 1px ${colors.light400}`
    }
  }
}

export const LightTooltip = (props) => Tooltip({...props, styles})