import React, { useState } from 'react'
import { TextField, LoadingButton, Flex, Button } from '@condofy/components'

export const EditablePassword = ({
  label,
  loading,
  onSubmit: emitSubmitEvent = () => {}
}) => {
  const [editable, setEditable] = useState(false)
  const [currentPassword, setCurrentPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmNewPassword, setConfirmNewPassword] = useState('')

  const toggle = () => setEditable(!editable)
  const close = () => {
    setEditable(false)
    setCurrentPassword('')
    setNewPassword('')
    setConfirmNewPassword('')
  }

  const handleSubmit = () =>
    emitSubmitEvent({
      currentPassword,
      newPassword,
      confirmNewPassword
    }).then(close)

  const canSubmit =
    currentPassword && newPassword && confirmNewPassword && !loading

  return (
    <Flex direction='column' spacingY={1}>
      <TextField
        type='password'
        label='Senha atual'
        placeholder='Senha atual'
        disabled={!editable}
        value={currentPassword}
        onChange={(event) => setCurrentPassword(event.target.value)}
      />
      {editable && (
        <>
          <TextField
            type='password'
            label='Nova senha'
            placeholder='Nova senha'
            value={newPassword}
            onChange={(event) => setNewPassword(event.target.value)}
          />
          <TextField
            type='password'
            label='Confirmar senha'
            placeholder='Confirmar senha'
            value={confirmNewPassword}
            onChange={(event) => setConfirmNewPassword(event.target.value)}
          />
        </>
      )}
      <Flex spacingX={2}>
        <Button variant='text' onClick={toggle}>
          {editable ? 'Cancelar' : 'Editar'}
        </Button>
        {editable && (
          <LoadingButton
            loading={loading}
            disabled={!canSubmit}
            onClick={handleSubmit}
          >
            Mudar {label}
          </LoadingButton>
        )}
      </Flex>
    </Flex>
  )
}
