import React from 'react'
import { TabsPanelItem, Page, TabsPanel } from '@condofy/components'
import { FinancialStatementTab } from './FinancialStatementTab'
import { OverviewTab } from './OverviewTab'
import { EditStatementItemCategoryModal } from '@condofy/next/containers/finance'

export const FinancesDashboardPage = () => (
  <Page>
    <TabsPanel>
      <TabsPanelItem label='Geral'>
        <OverviewTab />
      </TabsPanelItem>
      <TabsPanelItem label='Extrato Financeiro'>
        <FinancialStatementTab />
      </TabsPanelItem>
    </TabsPanel>
    <EditStatementItemCategoryModal />
  </Page>
)
