import { createCondominium } from '@condofy/next/service/condo'
import { store } from './index'

// ACTION TYPES
const CONDOMINIUM_CREATE = 'CONDOMINIUM_CREATE'
const CONDOMINIUM_CREATE_MODAL_VISIBLE = 'CONDOMINIUM_CREATE_MODAL_VISIBLE'

// ACTIONS
export const condominiumCreateActions = (state, dispatch) => ({
  createCondominium: (payload) =>
    dispatch({ type: CONDOMINIUM_CREATE, payload }),

  closeCreateCondominiumModal: () =>
    dispatch({ type: CONDOMINIUM_CREATE_MODAL_VISIBLE, payload: false }),

  openCreateCondominiumModal: () =>
    dispatch({ type: CONDOMINIUM_CREATE_MODAL_VISIBLE, payload: true })
})

// MIDDLEWARE
export const saveCondominium = (dispatch, payload) =>
  createCondominium({
    condominium: {
      ...payload,
      ...payload.address,
      typeId: payload.type.id,
      planId: payload.plan.id,
      cnpj: payload.cnpj.replace(/[//.-]/gi, ''),
      zipCode: payload.address.zipCode.replace('-', '').replace('.', '')
    }
  })

export const createActionTypes = {
  CONDOMINIUM_CREATE,
  CONDOMINIUM_CREATE_MODAL_VISIBLE
}

// REDUCER
export const condominiumCreateReducer = (state, action) => {
  switch (action.type) {
    case CONDOMINIUM_CREATE_MODAL_VISIBLE:
      return store(state, {
        createModalOpen: action.payload
      })

    default:
      return state
  }
}
