import React, { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useCommunityContext } from '@condofy/next/store/community'
import { Modal, ActionRow, ModalContent } from '@condofy/next/components'
import { useUserContext } from '@condofy/next/store/user'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormContainer } from '@condofy/components'
import { ListingForm } from './ListingForm'
import { listingSchema } from './listingSchema'

export const EditListingModal = () => {
  const {
    state: {
      updateListing: {
        modal: { open }
      },
      detailListing: { data, metadata }
    },
    actions: { closeUpdateListingModal, updateListing, loadListingList }
  } = useCommunityContext()

  const {
    state: { currentCondominium, id }
  } = useUserContext()

  const form = useForm({
    mode: 'onChange',
    resolver: yupResolver(listingSchema),
    defaultValues: {
      title: '',
      description: '',
      chargerType: '',
      price: '',
      files: []
    }
  })

  useEffect(() => {
    if (data.id) {
      Object.keys(data).map((k) => form.setValue(k, data[k]))
      // form.reset(data)
    }
  }, [data, form])

  const handleClose = () => {
    form.reset()
    closeUpdateListingModal()
  }

  const handleSubmit = () => {
    form.handleSubmit((values) => {
      updateListing({
        currentCondominium,
        payload: {
          ...values,
          price:
            values.chargerType === 1
              ? 0
              : values.price.toString().replace('.', ','),
          status: 1,
          id: data.id
        }
      }).then(() => {
        handleClose()
        loadListingList({ currentCondominium, membershipId: id })
      })
    })()
  }

  return (
    <Modal
      title={data.title}
      open={open}
      onClose={handleClose}
      maxWidth='xs'
      footer={
        <ActionRow
          fullWidth
          loading={metadata?.loading}
          confirmText='Salvar'
          onSubmit={handleSubmit}
          disabled={metadata?.loading}
          onCancel={handleClose}
        />
      }
    >
      <ModalContent>
        <FormContainer>
          <FormProvider {...form}>
            <ListingForm />
          </FormProvider>
        </FormContainer>
      </ModalContent>
    </Modal>
  )
}
