import React from 'react'
import styled from 'styled-components'
import { theme } from '../../styles'

const StyledSection = styled.div`
  margin-bottom: ${theme.spacing(6)} !important;
`

export const PageSection = (args) => {
  return <StyledSection {...args} />
}
