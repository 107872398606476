import React, { useContext } from 'react'
import { MainContext } from 'Layout/Main'
import Logo from 'Assets/icons/condofy-logo.svg'
import styled from 'styled-components'
import { ENVIRONMENT, PRODUCTION } from '@condofy/next/vendor/@condofy/env'
import { Badge } from '@mui/material'

const Wrapper = styled.div`
  transition: width, margin 0.7s ease;
  width: 132px;

  &.collapsed {
    margin-left: -10px !important;
    width: 65px;
  }

  .MuiBadge-badge:not(.MuiBadge-invisible) {
    left: 43px;
    top: -20px;
    transform: none;
    right: auto;
  }
`

const StyledLogo = styled(Logo)`
  display: block;
  height: 32px;
  width: 132px;

  &.collapsed {
    .letter {
      visibility: hidden;
      opacity: 0;
    }
  }
`

export const CondofyLogo = () => {
  const { sidebarCollapsed } = useContext(MainContext)
  const badgeText = ENVIRONMENT !== PRODUCTION ? ENVIRONMENT : 0

  return (
    <Wrapper className={sidebarCollapsed ? 'collapsed' : ''}>
      <Badge color='error' badgeContent={badgeText} showZero={false}>
        <StyledLogo className={sidebarCollapsed ? 'collapsed' : ''} />
      </Badge>
    </Wrapper>
  )
}
