import { inactiveCondominium } from '@condofy/next/service/condo'
import { store, condominiumActionTypes } from './index'

// ACTION TYPES
const CONDOMINIUM_INACTIVE = 'CONDOMINIUM_INACTIVE'
const CONDOMINIUM_INACTIVE_MODAL_VISIBLE = 'CONDOMINIUM_INACTIVE_MODAL_VISIBLE'

// ACTIONS
export const condominiumInactiveActions = (state, dispatch) => ({
  inactiveCondominium: (payload) =>
    dispatch({ type: CONDOMINIUM_INACTIVE, payload }),

  closeInactiveCondominiumModal: () => {
    dispatch({
      type: condominiumActionTypes.CONDOMINIUM_SELECT,
      payload: false
    })
    dispatch({ type: CONDOMINIUM_INACTIVE_MODAL_VISIBLE, payload: false })
  },

  openInactiveCondominiumModal: (condominium) => {
    dispatch({
      type: condominiumActionTypes.CONDOMINIUM_SELECT,
      payload: condominium
    })
    dispatch({ type: CONDOMINIUM_INACTIVE_MODAL_VISIBLE, payload: true })
  }
})

// MIDDLEWARE
export const disableCondominium = (dispatch, payload) =>
  inactiveCondominium(payload)

// REDUCER
export const inactiveActionTypes = {
  CONDOMINIUM_INACTIVE,
  CONDOMINIUM_INACTIVE_MODAL_VISIBLE
}

// REDUCER
export const condominiumInactiveReducer = (state, action) => {
  switch (action.type) {
    case CONDOMINIUM_INACTIVE_MODAL_VISIBLE:
      return store(state, {
        inactiveModalOpen: action.payload
      })

    default:
      return state
  }
}
