import React from 'react'
import { Radio as MuiRadio, FormControlLabel } from '@mui/material/'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { typography } from '../../styles'

const StyledFormControlLabel = styled(FormControlLabel)`
  .MuiFormControlLabel-label {
    font-family: ${typography.type.tertiary};
    font-size: 14px;
    font-weight: 600;
  }
`

export const Radio = ({
  color = 'primary',
  variant = 'standard',
  label,
  labelPlacement = 'end',
  value,
  // eslint-disable-next-line no-unused-vars
  error, // ignore incoming errors
  ...props
}) => {
  return (
    <StyledFormControlLabel
      value={value}
      className={variant}
      control={<MuiRadio color={color} {...props} />}
      label={label}
      labelPlacement={labelPlacement}
    />
  )
}
Radio.propTypes = {
  color: PropTypes.oneOf(['primary', 'secondary']),
  label: PropTypes.string,
  labelPlacement: PropTypes.oneOf(['bottom', 'end', 'start', 'top'])
}
