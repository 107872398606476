import React from 'react'
import { useNavigate } from 'react-router-dom'
import { MD5 } from '@condofy/next/service/md5'
import {
  ButtonMenu,
  MenuItem,
} from '@condofy/components'
import { useUserContext } from '@condofy/next/store/user'
import { UserMenuButton } from './UserMenuButton'
import styled from 'styled-components'

const StyledMenu = styled(ButtonMenu)`
  height: 100%;
`

export const UserMenu = () => {
  const navigate = useNavigate()
  const { state, actions } = useUserContext()
  const gravatarId = MD5(state.email)
  const gravatarImage = `https://www.gravatar.com/avatar/${gravatarId}?d=retro`

  const navigateToProfile = () => {
    navigate('/my-profile')
  }

  const handleUserModalOpen = (user) => () => {
    actions.openCreateConnectionModal(user)
  }

  return (
    <StyledMenu
      Button={<UserMenuButton name={state.name} image={gravatarImage} />}
    >
      <MenuItem onClick={navigateToProfile}>Meu Perfil</MenuItem>
      <MenuItem onClick={handleUserModalOpen({ id: state.id })}>
        Nova conexão
      </MenuItem>
      <MenuItem onClick={actions.logout}>Sair</MenuItem>
    </StyledMenu>
  )
}
