import { all, fork, select } from 'redux-saga/effects'
import Service from '~/lib/api/services/finance'
import { Actions } from '@Redux/finance'
import SagasUtil from '~/redux/sagas/util'
import { condominiumId } from '@condofy/next/service/user'
import moment from 'moment'

export const base = {
  baseType: 'finance',
  actions: Actions,
  service: Service
}

export const searchToPay = {
  beforeRequest: function* (payload) {
    const condominiumId = payload.currentCondominium.id
    const currentMonth = yield select(({ finance }) => finance.toPayMonth)

    const body = {
      startDate: moment(currentMonth).startOf('month').format('YYYY-MM-DD'),
      endDate: moment(currentMonth).endOf('month').format('YYYY-MM-DD')
    }

    if (payload.bankAccountId) {
      body.bankIds = [payload.bankAccountId]
    }

    return [
      {
        condominiumId,
        body,
        page: payload.page,
        rowsPerPage: payload.rowsPerPage
      }
    ]
  },
  request: 'toPay',
  afterRequest: ({ data: { data, paging } }) => {
    let result = {
      toPay: {
        list: data || []
      }
    }

    if (paging) {
      result.toPay.metadata = {
        page: paging.pageNumber,
        limit: paging.recordsPerPage,
        count: paging.totalRecords,
        ...paging
      }
    }

    return result
  }
}

export const searchToReceive = {
  beforeRequest: function* (payload) {
    const condominiumId = payload.currentCondominium.id
    const currentMonth = yield select(({ finance }) => finance.toReceiveMonth)
    const body = {
      startDate: moment(currentMonth).startOf('month').format('YYYY-MM-DD'),
      endDate: moment(currentMonth).endOf('month').format('YYYY-MM-DD')
    }

    if (payload.bankAccountId) {
      body.bankIds = [payload.bankAccountId]
    }

    return [
      {
        condominiumId,
        body,
        page: payload.page,
        rowsPerPage: payload.rowsPerPage
      }
    ]
  },
  request: 'toReceive',
  afterRequest: ({ data: { data, paging } }) => {
    let result = {
      toReceive: {
        list: data || []
      }
    }

    if (paging) {
      result.toReceive.metadata = {
        page: paging.pageNumber,
        limit: paging.recordsPerPage,
        count: paging.totalRecords,
        ...paging
      }
    }

    return result
  }
}

export const searchToDetailed = {
  beforeRequest: function* (payload) {
    const condominiumId = payload.currentCondominium.id
    const currentMonth = yield select(({ finance }) => finance.toDetailedMonth)
    const body = {
      startDate: moment(currentMonth).startOf('month').format('YYYY-MM-DD'),
      endDate: moment(currentMonth).endOf('month').format('YYYY-MM-DD'),
      fundingType: payload.fundingType
    }

    if (payload.bankAccountId) {
      body.bankIds = [payload.bankAccountId]
    }

    return [
      {
        condominiumId,
        body,
        page: payload.page,
        rowsPerPage: payload.rowsPerPage
      }
    ]
  },
  request: 'detailedExtract',
  afterRequest: ({ data: { data, paging } }) => {
    let result = {
      toDetailed: {
        list: data || []
      }
    }

    if (paging) {
      result.toDetailed.metadata = {
        page: paging.pageNumber,
        limit: paging.recordsPerPage,
        count: paging.totalRecords,
        ...paging
      }
    }

    return result
  }
}

export const download = {
  beforeRequest: function (payload) {
    return [condominiumId, payload]
  },
  request: 'download',
  afterRequest: (response, payload) => {
    var file = new Blob([response.data], { type: 'application/pdf' })
    var fileURL = URL.createObjectURL(file)

    const link = document.createElement('a')
    link.href = fileURL
    link.download = `entry-${payload}-documents.pdf`
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }
}

export const downloadExcel = {
  beforeRequest: function* (payload) {
    const condominiumId = payload.currentCondominium.id
    const currentMonth = yield select(({ finance }) => finance.toDetailedMonth)

    const start = moment(currentMonth).startOf('month').format('YYYY-MM-DD')
    const end = moment(currentMonth).endOf('month').format('YYYY-MM-DD')

    const body = {
      dueDateStart: start,
      dueDateEnd: end,
      settleDateStart: start,
      settleDateEnd: end,
      statuses: [3]
    }

    if (payload.bankAccountId) {
      body.bankIds = [payload.bankAccountId]
    }

    return [{ condominiumId, body, ...payload }]
  },
  request: 'downloadExcel',
  afterRequest: (response) => {
    var file = new Blob([response.data], {
      type: response.headers['content-type']
    })

    var fileURL = URL.createObjectURL(file)

    const link = document.createElement('a')
    link.href = fileURL
    link.download = `movimentação`
    link.target = '_blank'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }
}

export default function* root() {
  const sagas = SagasUtil(base, {
    searchToReceive,
    searchToDetailed,
    searchToPay,
    download,
    downloadExcel
  })
  yield all(sagas.map((saga) => fork(...saga)))
}
