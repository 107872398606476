import { editSchedulingArea } from '@condofy/next/service/area'
import { store, areaActionTypes, pending } from './index'

// ACTION TYPES
const AREA_EDIT = 'AREA_EDIT'
const AREA_UPDATE_SELECTED = 'AREA_UPDATE_SELECTED'
const AREA_EDIT_MODAL_VISIBLE = 'AREA_EDIT_MODAL_VISIBLE'

// ACTIONS
export const editActions = (state, dispatch) => ({
  updateSelectedArea: (data) =>
    dispatch({ type: AREA_UPDATE_SELECTED, payload: data }),

  editArea: (payload) => dispatch({ type: AREA_EDIT, payload }),

  closeEditAreaModal: () => {
    dispatch({ type: areaActionTypes.AREA_SELECT, payload: false })
    dispatch({ type: AREA_EDIT_MODAL_VISIBLE, payload: false })
  },

  openEditAreaModal: (area) => {
    dispatch({ type: areaActionTypes.AREA_SELECT, payload: area })
    dispatch({ type: AREA_EDIT_MODAL_VISIBLE, payload: true })
  }
})

// MIDDLEWARE
export const edit = async (dispatch, payload) => {
  try {
    pending(dispatch, true)
    return editSchedulingArea(payload)
  } finally {
    pending(dispatch, false)
  }
}

// REDUCER
export const editReducer = (state, action) => {
  switch (action.type) {
    case AREA_UPDATE_SELECTED:
      return store(state, { selectedArea: action.payload })

    case AREA_EDIT_MODAL_VISIBLE:
      return store(state, { editModalOpen: action.payload })

    default:
      return state
  }
}

export const editActionTypes = {
  AREA_EDIT,
  AREA_UPDATE_SELECTED,
  AREA_EDIT_MODAL_VISIBLE
}
