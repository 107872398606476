import React from 'react'
import {
  Alert,
  Card,
  Flex,
  TabsPanel,
  TabsPanelItem,
  CardContent,
  DateText
} from '@condofy/components'
import {
  BankAccountSelector,
  FinancialStatement
} from '../../next/containers/finance'

const StatementAlert = () => (
  <Alert severity='warning' variant='outlined'>
    Os saldos e lançamentos apresentados abaixo são baseados nas informações
    disponíveis e atualizadas até <DateText date={new Date()} /> às{' '}
    <DateText date={new Date()} format='time' />. As informações poderão sofrer
    alterações em função de novos lançamentos.
  </Alert>
)

export const FinancialStatementTab = () => {
  return (
    <Flex direction='column' spacingY={3}>
      <StatementAlert />
      <Card>
        <CardContent>
          <Flex direction='column' spacingY={3} width='100%'>
            <BankAccountSelector hideIfOneOption />
            <div>
              <TabsPanel>
                <TabsPanelItem label='Extrato Detalhado'>
                  <FinancialStatement type='toDetailed' />
                </TabsPanelItem>
                <TabsPanelItem label='A Pagar'>
                  <FinancialStatement type='toPay' />
                </TabsPanelItem>
                <TabsPanelItem label='A Receber'>
                  <FinancialStatement type='toReceive' />
                </TabsPanelItem>
              </TabsPanel>
            </div>
          </Flex>
        </CardContent>
      </Card>
    </Flex>
  )
}
