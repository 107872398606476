import React, { useCallback } from 'react'
import { Modal, ModalContent } from '@condofy/next/components'
import { useCommunityContext } from '@condofy/next/store/community'
import {
  Button,
  colors,
  Flex,
  Heading6,
  Icon,
  pxToRem,
  SmallBody,
  theme,
  typography
} from '@condofy/components'
import { Box } from '@mui/material'
import styled from 'styled-components'

const CheckIcon = styled(Icon)`
  color: ${colors.brandBlue700} !important;
  font-size: ${pxToRem(60)}rem;
  margin-bottom: ${theme.spacing(1)};
`

const StyledModalContent = styled(ModalContent)`
  * {
    font-family: ${typography.type.tertiary} !important;
    text-align: center;
  }
`

const StyledSmallBody = styled(SmallBody)`
  color: ${colors.dark200};
`

export const OrderCreatedModal = () => {
  const {
    state: {
      orderCreated: {
        modal: { open }
      }
    },
    actions: { closeCreatedOrderModal }
  } = useCommunityContext()
  const stableCloseCreatedOrderModal = useCallback(closeCreatedOrderModal, [])
  const handleClose = useCallback(() => {
    stableCloseCreatedOrderModal()
  }, [stableCloseCreatedOrderModal])

  return (
    <Modal title='Confirmação' open={open} maxWidth='xs' onClose={handleClose}>
      <StyledModalContent spacing={0}>
        <Box overflow='hidden'>
          <Flex direction='column' spacingY={3} alignItems='center'>
            <div>
              <CheckIcon icon='checkmark_circle_outlined' />
              <Heading6>Pedido enviado!</Heading6>
            </div>
            <StyledSmallBody>
              Seu vizinho foi notificado e entrará em contato se o item ainda
              estiver disponível.
            </StyledSmallBody>
            <Button fullWidth onClick={handleClose}>
              Ok!
            </Button>
          </Flex>
        </Box>
      </StyledModalContent>
    </Modal>
  )
}
