import { listComment } from '@condofy/next/service/ticket'
import { pending, store } from './index'

// ACTION TYPES
const TICKET_COMMENT_LIST = 'TICKET_COMMENT_LIST'
const TICKET_COMMENT_LIST_SUCCESS = 'TICKET_COMMENT_LIST_SUCCESS'

export const listActionTypes = {
  TICKET_COMMENT_LIST,
  TICKET_COMMENT_LIST_SUCCESS
}

// ACTIONS
export const listActions = (state, dispatch) => ({
  listTicketComment: (payload) =>
    dispatch({ type: TICKET_COMMENT_LIST, payload })
})

// MIDDLEWARE
export const list = async (dispatch, payload) => {
  pending(dispatch, true)
  try {
    const response = await listComment(payload)

    dispatch({ type: TICKET_COMMENT_LIST_SUCCESS, payload: response.data })

    return response
  } finally {
    pending(dispatch, false)
  }
}

// REDUCER
export const listReducer = (state, action) => {
  switch (action.type) {
    case TICKET_COMMENT_LIST_SUCCESS:
      return store(state, {
        items: action.payload.data,
        metadata: {
          ...state.ticket.metadata,
          ...action.payload.paging
        }
      })

    default:
      return state
  }
}
