import React from 'react'
import { Chip } from '@condofy/components'
import PropTypes from 'prop-types'

export const InvoiceStatusChip = ({ status }) => {
  const labels = ['Pendente', 'Expirado', 'Pago']
  const colors = ['secondary', 'light', 'success']

  return <Chip label={labels[status]} color={colors[status]} />
}
InvoiceStatusChip.propTypes = {
  status: PropTypes.oneOf([0, 1, 2])
}
