import React from 'react'
import styled, { css } from 'styled-components'
import { Button, CircularProgress } from '@mui/material'
import { Copy } from '../../assets/copy'

const styledWords = css`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #172b4d;
  text-transform: capitalize;
`

const BaseButton = styled(Button)`
  &.MuiButton-root {
    background: linear-gradient(180deg, #ffffff 0%, #f4f5f7 100%);
    border: 1px solid #dfe1e6;
    border-radius: 5px;
    white-space: nowrap;

    @media (max-width: 600px) {
      width: 100%;
      margin-bottom: 8px;
    }

    &:hover {
      background: linear-gradient(180deg, #f4f5f7 0%, #ffffff 100%);
    }
  }

  width: 151px;
  height: 40px;

  &.MuiButton-text {
    ${styledWords}
  }
`

export const CopyButton = ({ onClick, loading, disabled, ...props }) => {
  if (loading) {
    return (
      <BaseButton {...props}>
        <CircularProgress color='inherit' size={25} />
      </BaseButton>
    )
  }

  return (
    <BaseButton
      startIcon={
        <Copy
          width={14}
          height={14}
          style={{ fill: disabled ? '#ccc' : 'inherit' }}
        />
      }
      onClick={onClick}
      disabled={disabled}
      {...props}
    >
      Copiar Código
    </BaseButton>
  )
}
