import React from 'react'
import { TableCell as MuiTableCell } from '@mui/material'
import styled from 'styled-components'
import { colors, pxToRem, theme } from '../../styles'

const StyledTableCell = styled(MuiTableCell)`
  &.MuiTableCell {
    &-root {
      border-color: ${colors.light400};

      &:first-child {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }
      &:last-child {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }
    &-head {
      background: ${colors.light300};
      border-top: solid 1px ${colors.light400};
      border-bottom: solid 2px ${colors.light400};
      color: ${colors.dark900};
      font-weight: 700;
    }
    &-sizeSmall {
      font-size: ${pxToRem(13)}rem;
    }
    &-sizeSmall:not(.MuiTableCell-paddingNone) {
      padding: ${theme.spacing(1)};
    }
    &-root:not(.MuiTableCell-paddingNone) {
      padding: ${theme.spacing(2)};
    }
  }
`

export const TableCell = (args) => <StyledTableCell {...args} />
