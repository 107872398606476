import { useContext } from 'react'
import { createStore } from '../core/'
import * as statementItem from './statementItem'
import * as cashflow from './cashflow'
import * as entry from './entry'

const subStores = [statementItem, cashflow, entry]

const [FinancialContext, FinancialProvider] = createStore(subStores)

export const useFinancialContext = () => useContext(FinancialContext)

export { FinancialContext, FinancialProvider }
