import React from 'react'
import {
  colors,
  CurrencyText,
  LightTooltip,
  typography
} from '@condofy/components'
import styled from 'styled-components'

const TooltipTable = styled.table`
  border-collapse: collapse;
  td {
    font-family: ${typography.type.tertiary};
    padding: 4px 6px;
    vertical-align: top;
  }
  thead td {
    font-weight: bold;
    border-bottom: solid 2px ${colors.light400};
  }
  tbody td {
    border-bottom: solid 1px ${colors.light400};
  }
`

const Tooltip = styled(LightTooltip)`
  & .MuiTooltip-tooltip {
    max-width: 100%;
    width: 600px;
  }
`

const Content = ({ items }) => (
  <TooltipTable>
    <thead>
      <tr>
        <td>Itens</td>
        <td>Valor</td>
        <td>Categoria</td>
      </tr>
    </thead>
    <tbody>
      {items.map((item) => (
        <tr key={item.id}>
          <td>{item.description}</td>
          <td>
            <CurrencyText value={item.amount} />
          </td>
          <td>{item.category.name}</td>
        </tr>
      ))}
    </tbody>
  </TooltipTable>
)

export const FinancialStatementItemTooltip = ({ items, ...other }) => (
  <Tooltip title={<Content items={items} />} {...other} />
)
