import styled from 'styled-components'
import { colors, theme } from '../../styles'

export const DialogSection = styled.div`
  border-top: ${({ border = 0 }) => `solid ${border}px ${colors.light500}`};
  padding: ${theme.spacing(3)} 0;

  &:last-child {
    padding-bottom: 0;
  }
`
