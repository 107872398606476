export const navItems = [
  {
    id: 'platform',
    to: '/platform',
    text: 'Administrar Plataforma',
    icon: 'platform',
    admin: true,
    superAdmin: true
  },
  {
    id: 'admin',
    to: '/admin',
    text: 'Gerenciar Condomínio',
    icon: 'admin',
    admin: true
  },
  {
    id: 'community',
    to: '/community',
    text: 'Comunidade',
    icon: 'community'
  },
  {
    id: 'finance',
    to: '/finance',
    text: 'Dashboard Financeiro',
    icon: 'finance',
    premium: true,
    accessType: self
  },
  {
    id: 'billing',
    to: '/billing',
    text: 'Faturas',
    icon: 'billing',
    premium: true
  },
  {
    id: 'schedule',
    to: '/schedule',
    text: 'Reservas',
    icon: 'schedule'
  },
  {
    id: 'calendar',
    to: '/calendar',
    text: 'Calendário',
    icon: 'calendar'
  },
  {
    id: 'announcements',
    to: '/announcements',
    text: 'Comunicados',
    icon: 'announcements'
  },
  {
    id: 'tickets',
    to: '/tickets',
    text: 'Chamados',
    icon: 'tickets',
    premium: true
  },
  {
    id: 'files',
    to: '/files',
    text: 'Arquivos',
    icon: 'files'
  },
  {
    id: 'surveys',
    to: '/surveys',
    text: 'Enquetes',
    icon: 'surveys'
  },
  {
    id: 'complains',
    to: '/complains',
    text: 'Ocorrências',
    icon: 'complains'
  }
]
