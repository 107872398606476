import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Breadcrumb } from './Breadcrumb'
import { Actions } from '@Redux/storage'
import { useDispatch } from 'react-redux'
import {
  deleteFolder,
  makePathPublic,
  makePathPrivate
} from '@condofy/next/service/files'
import { useSnackbar } from '@condofy/next/hooks/useSnackbar'
import { useUserContext } from '@condofy/next/store/user'
import { track } from '@condofy/next/service/segment'
import {
  ButtonMenu as Menu,
  MenuItem,
  Button,
  Card,
  CardContent,
  CardHeader,
  colors,
  DataTable,
  DateText,
  Flex,
  Icon
} from '@condofy/components'

const VanishingButton = styled(Button)`
  ${({ $visible }) => `visibility: ${$visible ? 'visible' : 'hidden'};`}
`

export const StorageCard = ({
  currentFolder = [],
  currentPath = [],
  navigate = () => {},
  onChange: emitChangeEvent = () => {},
  onPathChange: emitPathChangeEvent = () => {},
  onFileModalOpen: emitFileModalEvent = () => {},
  onFolderModalOpen: emitFolderModalEvent = () => {},
  onEditFolderModalOpen: emitEditFolderModalEvent = () => {}
}) => {
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const {
    state: { currentCondominium, isSuperAdmin, isAdmin }
  } = useUserContext()
  const isWorkspace = currentPath.indexOf('condomínio') > 0
  const isPrivate = currentPath.indexOf('administração') > 0

  const buildPath = (path) => {
    const breadcrumb = path.split('/')
    breadcrumb.shift()
    return ['Meus Arquivos', ...breadcrumb]
  }

  const handleBreadcrumbChange = (path) => {
    emitPathChangeEvent(buildPath(path))
  }

  const handleFolderChange = (selectedItem) => {
    if (selectedItem.files.length > 0) {
      track(window.analytics, 'Files Folder Opened', selectedItem)
      emitChangeEvent(selectedItem.files)
      handleBreadcrumbChange(selectedItem.fullName)
    } else {
      track(window.analytics, 'Files File Downloaded', selectedItem)
      dispatch(
        Actions.storageDownload({
          currentCondominium,
          payload: { fullName: selectedItem.fullName }
        })
      )
    }
  }

  const handleDelete = (selectedItem) => () => {
    const deleteConfirmation = window.confirm(
      'Tem certeza que deseja remover este item?\n\nEsta ação não pode ser revertida.'
    )
    if (deleteConfirmation) {
      const isFolder = Boolean(selectedItem.files.length)

      deleteFolder({
        path: isFolder ? selectedItem.fullName + '/' : selectedItem.fullName,
        condominiumId: currentCondominium.id,
        onSuccess: () => {
          track(window.analytics, 'Files File Deleted', selectedItem)
          dispatch(Actions.storageList({ currentCondominium }))
          enqueueSnackbar('Item excluido com sucesso', {
            variant: 'success'
          })
        },
        onError: (error) => {
          let errorMessage = error.message
          if (error.response && error.response.data)
            errorMessage = error.response.data.reports[0].message

          enqueueSnackbar(errorMessage, {
            variant: 'error'
          })
        }
      })
    }
  }

  const handleIsPublic = (selectedItem) => () => {
    const isFolder = Boolean(selectedItem.files.length)

    makePathPublic({
      fullPath: isFolder ? selectedItem.fullName + '/' : selectedItem.fullName,
      condominiumId: currentCondominium.id,
      onSuccess: () => {
        enqueueSnackbar('Permissão alterada com sucesso', {
          variant: 'success'
        })
        dispatch(Actions.storageList({ currentCondominium }))
      },
      onError: (error) => {
        let errorMessage = error.message
        if (error.response && error.response.data)
          errorMessage = error.response.data.reports[0].message

        enqueueSnackbar(errorMessage, {
          variant: 'error'
        })
      }
    })
  }

  const handleIsPrivate = (selectedItem) => () => {
    const isFolder = Boolean(selectedItem.files.length)

    makePathPrivate({
      fullPath: isFolder ? selectedItem.fullName + '/' : selectedItem.fullName,
      condominiumId: currentCondominium.id,
      onSuccess: () => {
        enqueueSnackbar('Permissão alterada com sucesso', {
          variant: 'success'
        })
        dispatch(Actions.storageList({ currentCondominium }))
      },
      onError: (error) => {
        let errorMessage = error.message
        if (error.response && error.response.data)
          errorMessage = error.response.data.reports[0].message

        enqueueSnackbar(errorMessage, {
          variant: 'error'
        })
      }
    })
  }

  const isInsideFolders = isPrivate || isWorkspace
  const isSupperAdminInsideFolders = isInsideFolders && isSuperAdmin
  const isAdminInWorkspaceMenu = isInsideFolders && isAdmin
  const isAdminInWorkspace = isWorkspace && isAdmin
  const isManageable = isSupperAdminInsideFolders || isAdminInWorkspace
  const isFolder = (row) => Boolean(row?.files?.length)

  const [visibleFolderItems, setVisibleFolderItems] = useState([])

  useEffect(() => {
    if (currentFolder.length) {
      const items = currentFolder.filter((row) => {
        // const isAdmnistrationFolder = row.title === 'administração'
        const isPlaceholder = Boolean(row.title)
        const shouldShowItem = isPlaceholder
        return shouldShowItem
      })
      setVisibleFolderItems(
        items.sort((a, b) => {
          if (a.title > b.title) {
            return 1
          }
          if (a.title < b.title) {
            return -1
          }
          return 0
        })
      )
    }
  }, [currentFolder, isAdmin, isSuperAdmin])

  const columns = [
    {
      headerName: 'Nome',
      renderCell: ({ row }) => (
        <Flex alignItems='center' spacingX={1}>
          {isFolder(row) ? (
            <Icon icon='folder' style={{ fill: `${colors.yellow200}` }} />
          ) : (
            <Icon icon='file_text' style={{ fill: `${colors.red400}` }} />
          )}
          <span>{row.title}</span>
        </Flex>
      )
    },
    {
      headerName: 'Tamanho',
      valueGetter: ({ row }) =>
        isFolder(row) ? '-' : (row.size * 1024).toFixed(0) + ' kb'
    },
    {
      headerName: 'Data',
      renderCell: ({ row }) => <DateText date={row.createdAt} format='long' />
    },
    {
      valueGetter: ({ row }) => row.carbs
    },
    {
      hide: !(isAdminInWorkspaceMenu || isManageable),
      renderCell: ({ row }) =>
        !isFolder(row) && isManageable ? (
          <Menu>
            <MenuItem onClick={handleDelete(row)}>Excluir Arquivo</MenuItem>
          </Menu>
        ) : (
          <Menu>
            {isManageable && (
              <MenuItem onClick={() => emitEditFolderModalEvent(row)}>
                Renomear Pasta
              </MenuItem>
            )}
            {isManageable && (
              <MenuItem onClick={handleDelete(row)}>Excluir Pasta</MenuItem>
            )}
            {row.isPublic ? (
              <MenuItem onClick={handleIsPrivate(row)}>
                Tornar Pasta Privada
              </MenuItem>
            ) : (
              <MenuItem onClick={handleIsPublic(row)}>
                Tornar Pasta Pública
              </MenuItem>
            )}
          </Menu>
        )
    }
  ]
  return (
    <Card>
      <CardHeader
        title={<Breadcrumb path={currentPath} onPathChange={navigate} />}
        action={
          <Menu
            Button={
              <VanishingButton $visible={isManageable}>+ Novo</VanishingButton>
            }
          >
            <MenuItem onClick={() => emitFolderModalEvent(currentFolder)}>
              Nova Pasta
            </MenuItem>
            <MenuItem onClick={() => emitFileModalEvent(currentFolder)}>
              Novo Arquivo
            </MenuItem>
          </Menu>
        }
      />
      <CardContent>
        <DataTable
          variant='borderless'
          size='small'
          loading={!currentFolder.length}
          onRowClick={handleFolderChange}
          columns={columns}
          rows={visibleFolderItems}
        />
      </CardContent>
    </Card>
  )
}
