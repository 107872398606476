import { callApi } from 'Middleware/api'
import { toggleModal } from 'Actions/modals'
import { EDIT_USER_MODAL } from 'Const'
import { normalizeObject } from '~/lib/util/normalizeObject'

export const SET_USERS = 'admin/users/SET_USERS'
export const SET_METADATA = 'admin/users/SET_METADATA'
export const GET_USERS_LIST = 'admin/users/GET_USERS_LIST'
export const SELECT_USER_FOR_EDIT = 'admin/users/SELECT_USER_FOR_EDIT'
export const SET_USER_FOR_EDIT_FIELD = 'admin/users/SET_USER_FOR_EDIT_FIELD'
export const SET_USER_INFO = 'admin/users/SET_USER_INFO'
export const SET_ERROR = 'admin/users/SET_ERROR'

const setUsers = (users) => ({
  type: SET_USERS,
  users
})

export const setMetadata = (metadata) => ({
  type: SET_METADATA,
  metadata
})

export const selectUserForEdit = (id) => ({
  type: SELECT_USER_FOR_EDIT,
  id
})

export const setSelectedUserField = (key, value) => ({
  type: SET_USER_FOR_EDIT_FIELD,
  key,
  value
})

export const setUserInfo = (user) => ({
  type: SET_USER_INFO,
  user
})

export const setError = (errors) => ({
  type: SET_ERROR,
  errors
})
export const getUsers = (page, search) => (dispatch) =>
  callApi(`/users/search?PageNumber=${page || 1}`, {
    method: 'POST',
    body: JSON.stringify({ generalSearch: search })
  })
    .then(async (response) =>
      response.ok ? response.json() : Promise.reject(await response.json())
    )
    .then((_data) => {
      const {
        data,
        paging: { recordsPerPage, pageNumber, totalRecords }
      } = normalizeObject(_data)
      dispatch(setUsers(data))

      dispatch(
        setMetadata({
          page: pageNumber,
          limit: recordsPerPage,
          count: totalRecords
        })
      )
    })
    .catch(() => {})

export const getUserInfo = (id) => (dispatch) =>
  callApi(`/users/${id}`)
    .then(async (response) =>
      response.ok ? response.json() : Promise.reject(await response.json())
    )
    .then((_data) => {
      const { data } = normalizeObject(_data)
      dispatch(setUserInfo(data))
    })
    .catch(() => {})

export const updateUser = () => (dispatch, getState) => {
  const {
    users: { userEdit, metadata }
  } = getState()

  return callApi(`/users/${userEdit.id}`, {
    method: 'PUT',
    body: JSON.stringify(userEdit)
  })
    .then(async (response) =>
      response.ok ? response.json() : Promise.reject(await response.json())
    )
    .then(() => {
      dispatch(getUsers(metadata.page, metadata.search))
      dispatch(toggleModal(EDIT_USER_MODAL, false))
    })
    .catch(() => {})
}
