import { createContext, useContext } from 'react'
import { initialState, reducer } from './reducer'
import { useActions } from './actions'
import { applyMiddleware } from './middleware'
import { withProvider } from '../core/'

export const BillingContext = createContext()

export const BillingProvider = withProvider({
  reducer,
  initialState,
  useActions,
  applyMiddleware
})(BillingContext)

export const useBillingContext = () => useContext(BillingContext)
