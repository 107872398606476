import React from 'react'
import { RecordVoiceOver } from '@mui/icons-material'
import {
  Form,
  FormRow,
  QuillCounter,
  TextFieldCounter
} from '@condofy/components'
import { Modal, ActionRow, ModalContent } from '@condofy/next/components'
import { useUserContext } from '@condofy/next/store/user'
import { useSnackbar, handleError } from '@condofy/next/hooks/useSnackbar'

export const AnnouncementeModelEditModal = () => {
  const { enqueueSnackbar } = useSnackbar()

  const {
    state: {
      currentCondominium,
      announcementModel: { editModalOpen, metadata, selected }
    },
    actions: {
      listAnnouncementModel,
      closeEditAnnouncementModelModal: close,
      editAnnouncementModel,
      updateSelectedAnnouncementModel
    }
  } = useUserContext()

  const handleChange = (event) => {
    const { name, value } = event.target
    updateSelectedAnnouncementModel({ ...selected, [name]: value })
  }

  const contentChange = (value) => {
    updateSelectedAnnouncementModel({ ...selected, content: value })
  }

  const onSubmit = () => {
    editAnnouncementModel({
      currentCondominium,
      payload: selected
    })
      .then(() => {
        close()
        enqueueSnackbar('Modelo atualizado', {
          variant: 'success'
        })
        listAnnouncementModel({ currentCondominium })
      })
      .catch(handleError(enqueueSnackbar))
  }

  return (
    <Modal
      icon={<RecordVoiceOver />}
      title='Novo modelo'
      open={editModalOpen}
      onClose={close}
      footer={
        <ActionRow
          loading={metadata.loading}
          confirmText='Salvar modelo'
          onSubmit={onSubmit}
          onCancel={close}
        />
      }
    >
      <ModalContent>
        <Form>
          <FormRow>
            <TextFieldCounter
              name='title'
              label='Titulo'
              value={selected.title}
              onChange={handleChange}
              maxLength={50}
            />
          </FormRow>
          <FormRow>
            <TextFieldCounter
              name='subject'
              label='Assunto'
              value={selected.subject}
              onChange={handleChange}
              maxLength={50}
            />
          </FormRow>
          <FormRow>
            <QuillCounter
              required
              name='content'
              onChange={contentChange}
              defaultValue={selected.content || ''}
              maxLength={2500}
            />
          </FormRow>
        </Form>
      </ModalContent>
    </Modal>
  )
}
