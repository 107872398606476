import { condofyProtected } from '@condofy/next/service/instance'
import { format } from 'date-fns'

export const createReport = (condominiumId, reportId, file) => {
  const formData = new FormData()
  formData.append('file', file)

  return condofyProtected.post(
    `/v1/condominiums/${condominiumId}/document-signatures/${reportId}/send`,
    formData
  )
}

export const resendEmailReport = (condominiumId, reportId, email) => {
  return condofyProtected.post(
    `/v1/condominiums/${condominiumId}/document-signatures/${reportId}/signers/${email}/resend-email`
  )
}

export const cancelReportSubmission = (condominiumId, reportId) => {
  return condofyProtected.put(
    `/v1/condominiums/${condominiumId}/document-signatures/${reportId}/cancel`
  )
}

export const reportUpdated = (condominiumId, reportId, payload) => {
  return condofyProtected.put(
    `/v1/condominiums/${condominiumId}/document-signatures/${reportId}`,
    JSON.stringify(payload)
  )
}

export const previewReport = (currentCondominium, reportData, bodyData) => {
  return condofyProtected
    .post(
      `/v1/condominiums/${currentCondominium.id}/accountability`,
      bodyData,
      {
        responseType: 'arraybuffer'
      }
    )
    .then((response) => {
      const file = new Blob([response.data], { type: 'application/pdf' })
      const fileURL = URL.createObjectURL(file)
      const link = document.createElement('a')
      link.href = fileURL
      link.download = `prestacao-de-contas-${currentCondominium.code}-${format(
        new Date(reportData.month + '/01/' + reportData.year),
        'yyyy-MM'
      )}.pdf`
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    })
    .catch(() => console.log('Falha na prévia do relatório.'))
}

export const downloadReport = (currentCondominium, task, reportData) => {
  return condofyProtected
    .post(
      `/v1/condominiums/${currentCondominium.id}/document-signatures/${task.report.id}/download`,
      {},
      {
        responseType: 'arraybuffer'
      }
    )
    .then((response) => {
      const file = new Blob([response.data], { type: 'application/pdf' })
      const fileURL = URL.createObjectURL(file)
      const link = document.createElement('a')
      link.href = fileURL
      link.download = `prestacao-de-contas-${currentCondominium.code}-${format(
        new Date(reportData.month + '/01/' + reportData.year),
        'yyyy-MM'
      )}.pdf`
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    })
    .catch(() => console.log('Falha no download do relatório.'))
}
