import React, { useState } from 'react'
import { Actions } from '@Redux/storage'
import { useDispatch } from 'react-redux'
import { CreateNewFolder } from '@mui/icons-material'

import { useSnackbar } from '@condofy/next/hooks/useSnackbar'
import { Modal, ActionRow } from '@condofy/next/components'
import { editFolder } from '@condofy/next/service/files'
import { TextField, Form } from '@condofy/components'
import { useUserContext } from '@condofy/next/store/user'
import { track } from '@condofy/next/service/segment'
import { ModalContent } from '../../next/components/Modal'

export const EditFolderModal = ({
  open,
  onClose = () => {},
  currentFolder
}) => {
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const [loading, setLoading] = useState(false)
  const [folderName, setFolderName] = useState('')
  const {
    state: { currentCondominium }
  } = useUserContext()

  const buildPath = (folderName) => {
    const path = currentFolder.fullName.split('/')
    path.pop()
    path.push(folderName + '/')
    return path.join('/')
  }

  const handleSubmit = () => {
    setLoading(true)

    editFolder({
      condominiumId: currentCondominium.id,
      old_path: currentFolder.fullName + '/',
      new_path: buildPath(folderName),
      onSuccess: () => {
        track(window.analytics, 'Files File Edited', {
          old_path: currentFolder.fullName + '/',
          new_path: buildPath(folderName)
        })
        setLoading(false)
        dispatch(Actions.storageList({ currentCondominium }))
        onClose()
      },
      onError: (error) => {
        let errorMessage = error.message
        if (error.response && error.response.data)
          errorMessage = error.response.data.reports[0].message

        enqueueSnackbar(errorMessage, {
          variant: 'error'
        })
        setLoading(false)
        onClose()
      }
    })
  }

  return (
    <Modal
      icon={<CreateNewFolder />}
      title='Renomear Pasta'
      open={open}
      onClose={onClose}
      footer={
        <ActionRow
          loading={loading}
          onSubmit={handleSubmit}
          onCancel={onClose}
        />
      }
    >
      <ModalContent>
        <Form>
          <TextField
            label='Nome da Pasta'
            value={folderName}
            onChange={(event) => setFolderName(event.target.value)}
          />
        </Form>
      </ModalContent>
    </Modal>
  )
}
