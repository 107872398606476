/* eslint-disable no-template-curly-in-string */
import * as yup from 'yup'

export const translation = {
  mixed: {
    default: 'O valor é inválido',
    required: 'O campo é obrigatório',
    oneOf: 'O campo deve ser um dos seguintes valores: ${values}',
    notOneOf: 'O campo não pode ser um dos seguintes valores: ${values}',
    notType: 'O valor é inválido',
    defined: 'O campo deve ser definido'
  },
  string: {
    length: 'O campo deve ter exatamente ${length} caracteres',
    min: 'O campo deve ter pelo menos ${min} caracteres',
    max: 'O campo deve ter no máximo ${max} caracteres',
    matches: 'O campo deve corresponder ao padrão: "${regex}"',
    email: 'O campo deve ser um email válido',
    url: 'O campo deve ser um url válido',
    trim: 'O campo não deve conter espaços no início ou no fim.',
    lowercase: 'O campo deve estar em minúsculas',
    uppercase: 'O campo deve estar em maiúsculas'
  },
  number: {
    min: 'O campo deve ser no maior ou igual a ${min}',
    max: 'O campo deve ser no menor ou igual a ${max}',
    lessThan: 'O campo deve ser menor do que ${less}',
    moreThan: 'O campo deve ser maior do que ${more}',
    notEqual: 'O campo não pode ser igual a ${notEqual}',
    positive: 'O campo deve ser um número positivo',
    negative: 'O campo deve ser um número negativo',
    integer: 'O campo deve ser um número inteiro'
  },
  date: {
    min: 'A data deve ser posterior a ${min}',
    max: 'A data deve ser anterior a ${max}'
  },
  boolean: {
    isValue: 'O campo deve ser ${value}'
  },
  array: {
    min: 'O campo deve ter no mínimo ${min} itens',
    max: 'O campo deve ter no máximo ${max} itens',
    length: 'O campo deve ter exactamente ${length} itens'
  },
  object: {
    noUnknown: 'O campo contém chaves não especificadas: ${unknown}'
  }
}

yup.addMethod(yup.array, 'atLeastOne', function (predicate, message) {
  return this.test('atLeastOne', message, function (list = []) {
    const hasAtLeastOne = list.slice().filter(predicate).length >= 1

    return hasAtLeastOne
      ? true
      : this.createError({
          message
        })
  })
})

yup.setLocale(translation)

export default yup
