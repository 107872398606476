import { store, announcementActionTypes } from './index'
import { editAnnouncement } from '@condofy/next/service/announcement'

// ACTION TYPES
const ANNOUNCEMENT_EDIT = 'ANNOUNCEMENT_EDIT'
const ANNOUNCEMENT_UPDATE_SELECTED = 'ANNOUNCEMENT_UPDATE_SELECTED'
const ANNOUNCEMENT_EDIT_MODAL_VISIBLE = 'ANNOUNCEMENT_EDIT_MODAL_VISIBLE'
const ANNOUNCEMENT_EDIT_STEP = 'ANNOUNCEMENT_EDIT_STEP'

export const editActionTypes = {
  ANNOUNCEMENT_EDIT,
  ANNOUNCEMENT_UPDATE_SELECTED,
  ANNOUNCEMENT_EDIT_MODAL_VISIBLE,
  ANNOUNCEMENT_EDIT_STEP
}

// ACTIONS
export const editActions = (state, dispatch) => ({
  updateSelectedAnnouncement: (data) =>
    dispatch({ type: ANNOUNCEMENT_UPDATE_SELECTED, payload: data }),

  editAnnouncement: (payload) => dispatch({ type: ANNOUNCEMENT_EDIT, payload }),

  closeEditAnnouncementModal: () => {
    dispatch({
      type: announcementActionTypes.ANNOUNCEMENT_SELECT,
      payload: false
    })
    dispatch({ type: ANNOUNCEMENT_EDIT_STEP, payload: 1 })
    dispatch({ type: ANNOUNCEMENT_EDIT_MODAL_VISIBLE, payload: false })
  },

  announcementUpdateEditStep: (payload) =>
    dispatch({ type: ANNOUNCEMENT_EDIT_STEP, payload }),

  openEditAnnouncementModal: (announcement) => {
    dispatch({
      type: announcementActionTypes.ANNOUNCEMENT_SELECT,
      payload: announcement
    })
    dispatch({ type: ANNOUNCEMENT_EDIT_MODAL_VISIBLE, payload: true })
  }
})

// MIDDLEWARE
export const edit = async(dispatch, payload) => {
  if (payload.payload.Status !== 'A') {
    payload.payload.ReceiversEmail = payload.payload.ReceiversEmail.filter(
      (email) => email !== -1
    )
  }
  await editAnnouncement(payload)
}

// REDUCER
export const editReducer = (state, action) => {
  switch (action.type) {
    case ANNOUNCEMENT_UPDATE_SELECTED:
      return store(state, {
        selected: { ...state.announcement.selected, ...action.payload }
      })

    case ANNOUNCEMENT_EDIT_MODAL_VISIBLE:
      return store(state, { editModalOpen: action.payload })

    case ANNOUNCEMENT_EDIT_STEP:
      return store(state, { editStep: action.payload })

    default:
      return state
  }
}
