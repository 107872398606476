import React from 'react'
import { CardContent as MuiCardContent } from '@mui/material'
import { theme } from '../../styles'
import PropTypes from 'prop-types'

const styles = (padding) =>
  ({
      padding: padding ? `${theme.spacing(padding)}` : 0,
      '&:last-child': {
        padding: padding
          ? `${theme.spacing(padding)} !important`
          : `0 !important`
      }
  })

export const CardContent = ({ padding = 3, ...props }) => {
  return <MuiCardContent className="root" {...props} sx = {{...styles(padding)}}/>
}
CardContent.propTypes = {
  padding: PropTypes.number
}
