import React from 'react'
import styled from 'styled-components'
import { Typography } from '../Typography'
import { colors, theme, typography } from '../../styles'

const StyledTitle = styled(Typography)`
  color: ${colors.dark400};
  font-family: ${typography.type.tertiary};
  font-weight: 700!important;
  font-size: 14px!important;
  text-transform: uppercase;
  margin-bottom: ${theme.spacing(2)};
`

export const PageSubTitle = (args) => {
  return <StyledTitle component='h3' {...args} />
}
