import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { colors, TableCell, theme, typography } from '@condofy/components'

const StyledTableCell = styled(TableCell)`
  ${({ type }) => type !== 'rowHeader' && `text-align: right!important;`}
  ${({ category }) => category === 'income' && `color: ${colors.green500}!important;`}
  ${({ category }) => category === 'expenses' && `color: ${colors.red500}!important;`}

  &:not(.expand) {
    font-size: 12px;
    font-family: ${typography.type.tertiary};
    padding: ${theme.spacing(1)} ${theme.spacing(2)};
  }
`

export const BalanceSheetTableCell = ({ type = 'value', ...args }) => {
  return <StyledTableCell type={type} {...args} />
}
BalanceSheetTableCell.propTypes = {
  type: PropTypes.oneOf(['header', 'rowHeader', 'value'])
}
