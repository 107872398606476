import { deleteCondominium } from '@condofy/next/service/condo'
import { store, condominiumActionTypes } from './index'

// ACTION TYPES
const CONDOMINIUM_DELETE = 'CONDOMINIUM_DELETE'
const CONDOMINIUM_DELETE_MODAL_VISIBLE = 'CONDOMINIUM_DELETE_MODAL_VISIBLE'

// ACTIONS
export const condominiumDeleteActions = (state, dispatch) => ({
  deleteCondominium: (payload) =>
    dispatch({ type: CONDOMINIUM_DELETE, payload }),

  closeDeleteCondominiumModal: () => {
    dispatch({
      type: condominiumActionTypes.CONDOMINIUM_SELECT,
      payload: false
    })
    dispatch({ type: CONDOMINIUM_DELETE_MODAL_VISIBLE, payload: false })
  },

  openDeleteCondominiumModal: (condominium) => {
    dispatch({
      type: condominiumActionTypes.CONDOMINIUM_SELECT,
      payload: condominium
    })
    dispatch({ type: CONDOMINIUM_DELETE_MODAL_VISIBLE, payload: true })
  }
})

// MIDDLEWARE
export const removeCondominium = (dispatch, payload) =>
  deleteCondominium(payload)

// REDUCER
export const deleteActionTypes = {
  CONDOMINIUM_DELETE,
  CONDOMINIUM_DELETE_MODAL_VISIBLE
}

// REDUCER
export const condominiumDeleteReducer = (state, action) => {
  switch (action.type) {
    case CONDOMINIUM_DELETE_MODAL_VISIBLE:
      return store(state, {
        deleteModalOpen: action.payload
      })

    default:
      return state
  }
}
