import React, { useEffect, useCallback } from 'react'
import { useUserContext } from '@condofy/next/store/user'
import { ProcessAreaModal } from './ProcessAreaModal'
import { Button } from '@condofy/components'
import { ModalSection } from '@condofy/next/components'
import { track } from '@condofy/next/service/segment'
import { useSnackbar, handleError } from '@condofy/next/hooks/useSnackbar'

const Footer = ({ onDelete }) => (
  <ModalSection border={1}>
    <Button variant='text' color='danger' onClick={onDelete}>
      Excluir Área
    </Button>
  </ModalSection>
)

export const EditAreaModal = () => {
  const { enqueueSnackbar } = useSnackbar()
  const {
    state: {
      area: { selectedArea, editModalOpen },
      currentCondominium
    },
    actions: {
      editArea,
      updateSelectedArea,
      listAreas,
      closeEditAreaModal,
      deleteArea
    }
  } = useUserContext()
  const stableCloseEditAreaModal = useCallback(closeEditAreaModal, [])

  useEffect(() => {
    stableCloseEditAreaModal()
  }, [currentCondominium, stableCloseEditAreaModal])

  const onDelete = () => {
    deleteArea({ areaId: selectedArea.id, currentCondominium })
      .then(() => {
        track(window.analytics, 'Area Removed', selectedArea)
        enqueueSnackbar('Área excluida com sucesso.', { variant: 'success' })
        closeEditAreaModal()
        listAreas({ currentCondominium })
      })
      .catch(handleError(enqueueSnackbar))
  }

  return (
    <ProcessAreaModal
      title='Editar Área'
      area={selectedArea}
      onOpen={editModalOpen}
      onClose={closeEditAreaModal}
      onSave={editArea}
      onChange={updateSelectedArea}
      trackMsg='Booking Area Edited'
      footer={<Footer onDelete={onDelete} />}
    />
  )
}
