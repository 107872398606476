import { combineReducers } from '@condofy/next/store/user/combineReducers'
import { listActions, listReducer, list, listActionTypes } from './list'

// ACTION TYPES
const ANNOUNCEMENT_RECEIVER_SET_LOADING_INDICATOR =
  'ANNOUNCEMENT_RECEIVER_SET_LOADING_INDICATOR'
const ANNOUNCEMENT_RECEIVER_SELECT = 'ANNOUNCEMENT_RECEIVER_SELECT'

// ACTIONS
export const announcementReceiverActions = (state, dispatch) => ({
  ...listActions(state, dispatch),

  selectAnnouncementReceiver: (payload) =>
    dispatch({ type: ANNOUNCEMENT_RECEIVER_SELECT, payload })
})

export const pending = (dispatch, payload) =>
  dispatch({ type: ANNOUNCEMENT_RECEIVER_SET_LOADING_INDICATOR, payload })

// MIDDLEWARE
export const announcementReceiverMiddlewares = (dispatch, action) => {
  switch (action.type) {
    case listActionTypes.ANNOUNCEMENT_RECEIVER_LIST:
      return list(dispatch, action.payload)

    default:
      return false
  }
}

// REDUCER
const announcementReceiverMainReducer = (state, action) => {
  switch (action.type) {
    case ANNOUNCEMENT_RECEIVER_SELECT:
      return store(state, { selected: action.payload })

    case ANNOUNCEMENT_RECEIVER_SET_LOADING_INDICATOR:
      return store(state, {
        metadata: {
          ...state.announcementReceiver.metadata,
          loading: action.payload
        }
      })

    default:
      return state
  }
}

export const announcementReceiverReducer = combineReducers({
  announcementReceiverMainReducer,
  listReducer
})

export const store = (state, value) => ({
  ...state,
  announcementReceiver: { ...state.announcementReceiver, ...value }
})

export const announcementReceiver = {
  createModalOpen: false,
  deleteModalOpen: false,
  editModalOpen: false,
  selected: {},
  items: [],
  metadata: {
    pageNumber: 1,
    totalRecords: 0,
    recordsPerPage: 10,
    loading: false
  }
}

export const announcementReceiverActionTypes = {
  ANNOUNCEMENT_RECEIVER_SET_LOADING_INDICATOR,
  ANNOUNCEMENT_RECEIVER_SELECT,

  ...listActionTypes
}
